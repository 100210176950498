import React from "react";
import AnalogSensorCard from "./AnalogSensorCard";
import { renameSensor } from "../../../utils/chooseSensorName";
const AnalogSensorList = ({ metadataId, data, setDraggedSensor, deviceId, activeList, refetch, registeredSensors, priorityList, setCards, cards }) => {
  const sensors = [
    {
      name: "A1",
      whichSensor: "first",
      id: data?.firstAnalog?.id,
      isActive: activeList?.first,
      value: data?.firstAnalog?.value,
      isConsumption: data?.firstAnalog?.isConsumption,
    },
    {
      name: "A2",
      whichSensor: "second",
      id: data?.secondAnalog?.id,
      isActive: activeList?.second,
      value: data?.secondAnalog?.value,
      isConsumption: data?.secondAnalog?.isConsumption,
    },
    {
      name: "A3",
      whichSensor: "third",
      id: data?.thirdAnalog?.id,
      isActive: activeList?.third,
      value: data?.thirdAnalog?.value,
      isConsumption: data?.thirdAnalog?.isConsumption,
    },
    {
      name: "A4",
      whichSensor: "fourth",
      id: data?.fourthAnalog?.id,
      isActive: activeList?.fourth,
      value: data?.fourthAnalog?.value,
      isConsumption: data?.fourthAnalog?.isConsumption,
    },
    {
      name: "A2-A3-A4",
      whichSensor: "fifth",
      id: data?.fifthAnalog?.id,
      value: data?.fifthAnalog?.value,
      isActive: activeList?.fifth,
      isConsumption: data?.fifthAnalog?.isConsumption,
    },
  ];
  const availableValues = (whichSensor) => {
    const defaultValue = [0, 1, 2];
    const currentSensor = renameSensor("analog", whichSensor) ? renameSensor("analog", whichSensor) : "A5";
    const inUse = cards?.map((el) => {
      if (el?.type === "analog") {
        return {
          sensor: renameSensor(el?.type, el?.whichSensor ? el?.whichSensor : el?.analogSensor?.whichSensor)
            ? renameSensor(el?.type, el?.whichSensor ? el?.whichSensor : el?.analogSensor?.whichSensor)
            : "A5",
          value: el.value,
        };
      }
    });
    const filter = inUse?.filter((a) => a?.sensor == currentSensor)?.map((a) => a.value);
    return defaultValue.filter((d) => !filter?.includes(d));
  };
  return (
    <div className="w-full flex flex-col">
      <div className="h-[52px] w-full flex items-center">
        <div className="h-[28px] w-full flex items-end">
          <p className="text-lg font-semibold text-gray-900 w-[60%]">Analog Sensörler</p>
          <p className="text-xs font-medium text-gray-600 w-[20%] flex items-center justify-center text-center gap-1">
            Değer <Info />
          </p>
          <p className="text-xs font-medium text-gray-600 w-[8%] flex items-center justify-center text-clip">Consumption</p>
        </div>
      </div>

      <div className="w-full flex flex-col px-0.5 gap-3 ">
        {sensors.map((item, index) => {
          return (
            <AnalogSensorCard
              key={index}
              name={item.name}
              cards={cards}
              setCards={setCards}
              refetch={refetch}
              sensorId={item.id}
              deviceId={deviceId}
              metadataId={metadataId}
              isActive={item.isActive}
              priorityList={priorityList}
              whichSensor={item.whichSensor}
              setDraggedSensor={setDraggedSensor}
              registeredSensors={registeredSensors}
              savedData={{ isConsumption: item?.isConsumption, value: item?.value }}
              activeValueButtons={availableValues(item?.whichSensor)}
            />
          );
        })}
      </div>
    </div>
  );
};

const Info = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path
        d="M7.49996 9.33317V6.99984M7.49996 4.6665H7.50579M13.3333 6.99984C13.3333 10.2215 10.7216 12.8332 7.49996 12.8332C4.2783 12.8332 1.66663 10.2215 1.66663 6.99984C1.66663 3.77818 4.2783 1.1665 7.49996 1.1665C10.7216 1.1665 13.3333 3.77818 13.3333 6.99984Z"
        stroke="#98A2B3"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AnalogSensorList;
