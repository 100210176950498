import React from "react";
import Badgets from "../../../components/buttons/Badgets";
import { EyeIcons } from "../../../assets/icons/PersonIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";

export default function NotificationWhoSee({ t, data }) {
  const readParticipantsCount = data?.participants?.filter(
    (participant) => participant?.isRead
  )?.length;

  return (
    <>
      <div className="flex flex-col max-w-[300px] min-w-[300px] h-full px-5 py-4">
        <div className="flex items-center gap-2 px-2 pb-2 border-b border-secondary-200 min-h-[47px]">
          <span className="flex text-lg font-semibold text-secondary-900">
            {t("chat:whoSee")}
          </span>
          <Badgets
            colorType={"fill-success"}
            label={`${readParticipantsCount} / ${data?.participants?.length}`}
            size={"sm"}
          />
        </div>
        <div className="flex flex-col overflow-y-auto scrollbar-hide flex-1">
          {data?.participants.map((user,index) => {
            return (
              <div key={index} className="flex min-h-[60px] max-h-[60px] items-center gap-x-3 py-[10px] px-2">
                {user?.avatar ? (
                  <img
                    className="min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] rounded-full border border-[#0000001A]"
                    src={generateFileUrl(user?.avatar)}
                  />
                ) : (
                  <NoAvatar
                    size={40}
                    name={user?.name}
                    lastName={user?.lastname}
                  />
                )}

                <div className="flex w-[65%] flex-col justify-center">
                  <span className="text-[#344054] text-sm font-semibold truncate">
                    {user?.name} {user?.lastname}
                  </span>
                  <span className="text-[#667085] text-sm font-normal">
                    {user?.department?.name || "Diğer"}
                  </span>
                </div>
                <div className="flex ml-auto">
                  {user?.isRead === true ? <EyeIcons /> : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
