import React, { useState, useEffect } from "react";
import {
  ToolTip,
  ValidateError,
  ValidateSuccess,
  ValidateWarning,
} from "../../assets/icons";
import { useLocation } from "react-router-dom";

const MintueSecondInput = ({
  label,
  theme,
  icon,
  placeholder,
  onChange,
  onBlur,
  value,
  height,
  disabled,
  validate,
  iconPosition,
  field,
  type,
  size,
  errorMessage,
  isPercentage,
  ...rest
}) => {
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();

  const sizeStyles = {
    xs: "40px",
    sm: "44px",
  };
  const inputSize = sizeStyles[size] || "40px";
  const { pathname } = useLocation();

  const onChangeSeconds = (el) => {
    if (Number(el.target.value) > Number(el.target.max)) {
      el.target.value = 0;
      setSeconds(0);
    } else {
      el.target.value = Number(el.target.value);
      setSeconds(Number(el.target.value));
    }
  };
  const onChangeMinutes = (el) => {
    if (Number(el.target.value) > Number(el.target.max)) {
      el.target.value = 0;
      setMinutes(0);
    } else {
      el.target.value = Number(el.target.value);
      setMinutes(Number(el.target.value));
    }
  };

  useEffect(() => {
    let minToSec = minutes * 60;
    let totalSeconds = minToSec + seconds;
    onChange(totalSeconds);
  }, [minutes, seconds]);

  useEffect(() => {
    if (value && minutes !== "" && seconds !== "") {
      let min = Math.floor(value / 60);
      let sec = value % 60;
      setMinutes(min);
      setSeconds(sec);
    }
  }, [value]);

  return (
    <div className={`flex flex-col relative w-full`}>
      <span
        className={`text-sm pl-0  w-full text-secondary-700 font-medium truncate dark:text-[#D6D6D6] ${pathname.includes("list-view") ? "mb-0" : "mb-1.5"
          } ${label ? "flex" : "hidden"} `}
      >
        {label}
      </span>
      <div
        style={{ height: inputSize }}
        className={`flex ${size === "sm" ? "px-[14px] py-[10px]" : "px-2 py-0.5"
          }  ${theme === "person"
            ? "focus-within:border-teal-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#C4EDE6] "
            : theme === "product"
              ? "focus-within:border-warning-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#FDE3C1] "
              : theme === "embedded"
                ? "focus-within:border-grayBlue-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#D2D6E8] "
                : "focus-within:border-primary-300 focus-within:outline  focus-within:outline-4 focus-within:outline-[#E8DFFB] "
          } flex w-full items-center border focus-within:ring-opacity-50 transition duration-300 rounded-lg dark:border-[#424242] dark:bg-[#0F0F0F]
            ${validate === "success"
            ? "border-validate-success"
            : validate === "warning"
              ? "border-validate-warning"
              : validate === "error"
                ? "border-validate-errorBorder"
                : "border-secondary-300 dark:border-[#424242]"
          } text-secondary-500 text-base
              ${disabled ? "bg-secondary-50  mx-0" : "bg-white"} `}
      >
        {validate && type !== "password" ? (
          <div className="flex items-center absolute right-[10px] ">
            {validate === "success" ? (
              <img src={ValidateSuccess} />
            ) : validate === "warning" ? (
              <img src={ValidateWarning} />
            ) : validate === "error" ? (
              <img src={ValidateError} />
            ) : validate === "tooltip" ? (
              <img src={ToolTip} />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        <input
          name="minutes"
          value={minutes}
          onBlur={onBlur}
          {...rest}
          {...field}
          max={999}
          disabled={disabled}
          onChange={(e) => {
            onChangeMinutes(e);
          }}
          placeholder={"min"}
          style={{ height: inputSize }}
          className={`h-full border-transparent text-base font-normal outline-none ${placeholder
            ? "text-secondary-900"
            : disabled
              ? "text-secondary-500"
              : "text-secondary-900"
            } dark:bg-[#0F0F0F] dark:text-[#737373] focus:border-transparent focus:ring-0 border-none rounded-lg w-full max-w-[40px] px-0 text-center ${type !== "password" && icon
              ? iconPosition === "right"
                ? "mr-6"
                : "mx-6"
              : "pl-0"
            } ${disabled ? "bg-secondary-50 dark:bg-[#0F0F0F] " : <></>} `}
          type={"number"}
        />
        :
        <input
          name="seconds"
          value={seconds}
          max={59}
          onBlur={onBlur}
          {...rest}
          {...field}
          disabled={disabled}
          onChange={(e) => {
            onChangeSeconds(e);
          }}
          placeholder={"sec"}
          style={{ height: inputSize }}
          className={`h-full border-transparent text-base font-normal outline-none ${placeholder
            ? "text-secondary-900"
            : disabled
              ? "text-secondary-500"
              : "text-secondary-900"
            } dark:bg-[#0F0F0F] dark:text-[#737373] focus:border-transparent focus:ring-0 border-none rounded-lg w-full max-w-[40px] px-0 text-center ${type !== "password" && icon
              ? iconPosition === "right"
                ? "mr-6"
                : "mx-6"
              : "pl-0"
            } ${disabled ? "bg-secondary-50 dark:bg-[#0F0F0F] " : <></>} `}
          type={"number"}
        />
      </div>
      {validate ? (
        <p
          className={` ${validate === "success" ? (
            "text-validate-success"
          ) : validate === "warning" ? (
            "text-validate-warning"
          ) : validate === "error" ? (
            "text-validate-error"
          ) : validate === "tooltip" ? (
            "text-secondary-500 text-sm leading-5"
          ) : (
            <></>
          )
            } text-sm mt-1.5 font-normal `}
        >
          {errorMessage || validate.toLocaleUpperCase()}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};
export default MintueSecondInput;
