import React, { useState, useMemo, useCallback } from "react";
import { DotsHorizontalIcon } from "../../../assets/icons/productIcons";
import moment from "moment";
import {
  AlertIconNotify,
  AttendIconNotify,
  EquipmentIconNotify,
  LossIconNotify,
  MessageIcon,
  NotifyIcons,
  NotifyPollIcon,
  SettingsIconNotify,
  TaskStatusUpdatedIcon,
} from "../../../assets/icons/PersonIcons";
import { DeleteIcon } from "../../../assets/icons/departmentIcon";
import { NavLink, useNavigate } from "react-router-dom";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { Toast } from "../../../utils/toastify/toast";
import { notificationService } from "../../../services/notification.service";
import { Loading } from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimension";

export default function NotificationsArea({
  notification,
  t,
  loading,
  refresh,
}) {
  const [query, setQuery] = useState("");
  const [openMenuId, setOpenMenuId] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const deleteNotification = async (id) => {
    await notificationService.deleteNotification(id).then((res) => {
      if (res?.data?.code === 1) {
        Toast("success", res?.data?.message);
        setIsAlert(false);
        navigate("/app/person/notify");
      } else {
        Toast("error", res?.data?.message);
        setIsAlert(false);
      }
    });
    refresh();
  };

  const readAllNotifications = async () => {
    await notificationService
      .updateIsRead()
      .then((res) => {
        res?.data?.code == 0 ? refresh() : Toast("error", t("product:errors"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sortedNotifications = useMemo(() => {
    if (!loading) {
      return notification?.data
        ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
        ?.filter((d) => d?.title?.toLowerCase().includes(query));
    }
    return [];
  }, [loading, notification, query]);

  const formatTimeAgo = useCallback(
    (createdAt) => {
      const createdAtDate = moment(createdAt);
      const currentDate = moment();
      const timeDifference = currentDate.diff(createdAtDate, "minutes");
      if (timeDifference < 1) {
        return t("just now");
      } else if (timeDifference < 60) {
        return `${timeDifference} ${t("min")}`;
      } else if (timeDifference < 24 * 60) {
        const hours = Math.floor(timeDifference / 60);
        return `${hours} ${t("h")}`;
      } else if (timeDifference < 2 * 24 * 60) {
        return t("yesterday");
      } else if (timeDifference < 7 * 24 * 60) {
        const days = Math.floor(timeDifference / (24 * 60));
        if (days === 1) {
          return `1 ${t("day ago")}`;
        } else if (days <= 2) {
          return `${days} ${t("days ago")}`;
        }
      }
      return createdAtDate.format("DD.MM.YYYY");
    },
    [t]
  );

  const truncatedMessageLimit = () => {
    if (width >= 1024 && width < 1280) {
      return 10;
    } else if (width >= 1280 && width < 1440) {
      return 32;
    } else if (width >= 1440 && width < 1600) {
      return 50;
    } else if (width >= 1600 && width < 1900) {
      return 73;
    } else if (width >= 1900 && width < 2200) {
      return 97;
    } else if (width >= 2200 && width < 2560) {
      return 120;
    } else if (width >= 2560 && width < 4000) {
      return 200;
    } else {
      return 20;
    }
  };

  return (
    <>
      <div className="h-full flex flex-col w-full px-3 py-4 border-x border-secondary-300">
        <div className="px-2">
          <div className="flex px-2 pb-1 border-b border-secondary-200 min-h-[47px] items-center">
            <span className="flex text-lg font-semibold text-secondary-900">
              {t("dashboard:notifications")}
            </span>
          </div>
        </div>
        <div
          className={`${
            loading ? "hidden" : "flex"
          } w-full justify-end mb-2 mt-3 cursor-pointer`}
        >
          <div
            onClick={() => readAllNotifications()}
            className="text-[#107569] text-sm font-semibold mr-2"
          >
            {t("person:markAsAllRead")}
          </div>
        </div>
        <div className="w-full h-[89%] flex flex-col items-center overflow-y-auto scrollbar-hide">
          {loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <span className="w-9 h-9">
                <Loading primary={"#15B79E"} secondary={"#fff"} size={32} />
              </span>
            </div>
          ) : (
            sortedNotifications?.map((d, i) => {
              const timeAgo = formatTimeAgo(d.createdAt);
              let iconComponent;
              switch (d?.type) {
                case "new-task-message":
                case "task":
                case "new-task-group":
                case "new-task":
                case "delete-task":
                case "update-task-status":
                case "reject-task-status":
                case "add-comment":
                case "delete-comment":
                case "update-upu-point":
                case "add-system-comment":
                  iconComponent = <TaskStatusUpdatedIcon />;
                  break;
                case "poll":
                case "create-poll":
                case "result-poll":
                  iconComponent = <NotifyPollIcon />;
                  break;
                case "attend_permission":
                case "attend_permission_reply":
                  iconComponent = <AttendIconNotify />;
                  break;
                case "send-message":
                case "delete-conversation":
                case "new-conversation":
                  iconComponent = <MessageIcon />;
                  break;
                case "order":
                case "junction":
                case "sensor_warning":
                  iconComponent = <EquipmentIconNotify />;
                  break;
                case "system":
                case "Connection":
                  iconComponent = <SettingsIconNotify />;
                  break;
                case "package_limit":
                  iconComponent = <AlertIconNotify />;
                  break;
                case "Announcement":
                  iconComponent = <NotifyIcons />;
                  break;
                case "losses":
                  iconComponent = <LossIconNotify />;
                  break;
                default:
                  iconComponent = <NotifyIcons />;
              }
              const truncatedMessage =
                d?.message?.length > truncatedMessageLimit()
                  ? `${d?.message?.slice(0, truncatedMessageLimit())}...`
                  : d?.message;

              return (
                <NavLink
                  onMouseLeave={() => {
                    setOpenMenuId(false);
                  }}
                  to={d?._id === true ? "" : `${d?._id}`}
                  key={i}
                  className={({ isActive }) =>
                    `w-full flex items-center gap-x-3 min-h-[72px] max-h-[72px] px-3 py-3 rounded-lg hover:bg-[#F9FAFB] cursor-pointer group relative ${
                      isActive && "bg-gray-50"
                    }`
                  }
                >
                  <div className="flex flex-row w-full items-center gap-x-3">
                    <div className="w-8 max-w-[32px] flex">{iconComponent}</div>
                    <div className="flex flex-col w-full">
                      <p className=" text-base w-full font-semibold text-[#344054]">
                        {d?.title}
                      </p>
                      <p className="text-md font-normal w-full text-[#667085]">
                        {truncatedMessage}
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        setOpenMenuId((prevId) =>
                          prevId === d?._id ? null : d?._id
                        );
                      }}
                      className={`hidden group-hover:flex mb-auto ml-auto absolute right-1 w-4 h-4 rotate-90`}
                    >
                      <DotsHorizontalIcon />
                    </div>
                    {openMenuId === d?._id ? (
                      <div
                        onClick={() => {
                          setIsAlert(true);
                          setSelectItem(d?._id);
                        }}
                        className="flex rounded-md border items-center  absolute right-3 -bottom-3 z-50 bg-white px-3 py-2"
                      >
                        <button className="flex gap-x-2 items-center justify-center text-[#D92D20]">
                          <DeleteIcon />
                          <span className="text-sm font-medium">
                            {t("person:deleteNoti")}
                          </span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex items-center right-2 pt-[6px] gap-x-2 group-hover:hidden absolute">
                    <p className="font-normal text-xs text-[#667085]">
                      {timeAgo}
                    </p>
                    {d?.isRead == false ? (
                      <span className="w-3 h-3 bg-[#0E9384] rounded-full"></span>
                    ) : null}
                  </div>
                </NavLink>
              );
            })
          )}
        </div>
        <AlertModal
          title={t("person:deleteNoti")}
          subTitle={t("equipments:deleteAlert")}
          isOpen={isAlert}
          setIsOpen={setIsAlert}
          applyButton={() => {
            deleteNotification(selectItem);
          }}
        />
      </div>
    </>
  );
}
