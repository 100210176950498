import React, { useState } from "react";
import { Button, CustomModal } from "../../components";
import { PlusIconNew } from "../../assets/icons/productIcons";
import DefinedCard from "../../steps/equipments/components/DefinedCard";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { equipmentService } from "../../services";
import { Toast } from "../../utils/toastify/toast";
import Skeleton from "react-loading-skeleton";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import AddEquipmentModal from "../../steps/equipments/modals/AddEquipmentModal";
import SettingUndefinedEquipmentModal from "./modal/SettingUndefinedEquipmentModal";
import NoData from "../../steps/step-navigation/components/NoData";

const SettingEquipment = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteNode, setDeleteNode] = useState("");
  const [selectedNode, setSelectedNode] = useState({});
  const [openNewEquipment, setOpenNewEquipment] = useState(false);

  const { data, isLoading, refetch, isPending, status, isFetching } = useQuery({
    queryKey: ["getAllNodes"],
    queryFn: async () => await equipmentService.getAllNodes(),
    retry: 0,
  });

  const onDeleted = async (id) => {
    await equipmentService.updateEquipmentDefinedToUndefined(id).then((res) => {
      if (res.data.code == 0) {
        refetch();
        setIsDelete(false);
        Toast("success", t("addEquipment:deletedEqu"));
      } else {
        Toast("error", t("product:errors"));
      }
    });
  };

  const generateSkeleton = (count, height, width) => {
    const skeletons = [];
    for (let i = 0; i < count; i++) {
      skeletons.push(
        <div key={i} className="flex items-center justify-center w-full h-full">
          <Skeleton height={height} width={width} />
        </div>
      );
    }
    return skeletons;
  };

  return (
    <>
      <div className="w-full h-full flex-1 flex flex-col items-start gap-6">
        {/*equ header*/}
        <div className="w-full h-[72px] pb-5 flex flex-col items-start gap-1 border-b border-solid border-[#E4E7EC]">
          <div className="w-full h-[52px] flex justify-between items-end">
            <div className="min-w-[268px] h-full flex flex-col items-start gap-1">
              <span className="text[#101828] text-lg font-semibold">
                {t("settingTab:equipments")}
              </span>
              <span className="text[#101828] text-sm font-normal">
                {t("settingTab:identifyEquipments")}
              </span>
            </div>

            <div className="h-10 min-w-[203px] flex items-end gap-3">
              <Button
                iconLeft={<PlusIconNew color={"#344054"} />}
                label={t("settingTab:defineNewEquipment")}
                colorType={"secondary-gray"}
                onClick={() => setOpenNewEquipment(!openNewEquipment)}
              />
            </div>
          </div>
        </div>

        {/*equ body container*/}
        <div className="w-full h-[70vh] flex flex-col items-start gap-4 overflow-hidden overflow-y-auto scrollbar-hide ">
          {isFetching ? (
            <div className="flex flex-col rounded-lg gap-2 items-center justify-center w-full h-full">
              {generateSkeleton(8, 64, 1000)}
            </div>
          ) : (
            <>
              {data?.defined?.length < 1 ? (
                <div className="w-full h-full flex items-center justify-center">
                  <NoData
                    header={t("label:noData")}
                    theme={"machine"}
                    button={false}
                  />
                </div>
              ) : (
                data.defined.map((item, index) => (
                  <DefinedCard
                    key={index}
                    isEquipment={true}
                    width={"w-full"}
                    name={item?.metadata?.brand + " " + item?.metadata?.model}
                    process={item?.process?.name || "----"}
                    avatar={item?.metadata?.image}
                    deleteOnclick={() => {
                      setIsDelete(true);
                      setDeleteNode(item?.id);
                    }}
                    editOnClick={() => {
                      setSelectedNode(item);
                      setIsOpen(true);
                    }}
                  />
                ))
              )}
            </>
          )}
        </div>
      </div>

      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={
          selectedNode ? t("addEquipment:updateEq") : t("addEquipment:title")
        }
        width="fit-content"
        children={
          <AddEquipmentModal
            t={t}
            setIsOpen={setIsOpen}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            refetch={refetch}
          />
        }
      />

      <CustomModal
        isOpen={openNewEquipment}
        setIsOpen={setOpenNewEquipment}
        modalTitle={t("settingTab:undefinedNodes")}
        width="664px"
        height={"700px"}
        children={
          <SettingUndefinedEquipmentModal
            t={t}
            data={data?.undefined}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            openNewEquipment={openNewEquipment}
            setOpenNewEquipment={setOpenNewEquipment}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        }
      />

      <AlertModal
        title={t("equipments:deleteEquipment")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isDelete}
        setIsOpen={setIsDelete}
        applyButton={() => {
          onDeleted(deleteNode);
        }}
      />
    </>
  );
};

export default SettingEquipment;
