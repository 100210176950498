import React, { useState } from "react";
import {
  DeclinedQuantityIcon,
  EventsIcon,
  MadePlayIcon,
  NewAcceptedIcon,
  NewDotsIcon,
  NewRejectedIcon,
  PlannedIcon,
  QuantityGreenIcon,
  ReadyIcon,
  TargetIcon,
} from "../../../components/new-cards/card-icons";
import { ThreeDotsIcon } from "../../../assets/icons/machineIcons";
import { EditIcon } from "../../../assets/icons/departmentIcon";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
const MrpMultiOperationCard = ({ onClick, item, isLast, index, order }) => {
  const { t } = useTranslation();
  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
      default:
        return "DÖN";
    }
  };
  const statusColor = () => {
    if (item?.status?.includes("finished")) {
      return "#ECFDF3";
    } else if (item?.status?.includes("started")) {
      return "#FFFAEB";
    } else if (item?.status?.includes("rejected")) {
      return "#D92D20";
    } else {
      return "#F2F4F7";
    }
  };
  const operationMap = item?.operations?.map((a) => {
    if (a?.internalOperation) {
      return a?.internalOperation?.activePiece
        ? a?.internalOperation?.activePiece
        : 0;
    } else {
      return a?.externalOperation?.activePiece
        ? a?.externalOperation?.activePiece
        : 0;
    }
  });
  const orderDataItem = item?.operations?.reduce(
    (acc, b) => acc || b?.orderData,
    null
  );
  let times =
    item?.times?.find((a) => a?.avgEstimatedTime > 0) || item?.times[0];

  return (
    <>
      <div
        onClick={onClick}
        className={`flex w-[312px] h-[112px] flex-row border items-start cursor-pointer rounded-lg border-secondary-300  bg-white relative group `}
      >
        {/* <div style={{ backgroundColor: statusColor() }} className="flex w-full min-h-[4px] max-h-[4px] h-1 rounded-t-[8px]"></div> */}
        <div className="flex flex-col w-[93%]">
          {item?.name?.length == 1 ? (
            <>
              {item?.name?.map((b) => {
                return (
                  <div className="flex flex-row items-center w-[100%]  justify-between pr-1 pt-2 pl-3">
                    <div className="flex flex-row items-center gap-x-0.5 h-5 ">
                      {b?.processType == "external" ? (
                        <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                          <p className="text-xs font-semibold text-secondary-700">
                            {"HİZ"}
                          </p>
                        </div>
                      ) : (
                        <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                          <p className="text-xs font-semibold text-secondary-700">
                            {item?.processType
                              ? operationTypes(b?.processType)
                              : "DÖN"}
                          </p>
                        </div>
                      )}
                      <div className=" bg-white max-w-[230px] ml-1 rounded-r-[8px]">
                        <p className="font-semibold text-sm text-secondary-700  truncate">
                          {b?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="flex flex-row items-center gap-x-2 pr-1 pt-1.5 pl-2 w-[270px] min-w-[270px] max-w-[270px] ">
              {item?.name?.map((a, index) => {
                return (
                  <div
                    className={`flex border items-center ${
                      item?.name?.length == 2 ? "w-1/2 " : "w-1/3"
                    }  h-6 gap-x-1 flex-row rounded`}
                  >
                    <div className="px-1 bg-[#F2F4F7] w-fit border-r h-full flex items-center">
                      {a.processType === "external" ? (
                        <p className="text-xs font-semibold text-secondary-700">
                          {"HİZ"}
                        </p>
                      ) : (
                        <p className="text-xs font-semibold text-secondary-700">
                          {a?.processType
                            ? operationTypes(a?.processType)
                            : "--"}
                        </p>
                      )}
                    </div>
                    <div className="bg-white truncate px-1">
                      <p className="font-semibold text-xs text-secondary-700  truncate">
                        {a?.name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* <div className="flex flex-row items-center w-full">
            <div className="flex flex-col pr-2 pl-2 w-[36%] min-w-[36%]">
              <div className="flex flex-row items-center w-full  border-b pb-0.5">
                <p className="font-normal text-secondary-600 ml-1 text-[10px]">
                  {t("product:inStocks")}
                </p>
                <div className="ml-auto">
                  {isLast ? (
                    // <div data-tooltip-id={`${item?.id}`}
                    // data-tooltip-content={t("product:thisOperations")} className="ml-auto group-hover:flex">
                    //   <InfoIcon />
                    // </div>
                    <p className="text-xs font-medium max-w-[45px] truncate text-secondary-700">
                      {order?.product?.qualityQuantity || "0"}
                    </p>
                  ) : (
                    <p className="text-xs font-medium max-w-[45px] truncate text-secondary-700">
                      {operationMap?.reduce((a, b) => a + b, 0) || "0"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center w-full border-b pb-0.5  pt-[4px]">
                <p className="font-normal text-secondary-600 ml-1 text-[10px]">
                  {t("product:backlog")}
                </p>
                <div className="ml-auto">
                  <p className="text-xs font-medium max-w-[55px] truncate text-secondary-700">
                    {index === 1
                      ? item?.ready === 0
                        ? "0"
                        : item?.ready % 1 !== 0
                        ? item?.ready.toFixed(1)
                        : item?.ready || "0"
                      : item?.ready === 0
                      ? "0"
                      : item?.ready % 1 !== 0
                      ? item?.ready.toFixed(1)
                      : item?.ready || "0"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center w-full pb-0.5 pt-[4px]">
                <p className="font-normal text-secondary-600 ml-1 text-[10px]">
                  {t("product:planneds")}
                </p>
                <div className="ml-auto">
                  <p className="text-xs font-medium text-secondary-700">
                    {item?.planned || "0"}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center w-[64%] min-w-[64%] rounded-tl-[4px] border border-r-0 border-b-0 border-[#E4E7EC]">
              <div className="flex flex-col items-start border-r w-1/2 border-r-[#E4E7EC]">
                <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                  <p className="text-xs font-medium text-secondary-700">
                    {t("product:workOrderQty")}
                  </p>
                </div>
                <div className="flex items-center w-full px-1.5">
                  <div className="flex py-[3px] border-b flex-row items-center w-full justify-between">
                    <p className="text-[10px] w-[50px] truncate text-secondary-500 font-normal">
                      {t("product:remainder")}
                    </p>
                    <p className="font-medium text-secondary-600 text-xs">
                      {orderDataItem?.wipPiece == 0
                        ? "0"
                        : item?.operations[0]?.piece || "0"}
                    </p>
                  </div>
                </div>
                <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full justify-between">
                  <p className="text-[10px] text-secondary-500 font-normal">
                    {t("product:applieds")}
                  </p>
                  <p className={`font-medium text-secondary-600 text-xs`}>
                    {item?.finishedPiece || "0"}
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-center  w-1/2">
                <div className="px-0.5 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                  <p className="text-xs font-medium text-secondary-700">
                    {times?.type === "external"
                      ? t("product:cycleTimes")
                      : t("product:cycleTime")}
                  </p>
                </div>
                <div className="flex items-center w-full px-1.5">
                  <div className="flex py-[3px] flex-row border-b items-center w-full justify-between">
                    <p className="text-[10px] text-secondary-500 font-normal">
                      {t("product:target")}
                    </p>
                    <p className="font-medium text-secondary-600 ml-auto truncate text-xs">
                      {times?.type == "internal"
                        ? legacyFormatSeconds(times?.estimatedTime)
                        : `${times?.estimatedTime} `}
                    </p>
                  </div>
                </div>
                <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full justify-between">
                  <p className="text-[10px] text-secondary-500 font-normal">
                    {t("product:applieds")}
                  </p>
                  <p
                    className={`font-medium  ml-auto truncate text-xs ${
                      (
                        times?.type == "internal"
                          ? times?.estimatedTime < times?.avgEstimatedTime
                          : times?.estimatedTime * 86400 <
                            times?.avgEstimatedTime
                      )
                        ? "text-[#F04438]"
                        : "text-[#079455] "
                    }`}
                  >
                    {times?.avgEstimatedTime
                      ? times?.type == "internal"
                        ? legacyFormatSeconds(
                            times?.avgEstimatedTime?.toFixed(0)
                          )
                        : `${Math.ceil(
                            times?.avgEstimatedTime / 60 / 60 / 24
                          )} `
                      : "--"}
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          <div className="flex flex-row items-center h-[25px] justify-between w-full  border-t border-b border-t-[#E4E7EC] bg-[#F9FAFB] mt-1">
            <div className="w-[33%] pl-3 pr-1 border-r h-full flex items-center">
              <p className="text-xxs font-medium text-secondary-700">
                {times?.type === "external"
                  ? t("product:cycleTimes")
                  : t("product:cycleTime")}
              </p>
            </div>

            <div className="flex flex-row pl-2 pr-1 items-center w-[33%]">
              <TargetIcon />
              <p className="text-xs font-medium ml-1 text-secondary-700">
                {times?.type == "internal"
                  ? legacyFormatSeconds(times?.estimatedTime)
                  : `${times?.estimatedTime}`}
              </p>
              <p className="text-xxs text-secondary-600 ml-0.5">
                {t("product:target")}
              </p>
            </div>
            <div className="flex flex-row items-center w-[33%]">
              <PlayIcon
                color={`${
                  (
                    times?.type == "internal"
                      ? times?.estimatedTime < times?.avgEstimatedTime
                      : times?.estimatedTime * 86400 < times?.avgEstimatedTime
                  )
                    ? "#F04438"
                    : "#079455"
                }`}
              />
              <p
                className={`text-xs font-medium ml-1 ${
                  (
                    times?.type == "internal"
                      ? times?.estimatedTime < times?.avgEstimatedTime
                      : times?.estimatedTime * 86400 < times?.avgEstimatedTime
                  )
                    ? "text-[#F04438]"
                    : "text-[#079455] "
                }`}
              >
                {times?.avgEstimatedTime
                  ? times?.type == "internal"
                    ? legacyFormatSeconds(times?.avgEstimatedTime?.toFixed(0))
                    : `${Math.ceil(times?.avgEstimatedTime / 60 / 60 / 24)} `
                  : "--"}
              </p>
              <p
                className={`text-xxs ${
                  (
                    times?.type == "internal"
                      ? times?.estimatedTime < times?.avgEstimatedTime
                      : times?.estimatedTime * 86400 < times?.avgEstimatedTime
                  )
                    ? "text-[#F04438]"
                    : "text-[#079455] "
                }  ml-0.5`}
              >
                {t("product:applieds")}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2 w-[100%] pl-3 pr-3 mt-[5px]">
            <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] max-w-[28%]  border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">
                  {t("product:inStock")}
                </p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-0.5 py-[2px] justify-center">
                {isLast ? (
                  <p className="text-xs truncate font-medium text-secondary-700">
                    {order?.product?.qualityQuantity || "0"}
                  </p>
                ) : (
                  <p className="text-xs truncate font-medium text-secondary-700">
                    {operationMap?.reduce((a, b) => a + b, 0) || "0"}
                  </p>
                )}
              </div>
            </div>
            <div className="w-[71%] min-w-[71%] max-w-[71%] h-[42px] flex items-center border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
              <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                <span className="text-xxs truncate text-[#475467] font-medium ">
                  {t("product:workOrder")}
                </span>
              </div>
              <div className="flex flex-col w-full items-start">
                <div className="flex flex-row items-center w-full border-b border-r px-1">
                  <p className="text-xxs font-normal text-secondary-600">
                    {t("product:production")}
                  </p>

                  <p className="text-xs truncate max-w-[68%] font-medium ml-auto text-secondary-600">
                    {item?.open || "0"}
                  </p>
                </div>
                <div className="flex flex-row items-center w-full border-r px-1">
                  <p className="text-xxs font-normal text-secondary-600">
                    {t("product:plannedNew")}
                  </p>
                  <p className="text-xs font-medium truncate max-w-[68%] ml-auto text-secondary-600">
                    {item?.planned || "0"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-full items-start">
                <div className="flex flex-row items-center w-full border-b  px-1">
                  <p className="text-xxs font-normal text-secondary-600">
                    {t("product:remainings")}
                  </p>
                  <p className="text-xs font-medium truncate max-w-[67%] ml-auto text-secondary-600">
                    {item?.remainingPiece || "0"}
                  </p>
                </div>
                <div className="flex flex-row items-center w-full px-1">
                  <p className="text-xxs font-normal text-secondary-600">
                    {t("product:appl")}
                  </p>
                  <p className="text-xs font-medium truncate max-w-[67%] ml-auto text-secondary-600">
                    {item?.finishedPiece || "0"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: statusColor() }}
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0  border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          <p
            className={`text-xxs font-semibold whitespace-nowrap ${
              item?.status?.includes("started")
                ? "text-[#CA8504]"
                : item?.status?.includes("finished")
                ? "text-[#079455]"
                : item?.status.includes("rejected")
                ? "text-white"
                : "#475467"
            } -rotate-90`}
          >
            {t("product:semiProduct")}
          </p>
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
        <Tooltip className="z-50" id={`${item?.id}`} />
      </div>
    </>
  );
};

export default MrpMultiOperationCard;

const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_20580_11950)">
        <path
          d="M6.06065 6C6.21739 5.55445 6.52675 5.17874 6.93395 4.93942C7.34116 4.70011 7.81991 4.61263 8.28543 4.69248C8.75096 4.77233 9.17319 5.01435 9.47737 5.37569C9.78154 5.73702 9.94802 6.19435 9.94732 6.66667C9.94732 8 7.94732 8.66667 7.94732 8.66667M8.00065 11.3333H8.00732M14.6673 8C14.6673 11.6819 11.6825 14.6667 8.00065 14.6667C4.31875 14.6667 1.33398 11.6819 1.33398 8C1.33398 4.3181 4.31875 1.33334 8.00065 1.33334C11.6825 1.33334 14.6673 4.3181 14.6673 8Z"
          stroke="#98A2B3"
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_20580_11950">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PlayIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M2.91699 2.91065C2.91699 2.34414 2.91699 2.06088 3.03511 1.90474C3.13801 1.76871 3.2953 1.68454 3.46556 1.67437C3.661 1.6627 3.89668 1.81983 4.36805 2.13407L10.5021 6.22345C10.8916 6.48311 11.0863 6.61294 11.1542 6.77658C11.2135 6.91964 11.2135 7.08043 11.1542 7.22349C11.0863 7.38713 10.8916 7.51696 10.5021 7.77661L4.36805 11.866C3.89668 12.1802 3.661 12.3374 3.46556 12.3257C3.2953 12.3155 3.13801 12.2314 3.03511 12.0953C2.91699 11.9392 2.91699 11.6559 2.91699 11.0894V2.91065Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
