import React, { useEffect, useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import { Button } from "../../../components";
import { CoinsStackedIcon, FilterIcon, MeasuringIcon, MinusIcon } from "../../../assets/icons/productIcons";
import AssemblyProductCard from "./AssemblyProductCard";
import AddAssemblyCard from "./AddAssemblyCard";
import { productService } from "../../../services/product.service";

function SecondAssemblyModal({ prevData, isAddedProducts, setIsAddedProducts, setIsFirstModal }) {
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [unitValue, setUnitValue] = useState("");
  const [data, setData] = useState([]);
  async function handleProducts() {
    const { data } = await productService.listProduct();
    setData(data);
  }
  const handleAddUnitValue = () => {
    const newProductId = selectedProduct.id;
    const existingProductIndex = isAddedProducts.findIndex((product) => product.id === newProductId);
    if (existingProductIndex !== -1) {
      const updatedProducts = [...isAddedProducts];
      updatedProducts[existingProductIndex].unitValue += unitValue;
      setIsAddedProducts(updatedProducts);
    } else {
      const addedProduct = { ...selectedProduct, unitValue };
      setIsAddedProducts([...isAddedProducts, addedProduct]);
    }
    setSelectedProduct([]);
    setUnitValue("");
  };

  const handleRemoveProduct = (productId) => {
    const updatedProducts = isAddedProducts.filter((product) => product.id !== productId);
    setIsAddedProducts(updatedProducts);
  };

  useEffect(() => {
    if (!prevData) {
      setIsFirstModal(true);
    }
    handleProducts();
  }, []);
  return (
    <div className="flex py-4 gap-y-6 min-h-[520px] h-[520px] w-full gap-x-6">
      <div className="flex flex-col w-1/2 h-full gap-y-5">
        <div className="flex max-h-[42px] min-h-[42px] h-11 gap-x-3">
          <SearchInput />
          <Button iconLeft={<FilterIcon />} colorType={"secondary-gray"} size={"lg"} />
        </div>
        <div className="flex flex-1 w-full flex-col gap-y-4 overflow-y-scroll scrollbar-hide">
          {data.map((item, index) => {
            return (
              <AssemblyProductCard
                index={index}
                item={item}
                image={item?.image}
                stockId={item?.stockCode}
                setSelectedProduct={setSelectedProduct}
                selectedProduct={selectedProduct}
                setIsAddedProducts={setIsAddedProducts}
                isAddedProducts={isAddedProducts}
                handleAddUnitValue={handleAddUnitValue}
                unitValue={unitValue}
                setUnitValue={setUnitValue}
              />
            );
          })}
        </div>
      </div>
      <div className="flex h-full w-[1px] bg-[#EAECF0]"></div>
      <div className="flex flex-col w-1/2 h-full">
        <div className="flex gap-y-5 h-full w-full flex-col">
          <span className="text-[#101828] font-semibold text-lg">Eklenen Ürünler</span>
          <div className="flex flex-col h-full max-h-[415px] border-b border-[#EAECF0] overflow-y-auto overflow-x-hidden scrollbar-hide">
            {isAddedProducts.map((item, index) => {
              return (
                <div className="flex flex-col">
                  <AddAssemblyCard item={item} handleRemoveProduct={handleRemoveProduct} />
                  {index !== isAddedProducts.length - 1 && <div className="w-full h-[1px] bg-[#EAECF0] my-3"></div>}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center w-full mt-2">
          <span className="text-[#101828] text-base font-semibold">Toplam Ürün Sayısı</span>
          <span className="ml-auto text-[#475467] font-normal text-base">{isAddedProducts?.length}</span>
        </div>
      </div>
    </div>
  );
}

export default SecondAssemblyModal;
