import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input } from "../../../components";
import { Toast } from "../../../utils/toastify/toast";
import { equipmentService } from "../../../services/equipment.service";

const UpdateToleranceValue = ({ onClose, metadataId, refetch }) => {
  const { t } = useTranslation();
  const [isToleranceValue, setIsToleranceValue] = useState("");
  const [error, setError] = useState(false);

  const handleSave = async () => {
    const saveData = { workTolerance: isToleranceValue };
    if (isToleranceValue === "") {
      setError(true);
    } else {
      setError(false);
      await equipmentService.updateEquipmentWorkTolerance(metadataId, saveData).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res?.data?.message);
          refetch();
        } else {
          Toast("error", res?.data?.message);
        }
      });
      onClose();
    }
  };

  return (
    <div className="flex flex-col gap-y-6 mt-6">
      <Input
        validate={error ? "error" : ""}
        type={"number"}
        onChange={(e) => {
          setIsToleranceValue(e.target.value);
          if (e.target.value !== "") {
            setError(false);
          }
        }}
        placeholder={t("addProcess:stationTolerances")}
        errorMessage={t("addProcess:thisFieldCannot")}
        label={t("addProcess:stationTolerance")}
      />
      <div className="flex w-full items-center justify-center gap-x-3">
        <Button colorType={"secondary-gray"} label={t("buttons:stop")} onClick={onClose} />
        <Button colorType={"primary-machine"} label={t("buttons:save")} onClick={handleSave} />
      </div>
    </div>
  );
};

export default UpdateToleranceValue;
