import React, { useEffect, useState } from "react";
import MintueSecondInput from "../inputs/MintueSecondInput";
import UnitPriceInput from "../inputs/UnitPriceInput";
import { productService } from "../../services/product.service";
import { useQuery } from "@tanstack/react-query";
import generateFileUrl from "../../utils/generateFileUrl";
import Button from "../buttons/Button";
import Input from "../inputs/Input";
import { Toast } from "../../utils/toastify/toast";
import OperationDetailTargetEditModal from "./OperationDetailTargetEditModal";
import CustomModal from "./CustomModal";
import CustomImage from "../CustomImage";
import { useTranslation } from "react-i18next";

// Para birimi seçeneklerini burada tanımlıyoruz.
export const currencyItems = [
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
  { label: "RUB", value: "RUB" },
  { label: "UAH", value: "UAH" },
  { label: "TRY", value: "TRY" },
];

const ExternalOperationEditCycleTimeModal = ({ selectedOperation, onClose, refetcPage, setMultipleExternalOpenModal }) => {
  const {t} = useTranslation()
  const [inputs, setInputs] = useState({
    standard: {
      checked: false,
      productionTime: "00:00",
      setupTime: "00:00",
      costPerMinute: "1",
      currency: "TRY",
    },
    suppliers: {},
  });

  const [openTarget, setOpenTarget] = useState(false)

  const handleStandardCheckboxChange = () => {
    const newChecked = !inputs?.standard?.checked;

    setInputs((prevState) => ({
      ...prevState,
      standard: {
        ...prevState.standard,
        checked: newChecked,
      },
      suppliers: Object.keys(prevState.suppliers).reduce((acc, key) => {
        acc[key] = {
          ...prevState.suppliers[key],
          checked: newChecked,
          productionTime: newChecked ? prevState?.standard?.productionTime : prevState?.suppliers[key]?.productionTime,
          setupTime: newChecked ? prevState?.standard?.setupTime : prevState?.suppliers[key]?.setupTime,
          costPerMinute: newChecked ? prevState?.standard?.costPerMinute : prevState?.suppliers[key]?.costPerMinute,
          currency: newChecked ? prevState?.standard?.currency : prevState?.suppliers[key]?.currency,
        };
        return acc;
      }, {}),
    }));
  };

  const handleStationCheckboxChange = (supplier) => {
    setInputs((prevState) => {
      const updateSuppliers = {
        ...prevState.suppliers,
        [supplier]: {
          ...prevState.suppliers[supplier],
          checked: !prevState.suppliers[supplier].checked,
        },
      };
      const allSupplierSelected = Object.values(updateSuppliers).every((s) => s.checked);
      return {
        ...prevState,
        standard: {
          ...prevState.standard,
          checked: allSupplierSelected,
        },
        suppliers: updateSuppliers,
      };
    });
  };

  const handleStandardInputChange = (field, value) => {
    setInputs((prevState) => {
      const updatedSuppliers = Object.keys(prevState.suppliers).reduce((acc, key) => {
        acc[key] = {
          ...prevState.suppliers[key],
          [field]: prevState?.suppliers[key]?.checked ? value : prevState?.suppliers[key][field],
        };
        return acc;
      }, {});

      return {
        ...prevState,
        standard: {
          ...prevState.standard,
          [field]: value,
        },
        suppliers: updatedSuppliers,
      };
    });
  };

  const handleStationInputChange = (field, value, supplier) => {
    setInputs((prevState) => ({
      ...prevState,
      suppliers: {
        ...prevState.suppliers,
        [supplier]: {
          ...prevState.suppliers[supplier],
          [field]: value,
        },
      },
    }));
  };

  const { data, refetch } = useQuery({
    queryKey: ["operationDetailCycle"],
    queryFn: async () =>
      await productService?.getOperationCycleTimeList(
        selectedOperation?.internalOperationId ? "internal" : "external",
        selectedOperation?.internalOperationId ? selectedOperation?.internalOperationId : selectedOperation?.externalOperationId
      ),
    enabled: false,
    retry: 0,
  });
  const suppliers = data?.suppliers
    ? data?.suppliers.reduce((acc, item) => {
      acc[`${item?.supplier?.name}`] = {
        checked: false,
        productionTime: item?.estimatedTime,
        setupTime: item?.estimatedSettingsTime,
        costPerMinute: Number(item?.costOfPiece) ? item?.costOfPiece : 0,
        currency: item?.currency, // Varsayılan para birimi
        image: item?.supplier?.image,
        id: item?.id,
      };
      return acc;
    }, {})
    : {};

  const onSubmit = async () => {
    const values = {
      estimatedTime: Number(inputs?.standard?.productionTime),
      costOfPiece: Number(inputs.standard?.costPerMinute),
      type: "external",
      plannableSupplier: Object.entries(inputs?.suppliers).map(([_, supplier]) => {
        return supplier?.checked ? {
          id: supplier?.id,
          estimatedTime: Number(supplier.productionTime),
          costOfPiece: Number(supplier?.costPerMinute),
          currency: supplier?.currency,
        } : null
      }).filter((element) => { return element != null }),
    };
    await productService.updateProductOperation(selectedOperation?.externalOperationId, values).then((res) => {
      if (res.data.code == 0) {
        Toast("success", res?.data?.message);
        refetcPage()
      } else {
        Toast("error", res?.data?.message);
      }
      setMultipleExternalOpenModal(false)
    });
  };

  useEffect(() => {
    refetch();
  }, [selectedOperation]);

  useEffect(() => {
    if (data) {
      setInputs({
        standard: {
          checked: false,
          productionTime: data?.operations?.estimatedTime || "00:00",
          setupTime: data?.operations?.estimatedSettingsTime || "00:00",
          costPerMinute: data?.operations?.costOfPiece || "1",
          currency: "TRY",
        },
        suppliers,
      });
    }
  }, [data]);

  return (
    <>
      <div className="h-[550px] min-h-[550px] flex flex-col justify-between">
        <div className="mt-8 flex-1 overflow-y-auto scrollbar-hide">
          <table className="w-full">
            <thead>
              <tr>
                <th className="border-b border-r border-gray-200 p-2 text-xs font-medium text-gray-700">{t("product:stations")}</th>
                <th className="border-b border-r border-gray-200 p-2 text-xs font-medium text-gray-700">{t("product:estimatedProductionTimes")}</th>
                <th className="border-b border-r border-gray-200 p-2 text-xs font-medium text-gray-700">{t("product:minuteCost")}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-[80px] min-h-[80px] border-b border-gray-200">
                <td className="h-[80px] border-r py-5 px-4">
                  <label className="flex items-center">
                    <input
                      style={{ accentColor: "#DC6803" }}
                      className="w-4 h-4"
                      type="checkbox"
                      checked={inputs?.standard?.checked}
                      onChange={handleStandardCheckboxChange}
                    />
                    <p className="truncate font-semibold text-secondary-900 ml-3">{t("product:allOperationStandart")}</p>
                  </label>
                </td>
                <td className="border-r py-5 px-4">
                  <Input
                    value={inputs?.standard?.productionTime}
                    onChange={(e) => handleStandardInputChange("productionTime", e.target.value)}
                    theme={"product"}
                  />
                </td>
                <td className="border-r py-5 px-4 ">
                  <UnitPriceInput
                    theme={"product"}
                    items={currencyItems}
                    setValue={(field, value) => {
                      if (field === "price") {
                        handleStandardInputChange("costPerMinute", value); // Fiyat güncelleniyor
                      } else if (field === "currency") {
                        handleStandardInputChange("currency", value); // Para birimi güncelleniyor
                      }
                    }}
                    price={inputs?.standard?.costPerMinute}
                    currency={inputs?.standard?.currency}
                  />
                </td>
              </tr>

              {Object.keys(inputs?.suppliers || {}).map((supplier, index) => (
                <tr key={index} className="h-[80px] min-h-[80px] border-b border-secondary-200">
                  <td className="border-r border-gray-200 py-5 px-4">
                    <label className="flex items-center">
                      <input
                        style={{ accentColor: "#DC6803" }}
                        className="w-4 h-4"
                        type="checkbox"
                        checked={inputs?.suppliers[supplier]?.checked}
                        onChange={() => handleStationCheckboxChange(supplier)}
                      />
                      <div className="flex items-center ml-3">
                        {inputs?.suppliers[supplier]?.image ? (
                          <img
                            src={generateFileUrl(inputs?.suppliers[supplier]?.image)}
                            className="w-[64px] h-[48px] rounded border"
                          />
                        ) : (
                          <div className="w-[64px] min-w-[64px] h-[48px] min-h-[48px] rounded flex  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                            {supplier?.slice(0, 2)?.toUpperCase()}
                          </div>
                        )}
                        <div className="flex flex-col">
                          <p className="font-semibold ml-2 text-secondary-900">{supplier}</p>
                        </div>
                      </div>
                    </label>
                  </td>
                  <td className="border-r border-gray-200 py-5 px-4">
                    <Input
                      value={inputs?.suppliers[supplier]?.productionTime}
                      onChange={(e) => handleStationInputChange("productionTime", e.target.value, supplier)}
                      disabled={!inputs?.suppliers[supplier]?.checked}
                      theme={"product"}
                    />
                  </td>

                  <td className="border-r border-gray-200 py-5 px-4 ">
                    <UnitPriceInput
                      theme={"product"}
                      items={currencyItems}
                      setValue={(field, value) => {
                        if (field === "price") {
                          handleStationInputChange("costPerMinute", value, supplier); // Fiyat güncelleniyor
                        } else if (field === "currency") {
                          handleStationInputChange("currency", value, supplier); // Para birimi güncelleniyor
                        }
                      }}
                      price={inputs?.suppliers[supplier]?.costPerMinute}
                      currency={inputs?.suppliers[supplier]?.currency}
                      disabled={!inputs?.suppliers[supplier]?.checked}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="h-[44px] min-h-[44px] w-full flex mt-4 gap-4">
          <Button colorType={"secondary-gray"} label={t("buttons:stop")} onClick={onClose} />
          {/* <Button colorType={"primary-product"} label={"Güncelle"} onClick={()=> setOpenTarget(true)} /> */}
          <Button colorType={"primary-product"} label={t("buttons:update")} onClick={onSubmit} />
        </div>
      </div>
      <CustomModal
        isOpen={openTarget}
        setIsOpen={setOpenTarget}
        onClose={() => { setOpenTarget(false) }}
        width={400}
        children={
          <OperationDetailTargetEditModal setOpenTarget={setOpenTarget} onSubmit={onSubmit} />
        }
      />
    </>
  );
};

export default ExternalOperationEditCycleTimeModal;