import React, { useEffect, useState } from "react";
import { Button, CustomModal, Loading } from "../../components";
import { ArrowLeftIcon, ArrowRightIcon, ScrollChevronIcon } from "../../assets/icons/productIcons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { formatSeconds } from "../../utils/secondToHoursAndMinute";
import { useNavigate, useLocation } from "react-router-dom";
import TransactionDetailModal from "../../components/modal/TransactionDetailModal";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import SelectSupplierModal from "./modals/SelectSupplierModal";
import NewPlannedJunctionExternalModal from "./modals/NewPlannedJunctionExternalModal";
import SelectUserModal from "../../components/modal/SelectUserModal";
import SelectEquModal from "../../components/modal/SelectEquModal";
import QualityControlForm from "../../components/modal/QualityControlForm";
import ExternalActiveAndPlannedCard from "../../components/junction/ExternalActiveAndPlannedCard";
import { CheckIcon } from "../../assets/icons/PersonIcons";
import useWindowDimensions from "../../hooks/useWindowDimension";
import QualityDoneCard from "../../components/new-cards/quality-done-card/QualityDoneCard";
import DoneAndQualityWaitingCard from "../../components/new-cards/done-quality-waiting-card/DoneAndQualityWaitingCard";
import ActiveCard from "../../components/new-cards/active-card/ActiveCard";
import PlannedCard from "../../components/new-cards/plan-card/PlannedCard";
import SupplyPlannedCard from "../../components/new-cards/service-supply/components/SupplyPlannedCard";
import SupplyActiveCard from "../../components/new-cards/service-supply/components/SupplyActiveCard";
import SupplyQualityWaitingCard from "../../components/new-cards/service-supply/components/SupplyQualityWaitingCard";
import SupplyQualityDoneCard from "../../components/new-cards/service-supply/components/SupplyQualityDoneCard";
import QualityTab from "./quality/components/QualityTab";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Toast } from "../../utils/toastify/toast";

const FlexOperationJunctions = ({
  data,
  refetch,
  operation,
  orderIds,
  selectedJunctions,
  setSelectedJunctions,
  junctionIds,
  onScrolling = () => {},
  isFetching = false,
  setSelectedOperation = () => {},
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [junctionId, setJunctionId] = useState(null);
  const { height } = useWindowDimensions();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [junctionType, setJunctionType] = useState("internal");
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [isSelectUserModal, setIsSelectUserModal] = useState();
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [isSelectSupplierModal, setIsSelectSupplierModal] = useState();
  const [isSelectNodeModal, setIsSelectNodeModal] = useState();
  const [junctionDetailOpen, setJunctionDetailOpen] = useState(false);
  const [newPlannedJunctionExternalModal, setNewPlannedJunctionExternalModal] = useState(false);
  const [productType, setProductType] = useState("standart");
  const toggleSelection = (id) => {
    if (junctionIds.includes(id)) {
      setSelectedJunctions(selectedJunctions.filter((junction) => junction.id !== id));
    } else {
      setSelectedJunctions([...selectedJunctions, { id: id }]);
    }
  };
  const junctions = window.location.href.includes("move-junction")
    ? data?.filter((obj) => obj?.status === "active")
    : data?.filter((obj) => obj?.status === "active" || obj?.status === "planned");

  const activeJunctions = junctions ? [...junctions] : [];

  const completedWorks = data?.filter((obj) => obj?.status === "completed" && obj?.metadata?.qualityStatus == "waiting");

  const completedAndApprovedWorks = data?.filter((obj) => obj?.status === "completed" && obj?.metadata?.qualityStatus !== "waiting");

  const moments = require("moment");
  require("moment/locale/tr");

  const sortedCompletedWorks = completedWorks?.sort((a, b) => moment(b?.startDate).unix() - moment(a?.startDate).unix());

  const groupedByDateCompleted = sortedCompletedWorks?.reduce((acc, currentItem) => {
    const endDate = moments(currentItem?.startDate).locale("tr").format("LL");
    if (!acc[endDate]) {
      acc[endDate] = [];
    }
    acc[endDate].push(currentItem);
    return acc;
  }, {});

  const sortedCompletedAndApproved = completedAndApprovedWorks?.sort((a, b) => moment(b?.startDate).unix() - moment(a?.startDate).unix());

  const groupedByDateCompletedAndApproved = sortedCompletedAndApproved?.reduce((acc, currentItem) => {
    const endDate = moments(currentItem?.startDate).locale("tr").format("LL");
    if (!acc[endDate]) {
      acc[endDate] = [];
    }
    acc[endDate].push(currentItem);
    return acc;
  }, {});

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };

  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const [columns, setColumns] = useState({
    active: activeJunctions,
    waiting: sortedCompletedWorks,
    finished: sortedCompletedAndApproved,
  });

  const handleCardMove = (sourceColumn, destinationColumn, taskId) => {
    if (sourceColumn === "active" && destinationColumn === "waiting") {
      setJunctionType(operation?.operation?.type);
      setFinishJunctionId(taskId);
      setIsOpenJunctionPiece(true);
    } else if (sourceColumn === "waiting" && destinationColumn === "finished") {
      setJunctionId(taskId);
      setIsOpenForm(true);
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId) {
      Toast("error", t("product:sameColumnWarning"));
      return;
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    const validMoves = [
      { from: "active", to: "waiting" },
      { from: "waiting", to: "finished" },
    ];

    const isValidMove = validMoves.some((move) => move.from === source.droppableId && move.to === destination.droppableId);

    if (!isValidMove) {
      Toast("error", t("product:columnDragDropWarning"));
      return;
    }

    const startTaskIds = Array.from(start);
    startTaskIds.splice(source.index, 1);
    const newStart = startTaskIds;

    const finishTaskIds = Array.from(finish);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = finishTaskIds;

    setColumns({
      ...columns,
      [source.droppableId]: newStart,
      [destination.droppableId]: newFinish,
    });

    handleCardMove(source.droppableId, destination.droppableId, draggableId);
  };

  return (
    <>
      <div onScrollCapture={onScrolling} className={`w-full flex flex-col h-full mt-2`}>
        {pathname?.includes("move-junction") ? null : (
          <div className="flex flex-col gap-y-7 w-full pb-4">
            <div className="flex justify-between  w-full items-center">
              <div className="flex">
                <Button
                  colorType={"secondary-gray"}
                  iconLeft={<ArrowLeftIcon />}
                  label={t("buttons:previous")}
                  size={"md"}
                  onClick={() => {
                    setSelectedOperation("all");
                    navigate(pathname?.split("+")[0] + "+" + (Number(orderIds) - 1));
                  }}
                  disabled={!operation?.previousOrderOperation}
                />
              </div>
              <div className="flex gap-x-2">
                <span className="text-[#475467] text-lg font-normal">
                  {operation?.operationStep}.{t("product:operations")}
                </span>
                <span className="text-[#101828] text-lg font-semibold">
                  {operation?.operationName ||
                    operation?.operation?.externalOperation?.process?.name ||
                    operation?.operations?.map((i) => i?.operationName).join(", ")}
                </span>
              </div>
              <div className="flex">
                <Button
                  colorType={"secondary-gray"}
                  iconLeft={<ArrowRightIcon />}
                  label={t("buttons:next")}
                  size={"md"}
                  onClick={() => {
                    setSelectedOperation("all");
                    navigate(pathname?.split("+")[0] + "+" + (Number(orderIds) + 1));
                  }}
                  disabled={!operation?.nextOrderOperation}
                />
              </div>
            </div>
          </div>
        )}

        <div style={{ minHeight: height - 120, height: height - 120, maxHeight: height - 120 }} className="w-full h-full rounded-xl flex flex-col relative">
          <div
            className={`w-11 h-full hidden sm:flex md:flex lg:flex ${
              isRightArrowHidden ? "hidden duration-1000" : ""
            } z-[120] right-0 backdrop-blur-md cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-[#FFFFFF33]`}
            onClick={() => {
              sideScroll(document.getElementById("content-wrapper"), 25, 100, 20);
            }}
          >
            <ScrollChevronIcon />
          </div>

          <div
            className={`w-11 h-full  hidden sm:flex md:flex lg:flex ${
              isLeftArrowHidden ? "hidden duration-1000" : ""
            } z-50 left-0 absolute cursor-pointer flex bg-[#FFFFFF33] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
            onClick={() => {
              sideScroll(document.getElementById("content-wrapper"), 25, 100, -20);
            }}
          >
            <ScrollChevronIcon />
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <div id="content-wrapper" className="w-full pb-1 flex h-full max-h-[95vh]">
              <QualityTab
                type={"active"}
                length={activeJunctions?.filter((i) => i?.station)?.length || activeJunctions?.filter((i) => i?.externalOperation)?.length}
                children={
                  <Droppable droppableId="active">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef} className="w-full overflow-y-auto flex flex-col scrollbar-hide h-full">
                        {activeJunctions
                          ?.sort((b, a) => moment(a?.startDate ? a.startDate : 0).unix() - moment(b?.startDate ? b?.startDate : 0).unix())

                          ?.map((item, index) => {
                            const startDate = moment(item?.startDate);
                            const endDate = new Date();

                            if (item?.status === "active") {
                              if (item?.supplier) {
                                return (
                                  <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`${snapshot.isDragging ? "max-w-[500px]" : ""} w-full mb-3`}
                                        key={index}
                                      >
                                        <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                          <p className="text-sm font-medium text-secondary-700">{moments(new Date()).locale("tr").format("LL")}</p>
                                        </div>
                                        <div className="mt-2">
                                          <SelectBoxComp item={item} junctionIds={junctionIds} toggleSelection={toggleSelection} />
                                          <SupplyActiveCard
                                            operation={operation}
                                            data={item}
                                            refetch={refetch}
                                            setFinishJunctionId={setFinishJunctionId}
                                            setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                                            setJunctionType={setJunctionType}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              } else {
                                return (
                                  <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`${snapshot.isDragging ? "max-w-[500px]" : ""} w-full mb-3`}
                                        key={index}
                                      >
                                        <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                          <p className="text-sm font-medium text-secondary-700">{moments(new Date()).locale("tr").format("LL")}</p>
                                        </div>
                                        <div className="mt-3">
                                          <SelectBoxComp item={item} junctionIds={junctionIds} toggleSelection={toggleSelection} />
                                          <ActiveCard
                                            data={item}
                                            operation={operation}
                                            refetch={refetch}
                                            setProductType={setProductType}
                                            setJunctionType={setJunctionType}
                                            setFinishJunctionId={setFinishJunctionId}
                                            setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              }
                            } else {
                              if (item?.station) {
                                return (
                                  <div className="w-full mb-3 relative group" key={index}>
                                    <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                      <p className="text-sm font-medium text-secondary-700">{moments(new Date()).locale("tr").format("LL")}</p>
                                    </div>
                                    <div className="mt-2">
                                      <PlannedCard
                                        data={item}
                                        operation={operation}
                                        refetch={refetch}
                                        setIsSelectUserModal={setIsSelectUserModal}
                                        setSelectedJunction={setSelectedJunction}
                                      />
                                    </div>
                                  </div>
                                );
                              } else {
                                if (item?.externalOperation) {
                                  if (item?.supplier) {
                                    return (
                                      <div className="w-full mb-3" key={index}>
                                        <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                          <p className="text-sm font-medium text-secondary-700">{moments(new Date()).locale("tr").format("LL")}</p>
                                        </div>
                                        <div className="mt-2">
                                          <SupplyPlannedCard
                                            data={item}
                                            operation={operation}
                                            refetch={refetch}
                                            setIsSelectUserModal={setIsSelectUserModal}
                                            setSelectedJunction={setSelectedJunction}
                                          />
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="w-full mb-3  " key={index}>
                                        <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                          <p className="text-sm font-medium text-secondary-700">{moments(new Date()).locale("tr").format("LL")}</p>
                                        </div>
                                        <div className="mt-2">
                                          <ExternalActiveAndPlannedCard
                                            refetch={refetch}
                                            rightButton={() => {
                                              setIsSelectSupplierModal(true);
                                              setSelectedJunction(item);
                                            }}
                                            image={item?.job?.product?.image}
                                            id={item?.id}
                                            name={item?.job?.product?.name}
                                            // type={item?.type}
                                            piece={item?.job?.piece}
                                            estimatedTime={formatSeconds(item?.header?.totalEstimatedTime)}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                } else {
                                  return <></>;
                                }
                              }
                            }
                          })}
                      </div>
                    )}
                  </Droppable>
                }
              />

              <QualityTab
                type={"waiting"}
                length={completedWorks?.length}
                children={
                  <Droppable droppableId="waiting">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="w-full flex-1 overflow-y-auto flex flex-col scrollbar-hide gap-y-2 h-full"
                      >
                        {Object.keys(groupedByDateCompleted).map((date, index) => {
                          return (
                            <div key={index}>
                              <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                <p className="text-sm font-medium text-secondary-700">{date}</p>
                              </div>
                              {groupedByDateCompleted[date].map((item, i) => (
                                <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`${snapshot.isDragging ? "max-w-[500px]" : ""} w-full mb-3 mt-3`}
                                      key={i}
                                    >
                                      <SelectBoxComp item={item} junctionIds={junctionIds} toggleSelection={toggleSelection} />
                                      {item?.supplier ? (
                                        <SupplyQualityWaitingCard
                                          data={item}
                                          refetch={refetch}
                                          operation={operation}
                                          setJunctionId={setJunctionId}
                                          setIsOpenForm={setIsOpenForm}
                                        />
                                      ) : (
                                        <div className="flex flex-col">
                                          <DoneAndQualityWaitingCard
                                            data={item}
                                            operation={operation}
                                            setJunctionId={setJunctionId}
                                            setIsOpenForm={setIsOpenForm}
                                            setProductType={setProductType}
                                            refetch={refetch}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Droppable>
                }
              />
              <QualityTab
                type={"finished"}
                length={completedAndApprovedWorks?.length}
                children={
                  <Droppable droppableId="finished">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="w-full flex-1 overflow-y-auto flex flex-col scrollbar-hide gap-y-2 h-full"
                      >
                        {Object.keys(groupedByDateCompletedAndApproved).map((date, index) => {
                          return (
                            <div key={index}>
                              <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                <p className="text-sm font-medium text-secondary-700">{date}</p>
                              </div>

                              {groupedByDateCompletedAndApproved[date].map((item, i) => (
                                <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`${snapshot.isDragging ? "max-w-[500px]" : ""} w-full mt-3`}
                                      key={i}
                                    >
                                      <SelectBoxComp item={item} junctionIds={junctionIds} toggleSelection={toggleSelection} />
                                      {item?.externalOperation ? (
                                        <SupplyQualityDoneCard data={item} operation={operation} refetch={refetch} />
                                      ) : (
                                        <QualityDoneCard data={item} operation={operation} refetch={refetch} />
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Droppable>
                }
              />

              {isFetching && (
                <div className="w-full absolute bottom-0 p-2 px-3 flex items-center bg-white border-b border-secondary-200 flex-1 justify-center">
                  <Loading size={20} primary={"#B54708"} secondary={"#fff"} />
                </div>
              )}
            </div>
          </DragDropContext>
        </div>
      </div>
      {/* // Kontrol edilmis //  */}
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            productType={productType}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
      <CustomModal
        isOpen={isOpenForm}
        setIsOpen={setIsOpenForm}
        modalTitle={t("product:qualityControlForm")}
        onClose={() => {
          setIsOpenForm(false);
        }}
        children={
          <QualityControlForm
            onClose={() => {
              setIsOpenForm(false);
            }}
            productType={productType}
            junctionId={junctionId}
            refetch={refetch}
          />
        }
      />
      {/* // Kontrol edilmis //  */}
      <CustomModal
        isOpen={junctionDetailOpen}
        setIsOpen={setJunctionDetailOpen}
        onClose={() => {
          setJunctionDetailOpen(false);
        }}
        modalTitle={t("product:transactionDetail")}
        width="fit-content"
        children={<TransactionDetailModal t={t} setIsOpen={setJunctionDetailOpen} isOpen={junctionDetailOpen} id={finishJunctionId} />}
      />
      <CustomModal
        isOpen={isSelectSupplierModal}
        setIsOpen={setIsSelectSupplierModal}
        onClose={() => {
          setIsSelectSupplierModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectSupplierModal
            t={t}
            setIsOpen={setIsSelectSupplierModal}
            isOpen={isSelectSupplierModal}
            selectedJunction={selectedJunction}
            // currentOperation={currentOperation}
            handleData={refetch}
            processId={operation?.operation?.externalOperation?.process?.id}
            // processId={
            //   currentOperation?.internalOperation
            //     ? currentOperation?.internalOperation?.process?.id
            //     : currentOperation?.externalOperation?.process?.id
            // }
          />
        }
      />
      <CustomModal
        modalTitle={t("product:createNewWork")}
        setIsOpen={setNewPlannedJunctionExternalModal}
        isOpen={newPlannedJunctionExternalModal}
        children={
          <NewPlannedJunctionExternalModal
            // operationData={currentOperation}
            getJunctions={refetch}
            closeModal={() => setNewPlannedJunctionExternalModal(false)}
            // processId={currentOperation?.externalOperation?.process?.id}
          />
        }
      />
      <CustomModal
        isOpen={isSelectUserModal}
        setIsOpen={setIsSelectUserModal}
        onClose={() => {
          setIsSelectUserModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectUserModal
            t={t}
            setIsOpen={setIsSelectUserModal}
            isOpen={isSelectUserModal}
            selectedJunction={selectedJunction}
            // currentOperation={currentOperation}
            handleData={refetch}
            externalPlanned={operation?.operation?.externalOperation ? true : false}
          />
        }
      />
      <CustomModal
        isOpen={isSelectNodeModal}
        setIsOpen={setIsSelectNodeModal}
        onClose={() => {
          setIsSelectNodeModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectEquModal
            t={t}
            setIsOpen={setIsSelectNodeModal}
            isOpen={isSelectNodeModal}
            selectedJunction={selectedJunction}
            currentOperation={operation?.operationName}
            handleData={refetch}
            processId={operation?.processId}
          />
        }
      />
    </>
  );
};

export default FlexOperationJunctions;

const SelectBoxComp = ({ item, junctionIds, toggleSelection }) => {
  return (
    <>
      {window.location.href.includes("move-junction") ? (
        <div onClick={() => toggleSelection(item.id)} className="flex w-full mb-1 gap-x-2 items-center cursor-pointer">
          <div
            className={`flex min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] p-[2px] rounded  items-center justify-center ${
              junctionIds.includes(item.id) ? "bg-[#DC6803]" : "border border-secondary-300"
            }`}
          >
            <CheckIcon />
          </div>
          <span className={` font-medium text-sm ${junctionIds.includes(item.id) ? "text-[#DC6803]" : "text-secondary-500"}`}>
            {junctionIds.includes(item.id) ? "Selected" : "Not Selected"}
          </span>
        </div>
      ) : null}
    </>
  );
};
