import React, { useState, useEffect, useCallback } from "react";
import { Button, CustomModal, Loading } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { junctionService } from "../../services/junction.service";
import ExternalPlanView from "./components/ExternalPlanView";
import SliderComponent from "../machine/Slider/SliderComponent";
import diacritics from "diacritics";
import { ScrollChevronIcon } from "../../assets/icons/productIcons";
import Badgets from "../../components/buttons/Badgets";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ScrollSyncPane, ScrollSync } from "react-scroll-sync";
import useWindowDimensions from "../../hooks/useWindowDimension";

import SearchInput from "../../components/inputs/SearchInput";

import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import NewServiceStart from "./modals/NewServiceStart";
import SelectUserModal from "../../components/modal/SelectUserModal";
import { Toggle } from "../machine/Twin/components";
import ServiceSupplyHeaderCard from "./cards/service-supply/ServiceSupplyHeaderCard";
import NewServiceSupplyPlanViewPlannedCard from "./cards/service-supply/NewServiceSupplyPlanViewPlannedCard";
import CustomImage from "../../components/CustomImage";
import ServiceSupplyPlanModal from "./modals/ServiceSupplyPlanModal";
import ServiceSupplyPlanViewEventCard from "../../components/new-cards/service-supply/components/ServiceSupplyPlanViewEventCard";
import { serviceSupplyService } from "../../services/service-supply.service";
import { purchaseService } from "../../services/purchase.service";
const ServiceSupplyPlanView = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [tableHeadItemsLength, setTableHeadItemsLength] = useState(0);
  const [drappedSupplier, setDrappedSupplier] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isOpenSelectUserModal, setIsOpenSelectUserModal] = useState(false);
  const [isOpenFinishJunction, setIsOpenFinishJunction] = useState(false);
  const [rowLoading, setRowLoading] = useState(null);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [draggedProduct, setDraggedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [arr, setArr] = useState([]);
  const [showFlexProducts, setShowFlexProducts] = useState(true);
  const planView = useQuery({
    queryKey: ["service-supply-detail-plan-view"],
    enabled: false,
    queryFn: async () => await serviceSupplyService.getServiceSupplyList(id, "planner"),
    retry: 0,
  });
  const headData = useQuery({
    queryKey: ["service-supply-list-page-junction"],
    enabled: false,
    retry: 0,
    queryFn: async () => await serviceSupplyService.getServiceSupplyHeaderList(),
  });
  const refetch = () => {
    planView.refetch();
    headData.refetch();
  };
  useEffect(() => {
    headData.refetch();
    if (id != ":id" && id != "undefined") {
      planView.refetch();
      setSelectedGroup(headData?.data?.find((item) => item?.id == id));
    }
  }, [id]);
  useEffect(() => {
    if (headData?.data?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mrp/supply/service/${headData?.data[0]?.id}/plan-view`);
    }
  }, [headData?.data]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "10826fc4-bf01-4164-acf0-72bb213e88d3") {
      } else if (auth?.user?.company?.id == "b0d47c1a-e5dd-4ec1-a655-424af970bbfe") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);
  const tabOptions = [
    { value: "list", label: t("product:list") },
    { value: "junction", label: t("product:track") },
    { value: "plan", label: t("product:planner") },
  ];
  const handleHeaderLength = useCallback(() => {
    if (!planView?.data?.suppliers) return;
    const headLengths = planView?.data?.suppliers.map((d) => (d?.junctions?.length || 0) + (d?.junctions?.length || 0));
    setTableHeadItemsLength(Math.max(...headLengths, 2));
  }, [planView?.data]);

  const handleDrop = (node) => {
    setDrappedSupplier(node);
    setIsOpenPlanningModal(true);
  };
  // const onDragEnd = async (result) => {
  //   if (!result.destination) return;
  //   const newItems = Array.from(arr);
  //   const findChangedRowData = newItems[Number(result.source.droppableId)];
  //   const destIndex = findChangedRowData?.activeJunction?.length > 0 ? result.destination.index - 1 : result.destination.index;
  //   const findByIndex = findChangedRowData.plannedJunction.findIndex((element) => element.id === result.draggableId);
  //   const movedData = newItems[Number(result.source.droppableId)].plannedJunction.splice(findByIndex, 1)[0];
  //   newItems[Number(result.source.droppableId)].plannedJunction.splice(destIndex, 0, movedData);
  //   const newArr = newItems[Number(result.source.droppableId)].plannedJunction.filter(Boolean).map((obj, ind) => ({ ...obj, planningOrder: ind }));
  //   setRowLoading(Number(result.source.droppableId));
  //   setArr(newItems.map((obj) => (obj.id === newItems[Number(result.source.droppableId)].id ? newItems[Number(result.source.droppableId)] : obj)));
  //   await junctionService.updatePlanningJunctionList(newArr);
  //   refetch();
  // };

  const onDragEnd = async (result) => {
    if (!result.destination) return; // Eğer geçersiz bir hedef varsa çıkış yap

    const sourceIndex = Number(result.source.droppableId);
    const destinationIndex = result.destination.index;

    // Güncel state'in kopyasını aldik (immütability için)
    const newItems = [...arr];
    const findChangedRowData = newItems?.[sourceIndex];

    if (!findChangedRowData?.junctions) return;

    // Sürüklenen elemanın `junctions` arrayindeki indexi bulundu
    const draggedItemIndex = findChangedRowData.junctions.findIndex((element) => element?.id === result.draggableId);

    if (draggedItemIndex === -1) return;

    // Sürüklenen elemanı çıkarildi ve eklenerek güncellendi
    const updatedJunctions = [...findChangedRowData.junctions];
    const [movedData] = updatedJunctions.splice(draggedItemIndex, 1);

    // Hedef index'e göre eleman yerleştirildi
    updatedJunctions.splice(destinationIndex, 0, movedData);

    // Sıralama güncellendi
    const newArr = updatedJunctions.map((obj, ind) => ({
      ...obj,
      planningOrder: ind,
    }));

    // Güncellenmiş `junctions` array'i state'e set edildi
    const updatedItems = newItems.map((obj) => (obj.id === findChangedRowData.id ? { ...findChangedRowData, junctions: newArr } : obj));

    // State guncellendi
    setArr(updatedItems);

    // API'ye güncellenmiş veri gonderildi
    setRowLoading(sourceIndex);
    await purchaseService.updatePlanningJunctionList(newArr);
    refetch();
  };
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };
  useEffect(() => {
    if (id) {
      setArr(planView?.data?.suppliers);
      handleHeaderLength();
    }
  }, [planView?.data, handleHeaderLength, id]);
  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const filter = planView?.data?.orderList?.purchaseList
    ?.filter((item) => (!(item?.flexProduct || item?.flexRawMaterial || item?.name) ? item : showFlexProducts ? item : null))
    ?.filter((a) => a != null);
  const filteredData = filter?.filter(
    (item) =>
      diacritics.remove(`${item?.externalOperation?.product?.name}`).toLowerCase().includes(diacritics.remove(searchQuery.toLowerCase())) ||
      diacritics.remove(`${item?.orderData?.orderNo}`).toLowerCase().includes(diacritics.remove(searchQuery.toLowerCase())) ||
      diacritics.remove(`${item?.externalOperation?.product?.stockCode}`).toLowerCase().includes(diacritics.remove(searchQuery.toLowerCase()))
  );
  const tableHeadItems = [];
  for (let i = 0; i < Math.max(1, tableHeadItemsLength); i++) {
    tableHeadItems.push({ title: `${i + 1}`, id: i });
  }
  return (
    <div className="w-full h-full flex flex-col overflow-hidden scrollbar-hide overflow-y-auto">
      <>
        <ScrollSync>
          <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative step-1 scrollbar-hide">
            <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
              <span className="mr-3 md:flex sm:flex xs:flex hidden">
                <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
              </span>
              <div className="flex items-center">
                <span className="font-codecMedium text-2xl text-black">upu.</span>
                <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
                {!(headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading) && (
                  <p className="text-xl font-normal ml-2 text-secondary-500">
                    {selectedGroup && selectedGroup?.name ? `${t("product:serviceSupply")} / ${selectedGroup?.name}` : t("product:serviceSupply")}
                  </p>
                )}
              </div>
              <span className="ml-auto">
                <Button
                  colorType={"primary-product"}
                  size={"md"}
                  label={t("product:supplierList")}
                  onClick={() => navigate("/app/product/mrp/supply/service/list")}
                />
              </span>
            </div>
            {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
              <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
            ) : (
              <div className="h-[56px] min-h-[56px] w-full overflow-x-auto flex">
                {headData?.data
                  ?.sort((a, b) => b?.requestCount - a?.requestCount)
                  ?.map((item, index) => {
                    return (
                      <div className={`min-w-[192px] ${index !== headData?.data?.length - 1 ? "border-b" : "border-b-0"} max-h-[40px] pr-5`}>
                        <ServiceSupplyHeaderCard
                          onClick={async () => {
                            navigate(`/app/product/mrp/supply/service/${item?.id}/plan-view`);
                          }}
                          name={item?.name}
                          selectedOperation={{
                            id: id,
                            name: item?.name,
                          }}
                          id={item?.id}
                          length={item?.requestCount}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
            {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
              <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
            ) : (
              <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-6 mb-4">
                {tabOptions?.map((option, index) => (
                  <div
                    className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                      "plan" == option?.value ? "bg-white shadow-sm" : ""
                    }`}
                    onClick={() => {
                      if (option?.value === "junction") navigate(`/app/product/mrp/supply/service/${id}/junction`);
                      if (option?.value === "list") navigate(`/app/product/mrp/supply/service/${id}/list`);
                    }}
                  >
                    <p className={`text-md font-semibold ${"plan" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
                  </div>
                ))}
              </div>
            )}
            {planView?.isLoading || planView?.isFetching ? (
              <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
            ) : (
              <>
                <div className="flex border flex-col rounded-xl">
                  <div className="flex w-full px-6 py-3 items-center border-b mb-2">
                    <div className="flex w-full min-h-[28px] max-h-[28px] gap-x-2 items-center">
                      <p className="flex text-[#101828] font-semibold text-lg">
                        {/* {planView?.data?.name} {t("product:products")} */}
                        {t("product:plannableProduct")}
                      </p>
                      <Badgets colorType={"outline-warning"} label={planView?.data?.orderList?.purchaseList?.length} />
                    </div>
                    <div className="flex flex-row items-center gap-x-3 justify-end w-full">
                      <div className="flex max-w-[320px] w-full justify-end">
                        <SearchInput theme={"product"} setSearchVal={setSearchQuery} />
                      </div>
                      <div className="flex gap-x-4 items-center justify-end">
                        <p className="text-[#6941C6] font-semibold text-sm">{t("product:showFlex")}</p>
                        <Toggle enabled={showFlexProducts} setEnabled={(enabled) => setShowFlexProducts(enabled)} size={"sm"} />
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full px-4 pb-3">
                    {filteredData?.length > 0 && (
                      <div className="w-full pb-4 pt-2 flex overflow-x-auto overflow-y-hidden relative">
                        {filteredData?.map((item, index) => {
                          const statusColor = () => {
                            if (item?.flexProduct || item?.flexRawMaterial || item?.name) {
                              return "#E9D7FE";
                            } else {
                              switch (item?.status) {
                                case "finished":
                                  return "#12B76A";
                                case "started":
                                  return "#FDE172";
                                case "rejected":
                                  return "#D92D20";
                                case "flexProduct":
                                  return "#E9D7FE";
                                default:
                                  return "#F2F4F7";
                              }
                            }
                          };
                          return (
                            // <ServiceSupplyPlanViewPlannedCard item={item}/>
                            <NewServiceSupplyPlanViewPlannedCard item={item} statusColor={statusColor} setDraggedProduct={setDraggedProduct} />
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ minHeight: height - 285 }} className=" w-full mt-4 border rounded-xl border-gray-300  flex flex-col overflow-hidden relative">
                  <div
                    className={`w-11 h-full ${
                      isRightArrowHidden ? "hidden duration-1000" : ""
                    } z-[120] right-0 backdrop-blur-md cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-[#FFFFFF33]`}
                    onClick={() => {
                      sideScroll(document.getElementById("content-wrapper"), 55, 300, 50);
                    }}
                  >
                    <ScrollChevronIcon />
                  </div>

                  <div
                    className={`w-11 h-full ${
                      isLeftArrowHidden ? "hidden duration-1000" : ""
                    } z-[120] left-0 absolute cursor-pointer flex bg-[#FFFFFF33] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
                    onClick={() => {
                      sideScroll(document.getElementById("content-wrapper"), 55, 300, -20);
                    }}
                  >
                    <ScrollChevronIcon />
                  </div>
                  <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
                    <ScrollSyncPane>
                      <div id="content-wrapper" className="flex items-center w-full h-full overflow-x-auto scrollbar-hide">
                        {tableHeadItems?.map((item, index) => (
                          <div
                            key={index}
                            className={` min-h-[44px] ${!isLeftArrowHidden && index === 0 ? "sticky left-11 z-50" : ""} flex items-center ${
                              !(index === 0 || index === 1) ? "justify-between" : ""
                            } ${
                              index === 0 ? "w-[250px] max-w-[250px] min-w-[250px]" : "w-[400px] max-w-[400px] min-w-[400px]"
                            } px-6 py-3 text-sm bg-[#F9FAFB] font-semibold text-[#344054] border-r border-secondary-200`}
                          >
                            {index === 0 ? (
                              <>
                                <p className="text-sm text-[#344054] font-semibold">{t("product:suppliers")}</p>
                              </>
                            ) : index === 1 ? (
                              <>
                                <p className="text-sm text-[#344054] font-semibold">{t("product:ssPlanned")}</p>
                              </>
                            ) : index === 2 ? (
                              <div className="ml-auto">
                                {/* <p className="text-sm text-[#344054] font-semibold">{t("product:plannedWorks")}</p> */}
                                {item?.title}
                              </div>
                            ) : (
                              <>
                                <p></p>
                                {item?.title}
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </ScrollSyncPane>
                  </div>
                  <div className="flex-1 w-full flex flex-col overflow-y-auto scrollbar-hide bg-white">
                    {arr?.map((a, b) => {
                      return (
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId={`${b}`} direction="horizontal">
                            {(provided, snapshot) => (
                              <div
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={() => handleDrop(a)}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="flex w-full min-h-[128px]"
                              >
                                <div
                                  className={`w-[250px] max-w-[250px] min-w-[250px] ${
                                    isLeftArrowHidden ? "" : "sticky left-11 z-[100]"
                                  } h-full px-6 py-4 border-r bg-white border-b gap-y-3 flex flex-col items-start`}
                                >
                                  <p className="text-md  w-[210px] truncate font-semibold text-secondary-700">{a?.name || "--"}</p>
                                  <div className="flex flex-row items-center gap-x-3">
                                    {a?.image ? (
                                      <div>
                                        <CustomImage
                                          borderRadius={2}
                                          src={a?.image}
                                          style={{
                                            width: 72,
                                            height: 54,
                                            minWidth: 72,
                                            minHeight: 54,
                                            borderRadius: 4,
                                            borderWidth: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            display: "flex",
                                            borderColor: "#0000001A",
                                            backgroundColor: "#fff",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="w-[72px] min-w-[72px] h-[54px] min-h-[54px] rounded flex border items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                                        {a?.name?.slice(0, 2)?.toUpperCase()}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <ScrollSyncPane>
                                  <div
                                    id="content-wrapper"
                                    className="w-full overflow-x-auto scrollbar-hide flex border-b border-gray-200 max-h-[128px] m-0 p-0"
                                  >
                                    {a?.junctions?.map((item, index) => {
                                      return (
                                        <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                          {(provided, snapshot) => (
                                            <div className="" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                              <div className="w-[400px] min-w-[400px] max-w-[400px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white h-full">
                                                <ServiceSupplyPlanViewEventCard
                                                  data={item}
                                                  setDraggedProduct={setDraggedProduct}
                                                  setIsOpenPlanningModal={setIsOpenPlanningModal}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                  </div>
                                </ScrollSyncPane>
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </ScrollSync>
        {/* <CustomModal
          isOpen={isOpenPlanningModal}
          setIsOpen={setIsOpenPlanningModal}
          onClose={() => {
            setIsOpenPlanningModal(false);
          }}
          modalTitle={t("chat:startJob")}
          width="fit-content"
          children={
            <NewServiceStart
              setIsOpen={setIsOpenPlanningModal}
              processId={planView?.data?.id}
              refetch={refetch}
              selectedJunction={draggedProduct}
              supplierId={drappedSupplier?.id}
              companyId={auth.user.company.id}
            />
          }
        /> */}
        <CustomModal
          isOpen={isOpenPlanningModal}
          setIsOpen={setIsOpenPlanningModal}
          onClose={() => {
            setIsOpenPlanningModal(false);
          }}
          modalTitle={t("product:serviceSupplyPlanned")}
          // subTitle={"16.Op-ISL (Islah) için planlama detaylarını belirtin"}
          // subTitle={`${draggedProduct?.flexProduct ? draggedProduct?.step + ".Flex" : draggedProduct?.flexRawMaterial ? "Flex" : draggedProduct?.step + ".Op"} (${selectedGroup?.name}) için planlama detaylarını belirtin.`}

          subTitle={t("product:specifyPlannings")}
          width="fit-content"
          children={<ServiceSupplyPlanModal setIsOpen={setIsOpenPlanningModal} refetch={refetch} selectedJunction={draggedProduct} groupId={id} />}
        />
        <CustomModal
          isOpen={isOpenSelectUserModal}
          setIsOpen={setIsOpenSelectUserModal}
          onClose={() => {
            setIsOpenSelectUserModal(false);
          }}
          modalTitle={t("chat:startJob")}
          width="fit-content"
          children={
            <SelectUserModal
              t={t}
              setIsOpen={setIsOpenSelectUserModal}
              isOpen={isOpenSelectUserModal}
              selectedJunction={selectedJunction}
              handleData={refetch}
              externalPlanned={true}
            />
          }
        />
        <CustomModal
          isOpen={isOpenFinishJunction}
          setIsOpen={setIsOpenFinishJunction}
          modalTitle={t("product:finishJuction")}
          children={
            <FinishJunctionModal
              handleData={refetch}
              modalTheme={"machine"}
              junctionId={selectedJunction?.id}
              closeModal={() => setIsOpenFinishJunction(false)}
              type={selectedJunction?.internalOperation ? "internal" : "external"}
            />
          }
        />
      </>
    </div>
  );
};

export default ServiceSupplyPlanView;

const QtyLight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2.3335H3.71429C2.76751 2.3335 2 3.10101 2 4.04778V11.8692C2 12.816 2.76751 13.5835 3.71429 13.5835H12.2857C13.2325 13.5835 14 12.816 14 11.8692V4.04778C14 3.10101 13.2325 2.3335 12.2857 2.3335H10.25M5.75 2.3335V6.69481C5.75 6.75853 5.81706 6.79997 5.87405 6.77147L8 5.7085L10.126 6.77147C10.1829 6.79997 10.25 6.75853 10.25 6.69481V2.3335M5.75 2.3335H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14 6.66683H2M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M5.2 14.6668H10.8C11.9201 14.6668 12.4802 14.6668 12.908 14.4488C13.2843 14.2571 13.5903 13.9511 13.782 13.5748C14 13.147 14 12.5869 14 11.4668V5.86683C14 4.74672 14 4.18667 13.782 3.75885C13.5903 3.38252 13.2843 3.07656 12.908 2.88482C12.4802 2.66683 11.9201 2.66683 10.8 2.66683H5.2C4.0799 2.66683 3.51984 2.66683 3.09202 2.88482C2.71569 3.07656 2.40973 3.38252 2.21799 3.75885C2 4.18667 2 4.74672 2 5.86683V11.4668C2 12.5869 2 13.147 2.21799 13.5748C2.40973 13.9511 2.71569 14.2571 3.09202 14.4488C3.51984 14.6668 4.0799 14.6668 5.2 14.6668Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
