import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../components";
import TextArea from "../../../components/inputs/TextArea";
import { useTranslation } from "react-i18next";
import { customerService } from "../../../services/customer.service";
import { Toast } from "../../../utils/toastify/toast";

const AddCustomerGroupModal = ({ closeModal, refetch, selectedSupGroup }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleSave = async () => {
    const body = { name, description };
    await customerService.addCustomerGroup(body).then((res) => {
      if (res.data.code == 0) {
        Toast("success", res?.data?.message);
        refetch();
        closeModal();
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  const updateSupGroup = async () => {
    const body = { name, description };
    await customerService.updateCustomerGroup(selectedSupGroup?.id, body).then((res) => {
      // res eksik
      if (res.data.code == 0) {
        Toast("success", res?.data?.message);
        refetch();
        closeModal();
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  useEffect(() => {
    if (selectedSupGroup) {
      setName(selectedSupGroup?.name);
      setDescription(selectedSupGroup.description);
    }
  }, []);

  return (
    <div className="w-[352px] flex flex-col gap-4 mt-4">
      <Input theme={"product"} label={t("group:groupName")} value={name} onChange={({ target }) => setName(target?.value)} />
      <TextArea theme={"product"} label={t("product:description")} rows={5} value={description} onChange={({ target }) => setDescription(target?.value)} />
      <div className="w-full h-11 flex gap-3">
        <Button label={t("buttons:cancel")} colorType={"secondary-gray"} size={"sm"} onClick={closeModal} />

        {selectedSupGroup?.id ? (
          <Button label={t("product:editGroup")} colorType={"primary-product"} size={"sm"} onClick={updateSupGroup} />
        ) : (
          <Button label={t("product:saveGroup")} colorType={"primary-product"} size={"sm"} onClick={handleSave} />
        )}
      </div>
    </div>
  );
};

export default AddCustomerGroupModal;
