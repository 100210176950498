import React, { useCallback, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { PointHeaderText } from "../../../assets/icons/PersonIcons";
import Button from "../../../components/buttons/Button";
import { HumburgerIcon } from "../../../assets/icons/leftMenuIcons";
import moment from "moment";
import { Arrow, Calender } from "../../../assets/icons/machineIcons";
import { useStore } from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { NoDataPerson } from "../../../assets/illustrations";
import { personalService } from "../../../services/personal.service";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Tab } from "../../../components";
import FirstPerson from "../components/FirstPerson";
import SecondPerson from "../components/SecondPerson";
import ThirdPerson from "../components/ThirdPerson";
import TablePersonComp from "../components/TablePersonComp";

const UpuPoint = () => {
  const { t } = useTranslation();
  const { settings } = useStore();
  const navigate = useNavigate();
  const { auth } = useStore();
  const companyId = auth?.user?.company?.id;
  const [month, setMonth] = useState(new Date());
  const [monthRes, setMonthRes] = useState(new Date());
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedTab, setSelectedTab] = useState("operator");

  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["leaderboardPoint"],
    queryFn: async () =>
      personalService.leaderboardPoint(selectedTab === "operator" ? "operator" : "operation_responsible", {
        date: selectedTab === "operator" ? moment(month).format("YYYY-MM-01") : moment(monthRes).format("YYYY-MM-01"),
      }),
  });

  const currentMonthFormat = moment().startOf("month");
  const isDisabled = currentMonthFormat.isSame(moment(month).startOf("month"), "month");

  const currentMonthFormatRes = moment().startOf("month");
  const isDisabledRes = currentMonthFormatRes.isSame(moment(monthRes).startOf("month"), "month");

  const handlePreviousMonth = useCallback(() => {
    setMonth(moment(month).subtract(1, "day").startOf("month"));
  }, [month]);

  const handleCalender = () => {
    return moment(month).format("MMMM YYYY") || "DD MM YYYY";
  };

  const handleNextMonth = useCallback(() => {
    setMonth(moment(month).add(1, "month").startOf("month"));
  }, [month]);

  const handlePreviousMonthRes = useCallback(() => {
    setMonthRes(moment(monthRes).subtract(1, "day").startOf("month"));
  }, [monthRes]);

  const handleCalenderRes = () => {
    return moment(monthRes).format("MMMM YYYY") || "DD MM YYYY";
  };

  const handleNextMonthRes = useCallback(() => {
    setMonthRes(moment(monthRes).add(1, "month").startOf("month"));
  }, [monthRes]);

  const sortedData = data?.sort((a, b) => b?.upuPoint - a?.upuPoint);

  const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K";
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num < 999) {
      return num;
    }
  };

  useEffect(() => {
    refetch();
  }, [monthRes, selectedTab, month]);

  return (
    <>
      {(companyId === "e5c06127-3c5d-44b9-8403-74c160fc7f59" || companyId === "065e9b57-9da2-4394-9359-59ba71610dc3") ? (
        <div className="w-full h-full flex-col flex">
          <div className="w-full  min-h-[56px] border-b border-[#EAECF0] h-[56px] flex items-center dark:text-[#FAFAFA]">
            <span className="mr-3 md:flex sm:flex xs:flex hidden">
              <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
            </span>
            {isLoading ? (
              <Skeleton width={133} height={52} borderRadius={8} />
            ) : (
              <span className="flex min-w-[133px] min-h-[28px]">
                <PointHeaderText />
              </span>
            )}
          </div>
          {isLoading ? (
            <Skeleton width={"30%"} height={52} borderRadius={8} />
          ) : (
            <div className="w-[30%]">
              <Tab
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                options={[
                  { value: "operator", label: "Operatörler" },
                  {
                    value: "operationResponsible",
                    label: "Operasyon Sorumluları",
                  },
                ]}
              />
            </div>
          )}

          {selectedTab === "operator" ? (
            <>
              <div className="flex w-full h-full relative">
                <div className="absolute right-0  -top-16">
                  <div className={`w-fit ml-auto h-9 flex items-center gap-3`}>
                    {/*left arrow*/}
                    {isLoading ? (
                      <div className="absolute right-48  top-4">
                        <Skeleton width={36} height={36} borderRadius={8} />
                      </div>
                    ) : (
                      <button
                        role="button"
                        onClick={() => {
                          handlePreviousMonth();
                        }}
                        className={`w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs `}
                      >
                        <Arrow color="#344054" />
                      </button>
                    )}

                    {/*calender arrow*/}
                    {isLoading ? (
                      <div className="absolute right-16  top-4">
                        <Skeleton width={120} height={36} borderRadius={8} />
                      </div>
                    ) : (
                      <div
                        role="button"
                        className={`min-w-[119px] cursor-pointer h-full flex px-2 justify-center items-center gap-1 rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs`}
                      >
                        <Calender color="#344054" />
                        <div className={`w-fit h-5 flex justify-center items-center`}>
                          <span className={`text-[#344054] text-sm font-semibold flex`}>{moment(month).format("MMMM") || "DD MM YYYY"}</span>
                        </div>
                        {openCalendar && (
                          <>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenCalendar(true);
                              }}
                              style={{
                                marginTop: 40,
                              }}
                              className={`absolute top-10 -translate-x-1 bg-white z-[9999999] p-1 border rounded-lg border-gray-300 dark:bg-[#141414] dark:border-[#525252] shadow-lg`}
                            >
                              <Calender
                                value={month}
                                onChange={(e) => {
                                  setMonth(e);
                                  handleCalender();
                                }}
                              />
                            </div>
                            <div
                              className="fixed inset-0 z-[99999]"
                              onClick={(e) => {
                                setOpenCalendar(false);
                              }}
                              style={{ zIndex: 999 }}
                            />
                          </>
                        )}
                      </div>
                    )}

                    {/*right arrow*/}
                    {isLoading ? (
                      <div className="absolute right-4  top-4">
                        <Skeleton width={36} height={36} borderRadius={8} />
                      </div>
                    ) : (
                      <div
                        role="button"
                        onClick={() => {
                          if (!isDisabled) {
                            handleNextMonth();
                          }
                        }}
                        className={` ${
                          isDisabled ? "disabled-button" : ""
                        } disabled disabled-button  w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs rotate-180 `}
                      >
                        <Arrow color="#344054" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="flex px-5 pb-8 flex-col items-center">
                    {isLoading ? (
                      <Skeleton width={720} height={200} borderRadius={8} />
                    ) : (
                      <div className="flex flex-row  items-center gap-x-8 h-[284px] justify-center">
                        <SecondPerson
                          name={sortedData?.[1]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[1]?.avatar}
                          point={sortedData?.[1]?.upuPoint}
                          lastname={sortedData?.[1]?.lastName}
                          department={sortedData?.[1]?.department}
                          id={sortedData?.[1]?.id || sortedData?.[1]?._id}
                          navigate={navigate}
                        />

                        <FirstPerson
                          name={sortedData?.[0]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[0]?.avatar}
                          point={sortedData?.[0]?.upuPoint}
                          lastname={sortedData?.[0]?.lastName}
                          department={sortedData?.[0]?.department}
                          id={sortedData?.[0]?.id || sortedData?.[0]?._id}
                          navigate={navigate}
                        />

                        <ThirdPerson
                          name={sortedData?.[2]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[2]?.avatar}
                          point={sortedData?.[2]?.upuPoint}
                          lastname={sortedData?.[2]?.lastName}
                          department={sortedData?.[2]?.department}
                          id={sortedData?.[2]?.id || sortedData?.[2]?._id}
                          navigate={navigate}
                        />
                      </div>
                    )}
                  </div>
                  {isLoading ? (
                    <Skeleton width={"100%"} height={500} borderRadius={8} />
                  ) : (
                    <div className="flex flex-col h-[45%] bg-white w-full border shadow-sm border-gray-300 rounded-xl overflow-y-auto scrollbar-hide">
                      {sortedData?.map((item, index) => {
                        if (index > 2)
                          return (
                            <TablePersonComp
                              id={item?.id || item?._id}
                              status="up"
                              name={item?.name}
                              lastname={item?.lastName}
                              department={item?.department || "---"}
                              numFormatter={numFormatter}
                              point={item?.upuPoint}
                              avatar={item?.avatar}
                              difNumber={index + 1}
                              navigate={navigate}
                            />
                          );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex w-full h-full relative">
                <div className="absolute right-0  -top-16">
                  <div className={`w-fit ml-auto h-9 flex items-center gap-3`}>
                    {/*left arrow*/}
                    {isLoading ? (
                      <Skeleton width={36} height={36} borderRadius={8} />
                    ) : (
                      <button
                        role="button"
                        onClick={() => {
                          handlePreviousMonthRes();
                        }}
                        className={`w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs `}
                      >
                        <Arrow color="#344054" />
                      </button>
                    )}

                    {/*calender arrow*/}
                    {isLoading ? (
                      <Skeleton width={120} height={36} borderRadius={8} />
                    ) : (
                      <div
                        role="button"
                        className={`min-w-[119px] cursor-pointer h-full flex px-2 justify-center items-center gap-1 rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs`}
                      >
                        <Calender color="#344054" />
                        <div className={`w-fit h-5 flex justify-center items-center`}>
                          <span className={`text-[#344054] text-sm font-semibold flex`}>{moment(monthRes).format("MMMM") || "DD MM YYYY"}</span>
                        </div>
                        {openCalendar && (
                          <>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenCalendar(true);
                              }}
                              style={{
                                marginTop: 40,
                              }}
                              className={`absolute top-10 -translate-x-1 bg-white z-[9999999] p-1 border rounded-lg border-gray-300 dark:bg-[#141414] dark:border-[#525252] shadow-lg`}
                            >
                              <Calender
                                value={monthRes}
                                onChange={(e) => {
                                  setMonthRes(e);
                                  handleCalenderRes();
                                }}
                              />
                            </div>
                            <div
                              className="fixed inset-0 z-[99999]"
                              onClick={(e) => {
                                setOpenCalendar(false);
                              }}
                              style={{ zIndex: 999 }}
                            />
                          </>
                        )}
                      </div>
                    )}

                    {/*right arrow*/}
                    {isLoading ? (
                      <Skeleton width={36} height={36} borderRadius={8} />
                    ) : (
                      <div
                        role="button"
                        onClick={() => {
                          if (!isDisabledRes) {
                            handleNextMonthRes();
                          }
                        }}
                        className={` ${
                          isDisabledRes ? "disabled-button" : ""
                        } disabled disabled-button  w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs rotate-180 `}
                      >
                        <Arrow color="#344054" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="flex px-5 pb-8 flex-col items-center">
                    {isLoading ? (
                      <Skeleton width={720} height={200} borderRadius={8} />
                    ) : (
                      <div className="flex flex-row  items-center gap-x-8 h-[284px] justify-center">
                        <SecondPerson
                          name={sortedData?.[1]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[1]?.avatar}
                          point={sortedData?.[1]?.upuPoint}
                          lastname={sortedData?.[1]?.lastName}
                          department={sortedData?.[1]?.processName.join(", ")}
                          id={sortedData?.[1]?.id || sortedData?.[1]?._id}
                          navigate={navigate}
                        />

                        <FirstPerson
                          name={sortedData?.[0]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[0]?.avatar}
                          point={sortedData?.[0]?.upuPoint}
                          lastname={sortedData?.[0]?.lastName}
                          department={sortedData?.[0]?.processName.join(", ")}
                          id={sortedData?.[0]?.id || sortedData?.[0]?._id}
                          navigate={navigate}
                        />

                        <ThirdPerson
                          name={sortedData?.[2]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[2]?.avatar}
                          point={sortedData?.[2]?.upuPoint}
                          lastname={sortedData?.[2]?.lastName}
                          department={sortedData?.[2]?.processName.join(", ")}
                          id={sortedData?.[2]?.id || sortedData?.[2]?._id}
                          navigate={navigate}
                        />
                      </div>
                    )}
                  </div>
                  {isLoading ? (
                    <Skeleton width={"100%"} height={500} borderRadius={8} />
                  ) : (
                    <div className="flex flex-col h-[45%]  bg-white w-full border shadow-sm border-gray-300 rounded-xl overflow-y-auto scrollbar-hide">
                      {sortedData?.map((item, index) => {
                        if (index > 2)
                          return (
                            <TablePersonComp
                              id={item?.id || item?._id}
                              status="up"
                              name={item?.name}
                              lastname={item?.lastName}
                              department={item?.processName.join(", ") || "---"}
                              numFormatter={numFormatter}
                              point={item?.upuPoint}
                              avatar={item?.avatar}
                              difNumber={index + 1}
                              navigate={navigate}
                            />
                          );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center flex-col">
          <NoDataPerson />
          <p className="text-gray-700 font-medium text-xl mt-5">{t("chat:comingSoon")}</p>
        </div>
      )}
    </>
  );
};

export default UpuPoint;
