import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Button, Input, Loading } from "../../../components";
import Selector from "../../../components/inputs/Selector";
import { processService } from "../../../services/process.service";
import { productService } from "../../../services/product.service";
import { Toast } from "../../../utils/toastify/toast";
import { Loader } from "../../../assets/icons/stepsIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { CheckIcon } from "../../../assets/icons/PersonIcons";
import MintueSecondInput from "../../../components/inputs/MintueSecondInput";

export default function AddInternalOperation({
  t,
  selectedProduct,
  setIsOpen,
  internalUpdate,
  refresh,
  setItems = undefined,
  hasOrders = false,
}) {
  const [process, setProcess] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [valuePercentage, setValuePercentage] = useState("");
  const [hasQualityControl, setHasQualityControl] = useState(false);
  const [equipment, setEquiments] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const schema = yup.object({
    estimatedTime: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    entityId: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    costOfMinute: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    estimatedSettingsTime: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    description: yup
      .string()
      .notRequired()
      .typeError(`${t("validation:string")}`),
    qualityPercent: yup
      .number(0)
      .max(100, `${t("validation:max100")}`)
      .notRequired()
      .typeError(`${t("validation:number")}`),
    type: yup.string(),
    hasQualityControl: yup.boolean(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "internal",
    },
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  const triggerNode = (nodeId) => {
    if (selectedEquipments?.includes(nodeId)) {
      setSelectedEquipments(
        selectedEquipments?.filter((item) => item !== nodeId)
      );
    } else {
      setSelectedEquipments([...selectedEquipments, nodeId]);
    }
  };

  const handleProcess = async () => {
    setFetching(true);
    await processService.activeProcesses().then((res) => {
      setProcess(
        res?.data
          .filter((d) => d.name)
          .map((d) => {
            return {
              label: d.name,
              value: d.id,
              costOfMinute: d?.costOfMinute,
              iconKey: d?.iconKey,
            };
          })
      );
    });
    setFetching(false);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d+(\.\d{0,2})?$/.test(inputValue) && parseFloat(inputValue) <= 100) {
      setValue("qualityPercent", inputValue);
      setValuePercentage(inputValue);
    } else if (inputValue === "" || inputValue === ".") {
      setValue("qualityPercent", "0");
      setValuePercentage("");
    }
  };

  const getGroupedNodes = async () => {
    const { data } = await processService.getGroupEquipmentToProcess();
    setNodes(data);
    if (internalUpdate?.process?.id) {
      const findGroupedNodes = data?.find(
        (e) => e?.id === internalUpdate?.process?.id
      );
      setEquiments(findGroupedNodes?.equipments);
      setSelectedEquipments(
        internalUpdate?.plannableNodes?.length > 0
          ? internalUpdate?.plannableNodes
          : []
      );
    }
  };

  const onSubmit = async (data) => {
    if (setItems != undefined) {
      if (internalUpdate) {
        setItems((prev) => [
          ...prev.filter((item) => item?.step !== internalUpdate?.step),
          {
            id: internalUpdate?.operationId,
            status: "passive",
            type: "internal",
            step: internalUpdate?.step,
            type: "internal",
            processType: "conversion_operation",
            internalOperation: {
              step: internalUpdate?.step,
              ...data,
              plannableNodes: selectedEquipments,
              process: {
                id: data.entityId,
                name: selectedProcess?.label,
              },
            },
          },
        ]);
      } else {
        setItems((prev) => [
          ...prev,
          {
            status: "passive",
            type: "internal",
            step: prev?.length + 1,
            type: "internal",
            processType: "conversion_operation",
            internalOperation: {
              plannableNodes: selectedEquipments,
              step: prev?.length + 1,
              ...data,
              process: {
                id: data.entityId,
                name: selectedProcess?.label,
              },
            },
          },
        ]);
      }
      closeModal();
    } else {
      setLoading(true);
      if (internalUpdate && internalUpdate?.id) {
        await productService
          .updateProductOperation(internalUpdate?.id, {
            ...data,
            plannableNodes: selectedEquipments,
            type: "internal",
            processType: "conversion_operation",
          })
          .then((res) => {
            if (res.data.code === 0) {
              Toast("success", res?.data?.message);
              refresh();
              closeModal();
            } else {
              Toast("error", res?.data?.message);
            }
          });
      } else {
        await productService
          .addOperation(selectedProduct?.id, {
            ...data,
            plannableNodes: selectedEquipments,
            type: "internal",
            processType: "conversion_operation",
          })
          .then((res) => {
            if (res?.data?.code == 0) {
              Toast("success", res?.data?.message);
              closeModal();
              refresh();
            } else if (res?.data?.code == 2) {
              Toast("error", res?.data?.message);
              closeModal();
              refresh();
            } else {
              Toast("error", res?.data?.message);
              closeModal();
              refresh();
            }
          });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (internalUpdate && internalUpdate?.type) {
      setValue("estimatedTime", internalUpdate?.estimatedTime);
      setValue("entityId", internalUpdate?.process?.id);
      setValue("costOfMinute", internalUpdate?.costOfMinute);
      setValue("estimatedSettingsTime", internalUpdate?.estimatedSettingsTime);
      setValue("description", internalUpdate?.description);
      setValue("qualityPercent", internalUpdate?.qualityPercent);
      setValuePercentage(internalUpdate?.qualityPercent);
      setSelectedProcess({
        value: internalUpdate.process.id,
        label: internalUpdate.process.name,
      });
      setValue("hasQualityControl", internalUpdate?.hasQualityControl);
      setHasQualityControl(internalUpdate?.hasQualityControl);
    } else {
      setValue("estimatedTime", "");
      setValue("entityId", "");
      setValue("costOfMinute", "");
      setValue("estimatedSettingsTime", "");
      setValue("description", "");
      setValue("qualityPercent", 0);
    }
  }, []);

  useEffect(() => {
    handleProcess();
    getGroupedNodes();
  }, []);

  return (
    <>
      {fetching ? (
        <div className="w-[696px] h-[505px] flex items-center justify-center">
          <Loading size={44} primary={"#B54708"} secondary={"#fff"} />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="min-w-[696px] max-w-[696px] h-full scrollbar-hide mt-5"
        >
          <div className="w-full flex gap-5 h-full">
            <div className="w-1/2 gap-4 flex flex-col h-full">
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Selector
                    searchable={true}
                    value={value}
                    items={process}
                    theme={"product"}
                    label={t("product:internalOperation")}
                    onChange={(selectedOption) => {
                      const selectedProcess = process.find(
                        (p) => p.value === selectedOption
                      );
                      setSelectedProcess(selectedProcess);
                      setValue("costOfMinute", selectedProcess?.costOfMinute);
                      onChange(selectedOption);
                      const findGroupedNodes = nodes?.find(
                        (e) => e?.id === selectedOption
                      );
                      setEquiments(findGroupedNodes?.equipments);
                      setSelectedEquipments(
                        findGroupedNodes?.equipments?.map((e) => e.id)
                      );
                    }}
                    onBlur={onBlur}
                    validate={errors?.entityId ? "error" : ""}
                    errorMessage={
                      errors?.entityId ? errors?.entityId?.message : ""
                    }
                    disabled={hasOrders}
                  />
                )}
                name="entityId"
              />
              <div className="flex flex-col w-full">
                <span
                  className={`text-sm pl-0  w-full text-secondary-700 font-medium truncate dark:text-[#D6D6D6] mb-1.5`}
                >
                  {t("equipments:equipments")}
                </span>
                <div className="min-h-[312px] max-h-[312px] w-full flex flex-col overflow-y-auto scrollbar-hide p-1.5">
                  {equipment?.map((item, index) => {
                    const sameNode = selectedEquipments?.includes(item?.id);
                    return (
                      <div
                        onClick={() => triggerNode(item?.id)}
                        key={index}
                        className="w-full border-b py-3 cursor-pointer flex items-center hover:bg-gray-50 px-1.5 active:bg-white active:ring-4 active:ring-gray-50 active:border-transparent active:rounded-xl"
                      >
                        <img
                          className="w-10 h-8 rounded-lg"
                          src={generateFileUrl(item?.metadata?.image)}
                        />
                        <p className="text-sm font-semibold text-gray-700 ml-3 flex-1">
                          {item?.metadata?.brand + " " + item?.metadata?.model}
                        </p>
                        <div
                          className={`w-4 h-4 flex items-center justify-center rounded ${
                            sameNode
                              ? "bg-orange-600 "
                              : "bg-gray-100 border border-gray-300"
                          }`}
                        >
                          {sameNode && <CheckIcon />}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-px flex-1 border-l border-gray-300 mb-5" />
            <div className="w-1/2">
              <div className="flex flex-col gap-4">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("person:description")}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder={t("person:semiMaterialStockCode")}
                      theme={"product"}
                      value={value}
                      validate={errors?.description ? "error" : ""}
                      errorMessage={
                        errors?.description ? errors?.description?.message : ""
                      }
                    />
                  )}
                  name="description"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <MintueSecondInput
                      label={t("product:estimatedTime")}
                      onBlur={onBlur}
                      value={value}
                      theme={"product"}
                      onChange={onChange}
                      validate={errors?.estimatedTime ? "error" : ""}
                      errorMessage={
                        errors?.estimatedTime
                          ? errors?.estimatedTime?.message
                          : ""
                      }
                    />
                  )}
                  name="estimatedTime"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("product:estimatedSettingTime")}
                      onBlur={onBlur}
                      value={value}
                      onChange={onChange}
                      theme={"product"}
                      validate={errors?.estimatedSettingsTime ? "error" : ""}
                      errorMessage={
                        errors?.estimatedSettingsTime
                          ? errors?.estimatedSettingsTime?.message
                          : ""
                      }
                    />
                  )}
                  name="estimatedSettingsTime"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("product:minuteCost")}
                      value={value}
                      onBlur={onBlur}
                      theme={"product"}
                      onChange={onChange}
                      validate={errors?.costOfMinute ? "error" : ""}
                      errorMessage={
                        errors?.costOfMinute
                          ? errors?.costOfMinute?.message
                          : ""
                      }
                    />
                  )}
                  name="costOfMinute"
                />
                <label className="flex items-center">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <input
                        className="rounded"
                        type="checkbox"
                        onChange={(e) => {
                          setHasQualityControl(e.target.checked);
                          setValue("qualityPercent", 0);
                          setValuePercentage("");
                          setValue("hasQualityControl", e.target.checked);
                        }}
                        onBlur={onBlur}
                        checked={value}
                      />
                    )}
                    name="hasQualityControl"
                  />
                  <p className="text-sm text-secondary-600  ml-2">
                    {t("person:qualityControl")}
                  </p>
                </label>
                <Input
                  label={t("product:qualityPercent")}
                  value={valuePercentage}
                  theme={"product"}
                  onChange={handleChange}
                  isPercentage
                  disabled={hasQualityControl ? false : true}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full gap-3 mt-4">
            <Button
              size={"sm"}
              colorType={"secondary-gray"}
              label={t("buttons:cancel")}
              onClick={() => setIsOpen(false)}
            />
            {loading ? (
              <div className="flex items-center justify-center bg-[#DC6803]  rounded-lg w-full h-[46px] max-h-[46px]">
                <Loader currentColor="#DC6803" currentFill="currentFill" />
              </div>
            ) : (
              <Button
                size={"sm"}
                colorType={"primary-product"}
                label={t("buttons:add")}
                type="submit"
              />
            )}
          </div>
        </form>
      )}
    </>
  );
}
