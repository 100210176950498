import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { orderService } from "../../services/order.service";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../hooks/useStores";
import { Button, CustomModal, Loading } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
import { junctionService } from "../../services/junction.service";
import { useTranslation } from "react-i18next";
import Badgets from "../../components/buttons/Badgets";
import SearchInput from "../../components/inputs/SearchInput";
import InternalPlanViewPlannedCard from "./components/order/InternalPlanViewPlannedCard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useWindowDimensions from "../../hooks/useWindowDimension";
import InternalPlannedStationCard from "./components/order/InternalPlannedStationCard";
import InternalPlannedActiveCard from "./components/order/InternalPlannedActiveCard";
import InternalPlannedPlannedCard from "./components/order/InternalPlannedPlannedCard";
import InternalPlanViewNextPlanCard from "./components/order/InternalPlanViewNextPlanCard";
import InternalPlannedDifferentPlannedCard from "./components/order/InternalPlannedDifferentPlannedCard";
import InternalPlannedPassiveCard from "./components/order/InternalPlannedPassiveCard";
import SelectProductModal from "../../components/modal/SelectProductModal";
import DroppedJunctionPlan from "./components/DroppedJunctionPlan";
import SelectUserModal from "../../components/modal/SelectUserModal";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import { Toast } from "../../utils/toastify/toast";
import { NewDotsIcon } from "../../components/new-cards/card-icons";
import { ArrowCircleRight } from "../../assets/icons/productIcons";
import MovePlannedJobsModal from "../../components/modal/MovePlannedJobsModal";

const InternalOperationPlanView = () => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const { id, search } = useParams();
  const { settings, auth } = useStore();
  const outerDivRef = useRef(null);
  const mappedDivRefs = useRef([]);
  const navigate = useNavigate();
  const [rowLoading, setRowLoading] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleFlexProduct, setVisibleFlexProduct] = useState("all"); // types: 'all' | 'flex' | 'product'
  const [data, setData] = useState([]);
  const filteredData = data?.orderList?.filter((item) => item != null);
  const scrollContainerRef = useRef(null);
  const [isOpenFinishJunction, setIsOpenFinishJunction] = useState(false);
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [isOpenSelectUserModal, setIsOpenSelectUserModal] = useState(false);
  const [isOpenSelectProductModal, setIsOpenSelectProductModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [draggedProduct, setDraggedProduct] = useState(null);
  const [tableHeadItemsLength, setTableHeadItemsLength] = useState(0);
  const [products, setProducts] = useState([]);
  const [stations, setStations] = useState([]);
  const [drappedEquipment, setDrappedEquipment] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [movePlannedJobsModal, setMovePlannedJobsModal] = useState(false);
  const menuOptions = [
    {
      label: "Planlı İşleri Farklı İstasyona Taşı",
      value: "movePlannedJobs",
      icon: <ArrowCircleRight />,
    },
  ];

  const tabOptions = [
    { value: "plan", label: t("product:planner") },
    { value: "junction", label: t("product:eventss") },
  ];

  const headData = useQuery({
    queryKey: ["all-operations-junctions"],
    retry: 1,
    queryFn: async () => await orderService.getOrderHeadData(),
  });
  const operations = headData.data?.data?.operation?.sort((first, second) => second?.total - first?.total);

  const handlePlanningData = async (id, search, visibleFlexProduct) => {
    setLoading(true);
    await junctionService.getInternalOperationPlanview(id, search, visibleFlexProduct).then((res) => {
      handleHeaderLength();
      navigate(`/app/product/mrp/supply/operation/${id}/plan-view/${search}`);
      setData(res);
      setProducts(res?.orderList);
      setStations(
        res?.equipments?.map((item, index) => {
          return { ...item, droppableId: `station-${index}`, items: item?.plannedJunction };
        })
      );
      setLoading(false);
      setRowLoading([]);
    });
  };

  const tableHeadItems = [];
  for (let i = 0; i < tableHeadItemsLength; i++) {
    tableHeadItems.push({ title: `${i}`, id: i });
  }

  const handleScroll = (source, index = null) => {
    let currentScrollLeft;
    if (source === "outer") {
      currentScrollLeft = outerDivRef.current?.scrollLeft;
    } else if (source === "mapped") {
      currentScrollLeft = mappedDivRefs.current[index]?.scrollLeft;
    }
    if (outerDivRef.current && source !== "outer") {
      outerDivRef.current.scrollLeft = currentScrollLeft;
    }
    mappedDivRefs.current.forEach((div, idx) => {
      if (div && idx !== index) {
        div.scrollLeft = currentScrollLeft;
      }
    });
  };

  const handleHeaderLength = useCallback(() => {
    if (!data?.equipments) return;
    const headLengths = data?.equipments?.map((d) => (d.plannedJunction?.length || 0) + (d.activeJunction?.length || 0));
    setTableHeadItemsLength(Math.max(...headLengths, 2));
  }, [data]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (destination.droppableId === "products" && source.droppableId === "products") return;
    const changedRowIndex = String(destination.droppableId).replace("station-", "");
    const sourceNodeIndex = String(source.droppableId).replace("station-", "");
    const droppedNode = stations[Number(changedRowIndex)];
    const draggedProduct = products[source.index];
    const otherProduct = stations[Number(sourceNodeIndex)]?.items[source.index];
    if (source.droppableId === "products" && destination.droppableId.startsWith("station-")) {
      setDraggedProduct({ ...draggedProduct, planningOrder: destination.index });
      setDrappedEquipment(droppedNode);
      setIsOpenPlanningModal(true);
      return;
    }
    if (destination.droppableId === source.droppableId) {
      let order = [];
      const newArr = stations?.map((station) => {
        if (station.droppableId === source.droppableId) {
          order = reorder(station.items, source.index, destination.index);
          return { ...station, items: reorder(station.items, source.index, destination.index) };
        } else {
          return station;
        }
      });
      setRowLoading([Number(changedRowIndex)]);
      const body = order?.map((obj, ind) => ({ ...obj, planningOrder: ind }));
      junctionService.updatePlanningJunctionList(body).then(() => {
        handlePlanningData(id, searchQuery, visibleFlexProduct);
      });
      setStations(newArr);
    }
    if (source.droppableId !== destination.droppableId) {
      const findProduct = products.find((e) => {
        if (otherProduct.productType === "flex") {
          return e?.id === otherProduct?.flexHeader?.id;
        } else {
          return e?.id === otherProduct?.header?.id;
        }
      });
      if (findProduct) {
        setDraggedProduct({ ...findProduct, planningOrder: destination.index });
        setDrappedEquipment(droppedNode);
        setIsOpenPlanningModal(true);
      } else {
        Toast("warning", "Lütfen ürünler listesindeki filtreleri kaldırın.");
      }
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  useEffect(() => {
    if (operations?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mrp/supply/operation/${operations[0]?.id}/plan-view/`);
      handlePlanningData(operations[0]?.id, searchQuery, visibleFlexProduct);
    }
  }, [operations]);

  useEffect(() => {
    if (id) {
      handleHeaderLength();
    }
  }, [operations, data, id]);

  useEffect(() => {
    if (id != ":id" && id != "undefined") {
      handlePlanningData(id, searchQuery, visibleFlexProduct);
    }
  }, [searchQuery]);

  return (
    <>
      {pageLoading && (
        <div className="absolute inset-0 bg-black opacity-40 z-[99999] flex items-center justify-center backdrop-blur-3xl">
          <Loading size={44} primary={"#fff"} secondary={"#000"} />
        </div>
      )}
      <div className="w-full h-full flex flex-col overflow-hidden overflow-y-auto scrollbar-hide px-0.5">
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
          </span>
          <div className="flex items-center">
            <span className="font-codecMedium text-2xl text-black">Operations</span>
            {!headData?.isLoading && (
              <p className="text-xl ml-2 text-secondary-500">{operations?.find((item) => item?.id === id)?.name || "Internal Operations Planning"}</p>
            )}
          </div>
        </div>
        <div>
          {headData?.isLoading ? (
            <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[116px] min-h-[116px] flex gap-5" />
          ) : (
            <div className="h-[116px] min-h-[116px] w-full pb-0 overflow-x-auto flex ">
              {operations?.map((item, index) => {
                return (
                  <div key={index} className={`min-w-[192px] ${index !== operations - 1 ? "border-b" : "border-b-0"} max-h-[96px] pr-5`}>
                    <NewOrderHeaderCard
                      id={item?.id}
                      name={item?.name}
                      icon={item?.iconKey}
                      selectedOperation={{ id, name: item?.name }}
                      onClick={async () => {
                        setPageLoading(true);
                        const newIndices = stations?.map((_, index) => index);
                        setRowLoading(newIndices);
                        await handlePlanningData(item?.id, searchQuery, visibleFlexProduct);
                        setPageLoading(false);
                      }}
                      planned={Number(item?.plannedWorkLoad / 86400).toFixed(1)}
                      unPlanned={
                        Number((item?.total - item?.plannedWorkLoad) / 86400).toFixed(1) > 0
                          ? Number((item?.total - item?.plannedWorkLoad) / 86400).toFixed(1)
                          : (0).toFixed(1)
                      }
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-6 mb-4">
          {tabOptions?.map((option, index) => (
            <div
              key={index}
              className={`w-1/2 h-full rounded-md px-3 py-2 flex items-center justify-center cursor-pointer ${
                "plan" == option?.value ? "bg-white shadow-sm" : ""
              }`}
              onClick={() => {
                if (option?.value != "plan") navigate(`/app/product/mrp/supply/operation/${id}/junction`);
              }}
            >
              <p className={`text-md font-semibold ${"plan" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
            </div>
          ))}
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <div className="flex border border-gray-200 bg-white shadow-sm flex-col rounded-xl overflow-hidden h-[203px] min-h-[203px]">
            <div className="flex w-full pl-6 pr-3 py-3 items-center border-b">
              <div className="flex w-full min-h-[28px] max-h-[28px] gap-x-2 items-center">
                <p className="flex text-[#101828] font-semibold text-lg">
                  {data?.name} {t("product:products")}
                </p>
                <Badgets colorType={"outline-warning"} label={filteredData?.length} />
              </div>
              <div className="flex flex-row items-center gap-x-3 justify-end w-full min-h-10 max-h-10 h-10">
                <div className="flex max-w-[400px] w-full justify-end relative">
                  <SearchInput theme={"product"} setSearchVal={setSearchQuery} value={search} />
                </div>
                <div className="flex border h-full border-[#D0D5DD] rounded-lg whitespace-nowrap text-[#344054] font-semibold text-sm cursor-pointer">
                  <div
                    onClick={() => {
                      setVisibleFlexProduct("all");
                      handlePlanningData(id, searchQuery, "all");
                    }}
                    className={`flex px-4 py-2 border-r hover:bg-[#F2F4F7] border-[#D0D5DD] rounded-l-lg ${visibleFlexProduct === "all" && "bg-[#F2F4F7]"}`}
                  >
                    <p>Tümü</p>
                  </div>
                  <div
                    onClick={() => {
                      setVisibleFlexProduct("product");
                      handlePlanningData(id, searchQuery, "product");
                    }}
                    className={`flex px-4 py-2 border-r hover:bg-[#F2F4F7] border-[#D0D5DD] ${visibleFlexProduct === "product" && "bg-[#F2F4F7]"}`}
                  >
                    <p>Ürünler</p>
                  </div>
                  <div
                    onClick={() => {
                      setVisibleFlexProduct("flex");
                      handlePlanningData(id, searchQuery, "flex");
                    }}
                    className={`flex px-4 py-2 rounded-r-lg hover:bg-[#F2F4F7] ${visibleFlexProduct === "flex" && "bg-[#F2F4F7]"}`}
                  >
                    <p>Esnek Ürünler</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full p-3">
              <div className="flex flex-col gap-y-2 w-[130px] min-w-[130px]">
                <div className="flex flex-col gap-y-1 items-center w-[130px] min-w-[130px] pr-4">
                  <div className="flex flex-col border border-[#D0D5DD] px-3 py-[6px] rounded-lg w-full">
                    <p className="text-xs font-normal text-secondary-600 ">{t("product:totalWorkload")}</p>
                    <p className="text-sm font-medium text-secondary-700">
                      {Number(data?.totalWorkLoad / 86400).toFixed(1) || 0} {t("product:day")}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-y-1 items-center w-[130px] min-w-[130px] pr-4">
                  <div className="flex flex-col border border-[#D0D5DD] px-3 py-[6px] rounded-lg w-full">
                    <p className="text-xs font-normal text-secondary-600">{t("product:stationAvg")}</p>
                    <p className="text-sm font-medium text-secondary-700">
                      {Number(data?.totalWorkLoad / 86400 / data?.equipments?.length)?.toFixed(1) || 0} {t("product:day")}
                    </p>
                  </div>
                </div>
              </div>

              {loading ? (
                <div className="flex-1 flex bg-gray-200 rounded-xl animate-pulse" />
              ) : (
                <Droppable droppableId="products" direction="horizontal">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className="w-full overflow-x-auto flex overflow-y-hidden relative">
                      {products?.map((item, index) => {
                        const statusColor = () => {
                          if (item?.flexProduct || item?.flexRawMaterial || item?.name) {
                            return "#E9D7FE";
                          } else {
                            switch (item?.status) {
                              case "finished":
                                return "#12B76A";
                              case "started":
                                return "#FDE172";
                              case "rejected":
                                return "#D92D20";
                              case "flexProduct":
                                return "#E9D7FE";
                              default:
                                return "#F2F4F7";
                            }
                          }
                        };
                        return (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <InternalPlanViewPlannedCard key={item.id} item={item} statusColor={statusColor} setDraggedProduct={setDraggedProduct} />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}
            </div>
          </div>

          <div style={{ minHeight: height - 270 }} className="w-full mt-4 border rounded-xl border-gray-300 flex flex-col overflow-hidden relative">
            <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
              <div
                className={`min-h-[43px] flex items-center justify-between max-w-[233px] min-w-[233px] px-6 py-3 text-sm bg-[#F9FAFB] font-semibold text-[#344054] border-r border-secondary-200`}
              >
                <p className="text-sm text-[#344054] font-semibold">{t("product:stations")}</p>
              </div>
              <div
                ref={outerDivRef}
                onScroll={() => handleScroll("outer")}
                id="content-wrapper"
                className="flex items-center w-full h-full overflow-x-auto overflow-y-hidden"
              >
                {tableHeadItems.map((item, index) => (
                  <div
                    key={index}
                    className={` min-h-[44px] flex items-center justify-between min-w-[360px] px-6 py-3 text-sm bg-[#F9FAFB] font-semibold text-[#344054] border-r border-secondary-200`}
                  >
                    {index === 0 ? (
                      <>
                        <p className="text-sm text-[#344054] font-semibold">{t("product:active")}</p>
                      </>
                    ) : index === 1 ? (
                      <>
                        <p className="text-sm text-[#344054] font-semibold">{t("product:plannedWorks")}</p>
                        {item?.title}
                      </>
                    ) : (
                      <>
                        <p></p>
                        {item?.title}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex-1 w-full flex flex-col overflow-y-auto scrollbar-hide">
              {stations?.map((a, b) => {
                let filledData = [
                  ...(a?.activeJunction || []),
                  ...(a.items?.sort((a, b) => a?.planningOrder - b?.planningOrder) || []),
                  ...Array.from({ length: Math.max(tableHeadItemsLength - a?.items?.concat(a?.activeJunction)?.length, 0) }, () => null),
                ];
                return (
                  <Droppable key={a.droppableId} droppableId={a.droppableId} direction="horizontal">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps} className="flex w-full min-h-[106px]">
                        <div className={`flex w-[233px] sticky z-[50] h-full min-w-[233px] max-w-[233px] pl-6 pr-3 bg-white py-2 border-r border-b group`}>
                          <span className="flex w-full h-full relative">
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenDropdown(openDropdown === a.droppableId ? null : a.droppableId);
                              }}
                              className="flex absolute right-0 top-0 cursor-pointer"
                            >
                              <NewDotsIcon />
                            </span>
                            {openDropdown === a.droppableId && (
                              <div className="hidden flex-col absolute min-w-[270px] max-w-[270px] h-fit bg-white border top-[20px] left-[4px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
                                {menuOptions?.map((items, index) => {
                                  return (
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (items.value === "movePlannedJobs") {
                                          setSelectedStation(a);
                                          setMovePlannedJobsModal(true);
                                        }
                                        setOpenDropdown(null);
                                      }}
                                      key={index}
                                      type="button"
                                      className={`w-full h-[38px] flex items-center px-2 rounded-md gap-x-2 hover:bg-secondary-100`}
                                    >
                                      {items?.icon}
                                      <p className={`font-medium text-sm text-secondary-700 `}>{items?.label}</p>
                                    </button>
                                  );
                                })}
                              </div>
                            )}
                            <InternalPlannedStationCard filledData={filledData} a={a} />
                          </span>
                        </div>
                        <div
                          ref={(el) => {
                            scrollContainerRef.current = el;
                            provided.innerRef(el);
                            mappedDivRefs.current[b] = el;
                          }}
                          onScroll={() => handleScroll("mapped", b)}
                          id="content-wrapper"
                          className="w-full overflow-x-auto overflow-y-hidden scrollbar-hide flex border-b border-gray-200 max-h-[106px] m-0 p-0 z-20"
                        >
                          {filledData?.map((item, index) => {
                            if (item) {
                              if (item?.status === "active") {
                                return (
                                  <div
                                    className={`min-w-[360px] max-w-[360px] z-50 flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white cursor-pointer`}
                                  >
                                    <div className="cursor-pointer w-full">
                                      <InternalPlannedActiveCard
                                        data={item}
                                        setIsOpenFinishJunction={setIsOpenFinishJunction}
                                        setSelectedJunction={setSelectedJunction}
                                        refetch={() => handlePlanningData(id, searchQuery, visibleFlexProduct)}
                                      />
                                    </div>
                                  </div>
                                );
                              } else {
                                if (rowLoading.includes(b)) {
                                  return (
                                    <div className="min-w-[360px] max-w-[360px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white">
                                      <div className="w-full h-full rounded-lg bg-gray-200 animate-pulse" />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <Draggable key={item?.id} draggableId={item?.id} index={a?.activeJunction?.length > 0 ? index - 1 : index}>
                                      {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                          <div
                                            className={`min-w-[360px] max-w-[360px] flex items-center justify-center py-2 border-r min-h-[106px] max-h-[106px] border-gray-200 px-3 bg-white`}
                                          >
                                            {item?.status === "planned" ? (
                                              <>
                                                {index === 0 ? (
                                                  <div className="relative w-full group">
                                                    <InternalPlannedPlannedCard
                                                      data={item}
                                                      refetch={() => handlePlanningData(id, searchQuery, visibleFlexProduct)}
                                                      setIsOpenSelectUserModal={setIsOpenSelectUserModal}
                                                      setSelectedJunction={setSelectedJunction}
                                                      setIsOpenFinishJunction={setIsOpenFinishJunction}
                                                    />
                                                  </div>
                                                ) : (
                                                  <InternalPlanViewNextPlanCard
                                                    item={item}
                                                    operationId={id}
                                                    handlePlanData={() => handlePlanningData(id, searchQuery, visibleFlexProduct)}
                                                    handleHeaderData={headData.refetch}
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <InternalPlannedDifferentPlannedCard item={item} />
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }
                              }
                            } else {
                              return (
                                <div>
                                  {index === 0 ? (
                                    <div className={`min-w-[360px] max-w-[360px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white`}>
                                      <InternalPlannedPassiveCard
                                        setIsOpenSelectProductModal={setIsOpenSelectProductModal}
                                        setDrappedEquipment={setDrappedEquipment}
                                        a={a}
                                      />
                                    </div>
                                  ) : (
                                    <div className="min-w-[360px] max-w-[360px]" />
                                  )}
                                </div>
                              );
                            }
                          })}
                          {filledData?.length < 1 && (
                            <div className="min-w-[360px] max-w-[360px] min-h-[106px] max-h-[106px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white">
                              <InternalPlannedPassiveCard
                                setIsOpenSelectProductModal={setIsOpenSelectProductModal}
                                setDrappedEquipment={setDrappedEquipment}
                                a={a}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Droppable>
                );
              })}
            </div>
          </div>
        </DragDropContext>
        {draggedProduct && (
          <DroppedJunctionPlan
            nodeData={drappedEquipment}
            orderData={draggedProduct}
            isOpen={isOpenPlanningModal}
            setIsOpen={setIsOpenPlanningModal}
            refetch={() => handlePlanningData(id, searchQuery, visibleFlexProduct)}
            processId={id}
          />
        )}
      </div>
      <CustomModal
        isOpen={isOpenSelectProductModal}
        setIsOpen={setIsOpenSelectProductModal}
        onClose={() => {
          setIsOpenSelectProductModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectProductModal
            setIsOpenSelectProductModal={setIsOpenSelectProductModal}
            filteredData={filteredData}
            setIsOpenPlanningModal={setIsOpenPlanningModal}
            setDraggedProduct={setDraggedProduct}
          />
        }
      />

      <CustomModal
        isOpen={isOpenSelectUserModal}
        setIsOpen={setIsOpenSelectUserModal}
        onClose={() => {
          setIsOpenSelectUserModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectUserModal
            t={t}
            setIsOpen={setIsOpenSelectUserModal}
            isOpen={isOpenSelectUserModal}
            selectedJunction={selectedJunction}
            handleData={() => handlePlanningData(id, searchQuery, visibleFlexProduct)}
          />
        }
      />

      <CustomModal
        isOpen={isOpenFinishJunction}
        setIsOpen={setIsOpenFinishJunction}
        modalTitle={t("product:finishJuction")}
        children={
          <FinishJunctionModal
            handleData={() => handlePlanningData(id, searchQuery, visibleFlexProduct)}
            modalTheme={"product"}
            junctionId={selectedJunction?.id}
            closeModal={() => setIsOpenFinishJunction(false)}
            type={selectedJunction?.internalOperation ? "internal" : "external"}
            productType={selectedJunction?.productType}
          />
        }
      />
      <CustomModal
        isOpen={movePlannedJobsModal}
        setIsOpen={setMovePlannedJobsModal}
        modalTitle={"Planlı İşleri Taşı"}
        subTitle={"Bu istasyondaki tüm işleri farklı bir istasyona taşımak için seçin"}
        width={460}
        height={600}
        children={<MovePlannedJobsModal stations={stations} selectedStation={selectedStation} setMovePlannedJobsModal={setMovePlannedJobsModal} refetch={ ()=> {handlePlanningData(id, searchQuery, visibleFlexProduct)}} />}
      />
    </>
  );
};

export default InternalOperationPlanView;
