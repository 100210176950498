import { observer } from "mobx-react-lite";
import React from "react";
import { CheckIcon } from "../../../assets/icons/stepsIcons";
import generateFileUrl from "../../../utils/generateFileUrl";

const PollPersonalItem = observer(({ isSelected, chat, item, triggerUser }) => {

  return (
    <>
      <div className="flex flex-col items-start gap-4 select-none">
        <div
          onClick={() => {
            triggerUser(item);
            chat?.setCurrentChatUser(`${item?.name} ${item?.lastName}`, item?.avatar);
          }}
          className="flex flex-row items-center justify-center gap-3 p-0 w-full h-16"
        >
          <div className="w-full flex flex-row items-center gap-3 p-0 h-full">
            {isSelected ? (
              <div className="flex flex-row items-center gap-3 w-full ">
                <div
                  onClick={() => {
                    triggerUser(item);
                    chat?.setCurrentChatUser(`${item?.name} ${item?.lastName}`);
                  }}
                  className={`cursor-pointer flex w-4 h-4 rounded items-center justify-center bg-[#0E9384] text-[#FFF]`}
                >
                  <CheckIcon color={"#fff"} />
                </div>
                <div className="flex flex-row items-center gap-3">
                  {item?.avatar ? (
                    <img src={generateFileUrl(item?.avatar)} className="w-10 h-10 rounded-full border-[0.75px] border-solid box-border border-[#EAECF0]" />
                  ) : (
                    <div className="w-10 h-10 border-4 border-teal-500 bg-teal-100 text-teal-700 rounded-full flex items-center justify-center text-md">
                      {item?.name?.charAt(0).toUpperCase() + "" + item?.lastName?.charAt(0).toUpperCase()}
                    </div>
                  )}

                  <div className="flex flex-col justify-center items-start gap-1 p-0  h-12">
                    <p className="text-sm font-semibold text-[#344054]">
                      {item?.name} {item?.lastName}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full flex items-center gap-3">
                <div
                  onClick={() => {
                    triggerUser(item);
                    chat?.setCurrentChatUser(`${item?.name} ${item?.lastName}`, item?.avatar);
                  }}
                  className="cursor-pointer w-4 h-4 border border-[#D0D5DD] rounded items-center justify-center bg-[#fff]"
                ></div>
                <div className="flex flex-row items-center gap-3">
                  {item?.avatar ? (
                    <img src={generateFileUrl(item?.avatar)} className="w-10 h-10 rounded-full border-[0.75px] border-solid box-border border-[#EAECF0]" />
                  ) : (
                    <div className="w-10 h-10 border-4 border-teal-500 bg-teal-100 text-teal-700 rounded-full flex items-center justify-center text-md">
                      {item?.name?.charAt(0).toUpperCase() + "" + item?.lastName?.charAt(0).toUpperCase()}
                    </div>
                  )}

                  <div className="flex flex-col justify-center items-start gap-1 p-0  h-12">
                    <p className="text-sm font-semibold text-[#344054]">
                      {item?.name} {item?.lastName}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default PollPersonalItem;
