import React, { useEffect, useRef, useState } from "react";
import { companyService } from "../services/company.service";
import ShiftCalendarTable from "./machine/components/ShiftCalendarTable";
import { Button, CustomModal } from "../components";
import { ArrowLeftIcon, ChevronDown } from "../assets/icons/PersonIcons";
import { useNavigate } from "react-router-dom";
import PlanShiftDay from "../components/modal/PlanShiftDay";
import AddHolidayModal from "../components/modal/AddHolidayModal";
import { useTranslation } from "react-i18next";
import SavedShiftModal from "./machine/components/SavedShiftModal";

const SavedShifts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [savedShiftModal, setSavedShiftModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    { label: t("addShift:addHoliday"), value: "holiday" },
    { label: "Add Nonstandart Shift", value: "nonstandart" },
  ];

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleData = async () => {
    await companyService.getDailyShifts().then(({ data }) => {
      setData(
        data?.map((item) => {
          return { ...item, times: item?.shifts?.flat() };
        })
      );
    });
  };
  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="h-[56px] w-full flex items-center border-b">
          <Button
            size={"md"}
            onClick={() => navigate(-1)}
            colorType={"tertiary-gray"}
            iconLeft={
              <div className="text-secondary-700">
                <ArrowLeftIcon />
              </div>
            }
          />
          <h1 className="text-2xl font-semibold text-gray-900 ml-3">{t("addShift:registeredShifts")}</h1>
          <div className="ml-auto flex items-center gap-3">
            {/* <Button
              label={t("addShift:addHoliday")}
              size={"md"}
              colorType={"secondary-machine"}
              onClick={() => {
                setOpenModal(true);
              }}
            />
            <Button
              label={'Nonstandart Shift'}
              size={"md"}
              colorType={"secondary-machine"}
              onClick={() => {
                setSavedShiftModal(true);
              }}
            />
            */}
            <div className="relative flex flex-col" ref={menuRef}>
              <button
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className={`pl-3 pr-2 py-2 h-[42px] w-max flex items-center border border-gray-300 rounded-lg relative gap-2 hover:bg-gray-50 active:bg-white active:ring-2 ring-gray-200 ${
                  isOpen && "bg-gray-50"
                }`}
                type="button"
              >
                <p className="text-sm font-semibold text-secondary-700">Add Non Standart Shift</p>
                <ChevronDown />
              </button>

              {isOpen && (
                <div className="absolute w-[190px] p-1 bg-white right-0 top-10 border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1 z-50">
                  {options.map((item, index) => {
                    return (
                      <button
                        onClick={() => {
                          setIsOpen(false);
                          if (item.value === "holiday") {
                            setOpenModal(true);
                          } else {
                            setSavedShiftModal(true);
                          }
                        }}
                        key={index}
                        type="button"
                        className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                      >
                        <p className="font-medium text-sm text-secondary-700">{index === "taskList" ? "" : item.label}</p>
                      </button>
                    );
                  })}
                </div>
              )}
            </div>

            <Button
              label={t("addShift:addNewDailyShift")}
              size={"md"}
              colorType={"secondary-machine"}
              onClick={() => {
                navigate("/app/machine/shift/new-daily-shift");
              }}
            />
          </div>
        </div>
        <div className="h-full w-full flex flex-col pt-5 overflow-y-auto overflow-x-hidden scrollbar-hide">
          <ShiftCalendarTable title={"Kayıtlı Vardiyalar"} data={data} type="saved" refetch={handleData} />
        </div>
      </div>
      <CustomModal
        modalTitle={t("addShift:addHoliday")}
        isOpen={openModal}
        setIsOpen={setOpenModal}
        children={<AddHolidayModal closeModal={() => setOpenModal(false)} />}
      />

      <CustomModal
        modalTitle={t("addShift:planShiftDay")}
        isOpen={savedShiftModal}
        setIsOpen={setSavedShiftModal}
        children={
          <SavedShiftModal
            data={data}
            setModalOpen={setSavedShiftModal}
            refetch={handleData}
            type={"nonstandard"}
            openPlanModal={setModalOpen}
            setSelectedGroup={setSelectedGroup}
          />
        }
      />

      <CustomModal
        modalTitle={t("addShift:planShiftDay")}
        setIsOpen={setModalOpen}
        isOpen={modalOpen}
        children={
          <PlanShiftDay
            selectedGroup={selectedGroup}
            refetch={handleData}
            closeModal={() => {
              setModalOpen(false);
              setSavedShiftModal(false);
            }}
            type={"nonstandard"}
          />
        }
      />
    </>
  );
};

export default SavedShifts;
