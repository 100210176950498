import React, { useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button } from "../../../components";
import { TickIcon } from "../../../assets/icons/PersonIcons";
import { useTranslation } from "react-i18next";
import diacritics from "diacritics";

const SelectTaskUsers = ({
  setIsOpen,
  handleUser,
  selectedUser,
  groupParticipants,
  setSelectedUser,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = groupParticipants?.filter((item) =>
    diacritics
      .remove(`${item?.name} ${item?.lastName}`)
      .toLowerCase()
      .includes(diacritics.remove(searchTerm.toLowerCase()))
  );

  return (
    <div className="w-[500px] h-[600px] flex flex-col ">
      <div className="w-full mt-6">
        <SearchInput
          setSearchVal={setSearchTerm}
          placeholder={t("chat:search")}
          theme={"person"}
        />
      </div>
      <div
        onClick={() => {
          if (selectedUser?.length === groupParticipants?.length) {
            setSelectedUser([]);
          } else {
            setSelectedUser(groupParticipants);
          }
        }}
        className="w-full flex items-center justify-between mt-4 cursor-pointer group pr-3"
      >
        <p className="font-medium text-teal-700 group-hover:underline">
          {t("buttons:selectAll")}
        </p>
        <button
          className={`w-6 h-6 rounded border flex items-center justify-center ${
            selectedUser?.length === groupParticipants?.length
              ? "border-teal-600 text-teal-700"
              : "border-secondary-200 "
          }`}
          type="button"
        >
          {selectedUser?.length === groupParticipants?.length && <TickIcon />}
        </button>
      </div>
      <div className="h-[550px] flex flex-col mb-4 mt-2 w-full overflow-y-auto scrollbar-hide border-b border-secondary-200">
        {filteredData?.map((item, index) => {
          const isSelected = selectedUser?.find((a) => a?.id === item?.id);
          return (
            <>
              <div
                key={index}
                onClick={() => handleUser(item)}
                className={`flex flex-row items-center gap-3 px-3 py-2 w-full hover:bg-secondary-50 hover:rounded-lg cursor-pointer`}
              >
                {item?.avatar ? (
                  <div
                    style={{ border: "1px solid rgba(0, 0, 0, 0.10)" }}
                    className="w-10 h-10 rounded-full dark:border-[#424242] overflow-hidden"
                  >
                    <img src={generateFileUrl(item?.avatar)} />
                  </div>
                ) : (
                  <div className="w-10 h-10 border bg-secondary-100 border-secondary-300 text-secondary-500 rounded-full flex items-center font-semibold justify-center text-md ">
                    {item?.name?.charAt(0).toUpperCase() +
                      "" +
                      item?.lastName?.charAt(0).toUpperCase()}
                  </div>
                )}
                <div className="flex flex-col items-start">
                  <p className="">{item?.name + " " + item?.lastName}</p>
                </div>

                {isSelected && (
                  <span className="w-6 h-6 flex items-center justify-center text-teal-600 ml-auto">
                    <TickIcon />
                  </span>
                )}
              </div>
            </>
          );
        })}
      </div>
      <Button
        colorType={"primary-person"}
        size={"sm"}
        label={t("buttons:save")}
        onClick={() => setIsOpen(false)}
      />
    </div>
  );
};

export default SelectTaskUsers;
