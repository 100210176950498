import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CustomModal, Loading, PaginationComponent, TableOptions } from "../../components";
import { PlusIcon, QuantityIcon } from "../../assets/icons/stepsIcons";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { useQuery } from "@tanstack/react-query";
import { productService } from "../../services/product.service";
import NewProduct from "./modals/NewProduct";
import { ScrollChevronIcon, QuantityAcceptedIcon, QuantityDeclineIcon } from "../../assets/icons/productIcons";
import NoData from "../../steps/step-navigation/components/NoData";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimension";
import SearchInput from "../../components/inputs/SearchInput";
import { SearchIcon } from "../../assets/icons/PersonIcons";
import OrderSort from "../product/components/OrderSort";
import { DeadlineIconSort } from "../../assets/icons/equipmentIcon";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import diacritics from "diacritics";
import RawMaterialStockCard from "./cards/RawMaterialStockCard";
import StockProductCard from "./cards/StockProductCard";
import StockOperationCard from "./cards/StockOperationCard";
import NewStockExternalOperationCard from "./cards/NewStockExternalOperationCard";

const Stock = () => {
  const navigate = useNavigate();
  const { auth, settings } = useStore();
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [newProduct, setNewProduct] = useState([]);
  const [tableHeadItemsLength, settableHeadItemsLength] = useState(0);
  const [isNewProductModal, setIsNewProductModal] = useState(false);
  const [responsiveSearch, setResponsiveSearch] = useState(false);
  const [windowSize, setWindowSize] = useState(window?.innerWidth);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [runState, setRunState] = useState(false);
  const [stepIndexState, setStepIndexState] = useState(0);
  const [pageNo, setPageNo] = useState(Number(searchParams.get("pageNo")) || 1);
  const [searchVal, setSearchVal] = useState(searchParams.get("search") || "");
  const [sortValue, setSortValue] = useState(searchParams.get("sort") || "");
  const [ascDesc, setAscDesc] = useState(searchParams.get("ascDesc") === "true" ? true : false);
  const [pageLimit, setPageLimit] = useState(Number(searchParams.get("take")) || 10);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageData, setPageData] = useState([]);

  const isSmallScreen = windowSize <= 1023;

  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["listProduct"],
    queryFn: useMemo(
      () => async () => {
        try {
          const res = await productService.getStockListForGet(Number(pageNo), searchVal, sortValue, ascDesc ? "ASC" : "DESC", pageLimit);
          setTotalCount(res?.meta?.itemCount);
          setPageCount(res?.meta?.pageCount);
          setPageData(res?.data);

          const queryParams = new URLSearchParams();
          if (pageNo) queryParams.set("pageNo", pageNo.toString());
          if (searchVal) queryParams.set("search", searchVal);
          if (sortValue) queryParams.set("sort", sortValue);
          if (ascDesc !== undefined) queryParams.set("ascDesc", ascDesc.toString());
          if (pageLimit != 10) queryParams.set("take", pageLimit.toString());

          setSearchParams(queryParams);

          return res;
        } catch (error) {
          console.error("Error fetching data:", error);
          throw error;
        }
      },
      [pageNo, searchVal, sortValue, ascDesc, pageLimit]
    ),
    retry: 0,
  });

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  useEffect(() => {
    refetch();
  }, [pageNo, searchVal, sortValue, ascDesc, pageLimit]);

  const dataManipulate = () => {
    if (pageData?.length > 0) {
      const updatedProduct = pageData?.map((e) => {
        return {
          ...e,
          operations: e?.operations,
        };
      });
      setNewProduct(updatedProduct);
      const head = updatedProduct.map((d) => {
        return d?.operations?.length;
      });
      settableHeadItemsLength(Math?.max(...head));
    }
  };

  const tableHeadItems = [];
  for (let i = 0; i < Math.max(1, tableHeadItemsLength); i++) {
    tableHeadItems.push({ title: `${i + 1}.${t("product:operation")}`, id: i });
  }

  useEffect(() => {
    dataManipulate();
  }, [pageData]);

  const handleSearch = (input) => {
    const normalizedInput = diacritics.remove(input);
    setSearchVal(normalizedInput);
  };

  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };

  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window?.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleJoyrideCallback(data) {
    const { action, index, type, status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunState(false);
      setStepIndexState(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndexState(stepIndex);
    }
  }

  const changePage = (newPageNo) => {
    setPageNo(newPageNo);
    searchParams.set("pageNo", newPageNo.toString());
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setPageNo(1);
  }, [searchVal, sortValue]);

  return (
    <>
      <>
        <ScrollSync>
          <div className="flex flex-col w-full h-full overflow-hidden">
            <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
              <span className="mr-3 md:flex sm:flex xs:flex hidden">
                <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
              </span>
              <div className="flex items-center">
                <span className="font-codecMedium text-2xl text-black">upu.</span>
                <p className="text-2xl font-codecMedium text-[#B54708]">stock</p>
                <p className="text-xl ml-2 font-normal text-secondary-500">{t("product:productStock")}</p>
              </div>
              <div className="ml-auto flex flex-row items-center gap-x-3">
                <div className="w-[320px] max-w-[320px] min-w-[320px] sm:hidden md:hidden">
                  <SearchInput setSearchVal={handleSearch} theme={"product"} placeholder={t("chat:search")} />
                </div>
                <div className="hidden sm:flex md:flex">
                  <Button size="md" iconLeft={<SearchIcon />} colorType={"secondary-gray"} />
                </div>
                <OrderSort
                  sortValue={sortValue}
                  setSortValue={setSortValue}
                  ascDesc={ascDesc}
                  setAscDesc={setAscDesc}
                  options={[
                    {
                      label: t("product:productName"),
                      value: "name",
                      icon: <QuantityIcon />,
                    },
                    {
                      label: t("product:stockCode"),
                      value: "stockCode",
                      icon: <QuantityIcon />,
                    },
                    {
                      label: t("product:qualityQuantitity"),
                      value: "qualityQuantity",
                      icon: <QuantityAcceptedIcon />,
                    },
                    {
                      label: t("product:scrapQuantity"),
                      value: "scrapQuantity",
                      icon: <QuantityDeclineIcon />,
                    },
                    {
                      label: t("report:creationDate"),
                      value: "createdDate",
                      icon: <DeadlineIconSort />,
                    },
                  ]}
                />
                <TableOptions pageLimit={pageLimit} setPageLimit={setPageLimit} />
                <Button
                  colorType={"primary-product"}
                  size={"md"}
                  label={isSmallScreen ? t("product:newProduct") : t("product:createNewProduct")}
                  iconLeft={<PlusIcon />}
                  onClick={() => {
                    navigate("/app/product/mrp/stock/create");
                  }}
                />
              </div>
            </div>
            {responsiveSearch && (
              <div className="w-full hidden sm:flex md:flex pb-4 px-1">
                <SearchInput setSearchVal={setSearchVal} />
              </div>
            )}
            <div className="w-full min-h-[91%] max-h-[91%] border shadow-md rounded-xl border-[#D0D5DD]">
              {isLoading || isFetching ? (
                <div className="w-full flex h-full items-center justify-center">
                  <Loading primary={"#B54708"} size={44} secondary={"#fff"} />
                </div>
              ) : (
                <>
                  {pageData?.length <= 0 ? (
                    <div className="flex w-full h-full  items-center justify-center ">
                      <NoData header={t("label:noData")} button={false} />
                    </div>
                  ) : (
                    <div className="w-full h-full flex flex-col relative">
                      <>
                        <div
                          className={`w-11 h-full ${isRightArrowHidden ? "hidden duration-1000" : ""
                            } z-50 right-0 backdrop-blur-md cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-[#FFFFFF33]`}
                          onClick={() => {
                            sideScroll(document.getElementById("content-wrapper"), 55, 300, 50);
                          }}
                        >
                          <ScrollChevronIcon />
                        </div>
                        <div
                          className={`w-11 h-full ${isLeftArrowHidden ? "hidden duration-1000" : ""
                            } z-50 left-0 absolute cursor-pointer flex ml-[356px] bg-[#FFFFFF33] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
                          onClick={() => {
                            sideScroll(document.getElementById("content-wrapper"), 55, 300, -20);
                          }}
                        >
                          <ScrollChevronIcon />
                        </div>
                        {/* HEADER SATIRI */}
                        <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
                          <div className="flex items-center justify-center max-w-[356px] min-w-[356px] h-full border-r border-[#EAECF0] text-sm font-semibold text-[#344054]">
                            {t("routes:Product")}
                          </div>
                          <ScrollSyncPane>
                            <div className="flex items-center w-full h-full overflow-x-auto scrollbar-hide overflow-y-hidden">
                              <div className="min-w-[346px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200">
                                {t("product:rawMaterial")}
                              </div>
                              {tableHeadItems.map((item, index) => (
                                <div key={index} className="min-w-[346px] max-w-[346px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200">
                                  {item.title}
                                </div>
                              ))}
                            </div>
                          </ScrollSyncPane>
                        </div>
                        {/* PRODUCT SATIRI */}
                        <div className={`flex flex-col w-full ${height < 769 ? "h-[76vh]" : "h-[79vh]"}  overflow-y-auto scrollbar-hide`}>
                          {newProduct?.map((product, index) => {
                            const rawMaterialData = {
                              name: t("product:purchasing"),
                              id: product?.rawMaterial?.id,
                              source: product?.rawMaterial?.source,
                              materials: product?.rawMaterial?.type,
                              status: product?.rawMaterial?.status,
                            };
                            const filledOperations = [
                              rawMaterialData,
                              ...(product.operations || []),
                              ...Array.from({ length: Math.max(tableHeadItemsLength - (product.operations || []).length, 0) }, () => null),
                            ];
                            return (
                              <div key={index} className="flex w-full h-[132px] min-h-[132px] max-h-[132px]">
                                <div className="max-w-[356px] min-w-[356px] h-full px-4 py-2 border-r">
                                  <StockProductCard item={product} refetch={refetch} operations={filledOperations} />
                                </div>
                                <ScrollSyncPane>
                                  <div id="content-wrapper" className="w-full overflow-x-auto scrollbar-hide flex ">
                                    {filledOperations
                                      ?.sort((a, b) => a?.step - b?.step)
                                      ?.map((operations, index) => {
                                        const operationName = operations?.process?.name;
                                        const occurrences =
                                          filledOperations.slice(0, index).filter((op) => op && op?.process?.name === operationName).length + 1;
                                        return (
                                          <div key={index} className={`min-w-[346px] max-w-[346px] px-4 py-2 border-r border-b`}>
                                            {index === 0 ? (
                                              <>
                                                <RawMaterialStockCard item={product} />
                                              </>
                                            ) : (
                                              <>
                                                {operations ? (
                                                  <>
                                                    <StockOperationCard
                                                      item={operations}
                                                      occurrences={occurrences}
                                                      product={product}
                                                      refetch={refetch}
                                                      stockData={product}
                                                    />
                                                  </>
                                                ) : null}
                                              </>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </ScrollSyncPane>
                              </div>
                            );
                          })}
                        </div>
                      </>
                      <div className="z-50 border-t border-[#D0D5DD] bg-white rounded-b-xl">
                        <PaginationComponent pageNo={Number(pageNo) ? Number(pageNo) : 1} setPageNo={changePage} pageCount={pageCount} />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </ScrollSync>
        <CustomModal
          isOpen={isNewProductModal}
          setIsOpen={setIsNewProductModal}
          onClose={() => {
            setIsNewProductModal(false);
          }}
          width="fit-content"
          modalTitle={t("product:createNewProduct")}
          isBadges={true}
          badgesValue={totalCount + "/" + auth?.user?.company?.limits?.stockCard}
          badgesColor={"fill-warning"}
          badgesSize={"md"}
          children={<NewProduct t={t} setIsOpen={setIsNewProductModal} />}
        />
      </>
    </>
  );
};
export default React.memo(Stock);
