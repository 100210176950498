import React, { useState } from "react";
import CustomImage from "../CustomImage";
import NoAvatar from "../avatar/NoAvatar";
import CustomText from "../CustomText";
import { useTranslation } from "react-i18next";
import CustomModal from "../modal/CustomModal";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import AlertModal from "../alert-Modal/Alert-Modal";
import QualityControlForm from "../modal/QualityControlForm";
import { useNavigate } from "react-router-dom";
import {
  EquipmentDeleteIcon,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  OperationDetailIcon,
  ProductDetailIcon,
  QualityDetailIcon,
} from "../../assets/icons/machineIcons";
import { Tooltip } from "react-tooltip";
import EditJunctionModal from "../modal/EditJunctionModal";
import moment from "moment";
import {
  ArchiveIcon,
  DotsVerticalIcon,
  GreenClockIcon,
  PersonnelDetailIcon,
  PlusIcon,
  ProductCompleted,
  ProductControl,
  ProductIcon,
  ProductRejected,
  ProductWaiting,
  QualityGreenIcon2,
  QualityRedIcon2,
  RedClockIcon,
} from "../../assets/icons/commonIcons";

const junctionProps = {
  theme: "",
  ring: null,
  page: "",
  startDate: "",
  time: {
    timeGreen: "",
    timeRed: "",
  },
  product: {
    name: "",
    image: "",
    code: "",
    pieceGreen: 0,
    id: "",
    pieceRed: 0,
  },
  status: "",
  node: {
    name: "",
    id: "",
    image: "",
    process: "",
  },
  user: {
    image: "",
    id: "",
    name: "",
    lastName: "",
  },
  onPressNode: null,
  onPressUser: null,
  onPressProduct: null,
  onPressQuality: null,
  avatarPress: null,
};

const FinishedJunctionCard = ({
  props = junctionProps,
  step,
  junctionId,
  refetch,
  pages,
  onPressCard = null,
  allData,
  operation,
  isLoss,
}) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const navigate = useNavigate();
  const [isJunction, setIsJunction] = useState(false);
  const [editJunction, setEditJunction] = useState(false);
  const menuOptions = [
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "details",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("product:productDetail"),
      value: "operationDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("product:operationDetail"),
      value: "operation",
      icon: <OperationDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const menuOptionsPurchase = [
    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
  ];

  const onDeleted = async () => {
    await junctionService.deleteJunction(junctionId, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(junctionId);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  return (
    <>
      <div
        onClick={onPressCard}
        className="flex flex-col h-[80px] w-full relative items-center  group  justify-end "
      >
        <div className="flex absolute flex-col -top-[0px] left-[1px] items-center">
          <div className="flex flex-row items-center gap-1 mr-auto">
            <GreenClockIcon />
            <p className={`text-xs font-normal text-secondary-600 `}>
              {props?.time?.timeGreen || "--:--"}
            </p>
          </div>
          <div className="flex flex-row items-center gap-1 mr-auto">
            <RedClockIcon />
            <p className={`text-xs font-normal text-secondary-600 `}>
              {props?.time?.timeRed || "--:--"}
            </p>
          </div>
        </div>
        <div
          data-tooltip-id={`${junctionId}`}
          data-tooltip-content={`
        ${props?.user?.name + " " + props?.user?.lastName} 
        `}
          className="flex flex-col items-center justify-center absolute w-[50px] h-[50px] z-[100] bottom-7 "
        >
          <UserController
            avatarPress={props?.avatarPress}
            onPress={props?.onPressUser}
            pages={pages}
            theme={props?.theme}
            image={props?.user?.image}
            name={props?.user?.name}
            navigate={navigate}
            user={props?.user}
            startDate={props?.startDate}
            lastName={props?.user?.lastName}
          />
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <div
            className={`w-[49.5%] h-11 flex relative pl-3 pr-3 items-center flex-col border rounded border-t-4`}
            style={{
              borderColor: props.ring
                ? props?.ring
                : props?.theme === "dark"
                ? "#424242"
                : props?.isSetting === true
                ? "#DC6803"
                : "#D0D5DD",
              backgroundColor: props?.theme === "dark" ? "#141414" : "#fff",
            }}
          >
            <div
              data-tooltip-id={`${junctionId}`}
              data-tooltip-content={`
            ${props?.product?.name} 
            `}
              className="absolute bottom-8 z-[100] cursor-pointer"
              // onClick={props?.onPressProduct}
            >
              <PressedContoller
                theme={props?.theme}
                image={props?.product?.image}
              />
            </div>
            <CustomText
              text={props?.product?.code}
              numberOfLines={1}
              style={{
                marginTop: 9,
                flexDirection: "column",
                fontSize: "11px",
                fontWeight: "400",
                display: "flex",
                textAlign: "center",
                color: props.theme === "dark" ? "#D6D6D6" : "#667085",
              }}
            />
            <CustomText
              text={props?.product?.name}
              numberOfLines={1}
              style={{
                fontSize: "11px",
                flexDirection: "column",
                fontWeight: "500",
                display: "flex",
                marginTop: "-2px",
                textAlign: "center",
                color: props?.theme === "dark" ? "#D6D6D6" : "#344054",
              }}
            />
          </div>
          {!isLoss && (
            <div
              className={`z-[100] absolute flex items-center justify-center left-[46%]  w-[28px] bottom-2 h-[24px] rounded border cursor-pointer ${
                props.status === "completed"
                  ? "bg-[#17B26A] border-[#17B26A]"
                  : props.status === "waiting"
                  ? "bg-[#EAAA08] border-[#EAAA08]"
                  : props.status === "control"
                  ? "bg-[#98A2B3] border-[#98A2B3]"
                  : props.status === "rejected"
                  ? "bg-[#F04438] border-[#F04438]"
                  : props.status === "accepted"
                  ? "bg-[#17B26A] border-[#17B26A]"
                  : "bg-[#DC6803] border-[#DC6803]"
              }`}
              // onClick={props?.onPressQuality}
            >
              {props.status === "completed" ? (
                <ProductCompleted />
              ) : props.status === "waiting" ? (
                <ProductWaiting />
              ) : props.status === "control" ? (
                <ProductControl />
              ) : props.status === "rejected" ? (
                <ProductRejected />
              ) : props.status === "accepted" ? (
                <ProductCompleted />
              ) : (
                <ProductIcon />
              )}
            </div>
          )}
          <div
            className={`w-[49.5%] h-11 flex relative pl-3 pr-3 items-center flex-col border rounded  border-t-4`}
            style={{
              borderColor: props.ring
                ? props?.ring
                : props?.theme === "dark"
                ? "#424242"
                : props?.isSetting === true
                ? "#DC6803"
                : "#D0D5DD",
              backgroundColor: props?.theme === "dark" ? "#141414" : "#fff",
            }}
          >
            <div
              data-tooltip-id={`${junctionId}`}
              data-tooltip-content={`
         ${props?.node?.name} 
         `}
              className="absolute bottom-8 z-[100] cursor-pointer"
              onClick={props?.onPressNode}
            >
              <PressedContoller
                theme={props?.theme}
                image={props?.node?.image}
              />
            </div>
            <CustomText
              text={
                step
                  ? `(${step}.Op) ` + props?.node?.process
                  : props?.node?.process
              }
              numberOfLines={1}
              style={{
                marginTop: 9,
                flexDirection: "column",
                fontSize: "11px",
                fontWeight: "400",
                display: "flex",
                textAlign: "center",
                color: props.theme === "dark" ? "#D6D6D6" : "#667085",
              }}
            />
            <CustomText
              text={props?.node?.name}
              numberOfLines={1}
              style={{
                fontSize: "11px",
                flexDirection: "column",
                fontWeight: "500",
                display: "flex",
                marginTop: "-2px",
                textAlign: "center",
                color: props.theme === "dark" ? "#D6D6D6" : "#344054",
              }}
            />
          </div>

          {props.page === "pending" ? (
            <>
              <div
                className={` ${
                  pages === "purchase"
                    ? "group-hover:hidden"
                    : "group-hover:hidden"
                }  absolute flex-col top-[18px] right-[1px] items-center`}
              >
                <div className="flex flex-row items-center gap-1 ml-auto">
                  <p className={`text-xs font-normal text-secondary-600 `}>
                    {props?.product?.pieceGreen || "--"}
                  </p>
                  <ArchiveIcon />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={`${
                  pages === "purchase" ? "flex" : "group-hover:hidden"
                }   absolute flex-col -top-[0px] right-[1px] items-center`}
              >
                <div className="flex flex-row items-center gap-1 ml-auto">
                  <p className={`text-xs font-normal text-secondary-600 `}>
                    {props?.product?.pieceGreen || "0"}
                  </p>
                  <div className="ml-auto">
                    <QualityGreenIcon2 />
                  </div>
                </div>
                <div className="flex flex-row items-center gap-1 ml-auto">
                  <p className={`text-xs font-normal text-secondary-600 `}>
                    {props?.product?.pieceRed || "0"}
                  </p>
                  <div className="ml-auto">
                    <QualityRedIcon2 />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className={`hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white  ${
            pages === "purchase" ? "group-hover:flex" : "group-hover:flex"
          } right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer`}
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[32px] right-[3px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {pages === "purchase" ? (
              <>
                {menuOptionsPurchase?.map((item, index) => {
                  if (
                    operation &&
                    (index === 0 || index === 3 || index === 5 || index === 4)
                  ) {
                    return null;
                  }
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();

                        if (item.value === "quality") {
                          props?.onPressQuality();
                        }

                        setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : (
              <>
                {menuOptions?.map((item, index) => {
                  if (
                    operation &&
                    (index === 0 || index === 3 || index === 5 || index === 4)
                  ) {
                    return null;
                  }
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          // setDeleteId(id);
                        }
                        if (item?.value === "details") {
                          props?.onPressNode();
                        }
                        if (item?.value === "personnel") {
                          navigate(
                            `/app/machine/digital-twin/user-detail/${
                              props?.user?.id
                            }/${moment(props?.startDate).format("YYYY-MM-DD")}`
                          );
                        }
                        if (item?.value === "operation") {
                          navigate(
                            `/app/machine/digital-twin/operation-detail/${
                              allData?.internalOperation?.id
                            }/${moment(props?.startDate).format("YYYY-MM-DD")}`
                          );
                        }
                        if (item.value === "quality") {
                          props?.onPressQuality();
                        }
                        if (item.value === "operationDetail") {
                          props?.onPressProduct();
                        }
                        // if (
                        //   item.value === "edit" &&
                        //   allData?.metadata?.qualityStatus === "waiting"
                        // ) {
                        //   setEditJunction(true);
                        // }
                        if (item.value === "edit") {
                          setIsJunction(true);
                        }
                        setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            )}
          </div>
        )}
        <Tooltip className="z-[200]" id={`${junctionId}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <QualityControlForm
            junctionData={allData}
            refetch={refetch}
            setIsOpen={setIsJunction}
            onClose={() => {
              setIsJunction(false);
            }}
            junctionId={junctionId}
          />
        }
      />
      <CustomModal
        isOpen={editJunction}
        setIsOpen={setEditJunction}
        onClose={() => {
          setEditJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <EditJunctionModal
            junctionData={allData}
            refetch={refetch}
            setIsOpen={setEditJunction}
            onClose={() => {
              setEditJunction(false);
            }}
            junctionId={junctionId}
          />
        }
      />
    </>
  );
};

const PressedContoller = ({ image, theme }) => {
  return (
    <>
      {image ? (
        <CustomImage
          borderRadius={4}
          src={image}
          style={{
            width: 60,
            height: 45,
            borderRadius: 4,
            borderWidth: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
            borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
            backgroundColor: theme === "dark" ? "#000" : "#fff",
          }}
        />
      ) : (
        <div
          className={
            "min-w-[60px] min-h-[45px] max-w-[60px] rounded max-h-[45px] bg-[#DC6803] flex border-4 border-[#DC6803] items-center justify-center font-medium text-white text-xl"
          }
        >
          {/* {text?.name.toUpperCase()} */}
        </div>
      )}
    </>
  );
};

const UserController = ({
  onPress,
  image,
  theme,
  avatarPress,
  name,
  lastName,
  user,
  navigate,
  pages,
  startDate,
}) => {
  if (!onPress) {
    return (
      <div
        className={avatarPress && "cursor-pointer"}
        onClick={() => {
          if (avatarPress) {
            avatarPress();
          }
        }}
      >
        {pages === "purchase" ? (
          <>
            {image ? (
              <div className="cursor-pointer">
                <CustomImage
                  src={image}
                  style={{
                    width: 50,
                    height: 50,
                    flexDirection: "column",
                    borderRadius: 100,
                    display: "flex",
                    marginTop: 12,
                    borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
                    backgroundColor: theme === "dark" ? "#000" : "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="cursor-pointer">
                <NoAvatar
                  color={"#15B79E"}
                  size={50}
                  name={name}
                  lastName={lastName}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {image ? (
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate(
                    `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                      startDate
                    ).format("YYYY-MM-DD")}`
                  );
                }}
              >
                <CustomImage
                  src={image}
                  style={{
                    width: 50,
                    height: 50,
                    flexDirection: "column",
                    borderRadius: 100,
                    display: "flex",
                    marginTop: 12,
                    borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
                    backgroundColor: theme === "dark" ? "#000" : "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  navigate(
                    `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                      startDate
                    ).format("YYYY-MM-DD")}`
                  );
                }}
                className="cursor-pointer"
              >
                <NoAvatar
                  color={"#15B79E"}
                  size={50}
                  name={name}
                  lastName={lastName}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  } else {
    return (
      <button
        type="button"
        onClick={onPress}
        className="outline-none"
        style={{
          width: 50,
          height: 50,
          borderRadius: 100,
          flexDirection: "column",
          borderWidth: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderStyle: "dashed",
          display: "flex",
          borderColor: theme === "dark" ? "#424242" : "#D0D5DD",
          backgroundColor: theme === "dark" ? "#141414" : "#fff",
        }}
      >
        <PlusIcon />
      </button>
    );
  }
};

export default FinishedJunctionCard;
