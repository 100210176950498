import React, { useState } from "react";
import { PieceIcon } from "../../../assets/icons/productIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { NewDotsIcon } from "../../../components/new-cards/card-icons";
import { EquipmentDeleteIcon, NoEquIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../../components/CustomImage";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { flexProductService } from "../../../services/flex-product.service";
import { Toast } from "../../../utils/toastify/toast";

const FlexRawMaterialCard = ({ item, refetch }) => {
  const { t } = useTranslation();
  const [deleteId, setDeleteId] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const menuOptions = [
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const onDeleted = async () => {
    await flexProductService.deleteFlexRawMaterial(deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
      }
    });
  };

  return (
    <div className="flex bg-white flex-row  w-[312px] min-w-[312px] h-[112px] min-h-[112px] relative group pl-3  pt-2 border border-[#D0D5DD] rounded-lg">
      <div className="flex flex-col w-[90%]">
        <div className="flex flex-row items-center gap-x-3">
          {item?.image ? (
            <div>
              <CustomImage
                borderRadius={2}
                src={item?.image}
                style={{
                  width: 64,
                  height: 48,
                  minWidth: 64,
                  minHeight: 48,
                  borderRadius: 4,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
              <NoEquIcon />
            </div>
          )}
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-center gap-x-1 w-full">
              <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">{item?.name || "Product Name"}</p>
            </div>

            <div className="bg-[#FFFAEB] max-w-[185px] h-6 rounded px-[3px] py-1">
              <p className="text-xs font-medium max-w-[185px] truncate text-[#B54708]">
                {t("product:stock")} : {item?.stockCode || "--"}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center w-[100%] gap-x-2">
          <div className="w-[262px] h-[42px] flex ml-auto items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded  mr-3 overflow-hidden ">
            <div className="w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
              <span className="text-xxs truncate text-[#475467] font-medium ">{t("product:suplierss")}</span>
            </div>
            <div className="w-1/3 border-r border-r-secondary-200 h-full">
              <div className="px-2 py-[3px] flex flex-col items-center justify-center gap-y-[2px]">
                <p className="text-[10px] font-normal text-secondary-600">{t("tasks:total")}</p>
                <div className="flex gap-x-[2px] items-center">
                  <p className="text-xs font-medium text-secondary-600">{item?.orderedUnit ?? "0"}</p>
                  <p className="text-[#475467] text-xxs">{item?.unitType || "ad"}</p>
                </div>
              </div>
            </div>
            <div className="w-1/3 border-r border-r-secondary-200 h-full">
              <div className="px-2 py-[3px] flex flex-col items-center justify-center gap-y-[2px]">
                <p className="text-[10px] font-normal text-secondary-600">{t("product:waiting")}</p>
                <div className="flex gap-x-[2px] items-center">
                  <p className="text-xs font-medium text-secondary-600">{item?.remainingUnit ?? "0"}</p>
                  <p className="text-[#475467] text-xxs">{item?.unitType || "ad"}</p>
                </div>
              </div>
            </div>
            <div className="w-1/3  border-r-secondary-200 h-full">
              <div className="px-2 py-[3px] flex flex-col items-center justify-center gap-y-[2px]">
                <p className="text-[10px] font-normal text-secondary-600">{t("product:ready")}</p>
                <div className="flex gap-x-[2px] items-center">
                  <p className="text-xs font-medium text-secondary-600">{item?.acceptedUnit || "0"}</p>
                  <p className="text-[#475467] text-xxs">{item?.unitType || "ad"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[#F9F5FF] border-[#D0D5DD] h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
      >
        <div className="flex flex-col items-center gap-y-[14px]">
          <p className={`text-[10px] font-semibold text-[#7F56D9] -rotate-90 whitespace-nowrap`}>{t("product:flexRawMaterialCard")}</p>
        </div>
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
      >
        <NewDotsIcon />
      </div>
      {isOpen && (
        <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[20px] right-[4px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
          {menuOptions?.map((items, index) => {
            return (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (items.value === "delete") {
                    setIsAlert(true);
                    setDeleteId(item?.id);
                  }
                  setIsOpen(false);
                }}
                key={index}
                type="button"
                className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                  items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                }`}
              >
                {items?.icon}
                <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{items?.label}</p>
              </button>
            );
          })}
        </div>
      )}
      <AlertModal
        title={t("product:deleteRawMaterial")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
    </div>
  );
};

export default FlexRawMaterialCard;
