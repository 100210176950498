import { FontLoader, TextGeometry, MeshBasicMaterial, Mesh, BoxHelper } from "three";
import React from "react";
import { HELVETIKER } from "./helvetiker_regular.typeface.js";
import { timeFormatter } from "../../../../../../utils/timezoneFormatter.js";
import { EmptyPersonalDark, EmptyProductDark } from "../../../../../../assets/images/index.js";
import moment from "moment";
const fontLoader = new FontLoader();
const font = fontLoader.parse(HELVETIKER);
const defaultFontSize = 16;
const defaultColor = "#D3D5D3";

const a = {
  name: "workCard",
  prototype: "items",

  info: {
    tag: ["text"],
    title: "Work Card",
    description: "Text",
    image: require("./img.png"),
  },

  properties: {
    id: {
      label: "Id",
      type: "string",
      defaultValue: "Id",
    },
    equipmentBrand: {
      label: "Makine Adi",
      type: "string",
      defaultValue: "Machine",
    },
    equipmentModel: {
      label: "Makine Adi 2",
      type: "string",
      defaultValue: "Machine Name 2",
    },
    equipmentImage: {
      label: "Makine Resmi 1",
      type: "string",
      defaultValue: "https://cdn.upu.io/7f707c35-1fa9-4209-9c9f-f06ca812d216.png",
    },
    processName: {
      label: "Is Adi",
      type: "string",
      defaultValue: "Work",
    },
    secondImage: {
      label: "Is Resmi",
      type: "string",
      defaultValue: "Work Image",
    },
    firstPersonImage: {
      label: "Calisan Resmi 1",
      type: "string",
      defaultValue: "",
    },
    secondPersonImage: {
      label: "Calisan Resmi 2",
      type: "string",
      defaultValue: "",
    },
    firstPersonName: {
      label: "Calisan Ismi 1",
      type: "string",
      defaultValue: "",
    },
    secondPersonName: {
      label: "Makine Kodu",
      type: "string",
      defaultValue: "",
    },
    thirdPersonName: {
      label: "Calisan Ismi 3",
      type: "string",
      defaultValue: "",
    },
    ring: {
      label: "test",
      type: "string",
      defaultValue: "#63666A",
    },
    matrixCode: {
      label: "teee",
      type: "string",
      defaultValue: "0 0 0 0 0.39 0 0 0 0 0.40 0 0 0 0 0.42 0 0 0 1 0",
    },
  },

  render2D: function (element, layer, scene) {
    let id = element.properties.get("id") || "";
    let equipmentBrand = element.properties.get("equipmentBrand") || "";
    let equipmentModel = element.properties.get("equipmentModel") || "";
    let equipmentImage = element.properties.get("equipmentImage") || "";
    let processName = element.properties.get("processName") || "";
    let firstPersonName = element.properties.get("firstPersonName") || "";
    let thirdImage = element.properties.get("thirdImage") || "";
    let firstPersonImage = element.properties.get("firstPersonImage") || "";
    let secondPersonName = element.properties.get("secondPersonName") || "";
    let secondPersonImage = element.properties.get("secondPersonImage") || null;
    let thirdPersonName = element.properties.get("thirdPersonName") || "";
    let thirdPersonImage = element.properties.get("thirdPersonImage") || "";
    let workName = element.properties.get("workName") || "";
    let ring = element.properties.get("ring") || "#D3D5D3";
    let isSelected = element.properties.get("isSelected") || false;
    let eventStart = element.properties.get("eventStart") || "";
    let eventType = element.properties.get("eventType") || "";

    let currentTime = moment.duration(moment().diff(moment(element.properties.get("eventStart"))));

    let matrixCode = element.properties.get("matrixCode") || "0 0 0 0 0.39 0 0 0 0 0.40 0 0 0 0 0.42 0 0 0 1 0";
    const truncateText = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + "...";
      }
      return text;
    };
    return (
      <g
        x={-300 / 2}
        y={-300 / 2}
        width={"300"}
        height={"300"}
        fill={"none"}
        opacity={!isSelected ? 1 : 0.2}
        transform={`translate(${-355 / 2},${400 / 2}) scale(${
          // SIZE CHANGES
          isSelected ? -1.65 : -1.65
        },${isSelected ? 1.65 : 1.65}) rotate(180)`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 300 300"
      >
        {/* new card */}
        <g filter="url(#filter0_dd_2372_116320)">
          <rect
            x="3"
            y="20"
            width="200"
            height="140"
            rx="8"
            fill={localStorage.color_theme === "dark" ? "#292929" : "#fff"}
            shapeRendering="crispEdges"
            strokeWidth="3"
            id="borderBox"
            stroke={localStorage.color_theme == "dark" ? "#424242" : ring}
          />

          <svg
            width="200"
            height="22"
            viewBox="0 0 200 22"
            fill="none"
            // xmlns="http://www.w3.org/2000/svg"
            x={3}
            y={138}
          >
            <path d="M0 0H200V14C200 18.4183 196.418 22 192 22H8C3.58173 22 0 18.4183 0 14V0Z" fill={ring} />
          </svg>

          <defs>
            <rect x={9} y={-2} id="personal2" width="48" height="48" rx="25" fill={localStorage.color_theme === "dark" ? "#292929" : "#fff"} />
            <clipPath id="fourth">
              <use href="#personal2" />
            </clipPath>
          </defs>
          <use  href="#personal2" strokeWidth="1" stroke={localStorage.color_theme == "dark" ? "#FFF" : "#98A2B3"} />

          {secondPersonImage == "Empty" ? (
            <image
              objectFit="contain"
              x="9"
              y="-2"
              width="48"
              height="48"
              rx="30"
              stroke={localStorage.color_theme == "dark" ? "#292929" : "#FFF"}
              fill={localStorage.color_theme == "dark" ? "#292929" : "#FFF"}
              href={`${"https://cdn.upu.io/fb3590e7-ebe9-41e6-b4f0-3d3afb5015b0"}`}
              strokeWidth="4"
              clipPath="url(#fourth)"
            />
          ) : (
            <image
              objectFit="contain"
              x="9"
              y="-2"
              width="48"
              height="48"
              rx="30"
              stroke={localStorage.color_theme == "dark" ? "#292929" : "#FFF"}
              fill={localStorage.color_theme == "dark" ? "#292929" : "#FFF"}
              href={`${secondPersonImage != null ? secondPersonImage : "https://cdn.upu.io/fb3590e7-ebe9-41e6-b4f0-3d3afb5015b0"}`}
              strokeWidth="4"
              clipPath="url(#fourth)"
            />
          )}

          <g filter="url)">
            <defs z={5}>
              <rect x={63} y={-6} id="rect" fill={localStorage.color_theme == "dark" ? "#292929" : "#FFF"} width="80" height="60" rx="4" />
              <clipPath id="clip">
                <use href="#rect" />
              </clipPath>
            </defs>
            <use
              href="#rect"
              strokeWidth="1"
              stroke={"#98A2B3"}
              z={10}
              // fill="white"
            />
            <image
              objectFit="contain"
              z={5}
              href={`${equipmentImage}`}
              // equipment image
              height="60"
              x={63}
              y={-6}
              width="80"
              rx={4}
              fill={localStorage.color_theme == "dark" ? "#292929" : "#FFF"}
              clipPath="url(#clip)"
            />
          </g>
          <g filter="">
            <defs>
              <rect x={148} y={-2} id="personal" width="48" height="48" rx="100" fill={localStorage.color_theme == "dark" ? "#292929" : "#FFF"} />
              <clipPath id="third">
                <use href="#personal" />
              </clipPath>
            </defs>
            <use href="#personal" strokeWidth="1" stroke={"#98A2B3"} />
            {firstPersonImage == "Empty" ? (
              <>
                <rect x="148" y="2" width="48" height="48" rx="30" fill={"#F2f4f7"} />
                <text
                  x="172"
                  y="28"
                  alignmentBaseline="middle"
                  textAnchor="middle"
                  fontWeight={600}
                  width="48"
                  height="48"
                  fontSize={20}
                  fill="#667085"
                  z={100}
                  transform="translate(0,0)"
                >
                  {firstPersonName?.split(" - ")[0]?.charAt(0) + firstPersonName?.split(" - ")[1]?.charAt(0) || "--"}
                </text>
              </>
            ) : (
              <>
                <image
                  x="148"
                  y="-2"
                  width="48"
                  height="48"
                  rx="30"
                  stroke={localStorage.color_theme == "dark" ? "#292929" : "#FFF"}
                  fill={localStorage.color_theme == "dark" ? "#292929" : "#FFF"}
                  href={`${firstPersonImage || "https://cdn.upu.io/98a2229f-7103-4faf-b7e6-30e90c364efa"}`}
                  strokeWidth="4"
                  clipPath="url(#third)"
                />
              </>
            )}
          </g>
          <g filter="url(#filter4_d_2372_116320)">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              x="10"
              y={154}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM5.5 2C5.5 1.72386 5.27614 1.5 5 1.5C4.72386 1.5 4.5 1.72386 4.5 2V5C4.5 5.18939 4.607 5.36252 4.77639 5.44721L6.77639 6.44721C7.02338 6.57071 7.32372 6.4706 7.44721 6.22361C7.57071 5.97662 7.4706 5.67628 7.22361 5.55279L5.5 4.69098V2Z"
                fill={localStorage.color_theme == "dark" ? "#424242" : "#fff"}
              />
            </svg> */}

            <text
              x="10"
              y="150"
              alignmentBaseline="middle"
              fontWeight={600}
              width={150}
              height={28}
              fontSize={14}
              fill={localStorage.color_theme == "dark" ? "#424242" : "#fff"}
              z={100}
              transform="translate(0,0)"
            >
              {secondPersonName}
              {/* {`${currentTime.hours()}h ${currentTime.minutes()}m ${currentTime.seconds()}s`} */}
            </text>
          </g>

          <text
            x="103"
            y="74"
            alignmentBaseline="middle"
            textAnchor="middle"
            fontWeight={500}
            width={188}
            height={17}
            fontSize={14}
            fill={localStorage.color_theme == "dark" ? "#FFF" : "#101828"}
            z={100}
          >
            {truncateText(equipmentBrand + " " + equipmentModel, 20)}
          </text>

          <text
            x="103"
            y="96"
            alignmentBaseline="middle"
            textAnchor="middle"
            fontWeight={400}
            width={188}
            height={17}
            max={20}
            fontSize={12}
            overflow={"hidden"}
            fill={localStorage.color_theme == "dark" ? "#FAFAFA" : "#475467"}
            z={100}
            transform="translate(0,0)"
            className="truncate"
          >
            {truncateText(firstPersonName || "Tanımsız Personel", 23)}
          </text>

          {eventType == "losses" ? (
            <text
              x="103"
              y="116"
              alignmentBaseline="middle"
              textAnchor="middle"
              fontWeight={400}
              width={188}
              height={17}
              fontSize={12}
              fill={localStorage.color_theme == "dark" ? "#FAFAFA" : "#475467"}
              z={100}
              transform="translate(0,0)"
            >
              {truncateText(`kayıp süresi:${currentTime.hours()}h ${currentTime.minutes()}m ${currentTime.seconds()}s`, 35)}
            </text>
          ) : (
            <text
              x="103"
              y="116"
              alignmentBaseline="middle"
              textAnchor="middle"
              fontWeight={400}
              width={188}
              height={17}
              fontSize={12}
              fill={localStorage.color_theme == "dark" ? "#FAFAFA" : "#475467"}
              z={100}
              transform="translate(0,0)"
            >
              {truncateText(workName && workName != "null" ? workName : "Tanımsız İş", 25)}
            </text>
          )}

          <g filter="">
            {moment(timeFormatter(eventStart)?.formatted)?.format("HH:mm") != "Invalid date" ? (
              <svg x={140} y={143} xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
                <path
                  d="M0.916504 1.91062C0.916504 1.34411 0.916504 1.06085 1.03462 0.90471C1.13753 0.768683 1.29481 0.684508 1.46507 0.674342C1.66051 0.662673 1.89619 0.819795 2.36756 1.13404L8.50163 5.22342C8.89112 5.48308 9.08586 5.61291 9.15372 5.77655C9.21305 5.91961 9.21305 6.0804 9.15372 6.22346C9.08586 6.3871 8.89112 6.51693 8.50163 6.77658L2.36756 10.866C1.89619 11.1802 1.66051 11.3373 1.46507 11.3257C1.29481 11.3155 1.13753 11.2313 1.03462 11.0953C0.916504 10.9392 0.916504 10.6559 0.916504 10.0894V1.91062Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="1.16667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <></>
            )}

            {moment(timeFormatter(eventStart)?.formatted)?.format("HH:mm") != "Invalid date" ? (
              <text
                x="174"
                y="150"
                alignmentBaseline="middle"
                textAnchor="middle"
                fontWeight={600}
                width={188}
                height={17}
                fontSize={14}
                fill={localStorage.color_theme == "dark" ? "#FAFAFA" : "#FFF"}
                z={100}
                transform="translate(0,0)"
              >
                {truncateText(`${moment(timeFormatter(eventStart)?.formatted)?.format("HH:mm")}`, 45)}
              </text>
            ) : (
              <text
                x="166"
                y="150"
                alignmentBaseline="middle"
                textAnchor="middle"
                fontWeight={600}
                width={188}
                height={17}
                fontSize={14}
                fill={localStorage.color_theme == "dark" ? "#FAFAFA" : "#FFF"}
                z={100}
                transform="translate(0,0)"
              >
                No Event
              </text>
            )}
          </g>

          <defs>
            <filter id="filter0_dd_2372_116320" x="0" y="-7" width="206" height="182" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2372_116320" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.15 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_2372_116320" result="effect2_dropShadow_2372_116320" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2372_116320" result="shape" />
            </filter>
            <filter id="filter1_dd_2372_116320" x="6" y="6" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2372_116320" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_2372_116320" result="effect2_dropShadow_2372_116320" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2372_116320" result="shape" />
            </filter>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use transform="translate(-0.166667) scale(0.00416667)" />
            </pattern>
            <filter id="filter2_dd_2372_116320" x="58" y="-8" width="90" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0196078 0 0 0 0 0.309804 0 0 0 0 0.192157 0 0 0 0.06 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2372_116320" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0196078 0 0 0 0 0.309804 0 0 0 0 0.192157 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_2372_116320" result="effect2_dropShadow_2372_116320" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2372_116320" result="shape" />
            </filter>
            <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use transform="scale(0.00166667 0.00222222)" />
            </pattern>
            <filter id="filter3_dd_2372_116320" x="146" y="6" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2372_116320" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_2372_116320" result="effect2_dropShadow_2372_116320" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2372_116320" result="shape" />
            </filter>
            <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use transform="translate(-0.0399006 -0.0778813) scale(0.003125 0.00312827)" />
            </pattern>
            <filter id="filter4_d_2372_116320" x="7" y="135" width="192" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0196078 0 0 0 0 0.309804 0 0 0 0 0.192157 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2372_116320" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2372_116320" result="shape" />
            </filter>
            <image id="image0_2372_116320" width="320" height="240" />
            <image id="image1_2372_116320" width="600" height="450" />
            <image id="image2_2372_116320" width="320" height="480" />
          </defs>
        </g>

        {/* new card end */}
      </g>
    );
  },

  render3D: function (element, layer, scene) {
    let text = element.properties.get("text") || "";
    let size = element.properties.get("fontSize") || defaultFontSize;
    let textHorizontalPadding = defaultFontSize;
    let width = (text.length - text.length / 2) * size + textHorizontalPadding;
    let color = element.properties.get("color") || defaultColor;

    let mesh = new Mesh(new TextGeometry(text, { size, height: 1, font }), new MeshBasicMaterial({ color }));

    if (element.selected) {
      let box = new BoxHelper(mesh, 0x99c3fb);
      box.material.linewidth = 2;
      box.material.depthTest = false;
      box.renderOrder = 1000;
      mesh.add(box);
    }

    mesh.position.y += element.properties.getIn(["altitude", "length"]);
    mesh.position.x -= width / 2;

    return Promise.resolve(mesh);
  },
};
export default a;
