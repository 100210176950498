import React, { useState } from "react";
import CustomImage from "../CustomImage";
import { NoEquIcon } from "../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { EquipmentEditIcon, NewDotsIcon } from "../new-cards/card-icons";
import { useNavigate } from "react-router-dom";
import { RawIcon } from "../../pages/product/cards/WipRawMaterialCard";
import { SupplyIcon } from "../../assets/icons/stepsIcons";
import CustomModal from "../modal/CustomModal";
import RelatedProductsModal from "../modal/RelatedProductsModal";
import EditRequestQuantityModal from "../modal/EditRequestQuantityModal";
import MaterialCompletePurchaseModal from "../modal/MaterialCompletePurchaseModal";

const MaterialSupplyPlanModalRawCard = ({ item, onClick, selectedLeftCardId, isModal,refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const [relatedOpen,setRelatedOpen] = useState(false)
  const [editRequestOpen,setEditRequestOpen] = useState(false)
  const [completePurchase,setCompletePurchase] = useState(false)


  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];

  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };

  const totalPlannedUnits = item?.plannedJunctions?.reduce((acc, junction) => acc + (junction?.status === "active" ? junction?.plannedUnit || 0 : 0), 0);

  const menuOptions = [
    { label: t("product:rawMaterialDetail"), value: "rawMaterialDetail", icon: <RawMaterialDetailIcon /> },
    {
      label: t("product:relatedProducts"),
      value: "product",
      icon: <ProductIconsss />,
    },
    {
      label: t("product:editRequestQuantity"),
      value: "editRequest",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:completePurchase"),
      value: "completePurchase",
      icon: <CompleteIcons />,
    },
  ];


  return (
    <>
      <div
        onClick={onClick}
        className={`flex bg-white flex-row mr-4 w-[324px] min-w-[324px] h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border ${selectedLeftCardId === item?.id || selectedLeftCardId === item?.rawMaterial?.id ? "shadow-ring-product border-[#FEC84B]" : "border-[#D0D5DD]"
          } rounded-lg cursor-grab `}
      >
        <div className="flex flex-col w-[93%]">
          <div className="flex flex-row items-center gap-x-3">
            {item?.type === "semi_internal_product" ? (
              <>
                {item?.productInternalOperation ? (
                  <>
                    {item?.productInternalOperation?.product?.image ? (
                      <div
                        data-tooltip-id={`${item?.id}`}
                        data-tooltip-place="right"
                        data-tooltip-content={`${t("product:productName")}: ${item?.productInternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productInternalOperation?.product?.stockCode
                          }`}
                      >
                        <CustomImage
                          borderRadius={2}
                          src={item?.productInternalOperation?.product?.image}
                          style={{
                            width: 64,
                            height: 48,
                            minWidth: 64,
                            minHeight: 48,
                            borderRadius: 4,
                            borderWidth: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            display: "flex",
                            borderColor: "#0000001A",
                            backgroundColor: "#fff",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        data-tooltip-id={`${item?.id}`}
                        data-tooltip-place="right"
                        data-tooltip-content={`${t("product:productName")}: ${item?.productInternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productInternalOperation?.product?.stockCode
                          }`}
                        className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                      >
                        <NoEquIcon />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {item?.productExternalOperation?.product?.image ? (
                      <div
                        data-tooltip-id={`${item?.id}`}
                        data-tooltip-place="right"
                        data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                          }`}
                      >
                        <CustomImage
                          borderRadius={2}
                          src={item?.productExternalOperation?.product?.image}
                          style={{
                            width: 64,
                            height: 48,
                            minWidth: 64,
                            minHeight: 48,
                            borderRadius: 4,
                            borderWidth: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            display: "flex",
                            borderColor: "#0000001A",
                            backgroundColor: "#fff",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        data-tooltip-id={`${item?.id}`}
                        data-tooltip-place="right"
                        data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                          }`}
                        className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                      >
                        <NoEquIcon />
                      </div>
                    )}
                  </>
                )}
              </>
            ) : item?.rawMaterial ? (
              <>
                {item?.rawMaterial?.image ? (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stock")}: ${item?.rawMaterial?.stockCode}`}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={item?.rawMaterial?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stock")}: ${item?.rawMaterial?.stockCode}`}
                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                  >
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : item?.product?.image ? (
              <div
                data-tooltip-id={`${item?.id}`}
                data-tooltip-place="right"
                data-tooltip-content={`${t("product:productName")}: ${item?.product?.name} - ${t("product:stock")}: ${item?.product?.stockCode}`}
              >
                <CustomImage
                  borderRadius={2}
                  src={item?.product?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : item?.productExternalOperation ? (
              <>
                {item?.productExternalOperation?.product?.image ? (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                      }`}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={item?.productExternalOperation?.product?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                      }`}
                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                  >
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : item?.purchaseRequest?.rawMaterial?.image ? (
              <>
                {item?.purchaseRequest?.rawMaterial?.image ? (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.purchaseRequest?.rawMaterial?.name} - ${t("product:stock")}: ${item?.purchaseRequest?.rawMaterial?.stockCode
                      }`}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={item?.purchaseRequest?.rawMaterial?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                      }`}
                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                  >
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : item?.purchaseRequest?.productExternalOperation?.product?.image ? (
              <>
                {item?.purchaseRequest?.productExternalOperation?.product?.image ? (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.purchaseRequest?.productExternalOperation?.product?.name} - ${t(
                      "product:stock"
                    )}: ${item?.purchaseRequest?.productExternalOperation?.product?.stockCode}`}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={item?.purchaseRequest?.productExternalOperation?.product?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                      }`}
                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                  >
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : item?.purchaseRequest?.productInternalOperation?.product?.image ? (
              <>
                {item?.purchaseRequest?.productInternalOperation?.product?.image ? (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.purchaseRequest?.productInternalOperation?.product?.name} - ${t(
                      "product:stock"
                    )}: ${item?.purchaseRequest?.productInternalOperation?.product?.stockCode}`}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={item?.purchaseRequest?.productInternalOperation?.product?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                      }`}
                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                  >
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : (
              <div
                data-tooltip-id={`${item?.id}`}
                data-tooltip-place="right"
                data-tooltip-content={`${t("product:productName")}: ${item?.product?.name} - ${t("product:stock")}: ${item?.product?.stockCode}`}
                className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
              >
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1  w-full">
                <p className="font-semibold w-[175px] text-secondary-900 text-base truncate">
                  {item?.productInternalOperation
                    ? item?.productInternalOperation?.product?.name || "Product Name"
                    : item?.productExternalOperation
                      ? item?.productExternalOperation?.product?.name || "Product Name"
                      : item?.product
                        ? item?.product?.name || "Product Name"
                        : item?.rawMaterial
                          ? item?.rawMaterial?.name || "Product Name"
                          : item?.purchaseRequest?.rawMaterial
                            ? item.purchaseRequest?.rawMaterial?.name || "Product Name"
                            : item?.purchaseRequest?.productInternalOperation
                              ? item?.purchaseRequest?.productInternalOperation?.product?.name || "Product Name"
                              : item?.purchaseRequest?.productExternalOperation
                                ? item?.purchaseRequest?.productExternalOperation?.product?.name || "Product Name"
                                : "Product Name"}
                </p>
              </div>
              <div className={`h-6 max-w-[175px] flex px-1 py-[3px] ml-[2px] items-center justify-center bg-[#FFFAEB]`}>
                <p className={`text-sm font-normal max-w-[175px] truncate text-[#B54708]`}>
                  {item?.productInternalOperation
                    ? `${item?.productInternalOperation?.product?.stockCode || "--"} (${item?.productInternalOperation?.step}.Op)`
                    : item?.productExternalOperation
                      ? `${item?.productExternalOperation?.product?.stockCode || "--"} (${item?.productExternalOperation?.step}.Op)`
                      : item?.product
                        ? item?.product?.stockCode
                        : item?.rawMaterial
                          ? `${t("product:stock")} : ${item?.rawMaterial?.stockCode}`
                          : item?.purchaseRequest?.rawMaterial
                            ? item?.purchaseRequest?.rawMaterial?.stockCode
                            : item?.purchaseRequest?.productInternalOperation
                              ? item?.purchaseRequest?.productInternalOperation?.product?.stockCode
                              : item?.purchaseRequest?.productExternalOperation
                                ? item?.purchaseRequest?.productExternalOperation?.product?.stockCode
                                : "--"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-1 w-[100%]">
            <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:inStock")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">
                  {item?.rawMaterial
                    ? (Number(item?.rawMaterial?.quantity) >= 100000
                      ? (Number(item?.rawMaterial?.quantity) / 1000)?.toFixed(0) + "B"
                      : Number(item?.rawMaterial?.quantity) % 1 === 0
                        ? Number(item?.rawMaterial?.quantity)
                        : Number(item?.rawMaterial?.quantity)?.toFixed(2)) || "0"
                    : item?.product
                      ? (Number(item?.product?.qualityQuantity) >= 100000
                        ? (Number(item?.product?.qualityQuantity) / 1000).toFixed(0) + "B"
                        : Number(item?.product?.qualityQuantity) % 1 === 0
                          ? Number(item?.product?.qualityQuantity)
                          : Number(item?.product?.qualityQuantity)?.toFixed(2)) || "0"
                      : item?.productInternalOperation
                        ? (Number(item?.productInternalOperation?.product?.qualityQuantity) >= 100000
                          ? (Number(item?.productInternalOperation?.product?.qualityQuantity) / 1000)?.toFixed(0) + "B"
                          : Number(item?.productInternalOperation?.product?.qualityQuantity) % 1 === 0
                            ? Number(item?.productInternalOperation?.product?.qualityQuantity)
                            : Number(item?.productInternalOperation?.product?.qualityQuantity)?.toFixed(2)) || "0"
                        : item?.productExternalOperation
                          ? (Number(item?.productExternalOperation?.product?.qualityQuantity) >= 100000
                            ? (Number(item?.productExternalOperation?.product?.qualityQuantity) / 1000)?.toFixed(0) + "B"
                            : Number(item?.productExternalOperation?.product?.qualityQuantity) % 1 === 0
                              ? Number(item?.productExternalOperation?.product?.qualityQuantity)
                              : Number(item?.productExternalOperation?.product?.qualityQuantity).toFixed(2)) || "0"
                          : item?.purchaseRequest?.productInternalOperation?.product
                            ? (Number(item?.purchaseRequest?.productInternalOperation?.product?.qualityQuantity) >= 100000
                              ? (Number(item?.purchaseRequest?.productInternalOperation?.product?.qualityQuantity) / 1000)?.toFixed(0) + "B"
                              : Number(item?.purchaseRequest?.productInternalOperation?.product?.qualityQuantity) % 1 === 0
                                ? Number(item?.purchaseRequest?.productInternalOperation?.product?.qualityQuantity)
                                : Number(item?.purchaseRequest?.productInternalOperation?.product?.qualityQuantity)?.toFixed(2)) || "0"
                            : item?.purchaseRequest?.productExternalOperation?.product
                              ? (Number(item?.purchaseRequest?.productExternalOperation?.product?.qualityQuantity) >= 100000
                                ? (Number(item?.purchaseRequest?.productExternalOperation?.product?.qualityQuantity) / 1000).toFixed(0) + "B"
                                : Number(item?.purchaseRequest?.productExternalOperation?.product?.qualityQuantity) % 1 === 0
                                  ? Number(item?.purchaseRequest?.productExternalOperation?.product?.qualityQuantity)
                                  : Number(item?.purchaseRequest?.productExternalOperation?.product?.qualityQuantity).toFixed(2)) || "0"
                              : item?.purchaseRequest?.rawMaterial
                                ? (Number(item?.purchaseRequest?.rawMaterial?.quantity) >= 100000
                                  ? (Number(item?.purchaseRequest?.rawMaterial?.quantity) / 1000).toFixed(0) + "B"
                                  : Number(item?.purchaseRequest?.rawMaterial?.quantity) % 1 === 0
                                    ? Number(item?.purchaseRequest?.rawMaterial?.quantity)
                                    : Number(item?.purchaseRequest?.rawMaterial?.quantity).toFixed(2)) || ""
                                : ""}
                </p>
              </div>
            </div>
            <div className="flex flex-col h-[42px] min-w-[28%] w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:activeRequest")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">
                  {item?.orderedUnit
                    ? (Number(item?.orderedUnit) >= 100000
                      ? (Number(item?.orderedUnit) / 1000).toFixed(0) + "B"
                      : Number(item?.orderedUnit) % 1 === 0
                        ? Number(item?.orderedUnit)
                        : Number(item?.orderedUnit).toFixed(2)) || "0"
                    : item?.purchaseRequest?.orderedUnit
                      ? (Number(item?.purchaseRequest?.orderedUnit) >= 100000
                        ? (Number(item?.purchaseRequest?.orderedUnit) / 1000).toFixed(0) + "B"
                        : Number(item?.purchaseRequest?.orderedUnit) % 1 === 0
                          ? Number(item?.purchaseRequest?.orderedUnit)
                          : Number(item?.purchaseRequest?.orderedUnit).toFixed(2)) || "0"
                      : "0"}
                </p>
              </div>
            </div>
            <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
              <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                <span className="text-xxs truncate text-[#475467] font-medium ">{"MS"}</span>
              </div>
              <div className="flex flex-col w-full items-start">
                <div
                  data-tooltip-id={`${item?.id}`}
                  data-tooltip-place="left"
                  data-tooltip-content={`${t("product:remainings")}: ${item?.purchaseRequest
                    ? (item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit)?.toFixed(2)
                    : (item?.orderedUnit - item?.plannedUnit)?.toFixed(2)
                    }`}
                  className="flex flex-row items-center w-full border-b px-1"
                >
                  <p className="text-xxs font-normal text-secondary-600">{t("product:remainings")}</p>
                  <p className="text-xs font-medium ml-auto text-secondary-600">
                    {item?.purchaseRequest
                      ? (Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit) <= -10000 ||
                        Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit) >= 10000
                        ? (Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit) / 1000).toFixed(2) + "B"
                        : Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit) % 1 === 0
                          ? Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit)
                          : Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit).toFixed(2)) || "0"
                      : (Number(item?.orderedUnit - item?.plannedUnit) <= -10000 || Number(item?.orderedUnit - item?.plannedUnit) >= 10000
                        ? (Number(item?.orderedUnit - item?.plannedUnit) / 1000).toFixed(2) + "B"
                        : Number(item?.orderedUnit - item?.plannedUnit) % 1 === 0
                          ? Number(item?.orderedUnit - item?.plannedUnit)
                          : Number(item?.orderedUnit - item?.plannedUnit).toFixed(2)) || "0"}
                  </p>
                </div>
                <div
                  data-tooltip-id={`${item?.id}`}
                  data-tooltip-place="left"
                  data-tooltip-content={`${t("product:plannedNew")}: ${item?.purchaseRequest ? item?.purchaseRequest?.plannedUnit?.toFixed(2) : item?.plannedUnit?.toFixed(2)
                    }`}
                  className="flex flex-row items-center w-full px-1"
                >
                  <p className="text-xxs font-normal text-secondary-600">{t("product:plannedNew")}</p>
                  <p className="text-xs font-medium ml-auto text-secondary-600">
                    {item?.purchaseRequest
                      ? (Number(item?.purchaseRequest?.plannedUnit) <= -10000 || Number(item?.purchaseRequest?.plannedUnit) >= 10000
                        ? (Number(item?.purchaseRequest?.plannedUnit) / 1000).toFixed(2) + "B"
                        : Number(item?.purchaseRequest?.plannedUnit) % 1 === 0
                          ? Number(item?.purchaseRequest?.plannedUnit)
                          : Number(item?.purchaseRequest?.plannedUnit).toFixed(2)) || "0"
                      : (Number(item?.plannedUnit) <= -10000 || Number(item?.plannedUnit) >= 10000
                        ? (Number(item?.plannedUnit) / 1000).toFixed(2) + "B"
                        : Number(item?.plannedUnit) % 1 === 0
                          ? Number(item?.plannedUnit)
                          : Number(item?.plannedUnit).toFixed(2)) || "0"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`absolute right-0 bg-[#F2F4F7] bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
        >
          {item?.productInternalOperation ? (
            <p className="text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90">
              {t("product:semiProduct")}
              {/* ({`${item?.productInternalOperation?.step}.Op`}) */}
            </p>
          ) : item?.productExternalOperation ? (
            <p className="text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90">
              {t("product:semiProduct")}
              {/* ({`${item?.productExternalOperation?.step}.Op`}) */}
            </p>
          ) : item?.product ? (
            <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
              {t("product:productss")} {item?.rawMaterial ? `(${getUnitLabel(item?.rawMaterial?.unitType)})` : `(${t("product:pcs")})`}
            </p>
          ) : item?.rawMaterial ? (
            <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
              {t("product:rMaterial")} ({getUnitLabel(item?.rawMaterial?.unitType)})
            </p>
          ) : item?.purchaseRequest?.rawMaterial ? (
            <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
              {t("product:rMaterial")} ({getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)})
            </p>
          ) : (
            "--"
          )}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          className={`absolute min-h-[24px] ${isModal === true ? "hidden" : "flex"} max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer`}
        >
          <NewDotsIcon />
        </div>
        {isOpen && (
          <div className="absolute w-[230px] p-1 bg-white right-3 top-[16px] border border-secondary-200 rounded-lg shadow-sm flex-col z-[160] hidden group-hover:flex py-1">
            {menuOptions?.map((items, index) => {
              return (
                <>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (items.value === "product") {
                        setRelatedOpen(true)
                      } else if (items.value === "rawMaterialDetail") {
                        navigate(`/app/product/mrp/stock/raw-material/detail/${item?.rawMaterial?.id}`);
                      } else if (items.value === "editRequest") {
                        setEditRequestOpen(true)
                      }  else if (items.value === "completePurchase") {
                        setCompletePurchase(true)
                      }
                      setIsOpen(false);
                    }}
                    key={index}
                    type="button"
                    className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                  >
                    {items?.icon}
                    <p className={`font-medium text-sm ${index == 3 ? "text-[#D92D20]" : "text-secondary-700"}  ml-1`}>
                      {index === "taskList" ? "" : items?.label}
                    </p>
                  </button>
                </>
              );
            })}
          </div>
        )}
        <Tooltip className="z-[120]" id={`${item?.id}`} />
      </div>
      <CustomModal
        isOpen={relatedOpen}
        setIsOpen={setRelatedOpen}
        onClose={() => {
          setRelatedOpen(false);
        }}
        width={500}
        modalTitle={t("product:relatedProducts")}
        subTitle={t("product:theProductRelated")}
        children={
          <RelatedProductsModal
            onClose={() => {
              setRelatedOpen(false);
            }}
            item={item}
          />
        }
      />
       <CustomModal
        isOpen={editRequestOpen}
        setIsOpen={setEditRequestOpen}
        onClose={() => {
          setEditRequestOpen(false);
        }}
        width={430}
        modalTitle={t("product:editRequestQuantity")}
        subTitle={t("product:updateTheOpen")}
        children={
          <EditRequestQuantityModal
            onClose={() => {
              setEditRequestOpen(false);
            }}
            item={item}
            refetch={refetch}
          />
        }
      />
       <CustomModal
        isOpen={completePurchase}
        setIsOpen={setCompletePurchase}
        onClose={() => {
          setCompletePurchase(false);
        }}
        width={500}
        children={
          <MaterialCompletePurchaseModal
            onClose={() => {
              setCompletePurchase(false);
            }}
            item={item}
            refetchPage={refetch}
          />
        }
      />
    </>
  );
};

export default MaterialSupplyPlanModalRawCard;

export const RawMaterialDetailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M6.19046 12.4006C6.19046 13.6521 5.17594 14.6666 3.92446 14.6666C2.67297 14.6666 1.65845 13.6521 1.65845 12.4006C1.65845 11.1492 2.67297 10.1346 3.92446 10.1346C5.17594 10.1346 6.19046 11.1492 6.19046 12.4006ZM6.19046 12.4006C6.19046 13.6521 7.20499 14.6666 8.45647 14.6666C9.70795 14.6666 10.7225 13.6521 10.7225 12.4006C10.7225 11.1492 9.70795 10.1346 8.45647 10.1346C7.20499 10.1346 6.19046 11.1492 6.19046 12.4006ZM5.03805 6.52192C4.37146 6.91641 3.92446 7.64271 3.92446 8.47342C3.92446 9.7249 4.93898 10.7394 6.19046 10.7394C7.44195 10.7394 8.45647 9.7249 8.45647 8.47342C8.45647 7.22193 7.44195 6.20741 6.19046 6.20741C5.76969 6.20741 5.3757 6.3221 5.03805 6.52192ZM5.03805 6.52192L9.9158 2.36548C10.5943 1.78727 11.6071 1.8427 12.2185 2.49151V2.49151C12.7922 3.10032 12.8281 4.03932 12.3024 4.69011L12.0607 4.98938M8.06895 9.74114L12.0607 4.98938M10.4364 13.5037L14.0877 7.45395C14.8478 6.06043 13.8228 4.45961 12.0607 4.98938" stroke="#667085" stroke-width="1.33" />
    </svg>
  );
};

export const ProductIconsss = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M14.1019 7.26598L8.09145 13.2764C6.72462 14.6432 4.50854 14.6432 3.1417 13.2764C1.77487 11.9095 1.77487 9.69347 3.1417 8.32664L9.15211 2.31623C10.0633 1.40501 11.5407 1.40501 12.4519 2.31623C13.3632 3.22745 13.3632 4.70484 12.4519 5.61606L6.67724 11.3908C6.22163 11.8464 5.48293 11.8464 5.02732 11.3908C4.57171 10.9352 4.57171 10.1965 5.02732 9.74085L10.0949 4.67325" stroke="#667085" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export const CompleteIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M2 5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.0799 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2Z" stroke="#F04438" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
