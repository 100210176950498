import React, { useEffect, useState } from "react";
import { Button, CustomModal } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import Badgets from "../../components/buttons/Badgets";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
} from "../../assets/icons/PersonIcons";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import EndDatePicker from "../person/components/EndDatePicker";
import { timeFormatter } from "../../utils/timezoneFormatter";
import useWindowDimensions from "../../hooks/useWindowDimension";
import SearchInput from "../../components/inputs/SearchInput";

import { junctionService } from "../../services/junction.service";
import AllOperationsCard from "./components/AllOperationsCard";
import generateFileUrl from "../../utils/generateFileUrl";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import diacritics from "diacritics";
import SupplyEventCard from "../../components/new-cards/junction-card/SupplyEventCard";
import SupplyActiveEventCard from "../../components/new-cards/junction-card/SupplyActiveEventCard";
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "../../components/new-cards/card-icons";
import { SwitchIcon, TransformIcon } from "../../assets/icons/commonIcons";
import { QualityTab } from "./quality/components";
import { Toast } from "../../utils/toastify/toast";
import AllOperationsNewCard from "./components/AllOperationsNewCard";
import { flexJunctionService } from "../../services/flex-junction.service";
import { FilterIcon } from "../../assets/icons/productIcons";
import ServiceSupplyTab from "./serviceSupply/ServiceSupplyTab";
import ServiceSupplyPlanModal from "./modals/ServiceSupplyPlanModal";
import SendToSupplierModal from "./modals/SendToSupplierModal";
import ServiceSupplyReceiveModal from "./modals/ServiceSupplyReceiveModal";
import ServiceSupplyQualityControlForm from "./modals/ServiceSupplyQualityControlForm";
import ServiceSupplyReceivedEventCard from "../../components/new-cards/service-supply/components/ServiceSupplyReceivedEventCard";
import ServiceSupplierQualityApliedEventCard from "../../components/new-cards/service-supply/components/ServiceSupplierQualityApliedEventCard";
import ServiceSupplySsPlannedEventCard from "../../components/new-cards/service-supply/components/ServiceSupplySsPlannedEventCard";
import ServiceSupplySupplierEventCard from "../../components/new-cards/service-supply/components/ServiceSupplySupplierEventCard";
import { serviceSupplyService } from "../../services/service-supply.service";
import QualityServiceSupplyAllCard from "./components/QualityServiceSupplyAllCard";
import { CloseIcon } from "../../assets/icons/departmentIcon";

function QualityExternal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const { settings, auth } = useStore();
  const [visible, setVisible] = useState(false);
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [junctionType, setJunctionType] = useState("external");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [operations, setOperations] = useState([]);
  const [isPastDay, setIsPastDay] = useState(false);
  const [hiddenPreviousPending, setHiddenPreviousPending] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState("all");
  const [selectOperationData, setSelectOperationData] = useState(null);
  const [draggedJunction, setDraggedJunction] = useState(null);
  const [finishPurhcaseModal, setFinishPurhcaseModal] = useState(false);
  const [isOpenPlanningModalData, setIsOpenPlanningModalData] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [purchaseQualityForm, setPurchaseQualityForm] = useState(false);
  const [purchaseQualityFormData, setPurchaseQualityFormData] = useState(null);
  const [lastSortedCardId, setLastSortedCardId] = useState(null);
  const [selectedSsNo, setSelectedSsNo] = useState(null);
  const [finishPurhcaseModalData, setFinishPurhcaseModalData] = useState(null);
  const [supplierPurhcaseModal, setSupplierPurhcaseModal] = useState(false);
  const [finishSupplierModalData, setFinishSupplierModalData] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const handleCardClick = (cardInfo) => {
    setSelected(cardInfo);
  };
  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 1,
    retryDelay: 3200,
    queryKey: ["service-supply-quality-list"],
    queryFn: async () =>
      await serviceSupplyService.getQualityServiceSupplyList(),
  });

  const cleanAndMatchSearch = (item, searchTerm) => {
    const fullName = `${item?.job?.product?.name}`;
    const cleanedFullName = diacritics.remove(fullName).toLowerCase();
    const cleanedSearchTerm = diacritics.remove(searchTerm.toLowerCase());
    return cleanedFullName.includes(cleanedSearchTerm);
  };

  const filteredActiveJunction = data?.activeJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredOperationActiveJunction =
    selectOperationData?.activeJunction?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  const filteredPendingJunction = data?.pendingJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredOperationPendingJunction =
    selectOperationData?.pendingJunction?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  const filteredPreviousPendingJunction = data?.previousPending?.filter(
    (item) => {
      return cleanAndMatchSearch(item, searchTerm);
    }
  );

  const filteredOperationPreviousPendingJunction =
    selectOperationData?.previousPending?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  const filteredPastJunction = data?.pastJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredOperationPastJunction =
    selectOperationData?.pastJunction?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  useEffect(() => {
    refetch();
    setSelected("all");
  }, [date]);

   useEffect(() => {
      if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
      } else if (
        auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325"
      ) {
      } else if (
        auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592"
      ) {
      } else if (
        auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4"
      ) {
      } else if (
        auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706"
      ) {
      } else if (
        auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f"
      ) {
      } else if (
        auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35"
      ) {
      } else if (
        auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630"
      ) {
      } else if (
        auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689"
      ) {
      } else if (
        auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a"
      ) {
      } else if (
        auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3"
      ) {
      } else if (
        auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513"
      ) {
      } else if (
        auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86"
      ) {
      } else if (
        auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9"
      ) {
      } else if (
        auth?.user?.company?.id == "1dcc62be-318a-4c4d-9e41-be3b69241c25"
      ) {
      } else if (
        auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706"
      ) {
      } else if (
        auth?.user?.company?.id == "10826fc4-bf01-4164-acf0-72bb213e88d3"
      ) {
      } else {
        navigate("/app/product/verysoon");
      }
    }, []);

  useEffect(() => {
    setOperations(data?.operations);
  }, [data, isLoading]);

  const handleFinishedOnDrop = () => {
    if (draggedJunction?.id) {
      if (
        !(
          draggedJunction?.status === "received" ||
          draggedJunction?.status === "completed"
        )
      ) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setPurchaseQualityForm(true);
      setPurchaseQualityFormData(draggedJunction);
    }
  };

  const handleWaitingOnDrop = () => {
    if (draggedJunction?.id) {
      if (
        draggedJunction?.deliveredQuantity === draggedJunction?.acceptedQuantity
      ) {
        Toast(
          "error",
          t("product:columnDragDropWarningMaterialPlannedCardSameUnit")
        );
        return;
      }
      if (!(draggedJunction?.status === "waiting")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setFinishPurhcaseModal(true);
      setFinishPurhcaseModalData(draggedJunction);
    }
  };

  const handleSupplierOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === null)) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setSupplierPurhcaseModal(true);
      setFinishSupplierModalData(draggedJunction);
    }
  };

  const handleSortBySelectedCard = (items, type) => {
    if (!selectedCardId) return items;
    const isSorted = selectedCardId === lastSortedCardId;
    const sortedItems = items?.sort((a, b) => {
      let aId, bId;

      if (type === "supplier") {
        aId = a?.id;
        bId = b?.id;
      } else {
        aId =
          a?.plannedJunctionRef?.id ||
          a?.serviceSupplierJunction?.plannedJunctionRef?.id;
        bId =
          b?.plannedJunctionRef?.id ||
          b?.serviceSupplierJunction?.plannedJunctionRef?.id;
      }
      if (aId === selectedCardId && bId !== selectedCardId) return -1;
      if (aId !== selectedCardId && bId === selectedCardId) return 1;
      if (isSorted) return bId - aId;

      return 0;
    });
    return sortedItems?.map((item) => ({
      ...item,
      opacity:
        item?.plannedJunctionRef?.id === selectedCardId ||
          item?.id === selectedCardId ||
          item?.serviceSupplierJunction?.plannedJunctionRef?.id === selectedCardId
          ? 1
          : 0.25,
    }));
  };

  const handleCardClickInfo = (cardId, ssNo) => {
    if (selectedCardId === cardId) {
      // Aynı karta tekrar tıklanırsa sıralamayı ve msNo'yu sıfırla
      setSelectedCardId(null);
      setLastSortedCardId(null);
      setSelectedSsNo(null);
    } else {
      // Yeni bir kart seçildiyse, lastSortedCardId ve msNo'yu güncelle
      setSelectedCardId(cardId);
      setLastSortedCardId(cardId);
      setSelectedSsNo(ssNo);
    }
  };

  return (
    <>
      <div className="w-full h-full flex flex-col overflow-hidden scrollbar-hide overflow-y-auto">
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 sm:justify-between items-center ">
          {isLoading || isFetching ? (
            <div className="w-full h-[48px] bg-gray-200 rounded-xl animate-pulse" />
          ) : (
            <>
              <div className=" sm:w-1/2 sm:h-full sm:flex md:flex sm:items-center">
                <span className="mr-3 md:flex sm:flex xs:flex hidden">
                  <Button
                    colorType={"tertiary-gray"}
                    iconLeft={<HumburgerIcon />}
                    size={"md"}
                    onClick={() => settings.setIsActive(true)}
                  />
                </span>
                <div className="flex items-center">
                  <span className="font-codecMedium text-2xl text-black">
                    upu.
                  </span>
                  <p className="text-2xl font-codecMedium text-[#B54708]">
                    quality
                  </p>
                  <p className="text-xl font-normal ml-2 text-secondary-500">
                    {selected && selected?.name
                      ? `${t("product:serviceIncomingQuality")} / ${selected?.name
                      }`
                      : t("product:serviceIncomingQuality")}
                  </p>
                </div>
              </div>
              {/* <div className="ml-auto flex items-center gap-3 sm:hidden xs:hidden">
                <Button
                  iconLeft={<ArrowLeftIcon />}
                  size={"md"}
                  colorType={"secondary-gray"}
                  onClick={() => setDate(moment(date).subtract(1, "days").format("YYYY-MM-DD"))}
                />
                <EndDatePicker
                  date={date}
                  visible={visible}
                  setDate={setDate}
                  setVisible={setVisible}
                  buttonClassName={"flex flex-col items-center"}
                  calendarClassName={"absolute top-12 rounded-lg shadow-xl z-[600]"}
                  visibleFooter
                >
                  <Button
                    onClick={() => setVisible(!visible)}
                    iconLeft={<CalendarIcon />}
                    size={"md"}
                    colorType={"secondary-gray"}
                    label={moment(date).format("DD.MM.YYYY")}
                  />
                </EndDatePicker>
                <Button
                  disabled={moment(new Date()).format("YYYY-MM-DD") === moment(new Date(date)).format("YYYY-MM-DD")}
                  iconLeft={<ArrowRightIcon />}
                  size={"md"}
                  colorType={"secondary-gray"}
                  onClick={() => setDate(moment(date).subtract(-1, "days").format("YYYY-MM-DD"))}
                />
              </div> */}
            </>
          )}
        </div>
        {isLoading || isFetching ? (
          <div className="w-full h-[134px] bg-gray-200  rounded-xl animate-pulse" />
        ) : (
          <div
            className={`flex w-full overflow-x-auto pb-1 ${!isPastDay
              ? "min-h-[116px] max-h-[116px]"
              : "min-h-[116px] max-h-[116px]"
              }`}
          >
            <QualityServiceSupplyAllCard
              pendingJunctionlength={data?.allCount?.pendingJunctions}
              supplierLength={data?.allCount?.junctionAtServiceSupplier}
              onClick={handleCardClick}
              selected={selected}
            />
            {/* <AllOperationsNewCard
              isPastDay={isPastDay}
              pendingJunctionlength={data?.pendingJunction?.length}
              activeJunctionlength={data?.activeJunction?.length}
              pastJunctionlength={data?.pastJunction?.length}
              allCount={data?.allCount}
              onClick={handleCardClick}
              selected={selected}
            /> */}
            {/* <AllOperationsCard
              isPastDay={isPastDay}
              pendingJunctionlength={data?.pendingJunction?.length}
              activeJunctionlength={data?.activeJunction?.length}
              pastJunctionlength={data?.pastJunction?.length}
              allCount={data?.allCount}
              onClick={handleCardClick}
              selected={selected}
            /> */}
            {operations
              ?.sort(
                (a, b) =>
                  b?.junctionAtServiceSupplier - a?.junctionAtServiceSupplier
              )
              ?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`min-w-[192px] ${index !== operations?.length - 1
                      ? "border-b"
                      : "border-b-0"
                      } max-h-[96px]`}
                  >
                    <div
                      onClick={async () => {
                        handleCardClick(item);
                        await serviceSupplyService
                          .getQualityServiceSupplySelectOperation(item?.id)
                          .then((r) => setSelectOperationData(r));
                      }}
                      className={`min-w-[172px] max-w-[172px] rounded-t-lg ${selected?.id === item?.id
                        ? "border-b-[2px] border-b-[#A15C07] h-[96px]"
                        : "h-[96px]"
                        } rounded-b-none border cursor-pointer border-seconday-300  select-none flex flex-col`}
                    >
                      <div className="flex flex-row items-center justify-center w-full gap-x-1.5 p-2 border-b border-b-[#E4E7EC]">
                        {/* {icon ? <img className="w-4 h-4" src={generateFileUrl(icon)} /> : <TransformIcon className="w-4 h-4" />} */}
                        <p
                          className={`text-base  truncate ${selected?.id === item?.id
                            ? "text-[#B54708] font-semibold"
                            : "text-[#667085] font-medium"
                            } `}
                        >
                          {item?.name || "--"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center w-full h-full">
                        {!isPastDay && (
                          <div
                            className={`flex flex-col items-center w-1/2  border-r ${selected?.id === item?.id
                              ? "bg-[#F2F4F7]"
                              : "bg-[#F9FAFB]"
                              }`}
                          >
                            <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                              <p
                                className={`text-xs ${selected?.id === item?.id
                                  ? "text-[#344054] font-medium"
                                  : "text-[#667085] font-normal"
                                  }`}
                              >
                                {t("product:inSupplier")}
                              </p>
                            </div>
                            <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                              <p
                                className={`text-sm ${selected?.id === item?.id
                                  ? "font-semibold text-[#079455]"
                                  : "font-medium text-[#667085]"
                                  }`}
                              >
                                {item?.junctionAtServiceSupplier || 0}
                              </p>
                            </div>
                          </div>
                        )}
                        <div
                          className={`flex flex-col items-center w-1/2  ${selected?.id === item?.id
                            ? "bg-[#F2F4F7]"
                            : "bg-[#F9FAFB]"
                            } ${isPastDay ? "border-r" : ""}`}
                        >
                          <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                            <p
                              className={`text-xs ${selected?.id === item?.id
                                ? "text-[#344054] font-medium"
                                : "text-[#667085] font-normal"
                                }`}
                            >
                              {t("product:qualityPen")}
                            </p>
                          </div>
                          <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                            <p
                              className={`text-sm ${selected?.id === item?.id
                                ? "font-semibold text-[#A15C07]"
                                : "font-medium text-[#667085]"
                                }`}
                            >
                              {item?.pendingJunctions || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        {isLoading || isFetching ? (
          <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
        ) : (
          <div
            style={{ minHeight: height - 50 }}
            className="w-full flex border border-[#D0D5DD] rounded-xl mt-4 flex-col md:overflow-y-auto overflow-hidden"
          >
            <div className="flex min-h-[72px] max-h-[72px] p-4 border-b border-b-secondary-300 justify-between w-full items-center">
              <span className="text-[#101828] text-xl font-semibold">
                {selected?.name || t("product:allNew")}
              </span>
              {selectedCardId ? (
                <div className="flex flex-row items-center ml-4 gap-x-2">
                  <FiltersIcon />
                  <p className="text-sm font-medium text-secondary-700">
                    SS-{selectedSsNo} {t("product:filtered")}
                  </p>
                  {selectedSsNo && (
                    <Badgets
                      iconLeft={<CloseIcon />}
                      size={"sm"}
                      colorType={"outline-warning"}
                      label={t("product:resetFilter")}
                      onClick={() => {
                        setSelectedCardId(null);
                        setLastSortedCardId(null);
                        setSelectedSsNo(null);
                      }}
                    />
                  )}
                </div>
              ) : (
                <div className="flex flex-row items-center gap-x-2 ml-4">
                  <FiltersIcon />
                  <p className="text-sm text-secondary-700 font-medium">
                    {t("product:clickFilterSs")}
                  </p>
                </div>
              )}
              <div className="flex gap-x-3">
                <span className="flex min-w-[300px] max-w-[300px] h-full items-center justify-center">
                  <SearchInput
                    theme={"product"}
                    setSearchVal={setSearchTerm}
                    placeholder={t("chat:search")}
                  />
                </span>
                {/* <Button colorType={"secondary-gray"} size={"md"} label={t("product:sorted")} iconLeft={<SwitchIcon />} />
                <Button colorType={"secondary-gray"} size={"md"} label={t("product:filters")} iconLeft={<FilterIcon />} /> */}
              </div>
            </div>
            <div className="flex w-full flex-1 md:overflow-x-auto overflow-hidden pb-4">
              <ServiceSupplyTab
                length={
                  selected === "all"
                    ? data?.junctionAtServiceSupplier?.length || "0"
                    : selectOperationData?.junctionAtServiceSupplier?.length ||
                    0
                }
                type={"supplier"}
                onDragEnd={() => {
                  setDraggedJunction(null);
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={() => {
                  handleSupplierOnDrop();
                }}
                children={
                  <SupplierListController
                    setDraggedJunction={setDraggedJunction}
                    items={handleSortBySelectedCard(
                      selected === "all"
                        ? data?.junctionAtServiceSupplier
                        : selectOperationData?.junctionAtServiceSupplier
                    )}
                    onClick={handleCardClickInfo}
                    selectedCardId={selectedCardId}
                    setSupplierPurhcaseModal={setSupplierPurhcaseModal}
                    setFinishSupplierModalData={setFinishSupplierModalData}
                  />
                }
              />
              <ServiceSupplyTab
                length={
                  selected === "all"
                    ? data?.pendingJunctions?.length || "0"
                    : selectOperationData?.pendingJunctions?.length || 0
                }
                type={"received"}
                onDragOver={(e) => e.preventDefault()}
                onDrop={() => {
                  handleWaitingOnDrop();
                }}
                children={
                  <JunctionListController
                    setDraggedJunction={setDraggedJunction}
                    items={handleSortBySelectedCard(
                      selected === "all"
                        ? data?.pendingJunctions
                        : selectOperationData?.pendingJunctions
                    )}
                    onClick={handleCardClickInfo}
                    setFinishPurhcaseModalData={setFinishPurhcaseModalData}
                    setFinishPurhcaseModal={setFinishPurhcaseModal}
                    selectedCardId={selectedCardId}
                  />
                }
              />
              <ServiceSupplyTab
                length={
                  selected === "all"
                    ? data?.qualityAcceptedJunctions?.length || 0
                    : selectOperationData?.qualityAcceptedJunctions?.length ||
                    "0"
                }
                type={"complate"}
                onDragOver={(e) => e.preventDefault()}
                onDrop={() => {
                  handleFinishedOnDrop();
                }}
                children={
                  <QualityApliedController
                    setDraggedJunction={setDraggedJunction}
                    items={handleSortBySelectedCard(
                      selected === "all"
                        ? data?.qualityAcceptedJunctions
                        : selectOperationData?.qualityAcceptedJunctions
                    )}
                    onClick={handleCardClickInfo}
                    selectedCardId={selectedCardId}
                    setPurchaseQualityFormData={setPurchaseQualityFormData}
                    setPurchaseQualityForm={setPurchaseQualityForm}
                    refetch={refetch}
                  />
                }
              />
            </div>
          </div>
        )}
      </div>
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
      <CustomModal
        isOpen={supplierPurhcaseModal}
        setIsOpen={setSupplierPurhcaseModal}
        modalTitle={t("product:deliverToSupplier")}
        // subTitle={"Detayları kontrol edin ve gerekliyse düzenleyin."}
        children={
          <SendToSupplierModal
            refetch={refetch}
            setIsOpen={setSupplierPurhcaseModal}
            junctionData={finishSupplierModalData}
          />
        }
      />
      <CustomModal
        isOpen={finishPurhcaseModal}
        setIsOpen={setFinishPurhcaseModal}
        modalTitle={t("product:receiveDelivery")}
        // subTitle={"Alım miktarını ve varsa ek formları ekleyin"}
        children={
          <ServiceSupplyReceiveModal
            refetch={refetch}
            setIsOpen={setFinishPurhcaseModal}
            junctionData={finishPurhcaseModalData}
          />
        }
      />
      <CustomModal
        isOpen={purchaseQualityForm}
        setIsOpen={setPurchaseQualityForm}
        modalTitle={t("product:qualityControlForm")}
        children={
          <ServiceSupplyQualityControlForm
            refetch={refetch}
            setIsOpen={setPurchaseQualityForm}
            junctionData={purchaseQualityFormData}
          />
        }
      />
    </>
  );
}

export default QualityExternal;

// const JunctionListController = ({ items, refetch, setDraggedJunction }) => {
//   return items?.map((item, index) => {
//     return (
//       <div draggable onDragStart={() => setDraggedJunction(item)} className="min-h-[80px] mb-3">
//         <SupplyEventCard data={item} refetch={refetch} />
//       </div>
//     );
//   });
// };

const JunctionListController = ({
  items,
  onClick,
  setPurchaseQualityFormData,
  selectedCardId,
  setPurchaseQualityForm,
  setFinishPurhcaseModal,
  setFinishPurhcaseModalData,
  setDraggedJunction,
}) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] mb-3"
      style={{
        opacity: item.opacity, // Opaklığı ayarla
      }}
    >
      <ServiceSupplyReceivedEventCard
        data={item}
        onClick={() =>
          onClick(
            item?.serviceSupplierJunction?.plannedJunctionRef?.id,
            item?.serviceSupplierJunction?.plannedJunctionRef?.ssNo
          )
        }
        // Kart tıklandığında `handleCardClick` fonksiyonunu çağır
        setPurchaseQualityForm={setPurchaseQualityForm}
        setPurchaseQualityFormData={setPurchaseQualityFormData}
        selectedCardId={selectedCardId}
        setFinishPurhcaseModal={setFinishPurhcaseModal}
        setFinishPurhcaseModalData={setFinishPurhcaseModalData}
      />
    </div>
  ));
};

const QualityApliedController = ({
  items,
  onClick,
  setPurchaseQualityFormData,
  selectedCardId,
  setPurchaseQualityForm,
  setDraggedJunction,
  refetch
}) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] mb-3"
      style={{
        opacity: item.opacity, // Opaklığı ayarla
      }}
    >
      <ServiceSupplierQualityApliedEventCard
        data={item}
        onClick={() =>
          onClick(
            item?.serviceSupplierJunction?.plannedJunctionRef?.id,
            item?.serviceSupplierJunction?.plannedJunctionRef?.ssNo
          )
        }
        // Kart tıklandığında `handleCardClick` fonksiyonunu çağır
        setPurchaseQualityForm={setPurchaseQualityForm}
        setPurchaseQualityFormData={setPurchaseQualityFormData}
        selectedCardId={selectedCardId}
        refetch={refetch}
      />
    </div>
  ));
};

const SupplierListController = ({
  items,
  onClick,
  setFinishPurhcaseModal,
  selectedCardId,
  setFinishPurhcaseModalData,
  setSupplierPurhcaseModal,
  setFinishSupplierModalData,
  setDraggedJunction,
}) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] cursor-pointer mb-3"
      style={{
        opacity: item.opacity, // Opaklığı ayarla
      }}
    >
      <ServiceSupplySupplierEventCard
        data={item}
        onClick={() =>
          onClick(item?.plannedJunctionRef?.id, item?.plannedJunctionRef?.ssNo)
        }
        // Kart tıklandığında `handleCardClick` fonksiyonunu çağır
        setFinishPurhcaseModal={setFinishPurhcaseModal}
        setFinishPurhcaseModalData={setFinishPurhcaseModalData}
        selectedCardId={selectedCardId}
        setFinishSupplierModalData={setFinishSupplierModalData}
        setSupplierPurhcaseModal={setSupplierPurhcaseModal}
      />
    </div>
  ));
};

export const FiltersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M2.16699 3.83333C2.16699 3.36662 2.16699 3.13327 2.25782 2.95501C2.33771 2.79821 2.4652 2.67072 2.622 2.59083C2.80026 2.5 3.03361 2.5 3.50033 2.5H17.5003C17.967 2.5 18.2004 2.5 18.3787 2.59083C18.5355 2.67072 18.6629 2.79821 18.7428 2.95501C18.8337 3.13327 18.8337 3.36662 18.8337 3.83333V4.39116C18.8337 4.61516 18.8337 4.72716 18.8063 4.8313C18.782 4.92359 18.7421 5.01103 18.6882 5.0898C18.6275 5.17869 18.5428 5.25204 18.3736 5.39875L13.0438 10.0179C12.8745 10.1646 12.7898 10.238 12.7291 10.3269C12.6752 10.4056 12.6353 10.4931 12.611 10.5854C12.5837 10.6895 12.5837 10.8015 12.5837 11.0255V15.382C12.5837 15.5449 12.5837 15.6264 12.5574 15.6969C12.5342 15.7591 12.4964 15.8149 12.4472 15.8596C12.3916 15.9102 12.3159 15.9404 12.1646 16.001L9.33125 17.1343C9.02497 17.2568 8.87182 17.3181 8.74889 17.2925C8.64138 17.2702 8.54704 17.2063 8.48637 17.1148C8.41699 17.0101 8.41699 16.8452 8.41699 16.5153V11.0255C8.41699 10.8015 8.41699 10.6895 8.38962 10.5854C8.36536 10.4931 8.32544 10.4056 8.27158 10.3269C8.21081 10.238 8.12617 10.1646 7.9569 10.0179L2.62708 5.39875C2.45781 5.25204 2.37318 5.17869 2.3124 5.0898C2.25855 5.01103 2.21862 4.92359 2.19436 4.8313C2.16699 4.72716 2.16699 4.61516 2.16699 4.39116V3.83333Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
