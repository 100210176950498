import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "../../../assets/icons/stepsIcons";
import { Button, Input } from "../../../components";
import NoData from "../../step-navigation/components/NoData";
import Badgets from "../../../components/buttons/Badgets";
import ExternalOperationCard from "../components/ExternalOperationCard";

const ExternalOperationInfo = ({
  closeModal,
  control,
  setAddSuplierOpen,
  errors,
  setSupplierData,
  supplier,
  setSelectedTab,
  setSelectedSuplier,
  selectedSupplier,
}) => {
  const { t } = useTranslation();

  const memoizedOddSupplier = useMemo(() => {
    return supplier?.map((e, i) => {
      return i % 2 !== 0 ? (
        <ExternalOperationCard
          key={e?.id}
          image={e?.image}
          supplierName={e?.name}
          onClick={() => {
            if (selectedSupplier?.includes(e?.id)) {
              setSelectedSuplier(selectedSupplier?.filter((id) => id !== e?.id));
            } else {
              setSelectedSuplier([...selectedSupplier, e?.id]);
            }
          }}
          onEditClick={() => {
            setAddSuplierOpen(true);
            setSupplierData(e);
          }}
          checked={
            selectedSupplier.includes(e?.id)
            // suppliers.includes(e?.id)
            // ? suppliers.includes(e?.id)
            // : selectedSupplier.includes(e?.id)
          }
        />
      ) : null;
    });
  }, [supplier, ExternalOperationCard, selectedSupplier, setSelectedSuplier]);

  const memoizedEvenSupplier = useMemo(() => {
    return supplier?.map((e, i) => {
      return i % 2 === 0 ? (
        <ExternalOperationCard
          key={e?.id}
          i={i}
          image={e?.image}
          supplierName={e?.name}
          onClick={() => {
            if (selectedSupplier?.includes(e?.id)) {
              setSelectedSuplier(selectedSupplier?.filter((id) => id !== e?.id));
            } else {
              setSelectedSuplier([...selectedSupplier, e?.id]);
            }
          }}
          onEditClick={() => {
            setAddSuplierOpen(true);
            setSupplierData(e);
          }}
          checked={
            selectedSupplier.includes(e?.id)
            // suppliers.includes(e?.id)
            // ? suppliers.includes(e?.id)
            // : selectedSupplier.includes(e?.id)
          }
        />
      ) : null;
    });
  }, [supplier, ExternalOperationCard, selectedSupplier, setSelectedSuplier]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex h-full w-full flex-col pt-3 md:pb-8 sm:pb-8">
        <div className="flex gap-x-4 w-full mb-3">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("product:externalOperationName")}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                validate={errors?.name ? "error" : ""}
                errorMessage={errors?.name ? errors?.name?.message : ""}
              />
            )}
            name="name"
          />
          <Controller
            control={control}
            render={({ field: { onBlur, value, onChange } }) => (
              <Input
                label={t("product:operationCode")}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                onBlur={onBlur}
                value={value}
                height="200px"
                disabled={false}
                validate={errors.code ? "error" : ""}
                errorMessage={errors.code ? errors.code.message : ""}
                maxLength={4}
                inputType="letters"
              />
            )}
            name="code"
          />
        </div>
        <div className="w-full h-14 flex items-center justify-between border-b border-secondary-200 dark:border-b-[#292929]">
          <div className="flex gap-x-4 items-center">
            <p className="text-[#344054] text-md font-semibold">{t("product:suppliers")}</p>
            <Badgets colorType={"fill-primary"} label={supplier?.length} size={"sm"} />
          </div>
          <div className="flex ml-auto">
            <Button
              onClick={() => {
                setSupplierData(null);
                setAddSuplierOpen(true);
              }}
              iconLeft={<PlusIcon />}
              label={t("product:addNewSupplier")}
              size={"sm"}
              colorType={"tertiary-machine"}
            />
          </div>
        </div>
        <div className="flex-1 flex xs:flex-nowrap xs:flex-col xs:gap-3 overflow-y-auto scrollbar-hide border-b dark:border-b-[#292929] border-secondary-200 flex-wrap px-2 sm:px-0 content-start mt-3 relative gap-y-4">
          {supplier.length < 1 ? (
            <NoData theme={"machine"} header={t("product:noSupplier")} button={false} />
          ) : (
            <div className="min-h-[266px] max-h-[266px]  w-full  flex  sm:gap-2 items-center xs:flex-col xs:gap-4 ">
              <div className="flex flex-col w-full h-full items-start xs:gap-2">{memoizedEvenSupplier}</div>
              <div className="flex flex-col w-full h-full xs:gap-2">{memoizedOddSupplier}</div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex items-center justify-end mt-3 gap-3">
        <div className="flex w-1/2 items-center justify-center">
          <Button onClick={() => closeModal(false)} colorType={"secondary-gray"} label={t("buttons:stop")} />
        </div>
        <div className="flex w-1/2 items-center justify-center">
          <Button onClick={() => setSelectedTab("processResponsible")} colorType={"primary-machine"} label={t("buttons:next")} />
        </div>
      </div>
    </div>
  );
};

export default ExternalOperationInfo;
