import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { orderService } from "../../../services/order.service";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button, Loading } from "../../../components";
import { Toast } from "../../../utils/toastify/toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DeleteOrderProduct = ({ id, selectedOrder, setIsOpen, setIsAlert, detailsLenght, refetch }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data } = useQuery({ queryFn: () => orderService.controlPartial(id), queryKey: ["controlPartial"], retry: 0 });
  const totalDesiredQuantity = data?.reduce((a, b) => (a?.desiredQuantity || 0) + (b.desiredQuantity || 0), 0);
  const [selectedType, setSelectedType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const res = await orderService.deletePartials(id, selectedType === "first" ? "convert-to-stock" : "close-send-stock");
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsOpen(false);
        refetch();
        if (detailsLenght === 1) {
          navigate("/app/product/mrp/orders");
        }
      } else {
        Toast("error", res?.data?.message);
      }
    } catch (error) {
      Toast("error", t("product:errorOccurred"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={"w-[587px]"}>
      <p className={"text-sm text-gray-500"}>{t("product:makeDecision")}</p>

      <div className={"flex flex-col overflow-hidden w-full h-[106px] rounded-lg border border-gray-300 shadow mt-5"}>
        <div className={"bg-gray-50 border-b border-gray-300 w-full flex items-center"}>
          <div className={"w-1/2 py-1 pl-2 text-xs font-medium text-gray-700 border-r border-gray-300"}>{t("product:productNameStockCode")}</div>
          <div className={"w-[25%] py-1 flex items-center justify-center text-xs font-medium text-gray-700 border-r border-gray-300"}>
            {t("product:orderQuantityss")}
          </div>
          <div className={"w-[25%] py-1 flex items-center justify-center text-xs font-medium text-gray-700"}>{t("product:quantityInProduction")}</div>
        </div>
        <div className={"w-full flex items-center h-full"}>
          <div className={"w-1/2 border-r border-gray-300 h-full flex items-center px-2"}>
            <img src={generateFileUrl(selectedOrder?.product?.image)} className={"w-[64px] h-[48px] rounded-md"} />
            <div className={"flex flex-col ml-2"}>
              <p className={"font-semibold text-gray-900"}>{selectedOrder?.product?.name}</p>
              <p className={"font-medium text-sm text-orange-700"}>{`${t("product:stock")}:` + selectedOrder?.product?.stockCode}</p>
            </div>
          </div>
          <div className={"w-[25%] flex items-center justify-center text-xs font-medium text-gray-700 border-r border-gray-300 h-full"}>
            {selectedOrder?.orderedQuantity}
          </div>
          <div className={"w-[25%] flex items-center justify-center text-xs font-medium text-gray-700 h-full"}>{totalDesiredQuantity || 0}</div>
        </div>
      </div>

      <div className={"w-full mt-5"}>
        <div onClick={() => setSelectedType("first")} className={"flex items-center cursor-pointer"}>
          <div className={`${selectedType === "first" ? "border-orange-700 border-4" : "border-gray-300 border"} rounded-full w-4 h-4`} />
          <p className={"text-gray-600 text-sm ml-2"}>{t("product:holdWorkOrders")}</p>
        </div>
        <div onClick={() => setSelectedType("second")} className={"flex items-center mt-3 cursor-pointer"}>
          <div className={`${selectedType === "second" ? "border-orange-700 border-4" : "border-gray-300 border"} rounded-full w-4 h-4`} />
          <p className={"text-gray-600 text-sm ml-2"}>{t("product:closeWorkOrders")}</p>
        </div>
      </div>

      <div className={"mt-5 flex items-center gap-4"}>
        <Button onClick={() => setIsOpen(false)} colorType={"secondary-gray"} label={t("buttons:stop")} disabled={isLoading} />
        <Button
          onClick={handleDelete}
          colorType={"primary-error"}
          label={isLoading ? <Loading color="#fff" secondary="#fff" size={22} /> : t("buttons:approveDelete")}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default DeleteOrderProduct;
