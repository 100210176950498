import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components";
import QuantityModal from "./QuantityModal";
import { CheckIcon } from "../../../assets/icons/stepsIcons";
import { observer } from "mobx-react-lite";
import { ClockIcon3, DotsVerticalIcon, QuantityIcon } from "../../../assets/icons/commonIcons";
import UpdateToleranceValue from "./UpdateToleranceValue";
import CycleTimeSuggestionModal from "./CycleTimeSuggestionModal";
import TargetSettingTimeModal from "./TargetSettingTimeModal";

const colors = [
  localStorage.color_theme === "dark" ? "#9E77ED" : "#9E77ED",
  localStorage.color_theme === "dark" ? "#F79009" : "#F04438",
  localStorage.color_theme === "dark" ? "#15B79E" : "#DC6803",
  localStorage.color_theme === "dark" ? "#FFFF5D" : "#0E9384",
  localStorage.color_theme === "dark" ? "#2E90FA" : "#12B76A",
  localStorage.color_theme === "dark" ? "#8CF3AF" : "#EAAA08",
  localStorage.color_theme === "dark" ? "#F79009" : "#4E5BA6",
  localStorage.color_theme === "dark" ? "#667085" : "#008999",
];

const WorkCountCard = observer(({ workCount, metadataId, refetch, selectedJunction, equipmentData, junctionData, suggestedCycleTime }) => {
  const { t } = useTranslation();
  const contentWrapper = useRef(null);
  const [isOpenWorkCountModal, setIsOpenWorkCountModal] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [showDropDown, setShowDropDown] = useState(false);
  const [openCycleTime, setOpenCycleTime] = useState(false);
  const [openSetupTime, setOpenSetupTime] = useState(false);

  const [isUpdateTolerance, setIsUpdateTolerance] = useState(false);

  useEffect(() => {
    const initialCheckboxStates = {};
    workCount?.forEach((_, index) => {
      initialCheckboxStates[index] = true;
    });
    setCheckboxStates(initialCheckboxStates);
  }, [workCount]);

  const sorted = workCount?.sort(
    (a, b) => {
      if (a.count !== b.count) {
        return b.count - a.count;
      }
    },
    [workCount]
  );

  const firstElement = workCount.shift();

  workCount.sort((a, b) => Math.abs(a.maxRepeatValue - firstElement.maxRepeatValue) - Math.abs(b.maxRepeatValue - firstElement.maxRepeatValue));

  workCount.unshift(firstElement);

  const totalSelectedWorkCount = () => {
    let totalCount = 0;
    for (const index in checkboxStates) {
      if (checkboxStates[index]) {
        totalCount += workCount[index]?.count || 0;
      }
    }
    return totalCount;
  };

  const toggleCheckbox = (index) => {
    setCheckboxStates((prevState) => {
      const newCheckboxStates = {
        ...prevState,
        [index]: !prevState[index],
      };

      const allSelected = Object.values(newCheckboxStates).every((isChecked) => isChecked);
      setIsAllSelected(allSelected);

      return newCheckboxStates;
    });
  };

  const allSelected = () => {
    const newCheckboxStates = {};
    workCount?.forEach((_, index) => {
      newCheckboxStates[index] = !isAllSelected;
    });
    setCheckboxStates(newCheckboxStates);
    setIsAllSelected(!isAllSelected);
  };
 
  const junctionDatas = junctionData?.find(item => item?.id === selectedJunction);
  const estimatedTimeGroup = junctionDatas?.header ? junctionDatas?.header?.internalOperation?.plannableNodes?.find((element) => {return element?.id == equipmentData?.id }) : 
  junctionDatas?.flexHeader?.plannableNodes ? junctionDatas?.flexHeader?.plannableNodes?.find((element) => {return element?.id == equipmentData?.id }) : junctionDatas?.flexHeader?.estimatedSettingsTime
  
  return (
    <>
      <div className="w-full flex min-h-[72px] h-[72px] mb-4 justify-start items-center border dark:border-[#525252] dark:bg-[#292929] border-secondary-300 rounded-md pointer mt-4">
        <div className="flex flex-row items-center gap-x-2 border-r h-full px-4 py-2 ">
          <div className="flex flex-col whitespace-nowrap items-center text-[#475467] justify-center min-w-[160px] relative group">
            <div className="flex items-center justify-start gap-x-1 w-full">
              <QuantityIcon />
              <div className="flex gap-x-1 items-center">
                <span className="text-[#101828] font-semibold text-base">{totalSelectedWorkCount()}</span>
                <span className="text-[#667085] font-normal text-sm mt-[2px]">{t("product:totalpcs")}</span>
              </div>
            </div>
            <div onClick={allSelected} className="flex gap-x-2 w-full items-center justify-start cursor-pointer">
              <div
                className={`flex min-h-[16px] max-h-[16px] h-4 min-w-[16px] max-w-[16px] w-4 border border-[#D0D5DD] rounded p-[2px] items-center justify-center ${isAllSelected && "bg-[#7F56D9] border-[0px]"
                  } `}
              >
                {isAllSelected && <CheckIcon color={"white"} />}
              </div>
              <p className="text-[#667085] font-normal text-sm">{t("person:selectAll")}</p>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowDropDown(!showDropDown);
              }}
              className="flex min-w-[20px] max-w-[20px] w-5 min-h-[20px] max-h-[20px] h-5 absolute right-1 top-2 cursor-pointer "
            >
              <DotsVerticalIcon />
              {showDropDown && (
                <div className="hidden flex-col absolute w-fit h-fit bg-white border left-1 top-5 z-[99] rounded-lg px-[6px] py-1 group-hover:flex">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsUpdateTolerance(true);
                      setShowDropDown(false);
                    }}
                    type="button"
                    className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 hover:bg-secondary-100`}
                  >
                    {/* {item?.icon} */}
                    <p className={`font-medium text-sm text-secondary-700`}>{t("product:updateToleranceValue")}</p>
                  </button>
                </div>
              )}
            </div>
          </div>
          {selectedJunction && (
            <>
              {junctionDatas?.type === "work" ? (
                <div className="flex flex-row w-[200px] min-w-[200px] max-w-[200px] items-center h-full gap-x-2 py-1 px-2 border rounded-md border-[#D6BBFB] bg-[#F9F5FF]">
                  <LightIcon />
                  <div className="flex flex-col items-start">
                    <p className="text-xs font-medium text-[#7F56D9]">{t("product:cycleTimeSuggestion")}</p>
                    <div className="flex flex-row items-center justify-between w-full gap-x-1">
                      <p className="text-sm font-medium text-[#6941C6]">{Math.floor(suggestedCycleTime / 60 || "0")} dk {Math.round(suggestedCycleTime % 60 || "0")} sn</p>
                      <div onClick={() => setOpenCycleTime(true)} className="cursor-pointer ml-auto">
                        <ArrowRightIcon />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center w-[200px] min-w-[200px] max-w-[200px] h-full gap-x-2 py-1 px-2 border rounded-md border-[#FDE272] bg-[#FEFBE8]">
                  <SettingsIcon />
                  <div className="flex flex-col items-start">
                    <p className="text-xs font-medium text-[#A15C07]">{t("product:updateSetupTime")}</p>
                    <div className="flex flex-row items-center justify-between w-full gap-x-1">
                      <p className="text-sm font-medium text-[#A15C07]">{Math.floor(estimatedTimeGroup ? estimatedTimeGroup?.estimatedSettingsTime / 60 || "0" :  junctionDatas?.header ?  junctionDatas?.header.internalOperation?.estimatedSettingsTime / 60 || "0" : junctionDatas?.flexHeader.estimatedSettingsTime / 60 || "0")} dk {Math.round(estimatedTimeGroup ? estimatedTimeGroup?.estimatedSettingsTime % 60 || "0" :  junctionDatas?.header ?  junctionDatas?.header.internalOperation?.estimatedSettingsTime % 60 || "0" : junctionDatas?.flexHeader.estimatedSettingsTime % 60 || "0")} sn</p>
                      <div onClick={() => setOpenSetupTime(true)} className="cursor-pointer ml-auto">
                        <ArrowRightIconSetup />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>

          )}
        </div>


        <div ref={contentWrapper} className="flex flex-row p-1 pl-2 items-center overflow-x-auto gap-x-3">
          {workCount?.map((item, index) => {
            const isChecked = checkboxStates[index] || false;
            return (
              <div
                onDoubleClick={(e) => {
                  setIsOpenWorkCountModal(true);
                }}
                onClick={(e) => {
                  toggleCheckbox(index);
                }}
                key={index}
                style={{
                  backgroundColor: isChecked ? `${colors[index % colors.length]}` : "#F2F4F7",
                  color: isChecked ? `${colors[index % colors.length]}` : "#98A2B3",
                }}
                className={`w-fit max-h-[48px] flex items-center py-0.5 px-2 rounded-lg gap-x-2 cursor-pointer border transition duration-300 ease-in-out ${isChecked ? `border-[${colors[index % colors.length]}]` : "border-[#EAECF0]"
                  }`}
              >
                <div
                  className={`flex min-w-[16px] max-w-[16px] bg-white min-h-[16px] max-h-[16px] rounded p-[2px] items-center justify-center border transition duration-300 ease-in-out ${isChecked ? `border-[${colors[index % colors.length]}]` : "border-[#D0D5DD]"
                    }`}
                >
                  {isChecked ? <CheckIcon color={`${colors[index % colors.length]}`} /> : null}
                </div>
                <div
                  style={{
                    backgroundColor: `${localStorage.color_theme === "dark" ? "#F4EBFF" : "#FFFFFF"}`,
                  }}
                  className="h-5 w-5 flex items-center justify-center font-semibold text-[10px] rounded-full p-2"
                >
                  {item?.workQty ? item?.workQty : 0}
                </div>

                <div className="flex flex-col">
                  <div className="flex flex-row items-center justify-start gap-x-2">
                    <span className={`text-xs font-normal ${isChecked ? "text-[#FAFAFA]" : "text-[#98A2B3]"}`}>
                      <QuantityIcon />
                    </span>
                    <span className={`text-sm font-semibold whitespace-nowrap ${isChecked ? "text-[#FAFAFA] dark:text-[#FFFFFF]" : "text-[#98A2B3]"}`}>
                      {item?.count ? item?.count : 0} {t("product:pcs")}
                    </span>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-x-2">
                    <span className={`text-xs font-normal ${isChecked ? "text-[#FAFAFA]" : "text-[#98A2B3]"}`}>
                      <ClockIcon3 />
                    </span>
                    <span className={` text-sm font-semibold whitespace-nowrap ${isChecked ? "text-[#FAFAFA] dark:text-[#FFFFFF]" : "text-[#98A2B3]"}`}>
                      {item?.maxRepeatValue ? item?.maxRepeatValue : 0} ± {item?.workTolerance ? item?.workTolerance : 0}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <CustomModal
        isOpen={isOpenWorkCountModal}
        setIsOpen={setIsOpenWorkCountModal}
        modalTitle={t("consoleModal:qty")}
        children={<QuantityModal t={t} workCount={workCount} onClose={() => setIsOpenWorkCountModal(false)} />}
      />
      <CustomModal
        isOpen={isUpdateTolerance}
        setIsOpen={setIsUpdateTolerance}
        modalTitle={t("product:updateToleranceValue")}
        width={400}
        children={<UpdateToleranceValue t={t} onClose={() => setIsUpdateTolerance(false)} metadataId={metadataId} refetch={refetch} />}
      />
      <CustomModal
        isOpen={openCycleTime}
        setIsOpen={setOpenCycleTime}
        modalTitle={t("product:targetWorkDurationSuggestion")}
        width={410}
        children={<CycleTimeSuggestionModal setOpenCycleTime={setOpenCycleTime} refetch={refetch} metadataId={metadataId} selectedJunction={selectedJunction} equipmentData={equipmentData} junctionData={junctionData} suggestedCycleTime={suggestedCycleTime} />}
      />
      <CustomModal
        isOpen={openSetupTime}
        setIsOpen={setOpenSetupTime}
        modalTitle={t("product:targetSetupDuration")}
        width={400}
        children={<TargetSettingTimeModal setOpenSetupTime={setOpenSetupTime} refetch={refetch} metadataId={metadataId} selectedJunction={selectedJunction} equipmentData={equipmentData} junctionData={junctionData} suggestedCycleTime={suggestedCycleTime} />}
      />
    </>
  );
});
export default WorkCountCard;

export const LightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M10 17.6586V20C10 21.1046 10.8954 22 12 22C13.1046 22 14 21.1046 14 20V17.6586M12 2V3M3 12H2M5.5 5.5L4.8999 4.8999M18.5 5.5L19.1002 4.8999M22 12H21M18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12Z" stroke="#7F56D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
export const ArrowRightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M4.16675 9.99984H15.8334M15.8334 9.99984L10.0001 4.1665M15.8334 9.99984L10.0001 15.8332" stroke="#9E77ED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export const ArrowRightIconSetup = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M4.16675 10.0001H15.8334M15.8334 10.0001L10.0001 4.16675M15.8334 10.0001L10.0001 15.8334" stroke="#EAAA08" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export const SettingsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 15.3333C10.0952 15.3333 8.55108 13.8409 8.55108 12C8.55108 10.159 10.0952 8.66665 12 8.66665C13.2216 8.66665 14.2949 9.28048 14.9076 10.2064M11.7489 22H10.1561C9.72749 22 9.36167 21.7005 9.29122 21.2919L9.09235 20.1388C8.86556 18.8235 7.45301 18.0353 6.16103 18.5031L5.02841 18.9132C4.62699 19.0585 4.17573 18.9021 3.96138 18.5433L2.11752 15.4567C1.90322 15.0979 1.98866 14.6419 2.31958 14.3786L3.25342 13.6357C4.31856 12.7882 4.31856 11.2118 3.25342 10.3643L2.31958 9.62131C1.98866 9.35801 1.90322 8.90208 2.11752 8.5433L3.96138 5.45665C4.17573 5.09788 4.62699 4.94144 5.02841 5.08678L6.16103 5.49686C7.45301 5.96466 8.86556 5.17648 9.09235 3.86119L9.29122 2.70809C9.36167 2.29949 9.72749 2 10.1561 2H13.8439C14.2725 2 14.6383 2.29949 14.7088 2.70809L14.9076 3.86119C15.1344 5.17648 16.547 5.96466 17.839 5.49686L18.9716 5.08678C19.373 4.94144 19.8243 5.09788 20.0386 5.45665L21.8825 8.5433C22.0968 8.90208 22.0113 9.35801 21.6804 9.62131L20.7466 10.3643C20.214 10.788 19.9477 11.394 19.9477 12M21.5955 17.6586L15.5624 21.7699C15.419 21.8676 15.2247 21.7649 15.2247 21.5914V13.4049C15.2247 13.2318 15.4182 13.129 15.5616 13.2259L21.5948 17.3011C21.7213 17.3866 21.7217 17.5727 21.5955 17.6586Z" stroke="#CA8504" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};