import React, { useEffect, useState } from "react";
import { junctionService } from "../../services/junction.service";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import Input from "../inputs/Input";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../buttons/Button";
import { Toast } from "../../utils/toastify/toast";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import Loading from "../loading/Loading";
import DateTimeInput from "../inputs/DateTimeInput";
import { useStore } from "../../hooks/useStores";
import Selector from "../inputs/Selector";
import {
  CalendarIcon,
  ClockIcon,
  QtyIcon,
} from "../../assets/icons/commonIcons";

const JunctionUpdate = ({ junctionId, onClose, refetch, pages }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { auth } = useStore();
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss")
  );

  const [endDate, setEndDate] = useState(
    moment(data?.endDate).format("YYYY-MM-DDTHH:mm:ss")
  );

  const handleData = async () => {
    setLoading(true);
    const data = await junctionService
      .getQualityJunctionDetail(junctionId)
      .catch((err) => Toast("error", String(err)));
    setData(data);
    setLoading(false);
  };

  const junctionTypes = [
    { label: t("product:work"), value: "work" },
    { label: t("product:setup"), value: "setup" },
  ];

  const dayStartHour = moment(
    auth?.user?.company?.dayStartHour,
    "HH:mm:ss"
  ).format("HH:mm:ss");

  const schema = yup.object({
    workPiece: yup.number(),
    startTime: yup.date(),
    endTime: yup.date(),
    type: yup.string().required(`${t("validation:empty")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rejectedPiece: 0,
    },
  });

  const onSubmit = async (value) => {
    const send = {
      junctionId: junctionId,
      workPiece: value?.workPiece,
      startDate: moment(startDate).utc().format(),
      endDate: moment(endDate).utc().format(),
      type: value?.type,
    };
    const startDateUnix = moment(startDate).unix();
    const endDateUnix = moment(endDate).unix();
    if (startDateUnix > endDateUnix) {
      Toast("error", t("product:qualityUpdateError"));
      return;
    } else {
      await junctionService.updateJunction(junctionId, send).then((res) => {
        if (res.data.code === 0) {
          refetch();
          onClose();
          Toast("success", res?.data?.message);
        } else {
          onClose();
          Toast("error", res?.data?.message);
        }
      });
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    setValue("workPiece", data?.metadata?.madeWorkCount);
    setValue("rejectedPiece", data?.metadata?.rejectedWorkCount);
    setValue("acceptedPiece", data?.metadata?.acceptedWorkCount);
    setValue("type", data?.type);
    setEndDate(data?.endDate);
    setStartDate(data?.startDate);
  }, [data]);

  return (
    <>
      {loading ? (
        <div className="flex items-center w-[440px] justify-center  h-[500px]">
          <Loading size={44} primary={"#B54708"} secondary={"#fff"} />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[440px] flex flex-col mt-6"
        >
          <div className="w-full flex gap-6 h-fit">
            <div className="w-full flex flex-col gap-4">
              <div className="w-full flex items-center h-[72px] gap-3">
                <img
                  src={generateFileUrl(data?.job?.product?.image)}
                  className="w-[96px] h-[72px] rounded border border-gray-300"
                />
                <div className="flex flex-col justify-between h-[72px]">
                  <p className="font-semibold text-gray-900">
                    {data?.job?.product?.name}
                  </p>
                  <p className="text-sm text-gray-600">
                    {t("product:orderNo")}: {data?.job?.orderNo}
                  </p>
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1">
                      <QtyIcon />
                      <p className="text-sm text-gray-600">
                        {data?.metadata?.madeWorkCount} / {data?.job?.piece}
                      </p>
                    </div>
                    {pages === "activeAndPlanned" ? (
                      <></>
                    ) : (
                      <>
                        <div className="flex items-center gap-1">
                          <ClockIcon />
                          <p className="text-sm text-gray-600">
                            {moment(endDate)
                              .diff(startDate, "minutes")
                              .toFixed(1) + "dk"}
                          </p>
                        </div>
                        <div
                          data-tooltip-id={`${data?.id}`}
                          data-tooltip-place={"top"}
                          data-tooltip-content={"Bitiş tarihi ve saati"}
                          className="flex items-center gap-1"
                        >
                          <CalendarIcon />
                          <p className="text-sm text-gray-600">
                            {moment(data?.endDate).format("DD-MM-YYYY HH:mm")}
                          </p>
                        </div>
                      </>
                    )}

                    <Tooltip id={`${data?.id}`} />
                  </div>
                </div>
              </div>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Selector
                    value={value}
                    items={junctionTypes}
                    theme={"product"}
                    label={t("product:junctionType")}
                    onChange={onChange}
                    disabled={pages === "activeAndPlanned" ? true : false}
                    onBlur={onBlur}
                    validate={errors?.source ? "error" : ""}
                    errorMessage={errors?.source ? errors?.source?.message : ""}
                  />
                )}
                name="type"
              />

              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    theme={"product"}
                    disabled={pages === "activeAndPlanned" ? true : false}
                    label={t("product:numberOfProducts")}
                    errorMessage={errors?.workPiece?.message}
                    validate={errors?.workPiece ? "error" : ""}
                  />
                )}
                name="workPiece"
              />

              <div className="flex flex-row items-center gap-x-3 w-full">
                <DateTimeInput
                  minDate={
                    moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss") <
                    moment(data?.startDate).format("YYYY-MM-DD") +
                      "T" +
                      dayStartHour
                      ? moment(data?.startDate)
                          .add(-1, "day")
                          .format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                      : moment(data?.startDate).format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                  }
                  maxDate={
                    moment(data?.startDate).add(1, "day").format("YYYY-MM-DD") +
                    "T" +
                    dayStartHour
                  }
                  label={t("product:jobStartDate")}
                  theme={"product"}
                  initialValue={moment(data?.startDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )}
                  onChange={(e) => {
                    setStartDate(e);
                  }}
                />
                <DateTimeInput
                  label={t("product:jobEndDate")}
                  theme={"product"}
                  disabled={pages === "activeAndPlanned" ? true : false}
                  minDate={
                    moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss") <
                    moment(data?.startDate).format("YYYY-MM-DD") +
                      "T" +
                      dayStartHour
                      ? moment(data?.startDate)
                          .add(-1, "day")
                          .format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                      : moment(data?.startDate).format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                  }
                  maxDate={
                    moment(data?.startDate).add(1, "day").format("YYYY-MM-DD") +
                    "T" +
                    dayStartHour
                  }
                  initialValue={moment(data?.endDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )}
                  onChange={(e) => {
                    setEndDate(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex items-center ml-auto justify-end gap-3 mt-8">
            <Button
              label={t("buttons:stop")}
              colorType={"secondary-gray"}
              size={"md"}
              onClick={onClose}
            />
            <Button
              type={"submit"}
              label={"Update Junction"}
              colorType={"primary-product"}
              size={"md"}
            />
          </div>
        </form>
      )}
    </>
  );
};

export default JunctionUpdate;
