import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CustomModal, Loading, PaginationComponent } from "../../components";
import { PlusIcon } from "../../assets/icons/stepsIcons";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import { useTranslation } from "react-i18next";
import { DeleteIcon, EditIcon } from "../../assets/icons/departmentIcon";
import generateFileUrl from "../../utils/generateFileUrl";
import diacritics from "diacritics";
import NoAvatar from "../../components/avatar/NoAvatar";
import AddCustomerGroupModal from "./modals/AddCustomerGroupModal";
import AddCustomerModal from "./modals/AddCustomerModal";
import { customerService } from "../../services/customer.service";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { Toast } from "../../utils/toastify/toast";
import DeleteCustomerGroupModal from "./modals/DeleteCustomerGroupModal";
import { DotsHorizontalIcon } from "../../assets/icons/productIcons";
import { EquipmentEditIcon, NewDotsIcon } from "../../components/new-cards/card-icons";
import { EquipmentDeleteIcon } from "../../assets/icons/machineIcons";

const CustomerList = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [tableData, setTableData] = useState([]);
  const [addCustomerGroup, setAddCustomerGroup] = useState(false);
  const [addCustomer, setAddCustomer] = useState(false);
  const [customerUpdate, setCustomerUpdate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [customerGroups, setCustomerGroups] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isAlertCustomer, setIsAlertCustomer] = useState(false);
  const [deleteCustomerId, setDeleteCustomerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});

  //new
  const [selectedSupGroup, setSelectedSupGroup] = useState("");

  const [deleteCustomerGroup, setDeleteCustomerGroup] = useState();

  const toggleDropdown = (id) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const closeDropdown = (id) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const menuOptions = [
    {
      label: t("table:edit"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  //new

  const handleCustomerList = async () => {
    setLoading(true);
    const { data } = await customerService.getCustomerList();
    setTableData(data);
    setLoading(false);
  };

  const handleCustomerGroupList = async () => {
    setLoading(true);
    const { data } = await customerService.getCustomerGroupList();
    setCustomerGroups(data);
    setLoading(false);
  };

  const tableHeadItems = [
    { name: t("table:image"), value: "image", type: "" },
    { name: t("addCompany:companyName"), value: "companyName", type: "" },
    { name: t("table:phoneNo"), value: "phoneNumber", type: "number" },
    { name: t("product:email"), value: "email", type: "string" },
    { name: t("addCompany:contactName"), value: "authorization", type: "string" },
    { name: t("product:billingAddress"), value: "address", type: "string" },
    { name: t("buttons:edit"), value: "edit" },
    { name: t("buttons:delete"), value: "delete", type: "" },
  ];

  const filteredSupplier = tableData?.filter((d) => diacritics.remove(`${d?.name}`).toLowerCase().includes(diacritics.remove(searchQuery).toLowerCase()));

  const onDeleted = async () => {
    await customerService.deleteCustomer(deleteCustomerId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        handleCustomerList();
        setIsAlertCustomer(false);
      } else {
        Toast("error", res.data.message);
        setIsAlertCustomer(false);
      }
    });
  };

  useEffect(() => {
    handleCustomerList();
    handleCustomerGroupList();
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col overflow-hidden">
        {loading ? (
          <div className="w-full h-[58px] min-h-[58px] rounded-lg bg-gray-200 animate-pulse" />
        ) : (
          <div className="flex w-full border-b h-14 min-h-[58px] items-center">
            <Button
              colorType={"tertiary-gray"}
              onClick={() => navigate(-1)}
              size={"sm"}
              iconLeft={
                <div className="rotate-180">
                  <TableArrowLeft />
                </div>
              }
            />

            <div className="flex items-center">
              <span className="text-2xl text-secondary-900 font-semibold ml-2">{t("product:customerList")}</span>
            </div>
            <div className="ml-auto flex flex-row items-center gap-x-3">
              <Button
                colorType={"primary-product"}
                size={"md"}
                label={t("product:createCustomer")}
                iconLeft={<PlusIcon />}
                onClick={() => {
                  setCustomerUpdate(null);
                  setAddCustomer(true);
                }}
              />
            </div>
          </div>
        )}

        {loading ? (
          <div className="w-full h-[48px] min-h-[48px] mt-3 mb-3 rounded-lg bg-gray-200 animate-pulse" />
        ) : (
          <div className="flex w-full items-center pb-4 pt-3 h-[64px] min-h-[64px] overflow-x-auto gap-4">
            <button
              onClick={() => {
                setSelectedSupGroup("");
                setAddCustomerGroup(true);
              }}
              type="button"
              className="h-9 w-9 min-w-[36px] min-h-[36px] max-h-[36px] max-w-[36px] rounded-lg border shadow items-center justify-center flex border-gray-300"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M10.0003 4.1665V15.8332M4.16699 9.99984H15.8337"
                  stroke="#344054"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              type="button"
              onClick={() => {
                handleCustomerList();
                setSelectedGroup("all");
              }}
              className={`min-w-[180px] px-4 h-9 rounded-lg border shadow ${selectedGroup === "all" ? "border-[#DC6803]" : "border-gray-300"}`}
            >
              <p className={`text-sm font-semibold ${selectedGroup === "all" ? "text-[#B54708]" : "text-gray-700"}`}>Tümü</p>
            </button>
            {customerGroups?.map((item) => (
              <button
                type="button"
                key={item?.id}
                onClick={async () => {
                  setSelectedGroup(item?.id);
                  await customerService.groupListForId(item?.id).then((res) => setTableData(res.data));
                }}
                className={`min-w-[180px] max-w-[180px] w-[180px] px-2 h-9 rounded-lg border shadow ${
                  selectedGroup === item?.id ? "border-[#DC6803]" : "border-gray-300"
                } relative group`}
              >
                <p className={`text-sm font-semibold truncate max-w-[140px] w-[140px] ${selectedGroup === item?.id ? "text-[#B54708]" : "text-gray-700"}`}>
                  {item?.name}
                </p>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(item?.id);
                  }}
                  className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[4px] top-[6px] z-[50] items-center justify-center cursor-pointer"
                >
                  <NewDotsIcon />
                </div>
                {openDropdowns[item?.id] && (
                  <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[24px] right-[12px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
                    {menuOptions?.map((items, index) => {
                      return (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (items.value === "delete") {
                              setSelectedSupGroup(item?.id);
                              setDeleteCustomerGroup(true);
                            }
                            if (items.value === "edit") {
                              setAddCustomerGroup(true);
                              setSelectedSupGroup(item);
                            }
                            closeDropdown(item?.id);
                          }}
                          key={index}
                          type="button"
                          className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                            items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                          }`}
                        >
                          {items?.icon}
                          <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{items?.label}</p>
                        </button>
                      );
                    })}
                  </div>
                )}
              </button>
            ))}
          </div>
        )}
        {loading ? (
          <div className="w-full h-[86vh] mt-3 rounded-lg bg-gray-200 animate-pulse" />
        ) : (
          <div className="flex-1 w-full  border rounded-xl flex flex-col overflow-hidden relative sm:px-11">
            <div id="content-wrapper" className="min-w-full max-w-full w-full h-[86vh] rounded-xl overflow-x-auto scrollbar-hide flex flex-col">
              <div className="flex max-h-[44px] min-h-[44px] max-w-full min-w-full">
                {tableHeadItems.map((item, i) => {
                  return (
                    <div
                      key={i}
                      scope="col"
                      className={`flex px-4 py-3 ${i === 7 ? "" : "border-r"} ${
                        i === 6 || i === 7
                          ? "w-[5%] justify-center items-center"
                          : i === 4
                          ? "w-[12%] justify-center items-center"
                          : i === 0
                          ? "w-[6%] justify-center items-center"
                          : i === 2
                          ? `w-[11%] justify-center items-center`
                          : i === 1
                          ? "w-[18%]"
                          : i === 3
                          ? "w-[15%] justify-center items-center"
                          : i === 4
                          ? "w-[12%] justify-center items-center"
                          : i == 5
                          ? `w-[28%]`
                          : ""
                      } items-center text-center bg-secondary-50 border-gray-200 border-b border-r`}
                    >
                      <span
                        className={`text-center rounded flex text-xs font-medium ${i === 1 || i === 6 ? "" : "justify-center"}  items-center whitespace-nowrap`}
                      >
                        {item?.name}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="w-full bg-[#FFF] flex-1 flex flex-col">
                {filteredSupplier?.map((item, key) => (
                  <div key={key} className={`flex w-full items-center border-b h-[72px] min-h-[72px] `}>
                    <div className="py-4  overflow-hidden flex items-center justify-center border-r h-[72px] min-w-[6%] max-w-[6%] w-[6%]">
                      <div className="w-[60px] h-[45px] min-w-[60px] min-h-[45px] rounded border border-secondary-200 overflow-hidden">
                        {item.image ? (
                          <img src={generateFileUrl(item?.image)} />
                        ) : (
                          <div className="w-[60px] h-[45px] min-w-[60px] min-h-[45px] flex rounded  overflow-hidden">
                            <NoAvatar name={item?.name || "--"} rounded={4} theme={"product"} minHeight="45px" minWidth="60px" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="min-w-[18%] max-w-[18%] w-[18%] border-r h-full p-3 flex items-center">
                      <p className="w-[90%] whitespace-nowrap  overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                        {item?.name || "--"}
                      </p>
                    </div>
                    <div className="border-r h-full p-3 flex items-center justify-center min-w-[11%] max-w-[11%] w-[11%]">
                      <p className="w-[90%] whitespace-nowrap text-center overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                        {item?.contactPhone || "--"}
                      </p>
                    </div>
                    <div className="border-r h-full p-3 flex items-center justify-center min-w-[15%] max-w-[15%] w-[15%]">
                      <p className="w-[90%] whitespace-nowrap overflow-hidden text-center text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                        {item?.contactEmail || "--"}
                      </p>
                    </div>
                    <div className="whitespace-nowrap h-full flex p-3 border-r text-center items-center justify-center text-sm text-gray-500 dark:text-[#F5F5F5] font-normal min-w-[12%] max-w-[12%] w-[12%]">
                      {item?.contactName || "--"}
                    </div>
                    <div className={`h-full border-r flex  p-3 items-center min-w-[28%] max-w-[28%] w-[28%]`}>
                      <p className="w-[100%] text-sm text-gray-500 dark:text-[#F5F5F5] font-normal text-ellipsis whitespace-nowrap overflow-hidden">
                        {item?.invoiceAddress || "--"}
                      </p>
                    </div>
                    <div className="h-full items-center border-r flex justify-center min-w-[5%] max-w-[5%] w-[5%]">
                      <Button
                        onClick={() => {
                          setAddCustomer(true);
                          setCustomerUpdate(item);
                        }}
                        colorType={"tertiary-gray"}
                        iconLeft={<EditIcon />}
                        size={"sm"}
                      />
                    </div>
                    <div className="h-full items-center flex justify-center min-w-[5%] max-w-[5%] w-[5%]">
                      <Button
                        colorType={"tertiary-error"}
                        iconLeft={<DeleteIcon />}
                        size={"sm"}
                        onClick={() => {
                          setIsAlertCustomer(true);
                          setDeleteCustomerId(item?.id);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="z-50 border-t border-[#D0D5DD] bg-white rounded-b-xl">
              <PaginationComponent pageNo={pageNo} setPageNo={setPageNo} pageCount={pageCount} />
            </div>
          </div>
        )}
      </div>
      <AlertModal
        title={t("product:deleteCustomer")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlertCustomer}
        setIsOpen={setIsAlertCustomer}
        applyButton={() => {
          onDeleted(deleteCustomerId);
        }}
      />
      <CustomModal
        isOpen={addCustomerGroup}
        setIsOpen={setAddCustomerGroup}
        modalTitle={t("product:createCustomerGroup")}
        children={<AddCustomerGroupModal closeModal={() => setAddCustomerGroup(false)} refetch={handleCustomerGroupList} selectedSupGroup={selectedSupGroup} />}
      />
      <CustomModal
        isOpen={addCustomer}
        setIsOpen={setAddCustomer}
        modalTitle={customerUpdate?.id ? t("product:editCustomer") : t("product:addCustomer")}
        width="fit-content"
        isBadges={false}
        children={
          <AddCustomerModal
            customerUpdateId={customerUpdate?.id}
            t={t}
            isOpen={addCustomer}
            setIsOpen={setAddCustomer}
            handleCustomerList={handleCustomerList}
          />
        }
      />

      <CustomModal
        isOpen={deleteCustomerGroup}
        setIsOpen={setDeleteCustomerGroup}
        modalTitle={"Müşteri Grubu Sil"}
        subTitle={"Silinen müşteri grubundaki müşterilerin aktarılacağı grubu seçin."}
        width={400}
        children={
          <DeleteCustomerGroupModal
            closeModal={() => setDeleteCustomerGroup(false)}
            refetch={handleCustomerGroupList}
            selectedSupGroup={selectedSupGroup}
            setSelectedGroup={setSelectedGroup}
          />
        }
      />
    </>
  );
};

export default CustomerList;
