import React, { useEffect, useState } from "react";
import SearchInput from "../inputs/SearchInput";
import Button from "../buttons/Button";
import Badgets from "../buttons/Badgets";
import { stepData } from "../../steps/step-navigation/components/StepHeaderTab";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowUpRight, PlusIcon } from "../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";

const StepsMainSide = ({
  setIsOpen,
  children,
  length,
  title,
  definedDataLength,
  undefinedDataLength,
  onClickSubmitButton = () => {},
  placeholder,
  addlabel,
  setSearchVal,
  limitlabel,
  isLimitReached,
  secondChildren,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  const [mobileTab, setMobileTab] = useState("undefined");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex justify-center mt-[72px] sm:mt-[15px] md:mt-[15px] dark:z-50">
      <div className="flex flex-col gap-8 xs:gap-6 z-[999] h-full">
        <div className="w-full min-w-[736px] sm:min-w-[560px] h-full xs:min-w-[365px] xs:h-[500px] sm:h-[75%] md:h-[75%] bg-[#fff] dark:bg-[#141414] border-[#EAECF0] dark:border-[#292929] border rounded-xl border-solid shadow-md p-6 flex flex-col gap-6">
          <div className="flex justify-between">
            <p className=" text-xl font-semibold xs:text-lg text-[#101828] dark:text-[#FAFAFA]">
              {title}
            </p>
            <span
              className={`${pathname?.includes("equipment") ? "hidden" : ""}`}
            >
              {isLimitReached ? (
                <Badgets label={length} colorType={"fill-error"} />
              ) : (
                <>
                  <div className="block xs:hidden">
                    <Badgets
                      size={"md"}
                      label={length}
                      colorType={"fill-primary"}
                    />
                  </div>
                  <div className="xs:block hidden">
                    <Badgets
                      size={"sm"}
                      label={length}
                      colorType={"fill-primary"}
                    />
                  </div>
                </>
              )}
            </span>
          </div>
          <div className={`${pathname.includes("shift") ? "hidden" : ""}`}>
            <SearchInput
              setSearchVal={setSearchVal}
              placeholder={placeholder}
            />
          </div>

          {isMobile ? (
            <>
              <div
                className={`h-8 w-full flex items-start self-stretch ${
                  pathname.includes("equipment") ? "flex" : "hidden"
                }`}
              >
                <div
                  role="button"
                  onClick={() => {
                    setMobileTab("undefined");
                  }}
                  className={`${
                    mobileTab === "undefined"
                      ? "border-b-[2px] border-solid border-[#6941C6]"
                      : "border-b-[2px] border-solid border-secondary-200"
                  }  w-1/2 h-full pb-3 flex flex-col items-center gap-3 flex-1`}
                >
                  <span
                    className={` ${
                      mobileTab === "undefined"
                        ? "text-[#6941C6]"
                        : "text-[#667085]"
                    } text-center font-semibold text-sm`}
                  >
                    undefined
                  </span>
                </div>
                <div
                  role="button"
                  onClick={() => {
                    setMobileTab("defined");
                  }}
                  className={` ${
                    mobileTab === "defined"
                      ? "border-b-[2px] border-solid border-[#6941C6]"
                      : "border-b-[2px] border-solid border-secondary-200"
                  }   w-1/2 h-full pb-3 flex flex-col items-center gap-3 flex-1  `}
                >
                  <span
                    className={`${
                      mobileTab === "defined"
                        ? "text-[#6941C6] "
                        : "text-[#667085]"
                    }  text-center font-semibold text-sm `}
                  >
                    defined
                  </span>
                </div>
              </div>
            </>
          ) : (
            <div
              className={`h-10 bg-[#FCFCFD] dark:bg-[#141414] dark:border-[#292929] border w-full border-[#EAECF0] ${
                pathname.includes("equipment") ? "flex" : "hidden"
              }`}
            >
              <div className="flex items-center w-5/12  gap-3 py-2 pl-6 dark:text-[#FAFAFA]">
                <p>{t("equipments:undefined")}</p>
                <Badgets
                  colorType={"fill-blue"}
                  label={undefinedDataLength}
                  size={"sm"}
                />
              </div>
              <div className="flex items-center gap-3 pl-8 py-2 w-7/12 dark:text-[#FAFAFA]">
                <p>{t("equipments:defined")}</p>
                <Badgets
                  colorType={"fill-success"}
                  label={definedDataLength}
                  size={"sm"}
                />
              </div>
            </div>
          )}

          <div className="w-full flex flex-col gap-4 max-h-[270px]  min-h-[270px] xs:min-h-[200px] xs:max-h-[300px] overflow-auto pb-4 scrollbar-hide dark:border-[#292929]">
            {mobileTab == "undefined" ? children : secondChildren}
          </div>
          <div
            onClick={() => {
              onClickSubmitButton();
            }}
            className={`shadow-xs ${
              pathname.includes("equipment") ? "hidden" : ""
            }`}
          >
            {isLimitReached ? (
              <Button
                iconLeft={<ArrowUpRight />}
                colorType={"secondary-error"}
                size={"xl"}
                label={limitlabel}
                onClick={() => {}}
              />
            ) : (
              <Button
                iconLeft={<PlusIcon />}
                colorType={"secondary-gray"}
                size={"xl"}
                label={addlabel}
                onClick={() => setIsOpen(true)}
              />
            )}
          </div>
        </div>
        <div className="flex gap-6">
          <Button
            colorType={"secondary-gray"}
            label={t("buttons:previous")}
            size={"xl"}
            onClick={() => {
              const index = stepData.findIndex((route) =>
                pathname.includes(route.path)
              );
              navigate("/step/" + stepData[index - 1].path);
            }}
          />
          <Button
            colorType={"primary-machine"}
            label={t("buttons:next")}
            size={"xl"}
            onClick={() => {
              const index = stepData.findIndex((route) =>
                pathname.includes(route.path)
              );
              navigate("/step/" + stepData[index + 1].path);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StepsMainSide;
