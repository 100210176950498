import React, { useEffect, useState } from "react";
import Header from "./embeddedServer/Header";
import { useTranslation } from "react-i18next";
import { Toast } from "../../utils/toastify/toast";
import { authStore } from "../../stores/auth.store";
import { useNavigate, useParams } from "react-router-dom";
import PriorityTable from "./embeddedServer/PriorityTable";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import AnalogSensorList from "./embeddedServer/AnalogSensorList";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { Loading } from "./embeddedServer/svg/EmbeddedServerIcons";
import DigitalSensorList from "./embeddedServer/DigitalSensorList";
import { equipmentService } from "../../services/equipment.service";
import { embeddedServerService } from "../../services/embedded-server.service";
import { renameSensor } from "../../utils/chooseSensorName";
const EmbeddedServer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { nodeId } = useParams();
  const pattern = /^(.*?)-(.+)$/;
  const match = nodeId.match(pattern);
  const [resetAlert, setResetAlert] = useState(false);
  const [nodeLoading, setNodeLoading] = useState(true);
  const [connectedNode, setConnectedNode] = useState(null);
  const [activeSensors, setActiveSensors] = useState(null);
  const [registeredSensors, setRegisteredSensors] = useState(null);
  const [towerSatus, setTowerSatus] = useState(null);
  const [draggedSensor, setDraggedSensor] = useState(null);
  const [priorityList, setPriorityList] = useState([]);
  const [cards, setCards] = useState([]);
  const [deactives, setDeactives] = useState([]);
  const channel = useChannel(match[1]);
  useEvent(channel, "active-sensor-list", (socketEvent) => {
    setActiveSensors(socketEvent);
  });

  const priorityChannel = useChannel(match ? `${match[1]}-${authStore.user.id}` : null);
  useEvent(priorityChannel, "logout-embedded-server", (socket) => {
    Toast("error", socket?.message);
    navigate(-1);
  });

  const getNodeInformations = async () => {
    await equipmentService
      .findOneNode(match?.[2])
      .then(({ data }) => {
        setConnectedNode(data);
        setNodeLoading(false);
      })
      .catch(() => {
        Toast("error", t("consoleModal:connectToServer"));
        navigate(-1);
      });
  };

  const getRegisteredSensors = async () => {
    if (connectedNode) {
      await embeddedServerService
        .getSensorSettings(connectedNode?.metadata?.id)
        .then((data) => {
          setRegisteredSensors(data);
        })
        .catch(() => {
          Toast("error", t("consoleModal:sensorError"));
          navigate(-1);
        });
    }
  };

  const cancelButton = async () => {
    await embeddedServerService
      .stateEmbeddedServer(match[1], 0)
      .then((res) => {
        if (res.code === 1) {
          Toast("success", res.message);
          navigate(-1);
        } else {
          Toast("error", t("embedded:internetConnection"));
        }
      })
      .catch((err) => Toast("error", t("embedded:networkError")));
  };

  const resetDevice = async () => {
    await embeddedServerService.resetDevice(match[1]).then(() => setResetAlert(false));
  };

  const deviceInfo = {
    ring: "#F2F4F7",
    nodeId: match[1],
    activeAnalog: "-",
    activeDigital: "-",
    rssi: connectedNode?.rssi,
    status: connectedNode?.status,
    ipAddress: connectedNode?.ipAddress,
    macAddress: connectedNode?.macAddress,
    image: connectedNode?.metadata?.image,
    stmVersion: connectedNode?.stmVersion,
    espVersion: connectedNode?.espVersion,
    deviceState: connectedNode?.deviceState,
    name: connectedNode?.metadata?.brand + " " + connectedNode?.metadata?.model,
    analog: {
      first: activeSensors?.A1,
      second: activeSensors?.A2,
      third: activeSensors?.A3,
      fourth: activeSensors?.A4,
      fifth: activeSensors?.A2 === 1 && activeSensors?.A3 === 1 && activeSensors?.A4 === 1 ? 1 : 0,
    },
    digital: {
      first: activeSensors?.D1,
      second: activeSensors?.D2,
      third: activeSensors?.D3,
      fourth: activeSensors?.D4,
    },
  };

  useEffect(() => {
    const newCards = cards.map((el) => {
      let name = renameSensor(el?.type, el?.whichSensor ? el?.whichSensor : el?.analogSensor ? el?.analogSensor?.whichSensor : el?.digitalSensor?.whichSensor);
      return {
        ...el,
        isDeactive: activeSensors
          ? name
            ? activeSensors[name] == 1
              ? false
              : true
            : activeSensors?.A2 === 1 && activeSensors?.A3 === 1 && activeSensors?.A4 === 1
              ? false
              : true
          : true,
      };
    });
    setDeactives(newCards?.filter((a) => a.isDeactive == true)?.map((a) => a.order));
  }, [cards, activeSensors]);

  useEffect(() => {
    Toast("info", t("embedded:embeddedServerSession"));
    getNodeInformations();
  }, []);

  useEffect(() => {
    getRegisteredSensors();
  }, [connectedNode, setConnectedNode]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = t("embedded:leavePage");
      event.returnValue = message;
      return message;
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <div deviceInfo={deviceInfo} className="w-full h-full flex flex-col pt-3 overflow-hidden">
        <Header towerStatus={towerSatus} deviceInfo={deviceInfo} loading={nodeLoading} cancelButton={cancelButton} setResetAlert={setResetAlert} />
        <div className="flex flex-1 w-full overflow-y-auto justify-between">
          <div className="w-[53%] h-full">
            <DigitalSensorList
              deviceId={match[1]}
              cards={cards}
              data={registeredSensors}
              priorityList={priorityList}
              refetch={getRegisteredSensors}
              activeList={deviceInfo.digital}
              setDraggedSensor={setDraggedSensor}
              metadataId={connectedNode?.metadata?.id}
            />
            <AnalogSensorList
              setCards={setCards}
              cards={cards}
              deviceInfo={deviceInfo}
              data={registeredSensors}
              priorityList={priorityList}
              refetch={getRegisteredSensors}
              activeList={deviceInfo.analog}
              deviceId={connectedNode?.deviceId}
              setDraggedSensor={setDraggedSensor}
              registeredSensors={registeredSensors}
              metadataId={connectedNode?.metadata?.id}
            />
          </div>
          <div className="w-[45%] h-full py-0.5 pr-0.5">
            {nodeLoading ? (
              <div className="w-full rounded-xl h-full bg-gray-200 animate-pulse flex items-center justify-center">
                <Loading size={45} color={"#3E4784"} secondary={"#fff"} />
              </div>
            ) : (
              <PriorityTable
                cards={cards}
                setCards={setCards}
                deviceId={match[1]}
                setTowerSatus={setTowerSatus}
                draggedSensor={draggedSensor}
                setPriorityList={setPriorityList}
                metadataId={connectedNode?.metadata?.id}
                getRegisteredSensors={getRegisteredSensors}
                deactives={deactives}
              />
            )}
          </div>
        </div>
      </div>

      <AlertModal
        isOpen={resetAlert}
        setIsOpen={setResetAlert}
        applyButton={resetDevice}
        label={t("buttons:reset")}
        title={t("embedded:resetDevice")}
        subTitle={t("embedded:actionCannot")}
      />
    </>
  );
};

export default React.memo(EmbeddedServer);
