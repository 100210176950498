import React, { useEffect } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, UploadProfile } from "../../../components";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextArea from "../../../components/inputs/TextArea";
import { purchaseService } from "../../../services/purchase.service";
import { Toast } from "../../../utils/toastify/toast";
import { DateIcon, QtyIcon3 } from "../../../assets/icons/commonIcons";

function RawQualityControlForm({ rawData, junctionData, refetch, close }) {
  const { t } = useTranslation(["product"]);
  const schema = yup.object({
    qualityAcceptedFile: yup.string().notRequired(),
    qualityRejectedFile: yup.string().notRequired(),
    qualityDescription: yup.string().notRequired(),
    rejectedUnit: yup.number(),
    incomingUnit: yup.number(),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rejectedUnit: 0,
      incomingUnit: 0,
    },
  });

  const onSubmit = async (value) => {
    const send = {
      incomingUnit: value?.incomingUnit,
      rejectedUnit: value?.rejectedUnit,
      qualityDescription: value?.qualityDescription || "",
      qualityAcceptedFile: value?.qualityAcceptedFile || "",
      qualityRejectedFile: value?.qualityRejectedFile || "",
      acceptedUnit: value?.incomingUnit - value?.rejectedUnit,
    };
    await purchaseService
      .purchaseQuality(junctionData?.id, send)
      .then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          refetch();
          close();
        } else {
          Toast("error", res.data.message);
        }
      });
  };

  useEffect(() => {
    if (junctionData?.incomingUnit) {
      setValue("incomingUnit", junctionData?.incomingUnit);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
      <div className="flex flex-row w-full  mt-6 border border-secondary-300 rounded-lg pl-2 h-full ">
        <div className="w-full flex flex-col py-2">
          <div className="flex flex-row gap-x-2 items-center  ">
            {rawData?.image ? (
              <img
                src={generateFileUrl(rawData?.image)}
                className="min-w-[64px] w-[64px] h-[48px] rounded border border-secondary-300 object-cover"
              />
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}

            <div className="flex flex-col items-start">
              <p className="text-lg text-secondary-900 w-[330px] truncate font-medium">
                {rawData?.name || "---"}
              </p>
              <p className="text-sm text-secondary-600">
                {t("product:stockId")}: {rawData?.stockCode || "---"}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-3">
            <div className="flex flex-row items-center gap-x-1 mt-2">
              <QtyIcon3 />
              <p className="text-xs font-medium text-secondary-600">
                {rawData?.qualityQuantity === null
                  ? 0
                  : rawData?.qualityQuantity}
              </p>
              <p className="text-[10px] text-secondary-500 font-normal">
                {t("product:totalStocks")}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1 mt-2">
              <DateIcon />
              <p className="text-xs font-medium text-secondary-600">
                {rawData?.deliveryDate || "---"}
              </p>
              <p className="text-[10px] text-secondary-500 font-normal">
                {t("product:deliveryDate")}
              </p>
            </div>
          </div>
        </div>
        <div className="h-full flex items-start border-l rounded-bl-lg border-r-0 border-t-0 border-b bg-[#EAECF0] border border-[#D0D5DD] p-[3px]">
          <div className=" w-6 h-[70px] flex items-center justify-center">
            <p className="-rotate-90 text-[10px] font-normal text-secondary-600 tracking-widest">
              {t("product:rawMaterials")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-6 gap-y-4">
        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                theme={"product"}
                label={t("product:receivedQuantity")}
                errorMessage={errors?.acceptedPiece?.message}
                validate={errors?.acceptedPiece ? "error" : ""}
              />
            )}
            name="incomingUnit"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                theme={"product"}
                label={t("product:rejectedQuantityss")}
                errorMessage={errors?.rejectedUnit?.message}
                validate={errors?.rejectedUnit ? "error" : ""}
              />
            )}
            name="rejectedUnit"
          />
        </div>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextArea
              label={t("product:descOptional")}
              width={"100%"}
              onBlur={onBlur}
              onChange={onChange}
              theme={"product"}
              rows={3}
              value={value}
            />
          )}
          name="qualityDescription"
        />
        <div className="flex flex-col">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <UploadProfile
                title={t("product:qualityAttachmentOne")}
                subTitle={t("fileInput:removePicture")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
            name="qualityAcceptedFile"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <UploadProfile
                title={t("product:qualityAttachmentTwo")}
                subTitle={t("fileInput:removePicture")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
            name="qualityRejectedFile"
          />
        </div>
      </div>
      <div className="flex mt-6 w-full">
        <Button
          colorType={"primary-product"}
          type={"submit"}
          label={t("buttons:submitForm")}
          size={"lg"}
        />
      </div>
    </form>
  );
}

export default RawQualityControlForm;
