import React, { useState } from "react";
import { Button } from "../../../../components";
import moment from "moment";
import { PlusIcon } from "../../../../assets/icons/machineIcons";
import LegacyDateTimeInput from "../../../../components/inputs/LegacyDateTimeInput";
import { Toast } from "../../../../utils/toastify/toast";
import { SaveIcon } from "../../embeddedServer/svg/EmbeddedServerIcons";
import { useTranslation } from "react-i18next";

const TimeBreak = ({
  setRightTab,
  rightTab,
  endValidateError,
  setEndValidateError,
  validateError,
  setValidateError,
  setBreakTimes,
  breakTimes,

  defaultStartForm,
  defaultEndForm,
  endBeforeStartError,
  setEndBeforeStartError,
  breakOverlapError,
  breakOutOfRangeError,
  breakEndBeforeStartError,
  setBreakOverlapError,
  setBreakOutOfRangeError,
  setBreakEndBeforeStartError,

  //selectedDates

  setSelectedDates,
  selectedDates,
  newBreak,
  setNewBreak,
}) => {
  const { t } = useTranslation();
  const [editIndex, setEditIndex] = useState(null);
  const [molaEkle, setMolaEkle] = useState(true);

  const handleNewBreakChange = (field, value) => {
    setNewBreak({ ...newBreak, [field]: value });
  };

  const handleEdit = (index) => {
    setNewBreak(breakTimes[index]);
    setEditIndex(index);
    setMolaEkle(true);
  };

  const handleDelete = (index) => {
    setBreakTimes(breakTimes.filter((_, i) => i !== index));
  };

  const validateNewBreak = () => {
    setBreakOverlapError(false);
    setBreakOutOfRangeError(false);
    setBreakEndBeforeStartError(false);

    for (let breakTime of breakTimes) {
      if (
        moment(newBreak.start).isBetween(moment(breakTime.start), moment(breakTime.end)) ||
        moment(newBreak.end).isBetween(moment(breakTime.start), moment(breakTime.end)) ||
        moment(newBreak.start).isSame(moment(breakTime.start)) ||
        moment(newBreak.end).isSame(moment(breakTime.end))
      ) {
        setBreakOverlapError(true);
        return false;
      }
    }

    if (moment(newBreak.start).isBefore(moment(selectedDates?.start)) || moment(newBreak.end).isAfter(moment(selectedDates?.end))) {
      setBreakOutOfRangeError(true);
      return false;
    }

    if (moment(newBreak.end).isBefore(moment(newBreak.start))) {
      setBreakEndBeforeStartError(true);
      return false;
    }

    return true;
  };

  const addNewBreakTime = () => {
    if (validateNewBreak()) {
      setBreakTimes([...breakTimes, newBreak]);
      setNewBreak({ start: "", end: "" });
      setMolaEkle(true);
    }
  };

  const saveEditedBreakTime = () => {
    // if (validateNewBreak()) {
    const updatedBreakTimes = [...breakTimes];
    updatedBreakTimes[editIndex] = newBreak;
    setBreakTimes(updatedBreakTimes);
    setNewBreak({ start: "", end: "" });
    setEditIndex(null);
    setMolaEkle(true);
    setBreakOverlapError(false);
    setBreakOutOfRangeError(false);
    // }
  };

  return (
    <div className="flex flex-col w-[30%] min-w-[30%] h-full pr-6">
      <div className="flex w-full min-h-[40px] max-h-[40px] h-10 border border-[#D0D5DD] rounded-lg">
        <div
          onClick={() => {
            setRightTab("time");
          }}
          className={`flex w-1/2 border-r border-[#D0D5DD] items-center justify-center cursor-pointer rounded-l-lg ${
            rightTab === "time" ? "bg-[#F9FAFB]" : ""
          }`}
        >
          <p className="text-[#344054] font-semibold text-sm">{t("addEquipment:eventStartEnd")}</p>
        </div>
        <div
          onClick={() => {
            if (selectedDates?.start === "" || selectedDates?.end === "") {
              Toast("error", "Eylem başlangıç ve bitiş zamanlarını seçmelisiniz");
            } else {
              setRightTab("breaks");
            }
          }}
          className={`flex w-1/2 items-center justify-center cursor-pointer rounded-r-lg ${rightTab === "breaks" ? "bg-[#F9FAFB]" : ""}`}
        >
          <p className="text-[#344054] font-semibold text-sm">{t("addShift:breaks")}</p>
        </div>
      </div>

      {rightTab === "time" ? (
        <div className="flex flex-col w-full mt-6 gap-y-6">
          <div className="flex flex-col w-full gap-y-4">
            <div className="flex flex-col w-full">
              <LegacyDateTimeInput
                label={t("addEquipment:startDateAndTime")}
                theme={"machine"}
                minDate={defaultStartForm}
                maxDate={selectedDates?.end}
                setDate={setSelectedDates}
                date={selectedDates?.start || defaultStartForm}
                onChange={(e) => {
                  if (moment(e).isBefore(moment(defaultStartForm))) {
                    setValidateError(true);

                    setSelectedDates({ ...selectedDates, start: e });
                  } else {
                    setSelectedDates({ ...selectedDates, start: e });
                    setValidateError(false);
                  }
                }}
              />
              {validateError && <span className="text-xs text-red-500 mt-2">{t("addEquipment:theStartOfCannot")}</span>}
            </div>

            <div className="flex flex-col w-full">
              <LegacyDateTimeInput
                label={t("addEquipment:endDateAndTime")}
                theme={"machine"}
                minDate={selectedDates?.start}
                date={selectedDates.end == "" ? new Date() : selectedDates.end}
                maxDate={defaultEndForm}
                setDate={setSelectedDates}
                onChange={(e) => {
                  if (moment(e).isBefore(moment(defaultStartForm)) || moment(e).isAfter(moment(defaultEndForm))) {
                    setEndValidateError(true);

                    setSelectedDates({ ...selectedDates, end: e });
                  } else {
                    setSelectedDates({ ...selectedDates, end: e });
                    setEndValidateError(false);
                    if (moment(e).isBefore(moment(selectedDates?.start))) {
                      setEndBeforeStartError(true);
                    } else {
                      setEndBeforeStartError(false);
                    }
                  }
                }}
              />
              {endValidateError && <span className="text-xs text-red-500 mt-2">{t("addEquipment:theEndDateMustBe")}</span>}
              {endBeforeStartError && <span className="text-xs text-red-500 mt-2">{t("addEquipment:theEndDateCannotBe")}</span>}
            </div>
          </div>
          <Button colorType={"primary-machine"} label={t("addEquipment:saveStartEndTime")} />
        </div>
      ) : (
        <div className="flex flex-col w-full mt-6 gap-y-6 h-full">
          <div className="flex flex-col w-full gap-y-2">
            {breakTimes?.length === 0 ? null : (
              <div className="flex w-full ">
                
                <p className="w-1/2 text-[#344054] font-normal text-xs">{t("chat:startTime")}</p>
                <p className="w-1/2 text-[#344054] font-normal text-xs">{t("chat:endTime")}</p>
              </div>
            )}

            <div className="flex flex-col w-full gap-y-3">
              {breakTimes?.map((breakTime, index) => (
                <div key={index} className="flex w-full gap-x-1 max-h-[20px] min-h-[20px]">
                  <div className="text-xs flex items-center justify-center text-[#475467]  rounded-sm p-1 bg-slate-200">{index + 1}</div>
                  <div className="flex w-1/2 text-[#475467] font-medium text-sm">{moment(breakTime?.start).format("DD.MM.YYYY - HH:mm")}</div>
                  <div className="flex w-1/2 text-[#475467] font-medium text-sm">{moment(breakTime?.end).format("DD.MM.YYYY - HH:mm")}</div>
                  <div className="flex min-w-[44px] max-w-[44px] min-h-[20px] max-h-[20px] gap-x-1 items-center justify-center">
                    {editIndex + 1 > 0 && editIndex == index ? (
                      <span
                        className="p-1 hover:bg-[#E9D7FE] rounded-lg cursor-pointer"
                        onClick={() => {
                          // handleDelete(index);
                          saveEditedBreakTime();
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 20" fill="none">
                          <path
                            d="M6.33333 2.5V5.33333C6.33333 5.80004 6.33333 6.0334 6.42416 6.21166C6.50406 6.36846 6.63154 6.49594 6.78834 6.57584C6.9666 6.66667 7.19996 6.66667 7.66667 6.66667H13.3333C13.8 6.66667 14.0334 6.66667 14.2117 6.57584C14.3685 6.49594 14.4959 6.36846 14.5758 6.21166C14.6667 6.0334 14.6667 5.80004 14.6667 5.33333V3.33333M14.6667 17.5V12.1667C14.6667 11.7 14.6667 11.4666 14.5758 11.2883C14.4959 11.1315 14.3685 11.0041 14.2117 10.9242C14.0334 10.8333 13.8 10.8333 13.3333 10.8333H7.66667C7.19996 10.8333 6.9666 10.8333 6.78834 10.9242C6.63154 11.0041 6.50406 11.1315 6.42416 11.2883C6.33333 11.4666 6.33333 11.7 6.33333 12.1667V17.5M18 7.77124V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V6.5C3 5.09987 3 4.3998 3.27248 3.86502C3.51217 3.39462 3.89462 3.01217 4.36502 2.77248C4.8998 2.5 5.59987 2.5 7 2.5H12.7288C13.1364 2.5 13.3402 2.5 13.5321 2.54605C13.7021 2.58688 13.8647 2.65422 14.0138 2.7456C14.182 2.84867 14.3261 2.9928 14.6144 3.28105L17.219 5.88562C17.5072 6.17387 17.6513 6.318 17.7544 6.48619C17.8458 6.63531 17.9131 6.79789 17.9539 6.96795C18 7.15976 18 7.36358 18 7.77124Z"
                            stroke="#475467"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    ) : (
                      <>
                        <span
                          className="p-1 hover:bg-[#E9D7FE] rounded-lg cursor-pointer"
                          onClick={() => {
                            // handleDelete(index);
                            handleEdit(index);
                          }}
                        >
                          <EditIcon />
                        </span>
                      </>
                    )}

                    <span className="p-1 hover:bg-[#E9D7FE] rounded-lg cursor-pointer" onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {molaEkle ? (
            <>
              <div className="flex flex-col w-full gap-y-4">
                <LegacyDateTimeInput
                  date={newBreak?.start}
                  value={newBreak?.start}
                  onChange={(e) => handleNewBreakChange("start", e)}
                  label={`${editIndex == null ? breakTimes?.length + 1 : editIndex + 1} . ${t("product:breakDateAndTime")}`}
                  setDate={handleNewBreakChange}
                  minDate={selectedDates?.start}
                  maxDate={selectedDates?.end}
                />
                <LegacyDateTimeInput
                  minDate={selectedDates?.start}
                  maxDate={selectedDates?.end}
                  date={newBreak?.end}
                  value={newBreak?.end}
                  onChange={(e) => {
                    handleNewBreakChange("end", e);
                  }}
                  label={`${editIndex == null ? breakTimes?.length + 1 : editIndex + 1} . ${t("product:breakDateAndTime")}`}
                  setDate={handleNewBreakChange}
                />
              </div>
              {breakOverlapError && <span className="text-xs text-red-500">Mola zamanları birbiriyle çakışamaz</span>}
              {breakOutOfRangeError && <span className="text-xs text-red-500">Mola zamanları eylem zaman aralığında olmalıdır</span>}
              {breakEndBeforeStartError && <span className="text-xs text-red-500">Mola bitiş tarihi mola başlangıç tarihinden küçük olamaz</span>}
              <div className="flex w-full">
                <Button
                  colorType={"primary-machine"}
                  label={editIndex !== null ? t("product:updateBreakTime") : t("product:saveBreakTime")}
                  size={"md"}
                  onClick={editIndex !== null ? saveEditedBreakTime : addNewBreakTime}
                />
              </div>
            </>
          ) : (
            <div className="flex w-full">
              <Button
                size={"md"}
                iconLeft={<PlusIcon />}
                colorType={"secondary-gray"}
                label={t("product:addBreaks")}
                onClick={() => {
                  setMolaEkle(true);
                  addNewBreakTime();
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimeBreak;

export const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.6667 4.00016V3.46683C10.6667 2.72009 10.6667 2.34672 10.5213 2.06151C10.3935 1.81063 10.1895 1.60665 9.93865 1.47882C9.65344 1.3335 9.28007 1.3335 8.53333 1.3335H7.46667C6.71993 1.3335 6.34656 1.3335 6.06135 1.47882C5.81046 1.60665 5.60649 1.81063 5.47866 2.06151C5.33333 2.34672 5.33333 2.72009 5.33333 3.46683V4.00016M6.66667 7.66683V11.0002M9.33333 7.66683V11.0002M2 4.00016H14M12.6667 4.00016V11.4668C12.6667 12.5869 12.6667 13.147 12.4487 13.5748C12.2569 13.9511 11.951 14.2571 11.5746 14.4488C11.1468 14.6668 10.5868 14.6668 9.46667 14.6668H6.53333C5.41323 14.6668 4.85318 14.6668 4.42535 14.4488C4.04903 14.2571 3.74307 13.9511 3.55132 13.5748C3.33333 13.147 3.33333 12.5869 3.33333 11.4668V4.00016"
        stroke="#D92D20"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M1.91732 12.0771C1.94795 11.8015 1.96326 11.6636 2.00497 11.5348C2.04197 11.4205 2.09425 11.3117 2.16038 11.2114C2.23493 11.0984 2.33299 11.0003 2.52911 10.8042L11.3333 2.00004C12.0697 1.26366 13.2636 1.26366 14 2.00004C14.7364 2.73642 14.7364 3.93033 14 4.66671L5.19578 13.4709C4.99966 13.667 4.9016 13.7651 4.78855 13.8396C4.68826 13.9058 4.57949 13.958 4.46519 13.995C4.33636 14.0367 4.19853 14.0521 3.92287 14.0827L1.66663 14.3334L1.91732 12.0771Z"
        stroke="#475467"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
