import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CustomModal } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { HumburgerIcon, InfoIcon } from "../../../assets/icons/leftMenuIcons";
import { CalenderIcon } from "../../../assets/icons/PersonIcons";
import { DeleteIcon, OrderQuantityIcon } from "../../../components/new-cards/card-icons";
import { orderService } from "../../../services/order.service";
import OrderHeaderCards from "../components/order/OrderHeaderCards";
import generateFileUrl from "../../../utils/generateFileUrl";
import moment from "moment";
import { customerService } from "../../../services/customer.service";
import { observer } from "mobx-react-lite";
import SearchInput from "../../../components/inputs/SearchInput";
import diacritics from "diacritics";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { useStore } from "../../../hooks/useStores";
import AddCustomerGroupModal from "../modals/AddCustomerGroupModal";
import { DotsHorizontalIcon } from "../../../assets/icons/productIcons";
import EditOrderModal from "../modals/EditOrderModal";
import Badgets from "../../../components/buttons/Badgets";
import WorkOrderModal from "../modals/WorkOrderModal";
import { Toast } from "../../../utils/toastify/toast";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import DeleteOrderProduct from "../modals/DeleteOrderProduct";
import { FlipBackwardIcon, FlipForwardIcon, ListIcon } from "../../../assets/icons/machineIcons";
import LegacyWipExitModal from "../modals/LegacyWipExitModal";
import MoveWorkOrderModal from "../modals/MoveWorkOrderModal";
import { useQuery } from "@tanstack/react-query";

const NewOrderAndSupply = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [selectedOperation, setSelectedOperation] = useState("all");
  const [selectedOrder, setSelectedOrder] = useState(false);
  const [selectedOrderDetail, setSelectedOrderDetail] = useState(null);
  const [headData, setHeadData] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [searchVal, setSearchVal] = useState();
  const [customerGroups, setCustomerGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addCustomerGroup, setAddCustomerGroup] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [editOrderData, setEditOrderData] = useState(null);
  const [editOrderModal, setEditOrderModal] = useState(false);
  const [isOpenWorkOrderModal, setIsOpenWorkOrderModal] = useState(false);
  const [stockOrderList, setStockOrderList] = useState();
  const [isDetailId, setIsDetailId] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [openWipExitModal, setOpenWipExitModal] = useState(false);
  const [isFirstModal, setIsFirstModal] = useState(true);
  const [openOrderIds, setOpenOrderIds] = useState([]);

  const toggleDropdown = (id) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const toggleDetails = (orderId) => {
    setOpenOrderIds((prev) => {
      if (prev.includes(orderId)) {
        return prev.filter((id) => id !== orderId);
      } else {
        return [...prev, orderId];
      }
    });
  };

  const { auth, settings } = useStore();

  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["listOrderCustomer"],
    retry: 0,
    enabled: false,
    queryFn: useMemo(() => async () => {
      if (orderId == null || orderId == undefined) {
        return;
      }
      try {
        const res = await orderService.getNewOrderInfo(orderId, searchVal);
        setCustomers(res?.data?.res);
        setStockOrderList(res?.data?.stockOrderList);
        if (orderId != "all") {
          navigate(`/app/product/mrp/orders/${orderId}/?search=${searchVal}`);
        } else {
          navigate(`/app/product/mrp/orders/all/?search=${searchVal}`);
        }

        return res;
      } catch (error) {
        console.error("Error occured while fetching data:", error);
        throw error;
      }
    }),
  });

  useEffect(() => {
    const fetchHeadData = async () => {
      try {
        const res = await customerService.getCustomerListForOrder();
        setHeadData(res?.data);
      } catch (error) {
        console.error("Error occured while fetching head data:", error);
      }
    };

    fetchHeadData();
  }, []);

  useEffect(() => {
    refetch();
  }, [searchVal, orderId]);

  const alertDeleted = async (id) => {
    await orderService.deletePartials(id, "direct").then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsAlert(false);
        // getOrderData();
      } else {
        Toast("error", res?.data?.message);
        setIsAlert(false);
      }
    });
  };

  const getCustomerList = async () => {
    setLoading(true);
    await customerService.getCustomerListForOrder().then((res) => {
      setHeadData(res?.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "10826fc4-bf01-4164-acf0-72bb213e88d3") {
    } else if (auth?.user?.company?.id == "b0d47c1a-e5dd-4ec1-a655-424af970bbfe") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);

  const tableHeadItems = [
    { title: t("product:productStock"), key: "products" },
    { title: t("product:deliveryDateProduct"), key: "deliveryDate" },
    { title: t("product:orderQuantityss"), key: "orderQuantity" },
    { title: t("product:openWorkOrderQuantity"), key: "managementOrders" },
    {
      title: t("product:totalDeliveredQuantity"),
      key: "totalDeliveredQuantity",
    },
    { title: t("product:remainingOrders"), key: "remainingOrders" },

    { title: t("product:openWorkOrder"), key: "remainingOrders" },
    { title: "", key: "managementOrders" },
  ];

  const menuOptions = [
    { label: t("product:viewRelated"), value: "workOrder", icon: <ListIcon /> },
    {
      label: t("product:sendToProductionWip"),
      value: "sendToProduction",
      icon: <FlipBackwardIcon />,
    },
    {
      label: t("product:transferFrom"),
      value: "transferProduction",
      icon: <FlipForwardIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  console.log("orderId111", orderId);

  useEffect(() => {
    if (!orderId) {
      navigate(`/app/product/mrp/orders/all/?search=${searchVal}`);
    }
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col">
        {isLoading ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[56px] mb-4" />
        ) : (
          <div className="flex items-center justify-between w-full border-b-[1px] border-secondary-100 pb-3">
            <div className="flex items-center ">
              <span className="mr-3 md:flex sm:flex xs:flex hidden">
                <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
              </span>
              <span className="font-codecMedium text-2xl text-black">upu.</span>
              <p className="text-2xl font-codecMedium text-[#B54708]">order</p>
              <p className="text-xl font-normal ml-2 text-secondary-500">{t("product:ordersAndCustomers")}</p>
            </div>
            <div className="flex gap-x-3">
              <span className="">
                <Button
                  colorType={"secondary-gray"}
                  iconLeft={<SupplierIcon />}
                  size={"md"}
                  onClick={() => {
                    navigate("/app/product/mrp/orders/customer-list");
                  }}
                  label={t("product:customerList")}
                />
              </span>
              <span className="">
                <Button
                  colorType={"primary-product"}
                  iconLeft={<PlusIcon />}
                  size={"md"}
                  onClick={() => {
                    navigate("/app/product/mrp/orders/new-order");
                  }}
                  label={t("product:createNewOrder")}
                />
              </span>
            </div>
          </div>
        )}

        {isLoading ? (
          <div className="w-full min-h-[116px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div className={`h-[116px] min-h-[116px] w-full overflow-x-auto overflow-y-hidden my-4 flex border-[#E4E7EC]`}>
            <div
              onClick={() => {
                setAddCustomerGroup(true);
              }}
              className="w-9 min-w-[36px] cursor-pointer h-[96px] max-h-[96px] mr-5 max-w-9 flex items-center justify-center rounded-md border-secondary-200 border-[1px] text-[#344054]"
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
            >
              <PlusIcon />
            </div>
            <div className="min-w-[240px] border-b pr-5 max-h-[96px]">
              <div
                onClick={() => {
                  setSelectedOperation("all");
                  // getOrderData();
                  navigate("/app/product/mrp/orders/all");
                }}
                style={{
                  borderBottomColor: selectedOperation == "all" ? "#B54708" : "#E4E7EC",
                }}
                className={`min-w-[220px] max-w-[220px] rounded-t-lg h-[96px] rounded-b-none border-b-[2px]  border cursor-pointer border-seconday-300  select-none flex flex-col`}
              >
                <div
                  className="flex flex-row 
          items-center justify-center w-full gap-x-1.5 p-2 border-b border-b-[#E4E7EC] "
                  style={{
                    color: selectedOperation == "all" ? "#B54708" : "#667085",
                  }}
                >
                  <AllIcon color={"#DC6803"} />
                  <p className={`text-base  truncate  font-semibold  `}>{t("product:allNew")}</p>
                </div>
                <div className="flex  items-center w-full h-full">
                  <div className={`flex flex-col items-center w-1/2 bg-[#F2F4F7]  border-r `}>
                    <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                      <p className={`text-xs text-[#344054] font-medium`}>{t("product:opens")}</p>
                    </div>
                    <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                      <p className={`text-sm font-semibold text-[#475467`}>{headData?.all?.activeOrderCount || 0} </p>
                    </div>
                  </div>
                  <div className={`flex flex-col items-center w-1/2 bg-[#F2F4F7]`}>
                    <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                      <p className={`text-xs text-[#344054] font-medium`}>{t("product:waiting")}</p>
                    </div>
                    <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                      <p className={`text-sm font-semibold text-[#A15C07]`}>{headData?.all?.waitingOrderCount || 0}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {headData?.groups
              ?.sort((first, second) => second?.activeOrderCount - first?.activeOrderCount)
              ?.map((item, index) => {
                return (
                  <div key={index} className={`min-w-[192px] ${index !== headData?.groups?.length - 1 ? "border-b" : "border-b-0"} max-h-[96px] pr-5`}>
                    <OrderHeaderCards
                      onClick={async () => {
                        navigate(`/app/product/mrp/orders/${item?.id}/?search=${searchVal}`);
                        setSelectedOperation(item);
                      }}
                      icon={item?.iconKey}
                      name={item?.name}
                      active={item?.activeOrderCount}
                      waiting={item?.waitingOrderCount}
                      selectedOperation={selectedOperation}
                      id={item?.id}
                    />
                  </div>
                );
              })}
          </div>
        )}

        {isLoading ? (
          <div className="w-full min-h-[64px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div className="flex items-start justify-between border-b-[1px] border-secondary-200 pb-4 mb-5">
            <p className="text-[#101828] text-xl font-semibold ">{t("product:customerGroup")}</p>
            <div className="flex items-center gap-x-3">
              <div className="w-[320px] max-w-[320px] min-w-[320px] sm:hidden md:hidden step-2">
                <SearchInput setSearchVal={setSearchVal} theme={"product"} placeholder={t("chat:search")} />
              </div>
              {/* <p>sort</p>
            <p>filter</p> */}
            </div>
          </div>
        )}
        {isLoading ? (
          <div className="w-full h-full mb-3 bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div className="flex flex-col w-full h-full overflow-y-auto scrollbar-hide gap-y-4 ">
            {stockOrderList ? (
              <div className={` flex flex-col  ${!selectedOrder == stockOrderList?.id && "max-h-[70px]"} w-full border-[1px] border-[#E4E7EC] rounded-xl`}>
                <div className="items-center justify-between flex w-full px-6 py-3  border-[#E4E7EC]">
                  <div className="flex items-center min-w-[320px] max-w-[320px] w-[320px]">
                    {stockOrderList?.customerImage || stockOrderList?.image ? (
                      <img
                        src={generateFileUrl(stockOrderList?.customerImage || stockOrderList?.image)}
                        className="w-[60px] h-[45px] flex justify-center items-center fexl  gap-1 rounded-[4px] border border-solid border-[#0000001A] bg-[#F9FAFB] "
                      />
                    ) : (
                      <div className="w-[60px] h-[45px] min-w-[60px] min-h-[45px] rounded flex items-center justify-center border border-secondary-200 overflow-hidden">
                        <NoAvatar name={stockOrderList?.customerName || stockOrderList?.name || "--"} rounded={4} theme={"product"} />
                      </div>
                    )}
                    <div className="flex flex-col ml-3">
                      <span className="text-md text-secondary-900 font-semibold">{stockOrderList?.customerName || stockOrderList?.name}</span>
                      <span className="flex items-center gap-x-3">
                        <Badgets colorType={"fill-warning"} size={"sm"} label={`${stockOrderList?.detailsCount || 0} ${t("product:openOrder")}`} />
                        <Badgets colorType={"fill-gray"} size={"sm"} label={`${stockOrderList?.partialCount || 0} ${t("product:openWorkOrder")}`} />
                      </span>
                    </div>
                  </div>
                  <div className="flex w-[803px] min-w-[803px] max-w-[803px] gap-x-8 h-full">
                    <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-full">
                      <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs">
                        {t("product:openWorkOrderQuantity")}
                      </div>
                      <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                        <p className="text-[#344054] font-medium text-sm">{stockOrderList?.partialQuantity || 0}</p>
                        <p className="text-[#475467] text-xs">{t("product:pieces")}</p>
                      </div>
                    </div>
                    <span className="flex text-[#B54708] font-semibold text-2xl h-full items-center justify-center"></span>
                    <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-full">
                      <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs">
                        {t("product:totalDeliveredQuantity")}
                      </div>
                      <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                        <p className="text-[#344054] font-medium text-sm">{stockOrderList?.deliveredQuantity || 0}</p>
                        <p className="text-[#475467] text-xs">{t("product:pieces")}</p>
                      </div>
                    </div>
                    <span className="flex text-[#B54708] font-semibold text-2xl h-full items-center justify-center">+</span>
                    <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-full">
                      <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs">
                        {t("product:remainingOrders")}
                      </div>
                      <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                        <p className="text-[#344054] font-medium text-sm">{stockOrderList?.remainingQuantity || 0}</p>
                        <p className="text-[#475467] text-xs">{t("product:pieces")}</p>
                      </div>
                    </div>
                    <span className="flex text-[#B54708] font-semibold text-2xl h-full items-center justify-center">=</span>

                    <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-full">
                      <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs">
                        {t("product:orderedQuantity")}
                      </div>
                      <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                        <p className="text-[#344054] font-medium text-sm">{stockOrderList?.totalQuantity || 0}</p>
                        <p className="text-[#475467] text-xs">{t("product:pieces")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end gap-x-3">
                    <Button
                      colorType={"tertiary-gray"}
                      iconLeft={<InfoIcon />}
                      size={"md"}
                      onClick={() => {
                        navigate(`/app/product/mrp/orders/stock-production-detail/all`);
                      }}
                    />

                    {selectedOrder != (stockOrderList?.customerId || stockOrderList?.id) ? (
                      <Button
                        colorType={"tertiary-gray"}
                        iconLeft={<ArrowDownIcon />}
                        size={"md"}
                        onClick={() => {
                          setSelectedOrder(stockOrderList?.customerId || stockOrderList?.id);
                          setOpenOrderIds([]);
                        }}
                      />
                    ) : (
                      <Button
                        colorType={"tertiary-gray"}
                        iconLeft={<ArrowUpIcon />}
                        size={"md"}
                        onClick={() => {
                          setSelectedOrder(null);
                          setOpenOrderIds([]);
                        }}
                      />
                    )}
                  </div>
                </div>

                {selectedOrder == (stockOrderList?.customerId || stockOrderList?.id) &&
                  stockOrderList?.orders?.map((order, i) => {
                    const isOpen = openOrderIds.includes(order.id);
                    return (
                      <div key={i} className="bg-[#F9FAFB] w-full flex flex-col px-6 py-3 rounded-b-xl">
                        <div className="flex flex-col border-[1px] bg-white  rounded-xl shadow-xs border-[#E4E7EC]">
                          <div className="flex items-center gap-x-4 px-6 py-4">
                            <Badgets size={"lg"} label={i + 1} colorType={"fill-warning"} />
                            <div className="flex items-center gap-x-8">
                              <div className="flex items-center gap-x-1">
                                <OrderQuantityIcon />
                                <span className=" h-5 text-sm text-[#475467] font-normal ">{t("product:orderNumber")}</span>
                                <span className="truncate  h-6 text-md text-[#B54708] font-semibold ">STK-{order?.systemStockOrderNo}</span>
                              </div>
                              {order?.customerOrderNo && (
                                <div className="flex items-center gap-x-1">
                                  <OrderQuantityIcon />
                                  <span className=" h-5 text-sm text-[#475467] font-normal ">{t("product:customerOrderNo")}</span>
                                  <span className="truncate  h-6 text-md text-[#B54708] font-semibold ">{order?.customerOrderNo}</span>
                                </div>
                              )}
                              <div className="flex items-center gap-x-1">
                                <CalenderIcon />
                                <span className=" h-5 text-sm text-[#475467] font-normal ">{t("product:orderGeneratedDate")}</span>
                                <span className="truncate  h-6 text-md text-[#B54708] font-semibold ">{moment(order?.createdDate).format("DD-MM-YYYY")}</span>
                              </div>
                            </div>
                            <div className="ml-auto flex h-full gap-3">
                              <Button
                                colorType={"secondary-gray"}
                                label={t("product:goToOrderDetail")}
                                iconLeft={<InfoCircleIcon />}
                                size={"sm"}
                                onClick={() => {
                                  navigate(`/app/product/mrp/orders/order-detail/${order?.id}`);
                                }}
                              />
                              {isOpen ? (
                                <Button colorType={"tertiary-gray"} iconLeft={<ArrowUpIcon />} size={"md"} onClick={() => toggleDetails(order.id)} />
                              ) : (
                                <Button colorType={"tertiary-gray"} iconLeft={<ArrowDownIcon />} size={"md"} onClick={() => toggleDetails(order.id)} />
                              )}
                              {/* <Button
                                    colorType={"secondary-gray"}
                                    size={"sm"}
                                    iconLeft={<PlusIcon />}
                                    onClick={() => {
                                      navigate(`/app/product/mrp/orders/new-order/edit/${order?.id}`);
                                    }}
                                  /> */}
                            </div>
                          </div>
                          {isOpen && (
                            <>
                              <div className="flex w-full bg-[#F2F4F7] border-y border-[#E4E7EC]">
                                {tableHeadItems?.map((item, index) => (
                                  <p
                                    key={index}
                                    className={`text-sm text-secondary-600 py-1  border-r-[1px] border-[#E4E7EC] last:border-r-[0px] text-center ${
                                      index === 0 ? "w-[26%]" : index === 7 ? "w-[6%]" : index === 6 ? "w-[8%]" : "w-[12%]"
                                    }`}
                                  >
                                    {item.title}
                                  </p>
                                ))}
                              </div>

                              {order?.details?.map((item, a) => {
                                const totalDesiredQuantity = item?.partial?.reduce((total, item) => total + item?.desiredQuantity || 0, 0);

                                return (
                                  <div
                                    key={a}
                                    onMouseLeave={() => setOpenDropdowns([])}
                                    className="flex min-w-full w-full text-center border-b border-[#E4E7EC] last:border-b-0 group"
                                  >
                                    <div className="flex w-[26%] px-6 py-3 border-r border-[#E4E7EC] gap-x-3">
                                      <img
                                        src={generateFileUrl(item?.product?.image)}
                                        className="w-[64px] min-w-[64px] h-12  border-[1px] rounded border-solid border-[#0000001A] "
                                      />
                                      <div className="flex flex-col gap-y-1 w-[270px] items-start text-start">
                                        <div className="text-md text-[#344054] font-semibold h-5 truncate w-[97%]">{item?.product?.name || "--"}</div>
                                        <div className="text-sm text-[#667085] font-regular truncate max-w-[260px]  h-5 justify-center items-center flex">
                                          {t("product:stock")}:{item?.product?.stockCode || "--"}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col w-[12%] py-4 border-r border-[#E4E7EC]">
                                      <span className="text-md text-[#344054] font-semibold  h-5 justify-center items-center flex">
                                        {moment(item?.deliveryDate).format("LL")}
                                      </span>
                                      <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">
                                        {moment(item?.deliveryDate).format("dddd")}
                                      </span>
                                    </div>
                                    <div className="flex flex-col w-[12%] py-4 border-r border-[#E4E7EC]">
                                      <span className="text-md text-[#344054] font-semibold  h-5 justify-center items-center flex">
                                        {item?.orderedQuantity}
                                      </span>
                                      <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">{t("product:pieces")}</span>
                                    </div>
                                    <div className="flex flex-col w-[12%] py-4 border-r border-[#E4E7EC]">
                                      <span className="text-md text-[#344054] font-semibold  h-5 justify-center items-center flex">
                                        {totalDesiredQuantity || 0}
                                      </span>
                                      <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">{t("product:pieces")}</span>
                                    </div>
                                    <div className="flex flex-col w-[12%] py-4 border-r border-[#E4E7EC]">
                                      <span className="text-md text-[#344054] font-semibold  h-5 justify-center items-center flex">
                                        {item?.finishedQuantity}
                                      </span>
                                      <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">{t("product:pieces")}</span>
                                    </div>
                                    <div className="flex flex-col w-[12%] py-4 border-r border-[#E4E7EC]">
                                      <span className="text-md text-[#344054] font-semibold  h-5 justify-center items-center flex">
                                        {item?.orderedQuantity - item?.finishedQuantity}
                                      </span>
                                      <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">{t("product:pieces")}</span>
                                    </div>
                                    <div className="flex flex-col w-[8%] py-4 border-r border-[#E4E7EC]">
                                      <span className="text-md text-[#344054] font-semibold  h-5 justify-center items-center flex">
                                        {item?.partial?.length || 0}
                                      </span>
                                      <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">
                                        {t("product:workOrderTable")}
                                      </span>
                                    </div>
                                    <div className="flex w-[6%] py-4 items-center justify-center relative">
                                      <Button
                                        colorType={"tertiary-gray"}
                                        iconLeft={<DotsHorizontalIcon />}
                                        size={"md"}
                                        onClick={() => toggleDropdown(item?.id)}
                                      />
                                      {openDropdowns[item?.id] && (
                                        <div className="hidden flex-col absolute w-[280px] min-w-[280px] max-w-[280px] h-fit bg-white border top-[54px] right-[16px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
                                          {menuOptions?.map((items, index) => {
                                            return (
                                              <button
                                                onClick={async () => {
                                                  setIsDetailId(item?.id);
                                                  setSelectedOrderDetail(item);
                                                  if (items.value === "sendToProduction") {
                                                    navigate(`/app/product/mrp/orders/management/planning/${item?.id}`);
                                                  }
                                                  if (items.value === "delete") {
                                                    const response = await orderService.controlPartial(item?.id);

                                                    if (response?.length > 0) {
                                                      setIsOpenDeleteModal(true);
                                                    } else {
                                                      setIsAlert(true);
                                                    }
                                                  }
                                                  if (items.value === "workOrder") {
                                                    setIsOpenWorkOrderModal(true);
                                                  }
                                                  if (items.value === "sendToProduction") {
                                                  }
                                                  if (items.value === "transferProduction") {
                                                  }
                                                  toggleDropdown(item?.id);
                                                }}
                                                key={index}
                                                type="button"
                                                className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                                                  items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                                                }`}
                                              >
                                                {items?.icon}
                                                <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>
                                                  {items.label}
                                                </p>
                                              </button>
                                            );
                                          })}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : null}

            {customers?.length < 0 ? (
              <div className="flex-1 h-full flex items-center justify-center flex-col">
                <NoDataForProduct />
                <h1 className="text-[#B54708] font-semibold mt-4">{t("label:noData")}</h1>
              </div>
            ) : (
              <>
                {customers?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={` flex flex-col  ${!selectedOrder == item?.id && "max-h-[70px]"} w-full border-[1px] border-[#E4E7EC] rounded-xl `}
                    >
                      <div className="items-center justify-between flex w-full px-6 py-3  border-[#E4E7EC]">
                        <div className="flex items-center min-w-[320px] max-w-[320px] w-[320px]">
                          {item?.customerImage || item?.image ? (
                            <img
                              src={generateFileUrl(item?.customerImage || item?.image)}
                              className="w-[60px] h-[45px] flex justify-center items-center fexl  gap-1 rounded-[4px] border border-solid border-[#0000001A] bg-[#F9FAFB] "
                            />
                          ) : (
                            <div className="w-[60px] h-[45px] min-w-[60px] min-h-[45px] rounded flex items-center justify-center border border-secondary-200 overflow-hidden">
                              <NoAvatar name={item?.customerName || item?.name || "--"} rounded={4} theme={"product"} />
                            </div>
                          )}
                          <div className="flex flex-col ml-3 w-[220px]">
                            <span className="text-md text-secondary-900 font-semibold truncate">{item?.customerName || item?.name}</span>
                            <span className="flex items-center gap-x-3">
                              <Badgets colorType={"fill-warning"} size={"sm"} label={`${item?.detailsCount || 0} ${t("product:openOrder")}`} />
                              <Badgets colorType={"fill-gray"} size={"sm"} label={`${item?.partialCount || 0} ${t("product:openWorkOrder")}`} />
                            </span>
                          </div>
                        </div>
                        <div className="flex w-[803px] min-w-[803px] max-w-[803px] gap-x-8 h-full">
                          <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-full">
                            <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs">
                              {t("product:openWorkOrderQuantity")}
                            </div>
                            <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                              <p className="text-[#344054] font-medium text-sm">{item?.partialQuantity || 0}</p>
                              <p className="text-[#475467] text-xs">{t("product:pieces")}</p>
                            </div>
                          </div>
                          <span className="flex text-[#B54708] font-semibold text-2xl h-full items-center justify-center"></span>
                          <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-full">
                            <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs">
                              {t("product:totalDeliveredQuantity")}
                            </div>
                            <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                              <p className="text-[#344054] font-medium text-sm">{item?.deliveredQuantity || 0}</p>
                              <p className="text-[#475467] text-xs">{t("product:pieces")}</p>
                            </div>
                          </div>
                          <span className="flex text-[#B54708] font-semibold text-2xl h-full items-center justify-center">+</span>
                          <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-full">
                            <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs">
                              {t("product:remainingOrders")}
                            </div>
                            <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                              <p className="text-[#344054] font-medium text-sm">{item?.remainingQuantity || 0}</p>
                              <p className="text-[#475467] text-xs">{t("product:pieces")}</p>
                            </div>
                          </div>
                          <span className="flex text-[#B54708] font-semibold text-2xl h-full items-center justify-center">=</span>

                          <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-full">
                            <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs">
                              {t("product:orderedQuantity")}
                            </div>
                            <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                              <p className="text-[#344054] font-medium text-sm">{item?.totalQuantity || 0}</p>
                              <p className="text-[#475467] text-xs">{t("product:pieces")}</p>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-end gap-x-3">
                          <Button
                            colorType={"tertiary-gray"}
                            iconLeft={<InfoIcon />}
                            size={"md"}
                            onClick={() => {
                              setSelectedOrder(item?.customerId || item?.id);
                              navigate(`/app/product/mrp/orders/customer-detail/${item?.customerId || item?.id}/all`);
                            }}
                          />

                          {selectedOrder != (item?.customerId || item?.id) ? (
                            <Button
                              colorType={"tertiary-gray"}
                              iconLeft={<ArrowDownIcon />}
                              size={"md"}
                              onClick={() => {
                                setSelectedOrder(item?.customerId || item?.id);
                              }}
                            />
                          ) : (
                            <Button
                              colorType={"tertiary-gray"}
                              iconLeft={<ArrowUpIcon />}
                              size={"md"}
                              onClick={() => {
                                setSelectedOrder(null);
                              }}
                            />
                          )}
                        </div>
                      </div>

                      {selectedOrder == (item?.customerId || item?.id) &&
                        item?.orders?.map((order, i) => {
                          const isOpen = openOrderIds.includes(order.id);
                          return (
                            <div key={i} className="bg-[#F9FAFB] w-full flex flex-col px-6 py-3 rounded-b-xl">
                              <div className="flex flex-col border-[1px] bg-white  rounded-xl shadow-xs border-[#E4E7EC]">
                                <div className="flex items-center gap-x-4 px-6 py-4">
                                  <Badgets size={"lg"} label={i + 1} colorType={"fill-warning"} />
                                  <div className="flex gap-x-8 items-center">
                                    <div className="flex items-center gap-x-1">
                                      <OrderQuantityIcon />
                                      <span className=" h-5 text-sm text-[#475467] font-normal ">{t("product:orderNumber")}</span>
                                      <span className="truncate  h-6 text-md text-[#B54708] font-semibold ">ORD-{order?.systemOrderNo}</span>
                                    </div>
                                    {order?.customerOrderNo && (
                                      <div className="flex items-center gap-x-1">
                                        <OrderQuantityIcon />
                                        <span className=" h-5 text-sm text-[#475467] font-normal ">{t("product:customerOrderNo")}</span>
                                        <span className="truncate  h-6 text-md text-[#B54708] font-semibold ">{order?.customerOrderNo}</span>
                                      </div>
                                    )}
                                    <div className="flex items-center gap-x-1">
                                      <CalenderIcon />
                                      <span className=" h-5 text-sm text-[#475467] font-normal ">{t("product:orderGeneratedDate")}</span>
                                      <span className="truncate  h-6 text-md text-[#B54708] font-semibold ">
                                        {moment(order?.createdDate).format("DD-MM-YYYY")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="ml-auto flex h-full gap-3">
                                    <Button
                                      colorType={"secondary-gray"}
                                      label={t("product:goToOrderDetail")}
                                      iconLeft={<InfoCircleIcon />}
                                      size={"sm"}
                                      onClick={() => {
                                        navigate(`/app/product/mrp/orders/order-detail/${order?.id}`);
                                      }}
                                    />
                                    {isOpen ? (
                                      <Button colorType={"tertiary-gray"} iconLeft={<ArrowUpIcon />} size={"md"} onClick={() => toggleDetails(order.id)} />
                                    ) : (
                                      <Button colorType={"tertiary-gray"} iconLeft={<ArrowDownIcon />} size={"md"} onClick={() => toggleDetails(order.id)} />
                                    )}
                                    {/* <Button
                                      colorType={"secondary-gray"}
                                      size={"sm"}
                                      iconLeft={<PlusIcon />}
                                      onClick={() => {
                                        navigate(`/app/product/mrp/orders/new-order/edit/${order?.id}`);
                                      }}
                                    /> */}
                                  </div>
                                </div>
                                {isOpen && (
                                  <>
                                    <div className="flex w-full bg-[#F2F4F7] border-y border-[#E4E7EC]">
                                      {tableHeadItems?.map((item, index) => (
                                        <p
                                          key={index}
                                          className={`text-sm text-secondary-600 py-1  border-r-[1px] border-[#E4E7EC] last:border-r-[0px] text-center ${
                                            index === 0 ? "w-[26%]" : index === 7 ? "w-[6%]" : index === 6 ? "w-[8%]" : "w-[12%]"
                                          }`}
                                        >
                                          {item.title}
                                        </p>
                                      ))}
                                    </div>

                                    {order?.details?.map((item, a) => {
                                      const totalDesiredQuantity = item?.partial?.reduce((total, item) => total + item?.desiredQuantity || 0, 0);
                                      return (
                                        <div
                                          key={a}
                                          onMouseLeave={() => setOpenDropdowns([])}
                                          className="flex min-w-full w-full text-center border-b border-[#E4E7EC] last:border-b-0 group"
                                        >
                                          <div className="flex w-[26%] px-6 py-3 border-r border-[#E4E7EC] gap-x-3">
                                            <img
                                              src={generateFileUrl(item?.product?.image)}
                                              className="w-[64px] min-w-[64px] h-12  border-[1px] rounded border-solid border-[#0000001A] "
                                            />
                                            <div className="flex flex-col gap-y-1 w-[270px] items-start text-start">
                                              <div className="text-md text-[#344054] font-semibold h-5 truncate w-[97%]">{item?.product?.name || "--"}</div>
                                              <div className="text-sm text-[#667085] font-regular truncate max-w-[260px]  h-5 justify-center items-center flex">
                                                {t("product:stock")}:{item?.product?.stockCode || "--"}
                                              </div>
                                            </div>
                                          </div>

                                          <div className="flex flex-col w-[12%] py-4 border-r border-[#E4E7EC]">
                                            <span className="text-sm text-[#344054] font-medium  h-5 justify-center items-center flex">
                                              {moment(item?.deliveryDate).format("LL")}
                                            </span>
                                            <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">
                                              {moment(item?.deliveryDate).format("dddd")}
                                            </span>
                                          </div>
                                          <div className="flex flex-col w-[12%] py-4 border-r border-[#E4E7EC]">
                                            <span className="text-sm text-[#344054] font-medium  h-5 justify-center items-center flex">
                                              {item?.orderedQuantity}
                                            </span>
                                            <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">
                                              {t("product:pieces")}
                                            </span>
                                          </div>
                                          <div className="flex flex-col w-[12%] py-4 border-r border-[#E4E7EC]">
                                            <span className="text-sm text-[#344054] font-medium  h-5 justify-center items-center flex">
                                              {totalDesiredQuantity || 0}
                                            </span>
                                            <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">
                                              {t("product:pieces")}
                                            </span>
                                          </div>
                                          <div className="flex flex-col w-[12%] py-4 border-r border-[#E4E7EC]">
                                            <span className="text-sm text-[#344054] font-medium  h-5 justify-center items-center flex">
                                              {item?.finishedQuantity}
                                            </span>
                                            <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">
                                              {t("product:pieces")}
                                            </span>
                                          </div>
                                          <div className="flex flex-col w-[12%] py-4 border-r border-[#E4E7EC]">
                                            <span className="text-sm text-[#344054] font-medium  h-5 justify-center items-center flex">
                                              {item?.orderedQuantity - item?.finishedQuantity}
                                            </span>
                                            <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">
                                              {t("product:pieces")}
                                            </span>
                                          </div>
                                          <div className="flex flex-col w-[8%] py-4 border-r border-[#E4E7EC]">
                                            <span className="text-sm text-[#344054] font-medium  h-5 justify-center items-center flex">
                                              {item?.partial?.length || 0}
                                            </span>
                                            <span className="text-xs text-[#667085] font-regular  h-5 justify-center items-center flex">
                                              {t("product:workOrderTable")}
                                            </span>
                                          </div>
                                          <div className="flex w-[6%] py-4 items-center justify-center relative">
                                            <Button
                                              colorType={"tertiary-gray"}
                                              iconLeft={<DotsHorizontalIcon />}
                                              size={"md"}
                                              onClick={() => toggleDropdown(item?.id)}
                                            />
                                            {openDropdowns[item?.id] && (
                                              <div className="hidden flex-col absolute w-[280px] min-w-[280px] max-w-[280px] h-fit bg-white border top-[54px] right-[16px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
                                                {menuOptions?.map((items, index) => {
                                                  return (
                                                    <button
                                                      onClick={async () => {
                                                        setIsDetailId(item?.id);
                                                        setSelectedOrderDetail(item);
                                                        if (items.value === "sendToProduction") {
                                                          navigate(`/app/product/mrp/orders/management/planning/${item?.id}`);
                                                        }
                                                        if (items.value === "delete") {
                                                          const response = await orderService.controlPartial(item?.id);

                                                          if (response?.length > 0) {
                                                            setIsOpenDeleteModal(true);
                                                          } else {
                                                            setIsAlert(true);
                                                          }
                                                        }
                                                        if (items.value === "workOrder") {
                                                          setIsOpenWorkOrderModal(true);
                                                        }
                                                        if (items.value === "transferProduction") {
                                                          setOpenWipExitModal(true);
                                                        }
                                                        toggleDropdown(item?.id);
                                                      }}
                                                      key={index}
                                                      type="button"
                                                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                                                        items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                                                      }`}
                                                    >
                                                      {items?.icon}
                                                      <p
                                                        className={`font-medium text-sm  ${
                                                          items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"
                                                        }`}
                                                      >
                                                        {items.label}
                                                      </p>
                                                    </button>
                                                  );
                                                })}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
      <CustomModal
        isOpen={addCustomerGroup}
        setIsOpen={setAddCustomerGroup}
        modalTitle={t("product:createCustomerGroup")}
        children={<AddCustomerGroupModal closeModal={() => setAddCustomerGroup(false)} refetch={getCustomerList} />}
      />
      <CustomModal
        isOpen={editOrderModal}
        setIsOpen={setEditOrderModal}
        modalTitle={t("product:orderEdit")}
        width={400}
        children={
          <EditOrderModal
            closeModal={() => setEditOrderModal(false)}
            // refetch={getOrderData}
            orderId={editOrderData?.id}
          />
        }
      />
      <CustomModal
        isOpen={isOpenWorkOrderModal}
        setIsOpen={setIsOpenWorkOrderModal}
        subTitle={isFirstModal ? t("product:listOfOpen") : "Aynı ürüne ait diğer siparişlerin listesi"}
        modalTitle={isFirstModal ? t("product:relatedWorkOrders") : "İş Emrini Taşı"}
        width={900}
        navigateButton={isFirstModal ? false : true}
        navigateOnClick={() => setIsFirstModal(!isFirstModal)}
        children={
          <WorkOrderModal
            setIsFirstModal={setIsFirstModal}
            isFirstModal={isFirstModal}
            detailId={isDetailId}
            setOpenWipExitModal={setOpenWipExitModal}
            setIsOpenWorkOrderModal={setIsOpenWorkOrderModal}
            refetch={getCustomerList}
          />
        }
      />
      <CustomModal
        modalTitle={t("product:deleteProduct")}
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        children={
          <DeleteOrderProduct
            selectedOrder={selectedOrderDetail}
            id={isDetailId}
            setIsOpen={setIsOpenDeleteModal}
            setIsAlert={setIsAlert}
            // refetch={getOrderData}
          />
        }
      />

      <AlertModal
        setIsOpen={setIsAlert}
        isOpen={isAlert}
        title={t("product:deleteProduct")}
        icon={<DeleteIcon />}
        subTitle={t("product:thereAreNo")}
        applyButton={() => {
          alertDeleted(selectedOrderDetail?.id);
        }}
        label={"Sil"}
      />
      <CustomModal
        isOpen={openWipExitModal}
        setIsOpen={setOpenWipExitModal}
        modalTitle={t("product:transitionWipOrder")}
        subTitle={t("product:sendToCompletedOrder")}
        width={900}
        padding={true}
        children={
          <LegacyWipExitModal
            detailId={isDetailId}
            setIsOpen={setOpenWipExitModal}
            // refetch={getOrderData}
          />
        }
      />
    </>
  );
});

export default NewOrderAndSupply;

const SupplierIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
      <path
        d="M13.3334 1.8898C14.5681 2.50343 15.4167 3.77762 15.4167 5.25C15.4167 6.72238 14.5681 7.99657 13.3334 8.6102M15 12.972C16.2596 13.5419 17.3938 14.4708 18.3334 15.6667M1.66669 15.6667C3.28876 13.6021 5.491 12.3333 7.91669 12.3333C10.3424 12.3333 12.5446 13.6021 14.1667 15.6667M11.6667 5.25C11.6667 7.32107 9.98776 9 7.91669 9C5.84562 9 4.16669 7.32107 4.16669 5.25C4.16669 3.17893 5.84562 1.5 7.91669 1.5C9.98776 1.5 11.6667 3.17893 11.6667 5.25Z"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const InfoCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M10.4993 13.3334V10.0001M10.4993 6.66675H10.5077M18.8327 10.0001C18.8327 14.6025 15.1017 18.3334 10.4993 18.3334C5.89698 18.3334 2.16602 14.6025 2.16602 10.0001C2.16602 5.39771 5.89698 1.66675 10.4993 1.66675C15.1017 1.66675 18.8327 5.39771 18.8327 10.0001Z"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const PlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M9.99996 4.1665V15.8332M4.16663 9.99984H15.8333" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const AllIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
      <path
        d="M16 6.99984L6 6.99984M16 1.99984L6 1.99984M16 11.9998L6 11.9998M2.66667 6.99984C2.66667 7.46007 2.29357 7.83317 1.83333 7.83317C1.3731 7.83317 1 7.46007 1 6.99984C1 6.5396 1.3731 6.1665 1.83333 6.1665C2.29357 6.1665 2.66667 6.5396 2.66667 6.99984ZM2.66667 1.99984C2.66667 2.46007 2.29357 2.83317 1.83333 2.83317C1.3731 2.83317 1 2.46007 1 1.99984C1 1.5396 1.3731 1.1665 1.83333 1.1665C2.29357 1.1665 2.66667 1.5396 2.66667 1.99984ZM2.66667 11.9998C2.66667 12.4601 2.29357 12.8332 1.83333 12.8332C1.3731 12.8332 1 12.4601 1 11.9998C1 11.5396 1.3731 11.1665 1.83333 11.1665C2.29357 11.1665 2.66667 11.5396 2.66667 11.9998Z"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M5 8L10 13L15 8" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const ArrowUpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15 12.5L10 7.5L5 12.5" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const NoDataForProduct = () => {
  return (
    <svg width="152" height="120" viewBox="0 0 152 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="76" cy="52" r="52" fill="#FEDF89" />
      <g filter="url(#filter0_dd_150_48764)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.6 16C66.8273 16 57.2978 21.3233 51.4987 29.4829C49.605 29.0363 47.6301 28.8 45.6 28.8C31.4615 28.8 20 40.2615 20 54.4C20 68.5385 31.4615 80 45.6 80L45.62 80H109.6C121.971 80 132 69.9712 132 57.6C132 45.2288 121.971 35.2 109.6 35.2C108.721 35.2 107.854 35.2506 107.002 35.349C102.098 23.9677 90.7797 16 77.6 16Z"
          fill="#FFFAEB"
        />
        <ellipse cx="45.6" cy="54.4" rx="25.6" ry="25.6" fill="url(#paint0_linear_150_48764)" />
        <circle cx="77.6001" cy="48" r="32" fill="url(#paint1_linear_150_48764)" />
        <ellipse cx="109.6" cy="57.5999" rx="22.4" ry="22.4" fill="url(#paint2_linear_150_48764)" />
      </g>
      <circle cx="21" cy="19" r="5" fill="#FEF0C7" />
      <circle cx="18" cy="109" r="7" fill="#FEF0C7" />
      <circle cx="145" cy="35" r="7" fill="#FEF0C7" />
      <circle cx="134" cy="8" r="4" fill="#FEF0C7" />
      <g filter="url(#filter1_b_150_48764)">
        <rect x="52" y="62" width="48" height="48" rx="24" fill="#B54708" fill-opacity="0.4" />
        <path
          d="M83.513 90.3329C83.513 91.4714 83.2947 92.5987 82.8707 93.6505C82.4467 94.7022 81.8252 95.6578 81.0417 96.4627C80.2582 97.2675 79.3282 97.9059 78.3046 98.3412C77.281 98.7766 76.184 99.0004 75.0762 99V95.3336C75.9528 95.3424 76.8169 95.1197 77.5857 94.6867C78.3275 94.2698 78.9435 93.6513 79.3665 92.8989C79.7902 92.1227 80.0127 91.2476 80.0127 90.3575C80.0127 89.4675 79.7902 88.5924 79.3665 87.8162C78.9441 87.0644 78.3278 86.4472 77.5857 86.0327C76.8053 85.6106 75.9367 85.3901 75.0548 85.3901C74.173 85.3901 73.3044 85.6106 72.524 86.0327C71.7844 86.4448 71.1703 87.0595 70.7499 87.8084C70.4532 88.3345 70.2562 88.9136 70.1692 89.515C68.8903 88.6703 67.8384 87.5091 67.1099 86.1378C66.3814 84.7665 65.9998 83.2291 66 81.6667H75.0762C76.184 81.6662 77.281 81.8899 78.3045 82.3252C79.3281 82.7604 80.2582 83.3987 81.0416 84.2035C81.8251 85.0083 82.4466 85.9638 82.8707 87.0155C83.2947 88.0672 83.513 89.1945 83.513 90.3329Z"
          fill="white"
        />
        <path d="M83.5129 82.9995V78.9998H89.9999L83.5129 82.9995Z" fill="white" />
        <path
          d="M83.5129 76.9994V78.9988H77.025C76.7693 78.9992 76.516 78.9477 76.2796 78.8474C76.0432 78.7471 75.8284 78.5999 75.6474 78.4142C75.4664 78.2285 75.3228 78.008 75.2248 77.7652C75.1268 77.5225 75.0763 77.2622 75.0762 76.9994V75H81.564C81.8196 74.9999 82.0726 75.0515 82.3088 75.1519C82.5449 75.2524 82.7595 75.3996 82.9402 75.5853C83.121 75.771 83.2644 75.9914 83.3622 76.2341C83.46 76.4767 83.5104 76.7368 83.5104 76.9994"
          fill="white"
        />
        <path
          d="M76.8181 92.2131C76.3632 92.6887 75.7836 92.9286 75.0793 92.9326H75.0617C74.349 92.9326 73.7636 92.6928 73.3053 92.2131C72.9059 91.7908 72.6674 91.2351 72.633 90.6473C72.6246 90.5514 72.6204 90.4538 72.6204 90.3528C72.6204 89.5927 72.8495 88.9729 73.3078 88.4933C73.7661 88.0136 74.3516 87.7735 75.0642 87.7729C75.7769 87.7729 76.3624 88.013 76.8206 88.4933C77.2789 88.9735 77.5081 89.5933 77.5081 90.3528C77.5064 91.1145 77.2764 91.7347 76.8181 92.2131Z"
          fill="white"
        />
      </g>
      <defs>
        <filter id="filter0_dd_150_48764" x="0" y="16" width="152" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_150_48764" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_48764" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_150_48764" />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_150_48764" result="effect2_dropShadow_150_48764" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_150_48764" result="shape" />
        </filter>
        <filter id="filter1_b_150_48764" x="44" y="54" width="64" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_150_48764" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_150_48764" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_150_48764" x1="25.9429" y1="37.4858" x2="71.2" y2="80" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FEDF89" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_150_48764" x1="53.0287" y1="26.8571" x2="109.6" y2="80" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FEDF89" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_150_48764" x1="92.3999" y1="42.8" x2="132" y2="79.9999" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FEDF89" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
