import React from "react";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import CustomImage from "../../../../components/CustomImage";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import { AddPlusIcon, ClockEnd, ClockStart } from "../../../../components/new-cards/card-icons";

const EditManuelJunctionFormEventCard = ({
  data,
  selectedProduct,
  workType,
  selectedUser,
  selectedOperation,
  equipmentData,
  acceptedPiece,
  addUserOnClick,
  selectedDates,
  rejectedPiece,
}) => {
  return (
    <>
      <div className="flex w-full flex-row justify-center items-center group relative min-h-[128px] max-h-[128px]">
        <div className="flex flex-col items-start h-full w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 mr-auto">
          <div className={`w-full border-t-4 rounded-t-[8px] ${workType == "work" ? "border-t-success-500 " : workType === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"}`}></div>
          <div className="flex flex-row items-center w-full border-b">
            {selectedProduct?.image ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-content={`${selectedProduct?.name}`}
              >
                <CustomImage
                  src={selectedProduct?.image}
                  style={{
                    width: 84,
                    height: 63,
                    minWidth: 84,
                    minHeight: 63,
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                    borderRadius: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[84px] min-w-[84px] h-[63px] border-r border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col px-2 gap-x-[3px] w-full">
              <div className="flex items-center gap-x-1">
                <QuantityAcceptedIcon />
                <p className="text-[#475467] font-normal text-md truncate w-[50%]">{acceptedPiece || "--"}</p>
              </div>
              <div className="flex items-center gap-x-1">
                <QuantityRejectedIcon />
                <p className="text-[#475467] font-normal text-md truncate w-[50%]">{rejectedPiece || "--"}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start w-full pl-[18px] py-[6px] pr-[30px]">
            <p className="font-normal text-[#667085] text-md">{selectedProduct?.stockCode || "--"}</p>
            <p className="text-md font-semibold text-[#344054] truncate  w-[99%]">{selectedProduct?.name || "--"}</p>
          </div>
        </div>
        <div className="cursor-pointer flex absolute  w-[75px] h-[75px] min-w-[75px] min-h-[75px] z-[100]">
          {selectedUser?.image ? (
            <div data-tooltip-id={`${data?.id}`} data-tooltip-place={"left"} data-tooltip-content={`${selectedUser?.label} `} className="cursor-pointer">
              <CustomImage
                src={selectedUser?.image}
                style={{
                  width: 75,
                  height: 75,
                  borderRadius: 100,
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                  borderWidth: 1,
                  borderStyle: "solid",
                  boxShadow: "0 0 0 2px #00000008",
                }}
              />
            </div>
          ) : (
            <div
              onClick={addUserOnClick}
              className="flex min-w-[75px] max-w-[75px] w-[75px] min-h-[75px] max-h-[75px] h-[75px] rounded-full bg-white border border-[#D6BBFB] border-dashed items-center justify-center"
            >
              <AddPlusIcon />
            </div>
          )}
        </div>
        <div className="flex flex-col items-start h-full w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 ml-auto">
          <div className={`w-full border-t-4 rounded-t-[8px] ${workType == "work" ? "border-t-success-500 " : workType === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"}`}></div>
          <div className="flex flex-row items-center w-full border-b justify-end">
            <div className="flex flex-col items-start px-2">
              <div className="flex flex-row items-center gap-x-1 ml-auto">
                <p className="font-normal text-[#475467] text-md">{moment(selectedDates?.start)?.format("HH:mm") || "--"}</p>
                <ClockStart />
              </div>
              <div className="flex flex-row items-center gap-x-1 ml-auto">
                <p className="text-md font-normal text-[#475467]">{moment(selectedDates?.end)?.format("HH:mm") || "--"}</p>
                <ClockEnd />
              </div>
            </div>
            {equipmentData?.equipmentImage ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-content={`${equipmentData?.equipmentName}`}
              >
                <CustomImage
                  borderRadius={2}
                  src={equipmentData?.equipmentImage}
                  style={{
                    width: 84,
                    height: 63,
                    minWidth: 84,
                    minHeight: 63,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRadius: 0,
                    borderLeftWidth: 1,
                    borderRightWidth: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[84px] min-w-[84px] h-[63px] border-l border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
          </div>
          <div className="flex flex-col items-end w-full pr-3 py-1 pl-[30px] min-h-[60px] max-h-[60px]">
            <p className="font-normal text-[#667085] text-md">
              {`(${selectedOperation?.step || "--"}.Op) ` +
                (selectedOperation?.processName === undefined ? "--" : selectedOperation?.processName)}
            </p>
            <p className="text-md font-semibold text-end text-[#344054] truncate ml-auto w-[99%]">{equipmentData?.equipmentName || "--"}</p>
          </div>
        </div>
        <Tooltip className="z-[120]" id={`${data?.id}`} />
      </div>
    </>
  );
};

export default EditManuelJunctionFormEventCard;

export const QuantityAcceptedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path
        d="M7.54688 3.11719H4.875C3.63236 3.11719 2.625 4.12455 2.625 5.36719V15.6328C2.625 16.8755 3.63236 17.8828 4.875 17.8828H16.125C17.3676 17.8828 18.375 16.8755 18.375 15.6328V5.36719C18.375 4.12455 17.3676 3.11719 16.125 3.11719H13.4531M7.54688 3.11719V7.0333C7.54688 7.11693 7.63489 7.17133 7.70969 7.13393L10.5 5.73877L13.2903 7.13393C13.3651 7.17133 13.4531 7.11693 13.4531 7.0333V3.11719M7.54688 3.11719H13.4531M7.84162 11.9371L9.75732 13.8375L14.0191 9.58908"
        stroke="#17B26A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const QuantityRejectedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path
        d="M7.54688 3.11719H4.875C3.63236 3.11719 2.625 4.12455 2.625 5.36719V15.6328C2.625 16.8755 3.63236 17.8828 4.875 17.8828H16.125C17.3676 17.8828 18.375 16.8755 18.375 15.6328V5.36719C18.375 4.12455 17.3676 3.11719 16.125 3.11719H13.4531M7.54688 3.11719V7.02845C7.54688 7.11197 7.63466 7.16637 7.70945 7.1292L10.5 5.74219L13.2906 7.1292C13.3653 7.16637 13.4531 7.11197 13.4531 7.02845V3.11719M7.54688 3.11719H13.4531M8.31741 10.0783L10.5 12.2664M10.5 12.2664L12.6869 10.0783M10.5 12.2664L8.31741 14.4546M10.5 12.2664L12.6869 14.4546"
        stroke="#F04438"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
