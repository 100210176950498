import React, { useEffect, useState } from "react";
import { Button, Input, UploadProfile } from "../../components";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Selector from "../../components/inputs/Selector";
import TextArea from "../../components/inputs/TextArea";
import UnitPriceInput from "../../components/inputs/UnitPriceInput";
import { rawMaterialService } from "../../services/raw-material.service";
import { Toast } from "../../utils/toastify/toast";
import { useQuery } from "@tanstack/react-query";
import { ArrowLeftIcon } from "../../assets/icons/productIcons";

const NewCreateRawMaterial = () => {
  const { t } = useTranslation();
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [qualityPercent, setQualityPercent] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("USD");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["supplierGroup"],
    queryFn: async () => {
      return await rawMaterialService.supplierGroup();
    },
    retry: 0,
  });

  const {
    data: rawMaterialData,
    isLoading: rawMaterialDataLoading,
    refetch: rawMaterialDataRefetch,
  } = useQuery({
    queryKey: ["getRawMaterialDetail", itemId],
    queryFn: async () => (itemId ? await rawMaterialService.getPurchaseList(itemId) : ""),
    enabled: !!itemId,
    retry: 0,
  });

  const schema = yup.object({
    image: yup.string().notRequired(),
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    stockCode: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    // type: yup
    //   .string()
    //   // .required(`${t("validation:empty")}`)
    //   .typeError(`${t("validation:string")}`),
    supplierGroup: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    unit: yup
      .string()
      // .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    stockQuantity: yup.number(),
    criticalStock: yup.number(),
    description: yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const rawMaterials = [
    { label: "Çubuk", value: "stick" },
    { label: "Lama", value: "lama" },
    { label: "Boru", value: "pipe" },
    { label: "Diğer", value: "other" },
  ];
  const priceOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
  ];

  const units = [
    { label: "Kilogram", shortLabel: "kg", value: "kg" },
    { label: t("product:metricTon"), shortLabel: "ton", value: "ton" },
    { label: "Gram", shortLabel: "gr", value: "gr" },
    { label: "Miligram", shortLabel: "mg", value: "mg" },
    { label: "Microgram", shortLabel: "mcg", value: "mcg" },
    { label: "Pound", shortLabel: "lb", value: "lb" },
    { label: t("product:ounce"), shortLabel: "oz", value: "oz" },
    { label: "Metre", shortLabel: "m", value: "m" },
    { label: "Millilitre", shortLabel: "mm", value: "mm" },
    { label: t("product:gallon"), shortLabel: "gal", value: "gal" },
    { label: t("product:squareMetre"), shortLabel: "m2", value: "m2" },
    { label: t("product:squareMilimetre"), shortLabel: "mm2", value: "mm2" },
    { label: t("product:squareInch"), shortLabel: "in2", value: "mm2" },
    { label: t("product:pieceModal"), shortLabel: "ad", value: "ad" },
    { label: t("product:package"), shortLabel: "pkg", value: "pkg" },
    { label: t("product:box"), shortLabel: "box", value: "box" },
  ];

  const onSubmit = async (value) => {
    const send = {
      image: value?.image || "",
      name: value?.name,
      stockCode: value?.stockCode,
      // type: value?.type,
      supplierGroup: value?.supplierGroup,
      unitType: value?.unit,
      price: Number(price),
      quantity: value?.stockQuantity,
      qualityPercent: qualityPercent || 0,
      criticalStock: value?.criticalStock,
      description: value?.description,
      currency: currency || "USD",
    };

    
    if (!itemId) {
      await rawMaterialService.createRawMaterial(send).then((res) => {
        if (res.data.code == 0) {
          Toast("success", res?.data?.message);
          navigate(-1);
        } else {
          Toast("error", res?.data?.message);
        }
      });
    } else {
      await rawMaterialService.updateRawMaterial(itemId, send).then((res) => {
        if (res.data.code == 0) {
          Toast("success", res?.data?.message);
          navigate(-1);
        } else {
          Toast("error", res?.data?.message);
        }
      });
    }
  };

  useEffect(() => {
    if (rawMaterialData) {
      setValue("image", rawMaterialData.image);
      setValue("name", rawMaterialData.name);
      setValue("stockCode", rawMaterialData.stockCode);
      setValue("type", rawMaterialData?.type);
      setValue("supplierGroup", rawMaterialData.supplierGroup?.id);
      setValue("unit", rawMaterialData.unitType);
      setPrice(rawMaterialData.currentPrice);
      setCurrency(rawMaterialData.currency);
      setValue("stockQuantity", rawMaterialData.quantity);
      setValue("criticalStock", rawMaterialData.criticalStock);
      setValue("description", rawMaterialData.description);
      setQualityPercent(rawMaterialData.qualityPercent);
    }
  }, [rawMaterialData, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full h-full gap-y-8 overflow-y-auto overflow-x-hidden scrollbar-hide">
      {rawMaterialDataLoading ? (
        <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[56px]" />
      ) : (
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
          <p className="text-[#101828] font-semibold text-2xl">{rawMaterialData ? t("product:editRawMaterials") : t("product:createNewRawMaterial")}</p>
        </div>
      )}

      {rawMaterialDataLoading ? (
        <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[84vh]" />
      ) : (
        <div className="flex flex-col gap-y-6 w-full">
          <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
            <div className="flex flex-col gap-y-1">
              <p className="text-[#101828] font-semibold text-lg">{t("product:rawMaterialInformations")}</p>
              <p className="text-[#344054] font-normal text-sm">{t("product:enterTheInformationRaw")} </p>
            </div>
            <div className="flex gap-x-3 max-h-[40px]">
              <span className="flex min-w-[96px] max-w-[96px] w-[96px]">
                <Button size={"md"} colorType={"secondary-gray"} label={t("buttons:stop")} onClick={() => navigate(-1)} />
              </span>
              <span className="flex min-w-[96px] max-w-[96px] w-[96px]">
                <Button size={"md"} colorType={"primary-product"} label={t("buttons:save")} type={"submit"} />
              </span>
            </div>
          </div>
          <div className="flex flex-col w-full gap-y-5">
            <div className="flex w-full gap-x-8 items-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:productImages")}</p>
              </div>
              <div className="flex w-[75%] gap-x-[72px]">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <UploadProfile title={t("product:productImages")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
                  )}
                  name="image"
                />
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:rawMaterialName")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        theme={"product"}
                        errorMessage={errors?.name?.message}
                        validate={errors?.name ? "error" : ""}
                      />
                    )}
                    name="name"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:stockCode")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        theme={"product"}
                        errorMessage={errors?.stockCode?.message}
                        validate={errors?.stockCode ? "error" : ""}
                      />
                    )}
                    name="stockCode"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            {/* <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:rawMaterialType")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Selector
                        value={value}
                        items={rawMaterials}
                        theme={"product"}
                        placeholder={t("product:selectRawMaterialType")}
                        onChange={onChange}
                        onBlur={onBlur}
                        validate={errors?.type ? "error" : ""}
                        errorMessage={errors?.type ? errors?.type?.message : ""}
                      />
                    )}
                    name="type"
                  />
                </span>
              </div>
            </div> */}
            {/* <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" /> */}
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:unitAndUnitPrice")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%] gap-x-3">
                  <span className="flex w-1/2">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Selector
                          value={value}
                          items={units}
                          theme={"product"}
                          placeholder={"Metre (m)"}
                          onChange={onChange}
                          onBlur={onBlur}
                          validate={errors?.unit ? "error" : ""}
                          errorMessage={errors?.unit ? errors?.unit?.message : ""}
                        />
                      )}
                      name="unit"
                    />
                  </span>
                  <span className="flex w-1/2">
                    <UnitPriceInput
                      theme={"product"}
                      items={priceOptions}
                      setValue={(field, value) => {
                        field === "price" ? setPrice(value) : setCurrency(value);
                      }}
                      price={price}
                      currency={currency}
                    />
                  </span>
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:supplierGroup")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Selector
                        value={value}
                        items={data?.map((item) => {
                          return { label: item?.name, value: item?.id };
                        })}
                        theme={"product"}
                        onChange={onChange}
                        placeholder={t("product:selectSupplierGroup")}
                        onBlur={onBlur}
                        validate={errors?.supplierGroup ? "error" : ""}
                        errorMessage={errors?.supplierGroup ? errors?.supplierGroup?.message : ""}
                      />
                    )}
                    name="supplierGroup"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:currentStockQuantity")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        type={"number"}
                        theme={"product"}
                        errorMessage={errors?.stockQuantity?.message}
                        validate={errors?.stockQuantity ? "error" : ""}
                        icon={<QuantityIcon />}
                      />
                    )}
                    name="stockQuantity"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:criticalStockQuantity")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        type={"number"}
                        theme={"product"}
                        errorMessage={errors?.criticalStock?.message}
                        validate={errors?.criticalStock ? "error" : ""}
                        icon={<QuantityIcon />}
                      />
                    )}
                    name="criticalStock"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex flex-col gap-y-1 w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:qualityPercentage")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Input
                    onChange={(e) => {
                      setQualityPercent(e.target.value);
                    }}
                    value={qualityPercent}
                    type={"number"}
                    theme={"product"}
                    // disabled={!selectedQuality}
                    isPercentage
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:description")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextArea
                        onBlur={onBlur}
                        onChange={onChange}
                        rows={3}
                        theme={"product"}
                        value={value}
                        validate={errors.description ? "error" : ""}
                        errorMessage={errors?.description?.message}
                      />
                    )}
                    name="description"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default NewCreateRawMaterial;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_19015_8655)">
        <path
          d="M6.87508 1.66748H4.16675C2.78604 1.66748 1.66675 2.78677 1.66675 4.16748V15.8341C1.66675 17.2149 2.78604 18.3341 4.16675 18.3341H15.8334C17.2141 18.3341 18.3334 17.2149 18.3334 15.8341V4.16748C18.3334 2.78677 17.2141 1.66748 15.8334 1.66748H13.1251M6.87508 1.66748V6.45319C6.87508 6.55026 6.97852 6.61232 7.06416 6.56664L10.0001 5.00081L12.936 6.56664C13.0216 6.61232 13.1251 6.55026 13.1251 6.45319V1.66748M6.87508 1.66748H13.1251"
          stroke="#667085"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_19015_8655">
          <rect width="20" height="20" fill="white" transform="translate(0 0.000976562)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M10 3.00098L4.5 8.50098L2 6.00098" stroke="white" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
