import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useTranslation } from "react-i18next";
import { useStore } from "../../hooks/useStores";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import generateFileUrl from "../../utils/generateFileUrl";
import SearchInput from "../../components/inputs/SearchInput";
import { SortIcon } from "../../assets/icons/productIcons";
import { FilterIcon } from "../machine/embeddedServer/svg";
import { rawMaterialService } from "../../services/raw-material.service";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import NoAvatar from "../../components/avatar/NoAvatar";
import { Loader } from "../../assets/icons/stepsIcons";

const SupplierDetailsInfo = () => {
  const { t } = useTranslation();
  const { settings } = useStore();
  const navigate = useNavigate();
  const params = useLocation();
  const { id } = useParams();
  const pageType = params.pathname.split("/").pop();

  //states
  const [supplierInfo, setSupplierInfo] = useState(null);
  const [supplierGroup, setSupplierGroup] = useState([]);
  const [loading, setLoading] = useState(false);

  //

  const getSupplierInfo = async () => {
    setLoading(true);
    const data = await rawMaterialService.getSupplierInfo(id);
    setSupplierGroup(
      data?.group?.map((item) => {
        return {
          name: item.name,
          id: item.id,
        };
      })
    );
    setSupplierInfo(data);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getSupplierInfo();
    }
  }, []);

  const tabOptions = [
    { value: "info", label: t("product:supplierInfo") },
    { value: "past", label: t("product:supplierPast") },
  ];

  return (
    <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative step-1 scrollbar-hide">
      {loading ? (
        <div className="flex justify-center items-center h-[60px] w-full bg-secondary-100 animate-pulse rounded-xl"></div>
      ) : (
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
          </span>
          <div className="flex items-center">
            <Button
              colorType={"tertiary-gray"}
              onClick={() => {
                navigate(-1);
              }}
              size={"sm"}
              iconLeft={
                <div className="rotate-180">
                  <TableArrowLeft />
                </div>
              }
            />

            <div className="flex items-center ml-2">
              {supplierInfo?.image ? (
                <img className="w-12 h-9 rounded-[4px] border-[1px] border-[#D0D5DD]" src={generateFileUrl(supplierInfo?.image)} />
              ) : (
                <NoAvatar size={40} fontSize={22} name={supplierInfo?.name} lastName={""} color={"#B54708"} />
              )}

              <span className="text-2xl text-secondary-900 font-semibold ml-2">{supplierInfo?.name}</span>
            </div>
          </div>
          <span className="ml-auto">
            <Button
              colorType={"primary-product"}
              size={"md"}
              label={t("product:supplierList")}
              onClick={() => navigate("/app/product/mrp/supply/service/list")}
            />
          </span>
        </div>
      )}

      {loading ? (
        <div className="flex flex-col w-full ">
          <div className="bg-secondary-100 w-full animate-pulse h-[52px] rounded-xl my-3"></div>
          <div className="flex  max-h-[150px] h-[150px] bg-secondary-100 w-full animate-pulse rounded-xl"> </div>
          <div className="flex flex-col w-full border shadow-lg border-[#E4E7EC] rounded-xl mt-6 bg-secondary-100 animate-pulse h-[600px]"> </div>
        </div>
      ) : (
        <>
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-6 mb-4">
            {tabOptions?.map((option, index) => (
              <div
                className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                  "info" == option?.value ? "bg-white shadow-sm" : ""
                }`}
                onClick={() => {
                  if (option?.value != "info") navigate(`/app/product/mrp/supply/supplier/details/${id}/past/all`);
                }}
              >
                <p className={`text-md font-semibold ${"info" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
              </div>
            ))}
          </div>

          <div className="flex w-full max-h-[150px] h-[150px]">
            <div className="w-1/2 flex gap-x-6 mr-6 ">
              <div className="w-full flex flex-col gap-y-3">
                <div className=" border-[1px]  max-h-[68px] border-[#D0D5DD] rounded-lg px-4 py-3 ">
                  <p className="text-[#475467] text-sm font-normal">{t("table:phoneNo")}</p>
                  <p className="text-md text-[#B54708] font-medium">{supplierInfo?.contactPhone}</p>
                </div>
                <div className="border-[1px]  max-h-[68px] border-[#D0D5DD] rounded-lg px-4 py-3 ">
                  <p className="text-[#475467] text-sm font-normal">{t("login:email")}</p>
                  <p className="text-md text-[#B54708] font-medium">{supplierInfo?.contactEmail}</p>
                </div>
              </div>
              <div className="flex flex-col gap-y-3 w-full">
                <div className="border-[1px]  max-h-[68px] border-[#D0D5DD] rounded-lg px-4 py-3 ">
                  <p className="text-[#475467] text-sm font-normal">{t("product:totalPaymentMade")}</p>
                  <p className="text-md text-[#B54708] font-semibold">--</p>
                </div>
                <div className="border-[1px] flex-col  max-h-[68px] border-[#D0D5DD] rounded-lg px-4 py-3  flex items-start">
                  <p className="text-[#475467] text-sm font-normal">{t("product:authorizedPerson")}</p>
                  <p className="text-md text-[#B54708] font-medium">{supplierInfo?.contactName}</p>
                </div>
              </div>
            </div>
            <div className="w-1/2 h-[148px] min-h-[148px]">
              <div className="w-full flex flex-col gap-y-3">
                <div className=" border-[1px]  max-h-[68px] border-[#D0D5DD] rounded-lg px-4 py-3 ">
                  <p className="text-[#475467] text-sm font-normal">{t("product:associatedGroup")}</p>
                  {console.log("supplierGroup", supplierGroup)}
                  {supplierGroup.map((e, index) => (
                    <span key={index} className="text-md text-[#B54708] font-medium">
                      {e?.name}
                      {index !== supplierGroup.length - 1 && ", "}
                    </span>
                  ))}
                </div>
                <div className="border-[1px]  max-h-[68px] border-[#D0D5DD] rounded-lg px-4 py-3 ">
                  <p className="text-[#475467] text-sm font-normal">{t("routes:adress")}</p>
                  <p className="text-md text-[#B54708] font-medium">{supplierInfo?.address}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full border shadow-lg border-[#E4E7EC] rounded-xl mt-6">
            <div className="flex w-full min-h-[64px] max-h-[64px] h-[64px] justify-between items-center px-6 py-3 border-b border-[#E4E7EC]">
              <p className="text-[#101828] font-semibold text-lg">{t("product:supplierTransactions")}</p>
              <div className="flex gap-x-3 items-center">
                <SearchInput theme={"product"}/>
                <Button colorType={"secondary-gray"} iconLeft={<SortIcon />} size={"md"} />
                <Button colorType={"secondary-gray"} iconLeft={<FilterIcon />} size={"md"} />
              </div>
            </div>
            <div className="flex flex-col h-full overflow-y-auto overflow-x-hidden scrollbar-hide">
              <div className="flex max-h-[34px] min-h-[34px] max-w-full min-w-full">
                {/* {tableHeadItems.map((item, i) => {
                return (
                  <div
                    key={i}
                    scope="col"
                    className={`flex px-4 py-2 last:border-r-0 ${i === 5
                      ? "w-[4%] justify-center items-center"
                      : i === 4
                        ? "w-[29%] justify-center items-center"
                        : i === 2 || i === 1
                          ? `w-[17%] justify-center items-center`
                          : i == 5
                            ? `w-full`
                            : i === 3 ? "w-[18%]" :
                              i === 0
                                ? "w-[15%] justify-center items-center"
                                : ""
                      } bg-secondary-50 border-gray-200 border-b border-r`}
                  >
                    <span className={`text-center rounded flex text-xs font-medium items-center whitespace-nowrap`}>{item?.name}</span>
                  </div>
                );
              })} */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SupplierDetailsInfo;
