import React, { useState } from "react";
import { CheckIcon, PlusIcon } from "../card-icons";
import CustomImage from "../../CustomImage";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { junctionService } from "../../../services/junction.service";
import SelectUserModal from "../../modal/SelectUserModal";
import CustomModal from "../../modal/CustomModal";
import { useQuery } from "@tanstack/react-query";
import EquipmentDetailPlannedCard from "../equipment-detail-card/EquipmentDetailPlannedCard";
import NoData from "../../../steps/step-navigation/components/NoData";
import { useNavigate, useParams } from "react-router-dom";
const OperationDetailPassiveCard = ({ data, refetch, processId, startDate }) => {
  let { dates } = useParams();
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [isOpenSelectProductModal, setIsOpenSelectProductModal] = useState(false);
  const [selectedJunctionForPlanned, setSelectedJunctionForPlanned] = useState(null);
  const [isOpenSelectProduct, setIsOpenSelectProduct] = useState(false);
  const planned = useQuery({
    queryKey: ["get-planned-junctions"],
    queryFn: async () => await junctionService?.getPlannedJunctions(data?.nodeId),
    retry: 0,
  });
  
  return (
    <>
      <div className="cursor-pointer flex flex-row items-center gap-x-3 w-full">
        <div className="flex items-center flex-col gap-y-1 justify-center border border-secondary-300 rounded-t-[4px] rounded-b-lg p-1 w-[88px] min-w-[88px] bg-[#F9FAFB]">
          <p className="text-xs w-[75px] truncate text-center font-semibold text-secondary-700">{data?.nodeName || "--"}</p>
          {data?.nodeImage ? (
            <div
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"right"}
              data-tooltip-content={`
          ${data?.nodeName} 
          `}
            >
              <div onClick={() => navigate(`/app/machine/digital-twin/equipment-details/${data?.deviceId}-${data?.nodeId}/${dates}`)} className="cursor-pointer">
                <CustomImage
                  borderRadius={2}
                  src={data?.nodeImage}
                  style={{
                    width: 80,
                    height: 60,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            </div>
          ) : (
            <div onClick={() => navigate(`/app/machine/digital-twin/equipment-details/${data?.deviceId}-${data?.nodeId}/${dates}`)} className="w-[80px] min-w-[80px] h-[60px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
              <NoEquIcon />
            </div>
          )}
        </div>
        <div
          className={`flex w-full flex-col border border-t-4 cursor-pointer ${"border-t-[#98A2B3]"} items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
        >
          <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
            <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
              <button
                onClick={() => setIsOpenSelectProductModal(true)}
                type="button"
                className="w-[64px] min-w-[64px] h-[48px] border-dashed  border border-gray-300 flex items-center justify-center"
              >
                <PlusIcon />
              </button>
              <div className="flex flex-col items-start gap-y-[6px]">
                <p className="text-sm font-semibold text-secondary-900 truncate w-[120px]">{t("product:addProduct")}</p>
                <div className="flex flex-row items-center gap-x-2">
                  <p className="text-xs font-medium text-secondary-600 w-[120px] truncate">{t("product:addPersonnels")}</p>
                </div>
              </div>
            </div>
            <button onClick={() => setIsOpenSelectProductModal(true)} type="button" className="min-w-[48px] h-[48px] rounded-full border-dashed  border border-gray-300 flex items-center justify-center">
              <PlusIcon />
            </button>
          </div>
          <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB]  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
            <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 px-2 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
              <CheckIcon />
              <p className="text-xxs text text-secondary-600 font-semibold">{t("product:passives")}</p>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isOpenSelectProductModal}
        setIsOpen={setIsOpenSelectProductModal}
        onClose={() => {
          setIsOpenSelectProductModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <>
          {planned?.data?.length === 0 ? (
            <div className="flex items-center h-[440px]  min-w-[400px] justify-center">
            <NoData theme={"product"} header={t("label:noData")} button={false} />
            </div>
          ):(
            <div className="flex flex-col h-[440px] overflow-y-scroll scrollbar-hide min-w-[400px]">
            {planned?.data?.map((pla) => {
              return (
                <div className="flex w-full flex-col gap-y-3 ">
                <div className="flex flex-col  mt-4 ">
                <EquipmentDetailPlannedCard
                  data={pla}
                  refetch={refetch}
                  page={"operationDetail"}
                  setSelectedJunctionForPlanned={setSelectedJunctionForPlanned}
                  setIsOpenSelectProduct={setIsOpenSelectProduct}
                />
                </div>
                </div>
              );
            })}
          </div>
          )}
        
          </>
        }
      />
      <CustomModal
        isOpen={isOpenSelectProduct}
        setIsOpen={setIsOpenSelectProduct}
        modalTitle={t("chat:startJob")}
        subTitle={t("product:junctionSubtitile")}
        isBadges={false}
        badgesColor={"fill-primary"}
        badgesSize={"md"}
        children={<SelectUserModal selectedJunction={selectedJunctionForPlanned} setIsOpen={setIsOpenSelectProduct} handleData={refetch} />}
      />
    </>
  );
};

export default OperationDetailPassiveCard;
