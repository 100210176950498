import React, { useEffect, useRef, useState } from "react";
import { DeleteIcon, EditIcon } from "../../../assets/icons/departmentIcon";
import { Button } from "../../../components";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Threedots } from "../../../assets/icons/stepsIcons";

const DefinedCard = ({
  t,
  name,
  process,
  avatar,
  isEquipment = false,
  editOnClick,
  deleteOnclick,
  isMobile,
  width,
}) => {
  const cardref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (cardref?.current && !cardref?.current?.contains(event?.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={`bg-white min-h-[64px] ${
          width ? width : "min-w-[385px]"
        } xs:min-w-[315px] border border-l-[8px] dark:bg-[#141414] dark:border-[#424242] border-gray-300 shadow-xs rounded-lg flex items-center justify-between py-[10px] px-4 relative`}
      >
        <div className="flex items-center text-sm font-semibold text-[#101828] gap-3 xs:gap-2">
          {avatar ? (
            <img
              className={` ${
                isEquipment == false
                  ? "min-w-[48px] h-12 rounded-full"
                  : "min-w-[60px] h-[45px] rounded-lg"
              }      flex items-center justify-center`}
              src={generateFileUrl(avatar)}
              alt="image"
            />
          ) : (
            <div
              className={
                "min-w-[48px] h-12 rounded-full bg-primary-700 flex border-4 border-primary-400 items-center justify-center font-medium text-white text-xl"
              }
            >
              {name?.charAt(0).toUpperCase()}
            </div>
          )}

          <div className="leading-5 text-[14px] w-[175px] xs:w-[150px]">
            <p className="text-[#344054] font-semibold dark:text-[#D6D6D6] truncate">
              {name || "No Name"}
            </p>
            <p className="text-[#667085] font-normal dark:text-[#D6D6D6] truncate">
              {process}
            </p>
          </div>
        </div>

        {isMobile ? (
          <div
            ref={cardref}
            className="flex flex-row gap-0 items-center justify-center"
          >
            {isOpen ? (
              <>
                <div className=" flex flex-col rounded-lg gap-0 h-[77px] w-[100px] ml-[-66px] shadow-lg absolute bg-[#FFF] border border-solid border-[#EAECF0]">
                  <Button
                    label={t("buttons:edit")}
                    iconLeft={<EditIcon />}
                    colorType={"tertiary-gray"}
                    size={"md"}
                    onClick={() => {
                      editOnClick();
                    }}
                  />
                  <Button
                    label={t("buttons:delete")}
                    iconLeft={<DeleteIcon />}
                    colorType={"tertiary-error"}
                    size={"md"}
                    onClick={() => {
                      deleteOnclick();
                    }}
                  />
                </div>
              </>
            ) : (
              <Button
                iconLeft={<Threedots color="#98A2B3" />}
                colorType={"tertiary-gray"}
                size={"md"}
                onClick={() => {
                  setIsOpen(true);
                }}
              />
            )}
          </div>
        ) : (
          <div className="flex flex-row gap-3 xs:hidden items-center justify-center">
            <Button
              iconLeft={<EditIcon />}
              colorType={"tertiary-gray"}
              size={"md"}
              onClick={editOnClick}
            />
            <Button
              iconLeft={<DeleteIcon />}
              colorType={"tertiary-error"}
              size={"md"}
              onClick={deleteOnclick}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default DefinedCard;
