import React from 'react'
import PersonelDetailGaugeChart from './chart/PersonelDetailGaugeChart'
import { useTranslation } from 'react-i18next'
import PersonnelDashboardGaugeChart from './chart/PersonnelDashboardGaugeChart'

const PersonnelDashboardDetailCharts = ({gaugeData}) => {
    const {t} = useTranslation()
  return (
    <div className="flex w-full h-full gap-x-4">
    {gaugeData?.map((item, index) => (
      <PersonnelDashboardGaugeChart
        key={index}
        gaugeData={gaugeData}
        name={t(`addProcess:${item?.type}`)}
        value={item?.actual ? item?.actual : 0}
        timeMass={item?.timeMass || 0}
        kpi1={item?.average?.toFixed(2) || 0}
        kpi2={item?.kpi || 0}
        // selectedProcess={data?.id}
      />
    ))}
  </div>
  )
}

export default PersonnelDashboardDetailCharts