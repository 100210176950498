import React, { useState, useEffect } from "react";
import {
  ValidateSuccess,
  ValidateWarning,
  ToolTip,
  ValidateError,
} from "../../assets/icons";
import PhoneCodes from "./counter-phone-code.json";
import { ArrowDownIcon } from "../../assets/icons/stepsIcons";

const PhoneInput = ({
  label,
  icon,
  placeholder,
  onChange,
  onBlur,
  value,
  disabled,
  validate,
  iconPosition,
  field,
  type,
  size,
  theme,
  errorMessage,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({ code: "TR", value: "+90" });
  const sizeStyles = {
    xs: "40px",
    sm: "44px",
  };
  const inputSize = sizeStyles[size] || "40px";

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelect = (item) => {
    setSelected({ code: item.code, value: item.value });
    onChange(item.value);
    setOpen(false);
  };

  useEffect(() => {
    onChange("+90");
  }, []);

  return (
    <div className="flex flex-col relative w-full ">
      <span className="text-sm mb-1.5 pl-0  w-full text-secondary-700 dark:text-darkTrue-200 font-medium">
        {label}
      </span>

      <div
        style={{ height: inputSize }}
        className={`${size === "sm" ? "pr-[14px] py-[10px]" : "pr-3 py-2"}  ${
          theme === "person"
            ? `focus-within:border-teal-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#C4EDE6] `
            : theme === "product"
            ? "focus-within:border-warning-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#FDE3C1] "
            : theme === "embedded"
            ? "focus-within:border-grayBlue-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#D2D6E8] "
            : "focus-within:border-primary-300 focus-within:outline  focus-within:outline-4 focus-within:outline-[#E8DFFB] "
        } flex w-full items-center flex-row gap-2 border focus-within:ring-opacity-50  ${
          theme === "person"
            ? ` ${
                open && "outline outline-4 outline-[#C4EDE6] border-teal-300"
              } `
            : theme === "product"
            ? ` ${
                open && "outline outline-4 outline-[#FDE3C1] border-warning-300"
              } `
            : theme === "embedded"
            ? ` ${
                open &&
                "outline outline-4 outline-[#D2D6E8] border-grayBlue-300"
              }`
            : ` ${
                open && "outline outline-4 outline-[#E8DFFB] border-primary-300"
              }`
        } transition duration-300 rounded-lg dark:border-darkTrue-600 dark:bg-[#0F0F0F]
          ${
            validate === "success"
              ? "border-validate-success"
              : validate === "warning"
              ? "border-validate-warning"
              : validate === "error"
              ? "border-validate-errorBorder"
              : "border-secondary-300"
          } text-secondary-500 text-base
            ${disabled ? "bg-secondary-50  mx-0" : "bg-white"} `}
      >
        {validate && type !== "password" ? (
          <div className="flex items-center absolute right-[10px] ">
            {validate === "success" ? (
              <ValidateSuccess />
            ) : validate === "warning" ? (
              <ValidateWarning />
            ) : validate === "error" ? (
              <ValidateError />
            ) : validate === "tooltip" ? (
              <ToolTip />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        <div className="flex items-center w-[60px] max-w-[60px] relative">
          <div className="relative w-full ">
            <div
              onClick={handleToggle}
              style={{ height: 24 }}
              className={`flex ${
                size === "sm" ? "px-[14px] py-[10px]" : "px-3 py-2"
              }  flex-row gap-1 items-center bg-white dark:bg-[#0F0F0F] `}
            >
              <p className="text-secondary-700 dark:text-[#D6D6D6] font-normal text-md">
                {selected.code}
              </p>

              <span className="flex items-center ml-auto dark:text-[#737373] ">
                <ArrowDownIcon
                  width={20}
                  height={20}
                  className={`h-5 w-5 items-center justify-center dark:text-[#FAFAFA]  ${
                    open ? "rotate-180" : ""
                  }`}
                  aria-hidden="true"
                />
              </span>
            </div>
          </div>
        </div>
        {open && (
          <div className="absolute z-[99999] w-full max-h-40 py-1 ring-opacity-5 overflow-auto mt-52 focus:outline-none rounded-lg shadow-lg bg-white border-secondary-200 border-t border dark:border-[#525252] dark:bg-[#0F0F0F]">
            {PhoneCodes?.map((item) => (
              <div
                key={item.value}
                className="cursor-pointer hover:bg-[#292929]  rounded-md mx-1 p-[10px]"
                onClick={() => handleSelect(item)}
              >
                <div className="flex flex-row items-center gap-2">
                  <p className="font-medium text-secondary-900 text-md dark:text-[#FAFAFA]">
                    {item.label}
                  </p>
                  <p className="font-normal text-secondary-500 text-md dark:text-[#FAFAFA]">
                    ({item.value})
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        <input
          value={value ? value : "+90"}
          onBlur={onBlur}
          {...field}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          style={{ height: inputSize }}
          className={`h-full border-transparent text-base font-normal ${
            placeholder
              ? "text-secondary-500"
              : disabled
              ? "text-red-500"
              : "text-secondary-900"
          } dark:bg-[#0F0F0F] dark:text-darkTrue-50 focus:border-transparent focus:ring-0 focus:outline-none border-none rounded-lg w-full px-0 ${
            type !== "password" && icon
              ? iconPosition === "right"
                ? "mr-5"
                : "mx-5"
              : "pl-0"
          } ${disabled ? "bg-secondary-50 dark:bg-[#0F0F0F] " : <></>} `}
          type={
            type == "password"
              ? isVisible
                ? "text"
                : "password"
              : type == "number"
              ? "number"
              : "text"
          }
        />
      </div>
      {validate ? (
        <p
          className={` ${
            validate === "success" ? (
              "text-validate-success"
            ) : validate === "warning" ? (
              "text-validate-warning"
            ) : validate === "error" ? (
              "text-validate-error"
            ) : validate === "tooltip" ? (
              "text-secondary-500 text-sm leading-5"
            ) : (
              <></>
            )
          } text-sm mt-1.5 font-normal `}
        >
          {errorMessage || validate.toLocaleUpperCase()}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PhoneInput;
