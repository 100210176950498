import React, { useEffect, useState } from "react";
import { Button, CustomModal, Loading } from "../../components";
import { ArrowLeftIcon, ArrowRightIcon, ScrollChevronIcon } from "../../assets/icons/productIcons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import TransactionDetailModal from "../../components/modal/TransactionDetailModal";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import SelectUserModal from "../../components/modal/SelectUserModal";
import SelectEquModal from "../../components/modal/SelectEquModal";
import QualityControlForm from "../../components/modal/QualityControlForm";
import { CheckIcon, ChevronDown } from "../../assets/icons/PersonIcons";
import { ScrollSyncPane } from "react-scroll-sync";
import useWindowDimensions from "../../hooks/useWindowDimension";
import QualityDoneCard from "../../components/new-cards/quality-done-card/QualityDoneCard";
import DoneAndQualityWaitingCard from "../../components/new-cards/done-quality-waiting-card/DoneAndQualityWaitingCard";
import ActiveCard from "../../components/new-cards/active-card/ActiveCard";
import PlannedCard from "../../components/new-cards/plan-card/PlannedCard";
import QualityTab from "./quality/components/QualityTab";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Toast } from "../../utils/toastify/toast";
import ServiceSupplyTab from "./serviceSupply/ServiceSupplyTab";
import ServiceSupplyPlanModal from "./modals/ServiceSupplyPlanModal";
import SendToSupplierModal from "./modals/SendToSupplierModal";
import ServiceSupplyReceiveModal from "./modals/ServiceSupplyReceiveModal";
import ServiceSupplyQualityControlForm from "./modals/ServiceSupplyQualityControlForm";
import ServiceSupplySupplierEventCard from "../../components/new-cards/service-supply/components/ServiceSupplySupplierEventCard";
import ServiceSupplySsPlannedEventCard from "../../components/new-cards/service-supply/components/ServiceSupplySsPlannedEventCard";
import ServiceSupplierQualityApliedEventCard from "../../components/new-cards/service-supply/components/ServiceSupplierQualityApliedEventCard";
import ServiceSupplyReceivedEventCard from "../../components/new-cards/service-supply/components/ServiceSupplyReceivedEventCard";
import Badgets from "../../components/buttons/Badgets";
import OperationDetailQualityAppliedCard from "../../components/new-cards/material-supply/OperationDetailQualityAppliedCard";
const OperationJunctions = ({
  data,
  refetch,
  orderIds,
  selectedJunctions,
  setSelectedJunctions,
  junctionIds,
  onScrolling = () => { },
  isFetching = false,
  setSelectedOperation = () => { },
  selectedOperation,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [junctionId, setJunctionId] = useState(null);
  const { height } = useWindowDimensions();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [junctionType, setJunctionType] = useState("internal");
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [isSelectUserModal, setIsSelectUserModal] = useState();
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [isSelectNodeModal, setIsSelectNodeModal] = useState();
  const [junctionDetailOpen, setJunctionDetailOpen] = useState(false);
  const [productType, setProductType] = useState("standart");
  const [draggedJunction, setDraggedJunction] = useState(null);
  const [finishPurhcaseModal, setFinishPurhcaseModal] = useState(false);
  const [isOpenPlanningModalData, setIsOpenPlanningModalData] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [purchaseQualityForm, setPurchaseQualityForm] = useState(false);
  const [purchaseQualityFormData, setPurchaseQualityFormData] = useState(null);
  const [lastSortedCardId, setLastSortedCardId] = useState(null);
  const [finishPurhcaseModalData, setFinishPurhcaseModalData] = useState(null);
  const [supplierPurhcaseModal, setSupplierPurhcaseModal] = useState(false);
  const [finishSupplierModalData, setFinishSupplierModalData] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isPurchaseRequestVisible, setIsPurchaseRequestVisible] = useState(false);
  const [isServiceRequestVisible, setIsServiceRequestVisible] = useState(true);
  const [isMaterialRequestVisible, setIsMaterialRequestVisible] = useState(true);
  const [isPurchaseRequestMaterialVisible, setIsPurchaseRequestMaterialVisible] = useState(false);


  const toggleSelection = (id) => {
    if (junctionIds.includes(id)) {
      setSelectedJunctions(selectedJunctions.filter((junction) => junction.id !== id));
    } else {
      setSelectedJunctions([...selectedJunctions, { id: id }]);
    }
  };


  const junctions = window.location.href.includes("move-junction")
    ? data?.filter((obj) => obj?.status == "active" && obj.quality != "applied")
    : data?.filter((obj) => (obj?.status === "active" && obj.quality != "applied") || obj?.status === "planned" && obj.quality != "applied");

  const activeJunctions = [...junctions];

  const completedWorks = data?.filter((obj) => obj?.status === "completed" && obj?.metadata?.qualityStatus == "waiting");

  const completedAndApprovedWorks = data?.filter((obj) => (obj?.status === "completed" && obj?.metadata?.qualityStatus != "waiting"))
  const moments = require("moment");
  require("moment/locale/tr");

  const sortedCompletedWorks = completedWorks?.sort((a, b) => moment(b?.startDate).unix() - moment(a?.startDate).unix());

  const groupedByDateCompleted = sortedCompletedWorks?.reduce((acc, currentItem) => {
    const endDate = moments(currentItem?.startDate).locale("tr").format("LL");
    if (!acc[endDate]) {
      acc[endDate] = [];
    }
    acc[endDate].push(currentItem);
    return acc;
  }, {});

  const sortedCompletedAndApproved = completedAndApprovedWorks?.sort((a, b) => moment(b?.startDate).unix() - moment(a?.startDate).unix());

  const groupedByDateCompletedAndApproved = sortedCompletedAndApproved?.reduce((acc, currentItem) => {
    const endDate = moments(currentItem?.startDate).locale("tr").format("LL");
    if (!acc[endDate]) {
      acc[endDate] = [];
    }
    acc[endDate].push(currentItem);
    return acc;
  }, {});

  const handlePlannedOnDrop = () => {

    if (draggedJunction?.id) {
      if (draggedJunction?.status === "waiting" || draggedJunction?.status === "started" || draggedJunction?.flexProduct || draggedJunction?.flexRawMaterial) {
        setIsOpenPlanningModal(true);
        setIsOpenPlanningModalData(draggedJunction);
      } else {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
    }
  };

  const handleFinishedOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === "received" || draggedJunction?.status === "completed")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setPurchaseQualityForm(true);
      setPurchaseQualityFormData(draggedJunction);
    }
  };

  const handleWaitingOnDrop = () => {
    if (draggedJunction?.id) {
      if (draggedJunction?.deliveredQuantity === draggedJunction?.acceptedQuantity) {
        Toast("error", t("product:columnDragDropWarningMaterialPlannedCardSameUnit"));
        return
      }
      if (!(draggedJunction?.status === "waiting")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setFinishPurhcaseModal(true);
      setFinishPurhcaseModalData(draggedJunction);
    }
  };

  const handleSupplierOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === null || draggedJunction?.status === "waiting" || draggedJunction?.status === "active")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setSupplierPurhcaseModal(true);
      setFinishSupplierModalData(draggedJunction);
    }
  };

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };

  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const [columns, setColumns] = useState({
    active: activeJunctions,
    waiting: sortedCompletedWorks,
    finished: sortedCompletedAndApproved,
  });

  const handleCardMove = (sourceColumn, destinationColumn, taskId) => {
    if (sourceColumn === "active" && destinationColumn === "waiting") {
      setJunctionType(selectedOperation?.type);
      setFinishJunctionId(taskId);
      setIsOpenJunctionPiece(true);
    } else if (sourceColumn === "waiting" && destinationColumn === "finished") {
      setJunctionId(taskId);
      setIsOpenForm(true);
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId) {
      Toast("error", t("product:sameColumnWarning"));
      return;
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    const validMoves = [
      { from: "active", to: "waiting" },
      { from: "waiting", to: "finished" },
    ];

    const isValidMove = validMoves.some((move) => move.from === source.droppableId && move.to === destination.droppableId);

    if (!isValidMove) {
      Toast("error", t("product:columnDragDropWarning"));
      return;
    }

    const startTaskIds = Array.from(start);
    startTaskIds.splice(source.index, 1);
    const newStart = startTaskIds;

    const finishTaskIds = Array.from(finish);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = finishTaskIds;

    setColumns({
      ...columns,
      [source.droppableId]: newStart,
      [destination.droppableId]: newFinish,
    });

    handleCardMove(source.droppableId, destination.droppableId, draggableId);
  };


  const filteredOperationSsPlanned = selectedOperation?.plannedJunctions
  const filteredOperationSupplier = selectedOperation?.listjunctionAtServiceSupplier
  const filteredQualityController = selectedOperation?.junctions?.concat(selectedOperation?.purchaseJunction || [])?.filter(item => item?.status === "completed" || item?.quality === "applied");
  const filteredReceivedController = selectedOperation?.junctions?.filter(item => item?.status === "received");
  const purchaseRequestItems = filteredQualityController?.filter((item) => item?.purchaseRequest);

  const handleSortBySelectedCard = (items, type) => {
    if (!selectedCardId) return items;
    const isSorted = selectedCardId === lastSortedCardId;
    const sortedItems = items?.sort((a, b) => {
      let aId, bId;

      if (type === "planned") {
        aId = a?.id;
        bId = b?.id;
      } else {
        aId = a?.plannedJunctionRef?.id || a?.serviceSupplierJunction?.plannedJunctionRef?.id;
        bId = b?.plannedJunctionRef?.id || b?.serviceSupplierJunction?.plannedJunctionRef?.id;
      }
      if (aId === selectedCardId && bId !== selectedCardId) return -1;
      if (aId !== selectedCardId && bId === selectedCardId) return 1;
      if (isSorted) return bId - aId;

      return 0;
    });
    return sortedItems?.map(item => ({
      ...item,
      opacity: item?.plannedJunctionRef?.id === selectedCardId || item?.id === selectedCardId || item?.serviceSupplierJunction?.plannedJunctionRef?.id === selectedCardId ? 1 : 0.25,
    }));
  };

  const handleCardClick = (cardId, ssNo) => {
    if (selectedCardId === cardId) {
      // Aynı karta tekrar tıklanırsa sıralamayı ve msNo'yu sıfırla
      setSelectedCardId(null);
      setLastSortedCardId(null);
    } else {
      // Yeni bir kart seçildiyse, lastSortedCardId ve msNo'yu güncelle
      setSelectedCardId(cardId);
      setLastSortedCardId(cardId);
    }
  };

  return (
    <>
      <div
        onScrollCapture={onScrolling}
        className={`w-full flex flex-col h-full mt-2  `}
      >
        <ScrollSyncPane>
          <div style={{ minHeight: height - 120, height: height - 120, maxHeight: height - 120 }} className="w-full h-full rounded-xl flex flex-col relative">
            <div
              className={`w-11 h-full hidden sm:flex md:flex lg:flex ${isRightArrowHidden ? "hidden duration-1000" : ""
                } z-[120] right-0 backdrop-blur-md cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-[#FFFFFF33]`}
              onClick={() => {
                sideScroll(document.getElementById("content-wrapper"), 25, 100, 20);
              }}
            >
              <ScrollChevronIcon />
            </div>

            <div
              className={`w-11 h-full  hidden sm:flex md:flex lg:flex ${isLeftArrowHidden ? "hidden duration-1000" : ""
                } z-50 left-0 absolute cursor-pointer flex bg-[#FFFFFF33] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
              onClick={() => {
                sideScroll(document.getElementById("content-wrapper"), 25, 100, -20);
              }}
            >
              <ScrollChevronIcon />
            </div>
            {selectedOperation?.type === "external" ? (
              <div className="flex w-full h-full overflow-x-auto pb-6 pt-2 overflow-y-hidden">
                <ServiceSupplyTab
                  length={filteredOperationSsPlanned?.length || 0}
                  type={"planned"}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  onDrop={() => {
                    handlePlannedOnDrop();
                  }}
                  children={
                    <PlannedJunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={handleSortBySelectedCard(filteredOperationSsPlanned, "planned")}
                      onClick={handleCardClick}
                      selectedCardId={selectedCardId}
                      setIsOpenPlanningModalData={setIsOpenPlanningModalData}
                      setIsOpenPlanningModal={setIsOpenPlanningModal}
                      refetch={refetch}
                    />
                  }
                />
                <ServiceSupplyTab
                  length={filteredOperationSupplier?.length || 0}
                  type={"supplier"}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleSupplierOnDrop();
                  }}
                  children={
                    <SupplierListController
                      setDraggedJunction={setDraggedJunction}
                      items={handleSortBySelectedCard(filteredOperationSupplier, "supplier")}
                      setFinishSupplierModalData={setFinishSupplierModalData}
                      setSupplierPurhcaseModal={setSupplierPurhcaseModal}
                      onClick={handleCardClick}
                      selectedCardId={selectedCardId}
                      refetch={refetch}
                    />
                  }
                />
                <ServiceSupplyTab
                  length={filteredReceivedController?.length || 0}
                  type={"received"}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleWaitingOnDrop();
                  }}
                  children={
                    <JunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={handleSortBySelectedCard(filteredReceivedController, "received")}
                      onClick={handleCardClick}
                      selectedCardId={selectedCardId}
                      setFinishPurhcaseModalData={setFinishPurhcaseModalData}
                      setFinishPurhcaseModal={setFinishPurhcaseModal}
                      refetch={refetch}
                    />
                  }
                />
                <ServiceSupplyTab
                  length={filteredQualityController?.length || 0}
                  type={"complate"}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleFinishedOnDrop();
                  }}
                  children={
                    <div className="flex flex-col gap-y-2 w-full items-start">
                      <div className="flex flex-col w-full">
                        <div className="w-full flex flex-row items-center justify-between border-b pb-1">
                          <div className="flex flex-row items-center gap-x-2">
                            <p className="text-sm font-medium text-secondary-700">{t("product:serviceJunction")}</p>
                            <Badgets colorType={"fill-gray"} size={"sm"} label={filteredQualityController?.filter(
                              (item) => !item?.purchaseRequest
                            )?.length || 0} />
                          </div>
                          <div onClick={() => setIsServiceRequestVisible(!isServiceRequestVisible)} className={`w-9 ${isServiceRequestVisible && "rotate-180"}`}>
                            <Button colorType={"tertiary-gray"} size={"sm"} iconLeft={<ChevronDown />} />
                          </div>
                        </div>
                        {isServiceRequestVisible && (
                          <div className="mt-2">
                            <QualityApliedController
                              setDraggedJunction={setDraggedJunction}
                              items={handleSortBySelectedCard(filteredQualityController, "complate")}
                              onClick={handleCardClick}
                              selectedCardId={selectedCardId}
                              setPurchaseQualityFormData={setPurchaseQualityFormData}
                              setPurchaseQualityForm={setPurchaseQualityForm}
                              refetch={refetch}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex w-full flex-col">
                        <div className="w-full flex flex-row items-center justify-between border-b pb-1">
                          <div className="flex flex-row items-center gap-x-2">
                            <p className="text-sm font-medium text-secondary-700">{t("product:purchaseEvent")}</p>
                            <Badgets colorType={"fill-gray"} size={"sm"} label={purchaseRequestItems?.length || "0"} />
                          </div>

                          <div onClick={() => setIsPurchaseRequestVisible(!isPurchaseRequestVisible)} className={`w-9 ${isPurchaseRequestVisible && "rotate-180"}`}>
                            <Button colorType={"tertiary-gray"} size={"sm"} iconLeft={<ChevronDown />} />
                          </div>
                        </div>
                        {isPurchaseRequestVisible && (
                          <div className="mt-2">
                            {purchaseRequestItems?.map((item, index) => (
                              <div>
                                <div className="flex flex-col gap-y-1 pb-2">
                                  <OperationDetailQualityAppliedCard data={item} isModal={true} />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            ) : (
              <ScrollSyncPane>
                <DragDropContext onDragEnd={onDragEnd}>
                  <div id="content-wrapper" className="w-full pb-1 flex h-full max-h-[95vh]">
                    <QualityTab
                      type={"active"}
                      length={activeJunctions?.filter((i) => i?.station)?.length || activeJunctions?.filter((i) => i?.externalOperation)?.length}
                      children={
                        <Droppable droppableId="active">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className="w-full overflow-y-auto flex flex-col scrollbar-hide h-full">
                              {activeJunctions
                                ?.sort((b, a) => moment(a?.startDate ? a.startDate : 0).unix() - moment(b?.startDate ? b?.startDate : 0).unix())
                                ?.map((item, index) => {
                                  if (item?.status === "active") {
                                    return (
                                      <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`${snapshot.isDragging ? "max-w-[500px]" : ""} w-full mb-3`}
                                            key={index}
                                          >
                                            <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                              <p className="text-sm font-medium text-secondary-700">{moments(new Date()).locale("tr").format("LL")}</p>
                                            </div>
                                            <div className="mt-3">
                                              <SelectBoxComp item={item} junctionIds={junctionIds} toggleSelection={toggleSelection} />
                                              <ActiveCard
                                                data={item}
                                                operation={{
                                                  operation: {
                                                    internalOperation: {
                                                      estimatedSettingsTime: selectedOperation?.estimatedSettingsTime / 60,
                                                      qualityPercent: selectedOperation?.qualityPercent,
                                                    }
                                                  }
                                                }}
                                                refetch={refetch}
                                                setProductType={setProductType}
                                                setJunctionType={setJunctionType}
                                                setFinishJunctionId={setFinishJunctionId}
                                                setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  } else {
                                    return (
                                      <div className="w-full mb-3 relative group" key={index}>
                                        <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                          <p className="text-sm font-medium text-secondary-700">{moments(new Date()).locale("tr").format("LL")}</p>
                                        </div>
                                        <div className="mt-2">
                                          <PlannedCard
                                            data={item}
                                            operation={{ operation: { status: selectedOperation?.status } }}
                                            refetch={refetch}
                                            setIsSelectUserModal={setIsSelectUserModal}
                                            setSelectedJunction={setSelectedJunction}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          )}
                        </Droppable>
                      }
                    />
                    <QualityTab
                      type={"waiting"}
                      length={completedWorks?.length}
                      children={
                        <Droppable droppableId="waiting">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="w-full flex-1 overflow-y-auto flex flex-col scrollbar-hide gap-y-2 h-full"
                            >
                              {Object.keys(groupedByDateCompleted).map((date, index) => {
                                return (
                                  <div key={index}>
                                    <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                      <p className="text-sm font-medium text-secondary-700">{date}</p>
                                    </div>
                                    {groupedByDateCompleted[date].map((item, i) => (
                                      <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`${snapshot.isDragging ? "max-w-[500px]" : ""} w-full mb-3 mt-3`}
                                            key={i}
                                          >
                                            <SelectBoxComp item={item} junctionIds={junctionIds} toggleSelection={toggleSelection} />
                                            <div className="flex flex-col">
                                              <DoneAndQualityWaitingCard
                                                data={item}
                                                operation={{
                                                  operation: {
                                                    internalOperation: {
                                                      estimatedSettingsTime: selectedOperation?.estimatedSettingsTime / 60,
                                                      qualityPercent: selectedOperation?.qualityPercent,
                                                    }
                                                  }
                                                }}
                                                setJunctionId={setJunctionId}
                                                setIsOpenForm={setIsOpenForm}
                                                setProductType={setProductType}
                                                refetch={refetch}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </Droppable>
                      }
                    />
                    <QualityTab
                      type={"finished"}
                      length={completedAndApprovedWorks?.length}
                      children={
                        <Droppable droppableId="finished">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="w-full flex-1 overflow-y-auto flex flex-col scrollbar-hide gap-y-2 h-full"
                            >
                              {Object.keys(groupedByDateCompletedAndApproved)?.map((date, index) => {
                                return (
                                  <div key={index}>
                                    <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                      <p className="text-sm font-medium text-secondary-700">{date}</p>
                                    </div>

                                    <div className="flex flex-col gap-y-2 w-full items-start">
                                     
                                      <div className="flex flex-col w-full">
                                        {groupedByDateCompletedAndApproved[date]
                                          ?.filter((item) => !item?.purchaseRequest)
                                          ?.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                              {(provided, snapshot) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  className={`${snapshot.isDragging ? "max-w-[500px]" : ""
                                                    } w-full mt-3`}
                                                >
                                                  <SelectBoxComp
                                                    item={item}
                                                    junctionIds={junctionIds}
                                                    toggleSelection={toggleSelection}
                                                  />
                                                  <QualityDoneCard
                                                    data={item}
                                                    operation={{
                                                      operation: {
                                                        internalOperation: {
                                                          estimatedSettingsTime: selectedOperation?.estimatedSettingsTime / 60,
                                                          qualityPercent: selectedOperation?.qualityPercent,
                                                        }
                                                      }
                                                    }}
                                                    refetch={refetch}
                                                  />
                                                </div>
                                              )}
                                            </Draggable>
                                          ))}
                                      </div>
                                    </div>

                                  </div>
                                  
                                );
                              })}
                            </div>
                          )}
                        </Droppable>
                      }
                    />
                    {isFetching && (
                      <div className="w-full absolute bottom-0 p-2 px-3 flex items-center bg-white border-b border-secondary-200 flex-1 justify-center">
                        <Loading size={20} primary={"#B54708"} secondary={"#fff"} />
                      </div>
                    )}
                  </div>
                </DragDropContext>
              </ScrollSyncPane>
            )}
          </div>
        </ScrollSyncPane>
      </div>
      {/* // Kontrol edilmis //  */}
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => { setIsOpenJunctionPiece(false); }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            productType={productType}
            type={junctionType}
            closeModal={() => { setIsOpenJunctionPiece(false); }}
          />
        }
      />
      <CustomModal
        isOpen={isOpenForm}
        setIsOpen={setIsOpenForm}
        modalTitle={t("product:qualityControlForm")}
        onClose={() => {
          setIsOpenForm(false);
        }}
        children={
          <QualityControlForm
            onClose={() => { setIsOpenForm(false); }}
            productType={productType}
            junctionId={junctionId}
            refetch={refetch}
          />
        }
      />
      <CustomModal
        isOpen={junctionDetailOpen}
        setIsOpen={setJunctionDetailOpen}
        onClose={() => { setJunctionDetailOpen(false); }}
        modalTitle={t("product:transactionDetail")}
        width="fit-content"
        children={<TransactionDetailModal t={t} setIsOpen={setJunctionDetailOpen} isOpen={junctionDetailOpen} id={finishJunctionId} />}
      />
      <CustomModal
        isOpen={isSelectUserModal}
        setIsOpen={setIsSelectUserModal}
        onClose={() => { setIsSelectUserModal(false); }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectUserModal
            t={t}
            setIsOpen={setIsSelectUserModal}
            isOpen={isSelectUserModal}
            selectedJunction={selectedJunction}
            handleData={refetch}
          />
        }
      />
      <CustomModal
        isOpen={isSelectNodeModal}
        setIsOpen={setIsSelectNodeModal}
        onClose={() => { setIsSelectNodeModal(false); }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectEquModal
            t={t}
            setIsOpen={setIsSelectNodeModal}
            isOpen={isSelectNodeModal}
            selectedJunction={selectedJunction}
            currentOperation={selectedOperation?.processName}
            handleData={refetch}
            processId={selectedOperation?.processId}
          />
        }
      />
      <CustomModal
        isOpen={supplierPurhcaseModal}
        setIsOpen={setSupplierPurhcaseModal}
        modalTitle={t("product:deliverToSupplier")}
        children={<SendToSupplierModal refetch={refetch} setIsOpen={setSupplierPurhcaseModal} junctionData={finishSupplierModalData} />}
      />
      <CustomModal
        isOpen={finishPurhcaseModal}
        setIsOpen={setFinishPurhcaseModal}
        modalTitle={t("product:receiveDelivery")}
        children={<ServiceSupplyReceiveModal refetch={refetch} setIsOpen={setFinishPurhcaseModal} junctionData={finishPurhcaseModalData} />}
      />
      <CustomModal
        isOpen={purchaseQualityForm}
        setIsOpen={setPurchaseQualityForm}
        modalTitle={t("product:qualityControlForm")}
        children={<ServiceSupplyQualityControlForm refetch={refetch} setIsOpen={setPurchaseQualityForm} junctionData={purchaseQualityFormData} />}
      />
      <CustomModal
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        onClose={() => { setIsOpenPlanningModal(false); }}
        modalTitle={t("product:serviceSupplyPlanned")}
        subTitle={t("product:specifyPlannings")}
        width="fit-content"
        children={<ServiceSupplyPlanModal setIsOpen={setIsOpenPlanningModal} refetch={refetch} selectedJunction={isOpenPlanningModalData} groupId={selectedOperation?.processId} />}
      />
    </>
  );
};

export default OperationJunctions;


const JunctionListController = ({
  items,
  onClick,
  setPurchaseQualityFormData,
  selectedCardId,
  setPurchaseQualityForm,
  setFinishPurhcaseModalData,
  setFinishPurhcaseModal,
  setDraggedJunction,
  refetch
}) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] mb-3"
      style={{
        opacity: item?.opacity, // Opaklığı ayarla
      }}
    >

      <ServiceSupplyReceivedEventCard
        data={item}
        setPurchaseQualityForm={setPurchaseQualityForm}
        onClick={() => onClick(item?.serviceSupplierJunction?.plannedJunctionRef?.id, item?.serviceSupplierJunction?.plannedJunctionRef?.ssNo)}
        setPurchaseQualityFormData={setPurchaseQualityFormData}
        selectedCardId={selectedCardId}
        setFinishPurhcaseModalData={setFinishPurhcaseModalData}
        setFinishPurhcaseModal={setFinishPurhcaseModal}
        refetch={refetch}
      />
    </div>
  ));
};

const QualityApliedController = ({
  items,
  onClick,
  setPurchaseQualityFormData,
  selectedCardId,
  setPurchaseQualityForm,
  setDraggedJunction,
  refetch,
}) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="mb-3"
      style={{
        opacity: item?.opacity, // Opaklığı ayarla
      }}
    >
      {!item?.purchaseRequest && (
        <ServiceSupplierQualityApliedEventCard
          data={item}
          setPurchaseQualityForm={setPurchaseQualityForm}
          onClick={() => onClick(item?.serviceSupplierJunction?.plannedJunctionRef?.id, item?.serviceSupplierJunction?.plannedJunctionRef?.ssNo)}
          setPurchaseQualityFormData={setPurchaseQualityFormData}
          selectedCardId={selectedCardId}
          refetch={refetch}
        />
      )}
    </div>
  ));
};

const PlannedJunctionListController = ({
  items,
  onClick,
  selectedCardId,
  setDraggedJunction,
  setIsOpenPlanningModalData,
  setIsOpenPlanningModal,
  refetch
}) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] cursor-pointer mb-3"
      style={{
        opacity: item?.opacity, // Opaklığı ayarla
      }}
    >

      <ServiceSupplySsPlannedEventCard
        data={item}
        selectedCardId={selectedCardId}
        setIsOpenPlanningModalData={setIsOpenPlanningModalData}
        onClick={() => onClick(item?.id, item?.ssNo)}
        setIsOpenPlanningModal={setIsOpenPlanningModal}
        refetch={refetch}
      />
    </div>
  ));
};

const SupplierListController = ({
  items,
  onClick,
  setFinishPurhcaseModal,
  selectedCardId,
  setFinishPurhcaseModalData,
  setFinishSupplierModalData,
  setSupplierPurhcaseModal,
  setDraggedJunction,
  refetch
}) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] cursor-pointer mb-3"
      style={{
        opacity: item?.opacity, // Opaklığı ayarla
      }}
    >
      <ServiceSupplySupplierEventCard
        data={item}
        setFinishPurhcaseModal={setFinishPurhcaseModal}
        setFinishPurhcaseModalData={setFinishPurhcaseModalData}
        onClick={() => onClick(item?.plannedJunctionRef?.id, item?.plannedJunctionRef?.ssNo)}
        selectedCardId={selectedCardId}
        setSupplierPurhcaseModal={setSupplierPurhcaseModal}
        setFinishSupplierModalData={setFinishSupplierModalData}
        refetch={refetch}
      />
    </div>
  ));
};

const SelectBoxComp = ({ item, junctionIds, toggleSelection }) => {
  return (
    <>
      {window.location.href.includes("move-junction") ? (
        <div onClick={() => toggleSelection(item.id)} className="flex w-full mb-1 gap-x-2 items-center cursor-pointer">
          <div
            className={`flex min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] p-[2px] rounded  items-center justify-center ${junctionIds.includes(item.id) ? "bg-[#DC6803]" : "border border-secondary-300"
              }`}
          >
            <CheckIcon />
          </div>
          <span className={` font-medium text-sm ${junctionIds.includes(item.id) ? "text-[#DC6803]" : "text-secondary-500"}`}>
            {junctionIds.includes(item.id) ? "Selected" : "Not Selected"}
          </span>
        </div>
      ) : null}
    </>
  );
};
