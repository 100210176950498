import React, { useEffect, useState } from "react";
import { junctionService } from "../../../services/junction.service";
import { Toast } from "../../../utils/toastify/toast";
import { instance } from "../../../libs/client";
import SearchInput from "../../../components/inputs/SearchInput";
import { authStore } from "../../../stores/auth.store";
import generateFileUrl from "../../../utils/generateFileUrl";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { DangerIcon } from "../../../assets/icons/machineIcons";
import { Loading } from "../../../components";
import diacritics from "diacritics";
import { useTranslation } from "react-i18next";

const NewPlannedJunctionExternalModal = ({
  processId,
  closeModal,
  operationData,
  getJunctions,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEquipment, setselectedEquipment] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await instance.get(
          `external-operation/list/supplier/${processId}`
        );
        setData(response?.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (processId) {
      fetchData();
    }
  }, [processId]);

  const filteredData = data?.filter((d) =>
    diacritics
      .remove(`${d?.name}`)
      .toLowerCase()
      .includes(diacritics.remove(searchValue).toLowerCase())
  );

  const startNewPlannedJunction = async () => {
    const data = {
      headerId: operationData?.product?.id,
      companyId: authStore.user.company.id,
      process: {
        id: processId,
      },
      supplier: { id: selectedEquipment.id },
      type: "external",
      orderId: operationData?.orderId,
    };
    await junctionService.equipmentBasedJunctionPlanning(data).then((res) => {
      if (res?.data?.code == 0) {
        Toast("success", res?.data?.message);
        setIsAlert(false);
        closeModal();
        getJunctions();
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  return (
    <>
      <div className="w-[350px] flex flex-col max-h-[70vh] gap-4 pt-4">
        <SearchInput setSearchVal={setSearchValue} />
        {isLoading ? (
          <div className="flex flex-col max-h-[330px] min-h-[330px] items-center justify-center">
            <Loading primary={"#B54708"} secondary={"#fff"} size={22} />
          </div>
        ) : (
          <div className="flex flex-col max-h-[330px] min-h-[330px] overflow-y-auto scrollbar-hide gap-y-2 border-b">
            {filteredData?.map((d, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setselectedEquipment(d);
                    setIsAlert(true);
                  }}
                  className="border px-3 py-[10px] rounded-lg shadow-xs border-[#D0D5DD] flex gap-x-3 hover:bg-[#F9FAFB] cursor-pointer"
                >
                  {d?.image ? (
                    <img
                      className="min-w-[60px] max-w-[60px] min-h-[45px] max-h-[45px] rounded border border-[#0000001A]"
                      src={generateFileUrl(d?.image)}
                    />
                  ) : (
                    <div
                      className={
                        "min-w-[60px] min-h-[45px] max-w-[60px] max-h-[45px] rounded bg-[#DC6803] flex border-4 border-[#DC6803] items-center justify-center font-medium text-white text-xl"
                      }
                    >
                      {d?.name?.charAt(0).toUpperCase()}
                    </div>
                  )}
                  <div className="flex w-[70%] items-center">
                    <span className="text-sm font-semibold text-[#344054] truncate">
                      {d?.name}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <AlertModal
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        title={selectedEquipment?.name}
        subTitle={t("product:plannedwork")}
        label={"Create"}
        applyButton={startNewPlannedJunction}
        icon={<DangerIcon />}
      />
    </>
  );
};

export default NewPlannedJunctionExternalModal;
