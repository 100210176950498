import React, { useEffect, useState } from "react";
import generateFileUrl from "../../../../utils/generateFileUrl";
import { Button, CustomModal } from "../../../../components";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "../../../../assets/icons/machineIcons";
import CloseButton from "../../../../components/buttons/CloseButton";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import { InternalOperationIcon } from "../../../../assets/icons/stepHeaderIcons";
import { AddProcessModal } from "../../../../steps/process";

import { useTranslation } from "react-i18next";
import { authStore } from "../../../../stores/auth.store";
import { ArrowCircleBrokenRight } from "../../../../assets/icons/productIcons";
import MoveJunctionsModal from "../../../../components/modal/MoveJunctionsModal";

const MoveJunctionsHeader = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className="flex w-full min-h-[58px] max-h-[58px] justify-between items-center border-b">
        <div className="flex items-center gap-x-2">
          <Button
            size={"md"}
            iconLeft={<ArrowLeftIcon />}
            colorType={"tertiary-gray"}
            onClick={() => navigate(-1)}
          />
          <div className="text-[#101828] text-2xl font-semibold">
            {"Move Junctions"}
          </div>
        </div>
        <div className="flex gap-x-3 items-center">
          <Button
            colorType={"primary-product"}
            iconLeft={<ArrowCircleBrokenRight />}
            size={"md"}
            label={"Move Selected Ones"}
            onClick={() => setIsOpen(true)}
          />
          <Button
            colorType={"secondary-gray"}
            size={"md"}
            label={"Cancel"}
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
    </>
  );
};

export default MoveJunctionsHeader;
