import { instance } from "../libs/client";

class RawMaterialService {
  async createRawMaterial(data) {
    return await instance.post("raw-material/create", data);
  }

  async getRawMaterials() {
    const { data } = await instance.get("raw-material/list");
    return data;
  }

  async getPurchaseList(id) {
    const { data } = await instance.get(`raw-material/material/${id}`);
    return data;
  }

  async setManualRawMaterialEdit(type, id, data) {
    return await instance.post(`raw-material/set/manual/${type}/${id}`, data);
  }

  async supplierList() {
    const { data } = await instance.get("purchase");
    return data;
  }

  async deleteRawMaterial(id) {
    return await instance.delete(`raw-material/${id}`);
  }

  async supplierGroup() {
    const { data } = await instance.get("purchase/supplier-group");
    return data;
  }

  async addSupplier(model) {
    const { data } = await instance.post("purchase", model);
    return data;
  }

  async updateSuplier(id, data) {
    return await instance.put(`purchase/${id}`, data);
  }

  async updateRawMaterial(id, data) {
    return await instance.put(`raw-material/${id}`, data);
  }

  // paginatedd
  async getRawMaterialList(pageNo, search = "", sort = "", order = "", take = "", filter = []) {
    const { data } = await instance.get(
      `raw-material/list/paginated/?page=${pageNo}${search ? `&search=${search}` : ""}${sort ? `&sort=${sort}` : ""} ${take ? `&take=${take}` : 20}${
        order ? `&order=${order}` : ""
      }${filter ? `&filter=${filter}` : []} `
    );
    return data;
  }

  async getSupplierInfo(id) {
    const { data } = await instance.get(`purchase/supplier/info/${id}`);
    return data;
  }

  async getSupplierPastData(id, type) {
    const { data } = await instance.get(`purchase/supplier/past/${id}/${type}`);
    // const { data } = await instance.get(`purchase/supplier/past/3554c05f-9135-4932-8aa7-3e74b95816b8`);
    return data;
  }
}
const rawMaterialService = new RawMaterialService();
export { rawMaterialService };
