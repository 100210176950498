import React from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button } from "../../../components";
import {
  CoinsStackedIcon,
  MeasuringIcon,
  MinusIcon,
  QuantityIcons,
} from "../../../assets/icons/productIcons";

function AddAssemblyCard({
  image,
  item,
  unit,
  unitPrice,
  handleRemoveProduct,
}) {
  return (
    <div className="flex w-full min-h-[42px] gap-x-3">
      {!item?.image ? (
        <span className="min-w-[56px] max-w-[56px] min-h-[42px] max-h-[42px] rounded-sm border-[#0000001A] bg-black"></span>
      ) : (
        <img
          className="min-w-[56px] max-w-[56px] min-h-[42px] max-h-[42px] rounded-sm border-[#0000001A]"
          src={generateFileUrl(item?.image)}
          width={64}
          height={48}
          alt={item?.name?.charAt(0).toUpperCase()}
        />
      )}
      <div className="flex flex-col">
        <span className="text-[#101828] font-medium text-sm">
          {item?.name || "08 Driving Shaft"}
        </span>
        <div className="flex">
          <div className="flex gap-x-1 items-center pr-[6px] py-[2px]">
            <MeasuringIcon />
            <span className="flex gap-x-[2px] items-center">
              <span className="text-[#475467] font-medium text-xs">
                {item?.unitValue || 0}
              </span>
              <span className="text-[#667085] font-normal text-[10px] leading-4">
                birim
              </span>
            </span>
          </div>
          <div className="flex gap-x-1 items-center pr-[6px] py-[2px]">
            <CoinsStackedIcon />
            <span className="flex gap-x-[2px] items-center">
              <span className="text-[#475467] font-medium text-xs">
                ₺{unitPrice || 0}
              </span>
              <span className="text-[#667085] font-normal text-[10px] leading-4">
                birim fiyat
              </span>
            </span>
          </div>
        </div>
      </div>
      <span className="flex ml-auto">
        <Button
          colorType={"secondary-error"}
          size={"sm"}
          onClick={() => {
            handleRemoveProduct(item?.id);
          }}
          iconLeft={<MinusIcon />}
        />
      </span>
    </div>
  );
}

export default AddAssemblyCard;
