import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { authService } from "../../services";
import { KeyIcons } from "../../assets/icons/machineIcons";
import { EyeIcon } from "../../assets/icons/PersonIcons";
import { Input } from "../../components";
import { Toast } from "../../utils/toastify/toast";
import { DetailBackGround, UpuLogoNew } from "../../assets/illustrations";
import { StepsIllustrator } from "../../assets/images";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
const NewPassword = () => {
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null);
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [passError, setPassError] = useState(false);
  const [passRepeatError, setPassRepeatError] = useState(false);
  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      const decodedToken= jwtDecode(token)
      const currentTime = Date.now() / 1000
      if(decodedToken?.exp < currentTime) {
        Toast("error", "Token süreniz dolmuştur.")
        setTimeout(() => {
          window.location.href = "/auth/login";
        }, 1750);
      }
      setToken(token);
    }
  }, [searchParams]);
  const onSubmit = async () => {
    if (
      !passError &&
      !passRepeatError &&
      password != "" &&
      passwordRepeat != ""
    ) {
      await authService
        .addPasswordToAccount({
          token,
          passwordRepeat,
          password,
        })
        .then((res) => {
          if (res.data.code == 0) {
            Toast("success", "Başarıyla yeni şifre eklediniz.");
            setTimeout(() => {
              navigate("/auth/login");
            }, 3000);
          } else {
            Toast("error", "Şifreler uyuşmuyor!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (password == "") {
      setPassError(false);
    }
    if (passwordRepeat == "") {
      setPassRepeatError(false);
    }
    if (password != "") {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/.test(
          password
        );
      setPassError(!regex);
    }
    if (password != passwordRepeat && passwordRepeat != "") {
      setPassRepeatError(true);
    }
    if (password == passwordRepeat) {
      setPassRepeatError(false);
    }
  }, [password, passwordRepeat]);
  return (
    <div style={{ width, height }} className="flex flex-col">
      <div className="w-full h-[80px] min-h-[80px] border-b flex items-center px-20 justify-between">
        <UpuLogoNew />
        <select className="w-24 flex items-center justify-center border-transparent border-0 focus:border-transparent focus:ring-0 font-semibold text-gray-600">
          <option>{t("password:english")}</option>
          <option disabled>{t("password:turkish")}</option>
        </select>
      </div>
      <div className="flex-1 flex items-center justify-center relative xs:mt-48 mb-20">
        <div className="absolute -z-50">
          <DetailBackGround />
        </div>
        <div className="w-full h-full flex items-center justify-center relative xs:justify-start xs:flex-col">
          <div className="w-[360px] flex flex-col relative z-20 items-center">
            <div className="w-14 h-14 rounded-xl border shadow bg-white flex items-center justify-center">
              <KeyIcons />
            </div>
            <h1 className="text-[30px] font-semibold text-gray-900 mt-4">
              {t("password:createNewPassword")}
            </h1>
            <p className="text-gray-600 mt-3 text-sm">
              {t("password:passwordConditions")}
            </p>
            <div className="mt-7 w-full">
              <Input
                validate={passError ? "error" : ""}
                type={"password"}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t("password:newPassword")}
                errorMessage={t("password:passwordNotValid")}
                icon={<EyeIcon currentColor={"#98A2B3"} />}
                iconPosition={"right"}
              />
            </div>
            <div className="my-5 w-full">
              <Input
                validate={passRepeatError ? "error" : ""}
                type={"password"}
                onChange={(e) => setPasswordRepeat(e.target.value)}
                placeholder={t("password:newPasswordRepeat")}
                errorMessage={t("password:passwordNotMatch")}
                icon={<EyeIcon currentColor={"#98A2B3"} />}
                iconPosition={"right"}
              />
            </div>
            <button
              onClick={(e) => {
                onSubmit();
              }}
              className="w-full h-10 rounded-lg flex items-center justify-center bg-primary-600"
            >
              <p className="font-semibold text-white">
                {t("password:continue")}
              </p>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full max-h-[250px] flex items-center justify-center xs:flex  z-10 ">
        <img
          src={StepsIllustrator}
          alt="StepsIllustrator"
          className={`object-cover dark:max-w-[90%]  bottom-0 absolute mx-auto  -z-30 dark:z-30  dark:opacity-40 `}
        />
      </div>
    </div>
  );
};
export default NewPassword;
