import React from "react";
import { EventsIcon, NewAcceptedIcon, NewDotsIcon, NewRejectedIcon, PlannedIcon, ReadyIcon, TargetIcon } from "../../../components/new-cards/card-icons";
import { useNavigate } from "react-router-dom";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { useTranslation } from "react-i18next";
import { QuantityAcceptedIcon } from "../../../assets/icons/productIcons";
import { Tooltip } from "react-tooltip";

const StockDetailOperationCard = ({ item, status, occurrences, orderData, showOperationDetail = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
      default:
        return "DÖN";
    }
  };
  const statusColor = () => {
    if (item?.status?.includes("finished")) {
      return "#ECFDF3";
    } else if (item?.status?.includes("started")) {
      return "#FFFAEB";
    } else if (item?.status?.includes("rejected")) {
      return "#D92D20";
    } else {
      return "#F2F4F7";
    }
  };
  const operationMap = item?.operations?.map((a) => {
    if (a?.internalOperation) {
      return a?.internalOperation?.activePiece
        ? a?.internalOperation?.activePiece
        : 0;
    } else {
      return a?.externalOperation?.activePiece
        ? a?.externalOperation?.activePiece
        : 0;
    }
  });
  const orderDataItem = item?.operations?.reduce(
    (acc, b) => acc || b?.orderData,
    null
  );
  let times =
    item?.times?.find((a) => a?.avgEstimatedTime > 0) || item?.times[0];

  return (
    <>
      <div
        className={`flex w-[312px] h-[112px] flex-row border items-start cursor-pointer rounded-lg border-secondary-300  bg-white relative group `}
      >
        {/* <div style={{ backgroundColor: statusColor() }} className="flex w-full min-h-[4px] max-h-[4px] h-1 rounded-t-[8px]"></div> */}
        <div className="flex flex-col w-[93%]">
          {item?.name?.length == 1 ? (
            <>
              {item?.name?.map((b) => {
                return (
                  <div className="flex flex-row items-center w-[100%]  justify-between pr-1 pt-2 pl-3">
                    <div className="flex flex-row items-center gap-x-0.5 h-5 ">
                      {b?.processType == "external" ? (
                        <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                          <p className="text-xs font-semibold text-secondary-700">
                            {"HİZ"}
                          </p>
                        </div>
                      ) : (
                        <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                          <p className="text-xs font-semibold text-secondary-700">
                            {item?.processType
                              ? operationTypes(b?.processType)
                              : "DÖN"}
                          </p>
                        </div>
                      )}
                      <div className=" bg-white max-w-[230px] ml-1 rounded-r-[8px]">
                        <p className="font-semibold text-sm text-secondary-700  truncate">
                          {b?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="flex flex-row items-center gap-x-2 pr-1 pt-1.5 pl-2 w-[270px] min-w-[270px] max-w-[270px] ">
              {item?.name?.map((a, index) => {
                return (
                  <div
                    className={`flex border items-center ${item?.name?.length == 2 ? "w-1/2 " : "w-1/3"
                      }  h-6 gap-x-1 flex-row rounded`}
                  >
                    <div className="px-1 bg-[#F2F4F7] w-fit border-r h-full flex items-center">
                      {a.processType === "external" ? (
                        <p className="text-xs font-semibold text-secondary-700">
                          {"HİZ"}
                        </p>
                      ) : (
                        <p className="text-xs font-semibold text-secondary-700">
                          {a?.processType
                            ? operationTypes(a?.processType)
                            : "--"}
                        </p>
                      )}
                    </div>
                    <div className="bg-white truncate px-1">
                      <p className="font-semibold text-xs text-secondary-700  truncate">
                        {a?.name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="flex flex-row items-center h-[25px] justify-between w-full  border-t border-b border-t-[#E4E7EC] bg-[#F9FAFB] mt-1">
            <div className="w-[33%] pl-3 pr-1 border-r h-full flex items-center">
              <p className="text-xxs font-medium text-secondary-700">
                {times?.type === "external"
                  ? t("product:cycleTimes")
                  : t("product:cycleTime")}
              </p>
            </div>

            <div className="flex flex-row pl-2 pr-1 items-center w-[33%]">
              <TargetIcon />
              <p className="text-xs font-medium ml-1 text-secondary-700">
                {times?.type == "internal"
                  ? legacyFormatSeconds(times?.estimatedTime)
                  : `${times?.estimatedTime}`}
              </p>
              <p className="text-xxs text-secondary-600 ml-0.5">
                {t("product:target")}
              </p>
            </div>
            <div className="flex flex-row items-center w-[33%]">
              <PlayIcon
                color={`${(
                    times?.type == "internal"
                      ? times?.estimatedTime < times?.avgEstimatedTime
                      : times?.estimatedTime * 86400 < times?.avgEstimatedTime
                  )
                    ? "#F04438"
                    : "#079455"
                  }`}
              />
              <p
                className={`text-xs font-medium ml-1 ${(
                    times?.type == "internal"
                      ? times?.estimatedTime < times?.avgEstimatedTime
                      : times?.estimatedTime * 86400 < times?.avgEstimatedTime
                  )
                    ? "text-[#F04438]"
                    : "text-[#079455] "
                  }`}
              >
                {times?.avgEstimatedTime
                  ? times?.type == "internal"
                    ? legacyFormatSeconds(times?.avgEstimatedTime?.toFixed(0))
                    : `${Math.ceil(times?.avgEstimatedTime / 60 / 60 / 24)} `
                  : "--"}
              </p>
              <p
                className={`text-xxs ${(
                    times?.type == "internal"
                      ? times?.estimatedTime < times?.avgEstimatedTime
                      : times?.estimatedTime * 86400 < times?.avgEstimatedTime
                  )
                    ? "text-[#F04438]"
                    : "text-[#079455] "
                  }  ml-0.5`}
              >
                {t("product:applieds")}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2 w-[100%] pl-3 pr-3 mt-[5px]">
            <div className="flex flex-col h-[42px] w-1/3  border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">
                  {t("product:inStock")}
                </p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-0.5 py-[2px] justify-center">
                <p className="text-xs truncate font-medium text-secondary-700">
                  {item?.finishedPiece || "0"}
                </p>
              </div>
            </div>
            <div className="flex flex-col h-[42px]  w-1/3 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">
                  {t("product:fromSupply")}
                </p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-0.5 py-[2px] justify-center">
                <p className="text-xs truncate font-medium text-secondary-700">
                  {operationMap?.reduce((a, b) => a + b, 0) || "0"}
                </p>
              </div>
            </div>
            <div className="flex flex-col h-[42px]  w-1/3   border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">
                  {t("product:fromWorkOrder")}
                </p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-0.5 py-[2px] justify-center">
                <p className="text-xs truncate font-medium text-secondary-700">
                  {operationMap?.reduce((a, b) => a + b, 0) || "0"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: statusColor() }}
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0  border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          <p
            className={`text-xxs font-semibold whitespace-nowrap ${item?.status?.includes("started")
                ? "text-[#CA8504]"
                : item?.status?.includes("finished")
                  ? "text-[#079455]"
                  : item?.status?.includes("rejected")
                    ? "text-white"
                    : "#475467"
              } -rotate-90`}
          >
            {t("product:semiProduct")}
          </p>
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
        <Tooltip className="z-50" id={`${item?.id}`} />
      </div>
      {/* <div
        // onClick={() => {
        //   showOperationDetail && navigate(`/app/product/mrp/work-in-process/detail/${orderData?.id}+${item?.step}`);
        // }}
        className={`flex w-[318px] h-[112px] flex-col border items-start cursor-pointer rounded-lg border-secondary-300 bg-white relative group `}
      >
      {status && <div style={{ backgroundColor: statusColor() }} className="flex w-full min-h-[4px] max-h-[4px] h-1 rounded-t-[8px]"></div>}
        <div className="flex flex-col w-[92.5%]">
          <div className="flex flex-row items-center w-[100%]  justify-between pr-1 py-2 pl-3">
            <div className="flex flex-row items-center  h-5 ">
              <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                {item?.type === "external" ? (
                  <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                ) : (
                  <p className="text-xs font-semibold text-secondary-700">
                    {item?.processType || item?.process?.type ? operationTypes(item?.processType || item?.process?.type) : "--"}
                  </p>
                )}
              </div>
              <div className="px-1 bg-white max-w-[230px] rounded-r-[8px]">
                <p className="font-semibold text-sm ml-1 text-secondary-700 truncate">
                  {occurrences > 1 ? `${item?.process?.name} ${occurrences}` : item?.process?.name || "Product Name"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center w-full h-full">
            <div className="flex flex-col pr-2 pl-3 w-[37%] min-w-[37%] h-full">
              <div className="flex flex-row items-center w-full justify-between  border-b pb-0.5">
                <p className="font-normal text-secondary-600 ml-1 text-xxs">{t("product:inStocks")}</p>
                <p className="text-xs font-medium max-w-[50px]  truncate text-secondary-700">{item?.activePiece || "0"}</p>
              </div>
              <div className="flex flex-row items-center w-full justify-between border-b pb-0.5  pt-[4px] ">
                <p className="font-normal ml-1 text-xxs text-[#D92D20]">{t("product:reject")}</p>
                <p className="text-xs font-medium max-w-[40px] truncate text-[#D92D20]">{item?.rejectedPiece || 0}</p>
              </div>
              <div className="flex flex-row items-center w-full pb-0.5 pt-[4px] justify-between">
                <p className="font-normal text-secondary-600 ml-1 text-xxs">{t("product:cost")}</p>
                <p className="text-xs font-medium text-secondary-700">{item?.currentPurchasePrice || "0"} {item?.currency} </p>
              </div>
            </div>
            <div className="flex flex-row items-center w-[63%] min-w-[63%] rounded-tl-[4px] border border-r-0 border-b-0 border-[#E4E7EC]">
              <div className="flex flex-col items-start border-r w-1/2 border-r-[#E4E7EC]">
                <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                  <p className="text-xs font-medium text-secondary-700">{t("product:cycleTime")}</p>
                </div>
                <div className="flex flex-col px-[6px] w-full">
                  <div className="flex px-1.5 py-[3px] flex-row items-center w-full justify-between border-b border-[#E4E7EC]">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                    <p className="font-medium text-secondary-600 text-xs">{legacyFormatSeconds(item?.estimatedTime) || "--"}</p>
                  </div>
                  <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full justify-between">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                    <p
                      className={`font-medium ${item?.estimatedTime < item?.avgActualTime ? "text-[#F04438]" : "text-[#079455] "
                        } text-xs`}
                    >
                      {legacyFormatSeconds(item?.avgActualTime) || "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center  w-1/2">
                <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                  <p className="text-xs font-medium text-secondary-700">{t("product:setupTime")}</p>
                </div>
                <div className="flex flex-col px-[6px] w-full">
                  <div className="flex px-1.5 py-[3px] flex-row items-center  gap-x-0.5 border-b border-[#E4E7EC]">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                    <p className="font-medium text-secondary-600 ml-auto text-xs">{legacyFormatSeconds(item?.estimatedSettingsTime) || "--"}</p>
                  </div>
                  <div className="flex px-1.5 py-[1.5px] flex-row items-center gap-x-0.5">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                    <p
                      className={`font-medium ml-auto ${item?.estimatedSettingsTime < item?.avgActualTime ? "text-[#F04438]" : "text-[#079455] "
                        } text-xs`}
                    >
                      {legacyFormatSeconds(item?.avgActualTime) || "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: statusColor() }}
            className={`absolute right-0 bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
          >
            <p
              className={`text-xxs font-semibold ${item?.status === "started"
                  ? "text-[#CA8504]"
                  : item?.status === "finished"
                    ? "text-[#079455]"
                    : item?.status === "rejected"
                      ? "text-white"
                      : "text-[#475467]"
                } -rotate-90 whitespace-nowrap`}
            >
              {t("product:semiProduct")}
            </p>
          </div>
          <div
           
            className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
          >
            <NewDotsIcon />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default StockDetailOperationCard;

const SubtractIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.9 0C1.57452 0 0.5 1.07452 0.5 2.4V9.6C0.5 10.9255 1.57452 12 2.9 12H10.1C11.4255 12 12.5 10.9255 12.5 9.6V2.4C12.5 1.07452 11.4255 0 10.1 0H2.9ZM4.1832 3.15663C4.1832 2.76272 4.1832 2.56576 4.26534 2.45719C4.33689 2.3626 4.44625 2.30407 4.56464 2.297C4.70054 2.28889 4.86442 2.39814 5.19218 2.61665L9.45744 5.46015C9.72826 5.6407 9.86367 5.73098 9.91086 5.84476C9.95211 5.94424 9.95211 6.05604 9.91086 6.15552C9.86367 6.2693 9.72826 6.35958 9.45744 6.54012L9.45744 6.54013L5.19218 9.38363L5.19217 9.38364C4.86441 9.60214 4.70054 9.71139 4.56464 9.70328C4.44625 9.69621 4.33689 9.63768 4.26534 9.54309C4.1832 9.43452 4.1832 9.23756 4.1832 8.84365V3.15663ZM5.41016 4.7521C5.41016 4.5792 5.41016 4.49275 5.44621 4.4451C5.47761 4.40359 5.52561 4.3779 5.57758 4.37479C5.63722 4.37123 5.70915 4.41919 5.85301 4.51509L7.72509 5.76315L7.7251 5.76315C7.84396 5.84239 7.90339 5.88201 7.9241 5.93196C7.94221 5.97562 7.94221 6.02469 7.9241 6.06835C7.90339 6.11829 7.84396 6.15791 7.7251 6.23716L7.72509 6.23716L5.85301 7.48522C5.70915 7.58112 5.63722 7.62907 5.57758 7.62551C5.52561 7.62241 5.47761 7.59672 5.44621 7.55521C5.41016 7.50755 5.41016 7.4211 5.41016 7.24821V4.7521Z"
        fill="#17B26A"
      />
    </svg>
  );
};


export const PlayIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M2.91699 2.91065C2.91699 2.34414 2.91699 2.06088 3.03511 1.90474C3.13801 1.76871 3.2953 1.68454 3.46556 1.67437C3.661 1.6627 3.89668 1.81983 4.36805 2.13407L10.5021 6.22345C10.8916 6.48311 11.0863 6.61294 11.1542 6.77658C11.2135 6.91964 11.2135 7.08043 11.1542 7.22349C11.0863 7.38713 10.8916 7.51696 10.5021 7.77661L4.36805 11.866C3.89668 12.1802 3.661 12.3374 3.46556 12.3257C3.2953 12.3155 3.13801 12.2314 3.03511 12.0953C2.91699 11.9392 2.91699 11.6559 2.91699 11.0894V2.91065Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
