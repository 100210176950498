import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FilterIcon } from '../../../assets/icons/commonIcons';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '../../../components';
import { ChevronDown } from '../../../assets/icons/PersonIcons';
import { CheckIcon } from '../../../assets/icons/stepsIcons';
import UnitPriceInput from '../../../components/inputs/UnitPriceInput';
import { useQuery } from '@tanstack/react-query';
import { rawMaterialService } from '../../../services/raw-material.service';
import { CloseIconSort } from '../../../assets/icons/equipmentIcon';

const RawMaterialListFilter = ({ setFilterOptions, refetch, filterOptions }) => {
    const menuRef = useRef();
    const { t } = useTranslation()
    const [expandedSupplier, setExpandedSupplier] = useState(true);
    const [expandedUnit, setExpandedUnit] = useState(true);
    const [expandedPrice, setExpandedPrice] = useState(true);

    const [selectedUnits, setSelectedUnits] = useState([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [isSortDropdown, setIsSortDropdown] = useState(false);
    const [priceLeast, setPriceLeast] = useState("");
    const [currencyLeast, setCurrencyLeast] = useState("TRY");
    const [priceMost, setPriceMost] = useState("");
    const [priceLeastError, setPriceLeastError] = useState(false);
    const [priceMostError, setPriceMostError] = useState(false);

    const { data, isLoading } = useQuery({
        queryKey: ["supplierGroupRawMaterialList"],
        queryFn: async () => {
            return await rawMaterialService.supplierGroup();
        },
        retry: 0,
    });

    const handleClickOutside = useCallback((event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsSortDropdown(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);


    const units = [
        { label: "Kilogram", shortLabel: "kg", value: "kg" },
        { label: t("product:metricTon"), shortLabel: "ton", value: "ton" },
        { label: "Gram", shortLabel: "gr", value: "gr" },
        { label: "Miligram", shortLabel: "mg", value: "mg" },
        { label: "Microgram", shortLabel: "mcg", value: "mcg" },
        { label: "Pound", shortLabel: "lb", value: "lb" },
        { label: t("product:ounce"), shortLabel: "oz", value: "oz" },
        { label: "Metre", shortLabel: "m", value: "m" },
        { label: "Millilitre", shortLabel: "mm", value: "mm" },
        { label: t("product:gallon"), shortLabel: "gal", value: "gal" },
        { label: t("product:squareMetre"), shortLabel: "m2", value: "m2" },
        { label: t("product:squareMilimetre"), shortLabel: "mm2", value: "mm2" },
        { label: t("product:squareInch"), shortLabel: "in2", value: "mm2" },
        { label: t("product:pieceModal"), shortLabel: "ad", value: "ad" },
        { label: t("product:package"), shortLabel: "pkg", value: "pkg" },
        { label: t("product:box"), shortLabel: "box", value: "box" },
    ];

    const handleUnitChange = (unit) => {
        setSelectedUnits((prevSelectedUnits) =>
            prevSelectedUnits.includes(unit?.value)
                ? prevSelectedUnits.filter((item) => item !== unit?.value)
                : [...prevSelectedUnits, unit?.value]
        );
    };
    const handleSupplierChange = (supplier) => {
        setSelectedSuppliers((prevSelectedSuppliers) => {
            const updatedSuppliers = prevSelectedSuppliers.includes(supplier?.id)
                ? prevSelectedSuppliers.filter((item) => item !== supplier?.id)
                : [...prevSelectedSuppliers, supplier?.id];
            return updatedSuppliers;
        });
    };

    const applyFilter = useCallback(() => {
        let hasError = false;

        // Eğer fiyat alanları dolu değilse hata göster
        if (priceLeast !== "" || priceMost !== "") { // Fiyatlarla ilgili bir işlem yapıldığında
            if (priceLeast === "") {
                setPriceLeastError(true);
                hasError = true;
            } else {
                setPriceLeastError(false);
            }

            if (priceMost === "") {
                setPriceMostError(true);
                hasError = true;
            } else {
                setPriceMostError(false);
            }
        }

        // Eğer hata yoksa filtreleri uygula
        if (!hasError) {
            const filters = {
                unitType: selectedUnits.length > 0 ? selectedUnits : [],
                supplierGroup: selectedSuppliers.length > 0 ? selectedSuppliers : [],
                currentPrice:
                    priceLeast !== "" && priceMost !== "" // Sadece fiyatlar dolu olduğunda filtreye ekle
                        ? [{ min: priceLeast, max: priceMost }]
                        : []
            };
            setFilterOptions(filters);
            setIsSortDropdown(false);
            // refetch()
        }
    }, [selectedUnits, selectedSuppliers, currencyLeast, priceLeast, priceMost, setFilterOptions]);

    const handleSortCancel = (e) => {
        e?.stopPropagation();
        setSelectedSuppliers([]);
        setSelectedUnits([]);
        setPriceLeast("");
        setPriceMost("");
        setIsSortDropdown(false);
        setFilterOptions({})
        refetch();
    };

    return (
        <div ref={menuRef} className='w-full h-full'>
            {Object.keys(filterOptions).length > 0 ? (
                <div
                    onClick={() => {
                        setIsSortDropdown(true && !isSortDropdown);
                        setFilterOptions({});
                    } }
                    className={`flex border hover:bg-secondary-50 border-secondary-300 px-[14px] py-[10px] rounded-lg gap-x-1 justify-center items-center cursor-pointer ${isSortDropdown ? "shadow-ring-gray" : ""
                        }`}
                >
                    <span className="min-w-[20px] min-h-[20px]">
                        <FilterIcon />
                    </span>
                    <span className="text-sm font-semibold text-secondary-700 whitespace-nowrap">
                      {t("product:clearFilters")}
                    </span>
                    <span
                        onClick={handleSortCancel}
                        className="p-[5px] min-w-[20px] min-h-[20px] hover:bg-secondary-100 rounded"
                    >
                        <CloseIconSort />
                    </span>
                </div>
            ) : (
                <div
                    onClick={() => setIsSortDropdown(true && !isSortDropdown)}
                    className={`flex border hover:bg-secondary-50 border-secondary-300 px-[14px] py-[10px] rounded-lg gap-x-1 justify-center items-center cursor-pointer ${isSortDropdown ? "shadow-ring-gray" : ""
                        }`}
                >
                    <span className="min-w-[20px] min-h-[20px]">
                        <FilterIcon />
                    </span>
                    <span className="text-sm font-semibold text-secondary-700 whitespace-nowrap">
                        {t("product:filters")}
                    </span>
                </div>
            )}

            {isSortDropdown === true && (
                <div className="z-[50] absolute top-[76px] right-[265px] bg-white border  border-secondary-200 shadow-lg rounded-lg px-4 py-3 flex flex-col items-start w-[384px]">
                    {/* <div className="w-full flex justify-end mb-2">
                        <Button onClick={handleSortCancel} label={"Filtreleri Temizle"} colorType={"fill-product"} size={"sm"} />
                    </div> */}

                    <div className='w-full flex flex-row items-center justify-between border-b pb-1'>
                        <p className='text-sm font-medium text-secondary-700'>{t("product:unit")}</p>
                        <div onClick={() => setExpandedUnit(!expandedUnit)} className={`w-9 ${expandedUnit && "rotate-180"}`}>
                            <Button colorType={"tertiary-gray"} size={"sm"} iconLeft={<ChevronDown />} />
                        </div>
                    </div>
                    {expandedUnit && (
                        <div className='h-[180px] w-full flex flex-col overflow-y-scroll scrollbar-hide'>
                            {units?.map((unit, index) => (
                                <div
                                    key={index}
                                    className={`flex items-center cursor-pointer  p-2 text-secondary-600 font-normal bg-white`}
                                    onClick={() => handleUnitChange(unit)}
                                >
                                    <span className={`w-5 h-5 border rounded-md  flex justify-center  items-center mr-2 ${selectedUnits.includes(unit.value) ? 'bg-[#DC6803] border-[#DC6803]' : 'border-[#D0D5DD]'}`}>
                                        {selectedUnits.includes(unit?.value) && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4 w-4 text-white"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                />
                                            </svg>
                                        )}
                                    </span>
                                    <span className="text-sm font-normal text-secondary-600"> {`${unit?.label} (${unit?.shortLabel})`}</span>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className='w-full flex flex-row items-center justify-between border-b pb-1'>
                        <p className='text-sm font-medium text-secondary-700'>{t("product:supplierGroups")}</p>
                        <div onClick={() => setExpandedSupplier(!expandedSupplier)} className={`w-9 ${expandedSupplier && "rotate-180"}`}>
                            <Button colorType={"tertiary-gray"} size={"sm"} iconLeft={<ChevronDown />} />
                        </div>
                    </div>
                    {expandedSupplier && (
                        <div className='h-[180px] w-full flex flex-col overflow-y-scroll scrollbar-hide'>
                            {data?.map((supplier, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`flex items-center cursor-pointer p-2 text-secondary-600 font-normal bg-white`}
                                        onClick={() => handleSupplierChange(supplier)}
                                    >
                                        <span className={`w-5 h-5 border rounded-md  flex justify-center  items-center mr-2 ${selectedSuppliers.includes(supplier?.id) ? 'bg-[#DC6803] border-[#DC6803]' : 'border-[#D0D5DD]'}`}
                                        >
                                            {selectedSuppliers.includes(supplier?.id) && (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-4 w-4 text-white"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M5 13l4 4L19 7"
                                                    />
                                                </svg>
                                            )}
                                        </span>
                                        <span className="text-sm font-normal text-secondary-600">{supplier?.name}</span>
                                    </div>
                                )

                            })}
                        </div>
                    )}

                    <div className='w-full flex flex-row items-center justify-between border-b pb-1'>
                        <p className='text-sm font-medium text-secondary-700'>{t("product:priceRange")}</p>
                        <div onClick={() => setExpandedPrice(!expandedPrice)} className={`w-9 ${expandedPrice && "rotate-180"}`}>
                            <Button colorType={"tertiary-gray"} size={"sm"} iconLeft={<ChevronDown />} />
                        </div>
                    </div>
                    {expandedPrice && (
                        <div className='flex flex-col w-[97%]'>
                            <div className='w-[100%] mt-3 ml-3'>
                                {/* <UnitPriceInput
                                    theme={"product"}
                                    items={priceOptions}
                                    label={"En Az"}
                                    setValue={(field, value) => {
                                        field === "price" ? setPriceLeast(value) : setCurrencyLeast(value);
                                    }}
                                    price={priceLeast}
                                    currency={currencyLeast}
                                />
                                {priceLeastError && <p className="text-red-500">Bu alanın doldurulması gerekir</p>} */}
                                <Input
                                    theme={"product"}
                                    label={t("product:atLeast")}
                                    onChange={(event) => {
                                        setPriceLeast(event.target.value);
                                    }}
                                    value={priceLeast}
                                />
                                {priceLeastError && <p className="text-red-500">{t("product:thisFieldRequired")}</p>}
                            </div>
                            <div className='w-[100%] mt-3 ml-3'>
                                {/* <UnitPriceInput
                                    theme={"product"}
                                    items={priceOptions}
                                    label={"En Çok"}
                                    setValue={(field, value) => {
                                        field === "price" ? setPriceMost(value) : setCurrencyLeast(value);
                                    }}
                                    price={priceMost}
                                    currency={currencyLeast}
                                />
                                {priceMostError && <p className="text-red-500">Bu alanın doldurulması gerekir</p>} */}
                                <Input
                                    theme={"product"}
                                    label={t("product:atMost")}
                                    onChange={(event) => {
                                        setPriceMost(event.target.value);
                                    }}
                                    value={priceMost}
                                />
                                {priceMostError && <p className="text-red-500">{t("product:thisFieldRequired")}</p>}
                            </div>
                        </div>
                    )}

                    <hr className="w-full bg-[#EAECF0] h-[1px] mt-3" />
                    <div className="w-full mt-3">
                        <Button
                            onClick={() => {
                                applyFilter();
                            }}
                            colorType={"primary-product"}
                            label={t("product:applyFilter")}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default RawMaterialListFilter