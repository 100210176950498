import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect, useCallback } from "react";
import { processService } from "../../services";
import { Button, Input } from "../../components";
import { ChevronDown } from "../../assets/icons/PersonIcons";
import { externalOperationService } from "../../services/external-operation.service";
import { useTranslation } from "react-i18next";
import EndDatePicker from "../person/components/EndDatePicker";
import { Calender } from "../../assets/icons/machineIcons";
import moment from "moment";
import Badgets from "../../components/buttons/Badgets";

const OrderFilterPage = ({ setFilterOptions, setIsFilterOpen, filterOptions }) => {
  
  const { t } = useTranslation();
  const [selectedOperation, setSelectedOperation] = useState([]);
  const [expandedOperation, setExpandedOperation] = useState(true);
  const [expandedTime, setExpandedTime] = useState(true);
  const [deliveryStartDate, deliverySetStartDate] = useState(new Date());
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [deliveryEndDate, deliverySetEndDate] = useState(new Date());
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);
  const [creationStartDate, setCreationStartDate] = useState(new Date());
  const [showStartDateCreationPicker, setShowStartDateCreationPicker] = useState(false);
  const [creationEndDate, setCreationEndDate] = useState(new Date());
  const [showEndDateCreationPicker, setShowEndDateCreationPicker] = useState(false);
  const [expandedQuantity, setExpandedQuantity] = useState(true);
  const [externalSelectedOperation, setExternalSelectedOperation] = useState([]);
  const [qty, setQty] = useState({ min: 0, max: 0 });

  const { data: externalData, isLoading: externalIsLoading } = useQuery({
    queryKey: ["externalOperationGroupOrderFilter"],
    enabled: true,
    queryFn: async () => {
      return await externalOperationService.getExternalOperationList();
    },
    retry: 0,
  });

  const { data, isLoading } = useQuery({
    queryKey: ["operationGroupOrder"],
    queryFn: async () => {
      return await processService.getActiveProcess();
    },
    retry: 0,
  });

  const handleOperationChange = (operation) => {
    setSelectedOperation((prevSelectedOperation) => {
      const updatedOperations = prevSelectedOperation.includes(operation?.id)
        ? prevSelectedOperation.filter((item) => item !== operation?.id)
        : [...prevSelectedOperation, operation?.id];
      return updatedOperations;
    });
  };

  const handleSortCancel = useCallback(
    (e) => {
      e.stopPropagation();
      setSelectedOperation([]);
      setExternalSelectedOperation([]);
      setCreationEndDate(new Date());
      setCreationStartDate(new Date());
      deliverySetStartDate(new Date());
      deliverySetEndDate(new Date());

      setQty({ min: 0, max: 0 });
      setFilterOptions({});
    },
    [
      setSelectedOperation,
      setExternalSelectedOperation,
      setCreationEndDate,
      setCreationStartDate,
      deliverySetStartDate,
      deliverySetEndDate,
      setQty,
      setFilterOptions,
    ]
  );

  const applyFilter = useCallback(() => {
    const filters = {
      operation: [...selectedOperation, ...externalSelectedOperation],
      creationStartDate: moment(creationStartDate)?.format("DD-MM-YYYY") != moment()?.format("DD-MM-YYYY") ? moment(creationStartDate).utc()?.format() : null,
      creationEndDate: moment(creationEndDate)?.format("DD-MM-YYYY") != moment()?.format("DD-MM-YYYY") ? moment(creationEndDate).utc()?.format() : null,
      deliveryStartDate: moment(deliveryStartDate)?.format("DD-MM-YYYY") != moment()?.format("DD-MM-YYYY") ? moment(deliveryStartDate).utc()?.format() : null,
      deliveryEndDate: moment(deliveryEndDate)?.format("DD-MM-YYYY") != moment()?.format("DD-MM-YYYY") ? moment(deliveryEndDate).utc()?.format() : null,
      min: qty?.min !== 0 ? qty?.min : null,
      max: qty?.max !== 0 ? qty?.max : null,
    };
    setFilterOptions(filters);
    setIsFilterOpen(false);
  }, [selectedOperation, deliveryStartDate, deliveryEndDate, qty, creationEndDate, creationStartDate, setFilterOptions]);

  const handleRemoveInternalOperation = (operationId) => {
    setSelectedOperation((prevSelectedOperation) => prevSelectedOperation.filter((id) => id !== operationId));
  };

  const handleRemoveExternalOperation = (operationId) => {
    setExternalSelectedOperation((prevExternalSelectedOperation) => prevExternalSelectedOperation.filter((id) => id !== operationId));
  };

  const handleRemoveQuantity = () => {
    setFilterOptions((prevFilterOptions) => ({
      ...prevFilterOptions,
      min: null,
      max: null,
    }));
  };

  const handleRemoveDateRange = (type) => {
    setFilterOptions((prevFilterOptions) => {
      return {
        ...prevFilterOptions,
        [type]: null,
      };
    });
  };

  const formatDate = (dateStr) => {
    return moment(dateStr).format("DD.MM.YYYY");
  };

  const getOperationName = (id) => {
    const operation = data?.find((op) => op?.id === id) || externalData?.find((op) => op?.id === id);
    return operation ? operation.name : "Unknown Operation";
  };

  return (
    <div className="w-full h-[95vh] bg-white flex flex-col items-start">
      <div className="flex w-full justify-between items-center">
        <p className="text-[#101828] font-semibold text-xl">{t("loss:filter")}</p>
        <Button colorType={"tertiary-gray"} iconLeft={<ModalCloseIcon />} onClick={() => setIsFilterOpen(false)} />
      </div>
      <div className="flex flex-col h-full w-full overflow-y-auto scrollbar-hide items-start justify-start gap-y-6 pt-6">
        <div className="flex flex-col gap-y-4 w-full">
          <div className="flex justify-between w-full items-center">
            <p className="text-[#101828] font-semibold text-md">{t("product:selectedFilter")}</p>
            <span>
              <Button colorType={"tertiary-error"} label={t("product:clear")} size={"sm"} iconRight={<CloseIcon />} onClick={handleSortCancel} />
            </span>
          </div>
          <div className="flex w-full flex-wrap gap-3">
            {selectedOperation.map((id, index) => (
              <Badgets
                key={index}
                colorType={"fill-gray"}
                label={getOperationName(id)}
                size={"sm"}
                iconRight={<MiniCloseIcon />}
                onClick={() => handleRemoveInternalOperation(id)}
              />
            ))}

            {externalSelectedOperation.map((id, index) => (
              <Badgets
                key={index}
                colorType={"fill-gray"}
                label={getOperationName(id)}
                size={"sm"}
                iconRight={<MiniCloseIcon />}
                onClick={() => handleRemoveExternalOperation(id)}
              />
            ))}
            {(qty?.min !== 0 || qty?.max !== 0) && (
              <Badgets
                colorType={"fill-gray"}
                label={`${qty?.min ? `${qty.min} ad.` : "Min belirtilmedi"} - ${qty?.max ? `${qty.max} ad.` : "Max belirtilmedi"}`}
                size={"sm"}
                iconRight={<MiniCloseIcon />}
                onClick={() => {
                  setQty({ min: 0, max: 0 });
                }}
              />
            )}

            {(filterOptions?.creationStartDate || filterOptions?.creationEndDate) && (
              <Badgets
                colorType={"fill-gray"}
                label={`${filterOptions?.creationStartDate ? formatDate(creationStartDate) : "Başlangıç seçilmedi"} - ${
                  filterOptions?.creationEndDate ? formatDate(creationEndDate) : "Bitiş seçilmedi"
                }`}
                size={"sm"}
                iconRight={<MiniCloseIcon />}
                onClick={() => handleRemoveDateRange("creationStartDate")}
              />
            )}

            {(filterOptions?.deliveryStartDate || filterOptions?.deliveryEndDate) && (
              <Badgets
                colorType={"fill-gray"}
                label={`${filterOptions?.deliveryStartDate ? formatDate(deliveryStartDate) : "Başlangıç seçilmedi"} - ${
                  filterOptions?.deliveryEndDate ? formatDate(deliveryEndDate) : "Bitiş seçilmedi"
                }`}
                size={"sm"}
                iconRight={<MiniCloseIcon />}
                onClick={() => handleRemoveDateRange("deliveryStartDate")}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="w-full flex flex-row items-center justify-between border-b pb-1">
            <p className="text-sm font-medium text-secondary-700">{t("settingTab:internalOperations")}</p>
            <div onClick={() => setExpandedOperation(!expandedOperation)} className={`w-9 ${expandedOperation && "rotate-180"}`}>
              <Button colorType={"tertiary-gray"} size={"sm"} iconLeft={<ChevronDown />} />
            </div>
          </div>
          {expandedOperation && (
            <div className="h-[180px] w-full flex flex-col overflow-y-scroll scrollbar-hide">
              {data?.map((op, index) => {
                return (
                  <div
                    key={index}
                    className={`flex items-center cursor-pointer p-2 text-secondary-600 font-normal bg-white`}
                    onClick={() => handleOperationChange(op)}
                  >
                    <span
                      className={`w-5 h-5 border rounded-md  flex justify-center  items-center mr-2 ${
                        selectedOperation.includes(op?.id) ? "bg-[#DC6803] border-[#DC6803]" : "border-[#D0D5DD]"
                      }`}
                    >
                      {selectedOperation.includes(op?.id) && (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                      )}
                    </span>
                    <span className="text-sm font-normal text-secondary-600">{op?.name}</span>
                  </div>
                );
              })}
            </div>
          )}

          <div className="w-full flex flex-row items-center justify-between border-b pb-1">
            <p className="text-sm font-medium text-secondary-700">{t("settingTab:externalOperations")}</p>
            <div onClick={() => setExpandedOperation(!expandedOperation)} className={`w-9 ${expandedOperation && "rotate-180"}`}>
              <Button colorType={"tertiary-gray"} size={"sm"} iconLeft={<ChevronDown />} />
            </div>
          </div>
          {expandedOperation && (
            <div className="h-[180px] w-full flex flex-col overflow-y-scroll scrollbar-hide">
              {externalData?.map((op, index) => {
                return (
                  <div
                    key={index}
                    className={`flex items-center cursor-pointer p-2 text-secondary-600 font-normal bg-white`}
                    onClick={() => handleOperationChange(op)}
                  >
                    <span
                      className={`w-5 h-5 border rounded-md  flex justify-center  items-center mr-2 ${
                        selectedOperation?.includes(op?.id) ? "bg-[#DC6803] border-[#DC6803]" : "border-[#D0D5DD]"
                      }`}
                    >
                      {selectedOperation?.includes(op?.id) && (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                      )}
                    </span>
                    <span className="text-sm font-normal text-secondary-600">{op?.name}</span>
                  </div>
                );
              })}
            </div>
          )}

          <div className="w-full flex flex-row items-center justify-between border-b pb-1">
            <p className="text-sm font-medium text-secondary-700"> {t("product:timeRange")}</p>
            <div onClick={() => setExpandedTime(!expandedTime)} className={`w-9 ${expandedTime && "rotate-180"}`}>
              <Button colorType={"tertiary-gray"} size={"sm"} iconLeft={<ChevronDown />} />
            </div>
          </div>
          {expandedTime && (
            <>
              <div className="flex flex-col w-full mt-3">
                <p className="text-sm font-medium text-secondary-700"> {t("product:deliveryTime")}</p>

                <div className="flex flex-row mt-1 items-center justify-center gap-x-2 p-4 w-full border h-11 border-secondary-300 rounded-lg">
                  <EndDatePicker
                    date={deliveryStartDate}
                    visibleFooter
                    setDate={deliverySetStartDate}
                    visible={showStartDatePicker}
                    setVisible={setShowStartDatePicker}
                    buttonClassName={"flex flex-col items-center"}
                    calendarClassName={"rounded-xl absolute top-11"}
                  >
                    <div onClick={() => setShowStartDatePicker(!showStartDatePicker)} className="flex flex-row cursor-pointer items-center gap-x-1">
                      <Calender color={"#344054"} />
                      <p className="text-sm font-medium text-secondary-800">{moment(deliveryStartDate).format("DD.MM.YYYY")}</p>
                    </div>
                  </EndDatePicker>
                  -
                  <EndDatePicker
                    date={deliveryEndDate}
                    visibleFooter
                    setDate={deliverySetEndDate}
                    visible={showEndDatePicker}
                    setVisible={setShowEndDatePicker}
                    buttonClassName={"flex flex-col items-center"}
                    calendarClassName={"rounded-xl absolute top-11"}
                  >
                    <div onClick={() => setShowEndDatePicker(!showEndDatePicker)} className="flex flex-row cursor-pointer items-center gap-x-1">
                      <p className="text-sm font-medium text-secondary-800">{moment(deliveryEndDate).format("DD.MM.YYYY")}</p>
                    </div>
                  </EndDatePicker>
                </div>
              </div>
              <div className="flex flex-col w-full mt-3">
                <p className="text-sm font-medium text-secondary-700"> {t("report:creationDate")}</p>

                <div className="flex flex-row mt-1 items-center justify-center gap-x-2 p-4 w-full border h-10 border-secondary-300 rounded-lg">
                  <EndDatePicker
                    date={creationStartDate}
                    visibleFooter
                    setDate={setCreationStartDate}
                    visible={showStartDateCreationPicker}
                    setVisible={setShowStartDateCreationPicker}
                    buttonClassName={"flex flex-col items-center"}
                    calendarClassName={"rounded-xl absolute top-11"}
                  >
                    <div
                      onClick={() => setShowStartDateCreationPicker(!showStartDateCreationPicker)}
                      className="flex flex-row cursor-pointer items-center gap-x-1"
                    >
                      <Calender color={"#344054"} />
                      <p className="text-sm font-medium text-secondary-800">{moment(creationStartDate).format("DD.MM.YYYY")}</p>
                    </div>
                  </EndDatePicker>
                  -
                  <EndDatePicker
                    date={creationEndDate}
                    visibleFooter
                    setDate={setCreationEndDate}
                    visible={showEndDateCreationPicker}
                    setVisible={setShowEndDateCreationPicker}
                    buttonClassName={"flex flex-col items-center"}
                    calendarClassName={"rounded-xl absolute top-11"}
                  >
                    <div onClick={() => setShowEndDateCreationPicker(!showEndDateCreationPicker)} className="flex flex-row cursor-pointer items-center gap-x-1">
                      <p className="text-sm font-medium text-secondary-800">{moment(creationEndDate).format("DD.MM.YYYY")}</p>
                    </div>
                  </EndDatePicker>
                </div>
              </div>
            </>
          )}
          <div className="w-full flex flex-row items-center justify-between border-b pb-1">
            <p className="text-sm font-medium text-secondary-700"> {t("product:quantitys")}</p>
            <div onClick={() => setExpandedQuantity(!expandedQuantity)} className={`w-9 ${expandedQuantity && "rotate-180"}`}>
              <Button colorType={"tertiary-gray"} size={"sm"} iconLeft={<ChevronDown />} />
            </div>
          </div>
          {expandedQuantity && (
            <div className="flex flex-col w-full mt-3 gap-y-3">
              <Input
                label={t("product:minimum")}
                value={qty?.min != 0 ? qty.min : null}
                onChange={(e) => {
                  const value = e.target.value;
                  setQty({
                    ...qty,
                    min: value === "" ? 0 : parseInt(value),
                  });
                }}
                theme={"product"}
              />
              <Input
                label={t("product:maximum")}
                value={qty?.max != 0 ? qty?.max : null}
                onChange={(e) => {
                  const value = e.target.value;
                  setQty({
                    ...qty,
                    max: value === "" ? 0 : parseInt(value),
                  });
                }}
                theme={"product"}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full mt-3">
        <Button
          onClick={() => {
            applyFilter();
          }}
          colorType={"primary-product"}
          label={t("product:applyFilter")}
        />
      </div>
    </div>
  );
};

export default OrderFilterPage;

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M14.1668 5.83331L5.8335 14.1666M5.8335 5.83331L14.1668 14.1666"
        stroke="#B54708"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const MiniCloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M9 3L3 9M3 3L9 9" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const ModalCloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15 5L5 15M5 5L15 15" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
