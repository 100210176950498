import React, { memo, useEffect, useState , useRef } from "react";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { authStore } from "../../../../stores/auth.store";
import { useTranslation } from "react-i18next";
import { reportService } from "../../../../services/report.service";
import { Button } from "../../../../components";
import {
  CheckIcon,
  ExportIcon,
  FlipBackwardIcon,
  LoadIcon,
  SaveIcon,
} from "../../../../assets/icons/machineIcons";
import { Toast } from "../../../../utils/toastify/toast";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const MonthlyChart = ({
  data,
  height = "320px",
  start,
  end,
  setDate,
  setLegendSelected,
  legendSelected,
  selectedEquipmentId,
  selectedDate,
  defaultLegends,

  selectedOperationName,
}) => {
  const { t } = useTranslation();
  const [selectedButton, setSelectedButton] = useState("daily");
  const [legacyData, setLegacyData] = useState(data);
  const [checkBox, setCheckBox] = useState(false);
  const [realLegends, setRealLegends] = useState(defaultLegends);
  const [backDefaultLegends, setBackDefaultLegends] = useState(false);
  const onChartClick = (params) => {
    if (params?.name) {
      setDate(params.name);
    }
  };
  const todayDate = moment(timeFormatter(new Date())?.formatted).format(
    "YYYY-MM-DD"
  );
  const FetchData = async (button) => {
    await reportService
      .legacyData("process", button, selectedEquipmentId, selectedDate)
      .then((res) => {
        setLegacyData(res?.data);
      });
  };

  const rotateFunction = () => {
    if (selectedButton === "weekly") {
      return 45;
    } else if (selectedButton === "monthly") {
    } else {
      return 90;
    }
  };

  const handleButtonClick = (button) => {
    if (button === "today") {
      setDate(todayDate);
    } else {
      FetchData(button);
      setSelectedButton(button);
    }
  };
  const onEvents = {
    click: onChartClick,
    legendselectchanged: function (params) {
      setLegendSelected(params.selected);
    },
  };
  const dates = legacyData?.dates?.map((item) =>
    String(moment(item).format("YYYY-MM-DD"))
  );
  const option = {
    title: {},
    tooltip: {
      trigger: "axis",
      position: function (pt, params, dom, rect, size) {
        const chartWidth = size.viewSize[0];
        const tooltipWidth = size.contentSize[0];
        const position =
          pt[0] + tooltipWidth + 200 > chartWidth ? "left" : "right";
        return { [position]: 150, top: pt[1] + 15 };
      },
      axisPointer: {
        type: "cross",
      },
    },
    legend: {
      selected: legendSelected,
      data: legacyData?.legend,
      right: "1%",
      left: "1%",
      top: "4%",
      alignTicks: false,
      formatter: function (name) {
        let itemValue = legacyData?.series?.filter(
          (item) => item.name === name
        );
        const total = itemValue[0].data.reduce((a, b) => {
          return a + b;
        }, 0);
        return `{name|${name}}:{total|Avg: ${Number((total / 30).toFixed(2))}}`;
      },
      textStyle: {
        rich: {
          name: { fontWeight: "regular", align: "left", fontSize: 10 },
          total: {
            fontSize: 10,
            fontWeight: "regular",
          },
        },
      },
    },
    grid: {
      left: "3%",
      right: "3%",
      bottom: "0%",
      top: "40%",
      containLabel: true,
    },
    // toolbox: {
    //   feature: {
    //     dataView: {
    //       title: "Data",
    //       iconStyle: {
    //         borderColor: "black",
    //       },
    //       // emphasis: {
    //       //   iconStyle: {
    //       //     borderColor: "red",
    //       //   },
    //       // },
    //       lang: ["Data View", "Close", "Refresh"],
    //       // optionToContent: function(opt) {
    //       //   var axisData = opt.xAxis[0].data;
    //       //   var series = opt.series;
    //       //   var table = '<table style="width:100%;text-align:center"><tbody><tr>'
    //       //               + '<td>Date</td>'
    //       //               + '<td>' + series[0].name + '</td>'
    //       //               + '<td>' + series[1].name + '</td>'
    //       //               + '</tr>';
    //       //   for (var i = 0, l = axisData.length; i < l; i++) {
    //       //     table += '<tr>'
    //       //             + '<td>' + axisData[i] + '</td>'
    //       //             + '<td>' + series[0].data[i] + '</td>'
    //       //             + '<td>' + series[1].data[i] + '</td>'
    //       //             + '</tr>';
    //       //   }
    //       //   table += '</tbody></table>';
    //       //   return table;
    //       // }
    //     },
    //     saveAsImage: {
    //       title: "Save",
    //       iconStyle: {
    //         borderColor: "black",
    //       },
    //       // emphasis: {
    //       //   iconStyle: {
    //       //     borderColor: "red",
    //       //   },
    //       // },
    //     },
    //   },
    // },
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: dates,
      axisLabel: {
        rotate: rotateFunction(),
        formatter: function (value, index) {
          if (selectedButton === "weekly") {
            return moment(value).format("YY-MM-DD");
          } else if (selectedButton === "monthly") {
            return moment(value).format("MMM-YY");
          } else {
            return moment(value).format("ddd DD");
          }
        },
        show: true,
      },
    },
    yAxis: [
      {
        type: "value",
        name: "kVAh",
        position: "right",
        alignTicks: false,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#035096",
            left: "10px",
          },
        },
        axisLabel: {
          formatter: "{value} kVAh",
          show: true,
          textStyle: {
            color: "#035096",
            right: "-10px",
          },
        },
      },
      {
        type: "value",
        name: t("addProcess:rate"),
        position: "rightleft",
        alignTicks: false,
        offset: 10,
        min: 0,
        max: 1,
        axisLine: {
          show: true,
          right: "20px",
        },
        axisLabel: {
          formatter: "{value} %",
          show: true,
          textStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
          },
        },
      },
      {
        type: "value",
        name: t("embedded:status"),
        position: "left",
        alignTicks: false,
        offset: 60,
        axisLine: {
          show: true,
          right: "20px",
        },
        axisLabel: {
          formatter: "{value} h",
          show: true,
          textStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
            left: "-20px",
          },
        },
      },
      {
        type: "value",
        name: "kVA",
        position: "right",
        alignTicks: false,
        offset: 80,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#be8663",
          },
        },
        axisLabel: {
          formatter: "{value} kVA",
          show: true,
          textStyle: {
            color: "#be8663",
          },
        },
      },
    ],
    series: legacyData?.series,
  };
  const allSelected = () => {
    const allChecked = Object.values(legendSelected).every((value) => value);
    const newLegendSelected = legacyData?.legend.reduce((acc, legend) => {
      acc[legend] = !allChecked;
      return acc;
    }, {});
    setLegendSelected(newLegendSelected);
  };
  const handleSave = async () => {
    const selectedLegends = Object.keys(legendSelected).filter(
      (key) => legendSelected[key]
    );
    if (selectedLegends.length === 0) {
      selectedLegends.push("empty");
    }
    const saveData = { legends: selectedLegends, type: "operation_equipment" };
    await reportService.saveLegends(saveData).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        setRealLegends(res?.data?.data);
      } else {
        Toast("error", res.data.message);
      }
    });
  };

  const exportToExcel = async () => {
    try {
      const chartInstance = echartRef.current.getEchartsInstance();

      // Grafiğin verilerini al
      const opt = chartInstance.getOption();
      const axisData = opt.xAxis[0].data;
      const series = opt.series;
      const data = [];

      // Çalışma sayfası için verileri hazırla
      const header = ["Date", ...series.map((s) => s.name)];
      data.push(header);
      for (let i = 0, l = axisData.length; i < l; i++) {
        const row = [axisData[i]];
        series.forEach((s) => {
          row.push(s.data[i]);
        });
        data.push(row);
      }

      // Yeni bir çalışma kitabı oluştur
      const workbook = new ExcelJS.Workbook();

      // Yeni bir çalışma sayfası oluştur
      const worksheet = workbook.addWorksheet("Report");

      // A1'den K1'e kadar hücreleri birleştir ve ekipman adı ve kodunu ekle
      worksheet.mergeCells("A1:K1");
      const titleCell = worksheet.getCell("A1");
      titleCell.value = `${selectedOperationName} ${selectedButton?.toUpperCase()} Report`;
      titleCell.font = { name: "Arial", size: 18 };
      titleCell.alignment = { horizontal: "center", vertical: "middle" };

      // L1'den M1'e kadar hücreleri birleştir ve tarih ekle
      worksheet.mergeCells("L1:O1");
      const dateCell = worksheet.getCell("L1");
      dateCell.value = moment(selectedDate).format("LL");
      dateCell.font = { name: "Arial", size: 18 };
      dateCell.alignment = { horizontal: "left", vertical: "middle" };

      // İkinci satırın yüksekliğini 500px olarak ayarla
      worksheet.getRow(2).height = 200 / 0.75; // Excel'de 1 satır yüksekliği yaklaşık 0.75 piksel olduğundan bu çevirim kullanıldı.

      // Grafikten resim URL'sini al
      const imageUrl = chartInstance.getDataURL({
        type: "png",
        pixelRatio: 2,
        backgroundColor: "#fff",
      });

      const imageResponse = await fetch(imageUrl);
      const imageBlob = await imageResponse.blob();
      const imageArrayBuffer = await imageBlob.arrayBuffer();
      const uint8Array = new Uint8Array(imageArrayBuffer);

      const imageId = workbook.addImage({
        buffer: uint8Array,
        extension: "png",
      });

      worksheet.addImage(imageId, {
        tl: { col: 0, row: 1 },
        ext: { width: 1050, height: 450 },
      });

      worksheet.insertRow(3, []);

      const thirdRow = worksheet.getRow(3);
      thirdRow.height = 40;
      data[0].forEach((value, colIndex) => {
        const cell = thirdRow.getCell(colIndex + 1);
        cell.value = value;
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "D0D5DD" },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = {
          wrapText: true,
          horizontal: "center",
          vertical: "center",
        };
        cell.font = { name: "Arial", size: 11 };
      });

      let rowIndex = 4;
      for (let i = 1; i < data.length; i++) {
        const row = worksheet.insertRow(rowIndex + i - 1, data[i]);
        row.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      }

      const buffer = await workbook.xlsx.writeBuffer();

      saveAs(
        new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        `${selectedOperationName} ${selectedDate} ${selectedButton.toUpperCase()} Report.xlsx`
      );
    } catch (error) {
      console.error("Excel dosyası işlenirken bir hata oluştu:", error);
      alert("Excel dosyası işlenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  const handleBack = () => {
    setBackDefaultLegends(!backDefaultLegends);
  };

  useEffect(() => {
    if (realLegends?.length === 0) {
      const allLegends = data?.series?.reduce((acc, legend) => {
        acc[legend.name] = true;
        return acc;
      }, {});
      setLegendSelected(allLegends);
    } else {
      const allLegends = data?.series?.reduce((acc, legend) => {
        acc[legend.name] = realLegends?.includes(legend.name);
        return acc;
      }, {});
      setLegendSelected(allLegends);
    }
  }, [data?.series, backDefaultLegends]);

  useEffect(() => {
    const allChecked = Object.values(legendSelected).every((value) => value);
    setCheckBox(allChecked);
  }, [legendSelected]);
  const echartRef = useRef(null);

  

  return (
    <div
      className="relative flex w-full h-full"
      style={{
        minWidth: "100%",
      }}
    >
      {/* <div className="flex w-full min-h-[64px] max-h-[64px] h-[64px] px-4 py-3 justify-between items-center">
        <div className="flex gap-x-4 items-center">
          <div
            onClick={() => {
              allSelected();
            }}
            className="flex gap-x-[6px] items-center text-[#475467] hover:text-[#344054] cursor-pointer"
          >
            <span
              className={`flex rounded min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] items-center justify-center ${
                checkBox ? "bg-[#7F56D9]" : "bg-white border border-[#D0D5DD]"
              }`}
            >
              {checkBox && <CheckIcon />}
            </span>
            <p className="font-semibold text-sm">{t("person:selectAll")}</p>
          </div>
          <div onClick={handleSave} className="flex gap-x-[6px] items-center text-[#6941C6] hover:text-[#53389E] cursor-pointer">
            <SaveIcon />
            <p className="font-semibold text-sm">{t("consoleModal:saveSelectedLegends")}</p>
          </div>
          <div onClick={handleBack} className="flex gap-x-[6px] items-center text-[#475467] hover:text-[#344054] cursor-pointer">
            <FlipBackwardIcon />
            <p className="font-semibold text-sm">{t("consoleModal:applySaveLegends")}</p>
          </div>
        </div>
        <div className="flex gap-x-2">
          <div className="flex border rounded-lg">
            <button
              onClick={() => handleButtonClick("daily")}
              className={`flex min-w-[80px] w-full min-h-[40px] max-h-[40px] h-10 border-r border-[#D0D5DD] rounded-l-lg py-3 px-4 items-center justify-center font-semibold text-sm  active:shadow-ring-gray text-[#344054] ${
                selectedButton === "daily" ? "bg-[#F2F4F7]" : "hover:bg-[#F9FAFB] bg-white"
              }`}
            >
              {t("buttons:daily")}
            </button>
            <button
              onClick={() => handleButtonClick("monthly")}
              className={`flex min-w-[80px] w-full min-h-[40px] max-h-[40px] h-10 py-[10px] px-[14px] items-center justify-center font-semibold text-sm  active:shadow-ring-gray ${
                selectedButton === "monthly" ? "bg-[#F2F4F7]" : "hover:bg-[#F9FAFB] bg-white"
              }`}
            >
              {t("buttons:monthly")}
            </button>
            <button
              onClick={() => handleButtonClick("weekly")}
              className={`flex min-w-[80px] w-full min-h-[40px] max-h-[40px] h-10 border-l border-[#D0D5DD] rounded-r-lg py-[10px] px-[14px] items-center justify-center font-semibold text-sm  active:shadow-ring-gray ${
                selectedButton === "weekly" ? "bg-[#F2F4F7]" : "hover:bg-[#F9FAFB] bg-white"
              }`}
            >
              {t("buttons:weekly")}
            </button>
          </div>
          <Button onClick={() => handleButtonClick("today")} colorType={"tertiary-gray"} label={t("chat:today")} size={"md"} iconLeft={<FlipBackwardIcon />} />
        </div>
      </div> */}
      <ReactECharts
        onEvents={onEvents}
        option={option}
        style={{
          height: height,
          width: "98%",
          paddingBottom: 16,
        }}
        ref={echartRef}
      />
      <div
        className={`absolute top-[45px] font-normal gap-2 dark:text-[#F5F5F5] left-4 w-fit gap-x-2`}
      >
        <p className="text-[12px] mt-6 font-semibold inline-block ">
          {t("settingTab:start")} :{" "}
          {start
            ? moment(
                timeFormatter(start, authStore?.user?.company?.timeZone)
                  ?.formatted
              )?.format("DD-MM-YYYY")
            : ""}
        </p>
        <p className="text-[12px] ml-2 mt-6 font-semibold inline-block ">
          {t("settingTab:end")} :{" "}
          {end
            ? moment(
                timeFormatter(end, authStore?.user?.company?.timeZone)
                  ?.formatted
              )?.format("DD-MM-YYYY")
            : ""}
        </p>
      </div>
      <div className="flex flex-col min-w-[164px] border-l">
        <div className="flex items-center flex-row gap-x-2 justify-center w-full border-b p-3">
          <div
            onClick={handleSave}
            className="flex  py-1 gap-x-1 items-center justify-center text-[#6941C6] hover:text-[#53389E] cursor-pointer"
          >
            <SaveIcon />
            <p className="font-semibold text-sm">{t("buttons:save")}</p>
          </div>
          <div
            onClick={handleBack}
            className="flex  py-1 gap-x-1 items-center justify-center text-[#6941C6] hover:text-[#53389E] cursor-pointer"
          >
            <LoadIcon />
            <p className="text-[#6941C6] font-semibold text-sm">{t("buttons:load")}</p>
          </div>
        </div>
        <div className="flex flex-col gap-y-3 w-full h-full p-3 bg-[#F9FAFB]">
          <Button
            onClick={() => exportToExcel()}
            colorType={"tertiary-gray"}
            label={t("buttons:exportXlsx")}
            size={"sm"}
            iconLeft={<ExportIcon />}
          />
          <button
            onClick={() => handleButtonClick("daily")}
            className={`flex min-w-[88px] w-full min-h-[40px] max-h-[40px] h-10 border border-[#D0D5DD] shadow-xs rounded-lg py-[10px] px-[14px] items-center justify-center font-semibold text-sm  active:shadow-ring-gray ${
              selectedButton === "daily"
                ? "bg-[#7F56D9] text-[#FFFFFF]"
                : "hover:bg-[#F9FAFB] text-[#344054] bg-white"
            }`}
          >
            {t("buttons:daily")}
          </button>
          <button
            onClick={() => handleButtonClick("weekly")}
            className={`flex min-w-[88px] w-full min-h-[40px] max-h-[40px] h-10 border border-[#D0D5DD] shadow-xs rounded-lg py-[10px] px-[14px] items-center justify-center font-semibold text-sm  active:shadow-ring-gray ${
              selectedButton === "weekly"
                ? "bg-[#7F56D9] text-[#FFFFFF]"
                : "hover:bg-[#F9FAFB] text-[#344054] bg-white"
            }`}
          >
            {t("buttons:weekly")}
          </button>
          <button
            onClick={() => handleButtonClick("monthly")}
            className={`flex min-w-[88px] w-full min-h-[40px] max-h-[40px] h-10 border border-[#D0D5DD] shadow-xs rounded-lg py-[10px] px-[14px] items-center justify-center font-semibold text-sm  active:shadow-ring-gray ${
              selectedButton === "monthly"
                ? "bg-[#7F56D9] text-[#FFFFFF]"
                : "hover:bg-[#F9FAFB] text-[#344054] bg-white"
            }`}
          >
            {t("buttons:monthly")}
          </button>
          <Button
            onClick={() => handleButtonClick("today")}
            colorType={"tertiary-gray"}
            label={t("chat:today")}
            size={"sm"}
            iconLeft={<FlipBackwardIcon />}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(MonthlyChart);
