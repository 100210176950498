import React, { useEffect, useState } from "react";
import MintueSecondInput from "../inputs/MintueSecondInput";
import UnitPriceInput from "../inputs/UnitPriceInput";
import { productService } from "../../services/product.service";
import { useQuery } from "@tanstack/react-query";
import generateFileUrl from "../../utils/generateFileUrl";
import Button from "../buttons/Button";
import { Toast } from "../../utils/toastify/toast";
import OperationDetailTargetEditModal from "./OperationDetailTargetEditModal";
import CustomModal from "./CustomModal";
import { useTranslation } from "react-i18next";

// Para birimi seçeneklerini burada tanımlıyoruz.
export const currencyItems = [
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
  { label: "RUB", value: "RUB" },
  { label: "UAH", value: "UAH" },
  { label: "TRY", value: "TRY" },
];

const InternalOperationEditCycleTimeModal = ({ selectedOperation, onClose, refetcPage, setMultipleOpenModal }) => {
  const {t} = useTranslation()
  const [inputs, setInputs] = useState({
    standard: {
      checked: false,
      productionTime: "00:00",
      setupTime: "00:00",
      costPerMinute: "1",
      currency: "TRY",
    },
    stations: {},
  });

  const [openTarget, setOpenTarget] = useState(false)

  const handleStandardCheckboxChange = () => {
    const newChecked = !inputs?.standard?.checked;

    setInputs((prevState) => ({
      ...prevState,
      standard: {
        ...prevState.standard,
        checked: newChecked,
      },
      stations: Object.keys(prevState.stations).reduce((acc, key) => {
        acc[key] = {
          ...prevState.stations[key],
          checked: newChecked,
          productionTime: newChecked ? prevState?.standard?.productionTime : prevState?.stations[key]?.productionTime,
          setupTime: newChecked ? prevState?.standard?.setupTime : prevState?.stations[key]?.setupTime,
          costPerMinute: newChecked ? prevState?.standard?.costPerMinute : prevState?.stations[key]?.costPerMinute,
          currency: newChecked ? prevState?.standard?.currency : prevState?.stations[key]?.currency,
        };
        return acc;
      }, {}),
    }));
  };

  const handleStationCheckboxChange = (station) => {
    setInputs((prevState) => {
      const updatedStations = {
        ...prevState.stations,
        [station]: {
          ...prevState.stations[station],
          checked: !prevState.stations[station].checked,
        },
      };
      const allStationsSelected = Object.values(updatedStations).every((s) => s.checked);
      return {
        ...prevState,
        standard: {
          ...prevState.standard,
          checked: allStationsSelected,
        },
        stations: updatedStations,
      };
    });
  };

  const handleStandardInputChange = (field, value) => {
    setInputs((prevState) => {
      const updatedStations = Object.keys(prevState.stations).reduce((acc, key) => {
        acc[key] = {
          ...prevState.stations[key],
          [field]: prevState?.stations[key]?.checked ? value : prevState?.stations[key][field],
        };
        return acc;
      }, {});

      return {
        ...prevState,
        standard: {
          ...prevState.standard,
          [field]: value,
        },
        stations: updatedStations,
      };
    });
  };

  const handleStationInputChange = (field, value, station) => {
    setInputs((prevState) => ({
      ...prevState,
      stations: {
        ...prevState.stations,
        [station]: {
          ...prevState.stations[station],
          [field]: value,
        },
      },
    }));
  };

  const { data, refetch } = useQuery({
    queryKey: ["operationDetailCycle"],
    queryFn: async () =>
      await productService?.getOperationCycleTimeList(
        selectedOperation?.internalOperationId ? "internal" : "external",
        selectedOperation?.internalOperationId ? selectedOperation?.internalOperationId : selectedOperation?.externalOperationId
      ),
    enabled: false,
    retry: 0,
  });


  const stations = data?.stations
    ? data.stations.reduce((acc, item) => {
      acc[`${item?.nodeInfo?.metadata?.brand} ${item?.nodeInfo?.metadata?.model}`] = {
        checked: false,
        productionTime: item?.estimatedTime,
        setupTime: item?.estimatedSettingsTime,
        costPerMinute: Number(item?.costOfMinute) ? item?.costOfMinute : 0,
        currency: item?.currency,
        image: item?.nodeInfo?.metadata?.image,
        id: item?.id,
      };
      return acc;
    }, {})
    : {};

  const onSubmit = async () => {
    const values = {
      estimatedTime: Number(inputs?.standard?.productionTime),
      estimatedSettingsTime: Number(inputs?.standard?.setupTime),
      costOfMinute: Number(inputs.standard?.costPerMinute),
      currency: inputs.standard?.currency,
      type: "internal",
      plannableNodes: Object.entries(inputs.stations).map(([_, station]) => {
        return station.checked ? {
          id: station?.id,
          estimatedTime: Number(station.productionTime),
          estimatedSettingsTime: Number(station?.setupTime),
          costOfMinute: Number(station?.costPerMinute),
          currency: station?.currency,
        } : null
      }).filter((element) => { return element != null }),
    };
    await productService.updateProductOperation(selectedOperation?.internalOperationId, values).then((res) => {
      if (res.data.code == 0) {
        Toast("success", res?.data?.message);
        refetcPage()
      } else {
        Toast("error", res?.data?.message);
      }
      setMultipleOpenModal(false)
    });
  };

  useEffect(() => {
    refetch();
  }, [selectedOperation]);

  useEffect(() => {
    if (data) {
      setInputs({
        standard: {
          checked: false,
          productionTime: data?.operations?.estimatedTime || "00:00",
          setupTime: data?.operations?.estimatedSettingsTime || "00:00",
          costPerMinute: data?.operations?.costOfMinute || "1",
          currency: data?.operations?.currency || "TRY",
        },
        stations,
      });
    }
  }, [data]);

  return (
    <>
      <div className="h-[550px] min-h-[550px] flex flex-col justify-between">
        <div className="mt-8 flex-1 overflow-y-auto scrollbar-hide">
          <table className="w-full">
            <thead>
              <tr>
                <th className="border-b border-r border-gray-200 p-2 text-xs font-medium text-gray-700">{t("product:stations")}</th>
                <th className="border-b border-r border-gray-200 p-2 text-xs font-medium text-gray-700">{t("product:estimatedProductionTimeMin")}</th>
                <th className="border-b border-r border-gray-200 p-2 text-xs font-medium text-gray-700">{t("product:estimatedSetupTimesMin")}</th>
                <th className="border-b border-r border-gray-200 p-2 text-xs font-medium text-gray-700">{t("product:minuteCost")}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-[80px] min-h-[80px] border-b border-gray-200">
                <td className="h-[80px] border-r py-5 px-4">
                  <label className="flex items-center">
                    <input
                      style={{ accentColor: "#DC6803" }}
                      className="w-4 h-4"
                      type="checkbox"
                      checked={inputs?.standard?.checked}
                      onChange={handleStandardCheckboxChange}
                    />
                    <p className="truncate font-semibold text-secondary-900 ml-3">{t("product:allOperationStandart")}</p>
                  </label>
                </td>
                <td className="border-r py-5 px-4">
                  <MintueSecondInput
                    value={inputs?.standard?.productionTime}
                    onChange={(e) => handleStandardInputChange("productionTime", e)}
                    theme={"product"}
                  />
                </td>
                <td className="border-r py-5 px-4">
                  <MintueSecondInput
                    theme={"product"}
                    value={inputs?.standard?.setupTime}
                    onChange={(e) => handleStandardInputChange("setupTime", e)}
                  />
                </td>
                <td className="border-r py-5 px-4 ">
                  <UnitPriceInput
                    theme={"product"}
                    items={currencyItems}
                    setValue={(field, value) => {
                      if (field === "price") {
                        handleStandardInputChange("costPerMinute", value); // Fiyat güncelleniyor
                      } else if (field === "currency") {
                        handleStandardInputChange("currency", value); // Para birimi güncelleniyor
                      }
                    }}
                    price={inputs?.standard?.costPerMinute}
                    currency={inputs?.standard?.currency}
                  />
                </td>
              </tr>

              {Object.keys(inputs?.stations || {}).map((station, index) => (
                <tr key={index} className="h-[80px] min-h-[80px] border-b border-secondary-200">
                  <td className="border-r border-gray-200 py-5 px-4">
                    <label className="flex items-center">
                      <input
                        style={{ accentColor: "#DC6803" }}
                        className="w-4 h-4"
                        type="checkbox"
                        checked={inputs?.stations[station]?.checked}
                        onChange={() => handleStationCheckboxChange(station)}
                      />
                      <div className="flex items-center ml-3">
                        {inputs?.stations[station]?.image ? (
                          <img
                            src={generateFileUrl(inputs?.stations[station]?.image)}
                            className="w-[64px] h-[48px] rounded border"
                          />
                        ) : (
                          <div className="w-[64px] min-w-[64px] h-[48px] min-h-[48px] rounded flex  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                            {station?.slice(0, 2)?.toUpperCase()}
                          </div>
                        )}
                        <div className="flex flex-col">
                          <p className="font-semibold ml-2 text-secondary-900">{station}</p>
                        </div>
                      </div>
                    </label>
                  </td>
                  <td className="border-r border-gray-200 py-5 px-4">
                    <MintueSecondInput
                      value={inputs?.stations[station]?.productionTime}
                      onChange={(e) => handleStationInputChange("productionTime", e, station)}
                      disabled={!inputs?.stations[station]?.checked}
                      theme={"product"}
                    />
                  </td>
                  <td className="border-r border-gray-200 py-5 px-4">
                    <MintueSecondInput
                      value={inputs?.stations[station]?.setupTime}
                      onChange={(e) => handleStationInputChange("setupTime", e, station)}
                      disabled={!inputs?.stations[station]?.checked}
                      theme={"product"}
                    />
                  </td>
                  <td className="border-r border-gray-200 py-5 px-4 ">
                    <UnitPriceInput
                      theme={"product"}
                      items={currencyItems}
                      setValue={(field, value) => {
                        if (field === "price") {
                          handleStationInputChange("costPerMinute", value, station); // Fiyat güncelleniyor
                        } else if (field === "currency") {
                          handleStationInputChange("currency", value, station); // Para birimi güncelleniyor
                        }
                      }}
                      price={inputs?.stations[station]?.costPerMinute}
                      currency={inputs?.stations[station]?.currency}
                      disabled={!inputs?.stations[station]?.checked}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="h-[44px] min-h-[44px] w-full flex mt-4 gap-4">
          <Button colorType={"secondary-gray"} label={t("buttons:stop")} onClick={onClose} />
          {/* <Button colorType={"primary-product"} label={"Güncelle"} onClick={() => setOpenTarget(true)} /> */}
          <Button colorType={"primary-product"} label={t("buttons:update")} onClick={onSubmit} />
        </div>
      </div>
      <CustomModal
        isOpen={openTarget}
        setIsOpen={setOpenTarget}
        onClose={() => { setOpenTarget(false) }}
        width={400}
        children={
          <OperationDetailTargetEditModal setOpenTarget={setOpenTarget} onSubmit={onSubmit} />
        }
      />
    </>

  );
};

export default InternalOperationEditCycleTimeModal;