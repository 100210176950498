import React, { memo, useEffect } from "react";
import { Button } from "../../../components";
import { EditIcon } from "../../../assets/icons/departmentIcon";
import { CheckIcon } from "../../../assets/icons/stepsIcons";
import generateFileUrl from "../../../utils/generateFileUrl";

const ExternalOperationCard = ({
  i,
  image,
  supplierName,
  onClick,
  onEditClick,
  checked,
}) => {
  return (
    <>
      <div
        onClick={onClick}
        className={`w-full h-16 flex gap-0 items-center ${
          i % 2 === 0 ? "border-r pr-4" : "border-r-0 pl-4"
        }  pb-4 `}
      >
        <div className="flex items-center justify-start gap-x-3 w-full">
          {checked ? (
            <div
              type="checkbox"
              className={`w-5 h-5 rounded-md border border-solid ${
                checked ? "border-[#7F56D9]" : "border-[#D0D5DD]"
              } bg-[#7F56D9]  items-center justify-center flex`}
            >
              <CheckIcon color="white" />
            </div>
          ) : (
            <div
              type="checkbox"
              className={`w-5 h-5 rounded-md border border-solid bg-[#FFF] dark:border-[#424242] dark:bg-[#0F0F0F] border-[#D0D5DD] items-center justify-center flex`}
            ></div>
          )}
          {image ? (
            <img
              src={generateFileUrl(image)}
              className="min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] rounded-full"
            />
          ) : (
            <div
              className={
                "min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] rounded-full bg-primary-700 flex border-4 border-primary-400 items-center justify-center font-medium text-white text-xl"
              }
            >
              {supplierName?.charAt(0).toUpperCase()}
            </div>
          )}

          <div className="flex w-52 md:max-w-44 sm:w-28 xs:w-48">
            <span className="text-[#344054] font-semibold text-sm truncate">
              {supplierName}
            </span>
          </div>
        </div>
        <Button
          colorType={"tertiary-gray"}
          size={"sm"}
          iconLeft={<EditIcon />}
          onClick={onEditClick}
        />
      </div>
    </>
  );
};

export default memo(ExternalOperationCard);
