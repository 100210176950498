import React, { useEffect, useState } from "react";
import { Button, CustomModal } from "../../../components";
import PriorityTableCard from "./PriorityTableCard";
import { Loading } from "./svg/EmbeddedServerIcons";
import { Toast } from "../../../utils/toastify/toast";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { embeddedServerService } from "../../../services/embedded-server.service";
import { ExportIcon } from "../../../assets/icons/machineIcons";
import LoadDefaultSettingsModal from "./LoadDefaultSettingsModal";

const sensorNames = (e, type) => {
  if (type === "analog") {
    switch (e) {
      case "first":
        return "A1";
      case "second":
        return "A2";
      case "third":
        return "A3";
      case "fourth":
        return "A4";
      case "fifth":
        return "A1-A2-A3";
      default:
        return "Sensor Name";
    }
  } else {
    switch (e) {
      case "first":
        return "D1";
      case "second":
        return "D2";
      case "third":
        return "D3";
      case "fourth":
        return "D4";
      default:
        return "Sensor Name";
    }
  }
};

const PriorityTable = ({ metadataId, draggedSensor, deviceId, setTowerSatus, setPriorityList, getRegisteredSensors, cards, setCards, deactives }) => {
  const channel = useChannel(deviceId);
  const [rejecteds, setRejecteds] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isColorByCard, setIsColorByCard] = useState(null);
  const [isLoadingByCard, setIsLoadingByCard] = useState(null);
  const [loadingTestButton, setLoadingTestButton] = useState(false);
  const [loadingApplyButton, setloadingApplyButton] = useState(false);
  const disables = cards?.length === 0 ? true : false;
  useEvent(channel, "priority-test", (socketEvent) => {
    setTowerSatus(socketEvent.status);
    for (let i = 0; i < cards?.length; i++) {
      setTimeout(() => {
        if (i + 1 == cards.length && cards[cards.length - 1].value !== socketEvent[cards[cards.length - 1].whichSensorKey]) {
          setIsColorByCard(null);
          setLoadingTestButton(false);
          setIsLoadingByCard(null);
          setRejecteds([]);
          Toast("error", "Sensörler ile öncelik ayarları uyumsuz.");
        } else {
          setRejecteds((a) => [...a, cards[i].whichSensorKey]);
          setIsLoadingByCard(cards[i].whichSensorKey);
        }
      }, i * 500);
      if (cards[i].value == socketEvent[cards[i].whichSensorKey]) {
        setTimeout(() => {
          setIsColorByCard((p) => (p == null ? cards[i]?.whichSensorKey : p));
          setIsLoadingByCard(null);
          setLoadingTestButton(false);
        }, i * 700);
        break;
      }
    }
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleData = async () => {
    setCards([]);
    await embeddedServerService.getPriorityTable(metadataId).then((res) => {
      setPriorityList(res.data);
      setCards(res.data);
    });
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(cards, result.source.index, result.destination.index);
    const newItems = items?.map((_, i) => {
      return { ..._, order: i };
    });
    setCards(newItems);
  };
  const handleDrop = async () => {
    if (draggedSensor?.value !== undefined) {
      if (draggedSensor?.type === "digital") {
        setCards([...cards, draggedSensor]);
      } else if (draggedSensor?.type === "analog" && draggedSensor?.sensorId) {
        setCards([...cards, draggedSensor]);
      } else {
        Toast("error", "Bir hata oluştu.");
      }
    } else {
      Toast("warning", "Lütfen sensöre ait değeri boş mırakmayınız.");
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <>
      <div
        onDrop={() => handleDrop()}
        onDragOver={(e) => e.preventDefault()}
        className={`w-full h-full border rounded-xl border-secondary-200 shadow flex flex-col overflow-hidden bg-white ${
          draggedSensor ? "ring-2 ring-blue-500" : ""
        }`}
      >
        <div className="w-full h-[60px] flex items-center justify-between pl-6 pr-4">
          <p className="text-lg font-semibold text-secondary-900">Öncelik Tablosu</p>
          <div className="flex items-center gap-3 ml-auto">
            <Button
              outSideClassName={"w-fit"}
              size={"sm"}
              label={"Kayıtlı Sensör Ayarı Yükle"}
              iconLeft={
                <div className="text-[#3E4784]">
                  <ExportIcon />
                </div>
              }
              colorType={"secondary-embedded"}
              onClick={() => {
                setIsOpenModal(true);
              }}
            />
            <Button
              outSideClassName={"w-fit"}
              width={67}
              size={"sm"}
              label={"Reset"}
              colorType={"secondary-error"}
              onClick={async () => await embeddedServerService.resetPriorityTable(deviceId, metadataId).then((res) => handleData())}
            />
            <Button
              outSideClassName={"w-fit"}
              disabled={loadingTestButton || disables}
              onClick={async () => {
                if (deactives.length > 0) {
                  Toast("error", "Aktif olmayan sensörler var.");
                } else {
                  setIsColorByCard(null);
                  setIsLoadingByCard(null);
                  setLoadingTestButton(true);
                  setRejecteds([]);
                  await embeddedServerService.priorityTest(deviceId).then((res) => {
                    setTimeout(() => {
                      setLoadingTestButton(false);
                    }, 8000);
                  });
                }
              }}
              label={loadingTestButton ? <Loading size={20} secondary={"#fff"} color={"#3E4784"} /> : "Test Et"}
              colorType={"primary-embedded"}
              size={"sm"}
              width={144}
            />
            <Button
              outSideClassName={"w-fit"}
              disabled={loadingApplyButton || disables}
              onClick={async () => {
                if (deactives.length > 0) {
                  Toast("error", "Aktif olmayan sensörler var.");
                } else {
                  setIsColorByCard(null);
                  setIsLoadingByCard(null);
                  setloadingApplyButton(true);
                  setLoadingTestButton(true);
                  setRejecteds([]);
                  await embeddedServerService
                    .sendPriority(
                      deviceId,
                      metadataId,
                      cards.map((item, index) => {
                        return { ...item, order: index };
                      })
                    )
                    .then((res) => {
                      setTimeout(() => {
                        setLoadingTestButton(false);
                        setloadingApplyButton(false);
                      }, cards.length * 1500);
                      handleData();
                      getRegisteredSensors();
                    });
                }
              }}
              label={loadingApplyButton ? <Loading size={20} secondary={"#fff"} color={"#3E4784"} /> : "Uygula"}
              colorType={"primary-embedded"}
              size={"sm"}
              width={144}
            />
          </div>
        </div>
        <div className="h-[34px] w-full flex items-center pr-3 mb-1">
          <div className="h-full w-[8%] flex items-center justify-center">
            <p className="text-xs font-semibold text-secondary-600">No</p>
          </div>
          <div className="h-full w-[15%] flex items-center px-3">
            <p className="text-xs font-semibold text-secondary-600">Sensörler</p>
          </div>
          <div className="h-full w-[21%] flex items-center justify-center">
            <p className="text-xs font-semibold text-secondary-600">Gecikme</p>
          </div>
          <div className="h-full w-[15%] flex items-center justify-center">
            <p className="text-xs font-semibold text-secondary-600">Değer</p>
          </div>
          <div className="h-full w-[33%] flex items-center justify-center">
            <p className="text-xs font-semibold text-secondary-600">Durum</p>
          </div>
          <div className="h-full w-[8%] flex items-center justify-center">
            <p className="text-xs font-semibold text-secondary-600">Kaldır</p>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable direction="vertical" droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="w-full flex flex-col flex-1 border-t border-secondary-200 overflow-y-auto scrollbar-hide cursor-default"
              >
                {cards?.map((item, index) => {
                  return (
                    <Draggable key={item.id || item.sensorId} draggableId={item.id || item.sensorId} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div
                            className={`${
                              index % 2 === 0 ? "bg-gray-50" : "bg-white"
                            } group w-full max-w-full border-b h-[64px] min-h-[64px] pr-3 flex items-center`}
                          >
                            <div className="w-[8%] h-12 flex items-center justify-center hover:cursor-grab">
                              <div className="group-hover:hidden">
                                {item?.whichSensorKey === isLoadingByCard ? (
                                  <Loading size={22} color={"#3E4784"} secondary={"#fff"} />
                                ) : rejecteds.includes(item?.whichSensorKey) ? (
                                  <>{isColorByCard == item?.whichSensorKey ? <CheckIcon /> : <CloseIcon />}</>
                                ) : (
                                  <p className={`text-secondary-600`}>{index + 1}.</p>
                                )}
                              </div>
                              <LineIcon className={"hidden group-hover:block"} />
                            </div>
                            <div className="flex-1 h-full flex items-center">
                              {item?.sensorId ? (
                                <PriorityTableCard
                                  cards={cards}
                                  id={item?.id}
                                  isDeactive={deactives.includes(item?.order)}
                                  isLoading={false}
                                  setCards={setCards}
                                  deviceId={deviceId}
                                  value={item?.value}
                                  refetch={handleData}
                                  status={item?.status}
                                  metadataId={metadataId}
                                  sensorId={item?.sensorId}
                                  priorityTimeDelay={item?.priorityTimeDelay}
                                  name={
                                    item?.whichSensor
                                      ? sensorNames(item?.whichSensor, item?.type)
                                      : item?.digitalSensor
                                      ? sensorNames(item?.digitalSensor?.whichSensor, "digital")
                                      : sensorNames(item?.analogSensor?.whichSensor, "analog")
                                  }
                                />
                              ) : (
                                <PriorityTableCard
                                  cards={cards}
                                  id={item?.id}
                                  deviceId={deviceId}
                                  isDeactive={deactives.includes(item?.order)}
                                  setCards={setCards}
                                  value={item?.value}
                                  refetch={handleData}
                                  status={item?.status}
                                  metadataId={metadataId}
                                  priorityTimeDelay={item?.priorityTimeDelay}
                                  isLoading={isLoadingByCard == item?.whichSensorKey}
                                  isColor={isColorByCard == item?.whichSensorKey ? "success" : rejecteds.includes(item?.whichSensorKey) ? "error" : null}
                                  name={
                                    item?.digitalSensor
                                      ? sensorNames(item?.digitalSensor?.whichSensor, "digital")
                                      : sensorNames(item?.analogSensor?.whichSensor, "analog")
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {draggedSensor && (
                  <div className="flex-1 w-full p-4 min-h-[88px]">
                    <div className={`animate-pulse border-dashed rounded-xl w-full h-full flex items-center border-2 border-gray-200 justify-center`}></div>
                  </div>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <CustomModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        modalTitle={"Kayıtlı Sensör Ayarı Yükle"}
        children={
          <LoadDefaultSettingsModal
            setCards={setCards}
            closeModal={() => setIsOpenModal(false)}
            metadataId={metadataId}
            getRegisteredSensors={getRegisteredSensors}
            refetch={handleData}
          />
        }
      />
    </>
  );
};

export default PriorityTable;

const Plus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
      <path d="M16.0003 6.66699V25.3337M6.66699 16.0003H25.3337" stroke="#98A2B3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const LineIcon = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M2.5 7.0835H17.5M2.5 12.9168H17.5" stroke="#98A2B3" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="#D92D20"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="#079455"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
