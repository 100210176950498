import React, { memo, useCallback, useEffect, useMemo } from "react";
import { FactoryIcon } from "../../../../assets/icons/plannerIcons";
import LossTimeCard from "../../Card/LossTimeCard";
import MultipleBarChart from "../../Tabs/MultipleBarChart";
import moment from "moment";
import LossesPieChart from "../../Tabs/LossesPieChart";
import { useTranslation } from "react-i18next";

const CompanyOperation = ({ Tabs, page, tabskey, month, firstTab, setFirstTab, data, getLossesData, setSelectedOperation }) => {
  const { t } = useTranslation();

  const handleTabClick = useCallback(
    (tabKey) => {
      setFirstTab(tabKey);
    },
    [setFirstTab]
  );

  const chartCategories = useMemo(() => {
    return data?.sort((a, b) => a.month - b.month)?.map((item) => `${item.month}`);
  }, [data]);

  const chartSeries = useMemo(() => {
    return tabskey?.map((tab) => ({
      name: tab?.name,
      type: "bar",
      barGap: 0,
      emphasis: {
        focus: "series",
      },
      data: data?.sort((a, b) => a?.month - b?.month)?.map((item) => item && item[tab?.key]),
    }));
  }, [data, tabskey]);

  useEffect(() => {
    setSelectedOperation(
      data
        ?.find((item) => {
          return item?.month == moment(month).format("M");
        })
        ?.operations?.sort((a, b) => b[firstTab] - a[firstTab])[0]?.typeId
    );
  }, [data]);

  return (
    <>
      <div className="w-full flex flex-col gap-y4  md:h-full md:max-h-full sm:h-full sm:max-h-full  xs:h-full xs:max-h-full">
        <div className="flex items-center gap-6 gap-y-3 xs:gap-y-6 h-full xs:px-2 sm:ml-1.5 xs:flex-col sm:flex-wrap sm:gap-y-6  md:gap-4 z-10 w-full sm:w-[97%]">
          <LossTimeCard name={t("settingTab:company")} icon={<FactoryIcon />} selectedCategory={"company"} selected={null} page={"loss"} value={""} />

          {Tabs?.map((tab, index) => {
            return (
              <div
                className={`w-full px-1 lg:px-1 md:px-0 sm:px-0 xs:px-0 sm:w-full ${index === 0 ? "sm:w-[47%] " : "" ? "xs:w-[100%]" : "sm:w-[47%]"}`}
                key={index}
              >
                <LossTimeCard
                  page="loss"
                  index={index}
                  key={index}
                  name={tab?.name}
                  icon={tab?.icon}
                  value={
                    data?.find((item) => {
                      return item?.month == moment(month).format("M");
                    })?.[tab?.key]
                  }
                  onClick={() => {
                    handleTabClick(tab?.key);
                  }}
                  selectedCategory={firstTab}
                  selected={tab?.key}
                />
              </div>
            );
          })}
        </div>

        <div className="flex  border-b pb-5 flex-row md:flex-col sm:flex-col xs:flex-col items-center gap-2 w-full h-full">
          {/*bar chart*/}
          <div className="w-1/2 overflow-hidden md:w-full md:h-[500px] md:max-h-[500px] sm:w-[100%] sm:h-[500px] xs:h-[500px] md:mt-4 xs:w-[100%]">
            <MultipleBarChart handleData={getLossesData} charts={"loss"} categories={chartCategories} series={chartSeries} />
          </div>

          {/*pie chart*/}
          <div className="flex items-center gap-2 w-1/2 md:w-full sm:w-full xs:w-full h-full">
            {data?.find((item) => {
              return item?.month == moment(month).format("M");
            })?.operations?.length >= 1 ? (
              <LossesPieChart
                page={page}
                data={data
                  ?.find((item) => {
                    return item?.month == moment(month).format("M");
                  })
                  ?.operations.map((operation) => {
                    return {
                      value: operation[firstTab],
                      id: operation?.typeId,
                      name: operation?.name,
                    };
                  })}
                setSelectedLegend={setSelectedOperation}
              />
            ) : (
              <>
                <LossesPieChart page={page} setSelectedLegend={setSelectedOperation} />
              </>
            )}
          </div>
        </div>
      </div>
      {/* <hr className="mt-5 border-[0.5px] border-secondary-200 w-full dark:border-[#202020]" /> */}
    </>
  );
};

export default memo(CompanyOperation);
