import React, { useState } from "react";
import CustomImage from "../../CustomImage";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import {
  AcceptedIcon,
  DeleteIcon,
  DotsVerticalIcon,
  EndClockIcon,
  EquCalculate,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  FlexProductIcon,
  PersonnelDetailIcon,
  PieceIcon,
  QualityDetailIcon,
  RejectedIcon,
  StartClockIcon,
} from "../card-icons";
import NoAvatar from "../../avatar/NoAvatar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { junctionService } from "../../../services/junction.service";
import { Toast } from "../../../utils/toastify/toast";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../hooks/useStores";
import { Tooltip } from "react-tooltip";
import AlertModal from "../../alert-Modal/Alert-Modal";
import CustomModal from "../../modal/CustomModal";
import QualityControlForm from "../../modal/QualityControlForm";
import JunctionUpdate from "../../modal/JunctionUpdate";
const InternalOperationPlannedWaitingEventCard = ({ data, setJunctionId, setProductType, setIsOpenForm, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isJunction, setIsJunction] = useState(false);
  const { auth } = useStore();
  const menuOptions = [
    {
      label: t("product:personnelDetail"),
      value: "personnelDetail",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:editJunction"),
      value: "editJunction",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:qualityControlForm"),
      value: "qualityControl",
      icon: <QualityDetailIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];
  const onDeleted = async () => {
    await junctionService.deleteJunction(data?.id, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data?.id);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };
  return (
    <>
      <div className="flex w-full flex-row justify-center items-center group relative">
        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 mr-auto">
          <div
            className={`w-full border-t-4 rounded-t-[8px] ${
              data?.type == "work" ? "border-t-success-500 " : data?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
            }`}
          ></div>
          <div className="flex flex-row items-center w-full border-b">
            {data?.productType === "flex" ? (
              <>
                {data?.flexProduct?.image ? (
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-content={`
      ${data?.flexProduct?.name} 
      `}
                  >
                    <CustomImage
                      src={data?.flexProduct?.image}
                      style={{
                        width: 56,
                        height: 42,
                        minWidth: 56,
                        minHeight: 42,
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4,
                        borderRadius: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[56px] min-w-[56px] h-[42px] rounded-[4px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : (
              <>
                {data?.job?.product?.image ? (
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-content={`
      ${data?.job?.product?.name} 
      `}
                  >
                    <CustomImage
                      src={data?.job?.product?.image}
                      style={{
                        width: 56,
                        height: 42,
                        minWidth: 56,
                        minHeight: 42,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderRadius: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[56px] min-w-[56px] h-[42px] rounded-[4px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            )}

            <div className="flex flex-col items-start px-2">
              <div className="flex flex-row items-center gap-x-1">
                {data?.metadata?.qualityStatus === "waiting" ? <PieceIcon /> : <AcceptedIcon />}
                <p className="text-xs font-normal text-secondary-600">
                  {data?.metadata?.qualityStatus === "waiting"
                    ? data?.metadata?.madeWorkCount == 0
                      ? data?.madeWorkCount || "0"
                      : data?.metadata?.madeWorkCount || "0"
                    : data?.metadata?.acceptedWorkCount}
                </p>
              </div>
              <div className="flex flex-row items-center gap-x-1">
                {data?.metadata?.qualityStatus === "waiting" ? <EquCalculate /> : <RejectedIcon />}
                <p className="text-xs font-normal text-secondary-600">
                  {data?.metadata?.qualityStatus === "waiting" ? data?.metadata?.calculateWorkCount : data?.metadata?.rejectedWorkCount || "0"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start w-full pl-3 py-1 pr-[44px]">
            {data?.productType === "flex" ? (
              <p className="text-xs font-normal text-secondary-500 truncate w-[99%]">{data?.flexProduct?.stockCode || "--"}</p>
            ) : (
              <p className="text-xs font-normal text-secondary-500 truncate w-[99%]">{t("product:stock")}:{data?.job?.product?.stockCode || "--"}</p>
            )}
            <div className="flex flex-row items-center gap-x-1 w-full">
              {data?.productType === "flex" ? (
                <>
                  <FlexProductIcon />
                  <p className="text-xs font-semibold text-secondary-700 truncate  w-[99%]">{data?.flexProduct?.name || "--"}</p>
                </>
              ) : (
                <p className="text-xs font-semibold text-secondary-700 truncate  w-[99%]">{data?.job?.product?.name || "--"}</p>
              )}
            </div>
          </div>
        </div>

        <div className="cursor-pointer flex absolute  w-[50px] h-[50px] mb-6 min-w-[50px] min-h-[50px] z-[100]">
          {data?.user?.avatar ? (
            <div
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${data?.user?.name + " " + data?.user?.lastName} `}
              className="cursor-pointer"
            >
              <CustomImage
                src={data?.user?.avatar}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                  borderWidth: 2,
                  borderStyle: "solid",
                  boxShadow: "0 0 0 2px #00000008",
                }}
              />
            </div>
          ) : (
            <div
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${data?.user?.name + " " + data?.user?.lastName} `}
              className="cursor-pointer"
            >
              <NoAvatar size={48} fontSize={22} name={data?.user?.name} lastName={data?.user?.lastName} color={"#B54708"} />
            </div>
          )}
        </div>

        <div className="absolute w-[86px] min-w-[86px] mt-14 flex items-center border-[0.5px] border-secondary-300 rounded-[34px] gap-x-[2px] bg-white pl-1 pr-[2px] py-[2px]">
          {data?.metadata?.qualityStatus === "waiting" ? (
            <p className="text-xxs text ml-5 text-secondary-600 font-semibold">{t("product:applied")}</p>
          ) : (
            <>
              <p className="text-xxs font-normal text-secondary-600">{t("product:qualityNew")}</p>
              <div className="flex items-center justify-center rounded-[32px] bg-[#B54708] px-1 ml-auto w-[36px]">
                <p className="text-xxs text-white font-bold">
                  {" "}
                  %{data?.productType === "flex" ? data?.flexHeader?.qualityPercent : data?.header?.internalOperation?.qualityPercent || "0"}
                </p>
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 ml-auto">
          <div
            className={`w-full border-t-4 rounded-t-[8px] ${
              data?.type == "work" ? "border-t-success-500 " : data?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
            }`}
          ></div>
          <div className="flex flex-row items-center w-full border-b justify-end">
            <div className="flex flex-col items-start px-2">
              <div className="flex flex-row items-center gap-x-1 ml-auto">
                <p className="text-xs font-normal text-secondary-600">{moment(data?.startDate)?.format("HH:mm")}</p>
                <StartClockIcon />
              </div>
              <div className="flex flex-row items-center gap-x-1 ml-auto">
                <p className="text-xs font-normal text-secondary-600">{moment(data?.endDate)?.format("HH:mm")}</p>
                <EndClockIcon />
              </div>
            </div>
            {data?.station?.metadata?.image ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-content={`
              ${data?.station?.metadata?.brand + " " + data?.station?.metadata?.model} 
              `}
              >
                <CustomImage
                  borderRadius={2}
                  src={data?.station?.metadata?.image}
                  style={{
                    width: 56,
                    height: 42,
                    minWidth: 56,
                    minHeight: 42,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRadius: 0,
                    borderLeftWidth: 1,
                    borderRightWidth: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[56px] min-w-[56px] h-[42px] rounded-[4px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
          </div>
          <div className="flex flex-col items-end w-full pr-3 py-1 pl-[44px]">
            <p className="text-xs font-normal text-secondary-500">
              {/* {`(${data?.productType === "flex" ? data?.flexHeader?.step + data?.internalOperation?.name || "--" : data?.header?.step || "--"}.Op) ` + data?.internalOperation?.name || "--"} */}
              {data?.productType === "flex" ? `(${data?.flexHeader?.step || "--"}.Op) ` + data?.internalOperation?.name || "--" : `(${data?.header?.step || "--"}.Op) ` + data?.internalOperation?.name || "--"}
            </p>
            <p className="text-xs font-semibold text-end text-secondary-700 truncate ml-auto w-[99%]">
              {data?.type === "manual_finished_work"
                ? t("product:finishedWork")
                : data?.station?.metadata?.brand + " " + data?.station?.metadata?.model || "--"}
            </p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[6px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[32px] right-[4px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {menuOptions?.map((item, index) => {
              const formattedDate = getFormattedNavigateDate(data?.startDate, auth.user.company.dayStartHour);
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "personnelDetail") {
                      navigate(`/app/machine/digital-twin/user-detail/${data?.user?.id}/${formattedDate}`);
                    }
                    if (item.value === "equipmentDetail") {
                      navigate(`/app/machine/digital-twin/equipment-details/${data?.station?.deviceId}-${data?.station?.id}/${formattedDate}`);
                    }
                    if (item.value === "editJunction") {
                      // setIsJunction(true);
                      navigate(
                        `/app/machine/digital-twin/equipment-details/${`${data?.station?.deviceId}-${data?.station?.id}`}/${moment(data?.startDate).format("YYYY-MM-DD")}/manuel-detail/edit/${moment(data?.startDate)?.subtract(1, "hour")?.format("YYYY-MM-DDTHH:mm:ss")}+${moment(data?.endDate)?.add(1, "hour")?.format("YYYY-MM-DDTHH:mm:ss")}/event/${data?.id}`
                      );
                    }
                    if (item.value === "qualityControl") {
                      setJunctionId(data?.id);
                      setProductType(data?.productType);
                      setIsOpenForm(true);
                    }
                    if (item.value === "delete") {
                      setIsAlert(true);
                    }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                  }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
        <Tooltip className="z-[120]" id={`${data?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={data?.id}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default InternalOperationPlannedWaitingEventCard;
