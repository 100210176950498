import React, { useState, useEffect, useRef, act } from "react";

import moment from "moment";

import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import TimeRange from "react-video-timelines-slider";
import "./styles.css";
import useWindowDimensions from "../../../../../hooks/useWindowDimension";
import { timeFormatter } from "../../../../../utils/timezoneFormatter";
import { useStore } from "../../../../../hooks/useStores";
import { Button } from "../../../../../components";
import { NotFound404 } from "../../../../../assets/illustrations";
import { getStartOfDayForCompany } from "../../../../../components/upuTimeline/TimelineSlider";
import ManuelJunctionSingleEvent from "./ManuelJunctionSingleEvent";

import ManuelJunctionMultiEvent from "./ManuelJunctionMultiEvent";

const AddManuelJunctionTimeline = observer(
  ({
    startTime,
    endTime,
    refetch,
    //kontrols
    setStartTime,
    setEndTime,
    setSelectedJunction,
    selectedJunction,
    defaultStartTime,
    defaultEndTime,
    //grouped data
    groupedDatas,
    //junctionDatas
    timelineChart,
    junctionData,
    //last
    timelineValues,
    setTimelineValues,
    shiftValues,
    setShiftValues,

    date,
    //bpttom
    setIsOpen,
    setDefaultStartForm,
    setDefaultEndForm,
    defaultStartForm,
    defaultEndForm,

    //tooltip

    selectDateMode,
    setSelectDateMode,

    // time selecttt

    setSelectedDates,
    selectedDates,
    //time and break
    setNewBreak,
    newBreak,
    breakTimes,
    setBreakTimes,
    rightTab,
    workType,
  }) => {
    const { width, height } = useWindowDimensions();
    const _ = require("lodash");
    const { t } = useTranslation();
    const { auth } = useStore();
    const [emptyTimes, setEmptyTimes] = useState([]);
    const [barValues, setBarValues] = useState([]);
    const [selectedDayType, setSelectedDayType] = useState(1);
    const [selectedShift, setSelectedShift] = useState(null);
    const [showTooltip, setShowTooltip] = useState(null);
    const [mouseX, setMouseX] = useState(null);
    const totalWidth = width;

    //new Statess

    const [selectMode, setSelectMode] = useState(false);
    const [globalMouseX, setGlobalMouseX] = useState(null);

    ///////////////

    const totalWidthh = totalWidth - 160;
    let svgWidth = window.innerWidth - 160;
    let svgHeight = 125;

    const handleMouseOver = (index, xPos, yPos, e) => {
      setShowTooltip({ index, xPos, yPos });
    };

    const handleMouseMove = (e) => {
      setMouseX(e.clientX);
    };

    const handleMouseOut = () => {
      if (showTooltip) {
        setShowTooltip(null);
      }
    };
    const junctionNumber = (item) => {
      const junctions = junctionData?.filter((a) => a.status != "planned");

      let sortedJunctions = junctions?.sort((a, b) => moment(a?.startDate)?.unix() - moment(b?.startDate)?.unix());

      const datas = sortedJunctions?.map((value, index) => {
        if (value?.junctionId == item) {
          return index + 1;
        } else {
          return "";
        }
      });

      const lastData = datas?.filter((a) => a != "");

      return lastData;
    };

    const [nodeId, setNodeId] = useState(null);
    const calculateData = async (data) => {
      const datas = [];
      data?.map((value, no) => {
        if (value?.nodeId) {
          setNodeId(value?.nodeId);
        }
        value?.value.map((da, i) => {
          datas.push({
            name: value.name,
            type: da?.index == 1 ? "status" : da.index == 2 ? "shift" : da.index == 0 ? "junction" : "",
            junctionId: da?.junctionId,
            lossesId: da?.lossesId,
            actionId: da?.actionId,
            productData: da?.productName,
            operationNo: da?.operationNo,
            lossesName: da?.lossesName,
            startDate: da?.start,
            endDate: da?.end,
            timeDiff: da?.timeDiff,
            index: da?.index,
            junctionType: da?.type,
            value: [
              da?.index,
              /// moment(da.start).unix(),
              // moment(da.end).unix(),
              moment(timeFormatter(da.start, auth?.user?.company?.timeZone).formatted)?.unix(),
              moment(timeFormatter(da.end, auth?.user?.company?.timeZone).formatted)?.unix(),
              da.timeDiff,
            ],
            shiftId: da?.shiftId,
            color:
              da?.index == 1
                ? value?.itemStyle?.normal?.color
                : da?.index == 2
                  ? value?.itemStyle?.normal?.color
                  : da?.index == 0
                    ? da?.type == "setup"
                      ? "#EAAA08"
                      : da.type == "losses"
                        ? "#D92D20"
                        : "#12B76A"
                    : value?.itemStyle?.normal?.color,
          });
        });
      });

      const Valuess = datas?.sort((a, b) => a.value[1] - b.value[1]);
      const lastValues = _.uniqWith(Valuess, _.isEqual);

      let timelineDatas = Valuess?.filter((item) => {
        return item?.index != 2;
      });

      let shiftValues = lastValues?.filter((item) => {
        return item?.index == 2;
      });

      let junctionValues = lastValues?.filter((item) => {
        return item?.index == 0;
      });

      let junctionValues2 = lastValues?.filter((item) => {
        return item?.index == 0 && item.junctionType != "subEvent";
      });

      const noShiftStartDate = moment(date).format("YYYY-MM-DD") + " " + auth.user.company.dayStartHour;
      const noShiftEndDate = moment(date).add(1, "day").format("YYYY-MM-DD") + " " + auth.user.company.dayStartHour;

      const startDate = shiftValues[0]?.startDate === undefined ? noShiftStartDate : shiftValues[0]?.startDate;
      const endDate = shiftValues[shiftValues.length - 1]?.endDate === undefined ? noShiftEndDate : shiftValues[shiftValues.length - 1]?.endDate;

      const endDateJunction = timelineDatas[timelineDatas.length - 1]?.endDate;

      const emptyTimes = [];
      const startUnixTime = moment.utc(startDate).unix(); // UTC formatında start date

      const endUnixTime = moment(startDate).format("YYYY-MM-DD") != moment().format("YYYY-MM-DD") ? moment.utc(endDateJunction).unix() : moment.utc().unix(); // UTC formatında end date

      let prevEndTime = startUnixTime;

      junctionValues2.forEach((value) => {
        const startTime = value.value[1];
        const endTime = value.value[2];

        if (startTime > prevEndTime) {
          emptyTimes.push([prevEndTime, startTime]);
        }

        prevEndTime = endTime;
      });

      if (prevEndTime < endUnixTime) {
        emptyTimes.push([prevEndTime, endUnixTime]);
      }

      const sortedEmptyTimes = emptyTimes.sort((a, b) => a[0] - b[0]);

      const junctionData = sortedEmptyTimes.map(([start, end]) => ({
        actionId: undefined,
        color: "#12B7",
        endDate: moment.utc(new Date(end * 1000).toISOString()).format("YYYY-MM-DD HH:mm:ss.SSS"),
        startDate: moment.utc(new Date(start * 1000).toISOString()).format("YYYY-MM-DD HH:mm:ss.SSS"),
        index: 0,
        junctionId: start,
        junctionType: "empty",
        timeDiff: end - start,
        value: [0, start, end, end - start],
      }));

      const sorted = [...timelineDatas, ...junctionData];

      await setShiftValues(shiftValues);
      await setTimelineValues(sorted);
    };
    const calculateTotalTime = (data) => {
      const totalTimeMap = {};

      data.forEach((item) => {
        if (item?.index === 1) {
          const name = item?.name;
          const startTime = moment(item.startDate);
          const endTime = moment(item.endDate);
          const diffTime = endTime - startTime;
          const hours = diffTime / (1000 * 3600);
          const color = item?.color;

          // İlgili adın süresini ve rengini topla
          if (totalTimeMap[name]) {
            totalTimeMap[name].totalTime += hours;
          } else {
            totalTimeMap[name] = {
              totalTime: hours,
              color: color,
            };
          }
        }
      });

      const result = Object.keys(totalTimeMap).map((name) => ({
        name,
        totalTime: totalTimeMap[name].totalTime.toFixed(1) + " h",
        color: totalTimeMap[name].color,
      }));

      setBarValues(result);
    };

    let totalGreenWidth = 0;
    let totalRedWidth = 0;

    const groupWidths = {};
    let toplam = 0;


    useEffect(() => {
      calculateData(timelineChart);
    }, []);

    useEffect(() => {
      calculateTotalTime(timelineValues);
    }, [timelineValues]);

    const [invalidShiftDetected, setInvalidShiftDetected] = useState(false);
    useEffect(() => {
      const checkShifts = () => {
        const invalidShiftExists = shiftValues.some((shift) => {
          const shiftStartTime = new Date(timeFormatter(shift?.startDate)?.formatted)?.getTime();
          return isNaN(shiftStartTime) || shiftStartTime === undefined;
        });

        if (invalidShiftExists) {
          setInvalidShiftDetected(true);
        }
      };

      checkShifts();
    }, [shiftValues]);

    useEffect(() => {
      const handleMouseMoveGlobal = (e) => {
        setGlobalMouseX({ x: e.clientX, y: e.clientY });
      };

      window.addEventListener("mousemove", handleMouseMoveGlobal);

      return () => {
        window.removeEventListener("mousemove", handleMouseMoveGlobal);
      };
    }, []);

    return (
      <div
        className=" w-full flex flex-col  border border-[#D0D5DD] rounded-xl  pl-4  bg-white relative"
        style={{
          width: totalWidthh - 150,
        }}
      >
        {invalidShiftDetected ? (
          <div className="w-full h-full flex items-center justify-center min-h-[150px] flex-col py-4 gap-y-4">
            <NotFound404 width={150} height={60} />
            <p className="text-sm font-semibold">{t("signals:internetConnectionLost")}</p>
            <Button
              label={"Yeniden Dene"}
              size={"sm"}
              width={width / 7}
              colorType={"primary-machine"}
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        ) : (
          <>
            <div className="w-full max-h-[40px] min-h-[40px] relative mt-2 pr-3">
              <TimeRange
                // error={error}
                step={1}
                ticksNumber={10}
                selectedInterval={getStartOfDayForCompany(startTime, endTime)}
                timelineInterval={getStartOfDayForCompany(defaultStartTime, defaultEndTime)}
                onUpdateCallback={(interval) => {
                  "";
                }}
                onChangeCallback={(interval) => {
                  setStartTime(moment(interval[0]).valueOf());
                  setEndTime(moment(interval[1]).valueOf());
                }}
                disabledIntervals={[]}
              />
            </div>
            <div className="w-full flex flex-col  relative pr-3 mt-2">
              <svg width={totalWidth - 150} height={svgHeight}>
                <svg width={svgWidth} height="115" y={15}>
                  <g clipPath="url(#clip0_12199_84484)"></g>
                </svg>

                {shiftValues?.map((shift, index) => {
                  const { startDate, endDate, color, timeDiff } = shift;
                  const shiftStartTime = new Date(timeFormatter(shift?.startDate)?.formatted)?.getTime();
                  const shiftStartTimeDene = new Date(timeFormatter(shiftValues?.[0])?.formatted)?.getTime();

                  const shiftEndTime = new Date(timeFormatter(endDate)?.formatted)?.getTime();

                  const timeRange = endTime - startTime;

                  const shiftX = selectedShift === index ? 0 : ((shiftStartTime - startTime) / timeRange) * svgWidth;
                  const shiftWidth = selectedShift === index ? svgWidth : ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;
                  const modifiedShift = {
                    ...shift,
                    startDate: moment(shift?.startDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                    endDate: moment(shift?.endDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                    nodeId: nodeId,
                  };
                  const dateToSend = shift?.endDate || new Date();

                  return (
                    <>
                      <ManuelJunctionSingleEvent
                        startPos={shiftX}
                        width={shiftWidth}
                        onClick={() => {
                          if (rightTab == "time") {
                            setSelectedDates({
                              start: moment(shift?.startDate).add(3, "hours", 1, "seconds").format("YYYY-MM-DD HH:mm:ss"),
                              end: moment(shift?.endDate).add(3, "hours", -1, "seconds").format("YYYY-MM-DD HH:mm:ss"),
                            });
                          } else {
                            setNewBreak({
                              start: moment(shift?.startDate).add(3, "hours", 1, "seconds").format("YYYY-MM-DD HH:mm:ss"),
                              end: moment(shift?.endDate).add(3, "hours", -1, "seconds").format("YYYY-MM-DD HH:mm:ss"),
                            });
                          }
                        }}
                        event={shift}
                      />
                      {shiftWidth > 15 ? (
                        <>
                          <defs>
                            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                              <path
                                d="M-1,1 l2,-2
               M0,4 l4,-4
               M3,5 l2,-2"
                                style={{ stroke: "#000000", strokeWidth: 1 }}
                              />
                            </pattern>
                          </defs>
                          <rect
                            key={index}
                            x={shiftX}
                            y={22}
                            width={shiftWidth}
                            height={svgHeight - 10}
                            fill={shift?.color === "#FFFFFF" ? "url(#diagonalHatch)" : shift?.color}
                            opacity={0.2}
                            style={{ cursor: "pointer" }}
                            ry={"4"}
                          ></rect>
                        </>
                      ) : (
                        <>
                          <defs>
                            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                              <path
                                d="M-1,1 l2,-2
         M0,4 l4,-4
         M3,5 l2,-2"
                                style={{ stroke: "#000000", strokeWidth: 1 }}
                              />
                            </pattern>
                          </defs>
                          <rect
                            key={index}
                            x={shiftX}
                            y={22}
                            width={shiftWidth}
                            height={svgHeight - 10}
                            fill={shift?.color === "#FFFFFF" ? "url(#diagonalHatch)" : "#FFFFFF"}
                            opacity={0.2}
                            style={{ cursor: "pointer" }}
                            ry={"4"}
                          ></rect>
                        </>
                      )}
                    </>
                  );
                })}

                {timelineValues?.map((shift, index) => {
                  const { startDate, endDate, color, timeDiff } = shift;
                  const shiftStartTime = new Date(timeFormatter(startDate)?.formatted)?.getTime();
                  const shiftEndTime = new Date(timeFormatter(endDate)?.formatted)?.getTime();

                  const timeRange = endTime - startTime;

                  if (selectedShift !== null && selectedShift !== index) {
                    return null;
                  }

                  const shiftX = selectedShift === index ? 0 : ((shiftStartTime - startTime) / timeRange) * svgWidth;

                  const statusWidth = ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;

                  let yOffset = 70;
                  const modifiedShift = {
                    ...shift,
                    startDate: moment(shift?.startDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                    endDate: moment(shift?.endDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                    nodeId: nodeId,
                  }; // Shift'i kopyala ve tarihleri güncelle
                  const dateToSend = shift?.endDate || new Date();
                  return (
                    <>
                      <ManuelJunctionMultiEvent
                        key={index}
                        yOffset={yOffset}
                        startPos={shiftX}
                        width={statusWidth}
                        widthS={statusWidth}
                        modifiedShift={modifiedShift}
                        selectedJunction={selectedJunction}
                        event={shift}
                        onClick={() => { }}
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                        handleMouseMove={handleMouseMove}
                        junctionNumber={junctionNumber}
                        // selectedJunction={selectedJunction}
                        //bottom
                        setDefaultEndForm={setDefaultEndForm}
                        setDefaultStartForm={setDefaultStartForm}
                        setIsOpen={setIsOpen}
                        // time select
                        setSelectedDates={setSelectedDates}
                        selectedDates={selectedDates}
                        rightTab={rightTab}
                        setNewBreak={setNewBreak}
                        newBreak={newBreak}
                        breakTimes={breakTimes}
                        setBreakTimes={setBreakTimes}
                        //breaks end

                        //Selected Day calculate
                        shiftStartTime={shiftStartTime}
                        startTime={startTime}
                        svgWidth={svgWidth}
                        timeRange={timeRange}
                        workType={workType}

                      // breakss
                      />
                    </>
                  );
                })}
              </svg>

              {showTooltip && showTooltip.index.junctionType != "empty" && (
                <>
                  <div
                    className={`absolute z-50 bg-white    rounded-lg shadow-md  flex flex-col`}
                    style={{
                      left: (showTooltip?.xPos + 30) * 2 > totalWidthh ? showTooltip?.xPos - 280 : showTooltip?.xPos + 40,
                      top: showTooltip?.yPos - 120,
                    }}
                  >
                    <div className="w-full bg-primary-600 py-1  flex items-center text-white pl-4 gap-x-2 rounded-t-lg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.07487 13.0071C8.43942 13.219 7.75954 13.3337 7.05286 13.3337C3.5255 13.3337 0.666016 10.4743 0.666016 6.9469C0.666016 3.41955 3.5255 0.560059 7.05286 0.560059C10.5802 0.560059 13.4397 3.41955 13.4397 6.9469C13.4397 7.02765 13.4382 7.10805 13.4352 7.18807L11.9269 6.09943C10.737 5.24066 9.07487 6.09086 9.07487 7.55826V13.0071ZM7.11962 3.61072C7.48689 3.61072 7.78462 3.90845 7.78462 4.27572V7.4046C7.78462 7.6382 7.66206 7.85466 7.46176 7.97484L5.5062 9.14817C5.19127 9.33713 4.78279 9.23501 4.59383 8.92008C4.40487 8.60515 4.50699 8.19666 4.82192 8.00771L6.45462 7.02809V4.27572C6.45462 3.90845 6.75235 3.61072 7.11962 3.61072Z"
                          fill="white"
                        />
                        <path
                          d="M10.6396 8.10549C10.6396 7.78445 10.6396 7.62392 10.7066 7.53544C10.7649 7.45835 10.854 7.41065 10.9505 7.40488C11.0613 7.39827 11.1948 7.48731 11.462 7.6654L14.9382 9.98289C15.1589 10.13 15.2693 10.2036 15.3078 10.2963C15.3414 10.3774 15.3414 10.4685 15.3078 10.5496C15.2693 10.6424 15.1589 10.7159 14.9382 10.8631L11.462 13.1806C11.1948 13.3586 11.0613 13.4477 10.9505 13.4411C10.854 13.4353 10.7649 13.3876 10.7066 13.3105C10.6396 13.222 10.6396 13.0615 10.6396 12.7405V8.10549Z"
                          fill="white"
                          stroke="white"
                          strokeWidth="1.17333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <p className="text-xs font-semibold"> Select {selectedDates.start == "" || selectedDates.end != "" ? "Start" : "End"} Point </p>
                    </div>
                    <div className="flex items-center mb-1  mt-2 px-4">
                      <div
                        style={{
                          width: "12px",
                          height: "12px",
                          backgroundColor: showTooltip?.index?.color,
                          borderRadius: 2,
                        }}
                      ></div>

                      <p
                        className="text-md font-semibold"
                        style={{
                          color: showTooltip?.index?.color === "#FFFFFF" ? "black" : showTooltip?.index?.color,
                          marginLeft: "6px",
                        }}
                      >
                        {showTooltip?.index?.name}
                      </p>
                    </div>
                    <div className="flex flex-col w-full items-center justify-center px-4 mb-2">
                      <div className="flex w-full">
                        <div className="flex min-w-[110px] max-w-[110px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">
                          Start-End Time
                        </div>
                        <div className="flex min-w-[142px] max-w-[172px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
                          <span>{moment(timeFormatter(showTooltip?.index?.startDate).formatted).format("HH:mm:ss")}</span>-
                          <span>{moment(timeFormatter(showTooltip?.index?.endDate).formatted).format("HH:mm:ss")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {selectDateMode == "start" && !showTooltip && (
          <div
            style={{
              position: "absolute",
              left: globalMouseX?.x - 100,
              top: globalMouseX?.y - 110,
            }}
            className="bg-primary-600 text-white rounded-xl py-1 px-2 text-xs flex flex-col z-[9999]"
          >
            Select {selectedDates.start == "" ? "Start" : "End"} Point
          </div>
        )}
      </div>
    );
  }
);

export default AddManuelJunctionTimeline;
