import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MoveJunctionsHeader from "../../machine/Twin/components/MoveJunctionsHeader";
import Badgets from "../../../components/buttons/Badgets";
import { Button, CustomModal, Loading } from "../../../components";
import OperationJunctions from "../OperationJunctions";
import { useQuery } from "@tanstack/react-query";
import { orderService } from "../../../services/order.service";
import MoveJunctionsModal from "../../../components/modal/MoveJunctionsModal";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../hooks/useWindowDimension";

const MoveJunctionDetail = () => {
  const { moveId } = useParams();
  const { operationId } = useParams();
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const [selectedJunctions, setSelectedJunctions] = useState([]);
  const [moveJunctionsModal, setMoveJunctionsModal] = useState(false);
  const { data, refetch, isLoading, isFetching } = useQuery({
    enabled: false,
    queryKey: ["find-by-operations-move"],
    queryFn: async () =>
      await orderService.getOperationDetails(
        operationId.split("+")[1],
        operationId.split("+")[0]
      ),
    retry: 0,
  });
  const junctionIds = selectedJunctions?.map((junction) => junction.id);
  useEffect(() => {
    refetch();
  }, [operationId]);
  return (
    <div className="w-full flex flex-col h-full overflow-y-auto scrollbar-hide">
      {isLoading || isFetching ? (
        <div className="flex w-full h-full items-center justify-center">
          <Loading color={"#B54708"} secondary={"#B54708"} size={48} />
        </div>
      ) : (
        <div style={{ minHeight: height - 60 }} className="w-full h-full flex flex-col ">
          <MoveJunctionsHeader
            setIsOpen={setMoveJunctionsModal}
            isOpen={moveJunctionsModal}
          />
          <div className="flex justify-between min-h-[40px] max-h-[40px] h-10 items-center mt-4">
            <Badgets
              label={selectedJunctions.length + " " + "Junctions Selected"}
              size={"lg"}
              colorType={"fill-error"}
            />
            {/* <div className="flex items-center">
              <Button
                colorType={"primary-product"}
                label={"Select All Junctions"}
                size={"md"}
              />
            </div> */}
          </div>
          <OperationJunctions
            setSelectedJunctions={setSelectedJunctions}
            selectedJunctions={selectedJunctions}
            refetch={refetch}
            operation={data}
            data={data?.junctions ? data?.junctions : []}
            avgActualData={data?.actualDurationAvg}
            orderIds={operationId.split("+")[1]}
            junctionIds={junctionIds}
          />
        </div>
      )}
      <CustomModal
        isOpen={moveJunctionsModal}
        setIsOpen={setMoveJunctionsModal}
        onClose={() => {
          setMoveJunctionsModal(false);
        }}
        modalTitle={t("product:moveJunction")}
        width="fit-content"
        children={
          <MoveJunctionsModal
            productId={data?.productId}
            operationName={data?.operation?.internalOperation?.process?.name}
            operationId={
              data?.operation?.type == "internal"
                ? data?.operation?.internalOperation?.process?.id
                : data?.operation?.externalOperation?.process?.id
            }
            orderOperationId={data?.operation?.id}
            operationType={data?.operation?.type}
            selectedJunctions={selectedJunctions}
            setMoveJunctionsModal={setMoveJunctionsModal}
            stockCode={data?.stockCode}
          />
        }
      />
    </div>
  );
};

export default MoveJunctionDetail;
