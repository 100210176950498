 const ArrowLeftIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M15.8337 9.99984H4.16699M4.16699 9.99984L10.0003 15.8332M4.16699 9.99984L10.0003 4.1665"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  
   const ArrowRightIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M4.16699 9.99984H15.8337M15.8337 9.99984L10.0003 4.1665M15.8337 9.99984L10.0003 15.8332"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

   const CalendarIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M17.5 8.33329H2.5M13.3333 1.66663V4.99996M6.66667 1.66663V4.99996M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8211 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7334 17.5 14.3333V7.33329C17.5 5.93316 17.5 5.2331 17.2275 4.69832C16.9878 4.22791 16.6054 3.84546 16.135 3.60578C15.6002 3.33329 14.9001 3.33329 13.5 3.33329H6.5C5.09987 3.33329 4.3998 3.33329 3.86502 3.60578C3.39462 3.84546 3.01217 4.22791 2.77248 4.69832C2.5 5.2331 2.5 5.93316 2.5 7.33329V14.3333C2.5 15.7334 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8211 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z"
          stroke="#475467"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const TransformIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M14.3847 0.666504H3.61501C1.9866 0.666504 0.666504 1.9866 0.666504 3.61501V14.3847C0.666504 16.0131 1.9866 17.3332 3.61501 17.3332H14.3847C16.0131 17.3332 17.3332 16.0131 17.3332 14.3847V3.61501C17.3332 1.9866 16.0131 0.666504 14.3847 0.666504Z"
          stroke="#475467"
          strokeWidth="1.25"
          strokeMiterlimit="10"
        />
        <path
          d="M14.6114 9H3.38818"
          stroke="#475467"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M11.9482 6.05122C11.9482 6.05122 11.9101 5.78294 10.8063 4.6726C9.70241 3.56226 7.91269 3.56226 6.80883 4.6726C6.41773 5.066 6.16519 5.54599 6.05123 6.05122M11.9482 6.05122V4.15576M11.9482 6.05122H10.0638"
          stroke="#475467"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.05176 11.9488C6.05176 11.9488 6.08986 12.2171 7.19372 13.3274C8.29759 14.4377 10.0873 14.4377 11.1912 13.3274C11.5823 12.934 11.8348 12.454 11.9488 11.9488M6.05176 11.9488V13.8442M6.05176 11.9488H7.93617"
          stroke="#475467"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  export {
    ArrowLeftIcon,
    ArrowRightIcon,
    CalendarIcon,
    TransformIcon
  }