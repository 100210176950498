import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const TimeLineBarChart = ({ barChartData, totalWidth, startTime, endTime, shiftlyData }) => {
  const {t} = useTranslation()
  const barValues = barChartData?.data
    ?.filter((item) => item !== null)
    ?.map((item) => ({
      name: item?.name,
      color: item?.itemStyle?.normal?.color,
      totalTime: (item?.value[0] / 3600).toFixed(2) + " h",
      hours: item?.value[0] / 3600,
    }));

  const selectedShiftTime = ((endTime - startTime) / 3600000).toFixed(2);

  const totalShiftTime = ((new Date(shiftlyData.sort((a, b) => new Date(a?.start) - new Date(b?.start))[shiftlyData?.length - 1]?.end) - new Date(shiftlyData.sort((a, b) => new Date(a?.start) - new Date(b?.start))[0]?.start)) / 3600000).toFixed(2);

  const totalHours = barValues?.reduce((sum, bar) => sum + bar.hours, 0) || 1;

  const maxAllowedWidth = totalWidth - 160;
  const hourRatio = maxAllowedWidth / totalHours;
  const minWidth = 60;

  const filteredBarValues = barValues?.filter((bar) => bar?.totalTime !== "0.00 h");

  const totalWidths = filteredBarValues?.reduce((acc, bar) => {
    let width = bar.hours * hourRatio;
    width = Math.max(width, minWidth);
    return acc + width;
  }, 0);

  return (
    <>
      <div className="flex w-full items-center justify-end mt-5 text-[#475467] font-normal text-sm pr-3 gap-x-1 mb-1">
        {t("product:totalShiftTime")} : <p className="text-[#344054] font-semibold">{totalShiftTime} {t("product:hours")}</p>
        <span className="text-[#475467] font-normal text-sm">|</span>
        {t("product:selectedTime")} : <p className="text-[#344054] font-semibold">{selectedShiftTime} {t("product:hours")}</p>
      </div>

      {filteredBarValues !== undefined && (
        <div
          style={{ maxWidth: totalWidths, minWidth: totalWidths }}
          className={`flex min-h-[22px] max-h-[22px] justify-center mr-2 -ml-2 border rounded-lg border-[#D0D5DD]`}
        >
          {filteredBarValues?.map((bar, index) => {
            let width = bar.hours * hourRatio;
            width = Math.max(width, minWidth);

            return (
              <svg
                key={bar?.name}
                width={width}
                height={20}
                style={{
                  backgroundColor: bar?.color,
                  borderTopLeftRadius: index === 0 ? 8 : "",
                  borderBottomLeftRadius: index === 0 ? 8 : "",
                  borderTopRightRadius: index === filteredBarValues.length - 1 ? 8 : "",
                  borderBottomRightRadius: index === filteredBarValues.length - 1 ? 8 : "",
                }}
              >
                <text
                  x={width / 2}
                  y="16"
                  fill="#FFFFFF"
                  style={{
                    lineHeight: "20px",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter",
                    textAnchor: "middle",
                  }}
                >
                  {bar?.totalTime}
                </text>
              </svg>
            );
          })}
        </div>
      )}
      {filteredBarValues !== undefined && (
        <div className="flex items-center gap-x-6 justify-center w-full min-h-[21px] max-h-[21px] mb-4 mt-2">
          {filteredBarValues?.map((bar, index) => {
            return (
              <div
                data-tooltip-id={`${bar?.name}`}
                data-tooltip-place={"bottom"}
                data-tooltip-content={`${bar?.name} `}
                key={index}
                className="flex gap-x-1 items-center justify-center"
              >
                <span
                  style={{ backgroundColor: bar?.color, border: bar?.color === "#FFFFFF" ? "1px solid #D0D5DD" : "" }}
                  className="min-w-[12px] max-w-[12px] min-h-[12px] max-h-[12px] rounded-sm"
                ></span>
                <span className="text-[#475467] font-normal text-sm whitespace-nowrap">
                  {bar?.name?.length > 11 ? bar?.name?.slice(0, 11) + "..." : bar?.name}
                </span>
                <span className="flex gap-x-1 text-[#344054] font-semibold text-sm whitespace-nowrap">{bar?.totalTime}</span>
                <Tooltip id={`${bar?.name}`} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TimeLineBarChart;
