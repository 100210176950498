import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LossSelectCard from "../../Card/LossSelectCard";
import LossTimeCard from "../../Card/LossTimeCard";
import { reportService } from "../../../../services/report.service";
import { CcountIcon } from "../../../../assets/icons/leftMenuIcons";
import { KwhIcon } from "../../../../assets/icons/lossIcon";

const EquipmentCCount = observer(
  ({ selectedNode, energyData, xAsisData, month }) => {
    const { t } = useTranslation();

    const option = {
      title: {
        text: selectedNode?.name,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },

      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      yAxis: {
        position: "right",
        type: "value",
        boundaryGap: [0, 0.01],
      },
      xAxis: {
        type: "category",
        data: xAsisData,
      },
      series: [
        {
          name: "Total kVAh",
          type: "bar",
          data: energyData?.map((row) => row?.total_kwh),
        },
        {
          name: "Total C-Count",
          type: "bar",
          data: energyData?.map((row) => row?.total_cCount),
        },
      ],
    };

    const Tabs = [
      {
        name: "Elektrik (kVAh)",
        icon: <KwhIcon width={32} height={32} />,
        color: "#B692F6",
        bgColor: "#B692F6",
        key: "total_kwh",
      },

      {
        name: "C-Count",
        icon: <CcountIcon width={32} height={32} />,
        bgColor: "#F79009",
        color: "#F79009",
        key: "total_cCount",
      },
    ];

    return (
      <div className="w-full">
        <div className="flex items-center gap-x-5 my-5">
          {Tabs.map((tab, index) => {
            return (
              <LossTimeCard
                name={tab?.name}
                icon={tab?.icon}
                index={index}
                value={
                  energyData?.find((item) => {
                    return item?.month == moment(month).format("M");
                  })?.[tab?.key]
                }
                color={tab?.color}
                //   onClick={() => {
                //     setSelectedCategory(tab?.key);
                //   }}
                selected={tab?.key}
                //   selectedCategory={selectedCategory}
                bgColor={tab?.bgColor}
              />
            );
          })}
        </div>
        <ReactECharts
          // onEvents={onEvents}
          option={option}
          style={{
            width: "100%",
          }}
        />
      </div>
    );
  }
);

export default EquipmentCCount;
