import React, { useState } from "react";
import { productService } from "../../../services/product.service";
import { useQuery } from "@tanstack/react-query";
import generateFileUrl from "../../../utils/generateFileUrl";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import SemiProductCard from "../cards/SemiProductCard";
import { useTranslation } from "react-i18next";
import RawMaterialRecipeCard from "../cards/RawMaterialRecipeCard";

const RecipeIncreaseDecreaseModal = ({ pageData }) => {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState("all");

  const { data, refetch, isLoading, isFetching } = useQuery({
    retry: 0,
    queryKey: ["recipeIncreaseDecreaseModal"],
    queryFn: async () =>
      await productService.getWipTransaction(
        pageData?.operations?.[0]?.type,
        pageData?.operations?.[0]?.internalOperationId,
        pageData?.operations?.[0]?.operationId,
        pageData?.orderId
      ),
  });

  const headerData = [
    { name: t("product:allNew"), value: "all" },
    { name: t("product:productss"), value: "product" },
    { name: t("product:rawMaterial"), value: "raw-material" },
    { name: t("product:scrap"), value: "scrap" },
    { name: t("product:tooling"), value: "tool" },
    { name: t("product:consumables"), value: "consumable" },
    { name: t("product:component"), value: "component" },
    { name: t("product:other"), value: "other" },
  ];

  const filteredData =
    selectedFilter === "all"
      ? data?.data?.total
      : selectedFilter === "product"
      ? data?.data?.product
      : selectedFilter === "raw-material"
      ? data?.data?.rawMaterialRecord
      : [];
  const groupedData = {
    income: filteredData?.filter((item) => item?.type === "income"),
    outcome: filteredData?.filter((item) => item?.type === "outcome"),
  };

  const sortedIncome = groupedData?.income?.sort((a, b) => a?.junction?.id?.localeCompare(b?.junction?.id));
  const sortedOutcome = groupedData?.outcome?.sort((a, b) => a?.junction?.id?.localeCompare(b?.junction?.id));

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className="flex flex-col w-full gap-y-6 pt-6">
          <div className="flex w-full gap-x-2">
            {data?.data?.operationInfo?.product?.image ? (
              <img
                src={generateFileUrl(data?.data?.operationInfo?.product?.image || "")}
                className="w-[64px] min-w-[64px] h-[48px] rounded border border-secondary-300 "
              />
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded border border-secondary-300 shadow-sm bg-secondar-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start justify-center w-full">
              <p className="flex ml-1 text-md font-semibold text-[#101828]">{data?.data?.operationInfo?.product?.name || "Name"}</p>
              <span className="flex px-1 py-[3px] bg-[#FFFAEB]">
                <p className="text-[#B54708] font-medium text-xs">
                  {t("product:stock")}: {data?.data?.operationInfo?.product?.code} {data?.data?.operationInfo?.step}.Op.{" "}
                  {data?.data?.operationInfo?.process?.code && <span>({data?.data?.operationInfo?.process?.code})</span>}
                </p>
              </span>
            </div>
          </div>
          <div className="flex w-full border border-[#D0D5DD] rounded-lg min-h-10 h-10 max-h-10">
            {headerData.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`flex w-full border-r last:border-r-0 border-[#D0D5DD] items-center justify-center px-4 py-2 cursor-pointer first:rounded-l-lg last:rounded-r-lg ${
                    item?.value === selectedFilter ? "bg-[#F2F4F7]" : ""
                  }`}
                  onClick={() => {
                    if (index === 0 || index === 1 || index === 2) {
                      handleFilterChange(item?.value);
                    }
                  }}
                >
                  <p className="text-[#344054] text-sm font-semibold truncate">{item?.name}</p>
                </div>
              );
            })}
          </div>
          <div className="flex w-full h-[410px] gap-x-4">
            <div className="flex flex-col w-[calc(50%-8px)] h-full rounded-lg border border-[#E4E7EC]">
              <div className="flex w-full border-b px-[6px] py-1 items-center justify-center bg-[#F9FAFB] rounded-t-lg">
                <p className="text-[#344054] font-medium text-xs">{t("product:decrease")}</p>
              </div>
              <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide">
                {sortedOutcome?.map((item, index) => {
                  return (
                    <div className="flex w-full min-h-[128px] max-h-[128px] h-[128px] border-b border-[#E4E7EC]">
                      <div className="flex flex-col min-w-[82px] max-w-[82px] w-[82px] border-r border-[#E4E7EC]">
                        <div className="flex gap-x-1 w-full h-1/2 border-b border-[#E4E7EC] items-center justify-center text-[#D92D20] font-medium text-sm">
                          <p>x</p>
                          <p>{item?.rawMaterial ? data?.data?.operationInfo?.product?.quantityUsedPerPiece : "1"}</p>
                        </div>
                        <div className="flex flex-col h-1/2 items-center justify-center text-[#344054] font-medium text-sm">
                          <p>{item?.quantity.toFixed(2) || "0"}</p>
                          <p>{item?.rawMaterial ? item?.rawMaterial?.unitType : "adet"}</p>
                        </div>
                      </div>
                      <div className="flex w-full h-full px-3 py-2">
                        {item?.rawMaterial && <RawMaterialRecipeCard item={item} />}
                        {item?.internalOperation && <SemiProductCard item={item?.internalOperation} />}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col w-[calc(50%-8px)] h-full rounded-lg border border-[#E4E7EC]">
              <div className="flex w-full border-b px-[6px] py-1 items-center justify-center bg-[#F9FAFB] rounded-t-lg">
                <p className="text-[#344054] font-medium text-xs">{t("product:increase")}</p>
              </div>
              <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide">
                {sortedIncome?.map((item, index) => {
                  return (
                    <div className="flex w-full min-h-[128px] max-h-[128px] h-[128px] border-b border-[#E4E7EC]">
                      <div className="flex flex-col min-w-[82px] max-w-[82px] w-[82px] border-r border-[#E4E7EC]">
                        <div className="flex gap-x-1 w-full h-1/2 border-b border-[#E4E7EC] items-center justify-center text-[#079455] font-medium text-sm">
                          <p>x</p>
                          <p>{item?.rawMaterial ? data?.data?.operationInfo?.product?.quantityUsedPerPiece : "1"}</p>
                        </div>
                        <div className="flex flex-col w-full h-1/2 items-center justify-center text-[#344054] font-medium text-sm">
                          <p>{item?.quantity.toFixed(2) || "0"}</p>
                          <p>{item?.rawMaterial ? item?.rawMaterial?.unitType : "adet"}</p>
                        </div>
                      </div>
                      <div className="flex w-full h-full px-3 py-2">
                        {item?.rawMaterial && <RawMaterialRecipeCard item={item} />}
                        {item?.internalOperation && <SemiProductCard item={item?.internalOperation} />}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RecipeIncreaseDecreaseModal;
