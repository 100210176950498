import React, { useEffect, useRef, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Language } from "../assets/icons/stepsIcons";
import { Button } from "../components";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { HumburgerIcon } from "../assets/icons/leftMenuIcons";
import { useStore } from "../hooks/useStores";
import { useMediaQuery } from "react-responsive";
import { ArrowLeftIcon, ArrowRightIcon } from "../assets/icons/PersonIcons";

const Settings = () => {
  const { settings } = useStore();
  const { t } = useTranslation();
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState("en");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const navigate = useNavigate();
  const menuRef = useRef();
  const isXS = useMediaQuery({ maxWidth: 575 });

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setVisibleLanguage(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    setSelectLanguage(i18next.language);
  }, [i18next.language]);

  const language = [
    {
      name: "TR",
      value: "tr",
      // icon: <TurkishFlag />,
    },
    {
      name: "EN",
      value: "en",
      // icon: <BritainFlag />,
    },
  ];

  const handleLanguageSelect = (value) => {
    setSelectLanguage(value);
    setVisibleLanguage(false);
    i18next.changeLanguage(value);
  };

  const Tabs = [
    {
      name: t("settingTab:profileAndPassword"),
      path: "profile",
    },
    {
      name: t("settingTab:company"),
      path: "company",
    },
    {
      name: t("settingTab:departments"),
      path: "department",
    },
    {
      name: t("settingTab:internalOperation"),
      path: "internal-operation",
    },
    {
      name: t("settingTab:externalOperations"),
      path: "external-operation",
    },
    {
      name: t("settingTab:equipments"),
      path: "equipments",
    },
    {
      name: t("settingTab:shiftManagement"),
      path: "shift",
    },
    {
      name: "KPI",
      path: "kpi",
    },
  ];

  const navigateToTab = (index) => {
    if (index >= 0 && index < Tabs.length) {
      setActiveTabIndex(index);
      navigate(`/app/settings/${Tabs[index].path}`);
    }
  };

  return (
    <div className="w-full max-w-full flex flex-col overflow-hidden">
      <div ref={menuRef} className="flex flex-row items-center w-full">
        <span className="mr-3 md:flex sm:flex xs:flex hidden">
          <Button
            colorType={"tertiary-gray"}
            iconLeft={<HumburgerIcon />}
            size={"md"}
            onClick={() => settings.setIsActive(true)}
          />
        </span>
        <p className="text-displaySm font-medium text-secondary-900">
          {t("routes:settings")}
        </p>
        <div className="ml-auto">
          <Button
            colorType={"primary-machine"}
            iconLeft={<Language color={"white"} width={24} height={24}/>}
            size={"md"}
            onClick={() => {
              setVisibleLanguage(!visibleLanguage);
            }}
          />
        </div>
        {visibleLanguage && (
          <div className="absolute w-[100px] p-1 bg-white right-[60px] top-[72px] border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1 z-50">
            {language?.map((item, index) => {
              return (
                <div
                  onClick={() => handleLanguageSelect(item.value)}
                  className="flex flex-row items-center gap-2"
                >
                  <div className="w-4 h-4 rounded-full border border-secondary-300 flex items-center justify-center">
                    {selectLanguage === item.value && (
                      <div className="w-2 h-2 rounded-full bg-[#7F56D9]"></div>
                    )}
                  </div>
                  <p className="text-secondary-900 font-medium text-md">
                    {item?.name}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* Responsive rendering for tabs */}
      {isXS ? (
        <div className="mt-6 w-full border-b border-b-secondary-200">
          <nav className="flex" aria-label="Tabs">
            <Button
              colorType={"tertiary-gray"}
              iconLeft={<ArrowLeftIcon />}
              onClick={() => navigateToTab(activeTabIndex - 1)}
              disabled={activeTabIndex === 0}
            />
            {Tabs?.map((tab, index) => (
              <NavLink
                key={tab?.name}
                to={tab?.path}
                className={({ isActive }) => {
                  if (isActive)
                    return classNames(
                      "border-b-[2px] w-full text-center justify-center border-b-primary-600 text-primary-700 px-3 py-2 font-semibold text-sm  dark:bg-[#7F56D9] dark:text-[#FFFFFF]"
                    );
                  else {
                    return classNames("hidden");
                  }
                }}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab?.name}
              </NavLink>
            ))}
            <Button
              colorType={"tertiary-gray"}
              iconLeft={<ArrowRightIcon />}
              onClick={() => navigateToTab(activeTabIndex + 1)}
              disabled={activeTabIndex === 6}
            />
          </nav>
        </div>
      ) : (
        // for other devices
        <div className="mt-6 w-full border-b border-b-secondary-200">
          <nav className="flex" aria-label="Tabs">
            {Tabs?.map((tab) => (
              <NavLink
                key={tab?.name}
                to={tab?.path}
                className={({ isActive }) => {
                  if (isActive)
                    return classNames(
                      "border-b-[2px] border-b-primary-600 text-primary-700 px-3 py-2 font-semibold text-sm  dark:bg-[#7F56D9] dark:text-[#FFFFFF]"
                    );
                  else {
                    return classNames(
                      "text-secondary-500 hover:text-primary-700 px-3 py-2 font-semibold text-sm rounded-md dark:text-[#FAFAFA] dark:hover:bg-[#292929]"
                    );
                  }
                }}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab?.name}
              </NavLink>
            ))}
          </nav>
        </div>
      )}
      <div className="mt-8 w-full h-full overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;
