import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Button, Input } from "../../../components";
import EndDatePicker from "../../person/components/EndDatePicker";
import moment from "moment";
import { CalendarIcon } from "../../../assets/icons/PersonIcons";
import TextArea from "../../../components/inputs/TextArea";
import { Toast } from "../../../utils/toastify/toast";
import NewSupplyCard from "../../../components/junction/NewSupplyCard";
import PersonnelAndEquipmentSelector from "../../../components/inputs/PersonnelAndEquipmentSelector";
import { purchaseService } from "../../../services/purchase.service";
import { instance } from "../../../libs/client";

const PlanPurchaseJunctionModal = ({
  selectedJunction,
  setIsOpen,
  supplierId,
  refetch,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(moment(new Date()));
  const [visible, setVisible] = useState(false);
  const [suppliers, setSuppliers] = useState([]);

  const handleSupplier = async () => {
    await instance.get("purchase").then((res) => {
      setSuppliers(
        res.data?.map((item) => {
          return { label: item?.name, value: item?.id, image: item?.image };
        })
      );
    });
  };

  const schema = yup.object({
    unitCost: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    description: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    orderedUnit: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    supplier: yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (value) => {
    const data = {
      ...value,
      requestId: selectedJunction?.id,
      estimatedDeliveryDate: date,
      type: "planned",
      ...(supplierId && { supplierId }), // Koşullu olarak supplierId ekle
    };
    await purchaseService.createPlannedPurchaseJunction(data).then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsOpen(false);
        if (!supplierId) {
          handleSupplier();
          refetch();
        } else {
          refetch();
        }
      } else {
        Toast("error", "Error");
      }
    });
  };

  useEffect(() => {
    if (!supplierId) {
      handleSupplier();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-[500px] flex flex-col">
      <p className="font-normal text-sm text-secondary-600 mt-1">
        {t("product:addTheSupplier")}
      </p>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />
      <div className="flex w-full h-full mt-4 gap-x-3">
        <div className="flex flex-col h-full gap-y-3 min-w-[300px] max-w-[300px]">
          <NewSupplyCard
            id={selectedJunction?.id}
            acceptedUnit={selectedJunction?.acceptedUnit}
            orderedUnit={selectedJunction?.orderedUnit}
            status={selectedJunction?.status}
            rawMaterialImage={selectedJunction?.rawMaterial?.image}
            rawMaterialName={selectedJunction?.rawMaterial?.name}
            stockId={selectedJunction?.rawMaterial?.stockCode}
            unit={selectedJunction?.rawMaterial?.unit}
          />
        </div>
      </div>
      <div className="flex flex-col mt-5 w-full gap-y-4">
        <hr className="bg-secondary-200 w-full h-px" />
        <div className="flex gap-x-3">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:price")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.unitCost ? "error" : ""}
                errorMessage={errors?.unitCost ? errors?.unitCost?.message : ""}
              />
            )}
            name="unitCost"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:quantitys")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.orderedUnit ? "error" : ""}
                errorMessage={
                  errors?.orderedUnit ? errors?.orderedUnit?.message : ""
                }
              />
            )}
            name="orderedUnit"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-medium text-secondary-700">
            {t("product:estimatedDeliveryDate")}
          </p>
          <EndDatePicker
            date={date}
            visible={visible}
            setDate={(e) => {
              setDate(e);
            }}
            setVisible={setVisible}
            buttonClassName={"flex flex-col items-center"}
            calendarClassName={
              "absolute bottom-12 rounded-lg shadow-xl z-[600]"
            }
          >
            <Button
              onClick={() => {
                setVisible(!visible);
              }}
              iconLeft={<CalendarIcon />}
              size={"md"}
              colorType={"secondary-gray"}
              label={moment(date).format("DD.MM.YYYY")}
            />
          </EndDatePicker>
        </div>
        {!supplierId && (
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <PersonnelAndEquipmentSelector
                value={value}
                icon={"machine"}
                items={suppliers}
                theme={"product"}
                label={t("product:supplier")}
                onChange={onChange}
                onBlur={onBlur}
                validate={errors?.supplier ? "error" : ""}
                errorMessage={errors?.supplier ? errors?.supplier?.message : ""}
              />
            )}
            name="supplierId"
          />
        )}
        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                value={value}
                theme={"product"}
                label={t("product:description")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.description ? "error" : ""}
                errorMessage={
                  errors?.description ? errors?.description?.message : ""
                }
              />
            )}
            name="description"
          />
        </div>
      </div>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />
      <div className="w-[100%] flex items-center  gap-3 mt-4">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          type={"button"}
          onClick={() => setIsOpen(false)}
        />
        <Button
          type={"submit"}
          label={t("buttons:starts")}
          colorType={"primary-product"}
          size={"md"}
        />
      </div>
    </form>
  );
};

export default PlanPurchaseJunctionModal;
