import React, { memo, useEffect, useRef, useState } from "react";
import Button from "../../../components/buttons/Button";
import { AttachmentIcon, SendIcon } from "../../../assets/icons/PersonIcons";
import { useTranslation } from "react-i18next";

const TextField = ({ sendMessage }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <div className="w-full flex items-center justify-between gap-x-3">
      <div className="w-[44px]">
        <Button
          size={"md"}
          iconLeft={<AttachmentIcon />}
          colorType={"secondary-gray"}
        />
      </div>
      <textarea
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            if (message) {
              sendMessage(message);
              setMessage("");
            }
          }
        }}
        rows={message.length / 50 + 1}
        value={message}
        onChange={({ target }) => setMessage(target.value)}
        type="text"
        className=" min-h-[44px] max-h-[96px] w-full rounded-lg px-4  py-2 flex ring-0 focus:ring-0 active:ring-0 focus:outline-0 text-base font-normal border border-gray-300 text-secondary-600"
        placeholder={t("chat:message")}
      />
      <div className="w-[42px]">
        <Button
          onClick={() => {
            if (message) {
              sendMessage(message);
              setMessage("");
            }
          }}
          size={"md"}
          iconLeft={<SendIcon />}
          colorType={"primary-person"}
        />
      </div>
    </div>
  );
};

export default memo(TextField);
