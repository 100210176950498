import React, { useRef, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components";

const TechnicalDrawingModal = ({ product }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("original");
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const handleWheel = (e) => {
    e.preventDefault();
    const newScale = scale + e.deltaY * -0.005;
    setScale(Math.min(Math.max(newScale, 1), 5));
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({ x: e.clientX - startPosition.x, y: e.clientY - startPosition.y });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleDoubleClick = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };
  const handlePrint = () => {
    const url = generateFileUrl(activeTab === "original" ? product?.technicalDrawing : product?.technicalDrawingCrop);

    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>${product?.name}</title>
            <style>
              body {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
              }
              img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
              }
            </style>
          </head>
          <body>
            <img src="${url}" alt="${product?.name || "Image"}" />
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    }
  };

  return (
    <div className="flex flex-col w-full h-full relative bg-white pt-4 gap-y-3">
      <div className="flex justify-between items-center w-full px-4">
        <div className="flex min-h-10 max-h-10 h-10 border border-[#D0D5DD] w-fit rounded-lg text-[#344054] font-semibold text-sm">
          <span
            onClick={() => setActiveTab("original")}
            className={`flex px-4 py-2 items-center justify-center border-r border-[#D0D5DD] rounded-l-lg cursor-pointer ${
              activeTab === "original" ? "bg-[#F2F4F7]" : "bg-white"
            }`}
          >
            {t("product:original")}
          </span>
          <span
            onClick={() => setActiveTab("cropped")}
            className={`flex px-4 py-2 items-center justify-center rounded-r-lg cursor-pointer ${activeTab === "cropped" ? "bg-[#F2F4F7]" : "bg-white"}`}
          >
            {t("product:cropped")}
          </span>
        </div>
        <div className="flex gap-x-2">
          <Button colorType={"primary-product"} size={"sm"} onClick={handlePrint} label={"Print"} />
        </div>
      </div>
      <div
        className="flex w-full h-full items-center justify-center overflow-hidden relative"
        onWheel={handleWheel}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onDoubleClick={handleDoubleClick}
      >
        <img
          className="object-contain"
          style={{
            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            transition: isDragging ? "none" : "transform 0.2s ease-out",
            cursor: isDragging ? "grabbing" : "grab",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          src={generateFileUrl(activeTab === "original" ? product?.technicalDrawing : product?.technicalDrawingCrop)}
          alt={product?.name}
        />
      </div>
    </div>
  );
};

export default TechnicalDrawingModal;
