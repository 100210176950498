import React, { useEffect, useState } from "react";
import Input from "../inputs/Input";
import Button from "../buttons/Button";
import { junctionService } from "../../services/junction.service";
import Loading from "../loading/Loading";
import { useTranslation } from "react-i18next";
import { Toast } from "../../utils/toastify/toast";
import { flexJunctionService } from "../../services/flex-junction.service";

const FinishJunctionModal = ({
  junctionId,
  type,
  closeModal,
  handleData,
  modalTheme,
  productType,
}) => {
  const [finishedPiece, setFinishedPiece] = useState("");
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [change, setChange] = useState(type === "external" ? true : false);
  const [loading, setLoading] = useState(false);

  const getJunctionPiece = async () => {
    if (type === "internal") {
      if (junctionId) {
        setLoading(true);
        const { data } = await junctionService.getJunctionPiece(junctionId);
        setData(data);
        setFinishedPiece(data);
        setLoading(false);
      }
    }
  };

  async function finishJunction() {
    if (productType === "flex") {
      await flexJunctionService
        .finishFlexJunction(junctionId, {
          piece: Number(finishedPiece),
          calculateWorkCount: data,
        })
        .then((res) => {
          if (res?.data?.code === 0) {
            Toast("success", res?.data?.message);
            closeModal();
            handleData();
          } else {
            Toast("error", res?.data?.message);
          }
        });
    } else {
      await junctionService
        .finishJunction(junctionId, {
          piece: Number(finishedPiece),
          calculateWorkCount: data,
        })
        .then((res) => {
          if (res?.data?.code === 0) {
            Toast("success", res?.data?.message);
            closeModal();
            handleData();
          } else {
            Toast("error", res?.data?.message);
          }
        });
    }
  }

  useEffect(() => {
    if (type !== "external") {
      getJunctionPiece();
    }
  }, []);

  return (
    <div className="w-[375px] flex flex-col gap-4 pt-5">
      {loading ? (
        <div className="w-full h-[100px] flex items-center justify-center">
          <Loading color={"#B54708"} secondary={"#B54708"} size={36} />
        </div>
      ) : (
        <>
          {!change ? (
            <>
              {data > 0 ? (
                <div className="flex flex-col">
                  <p className="text-secondary-800">
                    {t("product:numberOfProduct")} <b>{data}</b>{" "}
                    {t("product:pieceConfirm")}
                  </p>
                  <div className="flex gap-4 mt-4">
                    <Button
                      onClick={finishJunction}
                      colorType={"secondary-gray"}
                      size={"md"}
                      label={t("buttons:approve")}
                    />
                    <Button
                      onClick={() => setChange(true)}
                      colorType={
                        modalTheme === "machine"
                          ? "primary-machine"
                          : "primary-product"
                      }
                      size={"md"}
                      label={t("buttons:change")}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-4">
                  <Input
                    label={t("product:pieceModal")}
                    value={finishedPiece}
                    theme={modalTheme === "machine" ? "machine" : "product"}
                    onChange={(e) => {
                      setFinishedPiece(e.target.value);
                    }}
                  />
                  <Button
                    onClick={finishJunction}
                    colorType={
                      modalTheme === "machine"
                        ? "primary-machine"
                        : "primary-product"
                    }
                    size={"md"}
                    label={t("buttons:finish")}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <Input
                label={t("product:pieceModal")}
                value={finishedPiece}
                theme={modalTheme === "machine" ? "machine" : "product"}
                onChange={(e) => {
                  setFinishedPiece(e.target.value);
                }}
              />
              <Button
                onClick={finishJunction}
                colorType={
                  modalTheme === "machine"
                    ? "primary-machine"
                    : "primary-product"
                }
                size={"md"}
                label={t("buttons:finish")}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FinishJunctionModal;
