import React, { useEffect, useRef, useState } from "react";
import Editor from "./Editor";
import { Map } from "immutable";
import { Provider } from "react-redux";
import { Models as PlannerModels, reducer as PlannerReducer, ReactPlanner, Plugins as PlannerPlugins, fitToViewer } from "react-planner";
import MyCatalog from "./Twin/catalog/mycatalog";
import { equipmentService, mapService } from "../../services";
import { configureStore } from "@reduxjs/toolkit";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../utils/toastify/toast";
import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/useStores";
import generateFileUrl from "../../utils/generateFileUrl";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "./embeddedServer/svg/EmbeddedServerIcons";
import { useTranslation } from "react-i18next";
let AppState = Map({
  "react-planner": new PlannerModels.State(),
});

let reducer = (state, action) => {
  state = state || AppState;
  state = state.update("react-planner", (plannerState) => PlannerReducer(plannerState, action));
  return state;
};

let store = configureStore({
  reducer: reducer,
  middleware: [],
});

let plugins = [PlannerPlugins.Keyboard(), PlannerPlugins.Autosave("react-planner_v0"), PlannerPlugins.ConsoleDebugger()];

const MapEditor = observer(() => {
  const { t } = useTranslation();
  const [functionLoading, setFunctionLoading] = useState(false);
  const menuRef = useRef(null);
  const ref = useRef(null);
  const { map } = useStore();
  const [maps, setMaps] = useState([]);
  const [selectedMap, setSelectedMap] = useState(0);
  const [lastData, setLastData] = useState([]);

  const { isLoading, data, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["settingsMapData"],
    queryFn: async () => await mapService.loaderMap(),
  });
  const navigate = useNavigate();
  const width = menuRef.current?.clientWidth || window.innerWidth / 1.6;
  const height = menuRef.current?.clientHeight || window.innerHeight / 1.2;
  const handleMaps = async () => {
    let location = "";
    ref?.current?.selector?.props.projectActions.newProject();
    if (!data || !data?.settings || data?.settings?.length <= 0 || data?.settings?.[0] === null) {
      setMaps([
        {
          name: "Alan 1",
          mapSettings: ref?.current?.store?.getState("scene").toJS()["react-planner"].scene,
        },
      ]);
    } else {
      setMaps(data?.settings);
      location = data?.settings[0]?.location || "";
      ref?.current?.selector?.props?.projectActions.loadProject(data?.settings[0]?.mapSettings);
    }
    setSelectedMap(0);
    if (location) {
      ref?.current?.selector?.props?.viewer2DActions?.updateCameraView(location);
    } else {
      const newValues = fitToViewer({
        SVGHeight: data?.settings?.mapSettings?.height || 2000,
        SVGWidth: data?.settings?.mapSettings?.width || 3000,
        viewerHeight: height - 300,
        viewerWidth: width / 2,
      });
      ref?.current?.selector?.props?.viewer2DActions?.updateCameraView({
        ...newValues,
        mode: "MODE_IDLE",
        e: 0,
        f: 0,
      });
    }
    data?.type == "default" ? map.setDefault(true) : map.setDefault(false);
  };

  const saveCurrentLocation = async () => {
    let newMaps = maps;
    newMaps[selectedMap] = {
      name: newMaps[selectedMap].name,
      mapSettings: ref?.current?.store.getState("scene").toJS()["react-planner"].scene,
    };
    let roles = [];
    if (newMaps.length <= 0) {
      roles = [
        {
          name: "Alan 1",
          location: ref?.current?.selector.props.state.toJS()["react-planner"].viewer2D,
          mapSettings: ref?.current?.store.getState("scene").toJS()["react-planner"].scene,
        },
      ];
    } else {
      roles = newMaps.map((d) => {
        return {
          ...d,
          location: ref?.current?.selector.props.state.toJS()["react-planner"].viewer2D,
        };
      });
    }
    await mapService.saveMaps({ roles });
  };

  const saveMap = async () => {
    setFunctionLoading(true);
    ref.current.selector.props.projectActions.unselectAll();
    let newMaps = maps;
    newMaps[selectedMap] = {
      name: newMaps[selectedMap]?.name,
      mapSettings: ref.current.store.getState("scene").toJS()["react-planner"].scene,
    };
    let roles = [];
    const filteredMaps = newMaps?.filter((area) => {
      const items = area?.mapSettings?.layers?.['layer-1']?.items;
      return items && Object.keys(items)?.length > 0;
    })
    if (newMaps.length <= 0) {
      roles = [
        {
          name: "Alan 1",
          location: ref?.current?.selector.props.state.toJS()["react-planner"].viewer2D,
          mapSettings: ref?.current?.store.getState("scene").toJS()["react-planner"].scene,
        },
      ];
    } else {
      roles = filteredMaps.map((d) => {
        return {
          ...d,
          location: ref?.current?.selector.props.state.toJS()["react-planner"].viewer2D,
        };
      });
    }
    await saveCurrentLocation();
    await mapService.saveMaps({ roles }).then((response) => {
      if (response) {
        setFunctionLoading(false);
        navigate("/app/machine/digital-twin");
        if (filteredMaps?.length === newMaps?.length ) {
          Toast("success", t("embedded:mapSaved"));
        } else {
          Toast("success", t("embedded:mapSevedEmptyArea"));
        }
      }
    });
  };

  const addNewFloor = () => {
    const newMaps = maps;
    newMaps[selectedMap] = {
      name: newMaps[selectedMap].name,
      mapSettings: ref?.current?.store.getState("scene").toJS()["react-planner"].scene,
    };
    ref.current.selector.props.projectActions.newProject();
    newMaps.push({
      name: "Alan " + (parseInt(maps?.length) + 1),
      mapSettings: ref?.current?.store.getState("scene").toJS()["react-planner"].scene,
    });
    setSelectedMap(newMaps.length - 1);
    setMaps(newMaps);
  };

  const selectTool = async (name, item) => {
    if (name === "workCard" || name === "text" || name === "round column") {
      ref.current.selector.props.itemsActions.selectToolDrawingItem(name);
      if (name === "workCard") {
        setTimeout(() => {
          ref.current.selector.props.projectActions.setProperties({
            id: item?.id,
            equipmentBrand: item?.brand,
            equipmentModel: item?.model,
            processName: item?.process?.name,
            equipmentImage: generateFileUrl(item?.image),
            secondPersonName: item?.code,
          });
        }, 1000);
      }
    } else if (name === "wall") {
      ref.current.selector.props.linesActions.selectToolDrawingLine(name);
    } else if (name === "door") {
      ref.current.selector.props.holesActions.selectToolDrawingHole(name);
    } else if (name === "square column") {
      ref.current.selector.props.itemsActions.selectToolDrawingItem(name);
    } else if (name === "sliding door") {
      ref.current.selector.props.holesActions.selectToolDrawingHole(name);
    }
  };

  const workCardEvent = async (index) => {
    let newMaps = maps;
    newMaps[selectedMap] = {
      name: newMaps[selectedMap]?.name,
      mapSettings: ref?.current?.store.getState("scene").toJS()["react-planner"].scene,
    };

    let roles = [];
    if (newMaps.length <= 0) {
      roles = [
        {
          name: "Alan 1",
          location: ref?.current?.selector.props.state.toJS()["react-planner"].viewer2D,
          mapSettings: ref?.current?.store.getState("scene").toJS()["react-planner"].scene,
        },
      ];
    } else {
      roles = newMaps.map((d) => {
        return {
          ...d,
          location: ref?.current?.selector.props.state.toJS()["react-planner"].viewer2D,
        };
      });
    }

    const lastPlannerItems = roles[selectedMap].mapSettings?.layers["layer-1"]?.items;

    equipmentService.workCardEvent(lastPlannerItems).then((response) => {
      response && setLastData(response?.data);
    });
  };

  const setCurrentMap = (mapSettings, index) => {
    ref.current.selector.props.projectActions.loadProject(mapSettings);
    setSelectedMap(index);
  };

  useEffect(() => {
    handleMaps();
    workCardEvent();
  }, [data]);

  return (
    <Provider store={store}>
      <div className="w-full h-full flex flex-col">
        {isFetching && (
          <div className="fixed inset-0 bg-black/40 z-[99999] flex items-center flex-col gap-4 justify-center">
            <Loading size={56} color={"#fff"} secondary={"rgb(0 0 0 / 0.4)"} />
            <p className="text-white font-semibold text-lg">{t("embedded:pleaseWaitData")}</p>
          </div>
        )}
        <div className="w-full flex items-center justify-between border-b-[1px] border-secondary-100 h-14 py-3 mb-3">
          <h1 className="text-2xl text-secondary-900 font-semibold">{t("embedded:mapEditor")}</h1>
          <div className="flex items-center gap-x-3">
            <Button
              size={"sm"}
              label={t("buttons:cancelAndQuit")}
              colorType={"secondary-gray"}
              onClick={() => {
                // map.setGoBackEditor(true);
                navigate("/app/machine/digital-twin");
              }}
            />
            <Button
              size={"sm"}
              colorType={"primary-machine"}
              onClick={() => {
                saveMap();
              }}
              label={
                functionLoading ? (
                  <Loading size={20} color={"#fff"} secondary={"#6941C6"} />
                ) : (
                  <p className="px-5 flex gap-1 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                      <path
                        d="M6.33333 2.5V5.33333C6.33333 5.80004 6.33333 6.0334 6.42416 6.21166C6.50406 6.36846 6.63154 6.49594 6.78834 6.57584C6.9666 6.66667 7.19996 6.66667 7.66667 6.66667H13.3333C13.8 6.66667 14.0334 6.66667 14.2117 6.57584C14.3685 6.49594 14.4959 6.36846 14.5758 6.21166C14.6667 6.0334 14.6667 5.80004 14.6667 5.33333V3.33333M14.6667 17.5V12.1667C14.6667 11.7 14.6667 11.4666 14.5758 11.2883C14.4959 11.1315 14.3685 11.0041 14.2117 10.9242C14.0334 10.8333 13.8 10.8333 13.3333 10.8333H7.66667C7.19996 10.8333 6.9666 10.8333 6.78834 10.9242C6.63154 11.0041 6.50406 11.1315 6.42416 11.2883C6.33333 11.4666 6.33333 11.7 6.33333 12.1667V17.5M18 7.77124V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V6.5C3 5.09987 3 4.3998 3.27248 3.86502C3.51217 3.39462 3.89462 3.01217 4.36502 2.77248C4.8998 2.5 5.59987 2.5 7 2.5H12.7288C13.1364 2.5 13.3402 2.5 13.5321 2.54605C13.7021 2.58688 13.8647 2.65422 14.0138 2.7456C14.182 2.84867 14.3261 2.9928 14.6144 3.28105L17.219 5.88562C17.5072 6.17387 17.6513 6.318 17.7544 6.48619C17.8458 6.63531 17.9131 6.79789 17.9539 6.96795C18 7.15976 18 7.36358 18 7.77124Z"
                        stroke="white"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {t("buttons:save")}
                  </p>
                )
              }
            />
          </div>
        </div>
        <div className="flex flex-1 gap-x-4 overflow-hidden">
          <Editor
            maps={maps}
            width={width}
            height={height}
            setMaps={setMaps}
            lastData={lastData}
            selectTool={selectTool}
            selectedMap={selectedMap}
            addNewFloor={addNewFloor}
            setCurrentMap={setCurrentMap}
            setSelectedMap={setCurrentMap}
            workCardEvent={workCardEvent}
            onSaveSize={(width, height) => {
              ref.current.selector.props.projectActions.setProjectProperties({
                width: parseInt(width),
                height: parseInt(height),
              });
            }}
          />
          <div ref={menuRef} className="flex-1 h-full flex border rounded-lg shadow-md border-secondary-200 items-center justify-center overflow-hidden">
            {isLoading ? (
              <p></p>
            ) : (
              <ReactPlanner
                ref={ref}
                catalog={MyCatalog}
                width={width}
                height={height}
                viewOnly={false}
                onClickOnItem={(item) => {}}
                plugins={plugins}
                stateExtractor={(state) => {
                  return state.get("react-planner");
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Provider>
  );
});

export default MapEditor;
