import React, { useState } from "react";
import {
  CoinsStackedIcon,
  MeasuringIcon,
  MeasuringIconSecond,
  MinusIcon,
  PlusIcon,
  RightIconAssembly,
} from "../../../assets/icons/productIcons";
import { Button } from "../../../components";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";

export default function AssemblyProductCard({
  image,
  stockId,
  unit,
  unitPrice,
  item,
  index,
  selectedProduct,
  setSelectedProduct,
  setIsAddedProducts,
  isAddedProducts,
  handleAddUnitValue,
  unitValue,
  setUnitValue,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-y-3 w-full">
      <div
        className={`flex border min-h-[90px] max-h-[90px] w-full px-3 py-2 rounded-lg border-[#D0D5DD] gap-x-2 shadow-sm ${
          selectedProduct?.id === item?.id ? "bg-[#F9FAFB]" : ""
        }`}
      >
        <div className="flex h-full flex-col gap-y-1 flex-1 w-[60%]">
          <div className="flex gap-x-3">
            {!image ? (
              <span className="min-w-[64px] max-w-[64px] min-h-[48px] max-h-[48px] rounded border-[#0000001A] bg-black"></span>
            ) : (
              <img
                className="min-w-[64px] max-w-[64px] min-h-[48px] max-h-[48px] rounded border-[#0000001A]"
                src={generateFileUrl(image)}
                width={64}
                height={48}
                alt={item?.name?.charAt(0).toUpperCase()}
              />
            )}

            <div className="flex flex-col justify-center w-[70%]">
              <span className="text-[#101828] font-semibold text-base truncate">
                {item?.name || t("product:productName")}
              </span>
              <span className="text-[#475467] font-normal text-sm truncate">
                Stok ID: {stockId || 0}
              </span>
            </div>
          </div>
          <div className="flex">
            <div className="flex gap-x-1 items-center pr-[6px] py-[2px]">
              <MeasuringIcon />
              <span className="flex gap-x-[2px] items-center">
                <span className="text-[#475467] font-medium text-xs">
                  {item?.rawMaterial?.unit || "---"}
                </span>
                <span className="text-[#667085] font-normal text-[10px] leading-4">
                  {t("product:units")}{" "}
                </span>
              </span>
            </div>
            <div className="flex gap-x-1 items-center pr-[6px] py-[2px]">
              <CoinsStackedIcon />
              <span className="flex gap-x-[2px] items-center">
                <span className="text-[#475467] font-medium text-xs">
                  ₺{unitPrice || 0}
                </span>
                <span className="text-[#667085] font-normal text-[10px] leading-4">
                  {t("product:units")} {t("product:prices")}{" "}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex h-full items-center justify-center">
          <Button
            colorType={"secondary-product"}
            size={"sm"}
            onClick={() => {
              if (selectedProduct?.id) {
                setSelectedProduct(null);
              } else {
                setSelectedProduct(item);
              }
            }}
            iconLeft={
              selectedProduct?.id === item?.id ? <MinusIcon /> : <PlusIcon />
            }
          />
        </div>
      </div>
      {selectedProduct?.id === item?.id ? (
        <div className="flex w-full h-10 min-h-[40px] max-h-[40px] bg-white rounded-lg border border-[#D0D5DD]">
          <div className="flex py-2 px-3 gap-x-2 items-center">
            <MeasuringIconSecond />
            <input
              type="number"
              placeholder={unit || "Metre"}
              className="focus:ring-0 focus:outline-0 text-[#667085] text-base font-normal bg-transparent"
              value={unitValue}
              onChange={(e) => setUnitValue(parseFloat(e.target.value))}
            />
          </div>
          <div
            onClick={handleAddUnitValue}
            className="flex cursor-pointer px-[14px] py-[10px] gap-x-1 items-center justify-center ml-auto border-l"
          >
            <span className="text-[#344054] text-sm font-semibold">
              {t("buttons:add")}{" "}
            </span>
            <RightIconAssembly />
          </div>
        </div>
      ) : null}
      {selectedProduct?.id === item?.id && (
        <div className="w-full h-[1px] bg-[#EAECF0] mt-1"></div>
      )}
    </div>
  );
}
