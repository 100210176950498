import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { useStore } from "../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { junctionService } from "../../services/junction.service";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { Button, CustomModal } from "../../components";
import CustomImage from "../../components/CustomImage";
import { NoEquIcon } from "../../assets/icons/machineIcons";
import { ArrowRight } from "../../assets/icons/equipmentIcon";
import ServiceSupplyHeaderCard from "./cards/service-supply/ServiceSupplyHeaderCard";
import { serviceSupplyService } from "../../services/service-supply.service";
import ServiceSupplyPlanModal from "./modals/ServiceSupplyPlanModal";
import QualityServiceSupplyAllCard from "./components/QualityServiceSupplyAllCard";
import ServiceSupplyListPageAllCard from "./components/ServiceSupplyListPageAllCard";

const ServiceSupplyListPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isOpenPlanningModalData, setIsOpenPlanningModalData] = useState(false);
  const headData = useQuery({
    queryKey: ["service-supply-list-page-junction"],
    retry: 0,
    queryFn: async () => await serviceSupplyService.getServiceSupplyHeaderList(true),
  });



  const  {data : listData , isLoading , refetch : listDataRefetch  }= useQuery({
    queryKey: ["service-supply-list-supplier"],
    enabled: false,
    retry: 0,
    queryFn: async () => await serviceSupplyService.getServiceSupplyList(id, "list"),
  });

  const refetch = () => {
    listDataRefetch();
  };



  useEffect(() => {
    if (id != ":id" && id != "undefined") {
      setSelectedGroup(headData?.data?.find((item) => item?.id == id));
    }
    listDataRefetch();
  }, [id]);

  useEffect(() => {
    if (headData?.data?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mrp/supply/service/${headData?.data[0]?.id}/list`);
    }
  }, [headData?.data]);

  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "10826fc4-bf01-4164-acf0-72bb213e88d3") {
      } else if (auth?.user?.company?.id == "b0d47c1a-e5dd-4ec1-a655-424af970bbfe") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);

  const tabOptions = [
    { value: "list", label: t("product:list") },
    { value: "junction", label: t("product:track") },
    { value: "plan", label: t("product:planner") },
  ];

  const tableHeadItems = [
    { name: t("product:materials") },
    { name: t("product:inStock") },
    { name: t("product:activeRequests") },
    { name: t("product:remainingRequest") },
    { name: t("product:ssPlanneds") },
    { name: t("product:ssPlannedQuantity") },
    { name: "" },
  ];

  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];

  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };

  return (
    <>
      <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative step-1 scrollbar-hide">
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
          </span>
          <div className="flex items-center">
            <span className="font-codecMedium text-2xl text-black">upu.</span>
            <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
            {!(headData?.isLoading || headData?.isFetching) && (
              <p className="text-xl font-normal ml-2 text-secondary-500">
                {selectedGroup && selectedGroup?.name ? `${t("product:serviceSupply")} / ${selectedGroup?.name}` : t("product:serviceSupply")}
              </p>
            )}
          </div>
          {/* <span className="ml-auto">
                    <Button
                        colorType={"primary-product"}
                        size={"md"}
                        label={t("product:supplierList")}
                        onClick={() => navigate("/app/product/mrp/supply/material/list")}
                    />
                </span> */}
        </div>
        {headData?.isLoading || headData?.isFetching ? (
          <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
        ) : (
          <div className="h-[56px] min-h-[56px] w-full overflow-x-auto flex">
            {headData?.data
              ?.sort((a, b) => b?.requestCount - a?.requestCount)
              ?.map((item, index) => {
                return (
                  <div className={`min-w-[192px] ${index !== headData?.data?.length - 1 ? "border-b" : "border-b-0"} max-h-[40px] pr-5`}>
                    <ServiceSupplyHeaderCard
                      onClick={async () => {
                        navigate(`/app/product/mrp/supply/service/${item?.id}/list`);
                      }}
                      name={item?.name}
                      selectedOperation={{
                        id: id,
                        name: item?.name,
                      }}
                      id={item?.id}
                      length={item?.requestCount}
                    />
                  </div>
                );
              })}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching ? (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
        ) : (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-4 mb-4">
            {tabOptions?.map((option, index) => (
              <div
                className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                  "list" == option?.value ? "bg-white shadow-sm" : ""
                }`}
                onClick={() => {
                  if (option?.value === "plan") navigate(`/app/product/mrp/supply/service/${id}/plan-view`);
                  if (option?.value === "junction") navigate(`/app/product/mrp/supply/service/${id}/junction`);
                }}
              >
                <p className={`text-md font-semibold ${"list" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
              </div>
            ))}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching ? (
          <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
        ) : (
          <>
            <div style={{ minHeight: height - 50 }} className="flex flex-col w-full border rounded-xl">
              <div className="flex max-h-[44px] min-h-[44px] max-w-full min-w-full">
                {tableHeadItems?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      scope="col"
                      className={` px-4 py-3 ${
                        i === 0 ? "min-w-[336px] max-w-[336px] rounded-tl-xl" : i === 6 ? "min-w-[72px] max-w-[72px] w-[72px] rounded-tr-xl" : "w-full"
                      } items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                    >
                      <span className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}>
                        {item?.name}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="w-full bg-white flex-1 flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide rounded-xl">
                
              {isLoading ? (
                <div> loadinggg</div>
              ) : (
                     listData?.purchaseList?.map((item, index) => {
                      const totalSsPlanned = item?.ssPlanedJunctions?.reduce((acc, curr) => acc + (curr?.targetedWorkCount || 0), 0);
                      return (
                        <div className={`flex w-full items-center border-b h-[80px] min-h-[80px]`}>
                          <div className="flex items-center h-full border-r min-w-[336px] max-w-[336px]">
                            <div className="flex w-full h-full px-6 py-3 gap-x-[6px]">
                              {item?.flexProduct ? (
                                <>
                                  {item?.flexProduct?.image ? (
                                    <div data-tooltip-id={`${item?.id}`} data-tooltip-place="right" data-tooltip-content={`${item?.flexProduct?.name}`}>
                                      <CustomImage
                                        borderRadius={2}
                                        src={item?.flexProduct?.image}
                                        style={{
                                          width: 64,
                                          height: 48,
                                          minWidth: 64,
                                          minHeight: 48,
                                          borderRadius: 4,
                                          borderWidth: 1,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          display: "flex",
                                          borderColor: "#0000001A",
                                          backgroundColor: "#fff",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                                      <NoEquIcon />
                                    </div>
                                  )}
                                </>
                              ) : item?.externalOperation?.product ? (
                                <>
                                  {item?.externalOperation?.product?.image ? (
                                    <div
                                      data-tooltip-id={`${item?.id}`}
                                      data-tooltip-place="right"
                                      data-tooltip-content={`${item?.externalOperation?.product?.name}`}
                                    >
                                      <CustomImage
                                        borderRadius={2}
                                        src={item?.externalOperation?.product?.image}
                                        style={{
                                          width: 64,
                                          height: 48,
                                          minWidth: 64,
                                          minHeight: 48,
                                          borderRadius: 4,
                                          borderWidth: 1,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          display: "flex",
                                          borderColor: "#0000001A",
                                          backgroundColor: "#fff",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                                      <NoEquIcon />
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item?.image ? (
                                    <div data-tooltip-id={`${item?.id}`} data-tooltip-place="right" data-tooltip-content={`${item?.name}`}>
                                      <CustomImage
                                        borderRadius={2}
                                        src={item?.image}
                                        style={{
                                          width: 64,
                                          height: 48,
                                          minWidth: 64,
                                          minHeight: 48,
                                          borderRadius: 4,
                                          borderWidth: 1,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          display: "flex",
                                          borderColor: "#0000001A",
                                          backgroundColor: "#fff",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                                      <NoEquIcon />
                                    </div>
                                  )}
                                </>
                              )}
    
                              <div className="flex flex-col w-full justify-center">
                                <p className="text-[#101828] text-md font-semibold px-1 max-w-[190px] truncate">
                                  {item?.flexProduct
                                    ? item?.flexProduct?.name
                                    : item?.externalOperation
                                    ? item?.externalOperation?.product?.name
                                    : item?.name || "Product Name"}
                                </p>
                                <p
                                  className={`px-1 py-[3px] max-w-[190px] w-fit truncate rounded bg-[${
                                    item?.flexProduct || item?.flexRawMaterial ? "#F9F5FF" : "#FFFAEB"
                                  }] ${item?.flexProduct || item?.flexRawMaterial ? "text-[#6941C6]" : "text-[#B54708]"} text-xs font-medium`}
                                >
                                  {t("product:stock")} :{" "}
                                  {item?.flexProduct
                                    ? item?.flexProduct?.stockCode
                                    : item?.flexRawMaterial
                                    ? item?.stockCode
                                    : item?.externalOperation?.product?.stockCode || "--"}{" "}
                                  {item?.flexProduct || item?.flexRawMaterial ? "" : `(${item?.step}.Op)`}
                                </p>
                              </div>
                            </div>
                            <div
                              className={`flex items-center justify-center h-full min-w-6 max-w-6 w-6 p-[3px] bg-[${
                                item?.flexProduct || item?.flexRawMaterial ? "#F9F5FF" : "#F2F4F7"
                              }] border-l border-[#E4E7EC]`}
                            >
                              <p
                                className={`${
                                  item?.flexProduct || item?.flexRawMaterial ? "text-[#7F56D9]" : "text-[#475467]"
                                } text-xxs font-semibold -rotate-90 whitespace-nowrap`}
                              >
                                <p
                                  className={`text-xxs font-semibold whitespace-nowrap ${
                                    item?.flexProduct || item?.flexRawMaterial ? "text-[#7F56D9]" : "text-[#475467]"
                                  }`}
                                >
                                  {item?.flexProduct ? item?.step + ".Flex" : item?.flexRawMaterial ? "Flex" : t("product:semiProduct")}
                                </p>
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                            <p className="text-[#344054] text-sm font-medium">
                              {/* {item?.rawMaterial
                                                        ? item?.rawMaterial?.quantity
                                                        : item?.product
                                                            ? item?.product?.qualityQuantity
                                                            : item?.productInternalOperation
                                                                ? item?.productInternalOperation?.activePiece || "--"
                                                                : item?.productExternalOperation?.activePiece || "--"} */}
                              {item?.flexProduct
                                ? item?.piece || "0"
                                : item?.externalOperation
                                ? item?.externalOperation?.activePiece || "0"
                                : item?.qualityQuantity || "0"}
                            </p>
                            <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                          </div>
                          <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                            <p className="text-[#344054] text-sm font-medium">{item?.flexProduct ? totalSsPlanned : item?.piece || "0"}</p>
                            <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                          </div>
                          <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                            <p className="text-[#344054] text-sm font-medium">{item?.piece - item?.finishedPiece || "0"}</p>
                            <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                          </div>
                          <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                            <p className="text-[#344054] text-sm font-medium">{totalSsPlanned || "0"}</p>
                            <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                          </div>
                          <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                            <p className="text-[#344054] text-sm font-medium">{item?.ssPlanedJunctions?.length || "0"}</p>
                          </div>
                          <div className="flex justify-center items-center h-full  min-w-[72px] max-w-[72px] w-[72px]">
                            <Button
                              colorType={"tertiary-gray"}
                              iconLeft={<ArrowRight />}
                              size={"md"}
                              onClick={() => {
                                const type = item?.flexProduct ? "flex-header" : item?.externalOperation ? "semi-operation" : "flex";
                                if (type == "flex") {
                                  setIsOpenPlanningModal(true);
                                  setIsOpenPlanningModalData({
                                    ...item,
                                    type: "flex",
                                  });
                                } else {
                                  navigate(`/app/product/mrp/supply/service/purchase-detail/${item?.id}/${headData?.data[0]?.id}/${type}`);
                                }
                              }}
                            />
                          </div>
                        </div>
                      );
                    })
              )}
                
           
              </div>
            </div>
          </>
        )}
      </div>
      <CustomModal
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        onClose={() => {
          setIsOpenPlanningModal(false);
        }}
        modalTitle={t("product:serviceSupplyPlanned")}
        subTitle={t("product:specifyPlannings")}
        // subTitle={`${isOpenPlanningModalData?.flexProduct ? isOpenPlanningModalData?.step + ".Flex" : isOpenPlanningModalData?.flexRawMaterial ? "Flex" : isOpenPlanningModalData?.step + ".Op"} (${selectedGroup?.name}) için planlama detaylarını belirtin.`}
        width="fit-content"
        children={<ServiceSupplyPlanModal setIsOpen={setIsOpenPlanningModal} refetch={refetch} selectedJunction={isOpenPlanningModalData} groupId={id} />}
      />
    </>
  );
};

export default ServiceSupplyListPage;
