import React from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { QuantityIcons } from "../../../assets/icons/productIcons";
import { CalenderIconProduct } from "../../../assets/icons/equipmentIcon";
const NewProductCard = ({
  name,
  productId,
  quantity,
  date,
  image,
  orderNo,
}) => {
  return (
    <div className="flex w-full min-h-[74px] max-h-[74px] border rounded-lg p-2 gap-x-3">
      <img
        className="flex min-w-[72px] max-w-[72px] min-h-[54px] max-h-[54px] shadow-sm border border-[#0000001A]"
        src={generateFileUrl(image)}
      />
      <div className="flex flex-col">
        <span className="text-secondary-900 font-semibold text-sm">{name}</span>
        <span className="text-secondary-600 font-normal text-xs">
          {orderNo}
        </span>
        <div className="flex gap-x-2 items-center">
          <div className="flex gap-x-[2px] items-center">
            <QuantityIcons />
            <span className="text-secondary-600 text-xs font-normal">
              {quantity}
            </span>
          </div>
          <div className="flex gap-x-[2px] items-center">
            <CalenderIconProduct />
            <span className="text-secondary-600 text-xs font-normal">
              {date}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductCard;
