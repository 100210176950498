import React, { useEffect, useState, useMemo } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import { useQuery } from "@tanstack/react-query";
import { productService } from "../../../services/product.service";
import generateFileUrl from "../../../utils/generateFileUrl";
import NewOrderSecond from "./NewOrderSecond";
import { CustomModal, PaginationComponent } from "../../../components";
import { Loader } from "../../../assets/icons/stepsIcons";
import diacritics from "diacritics";
import { Tooltip } from "react-tooltip";

const NewOrder = React.memo(
  ({
    t,
    setIsNewOrderModal,
    refresh,
    selectedProduct,
    setSelectedProduct,
    supplyForm,
    setSupplyForm,
    setOrderData,
    orderData,
  }) => {
    const [newProduct, setNewProduct] = useState([]);
    const [isNewOrderSecond, setIsNewOrderSecond] = useState(false);
    const [searchVal, setSearchVal] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [sortValue, setSortValue] = useState(null);
    const [ascDesc, setAscDesc] = useState(true);
    const [pageLimit, setPageLimit] = useState(10);
    const [filterOptions, setFilterOptions] = useState({});

    const { isLoading, isFetching, data, refetch } = useQuery({
      queryKey: ["listProductOrderModal"],
      queryFn: useMemo(
        () => async () => {
          try {
            const res = await productService.listProductPaginated(
              pageNo,
              searchVal,
              sortValue,
              ascDesc ? "DESC" : "ASC",
              pageLimit,
              filterOptions
            );
            setTotalCount(res?.data?.meta?.itemCount);
            setPageCount(res?.data?.meta?.pageCount);
            setPageData(res?.data?.data);
            if (res?.data?.meta?.pageCount < pageNo) {
              setPageNo(1);
            }
            return res;
          } catch (error) {
            console.error("Error occured while fetching data:", error);
            throw error;
          }
        },
        [pageNo, searchVal, sortValue, ascDesc, pageLimit, filterOptions]
      ),
      retry: 0,
    });

    useEffect(() => {
      refetch();
    }, [pageNo, searchVal, sortValue, ascDesc, pageLimit, filterOptions]);

    const dataManipulate = () => {
      if (data?.data?.data) {
        const updatedProduct = pageData?.map((e) => {
          return {
            ...e,
            operations: e?.internalOperations?.concat(e?.externalOperations),
          };
        });
        setNewProduct(updatedProduct);
      }
    };

    const handleSearch = (input) => {
      const normalizedInput = diacritics.remove(input.toLowerCase());
      setSearchVal(normalizedInput);
    };

    useEffect(() => {
      if (pageData?.length > 0) {
        dataManipulate();
      }
    }, [pageData]);

    return (
      <>
        <div className="flex flex-col  max-w-[850px] min-w-[850px] max-h-[590px] min-h-[590px]">
          <>
            <div className="mt-4 w-full">
              <SearchInput
                setSearchVal={handleSearch}
                theme={"product"}
                placeholder={t("addEquipment:searchProduct")}
              />
            </div>
            {isLoading || isFetching ? (
              <div className="flex items-center justify-center w-full max-h-[467px] min-h-[467px]">
                <Loader currentColor="#DC6803" currentFill="currentFill" />
              </div>
            ) : (
              <div className="flex flex-wrap  w-[850px] max-w-[850px]  max-h-[495px] min-h-[455px] gap-x-4 gap-y-3 mt-3">
                {pageData?.map((product, index) => {
                  return (
                    <div
                      data-tooltip-id={`${product?.id}`}
                      data-tooltip-content={`
                    ${t("product:productName")}: ${product?.name} |
                    ${t("product:revizionNo")}: ${product?.revisionNo} |
                    ${t("product:stockCode")}: ${product?.stockCode} 
                  `}
                      data-tooltip-place="top"
                      key={index}
                      className={`w-[49%] max-h-[72px] min-h-[72px]`}
                      onClick={() => {
                        setSelectedProduct(product);
                        setIsNewOrderSecond(true);
                      }}
                    >
                      <div className="flex border w-full h-full p-2 border-[#D0D5DD] shadow-xs rounded-lg gap-3 group relative hover:bg-[#F9FAFB] cursor-pointer">
                        <div className="max-w-[72px] max-h-[54px] ">
                          <img
                            src={generateFileUrl(product?.image)}
                            className="max-w-[72px] max-h-[54px] min-w-[72px] min-h-[54px] border rounded-[4px] shadow-sm"
                          />
                        </div>
                        <div className="flex flex-col w-[75%]">
                          <span className="text-sm font-semibold text-[#101828] truncate">
                            {product?.revisionNo
                              ? product?.revisionNo != 0
                                ? `${product?.name}(R-${product?.revisionNo})`
                                : product?.name
                              : product?.name}
                          </span>
                          <span className="text-xs font-normal text-[#475467] truncate">
                            {product?.code}
                          </span>
                          <span className="text-xs font-normal text-[#475467] truncate">
                            {product?.stockCode}
                          </span>
                        </div>
                      </div>
                      <Tooltip id={`${product?.id}`} />
                    </div>
                  );
                })}
              </div>
            )}
            <div className="z-50 border-t  border-[#D0D5DD] bg-white rounded-b-xl mt-1">
              <PaginationComponent
                pageNo={pageNo}
                setPageNo={setPageNo}
                pageCount={pageCount}
              />
            </div>
          </>

          <CustomModal
            isOpen={isNewOrderSecond}
            setIsOpen={setIsNewOrderSecond}
            onClose={() => {
              setSelectedProduct(null);
              setIsNewOrderSecond(false);
            }}
            modalTitle={t("product:createNewOrder")}
            children={
              <NewOrderSecond
                t={t}
                refresh={refresh}
                orderData={orderData}
                supplyForm={supplyForm}
                isOpen={isNewOrderSecond}
                setOrderData={setOrderData}
                setSupplyForm={setSupplyForm}
                setIsOpen={setIsNewOrderSecond}
                selectedProduct={selectedProduct}
                setIsNewOrderModal={setIsNewOrderModal}
              />
            }
          />
        </div>
      </>
    );
  }
);

export default NewOrder;
