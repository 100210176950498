import React, { useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import SearchInput from "../inputs/SearchInput";
import diacritics from "diacritics";
import { useTranslation } from "react-i18next";
import { ReactComponent as NoDataProduct } from "../../assets/icons/NoData/NoDataProduct.svg";
export default function SelectProductModal({ filteredData, setIsOpenPlanningModal, setDraggedProduct, setIsOpenSelectProductModal, orderCount }) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const filteredOrders = filteredData.filter((order) =>
    diacritics.remove(`${order?.internalOperation?.product?.name}`).toLowerCase().includes(diacritics.remove(searchValue).toLowerCase())
  );
  return (
    <div className="flex w-[350px] flex-col h-full gap-y-6 pt-6">
      <div className="flex flex-col gap-y-4">
        <SearchInput setSearchVal={setSearchValue} theme={"product"} />
        <div className="flex flex-col max-h-[330px] min-h-[330px] overflow-y-auto scrollbar-hide gap-y-4 border-b pb-4 items-center">
          {filteredOrders?.map((item) => {
            const statusColor = () => {
              if (item?.flexProduct || item?.flexRawMaterial || item?.name) {
                return "#E9D7FE";
              } else {
                switch (item?.status) {
                  case "finished":
                    return "#12B76A";
                  case "started":
                    return "#FDE172";
                  case "rejected":
                    return "#D92D20";
                  case "flexProduct":
                    return "#E9D7FE";
                  default:
                    return "#F2F4F7";
                }
              }
            };
            return (
              <>
                <div
                  onClick={() => {
                    setIsOpenPlanningModal(true);
                    setDraggedProduct(item);
                    setIsOpenSelectProductModal(false);
                  }}
                  key={item?.id}
                  className="flex bg-white flex-row  w-[350px] min-w-[350px] h-[90px] min-h-[90px] relative group pl-2  pt-2  border border-[#D0D5DD] rounded-lg cursor-grab "
                >
                  <div className="flex flex-col w-[88%]">
                    <div className="flex flex-row items-center gap-x-3">
                      <img
                        className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-300 select-none"
                        src={generateFileUrl(
                          item?.internalOperation?.product?.image
                            ? item?.internalOperation?.product?.image
                            : item?.flexProduct?.image
                              ? item?.flexProduct?.image
                              : item?.image
                        )}
                      />
                      <div className="flex flex-col items-start">
                        {item?.flexProduct || item?.flexRawMaterial || item?.name ? (
                          <>
                            <p className="font-semibold text-secondary-900 text-base w-[220px] truncate">
                              {item?.internalOperation?.product?.name
                                ? item?.internalOperation?.product?.name
                                : item?.flexProduct?.name
                                  ? item?.flexProduct?.name
                                  : item?.name || "Product Name"}
                            </p>
                          </>
                        ) : (
                          <p className="font-semibold text-secondary-900 text-base w-[220px] truncate">
                            {item?.internalOperation?.product?.name || "Product Name"}
                          </p>
                        )}
                        <p className="text-sm font-normal w-[220px] truncate text-secondary-600">
                          {t("product:stockCode")} :{" "}
                          {item?.internalOperation?.product?.stockCode
                            ? item?.internalOperation?.product?.stockCode
                            : item?.flexProduct?.stockCode
                              ? item?.flexProduct?.stockCode
                              : item?.stockCode || "--"}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-x-[6px]">
                      {item?.flexProduct ? (
                        <>
                          <div className="flex flex-row items-center mt-1.5">
                            <PieceFlexIcon color={"#7F56D9"} />
                            <p className="text-sm font-medium ml-1 text-[#6941C6]">{item?.finishedPiece || "0"}</p>
                            <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-500">{t("product:qtyMade")}</p>
                          </div>
                        </>
                      ) : (item?.flexRawMaterial || item?.name) ? (
                        <>
                          <div className="flex flex-row items-center mt-1.5">
                            <PieceFlexIcon color={"#7F56D9"} />
                            <p className="text-sm font-medium ml-1 text-[#6941C6]">{item?.qualityQuantity || "0"}</p>
                            <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-500">{t("product:qtyMade")}</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex flex-row items-center mt-1.5">
                            <PieceFlexIcon color={"#475467"} />
                            <p className="text-sm font-medium ml-1 text-secondary-700">{item?.finishedPiece || "0"}</p>
                            <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-600">{t("product:qtyMade")}</p>
                          </div>
                          <div className="flex flex-row items-center mt-1.5">
                            <PieceFlexIcon color={"#DC6803"} />
                            <p className="text-sm font-medium ml-1 text-[#B54708]">{item?.piece - item?.finishedPiece || "0"}</p>
                            <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-600">{t("product:qtyRemaining")}</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[${item?.flexProduct || item?.flexRawMaterial || item?.name ? "#F9F5FF" : statusColor()
                      }] border-secondary-300 h-[70px] min-h-[70px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
                  >
                    <p
                      className={`text-[10px] font-medium ${item?.status === "started"
                        ? "text-[#A15C07]"
                        : item?.status === "rejected"
                          ? "text-white"
                          : item?.status === "finished"
                            ? "text-white"
                            : item?.flexProduct || item?.flexRawMaterial || item?.name
                              ? "text-[#6941C6]"
                              : "text-secondary-500"
                        } -rotate-90`}
                    >
                      {item?.flexProduct || item?.flexRawMaterial || item?.name ? (item?.step ? item?.step + ".Flex" : "Flex") : item?.internalOperation?.step + ".Op"}
                    </p>
                    {item?.flexProduct || item?.flexRawMaterial || item?.name ? (
                      <></>
                    ) : (
                      <span className="w-3 min-w-3 h-3 min-h-3">
                        <ArrowIcon
                          color={
                            item?.status === "started" ? "#A15C07" : item?.status === "rejected" ? "#fff" : item?.status === "finished" ? "#fff" : "#475467"
                          }
                        />
                      </span>
                    )}
                  </div>
                </div>
              </>
            );
          })}
          {filteredOrders?.length === 0 && (
            <div className="flex flex-col w-full items-center mt-20">
              <NoDataProduct />
              <p className="font-semibold text-xl text-secondary-900 mt-6">{t("step:noDataFound")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
const PieceFlexIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17841_63067)">
        <path
          d="M5.49967 1.3335H2.99967C2.0792 1.3335 1.33301 2.07969 1.33301 3.00016V13.0002C1.33301 13.9206 2.0792 14.6668 2.99967 14.6668H12.9997C13.9201 14.6668 14.6663 13.9206 14.6663 13.0002V3.00016C14.6663 2.07969 13.9201 1.3335 12.9997 1.3335H10.4997M5.49967 1.3335V5.19064C5.49967 5.25535 5.56863 5.29672 5.62573 5.26627L7.99967 4.00016L10.3736 5.26627C10.4307 5.29672 10.4997 5.25535 10.4997 5.19064V1.3335M5.49967 1.3335H10.4997"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17841_63067">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M6 9.5L6 2.5M6 2.5L2.5 6M6 2.5L9.5 6" stroke={color} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
