import React from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import Badgets from "../buttons/Badgets";
import {
  ClockIcon,
  GreenTimeIcon,
  PlusIcon,
  ProductCompleted,
  ProductControl,
  ProductIcon,
  ProductRejected,
  ProductWaiting,
  QtyIcon,
  QualityAccepted,
  QualityRejected,
  RedTimeIcon,
  TimeIcon,
} from "../../assets/icons/commonIcons";

const junctionProps = {
  ring: "",
  status: "",
  leftImage: "",
  texts: {
    first: "",
    second: "",
    piece: "",
    time: "",
    qualityAcceptedPiece: "",
    qualityRejectedPiece: "",
    estimatedTime: "",
    estimatedTrue: "",
    point: "",
    oeeData: "",
    performanceData: "",
  },
  user: { avatar: "", name: "", lastName: "" },
  equipment: { name: "", image: "" },
  rightButton: () => null,
  onClick: () => null,
};

const SingleJunctionCard = ({ props = junctionProps, id, quality, step }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => {
        if (props?.onClick) {
          props?.onClick();
        }
      }}
      style={{
        borderLeftWidth: "1px",
        borderBottomWidth: "1px",
        borderRightWidth: "1px",
        borderTopWidth: props.status !== "completed" ? "4px" : "1px",
        borderTopColor: props.status !== "completed" ? props.ring : "#D0D5DD",
        cursor: props?.onClick ? "pointer" : "default",
      }}
      className="w-full p-2 rounded-lg flex border-l-gray-300 border-b-gray-300 border-r-gray-300 shadow h-[80px] max-h-[80px] min-h-[80px] bg-white"
    >
      <img
        data-tooltip-id={`${id}`}
        data-tooltip-content={`${props?.texts?.first} `}
        src={generateFileUrl(props.leftImage)}
        className="w-[72px] min-w-[72px] h-[54px] rounded border border-gray-300 mt-0.5"
      />
      <div className="flex-1 flex ml-3 flex-col overflow-hidden">
        <div className="w-full overflow-hidden -mt-0.5">
          <p className="truncate w-[130px] text-xs text-secondary-500">
            {props?.texts?.first || "---"}
          </p>
        </div>
        <div className="w-full overflow-hidden">
          {props.status === "completed" ? (
            <p></p>
          ) : (
            <p className="truncate text-xs text-secondary-700 font-medium">
              {props?.texts?.second || "---"}
            </p>
          )}
        </div>
        {props.status === "completed" ? (
          <div className="flex flex-col w-full">
            <div className="w-full flex items-center mt-0.5">
              <div className="w-1/2 flex items-center gap-1 overflow-hidden">
                <QualityAccepted />
                <p className="truncate text-xs text-secondary-700 font-medium">
                  {props.texts?.qualityAcceptedPiece || "--"} {t("product:pcs")}
                </p>
              </div>
              <div className="w-1/2 flex items-center gap-1 overflow-hidden">
                {props.texts.estimatedTrue ? (
                  <GreenTimeIcon />
                ) : (
                  <RedTimeIcon />
                )}

                {/* <ClockIcon /> */}
                <p className="truncate text-xs text-secondary-700 font-medium">
                  {props.texts?.estimatedTime || "--"}
                </p>
              </div>
            </div>

            <div className="w-full flex items-center -mb-1">
              <div className="w-1/2 flex items-center gap-1 overflow-hidden">
                <QualityRejected />
                <p className="truncate text-xs text-secondary-700 font-medium">
                  {props.texts?.qualityRejectedPiece === undefined
                    ? "0"
                    : props.texts?.qualityRejectedPiece}{" "}
                  {t("product:pcs")}
                </p>
              </div>
              <div className="w-1/2 flex items-center gap-1 overflow-hidden  ">
                <TimeIcon />
                <p className="truncate text-xs text-secondary-700 font-medium">
                  {props.texts?.totalTime || "--"}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full flex items-center gap-x-2 ">
            <div className="w-[70%] flex items-center gap-1 overflow-hidden">
              <QtyIcon color={"#667085"} />
              <p className="truncate text-xs text-secondary-700 font-medium">
                {props.texts?.piece || "--"} ad
              </p>
            </div>
            <div className="w-[60%] flex items-center gap-1 overflow-hidden">
              <ClockIcon color={"#667085"} />
              <p className="truncate text-xs text-secondary-700 font-medium">
                {props.texts?.time || "--"}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="ml-3">
        {props?.rightButton ? (
          <button
            onClick={() => {
              props?.rightButton();
            }}
            type="button"
            className="min-w-[50px] h-[50px] rounded-full border-dashed border border-gray-300 flex items-center justify-center mt-1"
          >
            <PlusIcon />
          </button>
        ) : (
          <>
            {props?.user ? (
              <div
                data-tooltip-id={`${id}`}
                data-tooltip-content={`${
                  props?.user?.name + " " + props?.user?.lastName
                } `}
                className="mt-0.5"
              >
                {props?.user?.avatar ? (
                  <div className=" relative ">
                    <img
                      className="w-[54px] min-w-[54px] h-[54px] rounded-full border border-gray-300"
                      src={generateFileUrl(props?.user?.avatar)}
                      alt={props?.user?.name + " " + props?.user?.lastName}
                    />

                    {quality && (
                      <div
                        className={`z-[1] absolute flex items-center justify-center ml-[14px]  w-[28px] -bottom-2.5 h-[24px] rounded border ${
                          quality === "completed"
                            ? "bg-[#17B26A] border-[#17B26A]"
                            : quality === "waiting"
                            ? "bg-[#EAAA08] border-[#EAAA08]"
                            : quality === "control"
                            ? "bg-[#98A2B3] border-[#98A2B3]"
                            : quality === "rejected"
                            ? "bg-[#F04438] border-[#F04438]"
                            : quality === "accepted"
                            ? "bg-[#17B26A] border-[#17B26A]"
                            : "bg-[#DC6803] border-[#DC6803]"
                        }`}
                      >
                        {quality === "completed" ? (
                          <ProductCompleted />
                        ) : quality === "waiting" ? (
                          <ProductWaiting />
                        ) : quality === "control" ? (
                          <ProductControl />
                        ) : quality === "rejected" ? (
                          <ProductRejected />
                        ) : quality === "accepted" ? (
                          <ProductCompleted />
                        ) : (
                          <ProductIcon />
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="relative">
                    <NoAvatar
                      size={54}
                      fontSize={22}
                      name={props?.user?.name}
                      lastName={props?.user?.lastName}
                      color={"#475467"}
                    />
                    {quality && (
                      <div
                        className={`z-[1] absolute flex items-center justify-center ml-[14px]  w-[28px] -bottom-2.5 h-[24px] rounded border ${
                          quality === "completed"
                            ? "bg-[#17B26A] border-[#17B26A]"
                            : quality === "waiting"
                            ? "bg-[#EAAA08] border-[#EAAA08]"
                            : quality === "control"
                            ? "bg-[#98A2B3] border-[#98A2B3]"
                            : quality === "rejected"
                            ? "bg-[#F04438] border-[#F04438]"
                            : quality === "accepted"
                            ? "bg-[#17B26A] border-[#17B26A]"
                            : "bg-[#DC6803] border-[#DC6803]"
                        }`}
                      >
                        {quality === "completed" ? (
                          <ProductCompleted />
                        ) : quality === "waiting" ? (
                          <ProductWaiting />
                        ) : quality === "control" ? (
                          <ProductControl />
                        ) : quality === "rejected" ? (
                          <ProductRejected />
                        ) : quality === "accepted" ? (
                          <ProductCompleted />
                        ) : (
                          <ProductIcon />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <img
                src={generateFileUrl(props?.equipment?.image)}
                className="w-[72px] min-w-[72px] h-[54px] rounded border border-gray-300"
              />
            )}
          </>
        )}
      </div>
      <Tooltip id={`${id}`} className="z-[100]" />
      {step === undefined ? null : (
        <span className="absolute left-1 top-2">
          <Badgets colorType={"fill-gray"} label={step + ".op"} size={"sm"} />
        </span>
      )}
    </div>
  );
};

export default SingleJunctionCard;
