import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { orderService } from "../../services/order.service";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CustomModal, Input, Loading } from "../../components";
import generateFileUrl from "../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import diacritics from "diacritics";
import { customerService } from "../../services/customer.service";
import { productService } from "../../services/product.service";
import { Toast } from "../../utils/toastify/toast";
import { ArrowLeftIcon } from "./quality/assets/qualitySvg";
import Selector from "../../components/inputs/Selector";
import Badgets from "../../components/buttons/Badgets";
import SearchInput from "../../components/inputs/SearchInput";
import CreateOrderPageProductCard from "./cards/CreateOrderPageProductCard";
import { PlusIcon, Threedots } from "../../assets/icons/stepsIcons";
import LegacyAddProduct from "./modals/LegacyAddProduct";
import moment from "moment";
import { EditIcon } from "../../assets/icons/productIcons";
import { DeleteIcon } from "../../components/new-cards/card-icons";
import DeleteOrderProduct from "./modals/DeleteOrderProduct";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { FiltersIcon } from "./QualityExternal";
import NoAvatar from "../../components/avatar/NoAvatar";
import LegacyEditOrderModal from "./modals/LegacyEditOrderModal";
import { legacyOrderService } from "../../services/legacy-new-order-service";

const EditOrderFirstStep = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["editOrderDetails"],
    queryFn: async () => await orderService.editOrderDetails(id),
  });
  let navigate = useNavigate();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const { t } = useTranslation();
  const [isAddedProducts, setIsAddedProducts] = useState([]);
  const [isOpenNewPorductModal, setIsOpenNewPorductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [customer, setCustomer] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [editOrder, setEditOrder] = useState(null);
  const [customerOrderNo, setCustomerOrderNo] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [initialProducts, setInitialProducts] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("stock");
  const [isAlert, setIsAlert] = useState(false);
  const [orderNo, setOrderNo] = useState("");
  const toggleDropdown = (id, deliveryDate) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [deliveryDate]: !prevState[id]?.[deliveryDate],
      },
    }));
  };
  const customerList = useQuery({
    queryKey: ["customer-list"],
    queryFn: async () => await customerService.getCustomerList(),
    retry: 0,
  });

  const productList = useQuery({
    queryKey: ["list-product"],
    queryFn: async () => {
      return await productService.getAllListProduct();
    },
    retry: 0,
  });

  const totalOrderAmount = isAddedProducts?.reduce((acc, item) => acc + (item.orderedQuantity || 0), 0);
  const totalCost = isAddedProducts?.reduce((acc, item) => acc + item.productPrice * (item.orderedQuantity || 0), 0);

  const onSubmit = async () => {
    if (!customerOrderNo) {
      Toast("error", "Müşteri sipariş kodu giriniz");
      return;
    }
    if (isAddedProducts.length === 0) {
      Toast("error", "Lütfen en az bir ürün ekleyiniz");
      return;
    }
    const changedProducts = isAddedProducts.filter((product) => {
      const initialProduct = initialProducts.find((initialProduct) => initialProduct.id === product.id);

      if (!initialProduct || initialProduct.orderedQuantity !== product.orderedQuantity) {
        return true;
      }

      return false;
    });

    const filteredProducts = changedProducts.filter((product) => product.extraQuantity !== 0);

    if (filteredProducts.length === 0) {
      const sendDataDene = {
        systemOrderNo: orderNo,
        customer: customer?.id,
        customerOrderNo: customerOrderNo,
        productType: selectedRadio,
        products: isAddedProducts.map((item) => ({
          currency: item?.currency,
          stockQuantity: item?.stockQuantity || 0,
          productionQuantity: item?.productionQuantity || 0,
          waitingQuantity: item?.waitingQuantity || 0,
          customerStockCode: item?.customerStockCode,
          orderedQuantity: item?.constantOrderedQuantity,
          status: "update",
          id: item?.id,
          productPrice: item?.productPrice,
          deliveryDate: item?.deliveryDate,
          product: item?.product?.id,
          purchaseRequest: {
            type: "raw",
            orderedQuantity: item?.orderedQuantity || 0,
            stockQuantity: item?.stockQuantity || 0,
            purchaseQuantity: 0,
            typeId: undefined,
          },
        })),
      };
      await legacyOrderService.flexUpdateOrder(id, sendDataDene).then((res) => {
        if (res.data.code == 0) {
          sessionStorage.removeItem("editOrderFirstData");
          Toast("success", res?.data?.message);
          navigate("/app/product/mrp/orders");
        } else {
          Toast("error", res?.data?.message);
        }
      });
      return;
    }

    const sendData = {
      customer: customer,
      selectedProducts: filteredProducts.map((product) => ({
        ...product,
        ...product.product,
        sendDetailId: product.id,
        orderedQuantity: product.extraQuantity ? product.extraQuantity : product.orderedQuantity,
        constantOrderedQuantity: product.orderedQuantity,
        status: product?.extraQuantity ? "update" : "new",
      })),
      customerOrderNo: customerOrderNo,
      productType: selectedRadio,
      systemOrderNo: orderNo,
    };
    sessionStorage.setItem("editOrderFirstData", JSON.stringify(sendData));
    navigate(`/app/product/mrp/orders/edit-order/second-page/${id}`);
    setButtonLoading(false);
  };
  useEffect(() => {
    if (data) {
      setCustomer(data?.customer);
      setSelectedRadio(data?.productType);
      setOrderNo(data?.systemOrderNo);
      setCustomerOrderNo(data?.customerOrderNo);
      if (data?.details?.length > 0) {
        const updatedDetails = data.details.map((item) => ({
          ...item,
          constantOrderedQuantity: item?.orderedQuantity || 0,
        }));
        setIsAddedProducts(updatedDetails);
        setInitialProducts(updatedDetails);
      } else {
        setIsAddedProducts([]);
        setInitialProducts([]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (sessionStorage.getItem("editOrderFirstData")) {
      const data = JSON.parse(sessionStorage.getItem("editOrderFirstData"));
      setCustomer(data?.customer);
      setCustomerOrderNo(data?.customerOrderNo);
      setIsAddedProducts(data?.selectedProducts);
    }
  }, [sessionStorage.getItem("editOrderFirstData")]);

  const FilteredProducts = productList?.data?.data?.filter(
    (item) =>
      diacritics.remove(`${item?.stockCode}`).toLowerCase().includes(diacritics.remove(searchQuery.toLowerCase())) ||
      diacritics.remove(`${item?.name}`).toLowerCase().includes(diacritics.remove(searchQuery).toLowerCase())
  );

  const tableHeadItems = [
    t("product:productsAndMaterialss"),
    t("product:orderQuantityss"),
    t("product:delivery"),
    t("product:unitSale"),
    t("product:totalSales"),
    "Edit",
  ];

  const currencyIcon = (currency) => {
    switch (currency || "TRY") {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "RUB":
        return "₽";
      case "UAH":
        return "₴";
      case "TRY":
        return "₺";
      default:
        return "₺";
    }
  };

  const menuOptions = [
    { label: t("tasks:edit"), value: "edit", icon: <EditIcon /> },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <DeleteIcon />,
    },
  ];

  const alertDeleted = async (id) => {
    await orderService.deletePartials(id, "direct").then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsAlert(false);
        if (data?.details?.length === 1) {
          navigate("/app/product/mrp/orders");
        }
      } else {
        Toast("error", res?.data?.message);
        setIsAlert(false);
      }
    });
  };

  useEffect(() => {
    if (isOpenNewPorductModal === false) {
      setEditOrder(null);
    }
  }, [isOpenNewPorductModal]);

  return (
    <>
      <div className="flex flex-col w-full gap-y-8 h-[95vh]">
        {customerList?.isLoading ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[56px]" />
        ) : (
          <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
            <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
            <p className="text-[#101828] font-semibold text-2xl">{"Edit Order"}</p>
          </div>
        )}

        <div className="flex flex-col gap-y-6 w-full h-full">
          {customerList?.isLoading ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full  min-h-[72px]" />
          ) : (
            <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
              <div className="flex flex-col gap-y-1">
                <p className="text-[#101828] font-semibold text-lg">{t("product:orderProducts")}</p>
                <p className="text-[#344054] font-normal text-sm">{t("product:productRelated")}</p>
              </div>
              <div className="flex gap-x-3 max-h-[40px] min-h-[40px] h-10">
                <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                  <Button
                    size={"md"}
                    colorType={"secondary-gray"}
                    label={t("buttons:stop")}
                    onClick={() => {
                      navigate("/app/product/mrp/orders");
                    }}
                  />
                </span>
                <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                  <Button
                    disabled={buttonLoading ? true : false}
                    size={"md"}
                    colorType={"primary-product"}
                    label={buttonLoading ? <Loading color={"#fff"} secondary={"#fff"} size={22} /> : t("buttons:save")}
                    onClick={onSubmit}
                  />
                </span>
              </div>
            </div>
          )}
          {customerList?.isLoading ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full  min-h-[86px]" />
          ) : (
            <div className="flex w-full max-h-5 min-h-5 h-5 items-center gap-x-6">
              <div
                className="flex items-center gap-x-2"
                onClick={() => {
                  setSelectedRadio("order");
                }}
              >
                <div
                  className={`w-5 h-5 items-center flex justify-center rounded-full ${
                    selectedRadio == "order" ? "bg-[#DC6803]" : "bg-white border-[#D0D5DD] border"
                  }`}
                >
                  {selectedRadio == "order" && <div className="w-2  h-2 rounded-full bg-white"></div>}
                </div>
                <p className="text-sm text-secondary-700 font-medium">{t("product:produceForOrder")}</p>
              </div>
              <div
                className="flex items-center gap-x-2"
                onClick={() => {
                  setSelectedRadio("stock");
                }}
              >
                <div
                  className={`w-5 h-5 items-center flex justify-center rounded-full ${
                    selectedRadio == "stock" ? "bg-[#DC6803]" : "bg-white border-[#D0D5DD] border"
                  }`}
                >
                  {selectedRadio == "stock" && <div className="w-2  h-2 rounded-full bg-white"></div>}
                </div>
                <p className="text-sm text-secondary-700 font-medium">{t("product:produceForStock")}</p>
              </div>
            </div>
          )}
          {customerList?.isLoading ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full  min-h-[86px]" />
          ) : (
            <div className="flex flex-col w-full ">
              <div className="flex w-full min-h-[86px] max-h-[86px] border-b border-[#E4E7EC] pb-5 gap-x-8">
                <span className="flex w-[33%]">
                  <Input label={t("product:orderCode")} theme={"product"} disabled={true} value={`${orderNo}`} />
                </span>
                <span className="flex w-[33%]">
                  <Selector
                    label={t("product:customer")}
                    items={customerList?.data?.data?.map((item) => {
                      return { label: item?.name, value: item?.id };
                    })}
                    theme={"product"}
                    placeholder={t("product:selectCustomer")}
                    onChange={(e) => {
                      setCustomer(customerList?.data?.data?.find((item) => item?.id === e));
                    }}
                    disabled={selectedRadio != "order" ? true : false}
                    value={customer?.id}
                  />
                </span>
                <span className="flex w-[33%]">
                  <Input
                    label={t("product:customerOrderCode")}
                    theme={"product"}
                    onChange={(e) => {
                      setCustomerOrderNo(e.target.value);
                    }}
                    value={customerOrderNo}
                  />
                </span>
              </div>
            </div>
          )}

          {productList?.isLoading ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full h-full" />
          ) : (
            <div className="flex w-full h-[55vh] border  border-[#E4E7EC] rounded-t-xl">
              <div className="flex flex-col h-full w-[420px] min-w-[420px] max-w-[420px] border-r border-[#EAECF0] gap-y-5">
                <div className="flex min-h-[40px] max-h-[40px] h-[40px] bg-[#F9FAFB] rounded-tl-xl border-b border-[#EAECF0] py-2 px-6 gap-x-2 items-center">
                  <p className="text-[#101828] font-semibold text-sm">{t("product:allProduct")}</p>
                  <Badgets colorType={"outline-warning"} size={"sm"} label={productList?.data?.data?.length || 0} />
                </div>
                <div className="flex gap-x-3 px-6 w-full min-h-[40px] max-h-[40px] h-[40px]">
                  <SearchInput setSearchVal={setSearchQuery} theme={"product"} />
                  <Button colorType={"secondary-gray"} size={"md"} iconLeft={<FiltersIcon />} />
                </div>
                <div className="flex flex-col  w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide ">
                  {FilteredProducts?.map((item, index) => {
                    return (
                      <div className="flex items-center justify-between even:bg-secondary-100   shadow-xs pr-6  ">
                        <div key={index} className="flex justify-center items-center w-full min-h-[136px] max-h-[136px] h-[136px]  ">
                          <CreateOrderPageProductCard item={item} />
                        </div>

                        <div className="flex items-center justify-center min-w-[36px] max-w-[36px] w-[36px]">
                          <Button
                            iconLeft={<PlusIcon />}
                            colorType={"secondary-gray"}
                            size={"sm"}
                            onClick={() => {
                              setSelectedProduct(item);
                              setIsOpenNewPorductModal(true);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col min-h-full w-[80%] gap-y-5 relative">
                <div className="flex min-h-[40px] max-h-[40px] h-[40px] bg-[#F9FAFB] rounded-tr-xl border-b border-[#EAECF0] py-2 px-6 gap-x-2 items-center">
                  <p className="text-[#101828] font-semibold text-sm">{t("product:addedProducts")}</p>
                  <Badgets colorType={"outline-warning"} size={"sm"} label={isAddedProducts?.length} />
                </div>
                <div className="flex flex-col w-full h-[73%] px-6 overflow-y-auto overflow-x-hidden scrollbar-hide pb-6 ">
                  {/* table header */}
                  <div className="flex max-h-[26px] min-h-[26px] h-[26px] w-full sticky top-0 bg-white">
                    {tableHeadItems.map((item, i) => (
                      <div
                        key={i}
                        className={`flex border-b border-r border-[#E4E7EC] items-center  px-3 pb-2 w-[${
                          i === 0 ? "30%" : i === 1 || i === 2 ? "15%" : i === 3 ? "13%" : i === 4 ? "18%" : i === 5 ? "9%" : ""
                        }] ${i === 5 ? "border-r-0" : ""} ${i === 0 ? "justify-start" : "justify-center"}`}
                      >
                        <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                      </div>
                    ))}
                  </div>
                  {isAddedProducts?.map((a, index) => {
                    let item;
                    if (a?.name) {
                      item = a;
                    } else {
                      item = a?.product;
                    }
                    return (
                      <div key={index} className="flex w-full items-center border-b h-[80px] min-h-[80px] relative">
                        {/* tablo 1.kolon */}
                        <div className="flex gap-x-3 h-full items-center justify-start w-[30%] py-4 pr-4 border-r border-[#E4E7EC]">
                          {item?.image ? (
                            <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(item?.image)} />
                          ) : (
                            <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden mr-3">
                              <NoAvatar name={item?.name || "--"} rounded={4} theme={"product"} />
                            </div>
                          )}

                          <div className="flex flex-col gap-y-1 justify-center">
                            <p className="text-[#101828] font-semibold text-md">{item?.name}</p>
                            <p className="text-[#475467] text-sm">
                              {t("product:stock")}: {item?.stockCode}
                            </p>
                          </div>
                        </div>
                        {/* tablo 2.kolon */}
                        <div className="flex flex-col h-full py-3  items-center justify-center w-[15%] border-r border-[#E4E7EC]">
                          <p className="text-[#344054] font-medium text-sm">{a?.orderedQuantity}</p>
                          <p className="text-[#667085] text-xs">{t("product:pieces")}</p>
                        </div>
                        {/* tablo 3.kolon */}
                        <div className="flex h-full py-3  items-center justify-center w-[15%] border-r border-[#E4E7EC]">
                          <p className="text-[#344054] font-medium text-sm">{moment(a?.deliveryDate).format("DD.MM.YYYY")}</p>
                        </div>
                        {/* tablo 4.kolon */}
                        <div className="flex w-[13%] items-center justify-center h-full py-3 border-r border-[#E4E7EC]">
                          <p className="text-[#344054] font-medium text-sm">
                            {currencyIcon(a?.currency)} {a?.productPrice}
                          </p>
                        </div>
                        {/* tablo 5.kolon */}
                        <div className="flex w-[18%] items-center justify-center h-full py-3 border-r border-[#E4E7EC]">
                          <p className="text-[#344054] font-medium text-sm">
                            {currencyIcon(a?.currency)} {a?.productPrice * a?.orderedQuantity}
                          </p>
                        </div>
                        {/* tablo 6.kolon */}
                        <div className="flex w-[9%] h-full py-3 justify-center items-center relative">
                          <Button
                            colorType={"secondary-gray"}
                            iconLeft={<Threedots color={"#000"} />}
                            onClick={() => toggleDropdown(item?.id, item?.deliveryDate)}
                            size={"sm"}
                          />
                          {openDropdowns[item?.id]?.[item?.deliveryDate] && (
                            <div className="flex-col absolute w-[240px] min-w-[240px] max-w-[240px] h-fit bg-white border top-[54px] right-[16px] z-[110] rounded-lg px-[6px] py-1">
                              {menuOptions?.map((items, index) => {
                                return (
                                  <button
                                    onClick={async () => {
                                      toggleDropdown(item?.id, item?.deliveryDate);
                                      setSelectedOrder(a);
                                      if (items.value === "delete") {
                                        if (a?.name) {
                                          setIsAddedProducts((prev) => prev.filter((product) => product.id !== a.id));
                                        } else {
                                          const response = await orderService.controlPartial(a?.id);

                                          if (response?.length > 0) {
                                            setIsOpenDeleteModal(true);
                                          } else {
                                            setIsAlert(true);
                                          }
                                        }
                                      } else {
                                        setIsOpenNewPorductModal(true);
                                        setSelectedProduct(item);
                                        setEditOrder(a);
                                      }
                                    }}
                                    key={index}
                                    type="button"
                                    className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                                      items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                                    }`}
                                  >
                                    {items?.icon}
                                    <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>
                                      {items.label}
                                    </p>
                                  </button>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {isAddedProducts?.length > 0 && (
                    <div className="flex absolute bottom-3 right-0 min-h-[72px] max-h-[72px] h-[72px] px-6 w-full">
                      <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] bg-[#B54708] shadow-lg rounded-xl justify-between items-center">
                        <div className="flex h-full py-3 px-4 gap-x-4 items-center">
                          <div className="flex min-w-[64px] max-w-[64px] min-h-[48px] max-h-[48px] relative">
                            <img
                              className="absolute z-[10] top-[8px] left-[-2px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                              src={generateFileUrl(isAddedProducts?.[0]?.image)}
                            />
                            <img
                              className="absolute z-[20] top-[-2px] left-[10px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                              src={generateFileUrl(isAddedProducts?.[1]?.image)}
                            />
                            <img
                              className="absolute z-[30] bottom-[-2px] right-[-2px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                              src={generateFileUrl(isAddedProducts?.[2]?.image)}
                            />
                          </div>
                          <div className="flex gap-x-1 items-center">
                            <p className="text-[#FFFFFF] font-semibold text-xl">{isAddedProducts?.length}</p>
                            <p className="text-[#FFFFFF] font-normal text-md">{t("product:productsss")}</p>
                          </div>
                        </div>
                        <div className="flex py-3 text-[#FFFFFF] font-semibold text-lg">
                          <div className="flex px-5 items-center justify-center border-r border-[#FFFFFF4D]">{t("product:totalAmount")}</div>
                          <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                            <p className="text-xs font-medium">{t("product:orderQuantity")}</p>
                            <p>{totalOrderAmount}</p>
                          </div>
                          <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                            <p className="text-xs font-medium">{t("product:totalSales")}</p>
                            <p>
                              {currencyIcon(isAddedProducts?.[0]?.currency)} {totalCost}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={isOpenNewPorductModal}
        setIsOpen={setIsOpenNewPorductModal}
        modalTitle={editOrder ? "Sipariş Detayını Düzenle" : t("product:addProductt")}
        width={400}
        children={
          <LegacyAddProduct
            editOrder={editOrder}
            selectedProduct={selectedProduct}
            setIsOpenNewPorductModal={setIsOpenNewPorductModal}
            setIsAddedProducts={setIsAddedProducts}
            isAddedProducts={isAddedProducts}
          />
        }
      />
      <CustomModal
        modalTitle={t("product:deleteProduct")}
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        children={
          <DeleteOrderProduct
            selectedOrder={selectedOrder}
            id={selectedOrder?.id}
            setIsOpen={setIsOpenDeleteModal}
            setIsAlert={setIsAlert}
            detailsLenght={data?.details?.length}
            refetch={refetch}
          />
        }
      />
      <AlertModal
        setIsOpen={setIsAlert}
        isOpen={isAlert}
        title={t("product:deleteProduct")}
        icon={<DeleteIcon />}
        subTitle={t("product:thereAreNo")}
        applyButton={() => {
          alertDeleted(selectedOrder?.id);
        }}
        label={"Sil"}
      />
    </>
  );
};

export default EditOrderFirstStep;
