import React, { useState } from "react";
import { Button, Input } from "../../../components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Toast } from "../../../utils/toastify/toast";

const UpdateWorkOrderQuantityModal = ({ updateWorkOrderData, setUpdateWorkOrderQuantityModal }) => {
  const { t } = useTranslation();
  const [newWorkOrderQuantity, setNewWorkOrderQuantity] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    const newQuantity = parseInt(newWorkOrderQuantity, 10);

    if (!newQuantity && newQuantity !== 0) {
      Toast("error", "Lütfen yeni iş emri miktarı değerini giriniz.");
    } else if (newQuantity > (updateWorkOrderData?.desiredQuantity || 0)) {
      Toast("error", "Toplam iş emri miktarını artıramazsınız. Yeni bir iş emri oluşturun.");
    } else if (newQuantity < (updateWorkOrderData?.finishedQuantity || 0)) {
      Toast("error", `Yeni iş emri miktarı, yapılan miktardan küçük olamaz.`);
    } else {
      console.log("Yeni İş Emri Miktarı:", newWorkOrderQuantity);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col w-full h-full gap-y-8 pt-8">
      <div className="flex flex-col w-full h-full gap-y-6">
        <div className="flex flex-col gap-y-3 w-full">
          <p className="flex w-[200px] truncate text-[#B54708] font-semibold text-xl">
            {updateWorkOrderData?.type === "order" ? `WO-${updateWorkOrderData?.workOrderNo}` : `SWO-${updateWorkOrderData?.stockWorkOrderNo}`}
          </p>
          <div className="flex w-full gap-x-6">
            <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-1/3">
              <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs truncate">
                {t("product:workOrderQuantity")}
              </div>
              <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                {updateWorkOrderData?.desiredQuantity || 0}
                <p className="text-[#475467] text-xs mb-[2px]">{t("product:pieces")}</p>
              </div>
            </div>
            <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-1/3">
              <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs truncate">
                {t("product:quantityProduced")}
              </div>
              <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                {updateWorkOrderData?.finishedQuantity || 0}
                <p className="text-[#475467] text-xs mb-[2px]">{t("product:pieces")}</p>
              </div>
            </div>
            <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-1/3">
              <div className="flex w-full min-h-[26px] h-[26px] max-h-[26px] justify-center items-center border-b border-[#E4E7EC] rounded-t-lg bg-[#F2F4F7] px-3 py-1 text-[#475467] font-medium text-xs truncate">
                {t("product:remainingOrders")}
              </div>
              <div className="flex px-3 py-2 gap-x-1 items-end justify-center">
                {updateWorkOrderData?.desiredQuantity - updateWorkOrderData?.finishedQuantity || 0}
                <p className="text-[#475467] text-xs mb-[2px]">{t("product:pieces")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <Input
            icon={<QtyIcon />}
            theme={"product"}
            label={t("product:newWorkOrderQuantity")}
            onChange={(e) => {
              setNewWorkOrderQuantity(e.target.value);
            }}
            value={newWorkOrderQuantity}
          />
        </div>
      </div>
      <div className="flex w-full h-11 min-h-11 max-h-11 gap-x-3">
        <Button
          colorType={"secondary-gray"}
          size={"lg"}
          label={t("buttons:stop")}
          onClick={() => {
            setUpdateWorkOrderQuantityModal(false);
          }}
        />
        <Button colorType={"primary-product"} size={"lg"} label={t("buttons:updateQuantity")} type="submit" />
      </div>
    </form>
  );
};

export default UpdateWorkOrderQuantityModal;

const QtyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.1875 2.5H5C3.61929 2.5 2.5 3.61929 2.5 5V15C2.5 16.3807 3.61929 17.5 5 17.5H15C16.3807 17.5 17.5 16.3807 17.5 15V5C17.5 3.61929 16.3807 2.5 15 2.5H12.8125M7.1875 2.5V6.78571C7.1875 6.88277 7.29093 6.94484 7.37658 6.89916L10 5.5L12.6234 6.89916C12.7091 6.94484 12.8125 6.88278 12.8125 6.78571V2.5M7.1875 2.5H12.8125"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
