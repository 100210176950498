import React, { useState, useEffect, useRef } from "react";
import OrderOperationGrid from "./components/OrderOperationGrid";
import OrderPurchaseCard from "./components/OrderPurchaseCard";
import NewOrderCard from "./components/NewOrderCard";

const OrderReceipeSection = ({ data, t, setItems, items }) => {
  const [tableHeadItemsLength, settableHeadItemsLength] = useState(0);

  const dataManipulate = () => {
    settableHeadItemsLength(items?.length);
  };

  const divRefs = useRef([]);

  const handleScroll = (index) => {
    const currentScrollLeft = divRefs.current[index]?.scrollLeft;
    divRefs.current.forEach((div, idx) => {
      if (div && idx !== index) {
        div.scrollLeft = currentScrollLeft;
      }
    });
  };

  const tableHeadItems = [];
  for (let i = 0; i < Math.max(1, tableHeadItemsLength); i++) {
    tableHeadItems.push({ title: `${i + 1}.${t("product:operation")}`, id: i });
  }

  useEffect(() => {
    if (items) {
      dataManipulate();
    }
  }, [items]);

  return (
    <div className="w-full h-full">
      <div className="w-full border-secondary-200 text-lg font-semibold flex items-center gap-x-2.5">{t("product:receipe")}</div>
      <div className="w-full h-full max-h-[175px] border shadow-md rounded-xl border-[#D0D5DD] flex flex-col relative mt-1">
        <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
          <div className="flex items-center justify-center max-w-[356px] min-w-[356px] h-full border-r border-[#EAECF0] text-sm font-semibold text-[#344054]">
            {t("product:orders")}
          </div>

          <div
            ref={(el) => (divRefs.current[0] = el)}
            onScroll={() => handleScroll(0)}
            className="flex items-center w-full h-full overflow-x-auto overflow-y-hidden"
          >
            <div className="min-w-[302px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200">
              {t("product:purchased")}
            </div>
            {tableHeadItems.map((item, index) => (
              <div
                key={index}
                className="min-w-[302px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200"
              >
                {item?.title}
              </div>
            ))}
            <div className="min-w-[250px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200">
              {t("product:addOperation")}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full h-[79vh] overflow-y-auto scrollbar-hide">
          <div className="flex w-full h-[127px]">
            <div className="max-w-[356px] min-w-[356px] h-full px-4 py-2 border-r max-h-[127px]">
              {/* <OrderListCard showMenuOptions={false} order={data} /> */}
              <NewOrderCard showMenuOptions={false} t={t} order={data} />
            </div>

            <div
              ref={(el) => (divRefs.current[1] = el)}
              onScroll={() => handleScroll(1)}
              id="content-wrapper"
              className="w-full h-full overflow-x-auto scrollbar-hide flex items-center border-gray-200"
            >
              <div className="min-w-[302px] max-w-[302px] h-full flex border-r justify-center border-gray-200 px-2 py-2">
                <span className="w-full max-h-[127px]">
                  <OrderPurchaseCard
                    status={data?.rawMaterial?.status}
                    qualityStatus={data?.rawMaterial?.qualityStatus}
                    source={data?.rawMaterial?.material?.source}
                    acceptedQuantity={data?.rawMaterial?.acceptedQuantity}
                    rejectedQuantity={data?.rawMaterial?.rejectedQuantity}
                    type={data?.rawMaterial?.material?.type}
                    t={t}
                    orderedQuantity={data?.rawMaterial?.orderedQuantity}
                  />
                </span>
              </div>
              <OrderOperationGrid setItems={setItems} items={items} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderReceipeSection;
