import React from 'react'
import Button from '../buttons/Button';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { purchaseService } from '../../services/purchase.service';
import { Toast } from '../../utils/toastify/toast';

const MaterialCompletePurchaseModal = ({ item, onClose, refetchPage }) => {
    const { t } = useTranslation()

    const { data, refetch } = useQuery({
        queryKey: ["operationDetailCycle"],
        queryFn: async () =>
            await purchaseService?.unitInformationForCompleting(item?.id),
        enabled: true,
        retry: 0,
    });

    const onSubmit = async () => {
        await purchaseService
            .earlyCompleteTransaction(item?.id)
            .then((response) => {
                if (response?.data?.code == 0) {
                    onClose()
                    Toast("success", response?.data?.message);
                    refetchPage()
                } else {
                    Toast("error", response?.data?.message);
                }
            });
    };

    return (
        <div className='flex flex-col items-start w-full'>
            <div className='flex items-center flex-col justify-center w-full'>
                <div className='w-12 h-12 min-w-12 min-h-12 rounded-md bg-[#FEE4E2] flex items-center justify-center mt-3'>
                    <CompleteIcons />
                </div>
                <p className='text-lg font-semibold text-secondary-900 mt-2'>{t("product:completePurchase")}</p>
                <p className='text-sm text-secondary-500 text-center mt-2'>{t("product:allPurchase")}</p>
            </div>
            <div className='flex flex-row items-center w-full justify-between mt-8'>
                <p className='text-md text-secondary-700 font-semibold'>{t("product:totalActiveRequest")}</p>
                <p className='text-md text-secondary-600'>{data?.orderedUnit || "0"} {item?.unitType}</p>
            </div>
            <div className='flex flex-row items-center w-full justify-between mt-2'>
                <p className='text-md text-secondary-700 font-semibold'>{t("product:quantityCompleted")}</p>
                <p className='text-md text-secondary-600'>{data?.acceptedUnit || "0"} {item?.unitType}</p>
            </div>
            <div className='w-full flex flex-row items-center mt-8 gap-x-3'>
                <Button onClick={() => onClose()} label={t("buttons:stop")} colorType={"secondary-gray"} size={"md"} />
                <Button onClick={()=>onSubmit()} type={"button"} label={t("product:competedss")} colorType={"primary-error"} size={"md"} />
            </div>
        </div>
    )
}

export default MaterialCompletePurchaseModal

export const CompleteIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};