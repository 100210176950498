import React, { useEffect, useMemo, useState } from "react";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Toast } from "../../utils/toastify/toast";
import { equipmentService } from "../../services";
import { TableArrowLeft, UpuCCount } from "../../assets/icons/machineIcons";
import { CalendarIcon } from "../../assets/icons/stepHeaderIcons";
import CCoundAndEnergyChart from "./Tabs/CCoundAndEnergyChart";
import CCountAndEnergyChart2 from "./Tabs/CCountAndEnergyChart2";
import { Arrow, Loader } from "../../assets/icons/stepsIcons";
import EquipmentCCount from "./Twin/components/EquipmentCCount";
import { reportService } from "../../services/report.service";
import { Button } from "../../components";
import { useStore } from "../../hooks/useStores";
import NoData from "../../steps/step-navigation/components/NoData";
import useWindowDimensions from "../../hooks/useWindowDimension";

const CCount = () => {
  const { settings } = useStore();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("total_cCount");
  const [energyData, setEnergyData] = useState([]);
  const [Datayear, setDataYear] = useState();
  const { height } = useWindowDimensions();
  const [selectedSecondCategory, setSelectedSecondCategory] =
    useState("total_kwh");
  const [selectedLegend, setSelectedLegend] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [datas, setDatas] = useState({});
  const [month, setMonth] = useState(moment().format("YYYY-MM"));
  const [selectedNode, setSelectedNode] = useState(null);

  const monthSelected = (month) => {
    switch (month) {
      case "1":
        return `${t("legends:january")}`;
      case "2":
        return `${t("legends:february")}`;
      case "3":
        return `${t("legends:march")}`;
      case "4":
        return `${t("legends:april")}`;
      case "5":
        return `${t("legends:may")}`;
      case "6":
        return `${t("legends:june")}`;
      case "7":
        return `${t("legends:july")}`;
      case "8":
        return `${t("legends:august")}`;
      case "9":
        return `${t("legends:september")}`;
      case "10":
        return `${t("legends:october")}`;
      case "11":
        return `${t("legends:november")}`;
      case "12":
        return `${t("legends:december")}`;
    }
  };

  const months = useMemo(
    () => [
      { value: 1, name: `${t("legends:january")}` },
      { value: 2, name: `${t("legends:february")}` },
      { value: 3, name: `${t("legends:march")}` },
      { value: 4, name: `${t("legends:april")}` },
      { value: 5, name: `${t("legends:may")}` },
      { value: 6, name: `${t("legends:june")}` },
      { value: 7, name: `${t("legends:july")}` },
      { value: 8, name: `${t("legends:august")}` },
      { value: 9, name: `${t("legends:september")}` },
      { value: 10, name: `${t("legends:october")}` },
      { value: 11, name: `${t("legends:november")}` },
      { value: 12, name: `${t("legends:december")}` },
    ],
    []
  );

  const getMonthName = (monthValue) => {
    switch (monthValue) {
      case "01":
        return `${t("legends:january")}`;
      case "02":
        return `${t("legends:february")}`;
      case "03":
        return `${t("legends:march")}`;
      case "04":
        return `${t("legends:april")}`;
      case "05":
        return `${t("legends:may")}`;
      case "06":
        return `${t("legends:june")}`;
      case "07":
        return `${t("legends:july")}`;
      case "08":
        return `${t("legends:august")}`;
      case "09":
        return `${t("legends:september")}`;
      case "10":
        return `${t("legends:october")}`;
      case "11":
        return `${t("legends:november")}`;
      case "12":
        return `${t("legends:december")}`;
      default:
        return "";
    }
  };

  const years = [
    { value: 2020, name: "2020" },
    { value: 2021, name: "2021" },
    { value: 2022, name: "2022" },
    { value: 2023, name: "2023" },
    { value: 2024, name: "2024" },
  ];

  const changeMonthOfDate = (originalDate, newMonth) => {
    const newDate = new Date(originalDate);
    newDate.setMonth(Number(newMonth) - 1);
    return newDate;
  };

  const changeMonthOfYear = (originalDate, newYear) => {
    const current = Number(moment(month).format("YYYY"));
    const selected = Number(newYear);
    if (current !== selected) {
      equipmentService
        .getConsumptionDataForCCount(
          moment().year(newYear).format("YYYY-MM-DD")
        )
        .then((res) => {
          setDatas(res?.data);
        })
        .catch((err) => {
          Toast("error", err?.response?.data?.message);
        });
    }
    const newDate = new Date(originalDate);
    newDate.setFullYear(newYear);
    Number(newYear) === 2024 ? newDate.setMonth(0) : newDate.setMonth(11);
    setDataYear(newYear);
    return String(newDate);
  };

  const handleData = async (name) => {
    setLoading(true);
    if (name) {
      const newMonth = changeMonthOfDate(month, name);
      setMonth(newMonth);
    } else {
    }

    await equipmentService
      .getConsumptionDataForCCount(moment(month).format("YYYY-MM-DD"))
      .then((res) => {
        setSelectedLegend(
          res.data?.operationNodeChart?.find((item) => {
            return item.month == moment(month).format("M");
          })?.operations[0]?.processId
        );
        setDatas(res?.data);
      })
      .catch((err) => {
        Toast("error", err?.response?.data?.message);
      });
    setLoading(false);
  };

  const processData = datas?.operationNodeChart
    ?.find((item) => {
      return item.month == moment(month).format("M");
    })
    ?.operations?.find((op) => {
      return op.processId == selectedLegend;
    });
  const sortData = processData?.equipments.sort(
    (a, b) => b?.total_cCount - a?.total_cCount
  );

  const transformedData = sortData?.map((item) => ({
    id: item?.processId,
    name: item?.name,
    nodeId: item?.nodeId,
    value: item?.total_kwh,
  }));
  const monthlyData = datas?.operationNodeChart?.find((item) => {
    return item.month == moment(month).format("M");
  });

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    const nodeIdToUse = selectedNode
      ? selectedNode.nodeId
      : transformedData?.[0]?.nodeId;
    if (nodeIdToUse) {
      reportService
        .getBarChartReportCCount(
          nodeIdToUse,
          moment()
            .year(Datayear || 2024)
            .format("YYYY-MM-DD")
        )
        .then((res) => {
          setEnergyData(res?.data);
        });
    }
  }, [selectedNode, sortData]);

  useEffect(() => {
    const nodeIdToUse = selectedNode
      ? selectedNode.nodeId
      : transformedData?.[0]?.nodeId;

    if (nodeIdToUse) {
      reportService
        .getBarChartReportKwh(
          nodeIdToUse,
          moment()
            .year(Datayear || 2024)
            .format("YYYY-MM-DD")
        )
        .then((res) => {
          setEnergyData(res?.data);
        });
    }
  }, [selectedNode, sortData]);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="w-full flex flex-col md:h-full">
          <div className="w-full h-[56px] xs:h-[124px] flex items-center  dark:text-[#FAFAFA] xs:flex-wrap">
            <span className="mr-3 md:flex sm:flex xs:flex hidden">
              <Button
                colorType={"tertiary-gray"}
                iconLeft={<HumburgerIcon />}
                size={"md"}
                onClick={() => settings.setIsActive(true)}
              />
            </span>
            <UpuCCount />
            <div className="ml-auto flex flex-row items-center xs:w-full xs:border-t xs:pt-3">
              <div className="xs:w-full items-center  gap-3 flex xs:justify-between">
                <button
                  onClick={() => {
                    setMonth(moment(month).subtract(1, "day").startOf("month"));
                  }}
                  className="w-[36px]  flex h-9 p-2 justify-center items-center bg-white rounded-lg border rotate-270 border-secondary-300"
                >
                  <TableArrowLeft size={20} color="#344054" />
                </button>

                <button
                  onClick={() => {}}
                  className="flex px-4 py-2 justify-center items-center bg-white h-9 gap-2 self-stretch rounded-lg border border-secondary-300"
                >
                  <CalendarIcon width={20} height={20} color="#344054" />
                  <span className="text-sm font-medium text-secondary-700 truncate flex relative items-center">
                    {moment(month).format("YYYY")}
                    <Arrow size={14} color={"#000"} />
                    <select
                      onChange={async ({ target }) => {
                        setLoading(true);
                        const updateDate = changeMonthOfYear(
                          month,
                          target?.value
                        );
                        setMonth(updateDate);
                        setLoading(false);
                      }}
                      className="opacity-0 absolute inset-0 -bottom-3  ml-4"
                    >
                      {years?.map((item,index) => {
                        return (
                          <option
                            key={index}
                            className="text-xs h-[20px]"
                            // value={item?.value}
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </span>

                  <span className="text-sm font-medium text-secondary-700 truncate flex relative items-center">
                    {getMonthName(moment(month).format("MM"))}
                    {/* <Arrow size={14} color={"#000"} /> */}
                    <select
                      onChange={async ({ target }) => {
                        setLoading(true);
                        const updateDate = changeMonthOfDate(
                          month,
                          target?.value
                        );
                        setMonth(updateDate);
                        setLoading(false);
                      }}
                      className="opacity-0 absolute inset-0 -bottom-3 ml-4"
                    >
                      {months?.map((item) => {
                        return (
                          <option
                            className="text-xs h-[20px]"
                            key={item?.value}
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </span>
                </button>
                <button
                  onClick={() => {
                    setMonth(moment(month).add(1, "month").startOf("month"));
                  }}
                  className="w-9 flex h-9 p-2 justify-center items-center rotate-180 bg-white rounded-lg border border-secondary-300"
                >
                  <TableArrowLeft size={20} color="#344054" />
                </button>
              </div>
            </div>
          </div>
          <hr className=" mt-0.5 border-[0.5px] border-secondary-200 w-full dark:border-[#202020]" />
          <div className="flex flex-col h-[800px] md:h-full overflow-y-scroll scrollbar-hide overflow-x-hidden">
            <div className="w-full h-full my-5">
              <CCoundAndEnergyChart
                t={t}
                charts={"c-count"}
                handleData={handleData}
                totalData={datas?.companyOperationChart}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setSelectedLegend={setSelectedLegend}
                selectedSecondCategory={selectedSecondCategory}
                setSelectedSecondCategory={setSelectedSecondCategory}
                setSelectedNode={setSelectedNode}
                month={month}
                grid={"energy"}
                monthlyData={datas?.operationNodeChart?.find((item) => {
                  return item.month == moment(month).format("M");
                })}
                processData={datas?.operationNodeChart
                  ?.find((item) => {
                    return item.month == moment(month).format("M");
                  })
                  ?.operations?.find((op) => {
                    return op.processId == selectedLegend;
                  })}
                yearlyProcessData={datas?.operationNodeChart?.map((month) => {
                  return month?.operations?.find((op) => {
                    return (
                      op.processId == selectedLegend ||
                      op.processId == "00000000-0000-0000-0000-000000000000"
                    );
                  });
                })}
              />
              <div className="mt-5">
                <CCountAndEnergyChart2
                  t={t}
                  charts={"c-count"}
                  handleData={handleData}
                  totalData={datas?.operationNodeChart}
                  selectedSecondCategory={selectedSecondCategory}
                  setSelectedSecondCategory={setSelectedSecondCategory}
                  setSelectedLegend={setSelectedLegend}
                  selectedLegend={selectedLegend}
                  grid={"energy"}
                  month={month}
                  monthlyData={datas?.operationNodeChart?.find((item) => {
                    return item.month == moment(month).format("M");
                  })}
                  processData={datas?.operationNodeChart
                    ?.find((item) => {
                      return item.month == moment(month).format("M");
                    })
                    ?.operations?.find((op) => {
                      return op.processId == selectedLegend;
                    })}
                  yearlyProcessData={datas?.operationNodeChart?.map((month) => {
                    return month?.operations?.find((op) => {
                      return (
                        op.processId == selectedLegend ||
                        op.processId == "00000000-0000-0000-0000-000000000000"
                      );
                    });
                  })}
                  selectedCategory={selectedSecondCategory}
                  setSelectedCategory={setSelectedSecondCategory}
                  setSelectedNode={setSelectedNode}
                  chartType="onlyEnergy"
                />
              </div>
              {/* {selectedNode &&
              selectedNode != "00000000-0000-0000-0000-000000000000" && (
                <EquipmentCCount
                  selectedNode={selectedNode}
                  selectedLegend={selectedLegend}
                  energyData={energyData}
                  xAsisData={energyData?.map((row) =>
                    monthSelected(`${row?.month}`)
                  )}
                  month={month}
                />
              )} */}
              {monthlyData?.operations?.length >= 1 ? (
                <>
                  {selectedNode ? (
                    <EquipmentCCount
                      selectedNode={selectedNode}
                      selectedLegend={selectedLegend}
                      energyData={energyData}
                      xAsisData={energyData?.map((row) =>
                        monthSelected(`${row?.month}`)
                      )}
                      month={month}
                    />
                  ) : (
                    <EquipmentCCount
                      selectedNode={transformedData?.[0]}
                      selectedLegend={selectedLegend}
                      energyData={energyData}
                      xAsisData={energyData?.map((row) =>
                        monthSelected(`${row?.month}`)
                      )}
                      month={month}
                    />
                  )}
                </>
              ) : (
                <>
                  <div className="w-full flex min-h-full gap-x-4  h-full  border-[#D0D5DD] border-[1px] rounded-lg mt-5">
                    <div
                      style={{
                        height: "100%",
                        minHeight: (height - 200) / 2,
                      }}
                      className="w-full h-full flex items-center justify-center"
                    >
                      <NoData
                        theme={"machine"}
                        header={t("label:noData")}
                        button={false}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CCount;
