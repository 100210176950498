import React, { useEffect, useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import {
  JpgIcon,
  PointIcon,
  QuantityAcceptedIcon,
  QuantityDeclineIcon,
  QuantityIcons,
} from "../../assets/icons/productIcons";
import { orderService } from "../../services/order.service";
import { useTranslation } from "react-i18next";
import moment from "moment";

const TransactionDetailModal = ({ id }) => {
  const [details, setDetails] = useState({});
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();

  const handleData = async () => {
    setloading(true);
    await orderService.getOrderJunctionDetail(id).then((res) => {
      setDetails(res.data);
    });
    setloading(false);
  };

  useEffect(() => {
    handleData();
  }, []);

  const DetailItem = ({ label }) => (
    <div className="flex px-4 py-2 border-b border-[#EAECF0] min-h-[52px] max-h-[52px] items-center">
      {label && <span>{label}</span>}
    </div>
  );

  const DateItem = ({ date1, date2 }) => (
    <div className="flex w-full border-b border-[#EAECF0] min-h-[52px] max-h-[52px]">
      <div className="flex border-r w-1/2 h-full px-4 py-2 items-center justify-center">
        {date1}
      </div>
      <div className="flex w-1/2 h-full px-4 py-2 items-center justify-center">
        {date2}
      </div>
    </div>
  );

  return (
    <div className="flex w-full h-full border border-[#EAECF0] rounded-lg shadow-sm mt-6">
      <div className="flex flex-col border-r border-[#EAECF0] text-xs text-[#344054] font-medium">
        <DetailItem label={t("addProcess:type")} />
        <DetailItem label={t("addProcess:processName")} />
        <DetailItem label={t("addProcess:stationName")} />
        <DetailItem label={t("addProcess:operatorName")} />
        <DetailItem label={t("product:startEnd")} />
        <DetailItem label={t("product:actualEstimated")} />
        <DetailItem label={"upu.point"} />
      </div>

      <div className="flex flex-col border-r border-[#EAECF0] text-xs font-normal text-[#667085]">
        <DetailItem
          label={details?.internalOperation ? "Internal" : "External"}
        />
        <DetailItem label={details?.internalOperation?.name} />
        <DetailItem
          label={
            details?.station?.metadata?.brand +
            " " +
            details?.station?.metadata?.model
          }
        />
        <div className="flex px-4 py-2 border-b border-[#EAECF0] items-center gap-x-2 min-h-[52px] max-h-[52px]">
          <img
            src={generateFileUrl(details?.user?.avatar)}
            className="w-6 h-6 rounded-full"
          />
          {details?.user?.name + " " + details?.user?.lastName}
        </div>
        <DateItem
          date1={moment(details?.startDate).format("DD.MM.YY")}
          date2={moment(details?.endDate).format("DD.MM.YY")}
        />
        <DateItem
          date1={moment(details?.startDate).format("HH:mm")}
          date2={moment(details?.endDate).format("HH:mm")}
        />
        <div className="flex px-4 py-2 gap-x-1 min-h-[52px] max-h-[52px] items-center justify-center">
          <PointIcon />
          {details?.metadata?.upuPoint ? details?.metadata?.upuPoint : "0"}
        </div>
      </div>

      <div className="flex flex-col border-r border-[#EAECF0] text-xs text-[#344054] font-medium">
        <DetailItem label={t("product:productName")} />
        <DetailItem label={t("product:stockCode")} />
        <DetailItem label={t("product:orderCode")} />
        <DetailItem label={t("product:totalApproved")} />
        <DetailItem label={t("product:appendixTechnicial")} />
        <DetailItem label={t("product:estimatedActual")} />
        <DetailItem label={t("product:estimatedActualQuantity")} />
      </div>

      <div className="flex flex-col border-r border-[#EAECF0] text-xs font-normal text-[#667085]">
        <DetailItem label={details?.job?.product?.name} />
        <DetailItem label={details?.job?.product?.stockCode} />
        <DetailItem label={details?.job?.orderNo} />
        <div className="flex w-full border-b border-[#EAECF0] min-h-[52px] max-h-[52px]">
          <div className="flex flex-col border-r w-1/3 h-full px-4 py-2 items-center justify-center gap-y-1">
            <QuantityIcons />
            {details?.metadata?.madeWorkCount}
          </div>
          <div className="flex flex-col border-r w-1/3 h-full px-4 py-2 items-center justify-center gap-y-1">
            <QuantityAcceptedIcon />
            {details?.metadata?.acceptedWorkCount}
          </div>
          <div className="flex flex-col w-1/3 h-full px-4 py-2 items-center justify-center gap-y-1">
            <QuantityDeclineIcon />
            {details?.metadata?.rejectedWorkCount}
          </div>
        </div>
        <div className="flex w-full border-b border-[#EAECF0] min-h-[52px] max-h-[52px]">
          <div className="flex border-r w-1/2 h-full px-4 py-2 items-center flex-col justify-center gap-y-1">
            <JpgIcon />
            {details?.tecnicalDrawing}
          </div>
          <div className="flex w-1/2 h-full px-4 py-2 items-center flex-col justify-center gap-y-1">
            <JpgIcon />
            {details?.tecnicalDrawingCrop}
          </div>
        </div>
        <DateItem date1={"--"} date2={"--"} />
        <DateItem date1={"--"} date2={"--"} />
      </div>
    </div>
  );
};

export default TransactionDetailModal;
