import { instance } from "../libs/client";

class PurchaseService {
  async startPurchaseJunction(body) {
    return await instance.post("purchase/junction/start", body);
  }

  async finishPurchaseJunction(id, body) {
    return await instance.post(`purchase/junction/finish/${id}`, body);
  }

  async deleteSupplier(id) {
    return await instance.delete(`purchase/supplier/${id}`);
  }

  async purchaseQuality(id, body) {
    return await instance.post(`purchase/make/quality/${id}`, body);
  }

  async getSuppliersGroup() {
    return await instance.get(`purchase/supplier-group`);
  }

  async getSuppliersGroupWithSuppliers() {
    const { data } = await instance.get(`purchase/groups-supplier-supplier`);
    return data;
  }

  async deletePlannedPurchase(id) {
    return await instance.delete(`purchase/planned/delete/${id}`);
  }

  async deletePlannedPurchaseNew(type, id) {
    return await instance.delete(`purchase/${type}/${id}`);
  }

  async deletePurchase(id) {
    return await instance.delete(`purchase/${id}`);
  }

  async supplierListByGroup(groupId) {
    const { data } = await instance.get(`purchase/list-by-group/${groupId}`);
    return data;
  }

  async createSupplierGroup(body) {
    return await instance.post(`purchase/create/supplier-group`, body);
  }

  async purchaseTransactionsPlanned(id, type, searchVal) {
    const { data } = await instance.get(`purchase/planned/list/${id}/${type}/?${searchVal ? `&search=${searchVal}` : ""}`);
    return data;
  }

  async purchaseTransactionsPlannedScroll(id, type, page, take, searchVal) {
    const queryString = `${page ? `page=${page}` : ""}${take ? `&take=${take}` : ""}${searchVal ? `&search=${searchVal}` : ""}`;
    const { data } = await instance.get(`purchase/planned/list/${id}/${type}/?${queryString}`);
    return data;
  }

  async getPurchaseJunctionsForCompany() {
    const { data } = await instance.get(`purchase/junction/list/for/company`);
    return data;
  }

  async listSupplierGroupForCompany(companyData) {
    const { data } = await instance.get(`purchase/list/supplier-group/?${companyData ? `&company=${companyData}` : ""}`);
    return data;
  }

  async getPurchaseJunctionsList(id) {
    const { data } = await instance.get(`purchase/request/list/${id}`);
    return data;
  }

  async getPurchaseRequestDetails(id) {
    const { data } = await instance.get(`purchase/request/details/${id}`);
    return data;
  }

  async getQualityMaterialSupplyList() {
    const { data } = await instance.get(`purchase/junctions/count`);
    return data;
  }

  async createPlannedPurchaseJunction(data) {
    return await instance.post("purchase/junction/create/planned", data);
  }

  async updatePlanningJunctionList(data) {
    return await instance.post(`purchase/update/planning-order`, data);
  }

  async getQualityMaterialSupplySelectOperation(id) {
    const { data } = await instance.get(`purchase/operations/quality-junctions/${id}`);
    return data;
  }

  async getActiveWipOperation(id) {
    const { data } = await instance.get(`work-order/control/active/wip/operation/${id}`);
    return data;
  }

  // /control/active/wip/operation/:id

  // @Get('/operations/quality-junctions/:operationId')

  async getPurchaseJunctionsForSupplierGroup(id) {
    const { data } = await instance.get(`purchase/junction/list/for/supplier-group/${id}`);
    return data;
  }
  // async purchaseTransaction(data) {
  //   const { data } = await instance.post(`purchase/create/transactions/${data}`);
  //   return data;
  // }

  async purchaseTransaction(data) {
    return await instance.post("purchase/create/transactions", data);
  }

  async getServiceSupplierPastDataPagination(id, type, pagination) {
    console.log("pagination", pagination);
    const { data } = await instance.get(
      `purchase/supplier/pagination/past/${id}/${type}?page=${pagination.page}&search=${pagination.search}&take=${pagination.take}`
    );
    // const { data } = await instance.get(`purchase/supplier/past/3554c05f-9135-4932-8aa7-3e74b95816b8`);
    return data;
  }

  //new

  async updateSupplierGroup(id, body) {
    return await instance.put(`purchase/update/supplier-group/${id}`, body);
  }

  async updateTransactionOrderedUnit(id, body) {
    return await instance.put(`purchase/update/transaction/ordered-unit/${id}`, body);
  }

  async unitInformationForCompleting(id) {
    const { data } = await instance.get(`purchase/unit/information/for/completing/junction/${id}`);
    return data;
  }

  async listJunctionForWipOperation(id) {
    const { data } = await instance.get(`purchase/list/junction/for/wip/operation/${id}`);
    return data;
  }

  async earlyCompleteTransaction(id) {
    return await instance.post(`purchase/early/complete/transaction/${id}`);
  }

  async deleteSupplierGroup(id, body) {
    return await instance.put(`purchase/delete/supplier-group/${id}`, body);
  }

  async deleteAndMoveSupplierGroup(id, body) {
    console.log("idddds", id, "bodyyy", body);
    return await instance.put(`purchase/delete/move/supplier-group/${id}`, body);
  }
}
const purchaseService = new PurchaseService();
export { purchaseService };
