import React, { useState } from "react";
import { DeleteIcon, DotsHorizontalIcon, EditIcon, InfoCircleIcon } from "../../assets/icons/productIcons";
import { Button, CustomModal } from "../../components";
import NoAvatar from "../../components/avatar/NoAvatar";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import WorkOrderModal from "./modals/WorkOrderModal";
import { orderService } from "../../services/order.service";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import DeleteOrderProduct from "./modals/DeleteOrderProduct";
import { Toast } from "../../utils/toastify/toast";
import { useNavigate } from "react-router-dom";
import { FlipBackwardIcon, FlipForwardIcon, ListIcon } from "../../assets/icons/machineIcons";
import WipExitModal from "./modals/WipExitModal";
import { useTranslation } from "react-i18next";
import LegacyWipExitModal from "./modals/LegacyWipExitModal";

const CustomerDetailTable = ({ detail, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [isOpenWorkOrderModal, setIsOpenWorkOrderModal] = useState(false);
  const totalDesiredQuantity = detail?.partial?.reduce((acc, item) => acc + item.desiredQuantity, 0);
  const [isAlert, setIsAlert] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openWipExitModal, setOpenWipExitModal] = useState(false);
  const [isFirstModal, setIsFirstModal] = useState(true);

  const toggleDropdown = (id) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const currencyIcon = () => {
    switch (detail?.currency || "TRY") {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "RUB":
        return "₽";
      case "UAH":
        return "₴";
      case "TRY":
        return "₺";
      default:
        return "₺";
    }
  };
  const menuOptions = [
    { label: t("product:viewRelated"), value: "workOrder", icon: <ListIcon /> },
    {
      label: t("product:sendToProductionWip"),
      value: "sendToProduction",
      icon: <FlipBackwardIcon />,
    },
    {
      label: t("product:transferFrom"),
      value: "transferProduction",
      icon: <FlipForwardIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const alertDeleted = async (id) => {
    await orderService.deletePartials(id, "direct").then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsAlert(false);
        refetch();
      } else {
        Toast("error", res?.data?.message);
        setIsAlert(false);
      }
    });
  };

  return (
    <div onMouseLeave={() => setOpenDropdowns([])} className="flex w-full items-center border-b h-[80px] min-h-[80px] group">
      {/* tablo 1.kolon */}
      <div className="flex gap-x-2 items-center px-6 py-3 border-r h-full w-[320px] min-w-[320px] max-w-[320px] justify-start">
        {detail?.product?.image ? (
          <img className="w-[64px] h-[48px] rounded border border-[#0000001A] flex-none" src={generateFileUrl(detail?.product?.image)} />
        ) : (
          <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden">
            <NoAvatar name={detail?.product?.name || "--"} rounded={4} theme={"product"} />
          </div>
        )}
        <div className="flex flex-col w-0 flex-grow">
          <p className="text-[#101828] text-md font-semibold ml-1 truncate">{detail?.product?.name || "--"}</p>
          <span className="truncate text-[#B54708] font-medium text-xs px-1 py-[3px] bg-[#FFFAEB] rounded w-fit max-w-[200px]">
            {t("product:stock")}: {detail?.product?.stockCode || "--"}
          </span>
        </div>
      </div>
      {/* tablo 2.kolon */}
      <div className="border-r flex flex-col h-full py-4 items-center justify-center flex-1">
        <p className="text-sm font-medium text-[#344054]">{moment(detail?.deliveryDate).format("DD.MM.YYYY") || "--.--.----"}</p>
        <p className="text-[#667085] text-xs">{moment(detail?.deliveryDate).format("dddd") || "--.--.----"}</p>
      </div>
      {/* tablo 3.kolon */}
      <div className="border-r flex flex-col h-full py-4 items-center justify-center flex-1">
        <p className="text-sm font-medium text-[#344054]">{detail?.orderedQuantity || "0"}</p>
        <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
      </div>
      {/* tablo 4.kolon */}
      <div className="flex flex-col items-center justify-center py-4 border-r h-full flex-1">
        <p className="text-sm font-medium text-[#344054]">{totalDesiredQuantity || "0"}</p>
        <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
      </div>
      {/* tablo 5.kolon */}
      <div className="border-r flex flex-col h-full py-4 items-center justify-center flex-1">
        <p className="text-sm font-medium text-[#344054]">{detail?.finishedQuantity || "0"}</p>
        <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
      </div>
      {/* tablo 6.kolon */}
      <div className="border-r flex flex-col h-full py-4 items-center justify-center flex-1">
        <p className="text-sm font-medium text-[#344054]">{detail?.remainingQuantity || "0"}</p>
        <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
      </div>
      {/* tablo 7.kolon */}
      <div className="flex py-4 border-r h-full flex-1 justify-center items-center">
        <p className="text-sm font-medium text-[#344054]">
          {currencyIcon(detail?.product?.currency || "TRY")} {detail?.productPrice || "0"}
        </p>
      </div>
      {/* tablo 8.kolon */}
      <div className="border-r flex flex-col h-full py-4 items-center justify-center flex-1">
        <p className="text-sm font-medium text-[#344054]">
          {currencyIcon(detail?.product?.currency || "TRY")} {detail?.productPrice * detail?.orderedQuantity || "0"}
        </p>
      </div>
      {/* tablo 9.kolon */}
      <div className="flex flex-col h-full py-4 items-center justify-center basis-[72px] flex-none relative">
        <Button colorType={"tertiary-gray"} iconLeft={<DotsHorizontalIcon />} size={"md"} onClick={() => toggleDropdown(detail?.id)} />
        {openDropdowns[detail?.id] && (
          <div className="hidden flex-col absolute w-[280px] min-w-[280px] max-w-[280px] h-fit bg-white border top-[54px] right-[16px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {menuOptions?.map((items, index) => {
              return (
                <button
                  onClick={async () => {
                    setSelectedOrder(detail);
                    if (items.value === "sendToProduction") {
                      navigate(`/app/product/mrp/orders/management/planning/${detail?.id}`);
                    }
                    if (items.value === "delete") {
                      const response = await orderService.controlPartial(detail?.id);

                      if (response?.length > 0) {
                        setIsOpenDeleteModal(true);
                      } else {
                        setIsAlert(true);
                      }
                    }
                    if (items.value === "workOrder") {
                      setIsOpenWorkOrderModal(true);
                    }
                    if (items.value === "transferProduction") {
                      setOpenWipExitModal(true);
                    }
                    toggleDropdown(detail?.id);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                  }`}
                >
                  {items?.icon}
                  <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{items.label}</p>
                </button>
              );
            })}
          </div>
        )}
      </div>
      <CustomModal
        isOpen={isOpenWorkOrderModal}
        setIsOpen={setIsOpenWorkOrderModal}
        subTitle={isFirstModal ? t("product:listOfOpen") : "Aynı ürüne ait diğer siparişlerin listesi"}
        modalTitle={isFirstModal ? t("product:relatedWorkOrders") : "İş Emrini Taşı"}
        width={900}
        navigateButton={isFirstModal ? false : true}
        navigateOnClick={() => setIsFirstModal(!isFirstModal)}
        children={
          <WorkOrderModal
            setIsFirstModal={setIsFirstModal}
            isFirstModal={isFirstModal}
            detailId={detail?.id}
            setOpenWipExitModal={setOpenWipExitModal}
            setIsOpenWorkOrderModal={setIsOpenWorkOrderModal}
            refetch={refetch}
          />
        }
      />
      <CustomModal
        modalTitle={t("product:deleteProduct")}
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        children={<DeleteOrderProduct selectedOrder={detail} id={detail?.id} setIsOpen={setIsOpenDeleteModal} setIsAlert={setIsAlert} refetch={refetch} />}
      />
      <AlertModal
        setIsOpen={setIsAlert}
        isOpen={isAlert}
        title={t("product:deleteProduct")}
        icon={<DeleteIcon />}
        subTitle={t("product:thereAreNo")}
        applyButton={() => {
          alertDeleted(detail?.id);
        }}
        label={"Sil"}
      />
      <CustomModal
        isOpen={openWipExitModal}
        setIsOpen={setOpenWipExitModal}
        modalTitle={t("product:transitionWipOrder")}
        subTitle={t("product:sendToCompletedOrder")}
        width={900}
        padding={true}
        children={<LegacyWipExitModal detailId={detail?.id} setIsOpen={setOpenWipExitModal} refetch={refetch} />}
      />
    </div>
  );
};

export default CustomerDetailTable;
