import React, { useState } from "react";
import CustomImage from "../../../components/CustomImage";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { UpuPointLogo } from "../../../assets/icons/PersonIcons";
import { DotsVerticalIcon, PersonnelDetailIcon } from "../../../components/new-cards/card-icons";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../hooks/useStores";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Badgets from "../../../components/buttons/Badgets";
import moment from "moment";

const PersonDashboardPersonnelCard = ({ user, key, date , setSelectedStatus , selectedStatus }) => {

  const { t } = useTranslation();
  const { auth } = useStore();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  
  const menuOptions = [
    {
      label: t("product:personnelDetail"),
      value: "personnelDetail",
      icon: <PersonnelDetailIcon />,
    },
  ];

  
  const activeJunctionLengths = user?.junctions?.filter((item) => item.status === "active")
  const completedJunctionLength = user?.junctions?.filter((item) => item.status === "completed")

  // const filteredJunctions = user?.junctions?.filter((item) => {
  //   if(selectedStatus === "active") {
  //     return item?.status === "active"
  //   } else if (selectedStatus === "completed") {
  //     return item?.status === "completed"
  //   } else {
  //     return item?.status === selectedStatus
  //   }
  // })

  return (
    <div key={key} className="flex flex-col w-[244px] min-w-[244px] relative group">
      <div className="flex flex-row  items-center gap-x-3 w-full">
        {user?.avatar ? (
          <div data-tooltip-id={`${user?.id}`} data-tooltip-place={"right"} data-tooltip-content={`${user?.name} ${user?.lastName}`} className="cursor-pointer">
            <CustomImage
              src={user?.avatar}
              style={{
                width: 48,
                height: 48,
                minWidth: 48,
                minHeight: 48,
                flexDirection: "column",
                borderRadius: 100,
                display: "flex",
                borderColor: "#0000001A",
                backgroundColor: "#fff",
              }}
            />
          </div>
        ) : (
          <div data-tooltip-id={`${user?.id}`} data-tooltip-place={"right"} data-tooltip-content={`${user?.name} ${user?.lastName}`} className="cursor-pointer">
            <NoAvatar size={48} fontSize={22} name={user?.name} lastName={user?.lastName} color={"#0E9384"} />
          </div>
        )}
        <div className="flex flex-col items-start">
          <p className="text-secondary-700 font-semibold w-[160px] truncate text-base">{user?.name + " " + user?.lastName}</p>
          <p className="text-base text-secondary-500 w-[160px] truncate font-normal">{user?.metadata?.department?.name || "--"}</p>
        </div>
      </div>
      {moment(date)?.format("YYYY-MM-DD") === moment(new Date())?.format("YYYY-MM-DD") ? (
         <div className="flex flex-row items-center gap-x-1 w-full mt-3">
         <div  
         onClick={()=>{
 
           if (selectedStatus == 'completed') {
             
           } else {
             setSelectedStatus('completed')
           }
          
         }}
         className={`border cursor-pointer rounded-lg  ${selectedStatus === "completed" ? "bg-secondary-50" : "bg-white"}  pl-2 py-1 pr-0.5 flex items-center flex-row w-1/2 h-[30px] min-h-[30px]`}>
           <p className="text-xs font-semibold text-secondary-700">{t("product:completedNew")}</p>
           <div className="ml-auto">
             <Badgets size={"sm"} colorType={"fill-gray"} label={completedJunctionLength?.length} />
           </div>
         </div>
         <div
          onClick={()=>{
 
           if (selectedStatus == 'active') {
             
           } else {
             setSelectedStatus('active')
           }
          
         }}
         className={`border cursor-pointer rounded-lg ${selectedStatus === "active" ? "bg-secondary-50" : "bg-white"} border-[#E4E7EC] pl-2 py-1 pr-0.5 flex items-center flex-row w-1/2 h-[30px] min-h-[30px]`}>
           <p className="text-xs font-semibold text-[#067647]">{t("product:active")}</p>
           <div className="ml-auto">
             <Badgets size={"sm"} colorType={"fill-success"} label={activeJunctionLengths?.length} />
           </div>
         </div>
       </div>
      ) : (
        <div className="flex flex-row items-center gap-x-1 w-full mt-3">
        <div  
        onClick={()=>{

          if (selectedStatus == 'completed') {
            
          } else {
            setSelectedStatus('completed')
          }
         
        }}
        className={`border cursor-pointer rounded-lg  ${selectedStatus === "completed" ? "bg-secondary-50" : "bg-white"}  pl-2 py-1 pr-0.5 flex items-center flex-row w-1/2 h-[30px] min-h-[30px]`}>
          <p className="text-xs font-semibold text-secondary-700">{t("product:completedNew")}</p>
          <div className="ml-auto">
            <Badgets size={"sm"} colorType={"fill-gray"} label={completedJunctionLength?.length} />
          </div>
        </div>
      
      </div>
      )}
     
      <div className="flex flex-col w-full gap-y-2 px-2 mt-2">
        <div className="flex flex-row items-center w-full">
          <p className="text-secondary-600 font-normal  text-xs ">upu.point</p>
          <p className="text-sm font-medium text-[#9B7D07] ml-auto">{user?.upuPoint?.toFixed(2) || "--"}</p>
        </div>
        <div className="flex flex-row items-center w-full">
          <p className="text-secondary-600 font-normal  text-xs ">{t("addProcess:performance")}</p>
          <p className="text-sm font-medium text-[#344054] ml-auto">
            {" "}
            %{user?.performance?.actual > 1 ? 1 * 100 : (user?.performance?.actual * 100)?.toFixed(0) || "--"}
          </p>
        </div>
        <div className="flex flex-row items-center w-full">
          <p className="text-secondary-600 font-normal  text-xs ">{t("addProcess:qualityrate")}</p>
          <p className="text-sm font-medium text-[#344054] ml-auto">
            {" "}
            %{user?.quality?.actual > 1 ? 1 * 100 : (user?.quality?.actual * 100)?.toFixed(0) || "--"}
          </p>
        </div>
      </div>
      {/* <div className="flex w-full h-[46px] max-h-[46px] min-h-[46px] border border-[#F8DA64] rounded-lg shadow-xs mt-4">
        <div className="flex w-11 bg-[#FEFAE9] p-2 border-r border-[#F8DA64] rounded-l-lg items-center justify-center">
          <span className="flex min-w-[28px] max-w-[28px] min-h-[28px] max-h-[28px] items-start justify-end">
            <UpuPointLogo />
          </span>
        </div>
        <div className="flex flex-col items-start px-3 py-[3px]">
          <span className="font-codec text-sm">
            upu.<span className="text-[#475467]">point</span>
          </span>
          <p className="text-sm font-semibold text-[#9B7D07]">{user?.upuPoint?.toFixed(2) || "--"}</p>
        </div>
      </div> */}
      {/* <div className="flex flex-row items-center gap-x-2 mt-3">
        <div className="w-1/2 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
            <p className="text-xs  font-normal text-secondary-600">{"Perf."}</p>
          </div>
          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
            <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
              %{user?.performance?.actual > 1 ? 1 * 100 : (user?.performance?.actual * 100)?.toFixed(0) || "--"}
            </p>
          </div>
        </div>
        <div className="w-1/2 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
            <p className="text-xs  font-normal text-secondary-600">{"Quality"}</p>
          </div>
          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
            <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
              %{user?.quality?.actual > 1 ? 1 * 100 : (user?.quality?.actual * 100)?.toFixed(0) || "--"}
            </p>
          </div>
        </div>
      </div> */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowDropDown(!showDropDown);
        }}
        className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  bg-white group-hover:flex right-[-8px] top-[0px] z-[50] items-center justify-center cursor-pointer"
      >
        <DotsVerticalIcon />
      </div>
      {showDropDown && (
        <div
          className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
        >
          {menuOptions?.map((item, index) => {
            //startDate eklenecekkkkkk ve sorulacakkkkk
            const formattedDate = getFormattedNavigateDate(user?.startDate, auth.user.company.dayStartHour);
            return (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.value === "personnelDetail") {
                    navigate(`/app/machine/digital-twin/user-detail/${user?.id}/${date}`);
                  }

                  setShowDropDown(false);
                }}
                key={index}
                type="button"
                className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                  item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                }`}
              >
                {item?.icon}
                <p className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}>
                  {item.label}
                </p>
              </button>
            );
          })}
        </div>
      )}
      <Tooltip className="z-[920]" id={`${user?.id}`} />
    </div>
  );
};

export default PersonDashboardPersonnelCard;
