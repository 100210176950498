import React, { useEffect, useMemo, useState } from 'react'
import SearchInput from '../inputs/SearchInput'
import { useTranslation } from 'react-i18next';
import RelatedProductCard from '../../pages/product/cards/RelatedProductCard';
import TechnicalDrawingModal from '../../pages/product/modals/TechnicalDrawingModal';
import CustomModal from './CustomModal';
import { productService } from '../../services/product.service';
import { useQuery } from '@tanstack/react-query';
import { PaginationComponent } from '..';
import { Toast } from '../../utils/toastify/toast';

const RelatedProductsModal = ({ item }) => {
    const { t } = useTranslation()
    const [searchVal, setSearchVal] = useState();
    const [openTechnicalModal, setOpenTechnicalModal] = useState(false);
    const [technicalProductData, setTechnicalProductData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const { isLoading, isFetching, data, refetch } = useQuery({
        queryKey: ["listProductOrderModal"],
        enabled: false,
        queryFn: useMemo(
            () => async () => {
                try {
                    const res = await productService.relatedProducts(
                        item?.rawMaterial?.id,
                        pageNo,
                        searchVal,
                        pageLimit,
                    );
                    setPageCount(res?.data?.meta?.pageCount);

                    if (res?.data?.meta?.pageCount < pageNo) {
                        setPageNo(1);
                    }
                    return res;
                } catch (error) {
                    console.error("Error occured while fetching data:", error);
                    throw error;
                }
            },
            [pageNo, searchVal, pageLimit, pageCount]
        ),
        retry: 1,
    });

    useEffect(() => {
        refetch();
    }, [pageNo, searchVal, pageLimit, pageCount]);


    return (
        <>
            <div className='flex flex-col w-full'>
                <div className='mt-6 w-full'>
                    <SearchInput theme={"product"} setSearchVal={setSearchVal} />
                </div>
                <div className='flex flex-col w-full h-[400px] min-h-[400px] overflow-y-scroll scrollbar-hide'>
                    {data?.data?.data?.map((items, index) => {
                        return (
                            <div className='flex flex-row items-center gap-x-4 w-full mt-5'>
                                <RelatedProductCard item={items} />
                                <div onClick={() => {
                                    if (items?.technicalDrawing) {
                                        setTechnicalProductData(items)
                                        setOpenTechnicalModal(true)
                                    } else {
                                        Toast("error", "Teknik resim bulunamadı.");
                                    }

                                }} className='border cursor-pointer border-secondary-300 ml-auto w-[120px] min-w-[120px] h-[112px] min-h-[112px] rounded-lg p-2 flex flex-col items-center justify-center'>
                                    <ImageIcon />
                                    <p className='text-sm  w-[100px] text-center truncate font-semibold text-secondary-700 mt-2'>{t("product:technicalPicture")}</p>
                                    <p className='text-xs font-normal text-secondary-600'>{t("product:viewss")}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="z-50 border-t border-[#D0D5DD] bg-white rounded-b-xl">
                    <PaginationComponent pageNo={pageNo} setPageNo={setPageNo} pageCount={pageCount} />
                </div>
            </div>
            <CustomModal
                isOpen={openTechnicalModal}
                setIsOpen={setOpenTechnicalModal}
                modalTitle={t("product:technicalDrawing")}
                width={"70%"}
                height={"85%"}
                children={<TechnicalDrawingModal setIsOpen={setOpenTechnicalModal} product={technicalProductData} />}
            />
        </>
    )
}

export default RelatedProductsModal

export const ImageIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C5.79086 0 4 1.79086 4 4V36C4 38.2091 5.79086 40 8 40H32C34.2091 40 36 38.2091 36 36V12L24 0H8ZM13.1505 32V25.4545H11.7666V32H13.1505ZM15.9958 25.4545H14.2891V32H15.6314V27.7237H15.6858L17.3797 31.968H18.2937L19.9876 27.7397H20.0419V32H21.3843V25.4545H19.6776L17.875 29.8523H17.7983L15.9958 25.4545ZM26.6761 27.158C26.7571 27.2773 26.8199 27.4148 26.8647 27.5703H28.2709C28.2262 27.2464 28.125 26.9503 27.9673 26.6818C27.8096 26.4112 27.6061 26.1779 27.3568 25.9819C27.1097 25.7859 26.8263 25.6346 26.5067 25.5281C26.1871 25.4194 25.8419 25.3651 25.4712 25.3651C25.0387 25.3651 24.637 25.4407 24.2663 25.592C23.8956 25.7433 23.5706 25.9638 23.2915 26.2536C23.0124 26.5433 22.7951 26.897 22.6395 27.3146C22.4861 27.7301 22.4094 28.2031 22.4094 28.7337C22.4094 29.424 22.5383 30.0206 22.7961 30.5234C23.0561 31.0241 23.4183 31.4109 23.8828 31.6836C24.3473 31.9542 24.8863 32.0895 25.5 32.0895C26.0497 32.0895 26.5376 31.9787 26.9637 31.7571C27.3899 31.5334 27.7244 31.2159 27.9673 30.8047C28.2102 30.3913 28.3316 29.897 28.3316 29.3217V28.4972H25.5926V29.5391H26.9984C26.9928 29.8007 26.9333 30.0287 26.8199 30.223C26.7027 30.4276 26.5323 30.5863 26.3085 30.6992C26.087 30.81 25.8196 30.8654 25.5063 30.8654C25.1612 30.8654 24.8618 30.7823 24.6083 30.6161C24.3547 30.4478 24.1587 30.2038 24.0202 29.8842C23.8817 29.5646 23.8125 29.1768 23.8125 28.7209C23.8125 28.2649 23.8828 27.8793 24.0234 27.5639C24.164 27.2464 24.36 27.0046 24.6115 26.8384C24.865 26.6722 25.159 26.5891 25.4936 26.5891C25.6725 26.5891 25.8355 26.6115 25.9826 26.6562C26.1317 26.701 26.2638 26.766 26.3789 26.8512C26.496 26.9343 26.5951 27.0366 26.6761 27.158Z" fill="#DC6803" />
            <path opacity="0.3" d="M24 0L36 12H28C25.7909 12 24 10.2091 24 8V0Z" fill="white" />
        </svg>
    );
};

