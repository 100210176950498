import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../CustomImage";
import { LossIcon } from "../../../assets/icons/leftMenuIcons";
import NoAvatar from "../../avatar/NoAvatar";
import { DotsVerticalIcon, EndClockIcon, EquipmentDetailIcon, PersonnelDetailIcon, StartClockIcon } from "../card-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import { useStore } from "../../../hooks/useStores";

const OperationDetailSelectedLossCard = ({ data, items }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const { auth } = useStore();
  const menuOptions = [
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnelDetail",
      icon: <PersonnelDetailIcon />,
    },
  ];
  const filteredLosses = items?.losses?.find((el)=>el?.id === data?.lossesId)
  return (
    <>
      <div className="cursor-pointer flex flex-row items-center gap-x-3 w-full">
        <div className="flex items-center flex-col gap-y-1 justify-center border border-secondary-300 rounded-t-[4px] rounded-b-lg p-1 w-[88px] min-w-[88px] bg-[#F9FAFB]">
          <p className="text-xs w-[75px] truncate text-center font-semibold text-secondary-700">{items?.nodeName || "--"}</p>
          {items?.nodeImage ? (
            <div
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"right"}
              data-tooltip-content={`
          ${items.nodeName} 
          `}
            >
              <CustomImage
                borderRadius={2}
                src={items?.nodeImage}
                style={{
                  width: 80,
                  height: 60,
                  borderRadius: 4,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div className="w-[80px] min-w-[80px] h-[60px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
              <NoEquIcon />
            </div>
          )}
        </div>
        <div
          className={`flex w-full flex-col border border-t-4 border-t-[#D92D20]  cursor-pointer items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
        >
          <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
            <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
              <div className="w-[64px] min-w-[64px] h-[48px] bg-[#FEF3F2] border border-[#FDA29B] flex items-center justify-center font-medium text-[#D92D20] text-xl cursor-pointer">
                <LossIcon />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-sm font-semibold text-secondary-900 w-[170px] truncate"> {filteredLosses?.type?.name  || "Undefined Loss"}</p>
                <p className="text-xs font-normal text-secondary-500 w-[175px] truncate">{data?.reasonDescription || "Unknown"}</p>
              </div>
            </div>

            {data?.avatar ? (
              <div className="cursor-pointer ml-auto">
                <CustomImage
                  src={data?.avatar}
                  style={{
                    width: 48,
                    height: 48,
                    minWidth: 48,
                    minHeight: 48,
                    flexDirection: "column",
                    borderRadius: 100,
                    display: "flex",
                    border: "1px solid #0000001A",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="cursor-pointer ml-auto">
                <NoAvatar size={48} fontSize={22} name={data?.name} color={"#B54708"} />
              </div>
            )}
          </div>
          <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB]  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
            <div className="flex flex-row items-center">
              <StartClockIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{moment(data?.startDate).add(3, "hour")?.format("HH:mm:ss") || "--"}</p>
              <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:timeStarteds")}</p>
            </div>
            <div className="flex flex-row items-center">
              <EndClockIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{moment(data?.endDate).add(3, "hour")?.format("HH:mm:ss") || "--"}</p>
              <p className="text-xxs font-normal ml-0.5 mt-[1px] text-secondary-600">{t("product:timeEnds")}</p>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowDropDown(!showDropDown);
            }}
            className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
          >
            <DotsVerticalIcon />
          </div>
          {showDropDown && (
            <div
              className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
            >
              {menuOptions?.map((item, index) => {
                const formattedDate = getFormattedNavigateDate(filteredLosses?.startDate, auth.user.company.dayStartHour);
                return (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item.value === "equipmentDetail") {
                        navigate(`/app/machine/digital-twin/equipment-details/${items?.deviceId}-${items?.nodeId}/${formattedDate}`);
                      }

                      if (item.value === "personnelDetail") {
                        navigate(`/app/machine/digital-twin/user-detail/${data?.status}/${formattedDate}`);
                      }

                      setShowDropDown(false);
                    }}
                    key={index}
                    type="button"
                    className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                      item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                    }`}
                  >
                    {item?.icon}
                    <p
                      className={`font-medium text-sm  ${
                        item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"
                      }`}
                    >
                      {item.label}
                    </p>
                  </button>
                );
              })}
            </div>
          )}
        </div>
        <Tooltip className="z-[120]" id={`${data?.id}`} />
      </div>
    </>
  );
};

export default OperationDetailSelectedLossCard;
