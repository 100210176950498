import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Input } from "../../../components";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { productService } from "../../../services/product.service";
import { Toast } from "../../../utils/toastify/toast";

const StockEditModal = ({closeModal,product,refetch}) => {
  const { t } = useTranslation();
  
  const schema = yup.object({
    increase: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    decrease: yup
      .number()
      .notRequired()
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      increase:0,
      decrease:0
    },
  });

  const onSubmit = async (value) => {
    const data = {
      increase: value?.increase,
      decrease: value?.decrease,
    };
    await productService
      .updateStockAmount(product?.id,data)
      .then((res) => {
        if (res?.data?.code === 0) {
          Toast("success", res?.data?.message);
          refetch();
          closeModal();
        } else {
          Toast("error", res?.data?.message);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex w-[320px] flex-col gap-y-6 mt-6">
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            theme={"product"}
            label={t("product:increaceAmount")}
            errorMessage={errors?.increase?.message}
            validate={errors?.increase ? "error" : ""}
          />
        )}
        name="increase"
      />
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            theme={"product"}
            label={t("product:decreaceAmount")}
            errorMessage={errors?.decrease?.message}
            validate={errors?.decrease ? "error" : ""}
          />
        )}
        name="decrease"
      />
      <div className="flex flex-row items-center gap-x-3 justify-between ">
        <Button label={t("buttons:stop")} colorType={"secondary-gray"} size={"md"} onClick={closeModal} />
        <Button type={"submit"} label={t("buttons:save")} colorType={"primary-product"} size={"md"} />
      </div>
    </form>
  );
};

export default StockEditModal;
