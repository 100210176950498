import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import CalendarRow from "./CalendarRow";
import SavedCalendarRow from "./CalendarRowSaved";
import { useStore } from "../../../hooks/useStores";

const ShiftCalendarTable = ({ data, title, type, refetch, menuType, isDailyAdd }) => {
  const { width } = useWindowDimensions();
  const [timeIntervals, setTimeIntervals] = useState([]);
  const containerWidth = isDailyAdd ? width - 252 : width - 430;
  const [defaultStart, setDefaultStart] = useState();
  const columnSize = containerWidth / 86400;
  const tableHeight = data?.length * 66 + 52;
  const { auth } = useStore();

  useEffect(() => {
    if (auth?.user?.company?.dayStartHour) {
      const [hours, minutes, seconds] = auth.user.company.dayStartHour.split(":").map(Number);
      const startTime = new Date();
      startTime.setHours(hours, minutes, seconds, 0);
      const formattedStart = startTime;
      setDefaultStart(formattedStart);

      const intervals = [];
      for (let i = 0; i <= 24; i++) {
        const newTime = new Date(startTime);
        newTime.setHours(startTime.getHours() + i);
        intervals.push(newTime.toTimeString().slice(0, 5));
      }
      setTimeIntervals(intervals);
    }
  }, [auth]);

  return (
    <div style={{ minHeight: tableHeight }} className="w-full rounded-xl border-gray-200 border flex shadow flex-col overflow-hidden">
      <div className="w-full h-[52px] border-b border-gray-200 flex items-center">
        {!isDailyAdd && (
          <div className="min-w-[228px] max-w-[228px] h-full border-r flex items-center px-5">
            <p className="text-lg font-semibold text-secondary-900 truncate">{title}</p>
          </div>
        )}
        <div className="flex-1 h-full flex items-center">
          {timeIntervals.map((time, index) => (
            <div style={{ width: containerWidth / 24 }} className={`text-xs text-secondary-500 h-full flex items-center justify-center relative`} key={index}>
              {time}
              <span className="w-px absolute bg-gray-200 h-[900px] top-9" />
            </div>
          ))}
        </div>
      </div>
      <div className="flex-1 w-full flex">
        {type == "saved" ? (
          <div className="flex flex-col w-full h-full">
            {data.map((item, index) => {
              return (
                <SavedCalendarRow
                  refetch={refetch}
                  key={index}
                  index={index}
                  item={item}
                  defaultStart={defaultStart}
                  columnSize={columnSize}
                  containerWidth={containerWidth}
                />
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col w-full h-full">
            {data.map((item, index) => {
              return (
                <CalendarRow
                  isDailyAdd={isDailyAdd}
                  key={index}
                  index={index}
                  item={item}
                  defaultStart={defaultStart}
                  columnSize={columnSize}
                  containerWidth={containerWidth}
                  type={type}
                  menuType={menuType}
                  refetch={refetch}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShiftCalendarTable;
