import React, { useEffect, useState, useRef } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import {
  ClockIconStock,
  DollarIcon,
  ExternalOperationIcon,
  InternalOperationIcon,
} from "../../../assets/icons/productIcons";
import { CustomModal } from "../../../components";
import { useTranslation } from "react-i18next";
import { MenuIcon } from "../../../assets/icons/PersonIcons";
import { DangerIcon } from "../../../assets/icons/machineIcons";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import AddExternalOperation from "../modals/AddExternalOperation";
import AddInternalOperation from "../modals/AddInternalOperation";
import SelectOperationTypeModal from "../modals/SelectOperationTypeModal";
import { useStore } from "../../../hooks/useStores";
import { QuantityIcon } from "../../../assets/icons/stepsIcons";
import InternalOperationCard from "./InternalOperationCard";
import ExternalOperationCard from "./ExternalOperationCard";
import NewSelectOperationTypeModal from "../modals/NewSelectOperationTypeModal";
import AssemblyModal from "../modals/AssemblyModal";
const SortableItem = SortableElement(
  ({
    key,
    index,
    value,
    setSelectedOperation,
    setIsDeleteOperation,
    setIsOpenOperationModal,
    name,
  }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef();
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    const menuOptions = [
      { label: t("buttons:update"), value: "update" },
      { label: t("buttons:delete"), value: "delete" },
    ];
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}m ${remainingSeconds}s`;
    };
    const externalOP = value?.type === "external" ? true : false;
    return (
      <div
        onClick={() => {
          setSelectedOperation(value);
        }}
        index={index}
        key={key}
        ref={menuRef}
        className="select-none flex items-center justify-center w-full max-w-[302px]"
      >
        <div
          className={`flex cursor-grab w-[270px] h-[100px] border px-3 py-2 gap-2 border-gray-300 rounded-lg relative bg-white shadow`}
        >
          <div className="w-5 h-5 mt-1">
            {value?.type === "internal" ? (
              <InternalOperationIcon />
            ) : (
              <ExternalOperationIcon />
            )}
          </div>
          <div className="flex flex-col w-[90%] ml-1">
            <div
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={`w-5 h-5 group-hover:flex absolute top-2 right-1.5 items-center justify-center cursor-pointer text-gray-500 hover:text-black transition-colors rotate-90 `}
            >
              <MenuIcon />
            </div>
            <span className="text-sm font-semibold text-[#344054] truncate w-[97%]">
              {name}
            </span>
            <div
              className={`${
                externalOP ? "flex" : "hidden"
              } justify-between w-full`}
            >
              <span className="text-xs w-1/2 truncate font-normal text-[#475467] flex items-center gap-1">
                {value?.madeTransaction}
              </span>
            </div>
            <div
              className={`flex ${
                externalOP ? "" : "flex-col"
              }  justify-between w-full`}
            >
              <span className="text-xs w-1/2 truncate font-normal text-[#475467] flex items-center gap-1">
                {externalOP ? <CalendarIcon /> : <ClockIconStock />}
                {externalOP ? (
                  <>
                    {value?.estimatedTime} {t("days:day")}
                  </>
                ) : (
                  <>{formatTime(value?.estimatedTime)}</>
                )}
              </span>
              {externalOP && (
                <span className="text-xs w-1/2 truncate font-normal text-[#475467] flex items-center gap-1">
                  <QuantityIcon />
                  {value?.costOfPiece}
                </span>
              )}
              {!externalOP && (
                <span className="text-xs w-1/2 truncate font-normal text-[#475467] flex items-center gap-1">
                  <DollarIcon />
                  {value?.costOfMinute} min/₺
                </span>
              )}
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="absolute w-[190px] p-1 bg-white right-10 top-[32px] border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1 z-50">
            {menuOptions?.map((item, index) => {
              return (
                <button
                  onClick={() => {
                    if (item.value === "delete") {
                      setIsDeleteOperation(true);
                      setSelectedOperation(value);
                    } else {
                      setSelectedOperation(value);
                      setIsOpenOperationModal(true);
                    }
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                >
                  <p className="font-medium text-sm text-secondary-700">
                    {index === "taskList" ? "" : item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);
const SortableList = SortableContainer(
  ({
    items,
    setSelectedOperation,
    setIsDeleteOperation,
    setIsOpenOperationModal,
    setIsOpenAddOperationModal,
  }) => {
    return (
      <div className="w-fit flex h-full items-center justify-center">
        {items?.map((value, index) => {
          const operationName =
            value?.internalOperation?.process?.name ||
            value?.externalOperation?.process?.name
              ? value?.internalOperation?.process?.name ||
                value?.externalOperation?.process?.name
              : value?.process?.name || value?.process?.name;
          const occurrences =
            items
              .slice(0, index)
              .filter(
                (op) =>
                  op &&
                  (op?.internalOperation?.process?.name ||
                    op?.externalOperation?.process?.name) === operationName
              ).length + 1;
          return (
            <div className="relative group h-full flex w-full min-w-[302px] items-center justify-center border-r border-gray-200">
              {value.status == "passive" || value.status == "waiting" ? (
                <SortableItem
                  key={String(index)}
                  index={index}
                  value={{
                    ...value[value.type + "Operation"],
                    operationId: value?.id,
                  }}
                  number={index}
                  dataIndex={items?.length}
                  setSelectedOperation={setSelectedOperation}
                  setIsDeleteOperation={setIsDeleteOperation}
                  setIsOpenOperationModal={setIsOpenOperationModal}
                  name={
                    occurrences > 1
                      ? `${operationName} ${occurrences}`
                      : operationName
                  }
                />
              ) : (
                <div className="min-w-[302px] max-h-[109px] h-full flex items-center justify-center px-2 border-gray-200">
                  {value?.type === "internal" ? (
                    <InternalOperationCard
                      status={value?.status}
                      iconKey={value?.internalOperation?.process?.iconKey}
                      name={
                        occurrences > 1
                          ? `${operationName} ${occurrences}`
                          : operationName
                      }
                      operations={value}
                      orderData={value}
                      showOperationDetail={false}
                    />
                  ) : (
                    <ExternalOperationCard
                      status={value?.status}
                      iconKey={value?.externalOperation?.process?.iconKey}
                      name={
                        occurrences > 1
                          ? `${operationName} ${occurrences}`
                          : operationName
                      }
                      operations={value}
                      showOperationDetail={false}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
        <div
          onClick={() => {
            setSelectedOperation(null);
            setIsOpenAddOperationModal(true);
          }}
          className="h-full flex items-center relative w-full min-w-[250px] justify-center border-r border-gray-200"
        >
          <div className="w-[200px] h-[92px] border border-gray-300 rounded-lg flex items-center justify-center cursor-pointer shadow">
            <PlusIcon />
          </div>
        </div>
      </div>
    );
  }
);
const OrderOperationGrid = ({ items, setItems }) => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const [selectedOperation, setSelectedOperation] = useState({});
  const [isOpenOperationModal, setIsOpenOperationModal] = useState(false);
  const [isDeleteOperation, setIsDeleteOperation] = useState(false);
  const [isOpenAddOperationModal, setIsOpenAddOperationModal] = useState(false);
  const [isFirstModal, setIsFirstModal] = useState(true);
  const [isAssemblyModalOpen, setIsAssemblyModalOpen] = useState(false);
  const [isAddExternalOperation, setIsAddExternalOperation] = useState(false);
  const [isAddInternalOperation, setIsAddInternalOperation] = useState(false);
  const [selectedInternalType, setselectedInternalType] = useState();
  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const newItems = arrayMoveImmutable(items, oldIndex, newIndex);
    const changedList = newItems.map((d, i) => {
      return {
        ...d,
        step: i + 1,
      };
    });
    setItems(changedList);
  };

  const deleteOperation = async () => {
    if (selectedOperation?.entityId) {
      if (
        items.find(
          (item) =>
            (item?.internalOperation?.entityId ||
              item?.externalOperation?.entityId) === selectedOperation?.entityId
        )
      ) {
        const removeItem = items.filter(
          (item) =>
            (item?.internalOperation?.entityId ||
              item?.externalOperation?.entityId) !== selectedOperation?.entityId
        );
        const newList = removeItem?.map((item) => {
          return { ...item, step: item?.step - 1 };
        });
        setItems(newList);
      }
    } else if (
      items.find(
        (item) =>
          (item?.internalOperation?.id || item?.externalOperation?.id) ===
          selectedOperation?.id
      )
    ) {
      const removeItem = items.filter(
        (item) =>
          (item?.internalOperation?.id || item?.externalOperation?.id) !==
          selectedOperation?.id
      );
      const newList = removeItem?.map((item) => {
        return { ...item, step: item?.step - 1 };
      });
      setItems(newList);
    }
    // const newItemsWithStep = items
    //   .filter((item) => item?.internalOperation?.id !== selectedOperation?.id)
    //   .map((d, i) => {
    //     return {
    //       ...d,
    //       step: d?.step - 1,
    //     };
    //   });
    // setItems(newItemsWithStep);
    setIsDeleteOperation(false);
  };

  const openModalFunc = () => {
    if (selectedInternalType === "assembly_operation") {
      setIsAssemblyModalOpen(true);
    } else if (selectedInternalType === undefined) {
      setIsAddExternalOperation(true);
    } else if (selectedInternalType === "conversion_operation") {
      setIsAddInternalOperation(true);
    }
    setIsOpenAddOperationModal(false);
  };

  useEffect(() => {
    setIsFirstModal(true);
  }, [isAssemblyModalOpen]);

  return (
    <>
      <div className="w-full h-full">
        <SortableList
          axis="x"
          items={items}
          onSortEnd={onSortEnd}
          setIsOpenAddOperationModal={setIsOpenAddOperationModal}
          setIsOpenOperationModal={setIsOpenOperationModal}
          setIsDeleteOperation={setIsDeleteOperation}
          setSelectedOperation={setSelectedOperation}
          transitionDuration={200}
          distance={-10}
        />
      </div>
      {/* <CustomModal
        isOpen={isOpenAddOperationModal}
        setIsOpen={setIsOpenAddOperationModal}
        onClose={() => {
          setIsOpenAddOperationModal(false);
        }}
        width="fit-content"
        modalTitle={t("product:chooseOperationType")}
        isBadges={true}
        badgesValue={
          items?.length + "/" + auth?.user?.company?.limits?.stockCard
        }
        badgesColor={"fill-warning"}
        badgesSize={"md"}
        children={
          <SelectOperationTypeModal
            t={t}
            setIsOpen={setIsOpenAddOperationModal}
            setItems={setItems}
          />
        }
      /> */}
      <CustomModal
        isOpen={isOpenAddOperationModal}
        setIsOpen={setIsOpenAddOperationModal}
        onClose={() => {
          setIsOpenAddOperationModal(false);
        }}
        width="fit-content"
        modalTitle={t("product:chooseOperationType")}
        badgesColor={"fill-warning"}
        badgesSize={"md"}
        children={
          <NewSelectOperationTypeModal
            t={t}
            openModalFunc={openModalFunc}
            setIsOpenAddOperationModal={setIsOpenAddOperationModal}
            setselectedInternalType={setselectedInternalType}
            selectedInternalType={selectedInternalType}
            setItems={setItems}
            items={items}
            onClose={() => {
              setIsOpenAddOperationModal(false);
            }}
            limit={auth?.user?.company?.limits?.stockCard}
          />
        }
      />
      <CustomModal
        isOpen={isAssemblyModalOpen}
        setIsOpen={setIsAssemblyModalOpen}
        modalTitle={"Yeni Birleştirme Operasyon Adımı"}
        subTitle={
          isFirstModal
            ? "Genel bilgiler"
            : "Bu operasyon için en iki ürün / hammadde seçin"
        }
        height={710}
        width={isFirstModal ? 500 : 744}
        children={
          <AssemblyModal
            setIsFirstModal={setIsFirstModal}
            isFirstModal={isFirstModal}
          />
        }
      />
      <CustomModal
        isOpen={isAddExternalOperation}
        setIsOpen={setIsAddExternalOperation}
        onClose={() => {
          setIsAddExternalOperation(false);
        }}
        width="fit-content"
        modalTitle={t("product:newExternalOperationStep")}
        children={
          <AddExternalOperation
            // refresh={refresh}
            t={t}
            setIsOpen={setIsAddExternalOperation}
            setIsAddExternalOperation={setIsAddExternalOperation}
            // selectedProduct={{ id: stockId }}
            setItems={setItems ? setItems : undefined} /// sadece siparis editi icin eklenmistir
          />
        }
      />
      <CustomModal
        isOpen={isAddInternalOperation}
        setIsOpen={setIsAddInternalOperation}
        onClose={() => {
          setIsAddInternalOperation(false);
        }}
        width="fit-content"
        modalTitle={t("product:newInternalOperationStep")}
        children={
          <AddInternalOperation
            t={t}
            // refresh={refresh}
            setIsOpen={setIsAddInternalOperation}
            // selectedProduct={selectedProduct}
            setIsAddInternalOperation={setIsAddInternalOperation}
            setItems={setItems ? setItems : undefined} /// sadece siparis editi icin eklenmistir
          />
        }
      />
      <AlertModal
        isOpen={isDeleteOperation}
        setIsOpen={setIsDeleteOperation}
        title={t("product:deleteOperation")}
        subTitle={t("product:cannotBeUndone")}
        applyButton={deleteOperation}
        icon={<DangerIcon />}
      />
      <CustomModal
        isOpen={isOpenOperationModal && selectedOperation?.type === "external"}
        setIsOpen={setIsOpenOperationModal}
        onClose={() => {
          setIsOpenOperationModal(false);
        }}
        width="fit-content"
        modalTitle={t("product:newExternalOperationStep")}
        children={
          <AddExternalOperation
            t={t}
            setIsOpen={setIsOpenOperationModal}
            setIsAddExternalOperation={setIsOpenOperationModal}
            setItems={setItems} /// sadece siparis editi icin eklenmistir
            externalUpdate={selectedOperation}
          />
        }
      />
      <CustomModal
        isOpen={isOpenOperationModal && selectedOperation?.type === "internal"}
        setIsOpen={setIsOpenOperationModal}
        onClose={() => {
          setIsOpenOperationModal(false);
        }}
        width="fit-content"
        modalTitle={t("product:newInternalOperationStep")}
        children={
          <AddInternalOperation
            t={t}
            setIsOpen={setIsOpenOperationModal}
            setIsAddInternalOperation={setIsOpenOperationModal}
            setItems={setItems} /// sadece siparis editi icin eklenmistir
            internalUpdate={selectedOperation}
          />
        }
      />
    </>
  );
};
export default OrderOperationGrid;
const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M24 10V38M10 24H38"
        stroke="#98A2B3"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 6.66732H2M10.6667 1.33398V4.00065M5.33333 1.33398V4.00065M5.2 14.6673H10.8C11.9201 14.6673 12.4802 14.6673 12.908 14.4493C13.2843 14.2576 13.5903 13.9516 13.782 13.5753C14 13.1475 14 12.5874 14 11.4673V5.86732C14 4.74721 14 4.18716 13.782 3.75934C13.5903 3.38301 13.2843 3.07705 12.908 2.8853C12.4802 2.66732 11.9201 2.66732 10.8 2.66732H5.2C4.0799 2.66732 3.51984 2.66732 3.09202 2.8853C2.71569 3.07705 2.40973 3.38301 2.21799 3.75934C2 4.18716 2 4.74721 2 5.86732V11.4673C2 12.5874 2 13.1475 2.21799 13.5753C2.40973 13.9516 2.71569 14.2576 3.09202 14.4493C3.51984 14.6673 4.0799 14.6673 5.2 14.6673Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
