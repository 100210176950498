import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProductToggle = ({ enabled, setEnabled }) => {
  return (
    <div className="w-[44px] min-w-[44px] max-w-[44px] h-[24px] min-h-[24px] max-h-[24px] rounded-full flex items-center">
      <div
        checked={enabled}
        onClick={() => setEnabled(!enabled)}
        className={classNames(
          enabled ? "bg-[#DC6803] " : "bg-[#F2F4F7]",
          "relative inline-flex flex-shrink-0 h-6 min-w-[44px] border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-1",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 my-auto"
          )}
        />
      </div>
    </div>
  );
};

export default ProductToggle;
