import React, { useRef, useState } from "react";
import { ArrowDownIcon } from "../../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";

const FinishOperationDropDown = ({
  setIsOpenFinishOperation,
  setIsOpenJunction,
  disabled,
  setIsOpenFromStock,
  currentOperation,
  setIsOpenPurchase,
}) => {
  const { t } = useTranslation();
  const [isSortDropdown, setIsSortDropdown] = useState(false);

  const menuRef = useRef();

  const optionsInternal = [
    {
      label: t("product:junctionManually"),
      value: "addJunction",
      icon: <PlusIcon />,
      type: currentOperation?.type
    },
    {
      label: t("product:applyFromStock"),
      value: "appliedStock",
      icon: <StockIcon />,
      type: currentOperation?.type
    },
    {
      label: t("product:markPurchase"),
      value: "service",
      icon: <ServiceIcon />,
      type: currentOperation?.type
    },
    // {
    //   label: t("product:finishThisOperation"),
    //   value: "finishOperation",
    //   icon: "stop",
    //   type : "internal"
    // },
  ];

  const optionsExternal = [

    {
      label: t("product:applyFromStock"),
      value: "appliedStock",
      icon: <StockIcon />,
      type: currentOperation?.type
    },
    {
      label: t("product:markPurchase"),
      value: "service",
      icon: <ServiceIcon />,
      type: currentOperation?.type
    },

  ];

  return (
    <div ref={menuRef} className="flex w-full h-full relative">
      <div
        onClick={() => setIsSortDropdown(true && !isSortDropdown)}
        className={`flex border  border-secondary-300 px-[14px] py-[10px] rounded-lg gap-x-1 justify-center items-center cursor-pointer ${isSortDropdown ? "shadow-ring-gray" : ""
          }`}
      >
        <span className="text-sm font-semibold text-secondary-700 whitespace-nowrap">
          {t("product:manage")}
        </span>
        <span className="min-w-[20px] min-h-[20px]">
          <ArrowDownIcon />
        </span>
      </div>
      {isSortDropdown === true && (
        <div className="flex flex-col min-w-[240px] max-w-[240px] h-fit absolute top-[48px] right-0 bg-white border z-[999999999] rounded-lg shadow-lg py-1 px-[6px] gap-y-[2px] border-secondary-200">
          {currentOperation?.type === "internal" ? (
            <>
              {optionsInternal?.map((item, index) => {
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();

                      if (item.value === "addJunction") {
                        setIsOpenJunction(true);
                        setIsSortDropdown(false);
                      }
                      if (item.value === "appliedStock") {
                        setIsOpenFromStock(true);
                        setIsSortDropdown(false);
                      }
                      if (item.value === "service") {
                        setIsOpenPurchase(true);
                        setIsSortDropdown(false);
                      }
                      // if (!disabled) {
                      //   if (item.value === "finishOperation") {
                      //     setIsOpenFinishOperation(true);
                      //     setIsSortDropdown(false);
                      //   }
                      // }
                    }}
                    key={index}
                    className={`flex items-center min-h-[38px] max-h-[38px] hover:bg-secondary-50 rounded-md gap-x-3 px-[10px] py-[9px] cursor-pointer  `}
                  >
                    <div className="flex gap-x-2 items-center w-full">
                      <div className="flex min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] justify-end items-center">
                        {item?.icon}
                      </div>
                      <span
                        className={`text-sm  font-medium text-[#344054]`}
                      >
                        {item?.label}
                      </span>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <>
              {optionsExternal?.map((item, index) => {

                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item.value === "appliedStock") {
                        setIsOpenFromStock(true);
                        setIsSortDropdown(false);
                      }
                      if (item.value === "service") {
                        setIsOpenPurchase(true);
                        setIsSortDropdown(false);
                      }
                    }}
                    key={index}
                    className={`flex items-center min-h-[38px] max-h-[38px] hover:bg-secondary-50 rounded-md gap-x-3 px-[10px] py-[9px] cursor-pointer  `}
                  >
                    <div className="flex gap-x-2 items-center w-full">
                      <div className="flex min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] justify-end items-center">
                        {item?.icon}
                      </div>

                      <span
                        className={`text-sm  font-medium text-[#344054]`}
                      >
                        {item?.label}
                      </span>
                    </div>
                  </div>
                )
              })}
            </>
          )}

        </div>
      )}
    </div>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6.00065 1.33398V10.6673M1.33398 6.00065H10.6673"
        stroke="#475467"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_21552_77481)">
        <path d="M5.49967 1.33331H3.83301C2.4523 1.33331 1.33301 2.4526 1.33301 3.83331V12.1666C1.33301 13.5474 2.4523 14.6666 3.83301 14.6666H6.51819M5.49967 1.33331V5.11903C5.49967 5.21609 5.60311 5.27815 5.68875 5.23247L7.99967 3.99998L10.3106 5.23247C10.3962 5.27815 10.4997 5.21609 10.4997 5.11903V1.33331M5.49967 1.33331H10.4997M10.4997 1.33331H12.1663C13.5471 1.33331 14.6663 2.4526 14.6663 3.83331V7.20963M14.667 11.96H9.28973M14.667 11.96L11.9865 14.6666M14.667 11.96L11.9865 9.29838" stroke="#667085" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_21552_77481">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ServiceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M15.3193 8.00043C15.3193 7.63316 15.0215 7.33543 14.6543 7.33543C14.287 7.33543 13.9893 7.63316 13.9893 8.00043H15.3193ZM13.9893 8.00043C13.9893 11.3112 11.3054 13.9951 7.99455 13.9951V15.3251C12.0399 15.3251 15.3193 12.0458 15.3193 8.00043H13.9893ZM7.99455 13.9951C6.15652 13.9951 4.51215 13.1688 3.41147 11.8649L2.39517 12.7228C3.73775 14.3132 5.74832 15.3251 7.99455 15.3251V13.9951Z" fill="#667085" />
      <path d="M0.669961 8.00042C0.669961 8.36769 0.967692 8.66542 1.33496 8.66542C1.70223 8.66542 1.99996 8.36769 1.99996 8.00042H0.669961ZM1.99996 8.00042C1.99996 4.68961 4.68388 2.0057 7.99468 2.0057V0.675698C3.94934 0.675698 0.669961 3.95508 0.669961 8.00042H1.99996ZM7.99468 2.0057C9.80825 2.0057 11.4332 2.81021 12.5335 4.08412L13.54 3.21476C12.1978 1.6608 10.2109 0.675698 7.99468 0.675698V2.0057Z" fill="#667085" />
      <path d="M2.2851 12.5216C2.81092 12.5216 3.23719 12.0953 3.23719 11.5695C3.23719 11.0436 2.81092 10.6174 2.2851 10.6174C1.75927 10.6174 1.33301 11.0436 1.33301 11.5695C1.33301 12.0953 1.75927 12.5216 2.2851 12.5216Z" stroke="#667085" stroke-width="1.33" stroke-miterlimit="10" />
      <path d="M13.7138 5.26954C14.2396 5.26954 14.6659 4.84327 14.6659 4.31745C14.6659 3.79162 14.2396 3.36536 13.7138 3.36536C13.188 3.36536 12.7617 3.79162 12.7617 4.31745C12.7617 4.84327 13.188 5.26954 13.7138 5.26954Z" stroke="#667085" stroke-width="1.33" stroke-miterlimit="10" />
      <path d="M10.4643 9.9372C10.7381 9.77908 10.9069 9.48687 10.9069 9.17064V6.82973C10.9069 6.5135 10.7381 6.22129 10.4643 6.06317L8.43703 4.89273C8.16316 4.73461 7.82575 4.73461 7.55188 4.89273L5.52461 6.06317C5.25074 6.22129 5.08203 6.5135 5.08203 6.82973V9.17064C5.08203 9.48687 5.25074 9.77908 5.52461 9.9372L7.55188 11.1076C7.82575 11.2658 8.16316 11.2658 8.43703 11.1076L10.4643 9.9372Z" stroke="#667085" stroke-width="1.33" stroke-miterlimit="10" />
      <path d="M5.08203 6.31866L7.1093 7.48912C7.65703 7.80536 8.33188 7.80536 8.87961 7.48912L10.9069 6.31866" stroke="#667085" stroke-width="1.33" stroke-miterlimit="10" />
      <path d="M7.99414 8.00018V11.3631" stroke="#667085" stroke-width="1.33" stroke-miterlimit="10" />
    </svg>
  );
};

export const StopIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.0799 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2Z"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FinishOperationDropDown;
