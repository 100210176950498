import React from "react";

const CustomText = ({ fontSize, style, text, numberOfLines, ...rest }) => {
  return (
    <p
      style={{
        fontSize: fontSize,
        color: "#000",
        ...style,
      }}
      {...rest}
      className={numberOfLines === 1 ? "truncate w-[90%]" : ""}
    >
      {text}
    </p>
  );
};

export default CustomText;
