export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 1V15M1 8H15"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TargetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M12.8334 6.99996H10.5001M3.50008 6.99996H1.16675M7.00008 3.49996V1.16663M7.00008 12.8333V10.5M11.6667 6.99996C11.6667 9.57729 9.57741 11.6666 7.00008 11.6666C4.42275 11.6666 2.33341 9.57729 2.33341 6.99996C2.33341 4.42263 4.42275 2.33329 7.00008 2.33329C9.57741 2.33329 11.6667 4.42263 11.6667 6.99996Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GreenAvgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M4.66667 7.58333V9.91667M9.33333 6.41667V9.91667M7 4.08333V9.91667M4.55 12.25H9.45C10.4301 12.25 10.9201 12.25 11.2945 12.0593C11.6238 11.8915 11.8915 11.6238 12.0593 11.2945C12.25 10.9201 12.25 10.4301 12.25 9.45V4.55C12.25 3.56991 12.25 3.07986 12.0593 2.70552C11.8915 2.37623 11.6238 2.10852 11.2945 1.94074C10.9201 1.75 10.4301 1.75 9.45 1.75H4.55C3.56991 1.75 3.07986 1.75 2.70552 1.94074C2.37623 2.10852 2.10852 2.37623 1.94074 2.70552C1.75 3.07986 1.75 3.56991 1.75 4.55V9.45C1.75 10.4301 1.75 10.9201 1.94074 11.2945C2.10852 11.6238 2.37623 11.8915 2.70552 12.0593C3.07986 12.25 3.56991 12.25 4.55 12.25Z"
        stroke="#079455"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const BreakIconNewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.33333 10V6M9.66667 10V6M5.2 14H10.8C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8V5.2C14 4.0799 14 3.51984 13.782 3.09202C13.5903 2.71569 13.2843 2.40973 12.908 2.21799C12.4802 2 11.9201 2 10.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14Z"
        stroke="#667085"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.16663 9.99984H15.8333M15.8333 9.99984L9.99996 4.1665M15.8333 9.99984L9.99996 15.8332"
        stroke="#6941C6"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PersonDashboardIconLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g mask="url(#mask0_3864_6084)">
        <path
          d="M10.5938 10.5937C10.5938 11.4567 9.89418 12.1562 9.03125 12.1562H4.34375C3.48082 12.1562 2.78125 11.4567 2.78125 10.5937V4.34375C2.78125 3.48082 3.48082 2.78125 4.34375 2.78125H9.03125C9.89418 2.78125 10.5938 3.48082 10.5938 4.34375V10.5937Z"
          stroke="#F0FDF9"
          strokeWidth="1.5625"
          strokeMiterlimit="10"
        />
        <path
          d="M21.2188 19.6562C21.2188 20.5192 20.5192 21.2188 19.6562 21.2188H14.9688C14.1058 21.2188 13.4062 20.5192 13.4062 19.6562V13.4062C13.4062 12.5433 14.1058 11.8437 14.9688 11.8437H19.6562C20.5192 11.8437 21.2188 12.5433 21.2188 13.4062V19.6562Z"
          stroke="#F0FDF9"
          strokeWidth="1.5625"
          strokeMiterlimit="10"
        />
        <path
          d="M10.5938 19.6562C10.5938 20.5192 9.89418 21.2188 9.03125 21.2188H4.34375C3.48082 21.2188 2.78125 20.5192 2.78125 19.6562V16.5312C2.78125 15.6683 3.48082 14.9688 4.34375 14.9688H9.03125C9.89418 14.9688 10.5938 15.6683 10.5938 16.5312V19.6562Z"
          stroke="#F0FDF9"
          strokeWidth="1.5625"
          strokeMiterlimit="10"
        />
        <path
          d="M21.2188 7.46875C21.2188 8.33168 20.5192 9.03125 19.6562 9.03125H14.9688C14.1058 9.03125 13.4062 8.33168 13.4062 7.46875V4.34375C13.4062 3.48082 14.1058 2.78125 14.9688 2.78125H19.6562C20.5192 2.78125 21.2188 3.48082 21.2188 4.34375V7.46875Z"
          stroke="#F0FDF9"
          strokeWidth="1.5625"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
};
export const PersonnelAllIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M18 9.99984L8 9.99984M18 4.99984L8 4.99984M18 14.9998L8 14.9998M4.66667 9.99984C4.66667 10.4601 4.29357 10.8332 3.83333 10.8332C3.3731 10.8332 3 10.4601 3 9.99984C3 9.5396 3.3731 9.1665 3.83333 9.1665C4.29357 9.1665 4.66667 9.5396 4.66667 9.99984ZM4.66667 4.99984C4.66667 5.46007 4.29357 5.83317 3.83333 5.83317C3.3731 5.83317 3 5.46007 3 4.99984C3 4.5396 3.3731 4.1665 3.83333 4.1665C4.29357 4.1665 4.66667 4.5396 4.66667 4.99984ZM4.66667 14.9998C4.66667 15.4601 4.29357 15.8332 3.83333 15.8332C3.3731 15.8332 3 15.4601 3 14.9998C3 14.5396 3.3731 14.1665 3.83333 14.1665C4.29357 14.1665 4.66667 14.5396 4.66667 14.9998Z"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LoadIconPersonnel = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.6673 7.99967L8.00065 5.33301M8.00065 5.33301L5.33398 7.99967M8.00065 5.33301V11.4663C8.00065 12.3935 8.00065 12.857 8.36766 13.3761C8.61151 13.7209 9.3136 14.1466 9.73213 14.2033C10.3621 14.2886 10.6013 14.1638 11.0797 13.9142C13.2118 12.8021 14.6673 10.5708 14.6673 7.99967C14.6673 4.31777 11.6825 1.33301 8.00065 1.33301C4.31875 1.33301 1.33398 4.31778 1.33398 7.99967C1.33398 10.4673 2.67465 12.6218 4.66732 13.7745"
        stroke="#107569"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FlexProductIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66667 0C1.19391 0 0 1.19391 0 2.66667V13.3333C0 14.8061 1.19391 16 2.66667 16H13.3333C14.8061 16 16 14.8061 16 13.3333V2.66667C16 1.19391 14.8061 0 13.3333 0H2.66667ZM5.29831 3.15152V12.8485H7.05494V8.72917H11.0843V7.25663H7.05494V4.62405H11.5104V3.15152H5.29831Z"
        fill="#7F56D9"
      />
    </svg>
  );
};

export const JunctionNumberIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.91667 7.47111C7.91667 7.07337 7.91667 6.8745 7.99978 6.76348C8.07222 6.66673 8.18309 6.6062 8.30365 6.59759C8.44199 6.58771 8.60927 6.69524 8.94384 6.91032L12.8777 9.43921C13.168 9.62585 13.3131 9.71917 13.3633 9.83783C13.4071 9.9415 13.4071 10.0585 13.3633 10.1622C13.3131 10.2808 13.168 10.3742 12.8777 10.5608L8.94384 13.0897C8.60927 13.3048 8.44199 13.4123 8.30365 13.4024C8.18309 13.3938 8.07222 13.3333 7.99978 13.2365C7.91667 13.1255 7.91667 12.9266 7.91667 12.5289V7.47111Z"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5Z"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const JunctionNumberIconSelected = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.91667 7.47111C7.91667 7.07337 7.91667 6.8745 7.99978 6.76348C8.07222 6.66673 8.18309 6.6062 8.30365 6.59759C8.44199 6.58771 8.60927 6.69524 8.94384 6.91032L12.8777 9.43921C13.168 9.62585 13.3131 9.71917 13.3633 9.83783C13.4071 9.9415 13.4071 10.0585 13.3633 10.1622C13.3131 10.2808 13.168 10.3742 12.8777 10.5608L8.94384 13.0897C8.60927 13.3048 8.44199 13.4123 8.30365 13.4024C8.18309 13.3938 8.07222 13.3333 7.99978 13.2365C7.91667 13.1255 7.91667 12.9266 7.91667 12.5289V7.47111Z"
        stroke="#6941C6"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5Z"
        stroke="#6941C6"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const JunctionLossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 6.66667V10M10 13.3333H10.0083M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
        stroke="#667085"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ContinueNewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.33333 5.97689C6.33333 5.6587 6.33333 5.4996 6.39983 5.41078C6.45778 5.33338 6.54648 5.28496 6.64292 5.27807C6.75359 5.27016 6.88742 5.3562 7.15507 5.52826L10.3021 7.55137C10.5344 7.70068 10.6505 7.77533 10.6906 7.87026C10.7257 7.9532 10.7257 8.0468 10.6906 8.12974C10.6505 8.22467 10.5344 8.29932 10.3021 8.44863L7.15507 10.4717C6.88742 10.6438 6.75359 10.7298 6.64292 10.7219C6.54648 10.715 6.45778 10.6666 6.39983 10.5892C6.33333 10.5004 6.33333 10.3413 6.33333 10.0231V5.97689Z"
        stroke="#667085"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.0799 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2Z"
        stroke="#667085"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BreakIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.835 5.59961C6.835 5.13845 6.46116 4.76461 6 4.76461C5.53884 4.76461 5.165 5.13845 5.165 5.59961V10.3996C5.165 10.8608 5.53884 11.2346 6 11.2346C6.46116 11.2346 6.835 10.8608 6.835 10.3996V5.59961ZM10.835 5.59961C10.835 5.13845 10.4612 4.76461 10 4.76461C9.53884 4.76461 9.165 5.13845 9.165 5.59961V10.3996C9.165 10.8608 9.53884 11.2346 10 11.2346C10.4612 11.2346 10.835 10.8608 10.835 10.3996V5.59961Z"
        fill="white"
      />
    </svg>
  );
};

export const ContinueCardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99976 16C13.6493 16 17.4185 12.4183 17.4185 8C17.4185 3.58172 13.6493 0 8.99976 0C4.35024 0 0.581055 3.58172 0.581055 8C0.581055 12.4183 4.35024 16 8.99976 16ZM7.22328 4.92377C7.15197 5.01804 7.15197 5.18905 7.15197 5.53108V10.4689C7.15197 10.8109 7.15197 10.982 7.22328 11.0762C7.2854 11.1584 7.38036 11.2092 7.48316 11.2153C7.60115 11.2224 7.74344 11.1275 8.02802 10.9378L11.7314 8.46885C11.9666 8.31209 12.0841 8.23371 12.1251 8.13491C12.1609 8.04854 12.1609 7.95146 12.1251 7.86509C12.0841 7.76629 11.9666 7.68791 11.7314 7.53115L8.02802 5.06222L8.02802 5.06222C7.74344 4.8725 7.60115 4.77764 7.48316 4.78469C7.38036 4.79082 7.2854 4.84164 7.22328 4.92377Z"
        fill="white"
      />
    </svg>
  );
};

export const BreakModalsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.5 15V9M14.5 15V9M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ContinueModalsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.5 8.96533C9.5 8.48805 9.5 8.24941 9.59974 8.11618C9.68666 8.00007 9.81971 7.92744 9.96438 7.9171C10.1304 7.90525 10.3311 8.03429 10.7326 8.29239L15.4532 11.3271C15.8016 11.551 15.9758 11.663 16.0359 11.8054C16.0885 11.9298 16.0885 12.0702 16.0359 12.1946C15.9758 12.337 15.8016 12.449 15.4532 12.6729L10.7326 15.7076C10.3311 15.9657 10.1304 16.0948 9.96438 16.0829C9.81971 16.0726 9.68666 15.9999 9.59974 15.8838C9.5 15.7506 9.5 15.512 9.5 15.0347V8.96533Z"
        stroke="#079455"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z"
        stroke="#079455"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ErrorIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M5.875 4V6M5.875 8H5.88M10.875 6C10.875 8.76142 8.63642 11 5.875 11C3.11358 11 0.875 8.76142 0.875 6C0.875 3.23858 3.11358 1 5.875 1C8.63642 1 10.875 3.23858 10.875 6Z"
        stroke="#DC6803"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RedAvgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M4.66667 7.58333V9.91667M9.33333 6.41667V9.91667M7 4.08333V9.91667M4.55 12.25H9.45C10.4301 12.25 10.9201 12.25 11.2945 12.0593C11.6238 11.8915 11.8915 11.6238 12.0593 11.2945C12.25 10.9201 12.25 10.4301 12.25 9.45V4.55C12.25 3.56991 12.25 3.07986 12.0593 2.70552C11.8915 2.37623 11.6238 2.10852 11.2945 1.94074C10.9201 1.75 10.4301 1.75 9.45 1.75H4.55C3.56991 1.75 3.07986 1.75 2.70552 1.94074C2.37623 2.10852 2.10852 2.37623 1.94074 2.70552C1.75 3.07986 1.75 3.56991 1.75 4.55V9.45C1.75 10.4301 1.75 10.9201 1.94074 11.2945C2.10852 11.6238 2.37623 11.8915 2.70552 12.0593C3.07986 12.25 3.56991 12.25 4.55 12.25Z"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10 3L4.5 8.5L2 6"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7.00008 3.49996V6.99996L9.33342 8.16663M12.8334 6.99996C12.8334 10.2216 10.2217 12.8333 7.00008 12.8333C3.77842 12.8333 1.16675 10.2216 1.16675 6.99996C1.16675 3.7783 3.77842 1.16663 7.00008 1.16663C10.2217 1.16663 12.8334 3.7783 12.8334 6.99996Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MadePlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.50638 4.75122V7.3837L4.86108 8.37088"
        stroke="#CA8504"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7749 7.68289C12.799 7.45815 12.8113 7.22991 12.8113 6.99879C12.8113 3.48586 9.9635 0.638062 6.45057 0.638062C2.93764 0.638062 0.0898438 3.48586 0.0898438 6.99879C0.0898438 10.5117 2.93764 13.3595 6.45057 13.3595C7.03979 13.3595 7.6103 13.2794 8.15179 13.1295V12.0975C7.61716 12.2758 7.04514 12.3723 6.45057 12.3723C3.48284 12.3723 1.07702 9.96652 1.07702 6.99879C1.07702 4.03106 3.48284 1.62524 6.45057 1.62524C9.4183 1.62524 11.8241 4.03106 11.8241 6.99879C11.8241 7.00891 11.8241 7.01903 11.824 7.02915L12.7749 7.68289Z"
        fill="#CA8504"
      />
      <path
        d="M9.46802 7.97353C9.46802 7.70342 9.46802 7.56836 9.52434 7.49392C9.5734 7.42906 9.64839 7.38892 9.72957 7.38408C9.82276 7.37851 9.93513 7.45343 10.1599 7.60326L13.0846 9.55307C13.2703 9.67687 13.3632 9.73878 13.3955 9.8168C13.4238 9.88501 13.4238 9.96167 13.3955 10.0299C13.3632 10.1079 13.2703 10.1698 13.0846 10.2936L10.1599 12.2434C9.93513 12.3933 9.82276 12.4682 9.72957 12.4626C9.64839 12.4578 9.5734 12.4176 9.52434 12.3528C9.46802 12.2783 9.46802 12.1433 9.46802 11.8732V7.97353Z"
        stroke="#CA8504"
        strokeWidth="0.98718"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PieceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.07812H3.25C2.42157 2.07812 1.75 2.7497 1.75 3.57813V10.4219C1.75 11.2503 2.42157 11.9219 3.25 11.9219H10.75C11.5784 11.9219 12.25 11.2503 12.25 10.4219V3.57813C12.25 2.7497 11.5784 2.07812 10.75 2.07812H8.96875M5.03125 2.07812V5.89427C5.03125 5.95003 5.08992 5.98629 5.13979 5.96135L7 5.03125L8.86021 5.96135C8.91008 5.98629 8.96875 5.95003 8.96875 5.89427V2.07812M5.03125 2.07812H8.96875"
        stroke="#475467"
      />
    </svg>
  );
};

export const EquCalculate = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M3.81953 10.7271C3.65079 10.8947 3.45097 11.0271 3.23146 11.1168C3.01195 11.2065 2.77706 11.2517 2.5402 11.2499C2.30334 11.2482 2.06915 11.1994 1.851 11.1064C1.63285 11.0134 1.43501 10.878 1.26878 10.7079C1.10255 10.5378 0.971184 10.3364 0.882179 10.1152C0.793175 9.89396 0.748278 9.65722 0.750051 9.4185C0.751823 9.17977 0.800232 8.94374 0.892511 8.72387C0.984791 8.504 1.11914 8.30461 1.28787 8.13707L6.45642 1.60911C6.71467 1.33922 7.02391 1.12418 7.36591 0.976637C7.70791 0.829097 8.07575 0.752051 8.44776 0.75004C8.81977 0.74803 9.18841 0.821094 9.53196 0.964928C9.87551 1.10876 10.187 1.32045 10.4481 1.58753C10.7092 1.8546 10.9146 2.17165 11.0523 2.52C11.1899 2.86834 11.2569 3.24092 11.2494 3.61579C11.2419 3.99065 11.1601 4.36021 11.0087 4.70269C10.8572 5.04516 10.6393 5.35362 10.3678 5.60989L3.81953 10.7271Z"
        stroke="#6941C6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.84525 9.84723C3.23346 9.84723 3.54817 9.53206 3.54817 9.14328C3.54817 8.7545 3.23346 8.43933 2.84525 8.43933C2.45704 8.43933 2.14233 8.7545 2.14233 9.14328C2.14233 9.53206 2.45704 9.84723 2.84525 9.84723Z"
        stroke="#6941C6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.44049 4.91932C9.21852 4.91932 9.84924 4.28898 9.84924 3.51142C9.84924 2.73385 9.21852 2.10352 8.44049 2.10352C7.66246 2.10352 7.03174 2.73385 7.03174 3.51142C7.03174 4.28898 7.66246 4.91932 8.44049 4.91932Z"
        stroke="#6941C6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RejectedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.04163H3.25C2.42157 2.04163 1.75 2.7132 1.75 3.54163V10.3854C1.75 11.2138 2.42157 11.8854 3.25 11.8854H10.75C11.5784 11.8854 12.25 11.2138 12.25 10.3854V3.54163C12.25 2.7132 11.5784 2.04163 10.75 2.04163H8.96875M5.03125 2.04163V4.69111C5.03125 4.74686 5.08992 4.78312 5.13979 4.75819L7 3.82808L8.86021 4.75819C8.91008 4.78312 8.96875 4.74686 8.96875 4.69111V2.04163M5.03125 2.04163H8.96875"
        stroke="#D92D20"
      />
      <path
        d="M8.45829 6.70837L5.54163 9.62504M5.54163 6.70837L8.45829 9.62504"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AcceptedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.04163H3.25C2.42157 2.04163 1.75 2.7132 1.75 3.54163V10.3854C1.75 11.2138 2.42157 11.8854 3.25 11.8854H10.75C11.5784 11.8854 12.25 11.2138 12.25 10.3854V3.54163C12.25 2.7132 11.5784 2.04163 10.75 2.04163H8.96875M5.03125 2.04163V4.69111C5.03125 4.74686 5.08992 4.78312 5.13979 4.75819L7 3.82808L8.86021 4.75819C8.91008 4.78312 8.96875 4.74686 8.96875 4.69111V2.04163M5.03125 2.04163H8.96875"
        stroke="#079455"
      />
      <path
        d="M5.25 8.27889L6.50641 9.5353L9.33333 6.70837"
        stroke="#079455"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.6667 3.99967V3.46634C10.6667 2.7196 10.6667 2.34624 10.5213 2.06102C10.3935 1.81014 10.1895 1.60616 9.93865 1.47833C9.65344 1.33301 9.28007 1.33301 8.53333 1.33301H7.46667C6.71993 1.33301 6.34656 1.33301 6.06135 1.47833C5.81046 1.60616 5.60649 1.81014 5.47866 2.06102C5.33333 2.34624 5.33333 2.7196 5.33333 3.46634V3.99967M6.66667 7.66634V10.9997M9.33333 7.66634V10.9997M2 3.99967H14M12.6667 3.99967V11.4663C12.6667 12.5864 12.6667 13.1465 12.4487 13.5743C12.2569 13.9506 11.951 14.2566 11.5746 14.4484C11.1468 14.6663 10.5868 14.6663 9.46667 14.6663H6.53333C5.41323 14.6663 4.85318 14.6663 4.42535 14.4484C4.04903 14.2566 3.74307 13.9506 3.55132 13.5743C3.33333 13.1465 3.33333 12.5864 3.33333 11.4663V3.99967"
        stroke="#D92D20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PersonnelDetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6673 14L14.6673 12M14.6673 12L12.6673 10M14.6673 12H10.6673M8.00065 10.3333H5.00065C4.07028 10.3333 3.60509 10.3333 3.22656 10.4482C2.37429 10.7067 1.70734 11.3736 1.44881 12.2259C1.33398 12.6044 1.33398 13.0696 1.33398 14M9.66732 5C9.66732 6.65685 8.32417 8 6.66732 8C5.01046 8 3.66732 6.65685 3.66732 5C3.66732 3.34315 5.01046 2 6.66732 2C8.32417 2 9.66732 3.34315 9.66732 5Z"
        stroke="#475467"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const DotsVerticalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0001 10.833C10.4603 10.833 10.8334 10.4599 10.8334 9.99967C10.8334 9.53944 10.4603 9.16634 10.0001 9.16634C9.53984 9.16634 9.16675 9.53944 9.16675 9.99967C9.16675 10.4599 9.53984 10.833 10.0001 10.833Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 4.99967C10.4603 4.99967 10.8334 4.62658 10.8334 4.16634C10.8334 3.7061 10.4603 3.33301 10.0001 3.33301C9.53984 3.33301 9.16675 3.7061 9.16675 4.16634C9.16675 4.62658 9.53984 4.99967 10.0001 4.99967Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 16.6663C10.4603 16.6663 10.8334 16.2932 10.8334 15.833C10.8334 15.3728 10.4603 14.9997 10.0001 14.9997C9.53984 14.9997 9.16675 15.3728 9.16675 15.833C9.16675 16.2932 9.53984 16.6663 10.0001 16.6663Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EquipmentDetailIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M4.50804 12.4024C4.31519 12.5939 4.08682 12.7452 3.83595 12.8477C3.58509 12.9503 3.31664 13.002 3.04595 12.9999C2.77525 12.9979 2.5076 12.9421 2.25829 12.8358C2.00897 12.7296 1.78287 12.5748 1.59289 12.3804C1.40292 12.1861 1.25278 11.9559 1.15106 11.7031C1.04934 11.4502 0.998032 11.1797 1.00006 10.9069C1.00208 10.634 1.05741 10.3643 1.16287 10.113C1.26833 9.86172 1.42187 9.63384 1.61471 9.44236L7.52163 1.98184C7.81676 1.6734 8.17018 1.42763 8.56104 1.25901C8.95189 1.0904 9.37229 1.00234 9.79744 1.00005C10.2226 0.997748 10.6439 1.08125 11.0365 1.24563C11.4292 1.41001 11.7852 1.65195 12.0836 1.95718C12.382 2.2624 12.6167 2.62475 12.774 3.02285C12.9313 3.42096 13.0079 3.84677 12.9994 4.27518C12.9908 4.7036 12.8972 5.12596 12.7242 5.51736C12.5511 5.90876 12.3021 6.26128 11.9917 6.55416L4.50804 12.4024Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.39415 11.3961C3.83782 11.3961 4.19749 11.0359 4.19749 10.5916C4.19749 10.1473 3.83782 9.78711 3.39415 9.78711C2.95048 9.78711 2.59082 10.1473 2.59082 10.5916C2.59082 11.0359 2.95048 11.3961 3.39415 11.3961Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.78871 5.76493C10.6779 5.76493 11.3987 5.04455 11.3987 4.1559C11.3987 3.26726 10.6779 2.54688 9.78871 2.54688C8.89953 2.54688 8.17871 3.26726 8.17871 4.1559C8.17871 5.04455 8.89953 5.76493 9.78871 5.76493Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DateCardIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M12.25 5.83317H1.75M9.33333 1.1665V3.49984M4.66667 1.1665V3.49984M4.55 12.8332H9.45C10.4301 12.8332 10.9201 12.8332 11.2945 12.6424C11.6238 12.4747 11.8915 12.2069 12.0593 11.8777C12.25 11.5033 12.25 11.0133 12.25 10.0332V5.13317C12.25 4.15308 12.25 3.66303 12.0593 3.28869C11.8915 2.9594 11.6238 2.69169 11.2945 2.52391C10.9201 2.33317 10.4301 2.33317 9.45 2.33317H4.55C3.56991 2.33317 3.07986 2.33317 2.70552 2.52391C2.37623 2.69169 2.10852 2.9594 1.94074 3.28869C1.75 3.66303 1.75 4.15308 1.75 5.13317V10.0332C1.75 11.0133 1.75 11.5033 1.94074 11.8777C2.10852 12.2069 2.37623 12.4747 2.70552 12.6424C3.07986 12.8332 3.56991 12.8332 4.55 12.8332Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EquipmentEditIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.91769 12.0766C1.94831 11.801 1.96363 11.6631 2.00533 11.5343C2.04234 11.42 2.09461 11.3113 2.16075 11.211C2.2353 11.0979 2.33336 10.9999 2.52948 10.8037L11.3337 1.99955C12.0701 1.26317 13.264 1.26317 14.0003 1.99955C14.7367 2.73593 14.7367 3.92984 14.0003 4.66622L5.19614 13.4704C5.00002 13.6665 4.90196 13.7646 4.78892 13.8391C4.68862 13.9053 4.57985 13.9575 4.46556 13.9945C4.33673 14.0362 4.1989 14.0516 3.92324 14.0822L1.66699 14.3329L1.91769 12.0766Z"
        stroke="#667085"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FinishJunctionIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.0799 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2Z"
        stroke="#D92D20"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StartClockIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M12.8247 7.32084C12.8334 7.00008 12.8334 7.10771 12.8334 7.00008C12.8334 3.77842 10.2217 1.16675 7.00008 1.16675C3.77842 1.16675 1.16675 3.77842 1.16675 7.00008C1.16675 10.1707 3.69638 12.7506 6.84753 12.8315M7.00008 3.50008V7.00008L4.81936 8.09044M12.2556 9.8553L9.54459 8.02269C9.33197 7.87896 9.22566 7.80709 9.13741 7.81196C9.06053 7.8162 8.9894 7.85398 8.94283 7.91529C8.88937 7.98568 8.88937 8.114 8.88937 8.37064V12.0413C8.88937 12.2983 8.88937 12.4268 8.9429 12.4972C8.98952 12.5586 9.06073 12.5963 9.13766 12.6005C9.22597 12.6053 9.33234 12.5332 9.54507 12.3889L12.256 10.5509C12.4281 10.4343 12.5141 10.3759 12.5441 10.3028C12.5704 10.2388 12.5703 10.167 12.544 10.103C12.5138 10.0299 12.4277 9.9717 12.2556 9.8553Z"
        stroke="#17B26A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ChevronDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15 12.5L10 7.5L5 12.5"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EndClockIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M12.8247 7.32084C12.8305 7.21465 12.8334 7.10771 12.8334 7.00008C12.8334 3.77842 10.2217 1.16675 7.00008 1.16675C3.77842 1.16675 1.16675 3.77842 1.16675 7.00008C1.16675 10.1707 3.69638 12.7506 6.84753 12.8315M7.00008 3.50008V7.00008L4.81936 8.09044M12.577 11.9001V9.97252C12.577 9.64582 12.577 9.48247 12.5134 9.35769C12.4575 9.24793 12.3682 9.15869 12.2585 9.10276C12.1337 9.03918 11.9703 9.03918 11.6436 9.03918H9.71703C9.39034 9.03918 9.22699 9.03918 9.10221 9.10276C8.99245 9.15869 8.90321 9.24793 8.84728 9.35769C8.7837 9.48247 8.7837 9.64582 8.7837 9.97252V11.9001C8.7837 12.2268 8.7837 12.3901 8.84728 12.5149C8.90321 12.6247 8.99245 12.7139 9.10221 12.7698C9.22699 12.8334 9.39034 12.8334 9.71703 12.8334H11.6436C11.9703 12.8334 12.1337 12.8334 12.2585 12.7698C12.3682 12.7139 12.4575 12.6247 12.5134 12.5149C12.577 12.3901 12.577 12.2268 12.577 11.9001Z"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const QualityDetailIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_9915_14158"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path d="M0 1.90735e-06H24V24H0V1.90735e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_9915_14158)">
        <path
          d="M19.0312 12C19.0312 15.8833 15.8833 19.0312 12 19.0312C8.11673 19.0312 4.96875 15.8833 4.96875 12C4.96875 8.11673 8.11673 4.96875 12 4.96875C15.8833 4.96875 19.0312 8.11673 19.0312 12Z"
          stroke="#667085"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5156 9.89062L11.2969 14.1094L8.48438 11.2969"
          stroke="#667085"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0881 1.03887C11.6137 0.591213 12.3863 0.591213 12.9119 1.03887L14.7392 2.6516L17.8315 2.41231C18.5198 2.35906 19.145 2.81323 19.307 3.48429L19.927 6.05192L22.6288 7.6692C23.2169 8.0307 23.4557 8.7656 23.1924 9.40371L22.125 11.9901L23.1924 14.5766C23.4557 15.2147 23.2169 15.9496 22.6288 16.3111L19.927 17.9284L19.307 20.496C19.145 21.167 18.5198 21.6213 17.8315 21.568L14.7695 21.331L12.9119 22.9611C12.3863 23.4088 11.6137 23.4088 11.0881 22.9611L9.23053 21.331L6.16847 21.568C5.48015 21.6213 4.85498 21.167 4.69298 20.496L4.07053 17.9182L1.37325 16.3111C0.785062 15.9496 0.546281 15.2147 0.809672 14.5766L1.87701 11.9901L0.809672 9.40371C0.546281 8.7656 0.785062 8.0307 1.37325 7.6692L3.83934 6.20412L4.69298 3.48429C4.85498 2.81323 5.48015 2.35906 6.16847 2.41231L9.26081 2.6516L11.0881 1.03887Z"
          stroke="#667085"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const QuantityIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M7.54688 3.36719H4.875C3.63236 3.36719 2.625 4.37455 2.625 5.61719V15.8828C2.625 17.1255 3.63236 18.1328 4.875 18.1328H16.125C17.3676 18.1328 18.375 17.1255 18.375 15.8828V5.61719C18.375 4.37455 17.3676 3.36719 16.125 3.36719H13.4531M7.54688 3.36719V9.09141C7.54688 9.17504 7.63489 9.22943 7.70969 9.19203L10.5 7.79688L13.2903 9.19203C13.3651 9.22943 13.4531 9.17504 13.4531 9.09141V3.36719M7.54688 3.36719H13.4531"
        stroke="#475467"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const ClockStart = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M19.237 11.7311C19.25 11.25 19.25 11.4114 19.25 11.25C19.25 6.41751 15.3325 2.5 10.5 2.5C5.66751 2.5 1.75 6.41751 1.75 11.25C1.75 16.006 5.54445 19.8757 10.2712 19.9971M10.5 6V11.25L7.22893 12.8855M18.3832 15.5328L14.3168 12.7839C13.9978 12.5683 13.8384 12.4605 13.706 12.4678C13.5907 12.4742 13.484 12.5308 13.4141 12.6228C13.3339 12.7284 13.3339 12.9209 13.3339 13.3058V18.8118C13.3339 19.1973 13.3339 19.3901 13.4142 19.4957C13.4842 19.5877 13.591 19.6443 13.7064 19.6506C13.8388 19.6578 13.9984 19.5496 14.3175 19.3333L18.3839 16.5762C18.642 16.4013 18.771 16.3138 18.8161 16.204C18.8555 16.108 18.8554 16.0004 18.8158 15.9044C18.7706 15.7947 18.6415 15.7074 18.3832 15.5328Z"
        stroke="#17B26A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ClockEnd = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M19.237 11.7311C19.2456 11.5719 19.25 11.4114 19.25 11.25C19.25 6.41751 15.3325 2.5 10.5 2.5C5.66751 2.5 1.75 6.41751 1.75 11.25C1.75 16.006 5.54445 19.8757 10.2712 19.9971M10.5 6V11.25L7.22893 12.8855M18.8653 18.6V15.7087C18.8653 15.2186 18.8653 14.9736 18.7699 14.7864C18.6861 14.6218 18.5522 14.4879 18.3876 14.404C18.2004 14.3087 17.9554 14.3087 17.4653 14.3087H14.5754C14.0854 14.3087 13.8404 14.3087 13.6532 14.404C13.4885 14.4879 13.3547 14.6218 13.2708 14.7864C13.1754 14.9736 13.1754 15.2186 13.1754 15.7087V18.6C13.1754 19.09 13.1754 19.3351 13.2708 19.5222C13.3547 19.6869 13.4885 19.8207 13.6532 19.9046C13.8404 20 14.0854 20 14.5754 20H17.4653C17.9554 20 18.2004 20 18.3876 19.9046C18.5522 19.8207 18.6861 19.6869 18.7699 19.5222C18.8653 19.3351 18.8653 19.09 18.8653 18.6Z"
        stroke="#F04438"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ScrapIcons = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path
        d="M4.625 1H2.75C2.05964 1 1.5 1.55964 1.5 2.25V9.75C1.5 10.4404 2.05965 11 2.75 11H5.38889M4.625 1V3.89286C4.625 3.94139 4.67672 3.97242 4.71954 3.94958L6.5 3L8.28046 3.94958C8.32328 3.97242 8.375 3.94139 8.375 3.89286V1M4.625 1H8.375M8.375 1H10.25C10.9404 1 11.5 1.55964 11.5 2.25V5.40724M7.3707 7.82183H7.87186M11.4993 7.82183H10.9997M8.37301 7.82183V6.81242C8.37301 6.59151 8.55209 6.41242 8.77301 6.41242H10.1C10.3209 6.41242 10.5 6.59151 10.5 6.81242V7.82183M8.37301 7.82183H10.5M8.37301 7.82183H7.87186M10.5 7.82183H10.9997M7.87186 7.82183V10.375C7.87186 10.7202 8.15168 11 8.49686 11H10.3747C10.7198 11 10.9997 10.7202 10.9997 10.375V7.82183"
        stroke="#F04438"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AddPlusIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
    >
      <path
        d="M15.5 6.75V24.25M6.75 15.5H24.25"
        stroke="#7F56D9"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LossIconDropDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 14H3.06667C2.6933 14 2.50661 14 2.36401 13.9273C2.23856 13.8634 2.13658 13.7614 2.07266 13.636C2 13.4934 2 13.3067 2 12.9333V2M14 10L10.3771 6.37712C10.2451 6.24512 10.1791 6.17912 10.103 6.15439C10.0361 6.13263 9.96394 6.13263 9.89699 6.15439C9.82089 6.17912 9.75488 6.24512 9.62288 6.37712L8.37712 7.62288C8.24512 7.75488 8.17912 7.82088 8.10301 7.84561C8.03606 7.86737 7.96394 7.86737 7.89699 7.84561C7.82088 7.82088 7.75488 7.75488 7.62288 7.62288L4.66667 4.66667M14 10H11.3333M14 10V7.33333"
        stroke="#667085"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RezervStockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17757_65317)">
        <path
          d="M5.49967 1.3335H2.99967C2.0792 1.3335 1.33301 2.07969 1.33301 3.00016V13.0002C1.33301 13.9206 2.0792 14.6668 2.99968 14.6668H6.51819M5.49967 1.3335V5.19064C5.49967 5.25535 5.56863 5.29672 5.62573 5.26627L7.99967 4.00016L10.3736 5.26627C10.4307 5.29672 10.4997 5.25535 10.4997 5.19064V1.3335M5.49967 1.3335H10.4997M10.4997 1.3335H12.9997C13.9201 1.3335 14.6663 2.07969 14.6663 3.00016V7.20981M10.7849 11.5083H10.1507C9.78253 11.5083 9.48405 11.8068 9.48405 12.175V14.0002C9.48405 14.3684 9.78253 14.6668 10.1507 14.6668H13.9976C14.3658 14.6668 14.6643 14.3684 14.6643 14.0002V12.175C14.6643 11.8068 14.3658 11.5083 13.9976 11.5083H13.3662M10.7849 11.5083V10.0316C10.7849 9.31875 11.3627 8.7409 12.0755 8.7409V8.7409C12.7883 8.7409 13.3662 9.31875 13.3662 10.0316V11.5083M10.7849 11.5083H13.3662"
          stroke="#475467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17757_65317">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FreeStockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17757_65322)">
        <path
          d="M5.49967 1.33325H2.99967C2.0792 1.33325 1.33301 2.07944 1.33301 2.99992V12.9999C1.33301 13.9204 2.0792 14.6666 2.99968 14.6666H6.51819M5.49967 1.33325V5.19039C5.49967 5.2551 5.56863 5.29648 5.62573 5.26602L7.99967 3.99992L10.3736 5.26602C10.4307 5.29648 10.4997 5.2551 10.4997 5.19039V1.33325M5.49967 1.33325H10.4997M10.4997 1.33325H12.9997C13.9201 1.33325 14.6663 2.07944 14.6663 2.99992V7.20957M14.667 11.9599H9.28973M14.667 11.9599L11.9865 14.6666M14.667 11.9599L11.9865 9.29832"
          stroke="#475467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17757_65322">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NewDotsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.16699 10.0001C9.16699 10.4603 9.54009 10.8334 10.0003 10.8334C10.4606 10.8334 10.8337 10.4603 10.8337 10.0001C10.8337 9.53984 10.4606 9.16675 10.0003 9.16675C9.54009 9.16675 9.16699 9.53984 9.16699 10.0001Z"
        stroke="#98A2B3"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0003 10.0001C15.0003 10.4603 15.3734 10.8334 15.8337 10.8334C16.2939 10.8334 16.667 10.4603 16.667 10.0001C16.667 9.53984 16.2939 9.16675 15.8337 9.16675C15.3734 9.16675 15.0003 9.53984 15.0003 10.0001Z"
        stroke="#98A2B3"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33366 10.0001C3.33366 10.4603 3.70676 10.8334 4.16699 10.8334C4.62723 10.8334 5.00033 10.4603 5.00033 10.0001C5.00033 9.53984 4.62723 9.16675 4.16699 9.16675C3.70676 9.16675 3.33366 9.53984 3.33366 10.0001Z"
        stroke="#98A2B3"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IncreaStockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clipPath="url(#clip0_17757_65499)">
        <path
          d="M6 4V8M4 6H8M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#17B26A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17757_65499">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DecraceStockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clipPath="url(#clip0_17757_65435)">
        <path
          d="M4 6H8M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#F04438"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17757_65435">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NewAcceptedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13 12" fill="none">
      <path
        d="M4.62402 1H2.74902C2.05867 1 1.49902 1.55964 1.49902 2.25V9.75C1.49902 10.4404 2.05867 11 2.74902 11H5.38791M4.62402 1V3.89286C4.62402 3.94139 4.67574 3.97242 4.71856 3.94958L6.49902 3L8.27949 3.94958C8.32231 3.97242 8.37402 3.94139 8.37402 3.89286V1M4.62402 1H8.37402M8.37402 1H10.249C10.9394 1 11.499 1.55964 11.499 2.25V5.40724M6.93436 9.09187L8.35254 10.502L11.5009 7.35764"
        stroke="#17B26A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NewRejectedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13 12" fill="none">
      <path
        d="M4.625 1H2.75C2.05964 1 1.5 1.55964 1.5 2.25V9.75C1.5 10.4404 2.05965 11 2.75 11H5.38889M4.625 1V3.89286C4.625 3.94139 4.67672 3.97242 4.71954 3.94958L6.5 3L8.28046 3.94958C8.32328 3.97242 8.375 3.94139 8.375 3.89286V1M4.625 1H8.375M8.375 1H10.25C10.9404 1 11.5 1.55964 11.5 2.25V5.40724M7.86344 7.34116L9.4369 8.93436M11.0176 10.5013L9.4369 8.93436M9.4369 8.93436L11.0176 7.34116M9.4369 8.93436L7.86344 10.5013"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ReadyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895508 0 0 0.895508 0 2V8C0 9.10449 0.895508 10 2 10H8C9.10449 10 10 9.10449 10 8V2C10 0.895508 9.10449 0 8 0H2ZM4.94043 1.58691C4.83301 1.59961 4.72852 1.64722 4.64648 1.72974C4.54199 1.83423 4.49316 1.97339 4.5 2.11011C4.50684 2.229 4.55566 2.34595 4.64648 2.43677L6.70898 4.5H2.08301C1.80664 4.5 1.58301 4.72388 1.58301 5C1.58301 5.27612 1.80664 5.5 2.08301 5.5H6.70898L4.64648 7.56299C4.52344 7.68604 4.47754 7.85718 4.50977 8.01562C4.52832 8.10889 4.57422 8.19775 4.64648 8.27002C4.8418 8.46533 5.1582 8.46533 5.35352 8.27002L8.26953 5.35352C8.46484 5.1582 8.46484 4.84155 8.26953 4.64648L5.35352 1.72974C5.24023 1.61694 5.08691 1.56934 4.94043 1.58691Z"
        fill="#EAAA08"
      />
    </svg>
  );
};

export const QuantityGreenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V5.35871C5.75 5.42243 5.81706 5.46387 5.87405 5.43537L8 4.3724L10.126 5.43537C10.1829 5.46387 10.25 5.42243 10.25 5.35871V2.375M5.75 2.375H10.25M5.97457 9.0949L7.43415 10.5429L10.6812 7.30597"
        stroke="#17B26A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DeclinedQuantityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V5.35501C5.75 5.41864 5.81689 5.46009 5.87386 5.43177L8 4.375L10.1261 5.43177C10.1831 5.46009 10.25 5.41864 10.25 5.35501V2.375M5.75 2.375H10.25M6.33708 7.67872L8 9.34586M8 9.34586L9.66623 7.67872M8 9.34586L6.33708 11.013M8 9.34586L9.66623 11.013"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PlannedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895508 0 0 0.895508 0 2V8C0 9.10449 0.895508 10 2 10H8C9.10449 10 10 9.10449 10 8V2C10 0.895508 9.10449 0 8 0H2ZM2 2C1.72363 2 1.5 2.22388 1.5 2.5C1.5 2.77612 1.72363 3 2 3H8C8.10254 3 8.19824 2.96899 8.27734 2.91577C8.32715 2.88281 8.37012 2.84131 8.40527 2.79321C8.44336 2.74121 8.4707 2.6814 8.48633 2.6167C8.49512 2.57935 8.5 2.54028 8.5 2.5C8.5 2.22388 8.27637 2 8 2H2ZM1.5 5.00366C1.49805 4.72754 1.7207 4.50195 1.99609 4.5L5.99609 4.47144C6.27246 4.46948 6.49805 4.69165 6.5 4.96777C6.50098 5.15674 6.39746 5.32202 6.24414 5.40796C6.17285 5.44775 6.09082 5.4707 6.00391 5.47144L2.00391 5.5C1.72754 5.50195 1.50195 5.27979 1.5 5.00366ZM2 7C1.72363 7 1.5 7.22388 1.5 7.5C1.5 7.77612 1.72363 8 2 8H4C4.27637 8 4.5 7.77612 4.5 7.5C4.5 7.22388 4.27637 7 4 7H2Z"
        fill="#98A2B3"
      />
    </svg>
  );
};

export const EventsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V8C0 9.10457 0.89543 10 2 10H8C9.10457 10 10 9.10457 10 8V2C10 0.895431 9.10457 0 8 0H2ZM3.06934 2.63053C3.06934 2.30226 3.06934 2.13813 3.13778 2.04766C3.19741 1.96883 3.28854 1.92006 3.3872 1.91417C3.50045 1.90741 3.63701 1.99845 3.91015 2.18054L7.46453 4.55013C7.69022 4.70059 7.80306 4.77581 7.84238 4.87063C7.87676 4.95353 7.87676 5.0467 7.84238 5.1296C7.80306 5.22442 7.69022 5.29965 7.46453 5.4501L7.46453 5.4501L3.91015 7.81969L3.91014 7.8197C3.63701 8.00178 3.50045 8.09283 3.3872 8.08606C3.28854 8.08017 3.19741 8.0314 3.13778 7.95258C3.06934 7.8621 3.06934 7.69797 3.06934 7.36971V2.63053ZM4.0918 3.96008C4.0918 3.816 4.0918 3.74396 4.12184 3.70425C4.14801 3.66966 4.18801 3.64825 4.23131 3.64566C4.28102 3.64269 4.34096 3.68265 4.46084 3.76258L6.02091 4.80262L6.02091 4.80262C6.11997 4.86866 6.16949 4.90168 6.18675 4.9433C6.20184 4.97968 6.20184 5.02057 6.18675 5.05696C6.16949 5.09858 6.11997 5.1316 6.02091 5.19763L6.02091 5.19763L4.46084 6.23768C4.34096 6.3176 4.28102 6.35756 4.23131 6.35459C4.18801 6.35201 4.14801 6.3306 4.12184 6.296C4.0918 6.25629 4.0918 6.18425 4.0918 6.04017V3.96008Z"
        fill="#17B26A"
      />
    </svg>
  );
};

export const OrderQuantityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.6667 8.74984V5.6665C16.6667 4.26637 16.6667 3.56631 16.3942 3.03153C16.1545 2.56112 15.7721 2.17867 15.3017 1.93899C14.7669 1.6665 14.0668 1.6665 12.6667 1.6665H7.33337C5.93324 1.6665 5.23318 1.6665 4.6984 1.93899C4.22799 2.17867 3.84554 2.56112 3.60586 3.03153C3.33337 3.56631 3.33337 4.26637 3.33337 5.6665V14.3332C3.33337 15.7333 3.33337 16.4334 3.60586 16.9681C3.84554 17.4386 4.22799 17.821 4.6984 18.0607C5.23318 18.3332 5.93324 18.3332 7.33337 18.3332M10 7.49984H6.66671M11.6667 4.99984H6.66671M8.33337 9.99984H6.66671M9.83711 12.6319L12.8556 10.9452C12.9978 10.8658 13.0688 10.8261 13.1441 10.8105C13.2108 10.7967 13.2795 10.7967 13.3462 10.8103C13.4215 10.8258 13.4927 10.8654 13.6349 10.9446L16.6667 12.6319M9.83711 12.6319L13.2451 14.5309M9.83711 12.6319V15.9547C9.83711 16.1253 9.83711 16.2106 9.86218 16.2868C9.88436 16.3541 9.92062 16.416 9.96856 16.4683C10.0227 16.5274 10.0972 16.5691 10.246 16.6525L13.2451 18.3332M16.6667 12.6319L13.2451 14.5309M16.6667 12.6319V15.9537C16.6667 16.1247 16.6667 16.2102 16.6416 16.2864C16.6193 16.3539 16.5829 16.4159 16.5349 16.4682C16.4805 16.5273 16.4059 16.569 16.2566 16.6523L13.2451 18.3332M13.2451 14.5309V18.3332"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PersonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.3334 2.8898C14.5681 3.50343 15.4167 4.77762 15.4167 6.25C15.4167 7.72238 14.5681 8.99657 13.3334 9.6102M15 13.972C16.2596 14.5419 17.3938 15.4708 18.3334 16.6667M1.66669 16.6667C3.28876 14.6021 5.491 13.3333 7.91669 13.3333C10.3424 13.3333 12.5446 14.6021 14.1667 16.6667M11.6667 6.25C11.6667 8.32107 9.98776 10 7.91669 10C5.84562 10 4.16669 8.32107 4.16669 6.25C4.16669 4.17893 5.84562 2.5 7.91669 2.5C9.98776 2.5 11.6667 4.17893 11.6667 6.25Z"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Headericon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M18 9.99984L8 9.99984M18 4.99984L8 4.99984M18 14.9998L8 14.9998M4.66667 9.99984C4.66667 10.4601 4.29357 10.8332 3.83333 10.8332C3.3731 10.8332 3 10.4601 3 9.99984C3 9.5396 3.3731 9.1665 3.83333 9.1665C4.29357 9.1665 4.66667 9.5396 4.66667 9.99984ZM4.66667 4.99984C4.66667 5.46007 4.29357 5.83317 3.83333 5.83317C3.3731 5.83317 3 5.46007 3 4.99984C3 4.5396 3.3731 4.1665 3.83333 4.1665C4.29357 4.1665 4.66667 4.5396 4.66667 4.99984ZM4.66667 14.9998C4.66667 15.4601 4.29357 15.8332 3.83333 15.8332C3.3731 15.8332 3 15.4601 3 14.9998C3 14.5396 3.3731 14.1665 3.83333 14.1665C4.29357 14.1665 4.66667 14.5396 4.66667 14.9998Z"
        stroke="#667085"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
