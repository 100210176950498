import React, { memo, useEffect, useState } from "react";
import SearchInput from "../inputs/SearchInput";
import { Button } from "../../components";
import { ChevronDown } from "../../assets/icons/PersonIcons";
import { CloseIcon } from "../../assets/icons/departmentIcon";
import { SearchIcon } from "../../assets/icons/stepsIcons";

const ExpandedButton = memo(({ children, title, setSearchTerm, searchTerm, t }) => {
  const [expanded, setExpanded] = useState(true);
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    if (!searchOpen) {
      setSearchTerm("");
    }
  }, [searchOpen]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex w-full items-center justify-between px-1 border-b border-secondary-200 pb-2">
          <p className="text-lg font-semibold text-secondary-700">{title}</p>
          <div className="w-9 ml-auto">
            {title !== t("chat:pastPoll") && (
              <>
                {searchOpen === false ? (
                  <Button colorType={"tertiary-gray"} size={"sm"} onClick={() => setSearchOpen(true)} iconLeft={<SearchIcon />} />
                ) : (
                  <Button colorType={"tertiary-gray"} size={"sm"} onClick={() => setSearchOpen(false)} iconLeft={<CloseIcon />} />
                )}
              </>
            )}
          </div>
          <div onClick={() => setExpanded(!expanded)} className={`w-9 ${expanded && "rotate-180"}`}>
            <Button colorType={"tertiary-gray"} size={"sm"} iconLeft={<ChevronDown />} />
          </div>
        </div>

        {expanded && (
          <>
            {title !== t("chat:pastPoll") && searchOpen && (
              <div className="w-full h-10 mt-3 ">
                <SearchInput focus={searchOpen} setSearchVal={setSearchTerm} value={searchTerm} placeholder={t("chat:search")} />
              </div>
            )}
            {children}
          </>
        )}
      </div>
    </>
  );
});

export default ExpandedButton;
