import React, { memo, useCallback, useEffect, useState } from "react";
import PersonnelCard from "./PersonnelCard";
import { observer } from "mobx-react-lite";
import Button from "../../../components/buttons/Button";
import { ChevronDown } from "../../../assets/icons/PersonIcons";
import { useStore } from "../../../hooks/useStores";
import { Loader, SearchIcon } from "../../../assets/icons/stepsIcons";
import { CloseIcon } from "../../../assets/icons/departmentIcon";
import SearchInput from "../../../components/inputs/SearchInput";
import { useTranslation } from "react-i18next";
import diacritics from "diacritics";

const MessageList = observer(({ isLoading, refetch, isFetching }) => {
  const { t } = useTranslation();
  const { chat } = useStore();
  const findByCurrentGroup = useCallback((conversationId) => {
    const finder = chat.messageList?.find(
      (item) => item.conversationId === conversationId
    );
    return finder;
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = chat?.messageList?.filter((item) =>
    diacritics
      .remove(
        `${item?.participants[0]?.name}${item?.participants[0]?.lastName}${
          item?.name
        }${String(item?.lastMessage?.message)}`
      )
      .toLowerCase()
      .includes(diacritics.remove(searchTerm.toLowerCase()))
  );

  return (
    <div className="flex w-[375px] min-w-[375px] flex-col pt-4 overflow-y-auto scrollbar-hide border-l border-secondary-200 px-4 sm:w-full sm:min-w-full sm:border-l-0 xs:min-w-full xs:border-l-0 ">
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="#0E9384" currentFill="currentFill" />
        </div>
      ) : (
        <>
          <ExpandedButton
            title={t("person:directChats")}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            isFetching={isFetching}
            t={t}
          >
            <div className="flex flex-col mt-2">
              {filteredData?.map((item, index) => {
                if (item?.type !== "group-task") {
                  return (
                    <PersonnelCard
                      refetch={refetch}
                      onClick={() => {
                        chat.setCurrentGroupChat(
                          findByCurrentGroup(item.conversationId)
                        );
                      }}
                      chat={chat}
                      conversationId={item.conversationId}
                      key={index}
                      image={item?.participants[0]?.avatar}
                      lastName={item.participants.lastName}
                      name={
                        item?.participants[0]?.name +
                        " " +
                        item?.participants[0]?.lastName
                      }
                      lastMessage={item?.lastMessage?.message}
                      type={item?.type}
                      item={item}
                    />
                  );
                }
              })}
            </div>
          </ExpandedButton>

          <ExpandedButton
            title={t("person:groups")}
            isFetching={isFetching}
            t={t}
            setSearchTerm={setSearchTerm}
          >
            <div className="flex flex-col mt-2">
              {filteredData?.map((item, index) => {
                if (item?.type === "group-task") {
                  return (
                    <PersonnelCard
                      refetch={refetch}
                      onClick={() => {
                        chat.setCurrentGroupChat(
                          findByCurrentGroup(item.conversationId)
                        );
                      }}
                      chat={chat}
                      key={index}
                      item={item}
                      image={item?.task?.image}
                      lastName={item.participants.lastName}
                      name={item?.name}
                      conversationId={item.conversationId}
                      lastMessage={item?.lastMessage?.message}
                      type={item?.type}
                    />
                  );
                }
              })}
            </div>
          </ExpandedButton>
        </>
      )}
    </div>
  );
});

export default memo(MessageList);

const ExpandedButton = memo(
  ({ children, title, setSearchTerm, searchTerm, isFetching, t }) => {
    const [expanded, setExpanded] = useState(true);
    const [searchOpen, setSearchOpen] = useState(false);

    useEffect(() => {
      if (!searchOpen) {
        setSearchTerm("");
      }
    }, [searchOpen]);

    return (
      <div className="flex flex-col">
        <div className="flex w-full items-center justify-between mt-4 px-1 border-b border-secondary-200 pb-2 xs:mt-0">
          <p className="text-lg font-semibold text-secondary-700 flex items-center gap-2.5">
            {title}
            {isFetching && (
              <svg
                aria-hidden="true"
                className="w-4 h-4 animate-spin fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill={"#182230"}
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill={"#fff"}
                />
              </svg>
            )}
          </p>
          <div className="w-9 ml-auto">
            {title !== t("person:groups") && (
              <>
                {searchOpen === false ? (
                  <Button
                    colorType={"tertiary-gray"}
                    size={"sm"}
                    onClick={
                      searchOpen === true
                        ? () => setSearchOpen(false)
                        : () => setSearchOpen(true)
                    }
                    iconLeft={<SearchIcon />}
                  />
                ) : (
                  <Button
                    colorType={"tertiary-gray"}
                    size={"sm"}
                    onClick={
                      searchOpen === true
                        ? () => setSearchOpen(false)
                        : () => setSearchOpen(true)
                    }
                    iconLeft={<CloseIcon />}
                  />
                )}
              </>
            )}
          </div>
          <div
            onClick={() => setExpanded(!expanded)}
            className={`w-9 ${expanded && "rotate-180"}`}
          >
            <Button
              colorType={"tertiary-gray"}
              size={"sm"}
              iconLeft={<ChevronDown />}
            />
          </div>
        </div>

        {expanded && (
          <>
            {title !== t("person:groups") && searchOpen && (
              <div className="w-full h-10 my-2">
                <SearchInput
                  focus={searchOpen}
                  setSearchVal={setSearchTerm}
                  value={searchTerm}
                  placeholder={t("chat:search")}
                />
              </div>
            )}
            {children}
          </>
        )}
      </div>
    );
  }
);
