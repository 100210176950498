import { instance } from "../libs/client";

class ServiceSupplyService {
  async getServiceSupplyList(id, type , searchVal) {
    const { data } = await instance.get(`service-supply/planned/list/${id}/${type}/?${searchVal ? `&search=${searchVal}` : ""}`);
    return data;
  }
  async createPlannedServiceJunction(data) {
    return await instance.post("service-supply/create/planned", data);
  }
  async createServiceSupplyJunctionNew(data) {
    return await instance.post("service-supply/create/supplier-junction", data);
  }
  async createServiceSupplyReceivedNew(data) {
    return await instance.post(`service-supply/create`, data);
  }
  async serviceSupplierQualityApplied(id, data) {
    return await instance.post(`service-supply/finish/${id}`, data);
  }
  async getServicePurchaseRequestDetails(type, id) {
    const { data } = await instance.get(`service-supply/request/details/${type}/${id}`);
    return data;
  }
  async getQualityServiceSupplyList() {
    const { data } = await instance.get(`service-supply/junctions/count`);
    return data;
  }
  async getQualityServiceSupplySelectOperation(id) {
    const { data } = await instance.get(`service-supply/mrp/quality-junctions/${id}`);
    return data;
  }
  async getServiceSupplyHeaderList(companyData) {
    const { data } = await instance.get(`service-supply/list/external-operations/?${companyData ? `&company=${companyData}` : ""}`);
    return data;
  }
  async deleteServiceSupplyJunction(type, id) {
    const { data } = await instance.delete(`service-supply/${type}/${id}`);
    return data;
  }

  // new
  async getServiceSupplierInfo(id) {
    const { data } = await instance.get(`service-supply/supplier/info/${id}`);
    return data;
  }


  async getServiceSupplierPastDataPagination(id , type , pagination) {
    const { data } = await instance.get(`service-supply/supplier/pagination/past/${id}/${type}?page=${pagination.pageNo}&search=${pagination.search}&take=${pagination.take}`);
    // const { data } = await instance.get(`purchase/supplier/past/3554c05f-9135-4932-8aa7-3e74b95816b8`);
    return data;
  }



  

}
const serviceSupplyService = new ServiceSupplyService();
export { serviceSupplyService };
