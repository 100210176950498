import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Input } from "../../../components";

const QuantityModal = ({ workCount, t, setIsOpenWorkCountModal, onClose }) => {
  const schema = yup.object({
    tolerance: yup
      .number()
      .required()
      .typeError(`${t("validation:number")}`),
    qty: yup
      .number()
      .required()
      .typeError(`${t("validation:number")}`),
    minCount: yup
      .number()
      .required()
      .typeError(`${t("validation:number")}`),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tolerance: "",
      qty: "",
      minCount: "",
    },
  });
  const onSubmit = async (data) => {};
  useEffect(() => {
    if (workCount) {
      setValue("tolerance", workCount.workTolerance);
      setValue("qty", workCount.workQty);
      setValue("minCount", workCount.minCount);
    } else {
    }
  }, []);

  const colors = [
    "#7F56D9",
    "#F04438",
    "#DC6803",
    "#0E9384",
    "#12B76A",
    "#EAAA08",
    "#4E5BA6",
    "#008999",
  ];

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const slide = (shift) => {
    contentWrapper.current.scrollLeft += shift;
  };

  const contentWrapper = React.useRef(null);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);

  const handleScroll = () => {
    const container = contentWrapper.current;
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd =
      container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };

  useEffect(() => {
    const container = contentWrapper?.current;
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="flex flex-col p-5 w-[736px] h-[620px]">
      <div
        ref={contentWrapper}
        className="max-w-[878px] w-full flex flex-col relative gap-y-6 overflow-x-auto overflow-y-hidden scrollbar-hide"
      >
        <div
          className={`w-11 h-[442px] ${
            isRightArrowHidden ? "hidden duration-1000" : ""
          } z-50 flex top-[%55] blue right-5 backdrop-blur-md fixed bg-secondary-500/10 items-center justify-center focus:bg-secondary-500 group`}
          onClick={() => {
            sideScroll(contentWrapper.current, 25, 100, 10);
          }}
        ></div>

        <div
          className={`w-11 h-[442px] ${
            isLeftArrowHidden ? "hidden duration-1000" : ""
          } z-50 flex top-[%55] left-4 fixed bg-secondary-500/10 backdrop-blur-md items-center justify-center focus:bg-secondary-500 group`}
          onClick={() => {
            slide(-9999);
          }}
        ></div>
        <div className="flex flex-row gap-x-4">
          {workCount?.map((item, index) => {
            return (
              <div
                key={index}
                style={{ backgroundColor: `${colors[index % colors.length]}` }}
                className="max-w-[140px] min-w-[140px] max-h-[44px] gap-3 flex items-center justify-center px-2 py-0.5 rounded-lg"
              >
                <div
                  className={`h-6 w-6 bg-white flex items-center justify-center font-semibold text-xs rounded-full p-2 text-[${colors[index]}] } `}
                >
                  {item?.workQty ? item?.workQty : 0}
                </div>
                <div className="flex flex-col items-start">
                  <div className="flex  flex-row items-center gap-x-2">
                    <span className="text-[#FFFFFF] font-normal text-xs">
                      {/* <Quantity /> */}
                    </span>
                    <span className="text-[#FFFFFF] text-sm font-semibold">
                      {item?.count ? item?.count : 0}
                    </span>
                  </div>
                  <div className="flex flex-row items-center gap-x-2">
                    <span className="text-[#FFFFFF] font-normal text-xs">
                      {/* <ClockIcon width={16} height={16} /> */}
                    </span>
                    <span className="text-[#FFFFFF] text-sm font-semibold">
                      {item?.maxRepeatValue ? item?.maxRepeatValue : 0} ±{" "}
                      {item?.workTolerance ? item?.workTolerance : 0}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-full flex gap-x-4">
          {workCount?.map((e, key) => {
            return (
              <div className="max-w-[140px] min-w-[140px] flex flex-col gap-y-2">
                <div className="flex gap-5 justify-end text-xs font-semibold text-[#475467] pr-2">
                  <p>{t("consoleModal:count")}</p>
                  <p>{t("consoleModal:time")}</p>
                </div>
                <div className="flex flex-col gap-1 overflow-y-auto overflow-x-hidden max-h-[348px] min-h-[348px] scrollbar-hide">
                  {e?.usedData.map((d, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          backgroundColor: `${
                            e.maxRepeatValue == d?.value
                              ? `${colors[key % colors.length]}70`
                              : `${colors[key % colors.length]}40`
                          }`,
                        }}
                        className="w-full  min-w-[140px] max-w-[140px] text-[#344054] text-sm font-medium flex items-center px-2 rounded-lg py-1 gap-x-4"
                      >
                        <input
                          id={`radio-${key}-${i}`}
                          type="radio"
                          checked={e.maxRepeatValue === d?.value}
                          className={`ring-0 focus:ring-0 flex items-center text-[${
                            colors[key % colors.length]
                          }] justify-center`}
                        />

                        <div className="w-full flex items-center justify-center text-center">
                          {d?.count}
                        </div>
                        <div className="w-full flex items-center justify-center text-center">
                          {d?.value}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="h-[134px]  ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col"
        >
          <div className="w-full flex  items-center justify-center gap-x-4 py-2">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("addProcess:workTolerance")}
                  width={"100%"}
                  inputBg={"#000"}
                  onBlur={onBlur}
                  onChange={onChange}
                  disabled={true}
                  value={10}
                  validate={errors.tolerance ? "error" : ""}
                  errorMessage={
                    errors.tolerance ? errors.tolerance.message : ""
                  }
                  type={"number"}
                />
              )}
              name="tolerance"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("consoleModal:workQty")}
                  width={"100%"}
                  inputBg={"#000"}
                  onBlur={onBlur}
                  onChange={onChange}
                  disabled={true}
                  value={1}
                  validate={errors.qty ? "error" : ""}
                  errorMessage={errors.qty ? errors.qty.message : ""}
                  type={"number"}
                />
              )}
              name="qty"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("consoleModal:minCount")}
                  width={"100%"}
                  inputBg={"#000"}
                  onBlur={onBlur}
                  onChange={onChange}
                  disabled={true}
                  value={1}
                  validate={errors.minCount ? "error" : ""}
                  errorMessage={errors.minCount ? errors.minCount.message : ""}
                  type={"number"}
                />
              )}
              name="minCount"
            />
          </div>

          <div className="w-full flex items-center  mb-2 gap-x-3 mt-2">
            <button
              onClick={onClose}
              type="button"
              className="max-h-[44px] w-full border border-gray-300 dark:bg-[#292929] dark:border-[#525252] dark:text-[#FAFAFA] py-2.5 px-3 font-medium text-secondary-700 rounded-lg flex items-center justify-center"
            >
              {t("buttons:cancel")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuantityModal;
