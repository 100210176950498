import React, { useEffect, useRef, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { QuantityIcon } from "../../../assets/icons/stepsIcons";
import { PencilIcon, QuantityAcceptedIcon, QuantityDeclineIcon } from "../../../assets/icons/productIcons";
import moment from "moment";
import { orderService } from "../../../services/order.service";
import { Toast } from "../../../utils/toastify/toast";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { MenuIcon } from "../../../assets/icons/PersonIcons";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import OrderEditModal from "../modals/OrderEditModal";
import { CustomModal } from "../../../components";
import { DetailIcon, QrCode, SmallDeleteIcon, BigCalenderIcon } from "../../../assets/icons/commonIcons";

const NewOrderCard = ({ t, order, qrFunction, showMenuOptions = true, refetch }) => {
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState("");
  const menuOptions = [
    {
      label: t("product:orderDetail"),
      value: "details",
      icon: <DetailIcon color={"#667085"} />,
    },
    {
      label: t("product:orderEdit"),
      value: "edit",
      icon: <PencilIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <SmallDeleteIcon /> },
    { label: t("product:getQrCodes"), value: "qr", icon: <QrCode /> },
  ];

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const onDeleted = async () => {
    await orderService.orderDelete(deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId("");
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      data-tooltip-id={`${order?.id}`}
      data-tooltip-content={`
      ${t("product:productName")} : ${order?.product?.name} 
       -
      ${t("product:orderId")} : ${order?.orderNo}
      `}
      ref={menuRef}
      className="flex w-[324px] min-w-[324px] max-w-[324px] h-full border shadow-sm rounded-lg pl-3 pr-2 py-2 gap-x-3 group relative"
    >
      <div className="flex flex-col gap-y-[2px]">
        <div className="flex gap-x-3">
          <img
            className="flex border border-[#0000001A] max-w-[64px] min-w-[64px] max-h-[48px] min-h-[48px] rounded"
            src={generateFileUrl(order?.product?.image)}
          />
          <div className="flex flex-col h-[48px] w-[132px]">
            <span className="text-[#101828] text-md font-semibold truncate">{order?.product?.name}</span>
            <span className="text-[#475467] text-sm font-normal truncate">
              {t("product:orId")}: {order?.orderNo}
            </span>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="w-full border-b flex gap-x-[2px] items-center py-[2px] pr-[6px]">
            <div className="flex gap-x-1 items-center">
              <QuantityIcon />
              <span className="text-[#475467] text-xs font-medium">{order?.piece}</span>
            </div>
            <span className="text-[#667085] text-xxs">{t("product:orderQuantitys")}</span>
          </div>
          <div className="flex w-full">
            <div className="flex w-full gap-x-[2px] items-center pr-[5px] py-[2px]">
              <div className="flex gap-x-1">
                <BigCalenderIcon />
                <span className="text-xs font-medium text-[#475467]">{moment(order?.deliveryDate).format("DD.MM.YY")}</span>
              </div>
              <span className="text-[10px] leading-[18px] font-normal text-[#667085]">{t("product:deliveryDate")}</span>
            </div>
          </div>
        </div>
      </div>
      {/* kalite yeni ui */}
      {/* <div className="flex flex-col border border-secondary-200 bg-white  w-[84px] min-w-[84px] min-h-[76px] max-h-[76px] h-[76px]  rounded">
          <div className="flex bg-[#F9FAFB] rounded-t px-[6px] py-[2px]  border-b border-b-secondary-200 gap-x-1 items-center justify-center">
            <QualityIcon />
            <p className="text-secondary-700 font-semibold text-[10px]">
              {t("reports:quality")}
            </p>
          </div>
          <div className="flex flex-col  items-center justify-center">
            <div className="flex gap-x-[2px] p-1 items-center">
              <QuantityAcceptedIcon />
              <span className="text-xs font-medium text-[#079455]">
                {order?.finishedPiece}
              </span>
              <span className="text-[10px] leading-[18px] text-[#667085]">
                {t("product:pcs")}
              </span>
            </div>
            <div className="flex gap-x-[2px] p-1 items-center">
              <QuantityDeclineIcon />
              <span className="text-xs font-medium text-[#D92D20]">
                {order?.rejectedPiece}
              </span>
              <span className="text-[10px] leading-[18px] text-[#667085]">
                {t("product:pcs")}
              </span>
            </div>
          </div>
        </div> */}
      <div className="flex px-[6px] py-[2px] w-[84px] max-w-[84px] min-w-[84px] min-h-[76px] max-h-[76px] h-[76px] border border-[#D0D5DD] shadow-xs rounded flex-col gap-y-2">
        <div className="flex gap-x-1 items-center justify-center">
          <span className="w-2 h-2 min-w-[8px] max-w-[8px] min-h-[8px] max-h-[8px] bg-[#17B26A] rounded-[2px]"></span>
          <p className="text-[#475467] font-normal text-xs">{t("reports:quality")}</p>
        </div>
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-[2px] items-center">
            <QuantityAcceptedIcon />
            <span className="text-xs text-[#079455]">{order?.finishedPiece || 0}</span>
            <span className="text-[10px] leading-[18px] text-[#667085]">{t("product:pcs")}</span>
          </div>
          <div className="flex gap-x-[2px] items-center">
            <QuantityDeclineIcon />
            <span className="text-xs text-[#D92D20]">{order?.rejectedPiece || 0}</span>
            <span className="text-[10px] leading-[18px] text-[#667085]">{t("product:pcs")}</span>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`min-w-[20px] absolute bottom-1 right-3 h-[20px] cursor-pointer hidden  ${showMenuOptions ? "group-hover:flex" : "hidden"}`}
      >
        <MenuIcon />
      </div>
      {isOpen && (
        <div className="absolute w-[190px] p-1 bg-white right-3 top-[104px] border border-secondary-200 rounded-lg shadow-sm flex flex-col z-[100]">
          {menuOptions?.map((item, index) => {
            return (
              <button
                onClick={() => {
                  if (item.value === "delete") {
                    setIsAlert(true);
                    setDeleteId(order?.id);
                  } else if (item.value === "details") {
                    navigate(`/app/product/mrp/work-in-process/edit/${order?.id}`);
                  } else if (item.value === "qr") {
                    return qrFunction(order);
                  } else if (item.value === "edit") {
                    setEditModal(true);
                  }
                  // setIsOpen(false);
                }}
                key={index}
                type="button"
                className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
              >
                {item?.icon}
                <p className={`font-medium text-sm ${index == 2 ? "text-[#D92D20]" : "text-secondary-700"}  ml-1`}>{index === "taskList" ? "" : item.label}</p>
              </button>
            );
          })}
        </div>
      )}
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <Tooltip className="z-50" position={"top"} id={`${order?.id}`} />
      <CustomModal
        isOpen={editModal}
        setIsOpen={setEditModal}
        onClose={() => {
          setEditModal(false);
        }}
        modalTitle={t("product:createNewOrder")}
        children={<OrderEditModal selectedProduct={order?.product} setIsOpen={setEditModal} orderData={order} refresh={refetch} editData={order} t={t} />}
      />
    </div>
  );
};

export default NewOrderCard;
