import React, { memo, useEffect, useRef, useState } from "react";
import { ChevronDown } from "../../../assets/icons/PersonIcons";
import { useTranslation } from "react-i18next";

const TaskDropDownButton = ({ value, setValue, taskPage, page }) => {
  const { t } = useTranslation();
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    { label: t("tasks:allTasks"), value: "all" },
    { label: t("tasks:myTasks"), value: "my" },
    {
      label: t("chat:otherUser"),
      value: "other",
    },
  ];

  const optionsTasklist = [
    { label: t("tasks:allTasks"), value: "all" },
    { label: t("tasks:myTasks"), value: "my" },
  ];

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="relative flex flex-col" ref={menuRef}>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`pl-3 pr-2 py-2 max-h-[36px] flex items-center border border-gray-300 rounded-lg relative gap-2 hover:bg-gray-50 active:bg-white active:ring-2 ring-gray-200 ${
          isOpen && "bg-gray-50"
        }`}
        type="button"
      >
        <p className="text-sm font-semibold text-secondary-700">
          {options.find((a) => a.value === value).label}
        </p>
        <ChevronDown />
      </button>

      {isOpen && (
        <div className="absolute w-[190px] p-1 bg-white right-0 top-10 border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1 z-50">
          {page === "taskList" ? (
            <>
              {optionsTasklist?.map((item, index) => {
                return (
                  <button
                    onClick={() => {
                      setValue(item.value);
                      setIsOpen(false);
                    }}
                    key={index}
                    type="button"
                    className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                  >
                    <p className="font-medium text-sm text-secondary-700">
                      {index === "taskList" ? "" : item.label}
                    </p>
                  </button>
                );
              })}
            </>
          ) : (
            <>
              {options.map((item, index) => {
                return (
                  <button
                    onClick={() => {
                      setValue(item.value);
                      setIsOpen(false);
                      if (!taskPage) {
                        if (item.value === "my") {
                          // navigate("");
                        }
                      }
                    }}
                    key={index}
                    type="button"
                    className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                  >
                    <p className="font-medium text-sm text-secondary-700">
                      {index === "taskList" ? "" : item.label}
                    </p>
                  </button>
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(TaskDropDownButton);
