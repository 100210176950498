import React, { useState } from "react";
import CustomImage from "../../../../components/CustomImage";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import { NewDotsIcon } from "../../../../components/new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

const NewMaterialSupplyPlanViewPlannedCard = ({ item, setDraggedProduct }) => {

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];

  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };

  const totalPlannedUnits = item?.plannedJunctions.reduce(
    (acc, junction) => acc + (junction?.status === "active" ? (junction?.plannedUnit || 0) : 0),
    0
  );

  return (
    <div
      draggable
      onDragStart={() => setDraggedProduct(item)}
      className="flex bg-white flex-row mr-4 w-[324px] min-w-[324px] h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab "
    >
      <div className="flex flex-col w-[93%]">
        <div className="flex flex-row items-center gap-x-3">
          {item?.type === "semi_internal_product" ? (
            <>
              {item?.productInternalOperation ? (
                <>
                  {item?.productInternalOperation?.product?.image ? (
                    <div
                      data-tooltip-id={`${item?.id}`}
                      data-tooltip-place="right"
                      data-tooltip-content={`${t("product:productName")}: ${item?.productInternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productInternalOperation?.product?.stockCode
                        }`}
                    >
                      <CustomImage
                        borderRadius={2}
                        src={item?.productInternalOperation?.product?.image}
                        style={{
                          width: 64,
                          height: 48,
                          minWidth: 64,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      data-tooltip-id={`${item?.id}`}
                      data-tooltip-place="right"
                      data-tooltip-content={`${t("product:productName")}: ${item?.productInternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productInternalOperation?.product?.stockCode
                        }`}
                      className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                    >
                      <NoEquIcon />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {item?.productExternalOperation?.product?.image ? (
                    <div
                      data-tooltip-id={`${item?.id}`}
                      data-tooltip-place="right"
                      data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productExternalOperation?.product?.stockCode
                        }`}
                    >
                      <CustomImage
                        borderRadius={2}
                        src={item?.productExternalOperation?.product?.image}
                        style={{
                          width: 64,
                          height: 48,
                          minWidth: 64,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      data-tooltip-id={`${item?.id}`}
                      data-tooltip-place="right"
                      data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productExternalOperation?.product?.stockCode
                        }`}
                      className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                    >
                      <NoEquIcon />
                    </div>
                  )}
                </>
              )}
            </>
          ) : item?.rawMaterial ? (
            <>
              {item?.rawMaterial?.image ? (
                <div
                  data-tooltip-id={`${item?.id}`}
                  data-tooltip-place="right"
                  data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stockCode")}: ${item?.rawMaterial?.stockCode}`}
                >
                  <CustomImage
                    borderRadius={2}
                    src={item?.rawMaterial?.image}
                    style={{
                      width: 64,
                      height: 48,
                      minWidth: 64,
                      minHeight: 48,
                      borderRadius: 4,
                      borderWidth: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              ) : (
                <div
                  data-tooltip-id={`${item?.id}`}
                  data-tooltip-place="right"
                  data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stockCode")}: ${item?.rawMaterial?.stockCode}`}
                  className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                >
                  <NoEquIcon />
                </div>
              )}
            </>
          ) : item?.product?.image ? (
            <div
              data-tooltip-id={`${item?.id}`}
              data-tooltip-place="right"
              data-tooltip-content={`${t("product:productName")}: ${item?.product?.name} - ${t("product:stockCode")}: ${item?.product?.stockCode}`}
            >
              <CustomImage
                borderRadius={2}
                src={item?.product?.image}
                style={{
                  width: 64,
                  height: 48,
                  minWidth: 64,
                  minHeight: 48,
                  borderRadius: 4,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : item?.productExternalOperation ? (
            <>
              {item?.productExternalOperation?.product?.image ? (
                <div
                  data-tooltip-id={`${item?.id}`}
                  data-tooltip-place="right"
                  data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productExternalOperation?.product?.stockCode
                    }`}
                >
                  <CustomImage
                    borderRadius={2}
                    src={item?.productExternalOperation?.product?.image}
                    style={{
                      width: 64,
                      height: 48,
                      minWidth: 64,
                      minHeight: 48,
                      borderRadius: 4,
                      borderWidth: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              ) : (
                <div
                  data-tooltip-id={`${item?.id}`}
                  data-tooltip-place="right"
                  data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productExternalOperation?.product?.stockCode
                    }`}
                  className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                >
                  <NoEquIcon />
                </div>
              )}
            </>
          ) : (
            <div
              data-tooltip-id={`${item?.id}`}
              data-tooltip-place="right"
              data-tooltip-content={`${t("product:productName")}: ${item?.product?.name} - ${t("product:stockCode")}: ${item?.product?.stockCode}`}
              className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
            >
              <NoEquIcon />
            </div>
          )}
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-center gap-x-1  w-full">
              <p className="font-semibold w-[175px] text-secondary-900 text-base truncate">
                {/* {item?.type === "semi_internal_product" && item?.workOrder === null ? (
                <>
                  {item?.productInternalOperation ? (
                    <p className="font-semibold w-[175px] text-secondary-900 text-base truncate">
                      {item?.productInternalOperation?.product?.name || "Product Name"}
                    </p>
                  ) : (
                    <p className="font-semibold text-secondary-900 text-base truncate">
                      {item?.productExternalOperation?.process?.name || "Product Namdrgrgrwe"}
                    </p>
                  )}
                </>
              ) : (
                <p className="font-semibold text-secondary-900 text-base w-[175px] truncate">
                  {item?.product ? item?.product?.name || "Product Name" : item?.rawMaterial?.name || "Product Name"}
                </p>
              )} */}
                {item?.productInternalOperation
                  ? item?.productInternalOperation?.product?.name || "Product Name"
                  : item?.productExternalOperation
                    ? item?.productExternalOperation?.product?.name || "Product Name"
                    : item?.product
                      ? item?.product?.name
                      : item?.rawMaterial
                        ? item?.rawMaterial?.name
                        : "Product Name"}
              </p>
            </div>
            <div className={`h-6 max-w-[175px] flex px-1 py-[3px] ml-[2px] items-center justify-center bg-[#FFFAEB]`}>
              <p className={`text-sm font-normal max-w-[175px] truncate text-[#B54708]`}>
                {/* {t("product:stock")} :{" "} */}
                {/* {item?.type === "semi_internal_product" && item?.workOrder === null
                  ? item?.productInternalOperation
                    ? item?.productInternalOperation?.product?.stockCode || "--"
                    : item?.productExternalOperation?.product?.stockCode || "--"
                  : item?.product
                  ? item?.product?.stockCode || "--"
                  : item?.rawMaterial?.stockCode || "--"} */}
                {/* {item?.productInternalOperation
                  ? item?.productInternalOperation?.product?.stockCode || "--"
                  : item?.productExternalOperation
                  ? item?.productExternalOperation?.product?.stockCode || "--"
                  : item?.product
                  ? item?.product?.stockCode
                  : item?.rawMaterial
                  ? item?.rawMaterial?.stockCode
                  : "--"} */}
                {item?.productInternalOperation
                  ? `${item?.productInternalOperation?.product?.stockCode || "--"} (${item?.productInternalOperation?.step}.Op)`
                  : item?.productExternalOperation
                    ? `${item?.productExternalOperation?.product?.stockCode || "--"} (${item?.productExternalOperation?.step}.Op)`
                    : item?.product
                      ? item?.product?.stockCode
                      : item?.rawMaterial
                        ? `${t("product:stock")} : ${item?.rawMaterial?.stockCode}`
                        : "--"}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-1 w-[100%]">
          <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">{t("product:inStock")}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
              <p className="text-xs font-medium text-secondary-700">
                {
                  item?.rawMaterial
                    ? (Number(item?.rawMaterial?.quantity) >= 100000
                      ? (Number(item?.rawMaterial?.quantity) / 1000)?.toFixed(2) + "B"
                      : (Number(item?.rawMaterial?.quantity) % 1 === 0
                        ? Number(item?.rawMaterial?.quantity)
                        : Number(item?.rawMaterial?.quantity)?.toFixed(2))) || "0"
                    : item?.product
                      ? (Number(item?.product?.qualityQuantity) >= 100000
                        ? (Number(item?.product?.qualityQuantity) / 1000)?.toFixed(2) + "B"
                        : (Number(item?.product?.qualityQuantity) % 1 === 0
                          ? Number(item?.product?.qualityQuantity)
                          : Number(item?.product?.qualityQuantity)?.toFixed(2))) || "0"
                      : item?.productInternalOperation
                        ? (Number(item?.productInternalOperation?.product?.qualityQuantity) >= 100000
                          ? (Number(item?.productInternalOperation?.product?.qualityQuantity) / 1000)?.toFixed(2) + "B"
                          : (Number(item?.productInternalOperation?.product?.qualityQuantity) % 1 === 0
                            ? Number(item?.productInternalOperation?.product?.qualityQuantity)
                            : Number(item?.productInternalOperation?.product?.qualityQuantity)?.toFixed(2))) || "0"
                        : item?.productExternalOperation?.product?.qualityQuantity
                          ? (Number(item?.productExternalOperation?.product?.qualityQuantity) >= 100000
                            ? (Number(item?.productExternalOperation?.product?.qualityQuantity) / 1000)?.toFixed(2) + "B"
                            : (Number(item?.productExternalOperation?.product?.qualityQuantity) % 1 === 0
                              ? Number(item?.productExternalOperation?.product?.qualityQuantity)
                              : Number(item?.productExternalOperation?.product?.qualityQuantity)?.toFixed(2))) || "0"
                          : "0"
                }
              </p>
            </div>
          </div>
          <div className="flex flex-col h-[42px] min-w-[28%] w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">{t("product:activeRequest")}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
              <p className="text-xs font-medium text-secondary-700">
                {
                  item?.orderedUnit >= 100000
                    ? (item?.orderedUnit / 1000)?.toFixed(2) + "B"
                    : (item?.orderedUnit % 1 === 0
                      ? item?.orderedUnit
                      : (item?.orderedUnit)?.toFixed(2)) || "0"
                }
              </p>
            </div>
          </div>
          <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
            <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
              <span className="text-xxs truncate text-[#475467] font-medium ">{"MS"}</span>
            </div>
            <div className="flex flex-col w-full items-start">
              <div
                data-tooltip-id={`${item?.id}`}
                data-tooltip-place="left"
                data-tooltip-content={`${t("product:remainings")}: ${(item?.orderedUnit - totalPlannedUnits)?.toFixed(2)}`}
                className="flex flex-row items-center w-full border-b px-1">
                <p className="text-xxs font-normal text-secondary-600">{t("product:remainings")}</p>
                <p className="text-xs font-medium ml-auto text-secondary-600">
                  {
                    (item?.orderedUnit - totalPlannedUnits) >= 10000 || (item?.orderedUnit - totalPlannedUnits) <= -10000
                      ? ((item?.orderedUnit - totalPlannedUnits) / 1000)?.toFixed(2) + "B"
                      : (item?.orderedUnit - totalPlannedUnits) % 1 === 0
                        ? item?.orderedUnit - totalPlannedUnits
                        : (item?.orderedUnit - totalPlannedUnits)?.toFixed(2) || "0"
                  }
                </p>
              </div>
              <div
                data-tooltip-id={`${item?.id}`}
                data-tooltip-place="left"
                data-tooltip-content={`${t("product:plannedNew")}: ${(totalPlannedUnits)?.toFixed(2)}`}
                className="flex flex-row items-center w-full px-1">
                <p className="text-xxs font-normal text-secondary-600">{t("product:plannedNew")}</p>
                <p className="text-xs font-medium ml-auto text-secondary-600">
                  {
                    totalPlannedUnits >= 10000 || totalPlannedUnits <= -10000
                      ? (totalPlannedUnits / 1000)?.toFixed(2) + "B"
                      : totalPlannedUnits % 1 === 0
                        ? totalPlannedUnits
                        : (totalPlannedUnits)?.toFixed(2) || "0"
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute right-0 bg-[#F2F4F7] bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
      >
        {item?.productInternalOperation ? (
          <p className="text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90">
            {t("product:semiProduct")}
            {/* ({item?.productInternalOperation?.step}) */}
          </p>
        ) : item?.productExternalOperation ? (
          <p className="text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90">
            {t("product:semiProduct")}
            {/* ({item?.productExternalOperation?.step}) */}
          </p>
        ) : item?.product ? (
          <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
            {t("product:productss")} ({getUnitLabel(item?.rawMaterial?.unitType)})
          </p>
        ) : item?.rawMaterial ? (
          <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
            {t("product:rMaterial")} ({getUnitLabel(item?.rawMaterial?.unitType)})
          </p>
        ) : (
          "--"
        )}
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
      >
        <NewDotsIcon />
      </div>

      <Tooltip className="z-[120]" id={`${item?.id}`} />
    </div>
  );
};

export default NewMaterialSupplyPlanViewPlannedCard;
