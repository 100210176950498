import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StepNoData from "../../steps/step-navigation/components/StepNoData";
import StepsMainSide from "../../components/steps/StepsMainSide";
import { CustomModal } from "../../components";
import { useStore } from "../../hooks/useStores";
import UserCard from "./components/UserCard";
import AddUserModal from "./modals/AddUserModal";
import { personalService } from "../../services/personal.service";
import { Toast } from "../../utils/toastify/toast";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { Loader } from "../../assets/icons/stepsIcons";
import diacritics from "diacritics";

const Users = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { auth } = useStore();
  const [searchVal, setSearchVal] = useState(null);
  const [tempPersonal, setTempPersonal] = useState([]);
  const [personal, setPersonals] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [deletePerson, setDeletePerson] = useState("");
  const [selectedPersonal, setSelectedPersonal] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDeleted = async (id) => {
    await personalService.removePersonal(id).then(() => {
      Toast("success", t("addPersonal:deletedPersonalMessage"));
    });
  };

  const getPersonals = async () => {
    setLoading(true);
    let { data } = await personalService.activePersonals();

    data = data?.map((e) => {
      return {
        ...e,
        metadata: {
          ...e.metadata,
        },
      };
    });
    setTempPersonal(data);
    setPersonals(data);
    setLoading(false);
  };

  useEffect(() => {
    if (searchVal) {
      const filterData = personal.filter((e) => {
        let name = `${diacritics.remove(e.name)} ${diacritics.remove(
          e.lastName
        )}`;
        return name
          .replace(/^\./g, "")
          .toLowerCase()
          .includes(searchVal.toLowerCase());
      });

      setTempPersonal(filterData);
    } else {
      setTempPersonal(personal);
    }
  }, [searchVal, setSearchVal, personal]);

  useEffect(() => {
    getPersonals();
  }, []);

  return (
    <>
      {personal ? (
        <StepsMainSide
          t={t}
          title={t("routes:personals")}
          setSearchVal={setSearchVal}
          length={
            tempPersonal?.length +
            "/" +
            auth?.user?.company?.limits.user +
            " " +
            t("addPersonal:personCredit")
          }
          isLimitReached={
            tempPersonal?.length >= auth?.user?.company?.limits.user
          }
          limitlabel={t("addPersonal:buyStaff")}
          addlabel={t("addPersonal:modalTitle")}
          setIsOpen={setIsOpen}
          placeholder={t("addPersonal:searchInput")}
          children={
            <>
              {loading ? (
                <div className="flex items-center justify-center mt-[120px] w-full h-full">
                  <Loader
                    currentColor="currentColor"
                    currentFill="currentFill"
                  />
                </div>
              ) : (
                <>
                  {tempPersonal?.map((person, index) => {
                    return (
                      <UserCard
                        department={person?.metadata?.department?.name}
                        key={index}
                        name={person?.name + " " + person?.lastName}
                        labels={person?.labels}
                        id={person?.id}
                        avatar={person?.avatar}
                        responsibleUser={person?.responsibleUser}
                        editOnClick={() => {
                          setSelectedPersonal(person);
                          setIsOpen(true);
                        }}
                        deleteOnclick={() => {
                          setDeletePerson(person?.id);
                          setIsAlert(true);
                        }}
                      />
                    );
                  })}
                </>
              )}
            </>
          }
        />
      ) : (
        <StepNoData
          header={t("routes:addPersonal")}
          text={t("addPersonal:addPersonnel")}
          onClick={() => setIsOpen(true)}
        />
      )}

      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        // width={736}
        modalTitle={t("addPersonal:modalTitle")}
        onClose={() => {
          setSelectedPersonal(null);
          setIsOpen(false);
        }}
        children={
          <AddUserModal
            setTempPersonal={setTempPersonal}
            tempPersonal={tempPersonal}
            handleData={getPersonals}
            setIsOpen={setIsOpen}
            selectedPersonal={selectedPersonal}
            setSelectedPersonal={setSelectedPersonal}
          />
        }
      />

      <AlertModal
        title={t("addPersonal:deletePersonal")}
        subTitle={t("addPersonal:deletePersonalWarning")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={async () => {
          await onDeleted(deletePerson);
          await getPersonals();
          setIsAlert(false);
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default Users;
