import React, { useCallback, useEffect, useMemo } from "react";
import LossSelectCard from "../../Card/LossSelectCard";
import LossTimeCard from "../../Card/LossTimeCard";
import moment from "moment";
import MultipleBarChart from "../../Tabs/MultipleBarChart";
import LossesPieChart from "../../Tabs/LossesPieChart";

const OperationTypes = ({
  Tabs,
  data,
  page,
  month,
  tabskey,
  secondTab,
  setSecondTab,
  setSelectedOperation,
  getLossesData,
  selectedOperation,
  setSelectedLegend,
}) => {
  const chartData = useMemo(() => {
    return data?.find((item) => item?.month == moment(month).format("M"))?.operations.find((op) => op?.typeId == selectedOperation);
  }, [data, month, selectedOperation]);

  const anuallyProcessedData = useMemo(() => {
    return data?.map((month) => {
      return month?.operations?.find((op) => {
        return op?.typeId == selectedOperation || op?.typeId == "00000000-0000-0000-0000-000000000000";
      });
    });
  }, [data, selectedOperation]);

  const monthlyData = useMemo(() => {
    return data?.find((item) => {
      return item?.month == moment(month).format("M");
    });
  }, [data, month]);

  const handleSecondTabClick = useCallback(
    (tabKey) => {
      setSecondTab(tabKey);
    },
    [setSecondTab]
  );

  useEffect(() => {
    setSelectedLegend(chartData?.equipments.sort((a, b) => b[secondTab] - a[secondTab])[0].processId);
  }, [chartData]);

  return (
    <>
      <div className="w-full flex flex-col gap-y-4 md:h-full md:max-h-full sm:h-full sm:max-h-full xs:h-full xs:max-h-full">
        <div className="flex items-center gap-6 gap-y-3 xs:gap-y-6 h-full xs:px-2 xs:flex-col sm:flex-wrap md:gap-4 z-10 min-w-full">
          <LossSelectCard
            setValue={setSelectedOperation}
            name={
              monthlyData?.operations?.find((op) => {
                return op.typeId == selectedOperation;
              })?.name || "Tanımsız"
            }
            piece={
              monthlyData?.operations?.find((op) => {
                return op.typeId == selectedOperation;
              })?.[secondTab]
            }
            values={monthlyData?.operations?.map((item, index) => {
              return {
                value: `${item.typeId}`,
                label: `${item.name}`,
              };
            })}
          />

          {Tabs?.map((tab, index) => {
            return (
              <div className={`w-full px-1 lg:px-1 md:px-0 sm:px-0 xs:px-0 ${index === 0 ? "sm:w-[47%]" : "" ? "xs:w-[100%]" : "sm:w-[47%]"}`} key={index}>
                <LossTimeCard
                  page="loss"
                  index={index}
                  key={index}
                  name={tab?.name}
                  icon={tab?.icon}
                  value={
                    anuallyProcessedData?.find((item) => {
                      return item?.month == moment(month).format("M");
                    })?.[tab?.key] || "--"
                  }
                  onClick={() => handleSecondTabClick(tab?.key)}
                  selected={tab?.key}
                  selectedCategory={secondTab}
                />
              </div>
            );
          })}
        </div>
        <div className="flex border-b pb-5 flex-row md:flex-col sm:flex-col xs:flex-col items-center gap-2 w-full h-full">
          {/*bar chart*/}
          <div className="w-1/2 overflow-hidden md:w-full md:h-[500px] md:max-h-[500px] sm:h-[500px] xs:h-[500px] sm:w-[100%] md:mt-4 xs:w-[100%]">
            <MultipleBarChart
              charts={"loss"}
              handleData={getLossesData}
              categories={data
                ?.sort((a, b) => a.month - b.month)
                ?.map((item) => {
                  return `${item.month}`;
                })}
              series={tabskey?.map((tab) => {
                return {
                  name: tab?.name,
                  type: "bar",
                  barGap: 0,
                  emphasis: {
                    focus: "series",
                  },
                  data: anuallyProcessedData
                    ?.sort((a, b) => a?.month - b?.month)
                    ?.map((item) => {
                      return item && item[tab?.key];
                    }),
                };
              })}
            />
          </div>

          {/*pie chart*/}
          <div className="flex items-center  gap-2 w-1/2 md:w-full sm:w-full xs:w-full h-full">
            <LossesPieChart
              data={chartData?.equipments?.map((operation) => {
                return {
                  value: operation[secondTab],
                  id: operation.processId,
                  name: operation.processName,
                };
              })}
              setSelectedLegend={setSelectedLegend}
              page={page}
            />
          </div>
        </div>
      </div>
      
    </>
  );
};

export default OperationTypes;
