import React, { memo, useCallback, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../Header";
import { UpuLoss } from "../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { TableArrowLeft } from "../../../assets/icons/lossIcon";
import SearchInput from "../../../components/inputs/SearchInput";
import MobileLossHeader from "../Twin/components/MobileLossHeader";

const Losses = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState(null);
  const [month, setMonth] = useState(new Date());

  const handlePreviousMonth = useCallback(() => {
    setMonth(moment(month).subtract(1, "day").startOf("month"));
  }, [month]);

  const handleCalender = () => {
    return moment(month).format("MMMM YYYY") || "DD MM YYYY";
  };

  const handleNextMonth = useCallback(() => {
    setMonth(moment(month).add(1, "month").startOf("month"));
  }, [month]);

  const getMonthName = (monthValue) => {
    switch (monthValue) {
      case "01":
        return `${t("legends:january")}`;
      case "02":
        return `${t("legends:february")}`;
      case "03":
        return `${t("legends:march")}`;
      case "04":
        return `${t("legends:april")}`;
      case "05":
        return `${t("legends:may")}`;
      case "06":
        return `${t("legends:june")}`;
      case "07":
        return `${t("legends:july")}`;
      case "08":
        return `${t("legends:august")}`;
      case "09":
        return `${t("legends:september")}`;
      case "10":
        return `${t("legends:october")}`;
      case "11":
        return `${t("legends:november")}`;
      case "12":
        return `${t("legends:december")}`;
      default:
        return "";
    }
  };

  return (
    <>
      {pathname.includes("loss-table") ? (
        <>
          <div className="flex flex-row items-center w-full gap-4 h-14 border-b border-secondary-200">
            <div onClick={() => navigate("/app/machine/loss")} className="rotate-180 cursor-pointer">
              <TableArrowLeft />
            </div>
            <p className="text-secondary-900 font-semibold text-displayXs">{t("loss:typeOfLoss")}</p>
            <div className="ml-auto w-[25%]">
              <SearchInput placeholder={t("chat:search")} setSearchVal={setSearchType} />
            </div>
          </div>
        </>
      ) : (
        <>
          <Header
            t={t}
            icon={<UpuLoss />}
            page="loss"
            handlePreviousMonth={handlePreviousMonth}
            handleCalender={handleCalender}
            handleNextMonth={handleNextMonth}
            month={month}
            setMonth={setMonth}
            getMonthName={getMonthName}
          />
          <MobileLossHeader
            t={t}
            icon={<UpuLoss />}
            page="loss"
            handlePreviousMonth={handlePreviousMonth}
            handleCalender={handleCalender}
            handleNextMonth={handleNextMonth}
            month={month}
            setMonth={setMonth}
            getMonthName={getMonthName}
          />
        </>
      )}

      <div className="w-full  flex flex-col overflow-y-auto h-full">
        <Outlet context={[month, setMonth, searchType, setSearchType, handlePreviousMonth, handleCalender, handleNextMonth]} />
      </div>
    </>
  );
};

export default memo(Losses);
