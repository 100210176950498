import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import CustomImage from "../../../../components/CustomImage";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import { AddPlusIcon, ClockEnd, ClockStart, EndClockIcon, QuantityIcon, StartClockIcon } from "../../../../components/new-cards/card-icons";
import NoAvatar from "../../../../components/avatar/NoAvatar";
import { PieceIcon } from "../../../../assets/icons/productIcons";

const AddManuelJunctionFormEventCard = ({
  data,
  selectedProduct,
  workType,
  selectedUser,
  selectedOperation,
  equipmentData,
  startDate,
  endDate,
  workPiece,
  addUserOnClick,
  selectedDates,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex w-full flex-row justify-center items-center group relative min-h-[128px] max-h-[128px]">
        <div className="flex flex-col items-start h-full w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 mr-auto">
          <div
            className={`w-full border-t-4 rounded-t-[8px] ${
              workType == "work" ? "border-t-success-500 " : workType === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
            }`}
          ></div>
          <div className="flex flex-row items-center w-full border-b">
            {selectedProduct?.image ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-content={`
        ${selectedProduct?.name} 
        `}
              >
                <CustomImage
                  src={selectedProduct?.image}
                  style={{
                    width: 84,
                    height: 63,
                    minWidth: 84,
                    minHeight: 63,
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                    borderRadius: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[84px] min-w-[84px] h-[63px] border-r border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            <div className="flex items-center px-2 gap-x-[3px]">
              <QuantityIcon />
              <p className="text-[#475467] font-normal text-md">{workPiece || "--"}</p>
            </div>
          </div>
          <div className="flex flex-col items-start w-full pl-[18px] py-[6px] pr-[30px]">
            <p className="font-normal text-[#667085] text-md">{selectedOperation?.orderData?.orderNo || "--"}</p>
            <p className="text-md font-semibold text-[#344054] truncate  w-[99%]">{selectedProduct?.name || "--"}</p>
          </div>
        </div>
        <div className="cursor-pointer flex absolute  w-[75px] h-[75px] min-w-[75px] min-h-[75px] z-[100]">
          {selectedUser ? (
            <>
              {selectedUser?.image ? (
                <div data-tooltip-id={`${data?.id}`} data-tooltip-place={"left"} data-tooltip-content={`${selectedUser?.label} `} className="cursor-pointer">
                  <CustomImage
                    src={selectedUser?.image}
                    style={{
                      width: 75,
                      height: 75,
                      borderRadius: 100,
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                      borderWidth: 1,
                      borderStyle: "solid",
                      boxShadow: "0 0 0 2px #00000008",
                    }}
                  />
                </div>
              ) : (
                <div data-tooltip-id={`${data?.id}`} data-tooltip-place={"left"} data-tooltip-content={`${selectedUser?.label} `} className="cursor-pointer">
                  <NoAvatar size={75} fontSize={22} name={selectedUser?.label} lastName={data?.user?.lastName} theme={"person"} />
                </div>
              )}
            </>
          ) : (
            <div
              onClick={addUserOnClick}
              className="flex min-w-[75px] max-w-[75px] w-[75px] min-h-[75px] max-h-[75px] h-[75px] rounded-full bg-white border border-[#D6BBFB] border-dashed items-center justify-center"
            >
              <AddPlusIcon />
            </div>
          )}
        </div>
        <div className="flex flex-col items-start h-full w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 ml-auto">
          <div
            className={`w-full border-t-4 rounded-t-[8px] ${
              workType == "work" ? "border-t-success-500 " : workType === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
            }`}
          ></div>
          <div className="flex flex-row items-center w-full border-b justify-end">
            <div className="flex flex-col items-start px-2">
              <div className="flex flex-row items-center gap-x-1 ml-auto">
                <p className="font-normal text-[#475467] text-md">{moment(selectedDates?.start)?.format("HH:mm") || "--"}</p>
                <ClockStart />
              </div>
              <div className="flex flex-row items-center gap-x-1 ml-auto">
                <p className="text-md font-normal text-[#475467]">{moment(selectedDates?.end)?.format("HH:mm") || "--"}</p>
                <ClockEnd />
              </div>
            </div>
            {equipmentData?.equipmentImage ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-content={`
                ${equipmentData?.equipmentName} 
                `}
              >
                <CustomImage
                  borderRadius={2}
                  src={equipmentData?.equipmentImage}
                  style={{
                    width: 84,
                    height: 63,
                    minWidth: 84,
                    minHeight: 63,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRadius: 0,
                    borderLeftWidth: 1,
                    borderRightWidth: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[84px] min-w-[84px] h-[63px] border-l border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
          </div>
          <div className="flex flex-col items-end w-full pr-3 py-1 pl-[30px] min-h-[60px] max-h-[60px]">
            <p className="font-normal text-[#667085] text-md">
              {`(${selectedOperation?.step || "--"}.Op) ` +
                (selectedOperation?.internalOperation?.process?.name === undefined ? "--" : selectedOperation?.internalOperation?.process?.name)}
            </p>
            <p className="text-md font-semibold text-end text-[#344054] truncate ml-auto w-[99%]">{equipmentData?.equipmentName || "--"}</p>
          </div>
        </div>
        <Tooltip className="z-[120]" id={`${data?.id}`} />
      </div>
    </>
  );
};

export default AddManuelJunctionFormEventCard;
