import React, { useState } from "react";
import Badgets from "../../../../components/buttons/Badgets";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../../components";
import SendErrorMessageModal from "../../components/SendErrorMessageModal";
import moment from "moment";
import EquipmentDetailLossCard from "../../../../components/new-cards/loss-card/EquipmentDetailLossCard";
import { JunctionLossIcon, LossIconDropDown } from "../../../../components/new-cards/card-icons";
import { DotsVerticalIcon } from "../../../../assets/icons/commonIcons";

const EquipmentLosses = ({ lossesData, date, refetch }) => {
  const { t } = useTranslation();
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [selectedLoss, setSelectedLoss] = useState();
  const [selectedLossData, setselectedLossData] = useState();
  const [showDropDown, setShowDropDown] = useState(null);

  const handleDotsClick = (e, index) => {
    e.stopPropagation();
    setShowDropDown(showDropDown === index ? null : index);
  };

  const handleDropdownClick = (e, item) => {
    e.stopPropagation();
    setIsWarningModal(true);
    setSelectedLoss(item?.id);
    setselectedLossData(item);
    setShowDropDown(null);
  };

  return (
    <div className="w-full ml-4 flex items-start pl-0 pr-4 py-4">
      <div className="flex flex-col gap-1 w-[136px] min-w-[136px]  max-w-[136px]">
        <p className={`text-lg font-semibold text-[#B42318]`}>{t("routes:losses")}</p>
        <div className="max-w-[90px] flex h-[22px]">
          <Badgets label={`${lossesData?.length || "0"} ${t("product:pieces")}`} size={"sm"} colorType={"fill-error"} />
        </div>
      </div>

      <div className="w-full flex items-center overflow-x-auto ml-5 pb-4 gap-x-4">
        {lossesData?.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col items-start pt-2 border-[#E4E7EC] border px-3 cursor-pointer pb-3 min-w-[325px] group rounded-lg relative ${
              moment(date)?.format("YYYY-MM-DD") === moment(new Date())?.format("YYYY-MM-DD") ? "w-[325px]" : ""
            }`}
          >
            <JunctionLossIcon />
            <div className="min-w-[300px] w-[300px mt-2">
              <EquipmentDetailLossCard data={item} date={date}/>
            </div>
            <div
              onClick={(e) => handleDotsClick(e, index)}
              className="group-hover:flex hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] right-1 top-1 z-[50] items-center justify-center cursor-pointer hover:bg-secondary-50 hover:border-secondary-300 rounded"
            >
              <DotsVerticalIcon />
            </div>
            {showDropDown === index && (
              <div className="flex-col absolute w-[240px] min-w-[240px] max-w-[240px] h-fit bg-white border right-2 top-[30px] z-[99] rounded-lg px-[6px] py-1">
                <button
                  onClick={(e) => handleDropdownClick(e, item)}
                  type="button"
                  className="w-full h-[38px] flex items-center px-2.5 rounded-md gap-x-2 hover:bg-secondary-100"
                >
                  <LossIconDropDown />
                  <p className="font-medium text-sm text-secondary-700">{t("product:defineLoss")}</p>
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <CustomModal
        width={400}
        isOpen={isWarningModal}
        setIsOpen={setIsWarningModal}
        children={
          <SendErrorMessageModal setIsOpen={setIsWarningModal} t={t} selectedLossId={selectedLoss} refetch={refetch} selectedLossData={selectedLossData} />
        }
      />
    </div>
  );
};

export default EquipmentLosses;
