import React from "react";
import CustomImage from "../../../components/CustomImage";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { NewDotsIcon } from "../../../components/new-cards/card-icons";
import { Tooltip } from "react-tooltip";

const NewOrderOperationCard = ({ item }) => {
  const { t } = useTranslation();
  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];
  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };

  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
    }
  };

  return (
    <div className="flex bg-white flex-row w-full h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab ">
      <div className="flex flex-col w-[93%]">
        <div className="flex flex-row items-center gap-x-3">
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-center gap-x-2 w-full">
              <div className="flex bg-[#F2F4F7] px-1 border rounded border-[#E4E7EC]">
                <p className="text-[#344054] font-semibold text-xs">
                  {item?.type === "internal" ? operationTypes(item?.processType || "conversion_operation") : "HİZ"}
                </p>
              </div>
              <p className="font-semibold w-[175px] text-secondary-900 text-base truncate">{item?.name || item?.process?.name || "Product Name"}</p>
            </div>
            <div className={`h-6 max-w-[250px] flex px-1 py-[3px] ml-[2px] items-center justify-center bg-[#FFFAEB]`}>
              <p className={`text-sm font-normal bg-[#FFFAEB] w-full truncate text-[#B54708]`}>{item?.step}.op tamamlanmış yarı mamül</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-1 w-[100%]">
          <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">{t("product:inStock")}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
              <p className="text-xs font-medium text-secondary-700">{item?.freeStock || "--"}</p>
            </div>
          </div>
          <div className="flex flex-col h-[42px] min-w-[28%] w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">{t("product:activeRequest")}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
              <p className="text-xs font-medium text-secondary-700">{"0" || "--"}</p>
            </div>
          </div>
          <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
            <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
              <span className="text-xxs truncate text-[#475467] font-medium ">{"MS"}</span>
            </div>
            <div className="flex flex-col w-full items-start">
              <div className="flex flex-row items-center w-full border-b px-1">
                <p className="text-xxs font-normal text-secondary-600">{t("product:remainings")}</p>
                <p className="text-xs font-medium ml-auto text-secondary-600">{"0" || "0"}</p>
              </div>
              <div className="flex flex-row items-center w-full px-1">
                <p className="text-xxs font-normal text-secondary-600">{t("product:newPlanned")}</p>
                <p className="text-xs font-medium ml-auto text-secondary-600">{"0" || "0"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute right-0 bg-[#F2F4F7] bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
      >
        <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>{t("product:semiProduct")}</p>
      </div>
      <div className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer">
        <NewDotsIcon />
      </div>

      <Tooltip className="z-[120]" id={`${item?.id}`} />
    </div>
  );
};

export default NewOrderOperationCard;
