import React, { useEffect, useState } from "react";
import { junctionService } from "../../../services/junction.service";
import { Toast } from "../../../utils/toastify/toast";
import { instance } from "../../../libs/client";
import SearchInput from "../../../components/inputs/SearchInput";
import { authStore } from "../../../stores/auth.store";
import generateFileUrl from "../../../utils/generateFileUrl";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { DangerIcon } from "../../../assets/icons/machineIcons";
import diacritics from "diacritics";
import { Loader } from "../../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";
import { Input } from "../../../components";

const NewPlannedJunctionModal = ({ processId, closeModal, operationData, getJunctions, isFlex, headerId }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedEquipment, setselectedEquipment] = useState(null);
  const [plannedQuantity, setPlannedQuantity] = useState(0);
  const handleProcessToEquipments = async () => {
    const { data } = await instance.get(`nodes/list/for/${processId}`);
    setData(data);
  };

  const startNewPlannedJunction = async () => {
    setLoading(true);
    const data = {
      headerId: headerId ? headerId : operationData?.product?.id,
      companyId: authStore.user.company.id,
      process: {
        id: processId,
      },
      stationId: selectedEquipment?.id,
      type: "internal",
      orderId: operationData?.orderId,
      targetedWorkCount: plannedQuantity,
      productType: isFlex ? "flex" : "standart",
    };
    await junctionService.equipmentBasedJunctionPlanning(data).then((res) => {
      if (res.data?.code === 0) {
        Toast("success", res.data?.message);
        setIsAlert(false);
        closeModal();
        getJunctions();
      } else {
        Toast("error", res.data.message);
      }
    });
    setLoading(false);
  };

  const filteredData = data?.filter((d) =>
    diacritics.remove(`${d?.metadata?.brand} ${d?.metadata?.model}`).toLowerCase().includes(diacritics.remove(searchValue).toLowerCase())
  );

  useEffect(() => {
    handleProcessToEquipments();
  }, []);

  return (
    <>
      <div className="w-[350px] flex flex-col max-h-[70vh] gap-4 pt-4">
        <SearchInput setSearchVal={setSearchValue} theme={"product"} />
        <Input
          min={String(plannedQuantity)}
          theme={"product"}
          label={"Planlanan Adet"}
          type={"number"}
          placeholder={"Adet Giriniz"}
          value={plannedQuantity}
          onChange={(event) => {
            setPlannedQuantity(event.target.value);
          }}
        />
        <div className="flex flex-col max-h-[330px] min-h-[330px] overflow-y-auto scrollbar-hide gap-y-2 border-b">
          {filteredData?.map((d, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setselectedEquipment(d);
                  setIsAlert(true);
                }}
                className="border px-3 py-[10px] rounded-lg shadow-xs border-[#D0D5DD] flex gap-x-3 hover:bg-[#F9FAFB] cursor-pointer"
              >
                <img
                  className="min-w-[60px] max-w-[60px] min-h-[45px] max-h-[45px] rounded border border-[#0000001A]"
                  src={generateFileUrl(d?.metadata?.image)}
                />
                <div className="flex flex-col w-[70%]">
                  <span className="text-sm font-semibold text-[#344054] truncate">
                    {d?.metadata?.brand} {d?.metadata?.model}
                  </span>
                  <span className="truncate text-sm font-normal text-[#667085]">{/* {currentOperation?.internalOperation?.process?.name} */}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <AlertModal
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        title={selectedEquipment?.metadata?.brand + " " + selectedEquipment?.metadata?.model}
        subTitle={t("product:planWithEquipment")}
        label={
          loading ? (
            <div className="flex items-center justify-center bg-[#B42318] rounded-lg w-full h-[20px] max-h-[20px]">
              <Loader currentColor="#B42318" currentFill="currentFill" />
            </div>
          ) : (
            t("buttons:create")
          )
        }
        applyButton={startNewPlannedJunction}
        icon={<DangerIcon />}
      />
    </>
  );
};

export default NewPlannedJunctionModal;
