import React, { useState } from "react";
import { CheckIcon, DeleteIcon, NewDotsIcon, PlusIcon, TargetIcon } from "../../../../components/new-cards/card-icons";
import Badgets from "../../../../components/buttons/Badgets";
import CustomImage from "../../../../components/CustomImage";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { purchaseService } from "../../../../services/purchase.service";
import { Toast } from "../../../../utils/toastify/toast";
import { Tooltip } from "react-tooltip";
import AlertModal from "../../../../components/alert-Modal/Alert-Modal";
import moment from "moment";

const NewMaterialSupplyPlannedCard = ({ item, refetch, setIsOpenSelectUserModal, setSelectedJunction }) => {
  const { t } = useTranslation();
  const menuOptions = [{ label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> }];
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const onDeleted = async () => {
    await purchaseService.deletePlannedPurchase(item?.id).then((res) => {
      if (res.data.code == 0) {
        Toast("success", "Junction deleted");
        refetch();
        setIsAlert(false);
        setDeleteId(item?.id);
      } else {
        Toast("error", "Junction not deleted");
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  return (
    <>
      <div className="flex w-full flex-col border border-t-4 items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group">
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
            {item?.purchaseRequest?.rawMaterial?.image ||
              item?.purchaseRequest?.productInternalOperation?.product?.image ||
              item?.purchaseRequest?.productExternalOperation?.product?.image ||
              item?.purchaseRequest?.product?.image ? (
              <div
                data-tooltip-id={`${item?.id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${t("product:productName")} : ${item?.purchaseRequest?.rawMaterial?.name} / ${t("product:stockCodeRawMaterial")} : ${item?.purchaseRequest?.rawMaterial?.stockCode
                  } `}
              >
                <CustomImage
                  borderRadius={2}
                  src={
                    item?.purchaseRequest?.rawMaterial
                      ? item?.purchaseRequest?.rawMaterial?.image
                      : item?.purchaseRequest?.productInternalOperation
                        ? item?.purchaseRequest?.productInternalOperation?.product?.image
                        : item?.purchaseRequest?.productExternalOperation
                          ? item?.purchaseRequest?.productExternalOperation?.product?.image
                          : item?.purchaseRequest?.product
                            ? item?.purchaseRequest?.product?.image
                            : ""
                  }
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div data-tooltip-id={`${item?.id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${t("product:productName")} : ${item?.purchaseRequest?.rawMaterial
                  ? item?.purchaseRequest?.rawMaterial?.name
                  : item?.purchaseRequest?.productInternalOperation
                    ? item?.purchaseRequest?.productInternalOperation?.product?.name
                    : item?.purchaseRequest?.productExternalOperation
                      ? item?.purchaseRequest?.productExternalOperation?.product?.name
                      : item?.purchaseRequest?.product
                        ? item?.purchaseRequest?.product?.name
                        : "--"} / ${t("product:stockCodeRawMaterial")} : ${item?.purchaseRequest?.rawMaterial?.stockCode ||
                        item?.purchaseRequest?.product?.stockCode ||
                        item?.purchaseRequest?.productInternalOperation?.product?.stockCode ||
                        item?.purchaseRequest?.productExternalOperation?.product?.stockCode ||
                        "--"
                  } `} className="w-[64px] min-w-[64px] min-h-[48px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}

            <div className="flex flex-col items-start gap-y-[6px]">
              <div className="flex flex-row items-center gap-x-1 w-full">
                <p className="text-sm font-semibold text-secondary-900 truncate w-[210px]">
                  {item?.purchaseRequest?.rawMaterial
                    ? item?.purchaseRequest?.rawMaterial?.name
                    : item?.purchaseRequest?.productInternalOperation
                      ? item?.purchaseRequest?.productInternalOperation?.product?.name
                      : item?.purchaseRequest?.productExternalOperation
                        ? item?.purchaseRequest?.productExternalOperation?.product?.name
                        : item?.purchaseRequest?.product
                          ? item?.purchaseRequest?.product?.name
                          : "--"}
                </p>
              </div>
              <div className="flex w-full gap-x-2">
                <div className={`h-6 max-w-[150px] flex px-1 py-[3px] items-center justify-center bg-[#FFFAEB] rounded`}>
                  <p className={`text-sm font-normal max-w-[150px] truncate text-[#B54708]`}>
                    {t("product:stock")} :{" "}
                    {item?.purchaseRequest?.rawMaterial?.stockCode ||
                      item?.purchaseRequest?.product?.stockCode ||
                      item?.purchaseRequest?.productInternalOperation?.product?.stockCode ||
                      item?.purchaseRequest?.productExternalOperation?.product?.stockCode ||
                      "--"}
                  </p>
                </div>
                {(item?.purchaseRequest?.productInternalOperation || item?.purchaseRequest?.productExternalOperation) && (
                  <div className={`h-6 max-w-[100px] flex py-[3px] items-center justify-center bg-[#F2F4F7] rounded px-1`}>
                    <p className={`text-sm font-normal max-w-[100px] truncate text-[#344054]`}>
                      {item?.purchaseRequest?.productInternalOperation
                        ? item?.purchaseRequest?.productInternalOperation?.step +
                        ((item?.purchaseRequest?.productInternalOperation?.process?.code && "-") +
                          item?.purchaseRequest?.productInternalOperation?.process?.code || "")
                        : item?.purchaseRequest?.productExternalOperation
                          ? item?.purchaseRequest?.productExternalOperation?.step +
                          ((item?.purchaseRequest?.productExternalOperation?.process?.code && "-") +
                            item?.purchaseRequest?.productExternalOperation?.process?.code || "")
                          : "--"}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              setIsOpenSelectUserModal(true);
              setSelectedJunction(item);
            }}
            type="button"
            className="min-w-[48px] h-[48px] rounded-full border-dashed ml-auto border border-gray-300 flex items-center justify-center mt-1"
          >
            <PlusIcon />
          </button>
        </div>
        <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB] border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
          <div className="flex flex-row items-center">
            <QuantityIcon />
            <p className="text-xs font-medium ml-1 mt-[1px] text-secondary-600">{item?.orderedUnit || "0"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[2px] text-secondary-600">{item?.purchaseRequest?.rawMaterial?.unitType || "ad"}</p>
          </div>
          <div className="flex flex-row items-center">
            <NewDateIcon />
            <p className="text-xs font-medium ml-1 mt-[1px] text-secondary-600">{moment(item?.estimatedDeliveryDate).format("DD.MM.YYYY") || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[2px] text-secondary-600">{t("product:deliveryDate")}</p>
          </div>
          {/* <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 px-2 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
            <CheckIcon />
            <p className="text-xxs text text-secondary-600 font-semibold">{t("product:plannedNews")}</p>
          </div> */}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {menuOptions?.map((items, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (items.value === "productDetail") {
                      navigate(`/app/product/mrp/work-in-process/detail/${item?.job?.id}+${item?.header?.step}`);
                    }
                    if (items.value === "delete") {
                      setIsAlert(true);
                    }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${items?.value === "delete" || items.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                    }`}
                >
                  {items?.icon}
                  <p
                    className={`font-medium text-sm  ${items?.value === "delete" || items.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"
                      }`}
                  >
                    {items.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
        {item?.header?.step === undefined ? null : (
          <span className="absolute left-1 top-1">
            <Badgets colorType={"fill-gray"} label={item?.header?.step + ".op"} size={"sm"} />
          </span>
        )}
        <Tooltip className="z-[99999]" id={`${item?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
    </>
  );
};

export default NewMaterialSupplyPlannedCard;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M4.81283 1.16699H2.62533C1.81991 1.16699 1.16699 1.81991 1.16699 2.62533V11.3753C1.16699 12.1807 1.81991 12.8337 2.62533 12.8337H11.3753C12.1807 12.8337 12.8337 12.1807 12.8337 11.3753V2.62533C12.8337 1.81991 12.1807 1.16699 11.3753 1.16699H9.18783M4.81283 1.16699V4.54199C4.81283 4.59861 4.87316 4.63481 4.92312 4.60817L7.00033 3.50033L9.07753 4.60817C9.12749 4.63481 9.18783 4.59861 9.18783 4.54199V1.16699M4.81283 1.16699H9.18783"
        stroke="#475467"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const NewDateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M12.25 5.83366H1.75M9.33333 1.16699V3.50033M4.66667 1.16699V3.50033M4.55 12.8337H9.45C10.4301 12.8337 10.9201 12.8337 11.2945 12.6429C11.6238 12.4751 11.8915 12.2074 12.0593 11.8781C12.25 11.5038 12.25 11.0138 12.25 10.0337V5.13366C12.25 4.15357 12.25 3.66352 12.0593 3.28918C11.8915 2.95989 11.6238 2.69218 11.2945 2.5244C10.9201 2.33366 10.4301 2.33366 9.45 2.33366H4.55C3.56991 2.33366 3.07986 2.33366 2.70552 2.5244C2.37623 2.69218 2.10852 2.95989 1.94074 3.28918C1.75 3.66352 1.75 4.15357 1.75 5.13366V10.0337C1.75 11.0138 1.75 11.5038 1.94074 11.8781C2.10852 12.2074 2.37623 12.4751 2.70552 12.6429C3.07986 12.8337 3.56991 12.8337 4.55 12.8337Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
