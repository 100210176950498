import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { PlusIcon, QuantityIcon } from "../../assets/icons/stepsIcons";
import {
  Button,
  CustomModal,
  PaginationComponent,
  TableOptions,
} from "../../components";
import NewOrder from "./modals/NewOrder";
import NoData from "../../steps/step-navigation/components/NoData";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import OrderQrModal from "./modals/OrderQrModal";
import {
  QuantityAcceptedIcon,
  QuantityDeclineIcon,
} from "../../assets/icons/productIcons";
import SearchInput from "../../components/inputs/SearchInput";
import diacritics from "diacritics";
import SupplyFormModal from "./modals/SupplyFormModal";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { flexProductService } from "../../services/flex-product.service";
import { ChevronDown } from "../../assets/icons/modalIcons";
import FlexibleProductCard from "./components/FlexibleProductCard";
import FlexProductSupplierCard from "./cards/FlexProductSupplierCard";
import NewFlexProductCard from "./cards/NewFlexProductCard";
import OrderSort from "./components/OrderSort";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";

const FlexProductManagement = () => {
  const { auth, settings } = useStore();
  const { height } = useWindowDimensions();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { pageNo } = useParams();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isOpenQrModal, setIsOpenQrModal] = useState(false);
  const [tableHeadItemsLength, setTableHeadItemsLength] = useState(0);
  const [isNewOrderModal, setIsNewOrderModal] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortValue, setSortValue] = useState(null);
  const [ascDesc, setAscDesc] = useState(true);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterOptions, setFilterOptions] = useState({});
  const [supplyForm, setSupplyForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openButtonDropDown, setOpenButtonDropDown] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [debouncedQuery, setDebouncedQuery] = useState(searchVal);
  const [sortOption, setSortOption] = useState(""); // Sıralama için state
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["listOrder"],
    retry: 0,
    enabled: false,
    queryFn: useMemo(
      () => async () => {
        try {
          const res = await flexProductService.getFlexProductListPaginatedNew(
            Number(pageNo) ? Number(pageNo) : 1,
            searchVal,
            sortValue,
            ascDesc ? "DESC" : "ASC",
            pageLimit,
            filterOptions
          );
          setTotalCount(res?.meta?.itemCount);
          setPageCount(res?.meta?.pageCount);
          setPageData(res?.data);
          if (res?.meta?.pageCount < pageNo) {
            navigate(`/app/product/mes/flex/1`);
          }
          return res;
        } catch (error) {
          console.error("Error occured while fetching data:", error);
          throw error;
        }
      },
      [pageNo, searchVal, sortValue, ascDesc, pageLimit, filterOptions]
    ),
  });

  useEffect(() => {
    refetch();
  }, [pageNo, searchVal, sortValue, ascDesc, pageLimit, filterOptions]);
  const dataManipulate = () => {
    if (pageData?.length > 0) {
      const head = pageData?.map((d) => {
        return d?.operations?.length;
      });
      setTableHeadItemsLength(Math?.max(...head));
    }
  };
  const handleSearch = (input) => {
    const normalizedInput = diacritics.remove(input);
    setSearchVal(normalizedInput);
  };
  useEffect(() => {
    if (auth.user?.rolesKey === "OPERATION_RESPONSIBLE") {
      window.location.href = "internal-operation";
    } else {
    }
  }, []);

  const tableHeadItems = useMemo(() => {
    const items = [];
    for (let i = 0; i < Math.max(3, tableHeadItemsLength); i++) {
      items.push({ title: `${i + 1}.${t("product:operation")}`, id: i });
    }
    return items;
  }, [tableHeadItemsLength, t]);

  useEffect(() => {
    if (pageData?.length > 0) {
      dataManipulate();
    }
  }, [pageData]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (
      auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325"
    ) {
    } else if (
      auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592"
    ) {
    } else if (
      auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4"
    ) {
    } else if (
      auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706"
    ) {
    } else if (
      auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f"
    ) {
    } else if (
      auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35"
    ) {
    } else if (
      auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630"
    ) {
    } else if (
      auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689"
    ) {
    } else if (
      auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a"
    ) {
    } else if (
      auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3"
    ) {
    } else if (
      auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513"
    ) {
    } else if (
      auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86"
    ) {
    } else if (
      auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9"
    ) {
    } else if (
      auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706"
    ) {
    } else if (
      auth?.user?.company?.id == "10826fc4-bf01-4164-acf0-72bb213e88d3"
    ) {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);

  const menuOptions = [
    {
      label: t("product:createNewProduct"),
      value: "newProduct",
      icon: <PlusIcon />,
      navigate: "create",
    },
    {
      label: t("product:createNewRawMaterial"),
      value: "newRawMaterial",
      icon: <PlusIcon />,
      navigate: "create/raw-material",
    },
  ];

  const outerDivRef = useRef(null);
  const mappedDivRefs = useRef([]);

  const handleScroll = (source, index = null) => {
    let currentScrollLeft;

    if (source === "outer") {
      currentScrollLeft = outerDivRef.current?.scrollLeft;
    } else if (source === "mapped") {
      currentScrollLeft = mappedDivRefs.current[index]?.scrollLeft;
    }

    if (outerDivRef.current && source !== "outer") {
      outerDivRef.current.scrollLeft = currentScrollLeft;
    }

    mappedDivRefs.current.forEach((div, idx) => {
      if (div && idx !== index) {
        div.scrollLeft = currentScrollLeft;
      }
    });
  };

  const changePage = (newPageNo) => {
    navigate(`/app/product/mes/flex/${newPageNo}`);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchVal);
    }, 200);

    return () => {
      clearTimeout(handler);
    };
  }, [searchVal]);

  useEffect(() => {
    const params = {};

    if (debouncedQuery) {
      params.search = debouncedQuery;
    }

    if (sortOption) {
      params.sort = sortOption;
    }
    setSearchParams(params);
  }, [debouncedQuery, sortOption]);

  useEffect(() => {
    if (ascDesc) {
      if (sortValue === "qualityQuantity") {
        setSortOption("qualityQuantity-desc");
      } else if (sortValue === "scrapQuantity") {
        setSortOption("scrapQuantity-desc");
      } else if (sortValue === "createdDate") {
        setSortOption("createdDate-desc");
      }
    } else {
      if (sortValue === "qualityQuantity") {
        setSortOption("qualityQuantity-asc");
      } else if (sortValue === "scrapQuantity") {
        setSortOption("scrapQuantity-asc");
      } else if (sortValue === "createdDate") {
        setSortOption("createdDate-asc");
      }
    }
    if (sortValue == null) {
      setSortOption();
    }
  }, [ascDesc, sortValue]);

  return (
    <>
      <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative scrollbar-hide">
        {/*header*/}
        <div className="flex w-full min-h-[56px] max-h-[56px] h-[56px] md:h-[48px] md:min-h-[48px] md:max-h-[48px] mb-4 items-center md:flex-col md:items-start justify-between border-b border-[#E4E7EC]">
          <span className="md:flex flex-row items-center gap-x-2 sm:flex xs:flex hidden">
            <Button
              colorType={"tertiary-gray"}
              iconLeft={<HumburgerIcon />}
              size={"md"}
              onClick={() => settings.setIsActive(true)}
            />
            <p className="text-[#101828] font-semibold text-2xl">
              {t("product:flexibleProductManagement")}
            </p>
          </span>
          <p className="text-[#101828] md:hidden sm:hidden xs:hidden font-semibold text-2xl">
            {t("product:flexibleProductManagement")}
          </p>
          <div className="flex gap-x-3 items-center justify-center md:mt-4">
            <div className="w-[220px] max-w-[220px] min-w-[220px]">
              <SearchInput
                theme={"product"}
                placeholder={t("chat:search")}
                setSearchVal={handleSearch}
              />
            </div>
            <OrderSort
              sortValue={sortValue}
              setSortValue={setSortValue}
              ascDesc={ascDesc}
              setAscDesc={setAscDesc}
              options={[
                {
                  label: t("product:qualityQuantitity"),
                  value: "qualityQuantity",
                  icon: <QuantityIcon />,
                },
                {
                  label: t("product:scrapQuantity"),
                  value: "scrapQuantity",
                  icon: <QuantityAcceptedIcon />,
                },
                {
                  label: t("product:createdDate"),
                  value: "createdDate",
                  icon: <QuantityDeclineIcon />,
                },
              ]}
            />

            <TableOptions pageLimit={pageLimit} setPageLimit={setPageLimit} />

            <Button
              colorType={"secondary-gray"}
              size={"lg"}
              label={t("product:rawMaterialList")}
              onClick={() => navigate("raw-material/list")}
            />
            <span className="relative">
              <Button
                onClick={() => {
                  setOpenButtonDropDown(!openButtonDropDown);
                }}
                colorType={"primary-product"}
                iconLeft={<PlusIcon />}
                label={t("buttons:create")}
                iconRight={<ChevronDown />}
              />
              {openButtonDropDown && (
                <div className="flex-col absolute min-w-[240px] max-w-[240px] h-fit bg-white border border-[#E4E7EC] shadow-lg top-[50px] right-0 z-[99] rounded-lg px-[6px] py-1">
                  {menuOptions?.map((item, index) => {
                    return (
                      <button
                        onClick={(e) => {
                          navigate(item?.navigate);
                          setOpenButtonDropDown(false);
                        }}
                        key={index}
                        type="button"
                        className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 hover:bg-secondary-100`}
                      >
                        {item?.icon}
                        <p className={`font-medium text-sm text-[#344054]`}>
                          {item.label}
                        </p>
                      </button>
                    );
                  })}
                </div>
              )}
            </span>
          </div>
        </div>

        {/*body*/}
        {isLoading || isFetching ? (
          <div className="w-full h-full mb-3 bg-gray-200 animate-pulse rounded-lg md:mt-12" />
        ) : (
          <div
            style={{ minHeight: height - 50 }}
            className="w-full border shadow-md md:mt-12 rounded-xl border-[#D0D5DD]"
          >
            <>
              <div className="w-full h-full flex flex-col relative">
                {pageData?.length <= 0 ? (
                  <div className="flex w-full h-full justify-center items-center">
                    <NoData header={t("label:noData")} button={false} />
                  </div>
                ) : (
                  <>
                    <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
                      <div className="flex items-center justify-center max-w-[356px] min-w-[356px] h-full border-r border-[#EAECF0] text-sm font-semibold text-[#344054]">
                        {t("routes:Product")}
                      </div>
                      <div
                        ref={outerDivRef}
                        onScroll={() => handleScroll("outer")}
                        className="flex items-center w-full h-full overflow-x-auto overflow-y-hidden"
                      >
                        <div className="min-w-[346px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200">
                          {t("product:stockOperation")}
                        </div>
                        {tableHeadItems?.map((item, index) => (
                          <div
                            key={index}
                            className="min-w-[346px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200"
                          >
                            {item?.title}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={`flex flex-col w-full flex-1 overflow-y-auto scrollbar-hide`}
                    >
                      {pageData?.map((order, index) => {
                        const rawMaterialData = {
                          name: t("product:purchasing"),
                          id: order?.flexRawMaterial?.id,
                          source: order?.flexRawMaterial?.material?.source,
                          materials: order?.flexRawMaterial?.materials,
                          status: order?.flexRawMaterial?.status,
                        };

                        const filledOperations = [
                          rawMaterialData,
                          ...(order.operations || []),
                          ...Array.from(
                            {
                              length: Math.max(
                                tableHeadItemsLength -
                                  (order.operations || []).length,
                                0
                              ),
                            },
                            () => null
                          ),
                        ];

                        return (
                          <div
                            key={index}
                            style={{ height: "2000px" }}
                            className="flex w-full h-[128px] "
                          >
                            <div className="max-w-[356px] min-w-[356px] h-full px-4 py-2 border-r">
                              <NewFlexProductCard
                                item={order}
                                refetch={refetch}
                                qrFunction={(order) => {
                                  setSelectedOrder(order);
                                  setIsOpenQrModal(true);
                                }}
                              />
                            </div>
                            <div
                              ref={(el) => (mappedDivRefs.current[index] = el)}
                              onScroll={() => handleScroll("mapped", index)}
                              id="content-wrapper"
                              className="w-full overflow-x-auto scrollbar-hide flex border-b border-gray-200"
                            >
                              <>
                                <div
                                  key={index}
                                  className="min-w-[346px] flex items-center py-2 px-4 border-r border-gray-200"
                                >
                                  <FlexProductSupplierCard item={order} />
                                </div>
                              </>

                              {filledOperations
                                ?.sort((a, b) => a?.step - b?.step)
                                ?.map((item, index) => {
                                  const operationName =
                                    item?.internalOperation?.process?.name ||
                                    item?.externalOperation?.process?.name;
                                  const occurrences =
                                    filledOperations
                                      .slice(0, index)
                                      .filter(
                                        (op) =>
                                          op &&
                                          (op?.internalOperation?.process
                                            ?.name ||
                                            op?.externalOperation?.process
                                              ?.name) === operationName
                                      ).length + 1;
                                  if (index > 0)
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="min-w-[346px] flex items-center justify-center py-2 px-4 border-r border-gray-200"
                                        >
                                          {item != null && (
                                            <FlexibleProductCard
                                              item={item}
                                              onClick={() => {
                                                navigate(
                                                  `/app/product/mes/flex/operation/detail/${order?.id}+${item?.step}`
                                                );
                                              }}
                                            />
                                          )}
                                        </div>
                                      </>
                                    );
                                })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className="z-50 border-t border-[#D0D5DD] bg-white rounded-b-xl">
                  <PaginationComponent
                    pageNo={Number(pageNo) ? Number(pageNo) : 1}
                    setPageNo={changePage}
                    pageCount={pageCount}
                  />
                </div>
              </div>
            </>
          </div>
        )}
      </div>

      <CustomModal
        isOpen={isNewOrderModal}
        setIsOpen={setIsNewOrderModal}
        modalTitle={t("product:createNewOrder")}
        width="fit-content"
        isBadges={true}
        badgesValue={totalCount + "/" + auth?.user?.company?.limits?.order}
        badgesColor={"fill-warning"}
        badgesSize={"md"}
        children={
          <NewOrder
            t={t}
            setOrderData={setOrderData}
            orderData={orderData}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            setSupplyForm={setSupplyForm}
            supplyForm={supplyForm}
            refresh={refetch}
            isOpen={isNewOrderModal}
            setIsNewOrderModal={setIsNewOrderModal}
          />
        }
      />
      <CustomModal
        isOpen={isOpenQrModal}
        setIsOpen={setIsOpenQrModal}
        modalTitle={t("embedded:getQr")}
        children={
          <OrderQrModal orderId={selectedOrder?.id} order={selectedOrder} />
        }
      />
      <CustomModal
        isOpen={supplyForm}
        setIsOpen={setSupplyForm}
        modalTitle={t("product:supplyForm")}
        children={
          <SupplyFormModal
            refresh={refetch}
            supplyForm={supplyForm}
            orderData={orderData}
            onClose={() => setSupplyForm(false)}
            setSupplyForm={setSupplyForm}
            selectedProduct={selectedProduct}
          />
        }
      />
    </>
  );
};

export default memo(FlexProductManagement);
