import React, { useEffect, useState } from "react";
import StepNoData from "../step-navigation/components/StepNoData";
import { useTranslation } from "react-i18next";
import StepsMainSide from "../../components/steps/StepsMainSide";
import { CustomModal } from "../../components";
import ShiftCard from "./components/ShiftCard";
import moment from "moment";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import NewShift from "./modals/NewShift";
import { shiftService } from "../../services/shift.service";
import { personalService } from "../../services/personal.service";
import { Toast } from "../../utils/toastify/toast";
import { Loader } from "../../assets/icons/stepsIcons";
import { timeFormatter } from "../../utils/timezoneFormatter";
import { useStore } from "../../hooks/useStores";

const Shifts = () => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("shiftInfo");
  const [deletedShift, setDeletedShift] = useState("");
  const [selectedShift, setSelectedShift] = useState({});
  const [isAlert, setIsAlert] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [personals, setPersonals] = useState([]);
  const [loading, setLoading] = useState(false);

  const isLimitReached =
    shifts && shifts?.length >= auth?.user?.company?.limits?.shift;
  const handleShifts = async () => {
    setLoading(true);
    await shiftService.getShifts().then(({ data }) => {
      setShifts(data);
    });
    setLoading(false);
  };

  const handlePersonals = async () => {
    await personalService.activePersonals().then(({ data }) => {
      setPersonals(
        data.map((d) => {
          return {
            value: d.id,
            label: d.name + " " + d.lastName,
          };
        })
      );
    });
  };

  const onDeleted = async (id) => {
    await shiftService
      .deleteShift(id)
      .then(() => Toast("success", t("addShift:deletedShift")));
  };

  useEffect(() => {
    handleShifts();
    handlePersonals();
  }, []);

  return (
    <>
      {shifts ? (
        <StepsMainSide
          setIsOpen={setIsOpen}
          title={t("addProcess:shifts")}
          length={
            shifts?.length +
            "/" +
            auth?.user?.company?.limits?.shift +
            " " +
            t("routes:shift")
          }
          addlabel={t("days:addNewShift")}
          limitlabel={t("days:addShiftCredit")}
          isLimitReached={isLimitReached}
          children={
            <>
              {loading ? (
                <div className="flex items-center mt-[120px] justify-center w-full h-full">
                  <Loader
                    currentColor="currentColor"
                    currentFill="currentFill"
                  />
                </div>
              ) : (
                <>
                  {shifts?.map((e, i) => (
                    <ShiftCard
                      key={i}
                      name={e.name}
                      labels={e.labels}
                      id={e.id}
                      responsibleUser={e.responsibleUser}
                      description={
                        moment(timeFormatter(e.start)?.formatted).format(
                          "HH:mm"
                        ) +
                        " - " +
                        moment(timeFormatter(e.end)?.formatted).format("HH:mm")
                      }
                      editOnClick={() => {
                        setSelectedTab("shiftInfo");
                        setSelectedShift(e);
                        setIsOpen(true);
                      }}
                      deleteOnclick={() => {
                        setDeletedShift(e?.id);
                        setIsAlert(true);
                      }}
                    />
                  ))}
                </>
              )}
            </>
          }
        />
      ) : (
        <StepNoData
          header={t("days:addShift")}
          text={t("addProcess:addShiftStep")}
          onClick={() => setIsOpen(true)}
        />
      )}

      <CustomModal
        onClose={() => {
          setSelectedShift(null);
          setIsOpen(false);
        }}
        children={
          <NewShift
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setIsOpen={setIsOpen}
            selectedShift={selectedShift}
            handleData={handleShifts}
            personals={personals}
          />
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        // width={"736px"}
      />
      <AlertModal
        title={t("addShift:deleteShift")}
        subTitle={t("addShift:deleteShiftMessage")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={async () => {
          await onDeleted(deletedShift);
          await handleShifts();
          setIsAlert(false);
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default Shifts;
