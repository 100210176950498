import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { TabModal } from "../../process";
import { externalOperationService } from "../../../services/external-operation.service";
import { Toast } from "../../../utils/toastify/toast";
import ExternalOperationInfo from "../pages/ExternalOperationInfo";
import { CustomModal } from "../../../components";
import AddSupplierModal from "./AddSupplierModal";
import ExternalResponsible from "../pages/ExternalResponsible";

const AddExternalOperationModal = observer(({ data, setIsOpen, selectedProcess, handleData }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("process");
  const [selectedPersonal, setSelectedPersonal] = useState([]);
  const [selectedSupplier, setSelectedSuplier] = useState([]);
  const [addSuplierOpen, setAddSuplierOpen] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const listSupplier = async () => {
    const response = await externalOperationService.listSupplier();
    setSupplier(response.data);
  };
  const [supplierData, setSupplierData] = useState({});
  const schema = yup.object({
    name: yup.string(),
    code: yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const sendData = {
      ...data,
      personals: selectedPersonal.map((item) => item?.id),
      supplier: selectedSupplier,
    };
    if (selectedProcess && selectedProcess.id) {
      await externalOperationService
        .updateOperation(selectedProcess.id, sendData)
        .then((res) => {

       
          if (res?.data?.code == 0) {
            handleData();
            setIsOpen(false);
            Toast("success", t("addProcess:updatedProcessMessage"));
          } else {
            setIsOpen(false);
            Toast("error", res?.data?.message);
          }
        })
        .catch((err) => {

        
          Toast("error", err?.data?.message);
        });
    } else {
      await externalOperationService
        .addOperation(sendData)
        .then((response) => {
          if (response?.data?.code == 0) {
            handleData();
            setIsOpen(false);
            Toast("success", t("addProcess:addedNewProcessMessage"));
          } else if (response?.data?.code == 2) {
            setIsOpen(false);
            Toast("error", t("addProcess:noLimitExternal"));
          } else if (response?.data?.code == 3) {
            handleData();
            setIsOpen(false);
            Toast("success", t("addProcess:processAddedButLimit"));
          } else {
            setIsOpen(false);
            Toast("error", response?.data?.message);
          }
        })
        .catch((err) => {
          Toast("error", err?.data?.message);
        });
    }
  };

  useEffect(() => {
    if (selectedProcess && selectedProcess?.id) {
      setValue("name", selectedProcess?.name);
      setValue("code", selectedProcess?.code);
      setValue("iconKey", selectedProcess?.iconKey);
      setValue(
        "supplier",
        selectedProcess?.supplier.map((d) => d.id)
      );
      setValue(
        "personals",
        selectedProcess?.responsibleUser.map((d) => d.id)
      );
      setSelectedSuplier(selectedProcess?.supplier.map((d) => d.id));
    } else {
      setValue("name", "");
      setValue("code", "");
      setValue("iconKey", "");
      setValue("supplier", "");
      setValue("personals", "");
    }
  }, [selectedProcess]);

  useEffect(() => {
    listSupplier();
  }, []);

  useEffect(() => {
    if (selectedProcess) {
      setSelectedPersonal(selectedProcess?.responsibleUser);
    }
  }, [selectedProcess]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-screen h-[542px] xs:w-[335px] xs:h-[656px] xs:overflow-hidden sm:w-[500px] md:w-[700px] sm:max-h-[500px] md:max-h-[520px] max-w-[800px] max-h-[570px] min-h-[450px]  xs:flex-col flex gap-x-4 scrollbar-hide"
      >
        <TabModal
          tabData={[
            {
              name: t("addProcess:processInfo"),
              value: "process",
              component: (
                <ExternalOperationInfo
                  setSelectedSuplier={setSelectedSuplier}
                  selectedSupplier={selectedSupplier}
                  setSupplierData={setSupplierData}
                  supplierData={supplierData}
                  control={control}
                  setValue={setValue}
                  closeModal={setIsOpen}
                  setAddSuplierOpen={setAddSuplierOpen}
                  errors={errors}
                  supplier={supplier}
                  setSelectedTab={setSelectedTab}
                />
              ),
            },
            {
              name: t("routes:processResponsible"),
              value: "processResponsible",
              component: (
                <ExternalResponsible
                  t={t}
                  setIsOpen={setIsOpen}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  selectedPersonals={selectedPersonal}
                  setSelectedPersonals={setSelectedPersonal}
                  errors={errors}
                  handleData={handleData}
                  selectedProcess={selectedProcess}
                  onSubmit={onSubmit}
                />
              ),
            },
          ]}
          width="1/2"
          data={data}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </form>
      <CustomModal
        isOpen={addSuplierOpen}
        setIsOpen={setAddSuplierOpen}
        children={<AddSupplierModal supplierData={supplierData} setSupplierData={setSupplierData} setIsOpen={setAddSuplierOpen} listSupplier={listSupplier} />}
        modalTitle={supplierData?.id ? t("step:updateSupplier") : t("product:createSupplier")}
      />
    </>
  );
});

export default AddExternalOperationModal;
