import React from "react";
import { CheckIcon, PlusIcon } from "../../../../components/new-cards/card-icons";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import CustomImage from "../../../../components/CustomImage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InternalPlannedPassiveCard = ({ setIsOpenSelectProductModal, setDrappedEquipment, a }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`flex w-full flex-col border border-t-4 cursor-pointer ${"border-t-[#98A2B3]"} items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
      >
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
            <button
              onClick={() => {
                setIsOpenSelectProductModal(true);
                setDrappedEquipment(a);
              }}
              type="button"
              className="w-[64px] min-w-[64px] h-[48px] border-dashed  border border-gray-300 flex items-center justify-center"
            >
              <PlusIcon />
            </button>
            <div className="flex flex-col items-start gap-y-[6px]">
              <p className="text-sm font-semibold text-secondary-900 truncate w-[120px]">{t("product:addProduct")}</p>
              <div className="flex flex-row items-center gap-x-2">
                <p className="text-xs font-medium text-secondary-600 w-[120px] truncate">{t("product:addPersonnels")}</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              setIsOpenSelectProductModal(true);
              setDrappedEquipment(a);
            }}
            type="button"
            className="min-w-[48px] h-[48px] rounded-full border-dashed  border border-gray-300 flex items-center justify-center"
          >
            <PlusIcon />
          </button>
        </div>
        <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB]  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
          <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 px-2 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
            <CheckIcon />
            <p className="text-xxs text text-secondary-600 font-semibold">{t("product:passives")}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternalPlannedPassiveCard;
