import React from "react";
import { MiniArrowUpIcon, UpuPointLogo } from "../../../../assets/icons/PersonIcons";
import Badgets from "../../../../components/buttons/Badgets";
import PersonelDetailCharts from "../PersonelDetailCharts";
import { useTranslation } from "react-i18next";

const PersonalDetailInfo = ({ gaugeData, data }) => {
  const {t} = useTranslation()
  return (
    <div className="flex flex-col min-w-[376px] max-w-[376px] w-[376px] h-full gap-y-4">
      <div className="flex w-full h-[124px] max-h-[124px] min-h-[124px] border border-[#F8DA64] rounded-lg shadow-xs">
        <div className="flex w-11 bg-[#FEFAE9] p-2 border-r border-[#F8DA64] rounded-l-lg items-center justify-center">
          <span className="flex min-w-[28px] max-w-[28px] min-h-[28px] max-h-[28px] items-start justify-end">
            <UpuPointLogo />
          </span>
        </div>
        <div className="flex w-full h-full items-center">
          <div className="flex w-1/2 h-full p-4 flex-col gap-y-3">
            <div className="flex w-full gap-x-2 items-center">
              <span className="text-[#475467] font-medium text-sm">{t("chat:today")}</span>
              {/* <Badgets size={"sm"} colorType={"fill-success"} iconLeft={<MiniArrowUpIcon />} label={"18th"} /> */}
            </div>
            <div className="flex w-full flex-col h-full">
              <span className="text-[#9B7D07] font-semibold text-3xl">{data?.upuPoint ? (data?.upuPoint).toFixed(2) : "0"}</span>
              <span className="font-codec text-sm">
                upu.<span className="text-[#CFAA01]">point</span>
              </span>
            </div>
          </div>
          <div className="flex w-[1px] min-w-[1px] max-w-[1px] h-full bg-[#E4E7EC] max-h-[100px]"></div>
          <div className="flex w-1/2 h-full p-4 flex-col gap-y-3">
            <div className="flex w-full gap-x-2 items-center">
              <span className="text-[#475467] font-medium text-sm">{t("chat:thisMonth")}</span>
              <Badgets size={"sm"} colorType={"fill-success"} label={data?.monthlyUpuPoint?.rank + "th"} />
            </div>
            <div className="flex w-full flex-col h-full">
              <span className="text-[#9B7D07] font-semibold text-3xl">
                {data?.monthlyUpuPoint?.upuPoint ? (data?.monthlyUpuPoint?.upuPoint).toFixed(2) : "0"}
              </span>
              <span className="font-codec text-sm">
                upu.<span className="text-[#CFAA01]">point</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <PersonelDetailCharts gaugeData={gaugeData} />
    </div>
  );
};

export default PersonalDetailInfo;
