import React from "react";
import { consoleStore } from "../../../stores/console.store";
import moment from "moment";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { useNavigate } from "react-router-dom";

const MultipleEventRect = ({
  event,
  handleMouseOver,
  handleMouseOut,
  handleMouseMove,
  index,
  startPos,
  width,
  widthS,
  totalWidth,
  selectedJunction,
  modifiedShift,
  setSelectedJunction,
  onClick,
  junctionNumber,
  type,

  //bottom
  setIsOpen,
  setDefaultStartForm,
  setDefaultEndForm,
  path,

  //edit event
  defaultEndTime,
  defaultStartTime,

  //only junction
  onlyJuntionValues,
}) => {
  let yOffset = 86;
  let junctionColor;

  if (event?.color === "#EAAA08") {
    junctionColor = "#EAAA08";
  } else if (event?.color === "#12B76A") {
    junctionColor = "#079455";
  } else if (event?.color === "#D92D20") {
    junctionColor = "#D92D20";
  } else if (event?.color === "#12B7") {
    junctionColor = "gray";
  } else {
    junctionColor = event?.color;
  }

  const navigate = useNavigate();

  return (
    <svg onMouseMove={handleMouseMove} width={totalWidth}>
      {event?.index == 0 ? (
        <>
          {event.junctionType == "empty" ? (
            <>
              {event.junctionId == selectedJunction ? (
                <>
                  <rect
                    onClick={async () => {
                      await setDefaultStartForm(moment(event.startDate).add(3, "hour").add(1, "second").format("YYYY-MM-DDTHH:mm:ss"));
                      await setDefaultEndForm(moment(event.endDate).add(3, "hour").add(1, "second").format("YYYY-MM-DDTHH:mm:ss"));
                      await navigate(
                        `manuel-detail/${moment(event.startDate).add(3, "hour").add(1, "second").format("YYYY-MM-DDTHH:mm:ss")}+${moment(event.endDate)
                          .add(3, "hour")
                          .add(1, "second")
                          .format("YYYY-MM-DDTHH:mm:ss")}`
                      );
                      // setIsOpen(true);
                    }}
                    key={index}
                    x={startPos}
                    y={94}
                    width={width}
                    rx={"4"}
                    height="24"
                    strokeWidth={selectedJunction === modifiedShift?.junctionId ? 4 : ""}
                    stroke={selectedJunction === modifiedShift?.junctionId ? "#7F56D9" : junctionColor}
                    stroke-dasharray="3"
                    fill={"#F9FAFB80"}
                    onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                    onMouseOut={handleMouseOut}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" x={startPos + width / 2} y={yOffset + 12}>
                    <path
                      d="M8.5 5.33333V10.6667M5.83333 8H11.1667M5.7 14H11.3C12.4201 14 12.9802 14 13.408 13.782C13.7843 13.5903 14.0903 13.2843 14.282 12.908C14.5 12.4802 14.5 11.9201 14.5 10.8V5.2C14.5 4.0799 14.5 3.51984 14.282 3.09202C14.0903 2.71569 13.7843 2.40973 13.408 2.21799C12.9802 2 12.4201 2 11.3 2H5.7C4.5799 2 4.01984 2 3.59202 2.21799C3.21569 2.40973 2.90973 2.71569 2.71799 3.09202C2.5 3.51984 2.5 4.0799 2.5 5.2V10.8C2.5 11.9201 2.5 12.4802 2.71799 12.908C2.90973 13.2843 3.21569 13.5903 3.59202 13.782C4.01984 14 4.5799 14 5.7 14Z"
                      stroke="#344054"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <text
                    onClick={async () => {
                      await setDefaultStartForm(moment(event.startDate).add(3, "hour").add(1, "second").format("YYYY-MM-DDTHH:mm:ss"));
                      await setDefaultEndForm(moment(event.endDate).add(3, "hour").add(1, "second").format("YYYY-MM-DDTHH:mm:ss"));
                      await navigate(
                        `manuel-detail/${moment(event.startDate).add(3, "hour").add(1, "second").format("YYYY-MM-DDTHH:mm:ss")}+${moment(event.endDate)
                          .add(3, "hour")
                          .add(1, "second")
                          .format("YYYY-MM-DDTHH:mm:ss")}`
                      );
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    x={startPos + width / 2 + 20}
                    y={yOffset + 25}
                    font-family="Inter"
                    font-size="14"
                    fill="#344054"
                    onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                    onMouseOut={handleMouseOut}
                  >
                    Add Manuel Event
                  </text>
                </>
              ) : (
                <>
                  <rect
                    onClick={() => {
                      onClick();
                      setDefaultStartForm(moment(event.startDate).add(3, "hour").add(1, "second").format("YYYY-MM-DDTHH:mm:ss"));
                      setDefaultEndForm(moment(event.endDate).add(3, "hour").add(1, "second").format("YYYY-MM-DDTHH:mm:ss"));
                    }}
                    key={index}
                    x={startPos}
                    y={94}
                    width={width}
                    rx={"4"}
                    height="24"
                    stroke={junctionColor}
                    stroke-dasharray="3"
                    fill={"#F9FAFB80"}
                    onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                    onMouseOut={handleMouseOut}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </>
              )}
            </>
          ) : event.junctionId == selectedJunction && event?.junctionType != "subEvent" && event?.junctionType != "losses" ? (
            <>
              <rect
                // onClick={onClick}
                onClick={async () => {
                  await setDefaultStartForm(defaultStartTime);
                  await setDefaultEndForm(defaultEndTime);

                  const matchedJunction = onlyJuntionValues.find((junction) => junction?.junctionId == event.junctionId);
                  const previousJunction = onlyJuntionValues[onlyJuntionValues.indexOf(matchedJunction) - 1];
                  const nextJunction = onlyJuntionValues[onlyJuntionValues.indexOf(matchedJunction) + 1];

                  if (previousJunction && nextJunction) {
                    await navigate(
                      `manuel-detail/edit/${moment(previousJunction?.endDate).add(2, "hour", 30, "minutes").format("YYYY-MM-DDTHH:mm:ss")}+${moment(
                        nextJunction.startDate
                      )
                        .add(4, "hour", 0, "minutes")
                        .format("YYYY-MM-DDTHH:mm:ss")}/event/${selectedJunction}`
                    );
                  } else if (previousJunction && !nextJunction) {
                    await navigate(
                      `manuel-detail/edit/${moment(previousJunction?.endDate).add(2, "hour", 30, "minutes").format("YYYY-MM-DDTHH:mm:ss")}+${moment(
                        defaultEndTime
                      ).format("YYYY-MM-DDTHH:mm:ss")}/event/${selectedJunction}`
                    );
                  } else if (!previousJunction && nextJunction) {
                    await navigate(
                      `manuel-detail/edit/${moment(defaultStartTime).format("YYYY-MM-DDTHH:mm:ss")}+${moment(nextJunction?.startDate)
                        .add(3, "hour", 30, "minutes")
                        .format("YYYY-MM-DDTHH:mm:ss")}/event/${selectedJunction}`
                    );
                  }
                }}
                key={index}
                x={startPos}
                y={94}
                width={width}
                rx={"4"}
                height="24"
                strokeWidth={selectedJunction === modifiedShift?.junctionId ? 4 : ""}
                stroke={selectedJunction === modifiedShift?.junctionId ? "#7F56D9" : junctionColor}
                fill={junctionColor}
                onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                onMouseOut={handleMouseOut}
                // stroke={'red'}
                style={{
                  cursor: "pointer",
                }}
              />

              {moment(event?.endDate).add(3, "hour")?.format("YYYY-MM-DDTHH:mm") != moment(new Date()).format("YYYY-MM-DDTHH:mm") && (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none" x={startPos + width / 2} y={yOffset + 12}>
                  <path
                    d="M2.39671 15.0966C2.435 14.752 2.45414 14.5798 2.50627 14.4187C2.55252 14.2758 2.61787 14.1399 2.70054 14.0145C2.79372 13.8732 2.9163 13.7506 3.16145 13.5055L14.1667 2.50025C15.0872 1.57978 16.5796 1.57978 17.5 2.50025C18.4205 3.42073 18.4205 4.91311 17.5 5.83359L6.49478 16.8388C6.24963 17.084 6.12706 17.2065 5.98575 17.2997C5.86038 17.3824 5.72442 17.4477 5.58155 17.494C5.42051 17.5461 5.24823 17.5653 4.90365 17.6036L2.08334 17.9169L2.39671 15.0966Z"
                    stroke="#FFF"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <text
                onClick={async () => {
                  await setDefaultStartForm(defaultStartTime);
                  await setDefaultEndForm(defaultEndTime);

                  const matchedJunction = onlyJuntionValues.find((junction) => junction.junctionId === event.junctionId);
                  const previousJunction = onlyJuntionValues[onlyJuntionValues.indexOf(matchedJunction) - 1];
                  const nextJunction = onlyJuntionValues[onlyJuntionValues.indexOf(matchedJunction) + 1];

                  if (previousJunction && nextJunction) {
                    await navigate(
                      `manuel-detail/edit/${moment(previousJunction?.endDate).add(2, "hour", 30, "minutes").format("YYYY-MM-DDTHH:mm:ss")}+${moment(
                        nextJunction.startDate
                      )
                        .add(4, "hour", 0, "minutes")
                        .format("YYYY-MM-DDTHH:mm:ss")}/event/${selectedJunction}`
                    );
                  } else if (previousJunction && !nextJunction) {
                    await navigate(
                      `manuel-detail/edit/${moment(previousJunction?.endDate).add(2, "hour", 30, "minutes").format("YYYY-MM-DDTHH:mm:ss")}+${moment(
                        defaultEndTime
                      ).format("YYYY-MM-DDTHH:mm:ss")}/event/${selectedJunction}`
                    );
                  } else if (!previousJunction && nextJunction) {
                    await navigate(
                      `manuel-detail/edit/${moment(defaultStartTime).format("YYYY-MM-DDTHH:mm:ss")}+${moment(
                        moment(nextJunction?.startDate).add(3, "hour", 30, "minutes").format("YYYY-MM-DDTHH:mm:ss")
                      )}/event/${selectedJunction}`
                    );
                  }
                }}
                style={{
                  cursor: "pointer",
                }}
                x={startPos + width / 2 + 20}
                y={yOffset + 25}
                font-family="Inter"
                font-size="14"
                fill="#FFF"
                onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                onMouseOut={handleMouseOut}
              >
                {moment(event?.endDate).add(3, "hour")?.format("YYYY-MM-DDTHH:mm") != moment(new Date()).format("YYYY-MM-DDTHH:mm") && "Edit Event"}
              </text>
            </>
          ) : (
            <>
              <rect
                onClick={onClick}
                key={index}
                x={startPos}
                y={94}
                width={width}
                rx={"4"}
                height="24"
                strokeWidth={selectedJunction === modifiedShift?.junctionId ? 4 : ""}
                stroke={selectedJunction === modifiedShift?.junctionId ? "#7F56D9" : junctionColor}
                fill={junctionColor}
                onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                onMouseOut={handleMouseOut}
                // stroke={'red'}
                style={{
                  cursor: "pointer",
                }}
              />

              {width > 30 ? (
                <>
                  <text
                    onClick={onClick}
                    style={{
                      cursor: "pointer",
                    }}
                    x={startPos + 22}
                    y={yOffset + 25}
                    font-family="Inter"
                    font-size="14"
                    fill="#FFF"
                    onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                    onMouseOut={handleMouseOut}
                  >
                    {junctionNumber(event?.junctionId)}
                  </text>
                  {event?.junctionType === "losses" ? (
                    <svg x={startPos + 6} y={yOffset + 14} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.895431 11.1046 0 10 0H2ZM7 2.5C7 1.94772 6.55228 1.5 6 1.5C5.44772 1.5 5 1.94772 5 2.5V6C5 6.55228 5.44772 7 6 7C6.55228 7 7 6.55228 7 6V2.5ZM6 8.5C5.44772 8.5 5 8.94772 5 9.5C5 10.0523 5.44772 10.5 6 10.5H6.01C6.56228 10.5 7.01 10.0523 7.01 9.5C7.01 8.94772 6.56228 8.5 6.01 8.5H6Z"
                        fill="white"
                      />
                    </svg>
                  ) : (
                    <svg x={startPos + 6} y={yOffset + 14} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.895431 11.1046 0 10 0H2ZM4.08189 3.13742C4.00708 3.23734 4.00708 3.41632 4.00708 3.77428V8.32628C4.00708 8.68425 4.00708 8.86323 4.08189 8.96315C4.14708 9.05023 4.24686 9.10471 4.35536 9.11246C4.47987 9.12135 4.63042 9.02456 4.93154 8.83099L8.47198 6.55499C8.73327 6.38702 8.86391 6.30303 8.90904 6.19624C8.94847 6.10293 8.94847 5.99764 8.90904 5.90433C8.86391 5.79753 8.73327 5.71355 8.47198 5.54558L4.93153 3.26958C4.63042 3.076 4.47987 2.97922 4.35536 2.98811C4.24686 2.99586 4.14708 3.05034 4.08189 3.13742Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </>
              ) : width >= 6 ? (
                <>
                  {" "}
                  <text
                    style={{
                      cursor: "pointer",
                    }}
                    x={startPos + 4}
                    y={yOffset + 24}
                    font-family="Inter"
                    font-size="14"
                    fill="#FFF"
                    onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                    onMouseOut={handleMouseOut}
                  >
                    {junctionNumber(event?.junctionId)}
                  </text>
                </>
              ) : (
                <>
                  <text
                    style={{
                      cursor: "pointer",
                    }}
                    x={startPos - 2}
                    y={yOffset + 25}
                    font-family="Inter"
                    font-size="14"
                    // fill={event?.color}
                    fill="#FFF"
                    onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                    onMouseOut={handleMouseOut}
                  >
                    {/* .. */}
                    {junctionNumber(event?.junctionId)}
                  </text>
                </>
              )}

              {event?.junctionType == "subEvent" && (
                <>
                  <defs>
                    <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4" backgroundColor="black">
                      <path
                        d="M-1,1 l2,-2 
M0,4 l4,-4
M3,5 l2,-2"
                        style={{ stroke: "#F9FAFB", strokeWidth: 1.5 }}
                      />
                    </pattern>
                  </defs>
                  <rect
                    onClick={() => {}}
                    key={index}
                    x={startPos}
                    y={96}
                    width={width}
                    rx={"4"}
                    height="20"
                    fill={"#98A2B3"}
                    opacity={1}
                    onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                    onMouseOut={handleMouseOut}
                    style={{
                      cursor: "pointer",
                    }}
                  ></rect>
                  <rect
                    onClick={onClick}
                    key={index}
                    x={startPos}
                    y={96}
                    width={width}
                    rx={"4"}
                    height="20"
                    fill={"url(#diagonalHatch)"}
                    opacity={1}
                    onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
                    onMouseOut={handleMouseOut}
                    style={{
                      cursor: "pointer",
                    }}
                  ></rect>
                </>
              )}
            </>
          )}
        </>
      ) : (
        //status
        <g>
          <rect
            key={index}
            x={startPos}
            y={30}
            width={widthS}
            height="60"
            fill={event?.color == "#FFFFFF" ? "#F9FAFB" : event?.color}
            onMouseOver={(e) => handleMouseOver(event, startPos, yOffset - 30, e)}
            onMouseOut={handleMouseOut}
            style={{ cursor: "pointer" }}
            rx={"2"}
            // strokeWidth="1"
            // stroke={hexToRGB("#000000", 10)}

            // stroke="red"
          />
        </g>
      )}
    </svg>
  );
};

export default MultipleEventRect;
