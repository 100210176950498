import React from "react";

const TextArea = ({
  label,
  icon,
  placeholder,
  onChange,
  onBlur,
  value,
  height,
  disabled,
  validate,
  iconPosition,
  field,
  type,
  theme,
  errorMessage,
  rows,
  minHeight,
  ...rest
}) => {
  return (
    <div className={"flex flex-col relative w-full"} {...rest}>
      <span className="text-sm mb-1.5 pl-0  w-full text-secondary-700 font-medium truncate dark:text-[#D6D6D6]">
        {label}
      </span>
      <div
        style={{
          height: height ? height : "",
        }}
        className={` ${
          theme === "person"
            ? "focus-within:border-teal-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#C4EDE6] "
            : theme === "product"
            ? "focus-within:border-warning-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#FDE3C1] "
            : theme === "embedded"
            ? "focus-within:border-grayBlue-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#D2D6E8] "
            : "focus-within:border-primary-300 focus-within:outline  focus-within:outline-4 focus-within:outline-[#E8DFFB] "
        } flex w-full items-center pl-[10px] pr-1 pt-[4px] pb-1.5 border focus-within:ring-opacity-50 transition duration-300 rounded-lg dark:border-darkTrue-600 dark:bg-[#0F0F0F]
            ${
              validate === "success"
                ? "border-validate-success"
                : validate === "warning"
                ? "border-validate-warning"
                : validate === "error"
                ? "border-validate-errorBorder"
                : "border-secondary-300 dark:border-[#424242]"
            } text-secondary-500 text-base
              ${disabled ? "bg-secondary-50  mx-0" : "bg-white"} `}
      >
        <textarea
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          {...rest}
          {...field}
          disabled={disabled}
          className={`h-full border-transparent text-base font-normal outline-none ${
            placeholder
              ? "text-secondary-900"
              : disabled
              ? "text-secondary-500"
              : "text-secondary-900"
          } dark:bg-[#0F0F0F] dark:text-darkTrue-50 focus:border-transparent focus:ring-0 border-none rounded-lg w-full px-0 ${
            disabled ? "bg-secondary-50 dark:bg-[#0F0F0F] " : <></>
          } ${minHeight ? `min-h-[${minHeight}px]` : <></>} `}
          rows={rows}
        />
      </div>
      {validate ? (
        <p
          className={` ${
            validate === "success" ? (
              "text-validate-success"
            ) : validate === "warning" ? (
              "text-validate-warning"
            ) : validate === "error" ? (
              "text-validate-error"
            ) : validate === "tooltip" ? (
              "text-secondary-500 text-[14px] leading-5"
            ) : (
              <></>
            )
          }  sm:mt-0.5 lg:mt-1 md:mt-1 sm:text-[10px]  text-[12px]  mt-0.5 font-normal `}
        >
          {errorMessage || validate.toLocaleUpperCase()}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TextArea;
