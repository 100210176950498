import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, Loading, UploadProfile } from "../../../components";
import TextArea from "../../../components/inputs/TextArea";
import { customerService } from "../../../services/customer.service";
import NewSelectInput from "../../../components/inputs/NewSelectInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Toast } from "../../../utils/toastify/toast";
import { CheckIcon } from "../../../assets/icons/PersonIcons";

const AddCustomerModal = ({ t, isOpen, setIsOpen, customerUpdateId, handleCustomerList }) => {
  const [customers, setCustomers] = useState([]);
  const [group, setGroup] = useState([]);
  const [checkBox, setCheckBox] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonLoading,setButtonLoading] = useState(false)
  const getCustomerGroupList = async () => {
    const { data } = await customerService.getCustomerGroupList();
    const list = data?.map((item, index) => {
      return { label: item?.name, value: item?.id };
    });
    setCustomers(list);
  };

  const handleData = async () => {
    setLoading(true);
    const data = await customerService.findOneCustomer(customerUpdateId).catch((err) => Toast("error", String(err)));
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (customerUpdateId) {
      handleData();
    }
    getCustomerGroupList();
  }, []);

  const schema = yup.object({
    customerName: yup.string(),
    image: yup.string().notRequired(),
    contactName: yup.string(),
    contactEmail: yup.string(),
    contactPhone: yup.string(),
    taxOffice: yup.string(),
    taxNo: yup.string(),
    deliveryAddress: yup.string(),
    invoiceAddress: yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (value) => {
    if (buttonLoading) return; 
    setButtonLoading(true);
    const send = {
      name: value?.customerName,
      image: value?.image,
      contactName: value?.contactName,
      contactEmail: value?.contactEmail,
      contactPhone: value?.contactPhone,
      taxOffice: value?.taxOffice,
      taxNo: value?.taxNo,
      deliveryAddress: value?.deliveryAddress,
      invoiceAddress: value?.invoiceAddress,
      group: group,
    };
    if (customerUpdateId) {
      await customerService.updateCustomer(customerUpdateId, send).then((res) => {
        if (res.data.code == 0) {
          Toast("success", res?.data?.message);
          setIsOpen(!isOpen);
          handleCustomerList();
        } else {
          Toast("error", res?.data?.message);
        }
      });
      return;
    } else {
      await customerService.addCustomer(send).then((res) => {
        if (res.data.code == 0) {
          Toast("success", res?.data?.message);
          setIsOpen(!isOpen);
          handleCustomerList();
        } else {
          Toast("error", res?.data?.message);
        }
        setButtonLoading(false)
      });
    }
  };

  useEffect(() => {
    setValue("image", data?.data?.image);
    setValue("customerName", data?.data?.name);
    setGroup();
    setGroup(
      data?.data?.group?.map((el) => {
        return el?.id;
      })
    );
    setValue("contactName", data?.data?.contactName);
    setValue("contactEmail", data?.data?.contactEmail);
    setValue("contactPhone", data?.data?.contactPhone);
    setValue("taxOffice", data?.data?.taxOffice);
    setValue("taxNo", data?.data?.taxNo);
    setValue("invoiceAddress", data?.data?.invoiceAddress);
    setValue("deliveryAddress", data?.data?.deliveryAddress);
  }, [data]);

  useEffect(() => {
    if (checkBox) {
      const invoiceAddress = getValues("invoiceAddress"); // Fatura adresini alıyoruz
      setValue("deliveryAddress", invoiceAddress); // Teslimat adresine kopyalıyoruz
    }
  }, [checkBox, setValue, getValues]);

  return (
    <>
      {loading ? (
        <div className="min-w-[632px] max-w-[632px] w-[632px] min-h-[665px] max-h-[665px] h-[665px] flex items-center justify-center">
          <Loading color={"#B54708"} secondary={"#B54708"} size={36} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="w-[632px] h-[655px] flex flex-col pt-6 gap-6 items-start">
          <div className="w-full h-[558px] flex flex-col items-start gap-4">
            <div className="flex w-[40%]">
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <UploadProfile title={t("product:customerLogo")} subTitle={t("fileInput:removePicture")} onChange={onChange} onBlur={onBlur} value={value} />
                )}
                name="image"
              />
            </div>

            <div className="w-full h-[476px] flex items-start gap-6">
              <div className="w-[48%] h-full flex flex-col items-start gap-4">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      label={t("product:customerName")}
                      onBlur={onBlur}
                      value={value}
                      theme={"product"}
                      errorMessage={errors?.customerName?.message}
                      validate={errors?.customerName ? "error" : ""}
                    />
                  )}
                  name="customerName"
                />
                <NewSelectInput
                  items={customers}
                  value={group}
                  placeholder={t("product:customerGroup")}
                  theme={"product"}
                  name="groupId"
                  onChange={(e) => {
                    setGroup(e);
                  }}
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      label={t("product:authorizedPerson")}
                      value={value}
                      theme={"product"}
                      errorMessage={errors?.contactName?.message}
                      validate={errors?.contactName ? "error" : ""}
                    />
                  )}
                  name="contactName"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      label={t("product:email")}
                      value={value}
                      theme={"product"}
                      errorMessage={errors?.contactEmail?.message}
                      validate={errors?.contactEmail ? "error" : ""}
                    />
                  )}
                  name="contactEmail"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      label={t("table:phoneNo")}
                      value={value}
                      theme={"product"}
                      errorMessage={errors?.contactPhone?.message}
                      validate={errors?.contactPhone ? "error" : ""}
                    />
                  )}
                  name="contactPhone"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      label={t("addCompany:taxOffice")}
                      value={value}
                      theme={"product"}
                      errorMessage={errors?.taxOffice?.message}
                      validate={errors?.taxOffice ? "error" : ""}
                    />
                  )}
                  name="taxOffice"
                />
              </div>

              <div className=" h-full w-[1px] bg-[#EAECF0]" />

              <div className="w-[48%] h-full flex flex-col items-start gap-4 ">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      label={t("addCompany:taxNumber")}
                      value={value}
                      theme={"product"}
                      errorMessage={errors?.taxNo?.message}
                      validate={errors?.taxNo ? "error" : ""}
                    />
                  )}
                  name="taxNo"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextArea
                      onBlur={onBlur}
                      onChange={onChange}
                      rows={5}
                      label={t("product:billingAddress")}
                      theme={"product"}
                      value={value}
                      validate={errors.invoiceAddress ? "error" : ""}
                      errorMessage={errors?.invoiceAddress?.message}
                    />
                  )}
                  name="invoiceAddress"
                />
                <div onClick={() => setCheckBox(!checkBox)} className="flex items-start justify-start gap-x-3 w-full h-fit cursor-pointer">
                  <span
                    className={`flex rounded min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] items-center justify-center mt-[2px] ${
                      checkBox ? "bg-[#DC6803]" : "bg-white border border-[#D0D5DD]"
                    }`}
                  >
                    {checkBox && <CheckIcon />}
                  </span>
                  <div className="flex w-full">
                    <span className="text-[#475467] font-normal text-sm">{t("product:letBilling")}</span>
                  </div>
                </div>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextArea
                      onBlur={onBlur}
                      onChange={onChange}
                      rows={5}
                      label={t("product:deliveryAddress")}
                      theme={"product"}
                      value={value}
                      disabled={checkBox}
                      validate={errors.deliveryAddress ? "error" : ""}
                      errorMessage={errors?.deliveryAddress?.message}
                    />
                  )}
                  name="deliveryAddress"
                />
              </div>
            </div>
          </div>

          <div className="w-full items-center h-11 flex gap-x-3">
            <Button label={t("buttons:cancel")} colorType={"secondary-gray"} size={"sm"} onClick={() => setIsOpen(!isOpen)} />
            <Button label={buttonLoading ? <Loading color={"#fff"} secondary={"#fff"} size={22} /> : t("routes:saveCustomer")} disabled={buttonLoading ? true : false} colorType={"primary-product"} size={"sm"} type={"submit"} />
          </div>
        </form>
      )}
    </>
  );
};

export default AddCustomerModal;
