import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FlexProductOperationDetailHeader from "./FlexProductOperationDetailHeader";
import FlexOperationJunctions from "./FlexOperationJunctions";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../components";
import FlexOperationDetailHeader from "../machine/Twin/components/FlexOperationDetailHeader";
import { flexProductService } from "../../services/flex-product.service";
const FlexProductOperationDetail = () => {
  const navigate = useNavigate();
  const { operationId } = useParams();
  const [selectedOperation, setSelectedOperation] = useState("all");
  const [modalSelectedOperation, setModalSelectedOperation] = useState("all");
  const multiOperation = useQuery({
    enabled: true,
    queryKey: ["multi-flex-operations"],
    queryFn: async () => {
      return selectedOperation == "all" && (await flexProductService.getOperationDetails(operationId.split("+")[0], operationId.split("+")[1]));
    },
    retry: 0,
  });
  const singleOperation = useQuery({
    enabled: true,
    queryKey: ["single-flex-operation"],
    retry: 0,
    queryFn: async () => {
      return selectedOperation != "all" && (await flexProductService.getFlexMultiOperationOneList(selectedOperation));
    },
  });
  useEffect(() => {
    selectedOperation == "all" ? multiOperation.refetch() : singleOperation.refetch();
  }, [selectedOperation, operationId]);
  useEffect(() => {
    selectedOperation == "all" ? setModalSelectedOperation(multiOperation?.data) : setModalSelectedOperation(singleOperation?.data);
  }, [multiOperation, singleOperation]);
  return (
    <>
      {multiOperation?.isLoading ||
      singleOperation?.isLoading ||
      multiOperation?.isFetching ||
      singleOperation?.isFetching ||
      multiOperation?.isRefetching ||
      singleOperation?.isRefetching ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading size={32} primary={"#B54708"} secondary={"#FFF"} />
        </div>
      ) : (
        <div className="w-full h-full overflow-y-auto scrollbar-hide">
          <FlexProductOperationDetailHeader
            navigate={navigate}
            refetch={selectedOperation == "all" ? multiOperation?.refetch : singleOperation?.refetch}
            productType={selectedOperation == "all" ? multiOperation?.data?.productType : singleOperation?.data?.productType}
            isLoading={
              multiOperation?.isLoading ||
              singleOperation?.isLoading ||
              multiOperation?.isFetching ||
              singleOperation?.isFetching ||
              multiOperation?.isRefetching ||
              singleOperation?.isRefetching
            }
            isFetching={
              multiOperation?.isLoading ||
              singleOperation?.isLoading ||
              multiOperation?.isFetching ||
              singleOperation?.isFetching ||
              multiOperation?.isRefetching ||
              singleOperation?.isRefetching
            }
            operationId={operationId}
            data={selectedOperation == "all" ? multiOperation?.data : singleOperation?.data}
            headerId={operationId.split("+")[0]}
            orderId={operationId.split("+")[1]}
            operation={selectedOperation == "all" ? multiOperation?.data : singleOperation?.data}
            currentOperation={
              selectedOperation != "all"
                ? singleOperation?.data?.operations?.find((item) => item.operationId == selectedOperation)
                : multiOperation?.data?.operations?.length == 1
                ? multiOperation?.data?.operations[0]
                : false
            }
          />
          <FlexOperationDetailHeader
            data={selectedOperation == "all" ? multiOperation?.data : singleOperation?.data}
            refetch={selectedOperation == "all" ? multiOperation?.refetch : singleOperation?.refetch}
            selectedOperation={selectedOperation}
            setSelectedOperation={setSelectedOperation}
            modalSelectedOperation={modalSelectedOperation}
            setModalSelectedOperation={setModalSelectedOperation}
          />
          <FlexOperationJunctions
            data={selectedOperation == "all" ? multiOperation?.data?.junctions : singleOperation?.data?.junctions || []}
            refetch={selectedOperation == "all" ? multiOperation?.refetch : singleOperation?.refetch}
            operation={selectedOperation == "all" ? multiOperation?.data : singleOperation?.data}
            orderIds={operationId.split("+")[1]}
            onScrolling={() => {}}
            setSelectedOperation={setSelectedOperation}
          />
        </div>
      )}
    </>
  );
};

export default FlexProductOperationDetail;
