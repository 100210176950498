import React from "react";
import { Button, Input, TimeInput } from "../../../components";
import { Controller, useForm } from "react-hook-form";
import Selector from "../../../components/inputs/Selector";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { orderService } from "../../../services/order.service";
import { Toast } from "../../../utils/toastify/toast";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { DateIcon, QtyIcon3 } from "../../../assets/icons/commonIcons";

const ExternalInternalRequestForm = ({
  purchaseData,
  product,
  closeModal,
  deliveryDate,
}) => {
  const { t } = useTranslation();

  const schema = yup.object({
    unitType: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    unitPrice: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    date: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    cost: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    productCost: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const units = [
    { label: "Kilogram", shortLabel: "kg", value: "kg" },
    { label: t("product:metricTon"), shortLabel: "ton", value: "ton" },
    { label: "Gram", shortLabel: "gr", value: "gr" },
    { label: "Miligram", shortLabel: "mg", value: "mg" },
    { label: "Microgram", shortLabel: "mcg", value: "mcg" },
    { label: "Pound", shortLabel: "lb", value: "lb" },
    { label: t("product:ounce"), shortLabel: "oz", value: "oz" },
    { label: "Metre", shortLabel: "m", value: "m" },
    { label: "Millilitre", shortLabel: "mm", value: "mm" },
    { label: t("product:gallon"), shortLabel: "gal", value: "gal" },
    { label: t("product:squareMetre"), shortLabel: "m2", value: "m2" },
    { label: t("product:squareMilimetre"), shortLabel: "mm2", value: "mm2" },
    { label: t("product:squareInch"), shortLabel: "in2", value: "mm2" },
    { label: t("product:pieceModal"), shortLabel: "ad", value: "ad" },
    { label: t("product:package"), shortLabel: "pkg", value: "pkg" },
    { label: t("product:box"), shortLabel: "box", value: "box" },
  ];

  const priceOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
  ];

  const onSubmit = async (value) => {
    const sendInternal = {
      orderedUnit: value?.productCost,
      estimatedDeliveryDate: value?.date,
      product: product?.id,
      unitType: value.unitType,
      type: "semi_internal_product",
      unitCost: value.cost,
    };

    const sendExternal = {
      orderedUnit: value?.productCost,
      estimatedDeliveryDate: value?.date,
      product: product?.id,
      unitType: value.unitType,
      type: "semi_external_product",
      unitCost: value.cost,
    };

    if (purchaseData?.type == "internal") {
      if (purchaseData?.id) {
        await orderService
          .purchaseRequest({
            ...sendInternal,
            internalOperation: purchaseData?.id,
          })
          .then((res) => {
            if (res.data.code == 0) {
              Toast("success", res?.data?.message);
            } else {
              Toast("error", t("product:failedCreation"));
            }
            closeModal();
          });
      }
    } else {
      if (purchaseData?.id) {
        await orderService
          .purchaseRequest({
            ...sendExternal,
            externalOperation: purchaseData?.id,
          })
          .then((res) => {
            if (res.data.code == 0) {
              Toast("success", res?.data?.message);
            } else {
              Toast("error", t("product:failedCreation"));
            }
            closeModal();
          });
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[500px] flex flex-col "
    >
      <p className="font-normal text-sm text-secondary-600 mt-1">
        {t("product:productAndRawmaterial")}
      </p>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />

      <div className="flex flex-row w-full  mt-4 border border-secondary-300 rounded-lg pl-2 h-full ">
        <div className="w-full flex flex-col py-2">
          <div className="flex flex-row gap-x-2 items-center  ">
            {purchaseData?.process?.iconKey ? (
              <img
                src={generateFileUrl(purchaseData?.process?.iconKey)}
                className="min-w-[64px] w-[64px] h-[48px] rounded border border-secondary-300 object-cover"
              />
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}

            <div className="flex flex-col items-start">
              <p className="text-lg text-secondary-900 w-[360px] truncate font-medium">
                {purchaseData?.process?.name || "---"}
              </p>
              <p className="text-sm text-secondary-600">
                Stock ID: {product?.stockCode || "---"}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-3">
            <div className="flex flex-row items-center gap-x-1 mt-2">
              <QtyIcon3 />
              <p className="text-xs font-medium text-secondary-600">
                {purchaseData?.qualityQuantity === null
                  ? 0
                  : purchaseData?.qualityQuantity}
              </p>
              <p className="text-[10px] text-secondary-500 font-normal">
                {t("product:totalStock")}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1 mt-2">
              <DateIcon />
              <p className="text-xs font-medium text-secondary-600">
                {deliveryDate || "---"}
              </p>
              <p className="text-[10px] text-secondary-500 font-normal">
                {t("product:deliveryDate")}
              </p>
            </div>
          </div>
        </div>
        <div className="h-full flex items-start border-l rounded-bl-lg border-r-0 rounded-tr-lg  border-t-0 border-b bg-[#EAECF0] border border-[#D0D5DD] p-[3px]">
          <div className=" w-6 h-[70px] flex items-center justify-center">
            <p className="-rotate-90 text-[10px] font-normal text-secondary-600 tracking-widest">
              {t("product:hammadde")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-5 w-full gap-y-4">
        {/* <div className="flex flex-col gap-y-[6px] w-full">
          <p className="text-sm font-medium text-secondary-700">
            Tahmini Teslimat Tarihi
          </p>
          <div className="flex flex-row w-[300px] h-full">
            <EndDatePicker
              date={date}
              visible={visible}
              setDate={setDate}
              setVisible={setVisible}
              buttonClassName={"flex flex-col items-center"}
              calendarClassName={"absolute top-12 rounded-lg shadow-xl z-[600]"}
              visibleFooter
            >
              <div className="w-[498px]">
                <Button
                  onClick={() => {
                    setVisible(!visible);
                  }}
                  iconLeft={<CalendarIcon />}
                  size={"md"}
                  colorType={"secondary-gray"}
                  label={moment(date).format("DD.MM.YYYY")}
                />
              </div>
            </EndDatePicker>
          </div>
        </div> */}
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TimeInput
              label={t("product:estimatedDeliveryDate")}
              placeholder={t("buttons:selectDate")}
              theme={"product"}
              value={moment(value).format("YYYY-MM-DD")}
              disabled={false}
              onChange={(v) => {
                setValue("date", v);
              }}
            />
          )}
          name="date"
        />

        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                value={value}
                theme={"product"}
                items={priceOptions}
                label={t("product:unit")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.unitPrice ? "error" : ""}
                errorMessage={
                  errors?.unitPrice ? errors?.unitPrice?.message : ""
                }
              />
            )}
            name="unitPrice"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:cost")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.cost ? "error" : ""}
                errorMessage={errors?.cost ? errors?.cost?.message : ""}
              />
            )}
            name="cost"
          />
        </div>

        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                value={value}
                theme={"product"}
                items={units}
                label={t("product:productUnit")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.unitType ? "error" : ""}
                errorMessage={errors?.unitType ? errors?.unitType?.message : ""}
              />
            )}
            name="unitType"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:orderQuantity")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.productCost ? "error" : ""}
                errorMessage={
                  errors?.productCost ? errors?.productCost?.message : ""
                }
              />
            )}
            name="productCost"
          />
        </div>
      </div>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />

      <div className="w-[100%] flex items-center  gap-3 mt-4">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          onClick={closeModal}
        />
        <Button
          // type={"submit"}
          onClick={onSubmit}
          label={t("buttons:starts")}
          colorType={"primary-product"}
          size={"md"}
        />
      </div>
    </form>
  );
};

export default ExternalInternalRequestForm;
