import React from "react";
// import { div, StyleSheet, Text, Pressable } from "react-native";
// import { Feather, Octicons } from "@expo/vector-icons";
import CustomText from "../CustomText";
import CustomImage from "../CustomImage";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { LossIcon } from "../../assets/icons/leftMenuIcons";

const junctionProps = {
  isSetting: "",
  theme: "",
  page: "",
  status: "",
  ring: null, // default dark = "#424242" // default light = "#D0D5DD"
  time: 0,
  product: {
    name: "",
    image: "",
    code: "",
    piece: 0,
    id: "",
    onClick:null
  },
  node: {
    name: "",
    id: "",
    image: "",
    process: "",
    onClick: null,
  },
  user: {
    image: "",
    id: "",
    name: "",
    lastName: "",
    onClick: null,
  },
  onPressNode: null,
  onPressUser: null,
  onPressProduct: null,
  avatarPress: null,
};

const styles = {
  container: {
    height: 80,
    width: "100%",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
  },
  base: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
  },
  box: {
    width: "49%",
    height: 44,
    borderWidth: 1,
    borderRadius: 4,
    borderTopWidth: 4,
    alignItems: "center",
    position: "relative",
    paddingLeft: "12px",
    paddingRight: "12px",
    flexDirection: "column",
    display: "flex",
  },
  image: {
    width: 60,
    height: 45,
    borderRadius: 4,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  squareButton: {
    width: 60,
    height: 45,
    borderRadius: 4,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "dashed",
    display: "flex",
  },
  userContent: {
    position: "absolute",
    width: 50,
    height: 50,
    zIndex: 100,
    bottom: 32,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
  },
  avatar: {
    width: 50,
    height: 50,
    flexDirection: "column",
    borderRadius: 100,
    display: "flex",
  },
  circleButton: {
    width: 50,
    height: 50,
    borderRadius: 100,
    flexDirection: "column",
    borderWidth: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed",
    display: "flex",
  },
  boldText: {
    fontSize: "11px",
    flexDirection: "column",
    fontWeight: "500",
    display: "flex",
    marginTop: "-2px",
    textAlign: "center",
  },
  lightText: {
    marginTop: 9,
    flexDirection: "column",
    fontSize: "11px",
    fontWeight: "400",
    display: "flex",
    textAlign: "center",
  },
  timeSection: {
    position: "absolute",
    display: "flex",
    top: 18,
    left: 1,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  pieceSection: {
    position: "absolute",
    top: 18,
    right: 1,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
};

const Junction = ({
  props = junctionProps,
  step,
  junctionId,
  leftIcon,
  rightIcon,
  losses,
}) => {
  const { t } = useTranslation();
  return (
    <div
      //   data-tooltip-id={`${junctionId}`}
      //   data-tooltip-place={"bottom"}
      //   data-tooltip-content={`
      //  ${props?.product?.name}

      // `}
      style={styles.container}
    >
      <div style={styles.timeSection}>
        {/* <ClockIcon /> */}
        {leftIcon ? leftIcon : <ClockIcon />}
        <CustomText
          text={props?.time || "--:--"}
          style={{
            color: props?.theme === "dark" ? "#D6D6D6" : "#667085",
            fontSize: 10,
            marginLeft: 2,
            marginBottom: 1,
          }}
        />
      </div>
      <div style={{ ...styles.userContent }}>
        <UserContoller
          junctionId={junctionId}
          avatarPress={props?.avatarPress}
          onPress={props?.onPressUser}
          theme={props?.theme}
          image={props?.user?.image}
          name={props?.user?.name}
          lastName={props?.user?.lastName}
        />
      </div>
      <div style={styles.base}>
        <div
          style={{
            ...styles.box,
            borderColor: props.ring
              ? props?.ring
              : props?.theme === "dark"
              ? "#424242"
              : props?.isSetting === true
              ? "#EAAA08"
              : "#D0D5DD",
            backgroundColor: props?.theme === "dark" ? "#141414" : "#fff",
          }}
        >
          <div style={{ position: "absolute", bottom: 32, zIndex: 100 }}>
            {losses ? (
              <div className="flex px-[18px] pt-[9px] pb-3 border rounded bg-[#FEF3F2] border-[#FDA29B] min-w-[60px] max-w-[60px] min-h-[45px] max-h-[45px] text-[#D92D20]">
                <LossIcon/>
              </div>
            ) : (
              <PressedContoller
                theme={props?.theme}
                onPress={props?.onPressProduct}
                image={props?.product?.image}
                onClick={props?.product?.onClick}
              />
            )}
          </div>

          <CustomText
            text={props?.product?.code}
            numberOfLines={1}
            style={{
              ...styles.lightText,
              color: props.theme === "dark" ? "#D6D6D6" : "#667085",
            }}
          />
          <CustomText
            text={props?.product?.name}
            numberOfLines={1}
            style={{
              ...styles.boldText,
              color: props?.theme === "dark" ? "#D6D6D6" : "#344054",
            }}
          />
        </div>

        <div
          className={`z-[100] absolute ${
            props.page === "activeWork" ? "flex" : "hidden"
          }  items-center justify-center left-[47%]  w-[28px] bottom-4 h-[24px] rounded border ${
            props.status === "waiting"
              ? "bg-[#98A2B3] border-[#98A2B3]"
              : "bg-[#DC6803] border-[#DC6803]"
          }`}
        >
          {props.status === "waiting" ? <ProductControl /> : ""}
        </div>

        <div
          style={{
            ...styles.box,
            borderColor: props.ring
              ? props?.ring
              : props?.theme === "dark"
              ? "#424242"
              : props?.isSetting === true
              ? "#EAAA08"
              : "#D0D5DD",
            backgroundColor: props.theme === "dark" ? "#141414" : "#fff",
          }}
        >
          <div style={{ position: "absolute", bottom: 32, zIndex: 100 }}>
            <PressedContoller
              theme={props?.theme}
              onPress={props?.onPressNode}
              image={props?.node?.image}
              onClick={props?.node?.onClick}
            />
          </div>

          <CustomText
            text={
              step
                ? `(${step}.Op) ` + props?.node?.process
                : props?.node?.process
            }
            numberOfLines={1}
            style={{
              ...styles.lightText,
              color: props.theme === "dark" ? "#D6D6D6" : "#667085",
            }}
          />
          <CustomText
            text={props?.node?.name}
            numberOfLines={1}
            style={{
              ...styles.boldText,
              color: props.theme === "dark" ? "#D6D6D6" : "#344054",
            }}
          />
        </div>
      </div>
      <div style={styles.pieceSection}>
        <CustomText
          text={props?.product?.piece || "--"}
          style={{
            color: props?.theme === "dark" ? "#D6D6D6" : "#667085",
            fontSize: 10,
            marginRight: 2,
            marginBottom: 2,
          }}
        />

        {/* <ArchiveIcon /> */}
        {rightIcon ? rightIcon : <ArchiveIcon />}
      </div>
      <Tooltip id={`${junctionId}`} className="z-[200]" />
    </div>
  );
};

const PressedContoller = ({ onPress, image, theme, onClick }) => {
  if (!onPress) {
    return (
      <>
        {image ? (
          <CustomImage
            borderRadius={4}
            onClick={onClick}
            src={image}
            style={{
              ...styles.image,
              borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
              backgroundColor: theme === "dark" ? "#000" : "#fff",
            }}
          />
        ) : (
          <div
            className={
              "min-w-[60px] min-h-[45px] max-w-[60px] rounded max-h-[45px] bg-[#DC6803] flex border-4 border-[#DC6803] items-center justify-center font-medium text-white text-xl"
            }
          >
            {/* {text?.name.toUpperCase()} */}
          </div>
        )}
      </>
    );
  } else {
    return (
      <button
        type="button"
        onClick={onPress}
        className="outline-none"
        style={{
          ...styles.squareButton,
          borderColor: theme === "dark" ? "#424242" : "#D0D5DD",
          backgroundColor: theme === "dark" ? "#141414" : "#fff",
        }}
      >
        <PlusIcon />
      </button>
    );
  }
};
const UserContoller = ({
  onPress,
  image,
  theme,
  avatarPress,
  name,
  lastName,
  junctionId,
}) => {
  if (!onPress) {
    return (
      <div
        data-tooltip-id={`${junctionId}`}
        data-tooltip-place={"top"}
        data-tooltip-content={`
         ${name + " " + lastName}
    `}
        className={avatarPress && "cursor-pointer"}
        onClick={() => {
          if (avatarPress) {
            avatarPress();
          }
        }}
      >
        {image ? (
          <CustomImage
            src={image}
            style={{
              ...styles.avatar,
              borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
              backgroundColor: theme === "dark" ? "#000" : "#fff",
            }}
          />
        ) : (
          <NoAvatar size={50} name={name} lastName={lastName} />
        )}
      </div>
    );
  } else {
    return (
      <button
        type="button"
        onClick={onPress}
        className="outline-none"
        style={{
          ...styles.circleButton,
          borderColor: theme === "dark" ? "#424242" : "#D0D5DD",
          backgroundColor: theme === "dark" ? "#141414" : "#fff",
        }}
      >
        <PlusIcon />
      </button>
    );
  }
};

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 5.5V19.5M5 12.5H19"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArchiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#A3A3A3"
        strokeWidth="0.75"
      />
    </svg>
  );
};

const ProductControl = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3285 4.71372L13.695 3.7364L13.327 2.21297C13.1636 1.53731 12.5378 1.08687 11.8516 1.14087L9.98257 1.28553L8.91188 0.337528C8.38454 -0.112441 7.61732 -0.112441 7.08816 0.337059L6.01745 1.2855L4.14838 1.14084C3.46998 1.09325 2.83598 1.53771 2.68995 2.15753C2.65973 2.25146 2.11388 3.94818 2.14932 3.83797L0.671758 4.71321C0.0798834 5.07668 -0.157242 5.80634 0.10779 6.44815L0.745446 7.99356L0.108258 9.53853C-0.157242 10.1808 0.0803209 10.91 0.671758 11.273L2.30357 12.2435L2.67298 13.7738C2.83548 14.449 3.45851 14.8971 4.14835 14.8459L5.99726 14.7031L7.0881 15.6622C7.35226 15.8874 7.67591 16 8.00001 16C8.32363 16 8.64776 15.8874 8.91188 15.6622L10.0027 14.703L11.8516 14.8458C12.5502 14.9012 13.1645 14.4494 13.327 13.7737L13.695 12.2503L15.3285 11.273C15.9195 10.91 16.1566 10.1808 15.892 9.53853L15.2544 7.99359L15.8925 6.44771C16.1571 5.8064 15.9199 5.07672 15.3285 4.71372ZM8.00001 13.1562C5.15685 13.1562 2.84376 10.8432 2.84376 8C2.84376 5.15684 5.15685 2.84375 8.00001 2.84375C10.8432 2.84375 13.1563 5.15684 13.1563 8C13.1563 10.8432 10.8432 13.1562 8.00001 13.1562Z"
        fill="white"
      />
      <path
        d="M8 3.78125C5.67366 3.78125 3.78125 5.67366 3.78125 8C3.78125 10.3263 5.67366 12.2188 8 12.2188C10.3263 12.2188 12.2188 10.3263 12.2188 8C12.2188 5.67366 10.3263 3.78125 8 3.78125ZM10.6752 6.92516L7.86266 9.73766C7.77109 9.82922 7.65116 9.875 7.53125 9.875C7.41134 9.875 7.29138 9.82922 7.19984 9.73766L5.32484 7.86266C5.14175 7.67956 5.14175 7.38291 5.32484 7.19981C5.50794 7.01672 5.80459 7.01672 5.98769 7.19981L7.53125 8.74341L10.0123 6.26231C10.1954 6.07922 10.4921 6.07922 10.6752 6.26231C10.8583 6.44541 10.8583 6.74206 10.6752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_1100_1592)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#A3A3A3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1100_1592">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(Junction);
