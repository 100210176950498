import React, { memo, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { lossesService } from "../../../services";
import CompanyOperation from "./subComponents/CompanyOperation";
import { CostLossIcon, KwhIcon, LossTimeIcon } from "../../../assets/icons/lossIcon";
import { useOutletContext } from "react-router-dom";
import NodeOperation from "./subComponents/NodeOperation";
import OperationTypes from "./subComponents/OperationTypes";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../assets/icons/stepsIcons";
import moment from "moment";

const MemoizedCompanyOperation = memo(CompanyOperation);
const MemoizedOperationTypes = memo(OperationTypes);
const MemoizedNodeOperation = memo(NodeOperation);

const LossGraphicView = () => {
  const { t } = useTranslation();
  const [month, setMonth] = useOutletContext();

  const [firstTab, setFirstTab] = useState("total_timeDiff");
  const [secondTab, setSecondTab] = useState("total_timeDiff");
  const [thirdTab, setThirdTab] = useState("total_timeDiff");

  // const tabskey = ["total_timeDiff", "total_cost", "total_kwh"];
  const tabskey = [
    { key: "total_timeDiff", name: "Total Time Diff" },
    { key: "total_kwh", name: "Total kVAh" },
    { key: "total_cost", name: "Total kVAh Cost" },
  ];

  const [selectedLegend, setSelectedLegend] = useState("");
  const [selectedNode, setSelectedNode] = useState("");
  const [selectedOperation, setSelectedOperation] = useState("");

  const { isLoading, data, refetch } = useQuery({
    retry: 0,
    queryKey: ["loss"],
    queryFn: async () => await lossesService.getLossesChart(),
    enabled: true,
  });

  const getLossesData = async (name) => {
    setMonth(moment(name).format("M"));
    refetch();
  };

  const Tabs = useMemo(
    () => [
      {
        name: t("loss:timeLoss"),
        icon: <LossTimeIcon color={firstTab === "total_timeDiff" ? "#475467" : "#344054"} />,
        key: "total_timeDiff",
      },
      {
        name: t("loss:costLoss"),
        icon: <CostLossIcon color={firstTab === "total_cost" ? "white" : "#344054"} />,
        key: "total_cost",
      },
      {
        name: t("loss:energyLoss"),
        icon: <KwhIcon width={32} height={32} color={firstTab === "total_kwh" ? "white" : "#344054"} />,
        key: "total_kwh",
      },
    ],
    [firstTab]
  );

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-full mt-96">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="flex w-full h-[90vh] xs:h-full flex-col pb-4 scrollbar-hide overflow-y-scroll">
          {/*companyOperation*/}
          <div className="w-full h-full max-h-full mt-4">
            <MemoizedCompanyOperation
              page="loss"
              Tabs={Tabs}
              getLossesData={getLossesData}
              month={month}
              tabskey={tabskey}
              firstTab={firstTab}
              setFirstTab={setFirstTab}
              data={data?.companyOperationChart}
              setSelectedOperation={setSelectedOperation}
            />
          </div>

          {/*OperationType*/}
          <div className="w-full h-full max-h-full mt-4">
            <MemoizedOperationTypes
              page="loss"
              Tabs={Tabs}
              month={month}
              tabskey={tabskey}
              getLossesData={getLossesData}
              secondTab={secondTab}
              setSecondTab={setSecondTab}
              data={data?.typesOperationChart}
              setSelectedOperation={setSelectedOperation}
              selectedOperation={selectedOperation}
              setSelectedLegend={setSelectedLegend}
            />
          </div>

          {/*OperationNode*/}
          <div className="w-full h-full max-h-full mt-4">
            <MemoizedNodeOperation
              page="loss"
              Tabs={Tabs}
              month={month}
              tabskey={tabskey}
              thirdTab={thirdTab}
              getLossesData={getLossesData}
              setThirdTab={setThirdTab}
              data={data?.operationNodeChart}
              selectedLegend={selectedLegend}
              setSelectedLegend={setSelectedLegend}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(LossGraphicView);
