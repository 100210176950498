import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../components";
import { personalService } from "../../../services/personal.service";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "../../../assets/icons/stepsIcons";
import { CloseIcon } from "../../../assets/icons/departmentIcon";
import { Toast } from "../../../utils/toastify/toast";

const AddDepartment = ({
  setIsOpen,
  selectedDepartmant,
  GetDepartment,
  setSelectedDepartmant,
  theme,
}) => {
  const { t } = useTranslation();
  const [labels, setLabels] = useState([]);
  const [color, setColor] = useState("#7F56D9");
  const [labelName, setLabelName] = useState("");

  const handleLabelList = async () => {
    if (selectedDepartmant) {
      personalService.getLabelList(selectedDepartmant.id).then((res) => {
        setLabels(res.data);
      });
    }
  };

  const schema = yup.object({
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    labelName: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    if (selectedDepartmant) {
      await personalService
        .updateDepartment(selectedDepartmant.id, data)
        .then((res) => {
          if (res.data.status == 1) {
            GetDepartment();
            setIsOpen(false);
            Toast("success", t("addDepartment:updatedDepartment"));
          } else {
            Toast("error", t("product:errors"));
          }
        })
        .catch((err) => {});
    } else {
      const send = { ...data, labels };
      await personalService
        .createDepartment(send)
        .then((res) => {
          if (res.data.status == 1) {
            GetDepartment();
            setIsOpen(false);
            Toast("success", t("addDepartment:createdDepartment"));
          } else if (res.data.status == 2) {
            setIsOpen(false);
            Toast("error", t("addDepartment:noLimit"));
          } else {
            Toast("error", t("product:errors"));
          }
        })
        .catch((err) => {});
    }
  };

  const addNewLabel = async () => {
    const data = {
      departmentId: selectedDepartmant.id,
      name: labelName,
      color: color,
    };
    await personalService.addNewLabelToDepartment(data).then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        handleLabelList();
      }
    });
  };

  const deleteLabel = async (id) => {
    await personalService.deleteLabel(id);
    handleLabelList();
  };

  const handleLabel = (label) => {
    const filter = labels.filter((u) => {
      return u?.name == label?.name;
    });
    if (filter.length > 0) {
      setLabels(labels.filter((item) => item?.name !== label?.name));
    } else {
      setLabels([...labels, label]);
    }
  };

  useEffect(() => {
    handleLabelList();
    if (selectedDepartmant) {
      setValue("name", selectedDepartmant?.name);
    }
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col item-start w-[400px] xs:w-[300px] h-full "
      >
        <div className="flex flex-col py-6 gap-4">
          <div className="w-full">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("department:departmentName")}
                  width={"100%"}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  validate={errors.name ? "error" : ""}
                  errorMessage={errors.name ? errors.name.message : ""}
                />
              )}
              name="name"
            />
          </div>
          <div className="flex flex-row items-center gap-4">
            <div className="flex flex-col w-full">
              <span className="text-sm mb-1.5 pl-0  w-full text-secondary-700 dark:text-darkTrue-200 font-medium">
                {t("chat:addLabel")}
              </span>
              <div className="flex flex-row items-center gap-4">
                <div
                  className={`flex ${
                    theme === "person"
                      ? "focus-within:border-teal-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#C4EDE6] "
                      : theme === "product"
                      ? "focus-within:border-warning-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#FDE3C1] "
                      : theme === "embedded"
                      ? "focus-within:border-grayBlue-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#D2D6E8] "
                      : "focus-within:border-primary-300 focus-within:outline  focus-within:outline-4 focus-within:outline-[#E8DFFB] "
                  } flex-row items-center border rounded-lg  border-secondary-300 grow h-10 overflow-hidden pr-2 dark:bg-[#0F0F0F] dark:border-[#424242]`}
                >
                  <input
                    maxLength={20}
                    value={labelName}
                    onChange={({ target }) => setLabelName(target?.value)}
                    type="text"
                    style={{
                      border: "none!important",
                      outline: "none",
                    }}
                    className="focus:border-none focus:ring-0 h-full pl-2 grow border-none dark:bg-transparent dark:text-[#FAFAFA]"
                  />
                  <div
                    className="w-6 h-6 rounded-full shadow cursor-pointer"
                    style={{ backgroundColor: color ? color : "#000" }}
                  >
                    <input
                      type="color"
                      value={color}
                      onChange={({ target }) => setColor(target?.value)}
                      className="focus:border-transparent focus:ring-0 border-none opacity-0"
                    />
                  </div>
                </div>

                <div className="w-[12%]">
                  <Button
                    onClick={() => {
                      if (selectedDepartmant) {
                        if (labelName) {
                          addNewLabel();
                          setLabelName("");
                        } else {
                          Toast("error", "Label name is empty.");
                        }
                      } else {
                        if (labelName) {
                          handleLabel({
                            name: labelName,
                            color: color ? color : "#000",
                          });
                          setLabelName("");
                        } else {
                          Toast("error", "Label name is empty.");
                        }
                      }
                    }}
                    size={"sm"}
                    colorType={"secondary-gray"}
                    iconLeft={<PlusIcon />}
                  />
                </div>
              </div>
              <div className="w-full h-6 mt-4 overflow-x-auto flex scrollbar-hide">
                {labels?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        if (selectedDepartmant) {
                          deleteLabel(item?.id);
                        } else {
                          handleLabel(item);
                        }
                      }}
                      className="flex cursor-pointer border"
                      style={{
                        height: 24,
                        borderRadius: 6,
                        backgroundColor: item?.color,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingLeft: 6,
                        paddingRight: 4,
                        marginRight: 12,
                        borderColor: item?.color,
                      }}
                    >
                      <p
                        className="truncate"
                        style={{
                          fontSize: 12,
                          fontWeight: "500",
                          color:
                            localStorage.color_theme === "dark"
                              ? "#FFFFFF"
                              : "#fff",
                          marginRight: 8,
                        }}
                      >
                        {item?.name}
                      </p>
                      <div className="w-4 flex text-[#fff] items-end justify-center">
                        <CloseIcon width={12} height={12} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center w-full gap-3">
          <Button
            onClick={() => {
              setIsOpen(false);
              setSelectedDepartmant(null);
            }}
            colorType={"secondary-gray"}
            size={"md"}
            label={t("buttons:stop")}
          />
          <Button
            colorType={theme || "primary-machine"}
            size={"md"}
            label={t("buttons:save")}
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

export default AddDepartment;
