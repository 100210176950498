import React, { memo, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import useWindowDimensions from "../../../hooks/useWindowDimension";

const LossesPieChart = ({ data, page, setSelectedLegend }) => {
  const { width, height } = useWindowDimensions();

  const onEvents = {
    legendselectchanged: function (params) {
      data?.map((row) => {
        row?.name == params?.name && setSelectedLegend(row?.id);
      });
    },
  };

  let items = data?.map((row) => row?.value);
  let total = items?.reduce((a, b) => a + b, 0);
  const heightValue = useMemo(() => {
    return width < 500
      ? page && page === "loss"
        ? height - 200
        : (height - 100) / 3
      : page && page === "loss"
      ? (width >= 1024 && width < 1200) || width < 600
        ? (height - 350) / 2
        : (height - 220) / 2
      : (height - 200) / 3;
  }, [width, height, page]);

  const leftValue = useMemo(() => {
    return width < 500 ? 25 : 5;
  }, [width]);

  const centerValue = useMemo(() => {
    return width < 500
      ? ["50%", "60%"]
      : (width >= 1024 && width < 1380) || width < 600
      ? ["77%", "40%"]
      : ["77%", "40%"];
  }, [width]);

  const option = useMemo(() => {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} ({d}%)",
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        left: leftValue,
        top: width < 500 ? 0 : 35,
        bottom: 20,
        show: data?.filter((a) => a?.value > 0)?.length > 0 ? true : false,
        data: data?.sort((a, b) => b?.value - a.value),

        formatter: function (name) {
          const value = data
            ?.filter((a) => a.name === name)
            ?.map((a) => a.value)[0];
          const percentage = ((value / total) * 100).toFixed(2);

          const maxLength =
            (width >= 1024 && width < 1380) ||
            (width >= 500 && width < 570) ||
            (width >= 370 && width < 400)
              ? 10
              : 35;
          const truncatedName =
            name.length > maxLength ? name.slice(0, maxLength) + "..." : name;

          return `{name|${truncatedName}}  %{total|${percentage} (${value})}`;
        },

        textStyle: {
          rich: {
            name: {
              fontSize: 12,
              fontWeight: "500",
              align: "left",
              color: "#344054",
            },
            total: {
              fontSize: 12,
              fontWeight: "400",
              color: "#667085",
            },
          },
        },
      },

      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["30%", "67%"],
          center: centerValue,
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          itemEvent: true,

          label: {
            formatter: "%{d}",
            position: "inside",
            fontSize: 12,
            fontWeight: "600",
            triggerEvent: true,
          },

          data: data,
        },
      ],
    };
  });

  return (
    <div className="w-full h-full min-h-full">
      <ReactECharts
        onEvents={onEvents}
        option={option}
        style={{
          width: width < 500 ? "87%" : "100%",
          height: heightValue,
        }}
      />
    </div>
  );
};

export default memo(LossesPieChart);
