import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { customerService } from "../../../services/customer.service";
import { useTranslation } from "react-i18next";
import Selector from "../../../components/inputs/Selector";
import { Button, Input } from "../../../components";
import { orderService } from "../../../services/order.service";
import { Toast } from "../../../utils/toastify/toast";

const EditOrderModal = ({ closeModal, orderId, refetch }) => {
  const { t } = useTranslation();
  const [orderCode, setOrderCode] = useState("");
  const [customerOrderNo, setCustomerOrderNo] = useState("");
  const [customer, setCustomer] = useState("");
  const customerList = useQuery({
    queryKey: ["customer-list"],
    queryFn: async () => await customerService.getCustomerList(),
    retry: 0,
  });

  const handleCustomerInfo = async () => {
    const { data } = await orderService.getCustomerInfo(orderId);
    setCustomer(data?.customer);
    setOrderCode(data?.orderNo);
    setCustomerOrderNo(data?.customerOrderNo);
  };

  const onSubmit = async () => {
    const data = {
      customer: customer?.id,
      orderNo: orderCode,
      customerOrderNo: customerOrderNo,
    };
    await orderService.updateOrder(orderId, data).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res?.data?.message);
        closeModal();
        refetch();
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  useEffect(() => {
    if (orderId) {
      handleCustomerInfo();
    }
  }, [orderId]);
  return (
    <div className="flex flex-col gap-4 mt-5">
      <Selector
        value={customer?.id}
        label={t("product:customerCompany")}
        items={customerList?.data?.data?.map((item) => {
          return { label: item?.name, value: item?.id };
        })}
        theme={"product"}
        placeholder={t("product:selectCustomer")}
        onChange={(e) => {
          setCustomer(customerList?.data?.data?.find((item) => item?.id === e));
        }}
      />
      <Input
        value={orderCode}
        label={t("product:orderCode")}
        theme={"product"}
        onChange={(e) => {
          setOrderCode(e.target.value);
        }}
      />
      <Input
        value={customerOrderNo}
        label={t("product:customerOrderCode")}
        theme={"product"}
        onChange={(e) => {
          setCustomerOrderNo(e.target.value);
        }}
      />
      <div className="flex items-center w-full gap-x-3 mt-3">
        <Button size={"md"} label={t("buttons:stop")} colorType={"secondary-gray"} onClick={closeModal} />
        <Button size={"md"} label={t("buttons:edit")} colorType={"primary-product"} onClick={onSubmit} />
      </div>
    </div>
  );
};

export default EditOrderModal;
