import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import moment from "moment";

import { useTranslation } from "react-i18next";

import { useChannel, useEvent } from "@harelpls/use-pusher";
import { useStore } from "../../../hooks/useStores";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { Loading } from "../embeddedServer/svg/EmbeddedServerIcons";
import WorkCountCard from "../components/WorkCountCard";
import DetailPageDateSelector from "../Twin/components/DetailPageDateSelector";
import { reportService } from "../../../services/report.service";
import DetailHeader from "../components/DetailHeader";
import ManuelJunction from "./ManuelJunction";
import AddManuelJunctionTimeline from "./components/timelineComponents/AddManuelJunctionTimeline";

const AddManuelJunction = () => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlData = useParams();
  let { dates, manuelVeriables } = useParams();
  const pattern = /^(.*?)-(.+)$/;
  const match = urlData.deviceId?.match(pattern);
  //bottom console
  const [isOpen, setIsOpen] = useState(false);
  const [defaultStartForm, setDefaultStartForm] = useState();
  const [defaultEndForm, setDefaultEndForm] = useState();
  const [leftTab, setLeftTab] = useState("product");
  const [rightTab, setRightTab] = useState("time");
  const [workType, setWorkType] = useState("work");

  const [workCount, setWorkCount] = useState();

  const [junctionData, setJunctionData] = useState();
  const [isOpenQr, setIsOpenQr] = useState(false);

  const [customLoading, setCustomLoading] = useState(false);
  const [dateChangeCount, setDateChangeCount] = useState(0);

  const [start, end] = manuelVeriables.split("+");

  //Manuel Timeline

  const [selectedDates, setSelectedDates] = useState({ start: "", end: "" });

  const [breakTimes, setBreakTimes] = useState([]);
  const [newBreak, setNewBreak] = useState({ start: "", end: "" });

  //New Timeline
  const [startTime, setStartTime] = useState(null);
  const [defaultStartTime, setDefaultStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [defaultEndTime, setDefaultEndTime] = useState(null);
  const [selectedJunction, setSelectedJunction] = useState("");
  const [groupedDatas, setGroupedDatas] = useState([]);

  //values
  const [shiftValues, setShiftValues] = useState([]);
  const [timelineValues, setTimelineValues] = useState([]);

  const [date, setDate] = useState(timeFormatter(moment().format("YYYY-MM-DD"), auth?.user?.company?.timeZone).formatted);
  const [warning, setWarning] = useState(false);
  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["daily"],
    queryFn: async () =>
      reportService.getDailyNodeData({
        nodeId: match[2],
        date: moment(timeFormatter(dates, auth?.user?.company?.timeZone).formatted).format("YYYY-MM-DD"),
      }),
  });

  useEffect(() => {
    setWarning(data?.equipmentData?.warning);
    setWorkCount(data?.workCountData || []);
  }, [data]);

  useEffect(() => {
    setDate(dates);
  }, []);

  useEffect(() => {
    if (!selectedJunction) {
      setWorkCount(data?.workCountData || []);
    }
  }, [selectedJunction]);

  useEffect(() => {
    refetch();
  }, [dates]);

  const [stepIndexState, setStepIndexState] = useState(0);
  const [runState, setRunState] = useState(true);

  useEffect(() => {
    if (isOpenQr) {
      const timer = setTimeout(() => {
        setStepIndexState(1);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [isOpenQr, setStepIndexState]);

  useEffect(() => {
    if (stepIndexState === 4) {
      setIsOpenQr(false);
    } else if (stepIndexState === 1) {
      setIsOpenQr(true);
    }
  }, [stepIndexState, setIsOpenQr]);

  const getAllShiftDatas = async () => {
    await setGroupedDatas(data?.shiftlyData ? data?.shiftlyData : data?.shiftData ? data?.shiftData : []);
  };
  const companyID = auth?.user?.company?.id;
  const channel = useChannel(companyID);
  useEvent(channel, "update-node", (socketEvent) => {
    if (socketEvent?.node?.deviceId == match[1]) {
      setWarning(socketEvent?.status == 11 ? true : false);
    }
  });

  const defaultTimer = async () => {
    await getAllShiftDatas();
    (await data) && setStartTime(moment(start)?.valueOf());
    await setDefaultStartTime(moment(start).subtract(15, "minutes")?.valueOf());
    await setEndTime(moment(end)?.valueOf() + 86400000);
    await setDefaultEndTime(moment(end).add(15, "minutes").valueOf());
  };

  useEffect(() => {
    defaultTimer();
  }, [date, data]);

  useEffect(() => {
    setDateChangeCount((prevCount) => prevCount + 1);
  }, [dates]);

  return (
    <>
      <div className="w-full h-fit overflow-hidden flex flex-col mt-2 relative">
        <DetailHeader
          dateChangeCount={dateChangeCount}
          t={t}
          match={match}
          warning={warning}
          refetch={refetch}
          isLoading={isLoading || isFetching}
          setWarning={setWarning}
          junctionData={junctionData}
          equipmentData={data?.equipmentData}
          isWarning={data?.equipmentData?.warning}
          name={data?.equipmentData?.equipmentName}
          process={data?.equipmentData?.processName}
          image={data?.equipmentData?.equipmentImage}
          setRunState={setRunState}
          setIsOpenQr={setIsOpenQr}
          isOpenQr={isOpenQr}
          setStepIndexState={setStepIndexState}
          runState={runState}
        />

        <div className="flex-1 w-full flex flex-col overflow-hidden scrollbar-hide pb-8 pt-3 overflow-x-hidden">
          {isLoading || isFetching ? (
            <div className="w-full min-h-[270px] rounded-lg shadow border border-secondary-300 bg-gray-200 animate-pulse flex items-center justify-center">
              <Loading size={38} color={"#475467"} secondary={"#EAECF0"} />
            </div>
          ) : (
            <div className="step-12">
              <AddManuelJunctionTimeline
                refetch={refetch}
                timelineChart={data?.timelineData}
                junctionData={junctionData}
                defaultStartTime={defaultStartTime}
                defaultEndTime={defaultEndTime}
                startTime={startTime}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                endTime={endTime}
                selectedJunction={selectedJunction}
                setSelectedJunction={setSelectedJunction}
                shiftValues={shiftValues}
                setShiftValues={setShiftValues}
                timelineValues={timelineValues}
                setTimelineValues={setTimelineValues}
                groupedDatas={groupedDatas}
                date={date}
                PastJunctionData={() => {}}
                //bottom console
                setIsOpen={setIsOpen}
                setDefaultEndForm={setDefaultEndForm}
                setDefaultStartForm={setDefaultStartForm}
                defaultEndForm={defaultEndForm}
                defaultStartForm={defaultStartForm}
                //selectedDates
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}
                rightTab={rightTab}
                //break
                setNewBreak={setNewBreak}
                newBreak={newBreak}
                breakTimes={breakTimes}
                setBreakTimes={setBreakTimes}
                //
                workType={workType}
              />

              {isLoading || isFetching ? (
                <div className="w-full h-[52.81px] rounded-lg bg-gray-200 animate-pulse mt-4" />
              ) : (
                <span className="step-15">
                  <WorkCountCard workCount={workCount} metadataId={data?.equipmentData?.metadataId} refetch={refetch} />
                </span>
              )}

              <ManuelJunction
                process={data?.processId}
                setIsOpen={setIsOpen}
                equipmentData={data?.equipmentData}
                setDefaultEndForm={setDefaultEndForm}
                setDefaultStartForm={setDefaultStartForm}
                defaultStartForm={defaultStartTime}
                defaultEndForm={defaultEndTime}
                setLeftTab={setLeftTab}
                leftTab={leftTab}
                rightTab={rightTab}
                setRightTab={setRightTab}
                workType={workType}
                setWorkType={setWorkType}
                //selectedDates
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}
                //break
                setBreakTimes={setBreakTimes}
                breakTimes={breakTimes}
                setNewBreak={setNewBreak}
                newBreak={newBreak}
                //url data
                match={match}
                dates={dates}
                dateChangeCount={dateChangeCount}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddManuelJunction;
