import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TabModal } from "../../../steps/process";
import GroupInfo from "../pages/GroupInfo";
import GroupMembers from "../pages/GroupMembers";
import { useStore } from "../../../hooks/useStores";
import { personalService } from "../../../services/personal.service";
import { chatService } from "../../../services/chat.service";
import { Toast } from "../../../utils/toastify/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { authStore } from "../../../stores/auth.store";
import moment from "moment";

const NewGroup = ({
  setIsNewGroupModalOpen,
  groupId,
  setIsOpen,
  selectedGroup,
  refetch = () => { },
  newRefetch = () => { },
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState("info");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [adminUser, setAdminUser] = useState([]);
  const { chat, auth } = useStore();
  const [departments, setDepartments] = useState([]);
  const [departmans, setDepartmans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState();
  const schema = yup.object({
    image: yup.string(),
    title: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    endDate: yup.date().required(`${t("validation:empty")}`),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const selectDepartmentUsers = (selected) => {
  //   const findToDepartment = departments?.filter(
  //     (item) => item.departmentId === selected
  //   );
  //   if (selectedGroup) {
  //     setSelectedUsers([...selectedUsers, ...findToDepartment[0]?.users]);
  //   } else {
  //     setSelectedUsers(findToDepartment[0]?.users);
  //   }
  // };

  const selectDepartmentUsers = (selected) => {
    const findToDepartment = departmans?.filter(
      (item) => item.departmentId === selected
    );
    if (selectedGroup) {
      setSelectedUsers([...selectedUsers, ...findToDepartment[0]?.users]);
    } else {
      setSelectedUsers(findToDepartment[0]?.users);
    }
  };

  const getDepartmentToUser = async () => {
    try {
      const { data } = await personalService.getDepartmentToUser();
      setLoadings(true);
      setData(data);
      setLoadings(false);
      setDepartmans(data);
      setDepartments(
        data.map((e) => {
          return {
            label: e.departmentName,
            value: e.departmentId,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const triggerUser = (user) => {
    const filter = selectedUsers?.filter((u) => {
      return u.id == user.id;
    });
    if (filter?.length) {
      setSelectedUsers(selectedUsers?.filter((item) => item?.id !== user?.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
    selectedUsers.includes(user);
  };

  const triggerAdmin = (user) => {
    const filter = adminUser?.filter((a) => {
      return a?.id == user?.id;
    });
    if (filter?.length > 0) {
      setAdminUser(adminUser?.filter((item) => item?.id != user?.id));
    } else {
      setAdminUser([...adminUser, user]);
    }
  };

  const selectAllPress = (users) => {
    const updatedSelectedUsers = [...selectedUsers];
    // Her kullanıcıyı kontrol edin
    users.forEach((user) => {
      // Kullanıcı zaten seçiliyse, seçimi kaldırın; değilse ekleyin
      const index = updatedSelectedUsers.findIndex(
        (selectedUser) => selectedUser.id === user.id
      );
      if (index !== -1) {
        updatedSelectedUsers.splice(index, 1);
      } else {
        updatedSelectedUsers.push(user);
      }
    });
    // Güncellenmiş kullanıcıları set edin
    setSelectedUsers(updatedSelectedUsers);
  };

  const labelsArray = chat?.labelsArray || [];
  const currentGroupLabels = chat?.currentGroupChat?.labels || [];
  const onSubmit = async (formData) => {
    setLoading(true);

    const isCurrentUserSelected = selectedUsers.some(
      (user) => user.id === authStore.user.id
    );

    if (!isCurrentUserSelected) {
      setSelectedUsers([...selectedUsers, authStore.user]);
    }

    if (selectedGroup) {
      const editData = {
        ...formData,
        authorizedParticipants: [
          ...adminUser
            .filter((d) => {
              if (selectedUsers?.find((f) => f?.id == d?.id)) {
                return d;
              }
            })
            .map((item) => item?.id),
          ...(isCurrentUserSelected ? [] : [authStore.user.id]),
        ],
        participants: [
          ...selectedUsers
            ?.filter((d) => d?.id)
            ?.map((d) => {
              return {
                userId: d?.id,
                isQuite: d?.isQuite || false,
              };
            }),
          ...(isCurrentUserSelected
            ? []
            : [{ userId: authStore.user?.id, isQuite: false }]),
        ],
        image: formData?.image,
        title: formData?.title,
        endDate: formData?.endDate,
        departmentId:
          selectedDepartments === "BLANK_DEPARTMENT"
            ? null
            : selectedDepartments,
        labels: Array(...labelsArray, ...currentGroupLabels),
      };
      await chatService
        .updateGroup(selectedGroup?.id, editData)
        .then(async (res) => {
          if (res.data.code === 0) {
            Toast("success", res?.data?.message);
          } else {
            Toast("error", "grup updatelenmedi");
          }
          setIsOpen(false);
          refetch();
          newRefetch();
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    } else {
      const sendLastData = {
        ...formData,
        authorizedParticipants: adminUser
          .filter((d) => {
            if (selectedUsers?.find((f) => f.id == d.id)) {
              return d;
            }
          })
          .map((item) => item.id),
        participants: selectedUsers?.map((d) => {
          return { userId: d?.id };
        }),
        endDate: formData?.endDate,
        // labels: chat?.labelsArray,
        departmentId:
          selectedDepartments === "BLANK_DEPARTMENT"
            ? null
            : selectedDepartments,
      };
      setLoading(true);
      await chatService
        .getNewGroup(sendLastData)
        .then(async (res) => {
          Toast("success", "Grup oluşturuldu.");
          setIsNewGroupModalOpen(false);
          if (pathname.includes("/app/person/task")) {
            navigate(
              `/app/person/task/${res?.data?.conversation?.conversationId}`
            );
          }
          if (pathname.includes("/app/person/message")) {
            navigate(
              `/app/person/message/${res?.data?.conversation?.conversationId}`
            );
          }
          refetch();
          newRefetch();
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      setSelectedDepartments(String(selectedGroup?.departmentId));
      setValue("image", selectedGroup?.image);
      setValue("title", selectedGroup?.title);
      setValue("endDate", new Date(moment(selectedGroup?.endDate)));
      setSelectedUsers(selectedGroup?.participants);
      setAdminUser(selectedGroup?.authorizedParticipants);
    } else {
      setValue("image", "");
      setValue("title", "");
      setValue("endDate", new Date());
      setSelectedUsers([]);
      setAdminUser([]);
    }
  }, []);

  useEffect(() => {
    getDepartmentToUser();
  }, []);

  return (
    <form className="w-full scrollbar-hide " onSubmit={handleSubmit(onSubmit)}>
      <TabModal
        width={"1/2"}
        theme={"person"}
        tabData={[
          {
            name: t("person:groupInfo"),
            component: (
              <GroupInfo
                departments={departments}
                t={t}
                errors={errors}
                departmans={departmans}
                control={control}
                setValue={setValue}
                setSelectedTab={setSelectedTab}
                setSelectedDepartments={setSelectedDepartments}
                selectedDepartments={selectedDepartments}
                selectDepartmentUsers={selectDepartmentUsers}
              />
            ),
            value: "info",
          },
          {
            name: t("person:groupMembers"),
            component: (
              <GroupMembers
                triggerAdmin={triggerAdmin}
                triggerUser={triggerUser}
                chat={chat}
                data={data}
                t={t}
                selectedUsers={selectedUsers}
                adminUser={adminUser}
                selectAllPress={selectAllPress}
                auth={auth}
                setSelectedTab={setSelectedTab}
                loadings={loadings}
                onSubmit={onSubmit}
                loading={loading}
                selectedDepartments={selectedDepartments}
              />
            ),
            value: "member",
          },
        ]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </form>
  );
};

export default NewGroup;
