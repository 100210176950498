import React, { useEffect, useMemo, useState } from "react";
import { QualityHeader } from "../components";
import moment from "moment";
import QualityMain from "./QualityMain";
import { useStore } from "../../../../hooks/useStores";
import { useNavigate } from "react-router-dom";

const QualityDashboard = () => {
  const { auth } = useStore();
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const navigate = useNavigate();

   useEffect(() => {
      if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
      } else if (
        auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325"
      ) {
      } else if (
        auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592"
      ) {
      } else if (
        auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4"
      ) {
      } else if (
        auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706"
      ) {
      } else if (
        auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f"
      ) {
      } else if (
        auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35"
      ) {
      } else if (
        auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630"
      ) {
      } else if (
        auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689"
      ) {
      } else if (
        auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a"
      ) {
      } else if (
        auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3"
      ) {
      } else if (
        auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513"
      ) {
      } else if (
        auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86"
      ) {
      } else if (
        auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9"
      ) {
      } else if (
        auth?.user?.company?.id == "1dcc62be-318a-4c4d-9e41-be3b69241c25"
      ) {
      } else if (
        auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706"
      ) {
      } else if (
        auth?.user?.company?.id == "10826fc4-bf01-4164-acf0-72bb213e88d3"
      ) {
      } else if (auth?.user?.company?.id == "10826fc4-bf01-4164-acf0-72bb213e88d3") {
        } else if (auth?.user?.company?.id == "b0d47c1a-e5dd-4ec1-a655-424af970bbfe") {
      } else {
        navigate("/app/product/verysoon");
      }
    }, []);

  const MemoizedQualityMain = useMemo(
    () => <QualityMain date={date} />,
    [date]
  );

  return (
    <div className="flex w-full h-full flex-col">
      <QualityHeader date={date} setDate={setDate} />
      {/* <QualityMain date={date} /> */}
      {MemoizedQualityMain}
    </div>
  );
};

export default QualityDashboard;
