import React, { useState } from "react";
import { NoEquIcon, ProductDetailIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../CustomImage";
import {
  BreakIconNewIcon,
  ClockIcon,
  ContinueNewIcon,
  DeleteIcon,
  DotsVerticalIcon,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  FinishJunctionIcon,
  FlexProductIcon,
  GreenAvgIcon,
  PersonnelDetailIcon,
  PieceIcon,
  RedAvgIcon,
  TargetIcon,
} from "../card-icons";
import { useTranslation } from "react-i18next";
import NoAvatar from "../../avatar/NoAvatar";
import AlertModal from "../../alert-Modal/Alert-Modal";
import JunctionUpdate from "../../modal/JunctionUpdate";
import CustomModal from "../../modal/CustomModal";
import { useNavigate, useParams } from "react-router-dom";
import { junctionService } from "../../../services/junction.service";
import { Toast } from "../../../utils/toastify/toast";
import { secondToHoursAndMinute2 } from "../../../utils/secondToHoursAndMinute";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import IsBreakModal from "../../modal/IsBreakModal";
import IsContiunueModal from "../../modal/IsContiunueModal";
import { useStore } from "../../../hooks/useStores";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import Badgets from "../../buttons/Badgets";
const OperationDetailActiveCard = ({ refetch, operation, data, isBreaks = false, setJunctionType, setFinishJunctionId, setIsOpenJunctionPiece, setProductType, equipmentData }) => {
  const { t } = useTranslation();
  let { dates } = useParams();
  const navigate = useNavigate();
  const { auth } = useStore();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isJunction, setIsJunction] = useState(false);
  const [isBreak, setIsBreak] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const menuOptions = [
    {
      label: t("product:personnelDetail"),
      value: "personnelDetail",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: isBreaks === true ? t("product:takeBreak") : t("password:continue"),
      value: "breaks",
      icon: isBreaks === true ? <BreakIconNewIcon /> : <ContinueNewIcon />,
    },
    {
      label: t("product:editJunction"),
      value: "editJunction",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:finishJuction"),
      value: "finishJunction",
      icon: <FinishJunctionIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const onDeleted = async () => {
    await junctionService.deleteJunction(data?.id, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data?.id);
      } else {
        Toast("error", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  const convertSeconds = (seconds) => {
    if (isNaN(seconds)) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };


  const totalEstimatedTime = data?.productType === "flex" ? data?.flexHeader?.estimatedTime : data?.header?.internalOperation?.estimatedTime;

  const formattedEstimatedOpTime =
    data?.type === "setup" ? convertSeconds(data?.productType === "flex" ? data?.flexHeader?.estimatedSettingsTime * 60 : data?.header?.internalOperation?.estimatedSettingsTime * 60) : convertSeconds(totalEstimatedTime);

  const estimatedTimeGroup = data?.header
    ? data?.header?.internalOperation?.plannableNodes?.find((element) =>
      equipmentData?.some((equipment) => equipment?.nodeId === element?.id)
    )
    : data?.flexHeader?.plannableNodes
      ? data?.flexHeader?.plannableNodes?.find((element) =>
        equipmentData?.some((equipment) => equipment?.nodeId === element?.id)
      )
      : data?.flexHeader?.estimatedTime;





  return (
    <>
      <div className="cursor-pointer flex flex-row items-center gap-x-3 w-full relative">
        <div className="flex items-center flex-col gap-y-1 justify-center border border-secondary-300 rounded-t-[4px] rounded-b-lg p-1 w-[88px] min-w-[88px] bg-[#F9FAFB]">
          <p className="text-xs w-[75px] truncate text-center font-semibold text-secondary-700">{data?.station?.metadata?.orderNumber || "--"}</p>
          {data?.station?.metadata.image ? (
            <div
              onClick={() => navigate(`/app/machine/digital-twin/equipment-details/${data?.station?.deviceId}-${data?.station?.id}/${dates}`)}
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"right"}
              data-tooltip-content={`
            ${data?.station?.metadata?.brand + " " + data?.station?.metadata?.model} 
            `}
            >
              <CustomImage
                borderRadius={2}
                src={data?.station?.metadata.image}
                style={{
                  width: 80,
                  height: 60,
                  borderRadius: 4,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div
              onClick={() => navigate(`/app/machine/digital-twin/equipment-details/${data?.station?.deviceId}-${data?.station?.id}/${dates}`)}
              className="w-[80px] min-w-[80px] h-[60px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
            >
              <NoEquIcon />
            </div>
          )}
        </div>
        <div
          onClick={() => {
            setJunctionType(data?.externalOperation ? "external" : "internal");
            setFinishJunctionId(data?.id);
            setProductType(data?.productType)
            setIsOpenJunctionPiece(true);
          }}
          className={`flex w-full flex-col border border-t-4 cursor-pointer ${data?.type == "work" ? "border-t-success-500 " : data?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
            } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
        >
          <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
            <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
              {data?.productType === "flex" ? (
                <>
                  {data?.flexProduct?.image ? (
                    <div
                      data-tooltip-id={`${data?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
                ${data?.flexProduct?.name} 
                `}
                    >
                      <CustomImage
                        borderRadius={2}
                        src={data?.flexProduct?.image}
                        style={{
                          width: 64,
                          minWidth: 64,
                          height: 48,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      data-tooltip-id={`${data?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
                ${data?.flexProduct?.name} 
                `}
                      className="w-[64px] min-w-[64px] h-[48px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl cursor-pointer"
                    >
                      {data?.flexProduct?.name?.slice(0, 2)?.toUpperCase()}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {data?.job?.product?.image ? (
                    <div
                      data-tooltip-id={`${data?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
                ${data?.job?.product?.name} 
                `}
                    >
                      <CustomImage
                        borderRadius={2}
                        src={data?.job?.product?.image}
                        style={{
                          width: 64,
                          minWidth: 64,
                          height: 48,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      data-tooltip-id={`${data?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
                ${data?.job?.product?.name} 
                `}
                      className="w-[64px] min-w-[64px] h-[48px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl cursor-pointer"
                    >
                      {data?.job?.product?.name?.slice(0, 2)?.toUpperCase()}
                    </div>
                  )}
                </>
              )}

              <div className="flex flex-col items-start gap-y-[6px]">
                <div className="flex flex-row items-center gap-x-1 w-full">
                  {data?.productType === "flex" ? (
                    <>
                      <FlexProductIcon />
                      <p className="text-sm font-semibold text-secondary-900 truncate w-[215px]">{data?.flexProduct?.name || "--"}</p>
                    </>
                  ) : (
                    <p className="text-sm font-semibold text-secondary-900 truncate w-[230px]">{data?.job?.product?.name || "--"}</p>
                  )}
                </div>

                <div className="flex flex-row items-center gap-x-2">
                  {data?.productType === "flex" ? (
                    <p className="text-xs font-medium text-secondary-600 w-[230px]  truncate">
                      {t("product:stockCode")}:{data?.flexProduct?.stockCode || "-"}
                    </p>
                  ) : (
                    <p className="text-xs font-medium text-secondary-600 w-[230px] truncate">
                      {t("product:stockCode")}:{data?.job?.product?.stockCode || "-"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {data?.user?.avatar ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`
            ${data?.user?.name}  ${data?.user?.lastName} 
            `}
                className="cursor-pointer ml-auto"
              >
                <CustomImage
                  src={data?.user?.avatar}
                  style={{
                    width: 48,
                    height: 48,
                    minWidth: 48,
                    minHeight: 48,
                    flexDirection: "column",
                    borderRadius: 100,
                    display: "flex",
                    border: "1px solid #0000001A",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`
            ${data?.user?.name} ${data?.user?.lastName} 
            `}
                className="cursor-pointer ml-auto"
              >
                <NoAvatar size={48} fontSize={22} name={data?.user?.name} lastName={data?.user?.lastName} color={"#B54708"} />
              </div>
            )}
          </div>
          <div className={`flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full ${data?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"}   border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg`}>
            <div className="flex flex-row items-center">
              <TargetIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">
                {data?.header?.internalOperation?.plannableNodes?.length == 0 ? (
                  <>
                    {formattedEstimatedOpTime || "--"}
                  </>
                ) : (
                  <>
                    {
                      data?.type === "setup"
                        ? estimatedTimeGroup?.estimatedSettingsTime
                          ? `${Math.floor(estimatedTimeGroup?.estimatedSettingsTime / 60 || 0)} dk ${(estimatedTimeGroup?.estimatedSettingsTime % 60 || 0).toFixed(0)} sn` || ""
                          : `${Math.floor(estimatedTimeGroup / 60 || 0)} dk ${(estimatedTimeGroup % 60 || 0).toFixed(0)} sn` || ""
                        : estimatedTimeGroup?.estimatedTime
                          ? `${Math.floor(estimatedTimeGroup?.estimatedTime / 60 || 0)} dk ${(estimatedTimeGroup?.estimatedTime % 60 || 0).toFixed(0)} sn` || ""
                          : `${Math.floor(estimatedTimeGroup / 60 || 0)} dk ${(estimatedTimeGroup % 60 || 0).toFixed(0)} sn` || ""
                    }
                  </>
                )}

              </p>
              <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMinActualss")}</p>
            </div>
            <div className="flex flex-row items-center">
              <ClockIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{secondToHoursAndMinute2(data?.startDate, new Date())}</p>
              <p className="text-xxs font-normal ml-0.5 mt-[1px] text-secondary-600">{t("product:timeStarted")}</p>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowDropDown(!showDropDown);
            }}
            className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
          >
            <DotsVerticalIcon />
          </div>
          {showDropDown && (
            <div
              className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[120] rounded-lg px-[6px] py-1 group-hover:flex`}
            >
              {menuOptions?.map((item, index) => {
                const formattedDate = getFormattedNavigateDate(data?.startDate, auth.user.company.dayStartHour);
                return (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item.value === "finishJunction") {
                        setJunctionType(data?.externalOperation ? "external" : "internal");
                        setFinishJunctionId(data?.id);
                        setProductType(data?.productType)
                        setIsOpenJunctionPiece(true);
                      }
                      if (item.value === "personnelDetail") {
                        navigate(`/app/machine/digital-twin/user-detail/${data?.user?.id}/${formattedDate}`);
                      }
                      if (item.value === "editJunction") {
                        setIsJunction(true);
                      }
                      if (item.value === "equipmentDetail") {
                        navigate(`/app/machine/digital-twin/equipment-details/${data?.station?.deviceId}-${data?.station?.id}/${formattedDate}`);
                      }
                      if (item.value === "breaks") {
                        isBreaks === true ? setIsBreak(true) : setIsContinue(true);
                      }
                      if (item.value === "delete") {
                        setIsAlert(true);
                      }
                      setShowDropDown(false);
                    }}
                    key={index}
                    type="button"
                    className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                      }`}
                  >
                    {item?.icon}
                    <p
                      className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"
                        }`}
                    >
                      {item.label}
                    </p>
                  </button>
                );
              })}
            </div>
          )}
          {(data?.header?.step || data?.flexHeader?.step) === undefined ? null : (
            <span className="absolute left-1 top-1">
              <Badgets colorType={"fill-gray"} label={data?.productType === "flex" ? data?.flexHeader?.step + ".op" : data?.header?.step + ".op"} size={"sm"} />
            </span>
          )}
        </div>
        <Tooltip className="z-[120]" id={`${data?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={data?.id}
            pages={"activeAndPlanned"}
            onClose={() => {
              setIsJunction(false);
            }}
            handleData={refetch}
          />
        }
      />
      <CustomModal
        isOpen={isBreak}
        setIsOpen={setIsBreak}
        onClose={() => {
          setIsBreak(false);
        }}
        children={
          <IsBreakModal
            onClose={() => {
              setIsBreak(false);
            }}
          />
        }
      />
      <CustomModal
        isOpen={isContinue}
        setIsOpen={setIsContinue}
        onClose={() => {
          setIsContinue(false);
        }}
        children={
          <IsContiunueModal
            onClose={() => {
              setIsContinue(false);
            }}
          />
        }
      />
    </>
  );
};

export default OperationDetailActiveCard;
