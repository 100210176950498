import React, { useEffect, useRef, useState } from "react";
import { EyeIconNew, FinishPoll, MenuIconNew, PollDeleteIcon, RepostIcon } from "../../../assets/icons/PersonIcons";
import { NavLink, useNavigate } from "react-router-dom";
import { pollService } from "../../../services/poll.service";
import { Toast } from "../../../utils/toastify/toast";
import { useTranslation } from "react-i18next";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";

const PollCard = ({ title, whoSee, createdAt, type, poll, id, onClick, setPollItem, pollItem, refetch }) => {
  const menuRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [finishPollAlert, setFinishPollAlert] = useState(false);
  const [retweetAlert, setRetweetAlert] = useState(false);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const deletePoll = async (id) => {
    await pollService
      .deletePoll(id)
      .then((res) => {
        if (res?.data?.code == 1) {
          Toast("error", res?.data?.message);
        } else {
          Toast("success", res?.data?.message);
        }
        setDeleteAlert(false);
        refetch();
        navigate("/app/person/poll");
      })
      .catch((err) => {
        console.log(err)
      });
  };

  const finishPoll = async (id) => {
    await pollService
      .FinishPoll(id)
      .then((res) => {
        if (res.data.code == 0) {
          Toast("success", res?.data?.message);
        } else {
          Toast("error", res?.data?.message);
        }
        setFinishPollAlert(false);
        refetch();
      })
      .catch((err) => {
        console.log(err)
      });
  };

  const retweetPoll = async (id) => {
    await pollService
      .retweetPoll(id)
      .then((res) => {
        if (res?.data?.code == 1) {
          Toast("success", res?.data?.message);
        } else {
          Toast("error", t("chat:notTransaction"));
        }
        setRetweetAlert(false);
        refetch();
      })
      .catch((err) => err);
  };

  const pendingPollItems = [
    {
      name: t("person:finishPoll"),
      icon: <FinishPoll />,
      onclick: () => {
        setPollItem(pollItem?.id);
        setFinishPollAlert(true);
      },
      index: 1,
    },
    // {
    //   name: t("chat:whoSee"),
    //   icon: <EyeIcon currentColor={"#667085"} />,
    //   onclick: function () {},
    //   index: 3,
    // },
    {
      name: t("person:deletePoll"),
      icon: <PollDeleteIcon />,
      onclick: () => {
        setPollItem(pollItem?.id);
        setDeleteAlert(true);
      },
      index: 2,
    },
  ];
  const pastPollItems = [
    {
      name: t("person:rePublishPoll"),
      icon: <RepostIcon />,
      onclick: () => {
        setPollItem(pollItem?.id);
        setRetweetAlert(true);
      },
      index: 1,
    },
    // {
    //   name: t("chat:whoSee"),
    //   icon: <EyeIcon currentColor={"#667085"} />,
    //   onclick: function () {},
    //   index: 3,
    // },
    {
      name: t("person:deletePoll"),
      icon: <PollDeleteIcon />,
      onclick: () => {
        setPollItem(pollItem?.id);
        setDeleteAlert(true);
      },
      index: 2,
    },
  ];

  const menuItems = type === "pending-poll" ? pendingPollItems : pastPollItems;
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div ref={menuRef} className="relative">
        <NavLink
          onClick={onClick}
          to={poll.isVote === true ? "" : `${id}`}
          className={({ isActive }) =>
            `w-full py-3 px-2 flex group items-center cursor-pointer relative ${isActive ? "bg-secondary-50 rounded-lg my-1" : "bg-white"}`
          }
        >
          <div className="h-full flex grow flex-col overflow-hidden">
            <p className="font-semibold text-secondary-700 truncate">{title}</p>
            <div className="flex flex-row items-center gap-2 w-full">
              <EyeIconNew />
              <p className="font-normal text-md text-secondary-500">{whoSee}</p>
              <div className="ml-auto">
                <div className="min-w-[50px] flex items-center  relative">
                  {poll?.isVote === true ? (
                    <button
                      // onClick={(e) => {
                      //   setIsOpen(!isOpen);
                      // }}
                      className={`w-[20px] h-[20px] items-center justify-center ml-auto `}
                    >
                      <MenuIconNew />
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        setIsOpen(!isOpen);
                      }}
                      className={`w-[20px] h-[20px] items-center justify-center ml-auto `}
                    >
                      <MenuIconNew />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <span className="absolute top-4 right-2 text-xs text-secondary-500 font-normal dark:text-[#A3A3A3]">{createdAt}</span>
        </NavLink>
        {isOpen && (
          <div
            className="w-[196px] p-1 bg-white border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1 z-[99999]"
            style={{ position: "absolute", top: 60, right: 0 }}
            onBlur={() => setIsOpen(false)}
          >
            {menuItems?.map((item, index) => {
              return (
                <button
                  onClick={() => {
                    item.onclick();
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-50 rounded"
                >
                  <div className={`w-6`}>{item.icon}</div>
                  <p className={`font-medium  ${item?.index === 2 ? "text-error-600" : "text-secondary-700"} text-sm `}>{item?.name}</p>
                </button>
              );
            })}
          </div>
        )}
      </div>
      <AlertModal
        title={t("person:deletePoll")}
        subTitle={t("person:areDeletePoll")}
        isOpen={deleteAlert}
        setIsOpen={setDeleteAlert}
        applyButton={() => {
          deletePoll(pollItem);
        }}
      />
      <AlertModal
        name={"finish"}
        title={t("person:finishPoll")}
        subTitle={t("person:areFinishPoll")}
        label={t("product:finish")}
        isOpen={finishPollAlert}
        setIsOpen={setFinishPollAlert}
        applyButton={() => {
          finishPoll(pollItem);
        }}
      />
      <AlertModal
        name={"retweet"}
        title={t("person:rePublishPoll")}
        subTitle={t("person:areRetweetPoll")}
        label={t("chat:publish")}
        isOpen={retweetAlert}
        setIsOpen={setRetweetAlert}
        applyButton={() => {
          retweetPoll(pollItem);
        }}
      />
    </>
  );
};

export default PollCard;
