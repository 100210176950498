import React, { useState } from "react";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../CustomImage";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { DotsVerticalIcon, EquipmentDetailIcon } from "../card-icons";
import { useNavigate, useParams } from "react-router-dom";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import { useStore } from "../../../hooks/useStores";

const OperationDetailPreviousEquipmentCard = ({ data, items }) => {
  let { dates } = useParams();
  const { t } = useTranslation();
  const {auth} = useStore()
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const menuOptions = [
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
  ];
  return (
    <>
      <div className="flex flex-col gap-y-[6px] hover:bg-secondary-50 relative h-[96px] w-[478px] group min-w-[478px] cursor-pointer min-h-[96px] items-start border border-[#E4E7EC] bg-white rounded-t-[4px] rounded-b-lg pt-1 px-2 pb-[6px]">
        <p className="text-xs text-secondary-700 font-semibold">
          {items?.nodeName || "--"} / {items?.orderNumber || "--"}
        </p>
        <div className="flex flex-row items-center w-full">
          {items?.nodeImage ? (
            <div
              data-tooltip-id={`${data?.junctionId}`}
              data-tooltip-content={`
              ${items?.nodeName} 
              `}
              data-tooltip-place={"right"}
              onClick={() => navigate(`/app/machine/digital-twin/equipment-details/${items?.deviceId}-${items?.nodeId}/${dates}`)}
            >
              <CustomImage
                borderRadius={4}
                src={items?.nodeImage}
                style={{
                  width: 72,
                  height: 54,
                  minWidth: 72,
                  minHeight: 54,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div
              onClick={() => navigate(`/app/machine/digital-twin/equipment-details/${items?.deviceId}-${items?.nodeId}/${dates}`)}
              className="w-[72px] min-w-[72px] h-[54px] min-h-[54px] shadow-xs border rounded border-[#E4E7EC] bg-secondary-50 flex items-center justify-center"
            >
              <NoEquIcon />
            </div>
          )}
          <div className="flex flex-row ml-5 items-center gap-x-2">
            <div className="flex flex-col min-h-[54px] h-[54px] min-w-[115px] w-[115px] items-center border border-[#E4E7EC] rounded shadow-xs">
              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                <p className="text-xs font-normal text-secondary-600">upu.point</p>
              </div>
              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                <p className={`text-base font-semibold text-secondary-700`}> {items?.upuPoint?.toFixed(2) || "0"}</p>
              </div>
            </div>
            <div className="flex flex-col min-h-[54px] h-[54px] min-w-[115px] w-[115px] items-center border border-[#E4E7EC] rounded shadow-xs">
              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                <p className="text-xs font-normal text-secondary-600">OEE</p>
              </div>
              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                <p className={`text-base font-semibold text-[#CA8504]`}> %{items?.oee > 1 ? 1 * 100 : (items?.oee * 100)?.toFixed(0) || "0"}</p>
              </div>
            </div>
            <div className="flex flex-col min-h-[54px] h-[54px] min-w-[115px] w-[115px] items-center border border-[#E4E7EC] rounded shadow-xs">
              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                <p className="text-xs font-normal text-secondary-600">{t("product:capacityUtilizationRateNew")}</p>
              </div>
              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                <p className={`text-base font-semibold text-[#079455]`}> %{items?.utilization > 1 ? 1 * 100 : (items.utilization * 100)?.toFixed(0) || "0"}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {menuOptions?.map((item, index) => {
              const formattedDate = getFormattedNavigateDate(data?.startDate, auth.user.company.dayStartHour);

              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                   
                    if (item.value === "equipmentDetail") {
                      navigate(`/app/machine/digital-twin/equipment-details/${items?.deviceId}-${items?.nodeId}/${dates}`);
                    } 
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5 rounded-md gap-x-2 ${
                    item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                  }`}
                >
                  {item?.icon}
                  <p className={`font-medium text-sm ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}>
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
      </div>
      <Tooltip className="z-[99]" id={`${data?.junctionId}`} />
    </>
  );
};

export default OperationDetailPreviousEquipmentCard;
