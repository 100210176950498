import React, { memo, useMemo, useState } from "react";
import { CustomModal } from "../../../components";
import OperationDetailModal from "../modals/OperationDetailModal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClockIcon, ClockIconStart, QuantityAcceptedIcon, QuantityDeclineIcon } from "../../../assets/icons/productIcons";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { Process } from "../../../assets/icons/commonIcons";

const InternalOperationCard = memo(({ status, name, operations, filledOperations, orderData, listOrder, showOperationDetail = true }) => {
  const { t } = useTranslation();
  const [operationDetailModal, setIsOperationDetailModal] = useState(false);
  const hours = useMemo(() => Math.floor(operations?.totalEstimatedTime / 3600));
  const hoursJunctionTime = useMemo(() => Math.floor(operations?.totalJunctionTime / 3600));

  const navigate = useNavigate();
  const statusColor = () => {
    switch (status) {
      case "finished":
        return "#12B76A";
      case "started":
        return "#FDE172";
      case "rejected":
        return "#D92D20";
      default:
        return "#D0D5DD";
    }
  };

  const qualityStatus = () => {
    if (operations?.hasQualityControl) {
      return "#17B26A";
    } else {
      return "#98A2B3";
    }
  };

  return (
    <>
      <div
        onClick={() => {
          showOperationDetail && navigate(`/app/product/mrp/work-in-process/detail/${orderData?.id}+${operations?.step}`);
        }}
        className="bg-white  flex w-full flex-col h-full cursor-pointer"
      >
        <div
          style={{
            backgroundColor: statusColor(),
          }}
          className={`h-1 max-h-1 min-h-[4px]  rounded-t`}
        ></div>
        <div className="flex w-full h-full border-x pl-3 pr-1 py-1 justify-between">
          <div className="flex flex-col w-[184px] h-full">
            <div className="w-full flex items-center overflow-hidden">
              <div className="w-4 h-4 flex items-center justify-center">
                <Process />
              </div>
              <p className="text-sm text-secondary-700 font-semibold truncate ml-1.5">{name}</p>
            </div>
            <div className="flex flex-col">
              <div className="flex border-b">
                <div className="min-w-[67px] border-r pr-[6px] py-1 text-xs font-normal text-[#475467]">{t("product:previousIncoming")}</div>
                <div className="w-[48px] border-r px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">{hours}</span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">{t("product:hours")}</span>
                </div>
                <div className="w-[76px] px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">{operations?.previousPiece}</span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">{t("product:pcs")}</span>
                </div>
              </div>
              <div className="flex">
                <div className="min-w-[67px] border-r pr-[6px] py-1 text-xs font-normal text-[#475467]">{t("product:applied")}</div>
                <div className="w-[48px] border-r px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">{hoursJunctionTime}</span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">{t("product:hours")}</span>
                </div>
                <div className="w-[76px] px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">{operations?.qualityAcceptedPiece}</span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">{t("product:pcs")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col border-[0.5px] w-[82px] h-[68px] px-[6px] py-[2px] gap-y-2 rounded">
            <div className="flex gap-x-1 items-center justify-center">
              <span className={`w-2 h-2 min-w-[8px] max-w-[8px] min-h-[8px] max-h-[8px] bg-[${qualityStatus()}] rounded-[2px]`}></span>
              <p className="text-[#475467] font-normal text-xs">{t("reports:quality")}</p>
            </div>
            <div className="flex flex-col gap-y-[2px]">
              <div className="flex gap-x-[2px] items-center">
                <QuantityAcceptedIcon />
                <span className="text-xs text-[#079455]">{operations?.qualityAcceptedPiece || "--"}</span>
                <span className="text-[10px] leading-[18px] text-[#667085]">{t("product:pcs")}</span>
              </div>
              <div className="flex gap-x-[2px] items-center">
                <QuantityDeclineIcon />
                <span className="text-xs text-[#D92D20]">{operations?.qualityRejectedPiece || "--"}</span>
                <span className="text-[10px] leading-[18px] text-[#667085]">{t("product:pcs")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="border min-h-[26px] max-h-[26px] rounded-b-lg bg-[#F9FAFB] pl-[6px] pr-1 py-1 flex justify-between items-center">
          <div className="flex h-full items-center gap-x-[1px]">
            <ClockIcon />
            <span className="text-xs text-[#344054] font-medium ml-[1px]">{legacyFormatSeconds(operations?.internalOperation?.estimatedTime)}</span>
            <span className="text-[10px] leading-[18px] text-[#475467]">{t("product:targetMin")}</span>
          </div>
          <div
            className={`flex h-full items-center gap-x-[1px] ${operations?.newAvgActualCalculate == null || operations?.newAvgActualCalculate === 0
                ? "text-[#7F7F7F]"
                : operations?.internalOperation?.estimatedTime > operations?.newAvgActualCalculate
                  ? "text-[#079455]"
                  : "text-[#D92D20]"
              }`}
          >
            <ClockIconStart />
            <span className="text-xs font-medium ml-[1px]">{legacyFormatSeconds(operations?.newAvgActualCalculate)}</span>
            <span className="text-[10px] leading-[18px] text-[#475467]">{t("product:minPieceAvg")}</span>
          </div>
          <div className="flex h-full items-center gap-x-[2px]">
            {/* <PlaySquareIcon /> */}
            <span className="text-[10px] leading-[18px] text-[#475467]">{t("product:events")}:</span>
            <span className={`text-[10px] text-success-500 font-medium`}>{operations?.acceptedJunctionLength || "--"}</span>

            <span className="text-[10px] text-[#344054] font-medium">/{operations?.totalJunctionLength || "--"}</span>
          </div>
        </div>
      </div>

      <CustomModal
        isOpen={operationDetailModal}
        setIsOpen={setIsOperationDetailModal}
        modalTitle={t("product:operationDetail")}
        children={<OperationDetailModal t={t} listOrder={listOrder} operations={operations} filledOperations={filledOperations} orderData={orderData} />}
      />
    </>
  );
});

export default memo(InternalOperationCard);
