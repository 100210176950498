import React, { useEffect, useState } from "react";
import { Button, Input, TimeInput } from "../../../components";
import generateFileUrl from "../../../utils/generateFileUrl";
import moment from "moment";
import { useTranslation } from "react-i18next";
import UnitPriceInput from "../../../components/inputs/UnitPriceInput";
import { Toast } from "../../../utils/toastify/toast";

const LegacyAddProduct = ({ selectedProduct, setIsOpenNewPorductModal, isAddedProducts, setIsAddedProducts, editOrder }) => {
  const { t } = useTranslation();
  const [orderAmount, setOrderAmount] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("TRY");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [orderAmountValidate, setOrderAmountValidate] = useState(null);
  const [priceValidate, setPriceValidate] = useState(null);
  const [deliveryDateValidate, setDeliveryDateValidate] = useState(null);
  const [totalDesiredQuantity, setTotalDesiredQuantity] = useState(0);

  const product = selectedProduct || editOrder?.product;
  const handleAddProduct = () => {
    let isValid = true;

    if (!orderAmount) {
      setOrderAmountValidate("error");
      isValid = false;
    } else {
      setOrderAmountValidate(null);
    }

    if (!price) {
      setPriceValidate("error");
      isValid = false;
    } else {
      setPriceValidate(null);
    }

    if (!currency) {
      setPriceValidate("error");
      isValid = false;
    } else {
      setPriceValidate(null);
    }

    if (!deliveryDate) {
      setDeliveryDateValidate("error");
      isValid = false;
    } else {
      setDeliveryDateValidate(null);
    }

    if (isValid) {
      if (editOrder) {
        if (orderAmount < totalDesiredQuantity) {
          Toast("error", `Sipariş adedi en az ${totalDesiredQuantity} olmalıdır.`);
          setOrderAmount(totalDesiredQuantity);
          isValid = false;
        }

        if (orderAmount >= totalDesiredQuantity) {
          const extraQuantity = orderAmount - totalDesiredQuantity;
          const findEditModule = isAddedProducts?.find((f) => f.id === editOrder.id);

          const newProducts = {
            ...findEditModule,
            orderedQuantity: parseFloat(orderAmount),
            productPrice: parseFloat(price),
            currency: currency,
            deliveryDate: deliveryDate,
            extraQuantity: extraQuantity,
          };

          const filter = isAddedProducts?.some((f) => f.id === editOrder.id && f.deliveryDate === deliveryDate && f.id !== editOrder.id);

          if (filter) {
            Toast("error", `${deliveryDate} tarihli aynı ürün olduğu için ekleyemezsiniz.`);
          } else {
            setIsAddedProducts([...isAddedProducts?.filter((f) => f.id !== editOrder.id), newProducts]);
            setIsOpenNewPorductModal(false);
          }
        }
      } else {
        const productToAdd = {
          ...selectedProduct,
          orderedQuantity: parseFloat(orderAmount),
          productPrice: parseFloat(price),
          currency: currency,
          deliveryDate: deliveryDate,
        };

        const filter = isAddedProducts?.some((f) => f.id === selectedProduct.id && f.deliveryDate === deliveryDate);

        if (filter) {
          Toast("error", `${deliveryDate} tarihli aynı ürün olduğu için ekleyemezsiniz.`);
        } else {
          setIsAddedProducts([...isAddedProducts, productToAdd]);
          setIsOpenNewPorductModal(false);
        }
      }
    }
  };

  const priceOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
  ];

  useEffect(() => {
    if (editOrder) {
      setCurrency(editOrder?.currency);
      setPrice(editOrder?.productPrice);
      setDeliveryDate(editOrder?.deliveryDate);
      setOrderAmount(editOrder?.orderedQuantity);

      const total = editOrder?.partial?.reduce((sum, item) => sum + (item.desiredQuantity || 0), 0);
      setTotalDesiredQuantity(total || 0);
    }
  }, [editOrder]);

  return (
    <div className="flex flex-col mt-6 gap-y-6 w-full">
      <div className="flex flex-col w-full gap-y-5">
        <div className="flex gap-x-2 p-2 pr-3 w-full min-h-[90px] max-h-[90px] h-[90px] border border-[#D0D5DD] shadow-xs rounded-lg">
          <div className="flex w-full flex-col gap-y-1">
            <div className="flex w-full gap-x-3">
              <img src={generateFileUrl(product?.image)} className="min-w-[64px] w-[64px] h-[48px] rounded border border-[#0000001A]" />
              <div className="flex flex-col justify-between">
                <p className="text-[#101828] font-semibold text-md truncate w-[250px]">{product?.name}</p>
                <p className="text-[#475467] font-normal text-sm truncate w-[250px]">
                  {t("product:stock")}:{product?.stockCode}
                </p>
              </div>
            </div>
            <div className="flex py-[2px] pr-[6px] gap-x-[2px] items-center">
              <div className="flex gap-x-1 items-center">
                <QuantityIcon />
                <p className="text-[#475467] font-medium text-xs">{product?.qualityQuantity || 0}</p>
              </div>
              <p className="text-[#667085] text-xxs font-normal">{t("product:totalStock")}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 w-full">
          {/* <Input
            label={t("product:customerStockCode")}
            theme={"product"}
            onChange={(e) => {
              setCustomerStockCode(e.target.value);
            }}
            value={customerStockCode}
          /> */}
          <Input
            label={t("product:orderQuantityss")}
            theme={"product"}
            type={"number"}
            onChange={(e) => {
              setOrderAmount(e.target.value);
            }}
            value={orderAmount}
            validate={orderAmountValidate}
            errorMessage={"Bu alan zorunludur"}
          />
          <UnitPriceInput
            theme={"product"}
            items={priceOptions}
            label={t("product:unitSalePrice")}
            setValue={(field, value) => {
              field === "price" ? setPrice(value) : setCurrency(value);
            }}
            price={price}
            currency={currency}
            validate={priceValidate ? "error" : ""}
            errorMessage={priceValidate ? "Bu alan zorunludur" : ""}
          />
          <TimeInput
            label={t("table:deliveryDate")}
            onChange={(v) => {
              setDeliveryDate(v);
            }}
            theme={"product"}
            value={moment(deliveryDate).format("YYYY-MM-DD")}
            minDate={moment().format("YYYY-MM-DD")}
            validate={deliveryDateValidate}
            errorMessage={"Bu alan zorunludur"}
          />
        </div>
      </div>
      <div className="flex gap-x-3">
        <Button
          colorType={"secondary-gray"}
          size={"lg"}
          label={t("buttons:stop")}
          onClick={() => {
            setIsOpenNewPorductModal(false);
          }}
        />
        <Button colorType={"primary-product"} size={"lg"} label={editOrder ? "Edit" : t("buttons:add")} onClick={handleAddProduct} />
      </div>
    </div>
  );
};

export default LegacyAddProduct;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="#475467"
      />
    </svg>
  );
};
