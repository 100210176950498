import React, { useState } from "react";
import { orderService } from "../../../services/order.service";
import { useQuery } from "@tanstack/react-query";
import { Button } from "../../../components";
import { ChevronRightIcon } from "../../../assets/icons/PersonIcons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import NoAvatar from "../../../components/avatar/NoAvatar";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useStore } from "../../../hooks/useStores";
import { Toast } from "../../../utils/toastify/toast";

const MoveWorkOrderModal = ({ productId, moveOrderData, movePartialData, refetch, setIsOpenWorkOrderModal, isFirstModal, setIsFirstModal }) => {
  const { t } = useTranslation();
  const { data } = useQuery({ queryFn: () => orderService.getSameProductActiveOrderList(productId, moveOrderData?.id), queryKey: ["moveWorkOrderModal"] });
  const { auth } = useStore();
  console.log("moveOrderData", moveOrderData);
  console.log("movePartialData", movePartialData);
  const tableHeadItems = ["", "Ürün", "Termin Tarihi", "Sipariş Miktarı", "İş Emri Miktarı", "Kalan Miktar"];

  console.log("data", data);

  const [selectedDetailId, setSelectedDetailId] = useState(null);

  const handleSelect = (id) => {
    setSelectedDetailId(id);
  };

  const handleSubmit = async () => {
    if (!selectedDetailId) {
      alert("Lütfen bir detay seçin!");
      return;
    }

    await orderService.transferWorkOrder(selectedDetailId, movePartialData?.id).then((res) => {
      if (res.data.code == 0) {
        Toast("success", res?.data?.message);
        refetch();
        setIsOpenWorkOrderModal(false);
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full py-5 justify-between items-center border-y border-[#E4E7EC] mt-5">
        <p className="text-[#101828] font-semibold text-xl">
          {movePartialData?.type === "order" ? `WO-${movePartialData?.workOrderNo || "--"}` : `SWO-${movePartialData?.stockWorkOrderNo || "--"}`}
        </p>
        <div className="flex gap-x-3">
          <div className="flex flex-col gap-y-1">
            <p className="text-[#475467] text-sm">İş Emri Miktarı</p>
            <p className="text-[#344054] font-semibold text-md">{movePartialData?.desiredQuantity || 0}</p>
          </div>
          <div className="flex flex-col gap-y-1">
            <p className="text-[#475467] text-sm">Yapılan Miktar</p>
            <p className="text-[#344054] font-semibold text-md">{movePartialData?.finishedQuantity || 0}</p>
          </div>
          <div className="flex flex-col gap-y-1">
            <p className="text-[#475467] text-sm">Kalan Miktar</p>
            <p className="text-[#344054] font-semibold text-md">{movePartialData?.desiredQuantity - movePartialData?.finishedQuantity || 0}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full py-4 gap-y-4 bg-[#F9FAFB] h-[444px] overflow-y-auto overflow-x-hidden scrollbar-hide">
        {data?.data?.map((order, index) => {
          return (
            <div className="flex flex-col w-full border border-[#E4E7EC] bg-white shadow-xs rounded-xl">
              <div className="flex w-full p-6 items-center justify-start gap-x-3">
                <div className="flex gap-x-2 items-center">
                  <OrderIcon />
                  <span className="flex gap-x-2 items-end">
                    <p className="text-[#475467] text-sm mb-[1px]">Sipariş Kodu</p>
                    <p className="text-[#B54708] font-semibold text-md">
                      {order?.productType === "order" ? `WO-${order?.systemOrderNo}` : `SWO-${order?.systemStockOrderNo}`}
                    </p>
                  </span>
                </div>
                <ChevronRightIcon />
                <div className="flex gap-x-3 items-center">
                  <p className="text-[#475467] text-sm">Müşteri</p>
                  <div className="flex gap-x-2 items-center">
                    {order?.customer?.image || auth?.user?.company?.image ? (
                      <img
                        className="w-[32px] h-[24px] rounded border border-[#0000001A] flex-none"
                        src={generateFileUrl(order?.customer?.image || auth?.user?.company?.image)}
                      />
                    ) : (
                      <div className="w-[32px] h-[24px] min-w-[32px] min-h-[24px] rounded border border-secondary-200 overflow-hidden">
                        <NoAvatar name={order?.customer?.name || auth?.user?.company?.name || "--"} rounded={4} theme={"product"} />
                      </div>
                    )}
                    <p className="text-[#B54708] font-semibold text-md">{order?.customer?.name || auth?.user?.company?.name}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col h-fit w-full">
                <div className="flex max-h-[26px] min-h-[26px] w-full bg-[#F2F4F7]">
                  {tableHeadItems.map((item, i) => (
                    <div
                      key={i}
                      className={`flex border-y px-3 py-1 border-r border-[#E4E7EC] items-center w-[${
                        i === 0 ? "72px" : i === 1 ? "313px" : "116px"
                      }]  min-w-[${i === 0 ? "72px" : i === 1 ? "313px" : "116px"}] max-w-[${
                        i === 0 ? "72px" : i === 1 ? "313px" : "116px"
                      }] last:border-r-0 justify-center `}
                    >
                      <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                    </div>
                  ))}
                </div>
                {order?.details?.map((detail, index) => {
                  const totalDesiredQuantity = detail?.partial?.reduce((total, item) => total + item?.desiredQuantity, 0);
                  return (
                    <div className="flex w-full items-center border-b last:border-b-0 h-[72px] min-h-[72px] max-h-[72px]">
                      {/* tablo 1.kolon */}
                      <div className="border-r flex flex-col h-full py-3 items-center justify-center min-w-[72px] max-w-[72px] w-[72px]">
                        <span
                          onClick={() => handleSelect(detail.id)}
                          className={`flex min-w-5 max-w-5 w-5 min-h-5 max-h-5 h-5 rounded-full items-center justify-center cursor-pointer ${
                            selectedDetailId === detail.id ? "bg-[#DC6803]" : "border border-[#D0D5DD]"
                          }`}
                        >
                          {selectedDetailId === detail.id && <span className="flex min-w-2 max-w-2 w-2 min-h-2 max-h-2 h-2 bg-white rounded-full"></span>}
                        </span>
                      </div>
                      {/* tablo 2.kolon */}
                      <div className="flex items-start px-6 py-4 gap-x-2 border-r h-full w-[313px] min-w-[313px] max-w-[313px] justify-start flex-grow">
                        {detail?.product?.image ? (
                          <img className="w-[56px] h-[42px] rounded border border-[#0000001A] flex-none" src={generateFileUrl(detail?.product?.image)} />
                        ) : (
                          <div className="w-[56px] h-[42px] min-w-[56px] min-h-[42px] rounded border border-secondary-200 overflow-hidden">
                            <NoAvatar name={detail?.product?.name || "--"} rounded={4} theme={"product"} />
                          </div>
                        )}
                        <div className="flex flex-col w-[220px]">
                          <p className="text-[#101828] text-sm font-semibold ml-1 truncate max-w-[350px]">{detail?.product?.name || "--"}</p>
                          <span className="truncate text-[#B54708] font-medium text-xs px-1 py-[2px] bg-[#FFFAEB] rounded w-fit max-w-[350px]">
                            {t("product:stock")}: {detail?.product?.stockCode || "--"}
                          </span>
                        </div>
                      </div>
                      {/* tablo 3.kolon */}
                      <div className="border-r flex flex-col gap-y-[2px] h-full py-4 items-center justify-center min-w-[116px] max-w-[116px] w-[116px] ">
                        <p className="text-[#344054] font-medium text-sm">{moment(detail?.deliveryDate).format("DD.MM.YYYY") || "--.--.----"}</p>
                        <p className="text-[#667085] text-xs">{moment(detail?.deliveryDate).format("dddd") || "--"}</p>
                      </div>
                      {/* tablo 4.kolon */}
                      <div className="border-r flex flex-col h-full gap-y-[2px] py-4 items-center justify-center min-w-[116px] max-w-[116px] w-[116px]">
                        <p className="text-[#344054] font-medium text-sm">{detail?.orderedQuantity || 0}</p>
                        <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                      </div>
                      {/* tablo 5.kolon */}
                      <div className="border-r flex flex-col h-full gap-y-[2px] py-4 items-center justify-center min-w-[116px] max-w-[116px] w-[116px] ">
                        <p className="text-[#344054] font-medium text-sm">{totalDesiredQuantity || 0}</p>
                        <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                      </div>
                      {/* tablo 6.kolon */}
                      <div className="flex flex-col h-full py-4 gap-y-[2px] items-center justify-center min-w-[116px] max-w-[116px] w-[116px]">
                        <p className="text-[#344054] font-medium text-sm">{detail?.orderedQuantity - totalDesiredQuantity || 0}</p>
                        <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex pt-6 gap-x-3 w-full border-t border-[#E4E7EC]">
        <Button colorType={"secondary-gray"} size={"lg"} label={"Geri Dön"} onClick={() => setIsFirstModal(!isFirstModal)} />
        <Button colorType={"primary-product"} size={"lg"} label={"Taşı"} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default MoveWorkOrderModal;

const OrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6673 8.75002V5.66669C16.6673 4.26656 16.6673 3.56649 16.3948 3.03171C16.1552 2.56131 15.7727 2.17885 15.3023 1.93917C14.7675 1.66669 14.0674 1.66669 12.6673 1.66669H7.33398C5.93385 1.66669 5.23379 1.66669 4.69901 1.93917C4.2286 2.17885 3.84615 2.56131 3.60647 3.03171C3.33398 3.56649 3.33398 4.26656 3.33398 5.66669V14.3334C3.33398 15.7335 3.33398 16.4336 3.60647 16.9683C3.84615 17.4387 4.2286 17.8212 4.69901 18.0609C5.23379 18.3334 5.93385 18.3334 7.33398 18.3334M10.0007 7.50002H6.66732M11.6673 5.00002H6.66732M8.33398 10H6.66732M9.83772 12.6321L12.8562 10.9454C12.9984 10.866 13.0694 10.8263 13.1447 10.8107C13.2114 10.7969 13.2801 10.7968 13.3468 10.8105C13.4221 10.826 13.4933 10.8656 13.6355 10.9448L16.6673 12.6321M9.83772 12.6321L13.2457 14.5311M9.83772 12.6321V15.9548C9.83772 16.1255 9.83772 16.2108 9.86279 16.287C9.88497 16.3543 9.92123 16.4162 9.96917 16.4685C10.0234 16.5276 10.0978 16.5693 10.2466 16.6527L13.2457 18.3334M16.6673 12.6321L13.2457 14.5311M16.6673 12.6321V15.9539C16.6673 16.1249 16.6673 16.2104 16.6422 16.2866C16.6199 16.3541 16.5835 16.4161 16.5355 16.4684C16.4811 16.5275 16.4065 16.5692 16.2572 16.6525L13.2457 18.3334M13.2457 14.5311V18.3334"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
