import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { useTranslation } from "react-i18next";
import ReactECharts from "echarts-for-react";

import { reportService } from "../../../../services/report.service";
import { Toast } from "../../../../utils/toastify/toast";
import { ExportIcon, FlipBackwardIcon, LoadIcon, SaveIcon } from "../../../../assets/icons/machineIcons";
import { Button } from "../../../../components";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const PersonalMonthlyChart = ({
  data,
  user,
  legendSelected,
  setLegendSelected,
  height = "290px",
  start,
  end,
  setDate,
  selectedDate,

  //legend
  defaultLegends,
}) => {
  const { t } = useTranslation();
  const [legacyData, setLegacyData] = useState(data);
  const [selectedButton, setSelectedButton] = useState("daily");
  const [backDefaultLegends, setBackDefaultLegends] = useState(false);
  const [realLegends, setRealLegends] = useState(defaultLegends);
  const todayDate = moment(timeFormatter(new Date())?.formatted).format("YYYY-MM-DD");





  const echartRef = useRef(null);

  const exportToExcel = async () => {
    try {
      const chartInstance = echartRef.current.getEchartsInstance();

      // Grafiğin verilerini al
      const opt = chartInstance.getOption();
      const axisData = opt.xAxis[0].data;
      const series = opt.series;
      const data = [];

      // Çalışma sayfası için verileri hazırla
      const header = ["Date", ...series.map((s) => s.name)];
      data.push(header);
      for (let i = 0, l = axisData.length; i < l; i++) {
        const row = [axisData[i]];
        series.forEach((s) => {
          row.push(s.data[i]);
        });
        data.push(row);
      }

      // Yeni bir çalışma kitabı oluştur
      const workbook = new ExcelJS.Workbook();

      // Yeni bir çalışma sayfası oluştur
      const worksheet = workbook.addWorksheet("Report");

      // A1'den K1'e kadar hücreleri birleştir ve ekipman adı ve kodunu ekle
      worksheet.mergeCells("A1:K1");
      const titleCell = worksheet.getCell("A1");
      titleCell.value = `${user?.name} ${user?.lastName} ${selectedButton?.toUpperCase()} Report`;
      titleCell.font = { name: "Arial", size: 18 };
      titleCell.alignment = { horizontal: "center", vertical: "middle" };

      // L1'den M1'e kadar hücreleri birleştir ve tarih ekle
      worksheet.mergeCells("L1:O1");
      const dateCell = worksheet.getCell("L1");
      dateCell.value = moment(selectedDate).format("LL");
      dateCell.font = { name: "Arial", size: 18 };
      dateCell.alignment = { horizontal: "left", vertical: "middle" };

      // İkinci satırın yüksekliğini 500px olarak ayarla
      worksheet.getRow(2).height = 200 / 0.75; // Excel'de 1 satır yüksekliği yaklaşık 0.75 piksel olduğundan bu çevirim kullanıldı.

      // Grafikten resim URL'sini al
      const imageUrl = chartInstance.getDataURL({
        type: "png",
        pixelRatio: 2,
        backgroundColor: "#fff",
      });

      const imageResponse = await fetch(imageUrl);
      const imageBlob = await imageResponse.blob();
      const imageArrayBuffer = await imageBlob.arrayBuffer();
      const uint8Array = new Uint8Array(imageArrayBuffer);

      const imageId = workbook.addImage({
        buffer: uint8Array,
        extension: "png",
      });

      worksheet.addImage(imageId, {
        tl: { col: 0, row: 1 },
        ext: { width: 1050, height: 450 },
      });

      worksheet.insertRow(3, []);

      const thirdRow = worksheet.getRow(3);
      thirdRow.height = 40;
      data[0].forEach((value, colIndex) => {
        const cell = thirdRow.getCell(colIndex + 1);
        cell.value = value;
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "D0D5DD" },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = {
          wrapText: true,
          horizontal: "center",
          vertical: "center",
        };
        cell.font = { name: "Arial", size: 11 };
      });

      let rowIndex = 4;
      for (let i = 1; i < data.length; i++) {
        const row = worksheet.insertRow(rowIndex + i - 1, data[i]);
        row.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      }

      const buffer = await workbook.xlsx.writeBuffer();

      saveAs(
        new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        `${user?.name} ${user?.lastName} ${selectedDate} ${selectedButton.toUpperCase()} Report.xlsx`
      );
    } catch (error) {
      console.error("Excel dosyası işlenirken bir hata oluştu:", error);
      alert("Excel dosyası işlenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  useEffect(() => {
    if (realLegends?.length === 0) {
      const allLegends = data?.series?.reduce((acc, legend) => {
        acc[legend?.name] = true;
        return acc;
      }, {});

      setLegendSelected(allLegends);
    } else {
      const allLegends = data?.series?.reduce((acc, legend) => {
        acc[legend?.name] = realLegends?.includes(legend?.name);
        return acc;
      }, {});

      setLegendSelected(allLegends);
    }
  }, [data?.series, backDefaultLegends]);
  const onChartClick = (params) => {
    if (params?.name) {
      setDate(params.name);
    }
  };
  const onEvents = {
    click: onChartClick,
    legendselectchanged: function (params) {
      setLegendSelected(params.selected);
    },
    legendselectchanged: function (params) {
      setLegendSelected(params.selected);
    },
  };

  const rotateFunction = () => {
    if (selectedButton === "weekly") {
      return 45;
    } else if (selectedButton === "monthly") {
      return 0;
    } else {
      return 90;
    }
  };

  // const dates = data?.dates?.map((item) => String(moment(item).format("YYYY-MM-DD")));

  const dates = legacyData?.dates?.map((item) => String(moment(item)?.format("YYYY-MM-DD")));

  const option = {
    title: {},
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "15%"];
      },
      axisPointer: {
        type: "cross",
      },
    },
    legend: {
      selected: legendSelected,
      data: data?.legend,
      right: "5%",
      left: "0",
      top: "0%",
      alignTicks: false,
      formatter: function (name) {
        let itemValue = data?.series?.filter((item) => item.name === name);
        const total = itemValue[0].data.reduce((a, b) => {
          return a + b;
        }, 0);
        return `{name|${name}}:{total|Avg: ${Number((total / 30).toFixed(2))}}`;
      },
      textStyle: {
        rich: {
          name: { fontWeight: "regular", align: "left", fontSize: 10 },
          total: {
            fontSize: 10,
            fontWeight: "regular",
          },
        },
      },
    },
    grid: {
      left: "1%",
      right: "1%",
      bottom: "2%",

      containLabel: true,
    },
    toolbox: {
      feature: {
        myCustomTool: {
          show: true,
          title: "All Selected Legends",
          icon: "M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213",
          onclick: function () {
            allSelected();
          },
        },
        saveAsImage: {
          show: false,
          title: "Save",
          icon: "M7.16196 3.39488C7.4329 3.35482 7.7124 3.33333 8.00028 3.33333C11.4036 3.33333 13.6369 6.33656 14.3871 7.52455C14.4779 7.66833 14.5233 7.74023 14.5488 7.85112C14.5678 7.93439 14.5678 8.06578 14.5487 8.14905C14.5233 8.25993 14.4776 8.3323 14.3861 8.47705C14.1862 8.79343 13.8814 9.23807 13.4777 9.7203M4.48288 4.47669C3.0415 5.45447 2.06297 6.81292 1.61407 7.52352C1.52286 7.66791 1.47725 7.74011 1.45183 7.85099C1.43273 7.93426 1.43272 8.06563 1.45181 8.14891C1.47722 8.25979 1.52262 8.33168 1.61342 8.47545C2.36369 9.66344 4.59694 12.6667 8.00028 12.6667C9.37255 12.6667 10.5546 12.1784 11.5259 11.5177M2.00028 2L14.0003 14M6.58606 6.58579C6.22413 6.94772 6.00028 7.44772 6.00028 8C6.00028 9.10457 6.89571 10 8.00028 10C8.55256 10 9.05256 9.77614 9.41449 9.41421",
          iconStyle: {
            stroke: "red",
            borderWidth: 1,
          },
          function(saveAsImage) {},
        },
        dataView: {
          show: true,
          readOnly: false,

          icon: "M2 6L14 6M6 2L6 14M5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2C2 4.07989 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2Z",
          iconStyle: {
            stroke: "red",
            borderWidth: 1,
          },

          optionToContent: function (opt) {
            const axisData = opt.xAxis[0].data;
            const series = opt.series;
            let table = '<table style="width:100%;text-align:center"><tbody><tr>' + "<td>Date</td>";
            series.forEach((s) => {
              table += `<td>${s.name}</td>`;
            });
            table += "</tr>";
            for (let i = 0, l = axisData.length; i < l; i++) {
              table += "<tr>" + `<td>${axisData[i]}</td>`;
              series.forEach((s) => {
                table += `<td>${s.data[i]}</td>`;
              });
              table += "</tr>";
            }
            table += "</tbody></table>";

            <div style={{ backgroundColor: "red" }} aaaaaaa></div>;
            return table;
          },
        },
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: dates,
      axisLabel: {
        rotate: rotateFunction(),
        formatter: function (value, index) {
          if (selectedButton === "weekly") {
            return moment(value).format("YY-MM-DD");
          } else if (selectedButton === "monthly") {
            return moment(value).format("MMM-YY");
          } else {
            return moment(value).format("ddd DD");
          }
        },
        show: true,
      },
    },
    yAxis: [
      {
        type: "value",
        name: "upu.point",
        position: "right",
        alignTicks: false,
        name: "upu.point",
        position: "right",
        alignTicks: false,
        axisLine: {
          show: true,
          lineStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
            left: "10px",
          },
        },
        axisLabel: {
          formatter: "{value}",
          show: true,
          style: {
            right: -"20px",
          },
          textStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
            marginRight: 20,
          },
        },
      },
      {
        type: "value",
        name: t("addProcess:rate"),
        min: 0,
        max: 1,
        position: "left",
        alignTicks: false,
        offset: 10,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: "{value} %",
          show: true,
          textStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
          },
        },
      },
      {},
    ],
    series: legacyData?.series,
  };

  const allSelected = () => {
    const allChecked = Object.values(legendSelected).every((value) => value);
    const newLegendSelected = legacyData?.legend.reduce((acc, legend) => {
      acc[legend] = !allChecked;
      return acc;
    }, {});
    setLegendSelected(newLegendSelected);
  };

  const FetchData = async (button) => {
    await reportService.getWeeklyOrMonthlyDataForPersonLineChart("node", button, user.id, selectedDate).then((res) => {
      setLegacyData(res?.data);
    });
  };

  const handleButtonClick = (button) => {
    if (button === "today") {
      setDate(todayDate);
    } else {
      FetchData(button);
      setSelectedButton(button);
    }
  };

  const handleSave = async () => {
    const selectedLegends = Object.keys(legendSelected)?.filter((key) => legendSelected[key]);
    if (selectedLegends?.length === 0) {
      selectedLegends.push("empty");
    }
    const saveData = { legends: selectedLegends, type: "person" };
    await reportService.saveLegends(saveData).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res?.data?.message);
        setRealLegends(res?.data?.data);
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  const handleBack = () => {
    setBackDefaultLegends(!backDefaultLegends);
  };

  return (
    <div className="flex w-full h-[308px] border border-secondary-200 rounded-lg shadow-xs">
      <div
        className=" relative mt-3 w-full h-full flex items-center"
        style={{
          marginLeft: "20px",
        }}
      >
        <ReactECharts
          onEvents={onEvents}
          option={option}
          style={{
            height: 270,
            width: "98%",
          }}
          ref={echartRef}
        />
        <div className={`absolute -top-4 font-normal w-[400px]  gap-2 dark:text-[#F5F5F5] left-9 gap-x-2`}>
          <p className="text-sm mt-4 font-semibold inline-block ">
            {t("settingTab:start")} : {start ? start : ""}
          </p>
          <p className="text-sm ml-2 mt-4 font-semibold inline-block ">
            {t("settingTab:end")} : {end ? end : ""}
          </p>
        </div>
      </div>
      <div className="flex flex-col min-w-[164px] border-l">
        <div className="flex items-center flex-row gap-x-2 justify-center w-full border-b p-3">
          <div onClick={handleSave} className="flex  py-1 gap-x-1 items-center justify-center text-[#6941C6] hover:text-[#53389E] cursor-pointer">
            <SaveIcon />
            <p className="font-semibold text-sm">{t("buttons:save")}</p>
          </div>
          <div onClick={handleBack} className="flex  py-1 gap-x-1 items-center justify-center text-[#6941C6] hover:text-[#53389E] cursor-pointer">
            <LoadIcon />
            <p className="text-[#6941C6] font-semibold text-sm">{t("buttons:load")}</p>
          </div>
        </div>
        <div className="flex flex-col gap-y-3 w-full h-full p-3 bg-[#F9FAFB]">
          <Button
            onClick={() => {
              exportToExcel();
            }}
            colorType={"tertiary-gray"}
            label={t("buttons:exportXlsx")}
            size={"sm"}
            iconLeft={<ExportIcon />}
          />
          <button
            onClick={() => handleButtonClick("daily")}
            className={`flex min-w-[88px] w-full min-h-[40px] max-h-[40px] h-10 border border-[#D0D5DD] shadow-xs rounded-lg py-[10px] px-[14px] items-center justify-center font-semibold text-sm  active:shadow-ring-gray ${
              selectedButton === "daily" ? "bg-[#7F56D9] text-[#FFFFFF]" : "hover:bg-[#F9FAFB] text-[#344054] bg-white"
            }`}
          >
            {t("buttons:daily")}
          </button>
          <button
            onClick={() => handleButtonClick("weekly")}
            className={`flex min-w-[88px] w-full min-h-[40px] max-h-[40px] h-10 border border-[#D0D5DD] shadow-xs rounded-lg py-[10px] px-[14px] items-center justify-center font-semibold text-sm  active:shadow-ring-gray ${
              selectedButton === "weekly" ? "bg-[#7F56D9] text-[#FFFFFF]" : "hover:bg-[#F9FAFB] text-[#344054] bg-white"
            }`}
          >
            {t("buttons:weekly")}
          </button>
          <button
            onClick={() => handleButtonClick("monthly")}
            className={`flex min-w-[88px] w-full min-h-[40px] max-h-[40px] h-10 border border-[#D0D5DD] shadow-xs rounded-lg py-[10px] px-[14px] items-center justify-center font-semibold text-sm  active:shadow-ring-gray ${
              selectedButton === "monthly" ? "bg-[#7F56D9] text-[#FFFFFF]" : "hover:bg-[#F9FAFB] text-[#344054] bg-white"
            }`}
          >
            {t("buttons:monthly")}
          </button>
          <Button onClick={() => handleButtonClick("today")} colorType={"tertiary-gray"} label={t("chat:today")} size={"sm"} iconLeft={<FlipBackwardIcon />} />
        </div>
      </div>
    </div>
  );
};

export default PersonalMonthlyChart;
