export const statusColors = (status) => {
  switch (status) {
    case 4:
      return "#08BAB2";
    case 10:
      return "#F97066";
    case 11:
      return "#F97066";
    case 14:
      return "#344054";
    case 22:
      return "#53B1FD";
    case 31:
      return "#98A2B3";
    case 32:
      return "#FDE272";
    case 33:
      return "#32D583";
    case 40:
      return "#B692F6";
    case 41:
      return "#B692F6";
    case 42:
      return "#B692F6";
    case 43:
      return "#B692F6";
    case 44:
      return "#B692F6";
    case 45:
      return "#B692F6";
    case 46:
      return "#B692F6";
    case 47:
      return "#B692F6";
    case 48:
      return "#B692F6";
    case 49:
      return "#B692F6";
    case 50:
      return "#B692F6";
    case 51:
      return "#B692F6";
    case 52:
      return "#B692F6";
    case 53:
      return "#B692F6";
    case 54:
      return "#B692F6";
    case 55:
      return "#B692F6";
    case 56:
      return "#B692F6";
    case 57:
      return "#B692F6";
    case 58:
      return "#B692F6";
    case 59:
      return "#B692F6";
    case 61:
      return "#FFFFFF";
    case 62:
      return "#98A2B3";
    case 63:
      return "#FFFFFF";
    case 64:
      return "#FFFFFF";
    case 70:
      return "#344054";
    case 81:
      return "#F79009";
    case 82:
      return "#F79009";
  }
};
