import React, { useEffect, useState } from 'react'
import { NoEquIcon } from '../../../assets/icons/machineIcons'
import CustomImage from '../../../components/CustomImage'
import { Button } from '../../../components'
import { useTranslation } from 'react-i18next'
import MintueSecondInput from '../../../components/inputs/MintueSecondInput'
import { productService } from '../../../services/product.service'
import { Toast } from '../../../utils/toastify/toast'

const CycleTimeSuggestionModal = ({ selectedJunction, setOpenCycleTime, metadataId, refetch, junctionData, equipmentData, suggestedCycleTime }) => {
    
    
    const { t } = useTranslation()
    const junctionDatas = junctionData.find(item => item?.id === selectedJunction);
    const [inputValue, setInputValue] = useState("");
    const [checked, setChecked] = useState(false);
    const [checkedMachine, setCheckedMachine] = useState(false);

    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };
    const toggleCheckedMachine = () => {
        setCheckedMachine((prev) => !prev);
    };

    const estimatedTimeGroup = junctionDatas?.header ? junctionDatas?.header?.internalOperation?.plannableNodes?.find((element) => { return element?.id == equipmentData?.id }) :
      junctionDatas?.flexHeader?.plannableNodes ?  junctionDatas?.flexHeader?.plannableNodes?.find((element) => { return element?.id == equipmentData?.id }) : junctionDatas?.flexHeader?.estimatedTime

    
    useEffect(() => {
        setInputValue(estimatedTimeGroup?.estimatedTime ? estimatedTimeGroup?.estimatedTime || "" : estimatedTimeGroup || "");
    }, [junctionDatas]);

    const handleApplySuggestedTime = () => {
        setInputValue(suggestedCycleTime);
    };

    const onSubmit = async () => {
        const sendData = {
            oldJunctionCalculateStatus: checked ? checked : false,
            estimatedTime: inputValue,
            type: junctionDatas?.header ? 'standart' : 'flex',
            allStationStatus: checkedMachine ? checkedMachine : false
        };
        const operationId = junctionDatas?.header ? junctionDatas?.header?.internalOperation?.id : junctionDatas?.flexHeader?.id
        await productService.updateStationCycleOrSettingTime(operationId, equipmentData?.id, sendData)
            .then((res) => {
                if (res.data.code === 0) {
                    Toast("success", res.data.message);
                    setOpenCycleTime(false)
                    refetch()
                } else {
                    Toast("error", res.data.message);
                }
            })
            .catch((err) => { Toast("error", err?.response?.data?.message) });
    };

    

    return (
        <div className='flex flex-col w-full gap-y-6'>
            <div className='flex flex-row items-center mt-6 gap-x-2 w-full border rounded-lg p-2 border-[#D0D5DD] bg-white'>
                {equipmentData?.equipmentImage ? (
                    <div>
                        <CustomImage
                            borderRadius={2}
                            src={equipmentData?.equipmentImage}
                            style={{
                                width: 64,
                                height: 48,
                                minWidth: 64,
                                minHeight: 48,
                                borderRadius: 4,
                                borderWidth: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                display: "flex",
                                borderColor: "#0000001A",
                                backgroundColor: "#fff",
                            }}
                        />
                    </div>
                ) : (
                    <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                        <NoEquIcon />
                    </div>
                )}
                <div className='flex flex-col items-start'>
                    <p className='text-secondary-700 w-[250px] truncate font-semibold text-md'>{equipmentData?.equipmentName || "--"}</p>
                    <p className='text-sm w-[250px] truncate text-secondary-500'>{equipmentData?.processName}</p>
                </div>
            </div>
            <div className='w-full'>
                <MintueSecondInput
                    label={t("product:targetWorkDuration")}
                    value={inputValue}
                    theme={"machine"}
                    onChange={(e) => setInputValue(e)}
                />
            </div>
            <div className='flex flex-row items-center w-full justify-between  border rounded-lg  px-4 py-1 border-[#D6BBFB]'>
                <div className='flex flex-col items-start'>
                    <div className='flex flex-row items-center gap-x-1'>
                        <LightIcon />
                        <p className='text-sm font-medium text-[#7F56D9]'>{t("product:suggestedDuration")}</p>
                    </div>
                    <p className='text-lg font-medium text-[#6941C6]'>{Math.floor(suggestedCycleTime / 60)} {t("product:min")} {Math.round(suggestedCycleTime % 60)} {t("product:second")}</p>
                </div>
                <div onClick={handleApplySuggestedTime} className='cursor-pointer text-sm font-semibold text-[#6941C6]'>
                   {t("buttons:apply")}
                </div>
            </div>

            <div className='flex flex-col gap-y-2 w-full'>
                <div className='flex flex-row items-center w-full'>
                    <span
                        className={`w-5 h-5 min-w-5 min-h-5 border rounded-md flex justify-center items-center mr-2 cursor-pointer ${checked ? "bg-[#7F56D9] border-[#7F56D9]" : "border-[#D0D5DD]"
                            }`}
                        onClick={toggleChecked}
                    >
                        {checked && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        )}
                    </span>

                    <p className='text-sm text-secondary-600'>{t("product:updatePastData")}</p>
                </div>
                <div className='flex flex-row items-center w-full'>
                    <span
                        className={`w-5 h-5 min-w-5 min-h-5 border rounded-md flex justify-center items-center mr-2 cursor-pointer ${checkedMachine ? "bg-[#7F56D9] border-[#7F56D9]" : "border-[#D0D5DD]"
                            }`}
                        onClick={toggleCheckedMachine}
                    >
                        {checkedMachine && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        )}
                    </span>

                    <p className='text-sm text-secondary-600'>{t("product:applyThisCycleTime")}</p>
                </div>
            </div>
            <div className='flex flex-row items-center gap-x-3 w-full'>
                <Button colorType={"secondary-gray"} label={t("buttons:stop")} onClick={() => setOpenCycleTime(false)} />
                <Button type={"button"} onClick={() => onSubmit()} colorType={"primary-machine"} label={t("buttons:save")} />
            </div>
        </div>
    )
}

export default CycleTimeSuggestionModal

export const ArrowIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const LightIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M8.33341 14.7156V16.6667C8.33341 17.5872 9.07961 18.3334 10.0001 18.3334C10.9206 18.3334 11.6667 17.5872 11.6667 16.6667V14.7156M10.0001 1.66675V2.50008M2.50008 10.0001H1.66675M4.58341 4.58341L4.08333 4.08333M15.4167 4.58341L15.917 4.08333M18.3334 10.0001H17.5001M15.0001 10.0001C15.0001 12.7615 12.7615 15.0001 10.0001 15.0001C7.23866 15.0001 5.00008 12.7615 5.00008 10.0001C5.00008 7.23866 7.23866 5.00008 10.0001 5.00008C12.7615 5.00008 15.0001 7.23866 15.0001 10.0001Z" stroke="#7F56D9" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};