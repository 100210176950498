import React, { useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import QtyInput from "../../../components/inputs/QtyInput";
import { Button, TimeInput } from "../../../components";
import { useTranslation } from "react-i18next";
import { rawMaterialService } from "../../../services/raw-material.service";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { orderService } from "../../../services/order.service";
import { QtyIcon3 } from "../../../assets/icons/commonIcons";

const TypeRawPurchaseRequestForm = ({
  cardStockId,
  closeModal,
  totalStock,
  purchaseData,
}) => {
  const { t } = useTranslation();
  const [priceValue, setPriceValue] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const tableHeadItems = [
    {
      name: t("product:productsAndMaterials"),
    },
    {
      name: t("product:orderQuantityss"),
    },
    {
      name: t("product:perUnitCost"),
    },
    {
      name: t("product:totalCost"),
    },
    {
      name: t("product:delivery"),
    },
  ];

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["getPurchaseList"],
    queryFn: async () =>
      await rawMaterialService.getPurchaseList(purchaseData?.id),
    retry: 0,
  });

  const onSubmit = async () => {
    const send = {
      orderedUnit: priceValue,
      estimatedDeliveryDate: date,
      rawMaterial: data?.id,
      unitType: data?.unit,
      type: "raw",
      unitCost: data?.price,
    };
    await orderService.purchaseRequest(send).then((res) => { });
  };

  return (
    <div className="w-[862px] flex flex-col">
      <p className="font-normal text-sm text-secondary-600 mt-1">
        {t("product:additionalRawmaterials")}
      </p>
      <div className="flex flex-row items-center gap-x-6 w-full h-12 bg-[#F9FAFB] border border-r-0 border-l-0 border-t-[#EAECF0] border-b-[#EAECF0] py-3 px-6 mt-4">
        <div className="flex flex-row items-center gap-x-2">
          <img
            src={generateFileUrl(purchaseData?.image)}
            className="min-w-[32px] w-[32px] h-[24px] rounded border border-secondary-300 object-cover"
          />
          <p className="text-sm font-semibold  truncate text-secondary-900">
            {purchaseData?.name || "----"}
          </p>
        </div>
        <p className="text-xs font-normal  truncate text-secondary-700">
          {t("product:stockCode")} : {purchaseData?.stockCode || "---"}
        </p>
        <div className="flex flex-row items-center gap-x-1">
          <QtyIcon3 />
          <p className="text-xs font-normal text-secondary-700">
            {totalStock || "---"}
          </p>
          <p className="text-[10px] font-normal text-secondary-600">
            {t("product:totalStock")}
          </p>
        </div>
      </div>
      <div className="w-full flex-1 h-full">
        <div className="min-w-full divide-y ">
          <div className={`w-full min-h-[320px] h-[320px] border-b`}>
            <div className="flex mt-5 max-h-[44px] min-h-[44px]">
              {tableHeadItems?.map((item, i) => {
                return (
                  <div
                    key={i}
                    scope="col"
                    className={`border-b px-4 py-3 ${i === 4 ? "" : "border-r"
                      } w-[${i === 0
                        ? "30%"
                        : i === 4
                          ? "22%"
                          : i === 3 || i === 2
                            ? "13%"
                            : i === 1
                              ? "22%"
                              : ""
                      }] ${!item.active ? "text-secondary-500" : "text-secondary-900"
                      } items-center text-center`}
                  >
                    <span
                      className={`text-center rounded  ${!item?.active ? "text-gray-500" : "text-gray-500"
                        } flex text-xs font-medium ${i === 0 ||
                          i === 1 ||
                          i === 2 ||
                          i === 3 ||
                          i === 4 ||
                          i === 5
                          ? "items-center justify-center"
                          : " items-center"
                        } cursor-pointer whitespace-nowrap`}
                    >
                      {item?.name}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="w-full bg-[#FFF]">
              <div className={`flex items-center border-b h-[72px] `}>
                <div className="flex flex-row gap-x-2 items-center w-[30%] h-full border-r">
                  <img
                    src={generateFileUrl(data?.image)}
                    className="min-w-[60px] w-[60px] h-[45px] rounded border border-secondary-300 object-cover"
                  />
                  <div className="flex flex-col items-start">
                    <p className="text-lg text-secondary-900 w-[180px] truncate font-medium">
                      {data?.name}
                    </p>
                    <p className="text-sm text-secondary-600">
                      Stock ID: {cardStockId || "---"}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center  w-[22%] h-full border-r px-4 ">
                  <QtyInput
                    theme={"product"}
                    qtyType={data?.unit}
                    onChange={(e) => {
                      setPriceValue(e.target.value);
                    }}
                    value={priceValue}
                  />
                </div>
                <div className="flex flex-row items-center justify-center  w-[13%] h-full border-r px-4 ">
                  <div className="flex flex-col items-center justify-center">
                    <p className="font-medium text-md text-secondary-600">
                      {data?.price}
                    </p>
                    <p className="font-normal text-sm text-secondary-500">
                      {data?.currency}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center  w-[13%] h-full border-r px-4 ">
                  <div className="flex flex-col items-center justify-center">
                    <p className="font-medium text-md text-secondary-600">
                      {priceValue * data?.price}
                    </p>
                    <p className="font-normal text-sm text-secondary-500">
                      {data?.currency}
                    </p>
                  </div>
                </div>

                <div className="flex flex-row items-center justify-center  w-[22%] h-full  px-4 ">
                  <TimeInput
                    onChange={(e) => {
                      setDate(e);
                    }}
                    theme={"product"}
                    value={moment(date).format("YYYY-MM-DD")}
                    minDate={moment().format("YYYY-MM-DD")}
                  />
                  {/* <EndDatePicker
                    date={date}
                    visible={visible}
                    setDate={setDate}
                    setVisible={setVisible}
                    buttonClassName={"flex flex-col items-center"}
                    calendarClassName={
                      "absolute top-12 rounded-lg shadow-xl z-[600]"
                    }
                    visibleFooter
                  >
                    <Button
                      onClick={() => {
                        setVisible(!visible);
                      }}
                      iconLeft={<CalendarIcon />}
                      size={"md"}
                      colorType={"secondary-gray"}
                      label={moment(date).format("DD.MM.YYYY")}
                    />
                  </EndDatePicker> */}

                  {/* <Selector
                    items={urgency}
                    onChange={(e) => {
                      setUrgencyValue(e);
                    }}
                    value={urgencyValue}
                    theme={"product"}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row items-center gap-x-4 ">
        <div className="w-[50%] flex flex-row items-center mt-4">
          <p className="text-md font-normal text-secondary-600">
            {t("product:totalCost")}
          </p>
          <p className="text-md text-secondary-900 font-semibold ml-auto">
            {priceValue * data?.price}
          </p>
        </div>
      </div>
      <div className="flex flex-row items-center gap-x-4 w-full mt-4">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          onClick={closeModal}
        />
        <Button
          type={""}
          label={t("buttons:save")}
          colorType={"primary-product"}
          size={"md"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default TypeRawPurchaseRequestForm;
