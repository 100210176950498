import React from "react";
import { Controller } from "react-hook-form";
import { Button, Input } from "../../../components";
import moment from "moment";
import { ShiftColorTick } from "../../../assets/icons/stepsIcons";
import HourTimeInput from "../../../components/inputs/HourTimeInput";
import Selector from "../../../components/inputs/Selector";
import { timeFormatter } from "../../../utils/timezoneFormatter";

export default function ShiftInfo({
  t,
  control,
  errors,
  personals,
  setIsOpen,
  setValue,
  setSelectedTab,
  color,
  workingDays = [],
}) {
  const days = [
    { value: 1, label: t("days:monday") },
    { value: 2, label: t("days:tuesday") },
    { value: 3, label: t("days:wednesday") },
    { value: 4, label: t("days:thursday") },
    { value: 5, label: t("days:friday") },
    { value: 6, label: t("days:saturday") },
    { value: 7, label: t("days:sunday") },
  ];
  const shiftColors = [
    { border: "#7F56D9", bg: "#B692F6", label: t("colors:purple") },
    { border: "#1570EF", bg: "#53B1FD", label: t("colors:blue") },
    { border: "#DC6803", bg: "#FEC84B", label: t("colors:orange") },
    { border: "#0E9384", bg: "#2ED3B7", label: t("colors:green") },
    { border: "#475467", bg: "#98A2B3", label: t("colors:gray") },
    { border: "#D92D20", bg: "#F97066", label: t("colors:red") },
  ];

  return (
    <>
      <div className="w-[736px] flex flex-col gap-6 mt-6 xs:h-[500px] md:w-[670px] sm:w-[600px]">
        <div className="w-full flex gap-4 xs:flex-col overflow-auto scrollbar-hide">
          <div className="w-1/2 xs:w-full flex flex-col gap-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("addShift:shiftName")}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  validate={errors?.name ? "error" : ""}
                  errorMessage={errors.name ? errors?.name?.message : ""}
                />
              )}
              name="name"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  label={t("addShift:supervisor")}
                  items={personals}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={(e) => {
                    setValue("userId", e);
                  }}
                  value={value}
                  validate={errors.userId ? "error" : ""}
                  errorMessage={errors.userId ? errors.userId.message : ""}
                />
              )}
              name="userId"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <HourTimeInput
                  // onChange={onChange}
                  // value={value}
                  label={t("addShift:startTime")}
                  setValue={moment(timeFormatter(setValue).formatted).format(
                    "HH:mm"
                  )}
                  validate={errors.start ? "error" : ""}
                  onChange={(v) => {
                    setValue(
                      `start`,
                      moment()
                        .set("hour", v.split(":")[0])
                        .set("minute", v.split(":")[1])
                        .toDate()
                    );
                  }}
                  value={moment(value).format("HH:mm")}
                  errorMessage={errors.start ? errors.start.message : ""}
                />
              )}
              name="start"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <HourTimeInput
                  label={t("addShift:endTime")}
                  onChange={(v) => {
                    setValue(
                      `end`,
                      moment()
                        .set("hour", v.split(":")[0])
                        .set("minute", v.split(":")[1])
                        .toDate()
                    );
                  }}
                  value={moment(value).format("HH:mm")}
                  validate={errors.end ? "error" : ""}
                  errorMessage={errors.end ? errors.end.message : ""}
                />
              )}
              name="end"
            />
          </div>
          <span className="min-h-full w-px bg-secondary-200 xs:hidden" />
          <div className="w-1/2 xs:w-full flex gap-4 xs:flex-col">
            <div className="shadow-xs border rounded-lg border-solid flex flex-col w-full px-4 py-3 gap-6 dark:border-[#292929] dark:bg-[#141414]">
              <div
                className={`text-sm font-medium sm:text-xs leading-5 ${
                  errors.workingDays
                    ? "text-red-500"
                    : "text-gray-700 dark:text-[#FAFAFA]"
                }`}
              >
                {t("addShift:repeatShift")}
              </div>
              <div className="flex flex-col w-full h-full gap-y-4 justify-between">
                {days.map((day, i) => (
                  <div
                    key={i}
                    className="relative flex items-center rounded-lg"
                  >
                    <div className="flex items-center h-5">
                      <input
                        type="checkbox"
                        className="focus:ring-primary-500 h-5 w-5 accent-[#7F56D9] sm:w-3 sm:h-3 text-primary-600 dark:border-[#424242] dark:checked:border-[#7F56D9] dark:checked:bg-[#7F56D9] dark:bg-[#292929] dark:focus:ring-0 border-secondary-300 rounded-md"
                        checked={workingDays?.includes(day?.value)}
                        onChange={() => {
                          if (workingDays?.includes(day?.value)) {
                            setValue(
                              "workingDays",
                              workingDays?.filter((d) => d !== day.value)
                            );
                          } else {
                            setValue("workingDays", [
                              ...workingDays,
                              day.value,
                            ]);
                          }
                        }}
                      />
                    </div>
                    <div className="text-sm  flex flex-col px-1.5">
                      <div className="flex ">
                        <span className="flex flex-col ml-2">
                          <p className="text-gray-700 text-sm sm:text-xs font-normal leading-6 dark:text-[#D6D6D6]">
                            {day.label}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="shadow-xs border rounded-lg border-solid flex flex-col w-full px-4 py-3 gap-6 items-start dark:bg-[#141414] dark:border-[#292929]">
              <div
                className={`text-sm font-medium leading-5 sm:text-xs ${
                  errors.color
                    ? "text-red-500"
                    : "text-gray-700 dark:text-[#FAFAFA]"
                }`}
              >
                {t("addShift:color")}
              </div>
              <div className="flex justify-center">
                <ul className="flex flex-col w-full gap-y-4 justify-between">
                  {shiftColors.map((colors, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => setValue("color", colors.bg)}
                        className={
                          "flex relative w-full gap-x-3 cursor-pointer"
                        }
                      >
                        <div
                          className="w-5 h-5 rounded-full flex sm:w-4 sm:h-4 items-center justify-center cursor-pointer border"
                          style={{
                            backgroundColor: colors.bg,

                            borderStyle: "solid",
                            borderWidth: "2px",
                            borderColor: colors.border,
                          }}
                        >
                          {colors.bg === color && (
                            <ShiftColorTick
                              color={"#FFFFFF"}
                              className="w-4 h-4"
                            />
                          )}
                        </div>
                        <div className="text-sm font-normal text-[#475467] dark:text-[#D6D6D6]">
                          {colors.label}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-3">
          <Button
            label={t("buttons:stop")}
            colorType={"secondary-gray"}
            onClick={() => setIsOpen(false)}
          />
          <Button
            label={t("buttons:next")}
            colorType={"primary-machine"}
            onClick={() => {
              setSelectedTab("breaks");
            }}
          />
        </div>
      </div>
    </>
  );
}
