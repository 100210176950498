import React, { useState } from "react";
import AddRawMaterialProductCard from "../components/AddRawMaterialProductCard";
import { Button, Input } from "../../../components";
import { MeasuringIcon } from "../../../assets/icons/productIcons";

const UnitQuantityModal = ({ selectedProduct }) => {
  const [value, setValue] = useState();
  return (
    <div className="w-[400px] flex flex-col items-start">
      <p className="text-sm text-secondary-600 font-normal ml-10">
        Ürün başına gereken miktarı girin
      </p>
      <hr className="h-[1px] w-full  bg-secondary-300 mt-5" />
      <div className="w-full h-[466px]">
        <div className="mt-4 w-full">
          <AddRawMaterialProductCard
            unit={selectedProduct?.unit}
            unitPrice={selectedProduct?.unitPrice}
            name={selectedProduct?.productName}
            stockId={selectedProduct?.id}
            isActive={true}
          />
        </div>
        <div className="w-full mt-5">
          <Input
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            theme={"product"}
            iconPosition={"left"}
            icon={<MeasuringIcon currentColor={"#98A2B3"} />}
          />
        </div>
      </div>
      <div className="flex flex-row items-center w-full justify-between">
        <p className="text-base font-semibold text-secondary-900">
          Toplam Birim Miktarı
        </p>
        <p className="text-base font-normal text-secondary-400">{value || 0}</p>
      </div>
      <div className="flex flex-row items-center w-full justify-between mt-3">
        <p className="text-base font-semibold text-secondary-900">
          Toplam Maliyet
        </p>
        <p className="text-base font-normal text-secondary-400">
          {selectedProduct?.unitPrice * value || 0}
        </p>
      </div>
      <hr className="h-[1px] w-full  bg-secondary-300 mt-5" />

      <div className="mt-4 w-full">
        <Button
          colorType={"primary-product"}
          size={"lg"}
          label={"Hammaddeyi Ekle"}
        />
      </div>
    </div>
  );
};

export default UnitQuantityModal;
