import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { instance } from '../../../libs/client';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Button, Input } from '../../../components';
import TextArea from '../../../components/inputs/TextArea';
import EndDatePicker from '../../person/components/EndDatePicker';
import UnitPriceInput from '../../../components/inputs/UnitPriceInput';
import QtyInput from '../../../components/inputs/QtyInput';
import PersonnelAndEquipmentSelector from '../../../components/inputs/PersonnelAndEquipmentSelector';
import ServiceSupplyRawCard from '../../../components/junction/ServiceSupplyRawCard';
import { CalendarIcon } from '../../../assets/icons/PersonIcons';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { authStore } from '../../../stores/auth.store';
import { purchaseService } from '../../../services/purchase.service';
import { serviceSupplyService } from '../../../services/service-supply.service';
import { Toast } from '../../../utils/toastify/toast';

const ServiceSupplyPlanModal = ({ selectedJunction, setIsOpen, refetch, groupId }) => {
    
    const { t } = useTranslation();
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupllier, setSelectedSupplier] = useState('')
    const [date, setDate] = useState(moment(new Date()));
    const [visible, setVisible] = useState(false);
    const [price, setPrice] = useState("");
    const [currency, setCurrency] = useState("USD");

    const handleSupplier = async () => {
        await instance.get(`external-operation/list/supplier/${groupId}`).then((res) => {

            setSuppliers(
                res?.data?.map((item) => {
                    return { label: item?.name, value: item?.id, image: item?.image };
                })
            );
        });
    };



    const priceOptions = [
        { label: "USD", value: "USD" },
        { label: "EUR", value: "EUR" },
        { label: "GBP", value: "GBP" },
        { label: "RUB", value: "RUB" },
        { label: "UAH", value: "UAH" },
        { label: "TRY", value: "TRY" },
    ];

    const qtyOptions = [
        { label: "Kilogram", value: "kg" },
        { label: t("product:metricTon"), value: "ton" },
        { label: "Gram", value: "gr" },
        { label: "Miligram", value: "mg" },
        { label: "Microgram", value: "mcg" },
        { label: "Pound", value: "lb" },
        { label: t("product:ounce"), value: "oz" },
        { label: "Metre", value: "m" },
        { label: "Millilitre", value: "mm" },
        { label: t("product:gallon"), value: "gal" },
        { label: t("product:squareMetre"), value: "m2" },
        { label: t("product:squareMilimetre"), value: "mm2" },
        { label: t("product:squareInch"), value: "mm2" },
        { label: t("product:pieceModal"), value: "ad" },
        { label: t("product:package"), value: "pkg" },
        { label: t("product:box"), value: "box" },
    ];

    const schema = yup.object({
        price: yup.string(),
        description: yup.string(),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        handleSupplier();
    }, []);

    const purchaseQuantity = useWatch({
        control,
        name: "purchaseQuantity",
    });

    const currencyValue = useWatch({
        control,
        name: "currency",
    });

    const priceValues = useWatch({
        control,
        name: "price",
    });

    const onSubmit = async (value) => {
        const data = {
            // companyId: authStore.user.company.id,
            unitCost: Number(price),
            currency: currency || "USD",
            description: value.description,
            plannedUnit: value?.purchaseQuantity,
            estimatedDeliveryDate: moment(date).format("YYYY-MM-DD"),
            process: {
                id: selectedJunction?.externalOperation?.process?.id ? selectedJunction?.externalOperation?.process?.id : groupId,
            },
            supplier: { id: value?.supplier },
            type: "external",
            orderId: selectedJunction?.orderData?.id,
            headerId: selectedJunction?.orderData ? selectedJunction?.id : null,
            flexProductId: selectedJunction?.flexProduct ? selectedJunction?.flexProduct?.id : selectedJunction?.flexRawMaterial ? selectedJunction?.id : null,
            flexHeaderId: selectedJunction?.flexProduct ? selectedJunction?.id : null,
        };
        await serviceSupplyService.createPlannedServiceJunction(data).then((res) => {
            if (res?.data?.code === 0) {
                Toast("success", res?.data?.message);
                setIsOpen(false);
                refetch();
            } else {
                Toast("error", res?.data?.message);
            }
        });
    };

    const totalSsPlanned = selectedJunction?.ssPlanedJunctions?.reduce(
        (acc, curr) => acc + (curr?.targetedWorkCount || 0),
        0
    );

    console.log(selectedJunction,"SELECTED JUNCTIONNNNNNN")

    useEffect(() => {
        if(selectedJunction?.status === "active" || selectedJunction?.status === "waiting") {
            setPrice(selectedJunction?.unitCost);
            setCurrency(selectedJunction?.currency);
            setValue("description", selectedJunction?.description);
            setValue("purchaseQuantity", selectedJunction?.targetedWorkCount);
            setValue("supplier", selectedJunction?.supplier?.id);
            setDate(selectedJunction?.estimatedDeliveryDate);
        }
 
        
    }, [selectedJunction]);


    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-[766px]">
            <div className="flex flex-row items-center mt-5 border-b border-t p-3 w-full bg-[#F9FAFB]">
                <ServiceSupplyRawCard item={selectedJunction} />
                <Button iconLeft={<ChevronRightIcon />} size={"sm"} colorType={"tertiary-gray"} />
                <div className="flex flex-row items-center ml-4 rounded-lg p-2 w-full">
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <PersonnelAndEquipmentSelector
                                value={value}
                                items={suppliers}
                                icon={"machine"}
                                theme={"product"}
                                label={t("product:supplier")}
                                onChange={onChange}
                                onBlur={onBlur}
                                validate={errors?.station ? "error" : ""}
                                errorMessage={errors?.station ? errors?.station?.message : ""}
                            />
                        )}
                        name="supplier"
                    />
                </div>
            </div>
            <div className="flex flex-col items-start gap-y-4 w-full mt-3">
                <div className="flex flex-row gap-x-6 w-full">
                    <div className="w-1/2">
                        <Controller
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <QtyInput
                                    value={value}
                                    theme={"product"}
                                    items={qtyOptions}
                                    qtyType={t("product:pcs")} // unittype gelecek
                                    label={t("product:quantityTobePurchased")}
                                    onChange={onChange}
                                    setValue={setValue}
                                    onBlur={onBlur}
                                    validate={errors?.purchaseQuantity ? "error" : ""}
                                    errorMessage={errors?.purchaseQuantity ? errors?.purchaseQuantity?.message : ""}
                                />
                            )}
                            name="purchaseQuantity"
                        />
                    </div>
                    <div className="flex w-1/2"></div>
                </div>
                <div className="flex flex-row gap-x-6 w-full">
                    <div className="w-1/2">
                        <UnitPriceInput
                            theme={"product"}
                            items={priceOptions}
                            setValue={(field, value) => {
                                field === "price" ? setPrice(value) : setCurrency(value);
                            }}
                            label={t("product:unitPriceRawMaterial")}
                            price={price}
                            currency={currency}
                        />
                    </div>
                    <div className="flex flex-col gap-1.5 w-1/2">
                        <p className="text-sm font-medium text-secondary-700">{t("product:dueDate")}</p>
                        <EndDatePicker
                            date={date}
                            visible={visible}
                            setDate={(e) => {
                                setDate(e);
                            }}
                            setVisible={setVisible}
                            buttonClassName={"flex flex-col items-center"}
                            calendarClassName={"absolute -top-[160px] rounded-lg shadow-xl z-[600]"}
                        >
                            <Button
                                onClick={() => {
                                    setVisible(!visible);
                                }}
                                iconLeft={<CalendarIcon />}
                                size={"md"}
                                colorType={"secondary-gray"}
                                label={moment(date).format("DD.MM.YYYY")}
                            />
                        </EndDatePicker>
                    </div>
                </div>
                <div className="flex flex-col gap-y-4 w-full">
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <TextArea
                                value={value}
                                theme={"product"}
                                height={80}
                                label={t("product:description")}
                                onChange={onChange}
                                setValue={setValue}
                                onBlur={onBlur}
                                validate={errors?.description ? "error" : ""}
                                errorMessage={errors?.description ? errors?.description?.message : ""}
                            />
                        )}
                        name="description"
                    />
                </div>
            </div>
            <div className="flex flex-col gap-y-4 bg-[#F9FAFB] w-full border-y mt-6 px6 py-4 min-h-[104px]">
                <div className="flex w-full justify-between items-center">
                    <p className="text-[#344054] text-md font-semibold">{t("product:quantityTobePurchased")}</p>
                    <p className="text-[#475467] text-md">{purchaseQuantity || "0"} {t("product:pcs")}</p>
                </div>
                <div className="flex w-full justify-between items-center">
                    <p className="text-[#344054] text-md font-semibold">{t("product:totalPrice")}</p>
                    <p className="text-[#475467] text-md">{purchaseQuantity * price || "0"} {currency}</p>
                </div>
            </div>
            <div className="w-[100%] flex items-center gap-x-3 mt-4">
                <Button onClick={() => setIsOpen(false)} label={t("buttons:stop")} colorType={"secondary-gray"} size={"md"} type={"button"} />
                <Button type={"submit"} label={t("buttons:save")} colorType={"primary-product"} size={"md"} />
            </div>
        </form>
    )
}

export default ServiceSupplyPlanModal

export const ChevronRightIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};