import React, { useState } from "react";
import Button from "../buttons/Button";
import {
  ArrowCircleBrokenRight,
  QuantityIcons,
} from "../../assets/icons/productIcons";
import SearchInput from "../inputs/SearchInput";
import generateFileUrl from "../../utils/generateFileUrl";
import { CalenderIconProduct } from "../../assets/icons/equipmentIcon";
import NewProductCard from "../../pages/product/components/NewProductCard";
import { orderService } from "../../services/order.service";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import Loading from "../loading/Loading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const MoveJunctionsModal = ({
  operationType,
  productId,
  orderOperationId,
  operationId,
  selectedJunctions,
  setMoveJunctionsModal,
  stockCode = null,
  operationName,
  orderNo,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: ["find-junctions-similar"],
    queryFn: async () =>
      await orderService.getCanBeMovedJunctions(
        operationType,
        productId,
        orderOperationId,
        operationId,
        stockCode
      ),
    retry: 0,
  });
  const handleOperationSelection = (operation) => {
    setSelectedOperation(operation);
  };
  const moveJunctions = async () => {
    await junctionService
      .moveJunctions(
        selectedOperation,
        selectedJunctions?.map((junction) => junction.id)
      )
      .then((res) => {
        if (res.data[0].code == 0) {
          Toast("success", res?.data[0]?.message);
        } else {
          Toast("error", res?.data[0]?.message);
        }
        navigate(-1);
        setMoveJunctionsModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="h-full flex w-[354px] min-h-[510px] items-center justify-center">
      {isLoading || isFetching ? (
        <div className="flex w-full h-full items-center justify-center">
          <Loading color={"#B54708"} secondary={"#B54708"} size={48} />
        </div>
      ) : (
        <div className="flex w-[354px] flex-col mt-1 gap-y-6">
          <div className="flex w-full text-secondary-600 text-sm font-normal">
            {data?.data?.code != 1
              ? `Select the product you want to move "${operationName}" junctions to`
              : t("product:availableTransport")}
          </div>
          {data?.data?.code != 1 ? (
            <div className="flex flex-col w-full gap-y-[20px]">
              <SearchInput setSearchVal={setSearchQuery} />
              <div className="flex min-h-[320px] max-h-[320px] overflow-y-auto overflow-x-hidden scrollbar-hide">
                <div className="flex flex-col gap-y-3 w-full">
                  {data?.data?.data?.map((product, index) => {
                    return (
                      <>
                        <NewProductCard
                          name={product?.product?.name}
                          productId={"Stock ID:" + product?.product?.stockCode}
                          orderNo={"Order No:" + product?.orderNo}
                          image={product?.product?.image}
                          quantity={
                            product?.product?.qualityQuantity +
                            product?.product?.scrapQuantity
                          }
                          date={moment(product?.deliveryDate).format(
                            "DD.MM.YY"
                          )}
                        />
                        {product?.operations?.map((operation, i) => {
                          return (
                            <div className="flex flex-col gap-y-2">
                              <div
                                onClick={() =>
                                  handleOperationSelection(operation?.id)
                                }
                                className="flex gap-x-2 items-center cursor-pointer w-fit"
                              >
                                <div
                                  className={`min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] w-4 rounded-full flex items-center justify-center ${selectedOperation === operation.id
                                      ? " bg-[#DC6803]"
                                      : "border border-secondary-300"
                                    }`}
                                >
                                  {selectedOperation === operation.id && (
                                    <div className="flex min-w-[6px] max-w-[6px] min-h-[6px] max-h-[6px] bg-white rounded-full"></div>
                                  )}
                                </div>
                                <div className="text-secondary-500 font-normal text-sm">
                                  {`${operation?.step}.Operation`}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="flex w-full min-h-[40px] max-h-[40px]">
            <Button
              colorType={"primary-product"}
              iconLeft={<ArrowCircleBrokenRight />}
              label={t("product:moveSelectedJunction")}
              disabled={data?.data?.code == 1 ? true : false}
              onClick={() => {
                moveJunctions();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MoveJunctionsModal;
