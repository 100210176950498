import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../components";
import { useTranslation } from "react-i18next";
import NewSelectInput from "../../../components/inputs/NewSelectInput";
import FirstAssemblyModal from "../components/FirstAssemblyModal";
import SecondAssemblyModal from "../components/SecondAssemblyModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { Toast } from "../../../utils/toastify/toast";
import { productService } from "../../../services/product.service";

function AssemblyModal({ setIsFirstModal, isFirstModal, selectedProduct, closeModal }) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [valuePercentage, setValuePercentage] = useState("");
  const [hasQualityControl, setHasQualityControl] = useState(false);
  const [prevData, setPrevData] = useState(null);
  const [isAddedProducts, setIsAddedProducts] = useState([]);
  const schema = yup.object({
    estimatedTime: yup.number(),
    // .required(`${t("validation:empty")}`)
    // .typeError(`${t("validation:number")}`),
    entityId: yup.string(),
    // .required(`${t("validation:empty")}`)
    // .typeError(`${t("validation:string")}`),
    costOfMinute: yup.number(),
    // .required(`${t("validation:empty")}`)
    // .typeError(`${t("validation:number")}`),
    qualityPercent: yup.number(),
    // .notRequired()
    // .typeError(`${t("validation:number")}`),
    hasQualityControl: yup.boolean(),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watch = useWatch({
    control: control,
  });
  const onSubmit = (value) => {};
  const sendData = async () => {
    const _data = {
      estimatedTime: Number(prevData?.estimatedTime),
      entityId: prevData?.entityId,
      costOfMinute: Number(prevData?.costOfMinute),
      qualityPercent: Number(prevData?.qualityPercent),
      hasQualityControl: prevData?.hasQualityControl,
      type: "internal",
      step: 1,
      processType: "assembly_operation",
      assemblyParts: isAddedProducts?.map((item) => {
        return {
          productId: item?.id,
          rawMaterialId: null,
          quantity: item?.unitValue,
          type: "product",
        };
      }),
    };
    
    await productService.addOperation(selectedProduct?.id, _data).then((res) => {
      if (res?.data?.code == 0) {
        Toast("success", res?.data?.message);
        closeModal();
      } else if (res?.data?.code == 2) {
        Toast("error", res?.data?.message);
        closeModal();
      } else {
        Toast("error", res?.data?.message);
        closeModal();
      }
    });
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d+(\.\d{0,2})?$/.test(inputValue) && parseFloat(inputValue) <= 100) {
      setValue("qualityPercent", inputValue);
      setValuePercentage(inputValue);
    } else if (inputValue === "" || inputValue === ".") {
      setValue("qualityPercent", "0");
      setValuePercentage("");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-center">
      <div className={`${isFirstModal ? "min-w-[500px] max-w-[500px]" : "min-w-[744px] max-w-[744px]"} bg-[#EAECF0] h-[1px] mt-5`}></div>
      {isFirstModal ? (
        <FirstAssemblyModal
          handleChange={handleChange}
          valuePercentage={valuePercentage}
          setValuePercentage={setValuePercentage}
          hasQualityControl={hasQualityControl}
          setHasQualityControl={setHasQualityControl}
          setIsChecked={setIsChecked}
          isChecked={isChecked}
          setValue={setValue}
          control={control}
          errors={errors}
        />
      ) : (
        <SecondAssemblyModal prevData={prevData} setIsFirstModal={setIsFirstModal} setIsAddedProducts={setIsAddedProducts} isAddedProducts={isAddedProducts} />
      )}

      <div className={`${isFirstModal ? "min-w-[500px] max-w-[500px]" : "min-w-[744px] max-w-[744px]"} bg-[#EAECF0] h-[1px]`}></div>
      <div className="flex mt-6 gap-x-3 items-center justify-center w-full">
        {isFirstModal ? (
          <>
            <Button colorType={"secondary-gray"} label={"Vazgeç"} />
            <Button
              colorType={"primary-product"}
              label={"Devam Et"}
              type={"submit"}
              onClick={() => {
                setPrevData(watch);
                setIsFirstModal(false);
              }}
            />
          </>
        ) : (
          <>
            <Button colorType={"secondary-gray"} label={"Geri"} onClick={() => setIsFirstModal(true)} />
            <Button
              colorType={"primary-product"}
              label={"Kaydet"}
              onClick={() => {
                if (isAddedProducts.length < 2) {
                  Toast("error", "Lutfen iki adet secim yapiniz.");
                } else {
                  sendData();
                }
              }}
            />
          </>
        )}
      </div>
    </form>
  );
}

export default AssemblyModal;
