import React, { memo, useEffect, useState } from "react";
import * as yup from "yup";
import { TabModal } from "../../process";
import ShiftInfo from "../pages/ShiftInfo";
import Breaks from "../pages/Breaks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "moment-timezone";
import { shiftService } from "../../../services/shift.service";
import { Toast } from "../../../utils/toastify/toast";
import { authStore } from "../../../stores/auth.store";
import { addTimeZone } from "../../../utils/addTimezoneFormatter";

const NewShift = observer(
  ({
    selectedTab,
    setSelectedTab,
    setIsOpen,
    selectedShift,
    handleData,
    personals,
  }) => {
    const { t } = useTranslation();
    const [activities, setActivities] = useState([]);

    const schema = yup.object({
      userId: yup
        .string()
        .required(`${t("validation:empty")}`)
        .typeError(`${t("validation:string")}`),
      name: yup
        .string()
        .required(`${t("validation:empty")}`)
        .typeError(`${t("validation:string")}`),
      color: yup
        .string()
        .required(`${t("validation:empty")}`)
        .typeError(`${t("validation:string")}`),
      workingDays: yup.array().required(`${t("validation:empty")}`),
      start: yup.date().required(`${t("validation:empty")}`),
      end: yup.date().required(`${t("validation:empty")}`),
    });

    const {
      handleSubmit,
      setValue,
      control,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });

    const color = useWatch({
      control: control,
      name: "color",
    });

    const workingDays = useWatch({
      control: control,
      name: "workingDays",
    });

    const addActivity = (id) => {
      if (activities.length > 0) {
        return activities.forEach(async (a) => {
          return await shiftService.addActivity(id, {
            ...a,
            start: addTimeZone(a.start, authStore.user.company.timeZone),
            end: addTimeZone(a.end, authStore.user.company.timeZone),
          });
        });
      }
    };

    const onSubmit = async (data) => {
      const sendData = {
        ...data,
        userId: data?.userId,
        end: addTimeZone(data?.end, authStore.user.company.timeZone),
        start: addTimeZone(data?.start, authStore.user.company.timeZone),
        activity: activities,
      };
      if (selectedShift && selectedShift.id) {
        delete sendData?.activity;
        delete sendData?.user;
        await shiftService
          .updateShift(selectedShift?.id, sendData)
          .then(async (res) => {
            if (res.data.type === "warning") {
              Toast("error", res?.data?.message);
            } else if (res.data.code === 0) {
              Toast("success", res?.data?.message);
              const result = await addActivity(selectedShift?.id);
              setIsOpen(false);
              handleData();
            } else if (res.data.code === 1) {
              Toast("error", res.data.message);
            }
          });
        setIsOpen(false);
      } else {
        delete sendData?.user;
        await shiftService?.addShift(sendData).then(async (res) => {
          if (res?.data?.type === "warning") {
            Toast("error", res?.data?.message);
          } else if (res.data.code === 1) {
            Toast("error", res.data.message);
          } else {
            setIsOpen(false);
            handleData();
            if (res.data.code === 0) {
              Toast("success", res?.data?.message);
            }
          }
        });
      }
    };

    const ShiftTabs = [
      { name: t("addShift:shiftInfo"), value: "shiftInfo" },
      { name: t("addShift:breaks"), value: "breaks" },
    ];

    useEffect(() => {
      if (selectedShift && selectedShift?.id) {
        setValue("name", selectedShift?.name);
        setValue("workingDays", selectedShift?.workingDays);
        setValue("userId", selectedShift?.user?.id);
        setValue("color", selectedShift?.color);
        setValue("start", selectedShift?.start);
        setValue("end", selectedShift?.end);
        setActivities(selectedShift?.activity);
      } else {
      }
    }, []);

    useEffect(() => {
      if (Object.keys(errors).length > 0) {
        setSelectedTab("shiftInfo");
      }
    }, [errors]);

    return (
      <>
        <form
          className="w-full xs:w-[330px]  scrollbar-hide h-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TabModal
            width="1/2"
            data={ShiftTabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabData={[
              {
                name: t("settingTab:shiftInfo"),
                value: "shiftInfo",
                component: (
                  <ShiftInfo
                    control={control}
                    errors={errors}
                    personals={personals}
                    setSelectedTab={setSelectedTab}
                    setValue={setValue}
                    workingDays={workingDays}
                    color={color}
                    setIsOpen={setIsOpen}
                    t={t}
                  />
                ),
              },
              {
                name: t("settingTab:breaks"),
                value: "breaks",
                component: (
                  <Breaks
                    setSelectedTab={setSelectedTab}
                    activities={activities}
                    setActivities={setActivities}
                  />
                ),
              },
            ]}
          />
        </form>
      </>
    );
  }
);

export default memo(NewShift);
