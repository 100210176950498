import React from "react";
import MonthlyChart from "./MonthlyChart";

const MothlyDetailChart = ({ data, setDate, legendSelected, setLegendSelected, selectedDate, selectedEquipmentId , selectedOperationName }) => {
  return (
    <div className="w-full h-[320px] border border-secondary-200 rounded-lg shadow-xs">
      <MonthlyChart
        selectedDate={selectedDate}
        selectedEquipmentId={selectedEquipmentId}
        data={data?.monthlyChart}
        legendSelected={legendSelected}
        setLegendSelected={setLegendSelected}
        start={data?.monthlyChart?.startDate}
        end={data?.monthlyChart?.endDate}
        defaultLegends={data?.legends}
        setDate={setDate}
        selectedOperationName={selectedOperationName}
      />
    </div>
  );
};

export default MothlyDetailChart;
