import React, { useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import { useQuery } from "@tanstack/react-query";
import { personalService } from "../../../services/personal.service";
import Badgets from "../../../components/buttons/Badgets";
import { Button } from "../../../components";
import { useStore } from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import PollExpandedCard from "../components/PollExpandedCard";
import PollPersonalItem from "../components/PollPersonalItem";

const AddUserToPoll = ({ selectedUsers, setSelectedUsers, createPoll }) => {
  const { t } = useTranslation();
  const { chat, auth } = useStore();

  const { isLoading, data, refetch } = useQuery({
    retry: 0,
    queryKey: ["pollUserList"],
    queryFn: async () => await personalService?.getDepartmentToUser(),
  });

  const triggerUser = (user) => {
    const filter = selectedUsers?.filter((u) => {
      return u.id == user.id;
    });
    if (filter?.length) {
      setSelectedUsers(selectedUsers?.filter((item) => item?.id !== user?.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
    selectedUsers.includes(user);
  };

  const selectAllPress = (users) => {
    const diff = users.filter((x) => !selectedUsers.includes(x));
    if (diff.length > 0) {
      setSelectedUsers([...selectedUsers, ...diff]);
    } else {
      setSelectedUsers(selectedUsers.filter((item) => !users.includes(item)));
    }
  };

  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="flex flex-col w-[500px] sm:w-[400px] xs:w-[300px] h-[600px] mt-6 gap-6">
      <SearchInput setSearchVal={setSearchValue} />
      <div className="flex flex-row items-center justify-between w-full">
        <Badgets
          colorType={"fill-success"}
          label={selectedUsers?.length + " " + "User"}
          // label={`${selectedUsers?.length ?? 0}/${
          //   data?.[0]?.users?.length ?? 0
          // }`}
          size={"md"}
        />
        <button className="text-md font-semibold text-teal-700">{t("chat:selectAllPerson")}</button>
      </div>

      <div className="h-[430px] flex flex-col  w-full items-start gap-6 overflow-y-scroll scrollbar-hide border-b">
        {data?.data?.map((item, i) => {
          if (item?.users?.length > 0) {
            return (
              <PollExpandedCard
                item={item}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                index={i}
                t={t}
                key={i}
                chat={chat}
                auth={auth}
                PersonalItem={<PollPersonalItem selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />}
                // PersonalItem={PollPersonalItem}
                selectedUsers={selectedUsers}
                triggerUser={triggerUser}
                selectAllPress={selectAllPress}
              />
            );
          }
        })}
      </div>
      <Button colorType={"primary-person"} label={t("buttons:createPool")} onClick={createPoll} />
    </div>
  );
};

export default AddUserToPoll;
