import React, { useState } from "react";
import SearchInput from "../../components/inputs/SearchInput";
import OrderSort from "./components/OrderSort";
import { Button } from "../../components";
import { ChevronDown } from "../../assets/icons/PersonIcons";
import { InfoIcon } from "../../assets/icons/leftMenuIcons";
import ExpandedOrderInfo from "./cards/ExpandedOrderInfo";
import { Headericon, PersonIcon } from "../../components/new-cards/card-icons";
import { PlusIcon } from "../../assets/icons/stepsIcons";
import ExpandableHeaderCard from "./components/order/ExpandableHeaderCard";
import { ChevronUp } from "../../assets/icons/productIcons";
import ProductFilterDropdown from "./components/ProductFilterDropdown";
import generateFileUrl from "../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";

const ExpandableOrder = ({
  totalOrderQuantity = 12.4,
  deliveredQuantity = 12.4,
  remainingQuantity = 12.4,
  activeOrders,
  title = "  Baca Makine Mühendislik",
  image = "f694506f-f373-4268-a534-b18783cec548",
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const quantityInfo = [
    {
      label: t("product:totalOrderQuantity"),
      value: totalOrderQuantity || "12.400",
    },
    {
      label: t("product:totalDeliveredQuantity"),
      value: deliveredQuantity || "12.400",
    },
    {
      label: t("product:remainingOrders"),
      value: remainingQuantity || "12.400",
    },
  ];

  return (
    <>
      <div className="flex flex-1 flex-col w-full  gap-8 h-screen overflow-hidden">
        {/* Header */}
        <div className="w-full h-[162px] flex flex-col items-start gap-4">
          <header className="w-full h-14 flex justify-between  items-center border-b border-solid border-[#E4E7EC] ">
            <div className="min-w-[422px] h-[30px] flex items-center gap-3 ">
              <div className="flex items-center">
                <span className="font-codecMedium text-2xl text-black">
                  upu.
                </span>
                <p className="text-2xl font-codecMedium text-[#B54708]">
                  order
                </p>
                <p className="text-xl font-normal ml-2 text-secondary-500">
                  Siparişler & Müşteriler
                </p>
              </div>
            </div>

            <button className="min-w-[156px] h-10 flex  justify-center items-center gap-1 rounded-lg border border-solid border-[#D0D5DD] shadow-xs ">
              <PersonIcon />
              <div className="min-w-[104px] min-h-5 px-[2px]  flex justify-center items-center  ">
                <span className="w-[102px] truncate text-sm font-codecMedium text-[#344054] ">
                  Müşteri Listesi
                </span>
              </div>
            </button>
          </header>

          <div className="w-full h-[92px] flex items-start gap-6 overflow-x-auto scrollbar-hide">
            <button className="w-9 h-full flex justify-center items-center p-2 gap-2 rounded-lg border border-solid border-[#D0D5DD] shadow-xs ">
              <PlusIcon />
            </button>

            {/*header conatiner card */}
            <div className="w-[1312px] h-[90px] flex items-start gap-4 ">
              {/*first card */}
              <div className="w-[220px] h-full flex flex-col items-start border border-solid border-[#E4E7EC] bg-[#fff] rounded-t-lg rounded-b-[1px] shadow-xs ">
                <div className="w-full h-10 flex justify-center items-center p-2 gap-2 border-b border-solid border-[#E4E7EC]  ">
                  <Headericon />
                  <span className="min-w-[43px] h-6 text-md text-[#667085] font-medium ">
                    Tümü
                  </span>
                </div>

                <div className="w-full h-[50px] flex">
                  {["Aktif", "Gecikmiş"].map((status, index) => (
                    <div
                      key={index}
                      className={`w-[110px] flex flex-col items-center border-r ${
                        index === 1
                          ? "border-none"
                          : "border-solid border-[#E4E7EC]"
                      }`}
                    >
                      <div className="w-full h-[18px] flex justify-center items-center gap-1 border-b border-solid border-[#E4E7EC] bg-[#F2F4F7]">
                        <span className="text-xs text-[#667085]">{status}</span>
                      </div>
                      <div className="w-full h-8 flex justify-center items-center">
                        <span className="text-sm text-[#667085] font-medium">
                          345
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <ExpandableHeaderCard />
            </div>
          </div>
        </div>

        {/*body*/}
        <div className="w-full h-full flex flex-col items-start gap-5">
          {/*body_header*/}
          <div className="w-full h-[56px] flex pb-4 justify-between items-center border-b border-solid border-[#E4E7EC] ">
            <span className="min-w-[139px] h-[30px] text-xl text-[#101828] font-semibold">
              {t("product:customerGroup")}
            </span>
            {/*body_filter*/}
            <div className="w-[441px] h-10 flex items-start gap-3">
              <SearchInput />
              <OrderSort />
              {/* <ProductFilterDropdown/> */}
            </div>
          </div>

          {/*body_content*/}
          <div className="w-full h-full flex flex-col items-start gap-4">
            <div
              className={`w-full  flex flex-col items-start rounded-xl border border-solid border-[#E4E7EC] shadow-xs overflow-auto scrollbar-hide ${
                isExpanded ? "max-h-[620px]" : "h-[69px]"
              }`}
            >
              <div
                className={`w-full h-[69px] sticky bg-[#fff]  flex p-[12px_16px_12px_24px] justify-between items-center  border-b border-solid border-[#E4E7EC] shadow-md}`}
              >
                {/*image*/}
                <div className="w-[320px] h-[45px] flex items-center gap-3 ">
                  <img
                    src={generateFileUrl(image)}
                    className="w-[60px] h-[45px] flex justify-center items-center fexl  gap-1 rounded-[4px] border border-solid border-[#0000001A] bg-[#F9FAFB] "
                  ></img>

                  <div className="w-[197px] h-11 flex flex-col justify-center items-start ">
                    <span className="w-full h-6 text-md text-[#101828] font-semibold ">
                      {title}
                    </span>
                    <div className="min-w-[92px] h-5 flex items-baseline gap-1 ">
                      <span className="min-w-[25px] max-w-[50px] truncate h-full text-sm text-[#B54708] font-semibold ">
                        {activeOrders || 123}
                      </span>
                      <span className="min-w-[63px] h-[18px] text-xs text-[#475467] font-normal ">
                        {t("product:activeOrders")}
                      </span>
                    </div>
                  </div>
                </div>

                {/*details*/}

                <div className="min-w-[504px] h-11 flex items-center gap-[72px]">
                  {quantityInfo?.map((item, index) => (
                    <div
                      key={index}
                      className="min-w-[146px] h-full flex flex-col justify-center items-start"
                    >
                      <span className="text-sm text-[#475467] font-normal min-w-[146px] h-5 justify-center items-center flex">
                        {item?.label}
                      </span>
                      <span className="text-md text-[#344054] font-semibold min-w-[146px] h-5 justify-center items-center flex">
                        {item?.value}
                      </span>
                    </div>
                  ))}
                </div>

                {/*info_Icon & expandable button*/}
                <div className="w-[100px] h-11 flex items-center gap-3 ">
                  <Button
                    colorType={"tertiary-machine"}
                    size={"md"}
                    iconLeft={<InfoIcon />}
                  />
                  <Button
                    colorType={"tertiary-machine"}
                    size={"md"}
                    iconLeft={isExpanded ? <ChevronUp /> : <ChevronDown />}
                    onClick={handleToggleExpand}
                  />
                </div>
              </div>
              {isExpanded && (
                <div className="w-full h-full p-4 bg-[#F9FAFB] gap-4">
                  <ExpandedOrderInfo t={t} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpandableOrder;
