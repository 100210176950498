import React from 'react'
import CustomImage from '../../../components/CustomImage'
import { useTranslation } from 'react-i18next'
import ServiceSupplyTab from '../serviceSupply/ServiceSupplyTab'
import StockWorkOrderSuppliesTab from './StockWorkOrderSuppliesTab'
import { useQuery } from '@tanstack/react-query'
import { purchaseService } from '../../../services/purchase.service'
import OperationDetailQualityAppliedCard from '../../../components/new-cards/material-supply/OperationDetailQualityAppliedCard'
import StockWorkOrderQualityEventCard from '../../../components/new-cards/StockWorkOrderQualityEventCard'
import OperationDetailStockWorkQualityAppliedCard from '../../../components/new-cards/OperationDetailStockWorkQualityAppliedCard'

const StockAndWorkOrderSuppliesModal = ({ selectedOperation, refetcPage }) => {
    console.log(selectedOperation, "selecteddd ooppp")
    const { t } = useTranslation()

    const { data, refetch } = useQuery({
        queryKey: ["stockAndWorkOrder"],
        queryFn: async () =>
            await purchaseService?.listJunctionForWipOperation(
                selectedOperation?.operationId
            ),
        enabled: true,
        retry: 0,
    });
    console.log(data, "dataaaa")
    return (
        <div className='flex flex-col items-start w-full'>
            <div className="flex flex-row items-center gap-x-2 my-6">
                {selectedOperation?.image ? (
                    <div>
                        <CustomImage
                            borderRadius={2}
                            src={selectedOperation?.image}
                            style={{
                                width: 64,
                                height: 48,
                                minWidth: 64,
                                minHeight: 48,
                                borderRadius: 4,
                                borderWidth: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                display: "flex",
                                borderColor: "#0000001A",
                                backgroundColor: "#fff",
                            }}
                        />
                    </div>
                ) : (
                    <div className="w-[64px] min-w-[64px] h-[48px] min-h-[48px]  flex border-r  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                        {selectedOperation?.processName?.slice(0, 2)?.toUpperCase() || selectedOperation?.name?.slice(0, 2)?.toUpperCase() || "ALL"}
                    </div>
                )}
                <div className="flex flex-col items-start">
                    <p className="text-md font-semibold text-secondary-900">{selectedOperation?.name || "--"}</p>
                    <div className="flex flex-row items-center gap-x-1 bg-[#FFFAEB] rounded">
                        <p className="text-xs font-medium text-[#B54708]">{t("product:stock")}:{selectedOperation?.stockCode || "--"}</p>
                        <p className="text-xs font-medium text-[#B54708]">{`(${selectedOperation?.operationStep || "--"}.Op)`}</p>
                    </div>
                </div>
            </div>
            <div className='flex flex-row items-center w-full gap-x-3'>
                <div className='w-1/2'>
                    <StockWorkOrderSuppliesTab type={"planned"}
                        length={data?.filter((element)=> element?.purchaseRequest?.source === "stock")?.length || "0"}
                        children={
                            <StockListController
                                items={data}
                            />
                        }
                    />
                </div>
                <div className='w-1/2'>
                    <StockWorkOrderSuppliesTab type={"supplier"}
                    length={data?.filter((element)=> element?.purchaseRequest?.source != "stock")?.length || "0"}
                        children={
                            <SupplyListController
                                items={data}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default StockAndWorkOrderSuppliesModal

const StockListController = ({
    items,
}) => {
    return items?.map((item, index) => (
        <>
            {item?.purchaseRequest?.source === "stock" && (
                <div
                    key={index}
                    draggable
                    className="min-h-[80px] cursor-pointer mb-3 "
                >
                    <StockWorkOrderQualityEventCard
                        data={item}
                    />
                </div>
            )}
        </>
    ));
};

const SupplyListController = ({
    items,
}) => {
    return items?.map((item, index) => (
        <>
            {item?.purchaseRequest?.source != "stock" && (
                <div
                    key={index}
                    draggable
                    className="min-h-[80px] cursor-pointer mb-3 "
                >
                    <OperationDetailStockWorkQualityAppliedCard
                        data={item}
                    />
                </div>
            )}
        </>
    ));
};

