import React from "react";
import moment from "moment";
import { timeFormatter } from "../../../utils/timezoneFormatter";

export const SingleEventRect = ({ event, index, startPos, width, totalWidth, onClick }) => {
  return (
    <svg width={totalWidth} height={24}>
      <g className="relative">
        <defs>
          <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
            <path
              d="M-1,1 l2,-2
               M0,4 l4,-4'
               M3,5 l2,-2"
              style={{ stroke: "#000000", strokeWidth: 1 }}
            />
          </pattern>
        </defs>
        <rect
          onClick={onClick}
          key={index}
          x={startPos}
          y={0}
          width={width}
          height="24"
          fill={event?.color === "#FFFFFF" ? "url(#diagonalHatch)" : event?.color}
          style={{ cursor: "pointer" }}
          ry={"4"}
        ></rect>
        <>
          {event.color !== "#FFFFFF" &&
            (width >= 80 ? (
              <>
                <text
                  onClick={onClick}
                  style={{
                    cursor: "pointer",
                  }}
                  x={startPos + 2}
                  y="16"
                  fontFamily="Inter"
                  fontWeight={500}
                  font-size="12"
                  fill="#FFF"
                  // onMouseOver={() => handleMouseOver(event, startPos)}
                  // onMouseOut={handleMouseOut}
                >
                  {moment(timeFormatter(event.startDate)?.formatted).format("HH:mm")}
                </text>
                <text
                  onClick={onClick}
                  style={{
                    cursor: "pointer",
                  }}
                  x={startPos + width - 36}
                  y="16"
                  fontFamily="Inter"
                  fontWeight={500}
                  font-size="12"
                  fill="#FFF"
                  // onMouseOver={() => handleMouseOver(event, startPos)}
                  // onMouseOut={handleMouseOut}
                >
                  {moment(timeFormatter(event?.endDate)?.formatted).format("HH:mm")}
                </text>
              </>
            ) : width > 40 && width < 80 ? (
              <>
                <text
                  onClick={onClick}
                  style={{
                    cursor: "pointer",
                  }}
                  x={startPos + 2}
                  y="11"
                  fontFamily="Inter"
                  fontWeight={600}
                  font-size="10"
                  fill="#FFF"
                  // onMouseOver={() => handleMouseOver(event, startPos)}
                  // onMouseOut={handleMouseOut}
                >
                  {moment(timeFormatter(event.startDate)?.formatted).format("HH:mm")}
                </text>
                <text
                  onClick={onClick}
                  style={{
                    cursor: "pointer",
                  }}
                  x={startPos + width - 32}
                  y="21"
                  fontFamily="Inter"
                  fontWeight={600}
                  font-size="10"
                  fill="#FFF"
                  // onMouseOver={() => handleMouseOver(event, startPos)}
                  // onMouseOut={handleMouseOut}
                >
                  {moment(timeFormatter(event?.endDate)?.formatted).format("HH:mm")}
                </text>
              </>
            ) : width < 40 ? (
              <>
                <text
                  onClick={onClick}
                  style={{
                    cursor: "pointer",
                  }}
                  x={startPos + 5}
                  y="11"
                  fontFamily="Inter"
                  fontWeight={600}
                  font-size="10"
                  fill="#FFF"
                  // onMouseOver={() => handleMouseOver(event, startPos)}
                  // onMouseOut={handleMouseOut}
                >
                  {moment(timeFormatter(event.startDate)?.formatted).format("HH:mm")}
                </text>
                <text
                  onClick={onClick}
                  style={{
                    cursor: "pointer",
                  }}
                  x={startPos + width - 35}
                  y="21"
                  fontFamily="Inter"
                  fontWeight={600}
                  font-size="10"
                  fill="#FFF"
                  // onMouseOver={() => handleMouseOver(event, startPos)}
                  // onMouseOut={handleMouseOut}
                >
                  {moment(timeFormatter(event?.endDate)?.formatted).format("HH:mm")}
                </text>
              </>
            ) : (
              <></>
            ))}
        </>
      </g>
    </svg>
  );
};

export const SingleEventRect2 = ({
  event,
  y,
  handleMouseOver,
  handleMouseOut,
  index,
  startPos,
  width,
  widthS,
  totalWidth,
  selectedEnd,
  selectedStart,
  setTotalTime,
  setSelectedEnd,
  onClick,
}) => {
  return (
    <svg width={totalWidth} height={30}>
      <g className="relative">
        <rect
          onClick={onClick}
          key={index}
          x={startPos}
          y={1000}
          width={width}
          height="5"
          fill={event?.color == "#FFFFFF" ? "#F2F4F7" : event?.color}
          // onMouseOver={() => handleMouseOver(event, startPos)}
          // onMouseOut={handleMouseOut}
          style={{ cursor: "pointer" }}
          ry={"4"}
        ></rect>

        <>
          <text
            onClick={onClick}
            style={{
              cursor: "pointer",
            }}
            x={startPos}
            y="16"
            fontFamily="Inter"
            fontWeight={500}
            font-size="12"
            fill="black"
            // onMouseOver={() => handleMouseOver(event, startPos)}
            // onMouseOut={handleMouseOut}
          >
            {moment(timeFormatter(event.startDate)?.formatted).format("HH:mm")}
          </text>
        </>
      </g>
    </svg>
  );
};

export default { SingleEventRect, SingleEventRect2 };
