import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { authStore } from "../../../stores/auth.store";
import { companyService } from "../../../services/company.service";
import { Toast } from "../../../utils/toastify/toast";
import { CustomModal } from "../../../components";
import SavedShiftModal from "./SavedShiftModal";
import PlanShiftDay from "../../../components/modal/PlanShiftDay";
import AddHolidayModal from "../../../components/modal/AddHolidayModal";
import { useTranslation } from "react-i18next";

const ShiftColor = (color) => {
  switch (color) {
    case "#F9F5FF":
      return {
        background: "#F9F5FF",
        border: "#E9D7FE",
        text: "#6941C6",
      };
    case "#B692F6":
      return {
        background: "#F9F5FF",
        border: "#E9D7FE",
        text: "#6941C6",
      };
    case "#FEF3F2":
      return {
        background: "#FFFAEB",
        border: "#FEDF89",
        text: "#B54708",
      };
    case "#FEC84B":
      return {
        background: "#FFFAEB",
        border: "#FEDF89",
        text: "#B54708",
      };
    case "#F97066":
      return {
        background: "#FEF3F2",
        border: "#FECDCA",
        text: "#B42318",
      };
    case "#53B1FD":
      return {
        background: "#EFF8FF",
        border: "#B2DDFF",
        text: "#175CD3",
      };
    case "sm":
      return {
        background: "#FDF2FA",
        border: "#FCCEEE",
        text: "#C11574",
      };
    case "sm":
      return {
        background: "#F2F4F7",
        border: "#E4E7EC",
        text: "#344054",
      };
    case "#2ED3B7":
      return {
        background: "#ECFDF3",
        border: "#ABEFC6",
        text: "#067647",
      };
    default:
      return {
        background: "#F2F4F7",
        border: "#E4E7EC",
        text: "#344054",
      };
  }
};

const CalendarRow = ({ item, defaultStart, columnSize, index, containerWidth, type, menuType, refetch, data, isDailyAdd }) => {
  const {t} = useTranslation()
  const menuRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openHoldidayModal, setOpenHoldidayModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const standartMenu = [
    {
      name: t("addShift:editShift"),
      icon: <EditIcon />,
      onClick: () => {
        setOpenModal(true);
      },
    },
    {
      name: t("addShift:removeShift"),
      icon: <DeleteIcon />,
      onClick: async () => {
        if (item?.id) {
          if (type === "nonstandard") {
            await companyService.deleteNonstandardShift(item?.id).then((res) => {
              if (res.data.code === 0) {
                Toast("success", res.data.message);
                refetch();
              }
            });
          } else {
            await companyService.deleteStandardShift(item?.id).then((res) => {
              if (res.data.code === 0) {
                Toast("success", res.data.message);
                refetch();
              }
            });
          }
        } else {
          Toast("error", "Error");
        }
      },
    },
  ];
  const menuItems = menuType === "standart" ? standartMenu : [];
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div ref={menuRef} className={`w-full flex h-[66px] border-b ${index % 2 === 1 ? "bg-gray-50" : "bg-white"} `}>
        {!isDailyAdd && (
          <div className={`min-w-[228px] max-w-[228px] h-full border-r flex flex-col justify-between pl-5 py-3 relative`}>
            {item?.workingDays && (
              <>
                <p className="text-xs font-medium text-primary-700">
                  {type === "nonstandard"
                    ? `${moment(item?.start).format("DD.MM.YY")} - ${moment(item?.end).format("DD.MM.YY")}`
                    : moment.weekdays(item?.workingDays)}
                </p>
              </>
            )}
            <p className="text-secondary-600 text-sm">{item?.name || item?.shiftGroups?.name}</p>
            <button
              onClick={(e) => {
                setIsOpen(!isOpen);
              }}
              className="absolute w-5 h-5 flex items-center justify-center right-1"
            >
              <MenuIcon />
            </button>
            {isOpen && (
              <div
                className="w-[244px] p-1 bg-white border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1 z-[99999]"
                style={{ position: "absolute", top: -21, left: 228 }}
                onBlur={() => setIsOpen(false)}
              >
                {menuItems.map((d, index) => {
                  return (
                    <button
                      onClick={() => {
                        setTimeout(() => {
                          d.onClick();
                        }, 100);
                        setIsOpen(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[36px] flex items-center px-2 hover:bg-secondary-100 rounded`}
                    >
                      <div className="w-6">{d.icon}</div>
                      <p className={`font-medium ${index === 2 ? "text-red-500" : "text-secondary-700"} text-xs ml-1`}>{d.name}</p>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        )}

        <div className="h-full flex-1 flex relative items-center">
          {/* {item?.shiftGroups ? ( */}
          <>
            {item?.times?.map((time, index) => {
              const timeformatters = moment(timeFormatter(time.start, authStore?.user?.company?.timeZone).formatted)?.unix();
              const timeformattersEnd = moment(timeFormatter(time.end, authStore?.user?.company?.timeZone).formatted)?.unix();
              const formattedDefaultStart = timeFormatter(defaultStart)?.unix;
              const defaultEnd = formattedDefaultStart + 86400;
              return (
                <div
                  key={index}
                  style={{
                    width: (timeformattersEnd - timeformatters) * columnSize,
                    left: `${(timeformatters - formattedDefaultStart) * columnSize + containerWidth / 48}px`,
                    // backgroundColor: time?.index == 0 ? time.color : "",
                    backgroundColor: ShiftColor(time.color)?.background,
                    borderColor: ShiftColor(time.color)?.border,
                    borderWidth: 1,
                  }}
                  className={`h-[34px] absolute rounded-md flex items-center justify-between  overflow-hidden `}
                >
                  {time?.index == 0 ? (
                    <div className="w-full px-1 flex items-center justify-between">
                      <p style={{ color: ShiftColor(time.color)?.text }} className="text-xs font-medium truncate">
                        {moment(timeFormatter(time.start).formatted).format("HH:mm")}
                      </p>
                      <p style={{ color: ShiftColor(time.color)?.text }} className="text-xs font-medium truncate">
                        {moment(timeFormatter(time.end).formatted).format("HH:mm")}
                      </p>
                    </div>
                  ) : (
                    <div
                      key={index}
                      style={{
                        backgroundImage: `linear-gradient(45deg, ${ShiftColor(time.color)?.border} 25%, transparent 25%, transparent 50%, ${
                          ShiftColor(time.color)?.border
                        } 50%, ${ShiftColor(time.color)?.border} 75%, transparent 75%, #fff)`,
                        backgroundSize: "8px 8px", // her bir desenin boyutu (4 * 2px = 8px)
                        width: (timeformattersEnd - timeformatters) * columnSize,
                        height: "32px",
                        borderRadius: 6,
                      }}
                      className=""
                    ></div>
                  )}
                </div>
              );
            })}
          </>
          {/* ) : (
            <>
              <div
                onClick={() => {}}
                key={index}
                style={{
                  width: 85000 * columnSize,
                  left: `${700 * columnSize + containerWidth / 48}px`,
                }}
                className={`h-[40px] absolute rounded-md flex items-center justify-between  border bg-white  z-10 cursor-pointer`}
              >
                <div className="w-full px-1 flex items-center justify-center gap-x-3">
                  <PlusIcon />
                  <p className="text-sm text-secondary-700 font-medium">Vardiya Günü Ekle</p>
                </div>
              </div>
            </>
          )} */}
        </div>
      </div>
      <CustomModal
        modalTitle={t("addShift:planShiftDay")}
        isOpen={openModal}
        setIsOpen={setOpenModal}
        children={
          <SavedShiftModal
            data={data}
            setModalOpen={setOpenModal}
            id={item?.id}
            refetch={refetch}
            type={type}
            openPlanModal={setModalOpen}
            setSelectedGroup={setSelectedGroup}
          />
        }
      />
      <CustomModal
        modalTitle={t("addShift:editHoliday")}
        isOpen={openHoldidayModal}
        setIsOpen={setOpenHoldidayModal}
        children={<AddHolidayModal closeModal={() => setOpenHoldidayModal(false)} selectedGroup={selectedGroup} refetch={refetch} type={type} />}
      />
      <CustomModal
        modalTitle={t("addShift:planShiftDay")}
        setIsOpen={setModalOpen}
        isOpen={modalOpen}
        children={
          <PlanShiftDay
            selectedGroup={selectedGroup}
            refetch={refetch}
            closeModal={() => {
              setModalOpen(false);
              setOpenModal(false);
            }}
            type={type}
          />
        }
      />
    </>
  );
};

const MenuIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10.0001 10.833C10.4603 10.833 10.8334 10.4599 10.8334 9.99967C10.8334 9.53944 10.4603 9.16634 10.0001 9.16634C9.53984 9.16634 9.16675 9.53944 9.16675 9.99967C9.16675 10.4599 9.53984 10.833 10.0001 10.833Z"
        stroke="#98A2B3"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 4.99967C10.4603 4.99967 10.8334 4.62658 10.8334 4.16634C10.8334 3.7061 10.4603 3.33301 10.0001 3.33301C9.53984 3.33301 9.16675 3.7061 9.16675 4.16634C9.16675 4.62658 9.53984 4.99967 10.0001 4.99967Z"
        stroke="#98A2B3"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 16.6663C10.4603 16.6663 10.8334 16.2932 10.8334 15.833C10.8334 15.3728 10.4603 14.9997 10.0001 14.9997C9.53984 14.9997 9.16675 15.3728 9.16675 15.833C9.16675 16.2932 9.53984 16.6663 10.0001 16.6663Z"
        stroke="#98A2B3"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const PlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M8.00004 3.33301V12.6663M3.33337 7.99967H12.6667" stroke="#667085" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M1.91732 12.0776C1.94795 11.802 1.96326 11.6641 2.00497 11.5353C2.04197 11.421 2.09425 11.3122 2.16038 11.2119C2.23493 11.0989 2.33299 11.0008 2.52911 10.8047L11.3333 2.00053C12.0697 1.26415 13.2636 1.26415 14 2.00053C14.7364 2.73691 14.7364 3.93082 14 4.66719L5.19578 13.4714C4.99966 13.6675 4.9016 13.7656 4.78855 13.8401C4.68826 13.9062 4.57949 13.9585 4.46519 13.9955C4.33636 14.0372 4.19853 14.0525 3.92287 14.0832L1.66663 14.3339L1.91732 12.0776Z"
        stroke="#667085"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.6667 3.99967V3.46634C10.6667 2.7196 10.6667 2.34624 10.5213 2.06102C10.3935 1.81014 10.1895 1.60616 9.93865 1.47833C9.65344 1.33301 9.28007 1.33301 8.53333 1.33301H7.46667C6.71993 1.33301 6.34656 1.33301 6.06135 1.47833C5.81046 1.60616 5.60649 1.81014 5.47866 2.06102C5.33333 2.34624 5.33333 2.7196 5.33333 3.46634V3.99967M6.66667 7.66634V10.9997M9.33333 7.66634V10.9997M2 3.99967H14M12.6667 3.99967V11.4663C12.6667 12.5864 12.6667 13.1465 12.4487 13.5743C12.2569 13.9506 11.951 14.2566 11.5746 14.4484C11.1468 14.6663 10.5868 14.6663 9.46667 14.6663H6.53333C5.41323 14.6663 4.85318 14.6663 4.42535 14.4484C4.04903 14.2566 3.74307 13.9506 3.55132 13.5743C3.33333 13.1465 3.33333 12.5864 3.33333 11.4663V3.99967"
        stroke="#D92D20"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarRow;
