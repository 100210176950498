import React, { useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import { Tooltip } from "react-tooltip";
import NoAvatar from "../avatar/NoAvatar";
import AlertModal from "../alert-Modal/Alert-Modal";
import { useTranslation } from "react-i18next";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import { NoEquIcon } from "../../assets/icons/machineIcons";
import { PlusIconNew } from "../../assets/icons/productIcons";
import {
  AveragePieceIcon,
  ClockIcon2,
  DeleteIcon,
  DotsVerticalIcon,
} from "../../assets/icons/commonIcons";

const ExternalActiveAndPlannedCard = ({
  id,
  image,
  name,
  estimatedTime,
  piece,
  user,
  rightButton,
  status,
  refetch,
  type,
  onClick,
  deletePlannedExternal,
}) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const menuOptions = [
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const onDeleted = async () => {
    if (deletePlannedExternal) {
      await junctionService.deleteSupplierForJunction(id).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          refetch();
          setIsAlert(false);
          setDeleteId(id);
        } else {
          Toast("error", res.data.message);
          setIsAlert(false);
          setDeleteId("");
        }
      });
    } else {
      await junctionService.deleteJunction(id, deleteId).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          refetch();
          setIsAlert(false);
          setDeleteId(id);
        } else {
          Toast("error", res.data.message);
          setIsAlert(false);
          setDeleteId("");
        }
      });
    }
  };

  return (
    <>
      <div
        onClick={onClick}
        className={`flex w-full h-[80px]  items-center rounded border border-t-[4px] group relative ${
          type == "work" && status === "active"
            ? "border-success-500"
            : type === "setup"
            ? "border-[#EAAA08]"
            : "border-[#98A2B3]"
        } p-2 shadow-xs max-h-[80px] min-h-[80px] bg-white`}
      >
        {image ? (
          <img
            src={generateFileUrl(image)}
            className="w-[72px] min-w-[72px] h-[54px] rounded border border-secondary-300 "
          />
        ) : (
          <div className="w-[72px] min-w-[72px] h-[54px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
            <NoEquIcon />
          </div>
        )}
        <div className="flex-1 flex ml-2 flex-col gap-0.5 overflow-hidden">
          <div className="w-full overflow-hidden">
            <p className="truncate w-[300px] text-sm text-secondary-700 font-semibold">
              {name || "---"}
            </p>
          </div>
          <div className="flex flex-row gap-2 items-start">
            <div className="w-full flex flex-col items-start gap-0.5 overflow-hidden">
              <div className="flex flex-row gap-1">
                <ClockIcon2 />
                <p className="text-xs font-normal text-secondary-600">
                  {estimatedTime || "---"} {t("product:timeStarted")}
                </p>
              </div>

              <div className="flex flex-row gap-1">
                <AveragePieceIcon />
                <p className="text-xs font-normal text-secondary-600">
                  {piece || "---"}
                </p>
              </div>
            </div>
          </div>
        </div>

        {user ? (
          user?.avatar ? (
            <div
              onClick={rightButton}
              data-tooltip-id={`${id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${user?.name + " " + user?.lastName} `}
              className="ml-auto relative "
            >
              <img
                className="w-[54px] min-w-[54px] h-[54px] -mt-2 rounded-full border border-gray-300"
                src={generateFileUrl(user?.avatar)}
              />
            </div>
          ) : (
            <NoAvatar
              size={54}
              fontSize={22}
              name={user?.name}
              lastName={user?.lastName}
              color={"#475467"}
            />
          )
        ) : (
          <button
            onClick={rightButton}
            type="button"
            className="min-w-[50px] h-[50px] rounded-full border-dashed border border-gray-300 flex items-center justify-center mt-1"
          >
            <PlusIconNew color={"#344054"} />
          </button>
        )}
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[32px] right-[3px] z-[99] rounded-lg px-[6px] py-1 group-hover:flex">
            {menuOptions?.map((item, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "delete") {
                      setIsAlert(true);
                      setDeleteId(id);
                    }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    item?.value === "delete"
                      ? "hover:bg-[#FECDCA]"
                      : "hover:bg-secondary-100"
                  }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${
                      item?.value === "delete"
                        ? "text-[#D92D20]"
                        : "text-secondary-700"
                    }`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
      </div>
      <Tooltip id={`${id}`} />
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
    </>
  );
};

export default ExternalActiveAndPlannedCard;
