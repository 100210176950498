import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Toast } from "../../../utils/toastify/toast";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { productService } from "../../../services/product.service";
import { Button, Input, Loading } from "../../../components";
import Selector from "../../../components/inputs/Selector";
import { externalOperationService } from "../../../services/external-operation.service";
import { Loader } from "../../../assets/icons/stepsIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { CheckIcon } from "../../../assets/icons/PersonIcons";

export default function AddExternalOperation({
  t,
  selectedProduct,
  setIsOpen,
  refresh,
  externalUpdate,
  setItems = undefined,
  hasOrders = false,
}) {
  const [selectedProcess, setSelectedProcess] = useState();
  const [selectProcess, setSelectProcess] = useState();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [valuePercentage, setValuePercentage] = useState("");
  const [hasQualityControl, setHasQualityControl] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const schema = yup.object({
    entityId: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    madeTransaction: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    estimatedTime: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    costOfPiece: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    qualityPercent: yup
      .number()
      .max(100, `${t("validation:max100")}`)
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    type: yup.string(),
    hasQualityControl: yup.boolean(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "external",
    },
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  const triggerSupplier = (supplierId) => {
    if (selectedSuppliers?.includes(supplierId)) {
      setSelectedSuppliers(
        selectedSuppliers.filter((item) => item !== supplierId)
      );
    } else {
      setSelectedSuppliers([...selectedSuppliers, supplierId]);
    }
  };

  const handleProcess = async () => {
    setFetching(true);
    await externalOperationService.listOperation().then((res) => {
      setSelectedProcess(res.data);
      if (externalUpdate?.process?.id) {
        const findGroupedSuppliers = res?.data?.find(
          (e) => e?.id === externalUpdate?.process?.id
        );
        setSuppliers(findGroupedSuppliers?.supplier);
        setSelectedSuppliers(
          externalUpdate?.plannableSupplier?.length > 0
            ? externalUpdate?.plannableSupplier
            : []
        );
      }
    });
    setFetching(false);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d+(\.\d{0,2})?$/.test(inputValue) && parseFloat(inputValue) <= 100) {
      setValue("qualityPercent", inputValue);
      setValuePercentage(inputValue);
    } else if (inputValue === "" || inputValue === ".") {
      setValue("qualityPercent", "0");
      setValuePercentage("");
    }
  };

  const onSubmit = async (data) => {
    if (setItems != undefined) {
      if (externalUpdate) {
        setItems((prev) => [
          ...prev.filter((item) => item?.step !== externalUpdate?.step),
          {
            id: externalUpdate?.operationId,
            status: "passive",
            type: "external",
            step: externalUpdate?.step,
            externalOperation: {
              step: externalUpdate?.step,
              ...data,
              plannableSupplier: selectedSuppliers,
              process: {
                id: data.entityId,
                name: selectProcess?.label,
              },
            },
          },
        ]);
      } else {
        setItems((prev) => [
          ...prev,
          {
            status: "passive",
            type: "external",
            step: prev?.length + 1,
            externalOperation: {
              step: prev?.length + 1,
              ...data,
              plannableSupplier: selectedSuppliers,
              process: {
                id: data.entityId,
                name: selectProcess?.label,
              },
            },
          },
        ]);
      }
      closeModal();
    } else {
      setLoading(true);
      if (externalUpdate && externalUpdate?.id) {
        await productService
          .updateProductOperation(externalUpdate?.id, {
            ...data,
            plannableSupplier: selectedSuppliers,
          })
          .then((res) => {
            if (res.data.code === 0) {
              Toast("success", res?.data?.message);
              refresh();
              closeModal();
            } else {
              Toast("error", res?.data?.message);
            }
          });
      } else {
        await productService
          .addOperation(selectedProduct.id, {
            ...data,
            plannableSupplier: selectedSuppliers,
          })
          .then((res) => {
            if (res?.data?.code == 0) {
              Toast("success", res?.data?.message);
              closeModal();
              refresh();
            } else if (res?.data?.code == 2) {
              Toast("error", res?.data?.message);
              closeModal();
              refresh();
            } else {
              Toast("error", res?.data?.message);
              closeModal();
              refresh();
            }
          });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (externalUpdate && externalUpdate?.type) {
      setValue("entityId", externalUpdate.process.id);
      setValue("madeTransaction", externalUpdate.madeTransaction);
      setValue("estimatedTime", externalUpdate.estimatedTime);
      setValue("costOfPiece", externalUpdate.costOfPiece);
      setValue("qualityPercent", externalUpdate.qualityPercent);
      setValuePercentage(externalUpdate?.qualityPercent);
      setSelectProcess({
        value: externalUpdate.process.id,
        label: externalUpdate.process.name,
      });
      setValue("hasQualityControl", externalUpdate?.hasQualityControl);
      setHasQualityControl(externalUpdate?.hasQualityControl);
    } else {
      setValue("entityId", "");
      setValue("madeTransaction", "");
      setValue("estimatedTime", "");
      setValue("costOfPiece", "");
      setValue("qualityPercent", 0);
    }
  }, []);

  useEffect(() => {
    handleProcess();
  }, []);

  return (
    <>
      {fetching ? (
        <div className="w-[696px] h-[440px] flex items-center justify-center">
          <Loading size={44} primary={"#B54708"} secondary={"#fff"} />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="min-w-[696px] max-w-[696px] h-full scrollbar-hide mt-5"
        >
          <div className="w-full flex gap-5 h-full">
            <div className="w-1/2 gap-4 flex flex-col h-full">
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Selector
                    theme={"product"}
                    items={selectedProcess?.map((d) => {
                      return {
                        label: d?.name,
                        value: d?.id,
                        iconKey: d?.iconKey,
                      };
                    })}
                    label={t("product:externalOperation")}
                    onChange={(selectedOption) => {
                      const selectedEntityId = selectedOption
                        ? selectedOption
                        : null;
                      const selectedProcessFind = selectedProcess?.find(
                        (e) => e?.id === selectedOption
                      );
                      setSelectProcess(selectedProcessFind);
                      onChange(selectedOption);
                      setSuppliers(selectedProcessFind?.supplier);
                      setSelectedSuppliers(
                        selectedProcessFind?.supplier?.map((e) => e.id)
                      );
                    }}
                    onBlur={onBlur}
                    value={value}
                    validate={errors?.entityId ? "error" : ""}
                    errorMessage={
                      errors?.entityId ? errors?.entityId?.message : ""
                    }
                    disabled={hasOrders}
                  />
                )}
                name="entityId"
              />
              <div className="flex flex-col w-full">
                <span
                  className={`text-sm pl-0  w-full text-secondary-700 font-medium truncate dark:text-[#D6D6D6] mb-1.5`}
                >
                  {t("product:suppliers")}
                </span>
                <div className="min-h-[312px] max-h-[312px] w-full flex flex-col overflow-y-auto scrollbar-hide p-1.5">
                  {suppliers?.map((item, index) => {
                    const sameSupp = selectedSuppliers?.includes(item?.id);
                    return (
                      <div
                        onClick={() => triggerSupplier(item?.id)}
                        key={index}
                        className="w-full border-b py-3 cursor-pointer flex items-center hover:bg-gray-50 px-1.5 active:bg-white active:ring-4 active:ring-gray-50 active:border-transparent active:rounded-xl"
                      >
                        <img
                          className="w-10 h-8 rounded-lg"
                          src={generateFileUrl(item?.image)}
                        />
                        <p className="text-sm font-semibold text-gray-700 ml-3 flex-1">
                          {item?.name}
                        </p>
                        <div
                          className={`w-4 h-4 flex items-center justify-center rounded ${
                            sameSupp
                              ? "bg-orange-600 "
                              : "bg-gray-100 border border-gray-300"
                          }`}
                        >
                          {sameSupp && <CheckIcon />}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-px flex-1 border-l border-gray-300 mb-5" />
            <div className="w-1/2">
              <div className="flex flex-col gap-4">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("product:processtypeonproduct")}
                      onChange={onChange}
                      onBlur={onBlur}
                      theme={"product"}
                      value={value}
                      validate={errors?.madeTransaction ? "error" : ""}
                      errorMessage={
                        errors?.madeTransaction
                          ? errors?.madeTransaction?.message
                          : ""
                      }
                    />
                  )}
                  name="madeTransaction"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("product:estimatedTotalTime")}
                      onChange={onChange}
                      onBlur={onBlur}
                      theme={"product"}
                      value={value}
                      validate={errors?.estimatedTime ? "error" : ""}
                      errorMessage={
                        errors?.estimatedTime
                          ? errors?.estimatedTime?.message
                          : ""
                      }
                    />
                  )}
                  name="estimatedTime"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("product:costOfPiece")}
                      onChange={onChange}
                      theme={"product"}
                      onBlur={onBlur}
                      value={value}
                      validate={errors?.costOfPiece ? "error" : ""}
                      errorMessage={
                        errors?.costOfPiece ? errors?.costOfPiece?.message : ""
                      }
                    />
                  )}
                  name="costOfPiece"
                />
                <label className="flex items-center">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <input
                        className="rounded"
                        type="checkbox"
                        onChange={(e) => {
                          setHasQualityControl(e.target.checked);
                          setValue("qualityPercent", 0);
                          setValuePercentage("");
                          setValue("hasQualityControl", e.target.checked);
                        }}
                        onBlur={onBlur}
                        checked={value}
                      />
                    )}
                    name="hasQualityControl"
                  />
                  <p className="text-sm text-secondary-600  ml-2">
                    {" "}
                    {t("person:qualityControl")}
                  </p>
                </label>
                <Input
                  label={t("product:qualityPercent")}
                  value={valuePercentage}
                  theme={"product"}
                  onChange={handleChange}
                  isPercentage
                  disabled={hasQualityControl ? false : true}
                />
                <div className="flex w-full gap-3">
                  <Button
                    colorType={"secondary-gray"}
                    label={t("buttons:cancel")}
                    size={"lg"}
                    onClick={() => setIsOpen(false)}
                  />
                  {loading ? (
                    <div className="flex items-center justify-center bg-[#DC6803]  rounded-lg w-full h-[46px] max-h-[46px]">
                      <Loader
                        currentColor="#DC6803"
                        currentFill="currentFill"
                      />
                    </div>
                  ) : (
                    <Button
                      size={"lg"}
                      colorType={"primary-product"}
                      label={t("buttons:add")}
                      type="submit"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
