import React from 'react'
import { ContinueModalsIcon } from '../new-cards/card-icons'
import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import { junctionService } from '../../services/junction.service'
import { Toast } from '../../utils/toastify/toast'

const IsContiunueModal = ({onClose,data,refetch}) => {
  let filteredSubEvent = data?.subEvent?.filter((item) => {
    return  item?.startDate && item?.endDate == null ;
  });

    const {t} = useTranslation()
    const ContinueEvent = async () => {
      await junctionService.startOrEndSubEvent('end',filteredSubEvent[0]?.id).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          refetch();
         onClose()
          
        } else {
          Toast("error", res.data.message);
          onClose()
        }
      });
    };
  return (
    <div className="flex flex-col items-center justify-center w-[400px]">
    <div className="flex items-center justify-center p-3 w-12 h-12 bg-[#DCFAE6] rounded-[6px]">
      <ContinueModalsIcon />
    </div>
    <p className="font-semibold text-lg text-secondary-900 mt-4">{t("product:resumeWorkAction")}</p>
    <p className="text-center text-secondary-500 font-normal text-sm w-[300px]">
    {t("product:resumeWorkPaused")}
    </p>
    <div className="flex flex-row items-center w-full gap-x-3 mt-8">
      <Button onClick={onClose} colorType={"secondary-gray"} size={"lg"} label={t("buttons:stop")} />
      <Button onClick={ContinueEvent} colorType={"primary-machine"} size={"lg"} label={t("password:continue")} />
    </div>
  </div>
  )
}

export default IsContiunueModal