import React from "react";
import { DeleteIcon, EditIcon } from "../../../assets/icons/departmentIcon";
import { Button } from "../../../components";

const DepartmentCard = ({ name, editOnClick, deleteOnclick }) => {
  return (
    <div className="w-full bg-white min-h-[60px] max-h-[60px]  border border-l-[8px] dark:bg-[#141414] dark:border-[#424242] border-gray-300 shadow-xs rounded-lg flex items-center justify-between px-3">
      <div className=" text-sm font-semibold text-[#101828] dark:text-[#FAFAFA]">
        {name || "No Name"}
      </div>
      <div className="flex flex-row gap-4 items-center justify-center">
        <Button
          iconLeft={<EditIcon />}
          colorType={"tertiary-gray"}
          size={"sm"}
          onClick={editOnClick}
        />
        <Button
          iconLeft={<DeleteIcon />}
          colorType={"tertiary-error"}
          size={"sm"}
          onClick={deleteOnclick}
        />
      </div>
    </div>
  );
};

export default DepartmentCard;
