import React, { useState } from 'react'
import MaterialSupplyPlanModalRawCard from '../junction/MaterialSupplyPlanModalRawCard'
import EditRequestRawMaterialCard from '../../pages/product/cards/EditRequestRawMaterialCard'
import Input from '../inputs/Input'
import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import { purchaseService } from '../../services/purchase.service'
import { Toast } from '../../utils/toastify/toast'

const EditRequestQuantityModal = ({ item, refetch, onClose }) => {
    const { t } = useTranslation()
    const [requestedQuantity, setRequstedQuantity] = useState("");

    const onSubmit = async () => {
        const sendData = {
            orderedUnit: requestedQuantity,
        };
        await purchaseService
            .updateTransactionOrderedUnit(item?.id, sendData)
            .then((response) => {
                if (requestedQuantity == 0) {
                    Toast("error", " Değer 0 girilemez");
                } else {
                    if (response?.data?.code == 0) {
                        onClose()
                        Toast("success", response?.data?.message);
                        refetch()
                    } else {
                        Toast("error", response?.data?.message);
                    }
                }
            });
    };
    return (
        <div className='flex flex-col w-full'>
            <div className='mt-5 w-full'>
                <EditRequestRawMaterialCard item={item} />
            </div>
            <div className='w-full mt-5'>
                <Input
                    onChange={({ target }) => {
                        setRequstedQuantity(target?.value);
                    }}
                    value={requestedQuantity}
                    theme={"product"}
                    label={t("product:requestedQuantitys")}
                />
            </div>
            <div className='flex flex-row items-center mt-6 gap-x-3 w-full'>
                <Button label={t("buttons:stop")} onClick={() => onClose()} colorType={"secondary-gray"} size={"md"} />
                <Button type={"button"} onClick={() => onSubmit()} label={t("buttons:save")} colorType={"primary-product"} size={"md"} />
            </div>
        </div>
    )
}

export default EditRequestQuantityModal