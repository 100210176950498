import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { purchaseService } from '../../../services/purchase.service';
import { Toast } from '../../../utils/toastify/toast';
import AlertModal from '../../alert-Modal/Alert-Modal';
import { Tooltip } from 'react-tooltip';
import { DotsVerticalIcon, EquipmentEditIcon } from '../card-icons';
import CustomImage from '../../CustomImage';
import NoAvatar from '../../avatar/NoAvatar';
import { PollDeleteIcon } from '../../../assets/icons/PersonIcons';
import moment from "moment";

const OperationDetailQualityAppliedCard = ({ data, refetch, onClick, isModal, setPurchaseQualityForm, setPurchaseQualityFormData }) => {
  console.log(data, "DATA QUALITYYYYYY")

  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const menuOptions = [
    {
      label: t("buttons:edit"),
      value: "editForm",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <PollDeleteIcon />,
    },
  ];
  const onDeleted = async () => {
    await purchaseService.deletePlannedPurchaseNew("accepted", data?.id).then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data?.id);
      } else {
        Toast("error", res?.data?.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };
  return (
    <>
      <div onClick={onClick} className="flex w-full flex-row justify-center items-center group relative cursor-grab">
        <div className={`flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 mr-auto`}>
          <div className={`w-full border-t-4 rounded-t-[8px] border-t-success-500`}></div>
          <div className={`flex w-full border-b h-[55px]`}>
            {(
              data?.purchaseRequest?.rawMaterial
                ? data?.purchaseRequest?.rawMaterial?.image
                : data?.purchaseRequest?.productInternalOperation
                  ? data?.purchaseRequest?.productInternalOperation?.product?.image
                  : data?.purchaseRequest?.productExternalOperation
                    ? data?.purchaseRequest?.productExternalOperation?.product?.image
                    : data?.purchaseRequest?.product
                      ? data?.purchaseRequest?.product?.image
                      : "--"
            ) ? (
              <div data-tooltip-id={`${data?.id}`}
                data-tooltip-place="right"
                data-tooltip-content={`${t("product:productName")}: ${data?.purchaseRequest?.rawMaterial
                  ? data?.purchaseRequest?.rawMaterial?.name || "--"
                  : data?.purchaseRequest?.productInternalOperation
                    ? data?.purchaseRequest?.productInternalOperation?.product?.name || "--"
                    : data?.purchaseRequest?.productExternalOperation
                      ? data?.purchaseRequest?.productExternalOperation?.product?.name || "--"
                      : data?.purchaseRequest?.product
                        ? data?.purchaseRequest?.product?.name || "--"
                        : "--"} - ${t("product:stock")}: ${data?.purchaseRequest?.rawMaterial
                          ? data?.purchaseRequest?.rawMaterial?.stockCode || "--"
                          : data?.purchaseRequest?.productInternalOperation
                            ? `${data?.purchaseRequest?.productInternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productInternalOperation?.step}.Op)`
                            : data?.purchaseRequest?.productExternalOperation
                              ? `${data?.purchaseRequest?.productExternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productExternalOperation?.step || "--"}.Op)`
                              : data?.purchaseRequest?.product
                                ? data?.purchaseRequest?.product?.stockCode || "--"
                                : "--"}`}>
                <CustomImage
                  src={
                    data?.purchaseRequest?.rawMaterial
                      ? data?.purchaseRequest?.rawMaterial?.image
                      : data?.purchaseRequest?.productInternalOperation
                        ? data?.purchaseRequest?.productInternalOperation?.product?.image
                        : data?.purchaseRequest?.productExternalOperation
                          ? data?.purchaseRequest?.productExternalOperation?.product?.image
                          : data?.purchaseRequest?.product
                            ? data?.purchaseRequest?.product?.image
                            : "--"
                  }
                  style={{
                    width: 72,
                    height: 54,
                    minWidth: 72,
                    minHeight: 54,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRadius: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-place="right"
                data-tooltip-content={`${t("product:productName")}: ${data?.purchaseRequest?.rawMaterial
                  ? data?.purchaseRequest?.rawMaterial?.name || "--"
                  : data?.purchaseRequest?.productInternalOperation
                    ? data?.purchaseRequest?.productInternalOperation?.product?.name || "--"
                    : data?.purchaseRequest?.productExternalOperation
                      ? data?.purchaseRequest?.productExternalOperation?.product?.name || "--"
                      : data?.purchaseRequest?.product
                        ? data?.purchaseRequest?.product?.name || "--"
                        : "--"} - ${t("product:stock")}: ${data?.purchaseRequest?.rawMaterial
                          ? data?.purchaseRequest?.rawMaterial?.stockCode || "--"
                          : data?.purchaseRequest?.productInternalOperation
                            ? `${data?.purchaseRequest?.productInternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productInternalOperation?.step}.Op)`
                            : data?.purchaseRequest?.productExternalOperation
                              ? `${data?.purchaseRequest?.productExternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productExternalOperation?.step || "--"}.Op)`
                              : data?.purchaseRequest?.product
                                ? data?.purchaseRequest?.product?.stockCode || "--"
                                : "--"}`}
                className="w-[72px] min-w-[72px] h-[54px] min-h-[54px]  flex border-r  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">

                {data?.purchaseRequest?.rawMaterial
                  ? data?.purchaseRequest?.rawMaterial?.name.slice(0, 2)?.toUpperCase()
                  : data?.purchaseRequest?.productInternalOperation
                    ? data?.purchaseRequest?.productInternalOperation?.product?.name.slice(0, 2)?.toUpperCase()
                    : data?.purchaseRequest?.productExternalOperation
                      ? data?.purchaseRequest?.productExternalOperation?.product?.name.slice(0, 2)?.toUpperCase()
                      : data?.purchaseRequest?.product
                        ? data?.purchaseRequest?.product?.name.slice(0, 2)?.toUpperCase()
                        : "--"}
              </div>
            )}
            <div className="flex flex-col w-full min-h-full">
              <div className="flex w-full h-1/2 border-b px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="text-xxs">{t("product:approveds")}</p>
                <p className="font-medium text-xs text-[#079455]">{data?.wipUnit || 0}</p>
              </div>
              <div className="flex w-full h-1/2 px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="text-xxs">{t("product:returns")}</p>
                <p className="font-medium text-xs text-[#D92D20]">{data?.rejectedUnit || 0}</p>
              </div>
            </div>
          </div>

          <div className={`flex flex-col items-start w-full pl-3 pr-[44px] gap-y-[2px]  py-2`}>
            <p className="text-xs font-normal text-[#667085]  truncate w-[99%]">
              {t("product:stock")}: {" "}
              {data?.purchaseRequest?.rawMaterial
                ? data?.purchaseRequest?.rawMaterial?.stockCode || "--"
                : data?.purchaseRequest?.productInternalOperation
                  ? `${data?.purchaseRequest?.productInternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productInternalOperation?.step}.Op)`
                  : data?.purchaseRequest?.productExternalOperation
                    ? `${data?.purchaseRequest?.productExternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productExternalOperation?.step || "--"}.Op)`
                    : data?.purchaseRequest?.product
                      ? data?.purchaseRequest?.product?.stockCode || "--"
                      : "--"}
            </p>
            <p className="text-xs font-semibold text-[#344054] truncate w-[99%]">
              {data?.purchaseRequest?.rawMaterial
                ? data?.purchaseRequest?.rawMaterial?.name || "--"
                : data?.purchaseRequest?.productInternalOperation
                  ? data?.purchaseRequest?.productInternalOperation?.product?.name || "--"
                  : data?.purchaseRequest?.productExternalOperation
                    ? data?.purchaseRequest?.productExternalOperation?.product?.name || "--"
                    : data?.purchaseRequest?.product
                      ? data?.purchaseRequest?.product?.name || "--"
                      : "--"}
            </p>
          </div>
        </div>

        <div className="cursor-pointer flex absolute w-[50px] h-[50px] min-w-[50px] min-h-[50px] z-[100] bottom-1">
          {data?.responsibleUser?.avatar ? (
            <div className="cursor-pointer">
              <CustomImage
                src={data?.responsibleUser?.avatar}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                  borderWidth: 2,
                  borderStyle: "solid",
                  boxShadow: "0 0 0 2px #00000008",
                }}
              />
            </div>
          ) : (
            <div className="cursor-pointer">
              <NoAvatar size={48} fontSize={22} name={data?.responsibleUser?.name} lastName={data?.responsibleUser?.lastName} color={"#B54708"} />
            </div>
          )}
        </div>
        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 ml-auto">
          <div className={`w-full border-t-4 rounded-t-[8px] border-t-success-500`}></div>
          <div className={`flex w-full border-b justify-end h-[55px]`}>
            <div className="flex flex-col w-full min-h-full">
              <div className="flex w-full h-1/2 border-b px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="font-medium text-xs">{moment(data?.createdDate).format("DD.MM.YYYY") || "--"}</p>
                <p className="text-xxs">{t("product:deliverys")}</p>
              </div>
              <div className="flex w-full h-1/2 px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="font-medium text-xs">{moment(data?.qualityControlDate ? data?.qualityControlDate : new Date()).format("DD.MM.YYYY") || "--"}</p>
                <p className="text-xxs">{t("product:control")}</p>
              </div>
            </div>

            {data?.purchaseRequest?.source === "stock" ? (
              <div className="w-[72px] min-w-[72px] h-[54px] min-h-[54px]  flex border-r  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                <StockIcons />
              </div>
            ) : (
              <>
                {data?.supplier?.image ? (
                  <div>
                    <CustomImage
                      src={data?.supplier?.image}
                      style={{
                        width: 72,
                        height: 54,
                        minWidth: 72,
                        minHeight: 54,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                        borderRadius: 0,
                        borderLeftWidth: 1,
                        borderRightWidth: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[72px] min-w-[72px] h-[54px] min-h-[54px]  flex border-r  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                    {data?.supplier?.name.slice(0, 2)?.toUpperCase()}
                  </div>
                )}
              </>
            )}
          </div>

          <div className={`flex flex-col items-end w-full pr-3 pl-[44px] gap-y-[2px] py-2`}>
            {data?.purchaseRequest?.source === "stock" ? (
              <p className="text-xs text-[#667085]">--</p>
            ) : (
              <p className="text-xs text-[#667085]">MS-{data?.plannedJunctionRef?.msNo || "--"}</p>
            )}

            <p className="text-xs font-semibold text-end text-[#344054] truncate w-[99%]">
              {data?.purchaseRequest?.source === "stock" ? (
                <>
                 {t("product:fromStoks")}
                </>
              ) : (
                <>
                  {data?.supplier?.name || "--"}
                </>
              )}
            </p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className={`hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white ${isModal == true ? "group-hover:hidden" : "group-hover:flex"}  right-[3px] top-[3px] z-[140] items-center justify-center cursor-pointer`}
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[140] rounded-lg px-[6px] py-1 ${isModal == true ? "group-hover:hidden" : "group-hover:flex"}`}
          >
            {menuOptions?.map((item, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "delete") {
                      setIsAlert(true);
                    }
                    if (item.value === "editForm") {
                      setPurchaseQualityFormData(data)
                      setPurchaseQualityForm(true);
                    }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                    }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
        <Tooltip className="z-[120]" id={`${data?.id}`} />
      </div>
      <AlertModal
        title={"Sil"}
        subTitle={"Kalitesi bitmiş işi silmek istediğinize emin misiniz?"}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(data?.id);
        }}
      />
    </>
  )
}

export default OperationDetailQualityAppliedCard


export const StockIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M22.6665 16.0041V3.11668C22.6665 2.86816 22.465 2.66668 22.2165 2.66668H12.9348M22.6665 16.0041H28.8888C29.1374 16.0041 29.3389 16.2057 29.3388 16.4543L29.3334 28.8892C29.3333 29.1377 29.1318 29.339 28.8834 29.339H15.9998M22.6665 16.0041H15.9998M9.33317 16.0041V3.11668C9.33317 2.86816 9.53464 2.66668 9.78317 2.66668H12.9348M9.33317 16.0041H15.9998M9.33317 16.0041H6.2665M15.9998 16.0041V29.339M15.9998 29.339H3.1165C2.86797 29.339 2.6665 29.1375 2.6665 28.889V16.4541C2.6665 16.2056 2.86798 16.0041 3.1165 16.0041H6.2665M12.9348 2.66668V8.65671C12.9348 8.81556 13.1111 8.91101 13.2441 8.82421L15.754 7.18645C15.9034 7.08896 16.0963 7.08894 16.2457 7.18639L18.7573 8.82434C18.8903 8.91111 19.0665 8.81565 19.0665 8.65682V2.66666M6.2665 16.0041V21.9942C6.2665 22.1531 6.44272 22.2485 6.57576 22.1618L9.08735 20.5238C9.23675 20.4263 9.42959 20.4263 9.57899 20.5238L12.0906 22.1618C12.2236 22.2485 12.3998 22.1531 12.3998 21.9942V16.0041M19.601 16.0041V21.9943C19.601 22.1531 19.7772 22.2485 19.9102 22.1618L22.4218 20.5238C22.5712 20.4264 22.7641 20.4264 22.9135 20.5238L25.4251 22.1618C25.5581 22.2485 25.7343 22.1531 25.7343 21.9943V16.0041" stroke="#B54708" stroke-width="1.75" />
    </svg>
  );
};