import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import CustomImage from "../../../../components/CustomImage";
import { ClockIcon, NoEquIcon } from "../../../../assets/icons/machineIcons";
import NoAvatar from "../../../../components/avatar/NoAvatar";
import { DeleteIcon, FinishJunctionIcon, NewDotsIcon, PieceIcon } from "../../../../components/new-cards/card-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { purchaseService } from "../../../../services/purchase.service";
import { Toast } from "../../../../utils/toastify/toast";
import AlertModal from "../../../../components/alert-Modal/Alert-Modal";
import { CustomModal } from "../../../../components";
import JunctionUpdate from "../../../../components/modal/JunctionUpdate";
import moment from "moment";
import FinishJunctionModal from "../../../../components/modal/FinishJunctionModal";
const MaterialSupplyPlannedActiveCard = ({ item, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isJunction, setIsJunction] = useState(false);
  // const [junctionType, setJunctionType] = useState("internal");
  // const [finishJunctionId, setFinishJunctionId] = useState(null);
  // const [productType, setProductType] = useState("standart");

  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);

  const menuOptions = [
    // {
    //   label: t("product:finishJuction"),
    //   value: "finishJunction",
    //   icon: <FinishJunctionIcon />,
    // },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> }];
  const onDeleted = async () => {
    await purchaseService.deletePurchase(item?.id).then((res) => {
      if (res.data.code == 0) {
        Toast("success", "Junction deleted");
        refetch();
        setIsAlert(false);
        setDeleteId(item?.id);
      } else {
        Toast("error", "Junction not deleted");
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };
  return (
    <>
      <div
        className={`flex w-full flex-col border border-t-4 cursor-pointer ${item?.type == "work" ? "border-t-success-500 " : item?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
          } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
      >
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
            {item?.purchaseRequest?.rawMaterial?.image ||
              item?.purchaseRequest?.productInternalOperation?.product?.image ||
              item?.purchaseRequest?.productExternalOperation?.product?.image ||
              item?.purchaseRequest?.product?.image ? (
              <div
                data-tooltip-id={`${item?.id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${t("product:productName")} : ${item?.purchaseRequest?.rawMaterial
                  ? item?.purchaseRequest?.rawMaterial?.name
                  : item?.purchaseRequest?.productInternalOperation
                    ? item?.purchaseRequest?.productInternalOperation?.product?.name
                    : item?.purchaseRequest?.productExternalOperation
                      ? item?.purchaseRequest?.productExternalOperation?.product?.name
                      : item?.purchaseRequest?.product
                        ? item?.purchaseRequest?.product?.name
                        : "--"} / ${t("product:stockCodeRawMaterial")} : ${item?.purchaseRequest?.rawMaterial?.stockCode ||
                        item?.purchaseRequest?.product?.stockCode ||
                        item?.purchaseRequest?.productInternalOperation?.product?.stockCode ||
                        item?.purchaseRequest?.productExternalOperation?.product?.stockCode ||
                        "--"
                  } `}
              >
                <CustomImage
                  borderRadius={2}
                  src={
                    item?.purchaseRequest?.rawMaterial
                      ? item?.purchaseRequest?.rawMaterial?.image
                      : item?.purchaseRequest?.productInternalOperation
                        ? item?.purchaseRequest?.productInternalOperation?.product?.image
                        : item?.purchaseRequest?.productExternalOperation
                          ? item?.purchaseRequest?.productExternalOperation?.product?.image
                          : item?.purchaseRequest?.product
                            ? item?.purchaseRequest?.product?.image
                            : ""
                  }
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div data-tooltip-id={`${item?.id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${t("product:productName")} : ${item?.purchaseRequest?.rawMaterial
                  ? item?.purchaseRequest?.rawMaterial?.name
                  : item?.purchaseRequest?.productInternalOperation
                    ? item?.purchaseRequest?.productInternalOperation?.product?.name
                    : item?.purchaseRequest?.productExternalOperation
                      ? item?.purchaseRequest?.productExternalOperation?.product?.name
                      : item?.purchaseRequest?.product
                        ? item?.purchaseRequest?.product?.name
                        : "--"} / ${t("product:stockCodeRawMaterial")} : ${item?.purchaseRequest?.rawMaterial?.stockCode ||
                        item?.purchaseRequest?.product?.stockCode ||
                        item?.purchaseRequest?.productInternalOperation?.product?.stockCode ||
                        item?.purchaseRequest?.productExternalOperation?.product?.stockCode ||
                        "--"
                  } `} className="w-[64px] min-w-[64px] min-h-[48px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}

            <div className="flex flex-col items-start gap-y-[6px]">
              <div className="flex flex-row items-center gap-x-1 w-full">
                <p className="text-sm font-semibold text-secondary-900 truncate w-[210px]">{item?.purchaseRequest?.rawMaterial
                  ? item?.purchaseRequest?.rawMaterial?.name
                  : item?.purchaseRequest?.productInternalOperation
                    ? item?.purchaseRequest?.productInternalOperation?.product?.name
                    : item?.purchaseRequest?.productExternalOperation
                      ? item?.purchaseRequest?.productExternalOperation?.product?.name
                      : item?.purchaseRequest?.product
                        ? item?.purchaseRequest?.product?.name
                        : "--"}</p>
              </div>

              <div className="flex flex-row items-center gap-x-2">
                <div className="flex flex-row items-center">
                  <ClockIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{moment(item?.startDate).format("HH:mm")}</p>
                  <p className="text-xxs font-normal ml-0.5 mt-[1px] text-secondary-600">{t("product:timeStarted")}</p>
                </div>
              </div>
            </div>
          </div>
          {item?.responsibleUser?.avatar ? (
            <div
              data-tooltip-id={`${item?.id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${item?.responsibleUser?.name + " " + item?.responsibleUser?.lastName} `}
              className="cursor-pointer ml-auto"
            >
              <CustomImage
                src={item?.responsibleUser?.avatar}
                style={{
                  width: 48,
                  height: 48,
                  flexDirection: "column",
                  borderRadius: 100,
                  display: "flex",
                  border: "1px solid #0000001A",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div
              data-tooltip-id={`${item?.id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${item?.responsibleUser?.name + " " + item?.responsibleUser?.lastName} `}
              className="cursor-pointer ml-auto"
            >
              <NoAvatar size={48} fontSize={22} name={item?.responsibleUser?.name} lastName={item?.responsibleUser?.lastName} color={"#B54708"} />
            </div>
          )}
        </div>
        <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB]  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
          <div className="flex flex-row items-center">
            <PieceIcon />
            <p className="text-xs font-medium ml-1 text-secondary-600">{item?.orderedUnit || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:plannedQuantity")}</p>
          </div>

          {/* <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 pl-2 pr-1 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
        <p className="text-xxs text text-secondary-600 font-semibold">{t("product:qualityNew")}</p>
        <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded-[2px]">
          <p className="text-xxs font-semibold text-white">%{item?.qualityPercent || "--"}</p>
        </div>
      </div> */}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {menuOptions?.map((items, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();

                    if (items.value === "delete") {
                      setIsAlert(true);
                    }
                    // if (items.value === "finishJunction") {
                    //   setJunctionType(item?.externalOperation ? "external" : "internal");
                    //   setFinishJunctionId(item?.id);
                    //   setProductType(item?.productType);
                    //   setIsOpenJunctionPiece(true);
                    // }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${items?.value === "delete" || items.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                    }`}
                >
                  {items?.icon}
                  <p
                    className={`font-medium text-sm  ${items?.value === "delete" || items.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}
                  >
                    {items.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}

        <Tooltip className="z-[99999]" id={`${item?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={item?.id}
            pages={"activeAndPlanned"}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
      {/* <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            productType={productType}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      /> */}
    </>
  );
};

export default MaterialSupplyPlannedActiveCard;
