import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { orderService } from "../../services/order.service";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { PlusIcon, QuantityIcon } from "../../assets/icons/stepsIcons";
import { Button, CustomModal, PaginationComponent, TableOptions } from "../../components";
import NewOrder from "./modals/NewOrder";
import NoData from "../../steps/step-navigation/components/NoData";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import OrderQrModal from "./modals/OrderQrModal";
import { QuantityAcceptedIcon, QuantityDeclineIcon } from "../../assets/icons/productIcons";
import SearchInput from "../../components/inputs/SearchInput";
import { SearchIcon } from "../../assets/icons/PersonIcons";
import OrderSort from "./components/OrderSort";
import { DeadlineIconSort } from "../../assets/icons/equipmentIcon";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import diacritics from "diacritics";
import SupplyFormModal from "./modals/SupplyFormModal";
import MontageTest from "./MontageTest";
import useWindowDimensions from "../../hooks/useWindowDimension";
import OrderWipProductCard from "./cards/OrderWipProductCard";
import OrderRawMaterialCard from "./cards/OrderRawMaterialCard";
import WipRawMaterialCard from "./cards/WipRawMaterialCard";
import { useSearchParams } from "react-router-dom";
import MrpMultiOperationCard from "./components/MrpMultiOperationCard";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import FilterDrawerLast from "../../components/filter-drawer/FilterDrawer";
import OrderFilterPage from "./OrderPageFilter";
import { FilterIcon } from "../../assets/icons/commonIcons";

const Order = () => {
  const { settings, auth } = useStore();
  const { height } = useWindowDimensions();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isOpenQrModal, setIsOpenQrModal] = useState(false);
  const [tableHeadItemsLength, setTableHeadItemsLength] = useState(0);
  const [isNewOrderModal, setIsNewOrderModal] = useState(false);
  const [windowSize, setWindowSize] = useState(window?.innerWidth);
  const [orderData, setOrderData] = useState({});
  const [pageNo, setPageNo] = useState(Number(searchParams.get("pageNo")) || 1);
  const [searchVal, setSearchVal] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortValue, setSortValue] = useState(searchParams.get("sort") || null);
  const [ascDesc, setAscDesc] = useState(searchParams.get("ascDesc") === "true");
  const [pageLimit, setPageLimit] = useState(Number(searchParams.get("take")) || 10);
  const [filterOptions, setFilterOptions] = useState({});
  const isSmallScreen = windowSize <= 1023;
  const [runState, setRunState] = useState(false);
  const [stepIndexState, setStepIndexState] = useState(0);
  const [supplyForm, setSupplyForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  //filter
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const filterString = filterOptions && Object.keys(filterOptions).length > 0 ? encodeURIComponent(JSON.stringify(filterOptions)) : "";

  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["listOrder"],
    retry: 0,
    enabled: false,
    queryFn: useMemo(
      () => async () => {
        try {
          const res = await orderService.getOrderListForGet(
            Number(pageNo),
            searchVal,
            sortValue,
            ascDesc ? "DESC" : "ASC",
            pageLimit,
            filterString ? [filterString] : []
          );

          setTotalCount(res?.meta?.itemCount);
          setPageCount(res?.meta?.pageCount);
          setPageData(res?.data);

          const queryParams = new URLSearchParams();
          if (pageNo) queryParams.set("pageNo", pageNo.toString());
          if (searchVal) queryParams.set("search", searchVal);
          if (sortValue) queryParams.set("sort", sortValue);
          if (ascDesc !== undefined) queryParams.set("ascDesc", ascDesc.toString());
          if (pageLimit !== 10) queryParams.set("take", pageLimit.toString());

          setSearchParams(queryParams);

          setIsFirstLoad(false);
          return res;
        } catch (error) {
          console.error("Error occurred while fetching data:", error);
          throw error;
        }
      },
      [pageNo, searchVal, sortValue, ascDesc, pageLimit, filterOptions]
    ),
  });

  const outerDivRef = useRef(null);
  const mappedDivRefs = useRef([]);

  const handleScroll = (source, index = null) => {
    let currentScrollLeft;

    if (source === "outer") {
      currentScrollLeft = outerDivRef.current?.scrollLeft;
    } else if (source === "mapped") {
      currentScrollLeft = mappedDivRefs.current[index]?.scrollLeft;
    }

    if (outerDivRef.current && source !== "outer") {
      outerDivRef.current.scrollLeft = currentScrollLeft;
    }

    mappedDivRefs.current.forEach((div, idx) => {
      if (div && idx !== index) {
        div.scrollLeft = currentScrollLeft;
      }
    });
  };

  const tableHeadItems = useMemo(() => {
    const items = [];
    for (let i = 0; i < Math.max(1, tableHeadItemsLength); i++) {
      items.push({ title: `${i + 1}.${t("product:operation")}`, id: i });
    }
    return items;
  }, [tableHeadItemsLength, t]);

  useEffect(() => {
    refetch();
  }, [pageNo, searchVal, sortValue, ascDesc, pageLimit, filterOptions]);

  const dataManipulate = () => {
    if (pageData?.length > 0) {
      const head = pageData?.map((d) => d?.operations?.length);
      setTableHeadItemsLength(Math.max(...head));
    }
  };

  useEffect(() => {
    if (pageData?.length > 0) {
      dataManipulate();
    }
  }, [pageData]);

  const handleSearch = (input) => {
    const normalizedInput = diacritics.remove(input);
    setSearchVal(normalizedInput);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window?.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const changePage = (newPageNo) => {
    setPageNo(newPageNo);
    searchParams.set("pageNo", newPageNo.toString());
    setSearchParams(searchParams);
  };

  const MemoizedButton = React.memo(({ isSmallScreen, t, setIsNewOrderModal }) => (
    <Button
      colorType={"primary-product"}
      size={"md"}
      label={isSmallScreen ? t("product:newOrder") : t("product:createNewOrder")}
      iconLeft={<PlusIcon />}
      onClick={() => setIsNewOrderModal(true)}
    />
  ));

  useEffect(() => {
    setPageNo(1);
  }, [searchVal, sortValue, filterOptions]);

  return (
    <>
      <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative scrollbar-hide">
        {/*header*/}
        <div className={`flex w-full md:flex-col md:items-start border-b h-14 min-h-[58px] md:h-12 md:min-h-12  mb-4 items-center  `}>
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
            <div className="flex items-center">
              <span className="font-codecMedium text-2xl text-black">upu.</span>
              <p className="text-2xl font-codecMedium text-[#B54708]">order</p>
              <p className="text-xl font-normal ml-2 text-secondary-500">{t("product:workOrders")}</p>
            </div>
          </span>

          <div className="flex items-center md:hidden sm:hidden flex:hidden">
            <span className="font-codecMedium text-2xl text-black">upu.</span>
            <p className="text-2xl font-codecMedium text-[#B54708]">order</p>
            <p className="text-xl font-normal ml-2 text-secondary-500">{t("product:workOrders")}</p>
          </div>
          {isFirstLoad ? (
            <div className="ml-auto flex h-[42px] w-[828px] bg-gray-200 rounded-xl animate-pulse" />
          ) : (
            <div className="ml-auto flex flex-row items-center gap-x-3 md:items-start md:ml-0 md:mt-6">
              <div className="w-[320px] max-w-[320px] min-w-[320px] md:w-[300px] md:min-w-[300px] md:max-w-[300px] sm:hidden md:flex step-2">
                <SearchInput setSearchVal={handleSearch} theme={"product"} placeholder={t("chat:search")} />
              </div>
              <div className="hidden sm:flex md:hidden">
                <Button size="md" iconLeft={<SearchIcon />} colorType={"secondary-gray"} />
              </div>
              <OrderSort
                sortValue={sortValue}
                setSortValue={setSortValue}
                ascDesc={ascDesc}
                setAscDesc={setAscDesc}
                options={[
                  {
                    label: t("product:orderNo"),
                    value: "piece",
                    icon: <QuantityIcon />,
                  },
                  {
                    label: t("product:finishedPiece"),
                    value: "finishedPiece",
                    icon: <QuantityAcceptedIcon />,
                  },
                  {
                    label: t("product:rejectedPieces"),
                    value: "rejectedPiece",
                    icon: <QuantityDeclineIcon />,
                  },
                  {
                    label: t("chat:deadLine"),
                    value: "deliveryDate",
                    icon: <DeadlineIconSort />,
                  },
                ]}
              />
              <Button colorType={"secondary-gray"} label={"Filter"} iconLeft={<FilterIcon />} size={"md"} onClick={() => setIsFilterOpen(!isFilterOpen)} />
              <TableOptions pageLimit={pageLimit} setPageLimit={setPageLimit} />
              <Button
                colorType={"primary-product"}
                size={"md"}
                label={t("product:createNewOrder")}
                iconLeft={<PlusIcon />}
                onClick={() => navigate("/app/product/mrp/orders/new-order")}
              />
              {/* <MemoizedButton isSmallScreen={isSmallScreen} t={t} setIsNewOrderModal={setIsNewOrderModal} /> */}
            </div>
          )}
        </div>
        {/*body*/}
        <>
          {isFetching ? (
            <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse md:mt-16" />
          ) : (
            <div style={{ minHeight: height - 50 }} className="w-full border shadow-md rounded-xl border-[#D0D5DD] overflow-hidden md:mt-16">
              <>
                {pageData?.length <= 0 ? (
                  <div className="flex w-full h-full justify-center items-center">
                    <NoData header={t("label:noData")} button={false} />
                  </div>
                ) : (
                  <div className="w-full h-full flex flex-col relative overflow-hidden">
                    <>
                      <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
                        <div className="flex items-center justify-center max-w-[356px] min-w-[356px] h-full border-r border-[#EAECF0] text-sm font-semibold text-[#344054]">
                          {t("routes:Product")}
                        </div>

                        <div
                          ref={outerDivRef}
                          onScroll={() => handleScroll("outer")}
                          className="flex items-center w-full h-full overflow-x-auto overflow-y-hidden"
                        >
                          <div className="min-w-[356px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200">
                            {t("product:rawMaterial")}
                          </div>
                          {tableHeadItems?.map((item, index) => (
                            <div
                              key={index}
                              className="min-w-[346px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200"
                            >
                              {item?.title}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className={`flex flex-col w-full flex-1 overflow-y-auto scrollbar-hide`}>
                        <>
                          {/* MONTAJ */}
                          {/* {(pageNo ? pageNo <= 1 : true) &&
                            (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59" ||
                              auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") && (
                              <MontageTest pageNo={pageNo} tableHeadItemsLength={tableHeadItemsLength} setTableHeadItemsLength={setTableHeadItemsLength} />
                            )} */}
                          {pageData?.map((order, index) => {
                            const rawMaterialData = {
                              name: t("product:purchasing"),
                              id: order?.rawMaterial?.id,
                              source: order?.rawMaterial?.material?.source,
                              materials: order?.rawMaterial?.materials,
                              status: order?.rawMaterial?.status,
                            };
                            const filledOperations = [
                              rawMaterialData,
                              ...(order.operations || []),
                              ...Array.from(
                                {
                                  length: Math.max(tableHeadItemsLength - (order.operations || []).length, 0),
                                },
                                () => null
                              ),
                            ];
                            return (
                              <div key={index} style={{ height: "2000px" }} className="flex w-full h-[128px]">
                                <div className="max-w-[356px] min-w-[356px] h-full px-4 py-2 border-r ">
                                  <OrderWipProductCard
                                    item={order}
                                    refetch={refetch}
                                    qrFunction={(order) => {
                                      setSelectedOrder(order);
                                      setIsOpenQrModal(true);
                                    }}
                                  />
                                </div>

                                <div
                                  ref={(el) => (mappedDivRefs.current[index] = el)}
                                  onScroll={() => handleScroll("mapped", index)}
                                  id="content-wrapper"
                                  className="w-full overflow-x-auto scrollbar-hide flex border-b border-gray-200"
                                >
                                  <>
                                    <div key={index} className="min-w-[356px] flex items-center py-2 px-4 border-r border-gray-200">
                                      {order?.purchaseRequest?.length > 0 ? (
                                        <>
                                          {order?.purchaseRequest[0]?.source === "stock" ? (
                                            <WipRawMaterialCard item={order?.purchaseRequest[0]} order={order} />
                                          ) : (
                                            <WipRawMaterialCard item={order?.purchaseRequest[0]} order={order} />
                                          )}
                                        </>
                                      ) : (
                                        <OrderRawMaterialCard item={order} />
                                      )}
                                    </div>
                                  </>
                                  {filledOperations
                                    ?.sort((a, b) => a?.step - b?.step)
                                    ?.map((item, index) => {
                                      if (index > 0)
                                        return (
                                          <>
                                            <div key={index} className="min-w-[346px] flex items-center justify-center py-2 px-4 border-r border-gray-200">
                                              {item != null && (
                                                <MrpMultiOperationCard
                                                  item={item}
                                                  onClick={() => {
                                                    navigate(`/app/product/mrp/work-in-process/detail/${item?.orderId}+${item?.step}`);
                                                  }}
                                                  order={order}
                                                  isLast={index + 1 === filledOperations?.length}
                                                  index={index}
                                                />
                                              )}
                                            </div>
                                          </>
                                        );
                                    })}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      </div>
                    </>
                    <div className="z-50 border-t border-[#D0D5DD] bg-white rounded-b-xl">
                      <PaginationComponent pageNo={Number(pageNo) ? Number(pageNo) : 1} setPageNo={changePage} pageCount={pageCount} />
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
        </>
      </div>

      <CustomModal
        isOpen={isNewOrderModal}
        setIsOpen={setIsNewOrderModal}
        modalTitle={t("product:createNewOrder")}
        width={900}
        isBadges={true}
        badgesValue={totalCount + "/" + auth?.user?.company?.limits?.order}
        badgesColor={"fill-warning"}
        badgesSize={"md"}
        children={
          <NewOrder
            t={t}
            setOrderData={setOrderData}
            orderData={orderData}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            setSupplyForm={setSupplyForm}
            supplyForm={supplyForm}
            refresh={refetch}
            isOpen={isNewOrderModal}
            setIsNewOrderModal={setIsNewOrderModal}
          />
        }
      />
      <CustomModal
        isOpen={isOpenQrModal}
        setIsOpen={setIsOpenQrModal}
        modalTitle={t("embedded:getQr")}
        children={<OrderQrModal orderId={selectedOrder?.id} order={selectedOrder} />}
      />
      <CustomModal
        isOpen={supplyForm}
        setIsOpen={setSupplyForm}
        modalTitle={t("product:supplyForm")}
        children={
          <SupplyFormModal
            refresh={refetch}
            supplyForm={supplyForm}
            orderData={orderData}
            onClose={() => setSupplyForm(false)}
            setSupplyForm={setSupplyForm}
            selectedProduct={selectedProduct}
          />
        }
      />

      <div
        style={{
          zIndex: 999999000,
        }}
        className="absolute top-3  right-0 "
      >
        <FilterDrawerLast
          isOpen={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          width={400}
          children={<OrderFilterPage setFilterOptions={setFilterOptions} setIsFilterOpen={setIsFilterOpen} filterOptions={filterOptions} />}
        />
      </div>
    </>
  );
};

export default memo(Order);
