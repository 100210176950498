import React, { useState, useEffect } from "react";
import { Button, CustomModal } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import SearchInput from "../../components/inputs/SearchInput";
import Badgets from "../../components/buttons/Badgets";
import generateFileUrl from "../../utils/generateFileUrl";
import AllOperationsCard from "./components/AllOperationsCard";
import { purchaseService } from "../../services/purchase.service";
import PurchaseJunctionFinish from "./modals/PurchaseJunctionFinish";
import RawQualityControlForm from "./modals/RawQualityControlForm";
import diacritics from "diacritics";
import QualityMaterialEventCard from "../../components/new-cards/junction-card/QualityMaterialEventCard";
import QualityMaterialActiveEventCard from "../../components/new-cards/junction-card/QualityMaterialActiveEventCard";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { SwitchIcon, TransformIcon } from "../../assets/icons/commonIcons";
import { QualityTab } from "./quality/components";
import { Toast } from "../../utils/toastify/toast";
import AllOperationsNewCard from "./components/AllOperationsNewCard";
import { FilterIcon } from "../../assets/icons/productIcons";
import MaterialSupplyTab from "./materialSupply/components/MaterialSupplyTab";
import NewPurchaseFinishModal from "./modals/NewPurchaseFinishModal";
import NewQualityControlForm from "./modals/NewQualityControlForm";
import MaterialSupplyPlanModal from "./modals/MaterialSupplyPlanModal";
import MaterialSupplyReceivedCard from "../../components/new-cards/material-supply/MaterialSupplyReceivedCard";
import MaterialSupplyQualityAppliedCard from "../../components/new-cards/material-supply/MaterialSupplyQualityAppliedCard";
import MaterialSupplyEventCardPlannedTriple from "../../components/new-cards/material-supply/MaterialSupplyEventCardPlannedTriple";
import { CloseIcon } from "../../assets/icons/departmentIcon";
import MaterialSupplyQualityAllCard from "./components/MaterialSupplyQualityAllCard";

const QualitySupply = ({ planView }) => {
  const { t } = useTranslation();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [searchValue, setSearchValue] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [finishPurhcaseModal, setFinishPurhcaseModal] = useState(false);
  const [finishPurhcaseModalData, setFinishPurhcaseModalData] = useState(null);
  const [purchaseQualityForm, setPurchaseQualityForm] = useState(false);
  const [purchaseQualityFormData, setPurchaseQualityFormData] = useState(null);
  const [selectOperationData, setSelectOperationData] = useState(null);
  const [draggedJunction, setDraggedJunction] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isOpenPlanningModalData, setIsOpenPlanningModalData] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [lastSortedCardId, setLastSortedCardId] = useState(null);
  const [selectedMsNo, setSelectedMsNo] = useState(null);
  const [supplierId, setSupplierId] = useState(null);

  // const purchaseJunctionData = useQuery({
  //   queryKey: ["all-supplier-group-junctions-for-quality"],
  //   enabled: true,
  //   retry: 0,
  //   queryFn: async () => {
  //     return await purchaseService.getPurchaseJunctionsForCompany();
  //   },
  // });
  // const dataBySupplierGroup = useQuery({
  //   queryKey: ["supplier-group-junctions-for-quality"],
  //   enabled: false,
  //   retry: 0,
  //   queryFn: async () => {
  //     return await purchaseService.getPurchaseJunctionsForSupplierGroup(selectedGroup.id);
  //   },
  // });

  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 1,
    retryDelay: 3200,
    queryKey: ["material-supply-quality-list"],
    queryFn: async () => await purchaseService.getQualityMaterialSupplyList(),
  });
  useEffect(() => {
    if (selectedGroup == "all") {
      refetch();
    } else {
      refetch();
    }
  }, [selectedGroup]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else if (auth?.user?.company?.id == "1dcc62be-318a-4c4d-9e41-be3b69241c25") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "10826fc4-bf01-4164-acf0-72bb213e88d3") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);

  const filteredPlannedJunctions =
    selectedGroup === "all"
      ? data?.plannedJunctions?.filter((d) =>
          diacritics
            .remove(
              `${d?.msNo || ""} ${d?.supplier?.name || ""} ${
                d?.purchaseRequest?.rawMaterial
                  ? d?.purchaseRequest?.rawMaterial?.stockCode || ""
                  : d?.purchaseRequest?.productInternalOperation
                  ? d?.purchaseRequest?.productInternalOperation?.product?.stockCode || ""
                  : d?.purchaseRequest?.productExternalOperation
                  ? d?.purchaseRequest?.productExternalOperation?.product?.stockCode || ""
                  : d?.purchaseRequest?.product
                  ? d?.purchaseRequest?.product?.stockCode || ""
                  : "--"
              } ${
                d?.purchaseRequest?.rawMaterial
                  ? d?.purchaseRequest?.rawMaterial?.name || ""
                  : d?.purchaseRequest?.productInternalOperation
                  ? d?.purchaseRequest?.productInternalOperation?.product?.name || ""
                  : d?.purchaseRequest?.productExternalOperation
                  ? d?.purchaseRequest?.productExternalOperation?.product?.name || ""
                  : d?.purchaseRequest?.product
                  ? d?.purchaseRequest?.product?.name || ""
                  : ""
              }`
            )
            ?.toLowerCase()
            ?.includes(diacritics?.remove(searchValue)?.toLowerCase())
        )
      : selectOperationData?.plannedJunctions?.filter(
          (d) =>
            diacritics
              .remove(
                `${d?.msNo || ""} ${d?.supplier?.name || ""} ${
                  d?.purchaseRequest?.rawMaterial
                    ? d?.purchaseRequest?.rawMaterial?.stockCode || ""
                    : d?.purchaseRequest?.productInternalOperation
                    ? d?.purchaseRequest?.productInternalOperation?.product?.stockCode || ""
                    : d?.purchaseRequest?.productExternalOperation
                    ? d?.purchaseRequest?.productExternalOperation?.product?.stockCode || ""
                    : d?.purchaseRequest?.product
                    ? d?.purchaseRequest?.product?.stockCode || ""
                    : "--"
                } ${
                  d?.purchaseRequest?.rawMaterial
                    ? d?.purchaseRequest?.rawMaterial?.name || ""
                    : d?.purchaseRequest?.productInternalOperation
                    ? d?.purchaseRequest?.productInternalOperation?.product?.name || ""
                    : d?.purchaseRequest?.productExternalOperation
                    ? d?.purchaseRequest?.productExternalOperation?.product?.name || ""
                    : d?.purchaseRequest?.product
                    ? d?.purchaseRequest?.product?.name || ""
                    : ""
                }`
              )
              .toLowerCase()
              .includes(diacritics.remove(searchValue).toLowerCase()),
          "planned"
        );

  const filteredReceivedJunctions =
    selectedGroup === "all"
      ? data?.pendingJunctions?.filter((d) =>
          diacritics
            .remove(
              `${d?.plannedJunctionRef?.msNo || ""} ${d?.supplier?.name || ""} ${
                d?.purchaseRequest?.rawMaterial
                  ? d?.purchaseRequest?.rawMaterial?.stockCode || ""
                  : d?.purchaseRequest?.productInternalOperation
                  ? d?.purchaseRequest?.productInternalOperation?.product?.stockCode || ""
                  : d?.purchaseRequest?.productExternalOperation
                  ? d?.purchaseRequest?.productExternalOperation?.product?.stockCode || ""
                  : d?.purchaseRequest?.product
                  ? d?.purchaseRequest?.product?.stockCode || ""
                  : "--"
              } ${
                d?.purchaseRequest?.rawMaterial
                  ? d?.purchaseRequest?.rawMaterial?.name || ""
                  : d?.purchaseRequest?.productInternalOperation
                  ? d?.purchaseRequest?.productInternalOperation?.product?.name || ""
                  : d?.purchaseRequest?.productExternalOperation
                  ? d?.purchaseRequest?.productExternalOperation?.product?.name || ""
                  : d?.purchaseRequest?.product
                  ? d?.purchaseRequest?.product?.name || ""
                  : ""
              }`
            )
            ?.toLowerCase()
            ?.includes(diacritics?.remove(searchValue)?.toLowerCase())
        )
      : selectOperationData?.pendingJunctions?.filter(
          (d) =>
            diacritics
              .remove(
                `${d?.plannedJunctionRef?.msNo || ""} ${d?.supplier?.name || ""} ${
                  d?.purchaseRequest?.rawMaterial
                    ? d?.purchaseRequest?.rawMaterial?.stockCode || ""
                    : d?.purchaseRequest?.productInternalOperation
                    ? d?.purchaseRequest?.productInternalOperation?.product?.stockCode || ""
                    : d?.purchaseRequest?.productExternalOperation
                    ? d?.purchaseRequest?.productExternalOperation?.product?.stockCode || ""
                    : d?.purchaseRequest?.product
                    ? d?.purchaseRequest?.product?.stockCode || ""
                    : "--"
                } ${
                  d?.purchaseRequest?.rawMaterial
                    ? d?.purchaseRequest?.rawMaterial?.name || ""
                    : d?.purchaseRequest?.productInternalOperation
                    ? d?.purchaseRequest?.productInternalOperation?.product?.name || ""
                    : d?.purchaseRequest?.productExternalOperation
                    ? d?.purchaseRequest?.productExternalOperation?.product?.name || ""
                    : d?.purchaseRequest?.product
                    ? d?.purchaseRequest?.product?.name || ""
                    : ""
                }`
              )
              .toLowerCase()
              .includes(diacritics.remove(searchValue).toLowerCase()),
          "planned"
        );

  const filteredQualityCompletedJunctions =
    selectedGroup === "all"
      ? data?.qualityAcceptedJunctions?.filter((d) =>
          diacritics
            .remove(
              `${d?.plannedJunctionRef?.msNo || ""} ${d?.supplier?.name || ""} ${
                d?.purchaseRequest?.rawMaterial
                  ? d?.purchaseRequest?.rawMaterial?.stockCode || ""
                  : d?.purchaseRequest?.productInternalOperation
                  ? d?.purchaseRequest?.productInternalOperation?.product?.stockCode || ""
                  : d?.purchaseRequest?.productExternalOperation
                  ? d?.purchaseRequest?.productExternalOperation?.product?.stockCode || ""
                  : d?.purchaseRequest?.product
                  ? d?.purchaseRequest?.product?.stockCode || ""
                  : "--"
              } ${
                d?.purchaseRequest?.rawMaterial
                  ? d?.purchaseRequest?.rawMaterial?.name || ""
                  : d?.purchaseRequest?.productInternalOperation
                  ? d?.purchaseRequest?.productInternalOperation?.product?.name || ""
                  : d?.purchaseRequest?.productExternalOperation
                  ? d?.purchaseRequest?.productExternalOperation?.product?.name || ""
                  : d?.purchaseRequest?.product
                  ? d?.purchaseRequest?.product?.name || ""
                  : ""
              }`
            )
            ?.toLowerCase()
            ?.includes(diacritics?.remove(searchValue)?.toLowerCase())
        )
      : selectOperationData?.qualityAcceptedJunctions?.filter(
          (d) =>
            diacritics
              .remove(
                `${d?.plannedJunctionRef?.msNo || ""} ${d?.supplier?.name || ""} ${
                  d?.purchaseRequest?.rawMaterial
                    ? d?.purchaseRequest?.rawMaterial?.stockCode || ""
                    : d?.purchaseRequest?.productInternalOperation
                    ? d?.purchaseRequest?.productInternalOperation?.product?.stockCode || ""
                    : d?.purchaseRequest?.productExternalOperation
                    ? d?.purchaseRequest?.productExternalOperation?.product?.stockCode || ""
                    : d?.purchaseRequest?.product
                    ? d?.purchaseRequest?.product?.stockCode || ""
                    : "--"
                } ${
                  d?.purchaseRequest?.rawMaterial
                    ? d?.purchaseRequest?.rawMaterial?.name || ""
                    : d?.purchaseRequest?.productInternalOperation
                    ? d?.purchaseRequest?.productInternalOperation?.product?.name || ""
                    : d?.purchaseRequest?.productExternalOperation
                    ? d?.purchaseRequest?.productExternalOperation?.product?.name || ""
                    : d?.purchaseRequest?.product
                    ? d?.purchaseRequest?.product?.name || ""
                    : ""
                }`
              )
              .toLowerCase()
              .includes(diacritics.remove(searchValue).toLowerCase()),
          "complate"
        );

  const handlePlannedOnDrop = () => {
    if (draggedJunction?.id) {
      if (draggedJunction?.status === "waiting" || draggedJunction?.status === "started") {
        setIsOpenPlanningModal(true);
        setIsOpenPlanningModalData(draggedJunction);
      } else {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
    }
  };

  const handleFinishedOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.quality === "waiting")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setPurchaseQualityForm(true);
      setPurchaseQualityFormData(draggedJunction);
    }
  };

  const handleWaitingOnDrop = () => {
    if (draggedJunction?.id) {
      if (draggedJunction?.plannedUnit == draggedJunction?.acceptedUnit) {
        Toast("error", t("product:columnDragDropWarningMaterialPlannedCardSameUnit"));
        return;
      }
      if (!(draggedJunction?.status === "active")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setFinishPurhcaseModal(true);
      setFinishPurhcaseModalData(draggedJunction);
    }
  };

  const handleSortBySelectedCard = (items, type) => {
    if (!selectedCardId) return items;
    const isSorted = selectedCardId === lastSortedCardId;
    const sortedItems = items.sort((a, b) => {
      let aId, bId;

      if (type === "planned") {
        aId = a?.id;
        bId = b?.id;
      } else {
        aId = a?.plannedJunctionRef?.id;
        bId = b?.plannedJunctionRef?.id;
      }
      if (aId === selectedCardId && bId !== selectedCardId) return -1;
      if (aId !== selectedCardId && bId === selectedCardId) return 1;
      if (isSorted) return bId - aId;

      return 0;
    });
    return sortedItems?.map((item) => ({
      ...item,
      opacity: item?.plannedJunctionRef?.id === selectedCardId || item?.id === selectedCardId ? 1 : 0.25,
    }));
  };

  const handleCardClick = (cardId, msNo) => {
    if (selectedCardId === cardId) {
      // Aynı karta tekrar tıklanırsa sıralamayı ve msNo'yu sıfırla
      setSelectedCardId(null);
      setLastSortedCardId(null);
      setSelectedMsNo(null);
    } else {
      // Yeni bir kart seçildiyse, lastSortedCardId ve msNo'yu güncelle
      setSelectedCardId(cardId);
      setLastSortedCardId(cardId);
      setSelectedMsNo(msNo);
    }
  };

  return (
    <div className="w-full h-full flex flex-col overflow-hidden overflow-y-auto scrollbar-hide">
      <>
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          {isLoading ? (
            <div className="w-full h-[48px] bg-gray-200 rounded-xl animate-pulse" />
          ) : (
            <>
              <span className="mr-3 md:flex sm:flex xs:flex hidden">
                <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
              </span>
              <div className="flex items-center">
                <span className="font-codecMedium text-2xl text-black">upu.</span>
                <p className="text-2xl font-codecMedium text-[#B54708]">quality</p>
                <p className="text-xl font-normal ml-2 text-secondary-500">
                  {selectedGroup ? `${t("product:materialIncomingQuality")}` : t("product:materialIncomingQuality")}
                </p>
              </div>
            </>
          )}
        </div>
        {isLoading || isFetching ? (
          <div className="w-full h-[134px] bg-gray-200  rounded-xl animate-pulse" />
        ) : (
          <div className={`flex w-full  overflow-x-auto pb-1 ${!false ? "min-h-[116px] max-h-[116px]" : "min-h-[116px] max-h-[116px]"}`}>
            <MaterialSupplyQualityAllCard
              plannedJunctionLength={data?.allCount?.plannedJunctions}
              selected={selectedGroup}
              pendingJunctionlength={data?.allCount?.pendingJunctions}
              onClick={setSelectedGroup}
            />
            {/* <AllOperationsNewCard
              isPastDay={false}
              // pendingJunctionlength={purchaseJunctionData?.data?.pendingJunction?.length}
              // activeJunctionlength={purchaseJunctionData?.data?.activeJunction?.length}
              // pastJunctionlength={purchaseJunctionData?.data?.pastJunction?.length}
              // allCount={purchaseJunctionData?.data?.allCount}
              onClick={setSelectedGroup}
              selected={selectedGroup}
            /> */}

            {data?.operations
              ?.sort((a, b) => b?.plannedJunctions - a?.plannedJunctions)
              ?.map((item, index) => {
                return (
                  <div className={`min-w-[192px] ${index !== data?.operations?.length - 1 ? "border-b" : "border-b-0"} max-h-[96px]`}>
                    <div
                      // onClick={async () => {
                      //   setSelectedGroup(item);
                      // }}
                      onClick={async () => {
                        setSelectedGroup(item);
                        await purchaseService.getQualityMaterialSupplySelectOperation(item?.id).then((r) => {
                          setSelectOperationData(r);
                          setSupplierId(item?.id);
                        });
                      }}
                      className={`min-w-[172px] max-w-[172px] rounded-t-lg ${
                        selectedGroup?.id === item?.id ? "border-b-[2px] border-b-[#A15C07] h-[96px]" : "h-[96px]"
                      } rounded-b-none border cursor-pointer border-seconday-300  select-none flex flex-col`}
                    >
                      <div className="flex flex-row items-center justify-center w-full gap-x-1.5 p-2 border-b border-b-[#E4E7EC]">
                        <p className={`text-base  truncate ${selectedGroup?.id === item?.id ? "text-[#B54708] font-semibold" : "text-[#667085] font-medium"} `}>
                          {item?.name || "--"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center w-full h-full">
                        {!false && (
                          <div className={`flex flex-col items-center w-1/2  border-r ${selectedGroup?.id === item?.id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
                            <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                              <p className={`text-xs ${selectedGroup?.id === item?.id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>
                                {t("product:plannedNew")}
                              </p>
                            </div>
                            <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                              <p className={`text-sm ${selectedGroup?.id === item?.id ? "font-semibold text-[#079455]" : "font-medium text-[#667085]"}`}>
                                {item?.plannedJunctions || 0}
                              </p>
                            </div>
                          </div>
                        )}

                        <div className={`flex flex-col items-center w-1/2  ${selectedGroup?.id === item?.id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
                          <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                            <p className={`text-xs ${selectedGroup?.id === item?.id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>
                              {t("product:qualityPen")}
                            </p>
                          </div>
                          <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                            <p className={`text-sm ${selectedGroup?.id === item?.id ? "font-semibold text-[#A15C07]" : "font-medium text-[#667085]"}`}>
                              {item?.pendingJunctions || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}

        {isLoading || isFetching ? (
          <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
        ) : (
          <>
            <div style={{ minHeight: height - 65 }} className="flex w-full flex-col h-full rounded-xl mt-4 relative border border-[#E4E7EC] bg-white">
              <div className="flex min-h-[72px] max-h-[72px] p-4 border-b border-b-secondary-300 justify-between w-full items-center">
                <span className="text-[#101828] text-xl font-semibold">{selectedGroup === "all" ? t("product:allNew") : selectedGroup?.name}</span>
                {selectedCardId ? (
                  <div className="flex flex-row items-center ml-4 gap-x-2">
                    <FiltersIcon />
                    <p className="text-sm font-medium text-secondary-700">
                      MS-{selectedMsNo} {t("product:filtered")}
                    </p>
                    {selectedMsNo && (
                      <Badgets
                        iconLeft={<CloseIcon />}
                        size={"sm"}
                        colorType={"outline-warning"}
                        label={t("product:resetFilter")}
                        onClick={() => {
                          setSelectedCardId(null);
                          setLastSortedCardId(null);
                          setSelectedMsNo(null);
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <div className="flex flex-row items-center gap-x-2 ml-4">
                    <FiltersIcon />
                    <p className="text-sm text-secondary-700 font-medium">{t("product:materialDetail")}</p>
                  </div>
                )}
                <div className="flex gap-x-3">
                  <span className="flex min-w-[300px] max-w-[300px] h-full items-center justify-center">
                    <SearchInput theme={"product"} setSearchVal={setSearchValue} placeholder={t("chat:search")} />
                  </span>
                  {/* <Button colorType={"secondary-gray"} size={"md"} label={t("product:sorted")} iconLeft={<SwitchIcon />} />
                <Button colorType={"secondary-gray"} size={"md"} label={t("product:filters")} iconLeft={<FilterIcon />} /> */}
                </div>
              </div>

              <div className="flex w-full h-full overflow-x-auto pb-6 overflow-y-hidden">
                <MaterialSupplyTab
                  length={filteredPlannedJunctions?.length || "0"}
                  type={"planned"}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  onDrop={() => {
                    handlePlannedOnDrop();
                  }}
                  children={
                    <PlannedJunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={handleSortBySelectedCard(filteredPlannedJunctions, "planned")}
                      onClick={handleCardClick}
                      refetch={refetch}
                      selectedCardId={selectedCardId}
                      setIsOpenPlanningModalData={setIsOpenPlanningModalData}
                      setIsOpenPlanningModal={setIsOpenPlanningModal}
                    />
                  }
                />
                <MaterialSupplyTab
                  length={filteredReceivedJunctions?.length || "0"}
                  type={"received"}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleWaitingOnDrop();
                  }}
                  children={
                    <JunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={handleSortBySelectedCard(filteredReceivedJunctions, "received")}
                      onClick={handleCardClick}
                      refetch={refetch}
                      selectedCardId={selectedCardId}
                      setFinishPurhcaseModalData={setFinishPurhcaseModalData}
                      setFinishPurhcaseModal={setFinishPurhcaseModal}
                    />
                  }
                />
                <MaterialSupplyTab
                  length={filteredQualityCompletedJunctions?.length || "0"}
                  type={"complate"}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleFinishedOnDrop();
                  }}
                  children={
                    <QualityApliedController
                      setDraggedJunction={setDraggedJunction}
                      items={handleSortBySelectedCard(filteredQualityCompletedJunctions, "complate")}
                      onClick={handleCardClick}
                      refetch={refetch}
                      selectedCardId={selectedCardId}
                      setPurchaseQualityForm={setPurchaseQualityForm}
                      setPurchaseQualityFormData={setPurchaseQualityFormData}
                    />
                  }
                />
              </div>
            </div>
          </>
        )}
      </>
      {/* <CustomModal
        isOpen={finishPurhcaseModal}
        setIsOpen={setFinishPurhcaseModal}
        modalTitle={t("product:purchaseApproval")}
        children={
          <PurchaseJunctionFinish
            refetch={() => {
              purchaseJunctionData?.refetch();
              // planView?.refetch();
              dataBySupplierGroup?.refetch();
              // planViewSliderData?.refetch();
            }}
            close={() => setFinishPurhcaseModal(false)}
            junctionData={finishPurhcaseModalData}
            rawData={{
              ...finishPurhcaseModalData?.purchaseRequest?.rawMaterial,
              deliveryDate: finishPurhcaseModalData?.estimatedDeliveryDate,
            }}
          />
        }
      /> */}
      {/* <CustomModal
        isOpen={purchaseQualityForm}
        setIsOpen={setPurchaseQualityForm}
        modalTitle={t("product:productRawMaterial")}
        children={
          <RawQualityControlForm
            refetch={() => {
              if (selectedGroup == "all") {
                purchaseJunctionData?.refetch();
              } else {
                dataBySupplierGroup?.refetch();
              }
            }}
            close={() => setPurchaseQualityForm(false)}
            junctionData={purchaseQualityFormData}
            rawData={{
              ...purchaseQualityFormData?.purchaseRequest?.rawMaterial,
              deliveryDate: purchaseQualityFormData?.estimatedDeliveryDate,
            }}
          />
        }
      /> */}
      <CustomModal
        isOpen={finishPurhcaseModal}
        setIsOpen={setFinishPurhcaseModal}
        modalTitle={t("product:receiveDelivery")}
        // subTitle={"Alım miktarını ve varsa ek formları ekleyin"}
        children={<NewPurchaseFinishModal refetch={refetch} setIsOpen={setFinishPurhcaseModal} junctionData={finishPurhcaseModalData} />}
      />
      <CustomModal
        isOpen={purchaseQualityForm}
        setIsOpen={setPurchaseQualityForm}
        modalTitle={t("product:qualityControlForm")}
        children={<NewQualityControlForm refetch={refetch} setIsOpen={setPurchaseQualityForm} junctionData={purchaseQualityFormData} />}
      />
      <CustomModal
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        onClose={() => {
          setIsOpenPlanningModal(false);
        }}
        modalTitle={t("product:planPurchase")}
        subTitle={t("product:specifyPlannings")}
        width="fit-content"
        children={
          <MaterialSupplyPlanModal
            setIsOpen={setIsOpenPlanningModal}
            refetch={refetch}
            selectedJunction={isOpenPlanningModalData}
            groupId={selectedGroup === "all" ? isOpenPlanningModalData?.supplier?.id : supplierId}
          />
        }
      />
    </div>
  );
};

export default QualitySupply;

const JunctionListController = ({
  items,
  onClick,
  setPurchaseQualityFormData,
  selectedCardId,
  setPurchaseQualityForm,
  setFinishPurhcaseModalData,
  setFinishPurhcaseModal,
  setDraggedJunction,
  refetch,
}) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] mb-3"
      style={{
        opacity: item.opacity, // Opaklığı ayarla
      }}
    >
      <MaterialSupplyReceivedCard
        data={item}
        refetch={refetch}
        onClick={() => onClick(item?.plannedJunctionRef?.id, item?.plannedJunctionRef?.msNo)} // Kart tıklandığında `handleCardClick` fonksiyonunu çağır
        setPurchaseQualityForm={setPurchaseQualityForm}
        setPurchaseQualityFormData={setPurchaseQualityFormData}
        selectedCardId={selectedCardId}
        setFinishPurhcaseModalData={setFinishPurhcaseModalData}
        setFinishPurhcaseModal={setFinishPurhcaseModal}
      />
    </div>
  ));
};

const QualityApliedController = ({ items, onClick, setPurchaseQualityFormData, selectedCardId, setPurchaseQualityForm, setDraggedJunction, refetch }) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] mb-3"
      style={{
        opacity: item.opacity, // Opaklığı ayarla
      }}
    >
      <MaterialSupplyQualityAppliedCard
        data={item}
        refetch={refetch}
        setPurchaseQualityForm={setPurchaseQualityForm}
        onClick={() => onClick(item?.plannedJunctionRef?.id, item?.plannedJunctionRef?.msNo)} // Kart tıklandığında `handleCardClick` fonksiyonunu çağır
        setPurchaseQualityFormData={setPurchaseQualityFormData}
        selectedCardId={selectedCardId}
      />
    </div>
  ));
};

const PlannedJunctionListController = ({
  items,
  onClick,
  setFinishPurhcaseModal,
  selectedCardId,
  setFinishPurhcaseModalData,
  setDraggedJunction,
  setIsOpenPlanningModal,
  setIsOpenPlanningModalData,
  refetch = { refetch },
}) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] cursor-pointer mb-3"
      style={{
        opacity: item.opacity, // Opaklığı ayarla
      }}
    >
      <MaterialSupplyEventCardPlannedTriple
        data={item}
        onClick={() => onClick(item?.id, item?.msNo)}
        refetch={refetch}
        setFinishPurhcaseModal={setFinishPurhcaseModal}
        setFinishPurhcaseModalData={setFinishPurhcaseModalData}
        selectedCardId={selectedCardId}
        setIsOpenPlanningModalData={setIsOpenPlanningModalData}
        setIsOpenPlanningModal={setIsOpenPlanningModal}
      />
    </div>
  ));
};

export const FiltersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M2.16699 3.83333C2.16699 3.36662 2.16699 3.13327 2.25782 2.95501C2.33771 2.79821 2.4652 2.67072 2.622 2.59083C2.80026 2.5 3.03361 2.5 3.50033 2.5H17.5003C17.967 2.5 18.2004 2.5 18.3787 2.59083C18.5355 2.67072 18.6629 2.79821 18.7428 2.95501C18.8337 3.13327 18.8337 3.36662 18.8337 3.83333V4.39116C18.8337 4.61516 18.8337 4.72716 18.8063 4.8313C18.782 4.92359 18.7421 5.01103 18.6882 5.0898C18.6275 5.17869 18.5428 5.25204 18.3736 5.39875L13.0438 10.0179C12.8745 10.1646 12.7898 10.238 12.7291 10.3269C12.6752 10.4056 12.6353 10.4931 12.611 10.5854C12.5837 10.6895 12.5837 10.8015 12.5837 11.0255V15.382C12.5837 15.5449 12.5837 15.6264 12.5574 15.6969C12.5342 15.7591 12.4964 15.8149 12.4472 15.8596C12.3916 15.9102 12.3159 15.9404 12.1646 16.001L9.33125 17.1343C9.02497 17.2568 8.87182 17.3181 8.74889 17.2925C8.64138 17.2702 8.54704 17.2063 8.48637 17.1148C8.41699 17.0101 8.41699 16.8452 8.41699 16.5153V11.0255C8.41699 10.8015 8.41699 10.6895 8.38962 10.5854C8.36536 10.4931 8.32544 10.4056 8.27158 10.3269C8.21081 10.238 8.12617 10.1646 7.9569 10.0179L2.62708 5.39875C2.45781 5.25204 2.37318 5.17869 2.3124 5.0898C2.25855 5.01103 2.21862 4.92359 2.19436 4.8313C2.16699 4.72716 2.16699 4.61516 2.16699 4.39116V3.83333Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
