import React from 'react'
import { useTranslation } from 'react-i18next'

const PersonDashboardDepartmentCard = ({selectedDepartment,onClick,departmentName,departmentId,totalPersonnel,activePersonnel}) => {
    const {t} = useTranslation()
  return (
    <div
    onClick={onClick}
    className={`min-w-[172px] rounded-t-lg ${selectedDepartment?.id === departmentId ? "border-b-[2px] border-b-[#107569] h-[96px]" : "h-[96px]"} rounded-b-none border cursor-pointer border-seconday-300  select-none flex flex-col`}
  >
    <div className="flex flex-row items-center justify-center w-full gap-x-1.5 p-2 border-b border-b-[#E4E7EC]">
      <p className={`text-base  truncate ${selectedDepartment?.id === departmentId ? "text-[#107569] font-semibold" : "text-secondary-500 font-medium"} `}>{departmentName || "--" }</p>
    </div>
    <div className="flex flex-row items-center w-full h-full">
      <div className={`flex flex-col items-center w-1/2  border-r ${selectedDepartment?.id === departmentId ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
        <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
          <p className={`text-xs ${selectedDepartment?.id === departmentId ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>{t("person:totalPersonnels")}</p>
        </div>
        <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
        <p className={`text-sm ${selectedDepartment?.id === departmentId ? "font-semibold text-[#475467]" : "font-medium text-[#667085]"}`}>{totalPersonnel}</p>
        </div>
      </div>
      <div className={`flex flex-col items-center w-1/2  ${selectedDepartment?.id === departmentId ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
        <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
          <p className={`text-xs ${selectedDepartment?.id === departmentId ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>{t("person:activePersonnels")}</p>
        </div>
        <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
        <p className={`text-sm ${selectedDepartment?.id === departmentId ? "font-semibold text-[#079455]" : "font-medium text-[#667085]"}`}>{activePersonnel}</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default PersonDashboardDepartmentCard