import React from "react";
import { Button } from "../../components";
import { ArrowLeftIcon } from "./quality/assets/qualitySvg";
import { useNavigate, useParams } from "react-router-dom";
import generateFileUrl from "../../utils/generateFileUrl";
import { EditIcon } from "../../assets/icons/departmentIcon";
import SearchInput from "../../components/inputs/SearchInput";
import { FilterIcon, SortIcon } from "../../assets/icons/productIcons";
import Badgets from "../../components/buttons/Badgets";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import NoAvatar from "../../components/avatar/NoAvatar";
import { useTranslation } from "react-i18next";
import StockDetailProductCard from "./cards/StockDetailProductCard";
import { productService } from "../../services/product.service";
import { useStore } from "../../hooks/useStores";
import { getFormattedNavigateDate } from "../../utils/getFomattedNavigateDate";
import OperationLogDetailMonthlyChart from "./chart/OperationLogDetailMonthlyChart";
import { timeFormatter } from "../../utils/timezoneFormatter";
import IntermediateWipCard from "./cards/IntermediateWipCard";

const OperationLogDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useStore();
  const { operationId, type } = useParams();

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ["getOperationLogDetail"],
    queryFn: async () => productService.getOperationLog(type, operationId),
    retry: 0,
  });

  

  const tabOptions = [
    { value: "general-info", label: "Genel Bilgiler & Hareketler" },
    { value: "orders-recipe", label: "Siparişler & Reçete" },
  ];

  const tableHeadItems = [{ name: t("label:date") }, { name: t("product:eventNew") }, { name: t("product:quantitys") }, { name: t("product:person") }, { name: t("product:placeLoaction") }, { name: t("product:go") }];

  const currencyIcon = (currency) => {
    switch (currency || "TRY") {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "RUB":
        return "₽";
      case "UAH":
        return "₴";
      case "TRY":
        return "₺";
      default:
        return "₺";
    }
  };

  const CurrencyCell = ({ currency, value }) => (
    <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">
      {currencyIcon(currency)} {value || "0"}
    </div>
  );

  const monthlyData = {
    dates: [
      1726272000000, 1726358400000, 1726444800000, 1726531200000, 1726617600000, 1726704000000, 1726790400000, 1726876800000, 1726963200000, 1727049600000,
      1727136000000, 1727222400000, 1727308800000, 1727395200000, 1727481600000, 1727568000000, 1727654400000, 1727740800000, 1727827200000, 1727913600000,
      1728000000000, 1728086400000, 1728172800000, 1728259200000, 1728345600000, 1728432000000, 1728518400000, 1728604800000, 1728691200000, 1728777600000,
      1728864000000,
    ],
    startDate: "2024-09-14T00:00:00.000Z",
    endDate: "2024-10-14T23:59:59.999Z",
    series: [
      {
        name: "Total Stock",
        type: "line",
        yAxisIndex: 1,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        itemStyle: {
          color: "#ED7E7E",
        },
      },
      {
        name: "Reserv Stock",
        type: "line",
        yAxisIndex: 1,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        itemStyle: {
          color: "#6941C6",
        },
      },
      {
        name: "Ordered Unit",
        type: "bar",
        yAxisIndex: 0,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        itemStyle: {
          color: "#035096",
        },
      },
      {
        name: "Total Stock Amount",
        type: "bar",
        yAxisIndex: 0,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        itemStyle: {
          color: "#be8663",
        },
      },
      {
        name: "Daily Average Amount",
        type: "line",
        yAxisIndex: 1,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        itemStyle: {
          color: "#53B1FD",
        },
      },
      {
        name: "Average Amount",
        type: "line",
        yAxisIndex: 1,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        itemStyle: {
          color: "#32D583",
        },
      },
    ],
    legend: ["Total Stock", "Reserv Stock", "Ordered Unit", "Total Stock Amount", "Daily Average Amount", "Average Amount"],
  };

  const deneme = { unitType: "kg", currency: "usd" };

  return (
    <div className="flex flex-col w-full h-full gap-y-6 overflow-y-auto overflow-x-hidden scrollbar-hide">
      {/* header */}
      {isLoading || isFetching ? (
        <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[56px]" />
      ) : (
        <div className="flex w-full min-h-[56px] max-h-[56px] h-[56px] justify-between items-center border-b border-[#E4E7EC] sticky top-0 bg-[#fff] z-[50]">
          <div className="flex gap-x-2 items-center">
            <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
            <div className="flex gap-x-3 items-center">
              {data?.data?.process?.image ? (
                <img
                  className="h-9 w-12 min-h-[36px] max-h-[36px] min-w-[48px] max-w-[48px] rounded border border-[#0000001A]"
                  src={generateFileUrl(data?.data?.process?.image)}
                  width={48}
                  height={36}
                  alt={data?.name?.charAt(0).toUpperCase()}
                />
              ) : (
                <NoAvatar name={data?.data?.process?.name || "--"} rounded={4} theme={"product"} minWidth="48px" minHeight="36px" />
              )}
              <p className="text-[#101828] font-semibold text-xl">{data?.data?.process?.name || "--"}</p>
              <p className="text-xl text-secondary-600">{t("product:stock")}:{data?.data?.product?.stockCode || "--"}</p>
              <p className="text-xl text-secondary-600">{data?.data?.step + `.Operasyon`}</p>
            </div>
          </div>
          <span>
            <Button colorType={"secondary-product"} iconLeft={<EditIcon />} label={t("buttons:edit")} size={"md"} onClick={() => console.log("edit")} />
          </span>
        </div>
      )}

      {/* detail header */}
      {isLoading || isFetching ? (
        <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[174px]" />
      ) : (
        <div className="flex gap-x-6">
          <div className="flex flex-col gap-y-[10px] min-w-[320px] max-w-[320px] w-[320px] h-full">
            <IntermediateWipCard item={data?.data} />
          </div>
          {/* ilk tablo */}
          <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-1/3">
            <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-b border-[#E4E7EC] text-[#475467] font-normal text-xs rounded-t-lg">
              <div className="w-1/2 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:currentStock")}</div>
              <div className="w-1/2 flex items-center px-4">{t("product:quantityInProduction")}</div>
            </div>
            <div className="flex w-full min-h-[52px] max-h-[52px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
              <div className="w-1/2 flex items-center px-4 border-r border-[#E4E7EC] ">{"0"}</div>
              <div className="w-1/2 flex items-center px-4">{"0"}</div>
            </div>
            <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-y border-[#E4E7EC] text-[#475467] font-normal text-xs">
              <div className="w-1/2 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:maxStock")}</div>
              <div className="w-1/2 flex items-center px-4">{t("product:minStock")}</div>
            </div>
            <div className="flex w-full min-h-[52px] max-h-[52px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
              <div className="w-1/2 flex items-center px-4 border-r border-[#E4E7EC]">{data?.data?.maxStock || "0"}</div>
              <div className="w-1/2 flex items-center px-4">{data?.data?.minStock || "0"}</div>
            </div>
          </div>
          {/* ikinci tablo */}
          <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-1/3">
            <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-b border-[#E4E7EC] text-[#475467] font-normal text-xs rounded-t-lg">
              <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:currentUnitSales")}</div>
              <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:highestPrice")}</div>
              <div className="w-1/3 flex items-center px-4">{t("product:lowestPrice")}</div>
            </div>
            <div className="flex w-full min-h-[52px] max-h-[52px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
              <CurrencyCell currency={data?.data?.currency} value={data?.data?.currentSalesPrice} />
              <CurrencyCell currency={data?.data?.currency} value={data?.data?.maxPriceOfItemsSold} />
              <CurrencyCell currency={data?.data?.currency} value={data?.data?.minPriceOfItemsSold} />
            </div>
            <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-y border-[#E4E7EC] text-[#475467] font-normal text-xs">
              <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC] whitespace-nowrap">{"Ortalama Fiyat"}</div>
              <div className="w-2/3 flex items-center px-4 ">{t("product:totalPriceAllPrice")}</div>
            </div>
            <div className="flex w-full min-h-[52px] max-h-[52px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
              <CurrencyCell currency={data?.data?.currency} value={data?.data?.averagePriceOfSales} />
              <div className="w-2/3 flex items-center px-4">
                {currencyIcon(data?.data?.currency)} {data?.data?.priceValueOfTotalSales || "0"}
              </div>
            </div>
          </div>
          {/* üçüncü tablo */}
          <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-1/3">
            <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-b border-[#E4E7EC] text-[#475467] font-normal text-xs rounded-t-lg">
              <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:currentPurchaseprice")}</div>
              <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:highestPrice")}</div>
              <div className="w-1/3 flex items-center px-4">{t("product:lowestPrice")}</div>
            </div>
            <div className="flex w-full min-h-[52px] max-h-[52px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
              <CurrencyCell currency={data?.data?.currency} value={data?.data?.currentPurchasePrice} />
              <CurrencyCell currency={data?.data?.currency} value={data?.data?.maxPurchasesPrice} />
              <CurrencyCell currency={data?.data?.currency} value={data?.data?.minPurchasesPrice} />
            </div>
            <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-y border-[#E4E7EC] text-[#475467] font-normal text-xs">
              <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:averagePrice")}</div>
              <div className="w-2/3 flex items-center px-4">{t("product:totalAllPurchases")}</div>
            </div>
            <div className="flex w-full min-h-[52px] max-h-[52px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
              <CurrencyCell currency={data?.data?.currency} value={data?.data?.averagePriceOfPurchases} />
              <div className="w-2/3 flex items-center px-4">
                {currencyIcon(data?.data?.currency)} {data?.data?.totalPriceValueOfPurchases || "0"}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* monthly chart */}
      <div className="flex w-full">
        {isLoading || isFetching ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[275px]" />
        ) : (
          <div className="flex w-full border rounded-lg shadow border-secondary-200">
            <OperationLogDetailMonthlyChart
              defaultLegends={monthlyData?.legend}
              data={monthlyData}
              end={monthlyData?.endDate}
              start={monthlyData?.startDate}
              selectedDate={moment(timeFormatter(new Date())?.formatted).format("YYYY-MM-DD")}
              selectedEquipmentId={data?.id || data?.nodeId}
              refetch={refetch}
              unitType={deneme?.unitType}
              currency={deneme?.currency}
            />
          </div>
        )}
      </div>
      {/* stock movements */}
      {isLoading || isFetching ? (
        <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[87vh]" />
      ) : (
        <div className="flex flex-col w-full border shadow-lg border-[#E4E7EC] rounded-xl min-h-[87vh]">
          <div className="flex w-full min-h-[64px] max-h-[64px] h-[64px] justify-between items-center px-6 py-3 border-b border-[#E4E7EC]">
            <p className="text-[#101828] font-semibold text-lg">{t("product:stockMovements")}</p>
            <div className="flex gap-x-3 items-center">
              <SearchInput />
              <Button colorType={"secondary-gray"} iconLeft={<SortIcon />} size={"md"} />
              <Button colorType={"secondary-gray"} iconLeft={<FilterIcon />} size={"md"} />
            </div>
          </div>
          <div className="flex flex-col h-full overflow-y-auto overflow-x-hidden scrollbar-hide">
            <div className="flex max-h-[34px] min-h-[34px] max-w-full min-w-full">
              {tableHeadItems?.map((item, i) => {
                return (
                  <div
                    key={i}
                    scope="col"
                    className={`flex px-4 py-2 last:border-r-0 ${i === 5
                      ? "w-[4%] justify-center items-center"
                      : i === 4
                        ? "w-[29%] justify-start items-center"
                        : i === 2 || i === 1
                          ? `w-[17%] justify-center items-center`
                          : i == 5
                            ? `w-full`
                            : i === 3 ? "w-[18%] justify-center items-center" :
                              i === 0
                                ? "w-[15%] justify-center items-center"
                                : ""
                      } bg-secondary-50 border-gray-200 border-b border-r`}
                  >
                    <span className={`text-center rounded flex text-xs font-medium items-center whitespace-nowrap`}>{item?.name}</span>
                  </div>
                );
              })}
            </div>
            {data?.data?.transactions?.map((item, index) => {

              const formattedDate = getFormattedNavigateDate(item?.junction?.startDate, auth.user.company.dayStartHour);
              return (
                <div className="flex w-full min-h-[56px] max-h-[56px] h-[56px] border-b border-[#E4E7EC]">
                  {/* <div className="flex min-w-[172px] max-w-[172px] w-[172px] justify-start items-center border-r border-[#E4E7EC] px-4 py-2">
                  <p className="text-[#344054] font-medium text-sm">SP-2435485345</p>
                </div> */}
                  <div className="flex flex-col w-[15%] justify-center items-center border-r border-[#E4E7EC] px-4 py-2">
                    <p className="text-[#344054] text-xs font-medium">{moment(item?.createdDate)?.format("DD.MM.YYYY")}</p>
                    <div className="flex gap-x-1 text-[#475467] text-xs">
                      <p>{moment(item?.createdDate)?.format("dddd")}</p>
                      <p>{moment(item?.createdDate)?.format("HH:mm")}</p>
                    </div>
                  </div>
                  <div className="flex w-[17%] justify-center items-center border-r border-[#E4E7EC] px-4 py-2">
                    <Badgets
                      colorType={
                        item?.type === "income" ? "fill-success" : item?.type === "outcome" ? "fill-error" : item?.type === "outcome" ? "fill-gray" : "fill-gray"
                      }
                      size={"sm"}
                      label={
                        item?.reason === "supply_request"
                          ? t("product:supplyRequest")
                          : item?.reason === "reserv"
                            ? t("product:reserve")
                            : item?.reason === "manual"
                              ? `Stok Düzeltme`
                              : item?.reason === "purchase"
                                ? t("product:purchases")
                                : item?.reason === "stock"
                                  ? `${t("product:stock")} ${item?.type === "income" ? t("product:entry") : item?.type === "outcome" ? t("product:exit") : ""}`
                                  : item?.reason === "junction"
                                    ? `${"Üretim"} ${item?.junction?.internalOperation ? "(İç Op.)" : "(Hizmet Al.)"}`
                                    : ""
                      }
                    />
                  </div>
                  <div
                    className={`flex w-[17%] justify-center items-center border-r border-[#E4E7EC] px-4 py-2 gap-x-1 ${item?.type === "income" ? "text-[#17B26A]" : item?.type === "outcome" ? "text-[#F04438]" : item?.type === "info" ? "text-[#475467]" : ""
                      }`}
                  >
                    <span
                      className={`${item?.type === "income" ? "-rotate-90" : item?.type === "outcome" ? "rotate-90" : item?.type === "info" ? "text-[#475467]" : ""
                        }`}
                    >
                      <TriangleIcon />
                    </span>
                    <div className="flex gap-x-1 items-center justify-center">
                      <p className="text-sm font-medium">{item?.quantity || "0"}</p>
                      <p className="text-[#475467] text-xs">adet</p>
                    </div>
                  </div>
                  <div className="flex w-[18%] justify-center items-center border-r border-[#E4E7EC] px-4 py-2">
                    <p className="text-[#344054] text-sm font-medium">
                      {item?.junction
                        ? item?.junction?.user?.name + " " + item?.junction?.user?.lastName || "--"
                        : item?.serviceSupplyJunction?.user?.name ? item?.serviceSupplyJunction?.user?.name  + " " + item?.serviceSupplyJunction?.user?.lastName + "" : `${item?.user?.name || "--"} ${item?.user?.lastName || "--"}`}
                    </p>
                  </div>
                  <div className="flex w-[29%] border-r border-[#E4E7EC] justify-start items-center px-4 py-2">
                    <p className="text-[#344054] text-sm font-medium">
                      {item?.junction || item?.serviceSupplyJunction
                        ? (item?.junction?.internalOperation
                          ? item?.junction?.internalOperation?.name +
                          " / " +
                          item?.junction?.station?.metadata?.brand +
                          " - " +
                          item?.junction?.station?.metadata?.model
                          : item?.serviceSupplyJunction?.externalOperation?.name + " / " + item?.serviceSupplyJunction?.supplier?.name) || "--"
                        : "-- / --"}
                    </p>
                  </div>
                  <div className="flex w-[4%] justify-center items-center border-r border-[#E4E7EC] px-2 py-2">
                    <Button
                      colorType={"tertiary-gray"}
                      iconLeft={<ArrowUpIcon />}
                      size={"md"}
                      disabled={item?.operationType === "external" && item?.serviceSupplyJunction === null ? true : false || item?.operationType === "internal" && item?.junction === null ? true : false}
                      onClick={() => {
                        if(item?.operationType === "external") {
                          navigate(
                            `/app/product/mrp/supply/service/supplier/details/${item?.serviceSupplyJunction?.supplier?.id}/past/all`
                          )
                        } else {
                          navigate(
                            `/app/machine/digital-twin/equipment-details/${item?.junction?.station?.deviceId}-${item?.junction?.station?.id}/${formattedDate}`
                          )
                        }
                      }
                    }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

    </div>
  );
};

export default OperationLogDetail;

const TriangleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5.74528 2.08725L16.8322 9.68002C16.8848 9.71948 16.927 9.76852 16.956 9.82377C16.985 9.87902 17 9.93915 17 10C17 10.0609 16.985 10.121 16.956 10.1762C16.927 10.2315 16.8848 10.2805 16.8322 10.32L5.74529 17.9127C5.67738 17.9601 5.59566 17.9895 5.50955 17.9977C5.42345 18.0058 5.33648 17.9924 5.25869 17.9588C5.18117 17.925 5.11589 17.8729 5.07019 17.8082C5.02449 17.7435 5.00018 17.6689 5 17.5928L5 2.40723C5.00018 2.33107 5.02449 2.25646 5.07019 2.19179C5.11589 2.12711 5.18117 2.07495 5.25869 2.04116C5.33648 2.00763 5.42345 1.99416 5.50955 2.00232C5.59566 2.01048 5.67738 2.03992 5.74528 2.08725Z"
        fill="currentColor"
      />
    </svg>
  );
};

const ArrowUpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5.8335 14.1668L14.1668 5.8335M14.1668 5.8335H5.8335M14.1668 5.8335V14.1668"
        stroke="currentColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
