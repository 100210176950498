import React, { memo, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import {
  Arrow,
  Calender,
  ChartView,
  Layout,
  ListView,
} from "../../../../assets/icons/machineIcons";

const MobileLossHeader = ({
  t,
  icon,
  title,
  page,
  handlePreviousMonth,
  handleCalender,
  handleNextMonth,
  month,
  setMonth,
  getMonthName,
}) => {
  const [selectedView, setSelectedView] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openCalendar, setOpenCalendar] = useState(false);
  const currentMonthFormat = moment().startOf("month");
  const isDisabled = currentMonthFormat.isSame(
    moment(month).startOf("month"),
    "month"
  );

  useEffect(() => {
    if (pathname.includes("list-view")) {
      setSelectedView("list-view");
    }
  }, []);

  return (
    <div
      className={
        "w-full h-14 xs:h-[100px] hidden sm:flex xs:flex items-center border-b border-secondary-200 xs:my-4"
      }
    >
      <div
        className={`w-full h-full flex items-center gap-x-6 gap-y-3 xs:flex-wrap-reverse ${
          page === "loss" ? "justify-center" : "justify-end"
        }`}
      >
        {/*date selector*/}
        {pathname.includes("list-view") ? (
          <></>
        ) : (
          <>
            <div className={`w-full h-9 flex items-center gap-3 xs:mb-4`}>
              {/*left arrow*/}
              <button
                role="button"
                onClick={() => {
                  handlePreviousMonth();
                }}
                className={`w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs `}
              >
                <Arrow color="#344054" />
              </button>
              {/*calender arrow*/}
              <div
                role="button"
                className={`min-w-[119px] xs:w-full cursor-pointer h-full flex px-2 justify-center items-center gap-1 rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs`}
              >
                <Calender color="#344054" />
                <div className={`w-fit h-5 flex justify-center items-center`}>
                  <span className={`text-[#344054] text-sm font-semibold flex`}>
                    {getMonthName(moment(month).format("MM")) || "DD MM YYYY"}
                  </span>
                </div>
                {openCalendar && (
                  <>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenCalendar(true);
                      }}
                      style={{
                        marginTop: 40,
                      }}
                      className={`absolute top-10 -translate-x-1 bg-white z-[9999999] p-1 border rounded-lg border-gray-300 dark:bg-[#141414] dark:border-[#525252] shadow-lg`}
                    >
                      <Calender
                        value={month}
                        onChange={(e) => {
                          setMonth(e);
                          handleCalender();
                        }}
                      />
                    </div>
                    <div
                      className="fixed inset-0 z-[99999]"
                      onClick={(e) => {
                        setOpenCalendar(false);
                      }}
                      style={{ zIndex: 999 }}
                    />
                  </>
                )}
              </div>
              {/*right arrow*/}
              <div
                role="button"
                onClick={() => {
                  if (!isDisabled) {
                    handleNextMonth();
                  }
                }}
                className={` ${
                  isDisabled ? "disabled-button" : ""
                } w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs rotate-180 `}
              >
                <Arrow color="#344054" />
              </div>
            </div>
          </>
        )}

        {/*loss*/}
        <div
          className={` ${
            page !== "loss" ? "hidden" : "visible xs:w-full flex h-10 gap-x-6"
          }`}
        >
          <div
            role="button"
            className={`w-fit xs:w-[70%] h-full flex cursor-pointer py-1 pr-2 pl-3 justify-between items-center rounded-md border border-solid border-[#D0D5DD] bg-[#FFF] shadow-xs`}
          >
            <div
              className="flex items-center select-none cursor-pointer"
              onClick={() => {
                navigate(""); //graph-view
                setSelectedView(false);
              }}
            >
              {!selectedView && (
                <p className="text-sm font-semibold  text-[#6941C6] mr-3">
                  {t("loss:graphView")}
                </p>
              )}
              <button
                className={`w-8 h-8 rounded flex items-center justify-center ${
                  selectedView ? "bg-transparent" : "bg-primary-600"
                }`}
              >
                <ChartView color={selectedView ? "#98A2B3" : "#fff"} />
              </button>
            </div>

            <div
              className="flex items-center select-none cursor-pointer"
              onClick={() => {
                navigate("list-view");
                setSelectedView(true);
              }}
            >
              <button
                className={`w-8 h-8 rounded flex items-center justify-center ml-3 ${
                  !selectedView ? "bg-transparent" : "bg-primary-600"
                }`}
              >
                <ListView color={!selectedView ? "#98A2B3" : "#fff"} />
              </button>
              {selectedView && (
                <p className="text-sm font-semibold text-[#6941C6] ml-3">
                  {t("loss:listView")}
                </p>
              )}
            </div>
          </div>
          <div
            onClick={() => {
              navigate("loss-table");
            }}
            role="button"
            className={`w-fit h-full xs:w-[30%] cursor-pointer flex py-2.5 px-3.5 justify-center items-center gap-1 rounded-lg border border-solid border-[#7F56D9] bg-[#7F56D9] shadow-xs`}
          >
            <Layout />
            <div
              className={`w-fit h-5 flex justify-center items-center px-0.5`}
            >
              <span className={`text-[#FFF] text-sm font-semibold`}>
                {t("loss:typeOfLoss")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MobileLossHeader);
