import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../components";
import TextArea from "../../../components/inputs/TextArea";
import { useTranslation } from "react-i18next";
import { customerService } from "../../../services/customer.service";
import { Toast } from "../../../utils/toastify/toast";

const DeleteCustomerGroupModal = ({ closeModal, refetch, selectedSupGroup, setSelectedGroup }) => {
  const { t } = useTranslation();
  const [customerGroups, setCustomerGroups] = useState([]);
  const [selectedNewGroup, setSelectedNewGroup] = useState("");

  const getAllCustomerGroup = async () => {
    await customerService.getCustomerGroupList().then((res) => {
      if (res?.data) {
        const filterData = res?.data?.filter((x) => x?.id != selectedSupGroup);
        setCustomerGroups(filterData);
      } else {
      }
    });

    // setCustomerGroups()
  };

  const deleteCustomerGroup = async () => {
    await customerService.deleteCustomerGroup(selectedSupGroup, selectedNewGroup).then((res) => {
      if (res?.data?.code == 0) {
        Toast("success", res?.data?.message);
        refetch();
        setSelectedGroup("all");
        closeModal();
      } else {
        Toast("warning", res?.data?.message);
      }
    });
  };

  useEffect(() => {
    getAllCustomerGroup();
  }, []);

  return (
    <div className="w-[352px] flex flex-col gap-4 mt-4">
      <div className="w-full h-[40vh] overflow-y-scroll scrollbar-hide px-1 py-1">
        {customerGroups?.map((e) => {
          return (
            <div
              onClick={() => {
                setSelectedNewGroup(e);
              }}
              className={`w-full px-4 py-4 border-[1px] mb-4 rounded-md cursor-pointer text-md font-medium ${
                selectedNewGroup.id == e.id ? "shadow-ring-product" : ""
              }`}
            >
              {e?.name}
            </div>
          );
        })}
      </div>

      <div className="w-full h-11 flex gap-3">
        <Button label={t("buttons:cancel")} colorType={"secondary-gray"} size={"sm"} onClick={closeModal} />
        <Button label={t("product:editGroup")} colorType={"primary-product"} size={"sm"} onClick={deleteCustomerGroup} />
      </div>
    </div>
  );
};

export default DeleteCustomerGroupModal;
