import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Input } from "../../../../components";
import TextArea from "../../../../components/inputs/TextArea";
import { Toast } from "../../../../utils/toastify/toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { lossesService } from "../../../../services";
import { useTranslation } from "react-i18next";

const LossTypeModal = ({ selectedType, setIsOpen, getLossesTypeList }) => {
  const { t } = useTranslation();

  const schema = yup.object({
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    description: yup.string().typeError(`${t("validation:string")}`),
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    if (selectedType?.id) {
      await lossesService.updateLossType(selectedType?.id, data).then((res) => {
        if (res.data.code == 1) {
          getLossesTypeList();
          Toast("success", res.data.message);
          setIsOpen(false);
        } else {
          getLossesTypeList();
          Toast("error", res.data.message);
          setIsOpen(false);
        }
      });
    } else {
      <></>;
      //   await lossesService.addLossType(data).then((res) => {
      //     if (res.data.code == 1) {
      //       getLossesTypeList();
      //       alert.success(res.data.message);
      //       setIsOpen(false);
      //     } else {
      //       getLossesTypeList();
      //       alert.error(res.data.message);
      //       setIsOpen(false);
      //     }
      //   });
    }
  };
  useEffect(() => {
    if (selectedType && selectedType?.id) {
      setValue("name", selectedType?.name);
      setValue("description", selectedType?.description);
      setValue("iconKey", selectedType?.iconKey);
    }
  }, []);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-[552px] h-full mt-6 "
    >
      <div className="w-full flex flex-col gap-4">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t("losses:typeName")}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              validate={errors.name ? "error" : ""}
            />
          )}
          name="name"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextArea
              label={t("losses:typeDescription")}
              width={"100%"}
              onBlur={onBlur}
              onChange={onChange}
              rows={2}
              value={value}
              validate={errors.description ? "error" : ""}
            />
          )}
          name="description"
        />
      </div>
      <div className="flex items-center flex-row gap-3 w-full mt-6">
        <div className="w-[48%]">
          <Button
            colorType={"secondary-gray"}
            size={"lg"}
            onClick={() => setIsOpen(false)}
            type={"button"}
            label={t("buttons:stop")}
          />
        </div>
        <div className="w-[48%]">
          <Button
            colorType={"primary-machine"}
            size={"lg"}
            type={"submit"}
            label={t("buttons:save")}
          />
        </div>
      </div>
    </form>
  );
};

export default LossTypeModal;
