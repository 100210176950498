import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CustomModal, Input, Loading, UploadProfile } from "../../components";
import { ArrowLeftIcon } from "../../assets/icons/PersonIcons";
import generateFileUrl from "../../utils/generateFileUrl";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { rawMaterialService } from "../../services/raw-material.service";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { instance } from "../../libs/client";
import Selector from "../../components/inputs/Selector";
import TextArea from "../../components/inputs/TextArea";
import CropperPdf from "../../components/inputs/CropperPdf";
import Preview from "../../components/modal/Preview";
import { orderService } from "../../services/order.service";
import OrderReceipeSection from "./OrderReceipeSection";
import { Toast } from "../../utils/toastify/toast";
import { productService } from "../../services/product.service";
import AddRawMaterialModal from "./modals/AddRawMaterialModal";
import ProductToggle from "../machine/Twin/components/ProductToggle";

const OrderEditClone = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [pdfData, setPdfData] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropData, setCropData] = useState("");
  const [croppedDataCDN, setCroppedDataCDN] = useState("");
  const [pdfDataCDN, setPdfDataCDN] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCrop, setIsCrop] = useState(false);
  const [addRawMaterial, setAddRawMaterial] = useState(false);
  const [isFirstModal, setIsFirstModal] = useState(true);
  const [items, setItems] = useState([]);
  const [enabled, setEnabled] = useState(false);

  const rawMaterials = useQuery({
    queryKey: ["raw-material-sources-order"],
    queryFn: async () => await rawMaterialService.getRawMaterials(),
  });
  const { isLoading, data, refetch, isFetching } = useQuery({
    queryKey: ["find-one-order"],
    queryFn: async () => await orderService.copyOrderData(orderId),
    retry: 0,
  });
  const schema = yup.object({
    image: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    code: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    stockCode: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    materialId: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    description: yup
      .string()
      .notRequired()
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (data?.data) {
      setValue("image", data?.data?.product?.image);
      setValue("name", data?.data?.product?.name);
      setValue("code", data?.data?.product?.code);
      setValue("stockCode", data?.data?.product?.stockCode);
      setValue("description", data?.data?.product?.description);
      setValue("materialId", data?.data?.rawMaterial?.material.id);
      setCroppedImage(generateFileUrl(data?.data?.product?.technicalDrawingCrop));
      setEnabled(data?.data?.isPieceControl);
    }
  }, [data]);
  const uploadBase64 = async (file) => {
    const data = { imageBinary: file };
    const response = await instance({
      method: "post",
      url: "file/upload/base64",
      data: { data },
    });
    setPdfDataCDN(response.data?.file);
  };
  const uploadBase64Cropped = async (file) => {
    const data = { imageBinary: file };
    const response = await instance({
      method: "post",
      url: "file/upload/base64",
      data: { data },
    });
    setCroppedDataCDN(response?.data?.file);
  };
  useEffect(() => {
    pdfData && uploadBase64(pdfData);
    cropData && uploadBase64Cropped(cropData);
  }, [pdfData, cropData]);
  useEffect(() => {
    setItems(data?.data?.operations?.sort((a, b) => a?.step - b?.step));
  }, [data]);
  const handleCustomSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    const sendData = {
      ...data?.data,
      product: {
        ...data?.data?.product,
        ...formData,
        technicalDrawing: pdfDataCDN,
        technicalDrawingCrop: croppedDataCDN,
      },
      operations: items,
      isPieceControl: enabled,
    };
    await productService
      .copyingFormOrder(orderId, sendData)
      .then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          navigate(`/app/product/mrp/stock/detail/${res.data.id}`);
          setLoading(false);
        } else {
          Toast("error", res.data.message);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  };
  return (
    <>
      <div className="w-full h-full flex flex-col overflow-hidden relative">
        {isLoading || loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loading size={56} primary={"#B54708"} secondary={"#fff"} />
          </div>
        ) : (
          <>
            <div className="w-full h-[78px] border-b flex items-center">
              <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
              <img className="w-[72px] h-[54px] rounded border border-gray-300 ml-3" src={generateFileUrl(data?.data?.product?.image)} />
              <div className="flex flex-col ml-3">
                <h1 className="text-xl font-semibold text-gray-900">
                  {data?.data?.product?.name}{" "}
                  {data?.data?.product?.revisionNo != null && data?.data?.product?.revisionNo != 0 && `(REVISION-${data?.data?.product?.revisionNo})`}
                </h1>
                <p className="text-gray-600">
                  {t("product:orderNo")}: {data?.data?.orderNo}{" "}
                </p>
              </div>
              <div className="flex items-center ml-auto gap-3 mr-1">
                <Button colorType={"secondary-gray"} size={"lg"} label={t("buttons:cancel")} type={"button"} onClick={() => navigate(-1)} />
                <Button colorType={"primary-product"} size={"lg"} label={t("buttons:saveChanges")} type={"button"} onClick={handleCustomSubmit} />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center w-full overflow-y-auto scrollbar-hide px-0.5 gap-y-6">
              <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full max-h-[366px] mt-5">
                <div className="flex min-w-full w-full h-full flex-col gap-6">
                  <div className="flex flex-col w-full h-full gap-y-6">
                    <div className="flex h-full justify-end items-end px-1 gap-6">
                      <div className="flex w-1/2 flex-col gap-4">
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <UploadProfile
                              title={t("fileInput:uploadAvatar")}
                              subTitle={t("fileInput:removePicture")}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                            />
                          )}
                          name="image"
                        />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              theme={"product"}
                              label={t("product:productName")}
                              validate={errors?.name ? "error" : ""}
                              errorMessage={errors?.name ? errors?.name?.message : ""}
                            />
                          )}
                          name="name"
                        />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              label={t("product:productCode")}
                              onChange={onChange}
                              onBlur={onBlur}
                              theme={"product"}
                              value={value}
                              validate={errors?.code ? "error" : ""}
                              errorMessage={errors?.code ? errors?.code?.message : ""}
                            />
                          )}
                          name="code"
                        />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              label={t("product:stockCode")}
                              onChange={onChange}
                              theme={"product"}
                              onBlur={onBlur}
                              value={value}
                              validate={errors?.stockCode ? "error" : ""}
                              errorMessage={errors?.stockCode ? errors?.stockCode?.message : ""}
                            />
                          )}
                          name="stockCode"
                        />
                      </div>
                      <div className="h-full w-[1px] bg-secondary-200" />
                      <div className="flex w-1/2 flex-col gap-4 h-full">
                        <CropperPdf
                          pdfData={croppedImage}
                          setPdfData={setPdfData}
                          setIsCrop={setIsCrop}
                          pdfLink={generateFileUrl(data?.data?.product?.technicalDrawing)}
                        />
                        {/* <div
                          onClick={() => {
                            setIsFirstModal(true);
                            setAddRawMaterial(true);
                          }}
                          className="flex cursor-pointer items-start flex-col gap-y-[6px]"
                        >
                          <p className="text-sm font-medium text-secondary-700">
                            {t("product:rawMaterial")}
                          </p>
                          <ImageUploader
                            color={"#D0D5DD"}
                            width="64"
                            height="48"
                          />
                        </div> */}
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Selector
                              value={value}
                              theme={"product"}
                              items={rawMaterials?.data?.map((item) => {
                                return { label: item?.name, value: item?.id };
                              })}
                              label={t("product:rawMaterial")}
                              onChange={onChange}
                              onBlur={onBlur}
                              validate={errors?.materialId ? "error" : ""}
                              errorMessage={errors?.materialId ? errors?.materialId?.message : ""}
                            />
                          )}
                          name="materialId"
                        />
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <TextArea
                              label={t("product:description")}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              theme={"product"}
                              rows={5}
                              validate={errors?.description ? "error" : ""}
                              errorMessage={errors?.description ? errors?.description?.message : ""}
                              minHeight={110}
                            />
                          )}
                          name="description"
                        />
                      </div>
                    </div>
                    <div className="flex w-full min-h-[20px] max-h-[20px] h-5 gap-x-3 items-center">
                      <ProductToggle enabled={enabled} setEnabled={setEnabled} />
                      <p className="text-[#344054] font-medium text-sm">{t("product:implementProcess")}</p>
                    </div>
                  </div>
                </div>
              </form>
              <OrderReceipeSection setItems={setItems} items={items} data={data?.data} t={t} />
            </div>
          </>
        )}
        {isFetching && (
          <div
            style={{
              height: "46px",
              borderWidth: isFetching ? 1 : 0,
              top: isFetching ? 22 : -100,
            }}
            className="rounded-xl z-[100] transition-all duration-300 border-black/60 backdrop-blur-sm bg-black/60 overflow-hidden absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center px-3"
          >
            <Loading primary={"#fff"} secondary={"rgb(0 0 0 / 0.4)"} size={26} />
            <span className="text-lg font-semibold text-white ml-2">{t("embedded:pleaseWaitData")}</span>
          </div>
        )}
      </div>

      <CustomModal
        isOpen={isCrop}
        setIsOpen={setIsCrop}
        modalTitle={t("product:pdfCropper")}
        children={<Preview file={pdfData} setCropData={setCropData} setIsCrop={setIsCrop} croppedImage={croppedImage} setCroppedImage={setCroppedImage} />}
      />
      <CustomModal
        isOpen={addRawMaterial}
        setIsOpen={setAddRawMaterial}
        modalTitle={isFirstModal ? t("product:addRawMaterial") : t("product:unitQuantity")}
        navigateButton={isFirstModal ? false : true}
        navigateOnClick={() => setIsFirstModal(!isFirstModal)}
        children={<AddRawMaterialModal setAddRawMaterial={setAddRawMaterial} setIsFirstModal={setIsFirstModal} isFirstModal={isFirstModal} />}
      />
    </>
  );
};

export default OrderEditClone;
