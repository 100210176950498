import React, { useState } from "react";
import { Button, CustomModal, Input, TimeInput } from "../../../components";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PollItem from "../components/PollItem";
import { Toast } from "../../../utils/toastify/toast";
import { pollService } from "../../../services/poll.service";
import AddUserToPoll from "./AddUserToPoll";
import { DashboardPlusIcon } from "../../../assets/icons/machineIcons";

const NewPollModal = ({ refetch, setIsNewPollModalOpen }) => {
  const { t } = useTranslation();
  const [endTime, setEndTime] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [poll, setPoll] = useState({
    title: "",
    questions: [
      {
        question: "",
        options: [
          {
            option: "",
            image: "",
          },
        ],
      },
    ],
    participants: [],
  });

  const generateUniqueId = () => {
    return Math.random();
  };

  const addQuestion = () => {
    const newQuestion = {
      id: generateUniqueId(),
      question: "Question",
      options: [
        {
          option: "",
        },
      ],
    };
    const newQuestions = [...poll.questions, newQuestion];
    setPoll({ ...poll, questions: newQuestions });
  };

  const deleteQuestion = (id) => {
    const currentQuestions = poll?.questions?.filter((ques) => ques?.id !== id);
    setPoll({ ...poll, questions: currentQuestions });
  };

  const createPoll = async () => {
    const sendData = {
      title: poll?.title,
      question: poll?.questions
        .map((d) => {
          return {
            name: d?.question,
            options: d?.options?.filter((a) => a?.option !== ""),
            image: d?.question?.options?.image,
          };
        })
        .filter((d) => d?.name),
      participants: [
        ...selectedUsers?.map((d) => {
          return { userId: d?.id };
        }),
      ],
      endTime: moment(endTime).format(""),
    };

    if (sendData?.participants?.length > 0) {
      await pollService
        .createPoll(sendData)
        .then((res) => {
          if (res?.data?.code == 0) {
            Toast("success", t("chat:pollCreated"));
            setIsOpen(false);
          } else if (res?.data?.code == 2) {
            Toast("error", t("chat:noLimitPoll"));
          } else if (res?.data?.code == 3) {
            Toast("success", t("chat:pollAddedButLimit"));
          } else {
            Toast("error", t("product:errors"));
          }
          refetch();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Toast("success", t("chat:announcementCannot"));
    }
    setIsNewPollModalOpen(false);
  };

  return (
    <>
      <div className="w-[500px] sm:w-[450px] xs:w-[300px] h-[660px] flex flex-col mt-6 gap-5">
        <Input
          label={t("chat:titlePool")}
          onChange={(e) => {
            setPoll({ ...poll, title: e?.target?.value });
          }}
          theme={"person"}
        />
        <TimeInput
          label={t("chat:surveyEndDate")}
          onChange={(v) => {
            setEndTime(v);
          }}
          theme={"person"}
          value={moment(endTime).format("YYYY-MM-DD")}
        />
        <hr className="h-[1px] w-full bg-secondary-200 " />
        <div className="h-[440px]  flex flex-col gap-5 w-full  border-b">
          <div className="h-[350px] flex flex-col gap-5 w-full overflow-y-scroll scrollbar-hide">
            {poll?.questions?.map((ques, i) => {
              return <PollItem key={ques?.id} ques={ques} mainIndex={i} poll={poll} setPoll={setPoll} deleteQuestion={() => deleteQuestion(ques.id)} />;
            })}
          </div>
          <div className="w-full ">
            <Button
              size={"md"}
              colorType={"secondary-gray"}
              type={"button"}
              label={t("chat:addQuestion")}
              onClick={poll?.questions?.length < 10 ? addQuestion : Toast("error", t("chat:reachedMaxLimit"))}
              iconLeft={<DashboardPlusIcon />}
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        <Button
          size={"lg"}
          colorType={"primary-person"}
          label={t("chat:sendPoll")}
          onClick={() => setIsOpen(true)}
          // onClick={() => {
          //   if (poll?.title == "" && poll?.questions[0].question != "") {
          //     setIsOpen(true);
          //   } else {
          //     Toast("error", t("chat:pollEmpty"));
          //     // alert.error(t("chat:pollEmpty"), {
          //     //   position: positions.TOP_LEFT,
          //     // });
          //   }
          // }}
        />
      </div>
      {isOpen && (
        <CustomModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          modalTitle={t("chat:sendToPoll")}
          children={<AddUserToPoll setIsOpen={setIsOpen} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} createPoll={createPoll} />}
        />
      )}
    </>
  );
};

export default NewPollModal;
