import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useTranslation } from "react-i18next";
import { useStore } from "../../hooks/useStores";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import generateFileUrl from "../../utils/generateFileUrl";
import { rawMaterialService } from "../../services/raw-material.service";
import moment from "moment";
import NoAvatar from "../../components/avatar/NoAvatar";
import { DeleteIcon } from "../../assets/icons/departmentIcon";
import CustomImage from "../../components/CustomImage";
import { NoEquIcon } from "../../assets/icons/machineIcons";
import { DotsHorizontalIcon, PlusIcon } from "../../assets/icons/productIcons";
import { AttachmentIconTask, EditIconPerson, PollDeleteIcon } from "../../assets/icons/PersonIcons";
import { QualityDetailIcon } from "../../components/new-cards/card-icons";
import { CalendarIcon } from "./quality/assets/qualitySvg";
import RawMaterialListMaterialCard from "../../components/junction/RawMaterialListMaterialCard";
import { serviceSupplyService } from "../../services/service-supply.service";
import { purchaseService } from "../../services/purchase.service";
import { useInfiniteQuery } from "@tanstack/react-query";
import SearchInput from "../../components/inputs/SearchInput";
import NoData from "../../steps/step-navigation/components/NoData";

const ServiceSupplierDetailsPast = () => {
  const { t } = useTranslation();
  const { settings } = useStore();
  const navigate = useNavigate();
  const params = useLocation();
  const { id, type } = useParams();


  //states
  const [searchVal, setSearchVal] = useState("");
  const [supplierInfo, setSupplierInfo] = useState(null);
  // const [supplierPastData, setSupplierPastData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isAlertJunction, setIsAlertJunction] = useState(false);
  const [deleteIdJunction, setDeleteIdJunction] = useState(null);
  const [qualityStatusJunction, setQualityStatusJunction] = useState(null);
  const [junctionData, setJunctionData] = useState(null);
  const [finishPurhcaseModal, setFinishPurhcaseModal] = useState(false);
  const [purchaseQualityForm, setPurchaseQualityForm] = useState(false);
  // const [data, setData] = useState(null);
  //

  const tabOptions = [
    { value: "info", label: t("product:supplierInfo") },
    { value: "past", label: t("product:supplierPast") },
  ];
  const tabOptionsType = [
    { value: "all", label: t("product:allNew") },
    { value: "active", label: t("product:actives") },
    { value: "finished", label: t("product:completedNew") },
  ];

  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };

  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];

  const attachCount = (purchaseFile, secondPurchaseFile, qualityControlFile, qualityAcceptedFile, qualityRejectedFile) => {
    let count = 0;
    if (purchaseFile) {
      count++;
    }
    if (secondPurchaseFile) {
      count++;
    }
    if (qualityControlFile) {
      count++;
    }
    if (qualityAcceptedFile) {
      count++;
    }
    if (qualityRejectedFile) {
      count++;
    }
    return count;
  };

  const detailTableHeadItems = [
    { name: t("product:receivingPersonnel") },
    { name: t("product:receivingDate") },
    { name: t("product:receivingQuantity") },
    { name: t("product:qualtyControl") },
    { name: "" },
    { name: "" },
  ];

  const menuOptions = [
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <PollDeleteIcon />,
    },
  ];

  const menuOptionsQuality = [
    {
      label: t("product:qualityControlForm"),
      value: "qualityControlForm",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <PollDeleteIcon />,
    },
  ];

  const getSupplierInfo = async () => {
    setLoading(true);
    const data = await serviceSupplyService.getServiceSupplierInfo(id);
    setSupplierInfo(data[0]);
    setLoading(false);
  };


  const fetchData = async ({ pageParam = 1, queryKey }) => {
    const [, search] = queryKey;
    const pagination = { page: pageParam, take: 10, search: search };
    return await serviceSupplyService.getServiceSupplierPastDataPagination(id, type, pagination);
  };

  const { data, fetchNextPage, hasNextPage, refetch, isLoading, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ["serviceSupplier", searchVal, type],
    queryFn: fetchData,
    initialPageParam: 1,
    retry: 0,
    getNextPageParam: (lastPage) => {
      return lastPage.meta.hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    enabled: !!searchVal || searchVal === "",
  });

  const supplierPastData = data?.pages.flatMap((page) => page.data) || [];



  useEffect(() => {
    getSupplierInfo();
  }, []);

  return (
    <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative step-1 scrollbar-hide">
      <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
        <span className="mr-3 md:flex sm:flex xs:flex hidden">
          <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
        </span>
        <div className="flex items-center">
          <Button
            colorType={"tertiary-gray"}
            onClick={() => {
              navigate(-1);
            }}
            size={"sm"}
            iconLeft={
              <div className="rotate-180">
                <TableArrowLeft />
              </div>
            }
          />

          <div className="flex items-center ml-2">
            {supplierInfo?.image && <img className="w-12 h-9 rounded-[4px] border-[1px] border-[#D0D5DD]" src={generateFileUrl(supplierInfo?.image)} />}

            <span className="text-2xl text-secondary-900 font-semibold ml-2">{supplierInfo?.name}</span>
          </div>
        </div>
        <span className="ml-auto">
          <Button
            colorType={"secondary-error"}
            disabled={true}
            size={"md"}
            label={t("buttons:edit")}
            iconLeft={<EditIconPerson />}
          // onClick={() => navigate("/app/product/mrp/supply/service/list")}
          />
        </span>
      </div>

      <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-6 mb-4">
        {tabOptions?.map((option, index) => (
          <div
            className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${"past" == option?.value ? "bg-white shadow-sm" : ""
              }`}
            onClick={() => {
              if (option?.value != "past") navigate(`/app/product/mrp/supply/service/supplier/details/${id}/info`);
            }}
          >
            <p className={`text-md font-semibold ${"past" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
          </div>
        ))}
      </div>

      <div className="w-full flex items-center justify-between mb-4 pr-1">
        <div className="flex w-1/4 flex-row  items-center rounded-lg border border-secondary-300 h-[42px] min-h-[42px]">
          {tabOptionsType?.map((option, index) => (
            <div
              onClick={() => {
                navigate(`/app/product/mrp/supply/service/supplier/details/${id}/past/${option.value}`);
              }}
              className={`px-4 py-2 flex ${type === option.value ? `bg-secondary-50 ${index == 2 ? "rounded-r-lg" : "rounded-l-lg"} ` : `bg-white ${index == 2 ? "rounded-r-lg" : "rounded-l-lg"}`
                } items-center cursor-pointer justify-center h-full ${index === 2 ? "border-r-0" : "border-r"} w-1/3`}
            >
              <p className="text-sm font-semibold text-secondary-700">{option?.label}</p>
            </div>
          ))}
        </div>
        <div className="w-1/3">
          {" "}
          <SearchInput setSearchVal={setSearchVal} theme={"product"}/>
        </div>
      </div>

      {supplierPastData?.length <= 0 ?
        (
          <>
            {loading || isLoading ? (
              <div className="w-full h-[80vh] mt-3 bg-gray-200 animate-pulse rounded-lg" />
            ) : (
              <div className="flex w-full h-full justify-center items-center">
                <NoData theme={"product"} header={t("label:noData")} button={false} />
              </div>
            )}
          </>
        ) : (
          <>
            {loading || isLoading ? (
              <div className="w-full h-[80vh] mt-3 bg-gray-200 animate-pulse rounded-lg" />
            ) : (
              <>
                {supplierPastData?.map((item, index) => {
                  return (
                    <div key={index} className="flex flex-col w-full h-fit border rounded-xl shadow-xs border-[#E4E7EC] bg-white relative mb-5">
                      <div className="flex w-full min-h-[60px] max-h-[60px] h-[60px] justify-between px-6 py-3">
                        <div className="flex h-full w-full max-w-1/2 items-center gap-x-3">
                          <div className="flex w-full gap-x-2 items-center">
                            <OrderIcon />
                            <div className="flex gap-x-2 items-center">
                              <p className="text-[#475467] text-sm">{t("product:supplyCode")}</p>
                              <p className="text-[#B54708] font-semibold text-lg">SS-{item?.ssNo || "--"}</p>
                            </div>
                          </div>
                        </div>

                        <div className="flex  items-center w-1/2">
                          <div className="flex w-full items-center gap-x-3 ">
                            <CalendarIcon />
                            <p className="text-[#475467] text-sm">{t("product:dueDate")}</p>
                            <p className="text-[#B54708] font-semibold text-lg">{moment(item?.estimatedDeliveryDate).format("DD.MM.YYYY")}</p>
                          </div>
                          <div className="flex w-full items-center gap-x-3 ">
                            <CalendarIcon />
                            <p className="text-[#475467] text-sm">{t("product:planningDate")}</p>
                            <p className="text-[#B54708] font-semibold text-lg">{moment(item?.createdDate).format("DD.MM.YYYY")}</p>
                          </div>
                        </div>
                      </div>

                      <div className="flex w-full min-h-[76px] max-h-[76px] h-[76px] gap-x-8 px-6 pb-3">
                        <div className="flex pl-2 w-full min-w-[288px] items-center border-[1px] border-[#E4E7EC] rounded-lg">
                          <CustomImage
                            borderRadius={2}
                            src={
                              item.purchaseRequest?.productInternalOperation
                                ? item.purchaseRequest?.productInternalOperation?.product?.image || "--"
                                : item.purchaseRequest?.productExternalOperation
                                  ? item.purchaseRequest?.productExternalOperation?.product?.image || "--"
                                  : item.job?.product
                                    ? item.job?.product?.image
                                    : item.purchaseRequest?.rawMaterial
                                      ? item.purchaseRequest?.rawMaterial?.image
                                      : "--"
                            }
                            style={{
                              width: 64,
                              height: 48,
                              minWidth: 64,
                              minHeight: 48,
                              borderRadius: 4,
                              borderWidth: 1,
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              display: "flex",
                              borderColor: "#0000001A",
                              backgroundColor: "#fff",
                            }}
                          />

                          <div className="flex flex-col ml-2">
                            <p className="text-secondary-900 text-base font-semibold truncate text-nowrap w-full pl-1">
                              {item?.purchaseRequest?.productInternalOperation
                                ? item?.purchaseRequest?.productInternalOperation?.product?.name || "Product Name"
                                : item?.purchaseRequest?.productExternalOperation
                                  ? item?.purchaseRequest?.productExternalOperation?.product?.name || "Product Name"
                                  : item?.job?.product
                                    ? item?.job?.product?.name
                                    : item?.purchaseRequest?.rawMaterial
                                      ? item?.purchaseRequest?.rawMaterial?.name
                                      : "Product Name"}
                            </p>
                            <div className="bg-[#FFFAEB] p-1 pl-1 text-[#B54708] text-xs w-fit">
                              {t("product:stock")} :
                              {item?.purchaseRequest?.productInternalOperation
                                ? item?.purchaseRequest?.productInternalOperation?.product?.stockCode || "--"
                                : item?.purchaseRequest?.productExternalOperation
                                  ? item?.purchaseRequest?.productExternalOperation?.product?.stockCode || "--"
                                  : item?.job?.product
                                    ? item?.job?.product?.stockCode
                                    : item?.purchaseRequest?.rawMaterial
                                      ? item?.purchaseRequest?.rawMaterial?.stockCode
                                      : "--"}
                            </div>
                          </div>

                          <div className="flex items-center justify-center h-full min-w-6 max-w-6 w-6 p-[3px] bg-[#F2F4F7] border-l border-[#E4E7EC] ml-auto">
                            <p className="text-[#475467] text-xxs font-semibold -rotate-90 whitespace-nowrap">
                              {item?.internalOperation ? (
                                <p className="text-xxs font-semibold whitespace-nowrap text-[#475467]">{t("product:semiProduct")}</p>
                              ) : item?.externalOperation ? (
                                <p className="text-xxs font-semibold whitespace-nowrap text-[#475467] ">{t("product:semiProduct")}</p>
                              ) : item.job?.product ? (
                                <p className={`text-xxs font-semibold whitespace-nowrap text-[#475467]`}>{t("product:rMaterial")}</p>
                              ) : item?.rawMaterial ? (
                                <p className={`text-xxs font-semibold whitespace-nowrap text-[#475467]`}>{t("product:rMaterial")}</p>
                              ) : (
                                "--"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="flex h-full w-full border border-[#D0D5DD] rounded-lg ">
                          <div className="flex flex-col w-full px-4 py-3 h-full border-r border-[#D0D5DD] items-start justify-center">
                            <p className="text-[#475467] text-xs whitespace-nowrap">{t("product:totalQuantityss")}</p>
                            <span className="flex gap-x-[2px] items-end">
                              <p className="text-[#B54708] font-semibold text-sm">{item?.serviceSupplierJunctions[0]?.deliveredQuantity || "0"}</p>
                              <p className="text-[#475467] text-xs">
                                {item?.internalOperation ? (
                                  <p className="text-xs whitespace-nowrap text-[#667085]">
                                    {/* {"Ara Mamül "}  */}
                                    {t("product:pcs")}
                                  </p>
                                ) : item?.externalOperation ? (
                                  <p className="text-xs whitespace-nowrap text-[#667085] ">
                                    {/* {"Ara Mamül"}  */}

                                    {t("product:pcs")}
                                  </p>
                                ) : item?.purchaseRequest?.product ? (
                                  <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                ) : item?.purchaseRequest?.rawMaterial ? (
                                  <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                ) : (
                                  "--"
                                )}
                              </p>
                            </span>
                          </div>
                          <div className="flex flex-col w-full px-4 py-3 h-full border-r border-[#D0D5DD] items-start justify-center">
                            <p className="text-[#475467] text-xs whitespace-nowrap">{t("product:receivingQuantity")}</p>
                            <span className="flex gap-x-[2px] items-end">
                              <p className="text-[#B54708] font-semibold text-sm">{item?.serviceSupplierJunctions[0]?.acceptedQuantity || "0"}</p>
                              <p className="text-[#475467] text-xs">
                                {item?.internalOperation ? (
                                  <p className="text-xs whitespace-nowrap text-[#667085]">{t("product:pcs")}</p>
                                ) : item?.externalOperation ? (
                                  <p className="text-xs whitespace-nowrap text-[#667085] ">{t("product:pcs")}</p>
                                ) : item?.purchaseRequest?.product ? (
                                  <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                ) : item?.purchaseRequest?.rawMaterial ? (
                                  <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                ) : (
                                  "--"
                                )}
                              </p>
                            </span>
                          </div>
                          <div className="flex flex-col w-full px-4 py-3 h-full">
                            <p className="text-[#475467] text-xs whitespace-nowrap">{t("product:remainingOrders")}</p>
                            <span className="flex gap-x-[2px] items-end ">
                              <p className="text-[#B54708] font-semibold text-sm">
                                {item?.serviceSupplierJunctions[0]?.deliveredQuantity - item?.serviceSupplierJunctions[0]?.acceptedQuantity || "0"}
                              </p>
                              <p className="text-[#475467] text-xs">
                                {item?.internalOperation ? (
                                  <p className="text-xs whitespace-nowrap text-[#667085]">{t("product:pcs")}</p>
                                ) : item?.externalOperation ? (
                                  <p className="text-xs whitespace-nowrap text-[#667085] ">{t("product:pcs")}</p>
                                ) : item?.purchaseRequest?.product ? (
                                  <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                ) : item?.purchaseRequest?.rawMaterial ? (
                                  <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                ) : (
                                  "--"
                                )}
                              </p>
                            </span>
                          </div>
                        </div>
                        <div className="flex h-full w-[224px] min-w-[224px] border border-[#D0D5DD] rounded-lg">
                          <div className="flex flex-col w-fit px-4 py-3 h-full border-r border-[#D0D5DD] items-start justify-center">
                            <p className="text-[#475467] text-xs whitespace-nowrap">{t("product:unitPrice")}</p>
                            <span className="flex gap-x-[2px] items-end">
                              <p className="text-[#B54708] font-semibold text-sm whitespace-nowrap">
                                {item?.unitCost} {item?.currency}
                              </p>
                            </span>
                          </div>
                          <div className="flex flex-col w-fit px-4 py-3 h-full items-start justify-center">
                            <p className="text-[#475467] text-xs whitespace-nowrap">{t("product:totalPrice")}</p>
                            <span className="flex gap-x-[2px] items-end">
                              <p className="text-[#B54708] font-semibold text-sm whitespace-nowrap">
                                {item?.job?.piece * item?.unitCost} {item?.currency}
                              </p>
                            </span>
                          </div>
                        </div>

                        <div className="flex h-full w-[224px] min-w-[224px] border border-[#D0D5DD] rounded-lg px-4 py-3 gap-x-3">
                          {item?.responsibleUser?.avatar ? (
                            <div className="cursor-pointer">
                              <CustomImage
                                src={item?.responsibleUser?.avatar}
                                style={{
                                  width: 40,
                                  height: 40,
                                  minWidth: 40,
                                  minHeight: 40,
                                  borderRadius: 100,
                                  borderColor: "#0000001A",
                                  backgroundColor: "#fff",
                                  borderWidth: 2,
                                  borderStyle: "solid",
                                  boxShadow: "0 0 0 2px #00000008",
                                }}
                              />
                            </div>
                          ) : (
                            <div className="cursor-pointer">
                              <NoAvatar size={40} fontSize={22} name={item?.responsibleUser?.name} lastName={item?.responsibleUser?.lastName} color={"#B54708"} />
                            </div>
                          )}
                          <div className="flex flex-col w-full">
                            <p className="text-[#344054] w-[150px] truncate text-sm font-medium">
                              {item?.responsibleUser?.name + item?.responsibleUser?.lastName || "--"}
                            </p>
                            <p className="text-[#667085] text-xs">--</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex max-h-[28px] min-h-[28px] max-w-full min-w-full ">
                        {detailTableHeadItems?.map((item, i) => {
                          return (
                            <div
                              key={i}
                              scope="col"
                              className={`px-6 py-1 ${i === 0 ? "min-w-[240px] max-w-[240px]" : i === 4 || i === 5 ? "min-w-[72px] max-w-[72px] w-[72px]" : "w-full"
                                } border-t items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                            >
                              <span className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}>
                                {item?.name}
                              </span>
                            </div>
                          );
                        })}
                      </div>

                      {item?.serviceSupplierJunctions?.map((items, index) => {
                        return (
                          <div key={index} className={`flex w-full items-center border-b h-[72px] min-h-[72px] last:border-b-0 relative group`}>
                            <div className="flex items-center h-full border-r min-w-[240px] max-w-[240px] px-6 py-4 gap-x-3">
                              {items?.responsibleUser?.avatar ? (
                                <div className="cursor-pointer">
                                  <CustomImage
                                    src={items?.junctions[0]?.responsibleUser?.avatar}
                                    style={{
                                      width: 40,
                                      height: 40,
                                      minWidth: 40,
                                      minHeight: 40,
                                      borderRadius: 100,
                                      borderColor: "#0000001A",
                                      backgroundColor: "#fff",
                                      borderWidth: 2,
                                      borderStyle: "solid",
                                      boxShadow: "0 0 0 2px #00000008",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="cursor-pointer">
                                  <NoAvatar
                                    size={40}
                                    fontSize={22}
                                    name={items?.junctions[0]?.responsibleUser?.name}
                                    lastName={items?.junctions[0]?.responsibleUser?.lastName}
                                    color={"#B54708"}
                                  />
                                </div>
                              )}
                              <div className="flex flex-col w-full">
                                <p className="text-[#344054] text-sm w-[155px] truncate font-medium whitespace-nowrap">
                                  {items?.junctions[0]?.responsibleUser?.name + items?.junctions[0]?.responsibleUser?.lastName || "--"}
                                </p>
                                <p className="text-[#667085] text-xs">{"--"}</p>
                              </div>
                            </div>
                            <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                              <p className="text-[#344054] text-sm font-medium">{moment(items?.junctions[0]?.createdDate).format("DD.MM.YY")}</p>
                              <p className="text-[#667085] text-xs">{moment(items?.junctions[0]?.createdDate).format("dddd")}</p>
                            </div>
                            <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                              <p className="text-[#344054] text-sm font-medium">{items?.junctions[0]?.madeWorkCount || "0"}</p>
                              <p className="text-[#667085] text-xs">
                                {item?.internalOperation ? (
                                  <p className="text-xs whitespace-nowrap text-[#667085]">{t("product:pcs")}</p>
                                ) : item?.externalOperation ? (
                                  <p className="text-xs whitespace-nowrap text-[#667085] ">{t("product:pcs")}</p>
                                ) : item?.purchaseRequest?.product ? (
                                  <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                ) : item?.purchaseRequest?.rawMaterial ? (
                                  <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                ) : (
                                  "--"
                                )}
                              </p>
                            </div>
                            <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                              {items?.status === "waiting" ? (
                                <p className="text-sm font-medium text-secondary-700">--</p>
                              ) : (
                                <>
                                  <span className="flex gap-x-1 items-center">
                                    <p className="text-[#079455] text-sm font-medium">{items?.junctions[0]?.madeWorkCount || "0"}</p>
                                    <p className="text-[#667085] text-xs">
                                      {item?.internalOperation ? (
                                        <p className="text-xs whitespace-nowrap text-[#667085]">{t("product:pcs")}</p>
                                      ) : item?.externalOperation ? (
                                        <p className="text-xs whitespace-nowrap text-[#667085] ">{t("product:pcs")}</p>
                                      ) : item?.purchaseRequest?.product ? (
                                        <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                      ) : item?.purchaseRequest?.rawMaterial ? (
                                        <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                      ) : (
                                        "--"
                                      )}
                                    </p>
                                  </span>
                                  <span className="flex gap-x-1 items-center">
                                    <p className="text-[#D92D20] text-sm font-medium">{items?.junctions[0]?.rejectedWorkCount || "0"}</p>
                                    <p className="text-[#667085] text-xs">
                                      {item?.internalOperation ? (
                                        <p className="text-xs whitespace-nowrap text-[#667085]">{t("product:pcs")}</p>
                                      ) : item?.externalOperation ? (
                                        <p className="text-xs whitespace-nowrap text-[#667085] ">{t("product:pcs")}</p>
                                      ) : item?.purchaseRequest?.product ? (
                                        <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                      ) : item?.purchaseRequest?.rawMaterial ? (
                                        <p className={`text-xs whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)}</p>
                                      ) : (
                                        "--"
                                      )}
                                    </p>
                                  </span>
                                </>
                              )}
                            </div>
                            <div className="flex flex-col items-center justify-center h-full border-r w-[72px] max-w-[72px] min-w-[72px] py-4 relative">
                              <Button colorType={"tertiary-gray"} size={"md"} iconLeft={<AttachmentIconTask />} />
                              <span className="flex absolute right-[10px] top-[14px] w-4 h-4 rounded-full bg-[#DC6803] items-center justify-center text-[#FFFFFF] text-xs font-semibold">
                                {attachCount(
                                  items?.purchaseFile,
                                  items?.secondPurchaseFile,
                                  items?.qualityControlFile,
                                  items?.qualityAcceptedFile,
                                  items?.qualityRejectedFile
                                )}
                              </span>
                            </div>
                            <div className="flex flex-col items-center justify-center h-full w-[72px] max-w-[72px] min-w-[72px] py-4">
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowDropDown(!showDropDown);
                                }}
                                colorType={"tertiary-gray"}
                                size={"md"}
                                iconLeft={<DotsHorizontalIcon />}
                              />
                            </div>
                            {showDropDown && (
                              <div
                                className={`hidden flex-col absolute min-w-[200px] top-[52px] right-[20px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
                              >
                                {items?.status === "applied" ? (
                                  <>
                                    {menuOptions?.map((menus, index) => {
                                      return (
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            // if (menus?.value === "qualityControlForm") {
                                            //   setPurchaseQualityForm(true)
                                            //   setJunctionData({ ...item, purchaseRequest: data })
                                            // }
                                            if (menus?.value === "delete") {
                                              setIsAlertJunction(true);
                                              setDeleteIdJunction(items?.id);
                                              setQualityStatusJunction(items?.quality);
                                            }
                                            setShowDropDown(false);
                                          }}
                                          key={index}
                                          type="button"
                                          className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${menus?.value === "delete" || menus.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                                            }`}
                                        >
                                          {menus?.icon}
                                          <p
                                            className={`font-medium text-sm  ${menus?.value === "delete" || menus.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"
                                              }`}
                                          >
                                            {menus?.label}
                                          </p>
                                        </button>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    {menuOptionsQuality?.map((menus, index) => {
                                      return (
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (menus?.value === "qualityControlForm") {
                                              setPurchaseQualityForm(true);
                                              setJunctionData({ ...items, purchaseRequest: data });
                                            }
                                            if (menus?.value === "delete") {
                                              setIsAlertJunction(true);
                                              setDeleteIdJunction(items?.id);
                                              setQualityStatusJunction(items?.quality);
                                            }
                                            setShowDropDown(false);
                                          }}
                                          key={index}
                                          type="button"
                                          className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${menus?.value === "delete" || menus.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                                            }`}
                                        >
                                          {menus?.icon}
                                          <p
                                            className={`font-medium text-sm  ${menus?.value === "delete" || menus.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"
                                              }`}
                                          >
                                            {menus?.label}
                                          </p>
                                        </button>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            )}
          </>

        )}
    </div>
  );
};

export default ServiceSupplierDetailsPast;

const OrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6673 8.74984V5.6665C16.6673 4.26637 16.6673 3.56631 16.3948 3.03153C16.1552 2.56112 15.7727 2.17867 15.3023 1.93899C14.7675 1.6665 14.0674 1.6665 12.6673 1.6665H7.33398C5.93385 1.6665 5.23379 1.6665 4.69901 1.93899C4.2286 2.17867 3.84615 2.56112 3.60647 3.03153C3.33398 3.56631 3.33398 4.26637 3.33398 5.6665V14.3332C3.33398 15.7333 3.33398 16.4334 3.60647 16.9681C3.84615 17.4386 4.2286 17.821 4.69901 18.0607C5.23379 18.3332 5.93385 18.3332 7.33398 18.3332M10.0007 7.49984H6.66732M11.6673 4.99984H6.66732M8.33398 9.99984H6.66732M9.83772 12.6319L12.8562 10.9452C12.9984 10.8658 13.0694 10.8261 13.1447 10.8105C13.2114 10.7967 13.2801 10.7967 13.3468 10.8103C13.4221 10.8258 13.4933 10.8654 13.6355 10.9446L16.6673 12.6319M9.83772 12.6319L13.2457 14.5309M9.83772 12.6319V15.9547C9.83772 16.1253 9.83772 16.2106 9.86279 16.2868C9.88497 16.3541 9.92123 16.416 9.96917 16.4683C10.0234 16.5274 10.0978 16.5691 10.2466 16.6525L13.2457 18.3332M16.6673 12.6319L13.2457 14.5309M16.6673 12.6319V15.9537C16.6673 16.1247 16.6673 16.2102 16.6422 16.2864C16.6199 16.3539 16.5835 16.4159 16.5355 16.4682C16.4811 16.5273 16.4065 16.569 16.2572 16.6523L13.2457 18.3332M13.2457 14.5309V18.3332"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ChevronRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M7.5 15L12.5 10L7.5 5" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
