import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EquipmentDetailIcon, EquipmentEditIcon, QualityDetailIcon } from "../../components/new-cards/card-icons";
import { ArrowLeft } from "../../assets/icons/commonIcons";
import { DotsHorizontalIcon, PlusIcon } from "../../assets/icons/productIcons";
import { ArrowUpRight } from "../../assets/icons/stepsIcons";
import CustomImage from "../../components/CustomImage";
import { NoEquIcon } from "../../assets/icons/machineIcons";
import { DeleteIcon } from "../../assets/icons/departmentIcon";
import NoAvatar from "../../components/avatar/NoAvatar";
import { AttachmentIconTask, PollDeleteIcon } from "../../assets/icons/PersonIcons";
import { useTranslation } from "react-i18next";
import { Button, CustomModal } from "../../components";
import ServiceSupplyPlanModal from "./modals/ServiceSupplyPlanModal";
import ServiceSupplyReceiveModal from "./modals/ServiceSupplyReceiveModal";
import { purchaseService } from "../../services/purchase.service";
import { serviceSupplyService } from "../../services/service-supply.service";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import ServiceSupplyQualityControlForm from "./modals/ServiceSupplyQualityControlForm";
import SendToSupplierModal from "./modals/SendToSupplierModal";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { Toast } from "../../utils/toastify/toast";
const ServiceSupplyPurchaseDetail = () => {
  const { id, supplierId, type } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [finishPurhcaseModal, setFinishPurhcaseModal] = useState(false);
  const [junctionData, setJunctionData] = useState("");
  const [purchaseQualityForm, setPurchaseQualityForm] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [supplierPurhcaseModal, setSupplierPurhcaseModal] = useState(false);
  const [qualityStatusJunction, setQualityStatusJunction] = useState("");
  const [deleteIdJunction, setDeleteIdJunction] = useState("");
  const [isAlertJunction, setIsAlertJunction] = useState("");

  const { data, refetch, isLoading, isFetching } = useQuery({
    retry: 0,
    queryKey: ["service-supply-purchase-request-details"],
    queryFn: async () => await serviceSupplyService.getServicePurchaseRequestDetails(type, id),
  });

  const tableHeadItems = [
    { name: t("product:semiProduct") },
    { name: t("product:inStock") },
    { name: t("product:activeRequests") },
    { name: t("product:remainingRequest") },
    { name: t("product:ssPlanned") },
    { name: t("product:ssPlannedQuantity") },
  ];

  const detailTableHeadItems = [
    { name: t("product:receivingPersonnel") },
    { name: t("product:receivingDate") },
    { name: t("product:receivingQuantity") },
    { name: t("product:qualtyControl") },
    { name: "" },
    { name: "" },
  ];

  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];

  const attachCount = (purchaseFile, secondPurchaseFile, qualityControlFile, qualityAcceptedFile, qualityRejectedFile) => {
    let count = 0;
    if (purchaseFile) {
      count++;
    }
    if (secondPurchaseFile) {
      count++;
    }
    if (qualityControlFile) {
      count++;
    }
    if (qualityAcceptedFile) {
      count++;
    }
    if (qualityRejectedFile) {
      count++;
    }
    return count;
  };

  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };

  //   const totalPlannedUnits = data?.plannedJunctions?.reduce((acc, junction) => acc + (junction?.plannedUnit || 0), 0);

  // const menuOptions = [
  //     {
  //         label: t("product:qualityControlForm"),
  //         value: "qualityControlForm",
  //         icon: <QualityDetailIcon />,
  //     },
  // ];
  const menuOptions = [
    {
      label: t("buttons:edit"),
      value: "editQuality",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <PollDeleteIcon />,
    },
  ];

  const menuOptionsQuality = [
    {
      label: t("product:qualityControlForm"),
      value: "qualityControlForm",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <PollDeleteIcon />,
    },
  ];

  const onDeletedJunctions = async () => {
    // await purchaseService.deletePlannedPurchaseNew(qualityStatusJunction === "waiting" ? "waiting" : "accepted", deleteIdJunction).then((res) => {
    //     if (res?.data?.code === 0) {
    //         Toast("success", res?.data?.message);
    //         refetch();
    //         setIsAlertJunction(false);
    //         // setDeleteId(data?.id);
    //     } else {
    //         Toast("error", res?.data?.message);
    //         setIsAlertJunction(false);
    //         setDeleteIdJunction("");
    //     }
    // });
  };

  const currencyIcon = (currency) => {
    switch (currency || "TRY") {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "RUB":
        return "₽";
      case "UAH":
        return "₴";
      case "TRY":
        return "₺";
      default:
        return "₺";
    }
  };

  return (
    <>
      <div className="flex flex-col w-full h-full gap-y-6">
        {isLoading || isFetching ? (
          <div className="w-full h-[56px] min-h-[56px] bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div className="flex w-full min-h-[56px] max-h-[56px] h-[56px] py-2 justify-between border-b border-[#E4E7EC]">
            <div className="flex gap-x-2 h-full items-center">
              <Button colorType={"tertiary-gray"} iconLeft={<ArrowLeft />} size={"md"} onClick={() => navigate(-1)} />
              <p className="text-[#101828] font-semibold text-2xl">{t("product:serviceSupplyDetail")}</p>
            </div>
            <div className="flex gap-x-3 h-full">
              <Button
                onClick={() => {
                  navigate(`/app/product/mrp/stock/detail/general-info/${data?.operation?.externalOperation?.product?.id}`);
                }}
                colorType={"secondary-gray"}
                label={t("product:stockDetail")}
                iconRight={<ArrowUpRight />}
                size={"md"}
              />
              <Button
                colorType={"primary-product"}
                label={t("product:newServiceSupply")}
                iconLeft={<PlusIcon />}
                size={"md"}
                onClick={() => {
                  setIsOpenPlanningModal(true);
                  setJunctionData(data?.operation);
                }}
              />
            </div>
          </div>
        )}
        {isLoading || isFetching ? (
          <div className="w-full h-[86vh] min-h-[86vh] bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div className="flex flex-col w-full border rounded-xl bg-[#F2F4F7] h-[86vh] overflow-y-auto scrollbar-hide overflow-x-hidden">
            <div className="flex max-h-[36px] min-h-[36px] max-w-full min-w-full">
              {tableHeadItems?.map((item, i) => {
                return (
                  <div
                    key={i}
                    scope="col"
                    className={`px-6 py-2 ${
                      i === 0 ? "w-[25%] rounded-tl-xl" : "w-[15%]"
                    } items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                  >
                    <span className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}>
                      {item?.name}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="w-full  h-full flex flex-col">
              <div className={`flex w-full items-center border-b h-[72px] min-h-[72px] bg-white`}>
                <div className="flex items-center h-full border-r w-[25%]">
                  <div className="flex w-full h-full px-6 py-3 gap-x-[6px]">
                    {data?.operation?.flexRawMaterial || data?.operation?.flexProduct ? (
                      <>
                        {data?.operation?.flexProduct?.image ? (
                          <div>
                            <CustomImage
                              borderRadius={2}
                              src={data?.operation?.flexProduct?.image}
                              style={{
                                width: 64,
                                height: 48,
                                minWidth: 64,
                                minHeight: 48,
                                borderRadius: 4,
                                borderWidth: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                display: "flex",
                                borderColor: "#0000001A",
                                backgroundColor: "#fff",
                              }}
                            />
                          </div>
                        ) : (
                          <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                            <NoEquIcon />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {data?.operation?.orderData?.product?.image ? (
                          <div>
                            <CustomImage
                              borderRadius={2}
                              src={data?.operation?.orderData?.product?.image}
                              style={{
                                width: 64,
                                height: 48,
                                minWidth: 64,
                                minHeight: 48,
                                borderRadius: 4,
                                borderWidth: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                display: "flex",
                                borderColor: "#0000001A",
                                backgroundColor: "#fff",
                              }}
                            />
                          </div>
                        ) : (
                          <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                            <NoEquIcon />
                          </div>
                        )}
                      </>
                    )}

                    <div className="flex flex-col w-full justify-center">
                      <p className="text-[#101828] text-md max-w-[190px]  truncate font-semibold px-1">
                        {data?.operation?.flexProduct ? data?.operation?.flexProduct?.name || "--" : data?.operation?.orderData?.product?.name || "--"}
                      </p>
                      <span className="flex w-fit px-1 max-w-[190px] truncate py-[3px] rounded bg-[#FFFAEB] text-[#B54708] text-xs font-medium">
                        {t("product:stock")} :{" "}
                        {data?.operation?.flexProduct ? data?.operation?.flexProduct?.stockCode : data?.operation?.orderData?.product?.stockCode || "--"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`flex items-center justify-center h-full min-w-6 max-w-6 w-6 p-[3px] ${
                      data?.operation?.flexProduct ? "bg-[#F9F5FF]" : "bg-[#F2F4F7]"
                    }  border-l border-[#E4E7EC]`}
                  >
                    <p className={`${data?.operation?.flexProduct ? "text-[#7F56D9]" : "text-[#475467]"}  text-xxs font-semibold -rotate-90 whitespace-nowrap`}>
                      <p className={`text-xxs font-semibold whitespace-nowrap ${data?.operation?.flexProduct ? "text-[#7F56D9]" : "text-[#475467]"} `}>
                        {data?.operation?.flexProduct ? `${data?.operation?.step}.Flex` : t("product:semiProduct")}
                      </p>
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center h-full border-r w-[15%] py-4">
                  <p className="text-[#344054] text-sm font-medium">
                    {data?.operation?.flexProduct ? data?.operation?.piece || "0" : data?.operation?.externalOperation?.activePiece || "0"}
                  </p>
                  <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                </div>
                <div className="flex flex-col items-center justify-center h-full border-r w-[15%] py-4">
                  <p className="text-[#344054] text-sm font-medium">{data?.operation?.header?.piece || "0"}</p>
                  <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                </div>
                <div className="flex flex-col items-center justify-center h-full border-r w-[15%] py-4">
                  <p className="text-[#344054] text-sm font-medium">{data?.operation?.piece - data?.operation?.finishedPiece || "0"}</p>
                  <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                </div>
                <div className="flex flex-col items-center justify-center h-full border-r w-[15%] py-4">
                  <p className="text-[#344054] text-sm font-medium">
                    {type === "semi-operation" && Array.isArray(data?.purchaseRequest) && data?.purchaseRequest?.length > 0
                      ? data?.purchaseRequest.reduce((acc, curr) => {
                          return acc + (curr?.targetedWorkCount || 0);
                        }, 0)
                      : "0"}
                    <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center h-full  w-[15%] py-4">
                  <p className="text-[#344054] text-sm font-medium">{data?.purchaseRequest?.length}</p>
                </div>
              </div>
              {data?.purchaseRequest?.map((e) => {
                return (
                  <div className="flex flex-col gap-y-4 w-full  px-6 py-4">
                    {e?.serviceSupplierJunctions?.length > 0 ? (
                      <>
                        {e?.serviceSupplierJunctions?.map((item, index) => {
                          return (
                            <div className="flex flex-col w-full h-fit border rounded-xl shadow-xs  border-[#E4E7EC] bg-white relative">
                              <div className="flex w-full min-h-[60px] max-h-[60px] h-[60px] justify-between px-6 py-3">
                                <div className="flex h-full w-fit items-center gap-x-3">
                                  <div className="flex w-full gap-x-2 items-center">
                                    <OrderIcon />
                                    <div className="flex gap-x-2 items-center">
                                      <p className="text-[#475467] text-sm">{t("product:serviceCode")}</p>
                                      <p className="text-[#B54708] font-semibold text-lg">
                                        SS-
                                        {e?.ssNo || "--"}
                                      </p>
                                    </div>
                                  </div>
                                  <span className="w-5 h-5">
                                    <ChevronRight />
                                  </span>
                                  <div className="flex gap-x-3 items-center">
                                    <p className="text-[#475467] text-sm">{t("product:supplier")}</p>
                                    <div className="flex gap-x-2 items-center">
                                      {e?.supplier?.image ? (
                                        <div>
                                          <CustomImage
                                            borderRadius={2}
                                            src={e?.supplier?.image}
                                            style={{
                                              width: 40,
                                              height: 30,
                                              minWidth: 40,
                                              minHeight: 30,
                                              borderRadius: 4,
                                              borderWidth: 1,
                                              alignItems: "center",
                                              justifyContent: "center",
                                              flexDirection: "column",
                                              display: "flex",
                                              borderColor: "#0000001A",
                                              backgroundColor: "#fff",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="w-[40px] min-w-[40px] h-[30px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                                          <NoEquIcon />
                                        </div>
                                      )}
                                      <p className="text-[#B54708] font-semibold text-md whitespace-nowrap">{e?.supplier?.name || "--"}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex gap-x-3 h-full">
                                  <div className="flex flex-row items-center gap-x-1">
                                    <p className="text-sm text-secondary-600">{t("embedded:status")}</p>
                                    <p className="text-md font-semibold w-[90px] min-w-[90px] text-[#1570EF]">{t("product:inSupplier")}</p>
                                  </div>
                                  <Button
                                    onClick={() => {
                                      if (item?.deliveredQuantity === item?.acceptedQuantity) {
                                        Toast("error", t("product:columnDragDropWarningMaterialPlannedCardSameUnit"));
                                        return;
                                      }
                                      setFinishPurhcaseModal(true);
                                      setJunctionData({ ...e, ...item, serviceSupplierJunctionId: item?.id });
                                    }}
                                    label={t("product:makePickUp")}
                                    colorType={"secondary-gray"}
                                    iconLeft={<PlusIcon />}
                                    size={"sm"}
                                  />

                                  <Button colorType={"secondary-error"} iconRight={<DeleteIcon />} size={"sm"} />
                                </div>
                              </div>
                              <div className="flex w-full min-h-[76px] max-h-[76px] h-[76px] gap-x-8 px-6 pb-3">
                                <div className="flex h-full w-[224px] min-w-[224px] border border-[#D0D5DD] rounded-lg px-4 py-3 gap-x-3">
                                  {item?.responsibleUser?.avatar ? (
                                    <div className="cursor-pointer">
                                      <CustomImage
                                        src={item?.responsibleUser?.avatar}
                                        style={{
                                          width: 40,
                                          height: 40,
                                          minWidth: 40,
                                          minHeight: 40,
                                          borderRadius: 100,
                                          borderColor: "#0000001A",
                                          backgroundColor: "#fff",
                                          borderWidth: 2,
                                          borderStyle: "solid",
                                          boxShadow: "0 0 0 2px #00000008",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="cursor-pointer">
                                      <NoAvatar
                                        size={40}
                                        fontSize={22}
                                        name={item?.responsibleUser?.name}
                                        lastName={item?.responsibleUser?.lastName}
                                        color={"#B54708"}
                                      />
                                    </div>
                                  )}
                                  <div className="flex flex-col w-full">
                                    <p className="text-[#344054] w-[150px] truncate text-sm font-medium">
                                      {item?.responsibleUser?.name + item?.responsibleUser?.lastName || "--"}
                                    </p>
                                    <p className="text-[#667085] text-xs">--</p>
                                  </div>
                                </div>
                                <div className="flex h-full w-full border border-[#D0D5DD] rounded-lg">
                                  <div className="flex flex-col border-r border-[#D0D5DD] w-1/3 h-full">
                                    <div className="flex w-full items-center justify-center border-b px-3 py-1 border-[#D0D5DD] bg-[#F2F4F7] rounded-tl-lg">
                                      <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:dispatchedQuantity")}</p>
                                    </div>
                                    <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px]">
                                      <p className="text-[#344054] text-sm font-medium">{item?.deliveredQuantity || "0"}</p>
                                      <p className="mt-[2px] text-[#475467] text-xs">{t("product:pcs")}</p>
                                    </div>
                                  </div>
                                  <div className="flex flex-col border-r border-[#D0D5DD] w-1/3 h-full">
                                    <div className="flex w-full items-center justify-center border-b border-[#D0D5DD] px-3 py-1 bg-[#F2F4F7]">
                                      <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:receivingQuantity")}</p>
                                    </div>
                                    <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px]">
                                      <p className="text-[#344054] text-sm font-medium">{item?.acceptedQuantity || "0"}</p>
                                      <p className="mt-[2px] text-[#475467] text-xs">{t("product:pcs")}</p>
                                    </div>
                                  </div>
                                  <div className="flex flex-col w-1/3 h-full">
                                    <div className="flex w-full items-center justify-center border-b border-[#D0D5DD] px-3 py-1 bg-[#F2F4F7] rounded-tr-lg">
                                      <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:remainingOrders")}</p>
                                    </div>
                                    <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px]">
                                      <p className="text-[#344054] text-sm font-medium">{item?.deliveredQuantity - item?.acceptedQuantity || "0"}</p>
                                      <p className="mt-[2px] text-[#475467] text-xs">{t("product:pcs")}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex h-full w-full border border-[#D0D5DD] rounded-lg">
                                  <div className="flex flex-col border-r border-[#D0D5DD] w-1/2 h-full">
                                    <div className="flex w-full items-center justify-center border-b px-3 py-1 border-[#D0D5DD] bg-[#F2F4F7] rounded-tl-lg">
                                      <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:unitPrice")}</p>
                                    </div>
                                    <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px] text-sm font-medium text-[#344054]">
                                      {currencyIcon(e?.currency)} {e?.unitCost}
                                    </div>
                                  </div>
                                  <div className="flex flex-col w-1/2 h-full">
                                    <div className="flex w-full items-center justify-center border-b border-[#D0D5DD] px-3 py-1 bg-[#F2F4F7] rounded-tr-lg">
                                      <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:totalPrice")}</p>
                                    </div>
                                    <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px] text-sm font-medium text-[#344054]">
                                      {currencyIcon(e?.currency)} {item?.deliveredQuantity * e?.unitCost}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex h-full w-full border border-[#D0D5DD] rounded-lg">
                                  <div className="flex flex-col border-r border-[#D0D5DD] w-1/2 h-full">
                                    <div className="flex w-full items-center justify-center border-b px-3 py-1 border-[#D0D5DD] bg-[#F2F4F7] rounded-tl-lg">
                                      <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:requestDate")}</p>
                                    </div>
                                    <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px] text-sm font-semibold text-[#B54708]">
                                      {moment(item?.createdDate).format("DD.MM.YYYY")}
                                    </div>
                                  </div>
                                  <div className="flex flex-col w-1/2 h-full">
                                    <div className="flex w-full items-center justify-center border-b border-[#D0D5DD] px-3 py-1 bg-[#F2F4F7] rounded-tr-lg">
                                      <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:dueDate")}</p>
                                    </div>
                                    <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px] text-sm font-semibold text-[#B54708]">
                                      {moment(item?.estimatedDeliveryDate).format("DD.MM.YYYY")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {item?.junctions?.length > 0 && (
                                <div className="flex max-h-[28px] min-h-[28px] max-w-full min-w-full">
                                  {detailTableHeadItems?.map((item, i) => {
                                    return (
                                      <div
                                        key={i}
                                        scope="col"
                                        className={`px-6 py-1 ${
                                          i === 0 ? "min-w-[240px] max-w-[240px]" : i === 4 || i === 5 ? "min-w-[72px] max-w-[72px] w-[72px]" : "w-full"
                                        } border-t items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                                      >
                                        <span
                                          className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}
                                        >
                                          {item?.name}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              {item?.junctions?.map((items, index) => {
                                return (
                                  <div className={`flex w-full items-center border-b h-[72px] min-h-[72px] last:border-b-0 relative group`}>
                                    <div className="flex items-center h-full border-r min-w-[240px] max-w-[240px] px-6 py-4 gap-x-3">
                                      {items?.user?.avatar ? (
                                        <div className="cursor-pointer">
                                          <CustomImage
                                            src={items?.user?.avatar}
                                            style={{
                                              width: 40,
                                              height: 40,
                                              minWidth: 40,
                                              minHeight: 40,
                                              borderRadius: 100,
                                              borderColor: "#0000001A",
                                              backgroundColor: "#fff",
                                              borderWidth: 2,
                                              borderStyle: "solid",
                                              boxShadow: "0 0 0 2px #00000008",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="cursor-pointer">
                                          <NoAvatar size={40} fontSize={22} name={items?.user?.name} lastName={items?.user?.lastName} color={"#B54708"} />
                                        </div>
                                      )}
                                      <div className="flex flex-col w-full">
                                        <p className="text-[#344054] text-sm w-[155px] truncate font-medium whitespace-nowrap">
                                          {items?.user?.name + items?.user?.lastName || "--"}
                                        </p>
                                        <p className="text-[#667085] text-xs">{"--"}</p>
                                      </div>
                                    </div>
                                    {/* teslim alim tarihi */}
                                    <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                                      <p className="text-[#344054] text-sm font-medium">{moment(items?.startDate).format("DD.MM.YY")}</p>
                                      <p className="text-[#667085] text-xs">{moment(items?.startDate).format("dddd")}</p>
                                    </div>
                                    {/* teslim alinan miktar */}
                                    <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                                      <p className="text-[#344054] text-sm font-medium">{items?.madeWorkCount || "0"}</p>
                                      <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                                    </div>
                                    {/* quality control */}
                                    <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                                      {items?.status === "received" ? (
                                        <p className="text-sm font-medium text-secondary-700">--</p>
                                      ) : (
                                        <>
                                          <span className="flex gap-x-1 items-center">
                                            <p className="text-[#079455] text-sm font-medium">{items?.metadata?.acceptedWorkCount || "0"}</p>
                                            <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                                          </span>
                                          <span className="flex gap-x-1 items-center">
                                            <p className="text-[#D92D20] text-sm font-medium">{items?.metadata?.rejectedWorkCount || "0"}</p>
                                            <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                                          </span>
                                        </>
                                      )}
                                    </div>
                                    {/* attachment */}
                                    <div className="flex flex-col items-center justify-center h-full border-r w-[72px] max-w-[72px] min-w-[72px] py-4 relative">
                                      <Button colorType={"tertiary-gray"} size={"md"} iconLeft={<AttachmentIconTask />} />
                                      <span className="flex absolute right-[10px] top-[14px] w-4 h-4 rounded-full bg-[#DC6803] items-center justify-center text-[#FFFFFF] text-xs font-semibold">
                                        {attachCount(
                                          items?.purchaseFile,
                                          items?.secondPurchaseFile,
                                          items?.qualityControlFile,
                                          items?.qualityAcceptedFile,
                                          items?.qualityRejectedFile
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex flex-col items-center justify-center h-full w-[72px] max-w-[72px] min-w-[72px] py-4">
                                      <Button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowDropDown(!showDropDown);
                                        }}
                                        colorType={"tertiary-gray"}
                                        size={"md"}
                                        iconLeft={<DotsHorizontalIcon />}
                                      />
                                    </div>
                                    {showDropDown && (
                                      <div
                                        className={`hidden flex-col absolute min-w-[200px] top-[52px] right-[20px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
                                      >
                                        {items?.status === "completed" ? (
                                          <>
                                            {menuOptions?.map((menus, index) => {
                                              return (
                                                <button
                                                  onClick={(el) => {
                                                    el.stopPropagation();
                                                    // if (menus?.value === "qualityControlForm") {
                                                    //   setPurchaseQualityForm(true)
                                                    //   setJunctionData({ ...item, purchaseRequest: data })
                                                    // }
                                                    if (menus?.value === "delete") {
                                                      setIsAlertJunction(true);
                                                      setDeleteIdJunction(items?.id);
                                                      setQualityStatusJunction(items?.status);
                                                    }
                                                    if (menus?.value === "editQuality") {
                                                      setJunctionData({ ...e, ...items });
                                                      setPurchaseQualityForm(true);
                                                    }
                                                    setShowDropDown(false);
                                                  }}
                                                  key={index}
                                                  type="button"
                                                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                                                    menus?.value === "delete" || menus.value === "finishJunction"
                                                      ? "hover:bg-error-50"
                                                      : "hover:bg-secondary-100"
                                                  }`}
                                                >
                                                  {menus?.icon}
                                                  <p
                                                    className={`font-medium text-sm  ${
                                                      menus?.value === "delete" || menus.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"
                                                    }`}
                                                  >
                                                    {menus?.label}
                                                  </p>
                                                </button>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <>
                                            {menuOptionsQuality?.map((menus, index) => {
                                              return (
                                                <button
                                                  onClick={(el) => {
                                                    el.stopPropagation();
                                                    if (menus?.value === "qualityControlForm") {
                                                      setPurchaseQualityForm(true);
                                                      setJunctionData({ ...items, job: e?.job });
                                                    }
                                                    if (menus?.value === "delete") {
                                                      setIsAlertJunction(true);
                                                      setDeleteIdJunction(items?.id);
                                                      setQualityStatusJunction(items?.status);
                                                    }
                                                    setShowDropDown(false);
                                                  }}
                                                  key={index}
                                                  type="button"
                                                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                                                    menus?.value === "delete" || menus.value === "finishJunction"
                                                      ? "hover:bg-error-50"
                                                      : "hover:bg-secondary-100"
                                                  }`}
                                                >
                                                  {menus?.icon}
                                                  <p
                                                    className={`font-medium text-sm  ${
                                                      menus?.value === "delete" || menus.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"
                                                    }`}
                                                  >
                                                    {menus?.label}
                                                  </p>
                                                </button>
                                              );
                                            })}
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div className="flex flex-col w-full h-fit border rounded-xl shadow-xs  border-[#E4E7EC] bg-white relative">
                          <div className="flex w-full min-h-[60px] max-h-[60px] h-[60px] justify-between px-6 py-3">
                            <div className="flex h-full w-fit items-center gap-x-3">
                              <div className="flex w-full gap-x-2 items-center">
                                <OrderIcon />
                                <div className="flex gap-x-2 items-center">
                                  <p className="text-[#475467] text-sm">{t("product:serviceCode")}</p>
                                  <p className="text-[#B54708] font-semibold text-lg">
                                    SS-
                                    {e?.ssNo || "--"}
                                  </p>
                                </div>
                              </div>
                              <span className="w-5 h-5">
                                <ChevronRight />
                              </span>
                              <div className="flex gap-x-3 items-center">
                                <p className="text-[#475467] text-sm">{t("product:supplier")}</p>
                                <div className="flex gap-x-2 items-center">
                                  {e?.supplier?.image ? (
                                    <div>
                                      <CustomImage
                                        borderRadius={2}
                                        src={e?.supplier?.image}
                                        style={{
                                          width: 40,
                                          height: 30,
                                          minWidth: 40,
                                          minHeight: 30,
                                          borderRadius: 4,
                                          borderWidth: 1,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          display: "flex",
                                          borderColor: "#0000001A",
                                          backgroundColor: "#fff",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-[40px] min-w-[40px] h-[30px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                                      <NoEquIcon />
                                    </div>
                                  )}
                                  <p className="text-[#B54708] font-semibold text-md whitespace-nowrap">{e?.supplier?.name || "--"}</p>
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-x-3 h-full">
                              <div className="flex flex-row items-center gap-x-1">
                                <p className="text-sm text-secondary-600">{t("embedded:status")}</p>
                                <p className="text-md font-semibold text-secondary-600">{t("product:plannedNew")}</p>
                              </div>
                              <Button
                                onClick={() => {
                                  setSupplierPurhcaseModal(true);
                                  setJunctionData({ ...e });
                                }}
                                label={t("product:deliverToSupplier")}
                                colorType={"secondary-gray"}
                                iconLeft={<PlusIcon />}
                                size={"sm"}
                              />

                              <Button colorType={"secondary-error"} iconRight={<DeleteIcon />} size={"sm"} />
                            </div>
                          </div>
                          <div className="flex w-full min-h-[76px] max-h-[76px] h-[76px] gap-x-8 px-6 pb-3">
                            <div className="flex h-full w-[224px] min-w-[224px] border border-[#D0D5DD] rounded-lg px-4 py-3 gap-x-3">
                              {e?.responsibleUser?.avatar ? (
                                <div className="cursor-pointer">
                                  <CustomImage
                                    src={e?.responsibleUser?.avatar}
                                    style={{
                                      width: 40,
                                      height: 40,
                                      minWidth: 40,
                                      minHeight: 40,
                                      borderRadius: 100,
                                      borderColor: "#0000001A",
                                      backgroundColor: "#fff",
                                      borderWidth: 2,
                                      borderStyle: "solid",
                                      boxShadow: "0 0 0 2px #00000008",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="cursor-pointer">
                                  <NoAvatar size={40} fontSize={22} name={e?.responsibleUser?.name} lastName={e?.responsibleUser?.lastName} color={"#B54708"} />
                                </div>
                              )}
                              <div className="flex flex-col w-full">
                                <p className="text-[#344054] w-[150px] truncate text-sm font-medium">
                                  {e?.responsibleUser?.name + e?.responsibleUser?.lastName || "--"}
                                </p>
                                <p className="text-[#667085] text-xs">--</p>
                              </div>
                            </div>
                            <div className="flex h-full w-full border border-[#D0D5DD] rounded-lg">
                              <div className="flex flex-col w-full px-4 py-3 h-full border-r border-[#D0D5DD] items-start justify-center">
                                <p className="text-[#475467] text-xs whitespace-nowrap">{t("product:dispatchedQuantity")}</p>
                                <span className="flex gap-x-[2px] items-end">
                                  <p className="text-[#B54708] font-semibold text-sm">{e?.targetedWorkCount || "0"}</p>
                                  <p className="text-[#475467] text-xs">{t("product:pcs")}</p>
                                </span>
                              </div>
                              <div className="flex flex-col w-full px-4 py-3 h-full border-r border-[#D0D5DD] items-start justify-center">
                                <p className="text-[#475467] text-xs whitespace-nowrap">{t("product:receivingQuantity")}</p>
                                <span className="flex gap-x-[2px] items-end">
                                  <p className="text-[#B54708] font-semibold text-sm">{e?.acceptedUnit || "0"}</p>
                                  <p className="text-[#475467] text-xs">{t("product:pcs")}</p>
                                </span>
                              </div>
                              <div className="flex flex-col w-full px-4 py-3 h-full">
                                <p className="text-[#475467] text-xs whitespace-nowrap">{t("product:remainingOrders")}</p>
                                <span className="flex gap-x-[2px] items-end ">
                                  <p className="text-[#B54708] font-semibold text-sm">{e?.deliveredQuantity - e?.acceptedUnit || "0"}</p>
                                  <p className="text-[#475467] text-xs">{t("product:pcs")}</p>
                                </span>
                              </div>
                            </div>
                            <div className="flex h-full w-full border border-[#D0D5DD] rounded-lg">
                              <div className="flex flex-col border-r border-[#D0D5DD] w-1/2 h-full">
                                <div className="flex w-full items-center justify-center border-b px-3 py-1 border-[#D0D5DD] bg-[#F2F4F7] rounded-tl-lg">
                                  <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:unitPrice")}</p>
                                </div>
                                <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px] text-sm font-medium text-[#344054]">
                                  {currencyIcon(e?.currency)} {e?.unitCost}
                                </div>
                              </div>
                              <div className="flex flex-col w-1/2 h-full">
                                <div className="flex w-full items-center justify-center border-b border-[#D0D5DD] px-3 py-1 bg-[#F2F4F7] rounded-tr-lg">
                                  <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:totalPrice")}</p>
                                </div>
                                <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px] text-sm font-medium text-[#344054]">
                                  {currencyIcon(e?.currency)} {e?.targetedWorkCount * e?.unitCost}
                                </div>
                              </div>
                            </div>
                            <div className="flex h-full w-full border border-[#D0D5DD] rounded-lg">
                              <div className="flex flex-col border-r border-[#D0D5DD] w-1/2 h-full">
                                <div className="flex w-full items-center justify-center border-b px-3 py-1 border-[#D0D5DD] bg-[#F2F4F7] rounded-tl-lg">
                                  <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:requestDate")}</p>
                                </div>
                                <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px] text-sm font-semibold text-[#B54708]">
                                  {moment(e?.createdDate).format("DD.MM.YYYY")}
                                </div>
                              </div>
                              <div className="flex flex-col w-1/2 h-full">
                                <div className="flex w-full items-center justify-center border-b border-[#D0D5DD] px-3 py-1 bg-[#F2F4F7] rounded-tr-lg">
                                  <p className="text-xs text-[#475467] font-medium whitespace-nowrap">{t("product:dueDate")}</p>
                                </div>
                                <div className="flex w-full items-center justify-center px-3 py-2 gap-x-[2px] text-sm font-semibold text-[#B54708]">
                                  {moment(e?.estimatedDeliveryDate).format("DD.MM.YYYY")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <CustomModal
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        onClose={() => {
          setIsOpenPlanningModal(false);
        }}
        modalTitle={t("product:serviceSupplyPlanned")}
        subTitle={t("product:specifyPlannings")}
        // subTitle={`${junctionData?.flexProduct ? junctionData?.step + ".Flex" : junctionData?.flexRawMaterial ? "Flex" : junctionData?.step + ".Op"} için planlama detaylarını belirtin.`}
        width="fit-content"
        children={<ServiceSupplyPlanModal setIsOpen={setIsOpenPlanningModal} refetch={refetch} selectedJunction={junctionData} groupId={supplierId} />}
      />
      <CustomModal
        isOpen={finishPurhcaseModal}
        setIsOpen={setFinishPurhcaseModal}
        modalTitle={t("product:receiveDelivery")}
        // subTitle={"Alım miktarını ve varsa ek formları ekleyin"}
        children={<ServiceSupplyReceiveModal refetch={refetch} setIsOpen={setFinishPurhcaseModal} junctionData={junctionData} />}
      />
      <CustomModal
        isOpen={supplierPurhcaseModal}
        setIsOpen={setSupplierPurhcaseModal}
        modalTitle={t("product:deliverToSupplier")}
        // subTitle={t("product:checkTheDetails")}
        children={<SendToSupplierModal refetch={refetch} setIsOpen={setSupplierPurhcaseModal} junctionData={junctionData} />}
      />
      <CustomModal
        isOpen={purchaseQualityForm}
        setIsOpen={setPurchaseQualityForm}
        modalTitle={t("product:qualityControlForm")}
        children={<ServiceSupplyQualityControlForm refetch={refetch} setIsOpen={setPurchaseQualityForm} junctionData={junctionData} />}
      />
      <AlertModal
        title={"Sil"}
        subTitle={"Teslim alınmış yada kaliteden geçmişleri silmek istediğinize emin misiniz ?"}
        isOpen={isAlertJunction}
        setIsOpen={setIsAlertJunction}
        applyButton={() => {
          onDeletedJunctions(deleteIdJunction);
        }}
      />
    </>
  );
};

export default ServiceSupplyPurchaseDetail;

const OrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6673 8.74984V5.6665C16.6673 4.26637 16.6673 3.56631 16.3948 3.03153C16.1552 2.56112 15.7727 2.17867 15.3023 1.93899C14.7675 1.6665 14.0674 1.6665 12.6673 1.6665H7.33398C5.93385 1.6665 5.23379 1.6665 4.69901 1.93899C4.2286 2.17867 3.84615 2.56112 3.60647 3.03153C3.33398 3.56631 3.33398 4.26637 3.33398 5.6665V14.3332C3.33398 15.7333 3.33398 16.4334 3.60647 16.9681C3.84615 17.4386 4.2286 17.821 4.69901 18.0607C5.23379 18.3332 5.93385 18.3332 7.33398 18.3332M10.0007 7.49984H6.66732M11.6673 4.99984H6.66732M8.33398 9.99984H6.66732M9.83772 12.6319L12.8562 10.9452C12.9984 10.8658 13.0694 10.8261 13.1447 10.8105C13.2114 10.7967 13.2801 10.7967 13.3468 10.8103C13.4221 10.8258 13.4933 10.8654 13.6355 10.9446L16.6673 12.6319M9.83772 12.6319L13.2457 14.5309M9.83772 12.6319V15.9547C9.83772 16.1253 9.83772 16.2106 9.86279 16.2868C9.88497 16.3541 9.92123 16.416 9.96917 16.4683C10.0234 16.5274 10.0978 16.5691 10.2466 16.6525L13.2457 18.3332M16.6673 12.6319L13.2457 14.5309M16.6673 12.6319V15.9537C16.6673 16.1247 16.6673 16.2102 16.6422 16.2864C16.6199 16.3539 16.5835 16.4159 16.5355 16.4682C16.4811 16.5273 16.4065 16.569 16.2572 16.6523L13.2457 18.3332M13.2457 14.5309V18.3332"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ChevronRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M7.5 15L12.5 10L7.5 5" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
