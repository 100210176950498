import React, { useState } from "react";
import generateFileUrl from "../../../../utils/generateFileUrl";
import { Button, CustomModal } from "../../../../components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "../../../../assets/icons/machineIcons";
import CloseButton from "../../../../components/buttons/CloseButton";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { InternalOperationIcon } from "../../../../assets/icons/stepHeaderIcons";
import { AddProcessModal } from "../../../../steps/process";
import { useTranslation } from "react-i18next";
import { authStore } from "../../../../stores/auth.store";
import moment from "moment";

const OperationDetailHeader = ({
  data,
  isLoading,
  index,
  refetch,
  datas,
  date,
  isFetching,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
 
  const prevOperations = () => {
    datas.processId = data?.operations[index - 1]?.id;
    navigate(
      `/app/machine/digital-twin/operation-detail/${
        data?.operations[index - 1]?.id
      }/${moment(date).format("YYYY-MM-DD")}`
    );
    refetch();
  };
  const nextOperations = () => {
    datas.processId = data?.operations[index + 1]?.id;
    navigate(
      `/app/machine/digital-twin/operation-detail/${
        data?.operations[index + 1]?.id
      }/${moment(date).format("YYYY-MM-DD")}`
    );
    refetch();
  };

  return (
    <>
      <div className="flex flex-row items-center h-14 w-full pb-2 border-b border-secondary-200">
        {isLoading || isFetching ? (
          <Skeleton className="ml-3" width={140} height={36} borderRadius={8} />
        ) : (
          <div className="flex flex-row gap-3 items-center ">
            {data?.processIcon ? (
              <img
                src={generateFileUrl(data?.processIcon)}
                className="w-8 h-8 min-w-[32px] min-h-[32px]"
              />
            ) : (
              <div className="w-8 h-8">
                <InternalOperationIcon />
              </div>
            )}

            <p className="text-displayXs font-semibold text-secondary-900">
              {data?.processName}
            </p>
          </div>
        )}

        <div className="flex flex-row  items-center ml-auto gap-3">
          {isLoading || isFetching ? (
            <Skeleton width={140} height={36} borderRadius={8} />
          ) : (
            <Button
              size={"md"}
              colorType={"secondary-gray"}
              iconLeft={<ArrowLeftIcon />}
              label={t("buttons:previousOperation")}
              disabled={index == 0 ? true : false}
              onClick={() => prevOperations()}
            />
          )}
          {isLoading || isFetching ? (
            <Skeleton width={140} height={36} borderRadius={8} />
          ) : (
            <Button
              size={"md"}
              colorType={"secondary-gray"}
              iconRight={<ArrowRightIcon />}
              label={t("buttons:nextOperation")}
              disabled={data?.operations?.length - 1 == index ? true : false}
              onClick={() => nextOperations()}
            />
          )}
          {isLoading || isFetching ? (
            <Skeleton width={40} height={36} borderRadius={8} />
          ) : (
            <CloseButton
              size={"lg"}
              onClick={() => {
                if (authStore.user.rolesKey === "OPERATION_RESPONSIBLE") {
                } else {
                  navigate("/app/machine/digital-twin");
                }
              }}
            />
          )}
        </div>
      </div>
      <CustomModal
        modalTitle={t("addProcess:updateProcess")}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        children={<AddProcessModal />}
      />
    </>
  );
};

export default OperationDetailHeader;
