import React, { useEffect, useRef } from "react";

const DepartmentItem = ({
  i,
  dataLength,
  department,
  departments,
  setCurrentPage,
  setVisibleButton,
  setIsIntersecting,
  selectedDepartment,
  onDoubleClickProcess,
  setSelectedDepartment,
  setVisibleRightButton,
}) => {
  const divRef = useRef();

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (i === 0) {
          if (entry.isIntersecting) {
            setVisibleButton(false);
            setIsIntersecting(entry.isIntersecting);
          } else {
            setVisibleButton(true);
          }
        } else if (i === dataLength - 1) {
          if (entry.isIntersecting) {
            setVisibleRightButton(false);
          } else {
            setVisibleRightButton(true);
          }
        }
      });
    }, options);

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [departments]);

  return (
    <>
      <div
        key={i}
        ref={divRef}
        className="w-[170px] min-h-[40px] max-h-[40px] max-w-[170px] min-w-[170px]"
      >
        <div
          className="flex flex-col cursor-pointer item-center"
          onDoubleClick={() => {
            if (department.id !== "all") {
              onDoubleClickProcess(department);
            }
          }}
          onClick={() => {
            if (department.id === "all") {
              setSelectedDepartment();
              setCurrentPage(1);
            } else {
              setSelectedDepartment(department);
              setCurrentPage(1);
            }
          }}
        >
          <div
            className={`px-[14px] py-[10px] select-none cursor-pointer justify-center max-w-[158px] min-w-[158px] border border-[#D0D5DD] shadow-xs flex items-center rounded-lg max-h-[40px] min-h-[40px] ${
              selectedDepartment?.id === department?.id &&
              "bg-teal-25  border border-teal-700"
            } `}
          >
            <p
              className={`text-sm whitespace-nowrap truncate font-semibold text-[#344054] ${
                selectedDepartment?.id === department?.id && "text-teal-900 "
              }`}
            >
              {department.name}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentItem;
