import React from "react";
import { DeleteIcon } from "../../../assets/icons/taskCardIcons";
import { FileIconTaskCard } from "../../../assets/icons/PersonIcons";

const AttachmentLine = ({
  t,
  index,
  name,
  onClick,
  attachmentId,
  editMode,
}) => {
  return (
    <div
      style={{
        backgroundColor:
          index % 2 === 0
            ? localStorage.color_theme === "dark"
              ? "#292929"
              : "#F9FAFB"
            : localStorage.color_theme === "dark"
            ? "#202020"
            : "#fff",
      }}
      className="w-full flex items-center px-3 py-[6px] h-11 rounded-md"
    >
      <FileIconTaskCard />
      <p className="ml-3 text-sm text-[#475467] font-normal truncate w-[90%]">
        {name}
      </p>

      {editMode ? (
        <button
          onClick={onClick}
          className="min-w-[20px] h-5 flex items-center justify-center ml-auto"
        >
          <DeleteIcon />
        </button>
      ) : (
        <a
          target="_blank"
          href={`https://cdn.upu.io/${attachmentId}`}
          onClick={onClick}
          className="ml-auto text-teal-700 dark:text-[#15B79E] hover:underline text-xs font-medium select-none cursor-pointer"
        >
          {t("tasks:view")}
        </a>
      )}
    </div>
  );
};

export default AttachmentLine;
