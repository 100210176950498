import React, { useEffect, useMemo, useState } from "react";
import { ManuelJunctionTimeline, ProductUser } from "./index.js";
import { orderService } from "../../../services/order.service.js";
import TimeBreak from "./subpages/TimeBreak.js";
import MainForm from "./subpages/MainForm.js";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Toast } from "../../../utils/toastify/toast.js";
import { junctionService } from "../../../services/junction.service.js";
import { Button } from "../../../components/index.js";
import { useNavigate } from "react-router-dom";
import { flexProductService } from "../../../services/flex-product.service.js";
import { useQuery } from "@tanstack/react-query";
import { flexJunctionService } from "../../../services/flex-junction.service.js";

const ManuelJunction = ({
  process,
  setIsOpen,
  equipmentData,
  defaultStartForm,
  defaultEndForm,
  setSelectedDates,
  selectedDates,
  leftTab,
  setLeftTab,
  rightTab,
  setRightTab,
  breakTimes,
  setBreakTimes,
  newBreak,
  setNewBreak,
  match,
  dates,
  dateChangeCount,
  workType,
  setWorkType,
  metadataId,

  //editt
  selectedJunctionId,
  setOnlyJuntionValues,
  onlyJuntionValues,
}) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  //new flex

  //new flex
  const [validateError, setValidateError] = useState(false);
  const [endValidateError, setEndValidateError] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [activeJunctionId, setActiveJunctionId] = useState(null);

  const [workPiece, setWorkPiece] = useState(null);
  const [startDate, setStartDate] = useState(defaultStartForm);
  const [endDate, setEndDate] = useState(defaultEndForm);
  const [endBeforeStartError, setEndBeforeStartError] = useState(false);
  const [breakOverlapError, setBreakOverlapError] = useState(false);
  const [breakOutOfRangeError, setBreakOutOfRangeError] = useState(false);
  const [breakEndBeforeStartError, setBreakEndBeforeStartError] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onSubmit = async () => {
    const send = {
      startDate: moment(selectedDates?.start).utc().format(),
      endDate: moment(selectedDates?.end).utc().format(),
      station: equipmentData?.id,
      user: selectedUser?.value,
      type: workType,
      madeWorkCount: workPiece,
      internalOperation: selectedProduct?.flexProduct ? selectedProduct?.internalOperation?.id : selectedOperation?.internalOperation?.process?.id,
      job: selectedProduct?.flexProduct ? selectedProduct?.flexProduct?.id : selectedProduct?.id,
      header: selectedProduct?.flexProduct ? selectedProduct?.id : selectedOperation?.id,
      subEvents: breakTimes
        .filter((breakTime) => moment(breakTime.start).isValid() && moment(breakTime.end).isValid())
        .map((breakTime) => ({
          startDate: moment(breakTime.start).utc().format(),
          endDate: moment(breakTime.end).utc().format(),
          id: breakTime?.id,
        })),
    };

    const flexSend = {
      startDate: moment(selectedDates?.start).utc().format(),
      endDate: moment(selectedDates?.end).utc().format(),
      station: equipmentData?.id,
      user: selectedUser?.value,
      type: workType,
      madeWorkCount: workPiece,
      internalOperation: selectedProduct?.flexProduct ? selectedProduct?.internalOperation?.id : selectedOperation?.internalOperation?.process?.id,

      job: selectedProduct?.flexProduct ? selectedProduct?.flexProduct?.id : selectedProduct?.id,
      header: selectedProduct?.flexProduct ? selectedProduct?.id : selectedOperation?.id,
      subEvents: breakTimes
        .filter((breakTime) => moment(breakTime.start).isValid() && moment(breakTime.end).isValid())
        .map((breakTime) => ({
          startDate: moment(breakTime.start).utc().format(),
          endDate: moment(breakTime.end).utc().format(),
          id: breakTime?.id,
        })),
    };

    if (moment(selectedDates?.start).utc().format() == null || moment(selectedDates?.end).utc().format() == null) {
      return Toast("error", "Lütfen bir zaman aralığı giriniz.");
    }

    if (!selectedProduct?.id) {
      return Toast("error", "Lütfen bir ürün seçin");
    }
    if (!selectedOperation && !selectedProduct?.flexProduct?.id) {
      return Toast("error", "Lütfen bir operasyon seçin");
    }
    if (!selectedUser?.value) {
      return Toast("error", "Lütfen bir personel seçin");
    }
    if (!workPiece) {
      return Toast("error", "Lütfen yapılan iş sayısını girin");
    }
    if (!workType) {
      return Toast("error", "Lütfen bir iş tipi seçin");
    }
    if (validateError || endValidateError || endBeforeStartError) {
      return Toast("error", "Hatalı eylem tarih ve saat aralığı. Lütfen tarihleri kontrol edin");
    }
    if (breakOverlapError || breakOutOfRangeError || breakEndBeforeStartError) {
      return Toast("error", "Hatalı mola tarih ve saat aralığı. Lütfen mola tarihlerini kontrol edin");
    }

    if (selectedProduct?.flexProduct?.id) {
      flexJunctionService.addJunctionManual(flexSend).then((res) => {
        res?.data?.code === 0 ? Toast("success", res?.data?.message) : Toast("error", res?.data?.message);
        if (res?.data?.code === 0) {
          setIsOpen(false);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          navigate(-dateChangeCount);
        }
      });
    } else {
      junctionService.addJunctionManual(send).then((res) => {
        res?.data?.code === 0 ? Toast("success", res?.data?.message) : Toast("error", res?.data?.message);
        if (res?.data?.code === 0) {
          setIsOpen(false);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          navigate(-dateChangeCount);
        }
      });
    }
  };

  const fetchProducts = async () => {
    if (process) {
      try {
        const response = await orderService.products(process);
        setProducts(response);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }
  };

  const getJunctionDetail = async () => {
    if (selectedJunctionId) {
      await junctionService.getJunctionDetail(selectedJunctionId).then((res) => {
        setActiveJunctionId(res?.data?.job?.product?.id);

        setWorkPiece(res?.data?.madeWorkCount || null);
        setWorkType(res?.data?.type || null);

        setSelectedUser({
          label: res?.data?.user?.name + " " + res?.data?.user?.lastName,
          value: res?.data?.user?.id,
          image: res?.data?.user?.avatar,
        });

        setSelectedDates({
          start: moment(res?.data?.startDate).utc().add(3, "hours").format("YYYY-MM-DDTHH:mm"),
          end: moment(res?.data?.endDate).utc().add(3, "hours").format("YYYY-MM-DDTHH:mm"),
        });
      });
    }
  };

  useEffect(() => {
    const getProduct = products?.data?.find((element) => {
      return element?.product?.id == activeJunctionId;
    });

    setSelectedProduct(getProduct);
  }, [activeJunctionId]);

  const getDetails = async () => {
    await fetchProducts();

    await getJunctionDetail();
  };

  useEffect(() => {
    getDetails();
  }, [selectedJunctionId, process]);

  useEffect(() => {
    setStartDate(defaultStartForm);
    setEndDate(defaultEndForm);
  }, [defaultEndForm, defaultStartForm]);

  return (
    <form className="flex flex-col w-full h-full pb-[50px] pr-6 gap-y-6">
      <div className="flex min-h-[40px] max-h-[40px] justify-between">
        <p className="text-[#101828] font-semibold text-lg">{t("addEquipment:addManualEvent")}</p>
        <span className="flex h-full min-w-[300px] max-w-[300px] w-full gap-x-3">
          <Button
            onClick={() => {
              navigate(-dateChangeCount);
            }}
            colorType={"secondary-gray"}
            label={t("buttons:stop")}
            size={"lg"}
          />
          <Button colorType={"primary-machine"} label={t("buttons:save")} size={"lg"} onClick={() => onSubmit()} />
        </span>
      </div>
      <div className="flex w-full h-fit">
        {/* <ManuelJunctionTimeline /> */}

        <TimeBreak
          setRightTab={setRightTab}
          rightTab={rightTab}
          setEndValidateError={setEndValidateError}
          endValidateError={endValidateError}
          validateError={validateError}
          setValidateError={setValidateError}
          //breakks
          setBreakTimes={setBreakTimes}
          breakTimes={breakTimes}
          setNewBreak={setNewBreak}
          newBreak={newBreak}
          //
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          defaultEndForm={defaultEndForm}
          defaultStartForm={defaultStartForm}
          setEndBeforeStartError={setEndBeforeStartError}
          endBeforeStartError={endBeforeStartError}
          breakOverlapError={breakOverlapError}
          setBreakOverlapError={setBreakOverlapError}
          breakOutOfRangeError={breakOutOfRangeError}
          setBreakOutOfRangeError={setBreakOutOfRangeError}
          breakEndBeforeStartError={breakEndBeforeStartError}
          setBreakEndBeforeStartError={setBreakEndBeforeStartError}
          // selectedTimes timess
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
        />
        <MainForm
          selectedProduct={selectedProduct}
          selectedUser={selectedUser}
          setLeftTab={setLeftTab}
          setWorkType={setWorkType}
          workType={workType}
          selectedOperation={selectedOperation}
          equipmentData={equipmentData}
          //selectedDatess gelicek
          startDate={startDate}
          endDate={endDate}
          // selectedTimes timess
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          workPiece={workPiece}
          setWorkPiece={setWorkPiece}
          leftTab={leftTab}
        />
        <ProductUser
          setLeftTab={setLeftTab}
          leftTab={leftTab}
          products={products?.data}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
          setSelectedProduct={setSelectedProduct}
          selectedProduct={selectedProduct}
          setSelectedOperation={setSelectedOperation}
          selectedOperation={selectedOperation}
          //new
          process={process}
        />
      </div>
    </form>
  );
};

export default ManuelJunction;
