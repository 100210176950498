import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, CustomModal, Input, Loading, UploadProfile } from "../../components";
import { ArrowLeftIcon } from "../../assets/icons/PersonIcons";
import generateFileUrl from "../../utils/generateFileUrl";
import StockOperationGrid from "./components/StockOperationGrid";
import { useQuery } from "@tanstack/react-query";
import { productService } from "../../services/product.service";
import { useTranslation } from "react-i18next";
import { Toast } from "../../utils/toastify/toast";
import { rawMaterialService } from "../../services/raw-material.service";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { instance } from "../../libs/client";
import Selector from "../../components/inputs/Selector";
import TextArea from "../../components/inputs/TextArea";
import CropperPdf from "../../components/inputs/CropperPdf";
import Badgets from "../../components/buttons/Badgets";
import Preview from "../../components/modal/Preview";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { DangerIcon } from "../../assets/icons/machineIcons";
import { orderService } from "../../services/order.service";
import { DeleteIcon } from "../../assets/icons/departmentIcon";
import AddRawMaterialModal from "./modals/AddRawMaterialModal";

const StockEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let location = useLocation();
  const [isAlert, setIsAlert] = useState(false);
  const [items, setItems] = useState([]);
  const { stockId } = useParams();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [pdfData, setPdfData] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropData, setCropData] = useState("");
  const [croppedDataCDN, setCroppedDataCDN] = useState("");
  const [pdfDataCDN, setPdfDataCDN] = useState("");
  const [addRawMaterial, setAddRawMaterial] = useState(false);
  const [isFirstModal, setIsFirstModal] = useState(true);
  const [isCrop, setIsCrop] = useState(false);
  const [windowSize, setWindowSize] = useState(window?.innerWidth);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { data, refetch, isLoading, isFetching } = useQuery({
    enabled: false,
    queryKey: ["find-by-product-stock"],
    queryFn: async () => await productService.productDetailData(stockId),
    retry: 0,
  });

  const rawMaterials = useQuery({
    queryKey: ["raw-material-sources-stock"],
    queryFn: async () => await rawMaterialService.getRawMaterials(),
  });

  const orders = useQuery({
    enabled: false,
    queryKey: ["finished-orders-stock"],
    queryFn: async () => await orderService.getFinishedOrderByStock(stockId),
    retry: 0,
  });

  const draggableList = data?.internalOperations?.concat(data?.externalOperations);
  const schema = yup.object({
    image: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    code: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    stockCode: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    materialId: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    quantityUsedPerPiece: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    description: yup
      .string()
      .notRequired()
      .typeError(`${t("validation:string")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const lastData = {
      ...data,
      technicalDrawing: pdfDataCDN,
      technicalDrawingCrop: croppedDataCDN,
    };
    await productService.updateProduct(selectedProduct.id, lastData).then((res) => {
      setButtonLoading(true)
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        // navigate(location?.pathname?.split("/edit")[0] + "/detail/" + res.data.id);
        navigate(-1);
      } else {
        Toast("error", res.data.message);
      }
      setButtonLoading(false)
    });
  };
  const deleteStock = async () => {
    await productService.deleteProduct(selectedProduct.id).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        navigate(navigate(-1));
      } else {
        Toast("error", res.data.message);
      }
    });
  };
  const uploadBase64 = async (file) => {
    const data = { imageBinary: file };
    const response = await instance({
      method: "post",
      url: "file/upload/base64",
      data: { data },
    });
    setPdfDataCDN(response.data?.file);
  };
  const uploadBase64Cropped = async (file) => {
    const data = { imageBinary: file };
    const response = await instance({
      method: "post",
      url: "file/upload/base64",
      data: { data },
    });
    setCroppedDataCDN(response?.data?.file);
  };
  const handleCustomSubmit = () => {
    handleSubmit(onSubmit)();
  };
  useEffect(() => {
    setSelectedProduct({ ...data, edit: true });
    setItems(draggableList?.sort((a, b) => a?.step - b?.step));
  }, [data]);
  useEffect(() => {
    orders.refetch();
    refetch();
  }, [stockId]);
  useEffect(() => {
    pdfData && uploadBase64(pdfData);
    cropData && uploadBase64Cropped(cropData);
  }, [pdfData, cropData]);
  useEffect(() => {
    if (selectedProduct?.edit === true) {
      setValue("image", selectedProduct?.image);
      setValue("name", selectedProduct?.name);
      setValue("code", selectedProduct?.code);
      setValue("stockCode", selectedProduct?.stockCode);
      setValue("description", selectedProduct?.description);
      setValue("quantityUsedPerPiece", selectedProduct?.quantityUsedPerPiece);
      setValue("materialId", selectedProduct?.rawMaterial?.id);
      if (selectedProduct?.technicalDrawingCrop) {
        setCroppedImage(generateFileUrl(selectedProduct?.technicalDrawingCrop));
      } else {
        setCroppedImage(null);
      }
    }
  }, [selectedProduct]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window?.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSmallScreen = windowSize <= 1023;
  const groupByStepArray = (arr) => {
    let grouped = {};
    if (arr) {
      grouped = arr.reduce((acc, curr) => {
        const step = curr.step;
        if (!acc[step]) {
          acc[step] = [];
        }
        acc[step].push(curr);
        return acc;
      }, {});
    }
    return Object.keys(grouped).map((step) => grouped[step]);
  };
  return (
    <>
      <div className="w-full h-full flex flex-col overflow-hidden relative">
        {isLoading || isFetching ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loading size={56} primary={"#B54708"} secondary={"#fff"} />
          </div>
        ) : (
          <>
            <div className="w-full h-[78px] border-b flex items-center">
              <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
              <img className="w-[72px] h-[54px] rounded border border-gray-300 ml-3" src={generateFileUrl(data?.image)} />
              <div className="flex flex-col ml-3">
                <h1 className="text-xl font-semibold text-gray-900">
                  {data?.name}
                  {data?.revisionNo != null && data?.revisionNo != 0 && `(REVISION-${data?.revisionNo})`}
                </h1>
                <p className="text-gray-600">Stock Code: {data?.stockCode} </p>
              </div>
              <div className="flex items-center ml-auto gap-3 mr-1">
                <Button colorType={"secondary-gray"} size={"lg"} label={t("buttons:cancel")} type={"button"} onClick={() => navigate(-1)} />
                <Button
                  onClick={() => {
                    setIsAlert(true);
                  }}
                  iconLeft={<DeleteIcon />}
                  size={"lg"}
                  label={isSmallScreen ? t("buttons:delete") : t("buttons:delete")}
                  colorType={"primary-error"}
                />
                <Button
                  colorType={"primary-product"}
                  size={"lg"}
                  label={buttonLoading ? <Loading size={20} secondary={"#fff"} /> : isSmallScreen ? t("buttons:save") : t("buttons:saveChanges")}
                  type={"button"}
                  onClick={buttonLoading ? undefined : handleCustomSubmit}
                />
              </div>
            </div>
            <div className="flex flex-col items-center w-full overflow-y-auto scrollbar-hide px-0.5 gap-y-12 h-full">
              <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full max-h-[320px] mt-5">
                <div className="flex min-w-full w-full h-full flex-col gap-6">
                  <div className="flex h-full justify-end items-end px-1 gap-6">
                    <div className="flex w-1/2 flex-col gap-4">
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <UploadProfile
                            title={t("fileInput:uploadAvatar")}
                            subTitle={t("fileInput:removePicture")}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                        name="image"
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Input
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            theme={"product"}
                            label={t("product:productName")}
                            validate={errors?.name ? "error" : ""}
                            errorMessage={errors?.name ? errors?.name?.message : ""}
                          />
                        )}
                        name="name"
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Input
                            label={t("product:productCode")}
                            onChange={onChange}
                            onBlur={onBlur}
                            theme={"product"}
                            value={value}
                            validate={errors?.code ? "error" : ""}
                            errorMessage={errors?.code ? errors?.code?.message : ""}
                          />
                        )}
                        name="code"
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Input
                            label={t("product:stockCode")}
                            onChange={onChange}
                            theme={"product"}
                            onBlur={onBlur}
                            value={value}
                            validate={errors?.stockCode ? "error" : ""}
                            errorMessage={errors?.stockCode ? errors?.stockCode?.message : ""}
                          />
                        )}
                        name="stockCode"
                      />
                    </div>
                    <div className="h-full w-[1px] bg-secondary-200" />
                    <div className="flex w-1/2 flex-col gap-4">
                      <CropperPdf
                        pdfData={croppedImage}
                        setPdfData={setPdfData}
                        setIsCrop={setIsCrop}
                        pdfLink={generateFileUrl(selectedProduct?.technicalDrawing)}
                      />
                      {/* <div
                        onClick={() => {
                          setIsFirstModal(true);
                          setAddRawMaterial(true);
                        }}
                        className="flex cursor-pointer items-start flex-col gap-y-[6px]"
                      >
                        <p className="text-sm font-medium text-secondary-700">
                          {t("product:rawMaterial")}
                        </p>
                        <ImageUploader
                          color={"#D0D5DD"}
                          width="64"
                          height="48"
                        />
                      </div> */}
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Selector
                            value={value}
                            theme={"product"}
                            items={rawMaterials?.data?.map((item) => {
                              return { label: item?.name, value: item?.id };
                            })}
                            label={t("product:rawMaterial")}
                            onChange={onChange}
                            onBlur={onBlur}
                            validate={errors?.materialId ? "error" : ""}
                            errorMessage={errors?.materialId ? errors?.materialId?.message : ""}
                          />
                        )}
                        name="materialId"
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Input
                            label={t("product:amountTobeUsed")}
                            onChange={onChange}
                            theme={"product"}
                            onBlur={onBlur}
                            value={value}
                            validate={errors?.quantityUsedPerPiece ? "error" : ""}
                            errorMessage={errors?.quantityUsedPerPiece ? errors?.quantityUsedPerPiece?.message : ""}
                          />
                        )}
                        name="quantityUsedPerPiece"
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextArea
                            label={t("product:description")}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            theme={"product"}
                            rows={5}
                            validate={errors?.description ? "error" : ""}
                            errorMessage={errors?.description ? errors?.description?.message : ""}
                            minHeight={110}
                          />
                        )}
                        name="description"
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className="w-full flex-1">
                <div className="w-full border-b border-secondary-200 text-lg font-semibold flex items-center gap-x-2.5">
                  {t("product:receipe")}
                  <Badgets colorType={"fill-warning"} size={"sm"} label={groupByStepArray(items)?.length} />
                </div>
                <StockOperationGrid
                  refetch={refetch}
                  stockId={stockId}
                  items={items}
                  isLoading={isLoading}
                  setItems={setItems}
                  hasOrders={orders?.data?.activeOrder?.length + orders?.data?.finishedOrder?.length > 0 ? true : false}
                  copyData={{
                    ...data,
                    technicalDrawing: pdfDataCDN,
                    technicalDrawingCrop: croppedDataCDN,
                    operations: items,
                  }}
                />
              </div>
            </div>
          </>
        )}
        {isFetching && (
          <div
            style={{
              height: "46px",
              borderWidth: isFetching ? 1 : 0,
              top: isFetching ? 22 : -100,
            }}
            className="rounded-xl z-[100] transition-all duration-300 border-black/60 backdrop-blur-sm bg-black/60 overflow-hidden absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center px-3"
          >
            <Loading primary={"#fff"} secondary={"rgb(0 0 0 / 0.4)"} size={26} />
            <span className="text-lg font-semibold text-white ml-2">{t("embedded:pleaseWaitData")}</span>
          </div>
        )}
      </div>
      <AlertModal
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        title={t("product:deleteProduct")}
        subTitle={t("product:cannotBeUndone")}
        applyButton={deleteStock}
        icon={<DangerIcon />}
      />
      <CustomModal
        isOpen={isCrop}
        setIsOpen={setIsCrop}
        modalTitle={t("product:pdfCropper")}
        children={<Preview file={pdfData} setCropData={setCropData} setIsCrop={setIsCrop} croppedImage={croppedImage} setCroppedImage={setCroppedImage} />}
      />
      <CustomModal
        isOpen={addRawMaterial}
        setIsOpen={setAddRawMaterial}
        modalTitle={isFirstModal ? t("product:addRawMaterial") : t("product:unitQuantity")}
        navigateButton={isFirstModal ? false : true}
        navigateOnClick={() => setIsFirstModal(!isFirstModal)}
        children={<AddRawMaterialModal setAddRawMaterial={setAddRawMaterial} setIsFirstModal={setIsFirstModal} isFirstModal={isFirstModal} />}
      />
    </>
  );
};

export default StockEdit;
