import React, { useEffect, useState } from 'react'
import ServiceSupplyReceivedEventCard from '../../../components/new-cards/service-supply/components/ServiceSupplyReceivedEventCard';
import { Controller, useForm } from 'react-hook-form';
import QtyInput from '../../../components/inputs/QtyInput';
import { Button, UploadProfile } from '../../../components';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import ServiceSupplySupplierEventCard from '../../../components/new-cards/service-supply/components/ServiceSupplySupplierEventCard';
import { serviceSupplyService } from '../../../services/service-supply.service';
import { useStore } from '../../../hooks/useStores';
import { Toast } from '../../../utils/toastify/toast';

const ServiceSupplyReceiveModal = ({ junctionData, setIsOpen, refetch }) => {
    
    const { t } = useTranslation(["product"]);
    const { auth } = useStore()
    const [priceValues, setPriceValues] = useState({});

    const schema = yup.object({
        qualityImage: yup.string().notRequired(),
        acceptedImage: yup.string().notRequired(),
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (value) => {
        const data = {
            orderId: junctionData?.job?.id,
            process: junctionData?.externalOperation?.id,
            supplier: junctionData?.supplier?.id,
            headerId: junctionData?.header?.id,
            user: auth?.user?.id,
            serviceSupplierJunctionId: junctionData?.id,
            targetedWorkCount: Number(priceValues),
            purchaseFile: value?.qualityImage,
            secondPurchaseFile: value?.acceptedImage,
        };


        await serviceSupplyService.createServiceSupplyReceivedNew(data).then((res) => {
            if (res?.data?.code === 0) {
                Toast("success", res?.data?.message);
                setIsOpen(false);
                refetch();
            } else {
                Toast("error", res?.data?.message);
            }
        });
    };

    useEffect(() => {
        if (junctionData?.status === "received") {
            setValue("qualityImage", junctionData?.purchaseFile);
            setValue("acceptedImage", junctionData?.secondPurchaseFile);
            setPriceValues(junctionData?.targetedWorkCount);
        }
    }, [junctionData]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col min-w-[385px] max-w-[385px] w-[385px]">
            <p className='text-sm mt-1 font-normal text-secondary-600'>{t("product:addThePurchase")}</p>
            <div className="h-[1px] w-full mt-3 bg-[#E4E7EC]"></div>
            <div className='flex flex-row items-center gap-x-2 pt-3'>
                <SupplierIcons />
                <p className='text-sm font-medium text-secondary-700'>{t("product:supplyCode")}</p>
                <p className='text-md font-semibold text-[#B54708]'>SS-{junctionData?.status === "received" ? junctionData?.serviceSupplierJunction?.plannedJunctionRef?.ssNo || "--" : junctionData?.plannedJunctionRef ? junctionData?.plannedJunctionRef?.ssNo || "--" : junctionData?.ssNo}</p>
            </div>
            <div className="flex flex-col gap-y-4">
                <div className="flex w-full pt-5">
                    {junctionData?.status === "received" ? (
                        <ServiceSupplyReceivedEventCard data={junctionData} isModal={true} />
                    ) : (
                        <ServiceSupplySupplierEventCard data={junctionData} isModal={true} />
                    )}

                </div>
                <div className="h-[1px] w-full mt-3 bg-[#E4E7EC]"></div>

                <div className="flex flex-col gap-y-6 w-full pb-5">
                    <QtyInput
                        theme={"product"}
                        label={t("product:receivingQuantity")}
                        qtyType={junctionData?.rawMaterial ? junctionData?.rawMaterial?.unit : t("product:pcs")}
                        onChange={(e) => {
                            setPriceValues(e?.target?.value);
                        }}
                        value={priceValues}
                    />
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <UploadProfile title={t("product:controlForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
                        )}
                        name="qualityImage"
                    />
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <UploadProfile title={t("product:approvalForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
                        )}
                        name="acceptedImage"
                    />
                    <div className="h-[1px] w-full bg-[#E4E7EC]"></div>
                </div>
            </div>

            <div className="flex flex-col gap-y-3">
                <span>
                    <Button colorType={"primary-product"} label={t("product:receiveDelivery")} size={"lg"} type={"submit"} />
                </span>
                <span>
                    <Button onClick={() => setIsOpen(false)} colorType={"secondary-gray"} label={t("buttons:stop")} size={"lg"} />
                </span>
            </div>
        </form>
    )
}

export default ServiceSupplyReceiveModal

export const SupplierIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
            <path d="M18.4436 9C18.4436 8.65482 18.1637 8.375 17.8186 8.375C17.4734 8.375 17.1936 8.65482 17.1936 9H18.4436ZM17.1936 9C17.1936 13.2524 13.7463 16.6996 9.49392 16.6996V17.9496C14.4367 17.9496 18.4436 13.9428 18.4436 9H17.1936ZM9.49392 16.6996C7.13308 16.6996 5.02083 15.6379 3.60747 13.9636L2.65229 14.7699C4.29301 16.7135 6.74943 17.9496 9.49392 17.9496V16.6996Z" fill="#475467" />
            <path d="M0.543945 9.00043C0.543945 9.34561 0.823767 9.62543 1.16895 9.62543C1.51412 9.62543 1.79395 9.34561 1.79395 9.00043H0.543945ZM1.79395 9.00043C1.79395 4.74802 5.24118 1.30078 9.49359 1.30078V0.0507812C4.55083 0.0507812 0.543945 4.05766 0.543945 9.00043H1.79395ZM9.49359 1.30078C11.823 1.30078 13.9103 2.33442 15.3232 3.97024L16.2692 3.15317C14.629 1.25416 12.2015 0.0507812 9.49359 0.0507812V1.30078Z" fill="#475467" />
            <path d="M2.35711 14.6517C3.01439 14.6517 3.54722 14.1189 3.54722 13.4616C3.54722 12.8043 3.01439 12.2715 2.35711 12.2715C1.69982 12.2715 1.16699 12.8043 1.16699 13.4616C1.16699 14.1189 1.69982 14.6517 2.35711 14.6517Z" stroke="#475467" stroke-width="1.25" stroke-miterlimit="10" />
            <path d="M16.6432 5.58628C17.3005 5.58628 17.8334 5.05345 17.8334 4.39617C17.8334 3.73889 17.3005 3.20605 16.6432 3.20605C15.986 3.20605 15.4531 3.73889 15.4531 4.39617C15.4531 5.05345 15.986 5.58628 16.6432 5.58628Z" stroke="#475467" stroke-width="1.25" stroke-miterlimit="10" />
            <path d="M12.691 11.357C12.9649 11.1989 13.1336 10.9067 13.1336 10.5905V7.40879C13.1336 7.09256 12.9649 6.80035 12.691 6.64223L9.93564 5.05142C9.66178 4.8933 9.32436 4.8933 9.0505 5.05142L6.29511 6.64223C6.02125 6.80035 5.85254 7.09256 5.85254 7.40879V10.5905C5.85254 10.9067 6.02125 11.1989 6.29511 11.357L9.0505 12.9478C9.32436 13.1059 9.66178 13.1059 9.93564 12.9478L12.691 11.357Z" stroke="#475467" stroke-width="1.25" stroke-miterlimit="10" />
            <path d="M5.85254 6.89746L8.60791 8.4883C9.15565 8.80454 9.83049 8.80453 10.3782 8.48829L13.1336 6.89746" stroke="#475467" stroke-width="1.25" stroke-miterlimit="10" />
            <path d="M9.49316 9V13.2037" stroke="#475467" stroke-width="1.25" stroke-miterlimit="10" />
        </svg>
    );
};