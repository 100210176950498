import React from "react";
import { EquipmentsIcon } from "../../../assets/icons/stepHeaderIcons";
import { Button } from "../../../components";

const SettingUndefinedEquimentsCards = ({
  t,
  key,
  name,
  isOpen,
  onClick,
  setIsOpen,
  description,
  openNewEquipment,
  setOpenNewEquipment,
}) => {
  const handleClick = () => {
    setOpenNewEquipment(!openNewEquipment);
    setIsOpen(!isOpen);
    onClick();
  };

  return (
    <>
      <div
        key={key}
        className="bg-white min-h-[64px] my-4 xs:w-full w-full border border-l-[8px] dark:bg-[#141414] dark:border-[#424242] border-gray-300 shadow-xs rounded-lg flex items-center justify-between py-[12px] px-4"
      >
        <div className="flex items-center text-sm font-semibold text-[#101828] gap-3 dark:text-[#A3A3A3]">
          <EquipmentsIcon />
          <div className="leading-5 text-[14px]">
            <p className="text-[#344054] font-semibold dark:text-[#D6D6D6]">
              {name || "No Name"}
            </p>
            <p className="text-[#667085] font-normal dark:text-[#D6D6D6]">
              {description || "----"}
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-3 items-center justify-center text-[#475467] dark:text-[#A3A3A3]">
          <Button
            colorType={"secondary-machine"}
            size={"sm"}
            label={t("settingTab:define")}
            onClick={handleClick}
          />
        </div>
      </div>
    </>
  );
};

export default SettingUndefinedEquimentsCards;
