import React, { useState } from "react";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../CustomImage";
import NoAvatar from "../../avatar/NoAvatar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DotsVerticalIcon, EquipmentEditIcon } from "../card-icons";
import { purchaseService } from "../../../services/purchase.service";
import AlertModal from "../../alert-Modal/Alert-Modal";
import { PollDeleteIcon } from "../../../assets/icons/PersonIcons";
import { Toast } from "../../../utils/toastify/toast";
const MaterialSupplyEventCardPlannedTriple = ({ data, onClick, refetch, setFinishPurhcaseModal, isModal, setIsOpenPlanningModal, setIsOpenPlanningModalData, selectedCardId, setFinishPurhcaseModalData }) => {

  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const menuOptions = [
    {
      label: t("buttons:edit"),
      value: "editPlanned",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <PollDeleteIcon />,
    },
  ];
  const onDeleted = async () => {
    await purchaseService.deletePlannedPurchaseNew("planned", data?.id).then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data?.id);
      } else {
        Toast("error", res?.data?.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };
  return (
    <>
      <div onClick={onClick} className="flex w-full flex-row justify-center items-center group relative cursor-grab">
        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 mr-auto">
          <div className={`w-full border-t-4 rounded-t-[8px] border-t-success-500`}></div>
          <div className={`flex w-full border-b h-[55px]`}>
            {(
              data?.purchaseRequest?.rawMaterial
                ? data?.purchaseRequest?.rawMaterial?.image
                : data?.purchaseRequest?.productInternalOperation
                  ? data?.purchaseRequest?.productInternalOperation?.product?.image
                  : data?.purchaseRequest?.productExternalOperation
                    ? data?.purchaseRequest?.productExternalOperation?.product?.image
                    : data?.purchaseRequest?.product
                      ? data?.purchaseRequest?.product?.image
                      : "--"
            ) ? (
              <div>
                <CustomImage
                  src={
                    data?.purchaseRequest?.rawMaterial
                      ? data?.purchaseRequest?.rawMaterial?.image
                      : data?.purchaseRequest?.productInternalOperation
                        ? data?.purchaseRequest?.productInternalOperation?.product?.image
                        : data?.purchaseRequest?.productExternalOperation
                          ? data?.purchaseRequest?.productExternalOperation?.product?.image
                          : data?.purchaseRequest?.product
                            ? data?.purchaseRequest?.product?.image
                            : "--"
                  }
                  style={{
                    width: 72,
                    height: 54,
                    minWidth: 72,
                    minHeight: 54,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRadius: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[72px] min-w-[72px] h-[54px] min-h-[54px]  flex border-r  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                {/* {item?.purchaseRequest?.productInternalOperation?.product?.name?.slice(0, 2)?.toUpperCase()} */}
                {data?.purchaseRequest?.rawMaterial
                  ? data?.purchaseRequest?.rawMaterial?.name.slice(0, 2)?.toUpperCase()
                  : data?.purchaseRequest?.productInternalOperation
                    ? data?.purchaseRequest?.productInternalOperation?.product?.name.slice(0, 2)?.toUpperCase()
                    : data?.purchaseRequest?.productExternalOperation
                      ? data?.purchaseRequest?.productExternalOperation?.product?.name.slice(0, 2)?.toUpperCase()
                      : data?.purchaseRequest?.product
                        ? data?.purchaseRequest?.product?.name.slice(0, 2)?.toUpperCase()
                        : "--"}
              </div>
            )}
            <div className="flex flex-col w-full min-h-full">
              <div className="flex w-full h-1/2 border-b px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="text-xxs">{t("product:plannedNew")}</p>
                <p className="font-medium text-xs">
                  {data?.plannedUnit % 1 === 0
                    ? data?.plannedUnit || 0
                    : (data?.plannedUnit || 0).toFixed(2)}
                </p>
              </div>
              <div className="flex w-full h-1/2 px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="text-xxs">{t("product:receiveds")}</p>
                <p className="font-medium text-xs">
                  {data?.acceptedUnit % 1 === 0
                    ? data?.acceptedUnit || 0
                    : (data?.acceptedUnit || 0).toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          <div className={`flex flex-col items-start w-full pl-3 pr-[44px] gap-y-[2px]  py-2`}>

            <p className="text-xs font-normal text-[#667085]  truncate w-[99%]">
              {t("product:stock")} : {" "}
              {data?.purchaseRequest?.rawMaterial
                ? data?.purchaseRequest?.rawMaterial?.stockCode
                : data?.purchaseRequest?.productInternalOperation
                  ? `${data?.purchaseRequest?.productInternalOperation?.product?.stockCode} (${data?.purchaseRequest?.productInternalOperation?.step}.Op)`
                  : data?.purchaseRequest?.productExternalOperation
                    ? `${data?.purchaseRequest?.productExternalOperation?.product?.stockCode} (${data?.purchaseRequest?.productExternalOperation?.step}.Op)`
                    : data?.purchaseRequest?.product
                      ? data?.purchaseRequest?.product?.stockCode
                      : "--"}
            </p>
            <p className="text-xs font-semibold text-[#344054] truncate w-[99%]">
              {data?.purchaseRequest?.rawMaterial
                ? data?.purchaseRequest?.rawMaterial?.name
                : data?.purchaseRequest?.productInternalOperation
                  ? data?.purchaseRequest?.productInternalOperation?.product?.name
                  : data?.purchaseRequest?.productExternalOperation
                    ? data?.purchaseRequest?.productExternalOperation?.product?.name
                    : data?.purchaseRequest?.product
                      ? data?.purchaseRequest?.product?.name
                      : "--"}
            </p>
          </div>
        </div>

        <div className="cursor-pointer flex absolute w-[50px] h-[50px] min-w-[50px] min-h-[50px] z-[100] bottom-1">
          {data?.responsibleUser?.avatar ? (
            <div className="cursor-pointer">
              <CustomImage
                src={data?.responsibleUser?.avatar}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                  borderWidth: 2,
                  borderStyle: "solid",
                  boxShadow: "0 0 0 2px #00000008",
                }}
              />
            </div>
          ) : (
            <div className="cursor-pointer">
              <NoAvatar size={48} fontSize={22} name={data?.responsibleUser?.name} lastName={data?.responsibleUser?.lastName} color={"#B54708"} />
            </div>
          )}
        </div>
        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 ml-auto">
          <div className={`w-full border-t-4 rounded-t-[8px] border-t-success-500`}></div>
          <div className={`flex w-full border-b justify-end h-[55px]`}>
            <div className="flex flex-col w-full min-h-full">
              <div className="flex w-full h-1/2 border-b px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="font-medium text-xs">{moment(data?.requestDate).format("DD.MM.YYYY") || "--"}</p>
                <p className="text-xxs">{t("product:requestss")}</p>
              </div>
              <div className="flex w-full h-1/2 px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="font-medium text-xs">{moment(data?.estimatedDeliveryDate).format("DD.MM.YYYY") || "--"}</p>
                <p className="text-xxs">{t("product:due")}</p>
              </div>
            </div>

            {data?.supplier?.image ? (
              <div>
                <CustomImage
                  src={data?.supplier?.image}
                  style={{
                    width: 72,
                    height: 54,
                    minWidth: 72,
                    minHeight: 54,
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    borderRadius: 0,
                    borderLeftWidth: 1,
                    borderRightWidth: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[72px] min-w-[72px] h-[54px] min-h-[54px]  flex border-r  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                {data?.supplier?.name.slice(0, 2)?.toUpperCase()}
              </div>
            )}
          </div>
          <div className={`flex flex-col items-end w-full pr-3 pl-[44px] gap-y-[2px] py-2`}>
            <p className="text-xs text-[#667085]">MS-{data?.msNo || "--"}</p>
            <p className="text-xs font-semibold text-end text-[#344054] truncate w-[99%]">{data?.supplier?.name || "--"}</p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className={`hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded ${isModal === true ? " group-hover:hidden " : "group-hover:flex "}  bg-white  right-[3px] top-[3px] z-[140] items-center justify-center cursor-pointer`}
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] z-[160] h-fit bg-white border rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {menuOptions?.map((item, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "delete") {
                      setIsAlert(true);
                    }
                    if (item.value === "editPlanned") {
                      setIsOpenPlanningModalData(data)
                      setIsOpenPlanningModal(true);
                    }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                    }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
      </div>
      <AlertModal
        title={"Sil"}
        subTitle={"Planlamayı silmek ister misiniz"}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(data?.id);
        }}
      />
    </>
  );
};

export default MaterialSupplyEventCardPlannedTriple;
