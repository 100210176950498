import React from "react";
import { useTranslation } from "react-i18next";

const NewOrderHeaderCard = ({ selectedOperation, id, name, planned, unPlanned, onClick, icon }) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick}
      className={`min-w-[172px] rounded-t-lg group hover:shadow ${
        selectedOperation?.id === id ? "border-b-[2px] border-b-[#A15C07] h-[96px] shadow" : "h-[96px]"
      } rounded-b-none border cursor-pointer border-gray-300 select-none flex flex-col`}
    >
      <div className="flex flex-row items-center justify-center w-full gap-x-1.5 p-2 border-b border-b-[#E4E7EC]">
        <p className={`text-base group-hover:text-[#B54708] truncate ${selectedOperation?.id === id ? "text-[#B54708] font-semibold" : "text-secondary-800 font-medium"} `}>{name}</p>
      </div>
      <div className="flex flex-row items-center w-full h-full">
        <div className={`flex flex-col items-center w-1/2  border-r ${selectedOperation?.id === id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
          <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
            <p className={`text-xs ${selectedOperation?.id === id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>{t("reports:planned")}</p>
          </div>
          <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
            <p className={`text-sm truncate ${selectedOperation?.id === id ? "font-semibold text-[#475467]" : "font-medium text-[#667085]"}`}>
              {planned} {t("product:day")}
            </p>
          </div>
        </div>
        <div className={`flex flex-col items-center w-1/2  ${selectedOperation?.id === id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
          <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
            <p className={`text-xs ${selectedOperation?.id === id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>{t("product:unplanned")}</p>
          </div>
          <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
            <p className={`text-sm truncate ${selectedOperation?.id === id ? "font-semibold text-[#A15C07]" : "font-medium text-[#667085]"}`}>
              {unPlanned} {t("product:day")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrderHeaderCard;
