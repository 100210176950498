import React from "react";
import CustomImage from "../../CustomImage";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import {
  AcceptedIcon,
  ClockIcon,
  EquCalculate,
  FlexProductIcon,
  GreenAvgIcon,
  JunctionNumberIcon,
  JunctionNumberIconSelected,
  PieceIcon,
  RedAvgIcon,
  RejectedIcon,
  TargetIcon,
} from "../card-icons";
import NoAvatar from "../../avatar/NoAvatar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { formatSeconds } from "../../../utils/secondToHoursAndMinute";
import Badgets from "../../buttons/Badgets";

const TimeLineDoneCard = ({ data, items, findJunctionIndexByIds, equipmentData }) => {
  const { t } = useTranslation();
  const filterJunction = items?.find((item) => item?.activeJunction?.some((junction) => junction?.id === data?.junction?.junctionId));

  const matchedJunction = filterJunction?.activeJunction?.find((junction) => junction?.id === data?.junction?.junctionId);
  const result = matchedJunction ? matchedJunction : [];

  const convertSeconds = (seconds) => {
    if (isNaN(seconds) || seconds == Infinity) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };

  const oneOrderTime = (start, end, count, type) => {
    const diff = moment(end).diff(moment(start), "seconds");
    const oneOrder = diff / count;
    return type === "setup" ? diff : `${oneOrder?.toFixed(0)}`;
  };

  // const piece = result?.productType === "flex" ? result?.flexHeader?.quantity : result?.header?.piece;

  // const totalEstimatedTime = result?.productType === "flex" ? result?.flexHeader?.estimatedTime : result?.header?.internalOperation?.estimatedTime;
  // const setupEstimatedTime = result?.productType === "flex" ? result?.flexHeader?.estimatedSettingsTime * 60 : result?.header?.internalOperation?.estimatedSettingsTime * 60;
  // const formattedEstimatedOpTime =
  //   result?.type === "setup" ? convertSeconds(result?.productType === "flex" ? result?.flexHeader?.estimatedSettingsTime * 60 : result?.header?.internalOperation?.estimatedSettingsTime * 60) : convertSeconds(totalEstimatedTime);

  // const startDate = result?.startDate;
  // const endDate = result?.endDate;
  // const madeWorkCount = result?.metadata?.madeWorkCount;
  // const type = result?.type;
  // const oneOrderTimeInSeconds = oneOrderTime(startDate, endDate, madeWorkCount, type);
  // const formattedOneOrderTime = convertSeconds(oneOrderTimeInSeconds);

  // const isRed = result?.type === "setup" ? setupEstimatedTime < oneOrderTimeInSeconds : totalEstimatedTime / piece < oneOrderTimeInSeconds;

  const totalEstimatedTime = matchedJunction?.productType === "flex" ? matchedJunction?.flexHeader?.estimatedTime : matchedJunction?.header?.internalOperation?.estimatedTime;
  const setupEstimatedTime = matchedJunction?.productType === "flex" ? matchedJunction?.flexHeader?.estimatedSettingsTime * 60 : matchedJunction?.header?.internalOperation?.estimatedSettingsTime * 60;
  const formattedEstimatedOpTime =
    matchedJunction?.type === "setup" ? convertSeconds(matchedJunction?.productType === "flex" ? matchedJunction?.flexHeader?.estimatedSettingsTime * 60 : matchedJunction?.header?.internalOperation?.estimatedSettingsTime * 60) : convertSeconds(totalEstimatedTime);

  const startDate = matchedJunction?.startDate;
  const endDate = matchedJunction?.endDate;
  const madeWorkCount = matchedJunction?.metadata?.madeWorkCount;
  const type = matchedJunction?.type;
  const oneOrderTimeInSeconds = oneOrderTime(startDate, endDate, madeWorkCount, type);
  const formattedOneOrderTime = convertSeconds(oneOrderTimeInSeconds);
  const isRedOld = matchedJunction?.type == "setup" ? setupEstimatedTime < oneOrderTimeInSeconds : totalEstimatedTime < oneOrderTimeInSeconds;

  const estimatedTimeGroup = result?.header
    ? result?.header?.internalOperation?.plannableNodes?.find((element) =>
      equipmentData?.some((equipment) => equipment?.nodeId === element?.id)
    )
    : result?.flexHeader?.plannableNodes
      ? result?.flexHeader?.plannableNodes?.find((element) =>
        equipmentData?.some((equipment) => equipment?.nodeId === element?.id)
      )
      : result?.flexHeader?.estimatedTime;


  const estimatedTimeInSeconds = result?.type === "setup"
    ? estimatedTimeGroup?.estimatedSettingsTime
      ? estimatedTimeGroup?.estimatedSettingsTime
      : estimatedTimeGroup
    : estimatedTimeGroup?.estimatedTime
      ? estimatedTimeGroup?.estimatedTime
      : estimatedTimeGroup;

  // Perform the comparison in seconds
  const isRed = estimatedTimeInSeconds < oneOrderTimeInSeconds;

  return (
    <div className="flex flex-col items-start">
      <div
        className={`flex w-full flex-col border border-t-4 ${matchedJunction?.type === "work"
            ? "border-t-success-500"
            : matchedJunction?.type === "setup"
              ? "border-t-[#EAAA08]"
              : "border-t-[#98A2B3]"
          } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
      >
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-2 w-[64%] min-w-[64%]">
            {matchedJunction?.productType === "flex" ? (
              <>
                {matchedJunction?.flexProduct?.image ? (
                  <div>
                    <CustomImage
                      borderRadius={2}
                      src={matchedJunction?.flexProduct?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : (
              <>
                {matchedJunction?.job?.product?.image ? (
                  <div>
                    <CustomImage
                      borderRadius={2}
                      src={matchedJunction?.job?.product?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            )}

            {matchedJunction?.type === "manual_finished_work" ? (
              <div className="flex flex-col items-start gap-y-1">
                <p className="text-sm font-semibold text-secondary-900 w-[100%] truncate">{t("product:finishedWork")}</p>
                <div className="flex flex-row items-center gap-x-3">
                  <div className="flex flex-row items-center">
                    <ClockIcon />
                    <p className="text-xs font-medium ml-1 text-secondary-600">
                      {moment(timeFormatter(matchedJunction.startDate).formatted).format("HH:mm")} -{" "}
                      {moment(timeFormatter(matchedJunction?.endDate).formatted).format("HH:mm")}
                    </p>
                    <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:hours")}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-start gap-y-1">
                <div className="flex flex-row items-center gap-x-1 w-full">
                  {matchedJunction?.productType === "flex" ? (
                    <>
                      <FlexProductIcon />
                      <p className="text-sm font-semibold text-secondary-900 w-[180px] truncate">{matchedJunction?.flexProduct?.name || ""}</p>
                    </>
                  ) : (
                    <p className="text-sm font-semibold text-secondary-900 w-[180px] truncate">{matchedJunction?.job?.product?.name || ""}</p>
                  )}
                </div>

                <div className="flex flex-row items-center gap-x-3">
                  <div className="flex flex-row items-center">
                    <ClockIcon />
                    <p className="text-xs font-medium ml-1 text-secondary-600">
                      {moment(timeFormatter(matchedJunction?.startDate).formatted).format("HH:mm")} -{" "}
                      {moment(timeFormatter(matchedJunction?.endDate === null ? new Date() : matchedJunction?.endDate).formatted).format("HH:mm")}
                    </p>
                    <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:hours")}</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <PieceIcon />
                    <p className="text-xs font-medium ml-1 text-secondary-600">{result?.metadata?.madeWorkCount || "--"}</p>
                    <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:pcs")}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {result?.user?.avatar ? (
            <div className="cursor-pointer">
              <CustomImage
                src={result?.user?.avatar}
                style={{
                  width: 48,
                  height: 48,
                  flexDirection: "column",
                  borderRadius: 100,
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div className="cursor-pointer">
              <NoAvatar size={48} fontSize={22} name={result?.user?.name} lastName={result?.user?.lastName} color={"#B54708"} />
            </div>
          )}
          <div className="flex flex-col z-[20] items-start rounded border-[0.5px] ml-auto absolute border-[#D0D5DD] w-[86px] min-w-[86px] bg-white top-[6px] right-[6px]">
            {result?.metadata?.qualityStatus === "waiting" ? (
              <div className={`flex flex-row items-center justify-center gap-x-1 h-5 min-h-5 px-[2px] py-[2px] ${matchedJunction?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"}  w-full rounded-t-[4px] border-b-[0.5px] border-secondary-300 ml-auto`}>
                <p className="text-xxs text text-secondary-600 font-semibold">{t("product:applied")}</p>
              </div>
            ) : (
              <div className={`flex flex-row items-center justify-center gap-x-1 h-5 min-h-5 px-[2px] py-[2px] ${matchedJunction?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"}  w-full rounded-t-[4px] border-b-[0.5px] border-secondary-300 ml-auto`}>
                <p className="text-xxs text text-secondary-600 font-semibold">{t("product:qualityNew")}</p>
                <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded">
                  <p className="text-xxs font-semibold text-white"> %{matchedJunction?.productType === "flex" ? matchedJunction?.flexHeader?.qualityPercent || "--" : result?.header?.internalOperation?.qualityPercent || "--"}</p>
                </div>
              </div>
            )}

            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                {result?.metadata?.qualityStatus === "waiting" ? <PieceIcon /> : <AcceptedIcon />}
              </div>
              <p className={`text-xs font-medium ${result?.metadata?.qualityStatus === "waiting" ? "text-secondary-600" : "text-[#079455]"} ml-1 `}>
                {result?.metadata?.qualityStatus === "waiting" ? result?.madeWorkCount || "0" : result?.metadata?.acceptedWorkCount}
              </p>
              <p className="text-[10px] font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                {result?.metadata?.qualityStatus === "waiting" ? <EquCalculate /> : <RejectedIcon />}
              </div>
              <p className={`text-xs font-medium ${result?.metadata?.qualityStatus === "waiting" ? "text-secondary-600" : "text-error-600"} ml-1 `}>
                {result?.metadata?.qualityStatus === "waiting" ? result?.metadata?.calculateWorkCount : result?.metadata?.rejectedWorkCount || "0"}
              </p>
              <p className="text-xxs font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
            </div>
          </div>
        </div>
        <div className={`flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] ${matchedJunction?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"} w-full border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg`}>
          <div className="flex flex-row items-center gap-x-2">
            <div className="flex flex-row items-center">
              <TargetIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">
                {result?.header?.internalOperation?.plannableNodes?.length == 0 ? (
                  <>
                  {formattedEstimatedOpTime || "--"}
                  </>
                ) : (
                  <>
                    {
                  result?.type === "setup"
                    ? estimatedTimeGroup?.estimatedSettingsTime
                      ? `${Math.floor(estimatedTimeGroup?.estimatedSettingsTime / 60 || 0)} dk ${(estimatedTimeGroup?.estimatedSettingsTime % 60 || 0).toFixed(0)} sn` || ""
                      : `${Math.floor(estimatedTimeGroup / 60 || 0)} dk ${(estimatedTimeGroup % 60 || 0).toFixed(0)} sn` || ""
                    : estimatedTimeGroup?.estimatedTime
                      ? `${Math.floor(estimatedTimeGroup?.estimatedTime / 60 || 0)} dk ${(estimatedTimeGroup?.estimatedTime % 60 || 0).toFixed(0)} sn` || ""
                      : `${Math.floor(estimatedTimeGroup / 60 || 0)} dk ${(estimatedTimeGroup % 60 || 0).toFixed(0)} sn` || ""
                }
                  </>
                )}
              
              </p>
              <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMinActual")}</p>
            </div>
            {/* <div className="flex flex-row items-center">
                  <PieceIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{result?.metadata?.madeWorkCount || "--"}</p>
                  <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:pcs")}</p>
                </div> */}
            <div className="flex flex-row items-center">
              {result?.header?.internalOperation?.plannableNodes?.length == 0 ? isRedOld ? <RedAvgIcon /> : <GreenAvgIcon /> : isRed ? <RedAvgIcon /> : <GreenAvgIcon />}
              <p className={`text-xs font-medium ml-1 ${result?.header?.internalOperation?.plannableNodes?.length == 0 ? isRedOld ? "text-[#D92D20]" : "text-[#079455]" : isRed ? "text-[#D92D20]" : "text-[#079455]"}`}>{formattedOneOrderTime || "--"}</p>
              <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:average")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row min-w-[440px] max-w-[440px] items-center gap-x-3 w-full mt-3">
        {data?.junctionId === result?.id ? (
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-row items-center gap-x-1">
              <JunctionNumberIconSelected color={"#6941C6"} />
              <p className="text-md font-semibold text-[#6941C6]">{findJunctionIndexByIds(filterJunction?.nodeId, result?.id)}</p>
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center gap-x-1">
            <JunctionNumberIcon color={"#667085"} />
            <p className="text-md font-semibold text-secondary-600">{findJunctionIndexByIds(filterJunction?.nodeId, result?.id)}</p>
          </div>
        )}
        <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
            <p className="text-xs font-normal text-secondary-600">{"upu.point"}</p>
          </div>
          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
            <p className={`text-base w-[100px] text-center truncate font-semibold text-[#9B7D07]`}>{result?.metadata?.upuPoint?.toFixed(2) || "0"}</p>
          </div>
        </div>
        <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
            <p className="text-xs  font-normal text-secondary-600">{t("reports:performance")}</p>
          </div>
          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
            <p className={`text-base w-[100px] truncate text-center font-semibold text-[#079455]`}>
              %{result?.metadata?.performancePercent > 1 ? 1 * 100 : (result?.metadata?.performancePercent * 100)?.toFixed(0) || "0"}
            </p>
          </div>
        </div>
        <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
            <p className="text-xs font-normal text-secondary-600">{t("product:qualityControlRate")}</p>
          </div>
          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
            <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
              %{result?.metadata?.qualityPercent > 1 ? 1 * 100 : (result?.metadata?.qualityPercent * 100)?.toFixed(0) || "0"}
            </p>
          </div>
        </div>
      </div>
      {(result?.header?.step || result?.flexHeader?.step) === undefined ? null : (
        <span className="absolute left-4 top-4">
          <Badgets colorType={"fill-gray"} label={result?.productType === "flex" ? result?.flexHeader?.step + ".op" : result?.header?.step + ".op"} size={"sm"} />
        </span>
      )}
    </div>
  );
};

export default TimeLineDoneCard;
