import React, { useEffect, useState } from "react";
import { ArrowLeftIcon } from "../assets/icons/PersonIcons";
import { Button, Input } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import SettingShiftInfo from "./SettingShiftInfo";
import SecondSettingShiftInfo from "./SecondSettingShiftInfo";
import TertiarySettingShiftInfo from "./TertiarySettingShiftInfo";
import { companyService } from "../services/company.service";
import { Toast } from "../utils/toastify/toast";
import { personalService } from "../services/personal.service";
import ShiftCalendarTable from "./machine/components/ShiftCalendarTable";
import moment from "moment";
import { useStore } from "../hooks/useStores";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

const EditDailyShift = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { shiftId } = useParams();
    const { auth } = useStore();
    const [selectedShift, setSelectedShift] = useState(1);
    const [groupName, setGroupName] = useState("");
    const [personals, setPersonals] = useState([]);
    const [shifts, setShifts] = useState([{ name: `1.${t("routes:shift")}`, value: 1 }]);
    const [firstShiftData, setFirstShiftData] = useState();
    const [secondShiftData, setSecondShiftData] = useState();
    const [tertiaryShiftData, setTertiaryShiftData] = useState();
    const currentOptions = shifts.find((element) => element.value === selectedShift);
    const e = auth?.user?.company?.dayStartHour;
    const companyStartHour = moment().set("hour", e.split(":")[0]).set("minute", e.split(":")[1]).set("second", 0).toDate();
    const companyStartHourUnix = moment(companyStartHour).set("second", 0).set("milliseconds", 0).valueOf();

    const saveData = async () => {
        const data = { name: groupName, shifts: [firstShiftData, secondShiftData, tertiaryShiftData].filter(Boolean) };
        if (data?.name === "") {
            Toast("error", "Vardiya adı boş bırakılamaz");
            return;
        }
        await companyService.updateDailyShift(shiftId, data).then((res) => {
            if (res.data.code === 1) {
                Toast("error", res?.data?.message);
            } else {
                Toast("success", res?.data?.message);
                navigate(-1);
            }
        });
    };
    const handlePersonals = async () => {
        await personalService.activePersonals().then(({ data }) => {
            setPersonals(
                data.map((d) => {
                    return {
                        value: d.id,
                        label: d.name + " " + d.lastName,
                    };
                })
            );
        });
    };
    const handleShift = async () => {
        await companyService.getOneShiftGroup(shiftId).then(({ data }) => {
            setGroupName(data?.name);
            if (data?.shifts?.length === 1) {
                // setFirstShiftData(data?.shifts[0]);
                setFirstShiftData({
                    id: data?.shifts[0]?.id ? data?.shifts[0]?.id : null,
                    userId: data?.shifts[0]?.user ? data?.shifts[0]?.user?.id : null,
                    end: moment(data?.shifts[0]?.end).utc().format(),
                    start: moment(data?.shifts[0]?.start).utc().format(),
                    activity: data?.shifts[0]?.activity,
                    secondaryValidate: data?.shifts[0]?.secondaryValidate,
                    validate: data?.shifts[0]?.validate,
                    color: data?.shifts[0]?.color,
                });
                setShifts([{ name: `1.${t("routes:shift")}`, value: 1 }]);
            } else if (data?.shifts?.length === 2) {
                // setFirstShiftData(data?.shifts[0]);
                // setSecondShiftData(data?.shifts[1]);
                setFirstShiftData({
                    id: data?.shifts[0]?.id ? data?.shifts[0]?.id : null,
                    userId: data?.shifts[0]?.user ? data?.shifts[0]?.user?.id : null,
                    end: moment(data?.shifts[0]?.end).utc().format(),
                    start: moment(data?.shifts[0]?.start).utc().format(),
                    activity: data?.shifts[0]?.activity,
                    secondaryValidate: data?.shifts[0]?.secondaryValidate,
                    validate: data?.shifts[0]?.validate,
                    color: data?.shifts[0]?.color,
                });
                setSecondShiftData({
                    id: data?.shifts[1]?.id ? data?.shifts[1]?.id : null,
                    userId: data?.shifts[1]?.user ? data?.shifts[1]?.user?.id : null,
                    end: moment(data?.shifts[1]?.end).utc().format(),
                    start: moment(data?.shifts[1]?.start).utc().format(),
                    activity: data?.shifts[1]?.activity,
                    secondaryValidate: data?.shifts[1]?.secondaryValidate,
                    validate: data?.shifts[1]?.validate,
                    color: data?.shifts[1]?.color,
                });
                setShifts([
                    { name: `1.${t("routes:shift")}`, value: 1 },
                    { name: `2.${t("routes:shift")}`, value: 2 },
                ]);
            } else {
                // setFirstShiftData(data?.shifts[0]);
                // setSecondShiftData(data?.shifts[1]);
                // setTertiaryShiftData(data?.shifts[2]);
                setFirstShiftData({
                    id: data?.shifts[0]?.id ? data?.shifts[0]?.id : null,
                    userId: data?.shifts[0]?.user ? data?.shifts[0]?.user?.id : null,
                    end: moment(data?.shifts[0]?.end).utc().format(),
                    start: moment(data?.shifts[0]?.start).utc().format(),
                    activity: data?.shifts[0]?.activity,
                    secondaryValidate: data?.shifts[0]?.secondaryValidate,
                    validate: data?.shifts[0]?.validate,
                    color: data?.shifts[0]?.color,
                });
                setSecondShiftData({
                    id: data?.shifts[1]?.id ? data?.shifts[1]?.id : null,
                    userId: data?.shifts[1]?.user ? data?.shifts[1]?.user?.id : null,
                    end: moment(data?.shifts[1]?.end).utc().format(),
                    start: moment(data?.shifts[1]?.start).utc().format(),
                    activity: data?.shifts[1]?.activity,
                    secondaryValidate: data?.shifts[1]?.secondaryValidate,
                    validate: data?.shifts[1]?.validate,
                    color: data?.shifts[1]?.color,
                });
                setTertiaryShiftData({
                    id: data?.shifts[2]?.id ? data?.shifts[2]?.id : null,
                    userId: data?.shifts[2]?.user ? data?.shifts[2]?.user?.id : null,
                    end: moment(data?.shifts[2]?.end).utc().format(),
                    start: moment(data?.shifts[2]?.start).utc().format(),
                    activity: data?.shifts[2]?.activity,
                    secondaryValidate: data?.shifts[2]?.secondaryValidate,
                    validate: data?.shifts[2]?.validate,
                    color: data?.shifts[2]?.color,
                });
                setShifts([
                    { name: `1.${t("routes:shift")}`, value: 1 },
                    { name: `2.${t("routes:shift")}`, value: 2 },
                    { name: `3.${t("routes:shift")}`, value: 3 },
                ]);
            }
        });
    };
    const dates = [
        { start: firstShiftData?.start, end: firstShiftData?.end, color: firstShiftData?.color, index: 0 },
        { start: secondShiftData?.start, end: secondShiftData?.end, color: secondShiftData?.color, index: 0 },
        { start: tertiaryShiftData?.start, end: tertiaryShiftData?.end, color: tertiaryShiftData?.color, index: 0 },
    ];
    const firstBreaks = firstShiftData
        ? firstShiftData?.activity
            ?.map((brk, idx) => ({ start: moment(brk?.start).utc().format(), end: moment(brk?.end).utc().format(), index: 1 }))
            ?.filter(Boolean)
        : [];
    const secondBreaks = secondShiftData
        ? secondShiftData?.activity
            ?.map((brk, idx) => ({ start: moment(brk?.start).utc().format(), end: moment(brk?.end).utc().format(), index: 1 }))
            ?.filter(Boolean)
        : [];
    const tertiaryBreaks = tertiaryShiftData
        ? tertiaryShiftData?.activity
            ?.map((brk, idx) => ({ start: moment(brk?.start).utc().format(), end: moment(brk?.end).utc().format(), index: 1 }))
            ?.filter(Boolean)
        : [];
    const insertAndAdjustDates = (dates, newDates) => {
        newDates?.forEach((newDate) => {
            let adjustedDates = [];
            let inserted = false;
            for (let i = 0; i < dates.length; i++) {
                let current = dates[i];
                if (!inserted && newDate.start < current.start) {
                    adjustedDates.push(newDate);
                    inserted = true;
                }
                if (
                    (newDate.start >= current.start && newDate.start <= current.end) ||
                    (newDate.end >= current.start && newDate.end <= current.end) ||
                    (newDate.start <= current.start && newDate.end >= current.end)
                ) {
                    if (newDate.start > current.start) {
                        adjustedDates.push({ start: current.start, end: newDate.start, index: current?.index, color: current?.color });
                    }
                    if (newDate.end < current.end) {
                        adjustedDates.push({ start: newDate.end, end: current.end, index: current?.index, color: current?.color });
                    }
                } else {
                    adjustedDates.push(current);
                }
            }
            if (!inserted) {
                adjustedDates.push(newDate);
            }
            dates = adjustedDates.sort((a, b) => a.start - b.start);
        });
        return dates;
    };
    const newDates = [...firstBreaks, ...secondBreaks, ...tertiaryBreaks];
    const updatedDates = insertAndAdjustDates(dates, newDates);
    const data = [{ day: null, name: null, times: updatedDates?.filter((e) => e?.start) }];

    // const data = [{ day: null, name: null, times: [] }];

    const defaultDayStartHour = "09:00";
    const companyDayStartHour = auth?.user?.company?.dayStartHour || defaultDayStartHour;
    const [hours, minutes] = companyDayStartHour.split(":").map(Number);
    const dayStart = new Date();
    dayStart.setHours(hours, minutes, 0, 0);

    useEffect(() => {
        handleShift();
        handlePersonals();
    }, [shiftId]);
    return (
        <>
            <div className="w-full h-full flex flex-col">
                <div className="h-[56px] w-full flex items-center border-b">
                    <Button
                        size={"md"}
                        onClick={() => navigate(-1)}
                        colorType={"tertiary-gray"}
                        iconLeft={
                            <div className="text-secondary-700">
                                <ArrowLeftIcon />
                            </div>
                        }
                    />
                    <h1 className="text-2xl font-semibold text-gray-900 ml-3">{t("addShift:createNewDailyShift")}</h1>
                </div>
                <div className="flex-1 pt-5 w-full overflow-y-auto scrollbar-hide flex flex-col">
                    <div className="w-full mb-6">
                        <ShiftCalendarTable data={data} isDailyAdd />
                    </div>
                    <ShiftTabs
                        selectedShift={selectedShift}
                        setSelectedShift={setSelectedShift}
                        shifts={shifts}
                        t={t}
                        setShifts={setShifts}
                        disableNewTab={firstShiftData}
                        isEmptySecondTab={!secondShiftData}
                        setSecondShiftData={setSecondShiftData}
                        setTertiaryShiftData={setTertiaryShiftData}
                    />
                    {currentOptions.value === 1 ? (
                        <SettingShiftInfo
                            currentOptions={currentOptions}
                            collectedData={setFirstShiftData}
                            allData={firstShiftData}
                            personals={personals}
                            dayStart={dayStart}
                            dayStartNew={companyStartHourUnix}
                        />
                    ) : currentOptions.value === 2 ? (
                        <SecondSettingShiftInfo
                            currentOptions={currentOptions}
                            collectedData={setSecondShiftData}
                            allData={secondShiftData}
                            firstShiftData={firstShiftData}
                            personals={personals}
                            dayStart={dayStart}
                        />
                    ) : currentOptions.value === 3 ? (
                        <TertiarySettingShiftInfo
                            currentOptions={currentOptions}
                            collectedData={setTertiaryShiftData}
                            allData={tertiaryShiftData}
                            personals={personals}
                            secondShiftData={secondShiftData}
                            dayStart={dayStart}
                        />
                    ) : null}
                    <div className="w-full min-h-[84px] bg-gray-50 mt-6 flex items-center justify-between border p-4 rounded-lg">
                        <div className="flex items-center">
                            <p className="w-[280px] text-secondary-700 text-sm">{t("addShift:shiftDayName")}</p>
                            <div className="w-[512px]">
                                <Input
                                    onChange={(e) => {
                                        setGroupName(e.target.value);
                                    }}
                                    value={groupName}
                                />
                            </div>
                        </div>
                        <Button label={t("buttons:saveEndExit")} size={"md"} colorType={"primary-machine"} width={158} onClick={saveData} />
                    </div>
                </div>
            </div>
        </>
    );
};

const ShiftTabs = ({ shifts, selectedShift, setSelectedShift, t, setShifts, disableNewTab, isEmptySecondTab, setSecondShiftData, setTertiaryShiftData }) => {
    return (
        <div className="w-full rounded-lg border border-gray-300 min-h-[40px] flex overflow-hidden">
            {shifts.map((item, index) => {
                return (
                    <div
                        onClick={(e) => {
                            setSelectedShift(item.value);
                        }}
                        key={index}
                        className={`flex-1 h-full group flex items-center justify-center border-r relative border-gray-300 ${selectedShift === item.value ? "bg-gray-50 " : "bg-white"
                            }`}
                    >
                        <p className={`text-sm select-none ${selectedShift === item.value ? "text-secondary-900" : "text-secondary-600"}`}>{item.name}</p>
                        {item?.value !== 1 && (
                            <div className="hidden group-hover:flex absolute right-2">
                                <div
                                    style={{ display: shifts?.length === 3 && item?.value == 2 ? "none" : "flex" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (shifts.length == 2) {
                                            setSelectedShift(1);
                                            setSecondShiftData(undefined);
                                            setShifts([{ name: "1.Vardiya", value: 1 }]);
                                        } else if (shifts.length == 3) {
                                            setSelectedShift(2);
                                            setTertiaryShiftData(undefined);
                                            setShifts([
                                                { name: "1.Vardiya", value: 1 },
                                                { name: "2.Vardiya", value: 2 },
                                            ]);
                                        }
                                    }}
                                    className="p-1  text-gray-500 hover:text-red-500 cursor-pointer"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path
                                            d="M13.3333 4.99935V4.33268C13.3333 3.39926 13.3333 2.93255 13.1517 2.57603C12.9919 2.26243 12.7369 2.00746 12.4233 1.84767C12.0668 1.66602 11.6001 1.66602 10.6667 1.66602H9.33333C8.39991 1.66602 7.9332 1.66602 7.57668 1.84767C7.26308 2.00746 7.00811 2.26243 6.84832 2.57603C6.66667 2.93255 6.66667 3.39926 6.66667 4.33268V4.99935M8.33333 9.58268V13.7493M11.6667 9.58268V13.7493M2.5 4.99935H17.5M15.8333 4.99935V14.3327C15.8333 15.7328 15.8333 16.4329 15.5608 16.9677C15.3212 17.4381 14.9387 17.8205 14.4683 18.0602C13.9335 18.3327 13.2335 18.3327 11.8333 18.3327H8.16667C6.76654 18.3327 6.06647 18.3327 5.53169 18.0602C5.06129 17.8205 4.67883 17.4381 4.43915 16.9677C4.16667 16.4329 4.16667 15.7328 4.16667 14.3327V4.99935"
                                            stroke="currentColor"
                                            stroke-width="1.67"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
            {shifts?.length < 3 && (
                <button
                    disabled={!disableNewTab}
                    onClick={() => {
                        if (shifts.length == 1) {
                            setShifts([...shifts, { name: "2.Vardiya", value: 2 }]);
                        } else {
                            if (isEmptySecondTab) {
                                Toast("warning", "İkinci Vardiyadaki değişikliker kaydedilmedi!");
                            } else {
                                setShifts([...shifts, { name: "3.Vardiya", value: 3 }]);
                            }
                        }
                    }}
                    type="button"
                    className={`flex-1 h-full flex items-center justify-center ${!disableNewTab ? "opacity-20" : ""}`}
                >
                    <p className={`text-sm text-secondary-600 mr-2`}>{t("addShift:addShifts")}</p>
                    <PlusIcon />
                </button>
            )}
        </div>
    );
};

const PlusIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M8.83317 3.33301V12.6663M4.1665 7.99967H13.4998" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default EditDailyShift;
