import React from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { Button, CustomModal } from "../../../components";
import { ArrowLeftIcon } from "../../../assets/icons/machineIcons";
import { useNavigate } from "react-router-dom";
import { EditIconPerson } from "../../../assets/icons/PersonIcons";
import { QrIcon } from "../../../assets/icons/equipmentIcon";
import UserQrModal from "../components/UserQrModal";
import { useTranslation } from "react-i18next";

const PersonelDetailHeader = ({ user, dateChangeCount }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpenQr, setIsOpenQr] = React.useState(false);

  const data = {
    userImage: user?.avatar,
    name: user?.name,
    lastName: user?.lastName,
    role: user?.role,
    id: user?.id,
    department: user?.department,
  };

  return (
    <div className="flex flex-row items-center h-20 w-full pb-2 border-b border-secondary-200 mt-3 mb-3">
      <div className="flex flex-row gap-3 items-center justify-between ">
        <div className="flex flex-col items-center gap-1">
          <Button size={"md"} colorType={"tertiary-gray"} iconLeft={<ArrowLeftIcon />} onClick={() => navigate(-dateChangeCount)} />

          <span className="step-1">
            <Button
              colorType={"secondary-machine"}
              onClick={() => {
                setIsOpenQr(true);
              }}
              size={"sm"}
              iconLeft={<QrIcon color={"#7F56D9"} />}
            />
          </span>
        </div>

        {data?.userImage ? (
          <img src={generateFileUrl(data?.userImage)} className="w-12 h-12 min-w-[48px] min-h-[48px] rounded-full object-cover" />
        ) : (
          <div className="w-12 h-12">
            <NoAvatar size={48} name={data?.name} lastName={data?.lastName} />
          </div>
        )}

        <p className="text-displayXs font-semibold text-secondary-700">
          {data?.name} {data?.lastName}
        </p>
        <p className="text-[20px] font-regular text-secondary-500">{data?.role}</p>
      </div>
      <CustomModal
        isOpen={isOpenQr}
        setIsOpen={setIsOpenQr}
        children={<UserQrModal userId={data?.id} name={data?.name + " " + data?.lastName} role={data?.role} department={data?.department} />}
      />
    </div>
  );
};

export default PersonelDetailHeader;

{
  /* <CustomModal
        isOpen={isOpenQr}
        setIsOpen={setIsOpenQr}
        modalTitle={t("embedded:getQr")}
        children={<UserQrModal nodeId={match[2]} name={name} image={image} process={process} equipmentCode={equipmentData?.equipmentCode} />}
      /> */
}
