import React, { useState } from "react";
import CustomImage from "../../CustomImage";
import { EquipmentDeleteIcon, ProductDetailIcon } from "../../../assets/icons/machineIcons";
import { AcceptedIcon, DotsVerticalIcon, EndClockIcon, FlexProductIcon, QualityDetailIcon, RejectedIcon, StartClockIcon } from "../card-icons";
import NoAvatar from "../../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { purchaseService } from "../../../services/purchase.service";
import AlertModal from "../../alert-Modal/Alert-Modal";
import { Toast } from "../../../utils/toastify/toast";

const MaterialSupplyEventCard = ({ data, setPurchaseQualityForm, setPurchaseQualityFormData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const menuOptionSupply = [
    {
      label: t("product:qualityControlForm"),
      value: "qualityControlForm",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("product:purchaseDetails"),
      value: "purchaseDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const onDeleted = async () => {
    await purchaseService.deletePurchase(data?.id, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        // refetch();
        window.location.reload();
        setIsAlert(false);
        setDeleteId(data?.id);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  return (
    <>
      <div className="flex w-full flex-row justify-center items-center group relative">
        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 mr-auto">
          <div className={`w-full border-t-4 rounded-t-[8px] ${"border-t-[#98A2B3]"}`}></div>
          <div className="flex flex-row items-center w-full border-b">
            {data?.purchaseRequest?.type === "semi_internal_product" ? (
              <>
                {data?.purchaseRequest?.productInternalOperation ? (
                  <>
                    {data?.purchaseRequest?.productInternalOperation?.product?.image ? (
                      <div
                        data-tooltip-id={`${data?.id}`}
                        data-tooltip-place="right"
                        data-tooltip-content={`${t("product:productName")}: ${data?.purchaseRequest?.productInternalOperation?.product?.name} - ${t(
                          "product:stockCode"
                        )}: ${data?.purchaseRequest?.productInternalOperation?.product?.stockCode}`}
                      >
                        <CustomImage
                          borderRadius={2}
                          src={data?.purchaseRequest?.productInternalOperation?.product?.image}
                          style={{
                            width: 56,
                            height: 42,
                            minWidth: 56,
                            minHeight: 42,
                            borderRadius: 4,
                            borderWidth: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            display: "flex",
                            borderColor: "#0000001A",
                            backgroundColor: "#fff",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        data-tooltip-id={`${data?.id}`}
                        data-tooltip-content={`
          ${data?.purchaseRequest?.productInternalOperation?.product?.name} 
          `}
                        className="w-[56px] min-w-[56px] min-h-[42px] h-[42px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl cursor-pointer"
                      >
                        {data?.purchaseRequest?.productInternalOperation?.product?.name?.slice(0, 2)?.toUpperCase()}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {data?.purchaseRequest?.productExternalOperation?.product?.image ? (
                      <div
                        data-tooltip-id={`${data?.id}`}
                        data-tooltip-place="right"
                        data-tooltip-content={`${t("product:productName")}: ${data?.purchaseRequest?.productExternalOperation?.product?.name} - ${t(
                          "product:stockCode"
                        )}: ${data?.purchaseRequest?.productExternalOperation?.product?.stockCode}`}
                      >
                        <CustomImage
                          borderRadius={2}
                          src={data?.purchaseRequest?.productExternalOperation?.product?.image}
                          style={{
                            width: 56,
                            height: 42,
                            minWidth: 56,
                            minHeight: 42,
                            borderRadius: 4,
                            borderWidth: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            display: "flex",
                            borderColor: "#0000001A",
                            backgroundColor: "#fff",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        data-tooltip-id={`${data?.id}`}
                        data-tooltip-content={`
        ${data?.purchaseRequest?.productInternalOperation?.product?.name} 
        `}
                        className="w-[56px] min-w-[56px] min-h-[42px] h-[42px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl cursor-pointer"
                      >
                        {data?.purchaseRequest?.productExternalOperation?.product?.name?.slice(0, 2)?.toUpperCase()}
                      </div>
                    )}
                  </>
                )}
              </>
            ) : data?.purchaseRequest?.rawMaterial ? (
              <>
                {data?.purchaseRequest?.rawMaterial?.image ? (
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${data?.purchaseRequest?.rawMaterial?.name} - ${t("product:stockCode")}: ${
                      data?.purchaseRequest?.rawMaterial?.stockCode
                    }`}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={data?.purchaseRequest?.rawMaterial?.image}
                      style={{
                        width: 56,
                        height: 42,
                        minWidth: 56,
                        minHeight: 42,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-content={`
      ${data?.purchaseRequest?.rawMaterial?.name} 
      `}
                    className="w-[56px] min-w-[56px] h-[42px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl cursor-pointer"
                  >
                    {data?.purchaseRequest?.rawMaterial?.name?.slice(0, 2)?.toUpperCase()}
                  </div>
                )}
              </>
            ) : data?.purchaseRequest?.product?.image ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-place="right"
                data-tooltip-content={`${t("product:productName")}: ${data?.purchaseRequest?.product?.name} - ${t("product:stockCode")}: ${
                  data?.purchaseRequest?.product?.stockCode
                }`}
              >
                <CustomImage
                  borderRadius={2}
                  src={data?.purchaseRequest?.product?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-content={`
  ${data?.purchaseRequest?.product?.name} 
  `}
                className="w-[56px] min-w-[56px] h-[42px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl cursor-pointer"
              >
                {data?.purchaseRequest?.product?.name?.slice(0, 2)?.toUpperCase()}
              </div>
            )}
            <div className="flex flex-col items-start px-2">
              <div className="flex flex-row items-center gap-x-1">
                <AcceptedIcon />
                <p className="text-xs font-normal text-secondary-600">
                  {setPurchaseQualityForm && setPurchaseQualityFormData ? data?.incomingUnit : data?.acceptedUnit || "0"}
                </p>
              </div>
              <div className="flex flex-row items-center gap-x-1">
                <RejectedIcon />
                <p className="text-xs font-normal text-secondary-600">{data?.rejectedUnit || "0"}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start w-full pl-3 py-1 pr-[44px]">
            <p className="text-xs font-normal text-secondary-500 truncate  w-[99%]">
              {data?.purchaseRequest?.type === "semi_internal_product"
                ? data?.purchaseRequest?.productInternalOperation
                  ? data?.purchaseRequest?.productInternalOperation?.product?.stockCode || "--"
                  : data?.purchaseRequest?.productExternalOperation?.product?.stockCode || "--"
                : data?.purchaseRequest?.product
                ? data?.purchaseRequest?.product?.stockCode || "--"
                : data?.purchaseRequest?.rawMaterial?.stockCode || "--"}
            </p>
            <div className="flex flex-row items-center gap-x-1 w-full">
              {data?.productType === "flex" ? (
                <>
                  <FlexProductIcon />
                  <p className="text-xs font-semibold text-secondary-700 truncate  w-[99%]">{data?.purchaseRequest?.rawMaterial?.name || "--"}</p>
                </>
              ) : (
                <p className="text-xs font-semibold text-secondary-700 truncate  w-[99%]">
                  {data?.purchaseRequest?.type === "semi_internal_product"
                    ? data?.purchaseRequest?.productInternalOperation
                      ? data?.purchaseRequest?.productInternalOperation?.product?.name || "--"
                      : data?.purchaseRequest?.productExternalOperation?.product?.name || "--"
                    : data?.purchaseRequest?.product
                    ? data?.purchaseRequest?.product?.name || "--"
                    : data?.purchaseRequest?.rawMaterial?.name || "--"}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="cursor-pointer flex absolute  w-[50px] h-[50px] mb-6 min-w-[50px] min-h-[50px] z-[100]">
          {data?.responsibleUser?.avatar ? (
            <div
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${data?.responsibleUser?.name + " " + data?.responsibleUser?.lastName} `}
              className="cursor-pointer"
            >
              <CustomImage
                src={data?.responsibleUser?.avatar}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                  borderWidth: 2,
                  borderStyle: "solid",
                  boxShadow: "0 0 0 2px #00000008",
                }}
              />
            </div>
          ) : (
            <div
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${data?.responsibleUser?.name + " " + data?.responsibleUser?.lastName} `}
              className="cursor-pointer"
            >
              <NoAvatar size={48} fontSize={22} name={data?.responsibleUser?.name} lastName={data?.responsibleUser?.lastName} color={"#B54708"} />
            </div>
          )}
        </div>

        <div className="absolute w-[86px] min-w-[86px] mt-14 flex items-center border-[0.5px] border-secondary-300 rounded-[34px] gap-x-[2px] bg-white pl-1 pr-[2px] py-[2px]">
          <p className="text-xxs font-normal text-secondary-600">{t("product:qualityNew")}</p>
          <div className="flex items-center justify-center rounded-[32px] bg-[#B54708] px-1 ml-auto w-[36px]">
            <p className="text-xxs text-white font-bold"> %{data?.metadata?.qualityPercent || "0"}</p>
          </div>
        </div>

        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 ml-auto">
          <div className={`w-full border-t-4 rounded-t-[8px] ${"border-t-[#98A2B3]"}`}></div>
          <div className="flex flex-row items-center w-full border-b justify-end">
            <div className="flex flex-col items-start px-2">
              <div className="flex flex-row items-center gap-x-1 ml-auto">
                <p className="text-xs font-normal text-secondary-600">{moment(data?.startDate)?.format("HH:mm")}</p>
                <StartClockIcon />
              </div>
              <div className="flex flex-row items-center gap-x-1 ml-auto">
                <p className="text-xs font-normal text-secondary-600">{moment(data?.endDate)?.format("HH:mm")}</p>
                <EndClockIcon />
              </div>
            </div>
            {data?.supplier?.image ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-content={`
          ${data?.supplier?.name} 
          `}
                className="cursor-pointer"
              >
                <CustomImage
                  borderRadius={2}
                  src={data?.supplier?.image}
                  style={{
                    width: 56,
                    height: 42,
                    minWidth: 56,
                    minHeight: 42,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRadius: 0,
                    borderLeftWidth: 1,
                    borderRightWidth: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-content={`
        ${data?.supplier?.name} 
        `}
                className="w-[56px] min-w-[56px] h-[42px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl"
              >
                {data?.supplier?.name?.slice(0, 2)?.toUpperCase()}
              </div>
            )}
          </div>
          <div className="flex flex-col items-end w-full pr-3 py-1 pl-[44px]">
            <p className="text-xs font-normal text-secondary-500 truncate ml-auto w-[99%] text-end">{data?.supplier?.contactPhone || "--"}</p>
            <p className="text-xs font-semibold text-end text-secondary-700 truncate ml-auto w-[99%]">{data?.supplier?.name || "--"}</p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[6px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[32px] right-[4px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {menuOptionSupply?.map((item, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "delete") {
                      setIsAlert(true);
                    }

                    if (item.value === "qualityControlForm") {
                      if (setPurchaseQualityForm && setPurchaseQualityFormData) {
                        setPurchaseQualityForm(true);
                        setPurchaseQualityFormData(data);
                      }
                    }
                    if (item.value === "purchaseDetail") {
                      navigate(`/app/product/mrp/supply/material/details/raw+${data?.purchaseRequest?.id}`);
                    }

                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    item?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                  }`}
                >
                  {item?.icon}
                  <p className={`font-medium text-sm  ${item?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{item.label}</p>
                </button>
              );
            })}
          </div>
        )}
        <Tooltip className="z-[120]" id={`${data?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
    </>
  );
};

export default MaterialSupplyEventCard;
