import React from "react";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import CustomImage from "../../../components/CustomImage";

const RawMaterialRecipeCard = ({ item }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex bg-white flex-row mr-4 w-[318px] min-w-[318px] h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab ">
        <div className="flex flex-col w-[93%]">
          <div className="flex flex-row items-center gap-x-3">
            {item?.rawMaterial?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={item?.rawMaterial?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1  w-full">
                <p className="font-semibold w-[175px] text-secondary-900 text-base truncate">{item?.rawMaterial?.name || item?.name || "Product Name"}</p>
              </div>
              <div className={`h-6 max-w-[175px] flex px-1 py-[3px] ml-[2px] items-center justify-center bg-[#FFFAEB]`}>
                <p className={`text-xs font-medium max-w-[175px] truncate text-[#B54708]`}>
                  {t("product:stock")} : {item?.rawMaterial?.stockCode || item?.stockCode || "--"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-1 w-[100%]">
            <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:inStock")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">
                  {/* {(item?.rawMaterial?.quantity)?.toFixed(2) || "0"} */}
                  {item?.rawMaterial?.quantity === 0
                    ? "0"
                    : item?.rawMaterial?.quantity % 1 !== 0
                    ? item?.rawMaterial?.quantity.toFixed(1)
                    : item?.rawMaterial?.quantity || "0"}
                </p>
              </div>
            </div>
            <div className="flex flex-col h-[42px] min-w-[28%] w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:activeRequest")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">
                  {item?.orderedUnit ? item?.orderedUnit?.toFixed(2) || "0" : item?.purchaseRequest?.orderedUnit?.toFixed(2) || "0"}
                </p>
              </div>
            </div>
            <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
              <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                <span className="text-xxs truncate text-[#475467] font-medium ">{"MS"}</span>
              </div>
              <div className="flex flex-col w-full items-start">
                <div className="flex flex-row items-center w-full border-b px-1">
                  <p className="text-xxs font-normal text-secondary-600">{t("product:remainings")}</p>
                  <p className="text-xs font-medium ml-auto text-secondary-600">
                    {item?.purchaseRequest
                      ? item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit || "0"
                      : item?.orderedUnit - item?.plannedUnit || "0"}
                  </p>
                </div>
                <div className="flex flex-row items-center w-full px-1">
                  <p className="text-xxs font-normal text-secondary-600">{t("product:plannedNew")}</p>
                  <p className="text-xs font-medium ml-auto text-secondary-600">
                    {item?.purchaseRequest ? item?.purchaseRequest?.plannedUnit?.toFixed(2) || "0" : item?.plannedUnit?.toFixed(2) || "0"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`absolute right-0 bg-[#F2F4F7] bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
        >
          <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
            {t("product:rMaterial")} ({item?.rawMaterial?.unitType})
          </p>
        </div>
      </div>
    </>
  );
};

export default RawMaterialRecipeCard;
