import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Toggle } from "./Twin/components";
import Planner from "./Twin/components/Planner";
import { observer } from "mobx-react-lite";
import { TwinIcon } from "../../assets/icons/plannerIcons";
import DashboardSlider from "./Slider/DashboardSlider";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { Button } from "../../components";
import { useStore } from "../../hooks/useStores";
import NoData from "../../steps/step-navigation/components/NoData";
import { processService } from "../../services";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../hooks/useWindowDimension";
import moment from "moment";
import { GaugeIcons } from "../../assets/icons/machineIcons";

const DigitalTwin = observer(() => {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(true);
  const ref = useRef();
  const { settings, auth } = useStore();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [selectedProcess, setSelectedProcess] = useState("all");

  const { isLoading, data, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["getProcessUtilization"],
    queryFn: async () => await processService.getQueryProcessUtilization(),
  });

  useEffect(() => {
    if (
      auth.user?.rolesKey === "OPERATION_RESPONSIBLE" &&
      !isLoading &&
      data?.operations?.length > 0
    ) {
      navigate(
        `operation-detail/${data.operations[0].id}/${moment(new Date()).format(
          "YYYY-MM-DD"
        )}`
      );
    }
  }, [auth?.user?.rolesKey, isLoading, data, navigate]);

  const handleHamburgerClick = useCallback(() => {
    settings.setIsActive(true);
  }, [settings]);

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex items-center border-b border-secondary-100  max-h-[56px] min-h-[56px]">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button
              colorType={"tertiary-gray"}
              iconLeft={<HumburgerIcon />}
              size={"md"}
              // onClick={() => settings.setIsActive(true)}
              onClick={handleHamburgerClick}
            />
          </span>
          <TwinIcon className="w-6 h-6" />
          <div className="flex min-h-[44px] max-h-[44px] h-11 gap-x-3 ml-auto items-center border shadow-xs border-[#D0D5DD] px-3 py-[10px] rounded-lg">
            <div className="flex gap-x-2 items-center">
              <GaugeIcons />
              <p className="text-[#475467] font-normal text-sm">
                {t("dashboard:capacityUtilizationRate")}
              </p>
            </div>
            <Toggle enabled={enabled} setEnabled={setEnabled} />
          </div>
        </div>

        {enabled && (
          <div className="flex flex-row items-center w-full gap-3 h-[190px] max-h-[190px] ">
            <div className="flex items-center flex-row gap-5  flex-1 h-full overflow-x-auto scrollbar-hide pl-2">
              <DashboardSlider
                enabled={enabled}
                data={data}
                refetch={refetch}
                isLoading={isLoading}
                setSelectedProcess={setSelectedProcess}
                selectedProcess={selectedProcess}
              />
            </div>
          </div>
        )}
        <div
          ref={ref}
          className="flex-1 flex rounded-lg border-[1px] border-secondary-300 overflow-hidden"
        >
          {auth.user?.rolesKey === "ADMIN" ? (
            <Planner
              // width={ref.current?.clientWidth}
              // height={ref.current?.clientHeight}
              width={width}
              height={height}
              selectedProcess={selectedProcess}
              setSelectedProcess={setSelectedProcess}
              
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <NoData
                theme={"machine"}
                header={t("consoleModal:notAuthorization")}
                text={t("consoleModal:seeAuthorization")}
                button={false}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default memo(DigitalTwin);
