import React, { useCallback, useState } from "react";
import { Button } from "../components";
import { CalendarIcon } from "../assets/icons/stepHeaderIcons";
import moment from "moment";
import { equipmentService } from "../services";
import { Toast } from "../utils/toastify/toast";
import { useStore } from "../hooks/useStores";
import { TableArrowLeft } from "../assets/icons/machineIcons";

const EnergHeader = React.memo(
  ({
    month,
    setMonth,
    changeMonthOfDate,
    years,
    months,
    getMonthName,
    icon,
    iconLeft,
  }) => {
    const { settings } = useStore();
    const [datas, setDatas] = useState({});
    const [Datayear, setDataYear] = useState();

    const changeMonthOfYear = useCallback(
      (originalDate, newYear) => {
        const current = Number(moment(month).format("YYYY"));
        const selected = Number(newYear);
        if (current !== selected) {
          equipmentService
            .energyData(moment().year(newYear).format("YYYY-MM-DD"))
            .then((res) => {
              setDatas(res?.data);
            })
            .catch((err) => {
              Toast("error", err?.response?.data?.message);
            });
        }
        const newDate = new Date(originalDate);
        newDate.setFullYear(newYear);
        Number(newYear) === 2024 ? newDate.setMonth(0) : newDate.setMonth(10);
        setDataYear(newYear);
        return String(newDate);
      },
      [month, setMonth]
    );

    return (
      <>
        <div className="w-full h-[56px] justify-between xs:h-[124px] flex items-center dark:text-[#FAFAFA] xs:flex-wrap">
          <span className="mr-3 md:flex items-center sm:flex xs:flex hidden">
            <Button
              colorType={"tertiary-gray"}
              iconLeft={iconLeft}
              size={"md"}
              onClick={() => settings.setIsActive(true)}
            />
            <span className="min-w-[152px] min-h-[28px] md:flex sm:flex xs:flex hidden">{icon}</span>
          </span>

          <span className="min-w-[152px] min-h-[28px] md:hidden sm:hidden xs:hidden">{icon}</span>
          <div className=" gap-3 justify-center items-center ml-auto h-full flex bg-white">
            <button
              onClick={() => {
                setMonth(moment(month).subtract(1, "day").startOf("month"));
              }}
              className="w-[36px] flex h-9 p-2 justify-center items-center bg-white rounded-lg border rotate-270 border-secondary-300"
            >
              <TableArrowLeft size={20} color="#344054" />
            </button>
            <div className="ml-auto flex flex-row items-center xs:w-full xs:border-t xs:pt-3">
              <div className="xs:w-full items-center  gap-3 flex xs:justify-between">
                <button
                  onClick={() => {}}
                  className="flex px-4 py-2 justify-center items-center bg-white h-9 gap-2 self-stretch rounded-lg border border-secondary-300"
                >
                  <CalendarIcon width={20} height={20} color="#344054" />
                  <span className="text-sm font-medium text-secondary-700 truncate flex relative items-center">
                    {moment(month).format("YYYY")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="#000"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <select
                      onChange={({ target }) => {
                        const updateDate = changeMonthOfYear(
                          month,
                          target.value
                        );
                        setMonth(moment(updateDate).format("YYYY-MM-DD"));
                      }}
                      className="opacity-0 absolute inset-0 -bottom-3  ml-4"
                    >
                      {years?.map((item, index) => {
                        return (
                          <option
                            className="text-xs h-[20px]"
                            value={item?.value}
                            key={index}
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </span>
                  <span>{getMonthName(moment(month).format("MM"))}</span>
                  {/* <MonthSelector
                  month={month}
                  setMonth={setMonth}
                  changeMonthOfDate={changeMonthOfDate}
                  months={months}
                /> */}
                </button>
              </div>
            </div>
            <button
                  onClick={() => {
                    setMonth(moment(month).add(1, "month").startOf("month"));
                  }}
                  className="w-[36px] flex h-9 p-2 justify-center items-center bg-white rounded-lg border rotate-180 border-secondary-300"
                >
                  <TableArrowLeft size={20} color="#344054" />
                </button>
          </div>
        </div>
      </>
    );
  }
);

export default EnergHeader;

const MonthSelector = React.memo(
  ({ month, setMonth, changeMonthOfDate, months }) => {
    return (
      <span className="text-sm font-medium text-secondary-700 truncate flex relative items-center">
        {moment(month).format("MMMM")}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#000"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <select
          onChange={({ target }) => {
            const updateDate = changeMonthOfDate(month, target.value);
            setMonth(moment(updateDate).format("YYYY-MM-DD"));
          }}
          className="opacity-0 absolute inset-0 -bottom-3 ml-4"
        >
          {months?.map((item, index) => {
            return (
              <option
                className="text-xs h-[20px]"
                value={item?.value}
                key={index}
              >
                {item?.name}
              </option>
            );
          })}
        </select>
      </span>
    );
  }
);
