import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../components";
import HourTimeInput from "../../../components/inputs/HourTimeInput";
import moment from "moment";
import { shiftService } from "../../../services/shift.service";
import { useStore } from "../../../hooks/useStores";

export default function NewBreak({ setIsOpen, selectedActivity, t, activities, setActivities, setSelectedActivity, minTime, maxTime, dayStart, nextStart }) {
  const { auth } = useStore();
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [name, setName] = useState("");
  const [validate, setValidate] = useState({ message: "", type: "" });
  const [secondaryValidate, setSecondaryValidate] = useState({ message: "", type: "" });

  const [error, setError] = useState(false);
  const [errorEnd, setErrorEnd] = useState(false);

  const e = auth.user.company.dayStartHour;
  const companyStartHour = moment(start).set("hour", e.split(":")[0]).set("minute", e.split(":")[1]).set("second", 0).toDate();
  const companyStartHourUnix = moment(companyStartHour).unix();
  const companyEndHourUnix = companyStartHourUnix + 86400;





  const inputDaySelector = (minTime, maxTime, inputTime) => {
    const companyStartUnix = moment(dayStart).unix();
    const companyEndUnix = moment(nextStart).unix();
    const midnightUnix = moment().startOf("day").add(1, "days").unix();

    const inputTimeUnix = moment(inputTime).unix();

    const newMaxTime = moment(maxTime).unix();

   

    //+1 day
    const nextDayInputTime = inputTimeUnix + 86400;
    const nextDayInput = moment(inputTime).add(1, "days").toDate();

    if (companyStartUnix <= inputTimeUnix && inputTimeUnix <= midnightUnix) {
    
      if (companyStartUnix < inputTimeUnix && companyEndUnix > inputTimeUnix && minTime < inputTimeUnix && newMaxTime > inputTimeUnix) {
        return {
          time: inputTime,
          error: "false",
        };
      } else {
        return {
          time: inputTime,
          error: "true",
        };
      }
    } else if (companyStartUnix <= nextDayInputTime && nextDayInputTime >= midnightUnix) {
      if (companyStartUnix < nextDayInputTime && companyEndUnix > nextDayInputTime && minTime < nextDayInputTime && newMaxTime > nextDayInputTime) {
       
        return {
          time: nextDayInput,
          error: "false",
        };
      } else {
        return {
          time: nextDayInput,
          error: "true",
        };
      }
    } else {
      return {
        time: inputTime,
        error: "true",
      };
    }
  };

  useEffect(() => {
    if (selectedActivity && selectedActivity?.id) {
      setName(selectedActivity?.name);
      setStart(selectedActivity?.start);
      setEnd(selectedActivity.end);
    } else {
      setName("");
      setStart(new Date());
      setEnd(new Date());
    }
  }, [selectedActivity]);

  const onApprove = async () => {
    const sendData = {
      name: name,
      start: start,
      end: end,
      unique: moment(),
    };
    if (selectedActivity && selectedActivity.id) {
      delete sendData.unique;
      await shiftService.updateActivity(selectedActivity.id, sendData);
    } else {
      setActivities((p) => [...p, sendData]);
    }
    setIsOpen(false);
  };

  return (
    <div className="flex flex-col gap-6 mt-6">
      <p className="text-sm text-secondary-600 ">
        {moment(minTime).format("HH:mm")} - {moment(maxTime).format("HH:mm")} saatleri arasında mola ekleyebilirsiniz.
      </p>

      <div className="flex flex-col gap-4">
        <Input
          label={t("settingTab:breakName")}
          disabled={false}
          onChange={(v) => {
            setName(v.target.value);
          }}
          value={name}
        />
        <HourTimeInput
          label={t("addShift:startTime")}
          validate={validate.type}
          errorMessage={validate.message}
          onChange={(e) => {
            const inputTime = moment().set("hour", e.split(":")[0]).set("minute", e.split(":")[1]).set("second", 0).toDate();

            setStart(inputDaySelector(moment(minTime).unix(), maxTime, inputTime)?.time);
            setValidate(
              inputDaySelector(moment(minTime).unix(), maxTime, inputTime)?.error === "true"
                ? { type: "error", message: "Vardiya başlangıcı, gün başlangıcından önce olamaz!" }
                : { type: "success", message: "Geçerli." }
            );
            setError(inputDaySelector(moment(minTime).unix(), maxTime, inputTime)?.error === "true" ? true : false);

            
          }}
          value={moment(start).format("HH:mm")}
        />
        <HourTimeInput
          label={t("addShift:endTime")}
          validate={secondaryValidate.type}
          errorMessage={secondaryValidate.message}
          onChange={(e) => {
            const inputTime = moment().set("hour", e.split(":")[0]).set("minute", e.split(":")[1]).set("second", 0).toDate();

                  setEnd(inputDaySelector(moment(start).unix(), maxTime, inputTime)?.time);
                  setSecondaryValidate(
                    inputDaySelector(moment(start).unix(), maxTime, inputTime)?.error === "true"
                      ? { type: "error", message: "Vardiya başlangıcı, gün başlangıcından önce olamaz!" }
                      : { type: "success", message: "Geçerli." }
                  );
                  setErrorEnd(inputDaySelector(moment(start).unix(), maxTime, inputTime)?.error === "true" ? true : false);
                }}
          value={moment(end).format("HH:mm")}
        />
      </div>
      <div className="flex gap-3">
        <Button
          onClick={() => {
            setIsOpen(false);
          }}
          colorType={"secondary-gray"}
          label={t("buttons:stop")}
        />
        <Button
          onClick={async () => {
            await onApprove();
          }}
          type={"button"}
          colorType={"primary-machine"}
          label={t("buttons:save")}
        />
      </div>
    </div>
  );
}
