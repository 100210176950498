import { instance } from "../libs/client";
import { authStore } from "../stores/auth.store";

class ProductService {
  async addProduct(data) {
    return await instance.post("product", data);
  }

  async addProductNewDesign(data) {
    return await instance.post("product/create", data);
  }

  async addOperation(id, data) {
    return await instance.post(`product/operation/${id}`, data);
  }

  async updateProductOperationDetails(id, type) {
    return await instance.get(`product/operation/${type}/${id}`);
  }

  async updateProductOperation(id, data) {
    return await instance.put(`product/operation/${id}`, data);
  }

  async updateProduct(id, data) {
    return await instance.put(`product/${id}`, data);
  }

  async updateStockProduct(id, data) {
    return await instance.post(`product/updateNew/${id}`, data);
  }

  async updateProductOperations(id, data) {
    return await instance.post(`product/updateNew/operations/${id}`, data);
  }

  async getOperationLog(type, id) {
    return await instance.get(`product/${type}/operation/log/${id}`);
  }

  async getProductLog(id) {
    return await instance.get(`product/log/${id}`);
  }

  async orderRecipeList(id) {
    const { data } = await instance.get(`product/list/work-order/${id}`);
    return data;
  }

  async getOperationCycleTimeList(type, id) {
    const { data } = await instance.get(`product/operation/cycle-time/list/${type}/${id}`);
    return data;
  }

  async orderRecipeDeteilList(productId, partialId, partialDetailsId) {
    const { data } = await instance.get(`product/work-order/list-junction/${productId}/${partialId}/${partialDetailsId}`);
    return data;
  }

  async setManualQuantityOperation(operationType, type, id, data) {
    return await instance.post(`product/${operationType}/set/manual/${type}/${id}`, data);
  }

  async setManualQuantity(type, id, data) {
    return await instance.post(`product/set/manual/${type}/${id}`, data);
  }

  async productDetailData(id) {
    const { data } = await instance.get(`product/${id}`);
    return data;
  }

  async rawMaterialsDetail(id) {
    const { data } = await instance.get(`product/list/raw-materials/for/supply/${id}`);
    return data;
  }

  async getAllListProduct() {
    const data = await instance.get("product/only/stock-card");
    return data;
  }

  async getRawMaterialOperations(id) {
    return await instance.get(`product/raw-material/operations/${id}`);
  }

  async deleteOperation(productId, id, type) {
    return await instance.delete(`product/operation/${type}/${productId}/${id}`);
  }

  async updateOperation(productId, data) {
    return await instance.post(`product/operation/order/${productId}`, data);
  }

  async getAssemblyParts(operationId) {
    return await instance.get(`product/assembly/parts/${operationId}`);
  }

  async deleteProduct(id) {
    return await instance.delete(`product/${id}`);
  }

  async listProduct(data) {
    return await instance.get("product", data);
  }

  async createOrder(id, data) {
    return await instance.put(`product/order/${id}`, data);
  }

  async copyOrder(id, data) {
    return await instance.post(`product/copy/order/${id}`, data);
  }

  async productSalesApproval(id, data) {
    return await instance.put(`product/raw/material/${id}`, data);
  }

  async productRawMaterialQualityApproval(id, data) {
    return await instance.put(`product/quality/raw/material/${id}`, data);
  }

  async updateStockAmount(id, data) {
    return await instance.put(`product/piece/${id}`, data);
  }

  async updateStockInternalAndExternal(type, id, data) {
    return await instance.put(`product/${type}/piece/${id}`, data);
  }

  async copyProduct(id, data) {
    return await instance.put(`product/copy/${id}`, data);
  }

  async copyingFormOrder(id, data) {
    return await instance.post(`product/copying/from/order/${id}`, data);
  }

  async relatedProducts(id, pageNo, search = "", take = "") {
    return await instance.get(`product/rawMaterial/list/${id}?page=${pageNo}${search ? `&search=${search}` : ""}${take ? `&take=${take}` : ""}`);
  }

  async updateStationCycleOrSettingTime(operationId, nodeId, data) {
    return await instance.post(`product/update/station/cycle-or-setting-time/${operationId}/${nodeId}`, data);
  }

  async listProductPaginated(pageNo, search = "", sort = "", order = "", take = "", type = "", start = "", end = "") {
    return await instance.get(
      `product/paginated?page=${pageNo}${search ? `&search=${search}` : ""}${sort ? `&sort=${sort}` : ""}${order ? `&order=${order}` : ""}${
        type ? `&type=${type}` : ""
      }${start ? `&start=${start}` : ""}${end ? `&end=${end}` : ""}${take ? `&take=${take}` : ""}`
    );
  }

  async getStockListForGet(pageNo, search = "", sort = "", order = "", take = "", filter = []) {
    const { data } = await instance.get(
      `product/paginated/new/?page=${pageNo}${search ? `&search=${search}` : ""}${sort ? `&sort=${sort}` : ""}${take ? `&take=${take}` : 20}${
        order ? `&order=${order}` : ""
      }${filter ? `&filter=${filter}` : []}`
    );
    return data;
  }

  async getWipTransaction(type, id, headerId, wipId) {
    return await instance.get(`product/semi-product/transaction/${type}/${id}/${headerId}/${wipId}`);
  }
}
const productService = new ProductService();
export { productService };
