import React, { useState, useEffect } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import QtyInput from "../../../components/inputs/QtyInput";
import { Button, TimeInput } from "../../../components";
import { useTranslation } from "react-i18next";
import moment from "moment";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { productService } from "../../../services/product.service";
import { Toast } from "../../../utils/toastify/toast";
import { AlertCircleIcon, DateIcon } from "../../../assets/icons/commonIcons";

const PurchaseRequestForm = ({
  selectedProduct,
  setPurchaseForm,
  purchaseSelected,
  orderData,
  setSupplyForm,
  refresh,
}) => {
  const { t } = useTranslation();
  const [priceValues, setPriceValues] = useState({});
  const [dateValues, setDateValues] = useState({});

  const tableHeadItems = [
    {
      name: t("product:productsAndMaterials"),
    },
    {
      name: t("product:orderQuantityss"),
    },
    {
      name: t("product:perUnitCost"),
    },
    {
      name: t("product:totalCost"),
    },
    {
      name: t("product:delivery"),
    },
  ];

  const handleChangePrice = (index, value) => {
    setPriceValues((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleChangeDate = (index, value) => {
    setDateValues((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const updatedPurchaseSelected = purchaseSelected.map((item, index) => {
    return {
      ...item,
      orderQuantity: priceValues,
      deliveryDate:
        dateValues[index] || moment(new Date()).format("YYYY-MM-DD"),
    };
  });

  const onSubmit = async () => {
    let datas = {
      ...orderData,
      purchaseRequest: {
        rawMaterial: updatedPurchaseSelected[0]?.id,
        type: "raw",
        source: "supply",
        currency: updatedPurchaseSelected[0]?.currency,
        estimatedDeliveryDate: updatedPurchaseSelected[0]?.deliveryDate,
        orderedUnit: priceValues,
        unitType: updatedPurchaseSelected[0]?.unit,
        unitCost: updatedPurchaseSelected[0]?.price,
      },
    };
    const id = selectedProduct?.id;
    await productService.createOrder(id, datas).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res?.data?.message);
        setSupplyForm(false);
        setPurchaseForm(false);
        refresh();
      } else if (res?.data?.code === 2) {
        Toast("error", t("product:noLimitOrder"));
        setSupplyForm(false);
        setPurchaseForm(false);
        refresh();
      } else {
        Toast("error", res?.data?.message);
        setSupplyForm(false);
        setPurchaseForm(false);
        refresh();
      }
    });
  };

  useEffect(() => {
    if (orderData.piece) {
      setPriceValues(orderData?.piece);
    } else {
    }
  }, []);

  return (
    <div className="w-[862px] flex flex-col">
      <p className="font-normal text-sm text-secondary-600 mt-1">
        {t("product:sentRawMaterials")}
      </p>
      <div className="h-12 flex flex-row items-center gap-x-4 w-full mt-5 bg-[#F9FAFB] border border-t border-r-0 border-l-0 border-b border-[#EAECF0]">
        <div className="flex flex-row items-center gap-x-2 ml-2">
          {selectedProduct?.image ? (
            <img
              src={generateFileUrl(selectedProduct?.image)}
              className="min-w-[32px] w-[32px] h-[24px] rounded border border-secondary-300 object-cover"
            />
          ) : (
            <div className="w-[32px] min-w-[32px] h-[24px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
              <NoEquIcon />
            </div>
          )}
          <p className="text-sm font-medium text-secondary-900">
            {selectedProduct?.name || "---"}
          </p>
        </div>

        <p className="text-xs font-normal text-secondary-700">
          {t("product:stockCode")}: {selectedProduct?.stockCode || "---"}
        </p>
        <p className="text-xs font-normal text-secondary-700">
          {t("product:workOrderCode")}: {selectedProduct?.code || "---"}
        </p>
        <div className="flex items-center gap-x-1 flex-row">
          <DateIcon />
          <p className="text-xs font-normal text-secondary-700">
            {moment(orderData?.deliveryDate).format("DD-MM-YYYY") || "---"}
          </p>
          <p className="text-xs font-normal text-secondary-700">
            {t("product:deliveryDate")}
          </p>
        </div>
      </div>
      <div className="w-full flex-1 h-full">
        <div
          className={`w-full min-h-[380px] h-[380px] overflow-y-scroll scrollbar-hide`}
        >
          <div className="flex mt-5 max-h-[44px] min-h-[44px]">
            {tableHeadItems?.map((item, i) => {
              return (
                <div
                  key={i}
                  scope="col"
                  className={`border-b px-4 py-3 ${
                    i === 4 ? "" : "border-r"
                  } w-[${
                    i === 0
                      ? "30%"
                      : i === 4
                      ? "22%"
                      : i === 3 || i === 2
                      ? "13%"
                      : i === 1
                      ? "22%"
                      : ""
                  }] ${
                    !item?.active ? "text-secondary-500" : "text-secondary-900"
                  } items-center text-center`}
                >
                  <span
                    className={`text-center rounded  ${
                      !item?.active ? "text-gray-500" : "text-gray-500"
                    } flex text-xs font-medium ${
                      i === 1 || i === 2 || i === 3 || i === 4 || i === 5
                        ? "items-center justify-center"
                        : "items-center"
                    } cursor-pointer whitespace-nowrap`}
                  >
                    {item?.name}
                  </span>
                </div>
              );
            })}
          </div>
          {purchaseSelected?.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex w-full items-center border-b h-[80px] min-h-[80px]`}
              >
                <div className="py-2 pr-3 flex items-center border-r h-[80px] w-[30%]">
                  <div className="flex items-center border w-full p-2 rounded-lg shadow-sm border-[#EAECF0]">
                    <div className="w-[56px] h-[42px] min-w-[56px] min-h-[42px] rounded border border-secondary-200 overflow-hidden">
                      {item?.image ? (
                        <img src={generateFileUrl(item?.image)} />
                      ) : (
                        <div className="w-[56px] h-[42px] min-w-[56px] min-h-[42px] flex rounded  overflow-hidden">
                          <NoAvatar
                            name={item?.machineName || "--"}
                            rounded={4}
                            theme={"product"}
                            minHeight="42px"
                            minWidth="56px"
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col ml-2">
                      <p className="text-sm font-medium text-secondary-900">
                        {item?.name || "---"}
                      </p>
                      <p className="text-xs font-normal text-secondary-600">
                        Stock ID: {item?.stockId}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[22%] ">
                  <QtyInput
                    theme={"product"}
                    qtyType={
                      item?.unitType === null
                        ? t("product:pcs")
                        : item?.unitType
                    }
                    onChange={(e) => {
                      if (e?.target?.value?.length < 0) {
                        setPriceValues(orderData?.piece);
                      } else {
                        setPriceValues(e?.target?.value);
                      }
                    }}
                    value={priceValues}
                  />
                </div>
                <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[13%] ">
                  <p className="w-[90%] whitespace-nowrap text-center overflow-hidden text-ellipsis text-md text-secondary-600 dark:text-[#F5F5F5] font-normal">
                    {item?.price}
                  </p>
                  <p className="text-sm font-normal text-secondary-500">
                    {item?.currency}
                  </p>
                </div>
                <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[13%] ">
                  <p
                    className={`w-[90%] whitespace-nowrap text-center overflow-hidden text-secondary-600  text-ellipsis text-md  dark:text-[#F5F5F5] font-normal`}
                  >
                    {item?.price *
                      updatedPurchaseSelected[index]?.orderQuantity}
                  </p>
                  <p className="text-sm font-normal text-secondary-500">
                    {item?.currency}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center  w-[22%] h-full  px-4 ">
                  <TimeInput
                    onChange={(e) => handleChangeDate(index, e)}
                    theme={"product"}
                    value={moment(dateValues[index] || new Date()).format(
                      "YYYY-MM-DD"
                    )}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex min-h-[72px] max-h-[72px] w-full border border-[#FDA29B] rounded-xl p-4 gap-x-3">
          <div className="flex min-h-[40px] max-h-[40px] min-w-[40px] max-w-[40px] rounded bg-[#FEE4E2] items-center justify-center">
            <AlertCircleIcon />
          </div>
          <span className="text-[#475467] font-normal text-sm w-[80%]">
            {t("product:excessPurchased")}
          </span>
        </div>
      </div>
      <div className="w-full flex flex-row items-center gap-x-4 ">
        <div className="w-[50%] flex flex-row items-center mt-4">
          <p className="text-md font-normal text-secondary-600">
            {t("product:totalNoOfProduct")}
          </p>
          <p className="text-md text-secondary-900 font-semibold ml-auto">
            {purchaseSelected?.length || "---"}
          </p>
        </div>
        <div className="w-[50%] flex flex-row items-center mt-4">
          <p className="text-md font-normal text-secondary-600">
            {t("product:totalCost")}
          </p>
          <p className="text-md text-secondary-900 font-semibold ml-auto">
            {updatedPurchaseSelected[0]?.price *
              updatedPurchaseSelected[0]?.orderQuantity || "---"}
          </p>
        </div>
      </div>
      <div className="flex flex-row items-center gap-x-4 w-full mt-4">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          onClick={() => {
            setPurchaseForm(false);
            setSupplyForm(true);
          }}
        />
        <Button
          type={"submit"}
          label={t("buttons:save")}
          colorType={"primary-product"}
          size={"md"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default PurchaseRequestForm;
