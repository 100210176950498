import React, { useState } from "react";
import { Button, CustomModal } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import Badgets from "../../components/buttons/Badgets";
import { ArrowUpRight } from "../../assets/icons/stepsIcons";
import { ChevronDown, DeleteIcon, DotsHorizontalIcon, FilterIcon, PlusIcon, SwitchIcon } from "../../assets/icons/productIcons";
import SearchInput from "../../components/inputs/SearchInput";
import DeleteOrderModal from "./modals/DeleteOrderModal";
import { useQuery } from "@tanstack/react-query";
import { orderService } from "../../services/order.service";
import NoAvatar from "../../components/avatar/NoAvatar";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import { useStore } from "../../hooks/useStores";
import CustomerDetailTable from "./CustomerDetailTable";
import { EditIconMachine } from "../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";

const OrderLogDetail = () => {
  const { orderId } = useParams();
  const { auth } = useStore();
  const { t } = useTranslation()
  const navigate = useNavigate();
  const [deleteOrder, setDeleteOrder] = useState(false);
  const [orderData, setOrderData] = useState(null);

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ["getOrderLogDetail", orderId],
    queryFn: async () => orderService.editOrderDetails(orderId),
    retry: 0,
  });

  const desiredQuantities = data?.details?.map((detail) => {
    const totalDesiredQuantity = detail.partial.reduce((total, partialItem) => total + partialItem.desiredQuantity, 0);
    return {
      ...detail,
      totalDesiredQuantity,
    };
  });

  const productsHeadItems = [
    t("product:products"),
    t("product:due"),
    t("product:orderQuantityss"),
    t("product:quantityInProduction"),
    t("product:totalDeliveredQuantity"),
    t("product:remainingOrders"),
    t("product:unitPricess"),
    t("product:totalPrice"),
    "",
  ];

  const LogHeadItems = ["Kod", "Tarih", "Eylem", "Miktar", "Kişi", "Müşteri", "Git"];
  const LogTableWidths = ["13%", "11%", "11%", "11%", "13%", "36%", "72px"];

  const fakeDataProducts = [
    {
      id: 1,
      productName: "Helis Dişli Z:15 MN:6DP WK",
      stockCode: "600.01.0216",
      orderAmount: 30000,
      productionAmount: 30000,
      sentAmount: 30000,
      unitPrice: 12.24,
      totalPrice: 90242.24,
    },
    {
      id: 1,
      productName: "Helis Dişli Z:15 MN:6DP WK",
      stockCode: "600.01.0216",
      orderAmount: 30000,
      productionAmount: 30000,
      sentAmount: 30000,
      unitPrice: 12.24,
      totalPrice: 90242.24,
    },
    {
      id: 1,
      productName: "Helis Dişli Z:15 MN:6DP WK",
      stockCode: "600.01.0216",
      orderAmount: 30000,
      productionAmount: 30000,
      sentAmount: 30000,
      unitPrice: 12.24,
      totalPrice: 90242.24,
    },
  ];

  return (
    <div className="flex flex-col gap-y-8 h-full w-full">
      {isLoading || isFetching ? (
        <div className="w-full h-[56px] min-h-[56px] bg-gray-200 rounded-xl animate-pulse" />
      ) : (
        <div className="flex w-full justify-between items-center min-h-[56px] max-h-[56px] h-[56px] border-b border-[#E4E7EC]">
          <div className="flex w-full justify-between items-center min-h-[40px] max-h-[40px] h-10">
            <div className="flex gap-x-2 items-center">
              <Button
                colorType={"tertiary-gray"}
                onClick={() => {
                  navigate(-1);
                }}
                size={"md"}
                iconLeft={
                  <div className="rotate-180">
                    <TableArrowLeft />
                  </div>
                }
              />
              <div className="flex gap-x-2 items-end">
                <p className="text-[#B54708] font-semibold text-2xl">
                  {data?.productType === "order" ? `ORD-${data?.systemOrderNo}` : `STK-${data?.systemStockOrderNo}`}
                </p>
                <p className="text-[#475467] text-xl">{t("product:orderDetail")}</p>
              </div>
            </div>
            <div className="flex gap-x-3">
              <Button
                label={t("product:orderEdit")}
                iconLeft={<EditIconMachine />}
                colorType={"secondary-machine"}
                size={"md"}
                onClick={() => navigate(`/app/product/mrp/orders/edit-order/${orderId}`)}
              />
              <Button
                label={t("product:deleteOrder")}
                iconLeft={<DeleteIcon />}
                colorType={"secondary-error"}
                size={"md"}
                onClick={() => {
                  setOrderData(data);
                  setDeleteOrder(true);
                }}
              />
            </div>
          </div>
          <div className="flex gap-x-3 h-10">
            {/* <Button colorType={"secondary-gray"} label={"Yönet"} iconRight={<ChevronDown />} size={"md"} /> */}
            {/* <Button colorType={"primary-product"} label={"Plan Work"} iconLeft={<PlusIcon />} size={"md"} /> */}
          </div>
        </div>
      )}
      {isLoading || isFetching ? (
        <div className="w-full h-[68px] min-h-[68px] bg-gray-200 rounded-xl animate-pulse" />
      ) : (
        <div className="flex w-full min-h-[68px] max-h-[68px] h-[68px] gap-x-6">
          <div className="flex gap-x-3 px-4 py-3 h-full rounded-lg border border-[#D0D5DD] w-[25%]">
            {data?.customer?.image ? (
              <img
                src={generateFileUrl(data?.customer?.image)}
                className="flex min-w-[57px] max-w-[57px] w-[57px] min-h-11 max-h-11 h-11 border border-[#0000001A] flex-shrink-0"
              />
            ) : (
              <div className="w-[57px] h-[44px] min-w-[57px] min-h-[44px] rounded border border-secondary-200 overflow-hidden">
                <NoAvatar name={data?.customer?.name || auth?.user?.company?.name || "--"} rounded={4} theme={"product"} />
              </div>
            )}
            <div className="flex flex-col w-full overflow-hidden">
              <p className="text-[#475467] text-sm">{t("product:customer")}</p>
              <p className="text-[#B54708] font-semibold text-md truncate w-full">
                {data?.productType === "order" ? data?.customer?.name : auth?.user?.company?.name}
              </p>
            </div>
          </div>
          <div className="flex flex-col px-4 py-3 h-full rounded-lg border border-[#D0D5DD] w-[12.5%]">
            <p className="text-[#475467] text-sm truncate">{t("product:orderNo")}</p>
            <p className="text-[#B54708] font-semibold text-md truncate">
              {data?.productType === "order" ? `ORD-${data?.systemOrderNo}` : `STK-${data?.systemStockOrderNo}`}
            </p>
          </div>
          <div className="flex flex-col px-4 py-3 h-full rounded-lg border border-[#D0D5DD] w-[12.5%]">
            <p className="text-[#475467] text-sm truncate">{t("product:customerOrderNo")}</p>
            <p className="text-[#B54708] font-semibold text-md truncate">{data?.customerOrderNo || "--"}</p>
          </div>
          <div className="flex flex-col px-4 py-3 h-full rounded-lg border border-[#D0D5DD] w-[12.5%]">
            <p className="text-[#475467] text-sm truncate">{t("product:totalPrice")}</p>
            <p className="text-[#B54708] font-semibold text-md">£ {data?.totalPrice}</p>
          </div>
          <div className="flex flex-col px-4 py-3 h-full rounded-lg border border-[#D0D5DD] w-[12.5%]">
            <p className="text-[#475467] text-sm truncate">{t("product:createdDate")}</p>
            <p className="text-[#B54708] font-semibold text-md">{moment(data?.createdDate).format("DD.MM.YYYY")}</p>
          </div>
          <div className="flex gap-x-3 px-4 py-3 h-full rounded-lg border border-[#D0D5DD] w-[25%]">
            <div className="flex min-w-11 max-w-11 w-11 min-h-11 max-h-11 h-11 border border-[#0000001A] rounded-full flex-shrink-0"></div>
            <div className="flex flex-col w-full overflow-hidden">
              <p className="text-[#475467] text-sm truncate">{t("product:orderCreatingPersonnel")}</p>
              <p className="text-[#344054] font-semibold text-md truncate w-full">{"--"}</p>
            </div>
          </div>
        </div>
      )}
      {isLoading || isFetching ? (
        <div className="w-full h-[72vh] min-h-[72vh] bg-gray-200 rounded-xl animate-pulse" />
      ) : (
        <div className="flex flex-col w-full border h-[72vh] border-[#E4E7EC] shadow-xs rounded-xl">
          <div className="flex w-full h-14 px-6 py-4 justify-start items-center gap-x-2">
            <p className="text-[#101828] font-semibold text-md">{t("product:products")}</p>
            <Badgets colorType={"outline-error"} label={data?.details?.length || 0} size={"sm"} />
          </div>
          <div className="flex max-h-[26px] min-h-[26px] w-full bg-[#F9FAFB]">
            {productsHeadItems.map((item, i) => (
              <div
                key={i}
                className={`flex border-y px-3 py-1 border-r border-[#E4E7EC] items-center 
        ${i === 0 ? "basis-[320px] flex-none" : i === productsHeadItems.length - 1 ? "basis-[72px] flex-none" : "flex-1"} justify-center
        last:border-r-0`}
              >
                <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide">
            {!data && <div className="flex min-h-20 max-h-20 h-20 w-full"></div>}
            {desiredQuantities?.map((order, i) => (
              <CustomerDetailTable detail={order} refetch={refetch} />
            ))}
          </div>
        </div>
      )}

      {/* <div className="flex flex-col w-full border border-[#E4E7EC] shadow-xs rounded-xl">
        <div className="flex w-full h-14 px-6 py-4 gap-x-3 items-center">
          <p className="text-[#101828] font-semibold text-md w-full">Sipariş Hareketleri</p>
          <div className="flex gap-x-3 h-10">
            <SearchInput />
            <Button colorType={"secondary-gray"} size={"md"} iconLeft={<SwitchIcon />} />
            <Button colorType={"secondary-gray"} size={"md"} iconLeft={<FilterIcon />} />
          </div>
        </div>
        <div className="flex w-full border-y border-[#E4E7EC] bg-[#F9FAFB] min-h-[34px] max-h-[34px] h-[34px]">
          {LogHeadItems.map((item, i) => (
            <div
              key={i}
              className={`flex px-4 py-2 last:border-0 border-r border-[#E4E7EC] min-w-[${LogTableWidths[i]}] max-w-[${LogTableWidths[i]}] w-[${
                LogTableWidths[i]
              }] ${i === 0 || i === 5 ? "justify-start" : "justify-center"}`}
            >
              <span className="text-[#344054] text-xs font-medium whitespace-nowrap">{item}</span>
            </div>
          ))}
        </div>
        <div className="flex flex-col w-full max-h-[640px] h-full overflow-y-auto overflow-x-hidden scrollbar-hide">
          {fakeDataLogs.map((data, i) => (
            <div className="flex min-h-20 max-h-20 h-20 w-full">
              <div className="flex min-w-[13%] max-w-[13%] w-[13%] border-r px-4 py-2 h-full items-center justify-start">
                <p className="text-[#344054] text-sm font-medium">ORD-00001</p>
              </div>
              <div className="flex flex-col gap-y-1 min-w-[11%] max-w-[11%] w-[11%] border-r items-center justify-center px-4 py-2">
                <p className="text-[#344054] font-medium text-xs">25.04.2025</p>
                <span className="flex gap-x-1">
                  <p className="text-[#475467] text-xs">Cuma</p>
                  <p className="text-[#475467] text-xs">19:15</p>
                </span>
              </div>
              <div className="flex flex-col min-w-[11%] max-w-[11%] w-[11%] border-r items-center justify-center px-4 py-2">
                <Badgets colorType={"fill-success"} label={"İş Emri Tamamlandı"} size={"sm"} />
              </div>
              <div className="flex gap-x-1 min-w-[11%] max-w-[11%] w-[11%] border-r items-center justify-center px-4 py-2">
                <TriangleIcon color={"#17B26A"} />
                <span className="flex gap-x-1 items-end">
                  <p className="text-[#067647] text-sm font-medium">20.000</p>
                  <p className="text-[#475467] text-xs">adet</p>
                </span>
              </div>
              <div className="flex min-w-[13%] max-w-[13%] w-[13%] border-r items-center justify-center px-4 py-2">
                <p className="text-[#344054] font-medium text-sm">Ertuğrul Yiğit</p>
              </div>
              <div className="flex min-w-[36%] max-w-[36%] w-[36%] border-r items-center justify-start px-4 py-2">
                <p className="text-[#344054] font-medium text-sm">Astor Enerji</p>
              </div>
              <div className="flex min-w-[72px] max-w-[72px] w-[72px] items-center justify-center">
                <Button colorType={"tertiary-gray"} size={"md"} iconLeft={<ArrowUpRight />} />
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <CustomModal
        isOpen={deleteOrder}
        setIsOpen={setDeleteOrder}
        modalTitle={t("product:deleteOrder")}
        subTitle={t("product:makeDecision")}
        width={1140}
        children={<DeleteOrderModal setIsOpen={setDeleteOrder} orderData={orderData} refetch={refetch} />}
      />
    </div>
  );
};

export default OrderLogDetail;

const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.39735 15.0965C2.43564 14.752 2.45478 14.5797 2.50691 14.4186C2.55316 14.2758 2.61851 14.1398 2.70118 14.0144C2.79436 13.8731 2.91694 13.7506 3.16209 13.5054L14.1673 2.50017C15.0878 1.5797 16.5802 1.5797 17.5007 2.50017C18.4211 3.42065 18.4211 4.91303 17.5007 5.83351L6.49542 16.8387C6.25027 17.0839 6.1277 17.2065 5.98639 17.2996C5.86102 17.3823 5.72506 17.4477 5.58219 17.4939C5.42115 17.546 5.24887 17.5652 4.90429 17.6035L2.08398 17.9168L2.39735 15.0965Z"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const TriangleIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.9127 15.255L10.32 4.16808C10.2805 4.11546 10.2315 4.07321 10.1762 4.04423C10.121 4.01525 10.0609 4.00024 10 4.00024C9.93915 4.00024 9.87902 4.01525 9.82377 4.04423C9.76853 4.07321 9.71948 4.11546 9.68002 4.16808L2.08725 15.255C2.03992 15.3229 2.01048 15.4046 2.00232 15.4907C1.99416 15.5768 2.00763 15.6638 2.04115 15.7415C2.07495 15.8191 2.12712 15.8844 2.19179 15.9301C2.25646 15.9758 2.33107 16.0001 2.40723 16.0002H17.5928C17.6689 16.0001 17.7435 15.9758 17.8082 15.9301C17.8729 15.8844 17.925 15.8191 17.9588 15.7415C17.9924 15.6638 18.0058 15.5768 17.9977 15.4907C17.9895 15.4046 17.9601 15.3229 17.9127 15.255Z"
        fill={color}
      />
    </svg>
  );
};
