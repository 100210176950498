import React from "react";
import { QuantityIcons } from "../../../../assets/icons/productIcons";
import { CalenderIconProduct } from "../../../../assets/icons/equipmentIcon";
import generateFileUrl from "../../../../utils/generateFileUrl";
import NoAvatar from "../../../../components/avatar/NoAvatar";

const AddManuelJunctionProductCard = ({ name, quantity, date, image, orderNo, productId, selectedProduct, setSelectedProduct, productData }) => {


  return (
    <div
      onClick={() => setSelectedProduct(productData)}
      className={`flex w-full min-h-[74px] max-h-[74px] border rounded-lg p-2 gap-x-3 shadow-xs cursor-pointer ${
        selectedProduct?.product?.id === productId ? "border-[#D6BBFB]" : "border-[#D0D5DD]"
      }`}
    >
      {image ? (
        <img className="flex min-w-[72px] max-w-[72px] min-h-[54px] max-h-[54px] shadow-sm border border-[#0000001A]" src={generateFileUrl(image)} />
      ) : (
        <div className="flex min-w-[72px] max-w-[72px] min-h-[54px] max-h-[54px] shadow-sm border border-[#0000001A] ">
          <NoAvatar name={name || "--"} rounded={4} theme={"product"} minWidth={72} />
        </div>
      )}

      <div className="flex flex-col w-full">
        <span className="text-secondary-900 font-semibold text-sm truncate w-[70%]">{name}</span>
        <span className="text-secondary-600 font-normal text-xs">{orderNo}</span>
        <div className="flex gap-x-2 items-center">
          <div className="flex gap-x-[2px] items-center">
            <QuantityIcons />
            <span className="text-secondary-600 text-xs font-normal">{quantity}</span>
          </div>
          <div className="flex gap-x-[2px] items-center">
            <CalenderIconProduct />
            <span className="text-secondary-600 text-xs font-normal">{date}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddManuelJunctionProductCard;
