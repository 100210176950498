import React, { useEffect, useState } from "react";
import { DeleteIcon } from "../assets/icons/departmentIcon";
import { ShiftColorTick } from "../assets/icons/stepsIcons";
import Selector from "../components/inputs/Selector";
import HourTimeInput from "../components/inputs/HourTimeInput";
import moment from "moment";
import { timeFormatter } from "../utils/timezoneFormatter";
import { Button, CustomModal, Input } from "../components";
import NewBreak from "../steps/shifts/modals/NewBreak";
import { useTranslation } from "react-i18next";
import { Toast } from "../utils/toastify/toast";

const TertiarySettingShiftInfo = ({ currentOptions, collectedData, allData, personals, secondShiftData, dayStart }) => {
  const { t } = useTranslation();
  const [selectedPersonnel, setSelectedPersonnel] = useState("");
  const [start, setStart] = useState();
  const [isNextDay, setIsNextDay] = useState(false);
  const [error, setError] = useState(false);

  const [errorEnd, setErrorEnd] = useState(false);
  const [firstInputNextDay, setFirstInputNextDay] = useState(false);
  const [end, setEnd] = useState();
  const [color, setColor] = useState("#B692F6");
  const [activities, setActivities] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [validate, setValidate] = useState({ message: "", type: "" });
  const [secondaryValidate, setSecondaryValidate] = useState({ message: "", type: "" });
  const minTime = moment(secondShiftData?.end).unix() - 60;
  const nextDayStart = moment(dayStart).add(1, "day").toDate();
  const nextStart = moment().add(1, "day").toDate();
  const shiftColors = [
    { border: "#7F56D9", bg: "#B692F6", label: t("colors:purple") },
    { border: "#1570EF", bg: "#53B1FD", label: t("colors:blue") },
    { border: "#DC6803", bg: "#FEC84B", label: t("colors:orange") },
    { border: "#0E9384", bg: "#2ED3B7", label: t("colors:green") },
    { border: "#475467", bg: "#98A2B3", label: t("colors:gray") },
    { border: "#D92D20", bg: "#F97066", label: t("colors:red") },
  ];

  const inputDaySelector = (minTime, maxTime, inputTime) => {
    const companyStartUnix = moment(dayStart).unix();
    const companyEndUnix = moment(nextStart).unix();
    const midnightUnix = moment().startOf("day").add(1, "days").unix();

    const inputTimeUnix = moment(inputTime).unix();

    let newMaxTime = moment(maxTime).unix();



    //+1 day
    const nextDayInputTime = inputTimeUnix + 86400;
    const nextDayInput = moment(inputTime).add(1, "days").toDate();

    if (companyStartUnix <= inputTimeUnix && inputTimeUnix <= midnightUnix) {

      if (companyStartUnix < inputTimeUnix && companyEndUnix > inputTimeUnix && minTime < inputTimeUnix && newMaxTime > inputTimeUnix) {
        return {
          time: inputTime,
          error: "false",
        };
      } else {
        return {
          time: inputTime,
          error: "true",
        };
      }
    } else if (companyStartUnix <= nextDayInputTime && nextDayInputTime >= midnightUnix) {
      if (companyStartUnix < nextDayInputTime && companyEndUnix > nextDayInputTime && minTime < nextDayInputTime && newMaxTime > nextDayInputTime) {

        return {
          time: nextDayInput,
          error: "false",
        };
      } else {
        return {
          time: nextDayInput,
          error: "true",
        };
      }
    } else {
      return {
        time: inputTime,
        error: "true",
      };
    }
  };

  useEffect(() => {
    if (allData) {
      setSelectedPersonnel(allData?.userId ? allData?.userId : allData?.user?.id);
      setColor(allData?.color);
      setStart(allData?.start);
      setEnd(allData?.end);
      setActivities(allData?.activity);
      if (allData?.validate) {
        setValidate(allData?.validate);
      }
      if (allData?.secondaryValidate) {
        setSecondaryValidate(allData?.secondaryValidate);
      }
    }
  }, [allData]);
  return (
    <>
      <div className="flex-1 w-full flex flex-col mt-6">
        <div className="flex w-full h-[79px] items-center border-b">
          <div className="w-full flex flex-col ">
            <p className="text-lg text-secondary-900 font-semibold">{currentOptions?.name} {t("addShift:informations")}</p>
            <p className="text-secondary-600">{t("addShift:enterTheThirdInformation")}</p>
          </div>
          <div className="flex ml-auto gap-4">
            <Button
              label={t("buttons:apply")}
              size={"md"}
              colorType={"secondary-machine"}
              disabled={error || errorEnd ? true : false}
              width={174}
              onClick={() => {
                const sendData = {
                  id: allData?.id ? allData?.id : null,
                  userId: selectedPersonnel,
                  end: moment(end).utc().format(),
                  start: moment(start).utc().format(),
                  activity: activities,
                  secondaryValidate,
                  validate,
                  color,
                };
                collectedData(sendData);
              }}
            />
          </div>
        </div>

        <div className="w-full h-[64px] mt-6 flex border-b">
          <p className="w-[280px] text-secondary-700 text-sm">{t("addShift:supervisor")}</p>
          <div className="w-[512px]">
            <Selector
              items={personals}
              disabled={false}
              onChange={(e) => {
                setSelectedPersonnel(e);
              }}
              value={selectedPersonnel}
            />
          </div>
        </div>
        <div className="w-full h-[110px] mt-6 flex border-b">
          <p className="w-[280px] text-secondary-700 text-sm">{t("addShift:startEndTimes")}</p>
          <div className="w-[512px] flex justify-between">
            <div className="w-[48%] pb-5">
              <HourTimeInput
                validate={validate.type}
                errorMessage={validate.message}
                onChange={(e) => {
                  const inputTime = moment().set("hour", e.split(":")[0]).set("minute", e.split(":")[1]).set("second", 0).add(10, "seconds").toDate();

                  setStart(inputDaySelector(minTime, nextDayStart, inputTime)?.time);
                  setValidate(
                    inputDaySelector(minTime, nextDayStart, inputTime)?.error === "true"
                      ? { type: "error", message: "Vardiya başlangıcı, gün başlangıcından önce olamaz!" }
                      : { type: "success", message: "Geçerli." }
                  );
                  setError(inputDaySelector(minTime, nextDayStart, inputTime)?.error === "true" ? true : false);
                }}
                icon={<ClockStart />}
                value={moment(start).format("HH:mm")}
              />
            </div>
            <div className="w-[48%] pb-5">
              <HourTimeInput
                validate={secondaryValidate.type}
                errorMessage={secondaryValidate.message}
                onChange={(e) => {
                  const inputTime = moment().set("hour", e.split(":")[0]).set("minute", e.split(":")[1]).set("second", 0).add(20, "seconds").toDate();

                  setEnd(inputDaySelector(moment(start).unix(), nextDayStart, inputTime)?.time);
                  setSecondaryValidate(
                    inputDaySelector(moment(start).unix(), nextDayStart, inputTime)?.error === "true"
                      ? { type: "error", message: "Vardiya başlangıcı, gün başlangıcından önce olamaz!" }
                      : { type: "success", message: "Geçerli." }
                  );
                  setErrorEnd(inputDaySelector(moment(start).unix(), nextDayStart, inputTime)?.error === "true" ? true : false);
                }}
                icon={<ClockEnd />}
                value={moment(end).format("HH:mm")}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 w-full flex pb-6">
          <p className="w-[280px] text-secondary-700 text-sm">{t("addShift:shiftColor")}</p>
          <ul className="flex flex-col gap-y-4 justify-between">
            {shiftColors?.map((colors, i) => {
              return (
                <li key={i} onClick={() => setColor(colors.bg)} className={"flex relative gap-x-3 cursor-pointer"}>
                  <div
                    className="w-5 h-5 rounded-full flex sm:w-4 sm:h-4 items-center justify-center cursor-pointer border"
                    style={{
                      backgroundColor: colors.bg,
                      borderStyle: "solid",
                      borderWidth: "2px",
                      borderColor: colors.border,
                    }}
                  >
                    {colors.bg === color && <ShiftColorTick color={"#FFFFFF"} className="w-4 h-4" />}
                  </div>
                  <div className="text-sm font-normal text-[#475467]">{colors.label}</div>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="flex w-full h-[79px] justify-between items-center  border-b">
          <div className="flex flex-col">
            <p className="text-lg text-secondary-900 font-semibold">{t("addShift:breaks")}</p>
            <p className="text-secondary-600">{t("addShift:defineYourBreaks")}</p>
          </div>
          <Button
            label={t("addShift:addBreak")}
            size={"md"}
            colorType={"secondary-gray"}
            width={174}
            onClick={() => {
              if (validate.type === "success" && secondaryValidate.type === "success") {
                setIsOpen(true);
              } else {
                Toast("warning", "Lutfen gecerli bir vardiya saati seciniz.");
              }
            }}
          />
        </div>

        <div className="w-full flex flex-col gap-5 pt-6">
          {activities?.map((e, i) => {
            return (
              <div className="flex w-full gap-y-4 min-h-[72px] border-b">
                <p className="w-[280px] text-secondary-700 text-sm">{i + 1}.{t("signals:break")}</p>

                <div className="flex w-[512px]">
                  <div className="flex flex-col">
                    <p className="text-sm font-semibold text-[#344054] dark:text-[#D6D6D6]">{e?.name}</p>
                    <p className="text-sm font-normal text-[#667085] dark:text-[#D6D6D6]">
                      {moment(e.start).format("HH:mm")} - {moment(e.end).format("HH:mm")}
                    </p>
                  </div>
                  <div className="flex ml-auto mt-1">
                    <Button
                      onClick={() => {
                        setActivities(activities.filter((element) => element?.name !== e?.name));
                      }}
                      iconLeft={<DeleteIcon />}
                      colorType={"tertiary-error"}
                      size={"sm"}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedActivity(null);
        }}
        children={
          <NewBreak
            selectedActivity={selectedActivity}
            setSelectedActivity={setSelectedActivity}
            setIsOpen={setIsOpen}
            t={t}
            activities={activities}
            setActivities={setActivities}
            minTime={moment(start).toDate()}
            maxTime={moment(end).toDate()}
            dayStart={dayStart}
            nextStart={nextStart}
          />
        }
        modalTitle={t("addShift:addBreak")}
        width={"400px"}
      />
    </>
  );
};

const ClockStart = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_15157_26668)">
        <path
          d="M18.321 10.4586C18.3334 10.0003 18.3334 10.1541 18.3334 10.0003C18.3334 5.39795 14.6025 1.66699 10.0001 1.66699C5.39771 1.66699 1.66675 5.39795 1.66675 10.0003C1.66675 14.5298 5.28051 18.2153 9.78216 18.3309M10.0001 5.00033V10.0003L6.88477 11.558M17.3611 13.9799L13.8223 11.5877C13.4578 11.3413 13.2756 11.2181 13.1243 11.2265C12.9925 11.2338 12.8705 11.2985 12.7907 11.4036C12.6991 11.5243 12.6991 11.7443 12.6991 12.1842V16.9757C12.6991 17.4163 12.6991 17.6366 12.7908 17.7573C12.8707 17.8625 12.9928 17.9272 13.1247 17.9343C13.2761 17.9425 13.4584 17.8189 13.8231 17.5717L17.3619 15.1724C17.6568 14.9724 17.8042 14.8725 17.8557 14.747C17.9008 14.6373 17.9007 14.5143 17.8555 14.4046C17.8038 14.2792 17.6562 14.1795 17.3611 13.9799Z"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15157_26668">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ClockEnd = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_15157_16177)">
        <path
          d="M18.321 10.4586C18.3293 10.3069 18.3334 10.1541 18.3334 10.0003C18.3334 5.39795 14.6025 1.66699 10.0001 1.66699C5.39771 1.66699 1.66675 5.39795 1.66675 10.0003C1.66675 14.5298 5.28051 18.2153 9.78216 18.3309M10.0001 5.00033V10.0003L6.88477 11.558M17.967 16.7337V14.5133C17.967 13.9533 17.967 13.6732 17.8581 13.4593C17.7622 13.2712 17.6092 13.1182 17.421 13.0223C17.2071 12.9133 16.9271 12.9133 16.367 12.9133H14.1481C13.5881 12.9133 13.308 12.9133 13.0941 13.0223C12.906 13.1182 12.753 13.2712 12.6571 13.4593C12.5481 13.6732 12.5481 13.9533 12.5481 14.5133V16.7337C12.5481 17.2937 12.5481 17.5737 12.6571 17.7877C12.753 17.9758 12.906 18.1288 13.0941 18.2247C13.308 18.3337 13.5881 18.3337 14.1481 18.3337H16.367C16.9271 18.3337 17.2071 18.3337 17.421 18.2247C17.6092 18.1288 17.7622 17.9758 17.8581 17.7877C17.967 17.5737 17.967 17.2937 17.967 16.7337Z"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15157_16177">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TertiarySettingShiftInfo;

// export default TertiarySettingShiftInfo
