import React from 'react'
import { NewDotsIcon } from '../../../components/new-cards/card-icons'
import { Tooltip } from 'react-tooltip'
import CustomImage from '../../../components/CustomImage'
import { NoEquIcon } from '../../../assets/icons/machineIcons'
import { useTranslation } from 'react-i18next'

const EditRequestRawMaterialCard = ({ item }) => {

    const { t } = useTranslation()

    const units = [
        { shortLabel: "kg", value: "kilogram" },
        { shortLabel: "ton", value: "ton" },
        { shortLabel: "gr", value: "gram" },
        { shortLabel: "mg", value: "miligram" },
        { shortLabel: "mcg", value: "mikrogram" },
        { shortLabel: "lb", value: "libre" },
        { shortLabel: "oz", value: "ons" },
        { shortLabel: "m", value: "metre" },
        { shortLabel: "mm", value: "milimetre" },
        { shortLabel: "gal", value: "galon" },
        { shortLabel: "m2", value: "metrekare" },
        { shortLabel: "mm2", value: "milimetrekare" },
        { shortLabel: "in2", value: "inçkare" },
        { shortLabel: "ad", value: "adet" },
        { shortLabel: "pkg", value: "paket" },
        { shortLabel: "box", value: "kutu" },
      ];

    const getUnitLabel = (unit) => {
        if (unit === undefined) {
          return "--";
        }
        const foundUnit = units.find((u) => u.value === unit);
        return foundUnit ? foundUnit.shortLabel : unit;
      };

    return (
        <div className="flex bg-white flex-row mr-4 w-[318px] min-w-[318px] h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab ">
            <div className="flex flex-col w-[93%]">
                <div className="flex flex-row items-center gap-x-3">
                    {item?.type === "semi_internal_product" ? (
                        <>
                            {item?.productInternalOperation ? (
                                <>
                                    {item?.productInternalOperation?.product?.image ? (
                                        <div
                                            data-tooltip-id={`${item?.id}`}
                                            data-tooltip-place="right"
                                            data-tooltip-content={`${t("product:productName")}: ${item?.productInternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productInternalOperation?.product?.stockCode
                                                }`}
                                        >
                                            <CustomImage
                                                borderRadius={2}
                                                src={item?.productInternalOperation?.product?.image}
                                                style={{
                                                    width: 64,
                                                    height: 48,
                                                    minWidth: 64,
                                                    minHeight: 48,
                                                    borderRadius: 4,
                                                    borderWidth: 1,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                    display: "flex",
                                                    borderColor: "#0000001A",
                                                    backgroundColor: "#fff",
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            data-tooltip-id={`${item?.id}`}
                                            data-tooltip-place="right"
                                            data-tooltip-content={`${t("product:productName")}: ${item?.productInternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productInternalOperation?.product?.stockCode
                                                }`}
                                            className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                        >
                                            <NoEquIcon />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {item?.productExternalOperation?.product?.image ? (
                                        <div
                                            data-tooltip-id={`${item?.id}`}
                                            data-tooltip-place="right"
                                            data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                                                }`}
                                        >
                                            <CustomImage
                                                borderRadius={2}
                                                src={item?.productExternalOperation?.product?.image}
                                                style={{
                                                    width: 64,
                                                    height: 48,
                                                    minWidth: 64,
                                                    minHeight: 48,
                                                    borderRadius: 4,
                                                    borderWidth: 1,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                    display: "flex",
                                                    borderColor: "#0000001A",
                                                    backgroundColor: "#fff",
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            data-tooltip-id={`${item?.id}`}
                                            data-tooltip-place="right"
                                            data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                                                }`}
                                            className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                        >
                                            <NoEquIcon />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ) : item?.rawMaterial ? (
                        <>
                            {item?.rawMaterial?.image ? (
                                <div
                                    data-tooltip-id={`${item?.id}`}
                                    data-tooltip-place="right"
                                    data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stock")}: ${item?.rawMaterial?.stockCode}`}
                                >
                                    <CustomImage
                                        borderRadius={2}
                                        src={item?.rawMaterial?.image}
                                        style={{
                                            width: 64,
                                            height: 48,
                                            minWidth: 64,
                                            minHeight: 48,
                                            borderRadius: 4,
                                            borderWidth: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            display: "flex",
                                            borderColor: "#0000001A",
                                            backgroundColor: "#fff",
                                        }}
                                    />
                                </div>
                            ) : (
                                <div
                                    data-tooltip-id={`${item?.id}`}
                                    data-tooltip-place="right"
                                    data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stock")}: ${item?.rawMaterial?.stockCode}`}
                                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                >
                                    <NoEquIcon />
                                </div>
                            )}
                        </>
                    ) : item?.product?.image ? (
                        <div
                            data-tooltip-id={`${item?.id}`}
                            data-tooltip-place="right"
                            data-tooltip-content={`${t("product:productName")}: ${item?.product?.name} - ${t("product:stock")}: ${item?.product?.stockCode}`}
                        >
                            <CustomImage
                                borderRadius={2}
                                src={item?.product?.image}
                                style={{
                                    width: 64,
                                    height: 48,
                                    minWidth: 64,
                                    minHeight: 48,
                                    borderRadius: 4,
                                    borderWidth: 1,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    display: "flex",
                                    borderColor: "#0000001A",
                                    backgroundColor: "#fff",
                                }}
                            />
                        </div>
                    ) : item?.productExternalOperation ? (
                        <>
                            {item?.productExternalOperation?.product?.image ? (
                                <div
                                    data-tooltip-id={`${item?.id}`}
                                    data-tooltip-place="right"
                                    data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                                        }`}
                                >
                                    <CustomImage
                                        borderRadius={2}
                                        src={item?.productExternalOperation?.product?.image}
                                        style={{
                                            width: 64,
                                            height: 48,
                                            minWidth: 64,
                                            minHeight: 48,
                                            borderRadius: 4,
                                            borderWidth: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            display: "flex",
                                            borderColor: "#0000001A",
                                            backgroundColor: "#fff",
                                        }}
                                    />
                                </div>
                            ) : (
                                <div
                                    data-tooltip-id={`${item?.id}`}
                                    data-tooltip-place="right"
                                    data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                                        }`}
                                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                >
                                    <NoEquIcon />
                                </div>
                            )}
                        </>
                    ) : item?.purchaseRequest?.rawMaterial?.image ? (
                        <>
                            {item?.purchaseRequest?.rawMaterial?.image ? (
                                <div
                                    data-tooltip-id={`${item?.id}`}
                                    data-tooltip-place="right"
                                    data-tooltip-content={`${t("product:productName")}: ${item?.purchaseRequest?.rawMaterial?.name} - ${t("product:stock")}: ${item?.purchaseRequest?.rawMaterial?.stockCode
                                        }`}
                                >
                                    <CustomImage
                                        borderRadius={2}
                                        src={item?.purchaseRequest?.rawMaterial?.image}
                                        style={{
                                            width: 64,
                                            height: 48,
                                            minWidth: 64,
                                            minHeight: 48,
                                            borderRadius: 4,
                                            borderWidth: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            display: "flex",
                                            borderColor: "#0000001A",
                                            backgroundColor: "#fff",
                                        }}
                                    />
                                </div>
                            ) : (
                                <div
                                    data-tooltip-id={`${item?.id}`}
                                    data-tooltip-place="right"
                                    data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                                        }`}
                                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                >
                                    <NoEquIcon />
                                </div>
                            )}
                        </>
                    ) : item?.purchaseRequest?.productExternalOperation?.product?.image ? (
                        <>
                            {item?.purchaseRequest?.productExternalOperation?.product?.image ? (
                                <div
                                    data-tooltip-id={`${item?.id}`}
                                    data-tooltip-place="right"
                                    data-tooltip-content={`${t("product:productName")}: ${item?.purchaseRequest?.productExternalOperation?.product?.name} - ${t(
                                        "product:stock"
                                    )}: ${item?.purchaseRequest?.productExternalOperation?.product?.stockCode}`}
                                >
                                    <CustomImage
                                        borderRadius={2}
                                        src={item?.purchaseRequest?.productExternalOperation?.product?.image}
                                        style={{
                                            width: 64,
                                            height: 48,
                                            minWidth: 64,
                                            minHeight: 48,
                                            borderRadius: 4,
                                            borderWidth: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            display: "flex",
                                            borderColor: "#0000001A",
                                            backgroundColor: "#fff",
                                        }}
                                    />
                                </div>
                            ) : (
                                <div
                                    data-tooltip-id={`${item?.id}`}
                                    data-tooltip-place="right"
                                    data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                                        }`}
                                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                >
                                    <NoEquIcon />
                                </div>
                            )}
                        </>
                    ) : item?.purchaseRequest?.productInternalOperation?.product?.image ? (
                        <>
                            {item?.purchaseRequest?.productInternalOperation?.product?.image ? (
                                <div
                                    data-tooltip-id={`${item?.id}`}
                                    data-tooltip-place="right"
                                    data-tooltip-content={`${t("product:productName")}: ${item?.purchaseRequest?.productInternalOperation?.product?.name} - ${t(
                                        "product:stock"
                                    )}: ${item?.purchaseRequest?.productInternalOperation?.product?.stockCode}`}
                                >
                                    <CustomImage
                                        borderRadius={2}
                                        src={item?.purchaseRequest?.productInternalOperation?.product?.image}
                                        style={{
                                            width: 64,
                                            height: 48,
                                            minWidth: 64,
                                            minHeight: 48,
                                            borderRadius: 4,
                                            borderWidth: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            display: "flex",
                                            borderColor: "#0000001A",
                                            backgroundColor: "#fff",
                                        }}
                                    />
                                </div>
                            ) : (
                                <div
                                    data-tooltip-id={`${item?.id}`}
                                    data-tooltip-place="right"
                                    data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stock")}: ${item?.productExternalOperation?.product?.stockCode
                                        }`}
                                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                >
                                    <NoEquIcon />
                                </div>
                            )}
                        </>
                    ) : (
                        <div
                            data-tooltip-id={`${item?.id}`}
                            data-tooltip-place="right"
                            data-tooltip-content={`${t("product:productName")}: ${item?.product?.name} - ${t("product:stock")}: ${item?.product?.stockCode}`}
                            className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                        >
                            <NoEquIcon />
                        </div>
                    )}
                    <div className="flex flex-col items-start">
                        <div className="flex flex-row items-center gap-x-1  w-full">
                            <p className="font-semibold w-[175px] text-secondary-900 text-base truncate">
                                {item?.productInternalOperation
                                    ? item?.productInternalOperation?.product?.name || "Product Name"
                                    : item?.productExternalOperation
                                        ? item?.productExternalOperation?.product?.name || "Product Name"
                                        : item?.product
                                            ? item?.product?.name || "Product Name"
                                            : item?.rawMaterial
                                                ? item?.rawMaterial?.name || "Product Name"
                                                : item?.purchaseRequest?.rawMaterial
                                                    ? item.purchaseRequest?.rawMaterial?.name || "Product Name"
                                                    : item?.purchaseRequest?.productInternalOperation
                                                        ? item?.purchaseRequest?.productInternalOperation?.product?.name || "Product Name"
                                                        : item?.purchaseRequest?.productExternalOperation
                                                            ? item?.purchaseRequest?.productExternalOperation?.product?.name || "Product Name"
                                                            : "Product Name"}
                            </p>
                        </div>
                        <div className={`h-6 max-w-[175px] flex px-1 py-[3px] ml-[2px] items-center justify-center bg-[#FFFAEB]`}>
                            <p className={`text-xs font-medium max-w-[175px] truncate text-[#B54708]`}>
                                {item?.productInternalOperation
                                    ? `${item?.productInternalOperation?.product?.stockCode || "--"} (${item?.productInternalOperation?.step}.Op)`
                                    : item?.productExternalOperation
                                        ? `${item?.productExternalOperation?.product?.stockCode || "--"} (${item?.productExternalOperation?.step}.Op)`
                                        : item?.product
                                            ? item?.product?.stockCode
                                            : item?.rawMaterial
                                                ? `${t("product:stock")} : ${item?.rawMaterial?.stockCode}`
                                                : item?.purchaseRequest?.rawMaterial
                                                    ? item?.purchaseRequest?.rawMaterial?.stockCode
                                                    : item?.purchaseRequest?.productInternalOperation
                                                        ? item?.purchaseRequest?.productInternalOperation?.product?.stockCode
                                                        : item?.purchaseRequest?.productExternalOperation
                                                            ? item?.purchaseRequest?.productExternalOperation?.product?.stockCode
                                                            : "--"}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center gap-x-1 w-[100%]">
                    <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
                        <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                            <p className="text-[10px] font-medium text-secondary-600">{t("product:inStock")}</p>
                        </div>
                        <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                            <p className="text-xs font-medium text-secondary-700">
                                {item?.rawMaterial
                                    ? (Number(item?.rawMaterial?.quantity) >= 100000
                                        ? (Number(item?.rawMaterial?.quantity) / 1000)?.toFixed(0) + "B"
                                        : Number(item?.rawMaterial?.quantity) % 1 === 0
                                            ? Number(item?.rawMaterial?.quantity)
                                            : Number(item?.rawMaterial?.quantity)?.toFixed(2)) || "0"
                                    : item?.product
                                        ? (Number(item?.product?.qualityQuantity) >= 100000
                                            ? (Number(item?.product?.qualityQuantity) / 1000).toFixed(0) + "B"
                                            : Number(item?.product?.qualityQuantity) % 1 === 0
                                                ? Number(item?.product?.qualityQuantity)
                                                : Number(item?.product?.qualityQuantity)?.toFixed(2)) || "0"
                                        : item?.productInternalOperation
                                            ? (Number(item?.productInternalOperation?.product?.qualityQuantity) >= 100000
                                                ? (Number(item?.productInternalOperation?.product?.qualityQuantity) / 1000)?.toFixed(0) + "B"
                                                : Number(item?.productInternalOperation?.product?.qualityQuantity) % 1 === 0
                                                    ? Number(item?.productInternalOperation?.product?.qualityQuantity)
                                                    : Number(item?.productInternalOperation?.product?.qualityQuantity)?.toFixed(2)) || "0"
                                            : item?.productExternalOperation
                                                ? (Number(item?.productExternalOperation?.product?.qualityQuantity) >= 100000
                                                    ? (Number(item?.productExternalOperation?.product?.qualityQuantity) / 1000)?.toFixed(0) + "B"
                                                    : Number(item?.productExternalOperation?.product?.qualityQuantity) % 1 === 0
                                                        ? Number(item?.productExternalOperation?.product?.qualityQuantity)
                                                        : Number(item?.productExternalOperation?.product?.qualityQuantity).toFixed(2)) || "0"
                                                : item?.purchaseRequest?.productInternalOperation?.product
                                                    ? (Number(item?.purchaseRequest?.productInternalOperation?.product?.qualityQuantity) >= 100000
                                                        ? (Number(item?.purchaseRequest?.productInternalOperation?.product?.qualityQuantity) / 1000)?.toFixed(0) + "B"
                                                        : Number(item?.purchaseRequest?.productInternalOperation?.product?.qualityQuantity) % 1 === 0
                                                            ? Number(item?.purchaseRequest?.productInternalOperation?.product?.qualityQuantity)
                                                            : Number(item?.purchaseRequest?.productInternalOperation?.product?.qualityQuantity)?.toFixed(2)) || "0"
                                                    : item?.purchaseRequest?.productExternalOperation?.product
                                                        ? (Number(item?.purchaseRequest?.productExternalOperation?.product?.qualityQuantity) >= 100000
                                                            ? (Number(item?.purchaseRequest?.productExternalOperation?.product?.qualityQuantity) / 1000).toFixed(0) + "B"
                                                            : Number(item?.purchaseRequest?.productExternalOperation?.product?.qualityQuantity) % 1 === 0
                                                                ? Number(item?.purchaseRequest?.productExternalOperation?.product?.qualityQuantity)
                                                                : Number(item?.purchaseRequest?.productExternalOperation?.product?.qualityQuantity).toFixed(2)) || "0"
                                                        : item?.purchaseRequest?.rawMaterial
                                                            ? (Number(item?.purchaseRequest?.rawMaterial?.quantity) >= 100000
                                                                ? (Number(item?.purchaseRequest?.rawMaterial?.quantity) / 1000).toFixed(0) + "B"
                                                                : Number(item?.purchaseRequest?.rawMaterial?.quantity) % 1 === 0
                                                                    ? Number(item?.purchaseRequest?.rawMaterial?.quantity)
                                                                    : Number(item?.purchaseRequest?.rawMaterial?.quantity).toFixed(2)) || ""
                                                            : ""}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col h-[42px] min-w-[28%] w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
                        <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                            <p className="text-[10px] font-medium text-secondary-600">{t("product:activeRequest")}</p>
                        </div>
                        <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                            <p className="text-xs font-medium text-secondary-700">
                                {item?.orderedUnit
                                    ? (Number(item?.orderedUnit) >= 100000
                                        ? (Number(item?.orderedUnit) / 1000).toFixed(0) + "B"
                                        : Number(item?.orderedUnit) % 1 === 0
                                            ? Number(item?.orderedUnit)
                                            : Number(item?.orderedUnit).toFixed(2)) || "0"
                                    : item?.purchaseRequest?.orderedUnit
                                        ? (Number(item?.purchaseRequest?.orderedUnit) >= 100000
                                            ? (Number(item?.purchaseRequest?.orderedUnit) / 1000).toFixed(0) + "B"
                                            : Number(item?.purchaseRequest?.orderedUnit) % 1 === 0
                                                ? Number(item?.purchaseRequest?.orderedUnit)
                                                : Number(item?.purchaseRequest?.orderedUnit).toFixed(2)) || "0"
                                        : "0"}
                            </p>
                        </div>
                    </div>
                    <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
                        <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                            <span className="text-xxs truncate text-[#475467] font-medium ">{"MS"}</span>
                        </div>
                        <div className="flex flex-col w-full items-start">
                            <div className="flex flex-row items-center w-full border-b px-1">
                                <p className="text-xxs font-normal text-secondary-600">{t("product:remainings")}</p>
                                <p className="text-xs font-medium ml-auto text-secondary-600">
                                    {item?.purchaseRequest
                                        ? (Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit) <= -10000 ||
                                            Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit) >= 10000
                                            ? (Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit) / 1000).toFixed(2) + "B"
                                            : Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit) % 1 === 0
                                                ? Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit)
                                                : Number(item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit).toFixed(2)) || "0"
                                        : (Number(item?.orderedUnit - item?.plannedUnit) <= -10000 || Number(item?.orderedUnit - item?.plannedUnit) >= 10000
                                            ? (Number(item?.orderedUnit - item?.plannedUnit) / 1000).toFixed(2) + "B"
                                            : Number(item?.orderedUnit - item?.plannedUnit) % 1 === 0
                                                ? Number(item?.orderedUnit - item?.plannedUnit)
                                                : Number(item?.orderedUnit - item?.plannedUnit).toFixed(2)) || "0"}
                                </p>
                            </div>
                            <div className="flex flex-row items-center w-full px-1">
                                <p className="text-xxs font-normal text-secondary-600">{t("product:plannedNew")}</p>
                                <p className="text-xs font-medium ml-auto text-secondary-600">
                                    {item?.purchaseRequest
                                        ? (Number(item?.purchaseRequest?.plannedUnit) <= -10000 || Number(item?.purchaseRequest?.plannedUnit) >= 10000
                                            ? (Number(item?.purchaseRequest?.plannedUnit) / 1000).toFixed(2) + "B"
                                            : Number(item?.purchaseRequest?.plannedUnit) % 1 === 0
                                                ? Number(item?.purchaseRequest?.plannedUnit)
                                                : Number(item?.purchaseRequest?.plannedUnit).toFixed(2)) || "0"
                                        : (Number(item?.plannedUnit) <= -10000 || Number(item?.plannedUnit) >= 10000
                                            ? (Number(item?.plannedUnit) / 1000).toFixed(2) + "B"
                                            : Number(item?.plannedUnit) % 1 === 0
                                                ? Number(item?.plannedUnit)
                                                : Number(item?.plannedUnit).toFixed(2)) || "0"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`absolute right-0 bg-[#F2F4F7] bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
            >
                {item?.productInternalOperation ? (
                    <p className="text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90">
                        {t("product:semiProduct")}
                        {/* ({`${item?.productInternalOperation?.step}.Op`}) */}
                    </p>
                ) : item?.productExternalOperation ? (
                    <p className="text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90">
                        {t("product:semiProduct")}
                        {/* ({`${item?.productExternalOperation?.step}.Op`}) */}
                    </p>
                ) : item?.product ? (
                    <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
                        {t("product:productss")} {item?.rawMaterial ? `(${getUnitLabel(item?.rawMaterial?.unitType)})` : `(${t("product:pcs")})`}
                    </p>
                ) : item?.rawMaterial ? (
                    <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
                        {t("product:rMaterial")} ({getUnitLabel(item?.rawMaterial?.unitType)})
                    </p>
                ) : item?.purchaseRequest?.rawMaterial ? (
                    <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
                        {t("product:rMaterial")} ({getUnitLabel(item?.purchaseRequest?.rawMaterial?.unitType)})
                    </p>
                ) : (
                    "--"
                )}
            </div>
            <div
                onClick={(e) => e.stopPropagation()}
                className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
            >
                <NewDotsIcon />
            </div>

            {<Tooltip id={`${item?.id}`} />}
        </div>
    )
}

export default EditRequestRawMaterialCard