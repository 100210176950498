import React, { useState, useEffect } from "react";
import UpuTimelineChart from "./components/UpuTimelineChart";
import useWindowDimensions from "../../hooks/useWindowDimension";
import moment from "moment";
import { timeFormatter } from "../../utils/timezoneFormatter";
import { observer } from "mobx-react-lite";
import StatusTooltip from "./components/StatusTooltip";
import ShiftTooltip from "./components/ShiftTooltip";
import JunctionTooltip from "./components/JunctionTooltip";
import TimelineTooltip from "./components/TimelineTooltip";

const UpuTimeline = observer(({ data, totalTime, multipleEvent }) => {
  
  const { width } = useWindowDimensions();

  const [selectedStart, setSelectedStart] = useState(0);
  const [selectedEnd, setSelectedEnd] = useState(0);
  const [timelineData, setTimelineData] = useState([]);

  const [showTooltip, setShowTooltip] = useState(null);

  const TimelineCalculator = async (data) => {
    let allValues = [];

    await data?.forEach((item) => {
      item?.value.forEach((valueItem) => {
        const newItem = {
          ...valueItem,
          itemStyle: item?.itemStyle,
          status: item.status,
          starts: moment(timeFormatter(valueItem?.start).formatted)._i,
          ends: moment(timeFormatter(valueItem?.end).formatted)._i,
        };

        allValues?.push(newItem);
      });

      let sorted = allValues?.sort(
        (a, b) => moment(a?.start).unix() - moment(b?.start).unix()
      );

      let junctionStatus = sorted?.filter((item) => {
        return item?.index == 0;
      });

      

      setTimelineData(sorted);

      setSelectedStart(moment(sorted[0]?.starts).unix());
      setSelectedEnd(moment(sorted[sorted.length - 1]?.ends).unix());
    });
  };

  
  useEffect(() => {
    TimelineCalculator(data);
  }, [data?.timelineData, width]);

  return (
    <div
      style={{
        minHeight: multipleEvent == 1 ? "40px" : "100px",
        maxHeight: multipleEvent == 1 ? "80px" : "150px",
      }}
      className="w-full h-full  border-[2px] border-secondary-300 rounded-xl flex items-center justify-center relative    "
    >
      <div className="w-[100px] h-full  flex items-start flex-col pl-4">
        {multipleEvent == 1 ? (
          <>
            <p className="text-[#475467] text-sm font-regular mt-7  ">Shift</p>
          </>
        ) : (
          <>
            <p className="text-[#475467] text-sm font-regular mt-8  ">Status</p>
            <p className="text-[#475467] text-sm font-regular mt-7 ">
              Junction
            </p>
          </>
        )}
      </div>

      <div
        style={{
          width: width - 300,
          height: "100%",
        }}
      >
        <UpuTimelineChart
          data={timelineData}
          totalWidth={width - 100}
          // totalTime={totalTime + 50}

          totalTime={86500}
          // setTotalTime={setTotalTime}
          selectedEnd={selectedEnd}
          selectedStart={selectedStart}
          setSelectedEnd={setSelectedEnd}
          setSelectedStart={setSelectedStart}
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          //multiple
          multipleEvent={multipleEvent}
        />
      </div>

      {/* {showTooltip && showTooltip?.index == 1 ? (
        <StatusTooltip showTooltip={showTooltip} totalWidth={width - 140} />
      ) : showTooltip && showTooltip?.index == 2 ? (
        <ShiftTooltip showTooltip={showTooltip} totalWidth={width - 140} />
      ) : (
        showTooltip &&
        showTooltip?.index == 0 && (
          <JunctionTooltip showTooltip={showTooltip} totalWidth={width - 140} />
        )
      )} */}

      {/* {showTooltip ? <TimelineTooltip showTooltip={showTooltip} /> : null} */}
    </div>
  );
});

export default UpuTimeline;
