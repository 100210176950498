import React, { useState } from "react";
import { UpuTower, UpuTowerText } from "./svg";
import generateFileUrl from "../../../utils/generateFileUrl";
import Button from "../../../components/buttons/Button";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import StatusColors from "./StatusColors";
import { CustomModal } from "../../../components";
import { InfoIcon } from "../../../assets/icons/commonIcons";
import { RefreshIcon } from "./svg/EmbeddedServerIcons";
import { statusColors } from "../../../utils/statusColors";

const Header = ({ deviceInfo, loading, setResetAlert, cancelButton, towerStatus }) => {
  const { t } = useTranslation();
  const [isOpenStatus, setIsOpenStatus] = useState(false);

  const items = [
    { name: "Bağlı Olduğu Ekipman", value: deviceInfo?.name, image: deviceInfo?.image },
    { name: "Node ID", value: deviceInfo?.nodeId },
    { name: t("embedded:macAddress"), value: String(deviceInfo?.macAddress) },
    { name: t("embedded:ipAddress"), value: deviceInfo?.ipAddress },
    { name: t("embedded:deviceState"), value: deviceInfo?.deviceState },
    { name: t("embedded:status"), value: deviceInfo?.status },
    { name: t("embedded:sensorFusion"), value: deviceInfo?.stmVersion },
    { name: t("embedded:connectionSoftware"), value: deviceInfo?.espVersion },
    { name: "RSSI", value: deviceInfo?.rssi },
  ];

  // const headItems = [
  //   { name: "Node ID", value: deviceInfo?.nodeId },
  //   { name: t("embedded:deviceState"), value: deviceInfo?.deviceState },
  //   { name: t("embedded:status"), value: deviceInfo?.status },
  //   { name: t("embedded:macAddress"), value: String(deviceInfo?.macAddress) },
  //   { name: t("embedded:ipAddress"), value: deviceInfo?.ipAddress },
  //   { name: t("embedded:sensorFusion"), value: deviceInfo?.stmVersion },
  //   { name: t("embedded:connectionSoftware"), value: deviceInfo?.espVersion },
  //   { name: "rssi", value: deviceInfo?.rssi },
  // ];

  return (
    <>
      <div className="flex">
        <div className="flex-1 flex flex-col mr-6">
          <div className="w-full h-[56px] border-b border-gray-300 flex items-center">
            <div className="flex items-center h-full">
              <div className="w-[135px]">
                <UpuTowerText />
              </div>
              <span className="text-lg lg:text-base md:text-base sm:text-sm text-secondary-500 truncate ml-3">Embedded Server</span>
            </div>
            <div className="gap-4 flex items-center ml-auto">
              <Button
                size={"sm"}
                width={177}
                label={"Exit"}
                colorType={"secondary-embedded"}
                onClick={() => {
                  cancelButton();
                }}
              />
              <Button size={"sm"} iconLeft={<RefreshIcon />} colorType={"secondary-error"} label={"Reset Device"} onClick={() => setResetAlert(true)} />

              {/* <Button
                size={"sm"}
                width={180}
                colorType={"primary-embedded"}
                label={"Exit"}
                onClick={() => {
                  cancelButton();
                }}
              /> */}
            </div>
          </div>
          <div className="mt-6 flex justify-between items-center gap-3">
            {items.map((item, index) => {
              return (
                <div className={`h-[52px] grow px-2 py-1.5 flex border rounded-md border-[#B3B8DB] ${item.image ? "flex-row" : "flex-col"}`}>
                  {item?.image ? <img src={generateFileUrl(item.image)} className="w-[48px] h-[36px] rounded mr-2" /> : null}
                  <div className="flex flex-col h-full">
                    {item.name === "RSSI" ? (
                      <p className="text-xs text-secondary-500 flex items-center gap-1 truncate">
                        {item.name + ""} <Icon />
                      </p>
                    ) : (
                      <p className="text-xs text-secondary-500 truncate">{item.name}</p>
                    )}
                    <p className="text-sm text-secondary-700 font-semibold truncate">{item.name === "RSSI" ? `${item.value} dBm` : item.value}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="h-full">
          <UpuTower color={statusColors(towerStatus)} />
        </div>
      </div>

      <CustomModal
        isOpen={isOpenStatus}
        setIsOpen={setIsOpenStatus}
        modalTitle={"Status Colors"}
        children={
          <StatusColors
            onClose={() => {
              setIsOpenStatus(false);
            }}
          />
        }
      />
    </>
  );
};

const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 2.6665H18.5C17.9477 2.6665 17.5 3.11422 17.5 3.6665V12.3332C17.5 12.8855 17.9477 13.3332 18.5 13.3332H19.5C20.0523 13.3332 20.5 12.8855 20.5 12.3332V3.6665C20.5 3.11422 20.0523 2.6665 19.5 2.6665ZM13.8333 4.99984H14.8333C15.3856 4.99984 15.8333 5.44755 15.8333 5.99984V12.3332C15.8333 12.8855 15.3856 13.3332 14.8333 13.3332H13.8333C13.281 13.3332 12.8333 12.8855 12.8333 12.3332V5.99984C12.8333 5.44755 13.281 4.99984 13.8333 4.99984ZM10.1667 7.33317H9.16667C8.61438 7.33317 8.16667 7.78089 8.16667 8.33317V12.3332C8.16667 12.8855 8.61438 13.3332 9.16667 13.3332H10.1667C10.719 13.3332 11.1667 12.8855 11.1667 12.3332V8.33317C11.1667 7.78089 10.719 7.33317 10.1667 7.33317ZM5.5 9.33317H4.5C3.94772 9.33317 3.5 9.78089 3.5 10.3332V12.3332C3.5 12.8855 3.94772 13.3332 4.5 13.3332H5.5C6.05228 13.3332 6.5 12.8855 6.5 12.3332V10.3332C6.5 9.78089 6.05228 9.33317 5.5 9.33317Z"
        fill="#667085"
      />
    </svg>
  );
};

export default React.memo(Header);
