import React from "react";
import {
  PurchaseIcon,
  QuantityAcceptedIcon,
  QuantityDeclineIcon,
} from "../../../assets/icons/productIcons";
import { useTranslation } from "react-i18next";

const OrderPurchaseCard = React.memo(
  ({
    source,
    status,
    qualityStatus,
    acceptedQuantity,
    rejectedQuantity,
    type,
    orderedQuantity,
  }) => {
    const { t } = useTranslation();
    const statusColor = () => {
      if (status === "approved" && qualityStatus === "accepted") {
        return "#12B76A";
      } else if (status === "waiting" && qualityStatus === "waiting") {
        return "#D0D5DD";
      } else if (qualityStatus === "waiting" && status === "approved") {
        return "#EAAA08";
      } else if (status === "approved" && qualityStatus === "rejected") {
        return "#D92D20";
      }
    };

    const capitalizeFirstLetter = (str) => {
      return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
    };

    return (
      <div
        // onClick={() => setIsOperationDetailModal(true)}
        className="flex w-full flex-col h-full"
      >
        <div
          style={{ backgroundColor: statusColor() }}
          className={`h-1 max-h-1 min-h-[4px] rounded-t`}
        ></div>
        <div className="flex w-full h-full border-x pl-3 pr-1 py-1 gap-x-2 border-b rounded-b-lg">
          <div className="flex flex-col w-full h-full">
            <div className="w-full flex items-center overflow-hidden">
              <div className="w-4 h-4 flex items-center justify-center">
                <PurchaseIcon />
              </div>
              <p className="text-sm text-secondary-700 font-semibold truncate ml-1.5">
                {t("product:purchasing")}
              </p>
            </div>
            <div className="flex flex-col">
              <div className="flex border-b">
                <div className="w-[55%] border-r pr-[6px] py-1 text-xs font-normal">
                  {t("product:orderQuantity")}
                </div>
                <div className="w-[45%] px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {orderedQuantity || "--"}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:pcs")}
                  </span>
                </div>
              </div>
              <div className="flex border-b">
                <div className="w-[55%] border-r pr-[6px] py-1 text-xs font-normal">
                  {t("product:rawMaterialTypess")}
                </div>
                <div className="w-[45%] px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {capitalizeFirstLetter(type) || "--"}
                  </span>
                </div>
              </div>
              <div className="flex">
                <div className="w-[55%] border-r pr-[6px] py-1 text-xs font-normal">
                  {t("product:rawMaterialSourcess")}
                </div>
                <div className="w-[45%] pl-[6px] py-1 flex">
                  <span className="font-medium text-xs text-[#475467]">
                    {source === "out"
                      ? t("product:purchasedRawMaterials")
                      : source === "in"
                      ? t("product:rawMaterialCompanys")
                      : "---"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* kalite yeni ui */}
          {/* <div className="flex flex-col border border-secondary-200 bg-white h-[72px]  w-[84px] min-w-[84px]  ml-auto rounded">
            <div className="flex bg-[#F9FAFB] rounded-t px-[6px] py-[2px]  border-b border-b-secondary-200 gap-x-1 items-center justify-center">
              <QualityIcon />
              <p className="text-secondary-700 font-semibold text-[10px]">
                {t("reports:quality")}
              </p>
            </div>
            <div className="flex flex-col  items-center justify-center">
              <div className="flex gap-x-[2px] p-1 items-center">
                <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                  <QuantityAcceptedIcon />
                </div>
                <span className="text-xs font-medium text-[#079455]">
                  {acceptedQuantity || "0"}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
              <div className="flex gap-x-[2px] p-1 items-center">
                <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                  <QuantityDeclineIcon />
                </div>
                <span className="text-xs font-medium text-[#D92D20]">
                  {rejectedQuantity || "0"}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
            </div>
          </div> */}
          <div className="flex flex-col border-[0.5px] w-[82px] h-[68px] px-[6px] py-[2px] gap-y-2 rounded min-w-[82px] max-w-[82px]">
            <div className="flex gap-x-1 items-center justify-center">
              <span className="w-2 h-2 min-w-[8px] max-w-[8px] min-h-[8px] max-h-[8px] bg-[#17B26A] rounded-[2px]"></span>
              <p className="text-[#475467] font-normal text-xs">
                {t("reports:quality")}
              </p>
            </div>
            <div className="flex flex-col gap-y-[2px]">
              <div className="flex gap-x-[2px] items-center">
                <QuantityAcceptedIcon />
                <span className="text-xs text-[#079455]">
                  {acceptedQuantity || "0"}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
              <div className="flex gap-x-[2px] items-center">
                <QuantityDeclineIcon />
                <span className="text-xs text-[#D92D20]">
                  {rejectedQuantity || "0"}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default OrderPurchaseCard;
