import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { lossesService } from "../../../services";
import { Loader } from "../../../assets/icons/stepsIcons";
import { useLoaderData, useOutletContext } from "react-router-dom";
import { EditIcon } from "../../../assets/icons/departmentIcon";
import generateFileUrl from "../../../utils/generateFileUrl";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { Button, CustomModal } from "../../../components";
import LossTypeModal from "./modal/LossTypeModal";
import { concatArrays } from "../../../utils/arrayUtils";
import { NoData } from "../../../steps/step-navigation/components";

const LossTable = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { data } = useLoaderData();
  const [datas, setDatas] = useState([]);
  const [selectedType, setSelectedType] = useState({});
  const [showFullText, setShowFullText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowDimensions();
  const [tempType, setTempType] = useState([]);
  const [a, b, searchType, setSearchType] = useOutletContext();

  const getLossesTypeList = async () => {
    setLoading(true);
    await lossesService.getLossesTypeList().then((res) => {
      setDatas(data);
      setTempType(data);
    });
    setLoading(false);
  };

  const slice = () => {
    if (width < 1000) {
      return 50;
    } else if (width < 1200) {
      return 70;
    } else if (width < 1300) {
      return 100;
    } else if (width < 1400) {
      return 130;
    } else if (width < 1500) {
      return 150;
    } else {
      return 250;
    }
  };

  useEffect(() => {
    if (searchType) {
      const filterDataType = tempType.filter((e) => {
        let name = `${e.name}`;
        return name.replace(/^\./g, "").toLowerCase().includes(searchType.toLowerCase());
      });
      let arr = [];
      const response = concatArrays(arr, filterDataType);
      setDatas(response);
    } else {
      setDatas(tempType);
    }
  }, [searchType, setSearchType, tempType]);

  useEffect(() => {
    getLossesTypeList();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="w-full h-[90%] flex flex-col mt-4 border border-secondary-200  rounded-xl shadow-sm ">
          <div className="w-full h-11 bg-secondary-50 flex border-b rounded-t-xl dark:bg-[#202020] dark:border-b-[#202020]">
            <div className="w-[6%] h-full border-r dark:border-r-[#292929] flex items-center  py-3 px-6 ">
              <p className="text-xs font-medium text-secondary-600 dark:text-[#D6D6D6]">{t("loss:code")}</p>
            </div>
            <div className="w-[8%] h-full border-r dark:border-r-[#292929] py-3 px-6 flex items-center justify-center">
              <p className="text-xs font-medium text-center text-secondary-600 dark:text-[#D6D6D6]">{t("loss:lossIcon")}</p>
            </div>
            <div className="w-[17%] h-full border-r dark:border-r-[#292929] flex items-center  px-6 py-3">
              <p className="text-xs font-medium text-secondary-600 dark:text-[#D6D6D6]">{t("loss:lossName")}</p>
            </div>
            <div className="w-[63%] h-full flex border-r items-center px-6 py-3">
              <p className="text-xs font-medium text-secondary-600 dark:text-[#D6D6D6]">{t("product:description")}</p>
            </div>
          </div>
          <div className="flex-1 flex flex-col h-[80%] overflow-y-scroll scrollbar-hide">
            {loading ? (
              <div className="flex items-center justify-center w-full h-full">
                <Loader currentColor="currentColor" currentFill="currentFill" />
              </div>
            ) : data?.length === 0 ? (
              <div className="w-full h-full flex items-center justify-center">
                <NoData theme={"machine"} header={t("label:noData")} button={false} />
              </div>
            ) : (
              datas?.map((item, index) => {
                const handleToggleText = () => {
                  if (showFullText == item?.id) {
                    setShowFullText("");
                  } else {
                    setShowFullText(item?.id);
                  }
                };
                const renderText = () => {
                  if (showFullText === item?.id) {
                    return item?.description;
                  }
                  if (item?.description?.length > slice()) {
                    return item?.description?.slice(0, slice()) + "...";
                  }
                  return item?.description;
                };
                const renderButton = () => {
                  if (item?.description?.length > slice()) {
                    return (
                      <p className="text-xs inline font-medium text-blue-600 hover:underline select-none cursor-pointer ml-1" onClick={handleToggleText}>
                        {!showFullText ? `${t("personals:showMore")}` : `${t("personals:less")}`}
                      </p>
                    );
                  }
                  return null;
                };
                return (
                  <div
                    key={index}
                    className={`w-full ${index === 9 ? "border-b-0" : "border-b"} dark:border-b-[#202020] flex ${
                      showFullText == item?.id ? "min-h-[72px] h-fit" : "h-[72px] min-h-[72px]"
                    }`}
                  >
                    <div className="w-[6%]  min-w-[6%]  max-w-[6%] h-full border-r dark:border-r-[#292929] flex items-center  px-6 py-3">
                      <p className="text-sm text-secondary-600 font-normal truncate dark:text-[#F5F5F5]">L{item.order}</p>
                    </div>
                    <div className="w-[8%] min-w-[8%] max-w-[8%] h-full border-r dark:border-r-[#292929] flex items-center px-6 py-3 justify-center">
                      {item?.iconKey ? (
                        <img src={generateFileUrl(item?.iconKey)} className="w-6 h-6 dark:text-[#F5F5F5]" />
                      ) : (
                        <></>
                        // <DefaultProcessingIcon />
                      )}
                    </div>
                    <div className="min-w-[17%] max-w-[17%] h-full border-r dark:border-r-[#292929] flex items-center px-6 py-3">
                      <p className="text-sm text-secondary-600 font-normal truncate dark:text-[#F5F5F5]">{item?.name || "---"}</p>
                    </div>

                    <div className={` h-full w-[63%] min-w-[63%] max-w-[63%] border-r flex items-center px-6 ${showFullText ? "py-3" : "py-1"}`}>
                      <p className="text-sm text-secondary-600 font-normal dark:text-[#F5F5F5]">
                        {renderText() || "---"}
                        {renderButton()}
                      </p>
                    </div>
                    <div className="flex items-center justify-center w-full">
                      <Button
                        onClick={() => {
                          setSelectedType(item);
                          setIsOpen(true);
                        }}
                        colorType={"tertiary-gray"}
                        iconLeft={<EditIcon />}
                        size={"md"}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}

      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={t("loss:editLossType")}
        children={<LossTypeModal selectedType={selectedType} setIsOpen={setIsOpen} getLossesTypeList={getLossesTypeList} />}
      />
    </>
  );
};

export default LossTable;
