import React, { useState } from "react";
import { Button, CustomModal } from "../../components";
import { ArrowLeftIcon } from "../../assets/icons/PersonIcons";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "../../assets/icons/productIcons";
import Badgets from "../../components/buttons/Badgets";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { instance } from "../../libs/client";
import PurchaseJunctionFinish from "./modals/PurchaseJunctionFinish";
import RawQualityControlForm from "./modals/RawQualityControlForm";
import PlanPurchaseJunctionModal from "./modals/PlanPurchaseJunctionModal";
import SelectUserModal from "../../components/modal/SelectUserModal";
import PurchaseDetailTable from "./components/PurchaseDetailTable";
import NewApprovedPurchaseJunctionCard from "../../components/supply-junctions/NewApprovedPurchaseJunctionCard";
import PurchaseJunctionCard from "../../components/supply-junctions/PurchaseJunctionCard";
require("moment/locale/tr");

const PurchaseDetail = () => {
  const { id } = useParams();
  const type = id.split("+")[0];
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [finishPurhcaseModal, setFinishPurhcaseModal] = useState(false);
  const [finishPurhcaseModalData, setFinishPurhcaseModalData] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [purchaseQualityForm, setPurchaseQualityForm] = useState(false);
  const [purchaseQualityFormData, setPurchaseQualityFormData] = useState(null);
  const [isOpenSelectUserModal, setIsOpenSelectUserModal] = useState(false);
  const [selectedJunction, setSelectedJunction] = useState(null);

  const relations = () => {
    switch (type) {
      case "raw":
        return { relations: ["rawMaterial"] };
      case "main_product":
        return { relations: ["product"] };
      case "semi_internal_product":
        return { relations: ["productInternalOperation", "product"] };
      case "semi_external_product":
        return { relations: ["productExternalOperation", "product"] };
      default:
        break;
    }
  };

  const query = useQuery({
    retry: 0,
    queryKey: ["purchase-details"],
    queryFn: async () => {
      const relation = relations();
      return await instance.post(`purchase/transaction/details/${id.split("+")[1]}`, relation);
    },
  });

  const headerData = query?.data?.data?.transactions;
  const junctionsData = query?.data?.data?.junctions;
  const refetch = query.refetch;
  const oneOrderTime = (start, end, count) => {
    const diff = moment(end).diff(moment(start), "seconds");
    const oneOrder = diff / count;
    return `${oneOrder?.toFixed(0)}`;
  };

  const activeAndPlannedData = junctionsData
    ?.filter((item) => item?.type === "planned" || item?.status === "active")
    ?.sort((b, a) => moment(a?.startDate ? a.startDate : 0).unix() - moment(b?.startDate ? b.startDate : 0).unix());
  const completedAndQualityPending = junctionsData
    ?.filter((item) => item?.quality === "waiting" && item?.status === "completed")
    ?.sort((b, a) => moment(a?.startDate ? a.startDate : 0).unix() - moment(b?.startDate ? b.startDate : 0).unix());
  const completedAndQualityCompletede = junctionsData
    ?.filter((item) => item?.quality === "applied" && item?.status === "completed")
    ?.sort((b, a) => moment(a?.startDate ? a.startDate : 0).unix() - moment(b?.startDate ? b.startDate : 0).unix());

  return (
    <>
      <div className="w-full h-full flex flex-col">
        {/* header */}
        <div className="w-full h-[56px] min-h-[56px] max-h-[56px] border-b flex items-center">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} />
          <div className="flex flex-col ml-3">
            <h1 className="text-xl font-semibold text-gray-900">{t("product:purchaseDetails")}</h1>
          </div>
          <div className="flex items-center ml-auto gap-3 mr-1">
            <Button
              colorType={"secondary-gray"}
              size={"sm"}
              iconLeft={<PlusIcon />}
              label={t("product:startPurchase")}
              onClick={() => {
                setIsOpenPlanningModal(true);
              }}
            />
          </div>
        </div>
        <PurchaseDetailTable headerData={headerData} />
        <div className="w-full h-full border shadow-md rounded-xl overflow-y-auto border-[#D0D5DD] flex flex-col relative mt-6">
          <div id="content-wrapper" className="w-full pb-1 flex mt-6 gap-x-3 overflow-y-auto scrollbar-hide overflow-x-hidden">
            <div className="w-1/3 h-full flex flex-col sm:min-w-[394px] md:min-w-[394px] lg:min-w-[394px] ml-4">
              <div className="w-full border-b bg-white border-gray-200 pb-1 flex items-center gap-3">
                <p className="text-lg text-secondary-900 font-semibold">{t("product:activePurchases")}</p>
                <Badgets colorType={"fill-warning"} size={"sm"} label={activeAndPlannedData?.length || 0} />
              </div>

              <div className="w-full flex-1 overflow-y-auto flex flex-col pt-3 scrollbar-hide">
                {activeAndPlannedData?.map((item, index) => {
                  if (item?.supplier) {
                    return (
                      <div className="w-full mb-3 mt-2" key={index}>
                        <PurchaseJunctionCard
                          id={item?.id}
                          name={item?.supplier?.name}
                          image={item?.supplier?.image}
                          piece={item?.orderedUnit}
                          status={item?.status}
                          type={item?.type}
                          rightButton={() => {
                            if (item?.type === "planned") {
                              setIsOpenSelectUserModal(true);
                              setSelectedJunction(item);
                            }
                          }}
                          user={item?.status === "active" ? item?.responsibleUser : undefined}
                          onClick={() => {
                            if (item?.status === "active") {
                              setFinishPurhcaseModal(true);
                              setFinishPurhcaseModalData(item);
                            }
                          }}
                          estimatedDeliveryDate={item?.estimatedDeliveryDate}
                          refetch={refetch}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div className="h-full w-px bg-gray-200 " />
            <div className="w-1/3 h-full flex flex-col">
              <div className="w-full border-b bg-white border-gray-200 pb-1 flex items-center gap-3">
                <p className="text-lg text-secondary-900 font-semibold">{t("product:qualityPendingPurchases")}</p>
                <Badgets colorType={"fill-warning"} size={"sm"} label={completedAndQualityPending?.length || 0} />
              </div>

              <div className="w-full flex-1 overflow-y-auto flex flex-col pt-3 scrollbar-hide gap-y-2">
                {completedAndQualityPending?.map((item, index) => {
                  return (
                    <div className="w-full mb-3" key={index}>
                      <div className="mt-2">
                        <PurchaseJunctionCard
                          id={item?.id}
                          name={item?.supplier?.name}
                          image={item?.supplier?.image}
                          piece={item?.incomingUnit}
                          status={item?.status}
                          type={item?.type}
                          rightButton={() => {
                            if (item?.type === "planned") {
                              setIsOpenSelectUserModal(true);
                              setSelectedJunction(item);
                            }
                          }}
                          user={item?.responsibleUser}
                          estimatedDeliveryDate={item?.estimatedDeliveryDate}
                          refetch={refetch}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="h-full w-px bg-gray-200 " />
            <div className="w-1/3 h-full flex flex-col sm:min-w-[394px] md:min-w-[394px] lg:min-w-[394px] mr-4">
              <div className="w-full border-b bg-white border-gray-200 pb-1 flex items-center gap-3">
                <p className="text-lg text-secondary-900 font-semibold">{t("product:qualityCompletedQuality")}</p>
                <Badgets colorType={"fill-warning"} size={"sm"} label={completedAndQualityCompletede?.length || 0} />
              </div>
              <div className="w-full flex-1 overflow-y-auto flex flex-col pt-3 scrollbar-hide gap-y-2 ">
                {completedAndQualityCompletede?.map((item, index) => {
                  return (
                    <div className="w-full mb-3" key={index}>
                      <div className="mt-2">
                        {/* <PurchaseJunctionCard
                              id={item?.id}
                              name={item?.supplier?.name}
                              image={item?.supplier?.image}
                              piece={item?.incomingUnit}
                              type={item?.type}
                              rightButton={() => {
                                if (item?.type === "planned") {
                                  setIsOpenSelectUserModal(true);
                                  setSelectedJunction(item);
                                }
                              }}
                              user={item?.responsibleUser}
                              estimatedDeliveryDate={
                                item?.estimatedDeliveryDate
                              }
                              refetch={refetch}
                            /> */}
                        <NewApprovedPurchaseJunctionCard
                          id={item?.id}
                          name={item?.supplier?.name}
                          image={item?.supplier?.image}
                          piece={item?.incomingUnit}
                          type={item?.type}
                          rightButton={() => {
                            if (item?.type === "planned") {
                              setIsOpenSelectUserModal(true);
                              setSelectedJunction(item);
                            }
                          }}
                          user={item?.responsibleUser}
                          qualityAcceptedPiece={item?.acceptedUnit}
                          qualityRejectedPiece={item?.rejectedUnit}
                          estimatedDeliveryDate={item?.estimatedDeliveryDate}
                          refetch={refetch}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={finishPurhcaseModal}
        setIsOpen={setFinishPurhcaseModal}
        modalTitle={t("product:purchaseApproval")}
        children={
          <PurchaseJunctionFinish refetch={refetch} close={() => setFinishPurhcaseModal(false)} junctionData={finishPurhcaseModalData} cardData={headerData} />
        }
      />
      <CustomModal
        isOpen={purchaseQualityForm}
        setIsOpen={setPurchaseQualityForm}
        modalTitle={t("product:productRawMaterial")}
        children={
          <RawQualityControlForm refetch={refetch} close={() => setPurchaseQualityForm(false)} junctionData={purchaseQualityFormData} cardData={headerData} />
        }
      />
      <CustomModal
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        onClose={() => {
          setIsOpenPlanningModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={<PlanPurchaseJunctionModal setIsOpen={setIsOpenPlanningModal} refetch={refetch} selectedJunction={headerData} />}
      />

      <CustomModal
        isOpen={isOpenSelectUserModal}
        setIsOpen={setIsOpenSelectUserModal}
        onClose={() => {
          setIsOpenSelectUserModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectUserModal
            t={t}
            setIsOpen={setIsOpenSelectUserModal}
            isOpen={isOpenSelectUserModal}
            selectedJunction={selectedJunction}
            handleData={refetch}
            purchase={true}
          />
        }
      />
    </>
  );
};

export default PurchaseDetail;
