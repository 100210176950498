import React, { useState } from "react";
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";

const AddManuelJunctionDrawer = ({ children, setIsOpen, isOpen = true }) => {
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className="h-full border-secondary-300 border-[1px]  rounded-2xl  ">
      {" "}
      <Drawer enableOverlay={false} open={isOpen} onClose={() => {}} direction="bottom" className="w-full h-[56vh]    ">
        {children}
      </Drawer>
    </div>
  );
};

export default AddManuelJunctionDrawer;
