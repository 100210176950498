import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CustomModal } from "../../../components";
import { PlusIcon } from "../../../assets/icons/PersonIcons";
import { JunctionNumberIcon, PersonnelAllIcon } from "../../../components/new-cards/card-icons";
import { DashboardPlusIcon } from "../../../assets/icons/machineIcons";
import PersonDashboardDepartmentCard from "../../product/cards/PersonDashboardDepartmentCard";
import PersonnelDashboardDateSelector from "../../machine/Twin/PersonnelDashboardDateSelector";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PersonnelDashboardDetailInfo from "../../machine/Twin/components/PersonnelDashboardDetailInfo";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import PersonnelDashboardMonthlyChart from "../../machine/Twin/components/PersonnelDashboardMonthlyChart";
import Badgets from "../../../components/buttons/Badgets";
import SearchInput from "../../../components/inputs/SearchInput";
import PersonDashboardPersonnelCard from "../../product/cards/PersonDashboardPersonnelCard";
import PersonnelDetailDashboardActiveCard from "../../../components/new-cards/active-card/PersonnelDetailDashboardActiveCard";
import PersonnelDashboardTodayDoneCard from "../../../components/new-cards/junction-card/personnel-dashboard/PersonnelDashboardTodayDoneCard";
import PersonnelDashboardDoneCard from "../../../components/new-cards/junction-card/personnel-dashboard/PersonnelDashboardDoneCard";
import AddUserModal from "../../../steps/users/modals/AddUserModal";
import AddDepartment from "../../../steps/department/modals/Add-Department";
import { reportService } from "../../../services/report.service";
import NoData from "../../../steps/step-navigation/components/NoData";
import diacritics from "diacritics";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { ScrollChevronIcon, SwitchIcon } from "../../../assets/icons/productIcons";
import { personalService } from "../../../services/personal.service";
import PersonDashboardSortButton from "../../product/cards/PersonDashboardSortButton";
import { HumburgerIcon } from "../../../assets/icons/leftMenuIcons";
import { useStore } from "../../../hooks/useStores";

const PersonDashboard = ({}) => {
  const { pathname } = useLocation();
  const { settings } = useStore();
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  let { dates, userId } = useParams();
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [gaugeData, setGaugeData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [defaultStartTime, setDefaultStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [defaultEndTime, setDefaultEndTime] = useState(null);
  const [isOpenPersonnel, setIsOpenPersonnel] = useState(null);
  const [isOpenDepartment, setIsOpenDepartment] = useState(null);
  const navigate = useNavigate();
  const [dateChangeCount, setDateChangeCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPersonal, setSelectedPersonal] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("all");
  const [departments, setDepartments] = useState([]);
  const newArr = [{ name: "All", id: "all" }, ...departments];
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(true);
  const [loadingHeader, setLoadingHeader] = useState(false);

  const [headerData, setHeaderData] = useState({});
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("completed");
  const [selectedDataType, setSelectedDataType] = useState("active");

  const getDashboardHeaderData = async () => {
    setLoadingHeader(true);
    setLoading(true);
    const data = { date: moment(date)?.format("YYYY-MM-DD"), type: "company" };
    await reportService.personelDashboard(data).then((res) => {
      setHeaderData(res?.data);
    });
    await reportService.personelDashboardDepartment(data).then((res) => {
      setData(res);
    });
    setLoadingHeader(false);
    setLoading(false);
  };

  const junctionDatas = (data) => {
    const activeJunc = data?.filter((e) => {
      return e?.junctions?.length > 0 && !false;
    });

    const passive = data?.filter((e) => {
      return e?.junctions?.length == 0 || e.junctions.length == null;
    });

    const all = data;

    return selectedDataType == "passive" ? passive : selectedDataType == "active" ? activeJunc : all;
  };

  const activePersonLength = data?.userList?.filter((e) => {
    return e?.junctions?.length > 0 && !false;
  });

  const passivePersonLength = data?.userList?.filter((e) => {
    return e?.junctions?.length == 0 || e.junctions.length == null;
  });

  const getDashboardDepartmentData = async (id) => {
    setLoading(true);
    const datas = { date: moment(date)?.format("YYYY-MM-DD"), type: "company" };
    const datasDepartment = { date: moment(date)?.format("YYYY-MM-DD"), type: "department", departmentId: id };
    if (id === "all") {
      await reportService.personelDashboardDepartment(datas).then((res) => {
        setData(res);
      });
    } else {
      await reportService.personelDashboardDepartment(datasDepartment).then((res) => {
        setData(res);
      });
    }
    setLoading(false);
  };

  const [legendSelected, setLegendSelected] = useState({
    Performance: true,
    Quality: true,
    upuPoint: true,
  });

  const handleData = async () => {
    await personalService
      .getDepartments()
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {});
  };

  const filteredUserList = data?.userList?.filter((d) =>
    diacritics.remove(`${d?.name} ${d?.lastName}`).toLowerCase().includes(diacritics.remove(searchQuery).toLowerCase())
  );

  // const junctionNumber = (item) => {
  //   const junctions = filteredUserList?.map(user => user?.junctions)
  //     .flat()
  //     .filter(a => a?.status != "planned");
  //   const sorted = junctions?.sort((a, b) => moment(a?.startDate).unix() - moment(b?.startDate).unix());
  //   const dataJunction = sorted?.map((value, index) => {
  //     if (value?.id == item) {
  //       return index + 1;
  //     } else {
  //       return "";
  //     }
  //   });

  //   const lastData = dataJunction?.filter(a => a != "");
  //   return lastData;
  // };

  const formattedStartDate = moment(dates)?.format("YYYY-MM-DD");
  const handleDateChange = (newDate) => {
    navigate(`/app/person/dashboard/${newDate}`);
  };

  let datas = {
    date: formattedStartDate,
  };

  useEffect(() => {
    setDate(dates);
  }, []);

  useEffect(() => {
    setGaugeData(
      [
        { ...data?.performance, type: "Performance" },
        { ...data?.quality, type: "qualityRate" },
      ] || [{}, {}, {}]
    );
    if (data?.code == 2) {
      setGaugeData(
        [
          { ...data?.performance, type: "Performance" },
          { ...data?.quality, type: "qualityRate" },
        ] || [{}, {}, {}]
      );

      setStartTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf());
      setDefaultStartTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf());
      setEndTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf() + 86400000);
      setDefaultEndTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf() + 86400000);
    } else {
      setStartTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf());
      setDefaultStartTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf());
      setEndTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf() + 86400000);
      setDefaultEndTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf() + 86400000);
    }
  }, [data]);

  useEffect(() => {
    getDashboardHeaderData();
  }, [dates]);

  useEffect(() => {
    if (moment(date).format("YYYY-MM-DD") === moment(dates).format("YYYY-MM-DD")) {
    } else {
      handleDateChange(moment(date).format("YYYY-MM-DD"));
    }
  }, [date]);

  useEffect(() => {
    setDateChangeCount((prevCount) => prevCount + 1);
  }, [dates]);

  // const handleScroll = () => {
  //   const container = document.getElementById("content-wrapper");
  //   const isAtBeginning = container.scrollLeft === 0;
  //   const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
  //   setIsLeftArrowHidden(isAtBeginning);
  //   setIsRightArrowHidden(isAtEnd);
  // };

  // const sideScroll = (element, speed, distance, step) => {
  //   let scrollAmount = 0;
  //   const slideTimer = setInterval(() => {
  //     element.scrollLeft += step;
  //     scrollAmount += Math.abs(step);
  //     if (scrollAmount >= distance) {
  //       clearInterval(slideTimer);
  //     }
  //   }, speed);
  // };

  // useEffect(() => {
  //   const container = document.getElementById("content-wrapper");
  //   container?.addEventListener("scroll", handleScroll);
  //   return () => {
  //     container?.removeEventListener("scroll", handleScroll);
  //   };
  // }, [handleScroll]);

  // <div className="w-full h-full mb-3 bg-gray-200 animate-pulse rounded-lg" />

  return (
    <>
      <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative scrollbar-hide">
        <div className={`flex w-full  h-14 min-h-[56px] mb-4 items-center  `}>
          <div className="flex items-center">
            <span className="mr-3 md:flex sm:flex xs:flex hidden">
              <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
            </span>
            <span className="font-codecMedium text-2xl text-black">upu.</span>
            <p className="text-2xl font-codecMedium text-[#0E9384]">person</p>
            <p className="text-xl font-normal ml-2 text-secondary-500">{"Dashboard"}</p>
          </div>
          <div className="ml-auto">
            <Button
              label={t("addPersonal:addNewPersonal")}
              onClick={() => {
                setIsOpenPersonnel(true);
                setSelectedPersonal(null);
              }}
              iconLeft={<PlusIcon />}
              colorType={"primary-person"}
              size={"md"}
            />
          </div>
        </div>
        {loadingHeader ? (
          <div className="w-full h-[116px] min-h-[116px] max-h-[116px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div className="flex flex-row items-center gap-x-4 w-full">
            <div
              onClick={() => {
                setSelectedDepartment(null);
                setIsOpenDepartment(true);
              }}
              className="flex items-center cursor-pointer justify-center  bg-white shadow-xs border-secondary-200 border border-[#D0D5DD)] rounded-lg p-2 hover:bg-[#F9FAFB] active:shadow-ring-gray w-9 h-[95px] mb-9"
            >
              <DashboardPlusIcon />
            </div>
            <div className="h-[116px] min-h-[116px] w-full overflow-x-auto mb-4 flex ">
              <div className="min-w-[242px] border-b pr-5 max-h-[96px]">
                <div
                  onClick={() => {
                    getDashboardDepartmentData("all");
                    setSelectedDepartment("all");
                  }}
                  className={`min-w-[220px] max-w-[220px] rounded-t-lg  rounded-b-none ${
                    selectedDepartment === "all" ? "border-b-[2px] border-b-[#107569] h-[96px]" : "h-[96px]"
                  }  border cursor-pointer border-seconday-300  select-none flex flex-col`}
                >
                  <div className="flex flex-row items-center justify-center w-full gap-x-1.5 p-2 border-b border-b-[#E4E7EC]">
                    <PersonnelAllIcon color={selectedDepartment === "all" ? "#344054" : "#667085"} />
                    <p className={`text-base truncate ${selectedDepartment === "all" ? "text-[#475467] font-semibold" : "text-secondary-500 font-medium"} `}>
                      {t("product:allNew")}
                    </p>
                  </div>
                  <div className="flex flex-row items-center w-full h-full">
                    <div className={`flex flex-col items-center w-1/2 ${selectedDepartment === "all" ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}  border-r `}>
                      <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                        <p className={`text-xs  ${selectedDepartment === "all" ? "font-semibold text-[#475467]" : "font-medium text-[#667085]"}  font-medium`}>
                          {t("person:totalPersonnel")}
                        </p>
                      </div>
                      <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                        <p className={`text-sm ${selectedDepartment === "all" ? "font-semibold text-[#475467]" : "font-medium text-[#667085]"}`}>
                          {headerData?.all?.userCount || "--"}{" "}
                        </p>
                      </div>
                    </div>
                    <div className={`flex flex-col items-center w-1/2 ${selectedDepartment === "all" ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
                      <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                        <p className={`text-xs ${selectedDepartment === "all" ? "font-semibold text-[#475467]" : "font-medium text-[#667085]"}   font-medium`}>
                          {t("person:activePersonnel")}
                        </p>
                      </div>
                      <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                        <p className={`text-sm  ${selectedDepartment === "all" ? "font-semibold text-[#079455]" : "font-medium text-[#667085]"}`}>
                          {headerData?.all?.activeUserCount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {headerData?.departments
                ?.sort((a, b) => {
                  return b?.userCount - a?.userCount;
                })
                ?.map((item, index) => {
                  return (
                    <div key={index} className={`min-w-[192px] ${index !== headerData?.departments?.length - 1 ? "border-b" : "border-b-0"} max-h-[96px] pr-5`}>
                      <PersonDashboardDepartmentCard
                        onClick={async () => {
                          getDashboardDepartmentData(item?.id);
                          setSelectedDepartment(item);
                        }}
                        selectedDepartment={selectedDepartment}
                        departmentId={item?.id}
                        departmentName={item?.name}
                        totalPersonnel={item?.userCount}
                        activePersonnel={item?.activeUserCount}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {loading ? (
          <div className="w-full h-[38px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div className="mt-2 w-full">
            <PersonnelDashboardDateSelector dates={dates} date={date} setDate={setDate} loading={loading} />
          </div>
        )}
        {loading ? (
          <div className="w-full h-[308px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div className="flex items-start gap-x-4 mt-6">
            <PersonnelDashboardDetailInfo data={data} gaugeData={gaugeData} />
            <PersonnelDashboardMonthlyChart
              data={data?.monthlyData}
              selectedDepartment={selectedDepartment}
              legendSelected={legendSelected}
              setLegendSelected={setLegendSelected}
              setDate={setDate}
              start={moment(date).format("YYYY/MM/DD")}
              end={moment(data?.dayEnd).format("YYYY/MM/DD")}
              selectedDate={dates}
              defaultLegends={data?.legends}
            />
          </div>
        )}
        {loading ? (
          <div className="w-full h-[308px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div style={{ minHeight: height - 60 }} className="w-full border relative shadow-md mt-6 rounded-xl border-[#D0D5DD] overflow-hidden">
            <div className="flex w-full px-6 py-3 items-center border-b">
              <div className="flex w-full min-h-[28px] max-h-[28px] gap-x-2 items-center">
                <p className="flex text-[#101828] font-semibold text-lg">{t("personals:personals")}</p>
                <Badgets
                  colorType={"fill-success"}
                  label={
                    selectedDataType === "passive"
                      ? passivePersonLength?.length
                      : selectedDataType === "active"
                      ? activePersonLength?.length
                      : data?.userList?.length
                  }
                />
              </div>
              <div className="w-[480px] min-w-[480px]">
                <div className="flex flex-row items-center rounded-lg border border-secondary-300 h-[42px] min-h-[42px]">
                  <div
                    onClick={() => {
                      setSelectedDataType("active");
                    }}
                    className={`px-4 py-2 flex ${
                      selectedDataType === "active" ? "bg-secondary-50 rounded-l-lg" : "bg-white rounded-l-lg"
                    } items-center cursor-pointer justify-center h-full border-r w-1/3`}
                  >
                    <p className="text-sm font-semibold text-secondary-700">{t("person:activePersonnel")}</p>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedDataType("passive");
                    }}
                    className={`px-4 py-2 flex ${
                      selectedDataType === "passive" ? "bg-secondary-50 " : "bg-white "
                    } items-center cursor-pointer justify-center h-full border-r w-1/3`}
                  >
                    <p className="text-sm font-semibold text-secondary-700">{t("person:passivePersonnel")}</p>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedDataType("all");
                    }}
                    className={`px-4 py-2 flex ${
                      selectedDataType === "all" ? "bg-secondary-50 rounded-r-lg" : "bg-white rounded-r-lg"
                    } cursor-pointer items-center justify-center h-full w-1/3`}
                  >
                    <p className="text-sm font-semibold text-secondary-700">{t("person:allPersonnel")}</p>
                  </div>
                </div>
              </div>
              {/* <div className="w-[95px] min-w-[95px] pl-4">
                  <PersonDashboardSortButton data={data} filteredUserList={filteredUserList} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus}/>
                </div> */}
              <div className="flex max-w-[320px] w-full pl-4 justify-end">
                <SearchInput theme={"product"} setSearchVal={setSearchQuery} />
              </div>
            </div>
            <div className="w-full h-full flex flex-col overflow-hidden overflow-y-auto scrollbar-hide pb-12">
              {/* <div
                  className={`w-11 h-full ${
                    isRightArrowHidden ? "hidden duration-1000" : ""
                  } z-120 right-0 backdrop-blur-md cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-[#FFFFFF30]`}
                  onClick={() => {
                    sideScroll(document.getElementById("content-wrapper"), 25, 100, 20);
                  }}
                >
                  <ScrollChevronIcon />
                </div> */}
              {/* <div
                  className={`w-11 h-full flex ${
                    isLeftArrowHidden ? "hidden duration-1000" : ""
                  } z-120 left-[280px] absolute cursor-pointer flex bg-[#FFFFFF30] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
                  onClick={() => {
                    sideScroll(document.getElementById("content-wrapper"), 25, 100, -20);
                  }}
                >
                  <ScrollChevronIcon />
                </div> */}

              {junctionDatas(filteredUserList)
                ?.sort((a, b) => {
                  return b?.junctions.length - a?.junctions.length;
                })
                .map((items, index) => {
                  const maxJunctionCount = Math.max(...filteredUserList.map((user) => user?.junctions?.length));
                  const paddedJunctions = items?.junctions.concat(Array(maxJunctionCount - items.junctions.length).fill(null));

                  let datas = paddedJunctions?.filter((item) => {
                    return item?.status == selectedStatus;
                  });

                  return (
                    <div key={index} className="flex w-full flex-row border-b gap-x-8 px-6 py-5">
                      <div className="flex flex-row items-start gap-x-8">
                        <PersonDashboardPersonnelCard
                          user={items}
                          key={index}
                          date={date}
                          setSelectedStatus={setSelectedStatus}
                          selectedStatus={selectedStatus}
                        />
                      </div>
                      {items?.junctions?.length == 0 ? (
                        <div className="flex w-full h-full items-center justify-center">
                          <NoData theme={"person"} header={"No data"} button={false} />
                        </div>
                      ) : (
                        <div className="w-full overflow-x-auto flex pb-3">
                          <>
                            {datas?.map((item, junc) => {
                              if (item) {
                                // item mevcutsa, yani gerçek bir junction varsa, normal junction kartını gösteriyoruz
                                if (moment(date).format("DD-MM-YYYY") === moment(new Date()).format("DD-MM-YYYY")) {
                                  if (item?.status === "active") {
                                    return (
                                      <div
                                        className={`flex flex-col mr-4 cursor-pointer w-[467px] min-w-[467px] max-w-[467px] min-h-[150px] max-h-[150px] hover:bg-secondary-50 items-start pt-2 px-3 border-[#E4E7EC] hover:border-secondary-300 border pb-3 rounded-lg`}
                                      >
                                        <div className="w-[440px] min-w-[440px] max-w-[440px]  flex flex-col gap-y-2 relative cursor-pointer">
                                          <div className="flex flex-col gap-y-8 mt-2">
                                            <PersonnelDetailDashboardActiveCard data={item} refetch={getDashboardHeaderData} />
                                          </div>
                                          <div className="flex flex-row items-center gap-x-1">
                                            <JunctionNumberIcon color={"#667085"} />
                                            <p className="text-md font-semibold text-secondary-600">{junc + 1}</p>
                                            <p className="font-medium text-sm text-secondary-600">{t("product:activeEvents")}</p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  } else if (item?.status === "completed") {
                                    if (item?.station) {
                                      return (
                                        <div
                                          className={`flex flex-col mr-4 cursor-pointer w-[467px] min-w-[467px]  max-w-[467px] hover:bg-secondary-50 border-[#E4E7EC] hover:border-secondary-300 border items-start pt-2 px-3 pb-3  rounded-lg`}
                                        >
                                          <div className="w-[440px] min-w-[440px] max-w-[440px] relative cursor-pointer">
                                            <div className="flex flex-col gap-y-8 mt-2">
                                              <PersonnelDashboardTodayDoneCard data={item} refetch={getDashboardHeaderData} />
                                            </div>
                                          </div>
                                          <div className="flex flex-row w-[440px] min-w-[440px] max-w-[440px] items-center gap-x-3 mt-3">
                                            <div className="flex flex-row items-center gap-x-1">
                                              <JunctionNumberIcon color={"#667085"} />
                                              <p className="text-md font-semibold text-secondary-600">{junc + 1}</p>
                                            </div>
                                            <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                                <p className="text-xs font-normal text-secondary-600">{"upu.point"}</p>
                                              </div>
                                              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                                <p className={`text-base w-[100px] text-center truncate font-semibold text-[#9B7D07]`}>
                                                  {item?.metadata?.upuPoint?.toFixed(2) || "0"}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                                <p className="text-xs font-normal text-secondary-600">{t("reports:performance")}</p>
                                              </div>
                                              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                                <p className={`text-base w-[100px] truncate text-center font-semibold text-[#079455]`}>
                                                  %{" "}
                                                  {item?.metadata?.performancePercent > 1
                                                    ? 1 * 100
                                                    : (item?.metadata?.performancePercent * 100)?.toFixed(0) || "0"}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                                <p className="text-xs font-normal text-secondary-600">{t("product:qualityControlRate")}</p>
                                              </div>
                                              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                                <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
                                                  %{item?.metadata?.qualityPercent > 1 ? 1 * 100 : (item?.metadata?.qualityPercent * 100)?.toFixed(0) || "0"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                } else {
                                  if (item?.status === "completed") {
                                    return (
                                      <div
                                        className={`flex flex-col mr-4 cursor-pointer w-[467px] min-w-[467px] max-w-[467px] border-[#E4E7EC] hover:border-secondary-300 border hover:bg-secondary-50 items-start pt-2 px-3 pb-3 rounded-lg`}
                                      >
                                        <div className="w-[440px] min-w-[440px] max-w-[440px] relative cursor-pointer">
                                          <div className="flex flex-col gap-y-8 mt-2">
                                            <PersonnelDashboardDoneCard data={item} refetch={getDashboardHeaderData} />
                                          </div>
                                        </div>
                                        <div className="flex flex-row w-[440px] min-w-[440px] max-w-[440px] items-center gap-x-3  mt-3">
                                          <div className="flex flex-row items-center gap-x-1">
                                            <JunctionNumberIcon color={"#667085"} />
                                            <p className="text-md font-semibold text-secondary-600">{junc + 1}</p>
                                          </div>
                                          <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                            <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                              <p className="text-xs font-normal text-secondary-600">{"upu.point"}</p>
                                            </div>
                                            <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                              <p className={`text-base w-[100px] text-center truncate font-semibold text-[#9B7D07]`}>
                                                {item?.metadata?.upuPoint?.toFixed(2) || "0"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                            <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                              <p className="text-xs font-normal text-secondary-600">{t("reports:performance")}</p>
                                            </div>
                                            <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                              <p className={`text-base w-[100px] truncate text-center font-semibold text-[#079455]`}>
                                                %{" "}
                                                {item?.metadata?.performancePercent > 1
                                                  ? 1 * 100
                                                  : (item?.metadata?.performancePercent * 100)?.toFixed(0) || "0"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                            <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                              <p className="text-xs font-normal text-secondary-600">{t("product:qualityControlRate")}</p>
                                            </div>
                                            <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                              <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
                                                %{item?.metadata?.qualityPercent > 1 ? 1 * 100 : (item?.metadata?.qualityPercent * 100)?.toFixed(0) || "0"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              } else {
                                return (
                                  <div
                                    className={`flex flex-col mr-4 cursor-pointer w-[467px] min-w-[467px] max-w-[467px] overflow-hidden items-start pt-2 px-3 pb-3 `}
                                  >
                                    <></>
                                  </div>
                                );
                              }
                            })}
                          </>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>

      <CustomModal
        isOpen={isOpenPersonnel}
        setIsOpen={setIsOpenPersonnel}
        modalTitle={t("addPersonal:modalTitle")}
        isBadges={selectedPersonal ? false : true}
        // badgesValue={
        //   tempPersonals?.length + "/" + auth?.user?.company?.limits?.user
        // }
        badgesColor={"fill-success"}
        badgesSize={"md"}
        children={
          <AddUserModal
            setIsOpen={setIsOpenPersonnel}
            handleData={getDashboardDepartmentData}
            selectedPersonal={selectedPersonal}
            setSelectedPersonal={setSelectedPersonal}
          />
        }
      />
      <CustomModal
        isOpen={isOpenDepartment}
        setIsOpen={setIsOpenDepartment}
        children={
          <AddDepartment
            theme={"primary-person"}
            setSelectedDepartmant={setSelectedDepartment}
            setIsOpen={setIsOpenDepartment}
            GetDepartment={handleData}
            selectedDepartmant={selectedDepartment}
          />
        }
        modalTitle={selectedDepartment ? t("department:updateDepartment") : t("department:modalTitle")}
      />
    </>
  );
};

export default PersonDashboard;
