import React, { useState } from "react";
import CustomImage from "../CustomImage";
import { EquipmentDeleteIcon, NoEquIcon } from "../../assets/icons/machineIcons";
import { DecraceStockIcon, EquipmentEditIcon, FreeStockIcon, NewDotsIcon, PieceIcon, RezervStockIcon } from "../new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import EditStockQuantityModal from "../../pages/product/modals/EditStockQuantityModal";
import CustomModal from "../modal/CustomModal";
import { DetailedIcon } from "../../assets/icons/commonIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { CalendarIcons } from "../../assets/icons/productIcons";
import moment from "moment";
import { rawMaterialService } from "../../services/raw-material.service";
import { Toast } from "../../utils/toastify/toast";
import AlertModal from "../alert-Modal/Alert-Modal";
const RawMaterialListMaterialCard = ({ item, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openStockQuantityEdit, setOpenStockQuantityEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isAlert, setIsAlert] = useState(false);

  const menuOptions = [
    {
      label: t("product:editStockQuantity"),
      value: "stockQuantityEdit",
      icon: <DetailedIcon />,
    },
    {
      label: t("product:editRawMaterials"),
      value: "rawMaterialEdit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const onDeleted = async () => {
    await rawMaterialService.deleteRawMaterial(deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
      }
    });
  };

  return (
    <>
      <div
        data-tooltip-id={`${item?.id}`}
        data-tooltip-place={"right"}
        data-tooltip-content={`${item?.name} `}
        className="flex bg-white flex-row mr-4 w-[318px] min-w-[318px] h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab "
      >
        <div className="flex flex-col w-[93%]">
          <div className="flex flex-row items-center gap-x-3">
            {item?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={item?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1  w-full">
                <p className="font-semibold w-[175px] text-secondary-900 text-base truncate">{item?.name || "Product Name"}</p>
              </div>
              <div className={`h-6 max-w-[175px] flex px-1 py-[3px] ml-[2px] items-center justify-center bg-[#FFFAEB]`}>
                <p className={`text-sm font-normal max-w-[175px] truncate text-[#B54708]`}>
                  {t("product:stock")} : {item?.stockCode || "--"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-1 w-[100%]">
            <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:inStock")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">
                  {item?.quantity === 0 ? "0" : item?.quantity % 1 !== 0 ? item?.quantity.toFixed(2) : item?.quantity || "0"}
                </p>
              </div>
            </div>
            <div className="flex flex-col h-[42px] min-w-[28%] w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:activeRequest")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">
                  {item?.orderedUnit ? item?.orderedUnit?.toFixed(2) || "0" : item?.purchaseRequest?.orderedUnit?.toFixed(2) || "0"}
                </p>
              </div>
            </div>
            <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
              <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                <span className="text-xxs truncate text-[#475467] font-medium ">{"MS"}</span>
              </div>
              <div className="flex flex-col w-full items-start">
                <div className="flex flex-row items-center w-full border-b px-1">
                  <p className="text-xxs font-normal text-secondary-600">{t("product:remainings")}</p>
                  <p className="text-xs font-medium ml-auto text-secondary-600">
                    {item?.purchaseRequest
                      ? item?.purchaseRequest?.orderedUnit - item?.purchaseRequest?.plannedUnit || "0"
                      : item?.orderedUnit - item?.plannedUnit || "0"}
                  </p>
                </div>
                <div className="flex flex-row items-center w-full px-1">
                  <p className="text-xxs font-normal text-secondary-600">{t("product:plannedNew")}</p>
                  <p className="text-xs font-medium ml-auto text-secondary-600">
                    {item?.purchaseRequest ? item?.purchaseRequest?.plannedUnit || "0" : item?.plannedUnit || "0"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`absolute right-0 bg-[#F2F4F7] bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
        >
          <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
            {t("product:rMaterial")} ({item?.unitType})
          </p>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
        {isOpen && (
          <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[20px] right-[4px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {menuOptions?.map((items, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (items.value === "stockQuantityEdit") {
                      setOpenStockQuantityEdit(true);
                    }
                    if (items.value === "rawMaterialEdit") {
                      navigate(`/app/product/mrp/stock/raw-material/edit/${item?.id}`);
                    }
                    if (items.value === "delete") {
                      setIsAlert(true);
                      setDeleteId(item?.id);
                    }
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                  }`}
                >
                  {items?.icon}
                  <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{items?.label}</p>
                </button>
              );
            })}
          </div>
        )}
        {!isOpen && <Tooltip id={`${item?.id}`} />}
      </div>
      <CustomModal
        isOpen={openStockQuantityEdit}
        setIsOpen={setOpenStockQuantityEdit}
        onClose={() => {
          setOpenStockQuantityEdit(false);
        }}
        modalTitle={t("product:editStockQuantity")}
        width={400}
        children={
          <EditStockQuantityModal
            onClose={() => {
              setOpenStockQuantityEdit(false);
            }}
            item={item}
          />
        }
      />
      <AlertModal
        title={t("product:deleteRawMaterial")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
    </>
  );
};

export default RawMaterialListMaterialCard;
