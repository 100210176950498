import React, { useEffect, useState } from "react";
import { DangerIcon } from "../../../assets/icons/machineIcons";
import { Controller, useForm } from "react-hook-form";
import Selector from "../../../components/inputs/Selector";
import { Button } from "../../../components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { lossesService } from "../../../services/losses.service";
import { Toast } from "../../../utils/toastify/toast";
import TextArea from "../../../components/inputs/TextArea";
import { equipmentService } from "../../../services";
import { Loader } from "../../../assets/icons/stepsIcons";

export default function SendLossAndFaultModal({ t, setIsOpen, selectedLossData, setWarning, warning, setIsFaultAlert, equipmentData, refetch }) {
  const [selectedLoss, setSelectedLoss] = useState();
  const [loading, setLoading] = useState(false);
  const handleProcess = async () => {
    await lossesService.getLossesTypeList().then((res) => {
      setSelectedLoss(
        res.data
          .filter((d) => {
            return d.name !== null;
          })
          .map((d) => {
            return {
              label: d.name,
              value: d.id,
            };
          })
      );
    });
  };

  const schema = yup.object({
    lossId: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    lossDescription: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (data?.lossId === "Undefined Loss") {
      Toast("error", "Please select a loss type");
      return;
    }
    setLoading(true);
    await equipmentService
      .faultButton(equipmentData?.id, !warning, data?.lossDescription, data?.lossId)
      .then((res) => {
        if (res?.data?.code == 0) {
          setWarning(true);
          Toast("success", t("consoleModal:errorSent"));
          setIsOpen(false);
          refetch();
          setLoading(false);
        } else if (res?.data?.code == 2) {
          setIsFaultAlert(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleProcess();
  }, []);

  useEffect(() => {
    setValue("lossId", selectedLossData?.type ? selectedLossData?.type?.id : "Undefined Loss");
    setValue("lossDescription", selectedLossData?.reasonDescription);
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full h-full">
      <div className="flex flex-col gap-y-4 items-center">
        <span className="min-w-[48px] max-w-[48px] min-h-[48px] max-h-[48px] p-3 bg-[#FEE4E2] rounded-md">
          <DangerIcon />
        </span>
        <div className="flex flex-col items-center text-center gap-y-1">
          <span className="text-lg font-semibold text-[#101828]">{t("equipments:sendErrorMessage")}</span>
          <span className="text-sm font-normal text-[#667085]">{t("equipments:areYouSure")}</span>
        </div>
      </div>
      <div className="flex flex-col gap-y-3 mt-6">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Selector
              items={selectedLoss}
              label={t("loss:lostType")}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              validate={errors?.lossId ? "error" : ""}
              errorMessage={errors?.lossId ? errors?.lossId?.message : ""}
            />
          )}
          name="lossId"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextArea
              label={t("Loss Description")}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              rows={3}
              placeholder={"Write loss description"}
              validate={errors.lossDescription ? "error" : ""}
              errorMessage={errors.lossDescription ? errors.lossDescription.message : ""}
            />
          )}
          name="lossDescription"
        />
      </div>

      <div className="flex w-full gap-x-3 mt-8 min-h-[44px] max-h-[44px]">
        <Button colorType={"secondary-gray"} label={t("buttons:stop")} onClick={() => setIsOpen(false)} />
        {loading ? (
          <div className="flex items-center justify-center bg-[#D92D20]  rounded-lg w-full h-[44px] max-h-[44px] px-3 py-2">
            <Loader currentColor="#D92D20" currentFill="currentFill" />
          </div>
        ) : (
          <Button colorType={"primary-error"} label={t("buttons:send")} type="submit" />
        )}
      </div>
    </form>
  );
}
