import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const TwinNavigation = () => {
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const selectedMap = Number(localStorage.getItem("selectedMap"));
    if (
      selectedMap === 0 ||
      currentPath === "/app/machine/digital-twin/area/0"
    ) {
      
      localStorage.setItem("selectedMap", "1");
      navigate(`/app/machine/digital-twin/area/1`);
    } else if (currentPath === "/app/machine/digital-twin" && selectedMap) {
      
      navigate(`/app/machine/digital-twin/area/${selectedMap}`);
    } else if (currentPath === "/app/machine/digital-twin" && !selectedMap) {
      
      localStorage.setItem("selectedMap", "1");
      navigate(`/app/machine/digital-twin/area/1`);
    }
  }, [location]);

  return (
    <div className="w-full max-w-full h-full flex flex-col overflow-hidden">
      <Outlet />
    </div>
  );
};

export default TwinNavigation;
