import React, { useEffect, useState } from "react";
import moment from "moment";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { authStore } from "../../../stores/auth.store";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { SingleEventRect } from "../../../components/upuTimeline/components/SingleEventRect";
import { useTranslation } from "react-i18next";
import { formatSeconds, secondToHoursAndMinute2 } from "../../../utils/secondToHoursAndMinute";
import OperationNodeTimelines from "./OperationNodeTimelines";
import NoData from "../../../steps/step-navigation/components/NoData";
import Junction from "../../../components/junction/Junction";
import FinishedJunctionCard from "../../../components/junction/FinishedJunctionCard";
import { useNavigate } from "react-router-dom";
import TimeLineTooltip from "./components/TimeLineTooltip";
import LossesCardType from "../../../components/junction/LossesCardType";
import NewApprovedCard from "../../../components/junction/NewApprovedCard";
import NewActiveAndPlannedCard from "../../../components/junction/NewActiveAndPlannedCard";
import FinishJunctionModal from "../../../components/modal/FinishJunctionModal";
import { Button, CustomModal } from "../../../components";
import TimeRange from "react-video-timelines-slider";
import "../../../components/upuTimeline/styles.css";
import { getStartOfDayForCompany } from "../../../components/upuTimeline/TimelineSlider";
import OperationDetailEventCard from "../../../components/new-cards/junction-card/OperationDetailEventCard";
import OperationDetailPreviousEquipmentCard from "../../../components/new-cards/operation-detail/OperationDetailPreviousEquipmentCard";
import OperationDetailSelectedLossCard from "../../../components/new-cards/loss-card/OperationDetailSelectedLossCard";
import OperationDetailSelectedLossEventCard from "../../../components/new-cards/junction-card/OperationDetailSelectedLossEventCard";
import CloseButton from "../../../components/buttons/CloseButton";
import OperationDetailSelectedJunctionDoneCard from "../../../components/new-cards/operation-detail-done-card/OperationDetailSelectedJunctionDoneCard";
import OperationDetailSelectedDoneEventCard from "../../../components/new-cards/junction-card/OperationDetailSelectedDoneEventCard";
import OperationDetailPassiveCard from "../../../components/new-cards/passive-card/OperationDetailPassiveCard";
import OperationDetailLossCard from "../../../components/new-cards/loss-card/OperationDetailLossCard";
import OperationDetailActiveCard from "../../../components/new-cards/active-card/OperationDetailActiveCard";
import OperationDetailTimelineLossCard from "../../../components/new-cards/loss-card/OperationDetailTimelineLossCard";
import OperationDetailTimelineActiveCard from "../../../components/new-cards/active-card/OperationDetailTimelineActiveCard";
import TimeLineDoneCard from "../../../components/new-cards/quality-done-card/TimeLineDoneCard";
import OperationDetailDoneCard from "../../../components/new-cards/operation-detail-done-card/OperationDetailDoneCard";
import Badgets from "../../../components/buttons/Badgets";
import { ErrorIcons } from "../../../components/new-cards/card-icons";
import OperationDetailTodaySelectedDoneEventCard from "../../../components/new-cards/junction-card/OperationDetailTodaySelectedDoneEventCard";
import { junctionService } from "../../../services/junction.service";
import { Toast } from "../../../utils/toastify/toast";
import { NotFound404 } from "../../../assets/illustrations";

const OperationUpuTimeline = ({ data, shiftlyData, groupedDatas, date, refetch }) => {
  const navigate = useNavigate();
  const [shiftValues, setShiftValues] = useState([]);
  const [visible, setVisible] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [defaultStartTime, setDefaultStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [defaultEndTime, setDefaultEndTime] = useState(null);
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedDayType, setSelectedDayType] = useState(1);
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [productType, setProductType] = useState("standart");

  const [junctionType, setJunctionType] = useState("internal");
  const [todayCompleted, setTodayCompleted] = useState(null);
  const [todayLoading, setTodayLoading] = useState(false);

  const [pageLoadTime, setPageLoadTime] = useState(moment());
  const _ = require("lodash");
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [showTooltip, setShowTooltip] = useState(null);
  const [mouseX, setMouseX] = useState(null);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const totalWidth = width;

  const totalWidthh = totalWidth - 600;
  let svgWidth = width - 650;

  let svgHeight = data?.data?.length <= 2 ? 250 : data?.data?.length * 105;

  const junctionNumber = (item) => {
    const junctions = shiftlyData?.filter((a) => a.status != "planned");

    let sortedJunctions = junctions?.sort((a, b) => moment(a?.endDate)?.unix() - moment(b?.endDate)?.unix());

    const datas = sortedJunctions?.map((value, index) => {
      if (value?.id == item) {
        return index + 1;
      } else {
        return "";
      }
    });

    const lastData = datas?.filter((a) => a != "");

    if (lastData?.length > 0) {
      return lastData[0];
    } else {
      return 1;
    }
  };

  const handleMouseMove = (e) => {
    setMouseX(e.clientX);
  };

  const handleMouseOut = () => {
    if (showTooltip) {
      setShowTooltip(null);
    }
  };

  // const handleShiftClick = (index) => {
  //   if (selectedJunction) {
  //     setStartTime(new Date(timeFormatter(selectedJunction.startDate).formatted).valueOf());
  //     setEndTime(new Date(timeFormatter(selectedJunction.endDate).formatted).valueOf());
  //   } else {
  //     setStartTime(new Date(timeFormatter(index.startDate).formatted).valueOf());
  //     setEndTime(new Date(timeFormatter(index.endDate).formatted).valueOf());
  //   }
  // };

  const handleShiftClick = async (data) => {
    if ((data?.index == 0 && data?.junctionType == "work") || data?.junctionType == "setup") {
      setTodayLoading(true);
      await junctionService
        .getQualityJunctionDetail(data?.junctionId)
        .then((res) => {
          setTodayCompleted(res);
        })
        .catch((err) => {
          console.log(err);
        });
      setTodayLoading(false);
    } else {
    }
  };

  useEffect(() => {
    if (selectedJunction && selectedJunction?.lossesId === undefined) {
      handleShiftClick(selectedJunction);
    }
  }, [selectedJunction]);

  const calculateShiftData = async (data) => {
    const datas = [];

    data?.map((value, no) => {
      value?.value.map((da, i) => {
        datas.push({
          name: value.name,
          type: da?.index == 1 ? "status" : da.index == 2 ? "shift" : da.index == 0 ? "junction" : "",
          node: value.node,
          junctionId: da?.junctionId,

          actionId: da?.actionId,
          productData: da?.productName,
          operationNo: da?.operationNo,

          startDate: da?.start,
          endDate: da?.end,
          timeDiff: da?.timeDiff,
          index: da?.index,

          value: [
            da?.index,

            /// moment(da.start).unix(),
            // moment(da.end).unix(),
            moment(timeFormatter(da.start, authStore?.user?.company?.timeZone).formatted)?.unix(),
            moment(timeFormatter(da.end, authStore?.user?.company?.timeZone).formatted)?.unix(),
            da.timeDiff,
          ],
          shiftId: da?.shiftId,
          color:
            da?.index == 1
              ? value?.itemStyle?.normal?.color
              : da?.index == 2
                ? value?.itemStyle?.normal?.color
                : da?.index == 0
                  ? da?.type == "setup"
                    ? "#EAAA08"
                    : "#12B76A"
                  : value?.itemStyle?.normal?.color,
        });
      });
    });

    const Valuess = datas?.sort((a, b) => a.value[1] - b.value[1]);

    let shiftValues = Valuess?.filter((item) => {
      return item?.index == 2;
    });

    await setShiftValues(shiftValues);
  };

  const defaultTimer = async () => {
    await calculateShiftData(shiftlyData);
    await setStartTime(moment(timeFormatter(data?.startDate)?.formatted).valueOf());
    await setDefaultStartTime(moment(timeFormatter(data?.startDate)?.formatted).valueOf());
    await setEndTime(moment(timeFormatter(data?.startDate)?.formatted).valueOf() + 86400000);
    await setDefaultEndTime(moment(timeFormatter(data?.startDate)?.formatted).valueOf() + 86400000);
  };

  useEffect(() => {
    defaultTimer();
  }, []);

  useEffect(() => {
    setPageLoadTime(moment());
  }, [data]);

  const isToday = moment(date).format("YYYY/MM/DD") === moment(new Date()).format("YYYY/MM/DD");

  const [invalidShiftDetected, setInvalidShiftDetected] = useState(false);
  useEffect(() => {
    const checkShifts = () => {
      const invalidShiftExists = shiftValues.some((shift) => {
        const shiftStartTime = new Date(timeFormatter(shift?.startDate)?.formatted)?.getTime();
        return isNaN(shiftStartTime) || shiftStartTime === undefined;
      });

      if (invalidShiftExists) {
        setInvalidShiftDetected(true);
      }
    };

    checkShifts();
  }, [shiftValues]);

  function findJunctionIndexByIds(machineId, junctionId) {
    // birden fazla makinenin çalıştığı timeline'da, junctionId'ye göre index bulma
    for (let obj of data?.data) {
      if (obj.nodeId === machineId) {
        const sortedJunctions = [...obj.activeJunction].sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        for (let i = 0; i < sortedJunctions.length; i++) {
          if (sortedJunctions[i].id === junctionId) {
            return i + 1;
          }
        }
      }
    }
    return null;
  }

  return (
    <>
      <div
        className=" w-full flex flex-col h-full  border-[2px] border-gray-300 rounded-xl bg-white"
        style={{
          width: totalWidthh - 150,
          minHeight: svgHeight + 270,
        }}
      >
        {invalidShiftDetected ? (
          <div className="w-full h-full flex items-center justify-center min-h-[150px] flex-col py-4 gap-y-4">
            <NotFound404 width={150} height={60} />
            <p className="text-sm font-semibold">{t("signals:internetConnectionLost")}</p>
            <Button
              label={"Yeniden Dene"}
              size={"sm"}
              width={width / 7}
              colorType={"primary-machine"}
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        ) : (
          <>
            <div className="w-full  rounded-t-lg  flex items-center ml-3 mt-2 ">
              <span className="isolate inline-flex rounded-lg">
                <button
                  onClick={() => {
                    setStartTime(defaultStartTime);
                    setEndTime(defaultEndTime);
                    setSelectedDayType(1);
                    setSelectedJunction(null);
                  }}
                  type="button"
                  className="relative inline-flex items-center rounded-l-lg  px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  style={{
                    backgroundColor: selectedDayType == 1 ? "#F9FAFB" : "white",
                  }}
                >
                  {t("gauge:allDay")}
                </button>
                {Object?.values(groupedDatas)
                  .sort((a, b) => moment(a[0]?.value[0]?.start).valueOf() - moment(b[0]?.value[0]?.start).valueOf())
                  ?.map((shift, index) => {
                    return (
                      <button
                        key={index}
                        onClick={() => {
                          setStartTime(new Date(timeFormatter(shift[0]?.value[0]?.start)?.formatted).valueOf());
                          setEndTime(new Date(timeFormatter(shift[0].value[shift[0]?.value?.length - 1]?.end)?.formatted).valueOf());
                          setSelectedDayType(shift[0]?.shiftId);
                        }}
                        type="button"
                        className="relative -ml-px inline-flex items-center  px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300  focus:z-10 last:rounded-r-md "
                        style={{
                          backgroundColor: selectedDayType == shift[0]?.shiftId ? "#F9FAFB" : "white",
                        }}
                      >
                        <span
                          className={`w-3 h-3 rounded-sm mr-2`}
                          style={{
                            backgroundColor: shift[0]?.itemStyle?.normal?.color,
                          }}
                        ></span>
                        {shift[0]?.name}
                      </button>
                    );
                  })}
              </span>
            </div>

            <div className="w-full flex mt-2 h-full ">
              <div className={`w-[495px] min-w-[495px] max-w-[495px] flex flex-col items-start px-2 z-[30] `}>
                <div className="flex ml-1 w-full min-h-[75px] max-h-[75px] pb-2  border-b border-b-secondary-200 flex-col gap-y-2 justify-end items-start">
                  <p className="text-xl font-semibold text-[#101828]">{t("product:stationAndWorkActions")}</p>
                </div>
                <>
                  {data?.data?.map((e, i) => {
                    const valuesWithoutItemStyle = e?.timelineData.filter((item) => !item.hasOwnProperty("itemStyle"));
                    const sortedJunction = valuesWithoutItemStyle
                      ?.map((el) => {
                        return el?.value?.map((e) => {
                          return { name: el?.name, status: el?.status, ...e };
                        });
                      })
                      ?.flat(1)
                      ?.sort((a, b) => moment(b?.end).unix() - moment(a?.end).unix());
                    return (
                      <div className={`w-full flex mx-1 my-2 max-h-[96px] h-[96px] rounded-lg ${i === 0 ? "" : "mt-2"}`}>
                        {selectedJunction &&
                          sortedJunction?.find((e) => (e?.junctionId || e?.lossesId) === (selectedJunction?.junctionId || selectedJunction?.lossesId)) ? (
                          <>
                            {selectedJunction?.lossesId ? (
                              <>
                                {isToday ? (
                                  <div className="flex flex-row items-center gap-x-2 w-full">
                                    {todayLoading ? (
                                      <div className="w-[425px] min-w-[425px] rounded-xl bg-gray-200 animate-pulse h-[96px]" />
                                    ) : (
                                      <div className="min-w-[425px] w-[425px] max-w-[425px]">
                                        <OperationDetailSelectedLossCard data={selectedJunction} items={e} />
                                      </div>
                                    )}

                                    <div className="h-[90px] min-h-[90px]">
                                      <CloseButton
                                        onClick={() => {
                                          setStartTime(defaultStartTime);
                                          setEndTime(defaultEndTime);
                                          setSelectedDayType(1);
                                          setSelectedJunction(null);
                                        }}
                                        size={"sm"}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex flex-row items-center gap-x-2 w-full">
                                    <div className="min-w-[425px] w-[425px] max-w-[425px]">
                                      <OperationDetailSelectedLossEventCard data={selectedJunction} items={e} refetch={refetch} />
                                    </div>
                                    <div className="h-[90px] min-h-[90px]">
                                      <CloseButton
                                        onClick={() => {
                                          setStartTime(defaultStartTime);
                                          setEndTime(defaultEndTime);
                                          setSelectedDayType(1);
                                          setSelectedJunction(null);
                                        }}
                                        size={"sm"}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {isToday ? (
                                  <>
                                    {e?.activeJunction?.id === selectedJunction?.junctionId ? (
                                      <OperationDetailSelectedJunctionDoneCard
                                        data={selectedJunction}
                                        items={e?.activeJunction}
                                        todayCompleted={todayCompleted}
                                      />
                                    ) : (
                                      <div className="flex flex-row items-center gap-x-2 w-full">
                                        {todayLoading ? (
                                          <div className="w-[425px] min-w-[425px] rounded-xl bg-gray-200 animate-pulse h-[96px]" />
                                        ) : (
                                          <div className="w-[425px] min-w-[425px] max-w-[425px]">
                                            <OperationDetailTodaySelectedDoneEventCard
                                              data={todayCompleted}
                                              items={e?.activeJunction}
                                              operationName={data?.processName}
                                            />
                                          </div>
                                        )}

                                        <div className="h-[90px] min-h-[90px]">
                                          <CloseButton
                                            onClick={() => {
                                              setStartTime(defaultStartTime);
                                              setEndTime(defaultEndTime);
                                              setSelectedDayType(1);
                                              setSelectedJunction(null);
                                            }}
                                            size={"sm"}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className="flex flex-row items-center gap-x-2 w-full">
                                    <div className="w-[425px] min-w-[425px] max-w-[425px]">
                                      <OperationDetailSelectedDoneEventCard
                                        data={selectedJunction}
                                        items={e?.activeJunction}
                                        operationName={data?.processName}
                                      />
                                    </div>
                                    <div className="h-[90px] min-h-[90px]">
                                      <CloseButton
                                        onClick={() => {
                                          setStartTime(defaultStartTime);
                                          setEndTime(defaultEndTime);
                                          setSelectedDayType(1);
                                          setSelectedJunction(null);
                                        }}
                                        size={"sm"}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {valuesWithoutItemStyle?.length == 0 ? (
                              <>
                                {isToday ? (
                                  <OperationDetailPassiveCard data={e} refetch={refetch} processId={data?.processId} startDate={data?.startDate} />
                                ) : (
                                  <OperationDetailPreviousEquipmentCard data={sortedJunction[0]} items={e} />
                                )}
                              </>
                            ) : e?.activeJunction?.find((item) => item?.status == "active") === undefined ? (
                              <>
                                {sortedJunction[0]?.lossesId ? (
                                  <>
                                    {isToday ? (
                                      <OperationDetailLossCard data={sortedJunction[0]} items={e} />
                                    ) : (
                                      <OperationDetailPreviousEquipmentCard data={sortedJunction[0]} items={e} />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {isToday ? (
                                      <OperationDetailDoneCard  equipmentData={data?.data} data={sortedJunction[0]} items={e?.activeJunction} refetch={refetch} />
                                    ) : (
                                      <OperationDetailPreviousEquipmentCard data={sortedJunction[0]} items={e} />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <div className="cursor-pointer flex flex-row items-center w-full">
                                <OperationDetailActiveCard
                                  data={e?.activeJunction.find((a) => a.status == "active")}
                                  setJunctionType={setJunctionType}
                                  setFinishJunctionId={setFinishJunctionId}
                                  setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                                  refetch={refetch}
                                  setProductType={setProductType}
                                  equipmentData={data?.data}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              </div>
              <div className="w-full flex flex-col  relative px-3">
                {data?.data === undefined ? (
                  <div className="flex w-full items-center justify-center mt-5">
                    <span className="mr-[264px]">
                      <NoData theme={"machine"} header={t("label:noData")} button={false} />
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="w-full max-h-[40px] min-h-[40px] relative">
                      <TimeRange
                        // error={error}
                        ticksNumber={30}
                        selectedInterval={getStartOfDayForCompany(startTime, endTime)}
                        timelineInterval={getStartOfDayForCompany(defaultStartTime, defaultEndTime)}
                        onUpdateCallback={(interval) => {
                          "";
                        }}
                        onChangeCallback={(interval) => {
                          setStartTime(moment(interval[0]).valueOf());
                          setEndTime(moment(interval[1]).valueOf());
                        }}
                        disabledIntervals={[]}
                        step={1}
                      />
                    </div>

                    <svg className="mt-2" width={svgWidth} height={svgHeight + 145}>
                      {shiftValues?.map((shift, index) => {
                        const { startDate, endDate, color } = shift;
                        const shiftStartTime = new Date(timeFormatter(startDate)?.formatted)?.getTime();
                        const shiftEndTime = new Date(timeFormatter(endDate)?.formatted)?.getTime();

                        if (selectedShift !== null && selectedShift !== index) {
                          return null;
                        }

                        const timeRange = endTime - startTime;

                        const shiftX = selectedShift === index ? 0 : ((shiftStartTime - startTime) / timeRange) * svgWidth;
                        const shiftWidth = selectedShift === index ? svgWidth : ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;

                        return (
                          <>
                            <SingleEventRect
                              key={index}
                              startPos={shiftX}
                              width={shiftWidth}
                              fill={color}
                              onClick={() => handleShiftClick(shift)}
                              style={{ cursor: "pointer" }}
                              event={shift}
                              svgHeight={svgHeight}
                              handleMouseOut={handleMouseOut}
                            />

                            {shiftWidth > 15 ? (
                              <>
                                <defs>
                                  <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                                    <path
                                      d="M-1,1 l2,-2
                     M0,4 l4,-4
                     M3,5 l2,-2"
                                      style={{
                                        stroke: "#000000",
                                        strokeWidth: 1,
                                      }}
                                    />
                                  </pattern>
                                </defs>
                                <rect
                                  key={index}
                                  x={shiftX}
                                  y={30}
                                  width={shiftWidth}
                                  height={svgHeight + 110}
                                  fill={shift?.color === "#FFFFFF" ? "url(#diagonalHatch)" : shift?.color}
                                  opacity={0.2}
                                  onClick={() => handleShiftClick(shift)}
                                  // onMouseOver={() => handleMouseOver(event, startPos)}
                                  // onMouseOut={handleMouseOut}
                                  stroke={shift?.color}
                                  strokeWidth={1}
                                  style={{ cursor: "pointer" }}
                                  ry={"4"}
                                ></rect>
                              </>
                            ) : (
                              <>
                                <defs>
                                  <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                                    <path
                                      d="M-1,1 l2,-2
               M0,4 l4,-4
               M3,5 l2,-2"
                                      style={{
                                        stroke: "#000000",
                                        strokeWidth: 1,
                                      }}
                                    />
                                  </pattern>
                                </defs>
                                <rect
                                  key={index}
                                  x={shiftX}
                                  y={30}
                                  width={shiftWidth}
                                  height={svgHeight + 110}
                                  fill={shift?.color === "#FFFFFF" ? "url(#diagonalHatch)" : "#FFFFFF"}
                                  opacity={0.2}
                                  onClick={() => handleShiftClick(shift)}
                                  style={{ cursor: "pointer" }}
                                  ry={"4"}
                                ></rect>
                              </>
                            )}
                          </>
                        );
                      })}

                      {/* Status and junctions */}
                      {data?.data?.map((e, i) => {
                        return (
                          <OperationNodeTimelines
                            showTooltip={showTooltip}
                            setShowTooltip={setShowTooltip}
                            data={e?.timelineData}
                            nodeId={e?.nodeId}
                            deviceId={e?.deviceId}
                            endTime={endTime}
                            startTime={startTime}
                            setStartTime={setStartTime}
                            setEndTime={setEndTime}
                            selectedShift={selectedShift}
                            setSelectedShift={setSelectedShift}
                            svgWidth={svgWidth}
                            findJunctionIndexByIds={findJunctionIndexByIds}
                            handleMouseMove={handleMouseMove}
                            handleMouseOut={handleMouseOut}
                            handleShiftClick={handleShiftClick}
                            selectedJunction={selectedJunction}
                            setSelectedJunction={setSelectedJunction}
                            yOffset={(i + 1) * 112}
                            date={date}
                          />
                        );
                      })}
                    </svg>

                    {showTooltip && showTooltip?.index?.index == 0 ? (
                      <div
                        key={showTooltip?.index?.startDate}
                        style={{
                          right: totalWidthh - mouseX < 300 ? "55%" : "5%",
                          top: showTooltip.yPos - 160,
                        }}
                        className={`absolute z-50 bg-white p-2 w-[460px] min-w-[460px] max-w-[460px] rounded-lg shadow-md border-[1px]  `}
                      >
                        {moment(pageLoadTime).format("YYYY-MM-DDTHH:mm") === moment(showTooltip?.junction?.endDate).add(3, "hours").format("YYYY-MM-DDTHH:mm") ? (
                          <>
                            {showTooltip?.index?.junctionType === "losses" ? (
                              <OperationDetailTimelineLossCard data={showTooltip} items={data?.data} />
                            ) : (
                              <div className="flex min-w-[420px] max-w-[420px]">
                                <OperationDetailTimelineActiveCard data={showTooltip} findJunctionIndexByIds={findJunctionIndexByIds} items={data?.data}  equipmentData={data?.data}/>
                              </div>
                            )}
                          </>
                        ) : showTooltip?.index?.junctionType === "losses" ? (
                          <OperationDetailTimelineLossCard data={showTooltip} items={data?.data} />
                        ) : (
                          <div className="flex min-w-[420px] max-w-[420px]">
                            {isToday ? (
                              <OperationDetailTimelineActiveCard data={showTooltip} findJunctionIndexByIds={findJunctionIndexByIds} items={data?.data}  equipmentData={data?.data}/>
                            ) : (
                              <TimeLineDoneCard
                                data={showTooltip}
                                items={data?.data}
                                qualityDetail={data}
                                findJunctionIndexByIds={findJunctionIndexByIds}
                                todayCompleted={todayCompleted}
                                equipmentData={data?.data}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ) : showTooltip && showTooltip?.index?.index == 1 ? (
                      <div
                        key={showTooltip?.index?.startDate}
                        className={`absolute z-50 bg-white p-2  rounded-lg shadow-md `}
                        style={{
                          left: (showTooltip?.xPos + 30) * 2 > totalWidthh ? showTooltip?.xPos - 280 : showTooltip?.xPos + 40,
                          top: showTooltip?.yPos - 140,
                          borderTop: "5px solid " + showTooltip?.index?.color,
                        }}
                      >
                        <TimeLineTooltip showTooltip={showTooltip} />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            productType={productType}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
    </>
  );
};

export default OperationUpuTimeline;
