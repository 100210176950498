import { memo } from "react";

export const ChevronDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M5 7.5L10 12.5L15 7.5" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const TaskAdminGray = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M9.54844 0.0738277C9.52969 0.120703 9.37031 0.566015 9.1875 1.06289L8.85938 1.96758L7.62656 2.48789L6.39844 3.0082L5.44688 2.5582L4.5 2.1082L3.3 3.3082L2.09531 4.5082L2.49375 5.3332C2.71406 5.78789 2.91563 6.22383 2.93906 6.30351C2.98125 6.43008 2.93438 6.57539 2.475 7.66289L1.96406 8.87695L0.979688 9.22851L0 9.58008V11.291V13.002L0.975 13.3535L1.95 13.7051L2.47031 14.9379L2.98594 16.1707L2.54063 17.1176L2.09531 18.0645L3.3 19.2738L4.50938 20.4879L5.45625 20.0332C6.05625 19.7426 6.43125 19.5926 6.48281 19.6113C6.525 19.6254 7.08281 19.8598 7.71563 20.1316L8.87344 20.6285L9.22031 21.5941L9.5625 22.5645L11.2734 22.5785L12.9891 22.5879L13.3078 21.6879C13.4859 21.191 13.6453 20.7504 13.6688 20.7082C13.6875 20.666 14.2594 20.3988 14.9344 20.1082L16.1672 19.5832L16.6828 19.827C16.9688 19.9582 17.3953 20.1598 17.6297 20.2676L18.0563 20.4691L19.2609 19.2645L20.4656 18.0551L20.0156 17.1223C19.7672 16.6113 19.575 16.1566 19.5844 16.1191C19.5938 16.0816 19.8281 15.5191 20.1047 14.8723L20.6109 13.6957L21.5766 13.3488L22.5469 13.002L22.5609 11.291L22.5703 9.58008L21.5813 9.22851L20.5969 8.87695L20.0766 7.66289C19.6125 6.5707 19.5656 6.43476 19.6078 6.30351C19.6313 6.22383 19.8328 5.78789 20.0531 5.3332L20.4516 4.5082L19.2469 3.3082L18.0469 2.11289L17.1328 2.55351C16.6313 2.79726 16.1859 2.99414 16.1484 2.99414C16.1109 2.98945 15.5391 2.76445 14.8828 2.4832L13.6875 1.97695L13.3359 0.997265L12.9844 0.0175781L11.2828 0.00351524C9.69844 -0.00585938 9.58125 -0.00117302 9.54844 0.0738277ZM12.5859 5.3332C15.4875 6.00352 17.4656 8.56289 17.3531 11.502C17.2922 13.1379 16.6734 14.5254 15.4969 15.6645C14.625 16.5035 13.6547 17.0191 12.4453 17.277C11.8875 17.3941 10.6547 17.3895 10.1016 17.2723C8.82188 17.0004 7.78594 16.4285 6.90469 15.5191C5.81719 14.3941 5.25938 13.0957 5.19375 11.5254C5.10469 9.40195 6.09844 7.43789 7.875 6.22852C8.64375 5.7082 9.50625 5.38477 10.5469 5.22539C10.8938 5.17383 12.1969 5.23945 12.5859 5.3332Z"
        fill="#D0D5DD"
      />
      <path
        d="M10.4326 6.70625C10.0998 6.83281 9.89356 6.96406 9.67793 7.18906C8.77324 8.12187 9.05449 9.60781 10.2404 10.1703C10.5264 10.3062 10.5967 10.3203 11.0045 10.3203C11.4264 10.3203 11.4686 10.3109 11.8061 10.1422C13.2076 9.45312 13.2029 7.47969 11.7967 6.80937C11.5295 6.68281 11.4357 6.66406 11.0467 6.65469C10.7607 6.64531 10.5404 6.66406 10.4326 6.70625Z"
        fill="#D0D5DD"
      />
      <path
        d="M8.69824 11.1781C8.28574 11.4453 7.83574 11.8906 7.56855 12.2797C7.29667 12.6781 7.04824 13.2359 7.08105 13.3672C7.11855 13.5219 7.87792 14.2812 8.22949 14.5156C9.89824 15.6312 11.9982 15.6594 13.6576 14.5859C14.0982 14.3 14.8717 13.5547 14.9186 13.3672C14.9654 13.1703 14.5717 12.425 14.1451 11.9C13.9389 11.6515 13.3576 11.1734 13.0951 11.0422L12.9029 10.9437L12.5982 11.1078C12.0967 11.375 11.7404 11.4734 11.1639 11.5015C10.5029 11.5297 10.0857 11.4453 9.51386 11.164C9.27949 11.0469 9.07792 10.9531 9.06386 10.9531C9.05449 10.9531 8.88574 11.0562 8.69824 11.1781Z"
        fill="#D0D5DD"
      />
    </svg>
  );
};

export const TaskAdminGreen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M9.54844 0.0738277C9.52969 0.120703 9.37031 0.566015 9.1875 1.06289L8.85938 1.96758L7.62656 2.48789L6.39844 3.0082L5.44688 2.5582L4.5 2.1082L3.3 3.3082L2.09531 4.5082L2.49375 5.3332C2.71406 5.78789 2.91563 6.22383 2.93906 6.30351C2.98125 6.43008 2.93438 6.57539 2.475 7.66289L1.96406 8.87695L0.979688 9.22851L0 9.58008V11.291V13.002L0.975 13.3535L1.95 13.7051L2.47031 14.9379L2.98594 16.1707L2.54063 17.1176L2.09531 18.0645L3.3 19.2738L4.50938 20.4879L5.45625 20.0332C6.05625 19.7426 6.43125 19.5926 6.48281 19.6113C6.525 19.6254 7.08281 19.8598 7.71563 20.1316L8.87344 20.6285L9.22031 21.5941L9.5625 22.5645L11.2734 22.5785L12.9891 22.5879L13.3078 21.6879C13.4859 21.191 13.6453 20.7504 13.6688 20.7082C13.6875 20.666 14.2594 20.3988 14.9344 20.1082L16.1672 19.5832L16.6828 19.827C16.9688 19.9582 17.3953 20.1598 17.6297 20.2676L18.0563 20.4691L19.2609 19.2645L20.4656 18.0551L20.0156 17.1223C19.7672 16.6113 19.575 16.1566 19.5844 16.1191C19.5938 16.0816 19.8281 15.5191 20.1047 14.8723L20.6109 13.6957L21.5766 13.3488L22.5469 13.002L22.5609 11.291L22.5703 9.58008L21.5813 9.22851L20.5969 8.87695L20.0766 7.66289C19.6125 6.5707 19.5656 6.43476 19.6078 6.30351C19.6313 6.22383 19.8328 5.78789 20.0531 5.3332L20.4516 4.5082L19.2469 3.3082L18.0469 2.11289L17.1328 2.55351C16.6313 2.79726 16.1859 2.99414 16.1484 2.99414C16.1109 2.98945 15.5391 2.76445 14.8828 2.4832L13.6875 1.97695L13.3359 0.997265L12.9844 0.0175781L11.2828 0.00351524C9.69844 -0.00585938 9.58125 -0.00117302 9.54844 0.0738277ZM12.5859 5.3332C15.4875 6.00352 17.4656 8.56289 17.3531 11.502C17.2922 13.1379 16.6734 14.5254 15.4969 15.6645C14.625 16.5035 13.6547 17.0191 12.4453 17.277C11.8875 17.3941 10.6547 17.3895 10.1016 17.2723C8.82188 17.0004 7.78594 16.4285 6.90469 15.5191C5.81719 14.3941 5.25938 13.0957 5.19375 11.5254C5.10469 9.40195 6.09844 7.43789 7.875 6.22852C8.64375 5.7082 9.50625 5.38477 10.5469 5.22539C10.8938 5.17383 12.1969 5.23945 12.5859 5.3332Z"
        fill="#107569"
      />
      <path
        d="M10.4326 6.70625C10.0998 6.83281 9.89356 6.96406 9.67793 7.18906C8.77324 8.12187 9.05449 9.60781 10.2404 10.1703C10.5264 10.3062 10.5967 10.3203 11.0045 10.3203C11.4264 10.3203 11.4686 10.3109 11.8061 10.1422C13.2076 9.45312 13.2029 7.47969 11.7967 6.80937C11.5295 6.68281 11.4357 6.66406 11.0467 6.65469C10.7607 6.64531 10.5404 6.66406 10.4326 6.70625Z"
        fill="#107569"
      />
      <path
        d="M8.69824 11.1781C8.28574 11.4453 7.83574 11.8906 7.56855 12.2797C7.29667 12.6781 7.04824 13.2359 7.08105 13.3672C7.11855 13.5219 7.87792 14.2812 8.22949 14.5156C9.89824 15.6312 11.9982 15.6594 13.6576 14.5859C14.0982 14.3 14.8717 13.5547 14.9186 13.3672C14.9654 13.1703 14.5717 12.425 14.1451 11.9C13.9389 11.6515 13.3576 11.1734 13.0951 11.0422L12.9029 10.9437L12.5982 11.1078C12.0967 11.375 11.7404 11.4734 11.1639 11.5015C10.5029 11.5297 10.0857 11.4453 9.51386 11.164C9.27949 11.0469 9.07792 10.9531 9.06386 10.9531C9.05449 10.9531 8.88574 11.0562 8.69824 11.1781Z"
        fill="#107569"
      />
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path d="M11.6663 3.5L5.24967 9.91667L2.33301 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const UpuIoLogo = memo(() => {
  return (
    <svg width="104" height="32" viewBox="0 0 104 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9907 20.433C23.9907 21.9508 23.6917 23.4538 23.1109 24.856C22.53 26.2582 21.6786 27.5322 20.6054 28.6052C19.5321 29.6783 18.258 30.5293 16.8558 31.1097C15.4537 31.6902 13.9509 31.9886 12.4334 31.988V27.1C13.6342 27.1117 14.8179 26.8148 15.871 26.2376C16.8872 25.6817 17.7311 24.8571 18.3105 23.854C18.8909 22.8191 19.1958 21.6524 19.1958 20.4658C19.1958 19.2792 18.8909 18.1125 18.3105 17.0776C17.7319 16.0754 16.8876 15.2525 15.871 14.6998C14.8019 14.1372 13.6121 13.8432 12.404 13.8432C11.196 13.8432 10.0061 14.1372 8.93708 14.6998C7.92394 15.2494 7.08276 16.0688 6.50681 17.0673C6.10041 17.7687 5.83048 18.5407 5.7113 19.3426C3.95938 18.2164 2.51834 16.6682 1.52043 14.84C0.522515 13.0118 -0.000291558 10.9621 1.21982e-07 8.87918H12.4334C13.9509 8.87843 15.4536 9.17672 16.8558 9.75701C18.2579 10.3373 19.532 11.1882 20.6053 12.2612C21.6786 13.3341 22.53 14.6081 23.1108 16.0102C23.6917 17.4124 23.9907 18.9152 23.9907 20.433Z"
        fill="white"
      />
      <path d="M23.9889 10.6376V5.33897H32.8314L23.9889 10.6376Z" fill="white" />
      <path
        d="M23.9895 2.66949V5.33897H15.0999C14.7495 5.33943 14.4024 5.27072 14.0786 5.13679C13.7547 5.00285 13.4603 4.80631 13.2123 4.55839C12.9643 4.31048 12.7676 4.01605 12.6333 3.69194C12.499 3.36783 12.4297 3.02039 12.4296 2.66949V2.49082e-07H21.3191C21.6693 -0.000151224 22.0161 0.0687842 22.3396 0.202871C22.6632 0.336957 22.9572 0.533564 23.2048 0.781464C23.4525 1.02936 23.6489 1.3237 23.783 1.64765C23.917 1.9716 23.986 2.31883 23.986 2.66949"
        fill="white"
      />
      <path
        d="M14.6911 22.9421C14.0878 23.5728 13.3192 23.8908 12.3853 23.8962H12.3619C11.4168 23.8962 10.6404 23.5781 10.0327 22.9421C9.50309 22.382 9.18673 21.6452 9.14109 20.8656C9.12994 20.7385 9.12437 20.609 9.12437 20.475C9.12437 19.4671 9.42825 18.6452 10.036 18.0091C10.6438 17.3731 11.4202 17.0547 12.3652 17.0539C13.3103 17.0539 14.0867 17.3723 14.6944 18.0091C15.3022 18.6459 15.6061 19.4679 15.6061 20.475C15.6038 21.4852 15.2988 22.3076 14.6911 22.9421Z"
        fill="white"
      />
      <path
        d="M40.8442 27.8178C39.0684 27.8178 37.6477 27.3023 36.5593 26.2597C35.4709 25.2286 34.9324 23.8538 34.9324 22.1581V14.9059H38.3237V21.7686C38.3237 22.6622 38.5414 23.3611 38.9882 23.8652C39.435 24.3693 40.0537 24.6214 40.8328 24.6214C41.6233 24.6214 42.2305 24.3693 42.6659 23.8652C43.1013 23.3611 43.3189 22.6622 43.3189 21.78V14.9173H46.7102V22.1696C46.7102 23.8652 46.1717 25.2286 45.0948 26.2712C44.0522 27.3023 42.6201 27.8178 40.8442 27.8178Z"
        fill="white"
      />
      <path
        d="M61.9245 17.8049C61.3632 16.7967 60.5726 15.9947 59.5644 15.4218C58.5677 14.849 57.4449 14.5626 56.1961 14.5626C54.6379 14.5626 53.2975 15.0667 52.1632 16.0634V14.9177H49.3563V32H52.7475V26.7871C53.7786 27.486 54.9243 27.8411 56.1961 27.8411C57.4334 27.8411 58.5562 27.5547 59.5644 26.9819C60.5726 26.409 61.3517 25.607 61.9245 24.5988C62.4859 23.5792 62.7724 22.4449 62.7724 21.1961C62.7724 19.9473 62.4859 18.8131 61.9245 17.8049ZM58.3614 23.6594C57.7198 24.3124 56.8949 24.6332 55.9211 24.6332C54.9473 24.6332 54.1224 24.3124 53.4808 23.6479C52.8392 22.9949 52.5069 22.17 52.5069 21.1847C52.5069 20.1994 52.8277 19.3859 53.4808 18.7329C54.1224 18.0798 54.9473 17.7476 55.9211 17.7476C56.8949 17.7476 57.7198 18.0684 58.3614 18.7214C59.0145 19.3745 59.3353 20.1879 59.3353 21.1847C59.3353 22.1814 59.0145 23.0063 58.3614 23.6594Z"
        fill="white"
      />
      <path
        d="M70.4703 27.8178C68.6945 27.8178 67.2738 27.3023 66.1854 26.2597C65.097 25.2286 64.5585 23.8538 64.5585 22.1581V14.9059H67.9498V21.7686C67.9498 22.6622 68.1674 23.3611 68.6143 23.8652C69.0611 24.3693 69.6798 24.6214 70.4588 24.6214C71.2494 24.6214 71.8566 24.3693 72.292 23.8652C72.7273 23.3611 72.945 22.6622 72.945 21.78V14.9173H76.3363V22.1696C76.3363 23.8652 75.7978 25.2286 74.7208 26.2712C73.6668 27.3023 72.2461 27.8178 70.4703 27.8178Z"
        fill="white"
      />
      <path d="M82.2626 23.8424H78.6766V27.4743H82.2626V23.8424Z" fill="white" />
      <path d="M84.8151 10.0943H88.1949V13.2449H84.8151V10.0943ZM84.8151 14.9177H88.2063V27.4744H84.8151V14.9177Z" fill="white" />
      <path
        d="M96.9393 27.7813C95.6561 27.7813 94.499 27.4948 93.4678 26.922C92.4367 26.3491 91.6233 25.5472 91.039 24.5389C90.4547 23.5307 90.1568 22.3965 90.1568 21.1592C90.1568 19.8989 90.4547 18.7647 91.039 17.7564C91.6233 16.7482 92.4367 15.9577 93.4678 15.3849C94.499 14.812 95.6561 14.5256 96.9393 14.5256C98.211 14.5256 99.3682 14.812 100.399 15.3849C101.43 15.9577 102.244 16.7482 102.84 17.7679C103.435 18.7876 103.722 19.9103 103.722 21.1592C103.722 22.3965 103.424 23.5307 102.84 24.5389C102.244 25.5586 101.442 26.3491 100.399 26.922C99.3567 27.4948 98.211 27.7813 96.9393 27.7813ZM94.5333 23.6338C95.1635 24.2754 95.9655 24.5962 96.9393 24.5962C97.9131 24.5962 98.7151 24.2754 99.3453 23.6338C99.9754 22.9923 100.285 22.1674 100.285 21.1592C100.285 20.1509 99.9754 19.3146 99.3453 18.6845C98.7151 18.0429 97.9131 17.7221 96.9393 17.7221C95.9655 17.7221 95.1635 18.0429 94.5333 18.6845C93.9032 19.326 93.5939 20.1509 93.5939 21.1592C93.5824 22.1674 93.9032 22.9923 94.5333 23.6338Z"
        fill="white"
      />
      <path
        d="M102.053 11.0154H102.576C102.651 11.0144 102.726 11.0318 102.793 11.0661C102.855 11.0972 102.906 11.1451 102.942 11.2043C102.978 11.2668 102.996 11.3381 102.995 11.4104C102.996 11.4637 102.986 11.5166 102.965 11.5658C102.944 11.6098 102.915 11.649 102.879 11.681C102.841 11.7146 102.797 11.7404 102.75 11.757L103.024 12.2176H102.769L102.53 11.7881H102.275V12.2176H102.053V11.0154ZM102.568 11.6108C102.605 11.6109 102.641 11.6022 102.674 11.5854C102.704 11.5692 102.73 11.5449 102.747 11.5152C102.765 11.4831 102.774 11.447 102.774 11.4104C102.774 11.3754 102.765 11.3411 102.746 11.3114C102.728 11.2812 102.702 11.2566 102.671 11.24C102.639 11.2223 102.603 11.2132 102.567 11.2135H102.275V11.6108H102.568Z"
        fill="white"
      />
      <path
        d="M102.494 12.8252C102.258 12.8252 102.026 12.755 101.829 12.6234C101.632 12.4918 101.479 12.3048 101.388 12.0859C101.297 11.8671 101.274 11.6263 101.32 11.394C101.366 11.1617 101.48 10.9483 101.648 10.7808C101.815 10.6133 102.028 10.4993 102.261 10.4531C102.493 10.4068 102.734 10.4306 102.953 10.5212C103.172 10.6118 103.359 10.7653 103.49 10.9623C103.622 11.1592 103.692 11.3908 103.692 11.6276C103.692 11.9453 103.566 12.2499 103.341 12.4745C103.117 12.6991 102.812 12.8252 102.494 12.8252ZM102.494 10.6316C102.297 10.6316 102.105 10.69 101.941 10.7994C101.777 10.9089 101.65 11.0644 101.574 11.2465C101.499 11.4285 101.479 11.6287 101.517 11.822C101.556 12.0152 101.651 12.1927 101.79 12.332C101.929 12.4713 102.107 12.5661 102.3 12.6046C102.493 12.643 102.694 12.6233 102.876 12.5479C103.058 12.4725 103.213 12.3448 103.323 12.181C103.432 12.0172 103.49 11.8246 103.49 11.6276C103.49 11.3635 103.385 11.1104 103.198 10.9236C103.012 10.7369 102.758 10.6319 102.494 10.6316Z"
        fill="white"
      />
    </svg>
  );
});

export const OrderIconLegacy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6667 8.74984V5.6665C16.6667 4.26637 16.6667 3.56631 16.3942 3.03153C16.1545 2.56112 15.772 2.17867 15.3016 1.93899C14.7669 1.6665 14.0668 1.6665 12.6667 1.6665H7.33333C5.9332 1.6665 5.23313 1.6665 4.69835 1.93899C4.22795 2.17867 3.8455 2.56112 3.60581 3.03153C3.33333 3.56631 3.33333 4.26637 3.33333 5.6665V14.3332C3.33333 15.7333 3.33333 16.4334 3.60581 16.9681C3.8455 17.4386 4.22795 17.821 4.69835 18.0607C5.23313 18.3332 5.9332 18.3332 7.33333 18.3332M9.99999 7.49984H6.66666M11.6667 4.99984H6.66666M8.33333 9.99984H6.66666M9.83707 12.6319L12.8556 10.9452C12.9977 10.8658 13.0688 10.8261 13.1441 10.8105C13.2107 10.7967 13.2795 10.7967 13.3462 10.8103C13.4215 10.8258 13.4926 10.8654 13.6349 10.9446L16.6667 12.6319M9.83707 12.6319L13.245 14.5309M9.83707 12.6319V15.9547C9.83707 16.1253 9.83707 16.2106 9.86214 16.2868C9.88432 16.3541 9.92058 16.416 9.96851 16.4683C10.0227 16.5274 10.0971 16.5691 10.246 16.6525L13.245 18.3332M16.6667 12.6319L13.245 14.5309M16.6667 12.6319V15.9537C16.6667 16.1247 16.6667 16.2102 16.6415 16.2864C16.6193 16.3539 16.5829 16.4159 16.5348 16.4682C16.4805 16.5273 16.4058 16.569 16.2566 16.6523L13.245 18.3332M13.245 14.5309V18.3332"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CalendarIconLegacy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 8.33317H2.5M13.3333 1.6665V4.99984M6.66667 1.6665V4.99984M6.5 18.3332H13.5C14.9001 18.3332 15.6002 18.3332 16.135 18.0607C16.6054 17.821 16.9878 17.4386 17.2275 16.9681C17.5 16.4334 17.5 15.7333 17.5 14.3332V7.33317C17.5 5.93304 17.5 5.23297 17.2275 4.69819C16.9878 4.22779 16.6054 3.84534 16.135 3.60565C15.6002 3.33317 14.9001 3.33317 13.5 3.33317H6.5C5.09987 3.33317 4.3998 3.33317 3.86502 3.60565C3.39462 3.84534 3.01217 4.22779 2.77248 4.69819C2.5 5.23297 2.5 5.93304 2.5 7.33317V14.3332C2.5 15.7333 2.5 16.4334 2.77248 16.9681C3.01217 17.4386 3.39462 17.821 3.86502 18.0607C4.3998 18.3332 5.09987 18.3332 6.5 18.3332Z"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
