import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import GroupChatDetails from "./GroupChatDetails";
import { useStore } from "../../../hooks/useStores";
import { usePubNub } from "pubnub-react";
import MessageList from "../components/MessageList";
import { useQuery } from "@tanstack/react-query";
import { chatService } from "../../../services/chat.service";
import NoData from "../../../steps/step-navigation/components/NoData";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../assets/icons/stepsIcons";
import useWindowDimensions from "../../../hooks/useWindowDimension";

const Message = observer(() => {
  const { t } = useTranslation();
  const { chat } = useStore();
  const { width } = useWindowDimensions();
  const { conversationId } = useParams();
  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["getConversationChannels"],
    queryFn: async () => await chatService.getConversationChannels(),
  });
  const pubnub = usePubNub();
  const handleMessageList = () => {
    const channels = data?.map((d) => d?.conversationId);
    pubnub.fetchMessages(
      {
        channels: channels,
        count: 1,
      },
      function (status, response) {
        if (!status.error) {
          chat.setMessageList(filteredConversations(response.channels));
        } else {
          chat.setMessageList(
            data?.map((d) => {
              return {
                ...d,
                lastMessage: "",
                unreadCount: d.unreadCount || 0,
              };
            })
          );
        }
      }
    );

    const filteredConversations = (channels) => {
      const mapper = data?.map((item) => {
        if (Object.keys(channels).includes(item.conversationId)) {
          return {
            ...item,
            lastMessage: channels[item.conversationId][0],
          };
        } else {
          return {
            ...item,
            lastMessage: {
              message: t("person:clickSendMessage"),
            },
          };
        }
      });
      return mapper;
    };
  };

  useEffect(() => {
    refetch();
  }, [conversationId]);


  useEffect(() => {
    if (!isLoading) {
      handleMessageList();
    }
  }, [conversationId, data]);

  return (
    <>
      <div className="w-full h-full flex border border-secondary-200 shadow-md bg-white rounded-xl sm:hidden xs:hidden">
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Loader currentColor="#0E9384" currentFill="currentFill" />
          </div>
        ) : (
          <>
            {width < 1024 && width > 768 ? (
              <>
                {conversationId ? (
                  <div className={"flex flex-1"}>
                    {chat.currentGroupChatInfoId && width < 1023 ? (
                      <GroupChatDetails
                        groupId={chat.currentGroupChatInfoId}
                        onClose={() => chat.setCurrentGroupChatId(null)}
                        newRefetch={refetch}
                      />
                    ) : (
                      <Outlet />
                    )}
                  </div>
                ) : (
                  <div className="flex-1 h-full flex items-center justify-center">
                    <NoData
                      button={false}
                      theme={"person"}
                      header={t("chat:message")}
                      text={t("person:selectItem")}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {conversationId ? (
                  <div className="flex flex-1">
                    {chat.currentGroupChatInfoId && (
                      <GroupChatDetails
                        groupId={chat.currentGroupChatInfoId}
                        onClose={() => chat.setCurrentGroupChatId(null)}
                        newRefetch={refetch}
                      />
                    )}
                    <Outlet />
                  </div>
                ) : (
                  <div className="flex-1 h-full flex items-center justify-center">
                    <NoData
                      button={false}
                      theme={"person"}
                      header={t("chat:message")}
                      text={t("person:selectItem")}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        <MessageList
          isLoading={isLoading}
          refetch={refetch}
          isFetching={isFetching}
        />
      </div>
      {/*  sm view */}
      <div className="hidden sm:flex w-full h-full  border border-secondary-200 shadow-md bg-white rounded-xl">
        {conversationId ? (
          <div className="flex flex-1">
            {chat.currentGroupChatInfoId ? (
              <GroupChatDetails
                groupId={chat.currentGroupChatInfoId}
                onClose={() => chat.setCurrentGroupChatId(null)}
                newRefetch={refetch}
              />
            ) : (
              <Outlet />
            )}
          </div>
        ) : (
          <MessageList
            isLoading={isLoading}
            refetch={refetch}
            isFetching={isFetching}
          />
        )}
      </div>
      {/*  mobile view */}
      <div className="hidden xs:flex w-full h-full  border border-secondary-200 shadow-md bg-white rounded-xl">
        {conversationId ? (
          <div className="flex flex-1">
            {chat.currentGroupChatInfoId ? (
              <GroupChatDetails
                groupId={chat.currentGroupChatInfoId}
                onClose={() => chat.setCurrentGroupChatId(null)}
                newRefetch={refetch}
              />
            ) : (
              <Outlet />
            )}
          </div>
        ) : (
          <MessageList
            isLoading={isLoading}
            refetch={refetch}
            isFetching={isFetching}
          />
        )}
      </div>
    </>
  );
});

export default Message;
