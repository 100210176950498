import React from 'react'
import CustomImage from '../CustomImage';
import { StockIcons } from './material-supply/OperationDetailQualityAppliedCard';
import NoAvatar from '../avatar/NoAvatar';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import moment from "moment";

const StockWorkOrderQualityEventCard = ({data}) => {
    const {t} = useTranslation()
  return (
    <div className="flex w-full flex-row justify-center items-center group relative cursor-grab">
        <div className={`flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 mr-auto`}>
          <div className={`w-full border-t-4 rounded-t-[8px] border-t-success-500`}></div>
          <div className={`flex w-full border-b h-[55px]`}>
            {(
              data?.purchaseRequest?.rawMaterial
                ? data?.purchaseRequest?.rawMaterial?.image
                : data?.purchaseRequest?.productInternalOperation
                  ? data?.purchaseRequest?.productInternalOperation?.product?.image
                  : data?.purchaseRequest?.productExternalOperation
                    ? data?.purchaseRequest?.productExternalOperation?.product?.image
                    : data?.purchaseRequest?.product
                      ? data?.purchaseRequest?.product?.image
                      : "--"
            ) ? (
              <div data-tooltip-id={`${data?.id}`}
                data-tooltip-place="right"
                data-tooltip-content={`${t("product:productName")}: ${data?.purchaseRequest?.rawMaterial
                  ? data?.purchaseRequest?.rawMaterial?.name || "--"
                  : data?.purchaseRequest?.productInternalOperation
                    ? data?.purchaseRequest?.productInternalOperation?.product?.name || "--"
                    : data?.purchaseRequest?.productExternalOperation
                      ? data?.purchaseRequest?.productExternalOperation?.product?.name || "--"
                      : data?.purchaseRequest?.product
                        ? data?.purchaseRequest?.product?.name || "--"
                        : "--"} - ${t("product:stock")}: ${data?.purchaseRequest?.rawMaterial
                          ? data?.purchaseRequest?.rawMaterial?.stockCode || "--"
                          : data?.purchaseRequest?.productInternalOperation
                            ? `${data?.purchaseRequest?.productInternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productInternalOperation?.step}.Op)`
                            : data?.purchaseRequest?.productExternalOperation
                              ? `${data?.purchaseRequest?.productExternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productExternalOperation?.step || "--"}.Op)`
                              : data?.purchaseRequest?.product
                                ? data?.purchaseRequest?.product?.stockCode || "--"
                                : "--"}`}>
                <CustomImage
                  src={
                    data?.purchaseRequest?.rawMaterial
                      ? data?.purchaseRequest?.rawMaterial?.image
                      : data?.purchaseRequest?.productInternalOperation
                        ? data?.purchaseRequest?.productInternalOperation?.product?.image
                        : data?.purchaseRequest?.productExternalOperation
                          ? data?.purchaseRequest?.productExternalOperation?.product?.image
                          : data?.purchaseRequest?.product
                            ? data?.purchaseRequest?.product?.image
                            : "--"
                  }
                  style={{
                    width: 72,
                    height: 54,
                    minWidth: 72,
                    minHeight: 54,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRadius: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-place="right"
                data-tooltip-content={`${t("product:productName")}: ${data?.purchaseRequest?.rawMaterial
                  ? data?.purchaseRequest?.rawMaterial?.name || "--"
                  : data?.purchaseRequest?.productInternalOperation
                    ? data?.purchaseRequest?.productInternalOperation?.product?.name || "--"
                    : data?.purchaseRequest?.productExternalOperation
                      ? data?.purchaseRequest?.productExternalOperation?.product?.name || "--"
                      : data?.purchaseRequest?.product
                        ? data?.purchaseRequest?.product?.name || "--"
                        : "--"} - ${t("product:stock")}: ${data?.purchaseRequest?.rawMaterial
                          ? data?.purchaseRequest?.rawMaterial?.stockCode || "--"
                          : data?.purchaseRequest?.productInternalOperation
                            ? `${data?.purchaseRequest?.productInternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productInternalOperation?.step}.Op)`
                            : data?.purchaseRequest?.productExternalOperation
                              ? `${data?.purchaseRequest?.productExternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productExternalOperation?.step || "--"}.Op)`
                              : data?.purchaseRequest?.product
                                ? data?.purchaseRequest?.product?.stockCode || "--"
                                : "--"}`}
                className="w-[72px] min-w-[72px] h-[54px] min-h-[54px]  flex border-r  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">

                {data?.purchaseRequest?.rawMaterial
                  ? data?.purchaseRequest?.rawMaterial?.name.slice(0, 2)?.toUpperCase()
                  : data?.purchaseRequest?.productInternalOperation
                    ? data?.purchaseRequest?.productInternalOperation?.product?.name.slice(0, 2)?.toUpperCase()
                    : data?.purchaseRequest?.productExternalOperation
                      ? data?.purchaseRequest?.productExternalOperation?.product?.name.slice(0, 2)?.toUpperCase()
                      : data?.purchaseRequest?.product
                        ? data?.purchaseRequest?.product?.name.slice(0, 2)?.toUpperCase()
                        : "--"}
              </div>
            )}
            <div className="flex flex-col w-full min-h-full">
              <div className="flex w-full h-1/2 border-b px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="text-xxs">{t("product:approveds")}</p>
                <p className="font-medium text-xs text-[#079455]">{data?.wipUnit || 0}</p>
              </div>
              <div className="flex w-full h-1/2 px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="text-xxs">{t("product:returns")}</p>
                <p className="font-medium text-xs text-[#D92D20]">{data?.rejectedUnit || 0}</p>
              </div>
            </div>
          </div>

          <div className={`flex flex-col items-start w-full pl-3 pr-[44px] gap-y-[2px]  py-2`}>
            <p className="text-xs font-normal text-[#667085]  truncate w-[99%]">
              {t("product:stock")}: {" "}
              {data?.purchaseRequest?.rawMaterial
                ? data?.purchaseRequest?.rawMaterial?.stockCode || "--"
                : data?.purchaseRequest?.productInternalOperation
                  ? `${data?.purchaseRequest?.productInternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productInternalOperation?.step}.Op)`
                  : data?.purchaseRequest?.productExternalOperation
                    ? `${data?.purchaseRequest?.productExternalOperation?.product?.stockCode || "--"} (${data?.purchaseRequest?.productExternalOperation?.step || "--"}.Op)`
                    : data?.purchaseRequest?.product
                      ? data?.purchaseRequest?.product?.stockCode || "--"
                      : "--"}
            </p>
            <p className="text-xs font-semibold text-[#344054] truncate w-[99%]">
              {data?.purchaseRequest?.rawMaterial
                ? data?.purchaseRequest?.rawMaterial?.name || "--"
                : data?.purchaseRequest?.productInternalOperation
                  ? data?.purchaseRequest?.productInternalOperation?.product?.name || "--"
                  : data?.purchaseRequest?.productExternalOperation
                    ? data?.purchaseRequest?.productExternalOperation?.product?.name || "--"
                    : data?.purchaseRequest?.product
                      ? data?.purchaseRequest?.product?.name || "--"
                      : "--"}
            </p>
          </div>
        </div>

        <div className="cursor-pointer flex absolute w-[50px] h-[50px] min-w-[50px] min-h-[50px] z-[100] bottom-1">
          {data?.responsibleUser?.avatar ? (
            <div className="cursor-pointer">
              <CustomImage
                src={data?.responsibleUser?.avatar}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                  borderWidth: 2,
                  borderStyle: "solid",
                  boxShadow: "0 0 0 2px #00000008",
                }}
              />
            </div>
          ) : (
            <div className="cursor-pointer">
              <NoAvatar size={48} fontSize={22} name={data?.responsibleUser?.name} lastName={data?.responsibleUser?.lastName} color={"#B54708"} />
            </div>
          )}
        </div>
        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 ml-auto">
          <div className={`w-full border-t-4 rounded-t-[8px] border-t-success-500`}></div>
          <div className={`flex w-full border-b justify-end h-[55px]`}>
            <div className="flex flex-col w-full min-h-full">
              <div className="flex w-full h-1/2 border-b px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="font-medium text-xs">{moment(data?.createdDate).format("DD.MM.YYYY") || "--"}</p>
                <p className="text-xxs">{t("product:deliverys")}</p>
              </div>
              <div className="flex w-full h-1/2 px-1 py-[3px] justify-between items-center text-[#475467]">
                <p className="font-medium text-xs">{moment(data?.qualityControlDate ? data?.qualityControlDate : new Date()).format("DD.MM.YYYY") || "--"}</p>
                <p className="text-xxs">{t("product:control")}</p>
              </div>
            </div>

            {data?.purchaseRequest?.source === "stock" ? (
              <div className="w-[72px] min-w-[72px] h-[54px] min-h-[54px]  flex border-r  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                <StockIcons />
              </div>
            ) : (
              <>
                {data?.supplier?.image ? (
                  <div>
                    <CustomImage
                      src={data?.supplier?.image}
                      style={{
                        width: 72,
                        height: 54,
                        minWidth: 72,
                        minHeight: 54,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                        borderRadius: 0,
                        borderLeftWidth: 1,
                        borderRightWidth: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[72px] min-w-[72px] h-[54px] min-h-[54px]  flex border-r  items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                    {data?.supplier?.name.slice(0, 2)?.toUpperCase()}
                  </div>
                )}
              </>
            )}
          </div>

          <div className={`flex flex-col items-end w-full pr-3 pl-[44px] gap-y-[2px] py-2`}>
            {data?.purchaseRequest?.source === "stock" ? (
              <p className="text-xs text-[#667085]">--</p>
            ) : (
              <p className="text-xs text-[#667085]">MS-{data?.plannedJunctionRef?.msNo || "--"}</p>
            )}

            <p className="text-xs font-semibold text-end text-[#344054] truncate w-[99%]">
              {data?.purchaseRequest?.source === "stock" ? (
                <>
                 {t("product:fromStoks")}
                </>
              ) : (
                <>
                  {data?.supplier?.name || "--"}
                </>
              )}
            </p>
          </div>
        </div>
       
      
        <Tooltip className="z-[120]" id={`${data?.id}`} />
      </div>
  )
}

export default StockWorkOrderQualityEventCard