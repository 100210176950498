import React from "react";
import { CheckIcon, TaskAdminGray, TaskAdminGreen } from "../../../assets/icons/modalIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { observer } from "mobx-react-lite";

const PersonalItem = observer(({ item, isAdmin, isSelected, triggerAdmin, triggerUser, chat }) => {
  return (
    <>
      <div className="flex flex-col items-start gap-4 select-none">
        <div
          onClick={() => {
            isSelected ? triggerAdmin(item) : triggerUser(item);
            chat?.setCurrentChatUser(`${item?.name} ${item?.lastName}`, item?.avatar);
          }}
          className="flex flex-row items-center justify-center gap-3 p-0 w-full h-16"
        >
          <div className="w-full flex flex-row items-center gap-3 p-0 h-full">
            {isSelected ? (
              <div className="flex flex-row items-center gap-3 w-full justify-between">
                <div
                  onClick={() => {
                    triggerUser(item);
                    chat?.setCurrentChatUser(`${item?.name} ${item?.lastName}`, item?.avatar);
                  }}
                  className={`cursor-pointer flex w-4 h-4 rounded items-center justify-center bg-[#0E9384] text-[#FFF]`}
                >
                  <CheckIcon />
                </div>
                <div className="flex flex-row items-center gap-3">
                  <div>
                    {item?.avatar ? (
                      <img src={generateFileUrl(item?.avatar)} className="w-10 h-10 rounded-full border-[0.75px] border-solid box-border border-[#EAECF0]" />
                    ) : (
                      <div className="w-10 h-10 border-4 border-teal-500 bg-teal-100 text-teal-700 rounded-full flex items-center justify-center text-md">
                        {item?.name?.charAt(0).toUpperCase() + "" + item?.lastName?.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-center items-start gap-1 p-0  h-12">
                    <p className="text-sm font-semibold text-[#344054]">
                      {item?.name} {item?.lastName}
                    </p>
                    <p className="text-xs font-normal text-[#344054]">{item?.metadata?.department?.name}</p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    triggerAdmin(item);
                  }}
                  className="cursor-pointer flex items-center justify-center ml-auto w-6 h-6"
                >
                  {isAdmin ? <TaskAdminGreen /> : <TaskAdminGray />}
                </div>
              </div>
            ) : (
              <div className="w-full flex items-center gap-3">
                <div
                  onClick={() => {
                    triggerUser(item);
                    chat?.setCurrentChatUser(`${item?.name} ${item?.lastName}`, item?.avatar);
                  }}
                  className="cursor-pointer w-4 h-4 border border-[#D0D5DD] rounded items-center justify-center bg-[#fff]"
                ></div>
                <div className="flex flex-row items-center gap-3">
                  <div>
                    {item?.avatar ? (
                      <img src={generateFileUrl(item?.avatar)} className="w-10 h-10 rounded-full border-[0.75px] border-solid box-border border-[#EAECF0]" />
                    ) : (
                      <div className="w-10 h-10 border-4 border-teal-500 bg-teal-100 text-teal-700 rounded-full flex items-center justify-center text-md">
                        {item?.name?.charAt(0).toUpperCase() + "" + item?.lastName?.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-center items-start gap-1 p-0  h-12">
                    <p className="text-sm font-semibold text-[#344054]">
                      {item?.name} {item?.lastName}
                    </p>
                    <p className="text-xs font-normal text-[#344054]">{item?.metadata?.department?.name}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default PersonalItem;
