import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import { useStore } from "../../hooks/useStores";
import { PlusIcon } from "../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import SearchInput from "../../components/inputs/SearchInput";
import diacritics from "diacritics";
import { SearchIcon } from "../../assets/icons/PersonIcons";
import { ScrollChevronIcon } from "../../assets/icons/productIcons";
import { flexProductService } from "../../services/flex-product.service";
import NoData from "../../steps/step-navigation/components/NoData";
import FlexRawMaterialCard from "./cards/FlexRawMaterialCard";

const FlexProductRawMaterialList = () => {
  const { auth } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [responsiveSearch, setResponsiveSearch] = useState(false);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["flex-raw-material-list"],
    queryFn: async () => await flexProductService.getFlexRawMaterialList(),
    retry: 0,
  });

  const tableHeadItems = [
    { name: t("product:rawMaterialss"), value: "image", type: "" },
    { name: t("product:rawMaterialType"), value: "name", type: "string" },
    { name: t("product:associatedGroup"), value: "lastName", type: "string" },
    { name: t("product:unitPriceAndUnit"), value: "rolesKey", type: "string" },
    { name: t("product:description"), value: "talents", type: "number" },
    { name: "", value: "detail", type: "string" },
  ];

  const handleSearch = () => {
    setResponsiveSearch(!responsiveSearch);
  };

  const filteredData = data?.data?.data?.length
    ? data?.data?.data?.filter(
        (raw) =>
          diacritics.remove(`${raw?.stockCode}`).toLowerCase().includes(diacritics.remove(searchQuery.toLowerCase())) ||
          diacritics.remove(`${raw?.type}`).toLowerCase().includes(diacritics.remove(searchQuery.toLowerCase())) ||
          diacritics.remove(`${raw?.name}`).toLowerCase().includes(diacritics.remove(searchQuery.toLowerCase())) ||
          diacritics.remove(`${raw?.supplierGroup?.name}`).toLowerCase().includes(diacritics.remove(searchQuery.toLowerCase()))
      )
    : [];

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };

  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "10826fc4-bf01-4164-acf0-72bb213e88d3") {
      } else if (auth?.user?.company?.id == "b0d47c1a-e5dd-4ec1-a655-424af970bbfe") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);

  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
        <Button
          colorType={"tertiary-gray"}
          onClick={() => {
            navigate(-1);
          }}
          size={"sm"}
          iconLeft={
            <div className="rotate-180">
              <TableArrowLeft />
            </div>
          }
        />

        <div className="flex items-center">
          <span className="text-2xl text-secondary-900 font-semibold ml-2">{t("product:flexRawMaterialList")}</span>
        </div>
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <div className="w-[320px] max-w-[320px] min-w-[320px] sm:hidden md:hidden">
            <SearchInput setSearchVal={setSearchQuery} theme={"product"} />
          </div>
          <div className="hidden sm:flex md:flex">
            <Button onClick={handleSearch} size="md" iconLeft={<SearchIcon />} colorType={"secondary-gray"} />
          </div>
          <Button
            colorType={"primary-product"}
            size={"md"}
            label={t("product:createFlexRawMaterial")}
            iconLeft={<PlusIcon />}
            onClick={() => navigate("/app/product/mes/flex/create/raw-material")}
          />
        </div>
      </div>
      {responsiveSearch && (
        <div className="w-full hidden sm:flex md:flex pb-4 px-1">
          <SearchInput setSearchVal={setSearchQuery} />
        </div>
      )}

      <div className="flex-1 w-full mt-2 border rounded-xl flex flex-col overflow-hidden relative sm:px-11">
        <div
          className={`w-11 h-full hidden sm:flex ${
            isRightArrowHidden ? "hidden duration-1000" : ""
          } z-[999] right-0 cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-gray-50`}
          onClick={() => {
            sideScroll(document.getElementById("content-wrapper"), 25, 100, 20);
          }}
        >
          <ScrollChevronIcon />
        </div>

        <div
          className={`w-11 h-full hidden sm:flex ${
            isLeftArrowHidden ? "hidden duration-1000" : ""
          } z-[999] left-0 absolute cursor-pointer flex rotate-180 items-center justify-center bg-gray-50 group`}
          onClick={() => {
            sideScroll(document.getElementById("content-wrapper"), 25, 100, -20);
          }}
        >
          <ScrollChevronIcon />
        </div>

        <div id="content-wrapper" className="min-w-full max-w-full w-full h-[86vh] rounded-xl flex flex-col">
          {filteredData?.length <= 0 ? (
            <div className="flex w-full h-full justify-center items-center">
              <NoData header={t("label:noData")} button={false} />
            </div>
          ) : (
            <>
              <div className="flex max-h-[44px] min-h-[44px] max-w-full min-w-full">
                {tableHeadItems?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      scope="col"
                      className={` px-4 py-3 ${
                        i === 0
                          ? "w-[22%] md:w-[43%] md:min-w-[43%]"
                          : i === 1
                          ? "w-[11%] md:w-[11%] md:min-w-[11%]"
                          : i === 2
                          ? "w-[15%] md:w-[15%] md:min-w-[15%]"
                          : i === 3
                          ? "w-[11%] md:w-[12%] md:min-w-[12%]"
                          : i === 4
                          ? "w-[35%] md:w-[14%] md:min-w-[14%]"
                          : i === 5
                          ? "w-[6%] md:w-[5%] md:min-w-[5%]"
                          : ""
                      } items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                    >
                      <span className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}>
                        {item?.name}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="w-full bg-[#FFF] flex-1 flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide">
                {filteredData?.map((item, key) => {
                  const currencyIcon = () => {
                    switch ((item?.currency).toLowerCase()) {
                      case "usd":
                        return "$";
                      case "eur":
                        return "€";
                      case "gbp":
                        return "£";
                      case "rub":
                        return "₽";
                      case "uah":
                        return "₴";
                      case "try":
                        return "₺";
                      default:
                        return "₺";
                    }
                  };
                  return (
                    <div key={key} className={`flex w-full items-center border-b h-[128px] min-h-[128px] `}>
                      <div className=" border-r h-full px-3 py-2 flex items-center w-[22%] md:w-[43%] md:min-w-[43%]">
                        <FlexRawMaterialCard item={item} refetch={refetch} />
                      </div>
                      <div className=" border-r h-full p-4 flex items-center justify-center w-[11%] md:w-[11%] md:min-w-[11%]">
                        <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-[#344054] dark:text-[#F5F5F5] font-medium text-center">
                          {item?.type === "lama" ? "Lama" : item?.type === "stick" ? "Çubuk" : item?.type === "pipe" ? "Boru" : "---"}
                        </p>
                      </div>
                      <div className="border-r h-full p-4 flex items-center justify-center w-[15%] md:w-[15%] md:min-w-[15%]">
                        <p className="w-[90%] line-clamp-4 text-sm text-[#344054] dark:text-[#F5F5F5] font-medium text-center">
                          {item?.supplierGroup?.name || "---"}
                        </p>
                      </div>
                      <div className="border-r h-full p-4 flex items-center w-[11%] md:w-[12%] md:min-w-[12%] flex-col justify-center text-center">
                        <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-[#344054] dark:text-[#F5F5F5] font-medium">
                          {currencyIcon()}
                          {item?.price}
                        </p>
                        <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-xs text-[#667085] dark:text-[#F5F5F5] font-normal">
                          {item?.unit}
                        </p>
                      </div>
                      <div className="h-full border-r justify-center flex text-start items-center w-[35%] md:w-[14%] md:min-w-[14%] p-3">
                        <p className="w-[95%] line-clamp-4 text-sm text-[#475467] dark:text-[#F5F5F5] font-normal">{item?.description || "---"}</p>
                      </div>
                      <div className="h-full justify-center flex text-start items-center w-[6%] md:w-[5%] md:min-w-[5%] p-3">
                        <span
                          onClick={() => navigate(`/app/product/mes/flex/create/raw-material/${item?.id}`)}
                          className="flex p-[10px] items-center justify-center cursor-pointer"
                        >
                          <DetailedIcon />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlexProductRawMaterialList;

const DetailedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_18404_15024)">
        <path
          d="M9.99984 13.3334V10.0001M9.99984 6.66675H10.0082M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z"
          stroke="#475467"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18404_15024">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
