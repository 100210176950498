import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { EditIcon } from "../../../assets/icons/departmentIcon";
import { ArrowDownIcon } from "../../../assets/icons/stepsIcons";
import Badgets from "../../../components/buttons/Badgets";
import { Button } from "../../../components";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../assets/icons/PersonIcons";
import SearchInput from "../../../components/inputs/SearchInput";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { personalService } from "../../../services/personal.service";
import { Toast } from "../../../utils/toastify/toast";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { DeleteIcon, DetailIcon } from "../../../assets/icons/commonIcons";

const UserTable = ({
  setSearchVal,
  userValue = "55",
  onEdit,
  tableBodyItems,
  setTableBodyItems,
  handlePageChange,
  getPersonals,
  setCurrentPage,
  currentPage,
  selectedDepartment,
}) => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const [deletePerson, setDeletePerson] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [tableHeadItems, setTableHeadItems] = useState([
    { name: t("table:image"), value: "image", type: "" },
    {
      name: t("table:name") + " " + t("table:surname"),
      value: "name",
      type: "string",
    },
    // {
    //   name: t("table:surname"),
    //   value: "lastName",
    //   type: "string",
    // },
    {
      name: t("table:authorization"),
      value: "rolesKey",
      type: "string",
    },
    { name: t("addPersonal:email"), value: "talents", type: "number" },
    { name: t("table:phoneNo"), value: "phoneNumber", type: "number" },
    {
      name: t("addPersonal:upuPoint"),
      value: "upuPoint",
      type: "number",
    },
    { name: t("tasks:edit"), value: "edit" },
    { name: t("buttons:delete"), value: "delete" },
    { name: t("buttons:detail"), value: "detail" },
  ]);

  // 👇️ sort by Numeric property ASCENDING (1 - 100)
  const numAscending = (key) => {
    setTableBodyItems([...tableBodyItems].sort((a, b) => a[key] - b[key]));
  };
  // 👇️ sort by Numeric property DESCENDING (100 - 1)
  const numDescending = (key) => {
    setTableBodyItems([...tableBodyItems].sort((a, b) => b[key] - a[key]));
  };

  // 👇️ sort by String property ASCENDING (A - Z)
  const strAscending = (key) => {
    setTableBodyItems([...tableBodyItems].sort((a, b) => a[key]?.localeCompare(b[key])));
  };
  // 👇️ sort by String property DESCENDING (Z - A)
  const strDescending = (key) => {
    setTableBodyItems([...tableBodyItems].sort((a, b) => b[key]?.localeCompare(a[key])));
  };

  const ITEMS_PER_PAGE = height > 950 ? 8 : 7;
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = tableBodyItems?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(tableBodyItems.length / ITEMS_PER_PAGE);

  const toggleSort = (item, index) => {
    const updatedItems = [...tableHeadItems];
    updatedItems.forEach((headItem, i) => {
      if (i === index) {
        if (!headItem.active) {
          headItem.ascending = true;
        } else {
          headItem.ascending = !headItem.ascending;
        }
        headItem.active = true;
      } else {
        headItem.active = false;
        headItem.ascending = true;
      }
    });
    setTableHeadItems(updatedItems);

    if (item.type === "number") {
      if (item.ascending) {
        numAscending(item.value);
      } else {
        numDescending(item.value);
      }
    } else {
      if (item.ascending) {
        strAscending(item.value);
      } else {
        strDescending(item.value);
      }
    }
  };

  const onDeleted = async (id) => {
    setLoading(true);
    await personalService.removePersonal(id).then(() => {
      Toast("success", t("addPersonal:deletedPersonalMessage"));
    });
    setLoading(false);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    const displayRange = 3;

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(renderPageButton(i));
      }
    } else {
      if (currentPage <= 4) {
        for (let i = 1; i <= 6; i++) {
          pageNumbers.push(renderPageButton(i));
        }
        pageNumbers.push(renderEllipsis());
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(renderEllipsis());
        for (let i = totalPages - 5; i <= totalPages; i++) {
          pageNumbers.push(renderPageButton(i));
        }
      } else {
        pageNumbers.push(renderEllipsis());
        for (let i = currentPage - displayRange; i <= currentPage + displayRange; i++) {
          pageNumbers.push(renderPageButton(i));
        }
        pageNumbers.push(renderEllipsis());
      }
    }

    return pageNumbers;
  };

  const renderPageButton = (pageNumber) => (
    <span>
      <Button
        key={pageNumber}
        onClick={() => setCurrentPage(pageNumber)}
        colorType={currentPage === pageNumber ? "secondary-gray" : "tertiary-gray"}
        size={"sm"}
        label={pageNumber.toString()}
      />
    </span>
  );

  const renderEllipsis = () => <span className="mx-1">...</span>;

  return (
    <>
      <div className="w-full flex  flex-1  flex-col h-full">
        <div className="h-[80px] border border-[#EAECF0] border-b-0 flex items-center justify-between px-6 py-5 rounded-t-xl gap-x-2">
          {selectedDepartment ? (
            <span className="text-[#101828] font-semibold text-lg">{selectedDepartment?.name}</span>
          ) : (
            <span className="text-[#101828] font-semibold text-lg">{t("settingsKpi:personels")}</span>
          )}
          <Badgets colorType={"fill-success"} label={userValue} size={"sm"} />
          <div className="flex items-center flex-row ml-auto w-[320px]">
            <SearchInput setSearchVal={setSearchVal} placeholder={t("chat:search")} theme={"person"} />
          </div>
        </div>
        <div className="w-full flex-1 h-full">
          <div className="min-w-full divide-y border border-gray-200 h-full rounded-b-xl">
            <div className={`w-full  ${height > 950 ? "min-h-[615px] h-[615px]" : "min-h-[547px] h-[547px]"} `}>
              <div className="flex bg-secondary-50 max-h-[44px] border-b border-b-secondary-200 min-h-[44px]">
                {tableHeadItems.map((item, i) => {
                  return (
                    <div
                      key={i}
                      scope="col"
                      className={` px-4 py-3 ${i === 8 ? "" : "border-r"} w-[${
                        i === 0 || i === 6 || i === 7 || i === 8
                          ? "6%"
                          : i === 3
                          ? "20%"
                          : i === 5
                          ? "11%"
                          : i === 1
                          ? "16%"
                          : i === 2
                          ? "17%"
                          : i === 4
                          ? "12%"
                          : ""
                      }] ${!item.active ? "text-secondary-500" : "text-secondary-900"} items-center text-center`}
                    >
                      <span
                        className={`text-center rounded  ${!item.active ? "text-gray-500" : "text-gray-500"} flex text-xs font-medium ${
                          i === 0 || i === 4 || i === 5 || i === 6 || i === 7 ? "items-center justify-center" : " items-center"
                        } cursor-pointer whitespace-nowrap`}
                        onClick={() => toggleSort(item, i)}
                      >
                        {item?.name}
                        <span className={`${item?.value === "edit" ? "hidden" : "flex"}`}>
                          {item?.active ? (
                            item?.value === "image" || item?.value === "detail" || item?.value === "delete" ? null : item.ascending ? (
                              <ArrowDownIcon
                                className=" ml-1 bg-b h-4 w-4 text-[#667085]"
                                aria-hidden="true"
                                style={{
                                  display: item.name ? "block" : "none",
                                }}
                              />
                            ) : (
                              <ArrowDownIcon className=" ml-1 h-4 w-4 rotate-180 text-[#667085]" aria-hidden="true" />
                            )
                          ) : item.value === "image" || item?.value === "detail" || item?.value === "delete" ? null : (
                            <ArrowDownIcon
                              className=" ml-1 h-4 w-4 text-[#667085]"
                              aria-hidden="true"
                              style={{
                                display: item.name ? "block" : "none",
                              }}
                            />
                          )}
                        </span>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="w-full bg-[#FFF]">
                {currentItems?.map((person, key) => (
                  <div key={key} className={`flex items-center border-b ${height > 950 ? "h-[71.5px]" : "h-[72px]"} `}>
                    <div className="whitespace-nowrap text-center border-r h-full  items-center text-sm font-medium text-gray-900 flex justify-center w-[6%]">
                      {person?.image === "Empty" ? (
                        <div
                          className={
                            "w-10 h-10 rounded-full bg-primary-700 flex border-4 border-primary-400 items-center justify-center font-medium text-white text-md"
                          }
                        >
                          {person?.name?.charAt(0).toUpperCase() + person?.lastName?.charAt(0)}
                        </div>
                      ) : person?.avatar ? (
                        <img className="h-10 w-10 rounded-full" src={generateFileUrl(person?.avatar)} alt=":)" />
                      ) : (
                        <NoAvatar name={person?.name} lastName={person?.lastName} size={40} theme={"person"} />
                      )}
                    </div>
                    <div className=" border-r h-full p-4 flex items-center w-[16%]">
                      {/* {person?.name} */}
                      <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                        {person?.name + " " + person?.lastName}
                      </p>
                    </div>
                    {/* <div className="border-r h-full p-4 flex items-center w-[20%]">
                      <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                        {person?.lastName}
                      </p>
                    </div> */}
                    <div className="border-r h-full p-4 flex items-center w-[17%]">
                      <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                        {person?.rolesKey}
                      </p>
                    </div>
                    <div className="border-r h-full p-4 flex items-center w-[20%]">
                      <p className="w-[99%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                        {person?.email || "--"}
                      </p>
                    </div>
                    <div className="whitespace-nowrap h-full border-r flex text-center items-center justify-center text-sm text-gray-500 dark:text-[#F5F5F5] font-normal w-[12%]">
                      {person?.phoneNumber}
                    </div>
                    <div className="whitespace-nowrap h-full border-r flex text-center items-center justify-center text-sm text-gray-500 dark:text-[#F5F5F5] font-normal w-[11%]">
                      {person?.upuPoint}
                    </div>
                    <div className="h-full items-center flex justify-center cursor-pointer whitespace-nowrap  border-r text-center text-sm w-[6%]">
                      <Button onClick={() => onEdit(person)} colorType={"tertiary-gray"} iconLeft={<EditIcon />} size={"sm"} />
                    </div>
                    <div className="h-full items-center flex justify-center cursor-pointer whitespace-nowrap  border-r text-center text-sm w-[6%]">
                      <Button
                        colorType={"tertiary-gray"}
                        iconLeft={<DeleteIcon />}
                        size={"sm"}
                        onClick={() => {
                          setDeletePerson(person?.id);
                          setIsAlert(true);
                        }}
                      />
                    </div>
                    <div className="h-full items-center flex justify-center cursor-pointer whitespace-nowrap   text-center text-sm w-[6%]">
                      <Button
                        colorType={"tertiary-gray"}
                        iconLeft={<DetailIcon />}
                        size={"sm"}
                        onClick={() => {
                          navigate(`/app/machine/digital-twin/user-detail/${person?.id}/${moment().format("YYYY-MM-DD")}`);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`flex w-full items-center ${height > 950 ? "h-[72px]" : "h-[64px]"}  `}>
              <div className={`flex w-full min-h-[40px] ${height < 950 ? "mt-4" : "mt-0"} max-h-[40px] px-6`}>
                <span className="flex mr-auto">
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    colorType={"secondary-gray"}
                    size={"sm"}
                    label={t("buttons:previous")}
                    iconLeft={<ArrowLeftIcon />}
                  />
                </span>
                <span className="flex gap-x-1">{renderPageNumbers()}</span>
                <span className="flex ml-auto">
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    colorType={"secondary-gray"}
                    size={"sm"}
                    label={t("buttons:next")}
                    iconRight={<ArrowRightIcon />}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        title={t("addPersonal:deletePersonal")}
        subTitle={t("addPersonal:deletePersonalWarning")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        loading={loading}
        applyButton={async () => {
          await onDeleted(deletePerson);
          await getPersonals();
          setIsAlert(false);
        }}
      />
    </>
  );
};

UserTable.propTypes = {
  tableBodyItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      machineModal: PropTypes.string,
      machineName: PropTypes.string,
      puantaj: PropTypes.number,
      user: PropTypes.arrayOf(
        PropTypes.shape({
          user: PropTypes.string,
        })
      ),
      upuPoint: PropTypes.number,
    })
  ),
  onSort: PropTypes.func,
  onClickPrev: PropTypes.func,
  onClickNext: PropTypes.func,
};

UserTable.defaultProps = {
  users: [],
  isOpen: false,
  setIsOpen: () => {},
};

export default UserTable;
