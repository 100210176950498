import React, { memo, useState } from "react";
import TaskCardHeader from "./TaskCardHeader";
import { chatService } from "../../../services/chat.service";
import TaskMessageSide from "./TaskMessageSide";
import TaskLabelsSide from "./TaskLabelsSide";
import TaskBottomTabs from "./TaskBottomTabs";
import { Toast } from "../../../utils/toastify/toast";
import CloseButton from "../../../components/buttons/CloseButton";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  ConfirmIcon,
  DeclineIcon,
  Migrate,
  TaskCardDeleteIcon,
  TaskEditIcon,
} from "../../../assets/icons/PersonIcons";
import { CustomModal } from "../../../components";
import SelectTaskUsers from "./SelectTaskUsers";
import { Loader } from "../../../assets/icons/stepsIcons";
import MoveTaskCardModal from "../modals/MoveTaskCardModal";
import { observer } from "mobx-react-lite";

const TaskCard = observer(
  ({
    status,
    point,
    date,
    number,
    task,
    index,
    taskId,
    creator,
    participants,
    comments,
    value,
    attachment,
    isStar,
    labels,
    refetch,
    departmentLabels,
    setSortValue,
    groupDataId,
    groupParticipants,
  }) => {
    const { t } = useTranslation();
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [upuPoint, setUpuPoint] = useState(point);
    const [endDate, setEndDate] = useState(moment(date));
    const [taskName, setTaskName] = useState(task);
    const [changeComment, onChangeComment] = useState(null);
    const [attachments, setAttachments] = useState(attachment);
    const [participant, setParticipant] = useState(participants);
    const [taskLabel, setTaskLabel] = useState(labels);
    const [selectedTab, setSelectedTab] = useState(null);
    const [selectedItem, setSelectedItem] = useState({});
    const [openMoveTask, setOpenMoveTask] = useState(false);
    const [isOpenUserModal, setIsOpenUserModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const changeStarStatus = async () => {
      const data = { starStatus: isStar === true ? false : true };
      await chatService.changStarStatusForTask(taskId, data).then((res) => {
        if (res.data.code === 0) {
          refetch();
        } else {
        }
      });
    };

    const handleUser = (user) => {
      if (participant.find((item) => item.id === user.id)) {
        setParticipant(participant.filter((item) => item.id !== user.id));
      } else {
        setParticipant([...participant, user]);
      }
    };

    const sendComment = async () => {
      const data = { comment: changeComment };
      await chatService.sendTaskComment(taskId, data).then((res) => {
        if (res.data.code === 0) {
          refetch();
          Toast("success", res.data.message);
          onChangeComment(null);
        } else {
          Toast("error", res.data.message);
          onChangeComment(null);
          refetch();
        }
      });
    };

    const deleteComment = async (id) => {
      const data = { commentId: id };
      await chatService.deleteComment(taskId, data).then((res) => {
        if (res.data.code == 0) {
          refetch();
          Toast("success", res.data.message);
        } else {
          refetch();
          Toast("error", res.data.message);
        }
      });
    };

    const handleLabel = (item) => {
      const newParam = { color: item.color, id: item.id, name: item.name };
      const filter = taskLabel?.filter((u) => {
        return u?.id == newParam?.id;
      });
      if (filter?.length) {
        setTaskLabel(taskLabel?.filter((f) => f?.id !== newParam?.id));
      } else {
        setTaskLabel([...taskLabel, newParam]);
      }
      taskLabel.includes(newParam);
      // const filter = taskLabel.filter((u) => {
      //   return u?.id == item?.id;
      // });
      // if (filter?.length) {
      //   setTaskLabel(taskLabel?.filter((f) => f?.id !== item?.id));
      // } else {
      //   setTaskLabel([...taskLabel, item]);
      // }
    };
    const updateTask = async () => {
      if (taskName?.length == 0 && participant?.length == 0) {
        Toast("error", t("person:taskSubjectCreated"));
      } else if (taskName?.length == 0 && participant?.length > 0) {
        Toast("error", t("person:taskSubject"), []);
      } else if (taskName?.length > 0 && participant?.length == 0) {
        Toast("error", t("person:taskCannot"), []);
      } else {
        setLoading(true);
        const editData = {
          endDate: endDate,
          upuPoint: upuPoint,
          name: taskName,
          attachment: attachments.map((f) => {
            if (f?.attachmentId) {
              return {
                attachmentId: f?.attachmentId,
                name: f?.name,
                type: f?.type,
              };
            }
          }),
          participants: participant.map((d) => d?.id),
          label: taskLabel.map((d) => {
            return { id: d?.id };
          }),
        };
        await chatService
          .updateTask(taskId, editData)
          .then((res) => {
            if (res.data.code == 0) {
              setIsEdit(false);
              refetch();
              Toast("success", t("chat:taskUpdated"));
              setSelectedTab(null);
              setSortValue(null);
            } else {
              Toast("error", t("chat:notAuthorized"));
            }
          })
          .catch((err) => {
            console.log(err)
          });
      }
      setLoading(false);
    };

    const statusColors = () => {
      switch (status) {
        case "approved":
          return "#17B26A";
        case "expired":
          return "#F04438";
        case "done":
          return "#EAAA08";
        case "todo":
          return "#98A2B3";
        default:
          return "#98A2B3";
      }
    };

    const menuButtons = [
      {
        icon: ConfirmIcon,
        name: t("tasks:confirm"),
        bgColor: "#CCFBEF90",
        textColor: "#107569",
        onClick: async () => {
          if (status != "approved") {
            setLoading(true);
            await chatService
              ?.updateTaskStatus(taskId)
              .then((res) => {
                setIsOpenMenu(false);
                Toast("success", res?.data?.message);
                refetch();
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Toast("success", t("chat:taskApproved"));
          }
          setLoading(false);
        },
      },
      {
        icon: DeclineIcon,
        name: t("tasks:decline"),
        bgColor: "#FEE4E290",
        textColor: "#DC6803",
        onClick: async () => {
          if (status != "todo") {
            setLoading(true);
            await chatService
              .updateTaskStatusDone(taskId)
              .then((res) => {
                setIsOpenMenu(false);
                Toast("success", res?.data?.message);

                refetch();
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Toast("success", t("chat:taskApproved"));
          }
          setLoading(false);
        },
      },
      {
        icon: Migrate,
        name: t("tasks:move"),
        bgColor: "#EAECF590",
        textColor: "#3E4784",
        onClick: () => {
          if (setOpenMoveTask) {
            setOpenMoveTask(true);
            setIsOpenMenu(false);
            setSelectedItem({
              taskId: taskId,
              groupDataId: groupDataId,
            });
          } else {
            setIsOpenMenu(false);
          }
        },
      },
      {
        icon: TaskEditIcon,
        name: t("tasks:edit"),
        bgColor: "#F4EBFF90",
        textColor: "#7F56D9",
        onClick: async () => {
          setTaskName(task);
          setUpuPoint(point);
          setEndDate(moment(date));
          setAttachments(attachment);
          setParticipant(participants);
          setTaskLabel(labels);
          setIsEdit(true);
          setIsOpenMenu(false);
        },
      },
      {
        icon: TaskCardDeleteIcon,
        name: t("tasks:delete"),
        bgColor: "#FEF3F290",
        textColor: "#D92D20",
        onClick: async () => {
          setLoading(true);
          await chatService
            .deleteTask(taskId)
            .then((res) => {
              if (res.data.code === 0) {
                Toast("success", t("chat:taskDeleted"));
              } else {
                Toast("error", t("chat:notAuthorized"));
              }
            })
            .catch((err) => Toast("error", t("embedded:networkError")));
          setIsOpenMenu(false);
          refetch();
          setLoading(false);
        },
      },
    ];

    return (
      <>
        <div
          className={`w-full h-fit rounded-lg bg-white shadow border-x border-b border-secondary-200 px-3 pt-4 pb-3 relative flex flex-col gap-y-5`}
        >
          <div
            className="w-full h-2 absolute top-0 left-0 right-0 rounded-t-lg"
            style={{ backgroundColor: statusColors() }}
          />
          <TaskCardHeader
            number={index}
            isStar={isStar}
            isEdit={isEdit}
            creator={creator}
            value={value}
            endDate={isEdit ? endDate : date}
            upuPoint={isEdit ? upuPoint : point}
            listNumber={number}
            setIsEdit={setIsEdit}
            setSelectedTab={setSelectedTab}
            setEndDate={setEndDate}
            isOpenMenu={isOpenMenu}
            setUpuPoint={setUpuPoint}
            activeColor={statusColors()}
            setIsOpenMenu={setIsOpenMenu}
            changeStarStatus={changeStarStatus}
          />

          {isEdit ? (
            <textarea
              placeholder={t("person:writeTask")}
              type="text"
              rows={taskName?.length / 45 + 1}
              onChange={({ target }) => {
                setTaskName(target.value);
              }}
              value={taskName}
              className="w-full rounded-lg border shadow-sm focus:outline-0 border-gray-300 px-3 py-2 resize-none text-sm text-secondary-600 focus:ring-0 dark:bg-[#292929] dark:border-[#525252] dark:text-[#FAFAFA]"
            />
          ) : (
            <TaskMessageSide text={task} />
          )}

          {labels?.length > 0 && (
            <TaskLabelsSide labels={labels} editMode={isEdit} />
          )}
          <TaskBottomTabs
            isEdit={isEdit}
            comments={comments}
            attachments={attachments}
            value={value}
            attachment={attachment}
            setAttachments={setAttachments}
            sendComment={sendComment}
            participants={participants}
            participant={participant}
            taskLabel={taskLabel}
            setTaskLabel={setTaskLabel}
            departmentLabels={departmentLabels}
            deleteComment={deleteComment}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            handleLabel={handleLabel}
            changeComment={changeComment}
            handleUser={handleUser}
            onChangeComment={onChangeComment}
            openPersonnelsModal={() => setIsOpenUserModal(true)}
          />
          {loading ? (
            <div
              style={{
                background: statusColors(),
              }}
              className="flex items-center justify-center w-full h-9 rounded-lg "
            >
              <Loader currentColor="#107569" currentFill={statusColors} />
            </div>
          ) : (
            <>
              {isEdit && (
                <button
                  onClick={updateTask}
                  style={{
                    background: statusColors(),
                  }}
                  className="w-full h-9 rounded-lg flex items-center justify-center hover:opacity-70"
                >
                  <p className="text-sm font-semibold text-white">
                    {t("buttons:save")}
                  </p>
                </button>
              )}
            </>
          )}

          {isOpenMenu && (
            <div className="absolute inset-0 rounded-lg flex-row flex items-center overflow-hidden z-50 transition-all duration-200">
              <div className="absolute -top-0.5 right-0 z-50 ">
                <CloseButton size={"sm"} onClick={() => setIsOpenMenu(false)} />
              </div>
              {loading ? (
                <div className="flex items-center justify-center w-full h-full backdrop-blur-md">
                  <Loader currentColor="#107569" currentFill="currentFill" />
                </div>
              ) : (
                <>
                  {menuButtons.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        background: item?.bgColor,
                        borderTopLeftRadius: index == 0 ? 7 : 0,
                        borderBottomLeftRadius: index == 0 ? 7 : 0,
                        borderBottomRightRadius: index == 4 ? 7 : 0,
                        borderTopRightRadius: index === 4 ? 7 : 0,
                      }}
                      className="w-[20%] h-full flex items-center justify-center cursor-pointer backdrop-blur-md flex-col group"
                      onClick={item?.onClick}
                    >
                      <item.icon color={item.textColor} />
                      <span
                        style={{ color: item.textColor }}
                        className={`text-xs font-medium mt-1.5 select-none group-active:hidden`}
                      >
                        {item?.name}
                      </span>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
        <CustomModal
          modalTitle={t("chat:selectUser")}
          isOpen={isOpenUserModal}
          setIsOpen={setIsOpenUserModal}
          children={
            <SelectTaskUsers
              handleUser={handleUser}
              selectedUser={participant}
              setIsOpen={setIsOpenUserModal}
              setSelectedUser={setParticipant}
              groupParticipants={groupParticipants}
            />
          }
        />
        <CustomModal
          isOpen={openMoveTask}
          setIsOpen={setOpenMoveTask}
          children={
            <MoveTaskCardModal
              selectedItem={selectedItem}
              onClose={() => {
                refetch();
                setOpenMoveTask(false);
              }}
            />
          }
          modalTitle={t("chat:moveCard")}
        />
      </>
    );
  }
);

export default memo(TaskCard);
