import React from "react";
import DigitalSensorCard from "./DigitalSensorCard";
import { embeddedServerService } from "../../../services/embedded-server.service";
import { useQuery } from "@tanstack/react-query";
import { renameSensor } from "../../../utils/chooseSensorName";

const DigitalSensorList = ({ metadataId, data, setDraggedSensor, activeList, priorityList, refetch, deviceId, cards }) => {
  const sensorType = useQuery({
    retry: 0,
    queryKey: "sensor-types-digital",
    queryFn: async () => await embeddedServerService.getSensorTypes(),
  });
  const sensors = [
    {
      name: "D1",
      whichSensor: "first",
      id: data?.firstDigital?.id,
      isActive: activeList?.first,
      value: data?.firstDigital?.value,
      isConsumption: data?.firstDigital?.isConsumption,
      typeId: data?.firstDigital?.type?.id,
    },
    {
      name: "D2",
      whichSensor: "second",
      id: data?.secondDigital?.id,
      isActive: activeList?.second,
      value: data?.secondDigital?.value,
      isConsumption: data?.secondDigital?.isConsumption,
      typeId: data?.secondDigital?.type?.id,
    },
    {
      name: "D3",
      whichSensor: "third",
      id: data?.thirdDigital?.id,
      isActive: activeList?.third,
      value: data?.thirdDigital?.value,
      isConsumption: data?.thirdDigital?.isConsumption,
      typeId: data?.thirdDigital?.type?.id,
    },
    {
      name: "D4",
      whichSensor: "fourth",
      id: data?.fourthDigital?.id,
      isActive: activeList?.fourth,
      value: data?.fourthDigital?.value,
      isConsumption: data?.fourthDigital?.isConsumption,
      typeId: data?.fourthDigital?.type?.id,
    },
  ];

  const sensorTypes = sensorType?.data?.digital?.map((item) => {
    return { label: item?.name, value: item?.id };
  });
  const availableValues = (whichSensor) => {
    const currentSensor = renameSensor("digital", whichSensor);
    const inUse = cards?.map((el) => {
      return {
        sensor: renameSensor(el?.type, el?.whichSensor ? el?.whichSensor : el?.analogSensor ? el?.analogSensor?.whichSensor : el?.digitalSensor?.whichSensor),
        value: el.value,
      };
    });
    const filter = inUse?.filter((a) => a?.sensor == currentSensor);
    if (filter.length == 0) return [0, 1];
    else if (filter.length == 2) return [];
    else return [filter[0].value == 1 ? 0 : 1];
  };
  return (
    <div className="w-full flex flex-col border-b pb-4 mb-4 border-secondary-200">
      <div className="h-[52px] w-full flex items-center">
        <div className="h-[28px] w-full flex items-end">
          <p className="text-lg font-semibold text-gray-900 w-[20%]">Dijital Sensörler</p>
          <p className="text-xs font-medium text-gray-600 w-[30%] flex items-center justify-center">Sensor Type</p>
          <p className="text-xs font-medium text-gray-600 w-[20%] flex items-center justify-center text-center gap-1">
            Değer <Info />
          </p>
          <p className="text-xs font-medium text-gray-600 w-[20%] flex items-center justify-center text-clip">Consumption</p>
        </div>
      </div>

      <div className="w-full flex flex-col px-0.5 gap-3 ">
        {sensors.map((item, index) => {
          return (
            <DigitalSensorCard
              cards={cards}
              key={index}
              name={item.name}
              refetch={refetch}
              sensorId={item.id}
              deviceId={deviceId}
              typeId={item.typeId}
              metadataId={metadataId}
              isActive={item.isActive}
              priorityList={priorityList}
              sensorTypes={sensorType.isLoading ? [{ label: "Loading...", value: undefined }] : [{ label: "Select an item..", value: "empty" }, ...sensorTypes]}
              whichSensor={item.whichSensor}
              setDraggedSensor={setDraggedSensor}
              savedData={{ isConsumption: item?.isConsumption, value: item?.value }}
              activeValueButtons={availableValues(item?.whichSensor)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DigitalSensorList;

const Info = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path
        d="M7.49996 9.33317V6.99984M7.49996 4.6665H7.50579M13.3333 6.99984C13.3333 10.2215 10.7216 12.8332 7.49996 12.8332C4.2783 12.8332 1.66663 10.2215 1.66663 6.99984C1.66663 3.77818 4.2783 1.1665 7.49996 1.1665C10.7216 1.1665 13.3333 3.77818 13.3333 6.99984Z"
        stroke="#98A2B3"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
