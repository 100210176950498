import React, { useEffect, useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import Badgets from "../../../components/buttons/Badgets";
import moment from "moment";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { Button } from "../../../components";
import { useTranslation } from "react-i18next";
import { companyService } from "../../../services/company.service";
import { Toast } from "../../../utils/toastify/toast";

const ShiftColor = (color) => {
  switch (color) {
    case "#F9F5FF":
      return {
        background: "#F9F5FF",
        border: "#E9D7FE",
        text: "#6941C6",
      };
      break;
    case "#FEF3F2":
      return {
        background: "#FFFAEB",
        border: "#FEDF89",
        text: "#B54708",
      };
      break;

    case "sm":
      return {
        background: "#FEF3F2",
        border: "#FECDCA",
        text: "#B42318",
      };
      break;
    case "sm":
      return {
        background: "#EFF8FF",
        border: "#B2DDFF",
        text: "#175CD3",
      };
      break;
    case "sm":
      return {
        background: "#F0FDF9",
        border: "#99F6E0",
        text: "#107569",
      };
      break;
    case "sm":
      return {
        background: "#FDF2FA",
        border: "#FCCEEE",
        text: "#C11574",
      };
      break;
    case "sm":
      return {
        background: "#F2F4F7",
        border: "#E4E7EC",
        text: "#344054",
      };
      break;
    case "sm":
      return {
        background: "#ECFDF3",
        border: "#ABEFC6",
        text: "#067647",
      };
      break;
  }
};

const SavedShiftModal = ({ setModalOpen, id, refetch, type, openPlanModal, setSelectedGroup }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedGroup(event.target.value);
  };

  const saveData = async () => {
    if (type === "nonstandard") {
      openPlanModal(true);
    } else {
      if (selectedOption) {
        await companyService.updateShift(id, selectedOption).then((res) => {
          if (res.data.code === 0) {
            Toast("success", res.data.message);
            refetch();
            setModalOpen(false);
          } else {
            Toast("error", res.data?.message);
          }
        });
      }
    }
  };

  const handleData = async () => {
    await companyService.getDailyShifts().then(({ data }) => {
      setData(
        data?.map((item) => {
          return { ...item, times: item?.shifts?.flat() };
        })
      );
    });
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <div className="flex items-center w-[770px]  h-[500px] flex-col mt-6">
      <SearchInput />

      <div className="w-full h-[400px] overflow-scroll scrollbar-hide">
        <div className="flex w-full flex-col mt-6">
          {data?.map((e, x) => {
            const shiftDatas = e?.shifts?.filter((e) => {
              return e.index != 1;
            });

            return (
              <div className="flex w-full items-center  h-[64px] min-h-[64px] border-b">
                <input
                  type="radio"
                  className="form-radio h-5 w-5 text-primary-700"
                  name="radio-option"
                  value={e?.id}
                  checked={selectedOption === e?.id}
                  onChange={handleChange}
                />
                <div className="flex flex-col items-start ml-3">
                  <p className="text-secondary-700 font-semibold text-sm">{e?.name}</p>
                  <p className="text-secondary-500 text-sm font-normal">{e?.shifts?.length} Vardiya</p>
                </div>
                {/* shiftlerr */}
                <div className="ml-auto flex items-center gap-x-3">
                  {shiftDatas?.map((shift, x) => {
                    let labelStart = moment(shift?.[0].start).format("HH:mm");
                    let labelEnd = moment(shift?.[shift?.length - 1]?.end).format("HH:mm");

                    return (
                      // <Badgets label={`${labelStart} ${labelEnd}`}  />

                      <div
                        style={{
                          backgroundColor: ShiftColor(shift[0]?.color)?.background,
                          borderColor: ShiftColor(shift[0]?.color)?.border,
                          borderWidth: 1,
                        }}
                        className="flex items-center px-2 py-1 text-sm rounded-md"
                      >
                        <p
                          style={{
                            color: ShiftColor(shift[0]?.color)?.text,
                          }}
                        >
                          {labelStart} {labelEnd}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          {/* mapppperr */}
        </div>
      </div>
      <div className="flex flex-row xs:flex-col xs:gap-3 w-full h-10 items-end gap-x-3 mt-auto ">
        <Button
          onClick={() => {
            setModalOpen(false);
          }}
          size="sm"
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
        />

        <Button onClick={saveData} size="sm" label={type === "nonstandard" ? "Seç & Devam Et" : t("buttons:save")} colorType={"primary-machine"} />
      </div>
    </div>
  );
};

export default SavedShiftModal;
