import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon } from "../../assets/icons/productIcons";
import { Button, Input, Loading } from "../../components";
import generateFileUrl from "../../utils/generateFileUrl";
import { CheckIcon } from "../../assets/icons/modalIcons";
import { Toast } from "../../utils/toastify/toast";
import { legacyOrderService } from "../../services/legacy-new-order-service";
import moment from "moment";
import NoAvatar from "../../components/avatar/NoAvatar";

const EditOrderSecondStep = ({}) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [stockSelected, setStockSelected] = useState([]);
  const [productionSelected, setProductionSelected] = useState([]);
  const [holdSelected, setHoldSelected] = useState([]);
  const [formData, setFormData] = useState({});
  const EditOrderFirstData = JSON.parse(sessionStorage.getItem("editOrderFirstData"));
  const tableHeadItems = [
    {
      name: t("product:productsAndMaterialss"),
      width: "26%",
    },
    {
      name: t("product:deadline"),
      width: "8%",
    },
    {
      name: t("product:stockAmount"),
      width: "8%",
    },
    {
      name: t("product:orderQuantityss"),
      width: "8%",
    },
    {
      name: t("product:supplyTypeAndPlanning"),
      width: "50%",
    },
  ];
  const [buttonLoading, setButtonLoading] = useState(false);
  const toggleStockSelected = (item) => {
    if (stockSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)) {
      setStockSelected(stockSelected.filter((selectedItem) => !(selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)));
      setFormData((prev) => ({
        ...prev,
        [item.id]: {
          ...prev[item.id],
          [item.deliveryDate]: {
            ...(prev[item.id]?.[item.deliveryDate] || {}),
            stoktanGetir: "",
          },
        },
      }));
    } else {
      setStockSelected([...stockSelected, item]);
    }
  };

  const toggleProductionSelected = (item) => {
    if (productionSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)) {
      setProductionSelected(productionSelected.filter((selectedItem) => !(selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)));
      setFormData((prev) => ({
        ...prev,
        [item.id]: {
          ...prev[item.id],
          [item.deliveryDate]: {
            ...(prev[item.id]?.[item.deliveryDate] || {}),
            uretimeGonder: "",
          },
        },
      }));
    } else {
      setProductionSelected([...productionSelected, item]);
    }
  };

  const toggleHoldSelected = (item) => {
    if (holdSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)) {
      setHoldSelected(holdSelected.filter((selectedItem) => !(selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)));
      setFormData((prev) => ({
        ...prev,
        [item.id]: {
          ...prev[item.id],
          [item.deliveryDate]: {
            ...(prev[item.id]?.[item.deliveryDate] || {}),
            hold: "",
          },
        },
      }));
    } else {
      setHoldSelected([...holdSelected, item]);
    }
  };

  const handleInputChange = (value, id, field, deliveryDate) => {
    setFormData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [deliveryDate]: {
          ...(prev[id]?.[deliveryDate] || {}),
          [field]: value,
        },
      },
    }));
  };

  const onSubmit = async () => {
    const updatedProducts = EditOrderFirstData?.selectedProducts.map((item) => ({
      ...item,
      stockQuantity: parseInt(formData[item.id]?.[item.deliveryDate]?.stoktanGetir) || 0,
      productionQuantity: parseInt(formData[item.id]?.[item.deliveryDate]?.uretimeGonder) || 0,
      waitingQuantity: parseInt(formData[item.id]?.[item.deliveryDate]?.hold) || 0,
    }));

    const hasStockError = updatedProducts.find((item) => (item.qualityQuantity < 0 ? 0 : item.qualityQuantity || 0) < item.stockQuantity);
    const hasOrderError = updatedProducts.find((item) => item.orderedQuantity !== item.stockQuantity + item.productionQuantity + item.waitingQuantity);

    const stockQuantityCheck = updatedProducts.reduce((acc, item) => {
      if (!acc[item.id]) {
        acc[item.id] = { totalStock: 0, qualityQuantity: item.qualityQuantity, name: item.name };
      }
      acc[item.id].totalStock += item.stockQuantity;
      return acc;
    }, {});

    const stockErrorItem = Object.values(stockQuantityCheck).find((item) => item.totalStock > item.qualityQuantity && item.totalStock !== 0);

    if (stockErrorItem) {
      // Toast("error", `${stockErrorItem.name} product's total "Bring from Stock" value cannot exceed the stock amount (${stockErrorItem.qualityQuantity}).`);
      Toast("error", `${stockErrorItem.name} ürününün toplam "Stoktan Getir" değeri, stok miktarını (${stockErrorItem.qualityQuantity}) aşamaz.`);
      return;
    }

    if (hasStockError) {
      Toast("error", `${hasStockError?.name} Stoktan çıkış miktarı, stok miktarından fazla olamaz.`);
      return;
    }

    if (hasOrderError) {
      Toast("error", `${hasOrderError?.name} Sipariş miktarı, stoktan çıkış, üretime gönderim ve bekletme miktarlarının toplamına eşit olmalıdır.`);
      return;
    }

    const allQuantitiesEqual = updatedProducts.every((item) => item.stockQuantity === item.orderedQuantity);

    if (allQuantitiesEqual) {
      const sendDataDene = {
        systemOrderNo: EditOrderFirstData?.systemOrderNo,
        customer: EditOrderFirstData?.customer?.id,
        customerOrderNo: EditOrderFirstData?.customerOrderNo,
        productType: EditOrderFirstData.productType,
        products: updatedProducts.map((item) => ({
          currency: item?.currency,
          stockQuantity: item?.stockQuantity,
          productionQuantity: item?.productionQuantity,
          waitingQuantity: item?.waitingQuantity,
          customerStockCode: item?.customerStockCode,
          orderedQuantity: item?.constantOrderedQuantity,
          productPrice: item?.productPrice,
          deliveryDate: item?.deliveryDate,
          product: item?.id,
          status: item?.status,
          id: item?.sendDetailId,
          purchaseRequest: {
            type: "raw",
            orderedQuantity: item?.orderedQuantity,
            stockQuantity: item?.stockQuantity,
            purchaseQuantity: 0,
            typeId: undefined,
          },
        })),
      };
      await legacyOrderService.flexUpdateOrder(id, sendDataDene).then((res) => {
        if (res.data.code == 0) {
          sessionStorage.removeItem("editOrderFirstData");
          sessionStorage.removeItem("editOrderSecondPageData");
          Toast("success", res?.data?.message);
          navigate("/app/product/mrp/orders");
        } else {
          Toast("error", res?.data?.message);
        }
      });
    } else if (updatedProducts.every((product) => product?.stockQuantity === 0 && product?.productionQuantity === 0)) {
      const sendDataDene = {
        systemOrderNo: EditOrderFirstData?.systemOrderNo,
        customer: EditOrderFirstData?.customer?.id,
        customerOrderNo: EditOrderFirstData?.customerOrderNo,
        productType: EditOrderFirstData.productType,
        products: updatedProducts.map((item) => ({
          currency: item?.currency,
          stockQuantity: item?.stockQuantity,
          productionQuantity: item?.productionQuantity,
          waitingQuantity: item?.waitingQuantity,
          customerStockCode: item?.customerStockCode,
          orderedQuantity: item?.constantOrderedQuantity,
          status: item?.status,
          id: item?.sendDetailId,
          productPrice: item?.productPrice,
          deliveryDate: item?.deliveryDate,
          product: item?.id,
          purchaseRequest: {
            type: "raw",
            orderedQuantity: item?.orderedQuantity,
            stockQuantity: item?.stockQuantity,
            purchaseQuantity: 0,
            typeId: undefined,
          },
        })),
      };
      await legacyOrderService.flexUpdateOrder(id, sendDataDene).then((res) => {
        if (res.data.code == 0) {
          sessionStorage.removeItem("editOrderFirstData");
          sessionStorage.removeItem("editOrderSecondPageData");
          Toast("success", res?.data?.message);
          navigate("/app/product/mrp/orders");
        } else {
          Toast("error", res?.data?.message);
        }
      });
    } else {
      const groupedProducts = updatedProducts.reduce((acc, item) => {
        if (!acc[item.id]) {
          acc[item.id] = [];
        }
        acc[item.id].push({
          ...item,
        });
        return acc;
      }, {});
      const sendData = {
        productType: EditOrderFirstData.productType,
        customer: EditOrderFirstData?.customer,
        orderCode: EditOrderFirstData?.systemOrderNo,
        customerOrderNo: EditOrderFirstData?.customerOrderNo,
        selectedProducts: Object.entries(groupedProducts).map(([id, items]) => ({
          productId: id,
          items,
        })),
      };

      sessionStorage.setItem("editOrderSecondPageData", JSON.stringify(sendData));
      navigate(`/app/product/mrp/orders/edit-order/third-page/${id}/0`);
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem("editOrderSecondPageData");

    if (storedData) {
      const data = JSON.parse(storedData);

      const mappedData = data.selectedProducts.reduce((acc, product) => {
        acc[product.id] = {
          stoktanGetir: product.stockQuantity,
          uretimeGonder: product.productionQuantity,
          hold: product.waitingQuantity,
        };
        return acc;
      }, {});

      setFormData(mappedData);

      const initialProductionSelected = data.selectedProducts.filter((product) => product.productionQuantity > 0);

      const initialHoldSelected = data.selectedProducts.filter((product) => product.waitingQuantity > 0);

      const initialStockSelected = data.selectedProducts.filter((product) => product.stockQuantity > 0);

      setProductionSelected(initialProductionSelected);
      setHoldSelected(initialHoldSelected);
      setStockSelected(initialStockSelected);
    }
  }, [sessionStorage.getItem("editOrderSecondPageData")]);

  return (
    <>
      <div className="flex flex-col w-full gap-y-8 h-[95vh]">
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
          <p className="text-[#101828] font-semibold text-2xl">{t("product:orderEdit")}</p>
        </div>

        <div className="flex flex-col gap-y-6 w-full h-full">
          <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
            <div className="flex flex-col gap-y-1">
              <p className="text-[#101828] font-semibold text-lg">{t("product:supplyTypeAndPlanning")}</p>
              <p className="text-[#344054] font-normal text-sm">{t("product:youCanDeduct")}</p>
            </div>
            <div className="flex gap-x-3 max-h-[40px] min-h-[40px] h-10">
              <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                <Button size={"md"} colorType={"secondary-gray"} label={t("buttons:previous")} onClick={() => navigate(-1)} />
              </span>
              <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                <Button
                  disabled={buttonLoading ? true : false}
                  size={"md"}
                  colorType={"primary-product"}
                  label={buttonLoading ? <Loading color={"#fff"} secondary={"#fff"} size={22} /> : t("buttons:save")}
                  type={"submit"}
                  onClick={onSubmit}
                />
              </span>
            </div>
          </div>
          <div className="flex flex-col w-full h-[75vh] border border-[#E4E7EC] rounded-t-xl">
            <div className="flex w-full min-h-[56px] max-h-[56px] h-[56px] justify-between bg-white rounded-t-xl items-center px-6 py-4 border-b border-[#E4E7EC]">
              <div className="flex gap-x-2 items-center">
                <img
                  src={generateFileUrl(EditOrderFirstData?.customer?.image)}
                  className="min-w-[32px] w-[32px] h-[24px] rounded-sm border border-[#0000001A]"
                />
                <p className="text-[#101828] font-medium text-sm">{EditOrderFirstData?.customer?.name}</p>
              </div>
              <div className="flex w-fit px-3 gap-x-6">
                <div className="flex gap-x-2 items-center">
                  <CalendarIcon />
                  <p className="text-[#475467] text-sm">{t("product:orderGeneratedDate")}</p>
                  <p className="text-[#B54708] font-semibold text-md">{moment().format("DD-MM-YYYY")}</p>
                </div>
                <div className="flex gap-x-2 items-center">
                  <OrderIcon />
                  <p className="text-[#475467] text-sm">{t("product:orderNo")}</p>
                  <p className="text-[#B54708] font-semibold text-md">ORD{EditOrderFirstData?.systemOrderNo}</p>
                </div>
                <div className="flex gap-x-2 items-center">
                  <OrderIcon />
                  <p className="text-[#475467] text-sm">{t("product:customerOrderNo")}</p>
                  <p className="text-[#B54708] font-semibold text-md">{EditOrderFirstData?.customerOrderNo}</p>
                </div>
              </div>
            </div>

            <div className={`flex flex-col w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide`}>
              {/* table header */}
              <div className="flex max-h-[34px] min-h-[34px] w-full sticky top-0 bg-[#F9FAFB] z-50">
                {tableHeadItems.map((item, i) => (
                  <div
                    key={i}
                    className={`flex border-b px-6 py-2 border-r  border-[#E4E7EC] items-center w-[${item.width}] ${
                      i === tableHeadItems.length - 1 ? "border-r-0" : ""
                    } ${i === 0 ? "justify-start" : "justify-center"} ${
                      i === 0 ? "min-w-[316px]" : i === 1 ? "min-w-[108px]" : i === 2 || i === 3 ? "min-w-[120px]" : ""
                    }`}
                  >
                    <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item?.name}</span>
                  </div>
                ))}
              </div>
              {EditOrderFirstData?.selectedProducts?.map((item, i) => (
                <div key={i} className="flex w-full items-center border-b h-[100px] min-h-[100px]">
                  {/* tablo 1.kolon */}
                  <div className=" flex items-center gap-x-3 py-4 px-6 border-r h-full justify-start w-[26%] min-w-[316px]">
                    {item?.image ? (
                      <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(item?.image)} />
                    ) : (
                      <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden mr-3">
                        <NoAvatar name={item?.name || "--"} rounded={4} theme={"product"} />
                      </div>
                    )}

                    <div className="flex flex-col gap-y-1 justify-center">
                      <p className="text-[#101828] font-semibold text-md">{item?.name || "--"}</p>
                      <p className="text-[#475467] text-sm">
                        {t("prodcut:stock")} ID: {item?.stockCode || "--"}
                      </p>
                    </div>
                  </div>

                  {/* tablo 2.kolon */}
                  <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[8%] min-w-[108px]">
                    <p className="text-sm text-[#344054] dark:text-[#F5F5F5] font-medium text-center">
                      {moment(item?.deliveryDate).format("DD.MM.YYYY") || "--:--:--"}
                    </p>
                  </div>
                  {/* tablo 3.kolon */}

                  <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[8%] min-w-[120px]">
                    <p
                      className={`whitespace-nowrap text-center overflow-hidden  ${
                        item?.stockCount < item?.orderCount ? "text-error-600" : "text-success-600"
                      } text-ellipsis text-md  dark:text-[#F5F5F5] font-normal`}
                    >
                      {item?.qualityQuantity || "0"}
                    </p>
                    <p className="text-sm font-normal text-secondary-500">{t("product:pieces")}</p>
                  </div>
                  {/* tablo 4.kolon */}
                  <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[8%] min-w-[120px]">
                    <p className="text-md text-secondary-600 dark:text-[#F5F5F5] font-normal text-center">{item?.orderedQuantity || "0"}</p>
                    <p className="text-sm font-normal text-secondary-500">{t("product:pieces")}</p>
                  </div>
                  {/* tablo 5.kolon */}
                  <div className="flex h-full px-6 py-4 justify-between w-[50%] gap-x-6">
                    <div className="flex flex-col gap-y-3 w-1/3 min-w-[202px]">
                      <div
                        onClick={() => {
                          if (item.qualityQuantity === null || item.qualityQuantity === 0) {
                            Toast("error", "Stok miktarı 0 olan ürünler için stoktan çıkış yapamazsınız.");
                          } else {
                            toggleStockSelected(item);
                          }
                        }}
                        className={`flex gap-x-2 items-center w-fit ${
                          item.qualityQuantity === null || item.qualityQuantity === 0 ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                        }`}
                      >
                        <div
                          className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                            stockSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)
                              ? "bg-[#DC6803] border-[#DC6803] text-[#fff]"
                              : "border-[#D0D5DD]"
                          }`}
                        >
                          {stockSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate) && (
                            <CheckIcon />
                          )}
                        </div>
                        <div className="text-secondary-500 font-normal text-sm">{t("product:bringFromStock")}</div>
                        <span className="group flex relative">
                          <HelpCircleIcon />
                          <div className="hidden absolute min-w-[293px] max-w-[293px] w-[293px] min-h-[52px] max-h-[52px] h-[52px] group-hover:flex bottom-[26px] -left-[136px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                            <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:enterTheDeducted")}</p>
                          </div>
                          <span className="hidden absolute group-hover:flex bottom-[20px]">
                            <TriangleTooltipIcon />
                          </span>
                        </span>
                      </div>
                      <div className="flex w-full h-full">
                        <Input
                          icon={<QuantityIcon />}
                          theme="product"
                          type={"number"}
                          value={formData[item.id]?.[item.deliveryDate]?.stoktanGetir || ""}
                          onChange={(e) => handleInputChange(e.target.value, item.id, "stoktanGetir", item.deliveryDate)}
                          disabled={!stockSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-3 w-1/3 min-w-[202px]">
                      <div onClick={() => toggleProductionSelected(item)} className="flex gap-x-2 items-center cursor-pointer w-fit">
                        <div
                          className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                            productionSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)
                              ? "bg-[#DC6803] border-[#DC6803] text-[#fff]"
                              : "border-[#D0D5DD]"
                          }`}
                        >
                          {productionSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate) && (
                            <CheckIcon />
                          )}
                        </div>
                        <div className="text-secondary-500 font-normal text-sm">{t("product:sendToProduction")}</div>
                        <span className="group flex relative">
                          <HelpCircleIcon />
                          <div className="hidden absolute min-w-[370px] max-w-[370px] w-[370px] min-h-[70px] max-h-[70px] h-[70px] group-hover:flex bottom-[26px] -left-[175px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                            <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:youCanSendPortion")}</p>
                          </div>
                          <span className="hidden absolute group-hover:flex bottom-[20px]">
                            <TriangleTooltipIcon />
                          </span>
                        </span>
                      </div>
                      <div className="flex w-full h-full">
                        <Input
                          icon={<QuantityIcon />}
                          theme="product"
                          type={"number"}
                          value={formData[item.id]?.[item.deliveryDate]?.uretimeGonder || ""}
                          onChange={(e) => handleInputChange(e.target.value, item.id, "uretimeGonder", item.deliveryDate)}
                          disabled={!productionSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-3 w-1/3 min-w-[202px]">
                      <div onClick={() => toggleHoldSelected(item)} className="flex gap-x-2 items-center cursor-pointer w-fit">
                        <div
                          className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                            holdSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)
                              ? "bg-[#DC6803] border-[#DC6803] text-[#fff]"
                              : "border-[#D0D5DD]"
                          }`}
                        >
                          {holdSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate) && <CheckIcon />}
                        </div>
                        <div className="text-secondary-500 font-normal text-sm">{t("product:holdProduce")}</div>
                        <span className="group flex relative">
                          <HelpCircleIcon />
                          <div className="hidden absolute min-w-[293px] max-w-[293px] w-[293px] min-h-[52px] max-h-[52px] h-[52px] group-hover:flex bottom-[26px] -left-[186px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                            <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:youCanCreateOrder")}</p>
                          </div>
                          <span className="hidden absolute group-hover:flex bottom-[20px]">
                            <TriangleTooltipIcon />
                          </span>
                        </span>
                      </div>
                      <div className="flex w-full h-full">
                        <Input
                          icon={<QuantityIcon />}
                          theme="product"
                          type={"number"}
                          value={formData[item.id]?.[item.deliveryDate]?.hold || ""}
                          onChange={(e) => handleInputChange(e.target.value, item.id, "hold", item.deliveryDate)}
                          disabled={!holdSelected.some((selectedItem) => selectedItem.id === item.id && selectedItem.deliveryDate === item.deliveryDate)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOrderSecondStep;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

const HelpCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17461_3132)">
        <path
          d="M6.06016 6.00016C6.2169 5.55461 6.52626 5.1789 6.93347 4.93958C7.34067 4.70027 7.81943 4.61279 8.28495 4.69264C8.75047 4.77249 9.17271 5.01451 9.47688 5.37585C9.78105 5.73718 9.94753 6.19451 9.94683 6.66683C9.94683 8.00016 7.94683 8.66683 7.94683 8.66683M8.00016 11.3335H8.00683M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z"
          stroke="#98A2B3"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17461_3132">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TriangleTooltipIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9" viewBox="0 0 17 9" fill="none">
      <path
        d="M8.5 0.49993L14.5711 0.49993C15.462 0.49993 15.9081 1.57707 15.2782 2.20704L9.20711 8.2781C8.81658 8.66863 8.18342 8.66863 7.79289 8.2781L1.72183 2.20704C1.09186 1.57707 1.53803 0.49993 2.42893 0.49993L8.5 0.49993Z"
        fill="#0C111D"
      />
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 8.33332H2.5M13.3333 1.66666V4.99999M6.66667 1.66666V4.99999M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8212 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7335 17.5 14.3333V7.33332C17.5 5.93319 17.5 5.23313 17.2275 4.69835C16.9878 4.22794 16.6054 3.84549 16.135 3.60581C15.6002 3.33332 14.9001 3.33332 13.5 3.33332H6.5C5.09987 3.33332 4.3998 3.33332 3.86502 3.60581C3.39462 3.84549 3.01217 4.22794 2.77248 4.69835C2.5 5.23313 2.5 5.93319 2.5 7.33332V14.3333C2.5 15.7335 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const OrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6673 8.74999V5.66666C16.6673 4.26653 16.6673 3.56646 16.3948 3.03168C16.1552 2.56128 15.7727 2.17882 15.3023 1.93914C14.7675 1.66666 14.0674 1.66666 12.6673 1.66666H7.33398C5.93385 1.66666 5.23379 1.66666 4.69901 1.93914C4.2286 2.17882 3.84615 2.56128 3.60647 3.03168C3.33398 3.56646 3.33398 4.26653 3.33398 5.66666V14.3333C3.33398 15.7335 3.33398 16.4335 3.60647 16.9683C3.84615 17.4387 4.2286 17.8212 4.69901 18.0608C5.23379 18.3333 5.93385 18.3333 7.33398 18.3333M10.0007 7.49999H6.66732M11.6673 4.99999H6.66732M8.33398 9.99999H6.66732M9.83772 12.6321L12.8562 10.9454C12.9984 10.866 13.0694 10.8263 13.1447 10.8107C13.2114 10.7969 13.2801 10.7968 13.3468 10.8105C13.4221 10.826 13.4933 10.8656 13.6355 10.9447L16.6673 12.6321M9.83772 12.6321L13.2457 14.531M9.83772 12.6321V15.9548C9.83772 16.1254 9.83772 16.2108 9.86279 16.2869C9.88497 16.3543 9.92123 16.4162 9.96917 16.4685C10.0234 16.5276 10.0978 16.5693 10.2466 16.6527L13.2457 18.3333M16.6673 12.6321L13.2457 14.531M16.6673 12.6321V15.9539C16.6673 16.1248 16.6673 16.2103 16.6422 16.2866C16.6199 16.3541 16.5835 16.416 16.5355 16.4683C16.4811 16.5275 16.4065 16.5691 16.2572 16.6524L13.2457 18.3333M13.2457 14.531V18.3333"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
