import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UpuEnergy } from "../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { Toast } from "../../utils/toastify/toast";
import { equipmentService } from "../../services";
import moment from "moment";
import CCoundAndEnergyChart from "./Tabs/CCoundAndEnergyChart";
import CCountAndEnergyChart2 from "./Tabs/CCountAndEnergyChart2";
import { Loader } from "../../assets/icons/stepsIcons";
import { reportService } from "../../services/report.service";
import EquipmentEnergy from "./Twin/components/EquipmentEnergy";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import NoData from "../../steps/step-navigation/components/NoData";
import useWindowDimensions from "../../hooks/useWindowDimension";
import EnergHeader from "../EnergHeader";

const Energy = () => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState("total_kwh");
  const [loading, setLoading] = useState(false);
  const [operationsEmpty, setOperationsEmpty] = useState(false);
  const [selectedSecondCategory, setSelectedSecondCategory] =
    useState("total_kwh");
  const { width, height } = useWindowDimensions();
  const [selectedLegend, setSelectedLegend] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [selectedNode, setSelectedNode] = useState(null);
  const [energyData, setEnergyData] = useState([]);
  const monthSelected = useCallback(
    (month) => {
      switch (month) {
        case "1":
          return `${t("legends:january")}`;
        case "2":
          return `${t("legends:february")}`;
        case "3":
          return `${t("legends:march")}`;
        case "4":
          return `${t("legends:april")}`;
        case "5":
          return `${t("legends:may")}`;
        case "6":
          return `${t("legends:june")}`;
        case "7":
          return `${t("legends:july")}`;
        case "8":
          return `${t("legends:august")}`;
        case "9":
          return `${t("legends:september")}`;
        case "10":
          return `${t("legends:october")}`;
        case "11":
          return `${t("legends:november")}`;
        case "12":
          return `${t("legends:december")}`;
      }
    },
    [t]
  );

  const [datas, setDatas] = useState({});
  const [month, setMonth] = useState(moment().format("YYYY-MM"));
  const [Datayear, setDataYear] = useState();

  const months = useMemo(
    () => [
      { value: 1, name: `${t("legends:january")}` },
      { value: 2, name: `${t("legends:february")}` },
      { value: 3, name: `${t("legends:march")}` },
      { value: 4, name: `${t("legends:april")}` },
      { value: 5, name: `${t("legends:may")}` },
      { value: 6, name: `${t("legends:june")}` },
      { value: 7, name: `${t("legends:july")}` },
      { value: 8, name: `${t("legends:august")}` },
      { value: 9, name: `${t("legends:september")}` },
      { value: 10, name: `${t("legends:october")}` },
      { value: 11, name: `${t("legends:november")}` },
      { value: 12, name: `${t("legends:december")}` },
    ],
    []
  );

  const getMonthName = (monthValue) => {
    switch (monthValue) {
      case "01":
        return `${t("legends:january")}`;
      case "02":
        return `${t("legends:february")}`;
      case "03":
        return `${t("legends:march")}`;
      case "04":
        return `${t("legends:april")}`;
      case "05":
        return `${t("legends:may")}`;
      case "06":
        return `${t("legends:june")}`;
      case "07":
        return `${t("legends:july")}`;
      case "08":
        return `${t("legends:august")}`;
      case "09":
        return `${t("legends:september")}`;
      case "10":
        return `${t("legends:october")}`;
      case "11":
        return `${t("legends:november")}`;
      case "12":
        return `${t("legends:december")}`;
      default:
        return "";
    }
  };

  const years = useMemo(
    () => [
      { value: 2020, name: "2020" },
      { value: 2021, name: "2021" },
      { value: 2022, name: "2022" },
      { value: 2023, name: "2023" },
      { value: 2024, name: "2024" },
    ],
    []
  );

  const changeMonthOfDate = useCallback((originalDate, newMonth) => {
    const newDate = new Date(originalDate);
    newDate.setMonth(newMonth - 1);
    return newDate;
  }, []);

  const handleData = useCallback(
    async (name) => {
      if (name) {
        const newMonth = changeMonthOfDate(month, name);
        setMonth(newMonth);
      } else {
      }
      setLoading(true);
      await equipmentService
        .energyData(moment(month).format("YYYY-MM-DD"))
        .then((res) => {
          setDatas(res?.data);
        })
        .catch((err) => {
          Toast("error", err?.response?.data?.message);
        });
      setLoading(false);
    },
    [changeMonthOfDate, month]
  );

  const processData = datas?.operationNodeChart
    ?.find((item) => {
      return item.month == moment(month).format("M");
    })
    ?.operations?.find((op) => {
      return op.processId == selectedLegend;
    });
  const sortData = processData?.equipments.sort(
    (a, b) => b?.total_cost - a?.total_cost
  );

  const transformedData = useMemo(() => {
    return sortData?.map((item) => ({
      id: item?.processId,
      name: item?.name,
      nodeId: item?.nodeId,
      value: item?.total_kwh,
    }));
  }, [sortData]);

  const monthlyData = useMemo(() => {
    return datas?.operationNodeChart?.find((item) => {
      return item.month == moment(month).format("M");
    });
  }, [datas, month]);

  useEffect(() => {
    controlEmpty(datas?.companyOperationChart);
  }, [datas]);

  useEffect(() => {
    handleData();
  }, [handleData]);

  const controlEmpty = useCallback((array) => {
    const allEmpty = array?.every((item) => item?.operations?.length === 0);
    setOperationsEmpty(allEmpty);
  }, []);

  useEffect(() => {
    const nodeIdToUse = selectedNode
      ? selectedNode.nodeId
      : transformedData?.[0]?.nodeId;

    if (nodeIdToUse) {
      reportService
        .getBarChartReportKwh(
          nodeIdToUse,
          moment()
            .year(Datayear || 2024)
            .format("YYYY-MM-DD")
        )
        .then((res) => {
          setEnergyData(res?.data);
        });
    }
  }, [selectedNode, sortData]);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="w-full flex flex-col md:h-full">
          <EnergHeader
            month={month}
            setMonth={setMonth}
            changeMonthOfDate={changeMonthOfDate}
            years={years}
            months={months}
            getMonthName={getMonthName}
            icon={<UpuEnergy />}
            iconLeft={<HumburgerIcon />}
          />
          <hr className=" mt-0.5  border-[0.5px] border-secondary-200 dark:border-[#202020]" />
          <div className="flex flex-col h-[800px] md:h-full overflow-y-scroll scrollbar-hide overflow-x-hidden">
            <div className="w-full h-full my-5">
              {operationsEmpty ? (
                <>
                  <div className="w-full flex min-h-full gap-x-4  h-full  border-[#D0D5DD] border-[1px] rounded-lg mt-5">
                    <div
                      style={{
                        height: "100%",
                        minHeight: (height - 200) / 2,
                      }}
                      className="w-full h-full flex items-center justify-center"
                    >
                      <NoData
                        theme={"machine"}
                        header={t("label:noData")}
                        button={false}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <CCoundAndEnergyChart
                  charts={"energy"}
                  handleData={handleData}
                  page={"energy"}
                  setSelectedNode={setSelectedNode}
                  t={t}
                  totalData={datas?.companyOperationChart}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  setSelectedLegend={setSelectedLegend}
                  selectedSecondCategory={selectedSecondCategory}
                  setSelectedSecondCategory={setSelectedSecondCategory}
                  grid={"energy"}
                  month={month}
                  monthlyData={datas?.operationNodeChart?.find((item) => {
                    return item?.month == moment(month).format("M");
                  })}
                  processData={datas?.operationNodeChart
                    ?.find((item) => {
                      return item.month == moment(month).format("M");
                    })
                    ?.operations?.find((op) => {
                      return op.processId == selectedLegend;
                    })}
                  yearlyProcessData={datas?.operationNodeChart?.map((month) => {
                    return month?.operations?.find((op) => {
                      return (
                        op.processId == selectedLegend ||
                        op.processId == "00000000-0000-0000-0000-000000000000"
                      );
                    });
                  })}
                />
              )}
              <div className="mt-5">
                <CCountAndEnergyChart2
                  charts={"energy"}
                  handleData={handleData}
                  t={t}
                  totalData={datas?.operationNodeChart}
                  selectedSecondCategory={selectedSecondCategory}
                  setSelectedSecondCategory={setSelectedSecondCategory}
                  setSelectedLegend={setSelectedLegend}
                  selectedLegend={selectedLegend}
                  grid={"energy"}
                  month={month}
                  monthlyData={monthlyData}
                  processData={processData}
                  yearlyProcessData={datas?.operationNodeChart?.map((month) => {
                    return month?.operations?.find((op) => {
                      return (
                        op.processId == selectedLegend ||
                        op.processId == "00000000-0000-0000-0000-000000000000"
                      );
                    });
                  })}
                  selectedCategory={selectedSecondCategory}
                  setSelectedCategory={setSelectedSecondCategory}
                  setSelectedNode={setSelectedNode}
                  chartType="onlyEnergy"
                />
              </div>
              {/* {selectedNode &&
              selectedNode != "00000000-0000-0000-0000-000000000000" && (
                <EquipmentEnergy
                  selectedNode={selectedNode}
                  selectedLegend={selectedLegend}
                  energyData={energyData}
                  xAsisData={energyData?.map((row) =>
                    monthSelected(`${row?.month}`)
                  )}
                  month={month}
                />
              )} */}
              {monthlyData?.operations?.length >= 1 ? (
                <>
                  {selectedNode ? (
                    <EquipmentEnergy
                      selectedNode={selectedNode}
                      selectedLegend={selectedLegend}
                      energyData={energyData}
                      xAsisData={energyData?.map((row) =>
                        monthSelected(`${row?.month}`)
                      )}
                      month={month}
                    />
                  ) : (
                    <EquipmentEnergy
                      selectedNode={transformedData?.[0]}
                      selectedLegend={selectedLegend}
                      energyData={energyData}
                      xAsisData={energyData?.map((row) =>
                        monthSelected(`${row?.month}`)
                      )}
                      month={month}
                    />
                  )}
                </>
              ) : (
                <>
                  <div className="w-full flex min-h-full gap-x-4  h-full  border-[#D0D5DD] border-[1px] rounded-lg mt-5">
                    <div
                      style={{
                        height: "100%",
                        minHeight: (height - 200) / 2,
                      }}
                      className="w-full h-full flex items-center justify-center"
                    >
                      <NoData
                        theme={"machine"}
                        header={t("label:noData")}
                        button={false}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(Energy);
