import React, { useState } from "react";
import { CheckIcon, ClockIcon, DateCardIcon, DeleteIcon, DotsVerticalIcon, FlexProductIcon, PieceIcon, PlusIcon } from "../../card-icons";
import CustomImage from "../../../CustomImage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { junctionService } from "../../../../services/junction.service";
import { Toast } from "../../../../utils/toastify/toast";
import AlertModal from "../../../alert-Modal/Alert-Modal";
import { Tooltip } from "react-tooltip";
const SupplyPlannedCard = ({data, refetch, setIsSelectUserModal, setSelectedJunction,operation}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const menuOptions = [{ label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> }];

  const onDeleted = async () => {
    await junctionService.deleteSupplierForJunction(data.id).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data.id);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };
  return (
    <>
      <div className="flex w-full flex-col border border-t-4 items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group">
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
            {data?.supplier?.image ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${t("product:supplierName")} : ${data?.supplier?.name} `}
              >
                <CustomImage
                  borderRadius={2}
                  src={data?.supplier?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border text-white text-xl select-none flex items-center justify-center bg-[#DC6803] border-[#DC6803]">
                {data?.job?.product?.name.charAt(0).toUpperCase() || "No Order"?.charAt(0).toUpperCase()}
              </div>
            )}
            <div className="flex flex-col items-start gap-y-[6px]">
            <div className="flex flex-row items-center gap-x-1 w-full">
                {data?.productType === "flex" ? (
                  <>
                    <FlexProductIcon />
                    <p className="text-sm font-semibold text-secondary-900 truncate w-[330px]">{data?.supplier?.name || "--"}</p>
                  </>
                ) : (
                  <p className="text-sm font-semibold text-secondary-900 truncate w-[330px]">{data?.supplier?.name || "--"}</p>
                )}
              </div>
              
              <div className="flex items-center flex-row gap-x-1">
                <div className="flex flex-row items-center">
                  <p className="text-xs font-normal text-secondary-600">{t("product:selectPersonnel")}</p>

                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
                setIsSelectUserModal(true);
                setSelectedJunction(data);
            }}
            type="button"
            className="min-w-[48px] h-[48px] rounded-full border-dashed ml-auto border border-gray-300 flex items-center justify-center"
          >
            <PlusIcon />
          </button>
        </div>
        <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB] border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
          <div className="flex flex-row items-center">
            <PieceIcon />
            <p className="text-xs font-medium ml-0.5  truncate text-secondary-600">{data?.header?.piece || "--"}</p>
            <p className="text-xxs font-normal ml-0.5  truncate text-secondary-600">{t("product:pcs")}</p>
          </div>
          <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 px-2 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
            <CheckIcon />
            <p className="text-xxs text text-secondary-600 font-semibold">{t("product:plannedNews")}</p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {menuOptions?.map((item, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "delete") {
                      setIsAlert(true);
                    }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                  }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}

        <Tooltip className="z-[120]" id={`${data?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
    </>
  );
};

export default SupplyPlannedCard;
