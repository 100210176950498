import React from "react";
import { Button } from "../../../components";
import {
  CoinsStackedIcon,
  MeasuringIcon,
  PlusIcon,
} from "../../../assets/icons/productIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";

const AddRawMaterialProductCard = ({
  onClick,
  image,
  name,
  stockId,
  unit,
  item,
  unitPrice,
  isActive,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-y-3 w-full">
      <div
        className={`flex bg-white border min-h-[90px] max-h-[90px] items-center w-full px-3 py-2 rounded-lg border-[#D0D5DD] gap-x-2 shadow-sm `}
      >
        <div className="flex w-full h-full flex-col gap-y-1">
          <div className="flex gap-x-3">
            {!image ? (
              <span className="min-w-[64px] max-w-[64px] min-h-[48px] max-h-[48px] rounded border-[#0000001A] bg-black"></span>
            ) : (
              <img
                className="min-w-[64px] max-w-[64px] min-h-[48px] max-h-[48px] rounded border-[#0000001A]"
                src={generateFileUrl(image)}
                width={64}
                height={48}
                alt={name?.charAt(0).toUpperCase()}
              />
            )}

            <div className="flex flex-col justify-center">
              <span className="text-[#101828] font-semibold text-base">
                {name || t("product:productName")}
              </span>
              <span className="text-[#475467] font-normal text-sm">
                Stok ID: {stockId || 0}
              </span>
            </div>
          </div>
          <div className="flex">
            <div className="flex gap-x-1 items-center pr-[6px] py-[2px]">
              <MeasuringIcon />
              <span className="flex gap-x-[2px] items-center">
                <span className="text-[#475467] font-medium text-xs">
                  {unit || "Metre"}
                </span>
                <span className="text-[#667085] font-normal text-[10px] leading-4">
                  {t("product:units")}
                </span>
              </span>
            </div>
            <div className="flex gap-x-1 items-center pr-[6px] py-[2px]">
              <CoinsStackedIcon />
              <span className="flex gap-x-[2px] items-center">
                <span className="text-[#475467] font-medium text-xs">
                  ₺{unitPrice || 0}
                </span>
                <span className="text-[#667085] font-normal text-[10px] leading-4">
                  {t("product:units")} {t("product:prices")}
                </span>
              </span>
            </div>
          </div>
        </div>
        {isActive == true ? (
          <></>
        ) : (
          <div className="flex h-full items-center justify-center">
            <Button
              colorType={"secondary-product"}
              size={"sm"}
              onClick={onClick}
              iconLeft={<PlusIcon />}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddRawMaterialProductCard;
