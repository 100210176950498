import React from "react";
import { Button } from "../../../components";
import { Filter } from "../../../assets/icons/machineIcons";
import { useNavigate } from "react-router-dom";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { PlusIcon } from "../../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";

const CompanyDetailsUsers = ({ isToday, todayData, users }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="w-1/3 rounded-xl border border-gray-200 shadow flex flex-col">
      <div className="w-full h-[72px] min-h-[72px] flex items-center px-4 pl-6 border-b border-secondary-200">
        <Icon />
        <h1 className="text-lg font-semibold text-secondary-900 ml-2">
          {isToday
            ? t("person:availablePersonnels")
            : t("person:leadersOfToday")}
        </h1>

        <div className="ml-auto">
          {isToday ? (
            <Button
              colorType={"secondary-gray"}
              iconLeft={<Filter />}
              size={"sm"}
            />
          ) : (
            <Button
              size={"sm"}
              label={
                <p className="font-codecMedium font-semibold">upu.point</p>
              }
              onClick={() => navigate("/app/person/point")}
              colorType={"secondary-person"}
              iconLeft={<ArrowUp />}
            />
          )}
        </div>
      </div>
      <div className="flex-1 flex flex-col w-full overflow-y-scroll scrollbar-hide">
        {isToday ? (
          <>
            {todayData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="w-full min-h-[73px] h-[73px] flex items-center px-3 pl-5 border-b border-secondary-200"
                >
                  {item?.avatar ? (
                    <img
                      src={generateFileUrl(item?.avatar)}
                      className="min-w-[40px] w-[40px] h-[40px] rounded-full"
                    />
                  ) : (
                    <NoAvatar
                      color={"#00000070"}
                      name={item?.name}
                      lastName={item?.lastName}
                      size={40}
                    />
                  )}

                  <div className="flex flex-col ml-3">
                    <p className="text-sm font-semibold text-secondary-700">
                      {item?.name}
                    </p>
                  </div>

                  <button className="ml-auto w-[60px] h-[45px] border border-dashed border-gray-300 flex items-center justify-center rounded-lg text-secondary-500">
                    <PlusIcon />
                  </button>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {todayData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="w-full min-h-[73px] h-[73px] flex items-center px-3 pl-5 border-b border-secondary-200"
                >
                  {item?.avatar ? (
                    <img
                      src={generateFileUrl(item?.avatar)}
                      className="min-w-[40px] w-[40px] h-[40px] rounded-full"
                    />
                  ) : (
                    <NoAvatar
                      color={"#00000070"}
                      name={item?.name}
                      lastName={item?.lastName}
                      size={40}
                    />
                  )}

                  <div className="flex flex-col ml-3">
                    <p className="text-sm font-semibold text-secondary-700">
                      {item?.name}
                    </p>
                  </div>

                  <div className="ml-auto items-center flex">
                    <PointIcon />
                    <p className="text-sm text-secondary-700 font-medium">
                      {item?.upuPoint}
                    </p>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

const PointIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M13.7126 12.4076C13.7126 13.1351 13.5693 13.8555 13.2908 14.5277C13.0124 15.1998 12.6043 15.8105 12.0899 16.3249C11.5754 16.8392 10.9647 17.2471 10.2926 17.5254C9.62044 17.8036 8.90011 17.9466 8.17271 17.9463V15.6033C8.7483 15.609 9.31569 15.4666 9.82049 15.1899C10.3076 14.9235 10.7121 14.5282 10.9898 14.0474C11.2681 13.5514 11.4142 12.9921 11.4142 12.4233C11.4142 11.8545 11.2681 11.2953 10.9898 10.7992C10.7125 10.3188 10.3078 9.92438 9.82049 9.65946C9.30805 9.38976 8.73769 9.24882 8.15864 9.24882C7.57958 9.24882 7.00923 9.38976 6.49679 9.65946C6.01115 9.92286 5.60794 10.3156 5.33186 10.7943C5.13706 11.1305 5.00767 11.5005 4.95054 11.8849C4.11078 11.3451 3.42003 10.603 2.94169 9.72663C2.46335 8.85029 2.21275 7.86782 2.21289 6.86939H8.17271C8.90009 6.86902 9.62041 7.012 10.2925 7.29016C10.9646 7.56832 11.5754 7.9762 12.0898 8.49051C12.6043 9.00482 13.0124 9.61546 13.2908 10.2876C13.5693 10.9597 13.7126 11.6801 13.7126 12.4076Z"
        fill="#C7A109"
      />
      <path
        d="M13.7119 7.71289V5.17303H17.9505L13.7119 7.71289Z"
        fill="#C7A109"
      />
      <path
        d="M9.25429 13.6107C8.9651 13.913 8.59668 14.0655 8.14902 14.068H8.1378C7.6848 14.068 7.31263 13.9156 7.02131 13.6107C6.76747 13.3422 6.61583 12.989 6.59395 12.6154C6.58861 12.5544 6.58594 12.4924 6.58594 12.4282C6.58594 11.945 6.7316 11.551 7.02292 11.2461C7.31424 10.9413 7.6864 10.7886 8.1394 10.7883C8.59241 10.7883 8.96457 10.9409 9.25589 11.2461C9.54721 11.5514 9.69287 11.9454 9.69287 12.4282C9.6918 12.9124 9.54561 13.3066 9.25429 13.6107Z"
        fill="#C7A109"
      />
      <path
        d="M14.6379 2.49712C14.6379 2.2793 14.4894 2.10212 14.3069 2.10212C14.1243 2.10212 13.9758 2.2793 13.9758 2.49712C13.9758 2.56807 13.9918 2.63452 14.0193 2.69212L12.5419 3.39722L11.9746 2.04371C12.1527 1.93509 12.2749 1.71413 12.2749 1.45962C12.2749 1.09804 12.0283 0.803894 11.7253 0.803894C11.4222 0.803894 11.1757 1.09807 11.1757 1.45962C11.1757 1.71413 11.2979 1.93506 11.4759 2.04371L10.9087 3.39722L9.42328 2.68834C9.4497 2.63162 9.46487 2.56647 9.46487 2.49712C9.46487 2.2793 9.31636 2.10212 9.13379 2.10212C8.95122 2.10212 8.80273 2.2793 8.80273 2.49712C8.80273 2.65716 8.88305 2.79499 8.99802 2.85697L9.74176 5.05543C9.76729 5.1309 9.82863 5.18028 9.89684 5.18028H13.576C13.6448 5.18028 13.7066 5.12995 13.7317 5.05349L14.4549 2.8499C14.5633 2.78493 14.6379 2.65139 14.6379 2.49712Z"
        fill="#C7A109"
      />
    </svg>
  );
};

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M2 19C2 16.8 3.8 15 6 15H10C12.2 15 14 16.8 14 19"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4997 5.99918C11.8997 7.39918 11.8997 9.59918 10.4997 10.8992C9.09968 12.1992 6.89968 12.2992 5.59968 10.8992C4.29968 9.49918 4.19968 7.39918 5.49968 5.99918C6.79968 4.59918 9.09968 4.69918 10.4997 5.99918"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 14H19C20.7 14 22 15.3 22 17"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2999 6.69844C20.2999 7.69844 20.2999 9.29844 19.2999 10.1984C18.2999 11.0984 16.6999 11.1984 15.7999 10.1984C14.8999 9.19844 14.7999 7.59844 15.7999 6.69844C16.6999 5.79844 18.2999 5.79844 19.2999 6.69844"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.8335 14.1667L14.1668 5.83334M14.1668 5.83334H5.8335M14.1668 5.83334V14.1667"
        stroke="#107569"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompanyDetailsUsers;
