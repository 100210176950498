import React, { useState, useEffect } from "react";
import { consoleStore } from "../../../stores/console.store";
import * as yup from "yup";
import CloseButton from "../../buttons/CloseButton";
import { personalService } from "../../../services/personal.service";
import PersonnelAndEquipmentSelector from "../../inputs/PersonnelAndEquipmentSelector";
import { useTranslation } from "react-i18next";
import Selector from "../../inputs/Selector";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { Toast } from "../../../utils/toastify/toast";
import DateTimeInput from "../../inputs/DateTimeInput";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import HourTimeInput from "../../inputs/HourTimeInput";
import { orderService } from "../../../services/order.service";
import { useQuery } from "@tanstack/react-query";
import { DeleteIcon, PlusIcon } from "../../../assets/icons/machineIcons";
import { observer } from "mobx-react-lite";
import { junctionService } from "../../../services/junction.service";

const AddManuelJunctionChildren = observer(({ process, setIsOpen, consoleStart, consoleEnd, stationId, setConsoleStart, setConsoleEnd }) => {
  const { t } = useTranslation();
  const [personels, setPersonels] = useState([]);
  const [validateError, setValidateError] = useState(false);
  const [endValidateError, setEndValidateError] = useState(false);

  const getActiveUser = async () => {
    const data = await personalService.activePersonals();
    let formatttedData = data?.data?.map((e, i) => {
      return {
        label: e?.name + " " + e?.lastName,
        value: e?.id,
        image: e?.avatar,
      };
    });
    setPersonels(formatttedData);
  };

  const schema = yup.object({
    workPiece: yup.number(),
    personnel: yup.string(),
    startTime: yup.date(),
    endTime: yup.date(),
    type: yup.string().required(`${t("validation:empty")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (value) => {
    const send = {
      startDate: moment(consoleStart).utc().format(),
      endDate: moment(consoleEnd).utc().format(),
      station: stationId,
      user: value?.personnel,
      type: value?.type,
      madeWorkCount: value?.workPiece,
      internalOperation: process, //torna id
      job: selectedProduct,
      header: value?.operation, //operaryon id
      subEvents: breakTimes
        .filter((breakTime) => moment(breakTime.start).isValid() && moment(breakTime.end).isValid())
        .map((breakTime) => ({
          startDate: moment(breakTime.start).utc().format(),
          endDate: moment(breakTime.end).utc().format(),
        })),
    };

    // if (validateError || endValidateError) {
    //   return Toast("error", "Hatalı tarih ve saat aralığı. Lütfen tarihleri kontrol");
    // } else {
    //   junctionService.addJunctionManual(send).then((res) => {
    //     res.data.code === 0 ? Toast("success", res?.data?.message) : Toast("error", res?.data?.message);
    //     res.data.code === 0 && setIsOpen(false);
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 1000);
    //   });
    // }

    // const startDateUnix = moment(startDate).unix();
    // const endDateUnix = moment(endDate).unix();
    // if (startDateUnix > endDateUnix) {
    //   Toast("error", t("product:qualityUpdateError"));
    //   return;
    // } else {
    //   await junctionService.updateJunction(junctionId, send).then((res) => {
    //     if (res.data.code === 0) {
    //       refetch();
    //       onClose();
    //       Toast("success", res?.data?.message);
    //     } else {
    //       onClose();
    //       Toast("error", res?.data?.message);
    //     }
    //   });
    // }
  };
  const [breakTimes, setBreakTimes] = useState([{ start: "", end: "" }]);

  const [operations, setOperations] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const addBreakTime = () => {
    setBreakTimes([...breakTimes, { start: "", end: "" }]);
  };

  const handleBreakTimeChange = (index, field, value) => {
    const updatedBreakTimes = [...breakTimes];
    updatedBreakTimes[index][field] = value;
    setBreakTimes(updatedBreakTimes);
  };

  const operationId = process;
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (operationId) {
        try {
          const response = await orderService.products(operationId);
          setProducts(response);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      }
    };

    fetchProducts();
  }, [operationId]);

  const manipulateProducts = products?.data?.map((unit, index) => ({
    label: unit?.product?.name,
    value: unit?.id,
  }));

  const junctionTypes = [
    { label: t("product:work"), value: "work" },
    { label: t("product:setup"), value: "setup" },
  ];

  const handleProductChange = async (productId) => {
    
    const selectedProduct = await products?.data?.find((product) => product.id === productId);

    if (selectedProduct) {
      setOperations(
        selectedProduct.operations.map((operation) => ({
          label: `${operation.internalOperation.step}. ${operation.internalOperation.process.name}`,
          value: operation?.id,
        }))
      );
    } else {
      setOperations([]);
    }
  };

  useEffect(() => {
    getActiveUser();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full pl-[104px] pr-4  border-t-[2px] border-primary flex flex-col  ">
      <div className="flex items-center justify-between">
        <p>Add Manuel Junction</p>
        <div
          onClick={() => {
            setOperations([]);
            setSelectedProduct(null);
            setConsoleEnd("");
            setConsoleStart("");
            setIsOpen(false);
            setBreakTimes([{ start: "", end: "" }]);
            setValue("type", "");
          }}
          className="w-11 h-11 max-h-11 max-w-11"
        >
          <CloseButton />
        </div>
      </div>
      <div className="w-full h-full max-h-[330px] flex items-start mt-2">
        <div className="w-1/3 h-full flex flex-col gap-y-4 border-r-[1px] border-[#E4E7EC] px-4">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                value={value}
                items={junctionTypes}
                theme={"product"}
                label={t("product:workType")}
                onChange={onChange}
                onBlur={onBlur}
                validate={errors?.type ? "error" : ""}
                errorMessage={errors?.type ? errors?.type?.message : ""}
              />
            )}
            name="type"
          />

          <div className="flex flex-col gap-y-[6px]">
            <span className="text-sm w-full text-[#344054] font-medium">Başlangıç Tarih & Saati</span>
            <input
              className="border px-3 py-2 rounded-lg shadow-xs border-[#D0D5DD] outline-none"
              type="datetime-local"
              value={consoleStart}
              onChange={(e) => {
                if (moment(e.target.value).isBefore(moment(consoleStart))) {
                  setValidateError(true);
                  setConsoleStart(e.target.value);
                } else {
                  setConsoleStart(e.target.value);
                  setValidateError(false);
                }
              }}
              min={consoleStart}
              max={consoleEnd}
            />
            {validateError == true ? <span className="text-xs text-red-500">Başlangıç tarihi minimum olması gereken değerden küçük olamaz</span> : null}
          </div>
          <div className="flex flex-col gap-y-[6px]">
            <span className="text-sm w-full text-[#344054] font-medium">Bitiş Tarih & Saati</span>
            <input
              className="border px-3 py-2 rounded-lg shadow-xs border-[#D0D5DD] outline-none"
              type="datetime-local"
              value={consoleEnd}
              onChange={(e) => {
                if (moment(e.target.value).isBefore(moment(consoleStart)) || moment(e.target.value).isAfter(moment(consoleEnd))) {
                  setEndValidateError(true);
                  setConsoleEnd(e.target.value);
                } else {
                  setConsoleEnd(e.target.value);
                  setEndValidateError(false);
                }
              }}
              min={consoleStart}
              max={consoleEnd}
            />
            {endValidateError == true ? <span className="text-xs text-red-500">Bitiş tarihi minimum ve maksimum değerler arasında olmalıdır </span> : null}
          </div>

          {/* <input type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)}  />
          <input type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} /> */}
        </div>
        <div className="w-1/3 h-full px-4 flex flex-col gap-y-4 border-r-[1px] border-[#E4E7EC]">
          <Selector
            items={manipulateProducts}
            theme={"product"}
            label={"Ürün"}
            onChange={(e) => {
              handleProductChange(e);
              setSelectedProduct(e);
            }}
            value={selectedProduct}
          />

          <div className="w-full flex items-center gap-x-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  value={value}
                  items={operations}
                  theme={"product"}
                  label={"Operasyon Sırası"}
                  onChange={onChange}
                  disabled={operations?.length === 0 ? true : false}
                  onBlur={onBlur}
                  validate={errors?.type ? "error" : ""}
                  errorMessage={errors?.type ? errors?.type?.message : ""}
                />
              )}
              name="operation"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  theme={"product"}
                  label={t("product:numberOfProducts")}
                  errorMessage={errors?.madeWorkCount?.message}
                  validate={errors?.madeWorkCount ? "error" : ""}
                />
              )}
              name="workPiece"
            />
          </div>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <PersonnelAndEquipmentSelector
                value={value}
                items={personels}
                icon={"personnel"}
                image={""}
                theme={"product"}
                label={t("personals:personal")}
                onChange={onChange}
                onBlur={onBlur}
                validate={errors?.personnel ? "error" : ""}
                errorMessage={errors?.personnel ? errors?.personnel?.message : ""}
              />
            )}
            name="personnel"
          />
        </div>
        <div className="w-1/3  h-full px-4">
          <div className="flex   max-h-[75%] flex-col overflow-y-auto gap-y-4 overflow-x-hidden scrollbar-hide">
            {breakTimes.map((breakTime, index) => (
              <div key={index} className="flex items-end gap-x-4">
                {/* <HourTimeInput
                  label={`${index + 1}.Mola Başlangıç`}
                  value={breakTime.start}
                  onChange={(value) => handleBreakTimeChange(index, "start", value)}
                /> */}

                <input
                  type="datetime-local"
                  label={`${index + 1}.Mola Başlangıç`}
                  value={breakTime.start}
                  onChange={(e) => handleBreakTimeChange(index, "start", e.target.value)}
                  min={consoleStart}
                  max={consoleEnd}
                  className="w-full"
                />

                <input
                  type="datetime-local"
                  label={`${index + 1}.Mola Bitiş`}
                  value={breakTime.end}
                  onChange={(e) => handleBreakTimeChange(index, "end", e.target.value)}
                  min={consoleStart}
                  max={consoleEnd}
                  className="w-full"
                />

                <Button
                  size={"md"}
                  colorType={"secondary-error"}
                  iconLeft={<DeleteIcon />}
                  onClick={() => setBreakTimes(breakTimes.filter((_, i) => i !== index))}
                />
              </div>
            ))}
          </div>

          <span className="flex mt-6">
            <Button size={"md"} iconLeft={<PlusIcon />} colorType={"secondary-gray"} label={"Mola Ekle"} onClick={addBreakTime} />
          </span>
        </div>
      </div>
      <div className="flex gap-x-3 w-full min-h-[20px] justify-end items-center pt-4 my-2 mb-4 border-t border-[#E4E7EC]">
        <span className="flex max-h-[44px] min-w-[200px] max-w-[200px]">
          <Button
            onClick={() => {
              setOperations([]);
              setSelectedProduct(null);
              setConsoleEnd("");
              setConsoleStart("");
              setIsOpen(false);
              setBreakTimes([{ start: "", end: "" }]);
              setValue("type", "");
            }}
            colorType={"secondary-gray"}
            label={"Vazgeç"}
            size={"lg"}
          />
        </span>
        <span className="flex max-h-[44px] min-w-[200px] max-w-[200px]">
          <Button colorType={"primary-machine"} label={"Kaydet"} size={"lg"} type={"submit"} />
        </span>
      </div>
    </form>
  );
});

export default AddManuelJunctionChildren;
