import React, { useEffect, useState } from "react";
import { UpuShift } from "../../assets/icons";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button, CustomModal } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import ShiftCalendarTable from "./components/ShiftCalendarTable";
import { companyService } from "../../services/company.service";
import SavedShiftModal from "./components/SavedShiftModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Shift = () => {
  const {t} = useTranslation()
  const { settings } = useStore();
  const [defaultShift, setDefaultShift] = useState([]);
  const [nonstandardShifts, setNonstandardShifts] = useState([]);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const getDefaultShifts = async () => {
    await companyService.getDefaultShift().then(({ data }) => {
      setDefaultShift(
        data?.map((item) => {
          return { ...item, times: item?.shiftGroups?.shifts?.flat() };
        })
      );
    });
  };
  

  const handleNonstandartShifts = async () => {
    const { data } = await companyService.getNonstandartShift();
    setNonstandardShifts(
      data?.map((item) => {
        return { ...item, times: item?.shiftGroups?.shifts?.flat() };
      })
    );
  };

  useEffect(() => {
    getDefaultShifts();
    handleNonstandartShifts();
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col overflow-hidden">
        <div className="w-full flex justify-between items-center border-b border-secondary-200 py-3 min-h-[56px]">
          <div className="w-full h-full flex items-center">
            <span className="mr-3 md:flex sm:flex xs:flex hidden">
              <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
            </span>
            <UpuShift width={130} height={30} />
            <div className="ml-auto flex items-center gap-3 mr-1">
              <Button
                colorType={"secondary-gray"}
                size={"md"}
                onClick={() => {
                  navigate("saved-shifts");
                }}
                label={t("addShift:registeredShifts")}
              />
              <Button
                colorType={"secondary-machine"}
                size={"md"}
                onClick={() => {
                  navigate("old-day-shifts");
                }}
                label={t("product:thePastDays")}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-1 overflow-y-auto p-1 w-full pt-4 flex-col gap-y-4 scrollbar-hide">
          <ShiftCalendarTable
            title={t("addShift:standartShifts")}
            data={defaultShift}
            type="default"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            menuType="standart"
            refetch={getDefaultShifts}
          />
          <ShiftCalendarTable
            title={t("addShift:nonStandartShifts")}
            data={nonstandardShifts}
            type="nonstandard"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            refetch={handleNonstandartShifts}
            menuType="standart"
          />
        </div>
      </div>

      {/* <CustomModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        modalTitle={"Kayitli Vardiyalar"}
        children={<SavedShiftModal setModalOpen={setModalOpen} data={nonstandardShifts} />}
      /> */}
    </>
  );
};

export default Shift;
