import React from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import NoAvatar from "../avatar/NoAvatar";
import {
  HomeLineIcon,
  QuantityIconSupply,
} from "../../assets/icons/productIcons";
import { Tooltip } from "react-tooltip";

function NewSupplyCard({
  status,
  rawMaterialImage,
  orderedUnit,
  rawMaterialName,
  onClick,
  stockId,
  acceptedUnit,
  id,
  unit,
}) {
  const { t } = useTranslation();

  const statusColor = () => {
    switch (status) {
      case "applied":
        return "#12B76A";
      case "started":
        return "#FDE172";
      default:
        return "#D0D5DD";
    }
  };

  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];

  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u?.value === unit);
    return foundUnit ? foundUnit?.shortLabel : unit;
  };

  return (
    <>
      <div
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        className={`flex flex-col w-full h-full ${onClick && "cursor-pointer"}`}
      >
        <div
          style={{ width: "calc(100% - 18px)", backgroundColor: statusColor() }}
          className="h-[4px] rounded-t-lg"
        ></div>
        <div
          style={{ width: "calc(100% - 16px)" }}
          className="h-full border border-[#D0D5DD] mr-auto rounded-lg p-2 flex flex-col relative min-h-[90px] border-r-0 rounded-r-lg rounded-tl-[0px] border-t-0"
        >
          <div className="flex">
            {rawMaterialImage ? (
              <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden mr-3">
                <img
                  src={generateFileUrl(rawMaterialImage)}
                  alt={rawMaterialName}
                />
              </div>
            ) : (
              <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden mr-3">
                <NoAvatar
                  name={rawMaterialName || "--"}
                  rounded={4}
                  theme={"product"}
                />
              </div>
            )}
            <div
              style={{ width: "calc(100% - 70px)" }}
              className="flex flex-col gap-y-1"
            >
              <span
                data-tooltip-id={`${id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${rawMaterialName} `}
                className="text-[#101828] font-medium w-[170px] text-sm truncate"
              >
                {rawMaterialName || "--"}
              </span>
              <span className="text-xs font-normal text-[#476467] w-[170px] truncate">
                {t("product:stock")} ID: {stockId || "--"}
              </span>
            </div>
          </div>
          <div className="flex w-full mt-1 items-center">
            <div className="flex w-[50%] whitespace-nowrap items-center gap-x-[2px]">
              <span className="flex items-center gap-x-1">
                <QuantityIconSupply />
                <span className="text-xs font-medium text-[#476467]">
                  {orderedUnit || "0"}
                </span>
              </span>
              <span className="text-[#667085] font-normal text-[10px] leading-4">
                {getUnitLabel(unit)} {t("product:totalOrder")}
              </span>
            </div>
          </div>
          <div className="flex w-full mt-1 items-center">
            <div className="flex w-[50%] whitespace-nowrap items-center gap-x-[2px]">
              <span className="flex items-center gap-x-1">
                <HomeLineIcon />
                <span className="text-xs font-medium text-[#476467]">
                  {acceptedUnit || "0"}
                </span>
              </span>
              <span className="text-[#667085] font-normal text-[10px] leading-4">
                {getUnitLabel(unit)} {t("product:fromStock")}
              </span>
            </div>
          </div>
          <div className="flex min-w-[61px] max-w-[61px] min-h-[10px] border-t border-[#D0D5DD] absolute right-[-35px] top-[22px] rotate-[68deg] rounded-lg"></div>
          <div className="flex min-w-[61px] max-w-[61px] min-h-[10px] border-b border-[#D0D5DD] absolute right-[-35px] bottom-[25px] rotate-[-68deg] rounded-lg"></div>
        </div>
      </div>
      <Tooltip id={`${id}`} />
    </>
  );
}

export default NewSupplyCard;
