import React, { useEffect, useState } from 'react'
import CustomImage from '../CustomImage'
import { NoEquIcon } from '../../assets/icons/machineIcons'
import { useTranslation } from 'react-i18next'
import Input from '../inputs/Input'
import Button from '../buttons/Button'
import { purchaseService } from '../../services/purchase.service'
import { Toast } from '../../utils/toastify/toast'


const ApplyFromStockModal = ({ setIsOpenFromStock, currentOperation }) => {
    
    const { t } = useTranslation()
    const maxAllowedPiece = currentOperation?.totalPiece - currentOperation?.qualityAcceptedPiece
    const [stockPiece, setStockPiece] = useState(currentOperation?.totalPiece - currentOperation?.qualityAcceptedPiece)
    const [error, setError] = useState(false);
    const onSubmit = async () => {
        const sendData = {
            source: 'stock',
            type: currentOperation?.internalOperationId ?  'semi_internal_product' : "semi_external_product",
            orderedUnit: Number(stockPiece),
            internalOperation: currentOperation?.internalOperationId,
            externalOperation: currentOperation?.externalOperationId,
            unitType: 'ad',
            unitCost: 0,
        };
        
        await purchaseService.purchaseTransaction(sendData).then((response) => {
            
            if (response?.data?.code == 0) {
                setIsOpenFromStock(false);
              Toast("success", t("addProcess:addedNewProcessMessage"));
            }  else {
              Toast("error", response?.data?.message);
            }
          });
    };

    return (
        <div className='flex w-full flex-col'>
            <p className='w-full text-sm text-secondary-600 border-b pb-5'>
                {currentOperation?.operationStep + `.Op`} {t("product:applyTheCompleted")}
            </p>
            <div className='flex items-center w-full flex-row gap-x-3 justify-between'>
                <div className='flex items-center gap-x-2 py-5'>
                    {currentOperation?.image ? (
                        <div>
                            <CustomImage
                                src={currentOperation?.image}
                                style={{
                                    width: 64,
                                    height: 48,
                                    minWidth: 64,
                                    minHeight: 48,
                                    borderTopLeftRadius: 4,
                                    borderBottomLeftRadius: 4,
                                    borderRadius: 0,
                                    borderLeftWidth: 0,
                                    borderRightWidth: 1,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    display: "flex",
                                    borderColor: "#0000001A",
                                    backgroundColor: "#fff",
                                }}
                            />
                        </div>
                    ) : (
                        <div className="w-[64px] min-w-[48px] h-[48px] rounded-[4px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                            <NoEquIcon />
                        </div>
                    )}
                    <div className='flex flex-col items-start'>
                        <p className='text-md font-semibold text-secondary-900'>
                            {currentOperation?.name}
                        </p>
                        <p className="text-xs font-medium max-w-[185px] truncate text-[#B54708] bg-[#FFFAEB]">
                            {t("product:stock")} : {currentOperation?.stockCode || "--"}
                        </p>
                    </div>
                </div>
                <div className='flex flex-col items-start'>
                    <p className='text-sm text-secondary-600'>{t("product:stockAmount")}</p>
                    <div className='flex flex-row items-center'>
                        <PieceIcons />
                        <p className='text-md font-semibold ml-1 text-secondary-700'>
                            {currentOperation?.activePiece || "0"}
                        </p>
                        <p className='text-sm ml-0.5 text-secondary-600'>{t("product:pcs")}</p>
                    </div>
                </div>
            </div>
            <div className='pb-5 border-b w-full'>
                <Input
                    theme={"product"}
                    label={t("product:quantitiyToApply")}
                    type={"number"}
                    value={stockPiece}
                    onChange={({ target }) => {
                        setStockPiece(target.value)
                        if (Number(target.value > maxAllowedPiece)) {
                            setError(true);
                        } else {
                            setError(false)
                        }
                    }}
                />
                {error && (
                    <span className="text-red-500 text-sm mt-[-14px] ml-1">
                       {maxAllowedPiece}  değerinden daha büyük değer girilemez.
                    </span>
                )}
            </div>
            <div className='flex flex-row items-center pt-6 gap-x-3 w-full'>
                <Button type={"button"} onClick={() => setIsOpenFromStock(false)} label={t("buttons:stop")} colorType={"secondary-gray"} size={"lg"} />
                <Button type={"button"} onClick={() => onSubmit()} label={t("product:applyFromStock")} colorType={"primary-product"} size={"lg"} />
            </div>
        </div>
    )
}

export default ApplyFromStockModal

export const PieceIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M7.1875 2.5H4.58333C3.43274 2.5 2.5 3.43274 2.5 4.58333V15.4167C2.5 16.5673 3.43274 17.5 4.58333 17.5H15.4167C16.5673 17.5 17.5 16.5673 17.5 15.4167V4.58333C17.5 3.43274 16.5673 2.5 15.4167 2.5H12.8125M7.1875 2.5V6.82143C7.1875 6.90231 7.27369 6.95403 7.34506 6.91597L10 5.5L12.6549 6.91597C12.7263 6.95403 12.8125 6.90231 12.8125 6.82143V2.5M7.1875 2.5H12.8125" stroke="#475467" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

