import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Arrow, Calender, Filter } from "../../../assets/icons/machineIcons";
import { useQuery } from "@tanstack/react-query";
import { instance } from "../../../libs/client";
import moment from "moment";
import SearchInput from "../../../components/inputs/SearchInput";
import { Loader } from "../../../assets/icons/stepsIcons";
import { Button, PaginationComponent } from "../../../components";
import diacritics from "diacritics";


const LossListView = () => {
  const { t } = useTranslation();
  const turkishLiraSymbol = `\u20BA`; //unicode
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [month, setMonth] = useState(moment(new Date()));
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedButton, setSelectedButton] = useState("allTime");

  const handleLossData = async (page = 1) => {
    try {

      const data = {
        selectedMonth: Number(moment(month).format("MM")),
        selectedYear: Number(moment(month).format("YYYY")),
      };
     
      if (selectedButton == "allTime") {
        const res = await instance.get(`losses/actions/user/paginated?page=${page}`);
      setPageCount(res.data.meta.pageCount);
      return res.data.data;
        
      } else {
        const res = await instance.post(`losses/actions/user/paginated/new?page=${page}` , data);
        setPageCount(res.data.meta.pageCount);
        return res.data.data;
      }
     
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const { data, isLoading , refetch } = useQuery({
    retry: 0,
    queryKey: ["lossList", page],
    queryFn: () => handleLossData(page),
    keepPreviousData: true,
  });

  const machineName = (item) => {
    return `${item.brand}${item.model}`;
  };

  useEffect(() => {
    refetch();
  }, [month]);


  

  const filteredData = data?.filter((item) => {
    return diacritics.remove(machineName(item))?.toLowerCase().includes(diacritics.remove(search)?.toLowerCase());
  });

  const handlePreviousMonth = useCallback(() => {
    setMonth((prevMonth) => moment(prevMonth).subtract(1, "month"));
  }, [setMonth]);

  const handleCalender = useMemo(() => moment(month).format("MMMM YYYY") || "DD MM YYYY", [month]);

  const handleNextMonth = useCallback(() => {
    setMonth((prevMonth) => {
      const nextMonth = moment(prevMonth).add(1, "month");
      const currentMonth = moment().startOf("month");
      return nextMonth.isSameOrBefore(currentMonth, "month") ? nextMonth : prevMonth;
    });
  }, [setMonth]);

  const getMonthName = (monthValue, year) => {
    switch (monthValue) {
      case "01":
        return `${t("legends:january")} ${year}`;
      case "02":
        return `${t("legends:february")} ${year}`;
      case "03":
        return `${t("legends:march")} ${year}`;
      case "04":
        return `${t("legends:april")} ${year}`;
      case "05":
        return `${t("legends:may")} ${year}`;
      case "06":
        return `${t("legends:june")} ${year}`;
      case "07":
        return `${t("legends:july")} ${year}`;
      case "08":
        return `${t("legends:august")} ${year}`;
      case "09":
        return `${t("legends:september")} ${year}`;
      case "10":
        return `${t("legends:october")} ${year}`;
      case "11":
        return `${t("legends:november")} ${year}`;
      case "12":
        return `${t("legends:december")} ${year}`;
      default:
        return "";
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <>
          <div className="w-full h-10 items-center flex gap-6 mt-5">
            <div className="w-[258px] h-full flex items-start rounded-lg border border-solid border-[#D0D5DD]">
              <div
                role="button"
                onClick={() => {
                  setSelectedButton("allTime");
                  refetch();
                }}
                className={`w-[129px] h-full flex cursor-pointer justify-center items-center gap-2 rounded-l-lg border-r border-r-solid  border-[#D0D5DD]  ${
                  selectedButton === "allTime" ? "bg-[#F9FAFB]" : "bg-[#fff]"
                }`}
              >
                <span className={`text-[#344054] text-sm font-semibold`}>{t("loss:allTime")}</span>
              </div>
              <div
                role="button"
                onClick={() => {
                  setSelectedButton("monthly");

                }}
                className={`w-[129px] h-full flex cursor-pointer justify-center items-center rounded-r-lg gap-2 ${
                  selectedButton === "allTime" ? "bg-[#fff]" : "bg-[#F9FAFB]"
                }`}
              >
                <span className={`text-[#344054] text-sm font-semibold`}>{t("loss:monthly")}</span>
              </div>
            </div>


                {selectedButton === "monthly" && ( <div className={`w-fit h-9 flex items-center gap-3 `}>
              {/*left arrow*/}
              <button
                onClick={() => handlePreviousMonth()}
                className={`w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#EAECF0] bg-[#fff] shadow-xs `}
              >
                <Arrow color="#000" />
              </button>
              {/*calender arrow*/}
              <div
                role="button"
                className={`w-fit cursor-pointer h-full flex px-2 justify-center items-center gap-1 rounded-lg border border-solid border-[#EAECF0] bg-[#fff] shadow-xs`}
              >
                <Calender color="#98A2B3" />
                <div className={`w-fit h-5 flex justify-center items-center`}>
                  <span className={`text-secondary-600 text-sm font-semibold flex`}>
                    {/* {moment(month).format("MMMM YYYY") || "DD MM YYYY"} */}
                    {getMonthName(moment(month).format("MM"), moment(month).format("YYYY")) || "DD MM YYYY"}
                  </span>
                </div>
                {openCalendar && (
                  <>
                    <div
                      onClick={() => handleCalender()}
                      style={{
                        marginTop: 40,
                      }}
                      className={`absolute top-10 -translate-x-1 bg-white z-[9999999] p-1 border rounded-lg border-gray-300 dark:bg-[#141414] dark:border-[#525252] shadow-lg`}
                    >
                      <Calender
                        value={month}
                        onChange={(e) => {
                          setMonth(e);
                          handleCalender();
                        }}
                      />
                    </div>
                    <div
                      className="fixed inset-0 z-[99999]"
                      onClick={(e) => {
                        setOpenCalendar(false);
                      }}
                      style={{ zIndex: 999 }}
                    />
                  </>
                )}
              </div>
              {/*right arrow*/}
              <div
                onClick={() => handleNextMonth()}
                className={`w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#EAECF0] bg-[#fff] shadow-xs rotate-180 `}
              >
                <Arrow color="#000" />
              </div>
            </div>)}
           
          </div>

          {/*table*/}
          <div className="w-full xs:-mt-4 h-[77vh] mt-5 min-h-[77vh] flex flex-col items-start self-stretch rounded-xl border border-solid border-[#EAECF0] bg-[#FFFFFF] shadow dark:bg-[#141414] dark:border-[#202020]">
            <div className="w-full h-20 py-5 px-6 flex items-start gap-4 justify-between">
              <div className="w-fit min-w-1/2 h-10 min-h-10 flex flex-col justify-center items-start gap-1">
                <div className="w-full h-7 flex items-center gap-2 self-stretch">
                  <span className="text-[#101828] dark:text-[#FAFAFA] text-lg font-semibold">{t("routes:losses")}</span>
                  <div className="w-fit max-w-[79px] h-[22px] flex items-center justify-center px-2 rounded-md border border-solid border-[#E9D7FE] bg-[#F9F5FF] dark:border-[#9E77ED] dark:bg-[#9E77ED]">
                    <span className="text-[#6941C6] dark:text-[#FAFAFA] text-center text-xs font-medium truncate ">{data?.length || "No Loss"}</span>
                  </div>
                </div>
              </div>

              <div className="w-fit h-10 flex items-center gap-4 justify-end">
                <SearchInput onChange={({ target }) => setSearch(target.value)} placeholder={t("chat:search")} />
                <div className="w-[25%]">
                  <Button size={"md"} colorType={"secondary-gray"} iconLeft={<Filter />} label={t("loss:filter")} />
                </div>
                {/* <div
                  role="button"
                  className={`cursor-pointer h-full min-w-[99px] flex justify-center items-center  py-2.5 gap-1 rounded-lg border border-solid border-[#D0D5DD] bg-[#FFF] shadow-xs`}
                >
                  <Filter />
                  <span className="text-[#344054] text-sm font-semibold px-[2px]">
                    {t("loss:filter")}
                  </span>
                </div> */}
                {/* <div className="w-[25%]">
                  <Button
                    colorType={"primary-machine"}
                    label={t("loss:export")}
                    iconLeft={<Export />}
                    size={"md"}
                  />
                </div> */}
                {/* <div
                  role="button"
                  className="min-w-[101px] h-10 flex cursor-pointer justify-center items-center gap-2 rounded-lg border border-solid border-[#7F56D9] bg-[#7F56D9] shadow "
                >
                  <Export width={20} height={20} color="#FFFFFF" />
                  <span className="text-sm font-semibold text-[#FFF]">
                    {t("loss:export")}
                  </span>
                </div> */}
              </div>
            </div>

            {/*divider*/}
            <div className="w-full h-[1px] self-stretch bg-[#EAECF0] dark:bg-[#202020]"></div>

            {/*table Header*/}
            <div className="w-full h-11 flex bg-[#fff] dark:bg-[#202020]">
              {/*Number*/}
              <div className="w-[6%] h-11 flex-col justify-center items-center border-r border-solid border-[#EAECF0] dark:border-r-[#202020]">
                <div className="w-full h-[44px] px-4 flex justify-center items-center gap-3 self-stretch border-b border-solid border-[#EAECF0] bg-[#F9FAFB] dark:bg-[#202020] dark:border-[#292929]">
                  <span className="text-[#475467] dark:text-[#FAFAFA] text-xs font-medium ">No.</span>
                </div>
              </div>

              <div className="w-[12%] h-11 flex justify-center items-center gap-3 self-stretch border-b border-r border-b-solid border-[#EAECF0] bg-[#F9FAFB] dark:bg-[#202020] dark:border-[#292929] dark:border-r-[#202020]">
                <span className="text-[#475467] dark:text-[#FAFAFA] text-xs font-medium">{t("loss:lostType")}</span>
              </div>
              {/*operation*/}
              <div className="w-[10%] flex h-11 px-2 gap-3 justify-center items-center self-stretch border-b border-r border-b-solid border-[#EAECF0] bg-[#F9FAFB] dark:bg-[#202020] dark:border-[#292929] dark:border-r-[#202020]">
                <span className="text-[#475467] dark:text-[#FAFAFA] text-xs font-medium">{t("loss:operation")}</span>
              </div>
              {/*machine*/}
              <div className="w-[20%] h-11 flex px-2 justify-center items-center gap-3 self-stretch border-b border-r border-b-solid border-[#EAECF0] bg-[#F9FAFB] dark:bg-[#202020] dark:border-[#292929] dark:border-r-[#202020]">
                <span className="text-[#475467] dark:text-[#FAFAFA] text-xs font-medium ">{t("loss:machine")}</span>
              </div>
              {/*start*/}
              <div className="w-[6%] h-11 flex px-2 justify-center items-center gap-3 self-stretch border-b border-r border-b-solid border-[#EAECF0] bg-[#F9FAFB] dark:bg-[#202020] dark:border-[#292929] dark:border-r-[#202020]">
                <span className="text-[#475467] dark:text-[#FAFAFA] text-xs font-medium ">{t("loss:start")}</span>
              </div>
              {/*end*/}
              <div className="w-[6%] h-11 flex px-2 justify-center items-center gap-3 self-stretch border-b border-r border-b-solid border-[#EAECF0] bg-[#F9FAFB] dark:bg-[#202020] dark:border-[#292929] dark:border-r-[#202020]">
                <span className="text-[#475467] dark:text-[#FAFAFA] text-xs font-medium">{t("loss:end")}</span>
              </div>
              {/*timeMass*/}
              <div className="w-[6%] h-11 flex px-2 justify-center items-center text-center self-stretch border-b border-r border-b-solid border-[#EAECF0] bg-[#F9FAFB] dark:bg-[#202020] dark:border-[#292929] dark:border-r-[#202020]">
                <span className="text-[#475467] dark:text-[#FAFAFA] text-xs font-medium ">{t("loss:timeMass")}</span>
              </div>
              {/*consumption*/}

              {/*cost*/}
              <div className="w-[7%] h-11 px-2 flex justify-center items-center gap-3 self-stretch border-b border-r border-b-solid border-[#EAECF0] bg-[#F9FAFB] dark:bg-[#202020] dark:border-[#292929] dark:border-r-[#202020]">
                <span className="text-[#475467] dark:text-[#FAFAFA] text-xs font-medium">{t("loss:cost")}</span>
              </div>
              {/*description*/}
              <div className="w-[22%] h-11 flex px-4 grow justify-start items-center gap-3 self-stretch border-b border-b-solid border-[#EAECF0] bg-[#F9FAFB] dark:bg-[#202020] dark:border-[#292929]">
                <span className="text-[#475467] dark:text-[#FAFAFA] text-xs font-medium ">{t("product:description")}</span>
              </div>
            </div>

            {/* table body/data */}
            <div className="justify-start flex flex-col overflow-y-auto scrollbar-hide w-full h-full">
              {filteredData?.map((item, i) => {
                return (
                  <div className="w-full h-[52px] flex border-b border-[#EAECF0] last:border-b-0" key={i}>
                    <div className="w-[6%] flex justify-center items-center self-stretch border-r border-[#EAECF0] dark:border-[#292929]">
                      <span className="text-[#475467] p-4 dark:text-[#F5F5F5] text-sm font-normal truncate">#{i + 1}</span>
                    </div>

                    <div className="w-[12%] p-2 flex justify-center items-center self-stretch   border-r border-[#EAECF0] dark:border-[#292929]">
                      <span className="text-[#475467] dark:text-[#F5F5F5] text-sm font-normal truncate">{item?.type || "---"}</span>
                    </div>

                    <div className="w-[10%] p-2 flex justify-center items-center self-stretch   border-r border-[#EAECF0] dark:border-[#292929]">
                      <span className="text-[#475467] dark:text-[#F5F5F5] text-sm font-normal truncate">{item?.processName}</span>
                    </div>

                    <div className="w-[20%] p-2 flex justify-center items-center self-stretch   border-r border-[#EAECF0] dark:border-[#292929]">
                      <span className="text-[#475467] dark:text-[#F5F5F5] text-sm font-normal truncate">{item?.brand + " " + item?.model}</span>
                    </div>

                    <div className="w-[6%] p-2 flex justify-center items-center self-stretch   border-r border-[#EAECF0] dark:border-[#292929]">
                      <span className="text-[#475467] dark:text-[#F5F5F5] text-sm font-normal truncate">
                        {/* {moment(item?.startDate).format("MM/DD")} */}
                        {moment(item?.startDate).isValid() ? moment(item?.startDate).format("MM/DD") : "---"}
                      </span>
                    </div>

                    <div className="w-[6%] p-2 flex justify-center items-center self-stretch   border-r border-[#EAECF0] dark:border-[#292929]">
                      <span className="text-[#475467] dark:text-[#F5F5F5] text-sm font-normal truncate">
                        {/* {moment(item?.startDate).format("MM/DD")} */}
                        {moment(item?.startDate).isValid() ? moment(item?.startDate).format("MM/DD") : "---"}
                      </span>
                    </div>

                    <div className="w-[6%] p-2 flex justify-center items-center self-stretch   border-r border-[#EAECF0] dark:border-[#292929]">
                      <span className="text-[#475467] dark:text-[#F5F5F5] text-sm font-normal truncate">{item?.timeDiff}</span>
                    </div>

                    <div className="w-[7%] p-2 flex justify-center items-center   border-r border-[#EAECF0] dark:border-[#292929]">
                      <span className="text-[#475467] dark:text-[#F5F5F5] text-sm font-normal truncate">
                        {item?.totalCost} {turkishLiraSymbol}
                      </span>
                    </div>

                    <div className="w-[22%] grow p-2 flex justify-start items-center self-stretch  border-[#EAECF0] dark:border-[#292929]">
                      <span className="text-[#475467] dark:text-[#F5F5F5] text-sm font-normal truncate">{item?.reasonDescription || "---"}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex w-full z-50 border-t border-[#D0D5DD] bg-white rounded-b-xl">
              <PaginationComponent pageNo={Number(page) ? Number(page) : 1} setPageNo={setPage} pageCount={pageCount} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LossListView;
