import moment from "moment";
import React from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { PointIconLogo } from "../../../assets/icons/PersonIcons";

const ThirdPerson = ({ navigate, id, name, lastname, point, department, avatar, numFormatter }) => {
  return (
    <>
      <div className="flex  gap-y-4  flex-col border p-6 h-[240px] w-[232px] min-w-[232px] rounded-lg ring-4 ring-[#C5832B24] border-[#C5832B48]  justify-center items-center mt-auto">
        <div
          onClick={() => {
            navigate(`/app/machine/digital-twin/user-detail/${id}/${moment().format("YYYY-MM-DD")}`);
          }}
          className="rounded-full cursor-pointer bg-[#F1E1CC] min-w-[92px] max-w-[92px] min-h-[92px] max-h-[92px] items-center flex justify-center relative"
        >
          {avatar ? (
            <img
              className="max-w-[80px] min-w-[80px] max-h-[80px] min-h-[80px] rounded-full border-2 outline-2 border-[#0000001A] outline-[#0000001A]"
              src={generateFileUrl(avatar)}
            />
          ) : (
            <NoAvatar name={name} lastName={lastname} size={40} />
          )}
          <div className="flex px-[6px] min-h-[24px] max-h-[24px] absolute border border-[#E2B57A] rounded-lg items-center justify-center text-[#AB7225] font-semibold text-sm bg-[#FBF4EC] bottom-[-6px]">
            3rd
          </div>
        </div>
        <div className="flex gap-y-2 w-full flex-col h-full items-center">
          <div className="flex flex-col w-full items-center justify-center">
            <span className="text-lg w-[184px] truncate text-center  font-semibold text-secondary-700">{name + " " + lastname}</span>
            <span className="text-sm font-normal text-center text-secondary-500">{department || "---"}</span>
          </div>
          <div className="min-h-[28px] max-h-[28px] px-2 bg-gradient-to-r from-[#AB7225] to-[#F4AD4D] rounded-[36px] flex gap-x-1 items-center justify-center">
            <div className="w-4 min-w-4 max-w-4">
            <PointIconLogo />
            </div>
            <span className="text-white font-semibold text-sm">{String(numFormatter(point))}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdPerson;
