import React, { useEffect, useState } from "react";
import { embeddedServerService } from "../../../services/embedded-server.service";

const options = [
  { name: "Select an item..", color: undefined },
  { name: "active", color: "#17B26A" },
  { name: "passive", color: "#EAAA08" },
  { name: "warning", color: "#F04438" },
  { name: "machine_off", color: "#98A2B3" },
];

const PriorityTableCard = ({
  id,
  name,
  value,
  metadataId,
  refetch,
  status,
  deviceId,
  priorityTimeDelay,
  isColor,
  isLoading,
  cards,
  setCards,
  sensorId,
  isDeactive,
}) => {
  const [selectedOption, setSelectedOption] = useState("Select an item..");
  const [selectedSecond, setSelectedSecond] = useState(0);
  const seconds = [0, ...Array.from({ length: 10 }, (_, i) => (i + 1) * 0.5)];
  const handleChange = async (event) => {
    setSelectedOption(event.target.value);
    const changedCards = cards?.map((i) => {
      if (i?.sensorId) {
        if (i?.sensorId === sensorId && i?.value === value) {
          return { ...i, status: event.target.value };
        } else {
          return i;
        }
      } else {
        if (i?.id === id) {
          return { ...i, status: event.target.value };
        } else {
          return i;
        }
      }
    });
    setCards(changedCards);
    // await embeddedServerService.updatePriorityCard(deviceId, id, { status: event.target.value, priorityTimeDelay: Number(selectedSecond) }).then((res) => {
    //   refetch();
    // });
  };

  const handleChangeSecond = async (event) => {
    setSelectedSecond(event.target.value);
    const changedCards = cards?.map((i) => {
      if (i?.sensorId) {
        if (i?.sensorId === sensorId && i?.value === value) {
          return { ...i, priorityTimeDelay: Number(event.target.value) };
        } else {
          return i;
        }
      } else {
        if (i?.id === id) {
          return { ...i, priorityTimeDelay: Number(event.target.value) };
        } else {
          return i;
        }
      }
    });
    setCards(changedCards);

    // await embeddedServerService.updatePriorityCard(deviceId, id, { status: selectedOption, priorityTimeDelay: Number(event.target.value) }).then((res) => {
    //   refetch();
    // });
  };

  const findOption = options.find((e) => e.name === selectedOption);

  useEffect(() => {
    setSelectedSecond(priorityTimeDelay || 0);
    setSelectedOption(status || "Select an item..");
  }, [status]);

  return (
    <div
      className={`w-full h-[48px] rounded-xl border shadow flex items-center relative ${
        isColor === "success"
          ? "bg-[#ECFDF3] border-[#ABEFC6]"
          : isLoading
          ? "bg-gray-50 animate-pulse border-secondary-200"
          : isColor === "error"
          ? "bg-[#FEF3F2] border-[#FECDCA]"
          : "bg-white border-secondary-200"
      }`}
    >
      <p className="w-[16.2%] pl-4 text-gray-700 font-semibold">{name}</p>
      <div className="w-[22.7%] flex items-center justify-center h-full">
        <div className="w-[107px] h-9 border rounded-lg border-gray-300 flex items-center px-3.5 cursor-default relative bg-white">
          <p className="text-sm text-gray-700 flex-1 truncate">{selectedSecond} sn.</p>
          <ArrowDown />
          <select defaultChecked id="select" value={selectedSecond} onChange={handleChangeSecond} className="absolute inset-0 opacity-0">
            {seconds.map((item) => (
              <option value={item}>{item} sn.</option>
            ))}
          </select>
        </div>
      </div>
      <div className="w-[17.2%] h-full flex items-center justify-center">
        <div className="w-8 h-8 border rounded-lg border-gray-300 flex items-center justify-center bg-white">
          <p className="text-sm font-semibold text-[#363F72]">{value}</p>
        </div>
      </div>
      <div className="h-full w-[35%] flex items-center justify-center">
        <div className="w-[170px] h-9 border rounded-lg border-gray-300 flex items-center px-3.5 cursor-default relative bg-white">
          {findOption.color && <div className={`w-3 h-3 rounded border`} style={{ backgroundColor: selectedOption ? findOption.color : "#98A2B3" }} />}
          <p className="ml-2 text-sm text-gray-700 flex-1 truncate">{selectedOption ? findOption.name : "Select an item.."}</p>
          <ArrowDown />
          <select defaultChecked id="select" value={selectedOption} onChange={handleChange} className="absolute inset-0 opacity-0">
            {options.map((item) => (
              <option disabled={!item.color} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center h-full">
        <div
          onClick={() => {
            const filtered = cards?.filter((a) => {
              if (a?.sensorId) {
                return a?.sensorId !== sensorId;
              } else {
                return a?.id !== id;
              }
            });
            setCards(filtered);
            // await embeddedServerService.deletePriorityTableCard(metadataId, id).then((res) => {
            //   refetch();
            // });
          }}
          className="w-8 h-8 flex items-center justify-center cursor-pointer"
        >
          <CloseIcon />
        </div>
      </div>
      {isDeactive && (
            <>
              <div className="bg-red-500 w-4 h-4 rounded-full absolute -top-0.5 -right-0.5 animate-ping" />
              <div className="bg-red-500 w-4 h-4 rounded-full absolute -top-0.5 -right-0.5" />
            </>
          )}
    </div>
  );
};

export default PriorityTableCard;

const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M5 7.5L10 12.5L15 7.5" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15 5L5 15M5 5L15 15" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
