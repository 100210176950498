import "./App.css";
import React, { useEffect } from "react";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Outlet, useLocation } from "react-router-dom";
import { PusherProvider } from "@harelpls/use-pusher";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";
import { useStore } from "./hooks/useStores";
import DigitalTwin from "./pages/machine/DigitalTwin";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import Shift from "./pages/machine/Shift";
import AuthNavigation from "./auth/AuthNavigation";
import NodeList from "./pages/machine/NodeList";
import Settings from "./pages/Settings";
import PersonnelList from "./pages/person/PersonnelList";
import { Company, Department, EndOfSteps, Equipments, Language, Process, Shifts, StepNavigation, Users } from "./steps";
import { Login } from "./pages/login";
import PersonLayout from "./pages/person/PersonLayout";
import Task from "./pages/person/pages/Task";
import Message from "./pages/person/pages/Message";
import Chat from "./pages/person/pages/Chat";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  AttendIcon,
  EnergyIcon,
  FlexProductionIcon,
  LeftMenuCCountIcon,
  LossIcon,
  MessageIcon,
  NodeListIcon,
  NotifyIcon,
  OrderIcon,
  OrdersListIcon,
  PointIcon,
  PollIcon,
  PurchaseIcon,
  QualityIcon,
  ShiftIcon,
  StockIcon,
  TaskIcon,
  TwinIcon,
} from "./assets/icons/leftMenuIcons";
import { LossTypeLoader } from "./utils/loaders/machine.loader";
import SettingProfile from "./pages/settings/SettingProfile";
import SettingsCompany from "./pages/settings/SettingsCompany";
import SettingKPI from "./pages/settings/SettingKPI";
import PrivateRoute from "./auth/PrivateRoute.js";
import TwinNavigation from "./pages/machine/TwinNavigation.js";
import MapEditor from "./pages/machine/MapEditor.js";
import EquipmentDetail from "./pages/machine/EquipmentDetail.js";
import OperationDetail from "./pages/machine/Twin/OperationDetail.js";
import { PersonnelsIcon } from "./assets/icons/stepHeaderIcons.js";
import Notify from "./pages/person/pages/Notify.js";
import Poll from "./pages/person/pages/Poll.js";
import PollViewer from "./pages/person/pages/PollViewer.js";
import Error from "./Error.js";
import SettingDepartment from "./pages/settings/SettingDepartment.js";
import SettingInternalOperation from "./pages/settings/SettingInternalOperation.js";
import SettingShift from "./pages/settings/SettingShift.js";
import NotifyViewer from "./pages/person/pages/NotifyViewer.js";
import EmbeddedServer from "./pages/machine/EmbeddedServer.js";
import Attend from "./pages/person/pages/Attend.js";
import UpuPoint from "./pages/person/pages/UpuPoint.js";
import NotFound from "./pages/errors/NotFound.js";
import { observer } from "mobx-react-lite";
import SettingExternal from "./pages/settings/SettingExternal.js";
import CompanyDetail from "./pages/machine/CompanyDetail.js";
import NewPassword from "./pages/login/NewPassword.js";
import VerySoonProduct from "./pages/product/VerySoonProduct.js";
import Quality from "./pages/product/Quality.js";
import QualityExternal from "./pages/product/QualityExternal.js";
import RawMaterialList from "./pages/product/RawMaterialList.js";
import PersonelDetail from "./pages/machine/Twin/PersonelDetail.js";
import SupplierList from "./pages/product/SupplierList.js";
import PurchaseDetail from "./pages/product/PurchaseDetail.js";
import NewCreateRawMaterial from "./pages/product/NewCreateRawMaterial.js";
import Montaj2 from "./pages/product/Montaj2.js";
import Montaj2Tree from "./pages/product/Montaj2Tree.js";
import Montaj3Tree from "./pages/product/Montaj3Tree.js";
import Montaj4Tree from "./pages/product/Montaj4Tree.js";
import { SupplyIcon } from "./assets/icons/productIcons.js";
import QualitySupply from "./pages/product/QualitySupply";
import MontageTest from "./pages/product/MontageTest.js";
import Stock from "./pages/product/Stock";
import QualityDashboard from "./pages/product/quality/pages/QualityDashboard.js";
import AddDailyShift from "./pages/AddDailyShift.js";
import SavedShifts from "./pages/SavedShifts.js";
import OldDayShifts from "./pages/OldDayShifts.js";
import SettingEquipment from "./pages/settings/SettingEquipment.js";
import AddManuelJunction from "./pages/machine/manuelJunctionModal/AddManuelJunction.js";
import MaterialSupplyJunction from "./pages/product/MaterialSupplyJunction.js";
import MaterialSupplyPlanViewPage from "./pages/product/MaterialSupplyPlanViewPage.js";
import ServiceSupplyJunction from "./pages/product/ServiceSupplyJunction.js";
import ServiceSupplyPlanView from "./pages/product/ServiceSupplyPlanView.js";
import FlexProductManagement from "./pages/product/FlexProductManagement.js";
import NewFlexProduct from "./pages/product/NewFlexProduct.js";
import FlexibleMaterialCreator from "./pages/product/components/FlexibleMaterialCreator.js";
import WorkInProcess from "./pages/product/WorkInProcess.js";
import NewFlexOrder from "./pages/product/NewFlexOrder.js";
import NewFlexOrderSecond from "./pages/product/NewFlexOrderSecond.js";
import NewFlexOrderThird from "./pages/product/NewFlexOrderThird.js";
import PersonDashboard from "./pages/person/pages/PersonDashboard.js";
import { PersonDashboardIconLeft } from "./components/new-cards/card-icons.js";
import ExpandableOrder from "./pages/product/ExpandableOrder.js";
import NewOrderAndSupply from "./pages/product/workOrderComponents/NewOrderAndSupply.js";
import SupplyPlanner from "./pages/product/SupplyPlanner.js";
import External from "./steps/external/External";
import Losses from "./pages/machine/loss/Losses";
import LossTable from "./pages/machine/loss/LossTable";
import LossListView from "./pages/machine/loss/LossListView";
import LossGraphicView from "./pages/machine/loss/LossGraphicView";
import CCount from "./pages/machine/C-Count";
import Energy from "./pages/machine/Energy";
import StockDetails from "./pages/product/StockDetails.js";
import StockEdit from "./pages/product/StockEdit.js";
import ProductOperationDetail from "./pages/product/ProductOperationDetail.js";
import MoveJunctionDetail from "./pages/product/components/MoveJunctionDetail.js";
import OrderEditClone from "./pages/product/OrderEditClone.js";
import Order from "./pages/product/Order.js";
import NewInternalPlanView from "./pages/product/components/NewInternalPlanView.js";
import FlexProductOperationDetail from "./pages/product/FlexOperationDetail.js";
import CustomerList from "./pages/product/CustomerList.js";
import InternalOperationJunction from "./pages/product/InternalOperationJunction.js";
import InternalOperationPlanView from "./pages/product/InternalOperationPlanView.js";
import OrderDetailLegacy from "./pages/product/OrderDetailLegacy.js";
import EditManuelJunction from "./pages/machine/manuelJunctionModal/EditManuelJunction.js";
import RawMaterialDetail from "./pages/product/RawMaterialDetail.js";
import FlexProductRawMaterialList from "./pages/product/FlexProductRawMaterialList.js";
import ManagementOrderPlanning from "./pages/product/ManagementOrderPlanning.js";
import ManagementOrderSupplyForm from "./pages/product/ManagementOrderSupplyForm.js";
import MesQuality from "./pages/product/MesQuality.js";
import MesInternalOperationJunction from "./pages/product/MesInternalOperationJunction.js";
import MesInternalOperationPlanView from "./pages/product/MesInternalOperationPlanView.js";
import MesMaterialSupplyJunction from "./pages/product/MesMaterialSupplyJunction.js";
import MesMaterialSupplyPlanViewPage from "./pages/product/MesMaterialSupplyPlanViewPage.js";
import MesServiceSupplyJunction from "./pages/product/MesServiceSupplyJunction.js";
import MesServiceSupplyPlanView from "./pages/product/MesServiceSupplyPlanView.js";
import MesPurchaseDetail from "./pages/product/MesPurchaseDetail.js";
import MesSupplierList from "./pages/product/MesSupplierList.js";
import MesQualityExternal from "./pages/product/MesQualityExternal.js";
import MesQualitySupply from "./pages/product/MesQualitySupply.js";
import AddNewOrderCustomer from "./pages/product/AddNewOrderCustomer.js";
import AddNewOrderCustomerSecond from "./pages/product/AddNewOrderCustomerSecond.js";
import AddNewOrderCustomerThird from "./pages/product/AddNewOrderCustomerThird.js";

import StockDetailGeneral from "./pages/product/StockDetailGeneral.js";
import StockDetailOrderRecipe from "./pages/product/StockDetailOrderRecipe.js";
import OperationLogDetail from "./pages/product/OperationLogDetail.js";
import MaterialSupplyListPage from "./pages/product/MaterialSupplyListPage.js";
import MaterialSupplyPurchaseDetail from "./pages/product/MaterialSupplyPurchaseDetail.js";

import ServiceSupplyListPage from "./pages/product/ServiceSupplyListPage.js";
import ServiceSupplyPurchaseDetail from "./pages/product/ServiceSupplyPurchaseDetail.js";

import StockCreateNewProduct from "./pages/product/StockCreateNewProduct.js";
import StockCreateRecipeProduct from "./pages/product/StockCreateRecipeProduct.js";
import StockCreateRawMaterialPage from "./pages/product/StockCreateRawMaterialPage.js";
import OrderLogDetail from "./pages/product/OrderLogDetail.js";
import EditOrderFirstStep from "./pages/product/EditOrderFirstStep";
import EditOrderSecondStep from "./pages/product/EditOrderSecondStep.js";
import EditOrderThirdStep from "./pages/product/EditOrderThirdStep.js";
import SupplierDetails from "./pages/product/SupplierDetailsInfo.js";
import SupplierDetailsInfo from "./pages/product/SupplierDetailsInfo.js";
import SupplierDetailsPast from "./pages/product/SupplierDetailsPast.js";

import ServiceSupplierDetailsInfo from "./pages/product/ServiceSupplierDetailsInfo.js";
import ServiceSupplierDetailsPast from "./pages/product/ServiceSupplierDetailsPast.js";

import StockProductionDetail from "./pages/product/StockProductionDetail.js";

import NewNodeListPaginated from "./pages/machine/NewNodeListPaginated.js";
import EditDailyShift from "./pages/EditDailyShift.js";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const screens = [
  {
    path: "/auth",
    content: false,
    element: AuthNavigation,
    isPrivate: false,
    subRoutes: [
      {
        path: "login",
        element: Login,
      },
    ],
  },
  {
    path: "change-password",
    roles: [
      "ADMIN",
      "SHIFT_RESPONSIBLE",
      "OPERATOR",
      "QUALITY_CONTROL_RESPONSIBLE",
      "OPERATION_RESPONSIBLE",
      "QUALITY_CONTROL_OPERATOR",
      "PURCHASING_RESPONSIBLE",
      "PURCHASING_OPERATOR",
    ],
    element: NewPassword,
    isPrivate: false,
    subRoutes: [],
  },
  {
    path: "/step",
    roles: ["ADMIN"],
    content: false,
    isPrivate: true,
    element: StepNavigation,
    subRoutes: [
      {
        path: "language",
        roles: ["ADMIN"],
        initialRouteName: "language",
        isPrivate: true,
        element: Language,
        errorElement: <Error />,
      },
      {
        path: "company",
        roles: ["ADMIN"],
        isPrivate: true,
        element: Company,
        errorElement: <Error />,
      },
      {
        path: "department",
        roles: ["ADMIN"],
        isPrivate: true,
        element: Department,
        errorElement: <Error />,
      },
      {
        path: "users",
        roles: ["ADMIN"],
        isPrivate: true,
        element: Users,
        errorElement: <Error />,
      },
      {
        path: "process",
        roles: ["ADMIN"],
        isPrivate: true,
        element: Process,
        errorElement: <Error />,
      },
      {
        path: "external-operation",
        roles: ["ADMIN"],
        isPrivate: true,
        element: External,
        errorElement: <Error />,
      },
      {
        path: "equipment",
        roles: ["ADMIN"],
        isPrivate: true,
        element: Equipments,
        errorElement: <Error />,
      },
      {
        path: "shift",
        roles: ["ADMIN"],
        isPrivate: true,
        element: Shifts,
        errorElement: <Error />,
      },
      {
        path: "end-of-step",
        roles: ["ADMIN"],
        isPrivate: true,
        element: EndOfSteps,
        errorElement: <Error />,
      },
    ],
  },
  {
    path: "/app",
    element: Layout,
    content: true,
    isPrivate: true,
    subRoutes: [
      {
        path: "machine/digital-twin",
        roles: ["ADMIN", "OPERATION_RESPONSIBLE", "SHIFT_RESPONSIBLE"],
        isPrivate: true,
        element: TwinNavigation,
        isVisible: true,
        icon: TwinIcon,
        package: "machine",
        name: "digital twin",
        subRoutes: [
          {
            path: "*",
            roles: ["ADMIN"],
            element: DigitalTwin,
            isPrivate: true,
            package: "machine",
            isVisible: false,
          },
          {
            path: "map-editor",
            roles: ["ADMIN"],
            element: MapEditor,
            isPrivate: true,
            package: "machine",
            isVisible: true,
          },
          {
            path: "equipment-details/:deviceId/:dates",
            roles: ["ADMIN", "OPERATION_RESPONSIBLE", "SHIFT_RESPONSIBLE"],
            element: EquipmentDetail,
            package: "machine",
            name: "equipment details",
            isVisible: false,
            isPrivate: true,
          },

          {
            path: "equipment-details/:deviceId/:dates/manuel-detail/:manuelVeriables",
            roles: ["ADMIN", "OPERATION_RESPONSIBLE", "SHIFT_RESPONSIBLE"],
            element: AddManuelJunction,
            package: "machine",
            name: "equipment details",
            isVisible: false,
            isPrivate: true,
          },
          {
            path: "equipment-details/:deviceId/:dates/manuel-detail/edit/:manuelVeriables/event/:junctionId",
            roles: ["ADMIN", "OPERATION_RESPONSIBLE", "SHIFT_RESPONSIBLE"],
            element: EditManuelJunction,
            package: "machine",
            name: "equipment details",
            isVisible: false,
            isPrivate: true,
          },

          {
            path: "user-detail/:userId/:dates/*",
            roles: ["ADMIN", "OPERATION_RESPONSIBLE", "SHIFT_RESPONSIBLE"],
            element: PersonelDetail,
            package: "machine",
            isVisible: true,
            isPrivate: true,
          },
          {
            path: "operation-detail/:operationId/:dates/*",
            roles: ["ADMIN", "OPERATION_RESPONSIBLE", "SHIFT_RESPONSIBLE"],
            element: OperationDetail,
            package: "machine",
            isVisible: true,
            isPrivate: true,
          },
          {
            path: "embedded-server/:nodeId",
            roles: ["ADMIN"],
            element: EmbeddedServer,
            package: "machine",
            isVisible: false,
            isPrivate: true,
          },
          {
            path: "company-details",
            roles: ["ADMIN"],
            element: CompanyDetail,
            package: "machine",
            isVisible: false,
            isPrivate: true,
          },
        ],
      },
      {
        path: "machine/loss",
        roles: ["ADMIN"],
        icon: LossIcon,
        package: "machine",
        isPrivate: true,
        isVisible: true,
        name: "loss",
        element: Losses,
        subRoutes: [
          {
            path: "",
            roles: ["ADMIN"],
            element: LossGraphicView,
            isPrivate: true,
          },
          {
            path: "list-view",
            roles: ["ADMIN"],
            element: LossListView,
            isPrivate: true,
          },
          {
            path: "loss-table",
            roles: ["ADMIN"],
            loader: LossTypeLoader,
            isPrivate: true,
            element: LossTable,
            package: "machine",
          },
        ],
      },
      {
        path: "machine/energy",
        roles: ["ADMIN"],
        isPrivate: true,
        element: Energy,
        icon: EnergyIcon,
        package: "machine",
        isVisible: true,
        name: "energy",
      },
      {
        path: "machine/c-count",
        roles: ["ADMIN"],
        isPrivate: true,
        element: CCount,
        icon: LeftMenuCCountIcon,
        package: "machine",
        isVisible: true,
        name: "c-count",
        isPrivate: true,
      },
      {
        path: "machine/shift",
        roles: ["ADMIN"],
        isPrivate: true,
        element: () => (
          <div className="w-full h-full">
            <Outlet />
          </div>
        ),
        icon: ShiftIcon,
        package: "machine",
        isVisible: true,
        name: "shift",
        subRoutes: [
          {
            path: "",
            roles: ["ADMIN"],
            element: Shift,
            isPrivate: true,
            package: "machine",
            isVisible: false,
          },
          {
            path: "new-daily-shift",
            roles: ["ADMIN"],
            element: AddDailyShift,
            isPrivate: true,
            package: "machine",
            isVisible: false,
          },
          {
            path: "edit-daily-shift/:shiftId",
            roles: ["ADMIN"],
            element: EditDailyShift,
            isPrivate: true,
            package: "machine",
            isVisible: false,
          },
          {
            path: "saved-shifts",
            roles: ["ADMIN"],
            element: SavedShifts,
            isPrivate: true,
            package: "machine",
            isVisible: false,
          },
          {
            path: "old-day-shifts",
            roles: ["ADMIN"],
            element: OldDayShifts,
            isPrivate: true,
            package: "machine",
            isVisible: false,
          },
        ],
      },
      {
        path: "machine/node-list",
        roles: ["ADMIN"],
        element: NodeList,
        // element: NewNodeListPaginated,
        isPrivate: true,
        name: "node List",
        icon: NodeListIcon,
        package: "machine",
        isVisible: false,
      },
      {
        path: "product/verysoon",
        element: VerySoonProduct,
        icon: OrderIcon,
        isPrivate: true,
        package: "product",
        isVisible: false,
        name: "order",
      },
      {
        roles: ["ADMIN"],
        path: "product/mrp/work-in-process",
        // element: Order,
        element: () => (
          <div className="w-full h-full">
            <Outlet />
          </div>
        ),
        icon: FlexProductionIcon,
        isPrivate: false,
        package: "product",
        isVisible: true,
        name: "Product Twin",
        mrpVisible: true,
        mesVisible: false,
        subRoutes: [
          {
            path: ":pageNo?/detail/:operationId/*",
            roles: ["ADMIN"],
            isPrivate: true,
            element: ProductOperationDetail,
            isVisible: false,
            name: "Stock Detail",
          },
          {
            path: "operation-detail/:operationId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewInternalPlanView,
            isVisible: false,
            name: "Operation Detail",
          },
          {
            path: ":pageNo?/detail/:operationId/move-junction",
            roles: ["ADMIN"],
            isPrivate: true,
            element: MoveJunctionDetail,
            isVisible: false,
            name: "Move Junction",
          },
          {
            path: ":pageNo?/edit/:orderId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: OrderEditClone,
            isVisible: false,
            name: "Order Copy",
          },
          {
            path: ":pageNo?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: Order,
            isVisible: false,
            name: "Order",
          },
        ],
      },
      {
        path: "product/mrp/orders",
        roles: ["ADMIN"],
        element: () => (
          <div className="w-full h-full">
            <Outlet />
          </div>
        ),
        icon: OrdersListIcon,
        isPrivate: true,
        package: "product",
        isVisible: true,
        name: "Orders",
        mrpVisible: true,
        mesVisible: false,
        subRoutes: [
          {
            path: "flex",
            roles: ["ADMIN"],
            isPrivate: true,
            element: FlexProductManagement,
            isVisible: false,
            name: "Stock Detail",
          },
          {
            path: "customer-list",
            roles: ["ADMIN"],
            isPrivate: true,
            element: CustomerList,
            isVisible: true,
            name: "Customer List",
          },
          {
            path: "flex/expandable-Order",
            roles: ["ADMIN"],
            isPrivate: true,
            element: ExpandableOrder,
            isVisible: false,
            name: "Expandale Order",
          },
          {
            path: "flex/create/:productId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewFlexProduct,
            isVisible: false,
            name: "Stock Detail",
          },
          {
            path: "flex/operation-detail/:operationId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewInternalPlanView,
            isVisible: false,
            name: "Operation Detail",
          },
          {
            path: "customer-detail/:id/:filterValue",
            roles: ["ADMIN"],
            isPrivate: true,
            element: OrderDetailLegacy,
            isVisible: false,
            name: "Order Detail",
          },
          {
            path: "stock-production-detail/:filterValue",
            roles: ["ADMIN"],
            isPrivate: true,
            element: StockProductionDetail,
            isVisible: false,
            name: "Stock Production Detail",
          },
          {
            path: "order-detail/:orderId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: OrderLogDetail,
            isVisible: false,
            name: "Order Log Detail",
          },
          // {
          //   path: ":search?",
          //   roles: ["ADMIN"],
          //   isPrivate: true,
          //   element: NewOrderAndSupply,
          //   isVisible: false,
          //   name: "Order & Supply",
          // },

          {
            path: ":orderId?/:search?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewOrderAndSupply,
            isVisible: true,
            name: "Order & Supply",
          },

          {
            path: "new-order/edit/:id",
            roles: ["ADMIN"],
            isPrivate: true,
            element: AddNewOrderCustomer,
            isVisible: false,
            name: "New Order",
          },
          {
            path: "new-order/edit/second-page/:id",
            roles: ["ADMIN"],
            isPrivate: true,
            element: AddNewOrderCustomerSecond,
            isVisible: false,
            name: "New Order Second",
          },
          {
            path: "new-order/edit/third-page/:id",
            roles: ["ADMIN"],
            isPrivate: true,
            element: AddNewOrderCustomerThird,
            isVisible: false,
            name: "New Order Third",
          },

          ////////

          {
            path: "edit-order/:id",
            roles: ["ADMIN"],
            isPrivate: true,
            element: EditOrderFirstStep,
            isVisible: false,
            name: "Edit Order",
          },
          {
            path: "edit-order/second-page/:id",
            roles: ["ADMIN"],
            isPrivate: true,
            element: EditOrderSecondStep,
            isVisible: false,
            name: "Edit Order Second",
          },
          {
            path: "edit-order/third-page/:id/:index?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: EditOrderThirdStep,
            isVisible: false,
            name: "Edit Order Third",
          },

          ////////

          {
            path: "new-order",
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewFlexOrder,
            isVisible: false,
            name: "New Order",
          },
          {
            path: "new-order/second-page",
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewFlexOrderSecond,
            isVisible: false,
            name: "New Order Second",
          },
          {
            path: "new-order/third-page/:index?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewFlexOrderThird,
            isVisible: false,
            name: "New Order Third",
          },
          {
            path: "management/planning/:orderId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: ManagementOrderPlanning,
            isVisible: false,
            name: "Manage Order",
          },
          {
            path: "management/supply-form/:orderId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: ManagementOrderSupplyForm,
            isVisible: false,
            name: "Manage Order",
          },
        ],
      },
      {
        path: "product/mrp/stock",
        roles: ["ADMIN"],
        element: () => (
          <div className="w-full h-full">
            <Outlet />
          </div>
        ),
        icon: StockIcon,
        package: "product",
        isPrivate: true,
        isVisible: true,
        name: "Stock",
        isSubMenu: true,
        mrpVisible: true,
        mesVisible: false,
        subRoutes: [
          {
            path: ":pageNo?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: Stock,
            isVisible: false,
            name: "stock",
            isSubItem: false,
          },
          {
            path: ":typeId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: Stock,
            isVisible: false,
            name: "stock",
            isSubItem: false,
          },
          {
            path: ":pageNo?/:type/:stockId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: StockEdit,
            isVisible: false,
            name: "Stock Edit",
          },
          {
            path: "detail/orders-recipe/:stockId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: StockDetailOrderRecipe,
            isVisible: false,
            name: "Stock Detail",
          },
          {
            path: "detail/general-info/:stockId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: StockDetailGeneral,
            isVisible: false,
            name: "Stock Detail General Info",
          },
          {
            path: "operation-detail/:type/:operationId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: OperationLogDetail,
            isVisible: false,
            name: "Operation Log Detail",
          },
          {
            path: ":pageNo?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: Stock,
            isVisible: false,
            name: "Stock",
          },
          {
            path: "raw-material/list/:pageNo?",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: RawMaterialList,
            isVisible: false,
            name: "Raw Material List",
            isSubItem: true,
          },
          {
            path: "raw-material/detail/:id",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: RawMaterialDetail,
            isVisible: false,
            name: "Raw Material Detail",
          },
          {
            path: "raw-material/create",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewCreateRawMaterial,
            isVisible: false,
            name: "Raw Material List",
          },
          {
            path: "flexible/raw-material/create",
            roles: ["ADMIN"],
            isPrivate: true,
            element: FlexibleMaterialCreator,
            isVisible: false,
            name: "Stock Detail",
          },
          {
            path: "raw-material/edit/:itemId",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewCreateRawMaterial,
            isVisible: false,
            name: "Raw Material List",
          },
          {
            path: "create",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: StockCreateNewProduct,
            isVisible: false,
            name: "Stock Create New Product",
          },
          {
            path: "edit/:stockId",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: StockCreateNewProduct,
            isVisible: false,
            name: "Stock Edit New Product",
          },
          {
            path: "edit/recipe/:stockId",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: StockCreateRecipeProduct,
            isVisible: false,
            name: "Stock Edit New Product",
          },
          {
            path: "create/raw-material",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: StockCreateRawMaterialPage,
            isVisible: false,
            name: "Stock Create New Raw Material",
          },
          {
            path: "create/recipe",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: StockCreateRecipeProduct,
            isVisible: false,
            name: "Stock Create Recipe Product",
          },
        ],
      },
      {
        roles: ["ADMIN"],
        path: "product/mes/flex",
        icon: FlexProductionIcon,
        isPrivate: false,
        package: "product",
        isVisible: true,
        name: "Flex Production",
        mrpVisible: false,
        mesVisible: true,
        element: () => (
          <div className="w-full h-full">
            <Outlet />
          </div>
        ),
        subRoutes: [
          {
            path: ":pageNo?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: FlexProductManagement,
            isVisible: false,
            name: "dashboard",
            isSubItem: false,
          },
          {
            path: "create",
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewFlexProduct,
            isVisible: false,
            name: "dashboard",
            isSubItem: false,
          },
          {
            path: "create/raw-material/:id?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: FlexibleMaterialCreator,
            isVisible: false,
            name: "dashboard",
            isSubItem: false,
          },

          {
            path: "raw-material/list/:pageNo?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: FlexProductRawMaterialList,
            isVisible: false,
            name: "Raw Material List",
            isSubItem: false,
          },
          {
            path: "operation/detail/:operationId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: FlexProductOperationDetail,
            isVisible: false,
            name: "Flex Operation Detail",
          },
        ],
      },
      {
        path: "product/mrp/quality-control",
        roles: ["ADMIN"],
        element: () => {
          return (
            <div className="w-full h-full">
              <Outlet />
            </div>
          );
        },
        icon: QualityIcon,
        isPrivate: true,
        package: "product",
        isVisible: true,
        name: "Quality Control",
        mrpVisible: true,
        mesVisible: false,
        isSubMenu: true,
        subRoutes: [
          {
            path: "dashboard",
            roles: ["ADMIN"],
            isPrivate: true,
            element: QualityDashboard,
            isVisible: false,
            name: "dashboard",
            isSubItem: false,
          },
          {
            path: "internal/:dates",
            roles: ["ADMIN"],
            isPrivate: true,
            element: Quality,
            isVisible: false,
            name: "Internal Operations",
            isSubItem: true,
          },
          {
            path: "external",
            roles: ["ADMIN"],
            isPrivate: true,
            element: QualityExternal,
            isVisible: false,
            name: "Service Supplies",
            isSubItem: true,
          },
          {
            path: "supply",
            roles: ["ADMIN"],
            isPrivate: true,
            element: QualitySupply,
            isVisible: false,
            name: "Material Supplies",
            isSubItem: true,
          },
        ],
      },
      {
        path: "product/mrp/supply",
        roles: ["ADMIN", "OPERATION_RESPONSIBLE", "SHIFT_RESPONSIBLE"],
        element: () => {
          return (
            <div className="w-full h-full">
              <Outlet />
            </div>
          );
        },
        icon: SupplyIcon,
        isPrivate: true,
        package: "product",
        isVisible: true,
        name: "Planning & Supply",
        isSubMenu: true,
        mrpVisible: true,
        mesVisible: false,
        subRoutes: [
          {
            path: "operation/:id/junction",
            icon: PurchaseIcon,
            roles: ["ADMIN", "OPERATION_RESPONSIBLE", "SHIFT_RESPONSIBLE"],
            isPrivate: true,
            element: InternalOperationJunction,
            isVisible: false,
            name: "Operations",
          },
          {
            path: "operation/:id/plan-view/:search?",
            icon: PurchaseIcon,
            roles: ["ADMIN", "OPERATION_RESPONSIBLE", "SHIFT_RESPONSIBLE"],
            isPrivate: true,
            element: InternalOperationPlanView,
            isVisible: false,
            name: "Operations",
            isSubItem: true,
          },
          {
            path: "material/:id/junction/:search?",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MaterialSupplyJunction,
            isVisible: false,
            name: "Material Supply",
            isSubItem: true,
          },
          {
            path: "material/:id/plan-view",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MaterialSupplyPlanViewPage,
            isVisible: false,
            name: "Material Supply Plan View",
          },
          {
            path: "material/:id/list",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MaterialSupplyListPage,
            isVisible: false,
            name: "Material Supply List",
          },
          {
            path: "material/purchase-detail/:id/:supplierId",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MaterialSupplyPurchaseDetail,
            isVisible: false,
            name: "Material Supply Purchase Detail",
          },
          {
            path: "service/:id/junction/:search?",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: ServiceSupplyJunction,
            isVisible: false,
            name: "Service Supply",
            isSubItem: true,
          },
          {
            path: "service/:id/plan-view",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: ServiceSupplyPlanView,
            isVisible: false,
            name: "Service Supply",
          },
          {
            path: "service/:id/list",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: ServiceSupplyListPage,
            isVisible: false,
            name: "Service Supply List",
          },
          {
            path: "service/purchase-detail/:id/:supplierId/:type",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: ServiceSupplyPurchaseDetail,
            isVisible: false,
            name: "Service Supply Purchase Detail",
          },
          {
            path: ":type/list",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SupplierList,
            isVisible: false,
            name: "Raw Material",
          },

          {
            path: "supplier/details/:id/info",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SupplierDetailsInfo,
            isVisible: false,
            name: "Raw Material",
          },
          {
            path: "supplier/details/:id/past/:type/:pageNo?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SupplierDetailsPast,
            isVisible: false,
            name: "Raw Material",
          },

          {
            path: "service/supplier/details/:id/info",
            roles: ["ADMIN"],
            isPrivate: true,
            element: ServiceSupplierDetailsInfo,
            isVisible: false,
            name: "Raw Material",
          },
          {
            path: "service/supplier/details/:id/past/:type/:pageNo?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: ServiceSupplierDetailsPast,
            isVisible: false,
            name: "Raw Material",
          },

          // {
          //   path: "/details/:id",
          //   roles: ["ADMIN"],
          //   isPrivate: true,
          //   element: SupplierList,
          //   isVisible: false,
          //   name: "Raw Material",
          // },
          {
            path: "material/details/:id",
            roles: ["ADMIN"],
            isPrivate: true,
            element: PurchaseDetail,
            isVisible: false,
            name: "Details",
          },
        ],
      },
      //
      {
        path: "product/mes/quality-control",
        roles: ["ADMIN"],
        element: () => {
          return (
            <div className="w-full h-full">
              <Outlet />
            </div>
          );
        },
        icon: QualityIcon,
        isPrivate: true,
        package: "product",
        isVisible: true,
        name: "Quality Control",
        mrpVisible: false,
        mesVisible: true,
        isSubMenu: true,
        subRoutes: [
          {
            path: "internal",
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesQuality,
            isVisible: false,
            name: "Internal Operations",
            isSubItem: true,
          },
          {
            path: "external",
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesQualityExternal,
            isVisible: false,
            name: "Service Supplies",
            isSubItem: true,
          },
          {
            path: "supply",
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesQualitySupply,
            isVisible: false,
            name: "Material Supplies",
            isSubItem: true,
          },
        ],
      },
      {
        path: "product/mes/supply",
        roles: ["ADMIN"],
        element: () => {
          return (
            <div className="w-full h-full">
              <Outlet />
            </div>
          );
        },
        icon: SupplyIcon,
        isPrivate: true,
        package: "product",
        isVisible: true,
        name: "Planning & Supply",
        isSubMenu: true,
        mrpVisible: false,
        mesVisible: true,
        subRoutes: [
          {
            path: "operation/:id/junction",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesInternalOperationJunction,
            isVisible: false,
            name: "Operations",
          },
          {
            path: "operation/:id/plan-view",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesInternalOperationPlanView,
            isVisible: false,
            name: "Operations",
            isSubItem: true,
          },
          {
            path: "material/:id/junction",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesMaterialSupplyJunction,
            isVisible: false,
            name: "Material Supply",
            isSubItem: true,
          },
          {
            path: "material/:id/plan-view",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesMaterialSupplyPlanViewPage,
            isVisible: false,
            name: "Material Supply",
          },
          {
            path: "service/:id/junction",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesServiceSupplyJunction,
            isVisible: false,
            name: "Service Supply",
            isSubItem: true,
          },
          {
            path: "service/:id/plan-view",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesServiceSupplyPlanView,
            isVisible: false,
            name: "Service Supply",
          },
          {
            path: ":type/list",
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesSupplierList,
            isVisible: false,
            name: "Raw Material",
          },
          {
            path: "material/details/:id",
            roles: ["ADMIN"],
            isPrivate: true,
            element: MesPurchaseDetail,
            isVisible: false,
            name: "Details",
          },
        ],
      },
      {
        roles: ["ADMIN"],
        path: "settings",
        element: Settings,
        package: "product",
        isVisible: false,
        isPrivate: true,
        name: "Settings",
        subRoutes: [
          {
            path: "profile",
            isPrivate: true,
            element: SettingProfile,
            isVisible: true,
            name: "Profile",
          },
          {
            path: "company",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingsCompany,
            isVisible: true,
            name: "Company",
          },
          {
            path: "department",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingDepartment,
            isVisible: true,
            name: "Department",
          },
          {
            path: "internal-operation",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingInternalOperation,
            isVisible: true,
            name: "Internal Operation",
          },
          {
            path: "external-operation",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingExternal,
            isVisible: true,
            name: "External Operation",
          },
          {
            path: "equipments",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingEquipment,
            isVisible: true,
            name: "Equipments",
          },
          {
            path: "shift",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingShift,
            isVisible: true,
            name: "Shift Management",
          },
          {
            path: "kpi",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingKPI,
            isVisible: true,
            name: "KPI",
          },
        ],
      },
      {
        path: "person",
        roles: [
          "ADMIN",
          "SHIFT_RESPONSIBLE",
          "OPERATOR",
          "QUALITY_CONTROL_RESPONSIBLE",
          "OPERATION_RESPONSIBLE",
          "QUALITY_CONTROL_OPERATOR",
          "PURCHASING_RESPONSIBLE",
          "PURCHASING_OPERATOR",
        ],
        element: PersonLayout,
        package: "person",
        isVisible: true,
        icon: () => <></>,
        name: "Person Layout",
        subRoutes: [
          {
            path: "dashboard/:dates",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: PersonDashboard,
            icon: PersonDashboardIconLeft,
            package: "person",
            isVisible: true,
            isPrivate: true,
            name: "person twin",
          },
          {
            path: "message",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: Message,
            icon: MessageIcon,
            isPrivate: true,
            package: "person",
            isVisible: true,
            name: "message",
            subIdRoutes: [
              {
                path: ":conversationId",
                roles: [
                  "ADMIN",
                  "SHIFT_RESPONSIBLE",
                  "OPERATOR",
                  "QUALITY_CONTROL_RESPONSIBLE",
                  "OPERATION_RESPONSIBLE",
                  "QUALITY_CONTROL_OPERATOR",
                  "PURCHASING_RESPONSIBLE",
                  "PURCHASING_OPERATOR",
                ],
                element: Chat,
                package: "person",
                isVisible: false,
                isPrivate: true,
              },
            ],
          },
          {
            path: "task",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: Task,
            isPrivate: true,
            subIdRoutes: [
              {
                path: ":conversationId",
                roles: [
                  "ADMIN",
                  "SHIFT_RESPONSIBLE",
                  "OPERATOR",
                  "QUALITY_CONTROL_RESPONSIBLE",
                  "OPERATION_RESPONSIBLE",
                  "QUALITY_CONTROL_OPERATOR",
                  "PURCHASING_RESPONSIBLE",
                  "PURCHASING_OPERATOR",
                ],
                element: Chat,
                package: "person",
                isVisible: false,
              },
            ],
            icon: TaskIcon,
            package: "person",
            isVisible: true,
            isPrivate: true,
            name: "task",
          },
          {
            path: "notify",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: () => <Notify />,
            icon: NotifyIcon,
            package: "person",
            isVisible: true,
            isPrivate: true,
            name: "notify",
            subIdRoutes: [
              {
                path: ":id",
                roles: [
                  "ADMIN",
                  "SHIFT_RESPONSIBLE",
                  "OPERATOR",
                  "QUALITY_CONTROL_RESPONSIBLE",
                  "OPERATION_RESPONSIBLE",
                  "QUALITY_CONTROL_OPERATOR",
                  "PURCHASING_RESPONSIBLE",
                  "PURCHASING_OPERATOR",
                ],
                element: NotifyViewer,
                package: "person",
                isVisible: "",
              },
            ],
          },
          {
            path: "poll",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: () => <Poll />,
            icon: PollIcon,
            isPrivate: true,
            package: "person",
            isVisible: true,
            name: "poll",
            subIdRoutes: [
              {
                path: ":id",
                roles: [
                  "ADMIN",
                  "SHIFT_RESPONSIBLE",
                  "OPERATOR",
                  "QUALITY_CONTROL_RESPONSIBLE",
                  "OPERATION_RESPONSIBLE",
                  "QUALITY_CONTROL_OPERATOR",
                  "PURCHASING_RESPONSIBLE",
                  "PURCHASING_OPERATOR",
                ],
                element: PollViewer,
                package: "person",
                isVisible: false,
              },
            ],
          },

          {
            path: "attend",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: Attend,
            icon: AttendIcon,
            package: "person",
            isVisible: true,
            isPrivate: true,
            name: "attend",
          },
          {
            path: "point",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: UpuPoint,
            icon: PointIcon,
            package: "person",
            isVisible: true,
            isPrivate: true,
            name: "point",
          },
          {
            path: "personnel-list",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: PersonnelList,
            icon: PersonnelsIcon,
            package: "person",
            isPrivate: true,
            isVisible: false,
            name: "user list",
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: NotFound,
  },
];

const App = observer(() => {
  const { auth } = useStore();
  const pubnub = new PubNub({
    publishKey: "pub-c-12c05759-f8bf-4dfb-b709-f7236dff04eb",
    subscribeKey: "sub-c-53a32eb2-a8c4-47a3-84df-4cc6369ee509",
    uuid: auth?.user?.id || "test",
  });

  useEffect(() => {
    if (window.top !== window.self) {
      console.log("IFRAME detected");
      window.top.location = window.location.href;
    }
  }, []);

  useEffect(() => {
    document.documentElement.classList.remove("dark");
    const handleWheel = (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    };
    window.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);
  const pusherConfig = {
    clientKey: "3fd6b03f01edee2945a0",
    cluster: "eu",
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      screens.map((item, index) => {
        return (
          <Route
            key={index}
            path={String(item.path)}
            element={
              item.isPrivate ? (
                <PrivateRoute>
                  <item.element />
                </PrivateRoute>
              ) : (
                <item.element />
              )
            }
            errorElement={<Error />}
          >
            {item?.subRoutes?.map((sub, ind) => {
              return (
                <Route key={ind} path={String(sub.path)} element={<sub.element />} loader={sub?.loader || null}>
                  {sub?.subRoutes?.map((a, b) => {
                    return (
                      <Route key={b} path={String(a.path)} element={<a.element />} loader={a?.loader || null}>
                        {a?.subIdRoutes?.map((s, i) => {
                          return <Route index path={String(s.path)} element={<s.element />} key={i}></Route>;
                        })}
                      </Route>
                    );
                  })}
                </Route>
              );
            })}
          </Route>
        );
      })
    )
  );

  return (
    <PusherProvider {...pusherConfig}>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
      <QueryClientProvider client={queryClient}>
        <PubNubProvider client={pubnub}>
          <RouterProvider router={router} />
        </PubNubProvider>
      </QueryClientProvider>
    </PusherProvider>
  );
});
export default App;
