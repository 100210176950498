import React, { useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import AlertModal from "../alert-Modal/Alert-Modal";
import JunctionUpdate from "../modal/JunctionUpdate";
import CustomModal from "../modal/CustomModal";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import QualityControlForm from "../modal/QualityControlForm";
import { useNavigate } from "react-router-dom";
import { EquipmentEditIcon, NoEquIcon } from "../../assets/icons/machineIcons";
import moment from "moment";
import { CalenderIconProduct } from "../../assets/icons/equipmentIcon";
import { AcceptedIcon, DotsVerticalIcon, PieceIcon, RejectedIcon } from "../../assets/icons/commonIcons";

const NewApprovedPurchaseJunctionCard = ({
  id,
  onClick,
  type,
  image,
  piece,
  qualityAcceptedPiece,
  qualityRejectedPiece,
  qualityPercent,
  refetch,
  name,
  user,
  productDetailClick,
  startDate,
  estimatedDeliveryDate,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isJunction, setIsJunction] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const menuOptions = [
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    // {
    //   label: t("product:productDetail"),
    //   value: "productDetail",
    //   icon: <ProductDetailIcon />,
    // },
    // {
    //   label: t("buttons:delete"),
    //   value: "delete",
    //   icon: <EquipmentDeleteIcon />,
    // },
  ];

  const onDeleted = async () => {
    await junctionService.deleteJunction(id, deleteId).then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        refetch();
        setIsAlert(false);
        setDeleteId(id);
      } else {
        Toast("error", res?.data?.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  return (
    <>
      <div
        className={`flex w-full h-[92px] flex-col  gap-x-2 border-[#D0D5DD] items-start  border  rounded-t-[4px] rounded-b-lg  shadow-xs max-h-[92px] min-h-[92px] bg-white group relative`}
      >
        <div
          className={`w-full border-t-4 rounded-t-[3px] ${
            type == "work" ? "border-t-success-500 " : type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
          }`}
        ></div>
        <div className="flex flex-row items-start gap-x-[6px] p-[6px] w-full">
          <div className="w-[50%] flex flex-row items-center gap-x-[6px]">
            {image ? (
              <img
                data-tooltip-id={`${id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${name} `}
                onClick={onClick}
                src={generateFileUrl(image || "")}
                className="w-[64px] min-w-[64px] h-[48px] cursor-pointer rounded-[2px] border border-secondary-300 "
              />
            ) : (
              <div
                onClick={onClick}
                className="w-[64px] min-w-[64px] h-[48px] rounded border border-secondary-300 shadow-sm bg-secondar-50 flex items-center justify-center"
              >
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start gap-y-[6px] w-full">
              <div className="flex flex-row items-center w-full">
                <p className="text-[#101828] font-semibold text-sm truncate w-[240px]">{name || "---"}</p>
              </div>
              <div className="flex flex-row items-center">
                <PieceIcon />
                <p className="text-xs text-secondary-600 ml-1 font-medium">{piece || "---"}</p>
                <p className="text-xxs font-normal ml-0.5 text-secondary-600">{t("product:pieces")}</p>
              </div>
            </div>
          </div>
          {user?.avatar ? (
            <div
              onClick={() => {
                navigate(`/app/machine/digital-twin/user-detail/${user?.id}/${moment(startDate).format("YYYY-MM-DD")}`);
              }}
              data-tooltip-id={`${id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${user?.name + " " + user?.lastName} `}
              className="relative ml-auto z-50 mr-[90px]"
            >
              <img className="w-[48px] min-w-[48px] h-[48px] cursor-pointer rounded-full border border-secondary-300" src={generateFileUrl(user?.avatar)} />
            </div>
          ) : (
            <div
              onClick={() => {
                navigate(`/app/machine/digital-twin/user-detail/${user?.id}/${moment(startDate).format("YYYY-MM-DD")}`);
              }}
              data-tooltip-id={`${id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${user?.name + " " + user?.lastName} `}
              className="relative ml-auto z-50 mr-[90px]"
            >
              <NoAvatar size={48} fontSize={16} name={user?.name} lastName={user?.lastName} color={"#475467"} />
            </div>
          )}
          <div className="flex flex-col z-[20] items-start rounded border-[0.5px] absolute border-[#D0D5DD] w-[82px] min-w-[82px] bg-white right-[6px]">
            <div className="w-full flex  items-center justify-center rounded-t-lg pr-1 py-[2px] pl-1 bg-[#F9FAFB] border-b">
              <p className="text-xxs font-semibold text-secondary-700">{t("reports:quality")}</p>
              <div className="px-1 py-[2px] rounded-[2px]  bg-[#079455] w-[35px] h-[14px] flex items-center justify-center ml-auto">
                <p className="text-xxs text-white font-semibold">%{qualityPercent || "0"}</p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                <AcceptedIcon />
              </div>
              <p className="text-xs font-medium ml-1 text-success-600">{qualityAcceptedPiece || "0"}</p>
              <p className="text-[10px] font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                <RejectedIcon />
              </div>
              <p className="text-xs font-medium ml-1 text-error-600">{qualityRejectedPiece || "0"}</p>
              <p className="text-xxs font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full h-[26px] bg-[#F9FAFB] py-1 px-2 rounded-b-[8px]  border-t-[0.5px] border-t-[#D0D5DD]">
          <div className="flex flex-row gap-1 items-center ">
            <CalenderIconProduct />
            <p className="text-xs font-medium text-secondary-600">{moment(estimatedDeliveryDate)?.format("DD/MM/YYYY") || "--/--/--"}</p>
            <p className="text-[10px] font-normal text-secondary-600">{t("product:deliveryDate")}</p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[8px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[2px] right-[32px] z-[150] rounded-lg px-[6px] py-1 group-hover:flex">
            {menuOptions?.map((item, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "delete") {
                      setIsAlert(true);
                      setDeleteId(id);
                    }
                    if (item.value === "edit") {
                      setIsJunction(true);
                    }
                    if (item.value === "productDetail") {
                      productDetailClick();
                    } else setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    item?.value === "delete" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                  }`}
                >
                  {item?.icon}
                  <p className={`font-medium text-sm  ${item?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{item?.label}</p>
                </button>
              );
            })}
          </div>
        )}
      </div>
      <Tooltip id={`${id}`} />
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isOpenForm}
        setIsOpen={setIsOpenForm}
        modalTitle={t("product:qualityControlForm")}
        onClose={() => {
          setIsOpenForm(false);
        }}
        children={
          <QualityControlForm
            onClose={() => {
              setIsOpenForm(false);
            }}
            junctionId={id}
            refetch={refetch}
          />
        }
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={id}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default NewApprovedPurchaseJunctionCard;
