import React, { useEffect, useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import { embeddedServerService } from "../../../services/embedded-server.service";
import { Button } from "../../../components";

const LoadDefaultSettingsModal = ({ closeModal, metadataId, getRegisteredSensors, setCards }) => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);

  const handleData = async () => {
    const { data } = await embeddedServerService.getDefaultSensorSettings();
    setData(data);
  };

  const loadDefaultSettings = async () => {
    await embeddedServerService.loadDefaultSensorSettings(metadataId, { defaultSensorId: selected }).then((res) => {
      getRegisteredSensors();
      const newData = res.data?.data?.map((item) => {
        if (item?.analogSensor) {
          return { ...item, sensorId: `${item?.analogSensor?.whichSensor}-${"analog"}-${item?.value}`, recordSensorId: item?.analogSensor?.id };
        } else {
          return { ...item, sensorId: `${item?.digitalSensor?.whichSensor}-${"digital"}-${item?.value}`, recordSensorId: item?.digitalSensor?.id };
        }
      });
      setCards(newData);
      closeModal();
    });
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <div className="w-[496px] flex flex-col mt-6">
      <SearchInput />
      <div className="flex flex-col w-full mt-6">
        {data.map((item, index) => {
          return (
            <div
              onClick={() => {
                setSelected(item.id);
              }}
              key={index}
              className="min-h-[56px] w-full border-b border-gray-200 flex items-center select-none cursor-pointer"
            >
              <div className={`w-5 h-5 rounded-full ${selected == item?.id ? "border-[6px] border-[#3E4784]" : "border border-gray-300"}`} />
              <p className="font-semibold text-secondary-700 ml-3">{item?.name}</p>
              <div className="ml-auto">
                <p className="text-secondary-600">ID: {item?.code}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-full flex mt-6 items-center justify-between gap-4">
        <Button onClick={closeModal} label={"Vazgeç"} colorType={"secondary-gray"} size={"md"} />
        <Button onClick={loadDefaultSettings} label={"Uygula"} colorType={"primary-embedded"} size={"md"} />
      </div>
    </div>
  );
};

export default LoadDefaultSettingsModal;
