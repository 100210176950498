import React, { memo, useEffect, useState } from "react";
import GroupChatCard from "./GroupChatCard";
import TaskStatusBar from "./TaskStatusBar";
import { useQuery } from "@tanstack/react-query";
import { chatService } from "../../../services/chat.service";
import { Button } from "../../../components";
import { ChevronDown } from "../../../assets/icons/PersonIcons";
import Badgets from "../../../components/buttons/Badgets";
import Skeleton from "react-loading-skeleton";
import { useStore } from "../../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../components/inputs/SearchInput";
import diacritics from "diacritics";

const GroupList = ({ conversationId, searchView }) => {
  const { chat } = useStore();
  const [searchInput, setSearchInput] = useState("");
  const { data, refetch, isLoading } = useQuery({
    retry: 0,
    queryKey: ["getGroupConversations"],
    queryFn: async () => await chatService.getGroupConversationsQuery(),
  });

  useEffect(() => {
    refetch();
  }, [conversationId]);

  const filteredData = data?.filter((item) => {
    const lowerCaseSearchInput = diacritics.remove(searchInput).toLowerCase();
    const filteredTaskGroups = item?.taskGroup?.filter((group) =>
      diacritics.remove(group.name).toLowerCase().includes(lowerCaseSearchInput)
    );
  
    return filteredTaskGroups.length > 0;
  });

  // useEffect(() => {
  //   if (data) {
  //     navigate(data?.[0]?.taskGroup?.[0]?.conversationId);
  //   }
  // }, [isLoading]);
  useEffect(() => {
    if (!searchView) {
      setSearchInput("");
    }
  }, [searchView]);

  return (
    <div className="w-full h-full flex flex-col">
      {isLoading ? (
        <Skeleton className="w-full" height={40} />
      ) : (
        <>
          <TaskStatusBar data={data} />
          {searchView ? (
            <div className="w-full px-1 mt-5">
              <SearchInput focus={searchView} setSearchVal={setSearchInput} theme={"person"}/>
            </div>
          ) : null}
        </>
      )}
      <div className="flex-1 overflow-y-auto scrollbar-hide w-full mt-2">
        {isLoading
          ? [...new Array(20)].map((_) => (
              <Skeleton className="w-full" height={42} />
            ))
          : filteredData?.map((item, index) => {
              if (item.taskGroup.length > 0) {
                return (
                  <ExpandedButton
                    searchInput={searchInput}
                    store={chat}
                    key={index}
                    item={item}
                  />
                );
              }
            })}
      </div>
    </div>
  );
};

export default memo(GroupList);

const ExpandedButton = memo(({ item, store, searchInput }) => {
  const [expanded, setExpanded] = useState(true);

  const filteredTaskGroup = item?.taskGroup?.filter((group) =>
    diacritics
      .remove(group.name)
      .toLowerCase()
      .includes(searchInput.toLowerCase())
  );

  return (
    <div className="w-full flex flex-col mt-2">
      <div
        onClick={() => {
          setExpanded(!expanded);
        }}
        className="flex w-full items-center justify-between px-1 border-b border-secondary-200 pb-1"
      >
        <div className="flex items-center gap-2">
          <p className="text-base font-semibold text-secondary-700">
            {item.departmentName}
          </p>
          <Badgets
            label={item.taskGroup.length}
            colorType={"fill-success"}
            size={"sm"}
          />
        </div>
        <div className={`w-9 ${expanded && "rotate-180"}`}>
          <Button
            colorType={"tertiary-gray"}
            size={"sm"}
            iconLeft={<ChevronDown />}
          />
        </div>
      </div>
      {expanded && (
        <div className="flex flex-col mt-2">
          {filteredTaskGroup.map((group, ind) => {
            return (
              <GroupChatCard
                key={ind}
                name={group.name}
                image={group.image}
                done={group.doneLength}
                todo={group.todoLength}
                total={group.totalLength}
                expired={group.expiredLength}
                approved={group.approvedLength}
                conversationId={group.conversationId}
                onClick={() => {
                  store?.setCurrentGroupChatId(null);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
});
