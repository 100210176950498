import React from 'react'
import { useTranslation } from 'react-i18next';
import { FactoryIcon } from '../../../assets/icons/commonIcons';

const AllOperationsNewCard = ({ isPastDay, pendingJunctionlength, activeJunctionlength, pastJunctionlength, onClick, selected }) => {
    const { t } = useTranslation()
    return (
        <div className='min-w-[192px] border-b pr-5 max-h-[96px]'>
        <div
            onClick={() => {
                onClick("all");
            }}

            className={`min-w-[172px] rounded-t-lg ${selected === "all" ? "border-b-[2px] border-b-[#A15C07] h-[96px]" : "h-[96px]"} rounded-b-none border cursor-pointer border-seconday-300  select-none flex flex-col`}
        >
            <div className="flex flex-row items-center justify-center w-full gap-x-1.5 p-2 border-b border-b-[#E4E7EC]">
                <span
                    className={`min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px] ${selected === "all" ? "text-[#DC6803]" : "text-[#475467]"
                        }`}
                >
                    <FactoryIcon />
                </span>
                <p className={`text-base  truncate ${selected === "all" ? "text-[#B54708] font-semibold" : "text-[#667085] font-medium"} `}>{t("product:allNew")}</p>
            </div>
            <div className="flex flex-row items-center w-full h-full">
                {!isPastDay && (
                    <div className={`flex flex-col items-center w-1/2  border-r ${selected === "all" ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
                        <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                            <p className={`text-xs ${selected === "all" ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>{t("product:actives")}</p>
                        </div>
                        <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                            <p className={`text-sm ${selected === "all" ? "font-semibold text-[#079455]" : "font-medium text-[#667085]"}`}>{activeJunctionlength || 0}</p>
                        </div>
                    </div>
                )}

                <div className={`flex flex-col items-center w-1/2  ${selected === "all" ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"} ${isPastDay ? "border-r" : ""}`}>
                    <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                        <p className={`text-xs ${selected === "all" ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>{t("product:waiting")}</p>
                    </div>
                    <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                        <p className={`text-sm ${selected === "all" ? "font-semibold text-[#A15C07]" : "font-medium text-[#667085]"}`}>{pendingJunctionlength || 0}</p>
                    </div>
                </div>
                {isPastDay && (
                    <div className={`flex flex-col items-center w-1/2  ${selected === "all" ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
                    <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                        <p className={`text-xs ${selected === "all" ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}> {t("product:completedNew")}</p>
                    </div>
                    <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                        <p className={`text-sm ${selected === "all" ? "font-semibold text-secondary-600" : "font-medium text-[#667085]"}`}>{pastJunctionlength || 0}</p>
                    </div>
                </div>
                )}
            </div>
        </div>
        </div>
    )
}

export default AllOperationsNewCard