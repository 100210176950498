import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import {
  AcceptedIcon,
  RejectedIcon,
  StockExternalIcon,
} from "../../../assets/icons/productIcons";
import { CustomModal } from "../../../components";
import ExternalInternalRequestForm from "../modals/ExternalInternalRequestForm";
import {
  DotsVerticalIcon,
  PurchaseIcon,
} from "../../../assets/icons/commonIcons";
import { EquipmentEditIcon } from "../../../assets/icons/machineIcons";
import StockCardOperationModal from "../modals/StockCardOperationModal";
import { useStore } from "../../../hooks/useStores";

const StockExternalOperationCard = ({
  type = "Boyama",
  estimatedTime,
  name = "External Operation",
  acceptedPiece,
  rejectedPiece,
  allData,
  onClick,
  operations,
  showMenuOptions = true,
  product,
  width = "w-[294px]",
  refetch,
}) => {
  const { t } = useTranslation();
  const {auth} = useStore()
  const [isOpen, setIsOpen] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [stockEditModal, setStockEditModal] = useState(false);
  const menuOptionsSelected = [
    {
      label: t("product:purchaseRequestCreation"),
      value: "form",
      icon: <PurchaseIcon />,
    },
    {
      label:t("product:editStock"),
      value: "stockEdit",
      icon: <EquipmentEditIcon />,
    },
  ];
 const menuOptions = auth?.user?.company?.id =="e5c06127-3c5d-44b9-8403-74c160fc7f59" ? menuOptionsSelected : [menuOptionsSelected[0]]
  return (
    <>
      <div
        data-tooltip-id={`${allData}`}
        data-tooltip-content={`
     ${t("product:operationTypes")}: ${type} 
      `}
        data-tooltip-place="right"
        className={`bg-white py-2 pl-3 pr-2 border border-secondary-300 rounded-lg ${width} flex items-start group relative flex-col`}
      >
        <div className="flex flex-row gap-x-3 w-full items-start">
          <div className="flex flex-col item-start ">
            <div className="flex flex-row items-center ">
              <StockExternalIcon />
              <p className="text-sm font-semibold text-secondary-900 ml-2 w-[140px] truncate">
                {name}
              </p>
            </div>
            <div className="flex flex-row items-center h-[22px] w-full border-b border-b-secondary-200 mt-2">
              <div className="w-[50%] py-[2px] pr-[6px]">
                <p className="text-xs font-normal text-secondary-600">
                  {t("product:opType")}
                </p>
              </div>
              <span className="h-full w-[1px] bg-secondary-200"></span>
              <div className="w-[50%] flex flex-row items-center gap-1 py-[2px]">
                <p className="text-xs w-[120px] font-medium text-secondary-600  pl-[6px] truncate">
                  {type}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center h-[22px] w-full ">
              <div className="w-[50%] py-[2px] pr-[6px]">
                <p className="text-xs font-normal text-secondary-600">
                  {t("product:estTime")}
                </p>
              </div>
              <span className="h-full w-[1px] bg-secondary-200"></span>
              <div className="w-[50%] flex flex-row items-center gap-1 py-[2px]">
                <p className="text-xs font-medium text-secondary-600 pl-[6px]">
                  {estimatedTime} {t("product:day")}
                </p>
              </div>
            </div>{" "}
          </div>
          <div
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={` absolute bottom-1 w-[24px] min-h-[24px] max-h-[24px] min-w-[24px] border rounded bg-white right-1 h-[24px] cursor-pointer hidden  ${
              showMenuOptions
                ? "group-hover:flex items-center justify-center"
                : "hidden"
            }`}
          >
            <DotsVerticalIcon />
          </div>
          {isOpen && (
            <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[12px] right-[32px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
              {menuOptions?.map((item, index) => {
                return (
                  <button
                    onClick={() => {
                      if (item.value === "details") {
                        onClick();
                      }
                      if (item.value === "form") {
                        setIsPurchased(true);
                      }
                      if (item.value === "stockEdit") {
                        setStockEditModal(true);
                      }
                      setIsOpen(false);
                    }}
                    key={index}
                    type="button"
                    className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                      item?.value === "delete"
                        ? "hover:bg-[#FECDCA]"
                        : "hover:bg-secondary-100"
                    }`}
                  >
                    {item?.icon}
                    <p
                      className={`font-medium text-sm  ${
                        item?.value === "delete"
                          ? "text-[#D92D20]"
                          : "text-secondary-700"
                      }`}
                    >
                      {item?.label}
                    </p>
                  </button>
                );
              })}
            </div>
          )}

          {/* kalite yeni ui */}
          {/* <div className="flex flex-col border border-secondary-200 bg-white  w-[84px] min-w-[84px]  ml-auto rounded">
            <div className="flex bg-[#F9FAFB] rounded-t px-[6px] py-[2px]  border-b border-b-secondary-200 gap-x-1 items-center justify-center">
              <QualityIcon />
              <p className="text-secondary-700 font-semibold text-[10px]">
                {t("reports:quality")}
              </p>
            </div>
            <div className="flex flex-col  items-center justify-center">
              <div className="flex gap-x-[2px] p-1 items-center">
                <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                  <AcceptedIcon />
                </div>
                <span className="text-xs font-medium text-[#079455]">
                  {acceptedPiece}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
              <div className="flex gap-x-[2px] p-1 items-center">
                <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                  <RejectedIcon />
                </div>
                <span className="text-xs font-medium text-[#D92D20]">
                  {rejectedPiece}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
            </div>
          </div> */}
          <div className="w-[84px] min-w-[84px] border border-secondary-200 px-[6px] pt-[2px] pb-1 flex flex-col rounded-md gap-y-2 ml-auto">
            <div className="flex flex-row justify-center items-center w-full gap-x-1">
              <span className="w-2 h-2 rounded-[2px] bg-[#17B26A]"></span>
              <p className="text-xs font-normal text-secondary-500">
                {t("reports:quality")}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                <AcceptedIcon />
              </div>
              <p className="text-xs font-normal text-success-600">
                {acceptedPiece}
              </p>
              <p className="text-[10px] font-normal text-secondary-500">
                {t("product:pcs")}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                <RejectedIcon />
              </div>
              <p className="text-xs font-normal text-error-600">
                {rejectedPiece}
              </p>
              <p className="text-[10px] font-normal text-secondary-500">
                {t("product:pcs")}
              </p>
            </div>
          </div>
        </div>
        <Tooltip id={`${allData}`} />
      </div>
      <CustomModal
        isOpen={isPurchased}
        setIsOpen={setIsPurchased}
        // onClose={() => {
        //   setIsPurchased(false);
        // }}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <ExternalInternalRequestForm
            setIsOpen={isPurchased}
            closeModal={() => {
              setIsPurchased(false);
            }}
            purchaseData={operations}
            product={product}
          />
        }
      />
       <CustomModal
        isOpen={stockEditModal}
        setIsOpen={setStockEditModal}
        modalTitle={t("product:editStock")}
        children={
          <StockCardOperationModal
            setIsOpen={setStockEditModal}
            closeModal={() => {
              setStockEditModal(false);
            }}
            refetch={refetch}
            product={product}
            purchaseData={operations}
          />
        }
      />
    </>
  );
};

export default StockExternalOperationCard;
