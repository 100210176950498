import React from "react";
import { useTranslation } from "react-i18next";
import generateFileUrl from "../../utils/generateFileUrl";
import { QuantityIcon } from "../../assets/icons/stepsIcons";
import { QuantityDeclineIcon } from "../../assets/icons/productIcons";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";

function NewRawMaterialCard({ item }) {
  const { t } = useTranslation();
  return (
    <div style={{ width: "calc(100% - 40px)" }} className="h-full border border-[#D0D5DD] rounded-lg p-2 flex flex-col relative border-r-0">
      <div className="flex">
        {item?.image ? (
          <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden mr-3">
            <img src={generateFileUrl(item?.image)} />
          </div>
        ) : (
          <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden mr-3">
            <NoAvatar name={item?.name || "--"} rounded={4} theme={"product"} />
          </div>
        )}
        <div className="flex flex-col gap-y-1 w-full">
          <span
            data-tooltip-id={`${item?.id}`}
            data-tooltip-place={"right"}
            data-tooltip-content={`${item?.name} `}
            className="text-[#101828] font-medium text-sm truncate  w-[78%]"
          >
            {item?.name || "--"}
          </span>
          <span className="text-xs font-normal text-[#475467]">
            {t("product:stock")} ID:
            {item?.stockCode ? item?.stockCode : "--"}
          </span>
        </div>
      </div>
      <div className="flex w-full mt-1 items-center justify-center">
        <div className="flex w-[50%] whitespace-nowrap items-center gap-x-[2px]">
          <span className="flex items-center gap-x-1">
            <QuantityIcon />
            <span className="text-xs font-medium text-[#475467]">{item?.quantity || "0"}</span>
          </span>
          <span className="text-[#667085] font-normal text-[10px] leading-4">{t("product:totalStock")}</span>
        </div>
        <div className="flex w-[50%] whitespace-nowrap items-center gap-x-[2px]">
          <span className="flex items-center gap-x-1">
            <QuantityDeclineIcon />
            <span className="text-xs font-medium text-[#D92D20]">{item?.scrapQuantity || "0"}</span>
          </span>
          <span className="text-[#667085] font-normal text-[10px] leading-4">{t("product:totalScrap")}</span>
        </div>
      </div>
      <div className="flex border border-[#D0D5DD] min-w-[67px] max-w-[67px] min-h-[67px] max-h-[67px] absolute right-[-27px] top-[11px] rotate-45 border-b-0 border-l-0 rounded-tl-md rounded-br-md rounded-tr-md"></div>
      <Tooltip id={`${item?.id}`} />
    </div>
  );
}

export default NewRawMaterialCard;
