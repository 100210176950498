import React, { useEffect, useState } from "react";
import { Button, CustomModal } from "../../components";
import { Loader, PlusIcon } from "../../assets/icons/stepsIcons";
import { processService } from "../../services";
import { useTranslation } from "react-i18next";
import Badgets from "../../components/buttons/Badgets";
import { personalService } from "../../services/personal.service";
import { Toast } from "../../utils/toastify/toast";
import OperationCard from "../../steps/process/components/OperationCard";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { AddProcessModal } from "../../steps/process";
import { useStore } from "../../hooks/useStores";

const SettingInternalOperation = () => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [process, setProcess] = useState([{}, {}]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [temporaryProcesses, setTemporaryProcesses] = useState([]);
  const [deleteProcess, setDeleteProcess] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [selectedTab, setSelectedTab] = useState("process");
  const [loading, setLoading] = useState(false);

  const handleData = async () => {
    setLoading(true);
    const { data } = await personalService.activePersonals();
    // setLoading(true);
    processService.activeProcesses().then(({ data }) => {
      data.map((d) => {
        return {
          ...d,
          responsibleUser: d.responsibleUser.map((r) => r.id),
        };
      });
      setProcess(data);
      setTemporaryProcesses(data);
      setLoading(false);
    });
  };

  const handleDelete = (id) => {
    setDeleteProcess(id);
    setSelectedItem(id);
    setIsOpen(false);
  };

  const handleEdit = (id) => {
    setSelectedItem(id);
    setIsOpen(true);
  };

  const onDelete = async (id) => {
    await processService.deleteProcess(id).then((res) => {
      setAlertOpen(false);
      Toast("success", t("addProcess:deletedProcessMessage"));
    });
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center mt-64 justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="w-full   h-[80vh] flex xs:h-[78vh] sm:h-[75vh]  md:h-[75vh] flex-col gap-5 overflow-hidden">
          <div className="flex xs:flex-col xs:gap-4 items-center min-h-[72px] border-b w-full border-secondary-200">
            <div className="flex flex-col w-full xs:h-[64px] xs:border-b xs:pb-2">
              <p className="text-gray-900 text-lg font-medium dark:text-[#FAFAFA]">
                {t("settingTab:internalOperation")}
              </p>
              <p className="text-gray-500 text-sm dark:text-[#D6D6D6]">
                {t("settingTab:manageYourInternal")}
              </p>
            </div>
            <div className="w-fit xs:w-full">
              <Button
                colorType={"secondary-gray"}
                label={t("buttons:createNewInternalOparation")}
                iconLeft={<PlusIcon />}
                size={"md"}
                onClick={() => {
                  setIsOpen(true);
                  setSelectedItem(null);
                }}
              />
            </div>
          </div>
          <div className="w-fit">
            <Badgets
              size={"md"}
              label={
                process?.length +
                "/" +
                auth?.user?.company?.limits?.operation +
                " " +
                t("product:internalOperation")
              }
              colorType={"fill-primary"}
            />
          </div>

          <div className="h-[80%] w-full overflow-y-scroll flex flex-col gap-4 scrollbar-hide">
            {temporaryProcesses?.map((e, i) => {
              return (
                <OperationCard
                  key={i}
                  t={t}
                  name={e?.name}
                  icon={e?.iconKey}
                  responsibleUser={e?.responsibleUser.map(
                    (r) => r?.name + " " + r?.lastName + " "
                  )}
                  editOnClick={() => {
                    handleEdit(e);
                  }}
                  deleteOnclick={() => {
                    handleDelete(e?.id);
                    setAlertOpen(true);
                  }}
                />
              );
            })}
          </div>
        </div>
      )}

      <CustomModal
        children={
          <AddProcessModal
            data={process}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedProcess={selectedItem}
            setSelectedProcess={setSelectedItem}
            handleData={handleData}
          />
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={
          selectedItem
            ? t("settingTab:updateInternalOperation")
            : t("buttons:createNewInternalOparation")
        }
      />
      <AlertModal
        title={t("equipments:deleteEquipment")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={alertOpen}
        setIsOpen={setAlertOpen}
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        deleteProcess={deleteProcess}
        applyButton={async () => {
          await onDelete(deleteProcess);
          await handleData();
          setIsAlert(false);
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default SettingInternalOperation;
