import React, { useEffect, useState } from "react";
import diacritics from "diacritics";
import Badgets from "../../../components/buttons/Badgets";
import { Button } from "../../../components";
import { ChevronDown } from "../../../assets/icons/modalIcons";

const ExpandedCard = ({
  t,
  chat,
  auth,
  item,
  index,
  adminUser,
  triggerUser,
  searchValue,
  triggerAdmin,
  PersonalItem,
  selectedUsers,
  selectAllPress,
  selectedDepartment,
}) => {
  const [expanded, setExpanded] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    if (selectedDepartment) {
      setExpanded(selectedDepartment);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedDepartment) {
      setSelectAllChecked(item.departmentId === selectedDepartment);
    }
  }, [selectedDepartment]);

  const filteredUsers = item?.users?.filter((user) =>
    diacritics.remove(`${user?.name} ${user?.lastName}`).toLowerCase().includes(diacritics.remove(searchValue).toLowerCase())
  );

  return (
    <>
      <div
        key={index}
        onClick={() => {
          if (item?.departmentId === expanded) {
            setExpanded("");
          } else {
            setExpanded(item.departmentId);
          }
        }}
        className="flex items-center w-full justify-between cursor-pointer border-b pb-2 mt-3"
      >
        <div className="flex items-center gap-2">
          <p className="text-base font-semibold text-[#101828]">{item.departmentId === "BLANK_DEPARTMENT" ? "Diğer" : item?.departmentName}</p>
          <Badgets colorType={"fill-success"} label={filteredUsers?.length || 0} />
        </div>
        <div className="ml-auto gap-4 flex items-center justify-center z-20 cursor-pointer">
          <div
            className="ml-auto flex gap-3 items-center justify-center text-sm font-normal cursor-pointer select-none text-[#667085] py-2"
            onClick={(e) => {
              e.stopPropagation();
              selectAllPress(item?.users);
            }}
          >
            <p className="whitespace-nowrap">{t("buttons:selectAll")}</p>
          </div>
          <Button
            colorType={"tertiary-gray"}
            size={"md"}
            iconLeft={expanded === item?.departmentId ? <ChevronDown className="rotate-180" /> : <ChevronDown />}
          />
        </div>
      </div>
      {expanded === item?.departmentId && (
        <div className="flex flex-wrap">
          {filteredUsers?.map((user, index) => {
            return (
              user?.id !== auth?.user?.id && (
                <div key={index} className={`w-${filteredUsers.length === 1 ? "full" : "1/2"} p-2`}>
                  <PersonalItem
                    chat={chat}
                    item={user}
                    index={index}
                    isSelected={selectedUsers?.find((a) => a?.id == user?.id) ? true : false}
                    triggerUser={triggerUser}
                    isAdmin={adminUser?.find((a) => a?.id == user?.id) ? true : false}
                    triggerAdmin={triggerAdmin}
                  />
                </div>
              )
            );
          })}
        </div>
      )}
    </>
  );
};

export default ExpandedCard;
