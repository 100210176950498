import React, { useEffect, useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import Badgets from "../../../components/buttons/Badgets";
import { Button } from "../../../components";
import { IconDropdown } from "react-day-picker";
import generateFileUrl from "../../../utils/generateFileUrl";
import { personalService } from "../../../services/personal.service";
import { notificationService } from "../../../services/notification.service";
import { Toast } from "../../../utils/toastify/toast";
import { CheckIcon } from "../../../assets/icons/PersonIcons";
import diacritics from "diacritics";

export default function AddUserToNotification({
  t,
  setValue,
  sendedData,
  setIsSelectUsersModal,
  setIsNewNotifyModalOpen,
  notifyRefetch,
}) {
  const [data, setData] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const getDepartmentToUser = async () => {
    try {
      const { data } = await personalService.getDepartmentToUser();
      setData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const selectAll = () => {
    const allUserIds = data.reduce((acc, department) => {
      return acc.concat(department.users.map((user) => user.id));
    }, []);
    if (selectedUsers.length === allUserIds.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(allUserIds);
    }
    const userObjects = allUserIds.map((userId) => ({ userId }));
    setValue("users", userObjects);
  };

  const sendNotify = async (e) => {
    if (e?.users?.length > 0) {
      await notificationService
        .createNotification(e)
        .then((res) => {
          notifyRefetch();
          setIsSelectUsersModal(false);
          Toast("success", t("chat:announcementCreated"));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Toast("error", t("chat:announcementCannot"));
      setIsSelectUsersModal(false);
    }
  };

  const sendData = async () => {
    const lastData = {
      ...sendedData,
      users: selectedUsers.map((id) => {
        return { userId: id };
      }),
    };

    await sendNotify(lastData);
  };

  const totalUsers = () => {
    return data.reduce((acc, department) => {
      return acc + department.users.length;
    }, 0);
  };

  useEffect(() => {
    getDepartmentToUser();
  }, []);

  const toggleDropdown = (departmentId) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [departmentId]: !prev[departmentId],
    }));
  };

  return (
    <div className="flex flex-col min-w-[452px] max-w-[452px] min-h-[580px] max-h-[580px]">
      <div className="flex w-full flex-col mt-6">
        <SearchInput
          setSearchVal={setSearchValue}
          placeholder={t("chat:search")}
        />
        <div className="flex item-center w-full my-6">
          <Badgets
            colorType={"fill-success"}
            label={selectedUsers?.length + "/" + totalUsers()}
            size={"md"}
          />
          <div
            className=" text-md font-semibold text-[#107569] ml-auto cursor-pointer"
            onClick={() => selectAll()}
          >
            {t("buttons:selectAll")}
          </div>
        </div>
        <div className="flex flex-col max-h-[368px] min-h-[368px] overflow-y-auto scrollbar-hide gap-y-6 border-b">
          {data?.map((department, index) => {
            if (department?.users?.length === 0) {
              return null;
            }
            const filteredUsers = department.users.filter((user) =>
              diacritics
                .remove(`${user?.name} ${user?.lastName}`)
                .toLowerCase()
                .includes(diacritics.remove(searchValue.toLowerCase()))
            );

            return (
              <>
                <div
                  onClick={() => toggleDropdown(department?.departmentId)}
                  key={index}
                  className="flex cursor-pointer flex-col w-full border-b pb-1 border-[#EAECF0]"
                >
                  <div className="flex w-full items-center">
                    <span className="text-md font-semibold text-[#101828] mr-2 truncate">
                      {department?.departmentName}
                    </span>
                    <Badgets
                      colorType={"fill-success"}
                      label={filteredUsers?.length}
                      size={"sm"}
                    />
                    <div className="flex ml-auto items-center">
                      {/* <div
                        className={`flex w-5 h-5 items-center justify-center rounded-md ${
                          true ? "bg-[#0E9384] border" : "bg-[black]"
                        }`}
                      >
                        {true ? <CheckIcon /> : null}
                      </div> */}
                      {/* <div className="text-sm font-normal text-[#667085] ml-3 mr-4 whitespace-nowrap cursor-pointer">
                        {t("person:selectAll")}
                      </div> */}
                      <Button
                        colorType={"tertiary-gray"}
                        iconLeft={<IconDropdown />}
                      />
                    </div>
                  </div>
                </div>
                {openDropdowns[department.departmentId] && (
                  <div className="flex flex-col gap-y-4">
                    {filteredUsers?.map((user, userIndex) => (
                      <div
                        onClick={() => {
                          if (selectedUsers?.includes(user?.id)) {
                            const updatedUsers = selectedUsers?.filter(
                              (id) => id !== user?.id
                            );
                            setSelectedUsers(updatedUsers);

                            const userObjects = updatedUsers.map((userId) => {
                              return { userId: userId };
                            });
                            setValue("users", userObjects);
                          } else {
                            const updatedUsers = [...selectedUsers, user?.id];
                            setSelectedUsers(updatedUsers);

                            const userObjects = updatedUsers.map((userId) => {
                              return { userId: userId };
                            });
                            setValue("users", userObjects);
                          }
                        }}
                        key={userIndex}
                        className="flex cursor-pointer w-full min-h-[40px] max-h-[40px] items-center"
                      >
                        <div
                          className={`flex w-5 h-5 rounded-md items-center justify-center ${selectedUsers?.includes(user?.id)
                              ? "bg-[#0E9384] "
                              : "bg-white border border-[#D0D5DD]"
                            }`}
                        >
                          {selectedUsers?.includes(user?.id) ? (
                            <CheckIcon />
                          ) : null}
                        </div>
                        {user?.avatar ? (
                          <img
                            src={generateFileUrl(user?.avatar)}
                            className="min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] rounded-full ml-3"
                            alt={user?.name}
                          />
                        ) : (
                          <div
                            className={
                              "min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] rounded-full ml-3 bg-primary-700 flex border-4 border-primary-400 items-center justify-center font-medium text-white text-xl"
                            }
                          >
                            {user?.name?.charAt(0).toUpperCase()}
                          </div>
                        )}
                        <div className="flex flex-col justify-center ml-3">
                          <span className="text-[#344054] text-sm font-semibold">
                            {user?.name} {user?.lastName}
                          </span>
                          <span className="text-sm font-normal text-[#667085]">
                            {user?.metadata?.department?.name || "Diğer"}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            );
          })}
        </div>
        <div className="flex mt-6">
          <Button
            onClick={() => {
              sendData();
              setIsNewNotifyModalOpen(false);
            }}
            colorType={"primary-person"}
            label={t("chat:sendAnnouncement")}
            size={"lg"}
          />
        </div>
      </div>
    </div>
  );
}
