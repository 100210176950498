import React, { useEffect, useState } from "react";
import MultipleEventRectMulti from "../../../components/upuTimeline/components/MultipleEventRectMulti";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import moment from "moment";
import { authStore } from "../../../stores/auth.store";

const OperationNodeTimelines = ({
  data,
  setShowTooltip,
  endTime,
  startTime,
  selectedShift,
  svgWidth,
  handleMouseMove,
  handleMouseOut,
  handleShiftClick,
  selectedJunction,
  setSelectedJunction,
  yOffset,
  nodeId,
  findJunctionIndexByIds,
  date,
  deviceId,
  setStartTime,
  setEndTime,
}) => {
  // const handleMouseOver = (index, xPos, yPos, e) => {
  //   if (index.index == 0) {
  //     values.filter((e) => {
  //       if (e.junctionId == index.junctionId) {
  //         setShowTooltip({ index, xPos, yPos, junction: e });
  //       } else {
  //         setShowTooltip({ index, xPos, yPos });
  //       }
  //     });
  //   } else {
  //     setShowTooltip({ index, xPos, yPos });
  //   }
  // };
  const handleMouseOver = (index, xPos, yPos, e) => {
    if (index.index == 0) {
      values.filter((e) => {
        if (e?.junctionId == index?.junctionId) {
          setShowTooltip({ index, xPos, yPos, junction: e });
        } else if (index?.lossesId) {
          setShowTooltip({ index, xPos, yPos });
        } else if (index?.junctionType === "subEvent") {
          setShowTooltip(null)
        }
      });
    } else {
      setShowTooltip({ index, xPos, yPos });
    }
  };

  const _ = require("lodash");
  const [values, setValues] = useState([]);
  const calculateData = async (data) => {
    const datas = [];
    data?.map((value, no) => {
      value?.value.map((da, i) => {
        datas.push({
          name: value?.name,
          type: da?.index == 1 ? "status" : da.index == 2 ? "shift" : da.index == 0 ? "junction" : "",
          name: value?.name,
          status: value?.status,
          node: value.node,
          junctionId: da?.junctionId,
          lossesId: da?.lossesId,
          lossesName: da?.losseType?.name,
          reasonDescription: da?.losseType?.description,
          avatar: da?.avatar,
          productImage: da?.productImage,
          actionId: da?.actionId,
          productData: da?.productName,
          operationNo: da?.operationNo,
          startDate: da?.start,
          endDate: da?.end,
          timeDiff: da?.timeDiff,
          index: da?.index,
          acceptedWorkCount: da?.acceptedWorkCount,
          rejectedWorkCount: da?.rejectedWorkCount,
          junctionType: da?.type,
          value: [
            da?.index,

            /// moment(da.start).unix(),
            // moment(da.end).unix(),
            moment(timeFormatter(da.start, authStore?.user?.company?.timeZone).formatted)?.unix(),
            moment(timeFormatter(da.end, authStore?.user?.company?.timeZone).formatted)?.unix(),
            da.timeDiff,
          ],
          shiftId: da?.shiftId,
          color:
            da?.index == 1
              ? value?.itemStyle?.normal?.color
              : da?.index == 2
              ? value?.itemStyle?.normal?.color
              : da?.index == 0
              ? da?.type == "setup"
                ? "#EAAA08"
                : da.type == "losses"
                ? "#D92D20"
                : "#12B76A"
              : value?.itemStyle?.normal?.color,
        });
      });
    });

    const Valuess = datas?.sort((a, b) => a.value[1] - b.value[1]);

    const lastValues = _.uniqWith(Valuess, _.isEqual);

    let timelineDatas = Valuess?.filter((item) => {
      return item?.index != 2;
    });

    let shiftValues = lastValues?.filter((item) => {
      return item?.index == 2;
    });

    let junctionValues = lastValues?.filter((item) => {
      return item?.index == 0;
    });

    let junctionValues2 = lastValues?.filter((item) => {
      return item?.index == 0 && item.junctionType != "subEvent";
    });

    const [hours, minutes, seconds] = authStore?.user?.company?.dayStartHour?.split(":").map(Number);

    const newDayStartMoment = moment().hours(hours).minutes(minutes).seconds(seconds).subtract(3, "hours");
    const newDayStartHour = newDayStartMoment.format("HH:mm:ss");

    const noShiftStartDate = moment(date).format("YYYY-MM-DD") + " " + newDayStartHour;
    const noShiftEndDate = moment(date).add(1, "day").format("YYYY-MM-DD") + " " + authStore.user.company.dayStartHour;

    const startDate = shiftValues[0]?.startDate === undefined ? noShiftStartDate : shiftValues[0]?.startDate;
    const endDate = shiftValues[shiftValues.length - 1]?.endDate === undefined ? noShiftEndDate : shiftValues[shiftValues.length - 1]?.endDate;

    const endDateJunction = timelineDatas[timelineDatas.length - 1]?.endDate;

    const emptyTimes = [];
    const startUnixTime = moment.utc(startDate).unix(); // UTC formatında start date

    const endUnixTime = moment(startDate).format("YYYY-MM-DD") != moment().format("YYYY-MM-DD") ? moment.utc(endDateJunction).unix() : moment.utc().unix(); // UTC formatında end date

    let prevEndTime = startUnixTime;

    junctionValues2.forEach((value) => {
      const startTime = value.value[1];
      const endTime = value.value[2];

      if (startTime > prevEndTime) {
        const diff = startTime - prevEndTime;
        // 10 dakikadan fazla mı kontrolü (600 saniye = 10 dakika)
        if (diff >= 600) {
          emptyTimes.push([prevEndTime, startTime]);
        }
      }

      prevEndTime = endTime;
    });

    if (prevEndTime < endUnixTime) {
      const diff = endUnixTime - prevEndTime;
      // 10 dakikadan fazla mı kontrolü
      if (diff >= 600) {
        emptyTimes.push([prevEndTime, endUnixTime]);
      }
    }

    const sortedEmptyTimes = emptyTimes.sort((a, b) => a[0] - b[0]);
    const junctionData = sortedEmptyTimes.map(([start, end], index) => ({
      actionId: undefined,
      color: "#12B7",
      endDate: moment.utc(new Date(end * 1000).toISOString()).format("YYYY-MM-DD HH:mm:ss.SSS"),
      startDate: moment.utc(new Date(start * 1000).toISOString()).format("YYYY-MM-DD HH:mm:ss.SSS"),
      index: 0,
      junctionId: `${start}-${index}-${deviceId}`,
      junctionType: "empty",
      timeDiff: end - start,
      value: [0, start, end, end - start],
      nodeId: nodeId,
      deviceId: deviceId,
    }));

    const sorted = [...timelineDatas, ...junctionData];

    await setValues(sorted);
    //   await  setTimelineValues(timelineDatas)
  };

  useEffect(() => {
    calculateData(data);
  }, []);

  const filteredValues = values?.filter((shift) => shift?.type !== "shift");
  return filteredValues?.map((shift, index) => {
    const { startDate, endDate, color, timeDiff } = shift;
    const shiftStartTime = new Date(timeFormatter(startDate)?.formatted).getTime();
    const shiftEndTime = new Date(timeFormatter(endDate)?.formatted).getTime();

    const timeRange = endTime - startTime;

    if (selectedShift !== null && selectedShift !== index) {
      // Seçili event dışındaki diğer eventleri gösterme
      return null;
    }

    const shiftX = selectedShift === index ? 0 : ((shiftStartTime - startTime) / timeRange) * svgWidth;

    const statusWidth = ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;
    return (
      <>
        <MultipleEventRectMulti
          key={index}
          yOffset={yOffset}
          startPos={shiftX}
          width={statusWidth}
          widthS={statusWidth}
          event={shift}
          // onClick={() => {
          //   setSelectedJunction(shift);
          //   if (selectedJunction?.lossesId === undefined) {
          //    if (selectedJunction) {
          //     handleShiftClick(shift);
          //   }
          //    }
          // }}
          onClick={() => {
            setSelectedJunction(shift);
            if (selectedJunction?.lossesId === undefined) {
              handleShiftClick(shift);
            }
          }}
          handleMouseOver={handleMouseOver}
          handleMouseOut={handleMouseOut}
          handleMouseMove={handleMouseMove}
          findJunctionIndexByIds={findJunctionIndexByIds}
          nodeId={nodeId}
          selectedJunction={selectedJunction}
          setSelectedJunction={setSelectedJunction}
          date={date}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
        />
      </>
    );
  });
};

export default OperationNodeTimelines;
