import React, { memo, useState } from "react";
import { Loader } from "../../../assets/icons/stepsIcons";
import PollCard from "./PollCard";
import moment from "moment";
import { useStore } from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import NoData from "../../../steps/step-navigation/components/NoData";
import diacritics from "diacritics";
import ExpandedButton from "../../../components/buttons/ExpandedButton";

const PollList = ({ data, isLoading, refetch }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [pollItem, setPollItem] = useState({});
  const [pollResult, setPollResult] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [poll, setPoll] = useState(false);
  const { auth } = useStore();


  return (
    <>
      <div className="flex w-[375px] min-w-[375px] flex-col pt-4 overflow-y-auto scrollbar-hide border-l border-secondary-300 px-4">
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Loader currentColor="#0E9384" currentFill="currentFill" />
          </div>
        ) : (
          <>
            <ExpandedButton title={t("chat:pendingSurveys")} setSearchTerm={setSearchTerm} searchTerm={searchTerm} t={t}>
              <div className="flex flex-col mt-2">
                {data?.length < 1 ? (
                  <div className="flex w-full h-full items-center justify-center">
                    <NoData theme={"person"} header={t("chat:noPendingPoll")} button={false} />
                  </div>
                ) : (
                  data
                    ?.sort((a, b) => moment(b?.createdAt).diff(moment(a?.createdAt)))
                    ?.filter((poll) => diacritics.remove(poll?.title).toLowerCase().includes(diacritics.remove(searchTerm.toLowerCase())))
                    ?.map((poll) =>
                      poll?.isCompleted == false ? (
                        <PollCard
                          refetch={refetch}
                          id={poll?.id}
                          pollItem={pollItem}
                          setPollItem={setPollItem}
                          title={poll?.title}
                          poll={poll}
                          whoSee={poll?.isVoteAllCount + "/" + poll?.isVoteTrueCount}
                          createdAt={(poll.time = moment(poll?.createdAt).format("DD.MM.YYYY"))}
                          type={"pending-poll"}
                          data={data}
                          onClick={() => {
                            if (poll.creator == auth?.user?.id) {
                              setPollItem(poll);
                              setPollResult(poll?.id);
                            } else if (poll?.isVote == true) {
                              setIsAlert(true);
                            } else {
                              setPoll(poll?.id);
                              setPollItem(poll);
                            }
                          }}
                        />
                      ) : (
                        <></>
                      )
                    )
                )}
              </div>
            </ExpandedButton>

            <ExpandedButton title={t("chat:pastPoll")} t={t} setSearchTerm={setSearchTerm}>
              <div className="flex flex-col mt-2">
                {data?.length < 1 ? (
                  <div className="flex w-full h-full items-center justify-center">
                    <NoData theme={"person"} header={t("chat:noPendingPoll")} button={false} />
                  </div>
                ) : (
                  data
                    ?.sort((a, b) => moment(b?.createdAt).diff(moment(a?.createdAt)))
                    ?.filter((poll) => poll?.title?.toLowerCase().includes(searchTerm))
                    ?.map((poll, p) =>
                      poll?.isCompleted == true ? (
                        <PollCard
                          refetch={refetch}
                          setPollItem={setPollItem}
                          pollItem={pollItem}
                          poll={poll}
                          id={poll?.id}
                          title={poll?.title}
                          whoSee={poll?.isVoteAllCount + "/" + poll?.isVoteTrueCount}
                          createdAt={(poll.time = moment(poll?.createdAt).format("DD.MM.YYYY"))}
                          type={"past-poll"}
                          onClick={() => {
                            if (poll.isVote === true) {
                              setIsAlert(true);
                            } else {
                              setPollItem(poll);
                              setPollResult(poll?.id);
                            }
                          }}
                        />
                      ) : (
                        <></>
                      )
                    )
                )}
              </div>
            </ExpandedButton>
          </>
        )}
      </div>
      <AlertModal
        label={t("buttons:okey")}
        title={t("person:pollCompleted")}
        subTitle={t("chat:pollCompleting")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          setIsAlert(false);
        }}
      />
    </>
  );
};

export default memo(PollList);
