import React, { useCallback } from "react";
import {
  CloseIcon,
  CommentIcon,
  LabelIcon,
  PlusIcon,
  SendIcon,
  ThreeDots,
  UsersIcon,
} from "../../../assets/icons/taskCardIcons";
import NoAvatar from "../../../components/avatar/NoAvatar";
import generateFileUrl from "../../../utils/generateFileUrl";
import CommentLine from "./CommentLine";
import AttachmentLine from "./AttachmentLine";
import { useDropzone } from "react-dropzone";
import { instance } from "../../../libs/client";
import UserLine from "./UserLine";
import { useTranslation } from "react-i18next";
import { AttachmentIconTask } from "../../../assets/icons/PersonIcons";

const TaskBottomTabs = ({
  participants,
  isEdit,
  comments,
  attachments,
  changeComment,
  onChangeComment,
  sendComment,
  deleteComment,
  openPersonnelsModal,
  participant,
  handleUser,
  setAttachments,
  attachment,
  taskLabel,
  handleLabel,
  value,
  departmentLabels,
  setSelectedTab,
  selectedTab,
}) => {
  const { t } = useTranslation();
  const newAttachments = isEdit ? attachments : attachment;
  const newParticipants = isEdit ? participant : participants;

  const handleAttachment = (item, isDelete) => {
    if (isDelete) {
      const newAttachments = attachments.filter(
        (f) => f.attachmentId !== item.attachmentId
      );
      setAttachments(newAttachments);
    } else {
      setAttachments([...attachments, item]);
    }
  };

  const deleteFileToTask = (item) => {
    const filter = attachments.filter(
      (a) => a.attachmentId == item.attachmentId
    );
    if (filter.length > 0) {
      setAttachments((p) =>
        p.filter((a) => a.attachmentId != item.attachmentId)
      );
    } else {
      setAttachments((p) => [...p, item]);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      var formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      await instance({
        method: "post",
        url: "file/message/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          handleAttachment({
            name: acceptedFiles[0].path,
            attachmentId: res?.data?.file,
            type: acceptedFiles[0].type,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [attachments, setAttachments]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const tabItems = [
    {
      component: (
        <div className="relative flex items-center gap-x-2">
          {comments?.length > 0 && selectedTab == null && (
            <div className="w-3 h-3 rounded-full bg-teal-600 flex items-center justify-center absolute -top-[2px] -right-[18px]">
              <p className="text-[8px] text-white font-medium">
                {comments?.length < 10 ? comments?.length : "?"}
              </p>
            </div>
          )}
          <CommentIcon />
          {value == undefined ? (
            <span className="text-sm font-semibold">{t("tasks:activity")}</span>
          ) : (
            <></>
          )}
        </div>
      ),
      value: "comment",
      collapsedComponent: (
        <div className="flex flex-col">
          {comments?.map((item, index) => {
            return (
              <CommentLine
                key={index}
                index={index}
                avatar={item?.avatar}
                name={item?.name}
                lastName={item?.lastName}
                title={item?.description}
                system={item?.type === "system" ? true : false}
                editMode={isEdit}
                onClick={() => {
                  deleteComment(item?._id);
                }}
              />
            );
          })}

          <div
            className={`${
              isEdit ? "hidden" : "flex"
            } items-center relative w-full h-[32px] mt-3 mb-2`}
          >
            <input
              value={changeComment || ""}
              onChange={({ target }) => {
                onChangeComment(target.value);
              }}
              placeholder={t("person:addComment")}
              type="text"
              className="w-full h-[40px] pr-[40px] border border-secondary-300 bg-white shadow-sm focus:ring-0 focus:outline-0 text-sm text-gray-700 pl-3 rounded-md dark:bg-[#292929] dark:border-[#525252] dark:text-[#D6D6D6] "
            />
            <button
              disabled={changeComment === null ? true : false}
              type="button"
              onClick={() => {
                sendComment();
                onChangeComment(null);
              }}
              className="w-[30px] h-[30px] flex items-center justify-center absolute right-1 hover:bg-gray-50 bg-white disabled:hover:bg-white rounded"
            >
              <SendIcon />
            </button>
          </div>
        </div>
      ),
    },
    {
      component: (
        <div className="relative flex items-center justify-center gap-x-2">
          <LabelIcon />
          {value == undefined ? (
            <span className="text-sm font-semibold">{t("tasks:labels")}</span>
          ) : (
            <></>
          )}
        </div>
      ),
      value: "label",
      collapsedComponent: (
        <div className="w-full flex flex-wrap content-start  gap-2 mt-2">
          {departmentLabels?.map((item, index) => {
            return (
              <span
                onClick={() => handleLabel(item)}
                key={index}
                style={{
                  height: 24,
                  borderRadius: 6,
                  backgroundColor: taskLabel
                    ?.filter((f) => f.name === item?.name)
                    ?.find((v) => v)?.name
                    ? item?.color
                    : "#fff",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingLeft: 6,
                  paddingRight: 4,
                  borderWidth: 1,
                  borderColor: taskLabel
                    ?.filter((f) => f.name === item.name)
                    ?.find((v) => v)?.name
                    ? "transparent"
                    : item.color,
                  color: taskLabel
                    ?.filter((f) => f.name === item.name)
                    ?.find((v) => v)?.name
                    ? "#fff"
                    : item?.color,
                }}
                className=" flex  items-center text-xs font-medium cursor-pointer select-none gap-1 max-h-[24px]"
              >
                {item?.name}
                {taskLabel?.filter((f) => f.name === item.name)?.find((v) => v)
                  ?.name ? (
                  <CloseIcon size={16} color={"#fff"} />
                ) : (
                  <PlusIcon size={14} color={item?.color} />
                )}
              </span>
            );
          })}
        </div>
      ),
    },
    {
      component: (
        <div className="relative flex items-center justify-center gap-x-2">
          {newAttachments?.length > 0 && selectedTab == null && (
            <div className="w-3 h-3 rounded-full bg-teal-600 flex items-center justify-center absolute -top-[4px] -right-[18px]">
              <p className="text-[8px] text-white font-medium">
                {newAttachments?.length < 10 ? newAttachments?.length : "?"}
              </p>
            </div>
          )}

          <AttachmentIconTask />
          {value == undefined ? (
            <span className="text-sm font-semibold">
              {t("tasks:attachments")}
            </span>
          ) : (
            <></>
          )}
        </div>
      ),
      value: "attachment",
      collapsedComponent: (
        <div className="flex flex-col w-full">
          {newAttachments?.map((item, index) => {
            return (
              <AttachmentLine
                key={index}
                t={t}
                index={index}
                name={item?.name}
                attachmentId={item?.attachmentId}
                editMode={isEdit}
                onClick={() => {
                  deleteFileToTask(item);
                }}
              />
            );
          })}
          {isEdit && (
            <div
              {...getRootProps()}
              className={`w-full flex items-center mb-0.5 mt-1 px-3 min-h-[28px] py-[10px] border border-dashed h-10 rounded-md cursor-pointer `}
            >
              <span className="text-sm text-[#667085] font-normal">
                {t("personals:addAttachments")}
              </span>
              <div className="min-w-[20px] h-5 flex items-center justify-center ml-auto">
                <PlusIcon color={"#98A2B3"} />
              </div>
              <input type="file" {...getInputProps()} />
            </div>
          )}
        </div>
      ),
    },
    {
      component: (
        <div className="flex items-center justify-center w-full h-full select-none">
          {newParticipants?.length > 0 ? (
            newParticipants?.length < 4 ? (
              newParticipants?.map((item, index) => {
                return item?.avatar ? (
                  <img
                    src={generateFileUrl(item?.avatar)}
                    className="w-8 h-8 rounded-full"
                    style={{ marginLeft: index !== 0 ? -8 : 0 }}
                  />
                ) : (
                  <div style={{ marginLeft: index !== 0 ? -8 : 0 }}>
                    <NoAvatar
                      name={item?.name}
                      lastName={item?.lastName}
                      size={32}
                      fontSize={10}
                    />
                  </div>
                );
              })
            ) : (
              <>
                <div className="w-6 h-6 rounded-full flex items-center justify-center border-[0.5px] border-secondary-300 bg-white">
                  <ThreeDots />
                </div>
                {newParticipants?.map((item, index) => {
                  if (index < 3) {
                    return item?.avatar ? (
                      <img
                        src={generateFileUrl(item?.avatar)}
                        style={{ marginLeft: -8 }}
                        className="w-6 h-6 rounded-full"
                      />
                    ) : (
                      <div style={{ marginLeft: -8 }}>
                        <NoAvatar
                          name={item?.name}
                          lastName={item?.lastName}
                          size={24}
                          fontSize={10}
                        />
                      </div>
                    );
                  }
                })}
              </>
            )
          ) : (
            <UsersIcon />
          )}
        </div>
      ),
      value: "user",
      collapsedComponent: (
        <div className="flex flex-col w-full">
          {newParticipants?.map((item, index) => {
            return (
              <UserLine
                userLength={newParticipants?.length}
                key={index}
                name={item?.name}
                index={index}
                lastName={item?.lastName}
                avatar={item?.avatar}
                editMode={isEdit}
                onClick={() => {
                  handleUser(item);
                }}
              />
            );
          })}
          {isEdit && (
            <button
              onClick={openPersonnelsModal}
              className="w-full flex items-center mb-0.5 mt-1 px-3 min-h-[28px] py-[10px] border border-dashed h-10 rounded-md cursor-pointer"
            >
              <p className="text-sm text-[#667085] font-normal">
                {t("person:addUser")}
              </p>
              <div className="min-w-[20px] h-5 flex items-center justify-center ml-auto">
                <PlusIcon color={"#98A2B3"} />
              </div>
            </button>
          )}
        </div>
      ),
    },
  ];
  const selectedComponent = tabItems.find((item) => item.value === selectedTab);

  return (
    <div className="flex flex-col w-full">
      <div className="w-full items-center flex justify-between border-b border-secondary-200 ">
        {tabItems.map((item, index) => {
          return (
            <div
              key={index}
              style={{ display: !isEdit && index === 1 ? "none" : "flex" }}
              onClick={() => {
                if (item.value === selectedTab) {
                  setSelectedTab(null);
                } else {
                  setSelectedTab(item.value);
                }
              }}
              className={`${isEdit ? "w-1/4" : "w-1/3"} ${
                selectedTab === item.value
                  ? "border-b-[1.5px] border-[#0E9384] text-[#107569]"
                  : "text-[#667085]"
              } items-center pb-[10px] justify-center h-9 cursor-pointer hover:bg-gray-50 active:bg-gray-100`}
            >
              {item.component}
            </div>
          );
        })}
      </div>
      <div className="flex w-full flex-col mt-1">
        {selectedComponent && selectedComponent.collapsedComponent}
      </div>
    </div>
  );
};

export default TaskBottomTabs;
