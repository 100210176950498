import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Badgets from "../../../buttons/Badgets";
import {
  AcceptedIcon,
  ClockIcon,
  DeleteIcon,
  DotsVerticalIcon,
  EquCalculate,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  FlexProductIcon,
  GreenAvgIcon,
  PersonnelDetailIcon,
  PieceIcon,
  QualityDetailIcon,
  RedAvgIcon,
  RejectedIcon,
  TargetIcon,
} from "../../card-icons";
import CustomImage from "../../../CustomImage";
import { NoEquIcon, ProductDetailIcon } from "../../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../hooks/useStores";
import moment from "moment";
import { getFormattedNavigateDate } from "../../../../utils/getFomattedNavigateDate";
import { junctionService } from "../../../../services/junction.service";
import QualityControlForm from "../../../modal/QualityControlForm";
import CustomModal from "../../../modal/CustomModal";
import JunctionUpdate from "../../../modal/JunctionUpdate";
import AlertModal from "../../../alert-Modal/Alert-Modal";
import { Toast } from "../../../../utils/toastify/toast";

const PersonnelDashboardDoneCard = ({ data, refetch, setQualityControlFormFix }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isJunction, setIsJunction] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const { auth } = useStore();

  const menuOptions = [
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnelDetail",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:productDetail"),
      value: "productDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("product:editJunction"),
      value: "editJunction",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:qualityControlForm"),
      value: "qualityControlForm",
      icon: <QualityDetailIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const oneOrderTime = (start, end, count, type) => {
    const diff = moment(end).diff(moment(start), "seconds");
    const oneOrder = diff / count;
    return type === "setup" ? diff : `${oneOrder?.toFixed(0)}`;
  };

  const convertSeconds = (seconds) => {
    if (isNaN(seconds) || seconds == Infinity) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };

  const totalEstimatedTime = data?.productType === "flex" ? data?.flexHeader?.estimatedTime : data?.header?.internalOperation?.estimatedTime;
  const setupEstimatedTime =
    data?.productType === "flex" ? data?.flexHeader?.estimatedSettingsTime * 60 : data?.header?.internalOperation?.estimatedSettingsTime * 60;
  const formattedEstimatedOpTime =
    data?.type === "setup"
      ? convertSeconds(
        data?.productType === "flex" ? data?.flexHeader?.estimatedSettingsTime * 60 : data?.header?.internalOperation?.estimatedSettingsTime * 60
      )
      : convertSeconds(totalEstimatedTime);

  const startDate = data?.startDate;
  const endDate = data?.endDate;
  const madeWorkCount = data?.metadata?.madeWorkCount;
  const type = data?.type;
  const oneOrderTimeInSeconds = oneOrderTime(startDate, endDate, madeWorkCount, type);
  const formattedOneOrderTime = convertSeconds(oneOrderTimeInSeconds);
  const isRedOld = data?.type == "setup" ? setupEstimatedTime < oneOrderTimeInSeconds : totalEstimatedTime < oneOrderTimeInSeconds;


  const estimatedTimeGroup = data?.header ? data?.header?.internalOperation?.plannableNodes?.find((element) => { return element?.id == data?.station?.id }) :
    data?.flexHeader?.plannableNodes ? data?.flexHeader?.plannableNodes?.find((element) => { return element?.id == data?.station?.id }) : data?.flexHeader?.estimatedTime


  const estimatedTimeInSeconds = data?.type === "setup"
    ? estimatedTimeGroup?.estimatedSettingsTime
      ? estimatedTimeGroup?.estimatedSettingsTime
      : estimatedTimeGroup
    : estimatedTimeGroup?.estimatedTime
      ? estimatedTimeGroup?.estimatedTime
      : estimatedTimeGroup;

  // Perform the comparison in seconds
  const isRed = estimatedTimeInSeconds < oneOrderTimeInSeconds;

  const onDeleted = async () => {
    await junctionService.deleteJunction(data?.id, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data?.id);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  useEffect(() => {
    if (setQualityControlFormFix) {
      setQualityControlFormFix(isOpenForm);
    }
  }, [isOpenForm]);

  useEffect(() => {
    if (setQualityControlFormFix) {
      setQualityControlFormFix(isJunction);
    }
  }, [isJunction]);

  return (
    <>
      <div
        className={`flex w-full flex-col border border-t-4 ${data?.type === "work" ? "border-t-success-500" : data?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
          } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
      >
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-2">
            {data?.productType === "flex" ? (
              <>
                {data?.flexProduct?.image ? (
                  <div data-tooltip-id={`${data?.id}`} data-tooltip-place={"right"} data-tooltip-content={`${data?.flexProduct?.name} `}>
                    <CustomImage
                      borderRadius={2}
                      src={data?.flexProduct?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : (
              <>
                {data?.job?.product?.image ? (
                  <div data-tooltip-id={`${data?.id}`} data-tooltip-place={"right"} data-tooltip-content={`${data?.job?.product?.name} `}>
                    <CustomImage
                      borderRadius={2}
                      src={data?.job?.product?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            )}

            <div className="flex flex-col items-start gap-y-1">
              <div className="flex flex-row items-center gap-x-1 w-full">
                {data?.productType === "flex" ? (
                  <>
                    <FlexProductIcon />
                    <p className="text-sm font-semibold text-secondary-900 w-[165px] truncate">{data?.flexProduct?.name || ""}</p>
                  </>
                ) : (
                  <p className="text-sm font-semibold text-secondary-900 w-[180px] truncate">{data?.job?.product?.name || ""}</p>
                )}
              </div>

              <div className="flex flex-row items-center gap-x-3">
                <div className="flex flex-row items-center">
                  <ClockIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">
                    {moment(data?.startDate).format("HH:mm") || "--"} - {moment(data?.endDate).format("HH:mm") || "--"}
                  </p>
                  <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:hours")}</p>
                </div>
                <div className="flex flex-row items-center">
                  <PieceIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{data?.metadata?.madeWorkCount || "--"}</p>
                  <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:pcs")}</p>
                </div>
              </div>
            </div>
          </div>
          {data?.station?.metadata?.image ? (
            <div
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${data?.station?.metadata?.brand} ${data?.station?.metadata?.model}`}
              className="cursor-pointer"
            >
              <CustomImage
                src={data?.station?.metadata?.image}
                style={{
                  width: 64,
                  height: 48,
                  minWidth: 64,
                  minHeight: 48,
                  flexDirection: "column",
                  borderRadius: 4,
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
              <NoEquIcon />
            </div>
          )}
          <div className="flex flex-col z-[20] items-start rounded border-[0.5px] ml-auto absolute border-[#D0D5DD] w-[86px] min-w-[86px] bg-white top-[6px] right-[6px]">
            <div
              className={`flex flex-row items-center justify-center gap-x-1 h-5 min-h-5 px-[2px] py-[2px] ${data?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
                }  w-full rounded-t-lg border-b-[0.5px] border-secondary-300 ml-auto`}
            >
              {data?.metadata?.qualityStatus === "waiting" ? (
                <p className="text-xxs text text-secondary-600 font-semibold">{t("product:done")}</p>
              ) : (
                <>
                  <p className="text-xxs text text-secondary-600 font-semibold">{t("product:qualityNew")}</p>
                  <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded">
                    <p className="text-xxs font-semibold text-white">
                      {" "}
                      %{data?.productType === "flex" ? data?.flexHeader?.qualityPercent || "--" : data?.header?.internalOperation?.qualityPercent || "--"}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                {data?.metadata?.qualityStatus === "waiting" ? <PieceIcon /> : <AcceptedIcon />}
              </div>
              <p className={`text-xs font-medium ml-1 ${data?.metadata?.qualityStatus === "waiting" ? "text-secondary-600" : "text-[#079455]"} `}>
                {data?.metadata?.qualityStatus === "waiting" ? data?.madeWorkCount || "0" : data?.metadata?.acceptedWorkCount || "0"}
              </p>
              <p className="text-[10px] font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              {data?.metadata?.qualityStatus === "waiting" ? (
                <>
                  <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                    <EquCalculate />
                  </div>
                  <p className="text-xs font-medium ml-1 text-[#6941C6]">{data?.metadata?.calculateWorkCount || "0"}</p>
                  <p className="text-xxs font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
                </>
              ) : (
                <>
                  <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                    <RejectedIcon />
                  </div>
                  <p className="text-xs font-medium ml-1 text-error-600">{data?.metadata?.rejectedWorkCount || "0"}</p>
                  <p className="text-xxs font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={`flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full ${data?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
            }  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg`}
        >
          <div className="flex flex-row items-center">
            <TargetIcon />
            <p className="text-xs font-medium ml-1 text-secondary-600">
              {data?.header?.internalOperation?.plannableNodes?.length == 0 ? (
                <>
                  {formattedEstimatedOpTime || "--"}
                </>
              ) : (
                <>
                  {
                    data?.type === "setup"
                      ? estimatedTimeGroup?.estimatedSettingsTime
                        ? `${Math.floor(estimatedTimeGroup?.estimatedSettingsTime / 60 || 0)} dk ${(estimatedTimeGroup?.estimatedSettingsTime % 60 || 0).toFixed(0)} sn` || ""
                        : `${Math.floor(estimatedTimeGroup / 60 || 0)} dk ${(estimatedTimeGroup % 60 || 0).toFixed(0)} sn` || ""
                      : estimatedTimeGroup?.estimatedTime
                        ? `${Math.floor(estimatedTimeGroup?.estimatedTime / 60 || 0)} dk ${(estimatedTimeGroup?.estimatedTime % 60 || 0).toFixed(0)} sn` || ""
                        : `${Math.floor(estimatedTimeGroup / 60 || 0)} dk ${(estimatedTimeGroup % 60 || 0).toFixed(0)} sn` || ""
                  }
                </>
              )}

            </p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMinActualss")}</p>
          </div>
          <div className="flex flex-row items-center">
            {data?.header?.internalOperation?.plannableNodes?.length == 0 ? isRedOld ? <RedAvgIcon /> : <GreenAvgIcon /> : isRed ? <RedAvgIcon /> : <GreenAvgIcon />}
            <p className={`text-xs font-medium ml-1 ${data?.header?.internalOperation?.plannableNodes?.length == 0 ? isRedOld ? "text-[#D92D20]" : "text-[#079455]" : isRed ? "text-[#D92D20]" : "text-[#079455]"}`}>{formattedOneOrderTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:average")}</p>
          </div>
          {/* backendde yapilinca eklenecek 12.06.2024 */}
          {/* <div className="flex flex-row items-center">
            <MadePlayIcon />
            <p className="text-xs font-medium ml-1 text-[#CA8504]">{data?.madeTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:doneMin")}</p>
          </div> */}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {menuOptions?.map((item, index) => {
              const formattedDate = getFormattedNavigateDate(data?.startDate, auth.user.company.dayStartHour);
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "equipmentDetail") {
                      navigate(`/app/machine/digital-twin/equipment-details/${data?.station?.deviceId}-${data?.station?.id}/${formattedDate}`);
                    }
                    if (item.value === "editJunction") {
                      // setIsJunction(true);
                      navigate(
                        `/app/machine/digital-twin/equipment-details/${`${data?.station?.deviceId}-${data?.station?.id}`}/${moment(data?.startDate).format("YYYY-MM-DD")}/manuel-detail/edit/${moment(data?.startDate)?.subtract(1, "hour")?.format("YYYY-MM-DDTHH:mm:ss")}+${moment(data?.endDate)?.add(1, "hour")?.format("YYYY-MM-DDTHH:mm:ss")}/event/${data?.id}`
                      );
                    }
                    if (item.value === "qualityControlForm") {
                      setIsOpenForm(true);
                    }
                    if (item.value === "personnelDetail") {
                      navigate(`/app/machine/digital-twin/user-detail/${data?.user?.id}/${formattedDate}`);
                    }
                    if (item.value === "productDetail") {
                      const urlFlex = `/app/product/mes/flex/operation/detail/${data?.flexProduct?.id}+${data?.flexHeader?.step}`;
                      if (data?.productType === "flex") {
                        navigate(urlFlex);
                      } else {
                        navigate(`/app/product/mrp/work-in-process/detail/${data?.job?.id}+${data?.header?.step}`);
                      }
                    }
                    // if (item.value === "productDetail") {
                    //   navigate(`/app/product/mrp/work-in-process/detail/${data?.job?.id}+${data?.header?.step}`);
                    // }
                    if (item.value === "delete") {
                      setIsAlert(true);
                    }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                    }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
        {data?.header?.step || data?.flexHeader?.step === undefined ? null : (
          <span className="absolute left-1 top-1">
            <Badgets colorType={"fill-gray"} label={data?.productType === "flex" ? data?.flexHeader?.step + ".op" : data?.header?.step + ".op"} size={"sm"} />
          </span>
        )}
      </div>
      <Tooltip className="z-[920]" id={`${data?.id}`} />
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isOpenForm}
        setIsOpen={setIsOpenForm}
        modalTitle={t("product:qualityControlForm")}
        onClose={() => {
          setIsOpenForm(false);
        }}
        children={
          <QualityControlForm
            onClose={() => {
              setIsOpenForm(false);
            }}
            junctionId={data?.id}
            refetch={refetch}
            productType={data?.productType}
          />
        }
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={data?.id}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default PersonnelDashboardDoneCard;
