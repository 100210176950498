import React, { useState } from "react";
import _ from "lodash";
import { CheckIcon } from "../../../../assets/icons/machineIcons";
import generateFileUrl from "../../../../utils/generateFileUrl";
import UnitPriceInput from "../../../../components/inputs/UnitPriceInput";
import MintueSecondInput from "../../../../components/inputs/MintueSecondInput";
import { Input } from "../../../../components";
const InternalOperationSide = ({ stepOneData, setStepOneData, selectedStep, setStepTwoData, stepTwoData, setStepThreeData, stepThreeData, t }) => {
    const data = selectedStep === 1 ? stepOneData : selectedStep === 2 ? stepTwoData : stepThreeData;
    const setData = selectedStep === 1 ? setStepOneData : selectedStep === 2 ? setStepTwoData : setStepThreeData;
    const [firstInput, setFirstInput] = useState("");
    const [secondInput, setSecondInput] = useState("");
    const [thirdInput, setThirdInput] = useState("");
    const [fourthInput, setFourthInput] = useState("");
    const [isAllSame, setIsAllSame] = useState(data?.isAllSame);
    const tableHeadItems = [t("product:stations"), `${t("product:estimatedProductionTime")} (min)`, `${t("product:estimatedSetupTimes")} (min)`, t("product:minuteCost")];
    return (
        <>
            <div className="flex flex-col gap-y-1 justify-center">
                <p className="text-[#101828] font-semibold text-xl">{t("product:timeAndCost")}</p>
                <p className="text-[#475467] text-sm">{t("product:enterThePerUnitSetup")}</p>
            </div>
            <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide border-b border-[#E4E7EC]">
                <div className="flex max-h-[26px] min-h-[26px] w-full sticky top-0 bg-white z-[100]">
                    {tableHeadItems.map((item, i) => (
                        <div
                            key={i}
                            className={`flex border-b px-2 pb-2 border-r border-[#E4E7EC] items-center ${i === 0 ? "justify-start w-[248px] min-w-[248px]" : i === 1 || i === 2 ? "justify-center w-[206px]" : "justify-center w-[222px] min-w-[222px]"
                                } ${i === tableHeadItems.length - 1 ? "border-r-0" : ""}`}
                        >
                            <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                        </div>
                    ))}
                </div>
                <div className="flex w-full items-center border-b h-[80px] min-h-[80px] max-h-[80px]">
                    <div
                        onClick={() => {
                            if (isAllSame == false) {
                                setIsAllSame(true);
                                setData({
                                    ...data,
                                    isAllSame: true,
                                    operations: {
                                        ...data?.operations,
                                        stations: data?.operations?.stations?.map((item) => ({ ...item, estimatedTime: data.estimatedTime || firstInput, estimatedSettingsTime: data.estimatedSettingsTime || secondInput, costOfMinute: data.costOfMinute || thirdInput, currency: data.currency || fourthInput })),
                                    },
                                });
                            } else { setIsAllSame(false); setData({ ...data, isAllSame: false }) }
                        }}
                        className="flex items-center gap-x-3 py-4 pr-4 border-r h-full min-w-[248px] justify-start text-[#101828] font-semibold text-md cursor-pointer">
                        <div
                            className={`flex min-w-[20px] max-w-[20px] w-[20px] min-h-[20px] max-h-[20px] h-[20px] border rounded-md p-[3px] items-center justify-center ${isAllSame && "bg-[#DC6803] border-0"}`}>
                            <CheckIcon />
                        </div>
                        {t("product:allOperationStandart")}
                    </div>
                    <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[204px] min-w-[204px]">
                        <MintueSecondInput
                            value={data?.estimatedTime}
                            theme={"product"}
                            onChange={(value) => {
                                setFirstInput(value);
                                if (isAllSame) {
                                    setData({
                                        ...data,
                                        estimatedTime: value,
                                        operations: {
                                            ...data?.operations,
                                            stations: data?.operations?.stations?.map((item) => ({ ...item, estimatedTime: value })),
                                        },
                                    });
                                } else {
                                    setData({
                                        ...data,
                                        estimatedTime: value,
                                    });
                                }
                            }}
                        />
                        {/* <Input
                                value={data?.estimatedTime}
                                theme={"product"}
                                type={"number"}
                                onChange={(e) => {
                                    setFirstInput(e.target.value);
                                    if (isAllSame) {
                                        setData({
                                            ...data,
                                            estimatedTime: e.target.value,
                                            operations: {
                                                ...data?.operations,
                                                stations: data?.operations?.stations?.map((item) => ({ ...item, estimatedTime: e.target.value })),
                                            },
                                        });
                                    } else {
                                        setData({
                                            ...data,
                                            estimatedTime: e.target.value,
                                        });
                                    }
                                }}
                            /> */}
                    </div>
                    <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[176px] min-w-[176px]  ">
                        <MintueSecondInput
                            value={data?.estimatedSettingsTime}
                            theme={"product"}
                            onChange={(value) => {
                                setFirstInput(value);
                                if (isAllSame) {
                                    setData({
                                        ...data,
                                        estimatedSettingsTime: value,
                                        operations: {
                                            ...data?.operations,
                                            stations: data?.operations?.stations?.map((item) => ({ ...item, estimatedSettingsTime: value })),
                                        },
                                    });
                                } else {
                                    setData({
                                        ...data,
                                        estimatedSettingsTime: value,
                                    });
                                }
                            }}
                        />
                        {/* <Input
                                value={data?.estimatedSettingsTime}
                                theme={"product"}
                                type={"number"}
                                onChange={(e) => {
                                    setFirstInput(e.target.value);
                                    if (isAllSame) {
                                        setData({
                                            ...data,
                                            estimatedSettingsTime: e.target.value,
                                            operations: {
                                                ...data?.operations,
                                                stations: data?.operations?.stations?.map((item) => ({ ...item, estimatedSettingsTime: e.target.value })),
                                            },
                                        });
                                    } else {
                                        setData({
                                            ...data,
                                            estimatedSettingsTime: e.target.value,
                                        });
                                    }
                                }}
                            /> */}
                    </div>
                    <div className="flex h-full px-4 py-5 justify-center w-[222px] gap-x-6 border-r  ">
                        <UnitPriceInput
                            setValue={(field, value) => {
                                field == "currency" ? setFourthInput(value) : setThirdInput(value);
                                if (isAllSame) {
                                    setData({
                                        ...data,
                                        [field]: value,
                                        operations: {
                                            ...data?.operations,
                                            stations: data?.operations?.stations?.map((item) => ({ ...item, [field]: value, })),
                                        },
                                    });
                                } else {
                                    setData({
                                        ...data,
                                        [field]: value,
                                    });
                                }
                            }}
                            items={currencyItems}
                            price={data?.costOfMinute}
                            theme={"product"}
                            currency={data?.currency || "USD"}
                            value={data?.costOfMinute || ""}
                            size="sm"
                            receipe={true}
                        />
                    </div>
                </div>
                {data?.operations?.stations?.map((station, i) => (
                    <div key={station.id} className="flex w-full items-center border-b h-[80px] min-h-[80px] max-h-[80px]">
                        {/* Kolon 1: İstasyon Bilgisi */}
                        <div className="flex items-center gap-x-3 py-4 pr-4 border-r h-full min-w-[248px] max-w-[248px] justify-start cursor-pointer">
                            <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(station?.metadata?.image)} alt={station?.name} />
                            <div className="flex flex-col gap-y-1 justify-center">
                                <p className="text-[#101828] font-semibold text-md truncate w-[136px]">{station?.metadata?.brand + station?.metadata?.model || "--"}</p>
                                <p className="text-[#475467] text-sm">{station?.metadata?.process?.name || "--"}</p>
                            </div>
                        </div>
                        {/* Kolon 2: Üretim Süresi */}
                        <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[204px] min-w-[204px]">
                            <MintueSecondInput
                                disabled={isAllSame}
                                value={station?.estimatedTime || data?.estimatedTime || ""}
                                theme={"product"}
                                onChange={(value) => {
                                    setData({
                                        ...data,
                                        operations: {
                                            ...data?.operations,
                                            stations: data?.operations?.stations?.map((item) => (item.id === station.id ? { ...item, estimatedTime: value } : item)),
                                        },
                                    });
                                }}
                            />
                            {/* <Input
                                value={station?.estimatedTime || data?.estimatedTime || ""}
                                theme={"product"}
                                type={"number"}
                                disabled={isAllSame}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        operations: {
                                            ...data?.operations,
                                            stations: data?.operations?.stations?.map((item) => (item.id === station.id ? { ...item, estimatedTime: e.target.value } : item)),
                                        },
                                    });
                                }}
                            /> */}
                        </div>
                        {/* Kolon 3: Kurulum Süresi */}
                        <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[176px] min-w-[176px]">
                            <MintueSecondInput
                                disabled={isAllSame}
                                value={station?.estimatedSettingsTime || data?.estimatedSettingsTime || ""}
                                theme={"product"}
                                onChange={(value) => {
                                    setData({
                                        ...data,
                                        operations: {
                                            ...data?.operations,
                                            stations: data?.operations?.stations?.map((item) => (item.id === station.id ? { ...item, estimatedSettingsTime: value } : item)),
                                        },
                                    });
                                }}
                            />
                            {/* <Input
                                value={station?.estimatedSettingsTime || data?.estimatedSettingsTime || ""}
                                theme={"product"}
                                type={"number"}
                                disabled={isAllSame}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        operations: {
                                            ...data?.operations,
                                            stations: data?.operations?.stations?.map((item) => (item.id === station.id ? { ...item, estimatedSettingsTime: e.target.value } : item)),
                                        },
                                    });
                                }}
                            /> */}
                        </div>
                        {/* Kolon 4 ve 5: Currency ve Price */}
                        <div className="flex h-full px-4 py-5 justify-center w-[222px] min-w-[222px] gap-x-6 border-r">
                            <UnitPriceInput
                                disabled={isAllSame}
                                price={station?.costOfMinute || station?.price || data?.costOfMinute || thirdInput || ""}
                                currency={station?.currency || data?.currency || "USD"}
                                theme={"product"}
                                items={currencyItems}
                                size="sm"
                                receipe={true}
                                setValue={(field, value) => {
                                    setData({
                                        ...data,
                                        operations: {
                                            ...data?.operations,
                                            stations: data?.operations?.stations?.map((item) => (item.id === station.id ? { ...item, [field]: value } : item)),
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
export default InternalOperationSide;

export const currencyItems = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
];