import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AcceptedIcon,
  DotsVerticalIcon,
  EndClockIcon,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  LossIconDropDown,
  PersonnelDetailIcon,
  QualityDetailIcon,
  RejectedIcon,
  StartClockIcon,
} from "../card-icons";
import { EquipmentDeleteIcon, NoEquIcon, ProductDetailIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../CustomImage";
import NoAvatar from "../../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { LossIcon } from "../../../assets/icons/leftMenuIcons";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import { useStore } from "../../../hooks/useStores";
import CustomModal from "../../modal/CustomModal";
import SendErrorMessageModal from "../../../pages/machine/components/SendErrorMessageModal";

const OperationDetailSelectedLossEventCard = ({ data, items, refetch }) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const navigate = useNavigate();
  const [isJunction, setIsJunction] = useState(false);
  const [editJunction, setEditJunction] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [selectedLossId,setSelectedLossId] = useState("")
  const [selectedLoss, setSelectedLoss] = useState();
  const [selectedLossData, setselectedLossData] = useState();
  const { auth } = useStore();
  const menuOptions = [
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetails",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:defineLoss"),
      value: "defineLoss",
      icon: <LossIconDropDown />,
    },
  ];

const filteredLosses = items?.losses?.find(loss => loss?.id === data?.lossesId)

  return (
    <>
      <div className="flex w-full flex-row justify-center items-center group relative">
        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 mr-auto">
          <div className={`w-full border-t-4 rounded-t-[8px] border-t-[#D92D20] `}></div>

          <div className="flex flex-row items-center w-full border-b">
            {items?.nodeImage ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-content={`
              ${items?.nodeName} 
              `}
              >
                <CustomImage
                  src={items?.nodeImage}
                  style={{
                    width: 56,
                    height: 42,
                    minWidth: 56,
                    minHeight: 42,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRadius: 0,
                    borderLeftWidth: 1,
                    borderRightWidth: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[56px] min-w-[56px] h-[42px]  border border-l-0 border-b-0 border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start px-2">
              <div className="flex flex-row items-center gap-x-1 mr-auto">
                <StartClockIcon />
                <p className="text-xs font-normal text-secondary-600">{moment(data?.startDate).add(3, "hour")?.format("HH:mm")}</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start w-full h-11 min-h-11 pl-3 py-1 pr-[44px]">
            <p className="text-xs font-semibold text-secondary-700 truncate  w-[99%]">
              {/* {data?.job?.product?.name || "--"} */}
              {items?.nodeName || "--"}
            </p>
          </div>
        </div>

        <div className="cursor-pointer flex absolute  w-[50px] h-[50px] mb-6 min-w-[50px] min-h-[50px] z-[100]">
          {data?.avatar ? (
            <CustomImage
              src={data?.avatar}
              style={{
                width: 50,
                height: 50,
                borderRadius: 100,
                borderColor: "#0000001A",
                backgroundColor: "#fff",
                borderWidth: 2,
                borderStyle: "solid",
                boxShadow: "0 0 0 2px #00000008",
              }}
            />
          ) : (
            <NoAvatar size={48} fontSize={22} name={data?.name} color={"#B54708"} />
          )}
        </div>

        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 ml-auto">
          <div className={`w-full border-t-4 rounded-t-[8px] border-t-[#D92D20]`}></div>
          <div className="flex flex-row items-center w-full border-b justify-end">
            <div className="flex flex-col items-start px-2">
              <div className="flex flex-row items-center gap-x-1 mr-auto">
                <p className="text-xs font-normal text-secondary-600">{moment(data?.endDate).add(3, "hour")?.format("HH:mm")}</p>
                <EndClockIcon />
              </div>
            </div>
            <div className="w-[56px] min-w-[56px] h-[42px]  bg-[#FEF3F2] border border-r-0 border-b-0 border-secondary-300 flex items-center justify-center font-medium text-[#D92D20] text-xl cursor-pointer">
              <LossIcon />
            </div>
          </div>

          <div className="flex flex-col items-end w-full pr-3 py-1 pl-[44px]">
            <p className="text-xs font-normal text-secondary-500 truncate text-end ml-auto w-[99%]">{data?.lossesName || "Undefined Loss"}</p>
            <p className="text-xs font-semibold text-end text-secondary-700 truncate ml-auto w-[99%]">{data?.reasonDescription|| "Unknown"}</p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[6px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[32px] right-[4px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {menuOptions?.map((item, index) => {
              const formattedDate = getFormattedNavigateDate(filteredLosses?.startDate, auth.user.company.dayStartHour);
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();

                    if (item?.value === "equipmentDetails") {
                      navigate(`/app/machine/digital-twin/equipment-details/${items?.deviceId}-${items?.nodeId}/${formattedDate}`);
                    }
                    if (item?.value === "defineLoss") {
                      setIsWarningModal(true);
                      setSelectedLoss(filteredLosses?.id);
                      setselectedLossData(filteredLosses);
                    }
                    setShowDropDown(false);
                    e.stopPropagation();
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    item?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                  }`}
                >
                  {item?.icon}
                  <p className={`font-medium text-sm  ${item?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{item.label}</p>
                </button>
              );
            })}
          </div>
        )}
        <Tooltip className="z-[120]" id={`${data?.id}`} /> 
      </div>
      <CustomModal
        width={400}
        isOpen={isWarningModal}
        setIsOpen={setIsWarningModal}
        children={
          <SendErrorMessageModal setIsOpen={setIsWarningModal} t={t} selectedLossId={selectedLoss} refetch={refetch} selectedLossData={selectedLossData} />
        }
      />
    </>
  );
};

export default OperationDetailSelectedLossEventCard;
