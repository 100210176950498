import React, { memo } from "react";
import Modal from "react-modal";
import { settingsStore } from "../../stores/settings.store";

const CustomJunctionInfoModal = ({
  children,
  isOpen,
  setIsOpen,
  width,
  onClose,
  height,
  isPreventShutdown = false,
  modalPosition,
}) => {
  function closeModal() {
    if (!isPreventShutdown) {
      setIsOpen(false);
    }
  }

  const customStyles = {
    content: {
      position: "absolute",
      top: `${modalPosition?.top}px`,
      left: `${modalPosition?.left}px`,
      padding: 24,
      zIndex: 99999,
      borderRadius: "12px",
      width: width || "fit-content",
      height: height || "auto",
      backgroundColor: settingsStore.theme === "light" ? "#FFF" : "#0F0F0F",
      borderColor: settingsStore.theme === "light" ? "#FFF" : "#424242",
      overflow: "hidden",
    },
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose ? onClose : closeModal}
        style={customStyles}
        className="border ml-8 border-secondary-200 bg-white p-3"
        overlayClassName=" inset-0 flex items-center justify-center z-[999]"
      >
        <div className="w-full h-full relative flex flex-col ">
          <div className="w-full h-full"> {children}</div>
        </div>
      </Modal>
    </>
  );
};

export default memo(CustomJunctionInfoModal);
