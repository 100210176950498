import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Map } from "immutable";
import { Provider } from "react-redux";
import MyCatalog from "../catalog/mycatalog";
import { useStore } from "../../../../hooks/useStores";
import { useQuery } from "@tanstack/react-query";
import { Models as PlannerModels, reducer as PlannerReducer, ReactPlanner, Plugins as PlannerPlugins, fitToViewer } from "react-planner";
import { mapService } from "../../../../services";
import { observer } from "mobx-react-lite";
import { configureStore } from "@reduxjs/toolkit";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { useNavigate } from "react-router";
import MapInfoButton from "./MapInfoButton";
import { MapEditIcon, MapIcon, MapInfoIcon, MapSquare } from "../../../../assets/icons/machineIcons.js";
import StatusInfoModal from "../modal/StatusInfoModal.js";
import AreaModal from "../modal/AreaModal.js";
import { statusColors } from "../../../../utils/statusColors.js";
import { Loader } from "../../../../assets/icons/stepsIcons.js";
import screenfull from "screenfull";
import moment from "moment";
import { useTranslation } from "react-i18next";


let AppState = Map({
  "react-planner": new PlannerModels.State(),
});

let reducer = (state, action) => {
  state = state || AppState;
  state = state.update("react-planner", (plannerState) => PlannerReducer(plannerState, action));
  return state;
};

let store = configureStore({
  reducer: reducer,
  middleware: [],
});

let plugins = [PlannerPlugins.Keyboard(), PlannerPlugins.Autosave("react-planner_v0"), PlannerPlugins.ConsoleDebugger()];

const Planner = observer(({ width = window.innerWidth, height = window.innerHeight, selectedProcess, setSelectedProcess }) => {
  const navigate = useNavigate();
  const [maps, setMaps] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedMap, setSelectedMap] = useState(Number(localStorage?.getItem("selectedMap")) || 1);
  const [view, setView] = useState(false);
  const [areaView, setAreaView] = useState(false);
  const { auth, map } = useStore();
  const [selectedButton, setSelectedButton] = useState(false);
  const { isLoading, data, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["handleMap"],
    queryFn: async () => await mapService.loaderMap(),
  });
  const companyID = auth?.user?.company?.id;
  const ref = useRef(null);
  const channel = useChannel(companyID);
  const {t} = useTranslation();
  const areaChannel = useChannel(`area-update-${companyID}`);
  useEvent(channel, "update-node", (socketEvent) => {
    let newMapItems = {};
    if (!isItemSelected()) {
      Object.entries(ref?.current?.store?.getState("scene")?.toJS()["react-planner"]?.scene?.layers["layer-1"]?.items)?.forEach((item) => {
        if (item[1].properties?.id == socketEvent?.node.id) {
          item[1].properties.ring = statusColors(socketEvent?.status);
          ref.current.selector.props.itemsActions.selectItem("layer-1", item[0]);
          ref.current.selector.props.projectActions.setProperties({
            ...item[1].properties,
          });
          ref.current.selector.props.projectActions.unselectAll();
        }
        newMapItems[item[0]] = item[1];
        const newMap = ref?.current?.store?.getState("scene")?.toJS()["react-planner"]?.scene;
        newMap.layers["layer-1"].items = newMapItems;
        ref.current.selector.props.projectActions.loadProject(newMap);
      });
    }
  });

  useEvent(areaChannel, "update-node-area", (socketEvent) => {
    let newMapItems = {};
    let newMapItemsSave = {};
    if (!isItemSelected()) {
      Object.entries(ref?.current?.store?.getState("scene")?.toJS()["react-planner"]?.scene?.layers["layer-1"]?.items)?.forEach((item) => {
        if (item[1].properties?.deviceId == socketEvent?.nodeId) {

          item[1].y = socketEvent?.connectionArea == 1 ? 240 : 1500;
           
          item[1].properties["isSelected"] = false;
          ref.current.selector.props.itemsActions.selectItem("layer-1", item[0]);
          
          ref.current.selector.props.projectActions.setProperties({
            ...item[1].properties,
          });
          ref.current.selector.props.projectActions.unselectAll();
        }
        newMapItems[item[0]] = item[1];
        const newMap = ref?.current?.store?.getState("scene")?.toJS()["react-planner"]?.scene;
        newMap.layers["layer-1"].items = newMapItems;
        ref.current.selector.props.projectActions.loadProject(newMap);
      });
     
      const mappers = maps.map((val, index) => {
        const items = val?.mapSettings?.layers['layer-1'].items;

        if (items) {
          let newMapItemsSave = {}; 
          for (const [key, item] of Object.entries(items)) {
        if (item.properties?.deviceId == socketEvent?.nodeId) {
          item.y = socketEvent?.connectionArea == 1 ? 240 : 1500;

          ref.current.selector.props.itemsActions.selectItem("layer-1", key);
          ref.current.selector.props.projectActions.setProperties({
            ...item.properties,
          });

          ref.current.selector.props.projectActions.unselectAll();
        }

        newMapItemsSave[key] = item;
          }
          val.mapSettings.layers["layer-1"].items = newMapItemsSave;
          return val;
        }
      });

      let roles = [];
      const filteredMaps = mappers?.filter((area) => {
        const items = area?.mapSettings?.layers?.['layer-1']?.items;
        return items && Object.keys(items)?.length > 0;
      })
      if (mappers.length <= 0) {
        roles = [
          {
            name: "Alan 1",
            location: ref?.current?.selector.props.state.toJS()["react-planner"].viewer2D,
            mapSettings: ref?.current?.store.getState("scene").toJS()["react-planner"].scene,
          },
        ];
      } else {
        roles = filteredMaps.map((d) => {
          return {
            ...d,
            location: ref?.current?.selector.props.state.toJS()["react-planner"].viewer2D,
          };
        });
      }
     
       mapService.saveMaps({ roles }).then((response) => {
        if (response) {
         
         
          if (filteredMaps?.length === mappers?.length ) {
            // Toast("success", t("embedded:mapSaved"));
          } else {
            // Toast("success", t("embedded:mapSevedEmptyArea"));
            
          }
        }
      });
    }
  });

  const setCurrentMap = (mapSettings, index) => {
    ref?.current?.selector?.props?.projectActions?.loadProject(mapSettings);
    setSelectedMap(index);
    localStorage.setItem("selectedMap", index);
    localStorage.setItem("mapSettings", JSON.stringify(mapSettings));
    navigate(`/app/machine/digital-twin/area/${index}`);
  };

  const isItemSelected = () => {
    const selecteds = Object.values(ref.current.store.getState("scene").toJS()["react-planner"]?.scene?.layers["layer-1"]?.selected)
      .map((a, b) => {
        if (a.length > 0) {
          return true;
        }
      })
      .find((a) => a == true);
    if (selecteds) {
      return true;
    } else {
      return false;
    }
  };

  const handleMaps = useCallback(async () => {
    let location = "";
    ref?.current?.selector?.props.projectActions.newProject();
    if (!data || !data?.settings || data?.settings?.length <= 0 || data?.settings?.[0] === null) {
      setMaps([
        {
          name: "Alan 1",
          mapSettings: ref?.current?.store?.getState("scene").toJS()["react-planner"].scene,
        },
      ]);
    } else {
      setMaps(data?.settings);
      location = data?.settings[0]?.location || "";
      ref?.current?.selector?.props?.projectActions.loadProject(data?.settings[Number(localStorage?.getItem("selectedMap")) - 1]?.mapSettings);
    }

    setRefresh(!refresh);
    if (location) {
      ref?.current?.selector?.props?.viewer2DActions?.updateCameraView(location);
    } else {
      const newValues = fitToViewer({
        SVGHeight: data?.settings?.mapSettings?.height || 2000,
        SVGWidth: data?.settings?.mapSettings?.width || 3000,
        viewerHeight: height - 300,
        viewerWidth: width / 2,
      });
      ref?.current?.selector?.props?.viewer2DActions?.updateCameraView({
        ...newValues,
        mode: "MODE_IDLE",
        e: 0,
        f: 0,
      });
    }
    data?.type == "default" ? map.setDefault(true) : map.setDefault(false);
  }, [height, width, data]);

  const getCurrentClickedItem = (id) => {
    Object.entries(ref?.current?.store?.getState("scene")?.toJS()["react-planner"]?.scene?.layers["layer-1"]?.items)?.forEach((item) => {
      if (item[1].id === id) {
        if (item[1]?.properties?.deviceId && item[1]?.properties?.id) {
          navigate(`equipment-details/${item[1]?.properties?.deviceId + "-" + item[1]?.properties?.id}/${moment(new Date()).format("YYYY-MM-DD")}`);
        }
      }
    });
  };

  const FullScreen = () => {
    setSelectedButton("fullScreen");

    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };
  const MapInfo = () => {
    setView(!view);
  };
  const MapDetail = () => {
    setAreaView(!areaView);
  };
  const MapEdit = () => {
    navigate("map-editor");
    setSelectedButton("edit");
  };
  const buttons = [
    { name: "fullScreen", icon: MapSquare, onClick: FullScreen },
    { name: "info", icon: MapInfoIcon, onClick: MapInfo },
    { name: "map", icon: MapIcon, onClick: MapDetail },
    { name: "edit", icon: MapEditIcon, onClick: MapEdit },
  ];
  // useEffect(() => {
  //   setSelectedMap(Number(localStorage.getItem("selectedmap")));
  // }, []);

  const selectedProcessFunc = async () => {
    let items = ref?.current?.store?.getState("scene")?.toJS()["react-planner"]?.scene?.layers["layer-1"]?.items;

    items == undefined ? (items = {}) : (items = items);
    await Object.entries(items)?.forEach((item) => {
      if (selectedProcess != "all") {
        if (item[1].properties?.processId == selectedProcess?.id) {
          item[1].properties["isSelected"] = false;

          ref.current.selector.props.itemsActions.selectItem("layer-1", item[0]);
          ref.current.selector.props.projectActions.setProperties({
            ...item[1].properties,
          });
          ref.current.selector.props.projectActions.unselectAll();
        } else {
          item[1].properties["isSelected"] = true;
          ref.current.selector.props.itemsActions.selectItem("layer-1", item[0]);
          ref.current.selector.props.projectActions.setProperties({
            ...item[1].properties,
          });
          ref.current.selector.props.projectActions.unselectAll();
        }
      } else {
        item[1].properties["isSelected"] = false;
        ref.current.selector.props.itemsActions.selectItem("layer-1", item[0]);
        ref.current.selector.props.projectActions.setProperties({
          ...item[1].properties,
        });
        ref.current.selector.props.projectActions.unselectAll();
      }
    });
  };

  useEffect(() => {
    handleMaps();
  }, [data]);

  useEffect(() => {
    selectedProcessFunc();
  }, [selectedProcess, setSelectedProcess]);

  // useEffect(() => {
  //   localStorage.setItem("selecteMap", selectedMap);
  // }, [selectedMap]);

  return (
    <React.Fragment>
      <Provider store={store}>
        <div className={screenfull.isFullscreen ? "fullscreen" : "flex justify-center w-full h-full items-center relative sm:justify-start"}>
          {isLoading ? (
            <div className="w-full items-center justify-center flex h-full">
              <Loader currentColor={"#6941C6"} currentFill={"#F4EBFF"} />
            </div>
          ) : (
            <>
              <ReactPlanner
                ref={ref}
                catalog={MyCatalog}
                width={width}
                height={height}
                onClickOnItem={getCurrentClickedItem}
                viewOnly={true}
                plugins={plugins}
                stateExtractor={(state) => {
                  return state.get("react-planner");
                }}
              />

              <div className={"absolute right-3 top-3 z-auto "}>
                <MapInfoButton buttons={buttons} selectedButton={selectedButton} />
              </div>
              {isFetching && (
                <div className="w-8 h-8 absolute left-3 top-3 flex items-center justify-center">
                  <Loader currentColor={"#6941C6"} currentFill={"#F4EBFF"} />
                </div>
              )}
              {view && <StatusInfoModal setView={setView} />}
              {areaView && <AreaModal setCurrentMap={setCurrentMap} selectedMap={selectedMap} maps={maps} setAreaView={setAreaView} />}
            </>
          )}
        </div>
      </Provider>
    </React.Fragment>
  );
});

export default memo(Planner);
