import React, { useState } from "react";
import Modal from "react-modal";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button, Input } from "../../../components";
import { junctionService } from "../../../services/junction.service";
import { authStore } from "../../../stores/auth.store";
import { Toast } from "../../../utils/toastify/toast";
import { useTranslation } from "react-i18next";
import { DotsVerticalIcon } from "../../../assets/icons/commonIcons";
import CloseButton from "../../../components/buttons/CloseButton";
const DroppedJunctionPlan = ({ isOpen, setIsOpen, nodeData, orderData, refetch, processId }) => {
  const { t } = useTranslation();
  const [plannedQuantity, setPlannedQuantity] = useState(0);
  const startNewPlannedJunction = async () => {
    const data = {
      planningOrder: orderData?.planningOrder,
      targetedWorkCount: plannedQuantity,
      companyId: authStore.user.company.id,
      process: { id: processId },
      stationId: nodeData?.id,
      type: "internal",
      orderId: orderData?.orderData?.id,
      headerId: orderData?.id,
      flexHeaderId: orderData?.flexProduct ? orderData?.id : null,
      flexProductId: orderData?.flexRawMaterial ? orderData?.id : orderData?.flexProduct ? orderData?.flexProduct?.id : null,
      productType: orderData?.flexProduct || orderData?.flexRawMaterial ? "flex" : "standart",
    };
    if (plannedQuantity === 0) {
      Toast("error", "Lütfen sıfırdan farklı bir miktarı giriniz");
      return;
    } else {
      await junctionService.equipmentBasedJunctionPlanning(data).then((res) => {
        setPlannedQuantity(0);
        if (res?.data?.code === 0) {
          Toast("success", res.data?.message);
          setIsOpen(false);
          refetch();
        } else if (res?.data.code === 1) {
          Toast("error", res?.data?.message);
          setIsOpen(false);
        } else {
          Toast("error", "Error");
        }
      });
    }
  };
  const statusColor = () => {
    if (orderData?.flexProduct || orderData?.flexRawMaterial || orderData?.name) {
      return "#E9D7FE";
    } else {
      switch (orderData?.status) {
        case "finished":
          return "#12B76A";
        case "started":
          return "#FDE172";
        case "rejected":
          return "#D92D20";
        case "flexProduct":
          return "#E9D7FE";
        default:
          return "#F2F4F7";
      }
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      overlayClassName="fixed inset-0  flex items-center justify-center bg-[#0F0F0F] bg-opacity-50 backdrop-blur-[4px] z-[999]"
    >
      <div className="w-full h-full relative flex flex-col">
        <div className="w-full h-full">
          <div className="w-[500px] bg-white flex flex-col items-start">
            <div className="flex flex-col border-b px-6 pt-5 w-full pb-5 items-start">
              <p className="text-xl font-semibold text-secondary-900">{t("product:planProduct")}</p>
              <p className="text-sm text-secondary-600 font-normal mt-1">{t("product:plannedEquipment")}</p>
            </div>
            <div className="flex flex-row items-center w-full">
              <div className="flex min-h-full min-w-[16px] max-w-[16px] pl-8 flex-col items-center justify-center">
                <div className="flex min-w-[16px] max-w-[16px] max-h-[16px] min-h-[16px] h-4 w-4 rounded-2xl border bg-[#F9FAFB] border-[#D0D5DD] items-center justify-center">
                  <div className="bg-[#D0D5DD] rounded-3xl w-2 h-2 min-w-2 min-h-2"></div>
                </div>
                <LineIcon />
                <div className="flex min-w-[16px] max-w-[16px] max-h-[16px] min-h-[16px] rounded-2xl border bg-[#F9FAFB] border-[#D0D5DD] p-1"></div>
              </div>

              <div className="flex w-full flex-col items-start">
                <div className="pr-6 pl-5 w-full">
                  <div className="flex bg-white flex-row mt-5  w-full h-[90px] min-h-[90px] relative group pl-2  pt-2  border border-[#D0D5DD] rounded-lg">
                    <div className="flex flex-col w-[88%]">
                      <div className="flex flex-row items-center gap-x-3">
                        <img
                          className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-300 select-none"
                          src={generateFileUrl(
                            orderData?.internalOperation?.product?.image
                              ? orderData?.internalOperation?.product?.image
                              : orderData?.flexProduct?.image
                              ? orderData?.flexProduct?.image
                              : orderData?.image
                          )}
                        />
                        <div className="flex flex-col items-start">
                          <div className="flex flex-row items-center gap-x-1 w-full">
                            {orderData?.flexProduct || orderData?.flexRawMaterial || orderData?.name ? (
                              <>
                                {/* <FlexProductIcon /> */}
                                <p className="font-semibold text-secondary-900 text-base w-[280px] truncate">
                                  {orderData?.internalOperation?.product?.name
                                    ? orderData?.internalOperation?.product?.name
                                    : orderData?.flexProduct?.name
                                    ? orderData?.flexProduct?.name
                                    : orderData?.name || "Product Name"}
                                </p>
                              </>
                            ) : (
                              <p className="font-semibold text-secondary-900 text-base  w-[280px] truncate">
                                {orderData?.internalOperation?.product?.name || "Product Name"}
                              </p>
                            )}
                          </div>
                          <div
                            className={`h-6 max-w-[280px] flex px-1 py-[3px] items-center justify-center bg-[${
                              orderData?.flexProduct || orderData?.flexRawMaterial || orderData?.name ? "#F9F5FF" : "#FFFAEB"
                            }]`}
                          >
                            <p
                              className={`text-sm font-normal ${
                                orderData?.flexProduct || orderData?.flexRawMaterial ? "text-[#6941C6]" : "text-[#B54708]"
                              } max-w-[280px] truncate`}
                            >
                              {orderData?.flexProduct || orderData?.flexRawMaterial ? t("product:flex") : t("product:stock")} :{" "}
                              {orderData?.internalOperation?.product?.stockCode
                                ? orderData?.internalOperation?.product?.stockCode
                                : orderData?.flexProduct?.stockCode
                                ? orderData?.flexProduct?.stockCode
                                : orderData?.stockCode || "--"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center gap-x-[6px]">
                        <div className="flex flex-row items-center gap-x-[6px]">
                          {orderData?.flexProduct ? (
                            <>
                              <div className="flex flex-row items-center mt-1.5">
                                <PieceFlexIcon color={"#7F56D9"} />
                                <p className="text-sm font-medium ml-1 text-[#6941C6]">{orderData?.finishedPiece || "0"}</p>
                                <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-500">{t("product:qtyMade")}</p>
                              </div>
                            </>
                          ) : orderData?.flexRawMaterial || orderData?.name ? (
                            <>
                              <div className="flex flex-row items-center mt-1.5">
                                <PieceFlexIcon color={"#7F56D9"} />
                                <p className="text-sm font-medium ml-1 text-[#6941C6]">{orderData?.qualityQuantity || "0"}</p>
                                <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-500">{t("product:qtyMade")}</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex flex-row items-center mt-1.5">
                                <PieceFlexIcon color={"#475467"} />
                                <p className="text-sm font-medium ml-1 text-secondary-700">{orderData?.finishedPiece || "0"}</p>
                                <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-600">{t("product:qtyMade")}</p>
                              </div>
                              <div className="flex flex-row items-center mt-1.5">
                                <PieceFlexIcon color={"#DC6803"} />
                                <p className="text-sm font-medium ml-1 text-[#B54708]">{orderData?.piece - orderData?.finishedPiece || "0"}</p>
                                <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-600">{t("product:qtyRemaining")}</p>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0  bg-[${
                        orderData?.flexProduct || orderData?.flexRawMaterial || orderData?.name ? "#F9F5FF" : statusColor()
                      }] border-secondary-300 h-[70px] min-h-[70px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
                    >
                      <p
                        className={`text-[10px] font-medium ${
                          orderData?.status === "started"
                            ? "text-[#A15C07]"
                            : orderData?.status === "rejected"
                            ? "text-white"
                            : orderData?.status === "finished"
                            ? "text-white"
                            : orderData?.flexProduct || orderData?.flexRawMaterial || orderData?.name
                            ? "text-[#6941C6]"
                            : "text-secondary-500"
                        } -rotate-90`}
                      >
                        {orderData?.flexProduct || orderData?.flexRawMaterial || orderData?.name
                          ? orderData?.step
                            ? orderData?.step + ".Flex"
                            : "Flex"
                          : orderData?.internalOperation?.step + ".Op"}
                      </p>
                      {orderData?.flexProduct || orderData?.flexRawMaterial || orderData?.name ? (
                        <></>
                      ) : (
                        <span className="w-3 min-w-3 h-3 min-h-3">
                          <ArrowIcon
                            color={
                              orderData?.status === "started"
                                ? "#A15C07"
                                : orderData?.status === "rejected"
                                ? "#fff"
                                : orderData?.status === "finished"
                                ? "#fff"
                                : "#475467"
                            }
                          />
                        </span>
                      )}
                    </div>
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="hidden absolute min-h-[24px] rotate-90 max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  group-hover:flex right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
                    >
                      <DotsVerticalIcon />
                    </div>
                  </div>
                </div>
                <div className="flex w-full pl-5 pr-6 mt-6">
                  <div className="w-full  rounded-lg  border border-[#D0D5DD] p-2 flex items-center bg-white">
                    <img
                      className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-300 select-none"
                      src={generateFileUrl(nodeData?.metadata?.image)}
                    />
                    <div className="flex flex-col ml-3  flex-1 overflow-hidden">
                      <p className="font-semibold text-secondary-700 text-base w-[320px] truncate">
                        {nodeData?.metadata?.brand + " " + nodeData?.metadata?.model}
                      </p>
                      <p className="text-sm font-normal w-[320px] truncate text-secondary-500">{nodeData?.process?.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full mt-6 pl-[52px] pr-6">
              <Input
                min={String(plannedQuantity)}
                theme={"product"}
                label={t("product:plannedQuantitys")}
                type={"number"}
                placeholder={"Adet Giriniz"}
                value={plannedQuantity}
                onChange={(event) => {
                  setPlannedQuantity(event.target.value);
                }}
              />
            </div>
            <div className="h-[1px] w-full bg-[#E4E7EC] mt-5"></div>
            <div className="w-full flex items-center justify-between gap-3 p-6">
              <Button colorType={"secondary-gray"} label={t("buttons:stop")} size={"md"} onClick={() => setIsOpen(false)} />
              <Button onClick={startNewPlannedJunction} colorType={"primary-product"} label={t("buttons:plan")} size={"md"} />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute right-4 top-2">
        <CloseButton onClick={() => setIsOpen(false)} size={"lg"} />
      </div>
    </Modal>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    zIndex: 99999,
    borderRadius: "12px",
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "#FFF",
    borderColor: "#FFF",
  },
};
export default DroppedJunctionPlan;
const ArrowIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M6 9.5L6 2.5M6 2.5L2.5 6M6 2.5L9.5 6" stroke={color} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const LineIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="90" viewBox="0 0 12 90" fill="none">
      <path d="M6 1L6 45M6 89L6 45M6 45L0.999998 40.1111M6 45L11 40.1111" stroke="#D0D5DD" strokeLinecap="round" />
    </svg>
  );
};
const PieceFlexIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17841_63067)">
        <path
          d="M5.49967 1.3335H2.99967C2.0792 1.3335 1.33301 2.07969 1.33301 3.00016V13.0002C1.33301 13.9206 2.0792 14.6668 2.99967 14.6668H12.9997C13.9201 14.6668 14.6663 13.9206 14.6663 13.0002V3.00016C14.6663 2.07969 13.9201 1.3335 12.9997 1.3335H10.4997M5.49967 1.3335V5.19064C5.49967 5.25535 5.56863 5.29672 5.62573 5.26627L7.99967 4.00016L10.3736 5.26627C10.4307 5.29672 10.4997 5.25535 10.4997 5.19064V1.3335M5.49967 1.3335H10.4997"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17841_63067">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
