import React, { useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import { Button, Loading } from "../../../components";
import { Loader } from "../../../assets/icons/stepsIcons";
import ExpandedCard from "../components/ExpandedCard";
import PersonalItem from "../components/PersonalItem";

const GroupMembers = ({ triggerAdmin, triggerUser, auth, chat, t, adminUser, selectAllPress, selectedUsers, data, setSelectedTab, loading, loadings }) => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="min-w-[700px] max-w-[700px] mt-6">
      {loadings ? (
        <div className="min-h-[382px] max-h-[382px] w-full h-full flex items-center justify-center">
          <Loading primary={"#15B79E"} secondary={"#fff"} size={32} />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <SearchInput setSearchVal={setSearchValue} placeholder={t("chat:search")} />
          <div>
            <div className="FORM w-full flex flex-col gap-6">
              <div className="w-full flex">
                <div className="max-w-full h-[244px] overflow-y-scroll min-w-full scrollbar-hide flex flex-col">
                  {data?.map((item, index) => {
                    if (item?.users?.length > 0) {
                      return (
                        <ExpandedCard
                          searchValue={searchValue}
                          t={t}
                          item={item}
                          index={index}
                          key={index}
                          chat={chat}
                          auth={auth}
                          PersonalItem={PersonalItem}
                          selectedUsers={selectedUsers}
                          triggerUser={triggerUser}
                          adminUser={adminUser}
                          selectAllPress={selectAllPress}
                          triggerAdmin={triggerAdmin}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
              <div className="flex gap-3">
                <Button
                  colorType="secondary-gray"
                  label={t("buttons:prev")}
                  onClick={() => {
                    setSelectedTab("info");
                  }}
                />
                {loading ? (
                  <div className="flex items-center justify-center bg-[#0E9384]  rounded-lg w-full h-[46px] max-h-[46px]">
                    <Loader currentColor="#0E9384" currentFill="currentFill" />
                  </div>
                ) : (
                  <Button colorType="primary-person" type="submit" label={t("buttons:save")} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupMembers;
