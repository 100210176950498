import React from "react";
import { PointIconLogo } from "../../../assets/icons/PersonIcons";
import NoAvatar from "../../../components/avatar/NoAvatar";
import moment from "moment";
import generateFileUrl from "../../../utils/generateFileUrl";

const FirstPerson = ({ navigate, id, name, lastname, point, department, avatar, numFormatter }) => {
  return (
    <>
      <div className="flex  gap-y-4 flex-col h-[284px] w-[232px] min-w-[232px] border rounded-[16px] p-6 ring-4 ring-[#F6D03924] border-[#F6D03948]  justify-center items-center">
        <div
          onClick={() => {
            navigate(`/app/machine/digital-twin/user-detail/${id}/${moment().format("YYYY-MM-DD")}`);
          }}
          className="rounded-full cursor-pointer bg-[#F6D0393D] min-w-[112px] max-w-[112px] min-h-[112px] max-h-[112px] items-center flex justify-center relative"
        >
          {avatar ? (
            <img
              className="max-w-[56px] min-w-[96px] max-h-[96px] min-h-[96px] rounded-full border-2 outline-2 border-[#0000001A] outline-[#0000001A]"
              src={generateFileUrl(avatar)}
            />
          ) : (
            <NoAvatar name={name} lastName={lastname} size={40} />
          )}
          <div className="flex px-[6px] min-h-[24px] max-h-[24px] absolute border border-[#F8DA64] rounded-lg items-center justify-center text-[#C7A109] font-semibold text-md bg-white bottom-[-6px]">
            1st
          </div>
        </div>
        <div className="flex gap-y-2 flex-col w-full h-full items-center">
          <div className="flex flex-col w-full items-center justify-center">
            <span className="text-lg w-[184px] truncate text-center font-semibold text-secondary-700">{name + " " + lastname}</span>
            <span className="text-sm text-center font-normal text-secondary-500">{department || "---"}</span>
          </div>
          <div className="min-h-[28px] max-h-[28px] bg-gradient-to-r from-[#BB9600] to-[#ECBE05] rounded-[36px] flex gap-x-1 px-2 items-center justify-center">
            <div className="w-4 min-w-4 max-w-4">
            <PointIconLogo />
            </div>
            <span className="text-white font-semibold text-sm">{String(numFormatter(point))}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstPerson;
