import React, { useState, useEffect } from "react";
import { Button, CustomModal } from "../../components";
import { ArrowLeftIcon, MoveIcon, PlusIconNew } from "../../assets/icons/productIcons";
import { useTranslation } from "react-i18next";
import NewPlannedJunctionModal from "./modals/NewPlannedJunctionModal";
import FinishOperationSelectNode from "../../components/modal/FinishOperationSelectNode";
import { Toast } from "../../utils/toastify/toast";
import FinishOperationDropDown from "./components/FinishOperationDropDown";
import AddJunctionManual from "../../components/modal/AddJunctionManual";
import ServiceSupplyPlanModal from "./modals/ServiceSupplyPlanModal";
import ApplyFromStockModal from "../../components/modal/ApplyFromStockModal";
import SendToPurchasingModal from "../../components/modal/SendToPurchasingModal";
import Badgets from "../../components/buttons/Badgets";
const ProductOperationDetailHeader = ({
  navigate,
  refetch,
  operation,
  headerId,
  currentOperation,
  disabledMove,
  isLoading,
  isFetching,
  stepNo,
}) => {
  const [newPlannedJunctionModal, setNewPlannedJunctionModal] = useState(false);
  const [newPlannedJunctionExternalModal, setNewPlannedJunctionExternalModal] = useState(false);
  const [isOpenFinishOperation, setIsOpenFinishOperation] = useState(false);
  const [isOpenFromStock, setIsOpenFromStock] = useState(false);
  const [isOpenPurchase, setIsOpenPurchase] = useState(false);


  const [isOpenJunction, setIsOpenJunction] = useState(false);
  const [status, setStatus] = useState("");
  const { t } = useTranslation();
  const operationData = {
    product: {
      id: currentOperation?.id ? currentOperation?.id : operation?.flexProductId,
      name: operation?.name,
      image: operation?.image,
    },
    orderNo: operation?.orderNo,
    orderId: operation?.orderId,
  };
  useEffect(() => {
    setStatus(operation?.operation?.status);
  }, [status]);
  return (
    <>
      <div>
        <div className="w-full h-[78px] border-b flex items-center sticky top-0 z-50 bg-white ">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} />
          <div className="flex flex-row items-center ml-3">
            <h1 className="text-xl font-semibold text-gray-900">{t("product:operationDetail")}</h1>
            {operation?.operations?.length > 1 ? (
              <div className="ml-2">
                <Badgets colorType={"fill-gray"} size={"lg"} label={t("product:multiOp")} />
              </div>
            ) : (
              <></>
            )}
            {/* <p className="ml-2 text-xl font-normal text-[#475467]">{operation?.name}</p> */}
          </div>
          <div className="flex items-center ml-auto gap-3 mr-1">
            <FinishOperationDropDown
              setIsOpenFinishOperation={setIsOpenFinishOperation}
              setIsOpenJunction={setIsOpenJunction}
              currentOperation={currentOperation}
              setIsOpenFromStock={setIsOpenFromStock}
              setIsOpenPurchase={setIsOpenPurchase}
              // disabled={status != "finished" ? false : true}
              manuelDisabled={currentOperation?.type == 'internal' ? false : true}
            />
            <Button
              colorType={"secondary-product"}
              iconLeft={<MoveIcon />}
              label={t("product:moveJunction")}
              size={"md"}
              disabled={disabledMove}
              onClick={() => {
                Toast("info", 'Bu özellik şu an aktif değil.');
                // navigate(`/app/product/mrp/work-in-process/detail/${operationId.split("+")[0]}+${operationId.split("+")[1]}/move-junction`);
              }}
            />
            <Button
              colorType={"primary-product"}
              size={"md"}
              label={t("product:planWork")}
              iconLeft={<PlusIconNew color={"#FFFFFF"} />}
              onClick={() => {
                if (operation?.operation?.status == "finished") {
                  Toast("error", t("product:noPlannedJunction"));
                } else {
                  if (currentOperation?.type == "external") {
                    setNewPlannedJunctionExternalModal(true);
                  } else {
                    setNewPlannedJunctionModal(true);
                  }
                }
              }}
            />
          </div>
        </div>
        <CustomModal
          isOpen={isOpenFinishOperation}
          setIsOpen={setIsOpenFinishOperation}
          onClose={() => {
            setIsOpenFinishOperation(false);
          }}
          modalTitle={t("product:finishThisOperation")}
          width="fit-content"
          children={
            <FinishOperationSelectNode
              refetch={refetch}
              orderData={operationData}
              isLoading={isLoading}
              isFetching={isFetching}
              currentOperation={currentOperation}
              processId={currentOperation?.internalOperation?.process?.id || currentOperation?.externalOperation?.process?.id}
              onClose={() => {
                setIsOpenFinishOperation(false);
              }}
              operationData={operationData}
              stepNo={stepNo}
            />
          }
        />
        <CustomModal
          isOpen={isOpenJunction}
          setIsOpen={setIsOpenJunction}
          onClose={() => {
            setIsOpenJunction(false);
          }}
          modalTitle={t("product:junctionManually")}
          width="fit-content"
          children={
            <AddJunctionManual
              currentOperation={currentOperation}
              refetch={refetch}
              setIsOpenJunction={setIsOpenJunction}
            />
          }
        />
        <CustomModal
          modalTitle={t("product:createNewWork")}
          setIsOpen={setNewPlannedJunctionModal}
          isOpen={newPlannedJunctionModal}
          children={
            <NewPlannedJunctionModal
              operationData={operationData}
              headerId={headerId}
              getJunctions={refetch}
              closeModal={() => setNewPlannedJunctionModal(false)}
              processId={currentOperation?.processId}
            />
          }
        />
        <CustomModal
          modalTitle={t("product:applyFromStock")}
          setIsOpen={setIsOpenFromStock}
          isOpen={isOpenFromStock}
          width={500}
          children={
            <ApplyFromStockModal
              setIsOpenFromStock={setIsOpenFromStock}
              currentOperation={currentOperation}
            />
          }
        />
        <CustomModal
          modalTitle={t("product:sendToPurchasing")}
          setIsOpen={setIsOpenPurchase}
          isOpen={isOpenPurchase}
          width={500}
          children={
            <SendToPurchasingModal
              setIsOpenPurchase={setIsOpenPurchase}
              currentOperation={currentOperation}
            />
          }
        />
        <CustomModal
          setIsOpen={setNewPlannedJunctionExternalModal}
          isOpen={newPlannedJunctionExternalModal}
          onClose={() => {
            setNewPlannedJunctionExternalModal(false);
          }}
          modalTitle={t("product:serviceSupplyPlanned")}
          // subTitle={`${isOpenPlanningModalData?.flexProduct ? isOpenPlanningModalData?.step + ".Flex" : isOpenPlanningModalData?.flexRawMaterial ? "Flex" : isOpenPlanningModalData?.step + ".Op"} (${selectedGroup?.name}) için planlama detaylarını belirtin.`}
          subTitle={t("product:specifyPlannings")}
          width="fit-content"
          children={
            <ServiceSupplyPlanModal
              setIsOpen={setNewPlannedJunctionExternalModal}
              refetch={refetch}
              selectedJunction={{
                id: currentOperation?.operationId,
                externalOperation: {
                  process: { id: currentOperation?.processId },
                  product: {
                    image: operation?.image,
                    name: operation?.name,
                    stockCode: operation?.stockCode,
                  },
                  activePiece: currentOperation?.activePiece,
                },
                orderData: { id: operation?.orderId },
                ssPlanedJunctions: currentOperation?.ssPlanedJunctions,
                step: operation?.operationStep,
                piece: operation?.totalPiece,
                finishedPiece: operation?.finishedPiece,
              }}
              groupId={currentOperation?.processId}
            />
          }
        />
      </div>
    </>
  );
};

export default ProductOperationDetailHeader;
