import React from "react";

const UnitPriceInput = ({
  receipe,
  label,
  placeholder,
  onBlur,
  value,
  disabled,
  validate,
  items,
  errorMessage,
  theme,
  size,
  setValue,
  price,
  currency,
  ...rest
}) => {
  const sizeStyles = {
    xs: "40px",
    sm: "44px",
  };
  const inputSize = sizeStyles[size] || "40px";

  const handleCurrencyChange = (e) => {
    setValue("currency", e.target.value);
  };

  const handlePriceChange = (e) => {
    const inputValue = e.target.value;
    receipe ? setValue("costOfMinute", inputValue) : setValue("price", inputValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "," || e.keyCode === 188) {
      e.preventDefault();
    }
  };

  return (
    <div className={"flex flex-col relative w-full "} {...rest}>
      {label && <span className="text-sm mb-1.5 pl-0  w-full text-secondary-700 dark:text-darkTrue-200 font-medium">{label}</span>}
      <div
        style={{ height: inputSize }}
        className={`${validate == "error" ? "border-validate-errorBorder " : "border-secondary-300 "} ${size === "sm" ? "pr-[14px] " : " "} ${
          theme === "person"
            ? `focus-within:border-teal-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#C4EDE6] `
            : theme === "product"
            ? "focus-within:border-warning-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#FDE3C1] "
            : theme === "embedded"
            ? "focus-within:border-grayBlue-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#D2D6E8] "
            : "focus-within:border-primary-300 focus-within:outline  focus-within:outline-4 focus-within:outline-[#E8DFFB] "
        } flex w-full border-secondary-300 items-center flex-row gap-2 border focus-within:ring-opacity-50 rounded-lg transition duration-300  dark:border-darkTrue-600 dark:bg-[#0F0F0F]
          
           ${disabled ? "bg-secondary-50 dark:bg-[#292929] mx-0" : "bg-white dark:bg-[#0F0F0F]"} `}
      >
        <div className="flex w-full h-full rounded-md">
          <select
            onChange={handleCurrencyChange}
            onBlur={onBlur}
            value={currency}
            disabled={disabled}
            className={`lg:text-sm md:text-md rounded-l-lg  sm:text-xs focus:ring-0 text-md text-secondary-900 pl-3 focus:outline-none  w-[80px] ${
              size === "sm" ? "py-[9px]" : "py-[7px]"
            } border-none ${disabled ? "bg-secondary-50 dark:text-[#FAFAFA]" : "dark:bg-[#0F0F0F] dark:text-[#737373]"}`}
          >
            {items?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <input
            onKeyDown={handleKeyDown}
            onChange={handlePriceChange}
            placeholder={placeholder}
            value={price}
            onBlur={onBlur}
            {...rest}
            disabled={disabled}
            className={`lg:text-sm md:text-md ${
              size === "sm" ? "w-full" : "w-[calc(100% - 80px)]"
            } rounded-l-lg rounded-r-lg focus:ring-0 text-md text-secondary-900 pl-3 focus:outline-none ${
              size === "sm" ? "py-[9px]" : "py-[7px]"
            } border-none ${disabled ? "bg-secondary-50 dark:text-[#FAFAFA]" : "dark:bg-[#0F0F0F] dark:text-[#737373]"}`}
            type="number"
            step="any"
            validate={validate}
          />
        </div>
      </div>
      {validate ? (
        <p
          className={` ${
            validate === "success"
              ? "text-validate-success"
              : validate === "warning"
              ? "text-validate-warning"
              : validate === "error"
              ? "text-validate-error"
              : validate === "tooltip"
              ? "text-secondary-500 text-[14px] leading-5"
              : ""
          }  sm:mt-0.5 lg:mt-1 md:mt-1 sm:text-[10px]  text-[12px]  mt-0.5 font-normal `}
        >
          {errorMessage || validate.toLocaleUpperCase()}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UnitPriceInput;
