import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import NoAvatar from "../../../components/avatar/NoAvatar";
import generateFileUrl from "../../../utils/generateFileUrl";
import useWindowDimensions from "../../../hooks/useWindowDimension";

const GroupChatCard = ({
  name,
  conversationId,
  image,
  total,
  approved,
  todo,
  done,
  expired,
  onClick,
}) => {
  const { width } = useWindowDimensions();
  const badgets = [
    { color: "#7F56D9", value: total },
    { color: "#475467", value: todo },
    { color: "#EAAA08", value: done },
    { color: "#17B26A", value: approved },
    { color: "#F04438", value: expired },
  ];

  return (
    <NavLink
      to={conversationId}
      onClick={onClick}
      className={({ isActive }) =>
        `w-full h-[72px] min-h-[72px] flex items-center  py-3 rounded-lg ${
          isActive ? "bg-gray-50 rounded-lg" : ""
        }`
      }
    >
      {image ? (
        <img
          className="min-w-[48px] w-[48px] h-[48px] rounded-full object-cover"
          src={generateFileUrl(image)}
        />
      ) : (
        <NoAvatar name={name} size={48} fontSize={22} />
      )}
      <div className="flex flex-col ml-3 flex-1">
        <p className="font-semibold text-secondary-700 truncate overflow-hidden">
          {name}
        </p>
        <div className="flex items-center w-full justify-between mt-0.5">
          {badgets.map((item, index) => {
            return (
              <div
                key={index}
                style={{ borderTopColor: item.color }}
                className={`h-[22px] ${
                  width >= 1360 && width < 1550 ? "w-[36px]" : "w-[46px]"
                }  flex items-center border-t-[3px] border border-x-secondary-200 rounded justify-center bg-white`}
              >
                <p
                  style={{ color: item.color }}
                  className="text-xs font-semibold"
                >
                  {item.value}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </NavLink>
  );
};

export default memo(GroupChatCard);
