import React from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { DeleteIcon } from "../../../assets/icons/taskCardIcons";

const UserLine = ({
  avatar,
  name,
  lastName,
  index,
  editMode,
  onClick,
  userLength,
}) => {
  return (
    <div
      style={{
        backgroundColor:
          index % 2 === 0
            ? localStorage.color_theme === "dark"
              ? "#292929"
              : "#F9FAFB"
            : localStorage.color_theme === "dark"
            ? ""
            : "#fff",
      }}
      className="w-full flex items-center px-3 py-[6px] h-11 rounded-md"
    >
      {avatar ? (
        <img
          className="w-8 h-8 rounded-full border border-solid dark:border-[#424242] object-cover"
          src={generateFileUrl(avatar)}
        />
      ) : (
        <NoAvatar
          name={name}
          lastName={lastName}
          size={32}
          fontSize={14}
          color={"#15B79E"}
        />
      )}
      <p className="ml-3 text-sm text-[#344054] font-medium dark:text-[#D6D6D6]">
        {name + " " + lastName}
      </p>
      {editMode && (
        <>
          {userLength <= 1 ? null : (
            <button
              onClick={onClick}
              className="min-w-[20px] h-5 flex items-center justify-center ml-auto"
            >
              <DeleteIcon />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default UserLine;
