import React, { useEffect, useState } from 'react'
import { NoEquIcon } from '../../../assets/icons/machineIcons'
import CustomImage from '../../../components/CustomImage'
import { useTranslation } from 'react-i18next'
import StockDetailRecipeQualityCard from '../cards/StockDetailRecipeQualityCard'
import moment from 'moment'
import { legacyFormatSeconds } from '../../../utils/secondToHoursAndMinute'
import { Button, Loading } from '../../../components'
import OperationDetailQualityAppliedCard from '../../../components/new-cards/material-supply/OperationDetailQualityAppliedCard'
import { ChevronDown } from '../../../assets/icons/PersonIcons'
import Badgets from '../../../components/buttons/Badgets'
import OperationDetailModalQualityAppliedCard from '../../../components/new-cards/material-supply/OperationDetailModalQualityAppliedCard'
import { useLocation } from 'react-router-dom'
import { ArrowLeftIcon, ArrowRightIcon } from '../../../assets/icons/productIcons'
const StockDetailRecipeOperationModal = ({ data, loading, partialData, productData, operationData, junctionData,operationDetail }) => {
    const { t } = useTranslation()
    const { pathname } = useLocation();
    const [isProductJunctionVisible,setIsProductJunctionVisible] = useState(true)
    const [isPurchaseJunctionVisible,setIsPurchaseJunctionVisible] = useState(false)
    const [selectedOperation, setSelectedOperation] = useState("all");
    const tableHeadItems = [
        {
            title: t("product:eventNew")
        },
        {
            title: t("product:cycleTimess")
        },
        {
            title: t("product:opCost")
        },
    ]
    return (
        <>
            {loading ? (
                <div className="flex items-center w-full justify-center  h-[480px]">
                    <Loading size={44} primary={"#B54708"} secondary={"#fff"} />
                </div>
            ) : (
                <div className='flex flex-col items-start'>
                    <div className='border-b pb-5 w-full mt-1'>
                        <p className='text-sm text-secondary-600'>{operationData?.step}.{t("loss:operation")} - {`${operationDetail?.process?.name || "--"} ${`(${operationDetail?.process?.code || "--"})`}`}</p>
                    </div>

                    {/* {data?.product?.operations?.length <= 1 ? (
                            <>
                              {pathname?.includes("move-junction") ? null : (
                                <div className="flex flex-col gap-y-7 w-full pt-4">
                                  <div className="flex justify-between  w-full items-center">
                                    <div className="flex">
                                      <Button
                                        colorType={"secondary-gray"}
                                        iconLeft={<ArrowLeftIcon />}
                                        label={t("buttons:previousOperationNew")}
                                        size={"md"}
                                        // onClick={() => {
                                        //   setSelectedOperation('all');
                                        //   navigate(pathname?.split("+")[0] + '+' + (Number(orderIds) - 1));
                                        // }}
                                        // disabled={!selectedOperation?.previousOrderOperation}
                                      />
                                    </div>
                                    <div className="flex gap-x-2">
                                      <span className="text-[#101828] text-lg font-semibold ">
                                        {selectedOperation?.operationStep}.{t("product:operations")}
                                      </span>
                                      <span className="text-[#475467] text-lg font-normal">
                                        {selectedOperation?.processName}
                                      </span>
                                    </div>
                                    <div className="flex">
                                      <Button
                                        colorType={"secondary-gray"}
                                        iconRight={<ArrowRightIcon />}
                                        label={t("buttons:nextOperationNew")}
                                        size={"md"}
                                        // onClick={() => {
                                        //   setSelectedOperation('all');
                                        //   navigate(pathname?.split("+")[0] + '+' + (Number(orderIds) + 1));
                                        // }}
                                        // disabled={!selectedOperation?.nextOrderOperation}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {pathname?.includes("move-junction") ? null : (
                                <div className="flex flex-col gap-y-7 w-full pt-4">
                                  <div className="flex justify-between  w-full items-center">
                                    <div className="flex">
                                      <Button
                                        colorType={"secondary-gray"}
                                        iconLeft={<ArrowLeftIcon />}
                                        label={t("buttons:previousOperationNew")}
                                        size={"md"}
                                        // onClick={() => {
                                        //   setSelectedOperation('all');
                                        //   navigate(pathname?.split("+")[0] + '+' + (Number(orderIds) - 1));
                                        // }}
                                        // disabled={!selectedOperation?.previousOrderOperation}
                                      />
                                    </div>
                                    <div className="flex items-center">
                                      <span className="text-[#101828] text-lg mr-2 font-semibold ">
                                        {operationData?.step}.{t("loss:operation")}
                                      </span>
                                      <div className="flex border rounded-lg border-[#D0D5DD]">
                                        {data?.product?.operations?.map((operation, index) => {
                                          return (
                                            <div
                                              key={index}
                                            //   onClick={() => {
                                            //     setSelectedOperation(operation);
                                            //   }}
                                              className={`flex justify-center items-center text-[#344054] font-semibold text-sm px-4 py-2 cursor-pointer ${index === data?.product?.operations?.length - 1 ? "rounded-r-lg" : "border-r border-[#D0D5DD]"
                                                } 
                                                ${selectedOperation?.operationId == operation?.operationId ? "bg-[#F2F4F7]" : "bg-white"} ${index === 0 ? "rounded-l-lg" : ""}`}
                                            >
                                             {operation?.process?.name || "--"}
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                    <div className="flex">
                                      <Button
                                        colorType={"secondary-gray"}
                                        iconRight={<ArrowRightIcon />}
                                        label={t("buttons:nextOperationNew")}
                                        size={"md"}
                                        // onClick={() => {
                                        //   setSelectedOperation('all');
                                        //   navigate(pathname?.split("+")[0] + '+' + (Number(orderIds) + 1));
                                        // }}
                                        // disabled={!selectedOperation?.nextOrderOperation}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )} */}

                    <div className='flex flex-row items-start gap-x-6 w-full pt-5'>
                        <div className='flex flex-col items-start gap-y-6 w-1/2 border-r pr-6'>
                            <div className='flex flex-row items-center gap-x-2 p-2 w-full rounded-lg border border-[#E4E7EC]'>
                                {productData?.image ? (
                                    <div>
                                        <CustomImage
                                            borderRadius={2}
                                            src={productData?.image}
                                            style={{
                                                width: 64,
                                                height: 48,
                                                minWidth: 64,
                                                minHeight: 48,
                                                borderRadius: 4,
                                                borderWidth: 1,
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                display: "flex",
                                                borderColor: "#0000001A",
                                                backgroundColor: "#fff",
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                                        <NoEquIcon />
                                    </div>
                                )}
                                <div className='flex flex-col items-start'>
                                    <p className='text-md font-semibold w-[235px] truncate text-secondary-900'>{productData?.name || "Product Name"}</p>
                                    <p className={`text-sm font-normal max-w-[235px] bg-[#FFFAEB] truncate text-[#B54708]`}>
                                        {t("product:stock")} :{" "}
                                        {productData?.stockCode || "--"}
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-col items-start w-full border rounded-lg px-3 py-2 border-[#E4E7EC] gap-y-2'>
                                <div className='flex items-center justify-between w-full'>
                                    <p className='text-lg font-semibold text-[#B54708]'>{partialData?.partial?.type === "order" ? `WO-${partialData?.partial.workOrderNo || "--"}` : `SWO-${partialData?.partial?.stockWorkOrderNo || "--"}`}</p>
                                    <div className='border rounded-md px-3 py-1 flex flex-row items-center gap-x-1'>
                                        <QuantityAcceptedIcon />
                                        <p className='text-sm font-medium text-[#067647]'>{partialData?.quantity || "0"}</p>
                                        <p className='text-xs text-secondary-600'>{t("product:pcs")}</p>
                                    </div>
                                </div>
                                <div className='flex flex-col w-full items-start'>
                                    <div className='flex flex-row items-center justify-between w-full border-b py-[6px]'>
                                        <div className='flex flex-row items-center gap-x-1'>
                                            <OrderNoIcon />
                                            <p className='text-xs text-secondary-600'>{t("product:orderNo")}</p>
                                        </div>
                                        <div className='flex flex-row items-center gap-x-1'>
                                            <p className='text-sm font-medium text-secondary-700'>{partialData?.partial?.orderDetails?.order?.productType === "order" ? `ORD-${partialData?.partial?.orderDetails?.order?.systemOrderNo || "--"}` : `STK-${partialData?.partial?.orderDetails?.order?.systemStockOrderNo || "--"}`}</p>
                                            <LinkIcon />
                                        </div>
                                    </div>
                                    <div className='flex flex-row items-center justify-between w-full py-[6px]'>
                                        <div className='flex flex-row items-center gap-x-1'>
                                            <DeliveryIcon />
                                            <p className='text-xs text-secondary-600'>{t("table:deliveryDate")}</p>
                                        </div>
                                        <div className='flex flex-row items-center gap-x-1'>
                                            <p className='text-sm font-medium text-secondary-700'>{moment(partialData?.partial?.orderDetails?.deliveryDate).format('DD.MM.YYYY')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2 items-start w-full'>
                                <p className='font-semibold text-sm text-secondary-900'>{operationData?.step}.{t("loss:operation")}</p>
                                <div className="flex flex-col w-full h-full border border-[#E4E7EC] rounded-lg">
                                    {/* Header Section */}
                                    <div className="flex max-h-[36px] min-h-[36px] w-full bg-[#F9FAFB] rounded-t-lg border-[#E4E7EC]">
                                        {tableHeadItems?.map((item, i) => (
                                            <div
                                                key={i}
                                                className={`flex items-center justify-center px-4 py-2 border-r last:border-r-0 w-${i === 0 ? "[20%]" : "[40%]"}`}
                                            >
                                                <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item?.title}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-row w-full h-full border-t border-[#E4E7EC]">
                                        <div className="flex items-center justify-center px-4 py-3 w-[20%] border-r">
                                            <p className="text-lg font-medium text-secondary-700">{operationData?.events || "--"}</p>
                                        </div>
                                        <div className="flex flex-col w-[40%] border-r">
                                            <div className="flex justify-between items-center px-2 py-[17px] border-b">
                                                <p className="text-xs text-[#667085]">{t("product:target")}</p>
                                                <p className="text-base text-[#475467]">{legacyFormatSeconds(operationData?.targetTime) || "--"}</p>
                                            </div>
                                            <div className="flex justify-between items-center px-2 py-[17px]">
                                                <p className="text-xs text-[#667085]">{t("product:applieds")}</p>
                                                <p className={`text-base ${operationData?.targetTime < operationData?.cycleTime ? "text-[#F04438]" : "text-[#079455] "}`}>
                                                    {/* {Number(e?.cycleTime).toFixed(2)} */}
                                                    {legacyFormatSeconds(operationData?.cycleTime) || "--"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[40%]">
                                            <div className="flex flex-col items-center px-2 py-2 border-b">
                                                <p className="text-base text-[#475467]">{operationData?.defaultPrice?.toFixed(2) || "--"}</p>
                                                <p className="text-xs text-[#344054]">{t("product:tot")}:{(operationData?.defaultPrice * partialData?.quantity)?.toFixed(2)}</p>
                                            </div>
                                            <div className="flex flex-col items-center px-2 py-2">
                                                <p className="text-base text-[#475467]">{Number(operationData?.appliedPrice).toFixed(2)}</p>
                                                <p className="text-xs text-[#344054]">{t("product:tot")}: {Number(operationData?.appliedPrice * partialData?.quantity).toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-3 w-1/2 h-[418px] overflow-y-scroll scrollbar-hide'>
                            <div className="flex flex-col gap-y-4 w-full items-start">
                                {/* Product Junction Section */}
                                <div className="flex flex-col w-full">
                                    <div className="w-full flex flex-row items-center justify-between border-b pb-1">
                                        <div className="flex flex-row items-center gap-x-2">
                                            <p className="text-sm font-medium text-secondary-700">{t("product:productEvent")}</p>
                                            <Badgets
                                                colorType={"fill-gray"}
                                                size={"sm"}
                                                label={
                                                    junctionData?.filter((item) => !item?.junction?.purchaseRequest)?.length || 0
                                                }
                                            />
                                        </div>
                                        <div
                                            onClick={() => setIsProductJunctionVisible(!isProductJunctionVisible)}
                                            className={`w-9 ${isProductJunctionVisible && "rotate-180"}`}
                                        >
                                            <Button
                                                colorType={"tertiary-gray"}
                                                size={"sm"}
                                                iconLeft={<ChevronDown />}
                                            />
                                        </div>
                                    </div>

                                    {isProductJunctionVisible && (
                                        <div className="mt-2">
                                            {junctionData
                                                ?.filter((item) => !item?.junction?.purchaseRequest)
                                                .map((item, index) => (
                                                    <div key={index} className="w-full mt-2">
                                                        <StockDetailRecipeQualityCard data={item} isModal={true}/>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                                 {/* Purchase Junction Section */}
                                 <div className="flex flex-col w-full">
                                    <div className="w-full flex flex-row items-center justify-between border-b pb-1">
                                        <div className="flex flex-row items-center gap-x-2">
                                            <p className="text-sm font-medium text-secondary-700">{t("product:purchaseEvent")}</p>
                                            <Badgets
                                                colorType={"fill-gray"}
                                                size={"sm"}
                                                label={
                                                    junctionData?.filter((item) => item?.junction?.purchaseRequest)?.length || 0
                                                }
                                            />
                                        </div>
                                        <div
                                            onClick={() => setIsPurchaseJunctionVisible(!isPurchaseJunctionVisible)}
                                            className={`w-9 ${isPurchaseJunctionVisible && "rotate-180"}`}
                                        >
                                            <Button
                                                colorType={"tertiary-gray"}
                                                size={"sm"}
                                                iconLeft={<ChevronDown />}
                                            />
                                        </div>
                                    </div>

                                    {isPurchaseJunctionVisible && (
                                        <div className="mt-2">
                                            {junctionData
                                                ?.filter((item) => item?.junction?.purchaseRequest)
                                                .map((item, index) => (
                                                    <div key={index} className="w-full mt-2">
                                                        <OperationDetailModalQualityAppliedCard
                                                            data={item?.junction}
                                                            isModal={true}
                                                            acceptedWorkCount={item?.acceptedWorkCount}
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </>
    )
}

export default StockDetailRecipeOperationModal


const QuantityAcceptedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_19634_72135)">
                <path d="M5.4987 1.33325H2.9987C2.07822 1.33325 1.33203 2.07944 1.33203 2.99992V12.9999C1.33203 13.9204 2.07822 14.6666 2.9987 14.6666H6.51722M5.4987 1.33325V5.19039C5.4987 5.2551 5.56765 5.29648 5.62475 5.26602L7.9987 3.99992L10.3726 5.26602C10.4297 5.29648 10.4987 5.2551 10.4987 5.19039V1.33325M5.4987 1.33325H10.4987M10.4987 1.33325H12.9987C13.9192 1.33325 14.6654 2.07944 14.6654 2.99992V7.20957M8.57914 12.1224L10.4701 14.0025L14.6679 9.8101" stroke="#17B26A" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_19634_72135">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const OrderNoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.3332 6.99992V4.53325C13.3332 3.41315 13.3332 2.85309 13.1152 2.42527C12.9234 2.04895 12.6175 1.74299 12.2412 1.55124C11.8133 1.33325 11.2533 1.33325 10.1332 1.33325H5.8665C4.7464 1.33325 4.18635 1.33325 3.75852 1.55124C3.3822 1.74299 3.07624 2.04895 2.88449 2.42527C2.6665 2.85309 2.6665 3.41315 2.6665 4.53325V11.4666C2.6665 12.5867 2.6665 13.1467 2.88449 13.5746C3.07624 13.9509 3.3822 14.2569 3.75852 14.4486C4.18635 14.6666 4.7464 14.6666 5.8665 14.6666M7.99984 5.99992H5.33317M9.33317 3.99992H5.33317M6.6665 7.99992H5.33317M7.8695 10.1056L10.3362 8.72723C10.431 8.67429 10.4784 8.64782 10.5286 8.63741C10.573 8.62821 10.6188 8.62817 10.6633 8.6373C10.7135 8.64762 10.7609 8.67401 10.8558 8.72679L13.3332 10.1056M7.8695 10.1056L10.5959 11.6248M7.8695 10.1056V12.8263C7.8695 12.94 7.8695 12.9969 7.88621 13.0477C7.901 13.0926 7.92517 13.1339 7.95713 13.1687C7.99325 13.2081 8.04287 13.2359 8.14211 13.2915L10.5959 14.6666M13.3332 10.1056L10.5959 11.6248M13.3332 10.1056V12.8257C13.3332 12.9396 13.3332 12.9966 13.3164 13.0475C13.3016 13.0924 13.2773 13.1338 13.2453 13.1686C13.209 13.208 13.1593 13.2358 13.0598 13.2914L10.5959 14.6666M10.5959 11.6248V14.6666" stroke="#475467" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const LinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M14 6L14 2M14 2H10M14 2L8.66667 7.33333M6.66667 3.33333H5.2C4.0799 3.33333 3.51984 3.33333 3.09202 3.55132C2.71569 3.74307 2.40973 4.04903 2.21799 4.42535C2 4.85318 2 5.41323 2 6.53333V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H9.46667C10.5868 14 11.1468 14 11.5746 13.782C11.951 13.5903 12.2569 13.2843 12.4487 12.908C12.6667 12.4802 12.6667 11.9201 12.6667 10.8V9.33333" stroke="#667085" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
const DeliveryIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M14 6.66659H2M14 8.33325V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666H8M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M9.66667 12.6666L11 13.9999L14 10.9999" stroke="#475467" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};