import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Button, Loading, UploadProfile } from "../../../components";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import QtyInput from "../../../components/inputs/QtyInput";
import PurchaseFinishJunctionCard from "../../../components/new-cards/material-supply/PurchaseFinishJunctionCard";
import MaterialSupplyEventCardPlannedTriple from "../../../components/new-cards/material-supply/MaterialSupplyEventCardPlannedTriple";
import { purchaseService } from "../../../services/purchase.service";
import { Toast } from "../../../utils/toastify/toast";
import MaterialSupplyReceivedCard from "../../../components/new-cards/material-supply/MaterialSupplyReceivedCard";

const NewPurchaseFinishModal = ({ junctionData, setIsOpen, refetch }) => {
  const { t } = useTranslation(["product"]);

  const [priceValues, setPriceValues] = useState({});

  const schema = yup.object({
    purchaseFile: yup.string().notRequired(),
    secondPurchaseFile: yup.string().notRequired(),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (value) => {
    if (loading) return;
    setLoading(true);

    const data = {
      plannedJunctionRef: junctionData?.id,
      incomingUnit: Number(priceValues),
      purchaseFile: value?.purchaseFile,
      secondPurchaseFile: value?.secondPurchaseFile,
      requestId: junctionData?.purchaseRequest?.id,
      supplierId: junctionData?.supplier?.id,
    };

    try {
      const res = await purchaseService.startPurchaseJunction(data);
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsOpen(false);
        refetch();
      } else {
        Toast("error", res?.data?.message);
      }
    } catch (error) {
      Toast("error", "Bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("purchaseFile", junctionData?.purchaseFile);
    setValue("secondPurchaseFile", junctionData?.secondPurchaseFile);
    setPriceValues(junctionData?.incomingUnit);
  }, [junctionData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col min-w-[365px] max-w-[365px] w-[365px]">
      <p className="text-sm font-normal text-secondary-600">{t("product:addThePurchase")}</p>
      <div className="flex flex-col gap-y-4">
        <div className="flex w-full pt-5">
          {junctionData?.plannedJunctionRef ? (
            <MaterialSupplyReceivedCard data={junctionData} isModal={true} />
          ) : (
            <MaterialSupplyEventCardPlannedTriple data={junctionData} isModal={true} />
          )}
        </div>
        <div className="h-[1px] w-full bg-[#E4E7EC]"></div>
        <div className="flex flex-col gap-y-6 w-full pb-5">
          <QtyInput
            theme={"product"}
            label={t("product:receivingQuantity")}
            qtyType={junctionData?.rawMaterial ? junctionData?.rawMaterial?.unit : t("product:pcs")}
            onChange={(e) => {
              setPriceValues(e?.target?.value);
            }}
            value={priceValues}
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <UploadProfile title={t("product:controlForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
            )}
            name="purchaseFile"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <UploadProfile title={t("product:approvalForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
            )}
            name="secondPurchaseFile"
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-3">
        <span>
          <Button
            colorType={"primary-product"}
            label={loading ? <Loading color="#fff" secondary="#fff" size={22} /> : t("product:completePurchase")}
            size={"lg"}
            type={"submit"}
            disabled={loading}
          />
        </span>
        <span>
          <Button onClick={() => !loading && setIsOpen(false)} colorType={"secondary-gray"} label={t("buttons:stop")} size={"lg"} disabled={loading} />
        </span>
      </div>
    </form>
  );
};

export default NewPurchaseFinishModal;
