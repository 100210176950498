import React, { useState } from "react";
import {
  DeclinedQuantityIcon,
  EventsIcon,
  NewAcceptedIcon,
  NewRejectedIcon,
  PlannedIcon,
  QuantityGreenIcon,
  ReadyIcon,
} from "../../../components/new-cards/card-icons";
import { ThreeDotsIcon } from "../../../assets/icons/machineIcons";
import { EditIcon } from "../../../assets/icons/departmentIcon";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { useTranslation } from "react-i18next";
const FlexibleProductCard = ({ onClick, item }) => {
  const {t} = useTranslation()
  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
    }
  };
  return (
    <>
      <div
        onClick={onClick}
        className={`flex w-[312px] h-[112px] flex-col border items-start cursor-pointer rounded-t-[4px] rounded-b-lg border-secondary-300 border-t-0 bg-white relative group `}
      >
        <div style={{ backgroundColor: "#D6BBFB" }} className="flex w-full min-h-[4px] max-h-[4px] h-1 rounded-t-[8px]"></div>
        {item?.name?.length == 1 ? (
          <>
            {item?.name?.map((b,i) => {
              return (
                <div key={i} className="flex flex-row items-center w-[100%]  justify-between pr-1 py-2 pl-3">
                  <div className="flex flex-row items-center gap-x-0.5 h-5 ">
                    {b?.processType == "external" ? (
                      <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                        <p className="text-sm font-semibold text-secondary-700">{"HİZ"}</p>
                      </div>
                    ) : (
                      <div className="px-1 ml-1 border h-full w-fit rounded bg-[#F2F4F7]">
                        <p className="text-sm font-semibold text-secondary-700">{item?.processType ? operationTypes(b?.processType) : "--"}</p>
                      </div>
                    )}
                    <div className=" bg-white max-w-[230px] ml-1 rounded-r-[8px]">
                      <p className="font-semibold text-sm text-secondary-700  truncate">{b?.name}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="flex flex-row items-center gap-x-2 pr-1 truncate py-1.5 pl-3 w-[305px] min-w-[305px] max-w-[305px] ">
            {item?.name?.map((a, index) => {
              return (
                <div key={index} className={`flex border items-center ${item?.name?.length == 2 ? "w-1/2 " : "w-1/3"}  h-6 gap-x-1 flex-row rounded`}>
                  <div className="px-1 bg-[#F2F4F7] w-fit border-r h-full flex items-center">
                    {a.processType === "external" ? (
                      <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                    ) : (
                      <p className="text-xs font-semibold text-secondary-700">{a?.processType ? operationTypes(a?.processType) : "--"}</p>
                    )}
                  </div>
                  <div className="bg-white truncate px-1">
                    <p className="font-semibold text-xs text-secondary-700  truncate">{a?.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className="flex flex-row items-center w-full">
          <div className="flex flex-col px-3 w-[40%]">
            <div className="flex flex-row items-center w-full  border-b pb-0.5">
              <ReadyIcon />
              <p className="font-normal text-secondary-600 ml-1 text-[10px]">{t("product:ready")}</p>
              <div className="ml-auto">
                <p className="text-xs font-medium max-w-[50px]  truncate text-secondary-700">{item?.ready || "0"}</p>
              </div>
            </div>
            <div className="flex flex-row items-center w-full border-b pb-0.5  pt-[4px]">
              <PlannedIcon />
              <p className="font-normal text-secondary-600 ml-1 text-[10px]">{t("reports:planned")}</p>
              <div className="ml-auto">
                <p className="text-xs font-medium max-w-[40px] truncate text-secondary-700">{item?.planned || "0"}</p>
              </div>
            </div>
            <div className="flex flex-row items-center w-full pb-0.5 pt-[4px]">
              <EventsIcon />
              <p className="font-normal text-secondary-600 ml-1 text-[10px]">{t("product:events")}</p>
              <div className="ml-auto">
                <p className="text-xs font-medium text-secondary-700">{item?.events || "0"}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center w-[60%] rounded-tl-[4px] border border-r-0 border-b-0 border-[#E4E7EC]">
            <div className="flex flex-col items-start border-r w-1/2 border-r-[#E4E7EC]">
              <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                <p className="text-xs font-medium text-secondary-700">{t("product:cycleTime")}</p>
              </div>
              <div className="flex px-1.5 py-[3px] flex-row items-center w-full gap-x-2">
                <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                <p className="font-medium text-secondary-600 ml-auto truncate text-xs">{legacyFormatSeconds(item?.avgEstimatedTime?.raw?.toFixed(0))}</p>
              </div>
              <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full gap-x-2">
                <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                <p className="font-medium text-[#079455]  ml-auto truncate text-xs">{legacyFormatSeconds(item?.estimatedTime)}</p>
              </div>
            </div>

            <div className="flex flex-col items-center  w-1/2">
              <div className="px-1.5 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                <p className="text-xs font-medium text-secondary-700">{t("product:qualityQty")}</p>
              </div>
              <div className="flex px-1.5 py-[3px] flex-row items-center  gap-x-0.5">
                <NewAcceptedIcon />
                <p className="font-medium text-secondary-600 text-xs">{item?.finishedPiece || 0}</p>
              </div>
              <div className="flex px-1.5 py-[1.5px] flex-row items-center gap-x-0.5">
                <NewRejectedIcon />
                <p className="font-medium text-[#475467] text-xs">{item?.rejectedPiece || 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlexibleProductCard;
