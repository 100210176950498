import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon, MinusIcon } from "../../assets/icons/productIcons";
import { Button, CustomModal, Input, Loading } from "../../components";
import Badgets from "../../components/buttons/Badgets";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import { CheckIcon, ChevronDown } from "../../assets/icons/modalIcons";
import { legacyOrderService } from "../../services/legacy-new-order-service";
import { Toast } from "../../utils/toastify/toast";
import NewFlexOrderSelectedMaterial from "./modals/NewFlexOrderSelectedMaterial";
import NewOrderRawMaterialCard from "./cards/NewOrderRawMaterialCard";
import NewOrderOperationCard from "./cards/NewOrderOperationCard";

const NewFlexOrderThird = ({}) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { index } = useParams();
  let indexNumber = parseInt(index);
  const [selectedRawModal, setSelectedRawModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [rowProductsData, setRowProductsData] = useState([]);

  const SecondPageData = JSON.parse(sessionStorage.getItem("secondPageData"));

  const tableHeadItems = [t("product:startingProduct"), t("product:stockAmount"), t("product:plannedQuantitys"), t("product:requiredQuantity"), t("product:supplyType"), t("product:remove")];
  const firsTableHeadItems = [t("product:productss"), t("product:deadline"), t("product:quantityToBeSentProduct"), t("product:plannedQuantityy")];

  const getValueDivided = (material, item) => {
    const quantityPerPiece = material?.type === "raw" ? Number(item?.quantityUsedPerPiece || 1) : 1;
    const purchaseValue = material?.purchaseChecked ? Number(material?.purchaseValue || 0) : 0;
    const stockValue = Number(material?.stockValue || 0);

    return (purchaseValue + stockValue) / quantityPerPiece;
  };
  const totalPlannedQuantity = (item) => {
    const product = productData.find((prod) => prod.id === item.id && prod.deliveryDate === item.deliveryDate);
    if (!product || !product.materials) return 0;
    const total = product.materials.reduce((acc, material) => {
      return acc + getValueDivided(material, item);
    }, 0);

    return Math.round(total);
  };

  const isButtonDisabled = currentProduct?.items.some((item) => {
    const plannedQuantity = totalPlannedQuantity(item);

    return item.productionQuantity < plannedQuantity || item.productionQuantity !== plannedQuantity;
  });

  const onSubmit = async () => {
    setButtonLoading(true);
    const exceededMaterials = [];
    const allPurchaseRequests = [];
    SecondPageData.selectedProducts.forEach((productGroup) => {
      productGroup.items.forEach((item) => {
        const productEntry = productData.find((prod) => prod.id === item.id && prod.deliveryDate === item.deliveryDate);

        if (productEntry?.materials) {
          productEntry.materials.forEach((material) => {
            allPurchaseRequests.push({
              materialId: material.id,
              materialName: material.name,
              productName: item.name,
              stockValue: material.stockValue || 0,
              availableStock: material.type === "raw" ? material.quantity || 0 : material.activePiece || 0,
            });
          });
        }
      });
    });

    const groupedMaterials = allPurchaseRequests.reduce((acc, req) => {
      if (req.stockValue > 0) {
        if (!acc[req.materialId]) {
          acc[req.materialId] = {
            materialName: req.materialName,
            productName: req.productName,
            totalUsed: 0,
            availableStock: req.availableStock,
          };
        }

        acc[req.materialId].totalUsed += req.stockValue;
      }
      return acc;
    }, {});

    Object.values(groupedMaterials).forEach((group) => {
      if (group.totalUsed > group.availableStock) {
        exceededMaterials.push({
          materialName: group.materialName,
          productName: group.productName,
          totalUsed: group.totalUsed,
          availableStock: group.availableStock,
        });
      }
    });

    if (exceededMaterials.length > 0) {
      exceededMaterials.forEach((exceeded) => {
        Toast(
          "error",
          `${exceeded.materialName} için toplam kullanılmak istenen miktar (${exceeded.totalUsed}), mevcut stok miktarını (${exceeded.availableStock}) aşıyor. Ürün: ${exceeded.productName}`
        );
      });
      setButtonLoading(false);
      return;
    }

    const sendData = {
      systemOrderNo: SecondPageData?.orderCode,
      productType: SecondPageData?.productType,
      customer: SecondPageData?.customer?.id,
      customerOrderNo: SecondPageData?.customerOrderNo,
      products: SecondPageData?.selectedProducts?.flatMap((productGroup, groupIndex) => {
        return productGroup.items.map((item, itemIndex) => {
          const productDataEntry = productData.find((prod) => prod.id === item.id && prod.deliveryDate === item.deliveryDate);

          const purchaseRequests = productDataEntry?.materials?.map((material) => ({
            type: material?.step ? (material?.type === "internal" ? "semi_internal_product" : "semi_external_product") : "raw",
            productionQuantity: item?.productionQuantity * (parseInt(material?.quantityUsedPerPiece) || 1),
            stockQuantity: material?.stockValue || 0,
            purchaseQuantity: material?.purchaseValue || 0,
            typeId: material?.id,
          }));

          return {
            stockQuantity: item?.stockQuantity,
            currency: item?.currency,
            productionQuantity: item?.productionQuantity,
            waitingQuantity: item?.waitingQuantity,
            orderedQuantity: item?.orderedQuantity,
            customerStockCode: item?.customerStockCode,
            productPrice: item?.productPrice,
            deliveryDate: item?.deliveryDate,
            product: item?.id,
            purchaseRequest: purchaseRequests,
          };
        });
      }),
    };

    await legacyOrderService.flexNewOrder(sendData).then((res) => {
      if (res.data.code == 0) {
        Toast("success", res?.data?.message);
        sessionStorage.removeItem("firstPageData");
        sessionStorage.removeItem("secondPageData");
        navigate("/app/product/mrp/orders");
      } else {
        Toast("error", res?.data?.message);
      }
    });
    setButtonLoading(false);
  };
  const handleMaterialCheck = () => {
    const hasMaterials = currentProduct?.items.every((item) => {
      const productEntry = productData.find((product) => product.id === item.id && product.deliveryDate === item.deliveryDate);
      return productEntry && productEntry.materials && productEntry.materials.length > 0;
    });

    if (!hasMaterials) {
      Toast("error", `Lütfen ${currentProduct?.name} ürünü için en az bir materyal seçin.`);
      return false;
    }

    return true;
  };

  const goToNextProduct = () => {
    if (!handleMaterialCheck()) {
      return;
    }
    const totalItems = SecondPageData.selectedProducts.length;

    const nextIndex = indexNumber + 1;

    if (nextIndex < totalItems) {
      navigate(`/app/product/mrp/orders/new-order/third-page/${nextIndex}`);
    } else {
      onSubmit();
    }
  };

  const getCurrentProductMaterials = (id, deliveryDate) => {
    const currentProduct = productData.find((product) => product.id === id && product.deliveryDate === deliveryDate);
    return currentProduct?.materials || [];
  };

  const handleMaterialSelection = (selectedMaterial, productId, deliveryDate) => {
    setProductData((prev) => {
      const updatedProducts = prev.length > 0 ? [...prev] : [];
      const productIndex = updatedProducts.findIndex((product) => product.id === productId && product.deliveryDate === deliveryDate);

      if (productIndex !== -1) {
        const materials = updatedProducts[productIndex].materials || [];

        const isMaterialAlreadyAdded = materials.some((material) => material.id === selectedMaterial.id);

        if (isMaterialAlreadyAdded) {
          Toast("error", "Bu materyal zaten eklenmiş.");
        } else {
          materials.push(selectedMaterial);
          updatedProducts[productIndex].materials = materials;
        }
      } else {
        updatedProducts.push({
          id: productId,
          deliveryDate: deliveryDate,
          materials: [selectedMaterial],
        });
      }

      return updatedProducts;
    });
  };

  const updateRowValues = (productId, deliveryDate, rowIndex, field, value) => {
    setProductData((prev) => {
      const updatedProducts = [...prev];

      const productIndex = updatedProducts.findIndex((product) => product.id === productId && product.deliveryDate === deliveryDate);

      if (productIndex !== -1) {
        const product = updatedProducts[productIndex];
        const material = product.materials[rowIndex];

        if (field === "stockValue") {
          material.stockValue = Number(value || 0);
        } else if (field === "purchaseValue") {
          material.purchaseValue = Number(value || 0);
        } else if (field === "stockChecked") {
          material.stockChecked = value;

          if (!value) {
            material.stockValue = "";
          }
        } else if (field === "purchaseChecked") {
          material.purchaseChecked = value;

          if (!value) {
            material.purchaseValue = "";
          }
        }
        updatedProducts[productIndex].materials[rowIndex] = material;
      }

      return updatedProducts;
    });
  };

  const removeRow = (productId, deliveryDate, rowIndex) => {
    setProductData((prev) => {
      const updatedProducts = [...prev];

      const productIndex = updatedProducts.findIndex((product) => product.id === productId && product.deliveryDate === deliveryDate);

      if (productIndex !== -1) {
        updatedProducts[productIndex].materials = updatedProducts[productIndex].materials.filter((_, index) => index !== rowIndex);
      }

      return updatedProducts;
    });
  };

  useEffect(() => {
    if (SecondPageData?.selectedProducts?.length > 0) {
      if (indexNumber < 0 || indexNumber >= SecondPageData.selectedProducts.length) {
        navigate(`/app/product/mrp/orders/new-order/third-page/0`);
        return;
      }
      const groupedProducts = SecondPageData.selectedProducts[indexNumber];
      setCurrentProduct({
        ...groupedProducts,
        items: groupedProducts.items,
      });
    }
  }, [indexNumber]);

  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];

  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };

  return (
    <>
      <div className="flex flex-col w-full gap-y-8 h-[95vh] overflow-y-auto overflow-x-hidden scrollbar-hide">
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
          <p className="text-[#101828] font-semibold text-2xl">{t("product:createNewOrder")}</p>
        </div>

        <div className="flex flex-col gap-y-6 w-full h-full">
          <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
            <div className="flex flex-col gap-y-1">
              <span className="flex gap-x-2">
                <p className="text-[#101828] font-semibold text-lg">{t("product:supplyForm")}</p>
                <Badgets colorType={"fill-warning"} size={"md"} label={`${indexNumber + 1}/${SecondPageData?.selectedProducts?.length}`} />
              </span>
              <p className="text-[#344054] font-normal text-sm">{t("product:selectTheSupply")}</p>
            </div>
            <div className="flex gap-x-3 max-h-[40px] min-h-[40px] h-10 relative">
              <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                <Button size={"md"} colorType={"secondary-gray"} label={t("buttons:previous")} onClick={() => navigate(-1)} />
              </span>
              <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                <Button
                  size="md"
                  disabled={buttonLoading || isButtonDisabled}
                  colorType="primary-product"
                  label={buttonLoading ? <Loading color="#fff" secondary="#fff" size={22} /> : t("buttons:save")}
                  onClick={goToNextProduct}
                />
              </span>
            </div>
          </div>
          {currentProduct?.items.map((item, itemIndex) => {
            const plannedQuantity = totalPlannedQuantity(item);
            return (
              <>
                {/* İLK TABLO */}
                <div className="flex flex-col w-full min-h-[170px] max-h-[170px] h-[170px] border rounded-xl border-[#E4E7EC]">
                  <div className="flex w-full min-h-[56px] max-h-[56px] h-[56px] justify-between bg-white rounded-t-xl items-center px-6 py-4 border-b border-[#E4E7EC]">
                    <div className="flex gap-x-2 items-center">
                      <img
                        src={generateFileUrl(SecondPageData?.customer?.image)}
                        className="min-w-[32px] w-[32px] h-[24px] rounded-sm border border-[#0000001A]"
                      />
                      <p className="text-[#101828] font-medium text-sm">{SecondPageData?.customer?.name || "--"}</p>
                    </div>
                    <div className="flex w-fit px-3 gap-x-6">
                      <div className="flex gap-x-2 items-center">
                        <CalendarIconNew />
                        <p className="text-[#475467] text-sm">{t("product:orderGeneratedDate")}</p>
                        <p className="text-[#B54708] font-semibold text-md">{moment().format("DD-MM-YYYY")}</p>
                      </div>
                      <div className="flex gap-x-2 items-center">
                        <OrderIcon />
                        <p className="text-[#475467] text-sm">{t("product:orderNo")}</p>
                        <p className="text-[#B54708] font-semibold text-md">{SecondPageData?.orderCode || "--"}</p>
                      </div>
                      <div className="flex gap-x-2 items-center">
                        <OrderIcon />
                        <p className="text-[#475467] text-sm">{t("product:customerOrderNo")}</p>
                        <p className="text-[#B54708] font-semibold text-md">19284294586</p>
                      </div>
                    </div>
                  </div>
                  <div className={`flex flex-col w-full h-full`}>
                    <div className="flex max-h-[34px] min-h-[34px] w-full sticky top-0 bg-[#F9FAFB] z-50">
                      {firsTableHeadItems.map((item, i) => (
                        <div
                          key={i}
                          className={`flex border-b px-6 py-2 border-r border-[#E4E7EC] items-center last:border-r-0 ${
                            i === 0 ? "justify-start min-w-[328px]" : "justify-center"
                          } w-1/4`}
                        >
                          <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                        </div>
                      ))}
                    </div>
                    <div className="flex w-full items-center h-[80px] min-h-[80px]">
                      {/* tablo 1.kolon */}
                      <div className="flex items-center gap-x-2 py-4 px-6 border-r h-full justify-start w-[25%] min-w-[328px]">
                        <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(item?.image)} />
                        <div className="flex flex-col justify-center">
                          <p className="text-[#101828] font-semibold text-md ml-1">{item?.name || "--"}</p>
                          <span className="bg-[#FFFAEB] w-fit px-1 py-[3px]">
                            <p className="text-[#B54708] text-xs font-medium">{t("product:stock")}: {item?.stockCode || "--"}</p>
                          </span>
                        </div>
                      </div>

                      {/* tablo 2.kolon */}
                      <div className="flex flex-col h-full px-6 py-4  items-center justify-center border-r w-[25%]">
                        <p className="text-md text-[#344054] font-medium">{moment(item?.deliveryDate)?.format("DD.MM.YYYY") || "--/--/--"}</p>
                        <p className="text-xs text-[#667085]">{moment(item?.deliveryDate)?.format("dddd")}</p>
                      </div>
                      {/* tablo 3.kolon */}
                      <div className="flex flex-col h-full px-6 py-4  items-center justify-center border-r w-[25%]">
                        <p className="text-md text-[#344054] font-medium">{item?.productionQuantity || "--"}</p>
                        <p className="text-xs text-[#667085]">{t("product:pieces")}</p>
                      </div>
                      {/* tablo 4.kolon */}
                      <div className="flex flex-col h-full px-6 py-4  items-center justify-center border-r w-[25%]">
                        {item.productionQuantity < plannedQuantity || item.productionQuantity !== plannedQuantity ? (
                          <div className="flex items-center justify-center flex-col">
                            <p className="text-md text-red-500 font-medium animate-bounce">{plannedQuantity}</p>
                            <p className="text-xs text-red-500 font-medium">{t("product:thePlannedCannot")}</p>
                          </div>
                        ) : (
                          <div className="flex flex-col">
                            <p className="text-md text-[#344054] font-medium">{plannedQuantity}</p>
                            <p className="text-xs text-[#667085]">{t("product:pieces")}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* İKİNCİ TABLO */}
                <div className="flex w-full h-full border rounded-xl border-[#E4E7EC]">
                  <div className={`flex flex-col w-full h-[50vh] overflow-y-auto overflow-x-hidden scrollbar-hide`}>
                    <div className="flex max-h-[34px] min-h-[34px] w-full sticky top-0 bg-[#F9FAFB] z-50 rounded-t-xl">
                      {tableHeadItems.map((item, i) => (
                        <div
                          key={i}
                          className={`flex border-b px-6 py-2 border-r border-[#E4E7EC] items-center last:border-r-0 ${
                            i === 0
                              ? "w-[27%] min-w-[27%] justify-start"
                              : i === 1 || i === 2 || i === 3
                              ? "w-[9%] min-w-[9%] justify-center"
                              : i === 4
                              ? "w-[42%] min-w-[42%] justify-center"
                              : "w-[4%] min-w-[4%] flex-none justify-center"
                          }`}
                        >
                          <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                        </div>
                      ))}
                    </div>
                    {getCurrentProductMaterials(item.id, item.deliveryDate).map((material, materialIndex) => {
                      return (
                        <div className="flex w-full items-center h-[144px] min-h-[144px] border-b">
                          {/* tablo 1.kolon */}
                          <div className="flex items-center py-4 px-6 border-r h-full justify-start w-[27%] min-w-[27%]">
                            {material?.type === "raw" ? (
                              <NewOrderRawMaterialCard item={material} data={item} />
                            ) : material?.step ? (
                              <NewOrderOperationCard item={material} />
                            ) : (
                              <div
                                onClick={() => {
                                  setRowProductsData(item);
                                  setSelectedRawModal(true);
                                }}
                                className="flex flex-col gap-y-1 w-full h-full border-[2px] border-dashed shadow-xs border-[#D0D5DD] rounded-lg justify-center items-center cursor-pointer"
                              >
                                <AddPlusIcon />
                                <p className="text-[#475467] text-md">{t("product:addSemiProduct")}</p>
                              </div>
                            )}
                          </div>
                          {/* tablo 2.kolon */}
                          <div className="flex flex-col h-full py-3 items-center justify-center border-r w-[9%] min-w-[9%]">
                            <p className="text-md text-[#344054] font-medium">
                              {material?.type === "raw"
                                ? (material?.quantity || 0) % 1 === 0
                                  ? material?.quantity || 0
                                  : (material?.quantity || 0)?.toFixed(2)
                                : (material?.activePiece || 0) % 1 === 0
                                ? material?.activePiece || 0
                                : (material?.activePiece || 0)?.toFixed(2)}
                            </p>
                            <p className="text-xs text-[#667085]">{material?.type === "raw" ? getUnitLabel(item?.rawMaterial?.unitType) : t("product:pieces")}</p>
                          </div>
                          {/* tablo 3.kolon */}
                          <div className="flex flex-col h-full py-3 items-center justify-center border-r w-[9%] min-w-[9%]">
                            <p className="text-md text-[#344054] font-medium">
                              {((Number(material?.stockValue || 0) + Number(material?.purchaseValue || 0)) % 1 === 0
                                ? Number(material?.stockValue || 0) + Number(material?.purchaseValue || 0)
                                : (Number(material?.stockValue || 0) + Number(material?.purchaseValue || 0)).toFixed(2)) || 0}
                            </p>
                            <p className="text-xs text-[#667085]">{material?.type === "raw" ? getUnitLabel(item?.rawMaterial?.unitType) : t("product:pieces")}</p>
                          </div>
                          {/* tablo 4.kolon */}
                          <div className="flex flex-col h-full py-3 items-center justify-center border-r w-[9%] min-w-[9%]">
                            <p className="text-md text-[#344054] font-medium">
                              {material?.type === "raw"
                                ? ((item?.quantityUsedPerPiece || 1) * item?.productionQuantity) % 1 === 0
                                  ? (item?.quantityUsedPerPiece || 1) * item?.productionQuantity
                                  : ((item?.quantityUsedPerPiece || 1) * item?.productionQuantity).toFixed(2)
                                : item?.productionQuantity % 1 === 0
                                ? item?.productionQuantity
                                : item?.productionQuantity.toFixed(2)}
                            </p>
                            <p className="text-xs text-[#667085]">{material?.type === "raw" ? getUnitLabel(item?.rawMaterial?.unitType) : t("product:pieces")}</p>
                          </div>

                          {/* tablo 5.kolon */}
                          <div className="flex gap-x-6 h-full px-6 py-4  items-center justify-center border-r w-[42%] min-w-[42%]">
                            <div className="flex flex-col gap-y-3 w-1/2">
                              <div
                                onClick={() => {
                                  if ((material?.type === "raw" ? material?.quantity : material?.activePiece) < 1) {
                                    Toast("error", "Stok miktarı 0'dan büyük olmalıdır.");
                                  } else {
                                    updateRowValues(item.id, item.deliveryDate, materialIndex, "stockChecked", !material?.stockChecked);
                                  }
                                }}
                                className={`flex gap-x-2 items-center  w-fit `}
                              >
                                <div
                                  className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                                    material?.stockChecked ? "bg-[#DC6803] border-[#DC6803] text-[#fff]" : "border-[#D0D5DD]"
                                  }`}
                                >
                                  {material?.stockChecked && <CheckIcon />}
                                </div>
                                <div className="text-secondary-500 font-normal text-sm">{t("product:bringFromStock")}</div>
                                <span className="group flex relative">
                                  <HelpCircleIcon />
                                  <div className="hidden absolute min-w-[293px] max-w-[293px] w-[293px] min-h-[52px] max-h-[52px] h-[52px] group-hover:flex bottom-[26px] -left-[136px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                                    <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:enterTheDeducted")}</p>
                                  </div>
                                  <span className="hidden absolute group-hover:flex bottom-[20px]">
                                    <TriangleTooltipIcon />
                                  </span>
                                </span>
                              </div>
                              <div className="flex w-full h-full">
                                <Input
                                  icon={<QuantityIcon />}
                                  theme="product"
                                  value={material?.stockValue || ""}
                                  onChange={(e) => updateRowValues(item.id, item.deliveryDate, materialIndex, "stockValue", e.target.value)}
                                  disabled={!material?.stockChecked}
                                  type={"number"}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col gap-y-3 w-1/2">
                              <div
                                onClick={() => {
                                  updateRowValues(item.id, item.deliveryDate, materialIndex, "purchaseChecked", !material?.purchaseChecked);
                                }}
                                className="flex gap-x-2 items-center cursor-pointer w-fit"
                              >
                                <div
                                  className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                                    material?.purchaseChecked ? "bg-[#DC6803] border-[#DC6803] text-[#fff]" : "border-[#D0D5DD]"
                                  }`}
                                >
                                  {material?.purchaseChecked && <CheckIcon />}
                                </div>
                                <div className="text-secondary-500 font-normal text-sm">{t("product:markPurchase")}</div>
                                <span className="group flex relative">
                                  <HelpCircleIcon />
                                  <div className="hidden absolute min-w-[370px] max-w-[370px] w-[370px] min-h-[70px] max-h-[70px] h-[70px] group-hover:flex bottom-[26px] -left-[175px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                                    <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:youCanSendTheEntire")}</p>
                                  </div>
                                  <span className="hidden absolute group-hover:flex bottom-[20px]">
                                    <TriangleTooltipIcon />
                                  </span>
                                </span>
                              </div>
                              <div className="flex w-full h-full">
                                <Input
                                  icon={<QuantityIcon />}
                                  theme="product"
                                  value={material?.purchaseValue || ""}
                                  onChange={(e) => updateRowValues(item.id, item.deliveryDate, materialIndex, "purchaseValue", e.target.value)}
                                  disabled={!material?.purchaseChecked}
                                  type={"number"}
                                />
                              </div>
                            </div>
                          </div>
                          {/* tablo 6.kolon */}
                          <div className="flex flex-col h-full py-3 items-center justify-center border-r w-[4%] max-w-[4%] min-w-[4%]">
                            <Button
                              colorType={"secondary-error"}
                              size={"sm"}
                              iconLeft={<MinusIcon />}
                              onClick={() => removeRow(item.id, item.deliveryDate, materialIndex)}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <div className="flex w-full items-center h-[144px] min-h-[144px] border-b">
                      <div className="flex items-center py-4 px-6 border-r h-full justify-start w-[27%] min-w-[27%]">
                        <div
                          onClick={() => {
                            setRowProductsData(item);
                            setSelectedRawModal(true);
                          }}
                          className="flex flex-col gap-y-1 w-full h-full border-[2px] border-dashed shadow-xs border-[#D0D5DD] rounded-lg justify-center items-center cursor-pointer"
                        >
                          <AddPlusIcon />
                          <p className="text-[#475467] text-md">{t("product:addRawMaterialIntermediate")} </p>
                        </div>
                      </div>
                      <div className="flex flex-col h-full border-r w-[9%] min-w-[9%]"></div>
                      <div className="flex flex-col h-full border-r w-[9%] min-w-[9%]"></div>
                      <div className="flex flex-col h-full border-r w-[9%] min-w-[9%]"></div>
                      <div className="flex h-full border-r w-[42%] min-w-[42%]"></div>
                      <div className="flex flex-col h-full border-r w-[4%] max-w-[4%] min-w-[4%]"></div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <CustomModal
        isOpen={selectedRawModal}
        setIsOpen={setSelectedRawModal}
        modalTitle={t("product:addRawMaterialIntermediate")}
        subTitle={t("product:addTheNecessaryProduct")}
        width={430}
      >
        <NewFlexOrderSelectedMaterial
          setSelectRawCardModal={setSelectedRawModal}
          handleRawMaterialSelection={handleMaterialSelection}
          rowProductsData={rowProductsData}
        />
      </CustomModal>
    </>
  );
};

export default NewFlexOrderThird;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

const HelpCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17461_3132)">
        <path
          d="M6.06016 6.00016C6.2169 5.55461 6.52626 5.1789 6.93347 4.93958C7.34067 4.70027 7.81943 4.61279 8.28495 4.69264C8.75047 4.77249 9.17271 5.01451 9.47688 5.37585C9.78105 5.73718 9.94753 6.19451 9.94683 6.66683C9.94683 8.00016 7.94683 8.66683 7.94683 8.66683M8.00016 11.3335H8.00683M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z"
          stroke="#98A2B3"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17461_3132">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TriangleTooltipIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9" viewBox="0 0 17 9" fill="none">
      <path
        d="M8.5 0.49993L14.5711 0.49993C15.462 0.49993 15.9081 1.57707 15.2782 2.20704L9.20711 8.2781C8.81658 8.66863 8.18342 8.66863 7.79289 8.2781L1.72183 2.20704C1.09186 1.57707 1.53803 0.49993 2.42893 0.49993L8.5 0.49993Z"
        fill="#0C111D"
      />
    </svg>
  );
};

const AddPlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M15.9998 6.6665V25.3332M6.6665 15.9998H25.3332" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const CalendarIconNew = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 8.33332H2.5M13.3333 1.66666V4.99999M6.66667 1.66666V4.99999M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8212 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7335 17.5 14.3333V7.33332C17.5 5.93319 17.5 5.23313 17.2275 4.69835C16.9878 4.22794 16.6054 3.84549 16.135 3.60581C15.6002 3.33332 14.9001 3.33332 13.5 3.33332H6.5C5.09987 3.33332 4.3998 3.33332 3.86502 3.60581C3.39462 3.84549 3.01217 4.22794 2.77248 4.69835C2.5 5.23313 2.5 5.93319 2.5 7.33332V14.3333C2.5 15.7335 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const OrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6673 8.74999V5.66666C16.6673 4.26653 16.6673 3.56646 16.3948 3.03168C16.1552 2.56128 15.7727 2.17882 15.3023 1.93914C14.7675 1.66666 14.0674 1.66666 12.6673 1.66666H7.33398C5.93385 1.66666 5.23379 1.66666 4.69901 1.93914C4.2286 2.17882 3.84615 2.56128 3.60647 3.03168C3.33398 3.56646 3.33398 4.26653 3.33398 5.66666V14.3333C3.33398 15.7335 3.33398 16.4335 3.60647 16.9683C3.84615 17.4387 4.2286 17.8212 4.69901 18.0608C5.23379 18.3333 5.93385 18.3333 7.33398 18.3333M10.0007 7.49999H6.66732M11.6673 4.99999H6.66732M8.33398 9.99999H6.66732M9.83772 12.6321L12.8562 10.9454C12.9984 10.866 13.0694 10.8263 13.1447 10.8107C13.2114 10.7969 13.2801 10.7968 13.3468 10.8105C13.4221 10.826 13.4933 10.8656 13.6355 10.9447L16.6673 12.6321M9.83772 12.6321L13.2457 14.531M9.83772 12.6321V15.9548C9.83772 16.1254 9.83772 16.2108 9.86279 16.2869C9.88497 16.3543 9.92123 16.4162 9.96917 16.4685C10.0234 16.5276 10.0978 16.5693 10.2466 16.6527L13.2457 18.3333M16.6673 12.6321L13.2457 14.531M16.6673 12.6321V15.9539C16.6673 16.1248 16.6673 16.2103 16.6422 16.2866C16.6199 16.3541 16.5835 16.416 16.5355 16.4683C16.4811 16.5275 16.4065 16.5691 16.2572 16.6524L13.2457 18.3333M13.2457 14.531V18.3333"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
