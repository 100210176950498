import React, { useState } from "react";
import { ClockIcon, DateCardIcon, DeleteIcon, DotsVerticalIcon, EquipmentEditIcon, FinishJunctionIcon, FlexProductIcon, PieceIcon, QualityDetailIcon } from "../../card-icons";
import NoAvatar from "../../../avatar/NoAvatar";
import CustomImage from "../../../CustomImage";
import { EquipmentDeleteIcon, NoEquIcon, ProductDetailIcon } from "../../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import AlertModal from "../../../alert-Modal/Alert-Modal";
import CustomModal from "../../../modal/CustomModal";
import JunctionUpdate from "../../../modal/JunctionUpdate";
import Badgets from "../../../buttons/Badgets";
import { Tooltip } from "react-tooltip";
import { secondToHoursAndMinute2 } from "../../../../utils/secondToHoursAndMinute";
import { junctionService } from "../../../../services/junction.service";
import { Toast } from "../../../../utils/toastify/toast";
import QualityControlForm from "../../../modal/QualityControlForm";
const SupplyQualityWaitingCard = ({ data, refetch, operation, setIsOpenForm, setJunctionId }) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isJunction, setIsJunction] = useState(false);
  const [isJunctionUpdate, setIsJunctionUpdate] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const navigate = useNavigate();
  const menuOptions = [
    {
      label: t("product:editJunction"),
      value: "editJunction",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:qualityControlForm"),
      value: "qualityControl",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];
  const onDeleted = async () => {
    await junctionService.deleteJunction(data?.id, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data?.id);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  return (
    <>
      <div
        className={`flex w-full flex-col border border-t-success-500 border-t-4 items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
      >
        <div className="flex flex-row items-center w-full gap-x-2 pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-2 w-[84%] min-w-[84%]">
            {data?.supplier?.image ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${t("product:supplierName")} : ${data?.supplier?.name} `}
              >
                <CustomImage
                  borderRadius={2}
                  src={data?.supplier?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${t("product:supplierName")} : ${data?.supplier?.name} `}
                className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border text-white text-xl select-none flex items-center justify-center bg-[#DC6803] border-[#DC6803]"
              >
                {data.supplier?.name.charAt(0).toUpperCase() || "No Order"?.charAt(0).toUpperCase()}
              </div>
            )}

            <div className="flex flex-col items-start gap-y-1">
            <div className="flex flex-row items-center gap-x-1 w-full">
                {data?.productType === "flex" ? (
                  <>
                    <FlexProductIcon />
                    <p className="text-sm font-semibold text-secondary-900 w-[330px] truncate">{data?.supplier?.name || "--"}</p>
                  </>
                ) : (
                  <p className="text-sm font-semibold text-secondary-900 w-[330px] truncate">{data?.supplier?.name || "--"}</p>
                )}
              </div>
              
              <div className="flex flex-row items-center gap-x-3">
                <div className="flex flex-row items-center">
                  <p className="text-xs font-normal  text-secondary-600">{t("product:orderNo")} :</p>
                  <p className="text-xs font-normal ml-1 w-[144px] truncate text-secondary-600">{data?.job?.orderNo || "--"}</p>
                </div>
              </div>
            </div>
          </div>
          {data?.user?.avatar ? (
            <div className="cursor-pointer ml-auto">
              <CustomImage
                src={data?.user?.avatar}
                style={{
                  width: 48,
                  height: 48,
                  flexDirection: "column",
                  borderRadius: 100,
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div className="cursor-pointer ml-auto">
              <NoAvatar size={48} fontSize={22} name={data?.user?.name} lastName={data?.user?.lastName} color={"#B54708"} />
            </div>
          )}
        </div>
        <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB]  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
          <div className="flex flex-row items-center">
            <PieceIcon />
            <p className="text-xs font-medium ml-1 text-secondary-600">{data?.metadata?.madeWorkCount || "--"}</p>
            <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:pcs")}</p>
          </div>
          <div className="flex flex-row items-center">
            <DateCardIcon />
            <p className="text-xs font-medium ml-1 text-secondary-600">{moment(data?.endDate).format("DD/MM/YYYY")}</p>
            <p className={`text-xxs text-secondary-600 ml-0.5  font-normal`}>{t("product:endDates")}</p>
          </div>
          <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 pl-2 pr-1 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
            <p className="text-xxs text text-secondary-600 font-semibold">{t("product:qualityNew")}</p>
            <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded-[2px]">
              <p className="text-xxs font-semibold text-white"> %{operation?.operation?.externalOperation?.qualityPercent || "0"}</p>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {menuOptions?.map((item, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();

                    if (item.value === "delete") {
                      setIsAlert(true);
                    }

                    if (item.value === "qualityControl") {
                      setJunctionId(data?.id);
                      setIsOpenForm(true);
                    }
                    if (item.value === "editJunction") {
                      setIsJunctionUpdate(true);
                    }

                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                  }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
        <Tooltip className="z-[99999]" id={`${data?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunctionUpdate}
        setIsOpen={setIsJunctionUpdate}
        onClose={() => {
          setIsJunctionUpdate(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={data?.id}
            onClose={() => {
              setIsJunctionUpdate(false);
            }}
            refetch={refetch}
          />
        }
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <QualityControlForm
            refetch={refetch}
            setIsOpen={setIsJunction}
            onClose={() => {
              setIsJunction(false);
            }}
            junctionId={data?.id}
          />
        }
      />
    </>
  );
};

export default SupplyQualityWaitingCard;
