import React, { useState  , useEffect} from "react";
import { Button, Input } from "../../../components";
import TextArea from "../../../components/inputs/TextArea";
import { useTranslation } from "react-i18next";
import { purchaseService } from "../../../services/purchase.service";
import { Toast } from "../../../utils/toastify/toast";

const AddSupplierGroupModal = ({ closeModal , refetch , selectedSupGroup , setSelectedGroup }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleSave = async () => {
    const body = { name, description };
    await purchaseService.createSupplierGroup(body).then((res) => {
      if (res.data.code == 0) {
        Toast("success", res?.data?.message);
        refetch()
      } else {
        Toast("error", res?.data?.message);
      }
      closeModal();
    });
  };

  

  useEffect(() => {
    if (selectedSupGroup?.id) {
        setName(selectedSupGroup?.name)
        setDescription(selectedSupGroup?.description)
    }
  }, [])
  


  const handleUpdate = async () =>{
    const body = { name, description };
    await purchaseService.updateSupplierGroup(selectedSupGroup.id , body).then((res)=>{
      if (res.data.code == 0) {
        Toast("success", res?.data?.message);
        
        //yorumdan cikarilacak
        // setSelectedGroup('all')
        refetch()
        closeModal();
      } else {
        Toast("error", res?.data?.message);
      }
      closeModal();
    });
    
  }


  return (
    <div className="w-[352px] flex flex-col gap-4 mt-4">
      <Input
        label={t("group:groupName")}
        value={name}
        onChange={({ target }) => setName(target?.value)}
        theme={"product"}
      />
      <TextArea
        label={t("product:description")}
        value={description}
        theme={"product"}
        onChange={({ target }) => setDescription(target?.value)}
      />
      <div className="w-full h-11 flex gap-3">
        <Button
          label={t("buttons:cancel")}
          colorType={"secondary-gray"}
          size={"sm"}
          width={152}
          onClick={closeModal}
        />
       
        {!selectedSupGroup?.id ? (
           <Button
           label={t("product:saveGroup")}
           colorType={"primary-product"}
           size={"sm"}
           width={200}
           onClick={handleSave}
         />
        ) : (
           <Button
           label={t("buttons:update")}
           colorType={"primary-product"}
           size={"sm"}
           width={200}
           onClick={handleUpdate}
         />
        )}
       
       
      </div>
    </div>
  );
};

export default AddSupplierGroupModal;
