import React from "react";
import { Button } from "../../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { productService } from "../../../services/product.service";
import { Toast } from "../../../utils/toastify/toast";
export default function CopyProductModal({ t, setIsOpen, label = "", text = "", stockId, copyData }) {
  const navigate = useNavigate();
  let location = useLocation();
  const copy = async () => {
    await productService.copyProduct(stockId, copyData).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        navigate("/app/product/mrp/stock/edit/" + res.data.id);
      } else {
        Toast("error", res.data.message);
      }
      setIsOpen(false);
    });
  };

  return (
    <>
      <div className="flex flex-col w-[544px]">
        <div className="flex  h-[120px]  overflow-y-scroll scrollbar-hide mt-4">
          <span className="text-sm text-secondary-600 font-normal">{text}</span>
        </div>
        <div className="w-full gap-x-3 flex items-center mt-4">
          <div className="flex w-1/2">
            <Button onClick={() => setIsOpen(false)} colorType={"secondary-gray"} label={t("buttons:cancel")} size={"md"} />
          </div>
          <div className="flex w-1/2">
            <Button onClick={() => copy()} colorType={"primary-product"} label={label} size={"md"} />
          </div>
        </div>
      </div>
    </>
  );
}
