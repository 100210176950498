import React from "react";
import { BreakModalsIcon } from "../new-cards/card-icons";

import { useTranslation } from "react-i18next";
import Button from "../buttons/Button";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";

const IsBreakModal = ({onClose,data,refetch}) => {
  const { t } = useTranslation();

  const BreaksEvent = async () => {
    await junctionService.startOrEndSubEvent('start',data?.id).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        onClose()
      } else {
        Toast("error", res.data.message);
        onClose()
      }
    });
  };


  return (
    <div className="flex flex-col items-center justify-center w-[400px]">
      <div className="flex items-center justify-center p-3 w-12 h-12 bg-[#F2F4F7] rounded-[6px]">
        <BreakModalsIcon />
      </div>
      <p className="font-semibold text-lg text-secondary-900 mt-4">{t("product:takeBreak")}</p>
      <p className="text-center text-secondary-500 font-normal text-sm w-[300px]">
        {t("product:theOngoing")}
      </p>
      <div className="flex flex-row items-center w-full gap-x-3 mt-8">
        <Button onClick={onClose} colorType={"secondary-gray"} size={"lg"} label={t("buttons:stop")} />
        <Button onClick={BreaksEvent} colorType={"primary-machine"} size={"lg"} label={t("product:takeBreak")} />
      </div>
    </div>
  );
};

export default IsBreakModal;
