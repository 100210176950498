import React, { useState, useEffect } from "react";
import { Button, Input } from "../../../components";
import TextArea from "../../../components/inputs/TextArea";
import { useTranslation } from "react-i18next";
import { purchaseService } from "../../../services/purchase.service";
import { Toast } from "../../../utils/toastify/toast";

const DeleteSupplierGroupModal = ({ closeModal, refetch, selectedSupGroup, setSelectedGroup }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  //new

  const [newGroupId, setNewGroupId] = useState("");
  const [customerGroups, setCustomerGroups] = useState([]);

  console.log("newGroupId", newGroupId);

  const getAllCustomerGroup = async () => {
    const data = await purchaseService.getSuppliersGroup().then((res) => {
      if (res?.data) {
        const filterData = res?.data?.filter((x) => x?.id != selectedSupGroup?.id);

        console.log("filterDAta", filterData, selectedSupGroup);
        setCustomerGroups(filterData);
      } else {
      }
    });
  };

  console.log("selectedSupGroup", selectedSupGroup);

  useEffect(() => {
    if (selectedSupGroup?.id) {
      setName(selectedSupGroup?.name);
      setDescription(selectedSupGroup?.description);
    }
    getAllCustomerGroup();
  }, []);

  const deleteGroup = async () => {
    await purchaseService.deleteAndMoveSupplierGroup(selectedSupGroup.id, newGroupId).then((res) => {
      if (res.data.code == 0) {
        Toast("success", res?.data?.message);

        //yorumdan cikarilacak
        // setSelectedGroup('all')
        refetch();
        closeModal();
      } else {
        Toast("error", res?.data?.message);
      }
      closeModal();
    });
  };

  return (
    <div className="w-[352px] flex flex-col gap-4 mt-4 ">
      <div className="w-full h-[40vh]  overflow-y-scroll scrollbar-hide px-1 py-1">
        {customerGroups?.map((e) => {
          return (
            <div
              onClick={() => {
                setNewGroupId(e);
              }}
              className={`w-full px-4 py-4 border-[1px] mb-4 rounded-md cursor-pointer text-md font-medium ${
                newGroupId.id == e.id ? "shadow-ring-product" : ""
              }`}
            >
              {e?.name}
            </div>
          );
        })}
      </div>
      <div className="w-full h-11 flex gap-3">
        <Button label={t("buttons:cancel")} colorType={"secondary-gray"} size={"sm"} width={152} onClick={closeModal} />

        <Button label={"Onayla"} colorType={"primary-product"} size={"sm"} width={200} onClick={deleteGroup} />
      </div>
    </div>
  );
};

export default DeleteSupplierGroupModal;
