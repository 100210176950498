import React, { useEffect, useState } from "react";
import { Button } from "../../../components";
import { orderService } from "../../../services/order.service";
import generateFileUrl from "../../../utils/generateFileUrl";
import moment from "moment";
import { Toast } from "../../../utils/toastify/toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DeleteOrderModal = ({ setIsOpen, orderData, refetch }) => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const [selectedOptions, setSelectedOptions] = useState([]);
  const tableHeadItems = [t("product:productNameStockCode"), t("product:orderQuantityss"), t("product:quantityInProduction"), t("product:manageOpenWork")];
  const tableHeadWidths = ["31%", "13%", "16%", "40%"];
  const [data, setData] = useState({});


  const handleData = async () => {
    const { data } = await orderService.getCustomerInfo(orderData?.id);
    setData(data);
  };

  const handleCheckboxChange = (id, option) => {
    setSelectedOptions((prev) => {
      const updated = prev.filter((item) => item.id !== id);
      return [...updated, { id, option }];
    });
  };


  const onSubmit = async () => {
    const body = {
      orderId: orderData?.id,
      details: selectedOptions?.map((item) => {
        return {
          id: item?.id,
          type: item?.option,
        };
      }),
    };
    await orderService.deleteAllOrder(body).then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsOpen(false);
        navigate("/app/product/mrp/orders");
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  const isSelected = (id, option) => {
    const selected = selectedOptions.find((item) => item.id === id);
    return selected?.option === option;
  };

  const isButtonDisabled = data?.details?.some((item) => {
    const selectedOption = selectedOptions.find((opt) => opt.id === item.id);

    if (item?.partial?.length === 0 || !item?.partial) {
      return false;
    } else {
      return !selectedOption;
    }
  });

  useEffect(() => {
    handleData();
  }, []);

  return (
    <div className="flex w-full flex-col h-full pt-4">
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full border-y border-[#E4E7EC] bg-[#F9FAFB] min-h-[26px] max-h-[26px] h-[26px]">
          {tableHeadItems.map((item, i) => (
            <div
              key={i}
              className={`flex py-1 last:border-0 border-r border-[#E4E7EC] min-w-[${tableHeadWidths[i]}] max-w-[${tableHeadWidths[i]}] w-[${tableHeadWidths[i]
                }] ${i === 0 ? "justify-start" : "justify-center"} ${i === 0 || i === 3 ? "px-6" : "px-3"}`}
            >
              <span className="text-[#344054] text-xs font-medium whitespace-nowrap">{item}</span>
            </div>
          ))}
        </div>
        <div className="flex flex-col w-full overflow-y-auto overflow-x-hidden scrollbar-hide h-[440px]">
          {data?.details?.map((item, i) => {
            const totalDesiredQuantity = item?.partial?.reduce((a, b) => (a?.desiredQuantity || 0) + (b.desiredQuantity || 0), 0);
            return (
              <div key={i} className="flex min-h-20 max-h-20 h-20 w-full border-b border-[#E4E7EC]">
                <div className="flex min-w-[31%] max-w-[31%] w-[31%] border-r px-6 py-4 h-full items-center justify-start gap-x-2">
                  <img
                    src={generateFileUrl(item?.product?.image)}
                    className="flex min-w-[64px] max-w-[64px] w-[64px] min-h-12 max-h-12 h-12 border border-[#0000001A] rounded"
                  />
                  <div className="flex flex-col w-[160px]">
                    <p className="text-[#101828] text-md font-semibold pl-1 truncate">{item?.product?.name}</p>
                    <div className="flex px-1 py-[3px] rounded bg-[#FFFAEB] max-w-[160px] w-fit">
                      <p className="text-[#B54708] text-xs font-medium truncate">{t("product:stock")}: {item?.product?.stockCode}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col min-w-[13%] max-w-[13%] w-[13%] border-r items-center justify-center py-[19px]">
                  <p className="text-[#344054] font-medium text-sm">{item?.orderedQuantity}</p>
                  <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                </div>
                <div className="flex flex-col min-w-[16%] max-w-[16%] w-[16%] border-r items-center justify-center py-[19px]">
                  <p className="text-[#344054] font-medium text-sm">{totalDesiredQuantity}</p>
                  <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                </div>
                <div className="flex flex-col gap-y-3 min-w-[40%] max-w-[40%] w-[40%] items-start justify-center px-4 py-[14px]">
                  {item?.partial?.length === 0 || !item?.partial ? (
                    <div onClick={() => handleCheckboxChange(item.id, "direct")} className="flex gap-x-2">
                      <span
                        className={`w-5 h-5 rounded-full flex justify-center items-center cursor-pointer ${isSelected(item.id, "direct") ? "bg-[#DC6803]" : "border border-[#D0D5DD]"
                          }`}
                      >
                        {isSelected(item.id, "direct") && <span className="w-2 h-2 rounded-full bg-white"></span>}
                      </span>
                      <p className="text-[#475467] text-sm">{t("buttons:delete")}</p>
                    </div>
                  ) : (
                    <>
                      <div onClick={() => handleCheckboxChange(item.id, "convert-to-stock")} className="flex gap-x-2">
                        <span
                          className={`w-5 h-5 rounded-full flex justify-center items-center cursor-pointer ${isSelected(item.id, "convert-to-stock") ? "bg-[#DC6803]" : "border border-[#D0D5DD]"
                            }`}
                        >
                          {isSelected(item.id, "convert-to-stock") && <span className="w-2 h-2 rounded-full bg-white"></span>}
                        </span>
                        <p className="text-[#475467] text-sm">{t("product:holdWorkOrders")}</p>
                      </div>
                      <div onClick={() => handleCheckboxChange(item.id, "close-send-stock")} className="flex gap-x-2">
                        <span
                          className={`w-5 h-5 rounded-full flex justify-center items-center cursor-pointer ${isSelected(item.id, "close-send-stock") ? "bg-[#DC6803]" : "border border-[#D0D5DD]"
                            }`}
                        >
                          {isSelected(item.id, "close-send-stock") && <span className="w-2 h-2 rounded-full bg-white"></span>}
                        </span>
                        <p className="text-[#475467] text-sm">{t("product:closeWorkOrders")}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex w-full pt-6 gap-x-3 border-t border-[#E4E7EC]">
        <Button colorType={"secondary-gray"} label={t("buttons:stop")} size={"lg"} onClick={() => setIsOpen(false)} />
        <Button
          colorType={"primary-error"}
          label={t("buttons:approveDelete")}
          size={"lg"}
          disabled={data?.details?.length > 0 ? (isButtonDisabled || selectedOptions?.length === 0) : false}
          onClick={() => onSubmit()}
        />
      </div>
    </div>
  );
};

export default DeleteOrderModal;
