import React, { useEffect, useState } from "react";
import { Button, Input, UploadProfile } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowLeftIcon } from "../../../assets/icons/productIcons";
import TextArea from "../../../components/inputs/TextArea";
import Selector from "../../../components/inputs/Selector";
import UnitPriceInput from "../../../components/inputs/UnitPriceInput";
import { flexProductService } from "../../../services/flex-product.service";
import { Toast } from "../../../utils/toastify/toast";
import { useQuery } from "@tanstack/react-query";
import { rawMaterialService } from "../../../services/raw-material.service";

const FlexibleMaterialCreator = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  const { t } = useTranslation();
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("TRY");
  const [priceValidate, setPriceValidate] = useState(null);

  const {
    data: supplierGroups,
    isLoading: isLoadingSupplierGroups,
    refetch: refetchSupplierGroups,
  } = useQuery({
    queryKey: ["supplierGroup-flex"],
    queryFn: async () => {
      return await rawMaterialService.supplierGroup();
    },
    retry: 0,
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["flex-raw-material", id],
    queryFn: async () => {
      return await flexProductService.getFlexRawMaterialById(id);
    },
    retry: 0,
    enabled: !!id,
  });

  const schema = yup.object({
    productName: yup.string().required(t("validation:empty")),
    image: yup.string().required(t("validation:empty")),
    stockCode: yup.string().required(t("validation:empty")),
    unitType: yup.string().required(t("validation:empty")),
    type: yup.string().required(t("validation:empty")),
    quantity: yup.number().required(t("validation:empty")),
    description: yup.string().required(t("validation:empty")),
    supplierGroup: yup.string().required(t("validation:empty")),
    criticalStockQuantity: yup.number().required(t("validation:empty")),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (value) => {
    let isValid = true;

    if (!price) {
      setPriceValidate("error");
      isValid = false;
    } else {
      setPriceValidate(null);
    }
    if (isValid) {
      const send = {
        name: value?.productName,
        image: value?.image,
        stockCode: value?.stockCode,
        unitType: value?.unitType,
        type: value?.type,
        currency: currency,
        price: price,
        quantity: value?.quantity,
        description: value?.description,
        supplierGroup: value?.supplierGroup,
        criticalStockQuantity: value?.criticalStockQuantity,
      };

      if (id) {
        await flexProductService.updateFlexRawMaterial(id, send).then((res) => {
          if (res.data.code == 0) {
            Toast("success", res?.data?.message);
            navigate(-1);
          } else {
            Toast("error", res?.data?.message);
          }
        });
      } else {
        await flexProductService.createFlexRawMaterial(send).then((res) => {
          if (res.data.code == 0) {
            Toast("success", res?.data?.message);
            navigate(-1);
          } else {
            Toast("error", res?.data?.message);
          }
        });
      }
    }
  };

  const rawMaterials = [
    { label: "Çubuk", value: "stick" },
    { label: "Lama", value: "lama" },
    { label: "Boru", value: "pipe" },
    { label: "Diğer", value: "other" },
  ];

  const units = [
    { label: "Kilogram", shortLabel: "kg", value: "kg" },
    { label: t("product:metricTon"), shortLabel: "ton", value: "ton" },
    { label: "Gram", shortLabel: "gr", value: "gr" },
    { label: "Miligram", shortLabel: "mg", value: "mg" },
    { label: "Microgram", shortLabel: "mcg", value: "mcg" },
    { label: "Pound", shortLabel: "lb", value: "lb" },
    { label: t("product:ounce"), shortLabel: "oz", value: "oz" },
    { label: "Metre", shortLabel: "m", value: "m" },
    { label: "Millilitre", shortLabel: "mm", value: "mm" },
    { label: t("product:gallon"), shortLabel: "gal", value: "gal" },
    { label: t("product:squareMetre"), shortLabel: "m2", value: "m2" },
    { label: t("product:squareMilimetre"), shortLabel: "mm2", value: "mm2" },
    { label: t("product:squareInch"), shortLabel: "in2", value: "mm2" },
    { label: t("product:pieceModal"), shortLabel: "ad", value: "ad" },
    { label: t("product:package"), shortLabel: "pkg", value: "pkg" },
    { label: t("product:box"), shortLabel: "box", value: "box" },
  ];

  const priceOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
  ];

  useEffect(() => {
    setValue("productName", data?.data?.name);
    setValue("image", data?.data?.image);
    setValue("stockCode", data?.data?.stockCode);
    setValue("unitType", data?.data?.unitType);
    setValue("type", data?.data?.type);
    setValue("quantity", data?.data?.quantity);
    setValue("description", data?.data?.description);
    setValue("supplierGroup", data?.data?.supplierGroup?.id);
    setValue("criticalStockQuantity", data?.data?.criticalStockQuantity);
    setPrice(data?.data?.price);
    setCurrency(data?.data?.currency);
  }, [data]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full h-full gap-y-8 overflow-y-auto overflow-x-hidden scrollbar-hide">
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
          <p className="text-[#101828] font-semibold text-2xl">{t("product:createNewFlexRaw")}</p>
        </div>

        <div className="flex flex-col gap-y-6 w-full">
          <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
            <div className="flex flex-col gap-y-1">
              <p className="text-[#101828] font-semibold text-lg">{t("product:rawMaterialInformations")}</p>
              <p className="text-[#344054] font-normal text-sm">{t("product:enterTheInformationRaw")} </p>
            </div>
            <div className="flex gap-x-3">
              <span className="flex min-w-[96px] max-w-[96px] w-[96px]">
                <Button size={"md"} colorType={"secondary-gray"} label={t("buttons:stop")} onClick={() => navigate(-1)} />
              </span>
              <span className="flex min-w-[96px] max-w-[96px] w-[96px]">
                <Button size={"md"} colorType={"primary-product"} label={t("buttons:save")} type={"submit"} />
              </span>
            </div>
          </div>
          <div className="flex flex-col w-full gap-y-5">
            <div className="flex w-full gap-x-8 items-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:productImages")}</p>
              </div>
              <div className="flex w-[75%] gap-x-[72px]">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <UploadProfile
                      title={t("product:productImages")}
                      subTitle={"Remove Picture"}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={errors?.image?.message}
                      validate={errors?.image ? "error" : ""}
                    />
                  )}
                  name="image"
                />
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:rawMaterialName")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        theme={"product"}
                        errorMessage={errors?.productName?.message}
                        validate={errors?.productName ? "error" : ""}
                      />
                    )}
                    name="productName"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:stockCode")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        theme={"product"}
                        errorMessage={errors?.stockCode?.message}
                        validate={errors?.stockCode ? "error" : ""}
                      />
                    )}
                    name="stockCode"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:rawMaterialType")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Selector
                        value={value}
                        items={rawMaterials}
                        theme={"product"}
                        placeholder={t("product:selectRawMaterialType")}
                        onChange={onChange}
                        onBlur={onBlur}
                        validate={errors?.type ? "error" : ""}
                        errorMessage={errors?.type ? errors?.type?.message : ""}
                      />
                    )}
                    name="type"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:unitAndUnitPrice")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%] gap-x-3">
                  <span className="flex w-1/2">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Selector
                          value={value}
                          items={units}
                          theme={"product"}
                          placeholder={"Metre (m)"}
                          onChange={onChange}
                          onBlur={onBlur}
                          validate={errors?.unitType ? "error" : ""}
                          errorMessage={errors?.unitType ? errors?.unitType?.message : ""}
                        />
                      )}
                      name="unitType"
                    />
                  </span>
                  <span className="flex w-1/2">
                    <UnitPriceInput
                      theme={"product"}
                      items={priceOptions}
                      setValue={(field, value) => {
                        field === "price" ? setPrice(value) : setCurrency(value);
                      }}
                      price={price}
                      currency={currency}
                      validate={priceValidate ? "error" : ""}
                      errorMessage={priceValidate ? "Bu alan zorunludur" : ""}
                    />
                  </span>
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:supplierGroup")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Selector
                        value={value}
                        items={supplierGroups?.map((item) => {
                          return { label: item?.name, value: item?.id };
                        })}
                        theme={"product"}
                        onChange={onChange}
                        placeholder={t("product:selectSupplierGroup")}
                        onBlur={onBlur}
                        validate={errors?.supplierGroup ? "error" : ""}
                        errorMessage={errors?.supplierGroup ? errors?.supplierGroup?.message : ""}
                      />
                    )}
                    name="supplierGroup"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:currentStockQuantity")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        type={"number"}
                        theme={"product"}
                        errorMessage={errors?.quantity?.message}
                        validate={errors?.quantity ? "error" : ""}
                      />
                    )}
                    name="quantity"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:criticalStockLimit")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        type={"number"}
                        theme={"product"}
                        errorMessage={errors?.criticalStockQuantity?.message}
                        validate={errors?.criticalStockQuantity ? "error" : ""}
                      />
                    )}
                    name="criticalStockQuantity"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:description")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextArea
                        onBlur={onBlur}
                        onChange={onChange}
                        rows={3}
                        theme={"product"}
                        value={value}
                        validate={errors.description ? "error" : ""}
                        errorMessage={errors?.description?.message}
                      />
                    )}
                    name="description"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default FlexibleMaterialCreator;
