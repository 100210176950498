import React, { useState } from "react";
import { Button, Loading } from "../../../components";
import { PlusIcon } from "../../../assets/icons/productIcons";
import SearchInput from "../../../components/inputs/SearchInput";
import NewRawMaterialCard from "../../../components/junction/NewRawMaterialCard";
import { useQuery } from "@tanstack/react-query";
import Badgets from "../../../components/buttons/Badgets";
import { useTranslation } from "react-i18next";
import { productService } from "../../../services/product.service";
import diacritics from "diacritics";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";

const NewFlexOrderThirdModal = ({ setSelectedRawMaterial, selectedRawMaterial, setSelectRawCardModal, rowProductsData, handleRawMaterialSelection }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["flex-raw-material-list"],
    queryFn: async () => {
      return await productService.getRawMaterialOperations(rowProductsData?.id);
    },
    retry: 0,
  });
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  const FilteredOperations = data?.data?.operations?.filter((value) =>
    diacritics.remove(`${value?.process?.name}`).toLowerCase().includes(diacritics.remove(searchQuery).toLowerCase())
  );

  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
    }
  };

  return (
    <div className="flex flex-col w-full h-full border-t mt-5 py-4 gap-y-6">
      <div className="flex flex-col w-full h-full gap-y-5">
        <SearchInput placeholder={"Search Raw Material"} theme={"product"} setSearchVal={setSearchQuery} />

        <div className="flex flex-col gap-y-4 w-full h-[50vh] overflow-y-auto overflow-x-hidden scrollbar-hide">
          {isLoading ? (
            <div className="flex w-full h-full items-center justify-center">
              <Loading size={56} primary={"#B54708"} secondary={"#fff"} />
            </div>
          ) : (
            <>
              {data && (
                <div
                  onClick={() => {
                    setSelectedRawMaterial(data?.data?.rawMaterial);
                    handleRawMaterialSelection(data?.data?.rawMaterial);
                    setSelectRawCardModal(false);
                  }}
                  className="flex w-full min-h-[90px] max-h-[90px] h-[90px] border border-[#D0D5DD] rounded-lg cursor-pointer"
                >
                  <div className="flex flex-col w-full h-full px-3 py-2 gap-y-[6px]">
                    <div className="flex w-full gap-x-2">
                      {data?.data?.rawMaterial?.image ? (
                        <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(data?.data?.rawMaterial?.image)} />
                      ) : (
                        <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden">
                          <NoAvatar name={data?.data?.rawMaterial?.name || "--"} rounded={4} theme={"product"} />
                        </div>
                      )}
                      <div className="flex flex-col justify-center">
                        <p className="text-md font-semibold text-[#101828] ml-1 truncate w-[325px]">{data?.data?.rawMaterial?.name || "--"}</p>
                        <span className="px-1 py-[3px] bg-[#FFFAEB] rounded text-[#B54708] font-medium text-xs truncate w-fit max-w-[320px]">
                          Stok: {data?.data?.rawMaterial?.stockCode || "--"}
                        </span>
                      </div>
                    </div>
                    <div className="flex w-full gap-x-[6px] items-center justify-start">
                      <FreeStockIcon />
                      <div className="flex gap-x-1 items-center">
                        <p className="text-[#344054] font-medium text-sm">{data?.data?.rawMaterial?.quantity}</p>
                        <p className="text-[#475467] text-xxs">m serbest stok</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full h-full relative">
                    <div className="flex min-h-[24px] max-h-[24px] h-6 min-w-[70px] max-w-[70px] w-[70px] border-t border-r border-[#D0D5DD] bg-[#F2F4F7] -rotate-90 absolute bottom-[23px] -right-[23px] rounded-bl-lg rounded-tr-lg p-1 items-center justify-center">
                      <p className="text-[#475467] font-semibold text-xxs">Hammadde</p>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex w-full h-[1px] min-h-[1px] max-h-[1px] bg-[#E4E7EC]"></div>
              {FilteredOperations?.sort((a, b) => a.step - b.step)?.map((value, index) => {
                return (
                  <div
                    onClick={() => {
                      setSelectedRawMaterial(value);
                      handleRawMaterialSelection(value);
                      setSelectRawCardModal(false);
                    }}
                    className="flex w-full min-h-[90px] max-h-[90px] h-[90px] border border-[#D0D5DD] rounded-lg cursor-pointer"
                  >
                    <div className="flex flex-col w-full h-full px-3 py-2 gap-y-[6px]">
                      <div className="flex w-full gap-x-2">
                        <div className="flex flex-col justify-center items-start">
                          <div className="flex gap-x-2 items-center">
                            <div className="flex bg-[#F2F4F7] px-1 border rounded border-[#E4E7EC]">
                              <p className="text-[#344054] font-semibold text-xs">
                                {value?.type === "internal" ? operationTypes(value?.process?.type || "conversion_operation") : "HİZ"}
                              </p>
                            </div>
                            <p className="text-md font-semibold text-[#101828] ml-1 truncate w-[325px]">{value?.process?.name || "--"}</p>
                          </div>
                          <span className="px-1 py-[3px] bg-[#FFFAEB] rounded text-[#B54708] font-medium text-xs truncate w-fit max-w-[320px]">
                            {value?.step}.op tamamlanmış yarı mamül
                          </span>
                        </div>
                      </div>
                      <div className="flex w-full gap-x-[6px] items-center justify-start">
                        <QuantityIcon />
                        <div className="flex gap-x-1 items-center">
                          <p className="text-[#344054] font-medium text-sm">{value?.activePiece ?? "0"}</p>
                          <p className="text-[#475467] text-xxs mt-[2px]">ad. yarı mamül stoğu</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full h-full relative">
                      <div className="flex min-h-[24px] max-h-[24px] h-6 min-w-[70px] max-w-[70px] w-[70px] border-t border-r border-[#D0D5DD] bg-[#F2F4F7] -rotate-90 absolute bottom-[23px] -right-[23px] rounded-bl-lg rounded-tr-lg p-1 items-center justify-center">
                        <p className="text-[#475467] font-semibold text-xxs">Yarı Mamül</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewFlexOrderThirdModal;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_18020_22402)">
        <path
          d="M5.50016 1.3335H3.00016C2.07969 1.3335 1.3335 2.07969 1.3335 3.00016V13.0002C1.3335 13.9206 2.07969 14.6668 3.00016 14.6668H13.0002C13.9206 14.6668 14.6668 13.9206 14.6668 13.0002V3.00016C14.6668 2.07969 13.9206 1.3335 13.0002 1.3335H10.5002M5.50016 1.3335V5.19064C5.50016 5.25535 5.56912 5.29672 5.62621 5.26627L8.00016 4.00016L10.3741 5.26627C10.4312 5.29672 10.5002 5.25535 10.5002 5.19064V1.3335M5.50016 1.3335H10.5002"
          stroke="#475467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18020_22402">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const SquareIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M5.57676 4.07251V6.32892L4.1665 7.17507" stroke="#079455" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0932 6.68412C11.1206 6.45957 11.1346 6.23091 11.1346 5.99896C11.1346 2.90291 8.6248 0.393066 5.52875 0.393066C2.4327 0.393066 -0.0771484 2.90291 -0.0771484 5.99896C-0.0771484 9.09502 2.4327 11.6049 5.52875 11.6049C6.0331 11.6049 6.52189 11.5383 6.98682 11.4134V10.3693C6.52859 10.5221 6.03834 10.6049 5.52875 10.6049C2.98498 10.6049 0.922852 8.54273 0.922852 5.99896C0.922852 3.4552 2.98498 1.39307 5.52875 1.39307C8.07252 1.39307 10.1346 3.4552 10.1346 5.99896C10.1346 6.00767 10.1346 6.01637 10.1346 6.02507L11.0932 6.68412Z"
        fill="#079455"
      />
      <path
        d="M8.11523 6.8346C8.11523 6.60307 8.11523 6.48731 8.16351 6.4235C8.20556 6.3679 8.26984 6.3335 8.33943 6.32935C8.4193 6.32458 8.51562 6.38879 8.70826 6.51722L11.2152 8.18849C11.3743 8.2946 11.4539 8.34766 11.4817 8.41454C11.5059 8.47301 11.5059 8.53872 11.4817 8.59718C11.4539 8.66406 11.3743 8.71712 11.2152 8.82324L8.70826 10.4945C8.51562 10.6229 8.4193 10.6871 8.33943 10.6824C8.26984 10.6782 8.20556 10.6438 8.16351 10.5882C8.11523 10.5244 8.11523 10.4087 8.11523 10.1771V6.8346Z"
        stroke="#079455"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const FreeStockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_18020_22338)">
        <path
          d="M5.50016 1.3335H3.00016C2.07969 1.3335 1.3335 2.07969 1.3335 3.00016V13.0002C1.3335 13.9206 2.07969 14.6668 3.00016 14.6668H6.51868M5.50016 1.3335V5.19064C5.50016 5.25535 5.56912 5.29672 5.62621 5.26627L8.00016 4.00016L10.3741 5.26627C10.4312 5.29672 10.5002 5.25535 10.5002 5.19064V1.3335M5.50016 1.3335H10.5002M10.5002 1.3335H13.0002C13.9206 1.3335 14.6668 2.07969 14.6668 3.00016V7.20981M14.6675 11.9602H9.29022M14.6675 11.9602L11.987 14.6668M14.6675 11.9602L11.987 9.29856"
          stroke="#475467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18020_22338">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
