import React from "react";
import CustomImage from "../../../../components/CustomImage";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import { NewDotsIcon } from "../../../../components/new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import Badgets from "../../../../components/buttons/Badgets";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

const MaterialSupplyPlanViewPlannedCard = ({ item, setDraggedProduct }) => {
  console.log(item, "ITEMMMMM");
  const { t } = useTranslation();
  const statusColor = () => {
    switch (item?.status) {
      case "applied":
        return "#12B76A";
      case "started":
        return "#FDE172";
      default:
        return "#D0D5DD";
    }
  };
  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];

  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };
  return (
    <>
      <div
        draggable
        onDragStart={() => setDraggedProduct(item)}
        // data-tooltip-id={`${item?.id}`}
        // data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stockCode")}: ${item?.rawMaterial?.stockCode
        //   }`}
        data-tooltip-place="right"
        className="flex bg-white flex-row  w-[300px] min-w-[300px] h-[100px] mr-4 min-h-[100px] relative group pl-2  pt-2  border border-[#D0D5DD] rounded-lg cursor-grab "
      >
        <div className="flex flex-col w-[88%]">
          <div className="flex flex-row items-center gap-x-2">
            {item?.type === "semi_internal_product" && !item?.workOrder ? (
              <>
                {item?.productInternalOperation ? (
                  <>
                    {item?.productInternalOperation?.product?.image ? (
                      <div
                        data-tooltip-id={`${item?.id}`}
                        data-tooltip-place="right"
                        data-tooltip-content={`${t("product:productName")}: ${item?.productInternalOperation?.product?.name} - ${t("product:stockCode")}: ${
                          item?.productInternalOperation?.product?.stockCode
                        }`}
                      >
                        <CustomImage
                          borderRadius={2}
                          src={item?.productInternalOperation?.product?.image}
                          style={{
                            width: 64,
                            height: 48,
                            minWidth: 64,
                            minHeight: 48,
                            borderRadius: 4,
                            borderWidth: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            display: "flex",
                            borderColor: "#0000001A",
                            backgroundColor: "#fff",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        data-tooltip-id={`${item?.id}`}
                        data-tooltip-place="right"
                        data-tooltip-content={`${t("product:productName")}: ${item?.productInternalOperation?.product?.name} - ${t("product:stockCode")}: ${
                          item?.productInternalOperation?.product?.stockCode
                        }`}
                        className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                      >
                        <NoEquIcon />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {item?.productExternalOperation?.product?.image ? (
                      <div
                        data-tooltip-id={`${item?.id}`}
                        data-tooltip-place="right"
                        data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stockCode")}: ${
                          item?.productExternalOperation?.product?.stockCode
                        }`}
                      >
                        <CustomImage
                          borderRadius={2}
                          src={item?.productExternalOperation?.product?.image}
                          style={{
                            width: 64,
                            height: 48,
                            minWidth: 64,
                            minHeight: 48,
                            borderRadius: 4,
                            borderWidth: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            display: "flex",
                            borderColor: "#0000001A",
                            backgroundColor: "#fff",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        data-tooltip-id={`${item?.id}`}
                        data-tooltip-place="right"
                        data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stockCode")}: ${
                          item?.productExternalOperation?.product?.stockCode
                        }`}
                        className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                      >
                        <NoEquIcon />
                      </div>
                    )}
                  </>
                )}
              </>
            ) : item?.rawMaterial ? (
              <>
                {item?.rawMaterial?.image ? (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stockCode")}: ${
                      item?.rawMaterial?.stockCode
                    }`}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={item?.rawMaterial?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place="right"
                    data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stockCode")}: ${
                      item?.rawMaterial?.stockCode
                    }`}
                    className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                  >
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : item?.product?.image ? (
              <div
                data-tooltip-id={`${item?.id}`}
                data-tooltip-place="right"
                data-tooltip-content={`${t("product:productName")}: ${item?.product?.name} - ${t("product:stockCode")}: ${item?.product?.stockCode}`}
              >
                <CustomImage
                  borderRadius={2}
                  src={item?.product?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                data-tooltip-id={`${item?.id}`}
                data-tooltip-place="right"
                data-tooltip-content={`${t("product:productName")}: ${item?.product?.name} - ${t("product:stockCode")}: ${item?.product?.stockCode}`}
                className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
              >
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 ml-1 w-full">
                {item?.productInternalOperation
                  ? item?.productInternalOperation?.product?.name || "Product Name"
                  : item?.productExternalOperation
                  ? item?.productExternalOperation?.product?.name || "Product Name"
                  : item?.product
                  ? item?.product?.name
                  : item?.rawMaterial
                  ? item?.rawMaterial?.name
                  : "Product Name"}
              </div>
              <div className={`h-6 max-w-[175px] flex px-1 py-[3px] ml-[2px] items-center justify-center bg-[#FFFAEB]`}>
                <p className={`text-sm font-normal max-w-[175px] truncate text-[#B54708]`}>
                  {t("product:stock")} :{" "}
                  {item?.type === "semi_internal_product" && item?.workOrder === null
                    ? item?.productInternalOperation
                      ? item?.productInternalOperation?.product?.stockCode || "--"
                      : item?.productExternalOperation?.product?.stockCode || "--"
                    : item?.product
                    ? item?.product?.stockCode || "--"
                    : item?.rawMaterial?.stockCode || "--"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-[6px]">
            <>
              <div className="flex flex-row items-center mt-1.5">
                <PieceFlexIcon color={"#475467"} />
                <p className="text-sm font-medium ml-1 text-secondary-700">{item?.orderedUnit || "--"}</p>
                <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-600">
                  {getUnitLabel(item?.rawMaterial?.unitType)} {t("product:sortOrder")}
                </p>
              </div>
              <div className="flex flex-row items-center mt-1.5">
                <Badgets label={"0"} colorType={"fill-warning"} size={"sm"} />

                <p className="text-[10px] ml-1 mt-[3px] font-normal text-secondary-600">{t("product:urgencyLevel")}</p>
              </div>
            </>
          </div>
        </div>

        <div
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[#F2F4F7] border-secondary-300 h-[78px] min-h-[78px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          <div className="flex flex-col items-center gap-y-[14px]">
            {item?.type === "semi_internal_product" && item?.workOrder === null ? (
              <div className="">
                {item?.productInternalOperation ? (
                  <>
                    <p className="text-[10px] font-semibold w-[70px] truncate whitespace-nowrap text-[#475467] -rotate-90">
                      {item?.productInternalOperation?.step + ".Op"} /{item?.productInternalOperation?.process?.name}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="text-[10px] font-semibold w-[70px] truncate whitespace-nowrap text-[#475467] -rotate-90">
                      {item?.productExternalOperation?.step + ".Op"} /{item?.productExternalOperation?.process?.name}
                    </p>
                  </>
                )}
              </div>
            ) : item?.product ? (
              <>
                <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>{t("product:productss")}</p>
              </>
            ) : (
              <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>{t("product:rawMaterial")}</p>
            )}
          </div>
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className="absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
      </div>
      {item?.id && <Tooltip className="z-[999]" id={`${item?.id}`} />}
    </>
  );
};

export default MaterialSupplyPlanViewPlannedCard;

const PieceFlexIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17841_63067)">
        <path
          d="M5.49967 1.3335H2.99967C2.0792 1.3335 1.33301 2.07969 1.33301 3.00016V13.0002C1.33301 13.9206 2.0792 14.6668 2.99967 14.6668H12.9997C13.9201 14.6668 14.6663 13.9206 14.6663 13.0002V3.00016C14.6663 2.07969 13.9201 1.3335 12.9997 1.3335H10.4997M5.49967 1.3335V5.19064C5.49967 5.25535 5.56863 5.29672 5.62573 5.26627L7.99967 4.00016L10.3736 5.26627C10.4307 5.29672 10.4997 5.25535 10.4997 5.19064V1.3335M5.49967 1.3335H10.4997"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17841_63067">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M6 9.5L6 2.5M6 2.5L2.5 6M6 2.5L9.5 6" stroke={color} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
