import React from "react";
import { OrderQuantityIcon } from "../../../components/new-cards/card-icons";
import { CalendarIcon } from "../quality/assets/qualitySvg";
import generateFileUrl from "../../../utils/generateFileUrl";

const ExpandedOrderInfo = ({
  t,
  date = "19.04.2024",
  day = "Pazartesi",
  orderNumber = 19284294586,
  productTitle = "Ayırıcı Hareket Mili",
  stockCode = 16245687273,
  image = "f694506f-f373-4268-a534-b18783cec548",
  orderedQuantity = 30000,
  deliveredQuantity = 30000,
  remainingQuantity = 30000,
}) => {
  const headerTitle = [
    { title: t("product:expandableProducts") },
    { title: t("product:deliveringDate") },
    { title: t("product:orderedQuantity") },
    { title: t("product:totalDeliveredQuantity") },
    { title: t("product:remainingOrders") },
  ];

  return (
    <div className="w-full h-[244px] mt-4 flex flex-col items-start rounded-xl border border-solid border-[#E4E7EC] bg-[#fff] shadow-xs overflow-hidden">
      {/*Header*/}
      <div className="w-full h-14 flex p-[16px_24px] items-center gap-8  border-b border-solid border-[#E4E7EC] ">
        <div className="min-w-[215px] h-6 flex items-center gap-2 ">
          <OrderQuantityIcon />
          <div className="min-w-[187px] h-full flex items-baseline gap-2 ">
            <span className="min-w-[68px] h-5 text-sm text-[#475467] font-normal ">
              {t("product:orderNumber")}
            </span>

            <span className="truncate w-[111px] h-6 text-md text-[#B54708] font-semibold ">
              {orderNumber}
            </span>
          </div>
        </div>

        <div className="min-w-[215px] h-6 flex items-center gap-2 ">
          <CalendarIcon />

          <div className="min-w-[187px] h-full flex items-baseline gap-2 ">
            <span className="min-w-[68px] h-5 text-sm text-[#475467] font-normal ">
              {t("product:orderGeneratedDate")}
            </span>

            <span className="min-w-[111px] h-6 text-md text-[#B54708] font-semibold ">
              {date}
            </span>
          </div>
        </div>
      </div>

      {/*lower container*/}
      <div className="w-full h-[188px] flex flex-col items-start">
        <div className="w-full h-7 flex  border-b border-solid border-[#E4E7EC] bg-[#F2F4F7] ">
          {headerTitle?.map((item, index) => (
            <div
              key={index}
              className={` ${
                index === 0 ? "w-[28%]" : "w-[18%]"
              } h-7 flex p-[4px_12px] justify-center items-center gap-2 ${
                index !== 4 ? "border-r" : ""
              }`}
            >
              <span className="min-w-[80px] h-5 text-sm text-[#475467] font-medium ">
                {item?.title}
              </span>
            </div>
          ))}
        </div>

        <div className="w-full h-[160px] flex flex-col overflow-auto scrollbar-hide">
          <div className="w-full h-20 flex  border-b border-solid border-[#E4E7EC] bg-[#fff]">
            <div className="w-[28%] h-full flex items-center p-[12px_24px] gap-3  border-r ">
              <div className="min-w-[250px] h-12 flex items-center gap-3  ">
                <img
                  // src="https://cdn.upu.io/f694506f-f373-4268-a534-b18783cec548"
                  src={generateFileUrl(image)}
                  className="w-[64px] h-12 rounded-[2.667px] border-[0.667px] border-solid border-[#0000001A] "
                ></img>

                <div className="min-w-[174px] h-12 flex flex-col items-start gap-1 ">
                  <span className="w-full h-6 text-md text-[#101828] font-semibold  ">
                    {productTitle}
                  </span>

                  <span className="w-[200px] truncate h-5 text-sm text-[#475467] font-normal  ">
                    {t("product:stockCode")} : {stockCode}
                  </span>
                </div>
              </div>
            </div>

            <div className="w-[18%] h-full p-[16px_0px] flex flex-col justify-center items-center  border-r">
              <span className="w-full h-5 text-sm text-[#344054] font-medium flex justify-center items-center">
                {date}
              </span>
              <span className="w-full h-[18px] text-xs font-normal text-[#667085] flex justify-center items-center">
                {day}
              </span>
            </div>

            <div className="w-[18%] h-full p-[16px_0px] flex flex-col justify-center items-center  border-r">
              <span className="w-full h-5 text-sm text-[#344054] font-medium flex justify-center items-center">
                {orderedQuantity}
              </span>
              <span className="w-full h-[18px] text-xs font-normal text-[#667085] flex justify-center items-center">
                {t("product:pieces")}
              </span>
            </div>

            <div className="w-[18%] h-full p-[16px_0px] flex flex-col justify-center items-center  border-r">
              <span className="w-full h-5 text-sm text-[#344054] font-medium flex justify-center items-center">
                {deliveredQuantity}
              </span>
              <span className="w-full h-[18px] text-xs font-normal text-[#667085] flex justify-center items-center">
                {t("product:pieces")}
              </span>
            </div>

            <div className="w-[18%] h-full p-[16px_0px] flex flex-col justify-center items-center">
              <span className="w-full h-5 text-sm text-[#344054] font-medium flex justify-center items-center">
                {remainingQuantity}
              </span>
              <span className="w-full h-[18px] text-xs font-normal text-[#667085] flex justify-center items-center">
                {t("product:pieces")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandedOrderInfo;
