import React from "react";
import Button from "../../../components/buttons/Button";
import { QRCode } from "react-qrcode-logo";
import { PrinterIcon } from "../../../assets/icons/buttonIcons";
import { useTranslation } from "react-i18next";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useReactToPrint } from "react-to-print";
import { authStore } from "../../../stores/auth.store";
import "./media.css";
import { NodeIcon, UpuLogo, UsersIcon } from "../../../assets/icons/commonIcons";

const MachineQrModal = ({ nodeId, name, image, equipmentCode, limit, process }) => {
  const { t } = useTranslation();
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "N-" + " " + name + " " + "upu.machine",
  });

  return (
    <div className="flex flex-col">
      <div ref={componentRef} className="w-[556px] h-[386px] px-3.5 pt-3.5 flex flex-col relative">
        <div className="w-full flex">
          <img className="w-[170px] mt-3.5 h-[128px] rounded border border-gray-300 shadow" src={generateFileUrl(image)} />
          <div className="flex-1 ml-3 flex flex-col pt-4">
            <p className="font-semibold text-secondary-900 text-2xl">{name}</p>
            <p className="text-secondary-600 mt-1.5 text-xl">{process}</p>
          </div>
        </div>
        <div className="flex-1 mt-7 mr-[210px] flex flex-col">
          <div className="w-full flex items-center border-b border-gray-300 pb-2">
            <NodeIcon />
            <div className="flex flex-col ml-3">
              <p className="text-[13px] text-secondary-600">{t("chat:equipmentCode")}</p>
              <p className="text-sm font-semibold text-secondary-900">{equipmentCode || "--"}</p>
            </div>
          </div>
          <div className="w-full flex items-center justify-between mt-2">
            <div className="w-full flex items-center">
              <UsersIcon />
              <div className="flex flex-col ml-3">
                <p className="text-[13px] text-secondary-600">{t("chat:numberOfPersonnel")}</p>
                <p className="text-sm font-semibold text-secondary-900">1 {t("personals:personal")}</p>
              </div>
            </div>
          </div>
          <div className="w-full mb-1 flex items-center mt-auto">
            <img src={generateFileUrl(authStore.user?.company?.image)} className="w-[40px] h-[30px] rounded-sm border border-gray-300" />
            <p className="text-[10px] font-medium text-secondary-600 ml-2">{authStore.user?.company?.name}</p>
            <div className="ml-auto flex items-center">
              <UpuLogo />
              <p className="text-xs font-codecMedium mt-1 text-black">upu.</p>
              <p className="text-xs font-codecMedium mt-1 text-primary-600">machine</p>
            </div>
          </div>
        </div>
        <div className="absolute w-[206px] h-[206px] rounded-lg border border-gray-300 bg-white bottom-0 right-0 shadow flex items-center justify-center">
          <QRCode
            id="qr-code"
            fgColor="#6941C6"
            bgColor="#FFF"
            size={180}
            ecLevel="M"
            value={nodeId || undefined}
            eyeRadius={[
              {
                outer: [2, 2, 2, 2],
                inner: [2, 2, 2, 2],
              },
              {
                outer: [2, 2, 2, 2],
                inner: [2, 2, 2, 2],
              },
              {
                outer: [2, 2, 2, 2],
                inner: [2, 2, 2, 2],
              },
            ]}
            qrStyle="squares"
          />
        </div>
      </div>
      <div className="w-full flex items-center gap-4 justify-between mt-4">
        <Button onClick={handlePrint} iconLeft={<PrinterIcon />} label={t("embedded:print")} colorType={"secondary-gray"} size={"md"} />
      </div>
    </div>
  );
};

export default MachineQrModal;
