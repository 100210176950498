import React, { useState, useEffect, useRef } from "react";
import { TableIcon } from "../assets/icons/productIcons";
import { useTranslation } from "react-i18next";

const TableOptions = React.memo(({ setPageLimit, pageLimit = 10 }) => {
  const { t } = useTranslation();
  const menuRef = useRef();
  const options = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
  ];
  const [isSortDropdown, setIsSortDropdown] = useState(false);
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsSortDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} className="flex w-full h-full relative">
      {pageLimit ? (
        <div
          onClick={() => setIsSortDropdown(true && !isSortDropdown)}
          className={`flex border hover:bg-secondary-50 border-secondary-300 px-[14px] py-[10px] rounded-lg gap-x-1 justify-center items-center cursor-pointer ${
            isSortDropdown ? "shadow-ring-gray" : ""
          }`}
        >
          <span className="min-w-[20px] min-h-[20px]">
            <TableIcon color={"#344054"} />
          </span>
          <span className="text-sm font-semibold text-secondary-700 whitespace-nowrap">
            {`${pageLimit}-${t("product:listed")}`}
          </span>
        </div>
      ) : (
        <div
          onClick={() => setIsSortDropdown(true && !isSortDropdown)}
          className={`flex border hover:bg-secondary-50 border-secondary-300 px-[14px] py-[10px] rounded-lg gap-x-1 justify-center items-center cursor-pointer ${
            isSortDropdown ? "shadow-ring-gray" : ""
          }`}
        >
          <span className="min-w-[20px] min-h-[20px]">
            <TableIcon color={"#344054"} />
          </span>
          <span className="text-sm font-semibold text-secondary-700 whitespace-nowrap">
            {`${pageLimit}-${t("product:listed")}`}
          </span>
        </div>
      )}
      {isSortDropdown === true && (
        <div className="flex flex-col min-w-[110px] max-w-[110px] h-fit absolute top-[48px] right-0 bg-white border z-[999999999] rounded-lg shadow-lg py-1 px-[6px] gap-y-[2px] border-secondary-200">
          {options?.map((item, index) => (
            <div
              onClick={() => {
                setPageLimit(item?.value);
                setIsSortDropdown(false);
              }}
              key={index}
              className={`flex items-center min-h-[38px] max-h-[38px] rounded-md gap-x-3 px-[10px] py-[9px] cursor-pointer hover:bg-secondary-50 ${
                pageLimit === item?.value ? "bg-secondary-50" : ""
              }`}
            >
              <div className="flex items-center justify-center w-full">
                <span className="text-sm text-secondary-700 font-medium">
                  {item?.label} {t("product:listed")}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default TableOptions;
