import React from 'react'
import { useTranslation } from 'react-i18next'
import Badgets from '../../../../components/buttons/Badgets'

const MaterialSupplyHeaderCard = ({onClick,selectedOperation,name,planned,unPlanned,id,length="12"}) => {
    const {t} = useTranslation()
    return (
        <div
            onClick={onClick}
            className={`min-w-[172px] rounded-t-lg ${selectedOperation?.id === id ? "border-b-[2px] border-b-[#A15C07] h-[40px]" : "h-[40px]"} rounded-b-none border cursor-pointer border-seconday-300  select-none flex flex-col`}
        >
            <div className="flex flex-row items-center justify-center w-full gap-x-2 p-2">
                <p className={`text-base  truncate ${selectedOperation?.id === id ? "text-[#B54708] font-semibold" : "text-[#667085] font-medium"} `}>{name}</p>
                {selectedOperation?.id === id ? (
                    <Badgets colorType={"fill-warning"} label={length} size={"sm"}/>
                ) : (
                    <Badgets colorType={"fill-gray"} label={length} size={"sm"}/>
                )}
            </div>
        </div>
    )
}

export default MaterialSupplyHeaderCard