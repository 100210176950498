import React, { memo, useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components";
import { PrinterIcon } from "../../../assets/icons/buttonIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { authStore } from "../../../stores/auth.store";
import { useReactToPrint } from "react-to-print";
import "./media.css";
import moment from "moment";
import { CalendarIcon2, OrderIcon, QtyIcon2, UpuLogo } from "../../../assets/icons/commonIcons";

const OrderQrModal = ({ orderId, order }) => {
  const { t } = useTranslation();
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "P-" + " " + order?.product?.name + " " + "upu.product",
  });

  return (
    <div className="flex flex-col">
      <div ref={componentRef} className="w-[556px] h-[386px] px-3.5 pt-3.5 flex flex-col relative">
        <div className="w-full flex justify-between">
          <div className="flex flex-col">
            <p className="text-[12px] text-secondary-600">{t("product:productCode")}</p>
            <p className="text font-semibold text-secondary-900">{order?.product?.code}</p>
          </div>
          <p className="text-[12px] text-secondary-600">{moment().format("DD/MM/YYYY")}</p>
        </div>
        <div className="w-full flex">
          <img className="w-[170px] mt-3.5 h-[128px] rounded border border-gray-300 shadow" src={generateFileUrl(order?.product?.image)} />
          <div className="flex-1 ml-3 flex flex-col pt-4">
            <p className="font-semibold text-secondary-900">{order?.product?.name}</p>
            <p className="text-secondary-600 mt-1.5">
              {t("product:stockCode")}: {order?.product?.stockCode}
            </p>
          </div>
        </div>
        <div className="flex-1 mt-5 mr-[184px] flex flex-col">
          <div className="w-full flex items-center border-b border-gray-300 pb-2">
            <OrderIcon />
            <div className="flex flex-col ml-2">
              <p className="text-[13px] text-secondary-600">{t("product:orderCode")}</p>
              <p className="text-sm font-semibold text-secondary-900">{order?.orderNo}</p>
            </div>
          </div>
          <div className="w-full flex items-center justify-between mt-2">
            <div className="w-1/2 flex items-center">
              <QtyIcon2 />
              <div className="flex flex-col ml-2">
                <p className="text-[13px] text-secondary-600">{t("product:orderQuantity")}</p>
                <p className="text-sm font-semibold text-secondary-900">{order?.piece}</p>
              </div>
            </div>
            <div className="w-1/2 flex items-center">
              <CalendarIcon2 />
              <div className="flex flex-col ml-2">
                <p className="text-[13px] text-secondary-600">{t("product:delivery")}</p>
                <p className="text-sm font-semibold text-secondary-900">{moment(order?.deliveryDate).format("DD/MM/YYYY")}</p>
              </div>
            </div>
          </div>
          <div className="w-full flex-1 flex mt-2 items-center">
            <img src={generateFileUrl(authStore?.user?.company?.image)} className="w-[40px] h-[30px] rounded-sm border border-gray-300" />
            <p className="text-[10px] font-medium text-secondary-600 ml-2">{authStore?.user?.company?.name}</p>
            <div className="ml-auto flex items-center">
              <UpuLogo color={"#B54708"} />
              <p className="text-xs font-codecMedium mt-1 text-black">upu.</p>
              <p className="text-xs font-codecMedium mt-1 text-orange-600">product</p>
            </div>
          </div>
        </div>
        <div className="absolute w-[183px] h-[183px] rounded border border-gray-300 bg-white bottom-0 right-0 shadow flex items-center justify-center">
          <QRCode
            id="qr-code"
            fgColor="#B54708"
            bgColor="#FFF"
            size={156}
            ecLevel="M"
            value={orderId || undefined}
            eyeRadius={[
              {
                outer: [2, 2, 2, 2],
                inner: [2, 2, 2, 2],
              },
              {
                outer: [2, 2, 2, 2],
                inner: [2, 2, 2, 2],
              },
              {
                outer: [2, 2, 2, 2],
                inner: [2, 2, 2, 2],
              },
            ]}
            qrStyle="squares"
          />
        </div>
      </div>
      <div className="w-full flex items-center gap-4 justify-between mt-4">
        <Button onClick={handlePrint} iconLeft={<PrinterIcon />} label={t("embedded:print")} colorType={"secondary-gray"} size={"md"} />
      </div>
    </div>
  );
};

export default memo(OrderQrModal);
