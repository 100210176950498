import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import SearchInput from "../../../components/inputs/SearchInput";
import { productService } from "../../../services/product.service";
import diacritics from "diacritics";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button, Input, Loading } from "../../../components";
import NoAvatar from "../../../components/avatar/NoAvatar";
import NewOrderRawMaterialCard from "../cards/NewOrderRawMaterialCard";
import NewOrderOperationCard from "../cards/NewOrderOperationCard";
import { useTranslation } from "react-i18next";

const NewFlexOrderSelectedMaterial = ({ setSelectRawCardModal, handleRawMaterialSelection, rowProductsData }) => {
  const {t} = useTranslation()
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [plannedQuantity, setPlannedQuantity] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: ["flex-raw-material-list"],
    queryFn: async () => {
      return await productService.getRawMaterialOperations(rowProductsData?.id);
    },
    retry: 0,
  });

  const FilteredOperations = data?.data?.operations?.filter((value) =>
    diacritics.remove(`${value?.process?.name}`).toLowerCase().includes(diacritics.remove(searchQuery).toLowerCase())
  );

  const handleSubmit = () => {
    if (!selectedMaterial) {
      alert("Lütfen bir materyal seçin.");
      return;
    }

    const result = {
      ...selectedMaterial,
      plannedQuantity: plannedQuantity,
    };

    handleRawMaterialSelection(result, rowProductsData?.id, rowProductsData?.deliveryDate);
    setSelectRawCardModal(false);
  };

  return (
    <div className="flex flex-col mt-5">
      <span className="flex w-full pb-5 pt-3 border-t">
        <SearchInput placeholder={t("product:searchRawMaterial")} theme="product" setSearchVal={setSearchQuery} />
      </span>
      {isLoading ? (
        <div className="flex w-full h-full items-center justify-center">
          <Loading size={56} primary={"#B54708"} secondary={"#fff"} />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-y-4 h-[450px] overflow-y-auto overflow-x-hidden scrollbar-hide items-center pb-1">
            {/* Raw Material */}
            <span
              onClick={() =>
                setSelectedMaterial({
                  id: data?.data?.rawMaterial?.id,
                  image: data?.data?.rawMaterial?.image,
                  stockCode: data?.data?.rawMaterial?.stockCode,
                  quantity: data?.data?.rawMaterial?.quantity,
                  name: data?.data?.rawMaterial?.name,
                  quantityUsedPerPiece: data?.data?.rawMaterial?.quantityUsedPerPiece,
                  type: "raw",
                  stockChecked: false,
                  purchaseChecked: false,
                })
              }
              className={`flex h-fit rounded-lg cursor-pointer max-w-[352px] min-w-[352px] w-[352px] mt-1 ${
                selectedMaterial?.id === data?.data?.rawMaterial?.id ? "shadow-ring-product" : ""
              }`}
            >
              <NewOrderRawMaterialCard item={data?.data?.rawMaterial} />
            </span>

            {/* Operations */}
            {FilteredOperations?.sort((a, b) => a.step - b.step)
              ?.slice(0, -1)
              ?.map((operation, index) => (
                <span
                  key={index}
                  onClick={() =>
                    setSelectedMaterial({
                      id: operation?.id,
                      name: operation?.process?.name,
                      type: operation?.type,
                      proccesType: operation?.process?.type,
                      activePiece: operation?.activePiece,
                      step: operation?.step,
                      stockChecked: false,
                      purchaseChecked: false,
                    })
                  }
                  className={`flex h-fit rounded-lg cursor-pointer max-w-[352px] min-w-[352px] w-[352px] mt-1 ${
                    selectedMaterial?.id === operation?.id ? "shadow-ring-product" : ""
                  }`}
                >
                  <NewOrderOperationCard item={operation} />
                </span>
              ))}
          </div>
          <div className="flex flex-col gap-y-6 pt-3 border-t border-[#E4E7EC]">
            <div className="flex flex-col gap-y-[6px]">
              <p className="text-[#475467] text-sm">{t("product:theLastOperation")}</p>
            </div>
            <div className="flex justify-between gap-x-3">
              <Button label={t("buttons:stop")} colorType="secondary-gray" onClick={() => setSelectRawCardModal(false)} size={"lg"} />
              <Button label={t("buttons:add")} colorType={"primary-product"} onClick={handleSubmit} disabled={!selectedMaterial} size={"lg"} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NewFlexOrderSelectedMaterial;
