import React, { memo, useEffect, useState } from "react";
import { Arrow, Calender, ChartView, Layout, ListView } from "../assets/icons/machineIcons";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { Button } from "../components";
import { HumburgerIcon } from "../assets/icons/leftMenuIcons";
import { useStore } from "../hooks/useStores";
import { lossesService } from "../services";
import { Toast } from "../utils/toastify/toast";
import { Loader } from "../assets/icons/stepsIcons";

const Header = ({ t, icon, title, page, handlePreviousMonth, handleCalender, handleNextMonth, month, setMonth, getMonthName }) => {
  const { settings } = useStore();
  const [selectedView, setSelectedView] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});

  const currentMonthFormat = moment().startOf("month");
  const isDisabled = currentMonthFormat.isSame(moment(month).startOf("month"), "month");

  const years = [
    { value: 2021, name: "2021" },
    { value: 2022, name: "2022" },
    { value: 2023, name: "2023" },
    { value: 2024, name: "2024" },
  ];

  const changeMonthOfYear = async (originalDate, newYear) => {
    const current = Number(moment(month).format("YYYY"));
    const selected = Number(newYear);
    if (current !== selected) {
      lossesService
        .getLosesDetails(moment().year(newYear).format("YYYY-MM-DD"))
        .then((res) => {
          setDatas(res?.data);
        })
        .catch((err) => {
          Toast("error", err?.response?.data?.message);
        });
    }
    const newDate = new Date(originalDate);
    newDate.setFullYear(newYear);
    Number(newYear) === 2024 ? newDate.setMonth(0) : newDate.setMonth(11);
    return String(newDate);
  };

  useEffect(() => {
    if (pathname.includes("list-view")) {
      setSelectedView(true);
    } else {
      setSelectedView(false);
    }
  }, [pathname]);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className={"w-full h-14 flex items-center border-b border-secondary-200 md:pr-3"}>
          {/*packages icon (machine. product, person etc.)*/}
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
          </span>

          <div className={`w-fit h-7 flex items-center gap-2.5`}>
            <span className={`flex w-fit h-full items-start justify-center  `}>{icon}</span>
            {/*title (machine. product, person etc.)*/}
            <span className={`w-fit h-[17px]  justify-center flex-col text-md font-normal text-[#667085]`}>{title}</span>
          </div>

          <div className={`w-fit h-10 flex items-center gap-6 ml-auto sm:hidden xs:hidden ${page === "loss" ? "justify-center" : "justify-end"}`}>
            {/*date selector*/}
            {pathname.includes("list-view") ? (
              <></>
            ) : (
              <>
                <div className={`w-fit h-9 flex items-center gap-3`}>
                  {/*left arrow*/}
                  <button
                    role="button"
                    onClick={() => {
                      handlePreviousMonth();
                    }}
                    className={`w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs `}
                  >
                    <Arrow color="#344054" />
                  </button>
                  {/*calender arrow*/}
                  <div
                    role="button"
                    className={`min-w-[119px] cursor-pointer h-full flex px-2 justify-center items-center gap-1.5 rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs`}
                  >
                    <Calender color="#344054" />
                    {/* Year dropdown selector */}
                    <span className="text-sm font-medium text-secondary-700 truncate flex relative items-center">
                      {moment(month).format("YYYY")}
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 20" fill="none">
                        <path d="M5 7.5L10 12.5L15 7.5" stroke="#000" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <select
                        onChange={async ({ target }) => {
                          setLoading(true);
                          const updateDate = await changeMonthOfYear(month, target.value);
                          setMonth(updateDate);
                          setLoading(false);
                        }}
                        className="opacity-0 absolute inset-0 -bottom-3 ml-0"
                      >
                        {years?.map((item) => {
                          return <option className="text-xs h-[20px]">{item?.name}</option>;
                        })}
                      </select>
                    </span>
                    <div className={`w-fit h-5 flex justify-center items-center`}>
                      <span className={`text-[#344054] text-sm font-semibold flex`}>{getMonthName(moment(month).format("MM")) || "DD MM YYYY"}</span>
                    </div>
                    {openCalendar && (
                      <>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenCalendar(true);
                          }}
                          style={{
                            marginTop: 40,
                          }}
                          className={`absolute top-10 -translate-x-1 bg-white z-[9999999] p-1 border rounded-lg border-gray-300 dark:bg-[#141414] dark:border-[#525252] shadow-lg`}
                        >
                          <Calender
                            value={month}
                            onChange={(e) => {
                              setMonth(e);
                              handleCalender();
                            }}
                          />
                        </div>
                        <div
                          className="fixed inset-0 z-[99999]"
                          onClick={(e) => {
                            setOpenCalendar(false);
                          }}
                          style={{ zIndex: 999 }}
                        />
                      </>
                    )}
                  </div>
                  {/*right arrow*/}
                  <div
                    role="button"
                    onClick={() => {
                      if (!isDisabled) {
                        handleNextMonth();
                      }
                    }}
                    className={` ${
                      isDisabled ? "disabled-button" : ""
                    } w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs rotate-180 `}
                  >
                    <Arrow color="#344054" />
                  </div>
                </div>
              </>
            )}

            {/*loss*/}
            <div className={` ${page !== "loss" ? "hidden" : "visible flex h-full gap-6"}`}>
              <div
                role="button"
                className={`w-fit h-full flex cursor-pointer py-1 pr-2 pl-3 justify-between items-center rounded-md border border-solid border-[#D0D5DD] bg-[#FFF] shadow-xs`}
              >
                <div
                  className="flex items-center select-none cursor-pointer"
                  onClick={() => {
                    navigate(""); //graph-view
                    setSelectedView(false);
                  }}
                >
                  {!selectedView && <p className="text-sm font-semibold  text-[#6941C6] mr-3">{t("loss:graphView")}</p>}
                  <button className={`w-8 h-8 rounded flex items-center justify-center ${selectedView ? "bg-transparent" : "bg-primary-600"}`}>
                    <ChartView color={selectedView ? "#98A2B3" : "#fff"} />
                  </button>
                </div>

                <div
                  className="flex items-center select-none cursor-pointer"
                  onClick={() => {
                    navigate("list-view");
                    setSelectedView(true);
                  }}
                >
                  <button className={`w-8 h-8 rounded flex items-center justify-center ml-3 ${!selectedView ? "bg-transparent" : "bg-primary-600"}`}>
                    <ListView color={!selectedView ? "#98A2B3" : "#fff"} />
                  </button>
                  {selectedView && <p className="text-sm font-semibold text-[#6941C6] ml-3">{t("loss:listView")}</p>}
                </div>
              </div>
              <div
                onClick={() => {
                  navigate("loss-table");
                }}
                role="button"
                className={`w-fit h-full cursor-pointer flex py-2.5 px-3.5 justify-center items-center gap-1 rounded-lg border border-solid border-[#7F56D9] bg-[#7F56D9] shadow-xs`}
              >
                <Layout />
                <div className={`w-fit h-5 flex justify-center items-center px-0.5`}>
                  <span className={`text-[#FFF] text-sm font-semibold`}>{t("loss:typeOfLoss")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(Header);
