import React, { memo, useEffect, useMemo } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import {
  Cost3TIcon,
  CostTIcon,
  KwIcon,
  KwhIcon,
  LossFactoryIcon,
} from "../../../assets/icons/lossIcon";
import LossSelectCard from "../Card/LossSelectCard";
import LossTimeCard from "../Card/LossTimeCard";
import MultipleBarChart from "./MultipleBarChart";
import moment from "moment";
import { CcountIcon } from "../../../assets/icons/leftMenuIcons";
import CCountAndEnergyPieChart from "./CCountAndEnergyPieChart";
import NoData from "../../../steps/step-navigation/components/NoData";
import { useTranslation } from "react-i18next";

const CCountAndEnergyChart2 = ({
  totalData,
  selectedCategory,
  setSelectedCategory,
  setSelectedLegend,
  month,
  monthlyData,
  selectedSecondCategory,
  setSelectedSecondCategory,
  type,
  processData,
  selectedLegend,
  charts,
  yearlyProcessData,
  activeIcon,
  handleData,
  setSelectedNode,
  chartType,
}) => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const tabss = useMemo(
    () => [
      { key: "total_kw", name: "Total kVA" },
      { key: "total_kwh", name: "Total kVAh" },
      { key: "total_cost", name: "Total kVAh Cost" },
      { key: "threeTimeCost", name: "Total kVAh Cost For 3 Time" },
    ],
    []
  );
  const cCountTabss = useMemo(
    () => [
      { key: "total_kwh", name: "Total kVAh" },
      { key: "total_cCount", name: "Total C-Count" },
    ],
    [t]
  );

  const cCountTabs = useMemo(
    () => [
      {
        name: t("loss:electricKwh"),
        icon: <KwhIcon width={32} height={32} />,
        key: "total_kwh",
      },

      {
        name: "C-Count",
        icon: <CcountIcon width={32} height={32} />,
        key: "total_cCount",
      },
    ],
    [t]
  );

  const Tabs = useMemo(
    () => [
      {
        name: t("loss:electricKwh"),
        icon: <KwhIcon width={32} height={32} />,
        key: "total_kwh",
      },
      {
        name: t("loss:electricKw"),
        icon: <KwIcon />,
        key: "total_kw",
      },
      {
        name: t("loss:costt"),
        icon: <CostTIcon width={32} height={32} />,
        key: "total_cost",
      },
      {
        name: t("loss:costThree"),
        icon: <Cost3TIcon width={32} height={32} />,
        key: "threeTimeCost",
      },
    ],
    [t]
  );

  useEffect(() => {
    totalData?.map((item) => {
      if (item?.month == Number(moment(month).format("M"))) {
        setSelectedLegend(
          item?.operations.sort((a, b) => b?.total_kwh - a?.total_kwh)[0]
            ?.processId
        );
      }
    });
  }, [totalData, month]);

  return monthlyData?.operations?.length >= 1 ? (
    <>
      <div className="w-full flex flex-col gap-y-4 md:h-full md:max-h-full sm:h-full sm:max-h-full xs:h-full xs:max-h-full">
        <div
          className={`flex items-center gap-6 gap-y-3 px-1 xs:gap-y-6 h-full  md:h-[84px] md:flex-wrap xs:px-2 xs:flex-col md:gap-4 z-10 min-w-full ${
            charts === undefined
              ? "sm:h-[132px] sm:flex-wrap sm:justify-center sm:gap-y-6  "
              : ""
          }`}
        >
          {selectedLegend ? (
            <LossSelectCard
              setValue={setSelectedLegend}
              name={
                monthlyData?.operations?.find((op) => {
                  return op.processId == selectedLegend;
                })?.name
              }
              piece={
                monthlyData?.operations?.find((op) => {
                  return op.processId == selectedLegend;
                })?.[selectedCategory]
              }
              values={monthlyData?.operations?.map((item, index) => {
                return {
                  value: `${item.processId}`,
                  label: `${item.name}`,
                };
              })}
              pageName={charts}
            />
          ) : (
            <LossTimeCard
              name={t("settingTab:company")}
              icon={<LossFactoryIcon />}
              type={type}
              activeIcon={activeIcon}
            />
          )}
          {charts == "c-count"
            ? cCountTabs?.map((tab, index) => {
                return (
                  <div className="w-full lg:px-1 ">
                    <LossTimeCard
                      name={tab?.name}
                      icon={tab?.icon}
                      index={index}
                      activeIcon={tab?.activeIcon}
                      value={
                        yearlyProcessData?.find((item) => {
                          return item?.month == moment(month).format("M");
                        })?.[tab?.key]
                      }
                      color={tab?.color}
                      onClick={() => {
                        setSelectedCategory(tab?.key);
                      }}
                      selected={tab?.key}
                      type={type}
                      selectedCategory={selectedCategory}
                      bgColor={tab?.bgColor}
                    />
                  </div>
                );
              })
            : Tabs?.map((tab, index) => {
                return (
                  <div
                    className={`w-full lg:px-1 xs:min-w-full md:h ${
                      index === 0 || index == 1 || index == 2 || index == 3
                        ? "sm:w-[47%] md:min-w-[29%] md:max-w-[29%]"
                        : ""
                        ? "xs:w-[100%]"
                        : "sm:w-[47%]"
                    } ${index === 3 ? "xs:min-w-full" : ""}`}
                    key={index}
                  >
                    <LossTimeCard
                      name={tab?.name}
                      icon={tab?.icon}
                      activeIcon={tab?.activeIcon}
                      index={index}
                      value={
                        yearlyProcessData?.find((item) => {
                          return item?.month == moment(month).format("M");
                        })?.[tab?.key]
                      }
                      color={tab?.color}
                      onClick={() => {
                        setSelectedCategory(tab?.key);
                      }}
                      selected={tab?.key}
                      type={type}
                      selectedCategory={selectedCategory}
                      bgColor={tab?.bgColor}
                    />
                  </div>
                );
              })}
        </div>
        <div className="flex flex-row border-b pb-5 md:flex-col sm:flex-col xs:flex-col items-center gap-2 w-full h-full">
          <div className="w-1/2 overflow-hidden md:pt-12 md:w-full md:h-[300px] md:max-h-[300px] sm:w-[100%] md:mt-4 xs:w-[100%]">
            <MultipleBarChart
              charts={charts}
              handleData={handleData}
              categories={totalData
                ?.sort((a, b) => a.month - b.month)
                ?.map((item) => {
                  return `${item.month}`;
                })}
              series={
                charts == "c-count"
                  ? cCountTabss?.map((tab) => {
                      // const index = yearlyProcessData[0]?.month;
                      return {
                        name: tab?.name,
                        type: "bar",
                        barGap: 0,
                        emphasis: {
                          focus: "series",
                        },
                        data: yearlyProcessData
                          ?.sort((a, b) => a?.month - b?.month)
                          ?.map((item) => {
                            return item && item[tab?.key];
                          }),
                      };
                    })
                  : tabss?.map((tab) => {
                      //const index = yearlyProcessData[0]?.month + 12;
                      return {
                        name: tab?.name,
                        type: "bar",
                        barGap: 0,
                        emphasis: {
                          focus: "series",
                        },
                        data: yearlyProcessData
                          ?.sort((a, b) => a?.month - b?.month)
                          ?.map((item) => {
                            return item && item[tab?.key];
                          }),
                      };
                    })
              }
            />
          </div>
          <div className="flex items-center md:pt-[120px] gap-2 w-1/2 md:w-full sm:w-full xs:w-full h-full">
            <CCountAndEnergyPieChart
              data={processData?.equipments?.map((operation) => {
                return {
                  value: operation[selectedCategory],
                  id: operation.processId,
                  name: operation.name,
                  nodeId: operation.nodeId,
                };
              })}
              // processData?.equipments
              type={"energy"}
              charts={charts}
              grid={"energy"}
              isPie={false}
              setSelectedCategory={setSelectedSecondCategory}
              selectedCategory={selectedSecondCategory}
              selectedLegend={selectedLegend}
              setSelectedLegend={setSelectedLegend}
              setSelectedNode={setSelectedNode}
              chartType={chartType}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="w-full flex min-h-full gap-x-4  h-full  border-[#D0D5DD] border-[1px] rounded-lg ">
        <div
          style={{
            height: "100%",
            minHeight: (height - 200) / 2,
          }}
          className="w-full h-full flex items-center justify-center"
        >
          <NoData theme={"machine"} header={t("label:noData")} button={false} />
        </div>
      </div>
    </>
  );
};

export default memo(CCountAndEnergyChart2);
