import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CustomModal } from "../../../components";
import AssemblyModal from "./AssemblyModal";

const NewSelectOperationTypeModal = ({
  limit,
  items,
  onClose,
  openModalFunc,
  selectedInternalType,
  setselectedInternalType,
}) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState();

  const datas = [
    {
      icon: <DecompositionIcon />,
      operation: t("product:seperationOperation"),
      operationName: "Lazer Kesim",
      type: "decomposition_operation",
    },
    {
      icon: <AssemblyIcon />,
      operation: t("product:unificationOperation"),
      operationName: "Montaj, Kaynak",
      type: "assembly_operation",
    },
    {
      icon: <ConversionIcon />,
      operation: t("product:transformationOperation"),
      operationName: "CNC Freze, Dişli, Torna",
      type: "conversion_operation",
    },
  ];

  return (
    <div className="w-[516px] flex flex-col mt-6">
      <div className="flex flex-col gap-y-3">
        <div
          className={`flex flex-row gap-x-3 border ${
            selectedType === "internal"
              ? "border-[#FDB022]"
              : "border-secondary-300"
          } items-start p-4 rounded-xl`}
        >
          <div className="w-10 h-10 flex items-center justify-center p-[10px] rounded bg-[#FEF0C7]">
            <InternalOperationIcons />
          </div>
          <div className="flex flex-col">
            <span className="text-md font-semibold text-[#101828]">
              {t("product:internalOperation")}
            </span>
            <p className="text-sm font-normal text-secondary-600">
              {t("product:productionArea")}
            </p>
            {datas.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={(e) => {
                    if (index === 2) {
                      e.stopPropagation();
                      setSelectedType("internal");
                      setselectedInternalType(item.type);
                    }
                  }}
                  className="flex flex-row cursor-pointer items-center gap-x-3 mt-3"
                >
                  <div
                    onClick={() => {
                      if (index === 2) {
                        setSelectedType("internal");
                        if (!selectedInternalType) {
                          setselectedInternalType("decomposition_operation");
                        }
                      }
                    }}
                    className={`min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] w-4 rounded-full flex items-center justify-center ${
                      selectedInternalType === item?.type
                        ? " bg-[#DC6803]"
                        : "border border-secondary-300"
                    }`}
                  >
                    {selectedInternalType === item?.type && (
                      <div className="flex min-w-[6px] max-w-[6px] min-h-[6px] max-h-[6px] bg-white rounded-full"></div>
                    )}
                  </div>
                  {item?.icon}
                  <div className="flex flex-col items-start">
                    <p className="text-sm font-medium text-secondary-700">
                      {item?.operation}
                    </p>
                    <p className="font-normal w-[140px] truncate text-xs text-secondary-600">
                      {item?.operationName}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          onClick={() => {
            if (selectedInternalType) {
              setselectedInternalType(undefined);
              setSelectedType("external");
            } else {
              setSelectedType("external");
            }
          }}
          className={`flex flex-row gap-x-3 border cursor-pointer ${
            selectedType === "external"
              ? "border-[#FDB022]"
              : "border-secondary-300"
          }  items-start p-4 rounded-xl`}
        >
          <div className="w-10 h-10 flex items-center justify-center p-[10px] rounded bg-[#FEF0C7]">
            <ExternalOperationIcon />
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-start justify-between w-full">
              <span className="text-md font-semibold text-[#101828]">
                {t("product:externalOperation")}
              </span>
              <div
                className={`min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px] w-5 rounded-full flex items-center justify-center ${
                  selectedType === "external"
                    ? " bg-[#DC6803]"
                    : "border border-secondary-300"
                }`}
              >
                {selectedType === "external" && (
                  <div className="flex min-w-[8px] max-w-[8px] min-h-[8px] max-h-[8px] bg-white rounded-full"></div>
                )}
              </div>
            </div>
            <p className="text-sm font-normal text-secondary-600">
              {t("product:allOperations")}
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center gap-x-4 mt-12 w-full">
          <div className="flex flex-row items-center mt-2 w-full">
            <InfoIcon />
            <p className="text-sm ml-2 font-normal text-secondary-500">
              {t("product:totalLimit")}
            </p>
            <div className="ml-auto">
              <p className="text-sm font-semibold text-[#DC6803]">
                {items?.length + " / " + limit}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mt-4 gap-x-4 w-full">
            <Button
              colorType={"secondary-gray"}
              size={"lg"}
              label={t("buttons:stop")}
              onClick={onClose}
            />
            <Button
              onClick={openModalFunc}
              colorType={"primary-product"}
              size={"lg"}
              label={t("password:continue")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const InternalOperationIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M2.78515 11.1186C3.95524 11.1186 4.90379 10.1701 4.90379 8.99999C4.90379 7.8299 3.95524 6.88135 2.78515 6.88135C1.61505 6.88135 0.666504 7.8299 0.666504 8.99999C0.666504 10.1701 1.61505 11.1186 2.78515 11.1186Z"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2143 11.1186C16.3844 11.1186 17.333 10.1701 17.333 8.99999C17.333 7.8299 16.3844 6.88135 15.2143 6.88135C14.0443 6.88135 13.0957 7.8299 13.0957 8.99999C13.0957 10.1701 14.0443 11.1186 15.2143 11.1186Z"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99999 4.90394C10.1701 4.90394 11.1186 3.9554 11.1186 2.7853C11.1186 1.61521 10.1701 0.666656 8.99999 0.666656C7.8299 0.666656 6.88135 1.61521 6.88135 2.7853C6.88135 3.9554 7.8299 4.90394 8.99999 4.90394Z"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99999 17.3333C10.1701 17.3333 11.1186 16.3848 11.1186 15.2147C11.1186 14.0446 10.1701 13.096 8.99999 13.096C7.8299 13.096 6.88135 14.0446 6.88135 15.2147C6.88135 16.3848 7.8299 17.3333 8.99999 17.3333Z"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1187 2.78531H14.5085C15.2885 2.78531 15.9209 3.41769 15.9209 4.19774V7.00191"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0959 9H4.90381"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.0791 10.9981V13.8022C2.0791 14.5823 2.71148 15.2147 3.49153 15.2147H6.88136"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1187 15.2147H17.3333"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.35012 2.78531H0.666504"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3501 4.19772L4.76253 2.78529L3.3501 1.37286"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9209 16.6271L17.3333 15.2147L15.9209 13.8022"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ExternalOperationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M11.5245 8.34804C11.4349 8.25847 11.3902 8.21368 11.3753 8.1601C11.3623 8.11296 11.365 8.06131 11.3831 8.01254C11.4037 7.95709 11.4534 7.90733 11.553 7.8078L15.1783 4.18247L13.8988 2.90295L18.6259 2.65415L18.3771 7.38129L17.0976 6.10176L13.4722 9.72709C13.3727 9.82662 13.3229 9.87638 13.2675 9.89696C13.2187 9.91507 13.1671 9.91779 13.1199 9.90473C13.0664 9.88988 13.0216 9.8451 12.932 9.75552L11.5245 8.34804Z"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.92875 8.77026C6.73442 9.96458 6.73445 11.901 7.92878 13.0953C9.1231 14.2896 11.0595 14.2897 12.2538 13.0953M10.6491 3.05268L10.205 2.07646C10.0476 1.73047 9.66314 1.54901 9.29598 1.64737L6.13735 2.49373C5.77022 2.59211 5.52801 2.94148 5.56468 3.3198L5.6682 4.38734C5.78625 5.60503 4.76354 6.62773 3.54585 6.50969L2.47831 6.40616C2.1 6.36949 1.75063 6.6117 1.65224 6.97883L0.805885 10.1375C0.707527 10.5046 0.888987 10.8891 1.23497 11.0465L2.21119 11.4906C3.32478 11.9972 3.69913 13.3942 2.98803 14.3898L2.36464 15.2625C2.14373 15.5718 2.17878 15.9955 2.44752 16.2642L4.75986 18.5766C5.0286 18.8453 5.4523 18.8804 5.76156 18.6595L6.63431 18.036C7.62985 17.325 9.02688 17.6993 9.53349 18.8129L9.97765 19.7891C10.135 20.1351 10.5195 20.3166 10.8866 20.2182L14.0453 19.3718C14.4124 19.2735 14.6546 18.9241 14.6179 18.5458L14.5144 17.4783C14.3964 16.2606 15.4191 15.2378 16.6368 15.3559L17.7043 15.4594C18.0826 15.4961 18.432 15.2539 18.5304 14.8867L19.3767 11.7281C19.4751 11.361 19.2936 10.9765 18.9477 10.8191"
        stroke="#DC6803"
        strokeWidth="1.33"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_13271_70011)">
        <path
          d="M8.00016 10.6666V7.99998M8.00016 5.33331H8.00683M14.6668 7.99998C14.6668 11.6819 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6819 1.3335 7.99998C1.3335 4.31808 4.31826 1.33331 8.00016 1.33331C11.6821 1.33331 14.6668 4.31808 14.6668 7.99998Z"
          stroke="#667085"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13271_70011">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const DecompositionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M14.3847 0.666626H3.61501C1.9866 0.666626 0.666504 1.98672 0.666504 3.61513V14.3848C0.666504 16.0132 1.9866 17.3333 3.61501 17.3333H14.3847C16.0131 17.3333 17.3332 16.0132 17.3332 14.3848V3.61513C17.3332 1.98672 16.0131 0.666626 14.3847 0.666626Z"
        stroke="#667085"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <path
        d="M14.6114 8.99997H3.38818"
        stroke="#667085"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.07031 5.04929L8.9476 3.17197C8.97639 3.14318 9.02306 3.14318 9.05185 3.17197L10.9291 5.04929"
        stroke="#667085"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.9291 12.9507L9.05185 14.828C9.02306 14.8568 8.97639 14.8568 8.9476 14.828L7.07031 12.9507"
        stroke="#667085"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

const AssemblyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M14.3847 0.666626H3.61501C1.9866 0.666626 0.666504 1.98672 0.666504 3.61513V14.3848C0.666504 16.0132 1.9866 17.3333 3.61501 17.3333H14.3847C16.0131 17.3333 17.3332 16.0132 17.3332 14.3848V3.61513C17.3332 1.98672 16.0131 0.666626 14.3847 0.666626Z"
        stroke="#667085"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <path
        d="M14.6114 8.99997H3.38818"
        stroke="#667085"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.07031 3.99139L8.9476 5.86871C8.97639 5.8975 9.02306 5.8975 9.05185 5.86871L10.9291 3.99139"
        stroke="#667085"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.9291 14.0085L9.05185 12.1312C9.02306 12.1024 8.97639 12.1024 8.9476 12.1312L7.07031 14.0085"
        stroke="#667085"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

const ConversionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M14.3847 0.666687H3.61501C1.9866 0.666687 0.666504 1.98678 0.666504 3.61519V14.3848C0.666504 16.0133 1.9866 17.3334 3.61501 17.3334H14.3847C16.0131 17.3334 17.3332 16.0133 17.3332 14.3848V3.61519C17.3332 1.98678 16.0131 0.666687 14.3847 0.666687Z"
        stroke="#667085"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <path
        d="M14.6114 9H3.38818"
        stroke="#667085"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.9482 6.05147C11.9482 6.05147 11.9101 5.78318 10.8063 4.67284C9.70241 3.5625 7.91269 3.5625 6.80883 4.67284C6.41773 5.06624 6.16519 5.54624 6.05123 6.05147M11.9482 6.05147V4.156M11.9482 6.05147H10.0638"
        stroke="#667085"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.05176 11.9485C6.05176 11.9485 6.08986 12.2168 7.19372 13.3272C8.29759 14.4375 10.0873 14.4375 11.1912 13.3272C11.5823 12.9338 11.8348 12.4538 11.9488 11.9485M6.05176 11.9485V13.844M6.05176 11.9485H7.93617"
        stroke="#667085"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewSelectOperationTypeModal;
