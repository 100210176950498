import React, { memo, useEffect, useRef, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { AverageLeftIcon, AverageRightIcon, ResetRefresh } from "../../../../assets/icons/machineIcons";
import { GaugeNoData } from "../../../../assets/illustrations";
import { ThreeDots } from "../../../../assets/icons/gaugeChartIcons";
import { CustomModal } from "../../../../components";
import ResetKpiValueModal from "../../../settings/modal/ResetKpiValueModal";
import { avarageService } from "../../../../services/average.service";
import { useTranslation } from "react-i18next";

const EquipmentDetailGaugeChart = ({
  textColorPercent = localStorage.color_theme === "dark" ? "#D6D6D6" : "#595959",
  value,
  kpi1 = 0.2,
  timeMass = 0,
  kpi2 = 0.4,
  name,
  selectedProcess,
  gaugeData,
  step,
}) => {
  const menuRef = useRef();
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [view, setView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [loading, setLoading] = useState(false);

  const data = { id: selectedProcess, name: name?.toLowerCase() };

  const getReportValue = (data) => {
    setLoading(true);
    avarageService.GetReportType(data).then((response) => {
      setSelectedValue(response.data);
    });
    setLoading(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setView(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  var chartRef = useRef(null);
  const getOption = () => ({
    series: [
      {
        type: "gauge",
        center: ["50%", "85%"],
        startAngle: 180,
        endAngle: 0,
        radius: "170%",
        min: 0,
        max: 100,
        splitNumber: !value ? 5 : 5,
        axisLine: {
          show: true,
          roundCap: true,
          lineStyle: {
            width: 5,
            fontWeight: "bold",
            color:
              value === 0
                ? [[1, localStorage.color_theme === "dark" ? "#424242" : "#63666A"]]
                : [
                    [kpi1, localStorage.color_theme === "dark" ? "#F04438" : "#F22032"],
                    [kpi2, localStorage.color_theme === "dark" ? "#FEC84B" : "#F79919"],
                    [1, localStorage.color_theme === "dark" ? "#12B76A" : "#02C851"],
                  ],
            [[1, localStorage.color_theme === "dark" ? "#424242" : "#63666A"]]: [
              [kpi1, localStorage.color_theme === "dark" ? "#F04438" : "#F22032"],
              [kpi2, localStorage.color_theme === "dark" ? "#FEC84B" : "#F79919"],
              [1, localStorage.color_theme === "dark" ? "#12B76A" : "#02C851"],
            ],
          },
        },
        pointer: {
          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z'",
          length: "16%",
          width: 4,
          offsetCenter: [0, "-60%"],
          itemStyle: {
            color: "auto",
          },
        },
        title: {
          offsetCenter: [0, "-35%"],
          fontSize: 12,
          color: textColorPercent,
          fontWeight: "normal",
        },
        axisTick: {
          length: 2,
          distance: 2,
          lineStyle: {
            color: "auto",
            width: 0.5,
          },
        },
        splitLine: {
          length: 3,
          distance: 3,
          lineStyle: {
            color: "auto",
            width: 0.8,
          },
        },
        axisLabel: {
          color: textColorPercent,
          show: false,
          fontSize: 30,
          fontWeight: "bold",
          formatter: function (value) {
            return "%" + value;
          },
          padding: [-20, -50, -20, -50],
        },
        detail: {
          fontSize: 20,
          offsetCenter: ["-2%", "5%"],
          valueAnimation: true,
          formatter: function (value) {
            return "%" + Math.round(value);
          },
          color: "auto",
        },
        data: [
          {
            value: value * 100 || 0,
            name: ((timeMass / 3600).toFixed(2) || 0) + ` ${t("product:hours")}`,
          },
        ],
      },
    ],
  });

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`h-full min-w-[178px] w-full min-h-[168px] items-center flex flex-col group relative border rounded-lg border-secondary-200 shadow-xs step-${
          step + 7
        }`}
        id="main"
      >
        {timeMass >= 0 ? (
          <>
            <div className="flex w-full min-h-[36px] px-3 py-2 bg-[#F9FAFB] text-[#344054] text-sm font-semibold justify-center rounded-t-lg border-b border-[#E4E7EC]">
              <p className="w-[90%] truncate text-center">{name || "KPI Adi Bulunamadi"}</p>
            </div>
            <div className="flex relative w-full h-full items-center justify-center">
              {isSafari ? (
                <>
                  <ReactEcharts
                    ref={(e) => {
                      chartRef = e;
                    }}
                    option={getOption()}
                    style={{
                      width: 150,
                      height: 85,
                    }}
                  />
                </>
              ) : (
                <>
                  <div className="absolute w-fit h-fit py-[10px] justify-center items-center">
                    <div className="flex relative w-full h-full">
                      <ReactEcharts
                        ref={(e) => {
                          chartRef = e;
                        }}
                        option={getOption()}
                        style={{
                          width: 150,
                          height: 85,
                        }}
                      />
                      <div className="absolute text-black text-[8px] left-[-5px] top-[77px] font-semibold dark:text-[#D6D6D6]">%0</div>
                      <div className="absolute text-black text-[8px] right-[-5px] top-[77px] font-semibold dark:text-[#D6D6D6]">%100</div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {isHovered && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setView(!view);
                }}
                type="button"
                className="w-6 h-6 group flex items-center absolute  top-2 right-1 -mt-0.5 justify-center cursor-pointer "
              >
                <ThreeDots color="#667085" className="opacity-100" />
              </div>
            )}

            <div className="flex w-full items-center justify-between px-3 py-[6px] mt-auto bg-[#F9FAFB] rounded-b-lg border-t border-[#E4E7EC]">
              <div className="flex items-center gap-x-1">
                <AverageLeftIcon width={10} height={10} />
                <p className="text-[#344054] font-medium text-[10px] leading-4">%{(kpi1 * 100).toFixed(0)}</p>
                <p className="text-[#475467] text-[12px] font-normal leading-4">avg</p>
              </div>
              <div className="flex items-center gap-x-1">
                <p className="text-[#475467] text-[12px] font-normal leading-4">max</p>
                <p className="text-[#344054] font-medium text-[10px] leading-4">%{parseInt(kpi2 * 100) > 100 ? 100 : parseInt(kpi2 * 100)}</p>
                <AverageRightIcon width={10} height={10} />
              </div>
            </div>
          </>
        ) : (
          <img src={GaugeNoData} className="w-[124px] h-[66px] mx-auto mt-auto mb-auto" />
        )}
        {view === true && (
          <div
            ref={menuRef}
            className="absolute w-[195px] z-50 flex flex-col py-1  top-[30px] right-[12px]  border-[1px]  border-secondary-200  shadow-lg bg-white rounded-lg cursor-pointer"
          >
            <div className="px-[6px] py-[1px] w-full flex-row items-center z-[100]">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  getReportValue(data);
                  setIsOpen(true);
                }}
                type="button"
                className={`flex flex-row w-full items-center gap-2 hover:bg-secondary-50 bg-white px-[6px] py-[10px] min-w-[144px] min-h-[40px] cursor-pointer`}
              >
                <div className="w-4 h-4 flex items-center text-[#667085]">
                  <ResetRefresh color={"#667085"} />
                </div>
                <p className="text-secondary-700 text-sm font-medium">{t("gauge:resetKpiValue")}</p>
              </button>
            </div>
          </div>
        )}
      </div>

      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={t("gauge:resetKpiValue")}
        children={<ResetKpiValueModal gaugeData={gaugeData} selectedValue={selectedValue} setValue={setSelectedValue} loading={loading} />}
      />
    </>
  );
};

export default EquipmentDetailGaugeChart;
