import React, { useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import AddRawMaterialProductCard from "../components/AddRawMaterialProductCard";
import UnitQuantityModal from "./UnitQuantityModal";

const AddRawMaterialModal = ({ setIsFirstModal, isFirstModal }) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");

  const fakeData = [
    {
      productName: "Normal Boru",
      unit: "metre",
      unitPrice: "100",
      id: 1,
    },
    {
      productName: "Demir Boru",
      unit: "gram",
      unitPrice: "232",
      id: 2,
    },
    {
      productName: "Ahşap",
      unit: "kilogram",
      unitPrice: "450",
      id: 3,
    },
    {
      productName: "Demir Sac",
      unit: "metrekare",
      unitPrice: "234",
      id: 4,
    },
    {
      productName: "Demir Boru",
      unit: "gram",
      unitPrice: "232",
      id: 5,
    },
    {
      productName: "Ahşap",
      unit: "kilogram",
      unitPrice: "450",
      id: 6,
    },
    {
      productName: "Demir Sac",
      unit: "metrekare",
      unitPrice: "234",
      id: 7,
    },
    {
      productName: "Demir Boru",
      unit: "gram",
      unitPrice: "232",
      id: 8,
    },
  ];

  return (
    <>
      {!isFirstModal ? (
        <>
          <UnitQuantityModal selectedProduct={selectedProduct} />
        </>
      ) : (
        <div className="w-[400px] flex flex-col items-start">
          <p className="text-sm font-normal text-secondary-600 mt-2">
            Ürün için gerekli tüm hammaddeleri ekleyin
          </p>
          <hr className="h-[1px] w-full  bg-secondary-300 mt-5" />
          <div className="w-full pt-4 pb-5">
            <SearchInput setSearchValue={setSearchValue} />
          </div>
          <div className="flex flex-col w-full gap-y-4 h-[520px] overflow-y-auto scrollbar-hide">
            {fakeData.map((item, index) => {
              return (
                <AddRawMaterialProductCard
                  unit={item?.unit}
                  unitPrice={item?.unitPrice}
                  name={item?.productName}
                  stockId={item?.id}
                  onClick={() => {
                    setSelectedProduct(item);
                    setIsFirstModal(false);
                  }}
                />
              );
            })}
          </div>
          <hr className="h-[1px] w-full bg-secondary-200 mt-2" />
        </div>
      )}
    </>
  );
};

export default AddRawMaterialModal;
