import React from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import NoAvatar from "../../../components/avatar/NoAvatar";
import moment from "moment";
import {
  CalendarIcon2,
  CalendarPlus,
  CoinIcon,
  Coins,
  MeasuringIcon,
  OrderIcon2,
  StockIcon,
} from "../../../assets/icons/commonIcons";

const PurchaseDetailTable = ({ headerData }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col ">
      <p className="text-[#101828] text-[20px] font-semibold my-4 mb-6">
        {t("product:rawMaterial")}
      </p>

      <div className="flex flex-row items-center sm:items-start w-full  h-[108px] sm:h-[216px] gap-x-6 pb-6 ">
        {headerData?.rawMaterial?.image || headerData?.product?.image ? (
          <img
            src={generateFileUrl(
              headerData?.rawMaterial?.image || headerData?.product?.image
            )}
            className="w-[144px] min-w-[144px] h-[108px] max-h-[108px] rounded border border-secondary-300 shadow-xs"
          />
        ) : (
          <div className="w-[144px] min-w-[144px] h-[108px] max-h-[108px] flex rounded  overflow-hidden">
            <NoAvatar
              name={
                headerData?.rawMaterial?.name ||
                headerData?.product?.name ||
                "---"
              }
              rounded={4}
              theme={"product"}
              minHeight="108px"
              minWidth="144px"
              fontSize={48}
            />
          </div>
        )}
        <div className="flex-1 flex flex-row items-center border rounded-lg h-[108px] sm:h-[216px] sm:flex-col border-secondary-300 lg:w-full md:w-full">
          <div className="flex flex-col w-1/4 sm:w-full sm:flex-row border-r sm:border-0 h-full ">
            <div className="flex  items-center w-full h-1/2 sm:h-[54px] sm:border-r border-b border-b-secondary-300">
              <div className="px-5 py-2 flex flex-row gap-x-2 items-center">
                <OrderIcon2 />
                <div className="flex flex-col items-start">
                  <p className="text-xs font-normal text-secondary-600">
                    {t("product:orderNo")}
                  </p>
                  <p className="text-sm font-semibold text-secondary-700">
                    {headerData?.orderNo || "---"}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex  items-center  w-full h-1/2 sm:h-[54px] sm:border-b sm:border-b-secondary-300">
              <div className="px-5 py-2 flex flex-row gap-x-2 items-center w-full">
                <StockIcon />
                <div className="flex flex-col items-start">
                  <p className="text-xs font-normal text-secondary-600">
                    {t("product:stockNo")}
                  </p>
                  <p className="text-sm font-semibold text-secondary-700">
                    {headerData?.product?.stockCode || "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/4  sm:w-full sm:flex-row border-r sm:border-0 h-full ">
            <div className="flex  items-center  w-full h-1/2 sm:h-[54px] sm:border-r border-b border-b-secondary-300">
              <div className="px-5 py-2 flex flex-row gap-x-2 items-center w-full">
                <MeasuringIcon color={"#475467"} />
                <div className="flex flex-col items-start">
                  <p className="text-xs font-normal text-secondary-600">
                    {t("product:quantitys")}
                  </p>
                  <p className="text-sm font-semibold text-secondary-700">
                    {headerData?.orderedUnit || "--"}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex  items-center  w-full h-1/2 sm:h-[54px] sm:border-b sm:border-b-secondary-300">
              <div className="px-5 py-2 flex flex-row gap-x-2 items-center w-full">
                {headerData?.rawMaterial?.image ||
                headerData?.product?.image ? (
                  <img
                    className="w-8 h-6 rounded"
                    src={generateFileUrl(
                      headerData?.rawMaterial?.image ||
                        headerData?.product?.image
                    )}
                  />
                ) : (
                  <div className="w-[32px] min-w-[32px] h-[24px] max-h-[24px] flex rounded  overflow-hidden">
                    <NoAvatar
                      name={
                        headerData?.rawMaterial?.name ||
                        headerData?.product?.name ||
                        "---"
                      }
                      rounded={4}
                      theme={"product"}
                      minHeight="24px"
                      minWidth="32px"
                      fontSize={12}
                    />
                  </div>
                )}
                <div className="flex flex-col items-start w-full">
                  <p className="text-xs font-normal text-secondary-600">
                    {t("product:productss")}
                  </p>
                  <p className="text-sm font-semibold text-secondary-700 truncate w-[85%] ">
                    {headerData?.rawMaterial?.name ||
                      headerData?.product?.name ||
                      "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/4  sm:w-full sm:flex-row border-r sm:border-0 h-full ">
            <div className="flex  items-center  w-full h-1/2 sm:h-[54px] sm:border-r border-b border-b-secondary-300">
              <div className="px-5 py-2 flex flex-row gap-x-2 items-center w-full">
                <Coins />
                <div className="flex flex-col items-start">
                  <p className="text-xs font-normal text-secondary-600">
                    {t("product:unitsCost")}
                  </p>
                  <p className="text-sm font-semibold text-secondary-700">
                    {headerData?.unitCost}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex  items-center  w-full h-1/2 sm:h-[54px] sm:border-b sm:border-b-secondary-300">
              <div className="px-5 py-2 flex flex-row gap-x-2 items-center w-full">
                <CoinIcon />
                <div className="flex flex-col items-start">
                  <p className="text-xs font-normal text-secondary-600">
                    {t("product:totalCost")}
                  </p>
                  <p className="text-sm font-semibold text-secondary-700">
                    {headerData?.totalCost}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/4  sm:w-full sm:flex-row border-r sm:border-0 h-full ">
            <div className="flex  items-center  w-full h-1/2 sm:h-[54px] sm:border-r border-b border-b-secondary-300">
              <div className="px-5 py-2 flex flex-row gap-x-2 items-center">
                <CalendarPlus />
                <div className="flex flex-col items-start">
                  <p className="text-xs font-normal text-secondary-600">
                    {t("report:creationDate")}
                  </p>
                  <p className="text-sm font-semibold text-secondary-700">
                    {moment(headerData?.createdDate).format("DD/MM/YY") ||
                      "---"}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex  items-center  w-full h-1/2 sm:h-[54px] sm:border-b sm:border-b-secondary-300">
              <div className="px-5 py-2 flex flex-row gap-x-2 items-center w-full">
                <CalendarIcon2 />
                <div className="flex flex-col items-start">
                  <p className="text-xs font-normal text-secondary-600">
                    {t("table:deliveryDate")}
                  </p>
                  <p className="text-sm font-semibold text-secondary-700">
                    {moment(headerData?.estimatedDeliveryDate).format(
                      "DD/MM/YY"
                    ) || "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/4 sm:w-full sm:flex-row h-full ">
            <div className="flex  items-center  w-full h-1/2 sm:h-[54px] sm:border-r sm:border-0 border-b border-b-secondary-300 ">
              <div className="px-5 py-2 flex flex-row gap-x-2 items-center w-full">
                <MeasuringIcon color={"#079455"} />
                <div className="flex flex-col items-start">
                  <p className="text-xs font-normal text-secondary-600">
                    {t("product:approvedQuantitys")}
                  </p>
                  <p className="text-sm font-semibold text-success-600">
                    {headerData?.acceptedUnit || "---"}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex  items-center  w-full h-1/2 sm:h-[54px] sm:border-0 sm:border-b-secondary-300">
              <div className="px-5 py-2 flex flex-row gap-x-2 items-center">
                <MeasuringIcon color={"#D92D20"} />
                <div className="flex flex-col items-start">
                  <p className="text-xs font-normal text-secondary-600">
                    {t("product:rejectedQuantityss")}
                  </p>
                  <p className="text-sm font-semibold text-error-600">
                    {headerData?.rejectedUnit || "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseDetailTable;
