import React, { useState } from "react";
import { NoEquIcon, ProductDetailIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../CustomImage";
import {
  AcceptedIcon,
  ClockIcon,
  DeleteIcon,
  DotsVerticalIcon,
  EndClockIcon,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  FlexProductIcon,
  PersonnelDetailIcon,
  RejectedIcon,
  StartClockIcon,
  TargetIcon,
} from "../card-icons";
import { useTranslation } from "react-i18next";
import NoAvatar from "../../avatar/NoAvatar";
import AlertModal from "../../alert-Modal/Alert-Modal";
import JunctionUpdate from "../../modal/JunctionUpdate";
import CustomModal from "../../modal/CustomModal";
import { useNavigate } from "react-router-dom";
import { junctionService } from "../../../services/junction.service";
import { Toast } from "../../../utils/toastify/toast";
import { secondToHoursAndMinute2 } from "../../../utils/secondToHoursAndMinute";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import { useStore } from "../../../hooks/useStores";
const OperationDetailSelectedJunctionDoneCard = ({ data, refetch, items }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isJunction, setIsJunction] = useState(false);
  const { auth } = useStore();

  const menuOptions = [
    {
      label: t("product:personnelDetail"),
      value: "personnelDetail",
      icon: <PersonnelDetailIcon />,
    },
    // {
    //   label: t("product:productDetail"),
    //   value: "productDetail",
    //   icon: <ProductDetailIcon />,
    // },
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:editJunction"),
      value: "editJunction",
      icon: <EquipmentEditIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const onDeleted = async () => {
    await junctionService.deleteJunction(data?.junctionId, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data?.junctionId);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  const convertSeconds = (seconds) => {
    if (isNaN(seconds)) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };

  let formattedTime =
    items?.type === "setup"
      ? convertSeconds(
          items?.productType === "flex" ? items?.flexHeader?.estimatedSettingsTime * 60 : items?.header?.internalOperation?.estimatedSettingsTime * 60
        )
      : convertSeconds(
          items?.productType === "flex"
            ? items?.flexHeader?.estimatedTime
            : items?.header?.totalEstimatedTime / items?.productType === "flex"
            ? items?.flexHeader?.quantity
            : items?.header?.piece
        );

  return (
    <>
      <div className="cursor-pointer flex flex-row items-center gap-x-3 w-full">
        <div className="flex items-center flex-col gap-y-1 justify-center border border-secondary-300 rounded-t-[4px] rounded-b-lg p-1 w-[88px] min-w-[88px] bg-[#F9FAFB]">
          <p className="text-xs w-[75px] truncate text-center font-semibold text-secondary-700">
            {items?.station?.metadata?.brand + "" + items?.station?.metadata?.model || "--"}
          </p>
          {items?.station?.metadata?.image ? (
            <div
              data-tooltip-id={`${items?.id}`}
              data-tooltip-place={"right"}
              data-tooltip-content={`
        ${items?.station?.metadata?.brand} ${items?.station?.metadata?.model} 
        `}
            >
              <CustomImage
                borderRadius={2}
                src={items?.station?.metadata?.image}
                style={{
                  width: 80,
                  height: 60,
                  borderRadius: 4,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div className="w-[80px] min-w-[80px] h-[60px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
              <NoEquIcon />
            </div>
          )}
        </div>
        <div
          className={`flex w-full flex-col border border-t-4 cursor-pointer ${
            items?.type == "work" ? "border-t-success-500 " : items?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
          } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
        >
          <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
            <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
              {items?.productType === "flex" ? (
                <>
                  {items?.flexProduct.image ? (
                    <div
                      data-tooltip-id={`${items?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
            ${items?.flexProduct?.name} 
            `}
                    >
                      <CustomImage
                        borderRadius={2}
                        src={items?.flexProduct?.image}
                        style={{
                          width: 64,
                          height: 48,
                          minWidth: 64,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      data-tooltip-id={`${items?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
            ${items?.flexProduct?.name} 
            `}
                      className="w-[64px] min-w-[64px] h-[48px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl cursor-pointer"
                    >
                      {items?.flexProduct?.name?.slice(0, 2)?.toUpperCase()}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {items?.job?.product?.image ? (
                    <div
                      data-tooltip-id={`${items?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
            ${items?.job?.product?.name} 
            `}
                    >
                      <CustomImage
                        borderRadius={2}
                        src={items?.job?.product?.image}
                        style={{
                          width: 64,
                          height: 48,
                          minWidth: 64,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      data-tooltip-id={`${items?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
            ${items?.job?.product?.name} 
            `}
                      className="w-[64px] min-w-[64px] h-[48px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl cursor-pointer"
                    >
                      {items?.job?.product?.name?.slice(0, 2)?.toUpperCase()}
                    </div>
                  )}
                </>
              )}

              <div className="flex flex-col items-start gap-y-[6px]">
                <div className="flex flex-row items-center gap-x-1 w-full">
                  {items?.productType === "flex" ? (
                    <>
                      <FlexProductIcon />
                      <p className="text-sm font-semibold text-secondary-900 truncate w-[120px]">{items?.flexProduct?.name || "--"}</p>
                    </>
                  ) : (
                    <p className="text-sm font-semibold text-secondary-900 truncate w-[120px]">{items?.job?.product?.name || "--"}</p>
                  )}
                </div>
                <div className="flex flex-row items-center">
                  <ClockIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{secondToHoursAndMinute2(data?.startDate, data?.endDate)}</p>
                  <p className="text-xxs font-normal ml-0.5 mt-[1px] text-secondary-600">{t("product:timeStarted")}</p>
                </div>
                {/* <div className="flex flex-row items-center gap-x-2">
                  <div className="flex flex-row items-center">
                    <AcceptedIcon />
                    <p className="text-xs font-medium ml-1 text-secondary-600">{data?.acceptedWorkCount || "0"}</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <RejectedIcon />
                    <p className="text-xs font-medium ml-1 text-secondary-600">{data?.rejectedWorkCount || "0"}</p>
                  </div>
                </div> */}
              </div>
            </div>
            {items?.user?.avatar ? (
              <div
                data-tooltip-id={`${items?.id}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`
        ${items?.user?.name} ${items?.user?.lastName} 
        `}
                className="cursor-pointer ml-auto"
              >
                <CustomImage
                  src={items?.user?.avatar}
                  style={{
                    width: 48,
                    height: 48,
                    minWidth: 48,
                    minHeight: 48,
                    flexDirection: "column",
                    borderRadius: 100,
                    display: "flex",
                    border: "1px solid #0000001A",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                data-tooltip-id={`${items?.id}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`
         ${items?.user?.name} ${items?.user?.lastName}
        `}
                className="cursor-pointer ml-auto"
              >
                <NoAvatar size={48} fontSize={22} name={items?.user?.name} color={"#B54708"} />
              </div>
            )}
          </div>
          <div
            className={`flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full ${
              items?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
            } border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg`}
          >
            <div className="flex flex-row items-center">
              <TargetIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{formattedTime || "--"}</p>
              <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMinActualss")}</p>
            </div>
            {/* <div className="flex flex-row items-center">
              <StartClockIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{moment(timeFormatter(data?.startDate)?.formatted)?.format("HH:mm") || "--"}</p>
              <p className="text-xxs font-normal ml-0.5 mt-[1px] text-secondary-600">{t("product:timeStarteds")}</p>
            </div>
            <div className="flex flex-row items-center">
              <EndClockIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{moment(timeFormatter(data?.endDate)?.formatted)?.format("HH:mm")}</p>
              <p className="text-xxs font-normal ml-0.5 mt-[1px] text-secondary-600">{t("product:timeEnds")}</p>
            </div> */}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowDropDown(!showDropDown);
            }}
            className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
          >
            <DotsVerticalIcon />
          </div>
          {showDropDown && (
            <div
              className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
            >
              {menuOptions?.map((item, index) => {
                const formattedDate = getFormattedNavigateDate(data?.startDate, auth.user.company.dayStartHour);
                return (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();

                      //   if (item.value === "productDetail") {
                      //     navigate(`/app/product/mrp/work-in-process/detail/${item?.job?.id}+${item?.header?.step}`);
                      //   }
                      if (item?.value === "personnelDetail") {
                        navigate(`/app/machine/digital-twin/user-detail/${data?.status}/${formattedDate}`);
                      }

                      if (item.value === "equipmentDetail") {
                        navigate(`/app/machine/digital-twin/equipment-details/${items?.deviceId}-${items?.nodeId}/${formattedDate}`);
                      }
                      if (item.value === "delete") {
                        setIsAlert(true);
                      }
                      if (item.value === "editJunction") {
                        setIsJunction(true);
                      }
                      setShowDropDown(false);
                    }}
                    key={index}
                    type="button"
                    className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                      item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                    }`}
                  >
                    {item?.icon}
                    <p
                      className={`font-medium text-sm  ${
                        item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"
                      }`}
                    >
                      {item.label}
                    </p>
                  </button>
                );
              })}
            </div>
          )}
        </div>
        <Tooltip className="z-[120]" id={`${data?.junctionId}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={data?.junctionId}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default OperationDetailSelectedJunctionDoneCard;
