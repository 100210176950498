import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { processService } from "../../../services";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { DashboardPlusIcon } from "../../../assets/icons/machineIcons";
import DashboardChartsLoading from "../Twin/chart/DashboardChartsLoading";
import DashboardSwiperItem from "./DashboardSwiperItem";
import DashboardCompanyInfo from "../Twin/components/DashboardCompanyInfo";
import { CustomModal } from "../../../components";
import { AddProcessModal } from "../../../steps/process";
import CompanyDetailChart from "../CompanyDetailChart";

const DashboardSlider = memo(({ enabled, selectedProcess, setSelectedProcess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visibleButton, setVisibleButton] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isOpenCompanyDetail, setIsOpenCompanyDetail] = useState(false);
  const [visibleRightButton, setVisibleRightButton] = useState(false);
  const contentWrapper = useRef(null);
  const [items, setItems] = useState([]);
  const [operation, setOperation] = useState();
  const [openOperation, setOpenOperation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { isLoading, data, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["getProcessUtilization"],
    queryFn: async () => await processService.getQueryProcessUtilization(),
  });

  const handleData = useCallback(async () => {
    await processService.activeProcesses().then((res) => {
      res?.data?.map((d) => {
        return {
          ...d,
          responsibleUser: d.responsibleUser.map((r) => r.id),
        };
      });
      setOperation(res?.data);
      refetch();
    });
  }, [refetch]);

  useEffect(() => {
    handleData();
  }, []);

  const handleEdit = useCallback(
    (id) => {
      if (operation?.length > 0) {
        const findOperation = operation?.find((a) => a?.id === id);
        setSelectedItem(findOperation);
      }
      setOpenOperation(true);
    },
    [operation]
  );

  const slide = useCallback((shift) => {
    contentWrapper.current.scrollLeft += shift;
  }, []);

  const sideScroll = useCallback((element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  }, []);

  useEffect(() => {
    setItems({
      ...data,
      operations: data?.operations?.sort((a, b) => b?.actual - a?.actual),
    });
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        const container = document.getElementById("container");
        if (container?.scrollWidth > container?.clientWidth) {
          setVisibleRightButton(true);
        } else {
          setVisibleRightButton(false);
        }
      }, 300);
    }
  }, [data, isLoading]);

  const memoizedData = useMemo(() => data, [data]);

  return (
    <>
      <div
        onClick={() => {
          setOpenOperation(true);
          setSelectedItem(null);
        }}
        className="flex items-center cursor-pointer justify-center bg-white shadow-xs border-secondary-200 border rounded-md p-2 hover:bg-[#F9FAFB] active:shadow-ring-gray w-9 h-[158px]"
      >
        <DashboardPlusIcon />
      </div>
      <div className="flex items-center w-[250px]">
        <DashboardCompanyInfo
          onClick={() => {
            // setIsOpenCompanyDetail(true);
            navigate("/app/machine/digital-twin/company-details");
          }}
          // data={data}
          data={memoizedData}
        />
      </div>
      <div
        id="container"
        ref={contentWrapper}
        onScroll={(e) => {
          if (!isIntersecting) {
            setVisibleButton(true);
          }
        }}
        className="flex items-center w-[100%] overflow-x-auto gap-6 pb-3  h-[160px] min-h-[160px]"
      >
        {isLoading ? (
          <>
            <div className="flex gap-x-5">
              {[...new Array(10)].map((d, index) => (
                <div className="w-[166px] h-[134px] flex items-center" key={index}>
                  <DashboardChartsLoading />
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="w-[166px] h-[138px] flex gap-5">
              {items?.operations?.map((process, j) => {
                return (
                  process.type !== "planned" && (
                    <DashboardSwiperItem
                      key={j}
                      index={j}
                      process={process}
                      selectedProcess={selectedProcess}
                      setSelectedProcess={setSelectedProcess}
                      // onDoubleClickProcess={getOperationDetails}
                      enabled={enabled}
                      processId={process.id}
                      setVisibleButton={setVisibleButton}
                      setIsIntersecting={setIsIntersecting}
                      dataLength={items?.length}
                      setVisibleRightButton={setVisibleRightButton}
                      handleEdit={handleEdit}
                    />
                  )
                );
              })}
            </div>
          </>
        )}
      </div>

      <CustomModal
        children={
          <AddProcessModal data={operation} isOpen={openOperation} setIsOpen={setOpenOperation} selectedProcess={selectedItem} handleData={handleData} />
        }
        isOpen={openOperation}
        setIsOpen={setOpenOperation}
        modalTitle={selectedItem ? t("addProcess:updateProcess") : t("addProcess:addNewProcess")}
      />
      <CustomModal isOpen={isOpenCompanyDetail} setIsOpen={setIsOpenCompanyDetail} modalTitle={"Company Detail"} children={<CompanyDetailChart />} />
    </>
  );
});

export default DashboardSlider;
