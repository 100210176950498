import React from "react";
import generateFileUrl from "../../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";

const ExpandableHeaderCard = ({
  selectedOperation = null,
  id = "",
  name = "Name",
  equipmentCount = 0,
  productCount = 0,
  onClick = () => {},
  icon = null,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={onClick}
        className={`min-w-[180px] rounded-t-lg ${
          selectedOperation?.id === id
            ? "border-b-[2px] border-b-[#A15C07] h-[90px]"
            : "h-[90px]"
        } rounded-b-none border cursor-pointer border-seconday-300  select-none flex flex-col`}
      >
        <div className="flex items-center h-[40px] justify-center w-full gap-x-2 p-1.5  border-b border-b-[#E4E7EC]">
          <p
            className={`text-base  truncate ${
              selectedOperation?.id === id
                ? "text-[#B54708] font-semibold"
                : "text-[#667085] font-medium"
            } `}
          >
            {name}
          </p>
        </div>
        <div className="flex flex-row items-center w-full h-[50px]">
          <div
            className={`flex flex-col items-center w-1/2  border-r ${
              selectedOperation?.id === id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"
            }`}
          >
            <div className="w-full h-[18px] flex items-center justify-center border-b border-b-[#E4E7EC]">
              <p
                className={`text-xs ${
                  selectedOperation?.id === id
                    ? "text-[#344054] font-medium"
                    : "text-[#667085] font-normal"
                }`}
              >
                {t("reports:planned")}
              </p>
            </div>
            <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
              <p
                className={`text-sm ${
                  selectedOperation?.id === id
                    ? "font-semibold text-[#475467]"
                    : "font-medium text-[#667085]"
                }`}
              >
                40 gün
              </p>
            </div>
          </div>
          <div
            className={`flex flex-col items-center w-1/2  ${
              selectedOperation?.id === id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"
            }`}
          >
            <div className="w-full h-[18px] flex items-center justify-center border-b border-b-[#E4E7EC]">
              <p
                className={`text-xs ${
                  selectedOperation?.id === id
                    ? "text-[#344054] font-medium"
                    : "text-[#667085] font-normal"
                }`}
              >
                {t("product:unplanned")}
              </p>
            </div>
            <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
              <p
                className={`text-sm ${
                  selectedOperation?.id === id
                    ? "font-semibold text-[#A15C07]"
                    : "font-medium text-[#667085]"
                }`}
              >
                4 gün
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpandableHeaderCard;
