import React, { useEffect, useState } from "react";
import * as yup from "yup";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStore } from "../../hooks/useStores";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TimeInput from "../../components/inputs/TimeInput";
import { settingsStore } from "../../stores/settings.store";
import { Button, Input, UploadProfile } from "../../components";
import { companyService } from "../../services/company.service";
import { stepData } from "../step-navigation/components/StepHeaderTab";
import Skeleton from "react-loading-skeleton";
import HourTimeInput from "../../components/inputs/HourTimeInput";
import { useQuery } from "@tanstack/react-query";
import { Toast } from "../../utils/toastify/toast";

const Company = ({ type, theme }) => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [startTime, setStartTime] = useState();

  const index = stepData.findIndex((route) => pathname.includes(route.path));
  const { data, isLoading } = useQuery({
    retry: 0,
    queryKey: "company-details",
    queryFn: async () => await companyService.activeCompany(),
  });

  const schema = yup.object({
    image: yup.string(),
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    address: yup
      .string()
      .notRequired()
      .typeError(`${t("validation:string")}`),
    email: yup
      .string()
      .notRequired()
      .typeError(`${t("validation:string")}`),
    phone: yup
      .number()
      .notRequired()
      .typeError(`${t("validation:number")}`),
    fiscalYearStart: yup.date().required(`${t("validation:empty")}`),
    contactName: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    contactEmail: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    contactPhone: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    taxOffice: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    taxNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    country: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    timeZone: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    nodeLimit: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    userLimit: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    t1Standard: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    t1: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    t2: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    t3: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      image: "",
      name: "",
      address: "",
      email: "",
      phone: "",
      country: "",
      timeZone: "",
      userLimit: "",
      nodeLimit: "",
      contactPhone: "",
      contactEmail: "",
      contactName: "",
      fiscalYearStart: "",
      taxOffice: "",
      taxNo: "",
      t1Standard: "",
      t1: "",
      t2: "",
      t3: "",
    },
  });

  //handling inputs
  const handleCompany = async () => {
    setValue("image", data?.data?.image);
    setValue("name", data?.data?.name);
    setValue("address", data?.data?.address);
    setValue("email", data?.data?.email);
    setValue("phone", data?.data?.phone);
    setValue("fiscalYearStart", data?.data?.fiscalYearStart);
    setValue("contactName", data?.data?.contactName);
    setValue("contactEmail", data?.data?.contactEmail);
    setValue("contactPhone", data?.data?.contactPhone);
    setValue("country", data?.data?.country);
    setValue("timeZone", data?.data?.timeZone);
    setValue("nodeLimit", data?.data?.nodeLimit);
    setValue("userLimit", data?.data?.userLimit);
    setValue("taxOffice", data?.data?.taxOffice);
    setValue("taxNo", data?.data?.taxNo);
    setValue("t1Standard", data?.data?.feeCoefficients.t1Standard);
    setValue("t1", data?.data?.feeCoefficients.t1);
    setValue("t2", data?.data?.feeCoefficients.t2);
    setValue("t3", data?.data?.feeCoefficients.t3);
    setStartTime(data?.data?.dayStartHour);
  };
  const onSubmit = async (data) => {
    await companyService
      .updateCompany({
        ...data,
        dayStartHour: `${startTime}`,
      })
      .then((res) => {
        if (res?.data?.code === 1) {
          auth.setCompany(res?.data?.data);
          setTimeout(() => {
            navigate("/step/" + stepData[index + 1].path);
          }, 1500);
        } else {
          Toast("error", "Hata oluştu");
        }
      });
  };

  useEffect(() => {
    handleCompany();
  }, [data]);

  return (
    <>
      <div className="flex flex-col xs:flex-c ol  items-center gap-10 sm:hidden md:hidden lg:w-[90%] relative xl:w-[90%] h-full justify-center w-full scrollbar-hide">
        {type !== "editModal" ? (
          <span
            className={`text-3xl xs:mt-32 font-semibold mt-6 xs:text-2xl dark:text-[#FAFAFA] text-[#101828]`}
          >
            {t("addCompany:title")}
          </span>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" h-full w-8/12 xs:w-[87%] lg:w-[85%] flex flex-col items-center justify-center xs:items-start xs:justify-start gap-6"
        >
          <div className="flex flex-row w-full xs:flex-col items-start gap-6 xs:overflow-y-auto xs:items-start xs:h-[74%] xs:gap-y-10 scrollbar-hide z-[9999]">
            <div
              className={`w-full gap-y-4 mb-4 h-full flex flex-col items-start`}
            >
              {isLoading ? (
                <div className={`w-full gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={40}
                    width={320}
                    count={6}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              ) : (
                <>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <UploadProfile
                        theme={theme}
                        title={t("fileInput:uploadAvatar")}
                        subTitle={t("fileInput:removePicture")}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        borderRadius={4}
                      />
                    )}
                    name="image"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyName")}
                        placeholder={t("addCompany:companyName")}
                        type="text"
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        validate={errors?.name ? "error" : ""}
                        errorMessage={errors.name ? errors?.name?.message : ""}
                      />
                    )}
                    name="name"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyAdress")}
                        placeholder={t("addCompany:companyAdress")}
                        type="text"
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        isLoading={true}
                        value={value}
                        validate={errors?.address ? "error" : ""}
                        errorMessage={
                          errors.address ? errors?.address?.message : ""
                        }
                      />
                    )}
                    name="address"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyMail")}
                        placeholder={t("addCompany:companyMail")}
                        type="text"
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        validate={errors?.email ? "error" : ""}
                        errorMessage={
                          errors?.email ? errors?.email?.message : ""
                        }
                      />
                    )}
                    name="email"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TimeInput
                        label={t("addCompany:companyFiscalNumber")}
                        placeholder={t("addCompany:companyFiscalNumber")}
                        value={moment(value).format("YYYY-MM-DD")}
                        disabled={false}
                        onChange={(v) => {
                          setValue("fiscalYearStart", v);
                        }}
                      />
                    )}
                    name="fiscalYearStart"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyNumber")}
                        type="number"
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        validate={errors?.phone ? "error" : ""}
                        errorMessage={
                          errors?.phone ? errors?.phone?.message : ""
                        }
                      />
                    )}
                    name="phone"
                  />
                </>
              )}
            </div>

            {/*2nd row*/}
            <div
              className={`w-full gap-y-4 h-full flex flex-col items-start mb-4`}
            >
              {isLoading ? (
                <div className={`w-full gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={40}
                    width={320}
                    count={6}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              ) : (
                <>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:contactName")}
                        placeholder={t("addCompany:contactName")}
                        type="text"
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        validate={errors?.contactName ? "" : ""}
                        errorMessage={
                          errors?.contactName
                            ? errors?.contactName?.message
                            : ""
                        }
                      />
                    )}
                    name="contactName"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:contactEmail")}
                        placeholder={t("addCompany:contactEmail")}
                        type="text"
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        validate={errors?.contactEmail ? "error" : ""}
                        errorMessage={
                          errors?.contactEmail
                            ? errors?.contactEmail?.message
                            : ""
                        }
                      />
                    )}
                    name="contactEmail"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:contactNumber")}
                        placeholder={t("addCompany:contactNumber")}
                        type="number"
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        validate={errors?.contactPhone ? "error" : ""}
                        errorMessage={
                          errors?.contactPhone
                            ? errors?.contactPhone?.message
                            : ""
                        }
                      />
                    )}
                    name="contactPhone"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:taxOffice")}
                        placeholder={t("addCompany:taxOffice")}
                        type="text"
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        validate={errors?.taxOffice ? "error" : ""}
                        errorMessage={
                          errors?.taxOffice ? errors?.taxOffice?.message : ""
                        }
                      />
                    )}
                    name="taxOffice"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:taxNumber")}
                        placeholder={t("addCompany:taxNumber")}
                        disabled={true}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        validate={errors?.taxNo ? "error" : ""}
                        errorMessage={
                          errors?.taxNo ? errors?.taxNo?.message : ""
                        }
                      />
                    )}
                    name="taxNo"
                  />

                  <Input
                    label={t("addCompany:status")}
                    value={auth?.user?.company?.solutions?.map((e) => {
                      return `${e?.label} `;
                    })}
                    disabled
                  />
                </>
              )}
            </div>
            {/*3nd row*/}
            <div className={`w-full gap-y-4 h-full flex flex-col items-start `}>
              {isLoading ? (
                <>
                  <div className={`w-full gap-y-4 h-full flex flex-col `}>
                    <Skeleton
                      height={40}
                      width={320}
                      count={6}
                      borderRadius={8}
                      containerClassName="flex flex-col gap-y-3"
                    />
                  </div>
                </>
              ) : (
                <>
                  <Controller
                    control={control}
                    render={({ field: { onchange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:country")}
                        value={auth?.user?.company?.country}
                        disabled={true}
                        // icon={<Arrow />}
                        iconPosition="right"
                      />
                    )}
                    name="country"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onchange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:timeZone")}
                        value={auth?.user?.company?.timeZone}
                        disabled={true}
                      />
                    )}
                    name="timeZone"
                  />

                  <div
                    className={`w-full h-full justify-between items-center flex gap-2`}
                  >
                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:equipmentCredit")}
                          value={auth?.user?.company?.nodeLimit}
                          disabled
                        />
                      )}
                      name="nodeLimit"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:personCredit")}
                          value={auth?.user?.company?.userLimit}
                          disabled
                        />
                      )}
                      name="userLimit"
                    />
                  </div>

                  <HourTimeInput
                    onChange={(v) => {
                      setStartTime(v);
                    }}
                    setValue={setStartTime}
                    value={startTime}
                    label={t("addShift:dayStartTime")}
                  />

                  <div className={`w-full h-full justify-between flex gap-2`}>
                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={"T1 Standard"}
                          value={value}
                          type="number"
                        />
                      )}
                      name="t1Standard"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input label={"T1"} value={value} type="number" />
                      )}
                      name="t1"
                    />
                  </div>
                  <div className={`w-full h-full justify-between flex gap-2`}>
                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input label={"T2"} value={value} type="number" />
                      )}
                      name="t2"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input label={"T3"} value={value} type="number" />
                      )}
                      name="t3"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-full flex items-center justify-center z-10 max-h-[60px] gap-x-6 dark:z-[99]">
            {isLoading ? (
              <>
                <div className={`w-full  gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={50}
                    count={1}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              </>
            ) : (
              <Button
                colorType={"secondary-gray"}
                size={"lg"}
                label={t("buttons:previous")}
                onClick={() => {
                  const index = stepData.findIndex((route) =>
                    pathname.includes(route.path)
                  );
                  navigate("/step/" + stepData[index - 1].path);
                }}
              />
            )}
            {isLoading ? (
              <>
                <div className={`w-full  gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={50}
                    count={1}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              </>
            ) : (
              <Button
                colorType={"primary-machine"}
                size={"lg"}
                label={t("buttons:next")}
                type={"submit"}
              />
            )}
          </div>
        </form>
      </div>

      {/* md-sm view*/}
      <div className="flex-col items-center gap-10 w-full h-full justify-center hidden sm:flex md:flex">
        {type !== "editModal" ? (
          <span
            className={`text-3xl font-semibold mt-6 ${
              settingsStore.theme === "light"
                ? "text-[#101828]"
                : "text-[#FAFAFA]"
            }`}
          >
            {t("addCompany:title")}
          </span>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="h-full w-full flex flex-col md:w-11/12 items-center justify-center gap-4 sm:min-h-[460px] sm:max-h-[460px] md:min-h-[460px] md:max-h-[460px]"
        >
          <div className="flex flex-col w-full h-[570px] gap-4 overflow-y-auto scrollbar-hide">
            <div className="flex flex-row w-full items-start gap-8">
              <div
                className={`w-full  gap-y-4 h-full flex  flex-col items-start  `}
              >
                {isLoading ? (
                  <div className={`w-full gap-y-4 h-full flex flex-col `}>
                    <Skeleton
                      height={40}
                      width={320}
                      count={6}
                      borderRadius={8}
                      containerClassName="flex flex-col gap-y-3"
                    />
                  </div>
                ) : (
                  <>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <UploadProfile
                          theme={theme}
                          title={t("fileInput:uploadAvatar")}
                          subTitle={t("fileInput:removePicture")}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          borderRadius={4}
                        />
                      )}
                      name="image"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:companyName")}
                          placeholder={t("addCompany:companyName")}
                          type="text"
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors?.name ? "error" : ""}
                          errorMessage={
                            errors.name ? errors?.name?.message : ""
                          }
                        />
                      )}
                      name="name"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:companyAdress")}
                          placeholder={t("addCompany:companyAdress")}
                          type="text"
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          isLoading={true}
                          value={value}
                          validate={errors?.address ? "error" : ""}
                          errorMessage={
                            errors.address ? errors?.address?.message : ""
                          }
                        />
                      )}
                      name="address"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:companyMail")}
                          placeholder={t("addCompany:companyMail")}
                          type="text"
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors?.email ? "error" : ""}
                          errorMessage={
                            errors?.email ? errors?.email?.message : ""
                          }
                        />
                      )}
                      name="email"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TimeInput
                          label={t("addCompany:companyFiscalNumber")}
                          placeholder={t("addCompany:companyFiscalNumber")}
                          value={moment(value).format("YYYY-MM-DD")}
                          disabled={false}
                          onChange={(v) => {
                            setValue("fiscalYearStart", v);
                          }}
                        />
                      )}
                      name="fiscalYearStart"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:companyNumber")}
                          type="number"
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors?.phone ? "error" : ""}
                          errorMessage={
                            errors?.phone ? errors?.phone?.message : ""
                          }
                        />
                      )}
                      name="phone"
                    />
                  </>
                )}
              </div>
              {/* 2rd */}
              <div
                className={`w-full gap-y-4 h-full flex flex-col items-start `}
              >
                {isLoading ? (
                  <div className={`w-full gap-y-4 h-full flex flex-col `}>
                    <Skeleton
                      height={40}
                      width={320}
                      count={6}
                      borderRadius={8}
                      containerClassName="flex flex-col gap-y-3"
                    />
                  </div>
                ) : (
                  <>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:contactName")}
                          placeholder={t("addCompany:contactName")}
                          type="text"
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors?.contactName ? "" : ""}
                          errorMessage={
                            errors?.contactName
                              ? errors?.contactName?.message
                              : ""
                          }
                        />
                      )}
                      name="contactName"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:contactEmail")}
                          placeholder={t("addCompany:contactEmail")}
                          type="text"
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors?.contactEmail ? "error" : ""}
                          errorMessage={
                            errors?.contactEmail
                              ? errors?.contactEmail?.message
                              : ""
                          }
                        />
                      )}
                      name="contactEmail"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:contactNumber")}
                          placeholder={t("addCompany:contactNumber")}
                          type="number"
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors?.contactPhone ? "error" : ""}
                          errorMessage={
                            errors?.contactPhone
                              ? errors?.contactPhone?.message
                              : ""
                          }
                        />
                      )}
                      name="contactPhone"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:taxOffice")}
                          placeholder={t("addCompany:taxOffice")}
                          type="text"
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors?.taxOffice ? "error" : ""}
                          errorMessage={
                            errors?.taxOffice ? errors?.taxOffice?.message : ""
                          }
                        />
                      )}
                      name="taxOffice"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:taxNumber")}
                          placeholder={t("addCompany:taxNumber")}
                          disabled={true}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors?.taxNo ? "error" : ""}
                          errorMessage={
                            errors?.taxNo ? errors?.taxNo?.message : ""
                          }
                        />
                      )}
                      name="taxNo"
                    />

                    <Input
                      label={t("addCompany:status")}
                      value={auth?.user?.company?.solutions?.map((e) => {
                        return `${e?.label} `;
                      })}
                      disabled
                    />
                  </>
                )}
              </div>
            </div>
            {/* 3rd */}
            <div className="w-full gap-y-4 h-full flex flex-col items-start ">
              {isLoading ? (
                <>
                  <div className={`w-full gap-y-4 h-full flex flex-col `}>
                    <Skeleton
                      height={40}
                      width={320}
                      count={6}
                      borderRadius={8}
                      containerClassName="flex flex-col gap-y-3"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-row gap-8 w-full items-center ">
                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:country")}
                          value={auth?.user?.company?.country}
                          disabled={true}
                          // icon={<Arrow />}
                          iconPosition="right"
                        />
                      )}
                      name="country"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:timeZone")}
                          value={auth?.user?.company?.timeZone}
                          disabled={true}
                        />
                      )}
                      name="timeZone"
                    />
                  </div>

                  <div className={`w-full flex flex-row gap-8 items-center `}>
                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:equipmentCredit")}
                          value={auth?.user?.company?.nodeLimit}
                          disabled
                        />
                      )}
                      name="nodeLimit"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:personCredit")}
                          value={auth?.user?.company?.userLimit}
                          disabled
                        />
                      )}
                      name="userLimit"
                    />
                  </div>
                  <div className="w-full">
                    <HourTimeInput
                      onChange={(v) => {
                        setStartTime(v);
                      }}
                      setValue={setStartTime}
                      value={startTime}
                      label={t("addShift:dayStartTime")}
                    />
                  </div>

                  <div className={`w-full flex flex-row gap-8 items-center`}>
                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={"T1 Standard"}
                          value={value}
                          type="number"
                        />
                      )}
                      name="t1Standard"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input label={"T1"} value={value} type="number" />
                      )}
                      name="t1"
                    />
                  </div>
                  <div className={`w-full flex flex-row gap-8 items-center`}>
                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input label={"T2"} value={value} type="number" />
                      )}
                      name="t2"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input label={"T3"} value={value} type="number" />
                      )}
                      name="t3"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-full flex items-center justify-center z-10 max-h-[60px] gap-x-6  ">
            {isLoading ? (
              <>
                <div className={`w-full  gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={50}
                    count={1}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              </>
            ) : (
              <Button
                colorType={"secondary-gray"}
                size={"lg"}
                label={t("buttons:previous")}
                onClick={() => {
                  const index = stepData.findIndex((route) =>
                    pathname.includes(route.path)
                  );
                  navigate("/step/" + stepData[index - 1].path);
                }}
              />
            )}
            {isLoading ? (
              <>
                <div className={`w-full  gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={50}
                    count={1}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              </>
            ) : (
              <Button
                colorType={"primary-machine"}
                size={"lg"}
                label={t("buttons:next")}
                type={"submit"}
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default Company;
