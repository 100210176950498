import React, { useState } from 'react'
import Button from '../buttons/Button';


const OperationDetailTargetEditModal = ({ setOpenTarget, onSubmit }) => {

    const [checkedSetting, setCheckedSetting] = useState(false);
    const toggleCheckedSetting = () => {
        setCheckedSetting((prev) => !prev);
    };

    return (
        <div className='flex flex-col items-center w-full justify-center'>
            <div className='flex items-center justify-center px-3 w-12 min-w-12 h-12 min-h-12 rounded-md bg-[#F2F4F7]'>
                <TargetIcons />
            </div>
            <p className='text-lg font-semibold text-secondary-900 mt-4'>Hedef Süreleri Güncelle </p>
            <p className='text-sm text-secondary-500 text-center mt-1'>Hedef iş süresinin geçmiş eylemlerde de güncellenmesi için aşağıdaki kutucuğu işaretleyin.</p>
            <div className='flex flex-row items-center w-full mt-8'>
                <span
                    className={`w-5 h-5 min-w-5 min-h-5 border rounded-md flex justify-center items-center mr-2 cursor-pointer ${checkedSetting ? "bg-[#DC6803] border-[#DC6803]" : "border-[#D0D5DD]"
                        }`}
                    onClick={toggleCheckedSetting}
                >
                    {checkedSetting && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    )}
                </span>

                <p className='text-sm text-secondary-600'>Geçmiş verileri bu ayara göre güncelle.</p>
            </div>
            <div className='flex flex-row items-center gap-x-3 w-full mt-8'>
                <Button colorType={"secondary-gray"} label={"Vazgeç"} onClick={() => setOpenTarget(false)} />
                <Button colorType={"primary-product"} label={"Kaydet"} onClick={onSubmit} />
            </div>
        </div>
    )
}

export default OperationDetailTargetEditModal

export const TargetIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M22 12H18M6 12H2M12 6V2M12 22V18M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};