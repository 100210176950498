import React, { memo } from "react";
import { NavLink } from "react-router-dom";

const Card = ({ to, item, segmentColor }) => {
  const SecondIcon = memo(({ children }) => {
    return <>{children}</>;
  });
  
  return (
    <>
      <>
        <NavLink
          to={to}
          className={"h-10 w-10 flex items-center justify-center rounded-lg"}
          style={({ isActive }) => ({
            color: isActive ? segmentColor?.labelActiveColor : segmentColor?.labelColor,
            backgroundColor: isActive ? segmentColor?.buttonColor : "transparent",
          })}
        >
          <div className="w-7 min-w-[28px] h-7 flex items-center justify-center">
            <SecondIcon>
              <item.icon />
            </SecondIcon>
          </div>
        </NavLink>
      </>
    </>
  );
};

export default memo(Card);
