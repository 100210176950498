import React, { useEffect, useState } from "react";
import Badgets from "../../../components/buttons/Badgets";
import diacritics from "diacritics";
import { Button } from "../../../components";
import { ChevronDown } from "../../../assets/icons/PersonIcons";
import PollPersonalItem from "./PollPersonalItem";

const PollExpandedCard = ({ item, index, auth, chat, selectedUsers, selectedDepartment, selectAllPress, triggerUser, searchValue }) => {
  const [expanded, setExpanded] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const filteredUsers = item?.users?.filter((d) =>
    diacritics.remove(`${d?.name} ${d?.lastName}`).toLowerCase().includes(diacritics.remove(searchValue).toLowerCase())
  );

  useEffect(() => {
    if (selectedDepartment) {
      setExpanded(selectedDepartment);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedDepartment) {
      setSelectAllChecked(item?.departmentId === selectedDepartment);
    }
  }, [selectedDepartment]);

  return (
    <>
      <div key={index} className="flex items-center w-full justify-between cursor-pointer border-b pb-2">
        <div className="flex items-center gap-2">
          <p className="text-base font-semibold text-[#101828]">{item.departmentId === "BLANK_DEPARTMENT" ? "Diğer" : item?.departmentName}</p>
          <Badgets colorType={"fill-success"} label={filteredUsers?.length || 0} />
        </div>
        <div className="ml-auto gap-4 flex items-center justify-center z-20 cursor-pointer">
          <div
            className="ml-auto flex gap-3 items-center justify-center text-sm font-normal cursor-pointer select-none text-[#667085] py-2"
            onClick={(e) => {
              selectAllPress(item?.users);
            }}
          >
            <p className="whitespace-nowrap">Select All</p>
          </div>
          <Button
            colorType={"tertiary-gray"}
            onClick={() => {
              if (item?.departmentId === expanded) {
                setExpanded("");
              } else {
                setExpanded(item?.departmentId);
              }
            }}
            size={"md"}
            iconLeft={
              expanded === item?.departmentId ? (
                <div className="rotate-180">
                  <ChevronDown />
                </div>
              ) : (
                <ChevronDown />
              )
            }
          />
        </div>
      </div>
      {expanded === item?.departmentId && (
        <div className="flex flex-wrap">
          {filteredUsers?.map((user, index) => {
            return (
              user?.id !== auth?.user?.id && (
                <div key={index} className={`w-${item?.users?.length === 1 ? "full" : "full"} px-2`}>
                  <PollPersonalItem
                    chat={chat}
                    item={user}
                    index={index}
                    isSelected={selectedUsers?.find((a) => a?.id == user?.id) ? true : false}
                    triggerUser={triggerUser}
                  />
                </div>
              )
            );
          })}
        </div>
      )}
    </>
  );
};

export default PollExpandedCard;
