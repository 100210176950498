import moment from "moment";

const now = moment();

const getTodayAtSpecificHour = (hour = 8, date) => {
  if (date) {
    return moment(date).set({ hour, minute: 0, second: 0, millisecond: 0 }).toDate();
  } else {
    return moment(now).set({ hour, minute: 0, second: 0, millisecond: 0 }).toDate();
  }
};

export const getStartOfDayForCompany = (start, end) => {
  let starts = moment(start).toDate();
  let ends = moment(end).toDate();

  return [starts, ends];
};

export const selectedInterval = [getTodayAtSpecificHour(), getTodayAtSpecificHour(24)];

export const timelineInterval = [getTodayAtSpecificHour(8), getTodayAtSpecificHour(24)];

export const disabledIntervals = [
  //   { start: getTodayAtSpecificHour(16), end: getTodayAtSpecificHour(17) },
  //   { start: getTodayAtSpecificHour(7), end: getTodayAtSpecificHour(12) },
  //   { start: getTodayAtSpecificHour(20), end: getTodayAtSpecificHour(24) }
];
