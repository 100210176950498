import React, { useEffect, useState } from "react";
import HourTimeInput from "../inputs/HourTimeInput";
import PersonnelAndEquipmentSelector from "../inputs/PersonnelAndEquipmentSelector";
import Selector from "../inputs/Selector";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../inputs/Input";
import Button from "../buttons/Button";
import { equipmentService } from "../../services/equipment.service";
import { personalService } from "../../services/personal.service";
import DateTimeInput from "../inputs/DateTimeInput";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import { flexJunctionService } from "../../services/flex-junction.service";
const AddJunctionManual = ({ currentOperation, refetch, setIsOpenJunction }) => {
  const { t } = useTranslation();
  const [equipments, setEquipments] = useState([]);
  const [personels, setPersonels] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"));
  const schema = yup.object({
    station: yup.string().required(),
    personnel: yup.string().required(),
    type: yup.string().required(),
    madeWorkCount: yup
      .number()
      .required()
      .typeError(`${t("validation:number")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rejectedPiece: 0,
    },
  });
  const junctionTypes = [
    { label: t("product:work"), value: "work" },
    { label: t("product:setup"), value: "setup" },
  ];
  const onSubmit = async (value) => {
    if (moment(startDate).isAfter(moment()) || moment(endDate).isAfter(moment())) {
      Toast("error", "Başlangıç veya bitiş saati şimdi ki saatten ileri olamaz");
    } else {
      const send = {
        startDate: moment(startDate).utc().format(),
        endDate: moment(endDate).utc().format(),
        station: value?.station,
        user: value?.personnel,
        type: value?.type,
        madeWorkCount: value?.madeWorkCount,
        internalOperation: currentOperation?.processId,
        job: currentOperation?.orderId,
        header: currentOperation?.operationId,
      };
      await junctionService.addJunctionManual(send).then((res) => {
        if (res?.data?.code === 1) {
          Toast("error", res?.data?.message);
          // setIsOpenJunction(false);
        } else {
          Toast("success", res?.data?.message);
          // setIsOpenJunction(false);
          refetch();
        }
      });
    }
  };
  const getActiveNodes = async () => {
    if (currentOperation?.processId) {
      const data = await equipmentService.getEquipmentForProcessId(currentOperation?.processId);
      let formatttedData = data?.data?.map((e, i) => {
        return {
          label: e?.metadata?.brand + " " + e?.metadata?.model,
          value: e?.id,
          image: e?.metadata?.image,
        };
      });
      setEquipments(formatttedData);
    } else {
      setEquipments([]);
    }
  };
  const getActiveUser = async () => {
    const data = await personalService.activePersonals();
    let formatttedData = data?.data?.map((e, i) => {
      return {
        label: e?.name + " " + e?.lastName,
        value: e?.id,
        image: e?.avatar,
      };
    });
    setPersonels(formatttedData);
  };
  useEffect(() => {
    getActiveUser();
    getActiveNodes();
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-[660px] flex flex-col  mt-6 ">
      <div className="w-full flex-col flex gap-6">
        <div className="w-full flex-row flex items-center h-[72px] gap-3">
          <img src={generateFileUrl(currentOperation?.image)} className="w-[64px] h-[48px] rounded border border-gray-300" />
          <div className="flex flex-col justify-between">
            <p className="font-semibold text-gray-900 w-full   truncate">{currentOperation?.name || "---"}</p>
            <div className="text-[#B54708] text-xs px-1 py-0.5 rounded-md font-medium bg-[#FFFAEB] ">Stok : {currentOperation?.stockCode || "---"}</div>
          </div>
        </div>
        <div className="flex flex-row items-start w-full ">
          <div className="w-1/2 flex flex-col gap-4 pr-4 border-r">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <PersonnelAndEquipmentSelector
                  value={value}
                  items={equipments}
                  icon={"machine"}
                  theme={"product"}
                  label={t("equipments:equipment")}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.station ? "error" : ""}
                  errorMessage={errors?.station ? errors?.station?.message : ""}
                />
              )}
              name="station"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <PersonnelAndEquipmentSelector
                  value={value}
                  items={personels}
                  icon={"personnel"}
                  image={""}
                  theme={"product"}
                  label={t("personals:personal")}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.personnel ? "error" : ""}
                  errorMessage={errors?.personnel ? errors?.personnel?.message : ""}
                />
              )}
              name="personnel"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  value={value}
                  items={junctionTypes}
                  theme={"product"}
                  label={t("product:workType")}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.type ? "error" : ""}
                  errorMessage={errors?.type ? errors?.type?.message : ""}
                />
              )}
              name="type"
            />
          </div>
          <div className="w-1/2 flex flex-col gap-4 pl-4 ">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  theme={"product"}
                  label={t("product:numberOfProducts")}
                  errorMessage={errors?.madeWorkCount?.message}
                  validate={errors?.madeWorkCount ? "error" : ""}
                />
              )}
              name="madeWorkCount"
            />
            <DateTimeInput
              label={t("product:jobStartDate")}
              theme={"product"}
              initialValue={moment(startDate).format("YYYY-MM-DDTHH:mm:ss")}
              onChange={(e) => {
                setStartDate(e);
              }}
            />
            <DateTimeInput
              label={t("product:jobEndDate")}
              theme={"product"}
              initialValue={moment(endDate).format("YYYY-MM-DDTHH:mm:ss")}
              onChange={(e) => {
                setEndDate(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-1/2 flex items-center ml-auto  gap-3 mt-8">
        <Button label={t("buttons:stop")} colorType={"secondary-gray"} size={"md"} onClick={() => setIsOpenJunction(false)} />
        <Button type={"submit"} label={t("buttons:save")} colorType={"primary-product"} size={"md"} />
      </div>
    </form>
  );
};

export default AddJunctionManual;