import React from "react";
import SettingUndefinedEquimentsCards from "./SettingUndefinedEquimentsCards";
import { useTranslation } from "react-i18next";
import NoData from "../../../steps/step-navigation/components/NoData";

const SettingUndefinedEquipmentModal = ({
  data,
  isOpen,
  setIsOpen,
  setSelectedNode,
  openNewEquipment,
  setOpenNewEquipment,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full h-[650px] gap-2 mt-4 overflow-y-auto overflow-hidden scrollbar-hide">
        {data?.length < 1 ? (
          <div className="w-full h-full flex items-center justify-center">
            <NoData
              header={t("label:noData")}
              theme={"machine"}
              button={false}
            />
          </div>
        ) : (
          data?.map((item, index) => (
            <SettingUndefinedEquimentsCards
              t={t}
              key={index}
              name={item?.name}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              undefinedData={data?.undefined}
              description={item?.electronicCardSerialNo}
              openNewEquipment={openNewEquipment}
              setOpenNewEquipment={setOpenNewEquipment}
              selectedNode={setSelectedNode}
              onClick={() => setSelectedNode(item)}
            />
          ))
        )}
      </div>
      <></>
    </>
  );
};

export default SettingUndefinedEquipmentModal;
