import React from "react";
import CustomImage from "../CustomImage";
import CustomText from "../CustomText";
import {
  DefaultLossesIcon,
  StartLossesIcon,
  StopLossesIcon,
} from "../../assets/icons/lossesIcons";

const LossesCardType = ({
  leftImage,
  theme,
  id,
  title = "asdasds",
  subTitle,
  type = "undefined",
  startTime,
  endTime,
  user = {
    name: "",
    lastName: "",
    avatar: "",
  },
}) => {
  return (
    <div
  //     data-tooltip-id={`${id}`}
  //     data-tooltip-content={`
  //     ${t("losses:typeName")} : ${title} 
  //     |
  //     ${t("product:description")} : ${subTitle}
  // `}
      style={{
        ...styles.card,
        borderColor: "red",
        borderTopWidth: 4,
      }}
    >
      {leftImage ? (
        <CustomImage
          src={leftImage}
          borderRadius={4}
          style={{
            ...styles.squareImage,
            borderColor: theme === "dark" ? "#292929" : "#D0D5DD",
          }}
        />
      ) : (
        <div className="flex items-center justify-center  w-[72px] h-[54px] max-w-[72px] min-w-[72px] min-h-[54px] max-h-[54px] border-[#D0D5DD] border-[1px] rounded">
          <DefaultLossesIcon />
        </div>
      )}

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          marginLeft: 12,
        }}
      >
        <CustomText
          numberOfLines={1}
          text={title || "---"}
          style={{
            ...styles.lightText,
            color: theme === "dark" ? "#D6D6D6" : "#667085",
          }}
        />
        <p className="w-[190px] truncate text-[#344054] text-xs font-medium">
          {subTitle || "---"}
        </p>
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 1,
            marginTop: 2,
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <StartLossesIcon />
            <CustomText
              numberOfLines={1}
              text={startTime || "--"}
              style={{
                ...styles.lightText,
                color: theme === "dark" ? "#D6D6D6" : "#667085",
                marginLeft: 6,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <StopLossesIcon />
            <CustomText
              numberOfLines={1}
              text={endTime || "--"}
              style={{
                ...styles.lightText,
                color: theme === "dark" ? "#D6D6D6" : "#667085",
                marginLeft: 2,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  card: {
    borderWidth: 1,
    width: "100%",
    padding: 8,
    borderRadius: 4,
    flexDirection: "row",
    overflow: "hidden",
    maxHeight: 80,
    paddingVertical: 10,
    alignItems: "center",
    display: "flex",
  },
  lightText: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: "400",
    display: "flex",
    flexDirection: "column",
  },
  boldText: {
    fontSize: 12,
    fontWeight: "500",
    lineHeight: "18px",
    display: "flex",
    flexDirection: "column",
  },
  squareImage: {
    borderRadius: 4,
    borderWidth: 1,
    width: 72,
    height: 54,
    display: "flex",
    flexDirection: "column",
  },
  sircleImage: {
    width: 54,
    height: 54,
    borderRadius: 100,
    borderWidth: 1,
    display: "flex",
    flexDirection: "column",
  },
  center: {
    flexGrow: 1,
    height: "100%",
    maxWidth: "50%",
    marginLeft: 12,
    display: "flex",
    flexDirection: "column",
  },
  rightButton: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: 100,
    width: 50,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
};

export default LossesCardType;
