import React, { useState } from "react";
import Button from "../buttons/Button";
import NoAvatar from "../avatar/NoAvatar";
import generateFileUrl from "../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import { Toast } from "../../utils/toastify/toast";
import { junctionService } from "../../services/junction.service";

const MovePlannedJobsModal = ({ stations, selectedStation ,setMovePlannedJobsModal , refetch }) => {
  const { t } = useTranslation();

  const [selectedEquipmentId, setSelectedEquipmentId] = useState(null);

  const handleSelectEquipment = (id) => {
    setSelectedEquipmentId((prev) => (prev === id ? null : id));
  };

  const handleSubmit = () => {
    if (!selectedEquipmentId) {
      Toast("error", "Lütfen bir ekipman seçin");
      return;
    } else {
      updatePlanningJunctionList();
    }


  };

  const updatePlanningJunctionList = async () => {
     return await junctionService.moveToPlannedJunctionsForDifferentEquipment(selectedStation?.id, selectedEquipmentId).then((res) => {
       if (res.data.code == 0) {
          Toast("success", res?.data?.message);
          setMovePlannedJobsModal(false);
          refetch();
       } else {
        Toast("error", "Bir hata oluştu");
       }
     });
  };

  return (
    <div className="flex flex-col w-full h-full pt-5">
      <div className="flex flex-col w-full h-[386px] border-y border-[#E4E7EC] bg-[#F9FAFB] py-5 overflow-y-auto overflow-x-hidden scrollbar-hide gap-y-3 px-3">
        {stations?.filter((station) => station?.id !== selectedStation?.id).map((equ) => (
          <div
            onClick={() => handleSelectEquipment(equ?.id)}
            className="flex flex-col gap-y-3 p-3 w-full min-h-[110px] max-h-[110px] h-[110px] shadow-xs border border-[#E4E7EC] bg-white rounded-lg cursor-pointer"
          >
            <div className="flex w-full items-center">
              <div className="flex gap-x-2 w-full items-center">
                <p className="text-[#344054] font-semibold text-sm">{equ?.metadata?.brand + equ?.metadata?.model || "Machine"}</p>
              </div>
              <span
                className={`flex rounded-full min-h-5 max-h-5 h-5 min-w-5 max-w-5 w-5 items-center justify-center cursor-pointer ${
                  selectedEquipmentId === equ?.id ? "bg-[#DC6803]" : "border border-[#D0D5DD]"
                }`}
              >
                {selectedEquipmentId === equ?.id && <span className="flex min-w-2 max-w-2 w-2 min-h-2 max-h-2 h-2 bg-white rounded-full"></span>}
              </span>
            </div>
            <div className="flex w-full h-full gap-x-3">
              {equ?.metadata?.image ? (
                <img className="w-[72px] h-[54px] rounded border border-[#0000001A] flex-none" src={generateFileUrl(equ?.metadata?.image)} />
              ) : (
                <div className="w-[72px] h-[54px] min-w-[72px] min-h-[54px] rounded border border-secondary-200 overflow-hidden">
                  <NoAvatar name={equ?.metadata?.brand + equ?.metadata?.model || "--"} rounded={4} theme={"product"} minHeight="54px" minWidth="72px" />
                </div>
              )}
              <div className="flex flex-col w-full border rounded px-3 py-[6px] gap-y-1">
                <p className="text-[#475467] text-xs">{t("product:totalPlannedWorks")}</p>
                <p className="text-[#344054] font-medium text-sm">
                  {Number(equ?.metadata?.plannedWorkLoad / 86400).toFixed(1)} {t("product:day")}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex w-full pt-6 gap-x-3">
        <Button colorType={"secondary-gray"} label={t("buttons:stop")} size={"lg"} />
        <Button colorType={"primary-product"} label={t("tasks:move")} size={"lg"} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default MovePlannedJobsModal;
