import React, { useEffect, useRef, useState } from "react";

import { Outlet } from "react-router-dom";
import { StepHeader } from "./components";
import { StepsIllustrator } from "../../assets/images";
import generateFileUrl from "../../utils/generateFileUrl";
import { useStore } from "../../hooks/useStores";
import { Language, Logout } from "../../assets/icons/stepsIcons";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { settingsStore } from "../../stores/settings.store";
import { Button } from "../../components";
import i18next from "i18next";
import { observer } from "mobx-react-lite";
import LoadingOverlay from "../../components/loading/LoadingOverlay";

const StepNavigation = observer(() => {
  const { auth } = useStore();
  const navigate = useNavigate();
  const [logo, setLogo] = useState(auth?.user?.company?.image);
  const location = useLocation();
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState("en");
  const [loading, setLoading] = useState(false);
  const menuRef = useRef();

  const logout = () => {
    setLoading(true);
    auth?.logOut();
    navigate("/auth/login");
    setLoading(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setVisibleLanguage(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectLanguage(i18next.language);
  }, [i18next.language]);

  const language = [
    {
      name: "TR",
      value: "tr",
      // icon: <TurkishFlag />,
    },
    {
      name: "EN",
      value: "en",
      // icon: <BritainFlag />,
    },
  ];

  const handleLanguageSelect = (value) => {
    setSelectLanguage(value);
    setVisibleLanguage(false);
    i18next.changeLanguage(value);
  };

  return (
    <div className="w-screen h-screen dark:bg-[#0F0F0F] max-w-screen max-h-screen flex-col overflow-hidden xs:px-4 sm:px-6 -z-40 ">
      <StepHeader />
      <div className="w-full flex flex-col items-center justify-center xs:h-[68%] z-20 ">
        <Outlet />
      </div>

      {/*Logout*/}
      {loading && <LoadingOverlay />}
      <div
        ref={menuRef}
        className="absolute md:bottom-6 sm:bottom-6 xs:bottom-6  flex flex-col items-start gap-6 xs:gap-4 left-12 bottom-12 z-[99] "
      >
        {visibleLanguage && (
          <div className="absolute w-[100px] p-1 bg-white -right-5 sm:-top-1 sm:-right-[120px] xs:-top-1 xs:-right-[120px] md:-top-1 md:-right-[120px] -top-[72px] border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1 z-50">
            {language?.map((item, index) => {
              return (
                <div
                  onClick={() => handleLanguageSelect(item.value)}
                  className="flex flex-row items-center gap-2"
                >
                  <div className="w-4 h-4 rounded-full border border-secondary-300 flex items-center justify-center">
                    {selectLanguage === item.value && (
                      <div className="w-2 h-2 rounded-full bg-[#7F56D9]"></div>
                    )}
                  </div>
                  <p className="text-secondary-900 font-medium text-md">
                    {item?.name}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {location?.pathname === "/step/language" ? (
          <>
            <div
              className="min-w-[56px] max-w-[56px] h-[56px]"
              style={{ visibility: "hidden" }}
            >
              <Button
                colorType={"secondary-gray"}
                onClick={() => {
                  setVisibleLanguage(!visibleLanguage);
                }}
                iconLeft={
                  <Language
                    color={
                      settingsStore.theme === "dark" ? "#D6D6D6" : "#344054"
                    }
                    width={24}
                    height={24}
                  />
                }
                size={"2xl"}
              />
            </div>
          </>
        ) : (
          <>
            <Button
              colorType={"secondary-gray"}
              onClick={() => {
                setVisibleLanguage(!visibleLanguage);
              }}
              iconLeft={
                <Language
                  color={settingsStore.theme === "dark" ? "#D6D6D6" : "#344054"}
                  width={24}
                  height={24}
                />
              }
              size={"2xl"}
            />
          </>
        )}

        <Button
          onClick={() => logout()}
          colorType={"primary-error"}
          iconLeft={<Logout color={"white"} height={24} width={24} />}
          size={"2xl"}
        />
      </div>

      <div className="w-full flex justify-center">
        <img
          src={StepsIllustrator}
          alt="StepsIllustrator"
          className={`object-cover dark:max-w-[90%]  bottom-0 absolute mx-auto  -z-30 dark:z-30  dark:opacity-40 ${
            location.pathname === "/step/language"
              ? "opacity-100"
              : "opacity-30"
          }`}
        />
      </div>

      {/*company logo as per user selection*/}
      {location?.pathname === "/step/language" ||
      location?.pathname === "/step/company" ? (
        <>
          <div
            style={{ visibility: "hidden" }}
            className="border-[1.5px] w-[120px] h-[90px] bottom-12 right-12 z-40 rounded-md items-center justify-center absolute"
          >
            {logo && (
              <img
                src={generateFileUrl(logo)}
                alt="Logo"
                className="flex flex-col w-full h-full rounded-md object-contain"
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className="border-[1.5px] w-[120px] h-[90px] bottom-12 sm:bottom-6 right-12 z-40 rounded-md flex items-center justify-center absolute dark:border-[#424242] xs:bottom-6 md:bottom-6">
            {logo && (
              <img
                src={generateFileUrl(logo)}
                alt="Logo"
                className="flex flex-col w-full h-full rounded-md object-contain"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

export default StepNavigation;
