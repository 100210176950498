import React, { memo, useMemo } from "react";
import { observer } from "mobx-react-lite";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LossTimeCard from "../../Card/LossTimeCard";
import {
  Cost3TIcon,
  CostTIcon,
  KwIcon,
  KwhIcon,
} from "../../../../assets/icons/lossIcon";

const EquipmentEnergy = observer(
  ({ selectedNode, energyData, xAsisData, month }) => {
    const { t } = useTranslation();

    const option = {
      title: {
        text: selectedNode?.name,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      yAxis: {
        position: "right",
        type: "value",
        boundaryGap: [0, 0.01],
      },
      xAxis: {
        type: "category",
        data: xAsisData,
      },
      series: [
        {
          name: "Total kVA",
          type: "bar",
          data: energyData?.map((row) => row?.total_kw),
        },
        {
          name: "Total kVAh",
          type: "bar",
          data: energyData?.map((row) => row?.total_kwh),
        },
        {
          name: "Total kVAh Cost",
          type: "bar",
          data: energyData?.map((row) => row?.total_cost),
        },
        {
          name: "Total kVAh Cost For 3 Time",
          type: "bar",
          data: energyData?.map((row) => row?.threeTimeCost),
        },
      ],
    };

    const Tabs = useMemo(
      () => [
        {
          name: "Elektrik (kVAh)",
          icon: <KwhIcon width={32} height={32} />,
          bgColor: "#079455",
          color: "#079455",
          key: "total_kwh",
        },
        {
          name: "Elektrik (kVA)",
          icon: <KwIcon />,
          color: "#EAAA08",
          bgColor: "#EAAA08",
          key: "total_kw",
        },
        {
          name: "Cost (t)",
          icon: <CostTIcon width={32} height={32} />,
          bgColor: "#06AED4",
          color: "#06AED4",
          key: "total_cost",
        },
        {
          name: "Cost (3t)",
          icon: <Cost3TIcon width={32} height={32} />,
          bgColor: "#6172F3",
          color: "#6172F3",
          key: "threeTimeCost",
        },
      ],
      []
    );

    return (
      <div className="w-full ">
        <div className="flex items-center gap-x-5 my-5">
          {Tabs.map((tab, index) => {
            return (
              <LossTimeCard
                key={index}
                name={tab?.name}
                icon={tab?.icon}
                activeIcon={tab?.activeIcon}
                value={
                  energyData?.find((item) => {
                    return item?.month == moment(month).format("M");
                  })?.[tab?.key]
                }
                color={tab?.color}
                //   onClick={() => {
                //     setSelectedCategory(tab?.key);
                //   }}
                selected={tab?.key}
                bgColor={tab?.bgColor}
              />
            );
          })}
        </div>
        <ReactECharts
          // onEvents={onEvents}
          option={option}
          style={{
            width: "100%",
          }}
        />
      </div>
    );
  }
);

export default memo(EquipmentEnergy);
