import moment from "moment";
import React, { memo, useState } from "react";
import { ArrowRightIcon, Calender } from "../../../../assets/icons/machineIcons";
import { TableArrowLeft } from "../../../../assets/icons/lossIcon";
import Button from "../../../../components/buttons/Button";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { authStore } from "../../../../stores/auth.store";
import { useTranslation } from "react-i18next";
import DetailsEndDatePicker from "../../../../components/details-date-picker/DetailsEndDatePicker";

const DetailPageDateSelector = ({ shiftSelecter, date, dates, setDate, isLoading }) => {
  const { t } = useTranslation();
  const [showDatePicker, setShowDatePicker] = useState(false);

  return (
    <div className="w-full h-10 my-4 flex items-center justify-between">
      {isLoading ? (
        <div className="w-[144px] h-[38px] rounded-lg bg-gray-200 animate-pulse" />
      ) : (
        <Button
          width={144}
          size={"sm"}
          label={t("buttons:previous")}
          colorType={"secondary-gray"}
          onClick={() => {
            shiftSelecter(moment(date).subtract(1, "days").format("YYYY-MM-DD"));
          }}
          iconLeft={
            <div className="rotate-180">
              <TableArrowLeft />
            </div>
          }
        />
      )}

      {isLoading ? (
        <div className="h-[38px] w-[240px] rounded-lg bg-gray-200 animate-pulse" />
      ) : (
        <DetailsEndDatePicker
          date={date}
          visibleFooter
          setDate={setDate}
          visible={showDatePicker}
          setVisible={setShowDatePicker}
          buttonClassName={"flex flex-col items-center"}
          calendarClassName={"rounded-xl absolute top-11"}
        >
          <div
            onClick={() => setShowDatePicker(!showDatePicker)}
            className={`h-[38px] gap-2 border hover:bg-secondary-50 border-secondary-300 shadow-sm hover:shadow-none rounded-lg cursor-pointer flex items-center justify-between px-3 active:ring-4 ring-secondary-100 select-none ${
              showDatePicker ? "bg-secondary-50 ring-2 ring-secondary-200 shadow-none" : ""
            }`}
          >
            <Calender color={"#344054"} />
            <p className="text-sm font-medium text-secondary-800">
              {moment(timeFormatter(dates ? dates : date, authStore.user.company.timeZone).formatted).format("DD.MM.YYYY")}
            </p>
            -
            <p className="text-sm font-medium text-secondary-800">
              {moment(timeFormatter(dates ? dates : date, authStore.user.company.timeZone).formatted)
                .add(1, "day")
                .format("DD.MM.YYYY")}
            </p>
          </div>
        </DetailsEndDatePicker>
        // <EndDatePicker
        //   date={date}
        //   visibleFooter
        //   setDate={setDate}
        //   visible={showDatePicker}
        //   setVisible={setShowDatePicker}
        //   buttonClassName={"flex flex-col items-center"}
        //   calendarClassName={"rounded-xl absolute top-11"}
        // >
        //   <div
        //     onClick={() => setShowDatePicker(!showDatePicker)}
        //     className={`h-[38px] gap-2 border hover:bg-secondary-50 border-secondary-300 shadow-sm hover:shadow-none rounded-lg cursor-pointer flex items-center justify-between px-3 active:ring-4 ring-secondary-100 select-none ${
        //       showDatePicker
        //         ? "bg-secondary-50 ring-2 ring-secondary-200 shadow-none"
        //         : ""
        //     }`}
        //   >
        //     <Calender color={"#344054"} />
        //     <p className="text-sm font-medium text-secondary-800">
        //       {moment(
        //         timeFormatter(
        //           dates ? dates : date,
        //           authStore.user.company.timeZone
        //         ).formatted
        //       ).format("DD.MM.YYYY")}
        //     </p>
        //     -
        //     <p className="text-sm font-medium text-secondary-800">
        //       {moment(
        //         timeFormatter(
        //           dates ? dates : date,
        //           authStore.user.company.timeZone
        //         ).formatted
        //       ).add(1, "day").format("DD.MM.YYYY")}
        //     </p>
        //   </div>
        // </EndDatePicker>
      )}

      {isLoading ? (
        <div className="w-[144px] h-[38px] rounded-lg bg-gray-200 animate-pulse" />
      ) : (
        <Button
          width={144}
          size={"sm"}
          label={t("buttons:next")}
          colorType={"secondary-gray"}
          iconRight={
            <div className={moment(date).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") ? "opacity-100" : "opacity-30"}>
              <ArrowRightIcon />
            </div>
          }
          disabled={
            // timeFormatter(date) <
            // timeFormatter(moment(), authStore.user.company.timeZone).formatted
            //   ? false
            //   : true
            moment(new Date()).format("YYYY-MM-DD") === moment(new Date(date)).format("YYYY-MM-DD")
          }
          onClick={() => {
            shiftSelecter(moment(date).subtract(-1, "days").format("YYYY-MM-DD"));
          }}
        />
      )}
    </div>
  );
};

export default memo(DetailPageDateSelector);
