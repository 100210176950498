import React, { useState } from "react";
import Badgets from "../../../../components/buttons/Badgets";
import CustomModal from "../../../../components/modal/CustomModal";
import SelectUserModal from "../../../../components/modal/SelectUserModal";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStore } from "../../../../hooks/useStores";
import SingleJunctionCard from "../../../../components/junction/SingleJunctionCard";
import { secondToHoursAndMinute, formatSeconds, secondToDay, secondToHoursAndMinute2 } from "../../../../utils/secondToHoursAndMinute";
import { junctionService } from "../../../../services/junction.service";
import { Toast } from "../../../../utils/toastify/toast";
import { useNavigate } from "react-router-dom";
import ApprovedCard from "../../../../components/junction/ApprovedCard";
import ActiveAndPlannedCard from "../../../../components/junction/ActiveAndPlannedCard";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { CloseIcon } from "../../../../assets/icons/departmentIcon";
import SelectJunctionTable from "./SelectJunctionTable";
import NewActiveAndPlannedCard from "../../../../components/junction/NewActiveAndPlannedCard";
import NewApprovedCard from "../../../../components/junction/NewApprovedCard";
import PersonnelDetailActiveCard from "../../../../components/new-cards/active-card/PersonnelDetailActiveCard";
import PersonnelDetailTodayDoneCard from "../../../../components/new-cards/personnel-detail-card/PersonnelDetailTodayDoneCard";
import PersonnelDetailDoneCard from "../../../../components/new-cards/personnel-detail-card/PersonnelDetailDoneCard";
import FinishJunctionModal from "../../../../components/modal/FinishJunctionModal";
import { JunctionNumberIcon, JunctionNumberIconSelected } from "../../../../components/new-cards/card-icons";
const PersonalJunctions = ({
  junctionData = [],
  date,
  selectedJunction,
  setSelectedJunction,
  activeLength,
  defaultEndTime,
  defaultStartTime,
  //new timeline prop
  refetch,
  setStartTime,
  equipmentData,
  setEndTime,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useStore();
  const [isOpenSelectProduct, setIsOpenSelectProduct] = useState(false);
  const [selectedJunctionForPlanned, setSelectedJunctionForPlanned] = useState(null);
  const [junctionType, setJunctionType] = useState("internal");
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [qualityControlFormFix, setQualityControlFormFix] = useState(false);
  const [productType, setProductType] = useState("standart");
  const _data = junctionData
    ?.sort((f, s) => f?.planningOrder - s?.planningOrder)
    ?.filter((el) => {
      return el?.status === "planned";
    })[0];

  const sortedJunctions = junctionData?.sort((f, s) => moment(f?.startDate).unix() - moment(s?.startDate).unix());

  const activeJunction = junctionData?.filter((item) => item?.status === "active");
  const junctionNumber = (item) => {
    const junctions = sortedJunctions?.filter((a) => a.status != "planned");

    const sorted = junctions?.sort((a, b) => moment(a?.startDate).unix() - moment(b?.startDate).unix());

    const data = sorted.map((value, index) => {
      if (value?.id == item) {
        return index + 1;
      } else {
        return "";
      }
    });
    const lastData = data.filter((a) => a != "");
    return lastData[0];
  };

  const activeJunctionLength = junctionData?.filter((item) => item.status != "planned");
  const activeJunctionLengths = activeJunctionLength?.length;

  return (
    <>
      <div className="w-full  px-4 flex items-center my-4 h-fit">
        <div className="flex flex-col gap-1 w-[70px] min-w-[70px] max-w-[70px]">
          <div className="max-w-[90px] flex h-[22px]">
            <Badgets label={activeJunctionLengths + ` ${t("table:piece")}`} size={"sm"} colorType={"fill-success"} />
          </div>

          <p className={`text-base font-semibold text-[#079455] dark:text-[#16B364]`}>{t("embedded:works")}</p>
        </div>

        <div className="flex-1 flex items-start ml-4 overflow-x-auto pb-3">
          {/* {activeJunction?.length > 0 &&
            activeJunction?.map((a) => {
              return (
                <>
                  <div
                    onClick={() => {
                      if (!qualityControlFormFix) {
                        if (selectedJunction?.id === a?.id) {
                          setSelectedJunction(!selectedJunction);
                          setStartTime(defaultStartTime);
                          setEndTime(new Date());
                        } else {
                          setSelectedJunction(a);
                          setStartTime(new Date(timeFormatter(a?.startDate).formatted).valueOf());
                          setEndTime(new Date(timeFormatter(new Date()).formatted).valueOf());
                        }
                      }
                    }}
                    className={`flex flex-col mr-4  cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full  ${
                      selectedJunction?.id === a?.id ? "border-[#7F56D9] border-[1.5px]" : "border-[#E4E7EC] hover:border-secondary-300 border"
                    }  rounded-lg `}
                  >
                    <div className="min-w-[440px] max-w-[440px] flex flex-col gap-y-2 relative cursor-pointer ">
                      <div className="flex flex-col gap-y-8 mt-2">
                        <PersonnelDetailActiveCard
                          data={a}
                          setQualityControlFormFix={setQualityControlFormFix}
                          refetch={refetch}
                          setJunctionType={setJunctionType}
                          setFinishJunctionId={setFinishJunctionId}
                          setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                        />
                      </div>
                      {selectedJunction?.id === a?.id ? (
                        <div className="flex items-center justify-between w-full">
                          <div className="flex flex-row items-center gap-x-1">
                            <JunctionNumberIconSelected color={"#6941C6"} />
                            <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(a?.id)}</p>
                            <p className="font-medium text-sm text-[#6941C6]">{t("product:activeEvents")}</p>
                          </div>
                          <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p>
                        </div>
                      ) : (
                        <div className="flex flex-row items-center gap-x-1">
                          <JunctionNumberIcon color={"#667085"} />
                          <p className="text-md font-semibold text-secondary-600">{junctionNumber(a?.id)}</p>
                          <p className="font-medium text-sm text-secondary-600">{t("product:activeEvents")}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })} */}

          {[...activeJunction, ...junctionData?.filter((obj) => obj?.status != "planned")?.filter((a) => a?.status != "active"), _data]
            // ?.sort((a, b) => {
            //   if (a?.id === selectedJunction?.id) {
            //     return -1;
            //   } else if (b?.id === selectedJunction?.id) {
            //     return 1;
            //   } else {
            //     return 0;
            //   }
            // })
            ?.sort((a, b) => {
              if (a?.status === "planned" && b?.status !== "planned") {
                return 1;
              } else if (a?.status !== "planned" && b?.status === "planned") {
                return -1;
              } else {
                return 0;
              }
            })
            ?.map((item, index) => {
              if (moment(date).format("DD-MM-YYYY") == moment(new Date()).format("DD-MM-YYYY")) {
                if (item?.status === "active") {
                  return (
                    <>
                      <div
                        onClick={() => {
                          if (!qualityControlFormFix) {
                            if (selectedJunction?.id === item?.id) {
                              setSelectedJunction(!selectedJunction);
                              setStartTime(defaultStartTime);
                              setEndTime(new Date());
                            } else {
                              setSelectedJunction(item);
                              setStartTime(new Date(timeFormatter(item?.startDate).formatted).valueOf());
                              setEndTime(new Date(timeFormatter(new Date()).formatted).valueOf());
                            }
                          }
                        }}
                        className={`flex flex-col mr-4  cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full  ${
                          selectedJunction?.id === item?.id ? "border-[#7F56D9] border-[1.5px]" : "border-[#E4E7EC] hover:border-secondary-300 border"
                        }  rounded-lg `}
                      >
                        <div className="min-w-[440px] max-w-[440px] flex flex-col gap-y-2 relative cursor-pointer ">
                          <div className="flex flex-col gap-y-8 mt-2">
                            <PersonnelDetailActiveCard
                              data={item}
                              setQualityControlFormFix={setQualityControlFormFix}
                              refetch={refetch}
                              setJunctionType={setJunctionType}
                              setProductType={setProductType}
                              setFinishJunctionId={setFinishJunctionId}
                              setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                              equipmentData={equipmentData}
                            />
                          </div>
                          {selectedJunction?.id === item?.id ? (
                            <div className="flex items-center justify-between w-full">
                              <div className="flex flex-row items-center gap-x-1">
                                <JunctionNumberIconSelected color={"#6941C6"} />
                                <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(item?.id)}</p>
                                <p className="font-medium text-sm text-[#6941C6]">{t("product:activeEvents")}</p>
                              </div>
                              <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p>
                            </div>
                          ) : (
                            <div className="flex flex-row items-center gap-x-1">
                              <JunctionNumberIcon color={"#667085"} />
                              <p className="text-md font-semibold text-secondary-600">{junctionNumber(item?.id)}</p>
                              <p className="font-medium text-sm text-secondary-600">{t("product:activeEvents")}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  );
                } else if (item?.status === "completed") {
                  if (item?.station) {
                    return (
                      <>
                        <div
                          onClick={() => {
                            if (!qualityControlFormFix) {
                              if (selectedJunction?.id === item?.id) {
                                setSelectedJunction(!selectedJunction);
                                setStartTime(defaultStartTime);
                                setEndTime(defaultEndTime);
                              } else {
                                setSelectedJunction(item);
                              }
                            }
                          }}
                          className={`flex flex-col mr-4 cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full  ${
                            selectedJunction?.id === item?.id ? "border-[#7F56D9] border-[1.5px]" : "border-[#E4E7EC] hover:border-secondary-300 border"
                          }  rounded-lg `}
                        >
                          <div className="min-w-[440px] max-w-[440px] relative cursor-pointer ">
                            <div
                              className="flex flex-col gap-y-8 mt-2"
                              onClick={() => {
                                {
                                  setStartTime(new Date(timeFormatter(item?.startDate).formatted).valueOf());
                                  setEndTime(new Date(timeFormatter(item?.endDate).formatted).valueOf());
                                }
                              }}
                            >
                              <PersonnelDetailTodayDoneCard data={item} setQualityControlFormFix={setQualityControlFormFix} refetch={refetch}  equipmentData={equipmentData}/>
                            </div>
                          </div>
                          <div className="flex flex-row min-w-[440px] max-w-[440px] items-center gap-x-3 w-full mt-3">
                            {selectedJunction?.id === item?.id ? (
                              <div className="flex items-center justify-between w-full">
                                <div className="flex flex-row items-center gap-x-1">
                                  <JunctionNumberIconSelected color={"#6941C6"} />
                                  <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(item?.id)}</p>
                                </div>
                                {/* <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p> */}
                              </div>
                            ) : (
                              <div className="flex flex-row items-center gap-x-1">
                                <JunctionNumberIcon color={"#667085"} />
                                <p className="text-md font-semibold text-secondary-600">{junctionNumber(item?.id)}</p>
                              </div>
                            )}
                            <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                <p className="text-xs font-normal text-secondary-600">{"upu.point"}</p>
                              </div>
                              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                <p className={`text-base w-[100px] text-center truncate font-semibold text-[#9B7D07]`}>
                                  {item?.metadata?.upuPoint?.toFixed(2) || "0"}
                                </p>
                              </div>
                            </div>
                            <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                <p className="text-xs  font-normal text-secondary-600">{t("reports:performance")}</p>
                              </div>
                              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                <p className={`text-base w-[100px] truncate text-center font-semibold text-[#079455]`}>
                                  %{item?.metadata?.performancePercent > 1 ? 1 * 100 : (item?.metadata?.performancePercent * 100)?.toFixed(0) || "0"}
                                </p>
                              </div>
                            </div>
                            <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                <p className="text-xs font-normal text-secondary-600">{t("product:qualityControlRate")}</p>
                              </div>
                              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
                                  %{item?.metadata?.qualityPercent > 1 ? 1 * 100 : (item?.metadata?.qualityPercent * 100)?.toFixed(0) || "0"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  } else {
                    return <></>;
                  }
                } else if (item?.status === "planned") {
                  if (item?.station) {
                    return (
                      <>
                        <div className="group min-w-[300px] max-w-[300px] mr-4 relative mt-8" key={index}>
                          <div
                            onClick={() => {
                              if (item?.internalOperation) {
                                navigate(`/app/product/mrp/work-in-process/operation-detail/${item?.internalOperation?.id}`);
                              }
                            }}
                            className="absolute -top-8 z-20 right-0 cursor-pointer"
                          >
                            <Badgets label={"View All Planned Works"} size={"sm"} colorType={"outline-warning"} />
                          </div>
                          <SingleJunctionCard
                            id={item?.id}
                            t={t}
                            junctionData={item}
                            props={{
                              status: item?.status,
                              leftImage: item?.job?.product?.image,
                              texts: {
                                second: item?.job?.product?.code,
                                first: item?.job?.product?.name,
                                piece: item?.job?.piece,
                                time: secondToDay(item?.header.totalEstimatedTime),
                              },
                              rightButton: () => {
                                setSelectedJunctionForPlanned(item);
                                setIsOpenSelectProduct(true);
                              },
                            }}
                          />
                          <button
                            onClick={async () => {
                              await junctionService.deleteStationForJunction(item?.id).then((res) => {
                                if (res.data.code == 0) {
                                  Toast("success", "Junction deleted");
                                  refetch();
                                } else {
                                  Toast("error", "Junction not deleted");
                                }
                              });
                            }}
                            type="button"
                            className="w-8 h-8 rounded-lg bg-white border border-[#FDA29B] hidden group-hover:flex items-center justify-center absolute -top-2 right-0"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                              <path
                                d="M13.3333 5.00008V4.33341C13.3333 3.39999 13.3333 2.93328 13.1517 2.57676C12.9919 2.26316 12.7369 2.00819 12.4233 1.8484C12.0668 1.66675 11.6001 1.66675 10.6667 1.66675H9.33333C8.39991 1.66675 7.9332 1.66675 7.57668 1.8484C7.26308 2.00819 7.00811 2.26316 6.84832 2.57676C6.66667 2.93328 6.66667 3.39999 6.66667 4.33341V5.00008M8.33333 9.58342V13.7501M11.6667 9.58342V13.7501M2.5 5.00008H17.5M15.8333 5.00008V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9684C15.3212 17.4388 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4388 4.43915 16.9684C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00008"
                                stroke="#B42318"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                        {/* <div
                          className={`flex flex-col mr-4 border-[#E4E7EC] hover:border-secondary-300 border  cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full rounded-lg `}
                          key={index}
                        >
                          <div className="min-w-[440px] max-w-[440px] relative cursor-pointer ">
                            <div className="flex flex-row items-center gap-x-1">
                              <JunctionNumberIcon color={"#667085"} />

                              <p className="font-medium text-sm text-secondary-600">{t("product:planedEvent")}</p>
                              <div
                                onClick={() => {
                                  if (item?.internalOperation) {
                                    navigate(`/app/product/mrp/work-in-process/operation-detail/${item?.internalOperation?.id}`);
                                  }
                                }}
                                className="ml-auto flex flex-row items-center gap-x-1 cursor-pointer"
                              >
                                <p className="text-sm font-semibold text-[#6941C6]">{t("losses:viewAll")}</p>
                                <ArrowRightIcon />
                              </div>
                            </div>

                            <div className="flex flex-col gap-y-8 mt-3">
                              <EquipmentDetailPlannedCard
                                data={item}
                                refetch={refetch}
                                setSelectedJunctionForPlanned={setSelectedJunctionForPlanned}
                                setIsOpenSelectProduct={setIsOpenSelectProduct}
                              />
                            </div>
                          </div>
                        </div> */}
                      </>
                    );
                  }
                }
              } else {
                return (
                  item?.status === "completed" && (
                    <div
                      onClick={() => {
                        if (selectedJunction?.id === item?.id) {
                          setSelectedJunction(!selectedJunction);
                          setStartTime(defaultStartTime);
                          setEndTime(defaultEndTime);
                        } else {
                          setSelectedJunction(item);
                          setStartTime(new Date(timeFormatter(item?.startDate).formatted).valueOf());
                          setEndTime(new Date(timeFormatter(item?.endDate).formatted).valueOf());
                        }
                      }}
                      className={`flex flex-col mr-4 cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full  ${
                        selectedJunction?.id === item?.id ? "border-[#7F56D9] border-[1.5px]" : "border-[#E4E7EC] hover:border-secondary-300 border"
                      }  rounded-lg `}
                    >
                      <div className="min-w-[440px] max-w-[440px] relative cursor-pointer ">
                        <div
                          className="flex flex-col gap-y-8 mt-2"
                          onClick={() => {
                            {
                              setStartTime(new Date(timeFormatter(item?.startDate).formatted).valueOf());
                              setEndTime(new Date(timeFormatter(item?.endDate).formatted).valueOf());
                            }
                          }}
                        >
                          <PersonnelDetailDoneCard data={item} refetch={refetch} setQualityControlFormFix={setQualityControlFormFix} equipmentData={equipmentData}/>
                        </div>
                      </div>
                      <div className="flex flex-row min-w-[440px] max-w-[440px] items-center gap-x-3 w-full mt-3">
                        {selectedJunction?.id === item?.id ? (
                          <div className="flex items-center justify-between w-full">
                            <div className="flex flex-row items-center gap-x-1">
                              <JunctionNumberIconSelected color={"#6941C6"} />
                              <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(item?.id)}</p>
                            </div>
                            {/* <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p> */}
                          </div>
                        ) : (
                          <div className="flex flex-row items-center gap-x-1">
                            <JunctionNumberIcon color={"#667085"} />
                            <p className="text-md font-semibold text-secondary-600">{junctionNumber(item?.id)}</p>
                          </div>
                        )}
                        <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                            <p className="text-xs font-normal text-secondary-600">{"upu.point"}</p>
                          </div>
                          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                            <p className={`text-base w-[100px] text-center truncate font-semibold text-[#9B7D07]`}>
                              {item?.metadata?.upuPoint?.toFixed(2) || "0"}
                            </p>
                          </div>
                        </div>
                        <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                            <p className="text-xs  font-normal text-secondary-600">{t("reports:performance")}</p>
                          </div>
                          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                            <p className={`text-base w-[100px] truncate text-center font-semibold text-[#079455]`}>
                              %{item?.metadata?.performancePercent > 1 ? 1 * 100 : (item?.metadata?.performancePercent * 100)?.toFixed(0) || "0"}
                            </p>
                          </div>
                        </div>
                        <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                            <p className="text-xs font-normal text-secondary-600">{t("product:qualityControlRate")}</p>
                          </div>
                          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                            <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
                              %{item?.metadata?.qualityPercent > 1 ? 1 * 100 : (item?.metadata?.qualityPercent * 100)?.toFixed(0) || "0"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              }
            })}
        </div>
      </div>
      <CustomModal
        isOpen={isOpenSelectProduct}
        setIsOpen={setIsOpenSelectProduct}
        modalTitle={t("embedded:startJob")}
        isBadges={true}
        badgesValue={activeLength + "/" + auth?.user?.company?.limits?.junction}
        badgesColor={"fill-primary"}
        badgesSize={"md"}
        children={<SelectUserModal selectedJunction={selectedJunctionForPlanned} setIsOpen={setIsOpenSelectProduct} handleData={refetch} />}
      />
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            type={junctionType}
            productType={productType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
    </>
  );
};

export default PersonalJunctions;
