import React, { useState } from "react";
import { ClockIcon, NewDotsIcon, PieceIcon } from "../../../../components/new-cards/card-icons";
import { NoEquIcon, ProductDetailIcon } from "../../../../assets/icons/machineIcons";
import NoAvatar from "../../../../components/avatar/NoAvatar";
import CustomImage from "../../../../components/CustomImage";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ServiceSupplyPlannedActiveCard = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [showDropDown, setShowDropDown] = useState(false);

  const menuOptions = [
    {
      label: t("product:productDetail"),
      value: "productDetail",
      icon: <ProductDetailIcon />,
    },
  ];
  return (
    <div
      className={`flex w-full flex-col border border-t-4 cursor-pointer ${item?.type == "work" ? "border-t-success-500 " : item?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
        } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
    >
      <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
        <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
          {item.productType === "flex" ? (
            <>
              {item?.flexProduct?.image ? (
                <div
                  data-tooltip-id={`${item?.id}`}
                  data-tooltip-place={"right"}
                  data-tooltip-content={`${t("product:productName")} : ${item?.flexProduct?.name} `}
                  className="cursor pointer"
                >
                  <CustomImage
                    borderRadius={2}
                    src={item?.flexProduct?.image}
                    style={{
                      width: 64,
                      height: 48,
                      minWidth: 64,
                      minHeight: 48,
                      borderRadius: 4,
                      borderWidth: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              ) : (
                <div className="w-[64px] min-w-[64px] min-h-[48px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                  <NoEquIcon />
                </div>
              )}
            </>
          ) : (
            <>
              {item?.job?.product?.image ? (
                <div
                  data-tooltip-id={`${item?.id}`}
                  data-tooltip-place={"right"}
                  data-tooltip-content={`${t("product:productName")} : ${item?.name} `}
                  className="cursor pointer"
                >
                  <CustomImage
                    borderRadius={2}
                    src={item?.job?.product?.image}
                    style={{
                      width: 64,
                      height: 48,
                      minWidth: 64,
                      minHeight: 48,
                      borderRadius: 4,
                      borderWidth: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              ) : (
                <div className="w-[64px] min-w-[64px] min-h-[48px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                  <NoEquIcon />
                </div>
              )}
            </>
          )}
          <div className="flex flex-col items-start gap-y-[6px]">
            <div className="flex flex-row items-center gap-x-1 w-full">
              <p className="text-sm font-semibold text-secondary-900 truncate w-[210px]">{item?.productType === "flex" ? item?.flexProduct?.name : item?.job?.product?.name || "--"}</p>
            </div>

            <div className="flex flex-row items-center gap-x-2">
              <div className="flex flex-row items-center">
                <ClockIcon />
                <p className="text-xs font-medium ml-1 text-secondary-600">{moment(item?.startDate).format("HH:mm")}</p>
                <p className="text-xxs font-normal ml-0.5 mt-[1px] text-secondary-600">{t("product:timeStarted")}</p>
              </div>
            </div>
          </div>
        </div>
        {item?.user?.avatar ? (
          <div
            data-tooltip-id={`${item?.id}`}
            data-tooltip-place={"left"}
            data-tooltip-content={`${item?.user?.name + " " + item?.user?.lastName} `}
            className="cursor-pointer ml-auto"
          >
            <CustomImage
              src={item?.user?.avatar}
              style={{
                width: 48,
                height: 48,
                flexDirection: "column",
                borderRadius: 100,
                display: "flex",
                border: "1px solid #0000001A",
                borderColor: "#0000001A",
                backgroundColor: "#fff",
              }}
            />
          </div>
        ) : (
          <div
            data-tooltip-id={`${item?.id}`}
            data-tooltip-place={"left"}
            data-tooltip-content={`${item?.user?.name + " " + item?.user?.lastName} `}
            className="cursor-pointer ml-auto"
          >
            <NoAvatar size={48} fontSize={22} name={item?.user?.name} lastName={item?.user?.lastName} color={"#B54708"} />
          </div>
        )}
      </div>
      <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB]  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
        <div className="flex flex-row items-center">
          <PieceIcon />
          <p className="text-xs font-medium ml-1 text-secondary-600">{item?.productType === "flex" ? item?.flexProduct?.piece || "--" : item?.header?.piece || "--"}</p>
          <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:plannedQuantity")}</p>
        </div>

        {/* <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 pl-2 pr-1 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
          <p className="text-xxs text text-secondary-600 font-semibold">{t("product:qualityNew")}</p>
          <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded-[2px]">
            <p className="text-xxs font-semibold text-white">%{item?.qualityPercent || "--"}</p>
          </div>
        </div> */}
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowDropDown(!showDropDown);
        }}
        className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
      >
        <NewDotsIcon />
      </div>
      {showDropDown && (
        <div
          className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
        >
          {menuOptions?.map((items, index) => {
            return (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (items.value === "productDetail") {
                    const url = `/app/product/mrp/work-in-process/detail/${item?.job?.id}+${item?.header?.step}`;
                    const urlFlex = `/app/product/mes/flex/operation/detail/${item?.flexProduct?.id}+${item?.flexHeader?.step}`
                    if (item?.productType === "flex") {
                      navigate(urlFlex);
                    } else {
                      navigate(url);
                    }
                  }

                  setShowDropDown(false);
                }}
                key={index}
                type="button"
                className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${items?.value === "delete" || items.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                  }`}
              >
                {items?.icon}
                <p className={`font-medium text-sm  ${items?.value === "delete" || items.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}>
                  {items.label}
                </p>
              </button>
            );
          })}
        </div>
      )}

      <Tooltip className="z-[99999]" id={`${item?.id}`} />
    </div>
  );
};

export default ServiceSupplyPlannedActiveCard;
