import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon, MinusIcon } from "../../assets/icons/productIcons";
import { Button, CustomModal, Input, Loading } from "../../components";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import NewFlexOrderThirdModal from "./modals/NewFlexOrderThirdModal";
import NoAvatar from "../../components/avatar/NoAvatar";
import { CheckIcon, ChevronDown } from "../../assets/icons/modalIcons";
import Badgets from "../../components/buttons/Badgets";
import { orderService } from "../../services/order.service";
import { Toast } from "../../utils/toastify/toast";
import NewOrderRawMaterialCard from "./cards/NewOrderRawMaterialCard";
import NewOrderOperationCard from "./cards/NewOrderOperationCard";
import NewFlexOrderSelectedMaterial from "./modals/NewFlexOrderSelectedMaterial";

const ManagementOrderSupplyForm = ({}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const planningData = JSON.parse(sessionStorage.getItem("managementPlanningData"));
  const { orderId } = useParams();
  const [selectedRawModal, setSelectedRawModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [materials, setMaterials] = useState([]);

  const tableHeadItems = [t("product:startingProduct"), t("product:stockAmount"), t("product:plannedQuantitys"), t("product:requiredQuantity"), t("product:supplyType"), t("product:remove")];
  const firsTableHeadItems = [t("product:productss"), t("product:deadline"), t("product:quantityToBeSentProduct"), t("product:plannedQuantityy")];

  const handleMaterialSelection = (selectedMaterial) => {
    const isAlreadyAdded = materials.some((material) => material.id === selectedMaterial.id);
    if (isAlreadyAdded) {
      Toast("error", "Bu materyal zaten eklendi.");
      return;
    }

    setMaterials((prev) => [
      ...prev,
      {
        ...selectedMaterial,
        stockValue: "",
        purchaseValue: "",
      },
    ]);
  };

  const removeRow = (rowIndex) => {
    setMaterials((prev) => prev.filter((_, index) => index !== rowIndex));
  };

  const updateRowValues = (rowIndex, field, value) => {
    setMaterials((prev) => {
      const updatedMaterials = [...prev];
      const material = updatedMaterials[rowIndex];

      const maxAllowedValue =
        material?.type === "raw"
          ? Number((planningData?.firstPageData?.product?.quantityUsedPerPiece || 1) * planningData?.productionQuantity)
          : planningData?.productionQuantity;

      const currentStockValue = Number(material?.stockValue || 0);
      const currentPurchaseValue = Number(material?.purchaseValue || 0);

      if (field === "stockValue") {
        const newStockValue = Math.min(Number(value || 0), maxAllowedValue);
        const remainingValue = maxAllowedValue - newStockValue;

        if (currentPurchaseValue > remainingValue) {
          material.purchaseValue = remainingValue;
        }

        material.stockValue = newStockValue;
      } else if (field === "purchaseValue") {
        const newPurchaseValue = Math.min(Number(value || 0), maxAllowedValue - currentStockValue);
        material.purchaseValue = newPurchaseValue;
      } else if (field === "stockChecked" || field === "purchaseChecked") {
        material[field] = value;
      }

      updatedMaterials[rowIndex] = material;
      return updatedMaterials;
    });
  };

  const getValueDivided = (material) => {
    const quantity = material?.type === "raw" ? Number(planningData?.firstPageData?.product?.quantityUsedPerPiece) || 1 : 1;
    const purchaseValue = material?.purchaseChecked ? Number(material?.purchaseValue || 0) : 0;
    const stockValue = Number(material?.stockValue || 0) || 0;
    return (purchaseValue + stockValue) / quantity;
  };

  const totalPlannedQuantity = Math.round(materials.reduce((acc, material) => acc + getValueDivided(material), 0));

  const onSubmit = async () => {
    setButtonLoading(true);
    const sendData = {
      stockQuantity: planningData?.stockQuantity,
      productionQuantity: planningData?.productionQuantity,
      purchaseRequest: materials?.map((material) => ({
        type: material?.step ? (material?.type === "internal" ? "semi_internal_product" : "semi_external_product") : "raw",
        productionQuantity:
          material?.type === "raw"
            ? planningData?.productionQuantity * (parseFloat(planningData?.firstPageData?.product?.quantityUsedPerPiece) || 1)
            : planningData?.productionQuantity,
        stockQuantity: material?.stockValue || 0,
        purchaseQuantity: material?.purchaseValue || 0,
        typeId: material?.id,
      })),
    };
    await orderService.sendOrderForWIP(orderId, sendData).then((res) => {
      if (res?.data?.code == 0) {
        Toast("success", res?.data?.message);
        sessionStorage.removeItem("managementPlanningData");
        navigate("/app/product/mrp/orders");
      } else {
        Toast("error", res?.data?.message);
      }
    });
    setButtonLoading(false);
  };

  return (
    <>
      <div className="flex flex-col w-full gap-y-8 h-[95vh]">
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
          <p className="text-[#101828] font-semibold text-2xl">{t("product:createFlexRawMaterial")}</p>
        </div>

        <div className="flex flex-col gap-y-6 w-full h-full">
          <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
            <div className="flex flex-col gap-y-1">
              <span className="flex gap-x-2">
                <p className="text-[#101828] font-semibold text-lg">{t("product:supplyForm")}</p>
                {/* <Badgets colorType={"fill-warning"} size={"md"} label={`${indexNumber + 1}/${SecondPageData?.selectedProducts?.length}`} /> */}
              </span>
              <p className="text-[#344054] font-normal text-sm">{t("product:selectTheSupply")}</p>
            </div>
            <div className="flex gap-x-3 max-h-[40px] min-h-[40px] h-10 relative">
              <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                <Button size={"md"} colorType={"secondary-gray"} label={t("buttons:previous")} onClick={() => navigate(-1)} />
              </span>
              <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                <Button
                  size={"md"}
                  disabled={
                    buttonLoading || planningData?.productionQuantity < totalPlannedQuantity || planningData?.productionQuantity != totalPlannedQuantity
                      ? true
                      : false
                  }
                  colorType={"primary-product"}
                  label={buttonLoading ? <Loading color={"#fff"} secondary={"#fff"} size={22} /> : t("buttons:save")}
                  onClick={onSubmit}
                />
              </span>
            </div>
          </div>
          {/* İLK TABLO */}
          <div className="flex flex-col w-full min-h-[170px] max-h-[170px] h-[170px] border rounded-xl border-[#E4E7EC]">
            <div className="flex w-full min-h-[56px] max-h-[56px] h-[56px] justify-between bg-white rounded-t-xl items-center px-6 py-4 border-b border-[#E4E7EC]">
              <div className="flex gap-x-2 items-center">
                <img
                  src={generateFileUrl(planningData?.firstPageData?.order?.customer?.image)}
                  className="min-w-[32px] w-[32px] h-[24px] rounded-sm border border-[#0000001A]"
                />
                <p className="text-[#101828] font-medium text-sm">{planningData?.firstPageData?.order?.customer?.name || "--"}</p>
              </div>
              <div className="flex w-fit px-3 gap-x-6">
                <div className="flex gap-x-2 items-center">
                  <CalendarIconNew />
                  <p className="text-[#475467] text-sm">{t("product:orderGeneratedDate")}</p>
                  <p className="text-[#B54708] font-semibold text-md">
                    {moment(planningData?.firstPageData?.order?.createdDate).format("DD.MM.YYYY") || "--.--.--"}
                  </p>
                </div>
                <div className="flex gap-x-2 items-center">
                  <OrderIcon />
                  <p className="text-[#475467] text-sm">{t("product:orderNo")}</p>
                  <p className="text-[#B54708] font-semibold text-md">{planningData?.firstPageData?.order?.orderNo || "--"}</p>
                </div>
                <div className="flex gap-x-2 items-center">
                  <OrderIcon />
                  <p className="text-[#475467] text-sm">{t("product:customerOrderNo")}</p>
                  <p className="text-[#B54708] font-semibold text-md">{planningData?.firstPageData?.order?.customerOrderNo || "--"}</p>
                </div>
              </div>
            </div>
            <div className={`flex flex-col w-full h-full`}>
              <div className="flex max-h-[34px] min-h-[34px] w-full sticky top-0 bg-[#F9FAFB] z-50">
                {firsTableHeadItems.map((item, i) => (
                  <div
                    key={i}
                    className={`flex border-b px-6 py-2 border-r border-[#E4E7EC] items-center last:border-r-0 ${
                      i === 0 ? "justify-start min-w-[328px]" : "justify-center"
                    } w-1/4`}
                  >
                    <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                  </div>
                ))}
              </div>
              <div className="flex w-full items-center h-[80px] min-h-[80px]">
                {/* tablo 1.kolon */}
                <div className="flex items-center gap-x-2 py-4 px-6 border-r h-full justify-start w-[25%] min-w-[328px]">
                  <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(planningData?.firstPageData?.product?.image)} />
                  <div className="flex flex-col justify-center">
                    <p className="text-[#101828] font-semibold text-md ml-1">{planningData?.firstPageData?.product?.name || "--"}</p>
                    <span className="bg-[#FFFAEB] w-fit px-1 py-[3px]">
                      <p className="text-[#B54708] text-xs font-medium">{t("product:stock")}: {planningData?.firstPageData?.product?.stockCode || "--"}</p>
                    </span>
                  </div>
                </div>

                {/* tablo 2.kolon */}
                <div className="flex flex-col h-full px-6 py-4  items-center justify-center border-r w-[25%]">
                  <p className="text-md text-[#344054] font-medium">{moment(planningData?.firstPageData?.deliveryDate)?.format("DD.MM.YYYY") || "--.--.--"}</p>
                  <p className="text-xs text-[#667085]">{moment(planningData?.firstPageData?.deliveryDate)?.format("dddd")}</p>
                </div>
                {/* tablo 3.kolon */}
                <div className="flex flex-col h-full px-6 py-4  items-center justify-center border-r w-[25%]">
                  <p className="text-md text-[#344054] font-medium">{planningData?.productionQuantity || "--"}</p>
                  <p className="text-xs text-[#667085]">{t("product:pieces")}</p>
                </div>
                {/* tablo 4.kolon */}
                <div className="flex flex-col h-full px-6 py-4  items-center justify-center border-r w-[25%]">
                  {planningData?.productionQuantity < totalPlannedQuantity || planningData?.productionQuantity != totalPlannedQuantity ? (
                    <div className="flex items-center justify-center flex-col">
                      <p className="text-md text-red-500 font-medium animate-bounce">{totalPlannedQuantity}</p>
                      <p className="text-xs text-red-500 font-medium ">{t("product:thePlannedCannot")}</p>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <p className="text-md text-[#344054] font-medium">{totalPlannedQuantity}</p>

                      <p className="text-xs text-[#667085]">{t("product:pieces")}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* İKİNCİ TABLO */}
          <div className="flex w-full h-full border rounded-xl border-[#E4E7EC]">
            <div className={`flex flex-col w-full h-[50vh] overflow-y-auto overflow-x-hidden scrollbar-hide`}>
              <div className="flex max-h-[34px] min-h-[34px] w-full sticky top-0 bg-[#F9FAFB] z-50 rounded-t-xl">
                {tableHeadItems.map((item, i) => (
                  <div
                    key={i}
                    className={`flex border-b px-6 py-2 border-r border-[#E4E7EC] items-center last:border-r-0 ${
                      i === 0
                        ? "w-[27%] min-w-[27%] justify-start"
                        : i === 1 || i === 2 || i === 3
                        ? "w-[9%] min-w-[9%] justify-center"
                        : i === 4
                        ? "w-[42%] min-w-[42%] justify-center"
                        : "w-[4%] min-w-[4%] flex-none justify-center"
                    }`}
                  >
                    <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                  </div>
                ))}
              </div>
              {materials?.map((material, materialIndex) => (
                <div className="flex w-full items-center h-[144px] min-h-[144px] border-b">
                  {/* tablo 1.kolon */}
                  <div className="flex items-center py-4 px-6 border-r h-full justify-start w-[27%] min-w-[27%]">
                    {material?.type === "raw" ? (
                      <NewOrderRawMaterialCard item={material} />
                    ) : material?.step ? (
                      <NewOrderOperationCard item={material} />
                    ) : (
                      <div
                        onClick={() => {
                          setSelectedRawModal(true);
                        }}
                        className="flex flex-col gap-y-1 w-full h-full border-[2px] border-dashed shadow-xs border-[#D0D5DD] rounded-lg justify-center items-center cursor-pointer"
                      >
                        <AddPlusIcon />
                        <p className="text-[#475467] text-md"> {t("product:addSemiProduct")} </p>
                      </div>
                    )}
                  </div>
                  {/* tablo 2.kolon */}
                  <div className="flex flex-col h-full py-3 items-center justify-center border-r w-[9%] min-w-[9%]">
                    <p className="text-md text-[#344054] font-medium">{material?.type === "raw" ? material?.quantity || 0 : material?.activePiece || 0}</p>
                    <p className="text-xs text-[#667085]">{t("product:pieces")}</p>
                  </div>
                  {/* tablo 3.kolon */}
                  <div className="flex flex-col h-full py-3 items-center justify-center border-r w-[9%] min-w-[9%]">
                    <p className="text-md text-[#344054] font-medium">{Number(material?.stockValue) + Number(material?.purchaseValue)}</p>
                    <p className="text-xs text-[#667085]">{t("product:pieces")}</p>
                  </div>
                  {/* tablo 4.kolon */}
                  <div className="flex flex-col h-full py-3 items-center justify-center border-r w-[9%] min-w-[9%]">
                    <p className="text-md text-[#344054] font-medium">
                      {material?.type === "raw"
                        ? Number((planningData?.firstPageData?.product?.quantityUsedPerPiece || 1) * planningData?.productionQuantity)
                        : planningData?.productionQuantity}
                    </p>
                    <p className="text-xs text-[#667085]">{t("product:pieces")}</p>
                  </div>

                  {/* tablo 5.kolon */}
                  <div className="flex gap-x-6 h-full px-6 py-4  items-center justify-center border-r w-[42%] min-w-[42%]">
                    <div className="flex flex-col gap-y-3 w-1/2">
                      <div
                        onClick={() => {
                          if ((material?.type === "raw" ? material?.quantity : material?.activePiece) < 1) {
                            // updateRowValues(materialIndex, "stockChecked", !material?.stockChecked);
                            Toast("error", "Stok miktarı 0'dan büyük olmalıdır.");
                          } else {
                            updateRowValues(materialIndex, "stockChecked", !material?.stockChecked);
                          }
                        }}
                        className={`flex gap-x-2 items-center  w-fit `}
                      >
                        <div
                          className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                            material?.stockChecked ? "bg-[#DC6803] border-[#DC6803] text-[#fff]" : "border-[#D0D5DD]"
                          }`}
                        >
                          {material?.stockChecked && <CheckIcon />}
                        </div>
                        <div className="text-secondary-500 font-normal text-sm">{t("product:bringFromStock")}</div>
                        <span className="group flex relative">
                          <HelpCircleIcon />
                          <div className="hidden absolute min-w-[293px] max-w-[293px] w-[293px] min-h-[52px] max-h-[52px] h-[52px] group-hover:flex bottom-[26px] -left-[136px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                            <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:enterTheDeducted")}</p>
                          </div>
                          <span className="hidden absolute group-hover:flex bottom-[20px]">
                            <TriangleTooltipIcon />
                          </span>
                        </span>
                      </div>
                      <div className="flex w-full h-full">
                        <Input
                          icon={<QuantityIcon />}
                          theme="product"
                          value={material?.stockValue || ""}
                          onChange={(e) => updateRowValues(materialIndex, "stockValue", e.target.value)}
                          disabled={!material?.stockChecked}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-3 w-1/2">
                      <div
                        onClick={() => {
                          updateRowValues(materialIndex, "purchaseChecked", !material?.purchaseChecked);
                        }}
                        className="flex gap-x-2 items-center cursor-pointer w-fit"
                      >
                        <div
                          className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                            material?.purchaseChecked ? "bg-[#DC6803] border-[#DC6803] text-[#fff]" : "border-[#D0D5DD]"
                          }`}
                        >
                          {material?.purchaseChecked && <CheckIcon />}
                        </div>
                        <div className="text-secondary-500 font-normal text-sm">{t("product:markPurchase")}</div>
                        <span className="group flex relative">
                          <HelpCircleIcon />
                          <div className="hidden absolute min-w-[370px] max-w-[370px] w-[370px] min-h-[70px] max-h-[70px] h-[70px] group-hover:flex bottom-[26px] -left-[175px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                            <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:youCanSendTheEntire")}</p>
                          </div>
                          <span className="hidden absolute group-hover:flex bottom-[20px]">
                            <TriangleTooltipIcon />
                          </span>
                        </span>
                      </div>
                      <div className="flex w-full h-full">
                        <Input
                          icon={<QuantityIcon />}
                          theme="product"
                          value={material?.purchaseValue || ""}
                          onChange={(e) => updateRowValues(materialIndex, "purchaseValue", e.target.value)}
                          disabled={!material?.purchaseChecked}
                        />
                      </div>
                    </div>
                  </div>
                  {/* tablo 6.kolon */}
                  <div className="flex flex-col h-full py-3 items-center justify-center border-r w-[4%] min-w-[4%]">
                    <Button colorType={"secondary-error"} size={"sm"} iconLeft={<MinusIcon />} onClick={() => removeRow(materialIndex)} />
                  </div>
                </div>
              ))}
              <div className="flex w-full items-center h-[144px] min-h-[144px] border-b">
                <div className="flex items-center py-4 px-6 border-r h-full justify-start w-[28%] min-w-[360px]">
                  <div
                    onClick={() => {
                      setSelectedRawModal(true);
                    }}
                    className="flex flex-col gap-y-1 w-full h-full border-[2px] border-dashed shadow-xs border-[#D0D5DD] rounded-lg justify-center items-center cursor-pointer"
                  >
                    <AddPlusIcon />
                    <p className="text-[#475467] text-md">{t("product:addRawMaterialIntermediate")} </p>
                  </div>
                </div>
                <div className="flex flex-col h-full border-r w-[9%] min-w-[9%]"></div>
                <div className="flex flex-col h-full border-r w-[9%] min-w-[9%]"></div>
                <div className="flex flex-col h-full border-r w-[9%] min-w-[9%]"></div>
                <div className="flex h-full border-r w-[42%] min-w-[42%]"></div>
                <div className="flex flex-col h-full border-r w-[4%] min-w-[4%]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={selectedRawModal}
        setIsOpen={setSelectedRawModal}
        modalTitle={t("product:addRawMaterialIntermediate")}
        subTitle={t("product:addTheNecessaryProduct")}
        width={430}
      >
        <NewFlexOrderSelectedMaterial
          setSelectRawCardModal={setSelectedRawModal}
          handleRawMaterialSelection={handleMaterialSelection}
          rowProductsData={planningData?.firstPageData?.product}
        />
      </CustomModal>
    </>
  );
};

export default ManagementOrderSupplyForm;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

const HelpCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17461_3132)">
        <path
          d="M6.06016 6.00016C6.2169 5.55461 6.52626 5.1789 6.93347 4.93958C7.34067 4.70027 7.81943 4.61279 8.28495 4.69264C8.75047 4.77249 9.17271 5.01451 9.47688 5.37585C9.78105 5.73718 9.94753 6.19451 9.94683 6.66683C9.94683 8.00016 7.94683 8.66683 7.94683 8.66683M8.00016 11.3335H8.00683M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z"
          stroke="#98A2B3"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17461_3132">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CalendarIconNew = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 8.33332H2.5M13.3333 1.66666V4.99999M6.66667 1.66666V4.99999M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8212 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7335 17.5 14.3333V7.33332C17.5 5.93319 17.5 5.23313 17.2275 4.69835C16.9878 4.22794 16.6054 3.84549 16.135 3.60581C15.6002 3.33332 14.9001 3.33332 13.5 3.33332H6.5C5.09987 3.33332 4.3998 3.33332 3.86502 3.60581C3.39462 3.84549 3.01217 4.22794 2.77248 4.69835C2.5 5.23313 2.5 5.93319 2.5 7.33332V14.3333C2.5 15.7335 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const TriangleTooltipIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9" viewBox="0 0 17 9" fill="none">
      <path
        d="M8.5 0.49993L14.5711 0.49993C15.462 0.49993 15.9081 1.57707 15.2782 2.20704L9.20711 8.2781C8.81658 8.66863 8.18342 8.66863 7.79289 8.2781L1.72183 2.20704C1.09186 1.57707 1.53803 0.49993 2.42893 0.49993L8.5 0.49993Z"
        fill="#0C111D"
      />
    </svg>
  );
};

const OrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6667 8.74984V5.6665C16.6667 4.26637 16.6667 3.56631 16.3942 3.03153C16.1545 2.56112 15.7721 2.17867 15.3017 1.93899C14.7669 1.6665 14.0668 1.6665 12.6667 1.6665H7.33337C5.93324 1.6665 5.23318 1.6665 4.6984 1.93899C4.22799 2.17867 3.84554 2.56112 3.60586 3.03153C3.33337 3.56631 3.33337 4.26637 3.33337 5.6665V14.3332C3.33337 15.7333 3.33337 16.4334 3.60586 16.9681C3.84554 17.4386 4.22799 17.821 4.6984 18.0607C5.23318 18.3332 5.93324 18.3332 7.33337 18.3332M10 7.49984H6.66671M11.6667 4.99984H6.66671M8.33337 9.99984H6.66671M9.83711 12.6319L12.8556 10.9452C12.9978 10.8658 13.0688 10.8261 13.1441 10.8105C13.2108 10.7967 13.2795 10.7967 13.3462 10.8103C13.4215 10.8258 13.4927 10.8654 13.6349 10.9446L16.6667 12.6319M9.83711 12.6319L13.2451 14.5309M9.83711 12.6319V15.9547C9.83711 16.1253 9.83711 16.2106 9.86218 16.2868C9.88436 16.3541 9.92062 16.416 9.96856 16.4683C10.0227 16.5274 10.0972 16.5691 10.246 16.6525L13.2451 18.3332M16.6667 12.6319L13.2451 14.5309M16.6667 12.6319V15.9537C16.6667 16.1247 16.6667 16.2102 16.6416 16.2864C16.6193 16.3539 16.5829 16.4159 16.5349 16.4682C16.4805 16.5273 16.4059 16.569 16.2566 16.6523L13.2451 18.3332M13.2451 14.5309V18.3332"
        stroke="#475467"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const AddPlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M15.9998 6.6665V25.3332M6.6665 15.9998H25.3332" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
