// FilterDrawer.js
import React from "react";
import "./FilterDrawer.css";

const FilterDrawerLast = ({ isOpen, onClose, width, children }) => {
  const drawerStyle = {
    width: isOpen ? `${width}px` : 0,
    border: "2px solid #D0D5DD",
    zIndex: "100",
    height: "100%",

    // position : "absolute",
    // right : "0",
    // top : "20"
  };

  return (
    <div className={`filter-drawer ${isOpen ? "open" : ""}`} style={drawerStyle}>
      <div className="content">{children}</div>
    </div>
  );
};

export default FilterDrawerLast;
