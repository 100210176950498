import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { ThreeDots } from "../../../assets/icons/gaugeChartIcons";
import GradeChart from "../Twin/chart/GradeChart";
import { InternalOperationIcon } from "../../../assets/icons/stepHeaderIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../../../assets/icons/departmentIcon";
import { OperationDetailIcon } from "../../../assets/icons/machineIcons";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const DashboardSwiperItem = ({
  name,
  process,
  enabled,
  setSelectedProcess,
  selectedProcess,
  index,
  processId,
  setVisibleButton,
  setIsIntersecting,
  dataLength,
  setVisibleRightButton,
  handleEdit,
}) => {
  const divRef = useRef();
  const menuRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [imageError, setImageError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [view, setView] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const getOperationDetails = useCallback(() => {
    navigate(
      `operation-detail/${processId}/${moment(new Date()).format("YYYY-MM-DD")}`
    );
  }, [navigate, processId]);

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (index === 0) {
          if (entry.isIntersecting) {
            setVisibleButton(false);
            setIsIntersecting(entry.isIntersecting);
          } else {
            setVisibleButton(true);
          }
        } else if (index === dataLength - 1) {
          if (entry.isIntersecting) {
            setVisibleRightButton(false);
          } else {
            setVisibleRightButton(true);
          }
        }
      });
    }, options);

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    //   return () => {
    //     if (divRef.current) {
    //       observer.unobserve(divRef.current);
    //     }
    //   };
    // }, []);
    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [
    index,
    dataLength,
    setVisibleButton,
    setIsIntersecting,
    setVisibleRightButton,
  ]);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setView(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  

  return (
    <div className="relative">
      <div
        ref={divRef}
        key={index}
        className={`flex flex-col cursor-pointer w-[166px] max-w-[166px] min-w-[166px] min-h-[138px] gap-[8px]  pt-2  rounded-lg border  items-center overflow-hidden shadow-md ${
          selectedProcess?.id == process.id ? "border-primary-600" : "border-secondary-200"
        }`}
        // onDoubleClick={() => getOperationDetails()}
        onDoubleClick={getOperationDetails}
        onClick={() => {
          if (selectedProcess?.id == process?.id) {
            setSelectedProcess('all');
          }
          else {setSelectedProcess(process)}
        
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          onClick={() => setSelectedProcess(process?.id)}
          className={`px-1 py-1 cursor-pointer w-full flex items-center justify-center text-[10px] gap-x-2 min-h-6 max-h-5 ${
            selectedProcess === process?.id
              ? " shadow-ring-machine  dark:border-[#7F56D9]"
              : " border-secondary-300 dark:bg-[#292929] dark:border-[#424242]"
          }`}
        >
          <div className="w-[80%] flex items-center gap-x-2 flex-row justify-center">
            {/* {imageError ? (
              <InternalOperationIcon width={20} height={20} />
            ) : (
              <img
                src={generateFileUrl(process?.iconKey)}
                onError={handleImageError}
                className="max-w-[28px] max-h-[20px] h-[20px] ml-1"
                alt={name}
              />
            )} */}
            <p
              className={`text-xs font-medium  ${
                selectedProcess === process.id
                  ? "text-secondary-700 dark:text-[#FFFFFF]"
                  : "text-secondary-700 dark:text-[#FFFFFF]"
              }  `}
              style={{
                display: "inline-block",
                width: "auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                numberOfLines: 1,
                textOverflow: "ellipsis",
              }}
            >
              {process?.name}
            </p>
          </div>
          {isHovered && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setView(!view);
              }}
              type="button"
              className="w-6 h-6 group flex items-center -mt-0.5 justify-center cursor-pointer "
            >
              <ThreeDots color="#667085" className="opacity-100" />
            </div>
          )}
        </div>

        {enabled && (
          <div className="min-h-[98px] max-h-[98px] min-w-full max-w-full relative dark:bg-[#292929] transition delay-150 duration-300 ">
            <div
              className="absolute -top-[18px] min-h-[90px] max-h-[90px] min-w-full max-w-full cursor-pointer "
              onClick={() => setSelectedProcess(process?.name)}
            >
              <GradeChart
                width={120}
                height={98}
                radius="115%"
                enabled={enabled}
                value={process.actual}
                kpi1={process.average ? process?.average.toFixed(2) : 0}
                kpi2={process.average == 0 ? 0 : process.kpiValue}
                isIncreaseStatus={process.isIncreaseStatus}
                timeMass={(process.timeMass / 3600).toFixed(1) || 25000}
              />
            </div>
          </div>
        )}
        {view === true && (
          <div
            ref={menuRef}
            className="absolute flex flex-col py-1  top-[36px] right-[12px]  border-[1px] border-secondary-200 z-50 shadow-lg bg-white rounded-lg cursor-pointer"
          >
            <div className="px-[6px] py-[1px] w-full flex-row items-center">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(process?.id);
                }}
                type="button"
                className={`flex flex-row items-center gap-2 hover:bg-secondary-50 bg-white px-[6px] py-[10px] min-w-[144px] min-h-[40px] cursor-pointer`}
              >
                <div className="w-4 h-4 flex items-center text-[#667085]">
                  <EditIcon />
                </div>
                <p className="text-secondary-700 text-sm font-medium">
                  {t("addProcess:editOperation")}
                </p>
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  getOperationDetails();
                }}
                className={`flex flex-row items-center gap-2 hover:bg-secondary-50 bg-white px-[6px] py-[10px] min-w-[144px] min-h-[44px] cursor-pointer`}
              >
                <div className="w-4 h-4 flex items-center text-[#667085]">
                  <OperationDetailIcon />
                </div>
                <p className="text-secondary-700 text-sm font-medium">
                  {t("addProcess:operationDetail")}
                </p>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(DashboardSwiperItem);
