import React from "react";
import { EndClockIcon, StartClockIcon } from "../card-icons";
import NoAvatar from "../../avatar/NoAvatar";
import CustomImage from "../../CustomImage";
import { LossIcon } from "../../../assets/icons/leftMenuIcons";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import moment from "moment";

const EquipmentDetailLossCard = ({ data, items,date }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-row items-center gap-x-3 w-full">
        <div
          className={`flex w-full flex-col border border-t-4 border-t-[#D92D20]  items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
        >
          <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
            <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
              <div className="w-[64px] min-w-[64px] h-[48px] bg-[#FEF3F2] border border-[#FDA29B] flex items-center justify-center font-medium text-[#D92D20] text-xl cursor-pointer">
                <LossIcon />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-sm font-semibold text-secondary-900 w-[200px] truncate"> {data?.type ? data?.type?.name : "Undefined Loss"}</p>
                <p className="text-xs font-normal text-secondary-500 w-[200px]  truncate">{data?.type?.description || "Unkown"}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB]  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
            <div className="flex flex-row items-center">
              <StartClockIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{moment(data?.startDate).format("HH:mm:ss")}</p>
              <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:timeStarteds")}</p>
            </div>
            <div className="flex flex-row items-center">
              <EndClockIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{moment(data?.endDate == null ? new Date() : data?.endDate).format("HH:mm:ss")}</p>
              <p className="text-xxs font-normal ml-0.5 mt-[1px] text-secondary-600">{t("product:timeEnds")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EquipmentDetailLossCard;
