import React, { useEffect, useState } from "react";
import { ArrowLeftIcon } from "../assets/icons/PersonIcons";
import { useNavigate } from "react-router-dom";
import ShiftCalendarTable from "./machine/components/ShiftCalendarTable";
import { Button } from "../components";
import { companyService } from "../services/company.service";

const OldDayShifts = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const handleData = async () => {
    const { data } = await companyService.getOldDayShifts();
    setData(
      data?.map((item) => {
        return { ...item, times: item?.shiftGroups?.shifts?.flat() };
      })
    );
  };
  useEffect(() => {
    handleData();
  }, []);
  return (
    <div className="w-full h-full flex flex-col">
      <div className="h-[56px] w-full flex items-center border-b">
        <Button
          size={"md"}
          onClick={() => navigate(-1)}
          colorType={"tertiary-gray"}
          iconLeft={
            <div className="text-secondary-700">
              <ArrowLeftIcon />
            </div>
          }
        />
        <h1 className="text-2xl font-semibold text-gray-900 ml-3">Geçmiş Günler</h1>
      </div>
      <div className="flex-1 w-full flex flex-col pt-5">
        <ShiftCalendarTable title={"Geçmiş Vardiyalar"} data={data} type="nonstandard" refetch={handleData} />
      </div>
    </div>
  );
};

export default OldDayShifts;
