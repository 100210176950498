import React, { memo, useState } from "react";
import { NavLink } from "react-router-dom";

const LargeCard = ({ to, item, segmentColor, index }) => {
  const [isHover, setIsHover] = useState(null);
  const SecondIcon = memo(({ children }) => {
    return <>{children}</>;
  });

  return (
    <>
      <NavLink
        onMouseEnter={() => setIsHover(index)}
        onMouseLeave={() => setIsHover(null)}
        to={to}
        className={"h-10 w-full px-3 py-2 flex items-center rounded-lg my-1 select-none"}
        style={({ isActive }) => ({
          color: isActive ? segmentColor?.labelActiveColor : segmentColor?.labelColor,
          backgroundColor: isActive || isHover === index ? segmentColor?.buttonColor : "transparent",
        })}
      >
        <div className="w-7 h-7 flex items-center justify-center">
          <SecondIcon>
            <item.icon />
          </SecondIcon>
        </div>
        <p className="ml-2 font-codec truncate">{item?.name}</p>
      </NavLink>
    </>
  );
};

export default memo(LargeCard);
