import React, { useEffect, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { Button, CustomModal } from "../../../components";
import { useTranslation } from "react-i18next";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { useQuery } from "@tanstack/react-query";
import { productService } from "../../../services/product.service";
import PurchaseRequestForm from "./PurchaseRequestForm";
import { Toast } from "../../../utils/toastify/toast";
import { QuantityIcon } from "../../../assets/icons/commonIcons";

const SupplyFormModal = ({
  onClose,
  refresh,
  orderData,
  setSupplyForm,
  selectedProduct,
}) => {
  const { t } = useTranslation();
  const [purchaseForm, setPurchaseForm] = useState(false);
  const [purchaseSelected, setPurchaseSelected] = useState([]);
  const [stockSelected, setStockSelected] = useState([]);

  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: ["product-raw-material"],
    queryFn: async () =>
      await productService.rawMaterialsDetail(selectedProduct?.id),
    retry: 0,
  });

  const tableHeadItems = [
    {
      name: t("product:productsAndMaterials"),
    },
    {
      name: t("product:perUnitCost"),
    },
    {
      name: t("product:orderQuantityss"),
    },

    {
      name: t("product:stockAmount"),
    },
    {
      name: t("product:supplyType"),
    },
  ];

  const togglePurchaseSelected = (item) => {
    const itemId = item.id;
    if (purchaseSelected.some((selectedItem) => selectedItem?.id === itemId)) {
      setPurchaseSelected((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem?.id !== itemId)
      );
    } else {
      setPurchaseSelected((prevSelected) => [...prevSelected, item]);
    }
    const foundIndex = stockSelected.findIndex(
      (selectedItem) => selectedItem.id === itemId
    );
    if (foundIndex !== -1) {
      setStockSelected((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem?.id !== itemId)
      );
    }
  };

  const toggleStockSelected = (item) => {
    const itemId = item?.id;
    if (stockSelected?.some((selectedItem) => selectedItem?.id === itemId)) {
      setStockSelected((prevSelected) =>
        prevSelected?.filter((selectedItem) => selectedItem?.id !== itemId)
      );
    } else {
      setStockSelected((prevSelected) => [...prevSelected, item]);
    }
    const foundIndex = purchaseSelected?.findIndex(
      (selectedItem) => selectedItem?.id === itemId
    );
    if (foundIndex !== -1) {
      setPurchaseSelected((prevSelected) =>
        prevSelected?.filter((selectedItem) => selectedItem?.id !== itemId)
      );
    }
  };

  const onSubmit = async () => {
    let datas = {
      ...orderData,
      purchaseRequest: {
        rawMaterial: stockSelected[0]?.id,
        type: "raw",
        source: "stock",
        currency: stockSelected[0]?.currency,
        // estimatedDeliveryDate: stockSelected[0]?.deliveryDate,
        orderedUnit: orderData?.piece,
        unitType: stockSelected[0]?.unit,
        unitCost: stockSelected[0]?.price,
      },
    };
    const id = selectedProduct?.id;
    await productService.createOrder(id, datas).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res?.data?.message);
        onClose();
        refresh();
      } else if (res?.data?.code === 2) {
        Toast("error", t("product:noLimitOrder"));
        onClose();
        refresh();
      } else {
        Toast("error", res?.data?.message);
        onClose();
        refresh();
      }
    });
  };

  useEffect(() => {
    if (data) {
      setPurchaseSelected(data.map((item) => item));
    }
  }, [data]);

  const totalCostSupply =
    data && data.length > 0
      ? data.reduce((sum, item) => {
          const itemTotal = item?.price * orderData?.piece;
          return sum + itemTotal;
        }, 0)
      : "---";

  return (
    <div className="flex flex-col w-[790px]">
      <p className="text-sm font-normal text-secondary-600 mt-1">
        {t("product:materialRequiredForWork")}
      </p>
      <div className="h-12 flex flex-row items-center gap-x-4 w-full mt-5 bg-[#F9FAFB] border border-t border-r-0 border-l-0 border-b border-[#EAECF0]">
        <div className="flex flex-row items-center gap-x-2 ml-2">
          {selectedProduct?.image ? (
            <img
              src={generateFileUrl(selectedProduct?.image)}
              className="min-w-[32px] w-[32px] h-[24px] rounded border border-secondary-300 object-cover"
            />
          ) : (
            <div className="w-[32px] min-w-[32px] h-[24px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
              <NoEquIcon />
            </div>
          )}
          <p className="text-sm font-medium text-secondary-900">
            {selectedProduct?.name || "---"}
          </p>
        </div>

        <p className="text-xs font-normal text-secondary-700">
          S {t("product:stockCode")}: {selectedProduct?.stockCode || "---"}
        </p>
        <p className="text-xs font-normal text-secondary-700">
          {t("product:workOrderCode")}: {selectedProduct?.code || "---"}
        </p>
        <div className="flex items-center gap-x-1 flex-row">
          <QuantityIcon color={"#475467"} />
          <p className="text-xs font-normal text-secondary-700">
            {selectedProduct?.qualityQuantity || "---"}
          </p>
          <p className="text-xs font-normal text-secondary-700">
            {t("product:totalStock")}
          </p>
        </div>
      </div>
      <div className="w-full flex-1 h-full">
        <div className="min-w-full divide-y ">
          <div
            className={`w-full min-h-[380px] h-[380px] border-b overflow-y-scroll scrollbar-hide`}
          >
            <div className="flex mt-5 max-h-[44px] min-h-[44px]">
              {tableHeadItems.map((item, i) => {
                return (
                  <div
                    key={i}
                    scope="col"
                    className={`border-b px-4 py-3 ${
                      i === 4 ? "" : "border-r"
                    } w-[${
                      i === 0
                        ? "35%"
                        : i === 4
                        ? "22%"
                        : i === 3 || i === 2
                        ? "13%"
                        : i === 1
                        ? "17%"
                        : ""
                    }] ${
                      !item.active ? "text-secondary-500" : "text-secondary-900"
                    } items-center text-center`}
                  >
                    <span
                      className={`text-center rounded  ${
                        !item.active ? "text-gray-500" : "text-gray-500"
                      } flex text-xs font-medium ${
                        i === 0 ||
                        i === 1 ||
                        i === 2 ||
                        i === 3 ||
                        i === 4 ||
                        i === 5
                          ? "items-center justify-center"
                          : " items-center"
                      } cursor-pointer whitespace-nowrap`}
                    >
                      {item?.name}
                    </span>
                  </div>
                );
              })}
            </div>
            {data?.map((item, i) => {
              return (
                <div
                  className={`flex w-full items-center border-b h-[72px] min-h-[72px] `}
                >
                  <div className="py-4 px-4 overflow-hidden flex items-center border-r h-[72px] w-[35%] ">
                    <div className="flex border w-full p-2 shadow-sm rounded-lg">
                      <div className="w-[56px] h-[42px] min-w-[56px] min-h-[42px] rounded border border-secondary-200 overflow-hidden">
                        {item?.image ? (
                          <img src={generateFileUrl(item?.image)} />
                        ) : (
                          <div className="w-[56px] h-[42px] min-w-[56px] min-h-[42px] flex rounded  overflow-hidden">
                            <NoAvatar
                              name={item?.name || "--"}
                              rounded={4}
                              theme={"product"}
                              minHeight="42px"
                              minWidth="56px"
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col ml-2">
                        <p className="text-sm font-medium text-secondary-900">
                          {item?.name}
                        </p>
                        <p className="text-xs font-normal text-secondary-600">
                          Stock ID: {item?.stockCode || "---"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[17%] ">
                    <p className="w-[90%] whitespace-nowrap text-center overflow-hidden text-ellipsis text-md text-secondary-600 dark:text-[#F5F5F5] font-normal">
                      {item?.price}
                    </p>
                    <p className="text-sm font-normal text-secondary-500">
                      {item?.currency}
                    </p>
                  </div>
                  <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[13%] ">
                    <p className="w-[90%] whitespace-nowrap text-center overflow-hidden text-ellipsis text-md text-secondary-600 dark:text-[#F5F5F5] font-normal">
                      {orderData?.piece || "---"}
                    </p>
                    <p className="text-sm font-normal text-secondary-500">
                      {item?.unit}
                    </p>
                  </div>
                  <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[13%] ">
                    <p
                      className={`w-[90%] whitespace-nowrap text-center overflow-hidden  ${
                        item?.stockCount < item?.orderCount
                          ? "text-error-600"
                          : "text-success-600"
                      } text-ellipsis text-md  dark:text-[#F5F5F5] font-normal`}
                    >
                      {item?.quantity || "---"}
                    </p>
                    <p className="text-sm font-normal text-secondary-500">
                      {item?.unit}
                    </p>
                  </div>

                  <div className=" h-full p-3 flex items-start flex-col w-[22%] justify-center gap-y-1">
                    <div className="flex gap-x-2 items-center cursor-pointer w-fit">
                      <div
                        onClick={() => {
                          if (
                            purchaseSelected?.some(
                              (selectedItem) => selectedItem?.id === item?.id
                            )
                          ) {
                          } else {
                            togglePurchaseSelected(item);
                          }
                        }}
                        className={`min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] w-4 rounded-full flex items-center justify-center border ${
                          purchaseSelected?.some(
                            (selectedItem) => selectedItem?.id === item?.id
                          )
                            ? "bg-[#DC6803] border-[#DC6803]"
                            : "border-[#D0D5DD]"
                        }`}
                      >
                        <div className="flex min-w-[6px] max-w-[6px] min-h-[6px] max-h-[6px] bg-white rounded-full"></div>
                      </div>
                      <div className="text-secondary-500 font-normal text-sm">
                        {t("product:markPurchase")}
                      </div>
                    </div>
                    {orderData?.piece <= item?.quantity && (
                      <div className="flex gap-x-2 items-center cursor-pointer w-fit">
                        <div
                          onClick={() => {
                            if (
                              stockSelected?.some(
                                (selectedItem) => selectedItem?.id === item?.id
                              )
                            ) {
                            } else {
                              toggleStockSelected(item);
                            }
                          }}
                          className={`min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] w-4 rounded-full flex items-center justify-center border ${
                            stockSelected?.some(
                              (selectedItem) => selectedItem?.id === item?.id
                            )
                              ? "bg-[#DC6803] border-[#DC6803]"
                              : "border-[#D0D5DD]"
                          } `}
                        >
                          <div className="flex min-w-[6px] max-w-[6px] min-h-[6px] max-h-[6px] bg-white rounded-full"></div>
                        </div>
                        <div className="text-secondary-500 font-normal text-sm">
                          {t("product:bringFromStock")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row items-center gap-x-4 ">
        <div className="w-[50%] flex flex-row items-center mt-4">
          <p className="text-md font-normal text-secondary-600">
            {t("product:totalNoOfProduct")}
          </p>
          <p className="text-md text-secondary-900 font-semibold ml-auto">
            {data?.length || "---"}
          </p>
        </div>
        <div className="w-[50%] flex flex-row items-center mt-4">
          <p className="text-md font-normal text-secondary-600">
            {t("product:totalCost")}
          </p>
          <p className="text-md text-secondary-900 font-semibold ml-auto">
            {totalCostSupply}
          </p>
        </div>
      </div>
      <hr className="mt-5 bg-secondary-200 w-full h-px" />
      <div className="flex flex-row items-center gap-x-4 w-full mt-4">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          onClick={onClose}
        />
        <Button
          type={""}
          label={t("buttons:save")}
          colorType={"primary-product"}
          size={"md"}
          onClick={() => {
            // setSupplyForm(false);
            stockSelected?.length === 0 ? setPurchaseForm(true) : onSubmit();
          }}
        />
      </div>
      <CustomModal
        isOpen={purchaseForm}
        setIsOpen={setPurchaseForm}
        modalTitle={t("product:supplyForm")}
        children={
          <PurchaseRequestForm
            refresh={refresh}
            orderData={orderData}
            setPurchaseSelected={setPurchaseSelected}
            purchaseSelected={purchaseSelected}
            setSupplyForm={setSupplyForm}
            setPurchaseForm={setPurchaseForm}
            selectedProduct={selectedProduct}
          />
        }
      />
    </div>
  );
};

export default SupplyFormModal;
