import React, { useState, useEffect } from "react";
import * as yup from "yup";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, TimeInput } from "../../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { Toast } from "../../../utils/toastify/toast";
import { Loader } from "../../../assets/icons/stepsIcons";
import { orderService } from "../../../services/order.service";
import ProductToggle from "../../machine/Twin/components/ProductToggle";

const OrderEditModal = ({ selectedProduct, setIsOpen, refresh, editData, t }) => {
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const schema = yup.object({
    orderNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    piece: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    deliveryDate: yup
      .date()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      deliveryDate: moment().format("YYYY-MM-DD"),
    },
  });

  const onSubmit = async (data) => {
    const sendData = { ...data, isPieceControl: enabled };
    if (editData?.id) {
      setLoading(true);
      await orderService.updateOrderInfo(editData?.id, sendData).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res?.data?.message);
          setIsOpen(false);
          refresh();
        } else {
          Toast("error", res?.data?.message);
          setIsOpen(false);
        }
        setLoading(false);
      });
    } else {
      Toast("error", "Order not found");
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (editData?.id) {
      setValue("orderNo", editData?.orderNo);
      setValue("piece", editData?.piece);
      setValue("deliveryDate", editData?.deliveryDate);
      setEnabled(editData?.isPieceControl);
    }
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-[352px] min-w-[352px] ">
        <div className="flex w-full h-full flex-col gap-5 my-6 ">
          <div className="flex border w-full h-full p-2 border-[#D0D5DD] shadow-xs rounded-lg gap-3 group relative">
            <div className="max-w-[72px] max-h-[54px] ">
              <img
                src={generateFileUrl(selectedProduct?.image)}
                className="max-w-[72px] max-h-[54px] min-w-[72px] min-h-[54px] border rounded-[4px] shadow-sm"
              />
            </div>
            <div className="flex flex-col w-[70%]">
              <span className="text-sm font-semibold text-[#101828] truncate">{selectedProduct?.name}</span>
              <span className="text-xs font-normal text-[#475467] truncate">{selectedProduct?.code}</span>
              <span className="text-xs font-normal text-[#475467] truncate">{selectedProduct?.stockCode}</span>
            </div>
          </div>
          <div className="flex flex-col gap-y-4 w-full">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("product:orderNo")}
                  value={value}
                  onChange={onChange}
                  theme={"product"}
                  onBlur={onBlur}
                  validate={errors?.orderNo ? "error" : ""}
                  errorMessage={errors?.orderNo ? errors?.orderNo?.message : ""}
                />
              )}
              name="orderNo"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("table:piece")}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  theme={"product"}
                  validate={errors?.piece ? "error" : ""}
                  errorMessage={errors?.piece ? errors?.piece?.message : ""}
                  disabled={true}
                />
              )}
              name="piece"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TimeInput
                  label={t("table:deliveryDate")}
                  theme={"product"}
                  value={moment(value).format("YYYY-MM-DD")}
                  onBlur={onBlur}
                  onChange={(v) => {
                    setValue("deliveryDate", v);
                  }}
                  errorMessage={errors?.deliveryDate ? errors?.deliveryDate?.message : ""}
                  validate={errors?.deliveryDate ? "error" : ""}
                />
              )}
              name="deliveryDate"
            />
          </div>
          <div className="flex w-full min-h-[20px] max-h-[20px] h-5 gap-x-3 items-center">
            <ProductToggle enabled={enabled} setEnabled={setEnabled} />
            <p className="text-[#344054] font-medium text-sm">{t("product:implementProcess")}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <Button
            colorType={"secondary-gray"}
            size={"lg"}
            label={t("buttons:cancel")}
            onClick={() => {
              setIsOpen(false);
            }}
          />
          {loading ? (
            <div className="flex items-center justify-center bg-[#DC6803]  rounded-lg w-full h-[46px] max-h-[46px]">
              <Loader currentColor="#DC6803" currentFill="currentFill" />
            </div>
          ) : (
            <Button colorType={"primary-product"} size={"lg"} label={t("product:createOrder")} type="submit" />
          )}
        </div>
      </form>
    </>
  );
};
export default OrderEditModal;
