import React, { useEffect, useState } from "react";
import OperationDetailHeader from "./components/OperationDetailHeader";
import { reportService } from "../../../services/report.service";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import OperationDetailInfo from "./components/OperationDetailInfo";
import MothlyDetailChart from "./components/MothlyDetailChart";
import Skeleton from "react-loading-skeleton";
import ShiftSelecter from "./components/ShiftSelecter";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { authStore } from "../../../stores/auth.store";
import OperationUpuTimeline from "./OperationUpuTimeline";

const OperationDetail = () => {
  const { operationId, dates } = useParams();
  const navigate = useNavigate();
  const [date, setDate] = useState(moment(dates).format("YYYY-MM-DD"));
  const formattedDate = moment(date).format("YYYY-MM-DD");

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [gaugeData, setGaugeData] = useState([]);
  const [energyUsageData, setEnergyUsageData] = useState([]);

  const [legendSelected, setLegendSelected] = useState({
    Availability: true,
    Performance: true,
    Quality: true,
    kWh: true,
    kw: true,
    "kWh Cost": true,
    kVA: true,
    kVAh: true,
  });

  let datas = {
    processId: operationId,
    date: formattedDate,
  };
  const [groupedDatas, setGroupedDatas] = useState([]);

  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["operationDetail", formattedDate],
    queryFn: async () => await reportService.getOperationConsoleData(datas),
  });


 

  const shiftlyDatas = async (data) => {
    await data?.shiftlyTimelineData?.reduce((acc, item) => {
      const key = item?.status;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key]?.push(item);
      setGroupedDatas(acc);
      return acc;
    }, {});
  };

  const handleDateChange = (newDate) => {
    const formattedNewDate = moment(newDate).format("YYYY-MM-DD");
    setDate(formattedNewDate);
    navigate(`/app/machine/digital-twin/operation-detail/${operationId}/${formattedNewDate}`);
  };

  useEffect(() => {
    // if (
    //   moment(date).format("YYYY-MM-DD") ===
    //   moment(new Date()).format("YYYY-MM-DD")
    // ) {
    // } else
    if (moment(date).format("YYYY-MM-DD") === moment(dates).format("YYYY-MM-DD")) {
    } else {
      handleDateChange(moment(date).format("YYYY-MM-DD"));
    }
  }, [date]);

  useEffect(() => {
    if (data) {
      shiftlyDatas(data);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [dates]);

  useEffect(() => {
    setDate(dates);
  }, []);

  const getSpecialZoneTime = (start, end) => {
    reportService
      .getCustomDateReportOperation({
        processId: data?.processId,
        startDate: moment(start).format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(end).format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        setGaugeData(
          [
            { ...res?.data?.measurable, type: "measurable" },
            { ...res?.data?.utilization, type: "Utilization" },
            { ...res?.data?.availability, type: "Availability" },
            { ...res?.data?.performance, type: "Performance" },
            { ...res?.data?.quality, type: "qualityRate" },
            { ...res?.data?.oee, type: "OEE" },
          ] || [{}, {}, {}]
        );

        setEnergyUsageData(
          {
            kw: res?.data?.kw,
            kwh: res?.data?.kwh,
            cCount: res?.data?.cCount,
            kwhCostT1: res?.data?.kwhCostForStandard,
            kwhCostForT3: res?.data?.kwhCostForThreeTime,
            kwhRatioByCompany: res?.data?.kwhRatioByCompany,
            kwhRatioByOperation: res?.data?.kwhRatioByOperation,
          } || []
        );
      });
  };

  useEffect(() => {
    setGaugeData(
      [
        { ...data?.measurable, type: "measurable" },
        { ...data?.utilization, type: "Utilization" },
        { ...data?.availability, type: "Availability" },
        { ...data?.performance, type: "Performance" },
        { ...data?.quality, type: "qualityRate" },
        { ...data?.oee, type: "OEE" },
      ] || [{}, {}, {}]
    );
    setEnergyUsageData(
      {
        kw: data?.kw,
        kwh: data?.kwh,
        cCount: data?.cCount,
        kwhCostT1: data?.kwhCostForStandard,
        kwhCostForT3: data?.kwhCostForThreeTime,
        kwhRatioByCompany: data?.kwhRatioByCompany,
        kwhRatioByOperation: data?.kwhRatioByOperation,
      } || []
    );
  }, [data]);

  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide">
      <OperationDetailHeader
        index={data?.operations?.findIndex((x) => x.id === operationId)}
        data={data}
        isLoading={isLoading}
        datas={datas}
        refetch={refetch}
        isFetching={isFetching}
        date={date}
      />
      <div className="flex items-start w-full pt-6">
        {isLoading || isFetching ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[275px]" />
        ) : (
          <MothlyDetailChart
            data={data}
            currentDay={date}
            setDate={setDate}
            setLegendSelected={setLegendSelected}
            legendSelected={legendSelected}
            selectedDate={dates}
            selectedEquipmentId={data?.processId}
            selectedOperationName={data?.processName}

          />
        )}
      </div>
      {isLoading || isFetching ? (
        <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[256px]" />
      ) : (
        <OperationDetailInfo data={data} isLoading={isLoading} isFetching={isFetching} gaugeData={gaugeData} energyUsageData={energyUsageData} />
      )}
      <div className="w-full mb-6 mt-4">
        <ShiftSelecter
          shiftSelecter={(r) => {
            setDate(r);
          }}
          dates={dates}
          isLoading={isLoading}
          currentDay={date}
          setCurrentDay={setDate}
          isFetching={isFetching}
        />
      </div>
      {isLoading || isFetching ? (
        <Skeleton containerClassName="w-full" height={450} borderRadius={8} />
      ) : (
        <OperationUpuTimeline
          refetch={refetch}
          date={date}
          data={data}
          shiftlyData={data?.shiftlyTimelineData}
          groupedDatas={groupedDatas}
          startTime={startTime}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          endTime={endTime}
          getSpecialZoneTime={getSpecialZoneTime}
        />
      )}
    </div>
  );
};

export default OperationDetail;
