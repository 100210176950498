import React, { memo, useEffect, useMemo, useState } from "react";
import { ArrowLeftIcon } from "../assets/icons/PersonIcons";
import { useNavigate, useSearchParams } from "react-router-dom";
import ShiftCalendarTable from "./machine/components/ShiftCalendarTable";
import { Button } from "../components";
import { companyService } from "../services/company.service";
import { useQuery } from "@tanstack/react-query";
const OldDayShifts = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageData, setPageData] = useState([]);
  const { isLoading, refetch, isFetching } = useQuery({
    enabled: false,
    queryKey: ["list-old-day-shifts"],
    queryFn: async () => {
      try {
        const res = await companyService.getOldDayShifts(Number(searchParams.get("pageNo")) || pageNo);
        const queryParams = new URLSearchParams();
        queryParams.set("pageNo", res?.data?.meta?.page);
        setSearchParams(queryParams);
        setPageCount(res?.data?.meta?.pageCount);
        setPageData(res?.data?.data?.map((item) => {
          return { ...item, times: item?.shiftGroups?.shifts?.flat() };
        }));
        return res;
      } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
      }
    },
    retry: 0,
  });

  const changePage = (newPageNo) => {
    setPageNo(newPageNo);
    searchParams.set("pageNo", newPageNo.toString());
    setSearchParams(searchParams);
  };

  useEffect(() => {
    refetch();
  }, [pageNo]);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="h-[56px] w-full flex items-center border-b">
        <Button
          size={"md"}
          onClick={() => navigate(-1)}
          colorType={"tertiary-gray"}
          iconLeft={
            <div className="text-secondary-700">
              <ArrowLeftIcon />
            </div>
          }
        />
        <h1 className="text-2xl font-semibold text-gray-900 ml-3">Geçmiş Günler</h1>
      </div>
      <div className="flex-1 w-full flex flex-col pt-5">
        <ShiftCalendarTable
          isLoading={isLoading || isFetching}
          title={"Geçmiş Vardiyalar"}
          data={pageData}
          type="past"
          menuType={"standart"}
          refetch={refetch}
          //
          pageNo={pageNo}
          pageCount={pageCount}
          changePage={changePage}
          isPaginated={true}
        />
      </div>
    </div>
  );
};

export default OldDayShifts;
