import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { productService } from '../../../services/product.service'
import { Button } from '../../../components'
import CustomImage from '../../../components/CustomImage'
import { NoEquIcon } from '../../../assets/icons/machineIcons'
import MintueSecondInput from '../../../components/inputs/MintueSecondInput'
import { Toast } from '../../../utils/toastify/toast'

const TargetSettingTimeModal = ({ selectedJunction, setOpenSetupTime, metadataId, refetch, junctionData, equipmentData, suggestedCycleTime }) => {
    
   
    const { t } = useTranslation()
    const junctionDatas = junctionData.find(item => item?.id === selectedJunction);
    const [inputValue, setInputValue] = useState("");
    const [checked, setChecked] = useState(false);
    const [checkedMachine, setCheckedMachine] = useState(false);

    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };
    const toggleCheckedMachine = () => {
        setCheckedMachine((prev) => !prev);
    };
    
    const estimatedTimeGroup = junctionDatas?.header ? junctionDatas?.header?.internalOperation?.plannableNodes?.find((element) => { return element?.id == equipmentData?.id }) :
      junctionDatas?.flexHeader?.plannableNodes ?  junctionDatas?.flexHeader?.plannableNodes?.find((element) => { return element?.id == equipmentData?.id }) : junctionDatas?.flexHeader?.estimatedSettingsTime


    useEffect(() => {
        setInputValue(estimatedTimeGroup?.estimatedSettingsTime ? estimatedTimeGroup?.estimatedSettingsTime || "" : estimatedTimeGroup || "");
    }, [junctionDatas]);


    const onSubmit = async () => {
        const sendData = {
            oldJunctionCalculateStatus: checked ? checked : false,
            estimatedSettingsTime: inputValue,
            allStationStatus: checkedMachine ? checkedMachine : false,
            type: junctionDatas?.header ? 'standart' : 'flex',
            
        };
        const operationId = junctionDatas?.header ? junctionDatas?.header?.internalOperation?.id : junctionDatas?.flexHeader?.id
        await productService.updateStationCycleOrSettingTime(operationId, equipmentData?.id, sendData)
            .then((res) => {
                if (res.data.code === 0) {
                  Toast("success", res.data.message);
                  setOpenSetupTime(false)
                  refetch()
                } else {
                  Toast("error", res.data.message);
                }
            })
            .catch((err) => { Toast("error", err?.response?.data?.message) });
    };
    return (
        <div className='flex flex-col w-full gap-y-6'>
            <div className='flex flex-row items-center mt-6 gap-x-2 w-full border rounded-lg p-2 border-[#D0D5DD] bg-white'>
                {equipmentData?.equipmentImage ? (
                    <div>
                        <CustomImage
                            borderRadius={2}
                            src={equipmentData?.equipmentImage}
                            style={{
                                width: 64,
                                height: 48,
                                minWidth: 64,
                                minHeight: 48,
                                borderRadius: 4,
                                borderWidth: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                display: "flex",
                                borderColor: "#0000001A",
                                backgroundColor: "#fff",
                            }}
                        />
                    </div>
                ) : (
                    <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                        <NoEquIcon />
                    </div>
                )}
                <div className='flex flex-col items-start'>
                    <p className='text-secondary-700 w-[250px] truncate font-semibold text-md'>{equipmentData?.equipmentName || "--"}</p>
                    <p className='text-sm w-[250px] truncate text-secondary-500'>{equipmentData?.processName}</p>
                </div>
            </div>
            <div className='w-full'>
                <MintueSecondInput
                    label={"Hedef İş Süresi "}
                    value={inputValue}
                    theme={"machine"}
                    onChange={(e) => setInputValue(e)}
                />
            </div>

            <div className='flex flex-col gap-y-2 w-full'>
                <div className='flex flex-row items-center w-full'>
                    <span className={`w-5 h-5 border rounded-md flex justify-center items-center mr-2 cursor-pointer ${checked ? "bg-[#7F56D9] border-[#7F56D9]" : "border-[#D0D5DD]"
                        }`}
                        onClick={toggleChecked}
                    >
                        {checked && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        )}
                    </span>
                    <p className='text-sm text-secondary-600'>{t("product:updatePastData")}</p>
                </div>
                <div className='flex flex-row items-center w-full'>
                    <span
                        className={`w-5 h-5 min-w-5 min-h-5 border rounded-md flex justify-center items-center mr-2 cursor-pointer ${checkedMachine ? "bg-[#7F56D9] border-[#7F56D9]" : "border-[#D0D5DD]"
                            }`}
                        onClick={toggleCheckedMachine}
                    >
                        {checkedMachine && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        )}
                    </span>
                    <p className='text-sm text-secondary-600'>{t("product:applyThisCycleTime")}</p>
                </div>
            </div>
            <div className='flex flex-row items-center gap-x-3 w-full'>
                <Button colorType={"secondary-gray"} label={t("buttons:stop")} onClick={() => setOpenSetupTime(false)} />
                <Button onClick={()=>onSubmit()} type={"button"} colorType={"primary-machine"} label={t("buttons:save")} />
            </div>
        </div>
    )
}

export default TargetSettingTimeModal