import React, { useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import AlertModal from "../alert-Modal/Alert-Modal";
import JunctionUpdate from "../modal/JunctionUpdate";
import CustomModal from "../modal/CustomModal";
import { Toast } from "../../utils/toastify/toast";
import { useNavigate } from "react-router-dom";
import {
  NoEquIcon,
  ProductDetailIcon,
  QualityDetailIcon,
} from "../../assets/icons/machineIcons";
import moment from "moment";
import { CalenderIconProduct } from "../../assets/icons/equipmentIcon";
import { purchaseService } from "../../services/purchase.service";
import {
  DeleteIcon,
  DotsVerticalIcon,
  NewPlusIcon,
  PieceIcon,
} from "../../assets/icons/commonIcons";

const PurchaseJunctionCard = ({
  rightButton,
  onClick,
  image,
  name,
  id,
  estimatedDeliveryDate,
  user,
  refetch,
  type,
  status,
  piece,
  pages,
  openCard,
  qualityPercent,
  startDate,
}) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isJunction, setIsJunction] = useState(false);
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState("");

  const menuOptionsActive = [
    {
      label: t("product:finishJuction"),
      value: "finish",
      icon: <ProductDetailIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
  ];

  const onDeleted = async () => {
    if (rightButton) {
      await purchaseService.deletePlannedPurchase(id).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          refetch();
          setIsAlert(false);
          setDeleteId(id);
        } else {
          Toast("error", res.data.message);
          setIsAlert(false);
          setDeleteId("");
        }
      });
    } else {
      await purchaseService.deletePurchase(id, deleteId).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          refetch();
          setIsAlert(false);
          setDeleteId(id);
        } else {
          Toast("error", res.data.message);
          setIsAlert(false);
          setDeleteId("");
        }
      });
    }
  };

  return (
    <>
      <div
        onClick={onClick}
        className={`flex w-full h-[92px] ${
          onClick && "cursor-pointer"
        } flex-col  gap-x-2 items-start rounded-t-[4px] rounded-b-lg border  border-[#D0D5DD]  shadow-xs max-h-[92px] min-h-[92px] bg-white group relative`}
      >
        <div
          className={`w-full border-t-4 rounded-t-[3px] ${
            type == "work"
              ? "border-t-success-500 "
              : type === "setup"
              ? "border-t-[#EAAA08]"
              : "border-t-[#98A2B3]"
          }`}
        ></div>
        <div className="flex flex-row items-start gap-x-[6px] pt-[6px] px-2 pb-[6px] w-full">
          <div className="w-[80%] flex flex-row items-center gap-x-[6px]">
            {image ? (
              <img
                data-tooltip-id={`${id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${name} `}
                onClick={openCard}
                src={generateFileUrl(image || "")}
                className="w-[64px] min-w-[64px] h-[48px] cursor-pointer rounded-[2px] border border-secondary-300 "
              />
            ) : (
              <div
                onClick={openCard}
                className="w-[64px] min-w-[64px] h-[48px] rounded border border-secondary-300 shadow-sm bg-secondar-50 flex items-center justify-center"
              >
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col w-[90%] items-start gap-y-[6px]">
              <p className="truncate w-[80%] text-sm text-secondary-700 font-semibold">
                {name || "---"}
              </p>
              <div className="flex flex-row items-center gap-x-3 w-full text-secondary-600">
                <div className="flex flex-row items-center">
                  <PieceIcon />
                  <p className="text-xs ml-1 font-normal">{piece || "---"}</p>
                  <p className="text-xxs ml-0.5 font-normal">ad</p>
                </div>
              </div>
            </div>
          </div>
          {user ? (
            user?.avatar ? (
              <div
                onClick={() => {
                  navigate(
                    `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                      startDate
                    ).format("YYYY-MM-DD")}`
                  );
                }}
                data-tooltip-id={`${id}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`${user?.name + " " + user?.lastName} `}
                className="ml-auto relative"
              >
                <img
                  className="w-[48px] min-w-[48px] cursor-pointer h-[48px] absolute right-[4px] rounded-full border border-gray-300"
                  src={generateFileUrl(user?.avatar)}
                />
              </div>
            ) : (
              <div
                className="cursor-pointer ml-auto relative"
                onClick={() => {
                  navigate(
                    `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                      startDate
                    ).format("YYYY-MM-DD")}`
                  );
                }}
                data-tooltip-id={`${id}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`${user?.name + " " + user?.lastName} `}
              >
                <NoAvatar
                  size={48}
                  fontSize={22}
                  name={user?.name}
                  lastName={user?.lastName}
                  color={"#475467"}
                />
              </div>
            )
          ) : (
            <button
              onClick={rightButton}
              type="button"
              className="min-w-[48px] h-[48px] rounded-full ml-auto border-dashed border border-gray-300 flex items-center justify-center"
            >
              <NewPlusIcon />
            </button>
          )}
        </div>
        <div className="flex flex-row items-center justify-between w-full h-[26px] bg-[#F9FAFB] py-1 px-2 rounded-b-[8px]  border-t-[0.5px] border-t-[#D0D5DD]">
          <div className="flex flex-row gap-1 items-center ">
            <CalenderIconProduct />
            <p className="text-xs font-medium text-secondary-600">
              {moment(estimatedDeliveryDate)?.format("DD/MM/YYYY") ||
                "--/--/--"}
            </p>
            <p className="text-[10px] font-normal text-secondary-600">
              {t("product:deliveryDate")}
            </p>
          </div>
          <div className="flex justify-between items-center border-[0.5px] border-[#D0D5DD] min-h-[20px] max-h-[20px] h-[20px] min-w-[84px] max-w-[84px] w-[84px] pl-[6px] pr-1 py-[2px] rounded">
            <p className="text-[#344054] text-xxs font-semibold">
              {t("reports:quality")}
            </p>
            <span className="flex min-h-[14px] max-h-[14px] h-[14px] bg-[#EAAA08] px-1 py-[2px] rounded-sm text-[#FFFFFF] font-semibold text-xxs items-center justify-center">
              %{qualityPercent || "0"}
            </span>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[8px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute ${
              pages === "equipmentDetail"
                ? "top-[2px] right-[32px]"
                : "top-[36px] right-[3px]"
            } min-w-[200px] max-w-[200px] h-fit bg-white border   z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            <>
              {menuOptionsActive?.map((item, index) => {
                if (type === "planned" && index === 0 && index === 2) {
                  return null;
                } else if (status === "completed" && index === 0) {
                  return null;
                }
                return (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item.value === "delete") {
                        setIsAlert(true);
                        setDeleteId(id);
                      }

                      if (item.value === "finish") {
                        onClick();
                      }

                      setShowDropDown(false);
                    }}
                    key={index}
                    type="button"
                    className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                      item?.value === "delete"
                        ? "hover:bg-error-50"
                        : "hover:bg-secondary-100"
                    }`}
                  >
                    {item?.icon}
                    <p
                      className={`font-medium text-sm  ${
                        item?.value === "delete"
                          ? "text-[#D92D20]"
                          : "text-secondary-700"
                      }`}
                    >
                      {item.label}
                    </p>
                  </button>
                );
              })}
            </>
          </div>
        )}
      </div>
      <Tooltip id={`${id}`} />
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={id}
            pages={"activeAndPlanned"}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default PurchaseJunctionCard;
