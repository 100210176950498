import React, { useState, useEffect, useCallback } from "react";
import { Button, CustomModal, Loading } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { purchaseService } from "../../services/purchase.service";
import useWindowDimensions from "../../hooks/useWindowDimension";
import diacritics from "diacritics";
import { junctionService } from "../../services/junction.service";
import Badgets from "../../components/buttons/Badgets";
import { ScrollChevronIcon } from "../../assets/icons/productIcons";
import SearchInput from "../../components/inputs/SearchInput";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import MaterialSupplyActivePlannedCard from "../../components/new-cards/material-supply/MaterialSupplyActivePlannedCard";
import MaterialSupplyPlannedCard from "../../components/new-cards/material-supply/MaterialSupplyPlannedCard";
import JunctionCardType from "../../components/junction/JunctionCardType";
import generateFileUrl from "../../utils/generateFileUrl";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
import { Toggle } from "../machine/Twin/components";
import moment from "moment";
import SelectUserModal from "../../components/modal/SelectUserModal";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import PlanPurchaseJunctionModal from "./modals/PlanPurchaseJunctionModal";
import MaterialSupplyPlannedActiveCard from "./cards/material-supply/MaterialSupplyPlannedActiveCard";
import NewMaterialSupplyPlannedCard from "./cards/material-supply/NewMaterialSupplyPlannedCard";
import MaterialSupplyPlanViewPlannedCard from "./cards/material-supply/MaterialSupplyPlanViewPlannedCard";
import NewMaterialSupplyPlanViewPlannedCard from "./cards/material-supply/NewMaterialSupplyPlanViewPlannedCard";
import MaterialSupplyPlanModal from "./modals/MaterialSupplyPlanModal";
import MaterialSupplyHeaderCard from "./cards/material-supply/MaterialSupplyHeaderCard";
import CustomImage from "../../components/CustomImage";
import MaterialSupplyPlannedEventCard from "../../components/new-cards/material-supply/MaterialSupplyPlannedEventCard";

const MaterialSupplyPlanViewPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [tableHeadItemsLength, setTableHeadItemsLength] = useState(0);
  const [supplierData, setSupplierData] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isOpenSelectUserModal, setIsOpenSelectUserModal] = useState(false);
  const [isOpenFinishJunction, setIsOpenFinishJunction] = useState(false);
  const [rowLoading, setRowLoading] = useState(null);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [draggedProduct, setDraggedProduct] = useState(null);
  const [isOpenPlanningModalData, setIsOpenPlanningModalData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [arr, setArr] = useState([]);
  const [showFlexProducts, setShowFlexProducts] = useState(true);
  const planView = useQuery({
    queryKey: ["purchase-junctions-plan-planned"],
    enabled: false,
    queryFn: async () => await purchaseService.purchaseTransactionsPlanned(id, "planner"),
    retry: 0,
  });

  const headData = useQuery({
    queryKey: ["all-supplier-plan-view-group-junctions"],
    enabled: false,
    retry: 0,
    queryFn: async () => await purchaseService.listSupplierGroupForCompany(),
  });

  const refetch = () => {
    planView.refetch();
    headData.refetch();
  };
  useEffect(() => {
    headData.refetch();
    if (id != ":id" && id != "undefined" && id != auth?.user?.company?.id) {
      planView.refetch();
      setSelectedGroup(headData?.data?.find((item) => item?.id == id));
    } else {
      console.log("else girdi", headData?.data);
      //  navigate(`/app/product/mrp/supply/material/${headData?.data[0]?.id}/plan-view`)
    }
  }, [id]);

  // useEffect(() => {
  //   if (headData?.data?.length > 0 && (id == ":id" || id == "undefined")) {
  //     navigate(
  //       `/app/product/mrp/supply/material/${headData?.data[0]?.id}/plan-view`
  //     );
  //   }
  // }, [headData?.data]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "10826fc4-bf01-4164-acf0-72bb213e88d3") {
      } else if (auth?.user?.company?.id == "b0d47c1a-e5dd-4ec1-a655-424af970bbfe") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);
  const tabOptions = [
    { value: "list", label: t("product:list") },
    { value: "junction", label: t("product:track") },
    { value: "plan", label: t("product:planner") },
  ];
  const handleHeaderLength = useCallback(() => {
    if (!planView?.data?.suppliers) return;
    const headLengths = planView?.data.suppliers.map((d) => (d.junctions?.length + 1 || 0) + (d.junctions?.length || 0));
    setTableHeadItemsLength(Math.max(...headLengths, 2));
  }, [planView?.data]);

  const handleDrop = (node) => {
    setSupplierData(node);
    setIsOpenPlanningModal(true);
  };
  const onDragEnd = async (result) => {
    if (!result.destination) return; // Eğer geçersiz bir hedef varsa çıkış yap

    const sourceIndex = Number(result.source.droppableId);
    const destinationIndex = result.destination.index;

    // Güncel state'in kopyasını aldik (immütability için)
    const newItems = [...arr];
    const findChangedRowData = newItems?.[sourceIndex];

    if (!findChangedRowData?.junctions) return;

    // Sürüklenen elemanın `junctions` arrayindeki indexi bulundu
    const draggedItemIndex = findChangedRowData.junctions.findIndex((element) => element?.id === result.draggableId);

    if (draggedItemIndex === -1) return;

    // Sürüklenen elemanı çıkarildi ve eklenerek güncellendi
    const updatedJunctions = [...findChangedRowData.junctions];
    const [movedData] = updatedJunctions.splice(draggedItemIndex, 1);

    // Hedef index'e göre eleman yerleştirildi
    updatedJunctions.splice(destinationIndex, 0, movedData);

    // Sıralama güncellendi
    const newArr = updatedJunctions.map((obj, ind) => ({
      ...obj,
      planningOrder: ind,
    }));

    // Güncellenmiş `junctions` array'i state'e set edildi
    const updatedItems = newItems.map((obj) => (obj.id === findChangedRowData.id ? { ...findChangedRowData, junctions: newArr } : obj));

    // State guncellendi
    setArr(updatedItems);

    // API'ye güncellenmiş veri gonderildi
    setRowLoading(sourceIndex);
    await purchaseService.updatePlanningJunctionList(newArr);
    refetch();
  };

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };
  useEffect(() => {
    if (id) {
      setArr(planView?.data?.suppliers);
      handleHeaderLength();
    }
  }, [planView?.data, handleHeaderLength, id]);
  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const filter = planView?.data?.orderList
    ?.filter((item) => (!(item?.flexProduct || item?.flexRawMaterial || item?.name) ? item : showFlexProducts ? item : null))
    ?.filter((a) => a != null);
  const filteredData = filter?.filter((item) => {
    const nameProcessed = diacritics.remove(
      item?.rawMaterial?.name?.toLowerCase() ||
        item?.productInternalOperation?.product?.name?.toLowerCase() ||
        item?.productExternalOperation?.product?.name?.toLowerCase()
    );
    const stockCodeProcessed = diacritics.remove(
      item?.rawMaterial?.stockCode?.toLowerCase() ||
        item?.productInternalOperation?.product?.stockCode?.toLowerCase() ||
        item?.productExternalOperation?.product?.stockCode?.toLowerCase()
    );
    const searchQueryProcessed = diacritics.remove(searchQuery.toLowerCase());
    return nameProcessed.includes(searchQueryProcessed) || stockCodeProcessed.includes(searchQueryProcessed);
  });
  const tableHeadItems = [];
  for (let i = 0; i < Math.max(1, tableHeadItemsLength); i++) {
    tableHeadItems.push({ title: `${i + 1}`, id: i });
  }

  return (
    <>
      <ScrollSync>
        <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative step-1 scrollbar-hide">
          <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
            <span className="mr-3 md:flex sm:flex xs:flex hidden">
              <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
            </span>
            <div className="flex items-center">
              <span className="font-codecMedium text-2xl text-black">upu.</span>
              <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
              {!(headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading) && (
                <p className="text-xl font-normal ml-2 text-secondary-500">
                  {selectedGroup && selectedGroup?.name ? `${t("product:materialSupply")} / ${selectedGroup?.name}` : t("product:materialSupply")}
                </p>
              )}
            </div>
            <span className="ml-auto">
              <Button
                colorType={"primary-product"}
                size={"md"}
                label={t("product:supplierList")}
                onClick={() => navigate("/app/product/mrp/supply/material/list")}
              />
            </span>
          </div>
          {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
            <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
          ) : (
            <div className="h-[48px] min-h-[48px] w-full overflow-x-auto overflow-y-hidden flex ">
              {headData?.data
                ?.sort((a, b) => b?.requestCount - a?.requestCount)
                ?.map((item, index) => {
                  return (
                    <div className={`min-w-[192px] ${index !== headData?.data?.length - 1 ? "border-b" : "border-b-0"} max-h-[40px] pr-5`}>
                      <MaterialSupplyHeaderCard
                        onClick={async () => {
                          navigate(`/app/product/mrp/supply/material/${item?.id}/plan-view`);
                        }}
                        name={item?.name}
                        selectedOperation={{
                          id: id,
                          name: item?.name,
                        }}
                        id={item?.id}
                        length={item?.requestCount}
                      />
                    </div>
                  );
                })}
            </div>
          )}
          {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
            <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
          ) : (
            <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-4 mb-4">
              {tabOptions?.map((option, index) => (
                <div
                  className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                    "plan" == option?.value ? "bg-white shadow-sm" : ""
                  }`}
                  onClick={() => {
                    if (option?.value === "junction") navigate(`/app/product/mrp/supply/material/${id}/junction/:cardId`);
                    if (option?.value === "list") navigate(`/app/product/mrp/supply/material/${id}/list`);
                  }}
                >
                  <p className={`text-md font-semibold ${"plan" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
                </div>
              ))}
            </div>
          )}
          {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
            <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
          ) : (
            <>
              <div className="flex border border-[#E4E7EC]  flex-col rounded-xl">
                <div className="flex w-full px-6 py-3 items-center border-b mb-2">
                  <div className="flex w-full min-h-[28px] max-h-[28px] gap-x-2 items-center">
                    <p className="flex text-[#101828] font-semibold text-lg">
                      {planView?.data?.name} {t("product:products")}
                    </p>
                    <Badgets colorType={"outline-warning"} label={filteredData?.length} />
                  </div>
                  <div className="flex flex-row items-center gap-x-3 justify-end w-full">
                    <div className="flex max-w-[320px] w-full justify-end">
                      <SearchInput theme={"product"} setSearchVal={setSearchQuery} />
                    </div>
                  </div>
                </div>
                <div className="flex w-full px-4 pb-3">
                  {planView?.data?.orderList?.length > 0 && (
                    <div className="w-full pb-4 pt-2 flex overflow-x-auto overflow-y-hidden relative">
                      {filteredData?.map((item, index) => {
                        const statusColor = () => {
                          if (item?.flexProduct || item?.flexRawMaterial || item?.name) {
                            return "#E9D7FE";
                          } else {
                            switch (item?.status) {
                              case "finished":
                                return "#12B76A";
                              case "started":
                                return "#FDE172";
                              case "rejected":
                                return "#D92D20";
                              case "flexProduct":
                                return "#E9D7FE";
                              default:
                                return "#F2F4F7";
                            }
                          }
                        };
                        return <NewMaterialSupplyPlanViewPlannedCard item={item} setDraggedProduct={setDraggedProduct} />;
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ minHeight: height - 300 }} className=" w-full mt-4 border rounded-xl border-[#E4E7EC]  flex flex-col overflow-hidden relative">
                <div
                  className={`w-11 h-full ${
                    isRightArrowHidden ? "hidden duration-1000" : ""
                  } z-[120] right-0 backdrop-blur-md cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-[#FFFFFF33]`}
                  onClick={() => {
                    sideScroll(document.getElementById("content-wrapper"), 55, 300, 50);
                  }}
                >
                  <ScrollChevronIcon />
                </div>

                <div
                  className={`w-11 h-full ${
                    isLeftArrowHidden ? "hidden duration-1000" : ""
                  } z-[120] left-0 absolute cursor-pointer flex bg-[#FFFFFF33] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
                  onClick={() => {
                    sideScroll(document.getElementById("content-wrapper"), 55, 300, -20);
                  }}
                >
                  <ScrollChevronIcon />
                </div>
                <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
                  <ScrollSyncPane>
                    <div id="content-wrapper" className="flex items-center w-full h-full overflow-x-auto scrollbar-hide">
                      {tableHeadItems?.map((item, index) => (
                        <div
                          key={index}
                          className={` min-h-[44px] ${!isLeftArrowHidden && index === 0 ? "sticky left-11 z-50" : ""} flex items-center ${
                            !(index === 0 || index === 1) ? "justify-between" : ""
                          } ${
                            index === 0 ? "w-[250px] max-w-[250px] min-w-[250px]" : "w-[400px] max-w-[400px] min-w-[400px]"
                          } px-6 py-3 text-sm bg-[#F9FAFB] font-semibold text-[#344054] border-r border-secondary-200`}
                        >
                          {index === 0 ? (
                            <>
                              <p className="text-sm text-[#344054] font-semibold">{t("product:suppliers")}</p>
                            </>
                          ) : index === 1 ? (
                            <>
                              <p className="text-sm text-[#344054] font-semibold">{t("product:msPlanneds")}</p>
                            </>
                          ) : index === 2 ? (
                            <div className="ml-auto">
                              {/* <p className="text-sm text-[#344054] font-semibold">{t("product:plannedWorks")}</p> */}
                              {item?.title}
                            </div>
                          ) : (
                            <>
                              <p></p>
                              {item?.title}
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </ScrollSyncPane>
                </div>

                <div className="flex-1 w-full flex flex-col overflow-y-auto scrollbar-hide bg-white">
                  {arr?.map((a, b) => {
                    return (
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={`${b}`} direction="horizontal">
                          {(provided, snapshot) => (
                            <div
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={() => handleDrop(a)}
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className="flex w-full min-h-[128px]"
                            >
                              <div
                                className={`w-[250px] max-w-[250px] min-w-[250px] ${
                                  isLeftArrowHidden ? "" : "sticky left-11 z-[100]"
                                } h-full px-6 py-4 border-r bg-white border-b gap-y-3 flex flex-col items-start`}
                              >
                                <p className="text-md  w-[210px] truncate font-semibold text-secondary-700">{a?.name || "--"}</p>
                                <div className="flex flex-row items-center gap-x-3">
                                  {a?.image ? (
                                    <div>
                                      <CustomImage
                                        borderRadius={2}
                                        src={a?.image}
                                        style={{
                                          width: 72,
                                          height: 54,
                                          minWidth: 72,
                                          minHeight: 54,
                                          borderRadius: 4,
                                          borderWidth: 1,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          display: "flex",
                                          borderColor: "#0000001A",
                                          backgroundColor: "#fff",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-[72px] min-w-[72px] h-[54px] min-h-[54px] rounded flex border items-center justify-center font-medium text-[#B54708] text-xl cursor-pointer bg-gradient-to-bl from-[#FFFAEB] to-[#FEF0C7]">
                                      {a?.name?.slice(0, 2)?.toUpperCase()}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <ScrollSyncPane>
                                <div id="content-wrapper" className="w-full overflow-x-auto scrollbar-hide flex border-b border-gray-200 max-h-[128px] m-0 p-0">
                                  {a?.junctions
                                    ?.sort((x, y) => x?.planningOrder - y?.planningOrder)
                                    .map((item, index) => {
                                      return (
                                        <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                          {(provided, snapshot) => (
                                            <div className="" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                              <div className="w-[400px] min-w-[400px] max-w-[400px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white h-full">
                                                <MaterialSupplyPlannedEventCard
                                                  data={item}
                                                  refetch={refetch}
                                                  setIsOpenPlanningModal={setIsOpenPlanningModal}
                                                  setIsOpenPlanningModalData={setDraggedProduct}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                </div>
                              </ScrollSyncPane>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </ScrollSync>
      {/* <CustomModal
          isOpen={isOpenPlanningModal}
          setIsOpen={setIsOpenPlanningModal}
          onClose={() => {
            setIsOpenPlanningModal(false);
          }}
          modalTitle={t("chat:startJob")}
          width="fit-content"
          children={
            <PlanPurchaseJunctionModal
              setIsOpen={setIsOpenPlanningModal}
              processId={planView?.data?.id}
              refetch={refetch}
              selectedJunction={draggedProduct}
              supplierId={supplierData?.id}
              companyId={auth.user.company.id}
            />
          }
        /> */}
      <CustomModal
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        onClose={() => {
          setIsOpenPlanningModal(false);
        }}
        width="fit-content"
        modalTitle={t("product:planPurchase")}
        subTitle={t("product:specifyPlannings")}
        children={
          <MaterialSupplyPlanModal
            setIsOpen={setIsOpenPlanningModal}
            processId={planView?.data?.id}
            refetch={refetch}
            selectedJunction={draggedProduct}
            groupId={id}
            companyId={auth.user.company.id}
            planView={planView}
          />
        }
      />
      <CustomModal
        isOpen={isOpenSelectUserModal}
        setIsOpen={setIsOpenSelectUserModal}
        onClose={() => {
          setIsOpenSelectUserModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectUserModal
            t={t}
            setIsOpen={setIsOpenSelectUserModal}
            isOpen={isOpenSelectUserModal}
            selectedJunction={selectedJunction}
            handleData={refetch}
            purchase={true}
          />
        }
      />
      <CustomModal
        isOpen={isOpenFinishJunction}
        setIsOpen={setIsOpenFinishJunction}
        modalTitle={t("product:finishJuction")}
        children={
          <FinishJunctionModal
            handleData={refetch}
            modalTheme={"machine"}
            junctionId={selectedJunction?.id}
            closeModal={() => setIsOpenFinishJunction(false)}
            type={selectedJunction?.internalOperation ? "internal" : "external"}
          />
        }
      />
    </>
  );
};

export default MaterialSupplyPlanViewPage;
