import React from "react";
import Badgets from "../../../components/buttons/Badgets";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { Button } from "../../../components";
import { PointIcon } from "../../../assets/icons/PersonIcons";
import { DetailIcon } from "../../../assets/icons/commonIcons";
import moment from "moment";

const TablePersonComp = ({ name, lastname, department, point, numFormatter, avatar, difNumber, id, navigate }) => {
  return (
    <>
      <div key={id} className="flex w-full min-h-[72px] max-h-[72px] px-5 py-2 border-b items-center justify-center">
        <span>
          <Badgets colorType={"fill-gray"} label={difNumber} size={"sm"} />
        </span>
        <div className="flex w-full ml-3">
          {avatar ? (
            <img className="max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px] rounded-full border border-[#0000001A]" src={generateFileUrl(avatar)} />
          ) : (
            <NoAvatar name={name} lastName={lastname} size={40} />
          )}
          <div className="flex flex-col w-full ml-3">
            <span className="text-sm font-semibold text-[#344054]">
              {name} {lastname}
            </span>
            <span className="text-xs font-normal text-[#667085]">{department}</span>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <PointIcon />
          <span className="ml-1 text-[#344054] text-sm font-medium">{String(numFormatter(point))}</span>
        </div>
        <div className="ml-8">
          <Button
            colorType={"tertiary-gray"}
            iconLeft={<DetailIcon />}
            size={"sm"}
            onClick={() => {
              navigate(`/app/machine/digital-twin/user-detail/${id}/${moment().format("YYYY-MM-DD")}`);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TablePersonComp;
