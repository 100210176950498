import React, { useEffect, useState } from "react";
import NoAvatar from "../../../components/avatar/NoAvatar";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button, Input } from "../../../components";
import moment from "moment";
import { orderService } from "../../../services/order.service";
import { useQuery } from "@tanstack/react-query";
import Badgets from "../../../components/buttons/Badgets";
import SearchInput from "../../../components/inputs/SearchInput";
import { Toast } from "../../../utils/toastify/toast";
import { useTranslation } from "react-i18next";
import diacritics from "diacritics";
import { useNavigate } from "react-router-dom";

const LegacyWipExitModal = ({ detailId, setIsOpen, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState(null);
  const [filteredOrder, setFilteredOrder] = useState([]);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["wip-exit-modal-detail-legacy"],
    queryFn: async () => await orderService.getOrderDetailsWip(detailId),
    retry: 0,
  });

  useEffect(() => {
    if (!searchVal) {
      setFilteredOrder(data?.data?.workOrderList || []);
    } else {
      const filtered = data?.data?.workOrderList?.filter((ord) => {
        const workOrderLabel = ord?.type === "order" ? `WO-${ord?.workOrderNo || "--"}` : `SWO-${ord?.stockWorkOrderNo || "--"}`;

        const normalizedLabel = diacritics.remove(workOrderLabel || "").toLowerCase();
        const normalizedSearchVal = diacritics.remove(searchVal || "").toLowerCase();

        return normalizedLabel.includes(normalizedSearchVal);
      });
      setFilteredOrder(filtered);
    }
  }, [searchVal, data]);

  const [sendAmounts, setSendAmounts] = useState({});

  const handleInputChange = (id, value) => {
    setSendAmounts((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = async () => {
    const sendData = data?.data?.workOrderList
      ?.filter((item) => sendAmounts[item?.id] && parseInt(sendAmounts[item?.id]) > 0)
      .map((item) => ({
        id: item?.id,
        desiredQuantity: parseInt(sendAmounts[item?.id]),
      }));

    const totalDesiredQuantity = sendData.reduce((total, item) => total + item?.desiredQuantity, 0);

    if (totalDesiredQuantity > data?.data?.wipQuantity?.ready) {
      Toast("error", `Girilen miktar hazır adetten büyük olamaz (max: ${data?.data?.wipQuantity?.ready}).`);
      return;
    }

    if (sendData?.length > 0) {
      await orderService.transferJunctionsToOrder(data?.data?.wipQuantity?.id, sendData).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          setIsOpen(false);
          refetch();
        } else {
          Toast("error", res.data.message);
        }
      });
    } else {
      Toast("error", "Lütfen gerekli alanları doldurun.");
    }
  };

  const tableHeadItems = [
    t("product:orderNoCustomer"),
    t("table:deliveryDate"),
    t("product:orderQuantityss"),
    t("product:totalDeliveredQuantity"),
    t("product:remainingOrders"),
    t("product:quantityToBeSent"),
  ];

  return (
    <div className="flex flex-col w-full h-full pt-4">
      {isLoading || isFetching ? (
        <div className="w-full h-[90px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
      ) : (
        <div className="flex w-full px-6 py-5 gap-x-6 border-t border-[#E4E7EC]">
          {data?.data?.orderDetails?.product?.image ? (
            <img
              className="flex min-w-[64px] max-w-[64px] w-[64px] min-h-[48px] max-h-[48px] h-[48px] rounded border border-[#0000001A]"
              src={generateFileUrl(data?.data?.orderDetails?.product?.image)}
              alt={data?.data?.orderDetails?.product?.name}
            />
          ) : (
            <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden">
              <NoAvatar name={data?.data?.orderDetails?.product?.name || "--"} rounded={4} theme={"product"} />
            </div>
          )}
          <div className="flex flex-col w-[464px]">
            <p className="ml-1 text-[#101828] font-semibold text-md w-[370px] truncate">{data?.data?.orderDetails?.product?.name || "--"}</p>
            <div className="flex w-fit max-w-[370px] truncate px-1 py-[3px] bg-[#FFFAEB] rounded items-center">
              <p className="text-[#B54708] text-xs font-medium">
                {t("product:stock")} : {data?.data?.orderDetails?.product?.stockCode || "--"}
              </p>
            </div>
          </div>
          <div className="flex w-[276px]">
            <div className="flex flex-col gap-y-1 w-1/2">
              <p className="text-[#475467] text-sm">{t("product:readyQuantity")}</p>
              <div className="flex gap-x-[6px]">
                <QuantityAcceptedIcon />
                <p className="text-md font-semibold text-[#079455]">{data?.data?.wipQuantity?.ready ?? "0"}</p>
              </div>
            </div>
            <div className="flex flex-col gap-y-1 w-1/2">
              <p className="text-[#475467] text-sm">{t("product:scrapQuantitys")}</p>
              <div className="flex gap-x-[6px]">
                <QuantityRejectedIcon />
                <p className="text-md font-semibold text-[#D92D20]">{data?.data?.wipQuantity?.scrap ?? "0"}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading || isFetching ? (
        <div className="w-full h-[50vh] max-h-[50vh] mb-3 bg-gray-200 animate-pulse rounded-lg" />
      ) : (
        <div className="flex flex-col w-full min-h-[50vh] max-h-[50vh] h-[50vh] overflow-y-auto overflow-x-hidden scrollbar-hide border rounded-lg border-[#E4E7EC]">
          <div className="flex w-full justify-between min-h-[64px] max-h-[64px] h-[64px] px-6 py-3 items-center">
            <div className="flex gap-x-2 items-center justify-center">
              <p className="text-[#101828] font-semibold text-lg">{t("product:openOrder")}</p>
              <Badgets colorType={"outline-error"} label={data?.data?.workOrderList?.length || 0} size={"sm"} />
            </div>
            <div className="flex min-w-[240px] max-w-[240px] w-[240px] ">
              <SearchInput theme={"product"} setSearchVal={setSearchVal} />
            </div>
          </div>
          <div className="flex max-h-[26px] min-h-[26px] w-full bg-[#F9FAFB] sticky top-0 z-[10]">
            {tableHeadItems.map((item, i) => (
              <div
                key={i}
                className={`flex border-y px-2 border-r border-[#E4E7EC] items-center justify-center text-center w-[${
                  i === 0 ? "24%" : i === 1 || i === 2 || i === 4 ? "13%" : i === 3 ? "16%" : "21%"
                }]`}
              >
                <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
              </div>
            ))}
          </div>
          {filteredOrder?.map((order, i) => (
            <div key={i} className="flex w-full items-center border-b h-[76px] min-h-[76px]">
              {/* tablo 1.kolon */}
              <div className="border-r flex h-full items-center justify-between w-[24%] px-6 py-3">
                <div className="flex flex-col">
                  <p className="text-[#B54708] text-sm font-semibold truncate w-[160px]">
                    {order?.type === "order" ? `WO-${order?.workOrderNo || "--"}` : `SWO-${order?.stockWorkOrderNo || "--"}`}
                  </p>
                  <span className="flex gap-x-1 group">
                    <p
                      onClick={() => navigate(`/app/product/mrp/orders/order-detail/${order?.orderDetails?.order?.id}`)}
                      className="text-[#475467] text-xs cursor-pointer hover:underline truncate w-[160px]"
                    >
                      {(order?.orderDetails?.order?.productType === "order"
                        ? "ORD-" + order?.orderDetails?.order?.systemOrderNo
                        : "SP-" + order?.orderDetails?.order?.systemStockOrderNo) || "--"}
                    </p>
                    <span className="group-hover:flex hidden">
                      <LinkIcon />
                    </span>
                  </span>
                  <span className="flex gap-x-1 group">
                    <p
                      onClick={() => navigate(`/app/product/mrp/orders/customer-detail/${order?.orderDetails?.order?.customer?.id}/all`)}
                      className="text-[#475467] text-xs cursor-pointer hover:underline truncate w-[160px]"
                    >
                      {order?.orderDetails?.order?.customer?.name || "--"}
                    </p>
                    <span className="group-hover:flex hidden">
                      <LinkIcon />
                    </span>
                  </span>
                </div>
              </div>
              {/* tablo 2.kolon */}
              <div className="border-r flex flex-col h-full items-center justify-center w-[13%] py-[19px]">
                <p className="text-[#344054] text-sm font-medium">{moment(order?.orderDetails?.deliveryDate).format("DD.MM.YYYY") || "--"}</p>
                <p className="text-[#667085] text-xs">{moment(order?.orderDetail?.deliveryDate).format("dddd")}</p>
              </div>
              {/* tablo 3.kolon */}
              <div className="border-r flex flex-col h-full items-center justify-center w-[13%] py-[19px]">
                <p className="text-[#344054] text-sm font-medium">{order?.desiredQuantity || "0"}</p>
                <p className="text-[#667085] text-xs">{t("product:pieces")}</p>
              </div>
              {/* tablo 4.kolon */}
              <div className="border-r flex flex-col h-full items-center justify-center w-[16%] py-[19px]">
                <p className="text-[#344054] text-sm font-medium">{order?.finishedQuantity ?? "0"}</p>
                <p className="text-[#667085] text-xs">{t("product:pieces")}</p>
              </div>
              {/* tablo 5.kolon */}
              <div className="border-r flex flex-col h-full items-center justify-center w-[13%] py-[19px]">
                <p className="text-[#344054] text-sm font-medium">{order?.desiredQuantity - order?.finishedQuantity ?? "0"}</p>
                <p className="text-[#667085] text-xs">{t("product:pieces")}</p>
              </div>
              {/* tablo 6.kolon */}
              <div className="flex w-[21%] h-full px-6 py-[18px]">
                <Input
                  icon={<QuantityIcon />}
                  theme={"product"}
                  type={"number"}
                  value={sendAmounts[order?.id] || ""}
                  onChange={(e) => handleInputChange(order?.id, parseInt(e?.target?.value))}
                  disabled={data?.data?.wipQuantity?.ready > 0 ? false : true}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {/* {isLoading || isFetching ? (
        <div className="w-full h-[64px] max-h-[64px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
      ) : (
        <div className="w-full pt-6 flex flex-row">
          <div className="flex flex-row items-center w-full py-3 px-4 h-[64px] bg-[#F9FAFB]">
            <div className="flex flex-row items-center gap-x-1 cursor-pointer">
              <p className="text-md text-secondary-600 font-semibold">Stoka Gönder</p>
              <span className="group flex relative">
                <HelpCircleIcon />
                <div className="hidden absolute min-w-[293px] max-w-[293px] w-[293px] min-h-[70px] max-h-[70px] h-[70px] group-hover:flex bottom-[26px] -left-[90px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                  <p className="text-[#FFFFFF] font-semibold text-xs">
                    {"Ürünlerinizin tümünü ya da bir kısmını iş emriyle bir siparişe aktarmak yerine direkt olarak stoka gönderebilirsiniz"}
                  </p>
                </div>
                <span className="hidden absolute group-hover:flex bottom-[20px]">
                  <TriangleTooltipIcon />
                </span>
              </span>
            </div>
            <div className="ml-auto w-[21%]">
              <Input
                icon={<QuantityIcon />}
                theme={"product"}
                type={"number"}
                value={sendAmounts[1] || ""}
                onChange={(e) => handleInputChange(1, parseInt(e?.target?.value))}
                disabled={!(data?.data?.wipQuantity?.ready > 0)}
              />
            </div>
          </div>
        </div>
      )} */}

      {isLoading || isFetching ? (
        <div className="w-full h-[64px] max-h-[64px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
      ) : (
        <div className="flex w-full pt-6 gap-x-3">
          <Button colorType={"secondary-gray"} label={t("buttons:stop")} size={"lg"} onClick={() => setIsOpen(false)} />
          <Button colorType={"primary-product"} label={t("buttons:send")} size={"lg"} onClick={handleSubmit} />
        </div>
      )}
    </div>
  );
};

export default LegacyWipExitModal;

const QuantityAcceptedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_17929_78674)">
        <path
          d="M6.87337 1.66669H3.74837C2.59778 1.66669 1.66504 2.59943 1.66504 3.75002V16.25C1.66504 17.4006 2.59778 18.3334 3.74837 18.3334H8.14652M6.87337 1.66669V6.48812C6.87337 6.569 6.95957 6.62072 7.03094 6.58265L9.99837 5.00002L12.9658 6.58265C13.0372 6.62072 13.1234 6.569 13.1234 6.48812V1.66669M6.87337 1.66669H13.1234M13.1234 1.66669H16.2484C17.399 1.66669 18.3317 2.59943 18.3317 3.75002V9.01208M10.7239 15.1531L13.0876 17.5033L18.3348 12.2628"
          stroke="#17B26A"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17929_78674">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QuantityRejectedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_17929_78679)">
        <path
          d="M6.87533 1.66669H3.75033C2.59973 1.66669 1.66699 2.59943 1.66699 3.75002V16.25C1.66699 17.4006 2.59973 18.3334 3.75033 18.3334H8.14847M6.87533 1.66669V6.48812C6.87533 6.569 6.96152 6.62072 7.03289 6.58265L10.0003 5.00002L12.9678 6.58265C13.0391 6.62072 13.1253 6.569 13.1253 6.48812V1.66669M6.87533 1.66669H13.1253M13.1253 1.66669H16.2503C17.4009 1.66669 18.3337 2.59943 18.3337 3.75002V9.01208M12.2727 12.2353L14.8952 14.8906M17.5297 17.5022L14.8952 14.8906M14.8952 14.8906L17.5297 12.2353M14.8952 14.8906L12.2727 17.5022"
          stroke="#F04438"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17929_78679">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_18121_1076)">
        <path
          d="M6.87533 1.66669H4.16699C2.78628 1.66669 1.66699 2.78597 1.66699 4.16669V15.8334C1.66699 17.2141 2.78628 18.3334 4.16699 18.3334H15.8337C17.2144 18.3334 18.3337 17.2141 18.3337 15.8334V4.16669C18.3337 2.78598 17.2144 1.66669 15.8337 1.66669H13.1253M6.87533 1.66669V6.4524C6.87533 6.54946 6.97876 6.61152 7.0644 6.56585L10.0003 5.00002L12.9363 6.56585C13.0219 6.61152 13.1253 6.54946 13.1253 6.4524V1.66669M6.87533 1.66669H13.1253"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18121_1076">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const LinkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14 6L14 2M14 2H10M14 2L8.66667 7.33333M6.66667 3.33333H5.2C4.0799 3.33333 3.51984 3.33333 3.09202 3.55132C2.71569 3.74307 2.40973 4.04903 2.21799 4.42535C2 4.85318 2 5.41323 2 6.53333V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H9.46667C10.5868 14 11.1468 14 11.5746 13.782C11.951 13.5903 12.2569 13.2843 12.4487 12.908C12.6667 12.4802 12.6667 11.9201 12.6667 10.8V9.33333"
        stroke="#475467"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const HelpCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17461_3132)">
        <path
          d="M6.06016 6.00016C6.2169 5.55461 6.52626 5.1789 6.93347 4.93958C7.34067 4.70027 7.81943 4.61279 8.28495 4.69264C8.75047 4.77249 9.17271 5.01451 9.47688 5.37585C9.78105 5.73718 9.94753 6.19451 9.94683 6.66683C9.94683 8.00016 7.94683 8.66683 7.94683 8.66683M8.00016 11.3335H8.00683M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z"
          stroke="#98A2B3"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17461_3132">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TriangleTooltipIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9" viewBox="0 0 17 9" fill="none">
      <path
        d="M8.5 0.49993L14.5711 0.49993C15.462 0.49993 15.9081 1.57707 15.2782 2.20704L9.20711 8.2781C8.81658 8.66863 8.18342 8.66863 7.79289 8.2781L1.72183 2.20704C1.09186 1.57707 1.53803 0.49993 2.42893 0.49993L8.5 0.49993Z"
        fill="#0C111D"
      />
    </svg>
  );
};
