import React, { useEffect, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { QualityOeeCard, QualityOperationOeeCard } from "../components";
import { useQuery } from "@tanstack/react-query";
import { junctionService } from "../../../../services/junction.service";
import moment from "moment";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { useStore } from "../../../../hooks/useStores";
import { purchaseService } from "../../../../services/purchase.service";
import { useTranslation } from "react-i18next";

const QualityMain = ({ date }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { auth } = useStore();
  const [loading, setLoading] = useState(true);

  const internalData = useQuery({
    queryKey: ["internalData"],
    queryFn: async () =>
      await junctionService.legacyQualityData({
        date: moment(timeFormatter(date, auth?.user?.company?.timeZone)?.formatted).format("YYYY-MM-DD"),
      }),
    retry: 0,
  });

  const serviceData = useQuery({
    queryKey: ["serviceData"],
    queryFn: async () =>
      await junctionService.legacyQualityExternalData({
        date: moment(timeFormatter(date, auth?.user?.company?.timeZone)?.formatted).format("YYYY-MM-DD"),
      }),
    retry: 0,
  });

  const materialData = useQuery({
    queryKey: ["materialData"],
    queryFn: async () => await await purchaseService.getPurchaseJunctionsForCompany(),
    retry: 0,
  });

  const getRandomInt = () => {
    return Math.floor(Math.random() * 1001);
  };

  const getRandomNumberBetween0And1 = () => {
    return Math.random();
  };
  const getRandomNumbers = () => {
    const num1 = Math.random();
    const num2 = num1 + Math.random() * (1 - num1);

    return [num1, num2];
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="w-full h-full gap-y-4 flex flex-col pt-4 overflow-y-auto overflow-x-hidden scrollbar-hide">
      {loading ? (
        <div className="flex flex-col w-full gap-y-4 pt-4">
          <div className="min-h-[268px] max-h-[268px] h-[268px] flex w-full bg-[#FCFCFD] border-[1px] border-[#D0D5DD] rounded-xl animate-pulse "></div>
          <div className="min-h-[268px] max-h-[268px] h-[268px] flex w-full bg-[#FCFCFD] border-[1px] border-[#D0D5DD] rounded-xl animate-pulse "></div>
          <div className="min-h-[268px] max-h-[268px] h-[268px] flex w-full bg-[#FCFCFD] border-[1px] border-[#D0D5DD] rounded-xl animate-pulse "></div>
        </div>
      ) : (
        <>
          <div className="min-h-[268px] max-h-[268px] h-[268px] flex w-full bg-[#FCFCFD] border-[1px] border-[#D0D5DD] rounded-xl ">
            <div
              onClick={() => {
                navigate("/app/product/mrp/quality-control/internal");
              }}
              className="bg-white rounded-l-xl border-r-[1px] border-[#EAECF0]  w-[292px] min-w-[292px] h-full px-3"
            >
              <QualityOeeCard
                timeMass={getRandomInt()}
                value={getRandomNumberBetween0And1()}
                kpi1={getRandomNumbers()[0]}
                kpi2={getRandomNumbers()[1]}
                gaugeWidth={200}
                activeLength={internalData?.data?.activeJunction?.length || 0}
                waitingLength={internalData?.data?.pendingJunction?.length || 0}
                gaugeHeight={108}
                gaugeRadius={"160%"}
                name={t("product:internalOperationsQualityControl")}
              />
            </div>
            <div className="flex w-full h-full gap-x-4 px-6 items-center overflow-x-auto overflow-y-hidden">
              {internalData?.data?.operations
                ?.sort((a, b) => b?.activeJunction - a?.activeJunction)
                .map((process, index) => {
                  return (
                    <QualityOperationOeeCard
                      onClick={() => {
                        navigate(`/app/product/mrp/quality-control/internal`);
                      }}
                      gaugeWidth={150}
                      gaugeHeight={84}
                      gaugeRadius={"160%"}
                      name={process.name}
                      activeLength={process?.activeJunction}
                      waitingLength={process?.pendingJunction}
                      timeMass={getRandomInt()}
                      value={getRandomNumberBetween0And1()}
                      kpi1={getRandomNumbers()[0]}
                      kpi2={getRandomNumbers()[1]}
                    />
                  );
                })}
            </div>
          </div>
          <div className="min-h-[268px] max-h-[268px] h-[268px] flex w-full bg-[#FCFCFD] border-[1px] border-[#D0D5DD] rounded-xl ">
            <div
              onClick={() => {
                navigate("/app/product/mrp/quality-control/external");
              }}
              className="bg-white rounded-l-xl border-r-[1px] border-[#EAECF0]  w-[292px] min-w-[292px] h-full px-3"
            >
              <QualityOeeCard
                timeMass={getRandomInt()}
                value={getRandomNumberBetween0And1()}
                kpi1={getRandomNumbers()[0]}
                kpi2={getRandomNumbers()[1]}
                gaugeWidth={200}
                activeLength={serviceData?.data?.activeJunction?.length || 0}
                waitingLength={serviceData?.data?.pendingJunction?.length || 0}
                gaugeHeight={108}
                gaugeRadius={"160%"}
                name={t("product:serviceIncomingQuality")}
              />
            </div>
            <div className="flex w-full h-full gap-x-4 px-6 items-center overflow-x-auto overflow-y-hidden">
              {serviceData?.data?.operations
                ?.sort((a, b) => b?.activeJunction - a?.activeJunction)
                ?.map((process, index) => {
                  return (
                    <QualityOperationOeeCard
                      gaugeWidth={150}
                      gaugeHeight={84}
                      gaugeRadius={"155%"}
                      name={process.name}
                      // value={process.actual}
                      // kpi1={process.average ? process?.average.toFixed(2) : 0}
                      // kpi2={process.average == 0 ? 0 : process.kpiValue}
                      // timeMass={(process.timeMass / 3600).toFixed(1) || 25000}
                      timeMass={getRandomInt()}
                      value={getRandomNumberBetween0And1()}
                      kpi1={getRandomNumbers()[0]}
                      kpi2={getRandomNumbers()[1]}
                      activeLength={process?.activeJunction}
                      waitingLength={process?.pendingJunction}
                      onClick={() => {
                        navigate("/app/product/mrp/quality-control/external");
                      }}
                    />
                  );
                })}
            </div>
          </div>
          <div className="min-h-[268px] max-h-[268px] h-[268px] flex w-full bg-[#FCFCFD] border-[1px] border-[#D0D5DD] rounded-xl ">
            <div
              onClick={() => {
                navigate("/app/product/mrp/quality-control/supply");
              }}
              className="bg-white rounded-l-xl border-r-[1px] border-[#EAECF0]  w-[292px] min-w-[292px] h-full px-3"
            >
              <QualityOeeCard
                timeMass={getRandomInt()}
                value={getRandomNumberBetween0And1()}
                kpi1={getRandomNumbers()[0]}
                kpi2={getRandomNumbers()[1]}
                activeLength={materialData?.data?.activeJunction?.length || 0}
                waitingLength={materialData?.data?.pendingJunction?.length || 0}
                gaugeWidth={200}
                gaugeHeight={108}
                gaugeRadius={"160%"}
                name={t("product:materialIncomingQuality")}
              />
            </div>
            <div className="flex w-full h-full gap-x-4 px-6 items-center overflow-x-auto overflow-y-hidden">
              {materialData?.data?.operations
                ?.sort((a, b) => b?.activeJunction - a?.activeJunction)
                ?.map((process, index) => {
                  return (
                    <QualityOperationOeeCard
                      gaugeWidth={150}
                      gaugeHeight={84}
                      gaugeRadius={"155%"}
                      name={process?.name}
                      activeLength={process?.activeJunction}
                      waitingLength={process?.pendingJunction}
                      timeMass={getRandomInt()}
                      value={getRandomNumberBetween0And1()}
                      kpi1={getRandomNumbers()[0]}
                      kpi2={getRandomNumbers()[1]}
                      onClick={() => {
                        navigate("/app/product/mrp/quality-control/supply");
                      }}
                    />
                  );
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(QualityMain);
