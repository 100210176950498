import React, { useEffect, useState } from "react";
import Badgets from "../../components/buttons/Badgets";
import { Button, CustomModal } from "../../components";
import { Loader, PlusIcon } from "../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";
import OperationCard from "../../steps/process/components/OperationCard";
import { externalOperationService } from "../../services/external-operation.service";
import AddExternalOperationModal from "../../steps/external/components/AddExternalOperationModal";
import { useStore } from "../../hooks/useStores";

const SettingExternal = () => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTab, setSelectedTab] = useState("process");
  const [loading, setLoading] = useState(false);
  const [externalOperation, setExternalOperation] = useState([]);
  const [tempOperation, setTempOperation] = useState([]);

  const handleData = async () => {
    setLoading(true);
    externalOperationService.listOperation().then(({ data }) => {
      data.map((d) => {
        return {
          ...d,
          responsibleUser: d.responsibleUser.map((r) => r.id),
        };
      });
      setExternalOperation(data);
      setTempOperation(data);
      setLoading(false);
    });
  };

  const handleEdit = (id) => {
    setSelectedItem(id);
    setIsOpen(true);
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center mt-64 justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="w-full flex flex-col h-[82vh] xs:h-[78vh] sm:h-[75vh] md:h-[75vh] gap-5 overflow-hidden">
          <div className="flex xs:flex-col items-center min-h-[72px] xs:gap-4  border-b w-full border-secondary-200">
            <div className="flex flex-col w-full xs:h-[64px] xs:border-b xs:pb-2">
              <p className="text-gray-900 text-lg font-medium dark:text-[#FAFAFA]">
                {t("settingTab:externalOperations")}
              </p>
              <p className="text-gray-500 text-sm dark:text-[#D6D6D6]">
                {t("settingTab:manageYourExternal")}
              </p>
            </div>
            <div className="ml-auto xs:w-full">
              <Button
                colorType={"secondary-gray"}
                label={t("settingTab:newCreateExternalOperation")}
                iconLeft={<PlusIcon />}
                size={"md"}
                onClick={() => {
                  setSelectedItem(null);
                  setIsOpen(true);
                }}
              />
            </div>
          </div>
          <div className="w-fit">
            <Badgets
              size={"md"}
              label={
                externalOperation?.length +
                "/" +
                auth?.user?.company?.limits?.externalOperation +
                " " +
                t("product:externalOperation")
              }
              colorType={"fill-primary"}
            />
          </div>
          <div className="h-[78%] w-full overflow-y-scroll flex flex-col gap-4 scrollbar-hide">
            {tempOperation?.map((e, i) => {
              return (
                <OperationCard
                  key={i}
                  t={t}
                  name={e?.name}
                  icon={e?.iconKey}
                  responsibleUser={e?.responsibleUser.map(
                    (r) => r?.name + " " + r?.lastName + " "
                  )}
                  editOnClick={() => {
                    handleEdit(e);
                  }}
                  deleteOnclick={() => {
                    // handleDelete(e?.id);
                    // setAlertOpen(true);
                  }}
                  page={"external"}
                />
              );
            })}
          </div>
        </div>
      )}
      <CustomModal
        children={
          <AddExternalOperationModal
            data={externalOperation}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedProcess={selectedItem}
            handleData={handleData}
          />
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={t("addProcess:updateProcess")}
      />
    </>
  );
};

export default SettingExternal;
