import React from 'react'
import { Loading } from '../../../components';
import { useTranslation } from 'react-i18next';

const ServiceSupplyTab = ({ type, length, children, loading, onDragEnd, onDragOver, onDrop }) => {

    const { t } = useTranslation();

    const ColorType = () => {
        if (type === "received") return "#FAC515";
        if (type === "complate") return "#17B26A";
        if (type === "planned") return "#98A2B3";
        if (type === "supplier") return "#2E90FA";
        return "#667085";
    };

    const textColorType = () => {
        if (type === "received") return "#A15C07";
        if (type === "complate") return "#067647";
        if (type === "planned") return "#344054";
        if (type === "supplier") return "#175CD3";
        return "#667085";
    };

    const dotColorType = () => {
        if (type === "received") return "#CA8504";
        if (type === "complate") return "#079455";
        if (type === "planned") return "#98A2B3";
        if (type === "supplier") return "#2E90FA";
        return "#667085";
    };

    return (
        <div
            onDragEnd={onDragEnd}
            onDrop={onDrop}
            onDragOver={onDragOver}
            className="w-full min-w-[420px] h-full flex flex-col mx-4 mt-3 bg-white shadow-lg border border-t-0 border-[#E4E7EC] rounded-t"
        >
            <div style={{ backgroundColor: ColorType() }} className="flex w-full min-h-[4px] rounded-t"></div>
            <div className="flex items-center px-6 py-4 border-b-[1px] border-[#E4E7EC]">
                <div
                    style={{
                        backgroundColor: dotColorType(),
                    }}
                    className="w-3 h-3 rounded-full"
                ></div>
                <p style={{ color: textColorType() }} className="text-base font-semibold ml-2">
                    {type === "planned" ? t("product:ssPlanned") : type === "supplier" ? t("product:inSupplier") : type === "received" ? t("product:received") : type === "complate" ? t("product:qualityCompleted") : ""}
                </p>
                <div
                    style={{
                        borderColor: ColorType(),
                        color: textColorType(),
                    }}
                    className="py-0.5 px-[10px] border-[1.5px] rounded-md ml-auto"
                >
                    {length}
                </div>
            </div>

            {loading ? (
                <div className="w-full h-full flex items-center justify-center">
                    <Loading
                        size={40}
                        primary={
                            type === "active" ? "#17B26A" : type === "waiting" ? "#F79009" : type === "finished" ? "#98A2B3" : type === "unplanned" ? "#FF6B6B" : "#667085"
                        }
                        secondary={
                            type === "active" ? "#067647" : type === "waiting" ? "#B54708" : type === "finished" ? "#475467" : type === "unplanned" ? "#A83232" : "#667085"
                        }
                    />
                </div>
            ) : (
                <div className="w-full px-4 py-3 overflow-y-scroll scrollbar-hide h-full overflow-x-hidden">{children}</div>
            )}
        </div>
    )
}

export default ServiceSupplyTab