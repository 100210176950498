import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomImage from "../../../components/CustomImage";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { NewDotsIcon } from "../../../components/new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const WipRawMaterialCard = ({ item, order }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const menuOptions = [
    { label: t("product:rawMaterialDetail"), value: "goToStock", icon: <RawIcon /> },
    {
      label: t("product:supplyDetail"),
      value: "goToSupply",
      icon: <SupplyIcon />,
    },
  ];

  return (
    <div
      data-tooltip-id={`${order?.id}`}
      data-tooltip-place={"right"}
      data-tooltip-content={`
      ${t("product:rawMaterialName")} : ${order?.rawMaterial?.material?.name} 
       -
      ${t("product:stockCode")} : ${order?.rawMaterial?.material?.stockCode}
      `}
      className="flex bg-white flex-row  w-[326px] min-w-[326px] h-[112px] min-h-[112px] relative group pl-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab "
    >
      <div className="flex flex-col w-[90%]">
        <div className="flex flex-row items-center gap-x-3">
          {order?.rawMaterial?.material?.image ? (
            <div>
              <CustomImage
                borderRadius={2}
                src={order?.rawMaterial?.material?.image}
                style={{
                  width: 64,
                  height: 48,
                  minWidth: 64,
                  minHeight: 48,
                  borderRadius: 4,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
              <NoEquIcon />
            </div>
          )}
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-center gap-x-1 w-full">
              <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">{order?.rawMaterial?.material?.name || "Product Name"}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <div className="bg-[#FFFAEB] max-w-[100px]  h-6 rounded px-[3px] py-1">
                <p className="text-xs font-medium max-w-[100px] truncate text-[#B54708]">{order?.rawMaterial?.material?.stockCode || "--"}</p>
              </div>
              <div className="flex items-center justify-center px-1 py-[2px] border border-[#E4E7EC] bg-[#F9FAFB] w-full rounded">
                <p className="text-xxs font-medium text-secondary-700">
                  {order?.product?.quantityUsedPerPiece || "1"} {order?.rawMaterial?.material?.unitType} = 1 ad
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-1.5 w-full">
          <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">{t("product:inSupply")}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
              <p className="text-xs font-medium text-secondary-700">
                {item?.orderedUnit === 0
                  ? "0"
                  : item?.orderedUnit >= 100000
                  ? (item?.orderedUnit / 1000).toFixed(2) + "B"
                  : item?.orderedUnit % 1 !== 0
                  ? item?.orderedUnit.toFixed(2)
                  : item?.orderedUnit || "0"}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">{t("product:inStocks")}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
              <p className="text-xs font-medium text-secondary-700">
                {order?.rawMaterial?.material?.quantity === 0
                  ? "0"
                  : order?.rawMaterial?.material?.quantity >= 100000
                  ? (order?.rawMaterial?.material?.quantity / 1000).toFixed(2) + "B"
                  : order?.rawMaterial?.material?.quantity % 1 !== 0
                  ? order?.rawMaterial?.material?.quantity.toFixed(2)
                  : order?.rawMaterial?.material?.quantity || "0"}
              </p>
            </div>
          </div>
          <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
            <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
              <span className="text-xxs truncate text-[#475467] font-medium ">{t("product:workOrder")}</span>
            </div>
            <div className="flex flex-col w-full items-start">
              <div
                data-tooltip-id={`${order?.id}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`
                                ${t("product:remainings")} : ${order?.rawMaterial?.orderedQuantity?.toFixed(2)} 
                               `}
                className="flex flex-row items-center w-full border-b px-1"
              >
                <p className="text-xxs font-normal text-secondary-600">{t("product:remainings")}</p>
                <p className="text-xs font-medium  truncate ml-auto text-secondary-600">
                  {order?.rawMaterial?.orderedQuantity === 0 || order?.wipPiece === 0
                    ? "0"
                    : order?.rawMaterial?.orderedQuantity >= 100000
                    ? (order?.rawMaterial?.orderedQuantity / 1000).toFixed(0) + "B"
                    : order?.rawMaterial?.orderedQuantity % 1 !== 0
                    ? order?.rawMaterial?.orderedQuantity.toFixed(2)
                    : order?.rawMaterial?.orderedQuantity || "0"}
                </p>
              </div>
              <div
                data-tooltip-id={`${order?.id}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`
                                ${t("product:appliedss")} : ${order?.rawMaterial?.acceptedQuantity?.toFixed(2)} 
                               `}
                className="flex flex-row items-center w-full px-1"
              >
                <p className="text-xxs font-normal text-secondary-600">{t("product:appliedss")}</p>
                <p className="text-xs font-medium max-w-[40px] truncate ml-auto text-secondary-600">
                  {order?.rawMaterial?.acceptedQuantity === 0
                    ? "0"
                    : order?.rawMaterial?.acceptedQuantity >= 100000
                    ? (order?.rawMaterial?.acceptedQuantity / 1000).toFixed(2) + "B"
                    : order?.rawMaterial?.acceptedQuantity % 1 !== 0
                    ? order?.rawMaterial?.acceptedQuantity.toFixed(2)
                    : order?.rawMaterial?.acceptedQuantity || "0"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`flex items-center bg-[#F2F4F7] justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0  border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
      >
        <p className={`text-xxs font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
          {t("product:rawMaterialsNew")} ({order?.rawMaterial?.material?.unitType || "--"})
        </p>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
      >
        <NewDotsIcon />
      </div>
      {isOpen && (
        <div className="absolute w-[190px] p-1 bg-white right-3 top-[16px] border border-secondary-200 rounded-lg shadow-sm flex-col z-[160] hidden group-hover:flex py-1">
          {menuOptions?.map((items, index) => {
            return (
              <>
                <button
                  onClick={() => {
                    if (items.value === "goToSupply") {
                      navigate(
                        `/app/product/mrp/supply/material/${order?.product?.rawMaterial?.supplierGroup?.id}/junction/${order?.product?.rawMaterial?.id}`
                      );
                    } else if (items.value === "goToStock") {
                      navigate(`/app/product/mrp/stock/raw-material/detail/${order?.rawMaterial?.material?.id}`);
                    }
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                >
                  {items?.icon}
                  <p className={`font-medium text-sm ${index == 2 ? "text-[#D92D20]" : "text-secondary-700"}  ml-1`}>
                    {index === "taskList" ? "" : items?.label}
                  </p>
                </button>
              </>
            );
          })}
        </div>
      )}
      <Tooltip className="z-[120]" id={`${order?.id}`} />
    </div>
  );
};

export default WipRawMaterialCard;

export const RawIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.19022 12.4006C6.19022 13.6521 5.17569 14.6666 3.92421 14.6666C2.67273 14.6666 1.6582 13.6521 1.6582 12.4006C1.6582 11.1492 2.67273 10.1346 3.92421 10.1346C5.17569 10.1346 6.19022 11.1492 6.19022 12.4006ZM6.19022 12.4006C6.19022 13.6521 7.20475 14.6666 8.45623 14.6666C9.70771 14.6666 10.7222 13.6521 10.7222 12.4006C10.7222 11.1492 9.70771 10.1346 8.45623 10.1346C7.20475 10.1346 6.19022 11.1492 6.19022 12.4006ZM5.0378 6.52192C4.37121 6.91641 3.92421 7.64271 3.92421 8.47342C3.92421 9.7249 4.93874 10.7394 6.19022 10.7394C7.4417 10.7394 8.45623 9.7249 8.45623 8.47342C8.45623 7.22193 7.4417 6.20741 6.19022 6.20741C5.76944 6.20741 5.37545 6.3221 5.0378 6.52192ZM5.0378 6.52192L9.91555 2.36548C10.5941 1.78727 11.6069 1.8427 12.2183 2.49151V2.49151C12.792 3.10032 12.8278 4.03932 12.3022 4.69011L12.0605 4.98938M8.0687 9.74114L12.0605 4.98938M10.4361 13.5037L14.0874 7.45395C14.8475 6.06043 13.8225 4.45961 12.0605 4.98938"
        stroke="#667085"
        stroke-width="1.33"
      />
    </svg>
  );
};

export const SupplyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M15.3188 8.00043C15.3188 7.63316 15.021 7.33543 14.6538 7.33543C14.2865 7.33543 13.9888 7.63316 13.9888 8.00043H15.3188ZM13.9888 8.00043C13.9888 11.3112 11.3049 13.9951 7.99406 13.9951V15.3251C12.0394 15.3251 15.3188 12.0458 15.3188 8.00043H13.9888ZM7.99406 13.9951C6.15604 13.9951 4.51166 13.1688 3.41099 11.8649L2.39468 12.7228C3.73726 14.3132 5.74783 15.3251 7.99406 15.3251V13.9951Z"
        fill="#667085"
      />
      <path
        d="M0.669473 8.00042C0.669473 8.36769 0.967203 8.66542 1.33447 8.66542C1.70174 8.66542 1.99947 8.36769 1.99947 8.00042H0.669473ZM1.99947 8.00042C1.99947 4.68961 4.68339 2.0057 7.99419 2.0057V0.675698C3.94885 0.675698 0.669473 3.95508 0.669473 8.00042H1.99947ZM7.99419 2.0057C9.80776 2.0057 11.4327 2.81021 12.533 4.08412L13.5395 3.21476C12.1973 1.6608 10.2104 0.675698 7.99419 0.675698V2.0057Z"
        fill="#667085"
      />
      <path
        d="M2.2851 12.5216C2.81092 12.5216 3.23719 12.0953 3.23719 11.5695C3.23719 11.0436 2.81092 10.6174 2.2851 10.6174C1.75927 10.6174 1.33301 11.0436 1.33301 11.5695C1.33301 12.0953 1.75927 12.5216 2.2851 12.5216Z"
        stroke="#667085"
        stroke-width="1.33"
        stroke-miterlimit="10"
      />
      <path
        d="M13.7143 5.26954C14.2401 5.26954 14.6664 4.84327 14.6664 4.31745C14.6664 3.79162 14.2401 3.36536 13.7143 3.36536C13.1885 3.36536 12.7622 3.79162 12.7622 4.31745C12.7622 4.84327 13.1885 5.26954 13.7143 5.26954Z"
        stroke="#667085"
        stroke-width="1.33"
        stroke-miterlimit="10"
      />
      <path
        d="M10.4638 9.9372C10.7377 9.77908 10.9064 9.48687 10.9064 9.17064V6.82973C10.9064 6.5135 10.7377 6.22129 10.4638 6.06317L8.43654 4.89273C8.16267 4.73461 7.82526 4.73461 7.55139 4.89273L5.52412 6.06317C5.25025 6.22129 5.08154 6.5135 5.08154 6.82973V9.17064C5.08154 9.48687 5.25025 9.77908 5.52412 9.9372L7.55139 11.1076C7.82526 11.2658 8.16268 11.2658 8.43654 11.1076L10.4638 9.9372Z"
        stroke="#667085"
        stroke-width="1.33"
        stroke-miterlimit="10"
      />
      <path
        d="M5.08154 6.31866L7.10881 7.48912C7.65655 7.80536 8.33139 7.80536 8.87912 7.48912L10.9064 6.31866"
        stroke="#667085"
        stroke-width="1.33"
        stroke-miterlimit="10"
      />
      <path d="M7.99414 8.00018V11.3631" stroke="#667085" stroke-width="1.33" stroke-miterlimit="10" />
    </svg>
  );
};
